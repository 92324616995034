// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const NoUnreadWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 400px;
  height: 96px;
  margin-top: ${spacing.space_m};
`;

const Heading = styled.div`
  > div {
    display: flex;
    > h3 {
      margin: 0px;
      font-size: 0.88rem;
      line-height: 1.25rem;
    }
  }
  display: grid;
  justify-items: center;
  > span {
    white-space: nowrap;
  }
`;

const Subtitle = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  font-size: 0.88rem;
  line-height: 1.25rem;
  align-items: center;
  justify-items: center;
  color: ${colors.secondary};
`;

const NoResultSubtitle = styled(Subtitle)`
  display: grid !important;
  line-height: 1rem;
`;

export { NoUnreadWrapper, Subtitle, Heading, NoResultSubtitle };
