// @flow

import React from "react";

const Up = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 15L12 10L7 15L17 15Z" fill="black" />
  </svg>
);

export default Up;
