// @flow

import React from "react";
import { InputRightElement, Input } from "@chakra-ui/react";
import { InputGroup as StyledInputGroup } from "./styles";

type Props = {
  domain: String
};

const DomainContainer = ({ domain }: Props) => {
  return (
    <div>
      <StyledInputGroup size="md">
        <Input pr="8.2rem" value={domain} isReadOnly={true} />
        <InputRightElement width="4.5rem" />
      </StyledInputGroup>
    </div>
  );
};

export default DomainContainer;
