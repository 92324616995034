// @flow

import React from "react";

import Title from "./Title";
import Creator from "./Creator";
import MenuBar from "./MenuBar";
import Body from "./Body";
import { Header } from "./styles";

const Current = () => {
  return (
    <div>
      <Header>
        <Title />
        <Creator />
        <MenuBar />
      </Header>
      <Body />
    </div>
  );
};

export default Current;
