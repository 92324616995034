// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const Name = styled.form`
  > span {
    padding-bottom: 0.2em;
    > span {
      font-size: 0.781rem;
      font-weight: bold;
    }
    > svg {
      position: relative;
      top: -2px;
      margin-left: 0.4em;
      cursor: pointer;
    }
  }

  > input {
    padding: 0px;
    font-size: 0.781rem;
    border-bottom: 1px solid ${colors.grey4} !important;
    padding-bottom: 0.2em;
  }
`;

const Email = styled.form`
  > span {
    padding-bottom: 0.2em;
    > span {
      font-size: 1.25rem;
      font-weight: bold;
    }
    > svg {
      position: relative;
      top: -2px;
      margin-left: 0.4em;
      cursor: pointer;
    }
  }

  > input {
    padding: 0px;
    font-size: 1.25rem;
    border-bottom: 1px solid ${colors.grey4} !important;
    padding-bottom: 0.2em;
  }
`;

const PhoneNumber = styled(Name)`
  .flag-dropdown {
    display: none;
  }
  em {
    color: ${colors.secondary};
  }
  .react-tel-input input[type="text"],
  .react-tel-input input[type="tel"] {
    padding: 0px;
    font-size: 0.781rem;
    border: none;
    height: auto;
    border-bottom: 1px solid ${colors.grey4} !important;
    padding: 0 0 0.2em 0 !important;
    border-radius: 0;
  }
  > svg {
    cursor: pointer;
  }
`;

export { Name, Email, PhoneNumber };
