// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

const Footer = styled.div`
  background: ${colors.brightLight};
  display: flex;
  padding: ${spacing.space_inset_l};
  justify-content: flex-end;
  border-top: 1px solid ${colors.grey4};
  > button {
    margin: ${spacing.space_list_m};
  }
  border: none;
`;

const SecondaryFooter = styled(Footer)`
  background: ${colors.grey0};
`;

const ModalFormBody = styled.div`
  background: ${colors.brightLight};
  min-height: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  margin: ${spacing.space_inset_default};

  > div {
    display: block;
    width: 95%;
    margin: ${spacing.space_inset_default};
  }
`;

const Form = styled.form`
  min-width: 355px;
  border-radius: ${radius.small};
  background-color: ${colors.brightLight};
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: ${spacing.space_s};
`;

const CloseOutline = styled.div`
  border: 1px solid ${props => (props.outline ? colors.close : colors.disabled)};
  border-radius: ${radius.medium};
  padding: 0.25em ${spacing.space_m};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: ${spacing.space_m};
  margin-top: ${spacing.space_m};

  > svg {
    display: flex;
  }

  > svg > g > path {
    fill: ${props => (props.outline ? colors.close : colors.disabled)};
  }
  > span {
    color: ${colors.close};
  }
`;

const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 122px 1fr;
  width: 90%;
  margin: ${spacing.space_l} auto;
`;

const BlockFormGroup = styled.div`
  padding: ${spacing.space_list_l};
  margin: ${spacing.space_l} auto;
`;

const InlineCheckboxFormGroup = styled.div`
  width: 90%;
  margin: 0px;
  padding: ${spacing.space_list_l};
  display: grid;
  grid-template-columns: 24px 1fr;
  > label {
    padding-top: ${spacing.space_s};
  }
`;

const InlineFormGroup = styled.div`
  width: 90%;
  margin: 16px auto;
  > textarea {
    margin: ${spacing.space_m};
    width: 98%;
    margin-bottom: 0px;
  }
`;

const InlineLabel = styled.label`
  display: block;
  margin: ${spacing.space_stack_s};
  color: ${colors.primary};
  strong {
    color: ${colors.primary};
  }
`;

const Label = styled(InlineLabel)`
  padding: ${spacing.space_inset_default};
  margin: 0px;
  background: ${colors.brightLight};
  font-size: 12px;
`;

export {
  Footer,
  ModalFormBody,
  Form,
  Body,
  FormGroup,
  InlineFormGroup,
  InlineCheckboxFormGroup,
  Label,
  BlockFormGroup,
  InlineLabel,
  SecondaryFooter,
  CloseOutline
};
