/* eslint-disable react/no-unescaped-entities */
// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import { UserItemWrapper } from "../styles";
import { CreateNewButton, NoResult } from "../Search/styles";
import { NewValue } from "./styles";
import { getUserSearchResult } from "src/reducers";
import { inviteUserFromChecklist } from "src/actions/invite";
import ImageLabel from "src/containers/user/ImageLabel/Medium";
import { getCurrentUserId, getRole } from "src/reducers";

import type { AppState, UID, RoomId } from "src/types";

type Props = {
  role: string,
  search: string,
  results: Array<UID>,
  multiple: boolean,
  fieldId: number,
  roomId: RoomId,
  checklistId: number,
  selected: Array<UID>,
  selectUser: Function,
  setInvite: Function,
  _inviteUserFromChecklist: Function,
  handleClose: Function
};

const Results = ({
  role,
  search,
  results,
  multiple,
  selectUser,
  fieldId,
  roomId,
  checklistId,
  selected,
  _inviteUserFromChecklist,
  setInvite,
  handleClose
}: Props) => {
  const handleInvite = useCallback(() => {
    if (search) {
      _inviteUserFromChecklist({
        email: search,
        displayName: R.head(search.split("@")) || "",
        multiple: multiple || false,
        fieldId,
        roomId,
        checklistId,
        selected
      });
      handleClose();
    } else {
      setInvite(true);
    }
  }, [
    handleClose,
    setInvite,
    fieldId,
    roomId,
    checklistId,
    search,
    multiple,
    selected,
    _inviteUserFromChecklist
  ]);

  const filteredUsers = R.without(selected, results);

  return (
    <>
      <ul>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user, userIndex) => (
            <UserItemWrapper key={userIndex} onClick={() => selectUser(user)}>
              <ImageLabel uid={user} />
            </UserItemWrapper>
          ))
        ) : (
          <NoResult>no matching results.</NoResult>
        )}
      </ul>

      {role !== "demo" && (
        <CreateNewButton
          onClick={handleInvite}
          title={search}
          onlyOption={filteredUsers.length > 0}
        >
          &#43; Add new user{" "}
          {search.length > 0 && <NewValue> "{search}"</NewValue>}
        </CreateNewButton>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  results: getUserSearchResult(app),
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps, {
  _inviteUserFromChecklist: inviteUserFromChecklist
})(Results);
