// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";

import Templates from "./Templates";
import Instances from "./Instances";
import FormInstances from "src/components/Manage/Forms/Instances";
import { getUniqueValues } from "src/actions/workflows";
import {
  getInstanceFilterId,
  getReportType,
  getFormIdOfReport,
  getInstanceReportId
} from "src/reducers";

import type { AppState, FieldId, RoomId, SelectedChecklist } from "src/types";

type Props = {
  id: ?number,
  _getUniqueValues: Function,
  selectedChecklist: SelectedChecklist,
  details: Map<string, any>,
  fieldId: FieldId,
  roomId: RoomId,
  value: any,
  formId: ?number,
  reportId: number
};

const Reports = ({ id, _getUniqueValues, formId, reportId }: Props) => {
  useEffect(() => {
    if (id && !formId) {
      _getUniqueValues({ workflow: id });
    }
  }, [id, _getUniqueValues, formId]);

  if (formId) {
    return <FormInstances id={`${formId}`} reportId={reportId} />;
  } else if (id) {
    return <Instances />;
  }
  return <Templates />;
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const reportId = getInstanceReportId(app);
  const reportType = getReportType(app, reportId || "");
  return {
    id: getInstanceFilterId(app),
    reportId,
    reportType,
    formId:
      reportType === "form" ? getFormIdOfReport(app, reportId || "") : null
  };
};

export default connect(mapStateToProps, {
  _getUniqueValues: getUniqueValues
})(Reports);
