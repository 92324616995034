// @flow

import React from "react";
import { connect } from "react-redux";

import WorkflowName from "src/containers/workflow/Name";
import { getTemplateId } from "src/reducers";

import type { ConversationType, AppState } from "src/types";

type Props = {
  type: ConversationType,
  templateId: ?number
};

const ConversationTypePill = ({ type, templateId }: Props) => {
  switch (type) {
    case "workflow":
      return templateId ? <WorkflowName id={templateId} /> : null;
    case "group":
      return "Feed";
    case "direct":
      return null;
    case "conversation":
      return "Discussion";
    case "approval":
      return "Approval";
    case "task":
      return "Task";
    default:
      return null;
  }
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }) => ({
  templateId: getTemplateId(app, roomId)
});

export default connect(mapStateToProps)(ConversationTypePill);
