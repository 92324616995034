// @flow

import { connect } from "react-redux";
import React, { useCallback, useEffect } from "react";

import {
  Content as StyledContent,
  PrimaryButton,
  Overlay,
  ModalText,
  SecondaryFooter,
  HeadingWrapper,
  Secondary,
  Form
} from "src/components/Manage/Reports/Modal/styles";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import * as colors from "src/styles/constants/colors";
import { deleteTile } from "src/actions/orgSettings";
import ButtonLoader from "src/components/ButtonLoader";

type Props = {
  tile: Object,
  _deleteTile: Function,
  handleClose: Function
};

const Content = ({ tile, _deleteTile, handleClose }: Props) => {
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      _deleteTile(tile.id);
      handleClose();
    },
    [_deleteTile, tile]
  );

  return (
    <Overlay>
      <StyledContent>
        <Form onSubmit={handleSubmit}>
          <HeadingWrapper>Delete</HeadingWrapper>
          <ModalFormBody>
            <ModalText>
              <div>
                Do you want to delete <strong>{tile.title}</strong>
              </div>
            </ModalText>
          </ModalFormBody>
          <SecondaryFooter>
            <PrimaryButton delete>
              <ButtonLoader type="submit" color={colors.danger}>
                Delete
              </ButtonLoader>
            </PrimaryButton>
            <Secondary type="button" onClick={handleClose}>
              Cancel
            </Secondary>
          </SecondaryFooter>
        </Form>
      </StyledContent>
    </Overlay>
  );
};

export default connect(null, {
  _deleteTile: deleteTile
})(Content);
