// @flow

import React from "react";
import { Switch as ChakraSwitch } from "@chakra-ui/react";

type Props = {
  isChecked: boolean,
  onChange: Function,
  color?: string,
  size?: string
};

const Switch = ({
  isChecked = false,
  onChange,
  color = "whatsapp",
  size = "md"
}: Props) => {
  return (
    <ChakraSwitch
      isChecked={isChecked}
      colorScheme={color}
      size={size}
      onChange={onChange}
    />
  );
};

export default Switch;
