export const container = {
  alignItems: "start",
  width: "100%"
};

export const avatar = {
  borderRadius: "8px",
  img: {
    borderRadius: "8px"
  }
};

export const metaDataContainer = {
  alignItems: "start"
};

export const authorAndChannel = {
  alignItems: "center"
};

export const author = {
  my: 0,
  fontWeight: 900,
  color: "unifize_active"
};

export const channel = {
  my: 0,
  color: "gray.500"
};

export const timestamp = {
  fontSize: "xs",
  color: "gray.500",
  my: 0
};
