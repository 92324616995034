// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import { openConversationModal } from "src/actions/chatroom";
import { Title as StyledTitle } from "src/components/chatroom/Name/styles";
import * as atypes from "src/constants/actionTypes";

type Props = {
  address: string,
  id: number,
  canceled: ?boolean,
  title: string,
  templateId: number,
  _openConversationModal: Function
};

const Chatroom = ({
  canceled,
  id,
  address,
  title,
  templateId,
  _openConversationModal
}: Props) => {
  const handleConversation = () => {
    _openConversationModal(id);
    return false;
  };

  return (
    <StyledTitle canceled={canceled}>
      <Link
        onClick={handleConversation}
        to={{
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: address
          },
          meta: {
            query: {
              templateId
            }
          }
        }}
        title={title}
      >
        {title}
      </Link>
    </StyledTitle>
  );
};

export default connect(null, { _openConversationModal: openConversationModal })(
  Chatroom
);
