// @flow

import React from "react";
import usePortal from "react-useportal";

import {
  Modalwrapper,
  Overlay,
  MessageText,
  PendingText,
  CompletedText,
  ConformButton
} from "./styles";

type Props = {
  hideModal: Function
};

const WarningModal = ({ hideModal }: Props) => {
  const { Portal } = usePortal();

  return (
    <Portal>
      <Overlay>
        <Modalwrapper>
          <MessageText>
            You need to have at least one status mapped to
            <PendingText>&nbsp;PENDING&nbsp;</PendingText> and
            <CompletedText>&nbsp;COMPLETED&nbsp;</CompletedText>
            each.
          </MessageText>
          <ConformButton onClick={hideModal}>OK</ConformButton>
        </Modalwrapper>
      </Overlay>
    </Portal>
  );
};

export default WarningModal;
