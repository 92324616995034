// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";
import { Box, Select, Table } from "@chakra-ui/react";

import {
  getCurrentChecklistBuilder,
  getChecklistFieldPosition,
  getFormChecklistFields
} from "src/reducers";
import { fetchForm } from "src/actions/forms";
import FormDropdownOption from "./FormDropdownOption";
import FormField from "./FormField";
import Builder from "./Builder";

type Props = {
  setLocalSettings: Function,
  selectedFormField: ?string,
  selectFormField: Function,
  selectedFormId: ?string,
  setSelectedFormId: Function
};

const FormConditions = ({
  setLocalSettings,
  selectedFormId,
  setSelectedFormId,
  selectedFormField,
  selectFormField
}: Props) => {
  const dispatch = useDispatch();

  const currentFields = useSelector(
    ({ app }) => getCurrentChecklistBuilder(app).fields
  );
  const checklistFieldPosition = useSelector(({ app }) =>
    getChecklistFieldPosition(app)
  );

  // Field data of the form field
  const currentFormField = currentFields[checklistFieldPosition];
  const currentFormFieldSettings = currentFormField?.settings || {};

  const currentFieldFormIds = currentFormFieldSettings.selectedForms || [];
  const selectedFormFields = useSelector(({ app }) =>
    getFormChecklistFields(app, selectedFormId || "")
  );

  useEffect(() => {
    if (R.isEmpty(selectedFormFields) && selectedFormId) {
      dispatch(fetchForm(Number(selectedFormId)));
    }
  }, [selectedFormId]);

  return selectedFormField && selectedFormId ? (
    <Builder
      selectedFormId={Number(selectedFormId)}
      selectedFormField={Number(selectedFormField)}
      selectFormField={selectFormField}
      setLocalSettings={setLocalSettings}
    />
  ) : (
    <Box w={64}>
      <Select
        size="sm"
        placeholder="Select a form to add conditions"
        onChange={e => setSelectedFormId(e.target.value)}
      >
        {currentFieldFormIds.map(formId => (
          <FormDropdownOption key={formId} formId={formId} />
        ))}
      </Select>
      <Table variant="unstyled" size="sm">
        {selectedFormFields
          ? selectedFormFields.map((field, index) => (
              <FormField
                key={field}
                fieldId={field}
                seqNo={index + 1}
                selectFormField={selectFormField}
              />
            ))
          : null}
      </Table>
    </Box>
  );
};

export default FormConditions;
