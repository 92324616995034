// @flow

import { backendUrl } from "src/config/firebase";
import connection from "src/db";
import type { RoomId } from "src/types";

/**
 * Get checklist fields to prompt in a conversation
 *
 * @param {string} roomId The chatroom id for which you are getting prompt fields
 * @returns {Object} containing checklist fields that need to be prompted
 */
export const getPromptFields = async (roomId: RoomId): any => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}/action`, {
    credentials: "include",
    method: "GET"
  });

  return response.json();
};

/**
 * Dismiss a prompt for a specific chatroom and specific user
 *
 * @param {string} uid User Id for which you want to dismiss prompts
 * @param {number} room Room Id for which you want to dismiss prompts
 * @param {number} field Field Id for which you want to dismiss prompts
 */
export const dismissPrompt = async (payload: any): any => {
  const { uid, room, field } = payload;
  const ref = connection()
    .collection(`userData/${uid}/chatrooms`)
    .doc(`${room}`);
  await ref.set(
    {
      dismissed: field
    },
    { merge: true }
  );
};

/**
 * Undo a dismissed prompt for a specific chatroom for a specific user
 *
 * @param {string} uid User Id for which dimissed prompts need to be undone
 * @param {number} room Room Id for which dismissed prompts need to be undone
 */
export const undoDismiss = async (payload: any): any => {
  const { uid, room } = payload;
  const ref = connection()
    .collection(`userData/${uid}/chatrooms`)
    .doc(`${room}`);
  await ref.set(
    {
      dismissed: null
    },
    { merge: true }
  );
};
