// @flow

import type { StackProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";

export const PriorityInfo: StackProps = {
  padding: "0.25rem",
  _hover: {
    backgroundColor: colors.grey32
  },
  _focus: {
    backgroundColor: colors.grey32
  },
  borderRadius: "0.25rem"
};
