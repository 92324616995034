// @flow

import React, { Component } from "react";
import * as R from "ramda";

import Result from "./result";
import Selection from "./SelectionMultiple";
import selector from "src/components/selector";
import { SelectMultiple as StyledSelectMultiple } from "src/styles/dropdown";
import { SmallText as SearchInput } from "src/styles/input";

type Props = {
  focus: number,
  dropdown: boolean,
  value: Array<number>,
  result: Array<number>,
  autoFocus?: boolean,
  placeholder?: string,
  handleChange: Function,
  handleDropdown: Function,
  handleClose: Function,
  setFocus: Function,
  handleSearch: Function,
  resetSearch: Function
};

type State = {
  search: string
};

class SelectMultiple extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false,
    placeholder: "Search Process"
  };

  state = {
    search: ""
  };

  handleSelect = (item: Object) => {
    const { handleChange, setFocus, resetSearch } = this.props;
    this.setState({ search: "" });
    setFocus(0);
    handleChange(item);
    if (this.searchRef) {
      this.searchRef.focus();
      resetSearch();
    }
  };

  handleDropdown = () => {
    const { handleDropdown, resetSearch } = this.props;
    if (this.searchRef) {
      this.searchRef.focus();
    }
    resetSearch();
    handleDropdown();
  };

  handleSearch = (e: any) => {
    const { value, id } = e.target;
    const { dropdown, handleDropdown, handleSearch, resetSearch } = this.props;
    this.setState({ [id]: value });
    if (!dropdown) {
      handleDropdown();
      resetSearch();
    }
    handleSearch(value);
  };

  removeHandler = (e: any, item: number) => {
    e.stopPropagation();
    this.handleSelect({ id: item });
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  onKeyDown = (e: any) => {
    if (e) {
      const { result, value } = this.props;
      const { search } = this.state;

      // Backspace
      if (e.keyCode === 8 && R.isEmpty(search)) {
        const lastValue = R.last(value);
        if (lastValue) {
          this.removeHandler(e, lastValue);
        }
      }

      // Detect ESC
      if (e.keyCode === 27) {
        const { dropdown, handleClose } = this.props;
        if (dropdown) {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }
      }

      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus } = this.props;

        e.preventDefault();
        e.stopPropagation();

        const filteredResult = R.reject(r => R.includes(r, value), result);

        if (filteredResult[focus]) {
          e.preventDefault();
          this.handleSelect(filteredResult[focus]);
        }
      }
    }
  };

  render() {
    const {
      dropdown,
      focus,
      value,
      placeholder,
      autoFocus,
      result,
      setFocus,
      handleClose
    } = this.props;
    const { search } = this.state;
    const filteredResult = R.reject(r => R.includes(r, value), result);
    return (
      <StyledSelectMultiple
        ref={node => {
          this.outerRef = node;
        }}
        onClick={this.handleDropdown}
      >
        <div>
          <Selection value={value} removeHandler={this.removeHandler}>
            <SearchInput
              type="text"
              id="search"
              value={search}
              ref={node => {
                this.searchRef = node;
              }}
              placeholder={placeholder}
              onChange={this.handleSearch}
              autoComplete="off"
              onKeyDown={this.onKeyDown}
              autoFocus={autoFocus}
            />
          </Selection>
        </div>
        <div>
          {!dropdown ? null : (
            <Result
              text={search}
              focus={focus}
              result={filteredResult}
              outerRef={this.outerRef}
              setFocus={setFocus}
              handleSelect={this.handleSelect}
              handleClose={handleClose}
            />
          )}
        </div>
      </StyledSelectMultiple>
    );
  }
}

export default selector(SelectMultiple);
