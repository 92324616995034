// @flow
import React, { useEffect, useCallback, useMemo, useReducer } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton
} from "@chakra-ui/react";

import { importJSONValidation } from "src/api/workflow";
import { dataStages } from "src/constants";
import {
  handleJSONImport,
  fetchJSONImportCollisions as fetchProcessCollisions
} from "src/utils/workflow";

import ProcessSelectPanel from "./ProcessSelectPanel";
import CollisionPanel from "./CollisionPanel";
import ErrorPanel from "./ErrorPanel";
import ImportPanel from "./ImportPanel";
import ImportSummaryPanel from "./ImportSummaryPanel";
import {
  importReducer,
  importActions as A,
  defaultImportState,
  ImportStateContext
} from "./importReducer";

type Props = {
  isOpen: boolean,
  onClose: () => void,
  fileName: string
};

const getPanel = (panel: number) => {
  switch (panel) {
    case 1:
      return ProcessSelectPanel;
    case 2:
      return CollisionPanel;
    case 3:
      return ImportPanel;
    case 4:
      return ImportSummaryPanel;
    default:
      return ErrorPanel;
  }
};

const ImportModal = ({ isOpen, onClose, fileName }: Props) => {
  const [state, dispatch] = useReducer(importReducer, defaultImportState);

  const CurrentPanel = useMemo(() => getPanel(state.panel), [state.panel]);

  useEffect(() => {
    importJSONValidation(fileName)
      .then(r => r.json())
      .then(data =>
        dispatch({ type: A.processes, payload: { processes: data } })
      )
      .catch(e => {
        dispatch({ type: A.status, payload: { status: dataStages.error } });
        console.error(e);
      });
  }, [fileName]);

  const handleProcessCollisions = useCallback(async () => {
    dispatch({ type: A.status, payload: { status: dataStages.fetching } });
    const data = await fetchProcessCollisions(fileName);
    const allCollisions = data ? data.collisions : null;
    let filteredCollisions = [];
    if (allCollisions) {
      filteredCollisions = allCollisions.filter(c => {
        let collision = state.processes.find(p => p.id === c.id);
        return collision && !collision?.exclude;
      });
      dispatch({
        type: A.collisions,
        payload: { collisions: filteredCollisions }
      });
    }
    dispatch({ type: A.status, payload: { status: dataStages.fetched } });
    if (filteredCollisions.length) {
      dispatch({ type: A.panel, payload: { panel: 2 } });
    } else {
      dispatch({
        type: A.importProcesses,
        payload: { importProcesses: state.processes }
      });
      dispatch({ type: A.panel, payload: { panel: 3 } });
    }
  }, [fileName, state.processes]);

  const handleMergeCollisions = useCallback(() => {
    const filteredProcesses = state.processes.map(p => {
      let collision = state.collisions.find(c => p.id === c.id);
      if (collision && !collision?.copy) {
        collision.exclude = true; // Exclude all non-copy collisions
      }
      return collision ?? p;
    });
    dispatch({
      type: A.importProcesses,
      payload: { importProcesses: filteredProcesses }
    });
    dispatch({ type: A.panel, payload: { panel: 3 } });
  }, [state.processes, state.collisions]);

  const panelBackHandler = useCallback(() => {
    if (state.panel === 3 && !state.collisions.length) {
      // skip the collisions panel (2) if there are no collisions
      dispatch({ type: A.panel, payload: { panel: 1 } });
    } else {
      dispatch({
        type: A.panel,
        payload: { panel: Math.min(Math.abs(state.panel - 1), 4) }
      });
    }
  }, [state.panel, state.collisions]);

  const handleImport = useCallback(async () => {
    dispatch({ type: "STATUS", payload: { status: dataStages.fetching } });
    try {
      const importResult = await handleJSONImport(
        fileName,
        state.importProcesses
      );
      dispatch({ type: "STATUS", payload: { status: dataStages.fetched } });
      if (importResult) {
        dispatch({
          type: "IMPORT_RESULT",
          payload: { importResult: importResult }
        });
        dispatch({ type: "PANEL", payload: { panel: 4 } });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: "STATUS", payload: { status: dataStages.error } });
    }
  }, [fileName, state.importProcesses]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent sx={{ height: "30rem", maxWidth: "38rem" }}>
        <ModalHeader sx={{ backgroundColor: "#F2F4F7" }}>
          Import process configurations
        </ModalHeader>
        <ModalCloseButton />
        <ImportStateContext.Provider value={state}>
          <CurrentPanel
            dispatch={dispatch}
            handleNextProcessSelect={handleProcessCollisions}
            handleNextCollision={handleMergeCollisions}
            handleBack={panelBackHandler}
            handleImport={handleImport}
          />
        </ImportStateContext.Provider>
      </ModalContent>
    </Modal>
  );
};

export default ImportModal;
