// @flow

import * as R from "ramda";
import { createSelector } from "reselect";

import { getAllGroupsById } from "src/reducers/groups";

import * as atypes from "src/constants/actionTypes";
import type { Action, AppState } from "src/types";

const initialState = [];

const roomMembers = (
  state: Array<string> = initialState,
  { type, payload }: Action
): Array<string> => {
  switch (type) {
    case atypes.CHANGE_CHATROOM_ATTRIBUTE:
      if (payload.value.owner) {
        return R.uniq([...state, payload.value.owner]);
      }
      return state;
    case atypes.ADD_ROOM_MEMBER_SUCCESS:
      return R.uniq([...state, ...R.keys(payload)]);
    case atypes.ADD_ROOM_MEMBER_REQUEST_FAILURE:
      return payload.request
        ? R.reject(R.equals(payload.member), state)
        : state;
    case atypes.ROOM_MEMBER_SYNC_SUCCESS:
      return payload.members;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.OPEN_CONVERSATION_MODAL:
    case atypes.CLOSE_CONVERSATION_MODAL:
      return initialState;
    case atypes.SET_ROOM_PARTICIPANTS:
      return [...(payload.members || [])];
    default:
      return state;
  }
};

export default roomMembers;

export const getRoomMembers = (state: AppState) => state.roomMembers;
const getRoomGroups = (state: AppState) => state.roomGroups;

const getAllUsers = (state: AppState) => R.values(state.users.byId);
const getAllGroups = (state: AppState) => R.values(state.groups.byId);

// Users which are part of the chatroom
const getAllRoomMembersDetails = createSelector(
  [getRoomMembers, getAllUsers],
  (roomMembers, users) => {
    return users
      .filter(user => roomMembers.includes(user.uid))
      .map(user => ({ type: "user", id: user.uid, name: user.displayName }));
  }
);

// Groups which are part of the chatroom
const getAllRoomGroupsDetails = createSelector(
  [getRoomGroups, getAllGroups],
  (roomGroups, groups) => {
    return groups
      .filter(group => roomGroups.includes(Number(group.id)))
      .map(group => ({
        type: "group",
        id: Number(group.id),
        name: group.title
      }));
  }
);

const getAllRoomMembersAndGroups = createSelector(
  [getAllRoomMembersDetails, getAllRoomGroupsDetails],
  (roomMembers, roomGroups) => [...roomMembers, ...roomGroups]
);

// Users which are not part of the chatroom
const getFilteredRoomMembers = createSelector(
  getRoomMembers,
  getAllUsers,
  (roomMembers, users) => {
    return users
      .filter(
        user =>
          user.disabled !== true &&
          user.orgRole !== "contact" &&
          !roomMembers.includes(user.uid)
      )
      .map(user => ({
        type: "user",
        id: user.uid,
        name: user.displayName,
        email: user.email
      }));
  }
);

// Groups which are not part of the chatroom
const getFilteredRoomGroups = createSelector(
  getRoomGroups,
  getAllGroups,
  (roomGroups, groups) => {
    return groups
      .filter(group => !roomGroups.includes(Number(group.id)))
      .map(group => ({
        type: "group",
        id: Number(group.id),
        name: group.title
      }));
  }
);

const getFilteredRoomMembersAndGroups = createSelector(
  [getFilteredRoomMembers, getFilteredRoomGroups],
  (members, groups) => R.concat(members, groups)
);

const firstLetterUpperCase = str => str[0].toUpperCase() + str.slice(1);

// Sorted Room Members And Room Groups by name
export const getSortedRoomMembersAndGroups = createSelector(
  getAllRoomMembersAndGroups,
  roomMembersAndGroups => {
    return [...roomMembersAndGroups].sort((a, b) => {
      const aName = firstLetterUpperCase(a.name);
      const bName = firstLetterUpperCase(b.name);

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
);

export const getRoomParticipantCount = createSelector(
  [getAllRoomMembersAndGroups, state => getAllGroupsById(state.groups)],
  (participants, groupsById) => {
    const roomParticipants = [];
    participants.map(participant => {
      if (typeof participant.id === "number") {
        roomParticipants.push(...(groupsById[participant.id].members ?? []));
      } else {
        roomParticipants.push(participant.id);
      }
    });
    return R.uniq(roomParticipants).length;
  }
);

// Filtered Sorted Room Members And Room Groups by name
const getSortedMembersAndGroups = createSelector(
  getFilteredRoomMembersAndGroups,
  roomMembersAndGroups => {
    return [...roomMembersAndGroups].sort((a, b) => {
      const aName = firstLetterUpperCase(a.name);
      const bName = firstLetterUpperCase(b.name);

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
);

export const getRoomSearchedFilteredResults = createSelector(
  [getSortedMembersAndGroups, (_, searchQuery) => searchQuery],
  (roomMembersAndGroups, query) => {
    if (query === "") {
      return roomMembersAndGroups;
    }
    return roomMembersAndGroups.filter(({ name, email, type }) => {
      return type === "user"
        ? name.toLowerCase().includes(query.toLowerCase()) ||
            email.toLowerCase().includes(query.toLowerCase())
        : name.toLowerCase().includes(query.toLowerCase());
    });
  }
);
