// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  Selector as StyledSelector,
  StyledCheckboxWrapper
} from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import Checkbox from "src/components/Checkbox";
import { toggleFormRowSelection } from "src/actions/forms";
import { isFormRowSelected } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  formId: number,
  selected: boolean,
  _toggleFormRowSelection: Function
};

const Selector = ({ formId, selected, _toggleFormRowSelection }: Props) => {
  const handleSelect = useCallback(() => {
    _toggleFormRowSelection(formId);
  }, [formId, _toggleFormRowSelection]);

  return (
    <StyledSelector selected={selected} onClick={handleSelect}>
      <StyledCheckboxWrapper>
        <Checkbox
          id={`select${formId}`}
          checked={selected}
          handleChange={handleSelect}
        />
      </StyledCheckboxWrapper>
    </StyledSelector>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  selected: isFormRowSelected(app, props.formId)
});

export default connect(mapStateToProps, {
  _toggleFormRowSelection: toggleFormRowSelection
})(Selector);
