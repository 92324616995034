// @flow

import { connect } from "react-redux";
import React from "react";

import Templates from "./Templates";
import Instances from "./Instances";
import { openFormModal, closeFormModal } from "src/actions/forms";
import { getInstanceFilterId } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  id: ?number,
  _openFormModal: Function,
  _closeFormModal: Function,
  formModal: boolean
};

const Forms = ({ id, _openFormModal, _closeFormModal, formModal }: Props) => {
  if (id) {
    return <Instances id={id} />;
  }

  return (
    <Templates
      openFormModal={_openFormModal}
      closeFormModal={_closeFormModal}
      formModal={formModal}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    id: getInstanceFilterId(app),
    formModal: app.form.formModal
  };
};

export default connect(mapStateToProps, {
  _openFormModal: openFormModal,
  _closeFormModal: closeFormModal
})(Forms);
