// @flow

import { connect, useSelector } from "react-redux";
import React, { useCallback } from "react";
import Link from "redux-first-router-link";
import { useInView } from "react-intersection-observer";
import { EditIconWrapper } from "../styles";

import { Title as StyledTitle, ExpandButton, TitleName } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import Edit from "./Edit";
import LoadMore from "./LoadMore";
import { NoTitle } from "src/components/chatroom/Name/styles";
import useBoolean from "src/hooks/useBoolean";
import useInput from "src/hooks/useInput";
import * as atypes from "src/constants/actionTypes";
import { openConversationModal, fetchConversation } from "src/actions/chatroom";
import {
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import { getCanceled, getTemplateId, getFieldWidth } from "src/reducers";
import Icon from "src/icons";
import { size } from "src/constants/processInstanceColumns";

import type { AppState } from "src/types";

type Props = {
  id: number,
  index: number,
  selected: boolean,
  childCount: number,
  title: string,
  address: string,
  last: boolean,
  expand: boolean,
  depth: number,
  seqNo: number,
  autoNo: string,
  processTitle: string,
  canceled: ?boolean,
  parentId: ?number,
  templateId: ?number,
  toggleExpand: Function,
  _openConversationModal: Function,
  _updateChatroomAttributes: Function,
  _fetchConversation: Function,
  _updateNestedRow: Function
};

const Title = ({
  id,
  index,
  selected,
  childCount,
  title,
  templateId,
  canceled,
  last,
  address,
  depth,
  seqNo,
  autoNo,
  parentId,
  processTitle,
  toggleExpand,
  _openConversationModal,
  _fetchConversation,
  _updateChatroomAttributes,
  _updateNestedRow
}: Props) => {
  const { value: edit, setTrue, setFalse: handleClose } = useBoolean();
  const { value: rotateIcon, toggleBoolean: setRotateIcon } = useBoolean();
  const { value, setValue, handleChange } = useInput(title);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, "title"));

  const handleConversation = useCallback(() => {
    _fetchConversation(id);
    _openConversationModal(id);
    return false;
  }, [id, _openConversationModal, _fetchConversation]);

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        handleClose();
        setValue("");
      }
    },
    [handleClose, setValue]
  );

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      handleClose();
      setValue("");
      if (depth > 0) {
        _updateNestedRow(id, { title: value }, index, parentId);
      } else {
        _updateChatroomAttributes(id, { title: value }, index);
      }
    },
    [
      id,
      value,
      parentId,
      depth,
      handleClose,
      _updateChatroomAttributes,
      _updateNestedRow
    ]
  );

  const handleEdit = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setTrue();
      setValue(title);
    },
    [title, setTrue]
  );

  const handleChildRowExpansion = () => {
    setRotateIcon(prev => !prev);
    toggleExpand();
  };

  const fullTitle = `${processTitle || ""} ${
    autoNo ? `#${autoNo}` : seqNo ? `#${seqNo}` : ""
  }${title ? `: ${title}` : ""}`;

  return (
    <StyledTitle
      width={fieldWidth}
      minWidth={size.title}
      depth={depth}
      selected={selected}
      ref={ref}
    >
      <ValueWrapper>
        {childCount > 0 && (
          <ExpandButton
            depth={depth}
            selected={selected}
            type="button"
            onClick={handleChildRowExpansion}
          >
            <Icon type={rotateIcon ? "caretDown" : "caretRight"} />
          </ExpandButton>
        )}

        <TitleName canceled={canceled}>
          {edit ? (
            <Edit
              handleKeyPress={handleKeyPress}
              value={value}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
          ) : (
            <Link
              onClick={handleConversation}
              to={{
                type: atypes.SET_CURRENT_CHATROOM_REQUEST,
                payload: {
                  id: address
                },
                meta: {
                  query: {
                    templateId
                  }
                }
              }}
              title={title ?? ""}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              {title ? (
                <span data-cy="currentChatroomTitle">
                  {depth > 0 ? fullTitle : title}
                </span>
              ) : (
                <NoTitle>Untitled Conversation</NoTitle>
              )}
              <EditIconWrapper depth={depth} onClick={handleEdit}>
                <Icon type="edit" />
              </EditIconWrapper>
            </Link>
          )}
        </TitleName>
        {last && <LoadMore isVisible={inView} />}
      </ValueWrapper>
    </StyledTitle>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  canceled: getCanceled(app, props.id),
  templateId: getTemplateId(app, props.id)
});

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal,
  _updateChatroomAttributes: updateChatroomFromManageView,
  _updateNestedRow: updateNestedRow,
  _fetchConversation: fetchConversation
})(Title);
