// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import ReactModal from "react-modal";

import { getFileViewer } from "src/reducers";
import ImageViewer from "src/components/file/ImageViewer";
import Close from "src/components/Close";
import { closeViewer } from "src/actions/file";

// $FlowFixMe
import PDFMarkup from "src/pdf-viewer";

import { ModalStickyHeader } from "src/styles";
import { Modal } from "src/styles/modal.old";

import type { AppState, FileViewer } from "src/types";

type Props = {
  viewer: FileViewer,
  _closeViewer: Function
};

const Viewer = ({ viewer, _closeViewer }: Props) => {
  const handleCloseViewer = useCallback(() => {
    _closeViewer();
  }, [_closeViewer]);

  return (
    <Modal id="file-viewer">
      <ReactModal
        isOpen={
          viewer.isOpen && (viewer.type === "pdf" || viewer.type === "image")
        }
        onRequestClose={handleCloseViewer}
        parentSelector={() => document.querySelector("#file-viewer")}
      >
        <ModalStickyHeader
          style={viewer.type === "image" ? { background: "transparent" } : null}
        >
          <Close handleClose={handleCloseViewer} />
        </ModalStickyHeader>
        {viewer.type === "image" ? <ImageViewer url={viewer.url} /> : null}
        {viewer.type === "pdf" ? <PDFMarkup url={viewer.url} /> : null}
      </ReactModal>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  viewer: getFileViewer(app)
});

export default connect(mapStateToProps, {
  _closeViewer: closeViewer
})(Viewer);
