// @flow

import React from "react";

const NewFolder = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M13.3334 4.00008H8.00004L6.66671 2.66675H2.66671C1.92671 2.66675 1.34004 3.26008 1.34004 4.00008L1.33337 12.0001C1.33337 12.7401 1.92671 13.3334 2.66671 13.3334H13.3334C14.0734 13.3334 14.6667 12.7401 14.6667 12.0001V5.33341C14.6667 4.59341 14.0734 4.00008 13.3334 4.00008ZM13.3334 12.0001H2.66671V4.00008H6.11337L7.44671 5.33341H13.3334V12.0001ZM8.00004 9.33341H9.33337V10.6667H10.6667V9.33341H12V8.00008H10.6667V6.66675H9.33337V8.00008H8.00004V9.33341Z"
      fill="white"
    />{" "}
  </svg>
);

export default NewFolder;
