// @flow

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HStack, VStack, Button, Box } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import * as fileApi from "src/api/file";
import {
  addFileSuccess,
  getFile as resolveFile,
  deleteFile,
  downloadFile
} from "src/actions/file";
import { getFile, getCurrentRoomId } from "src/reducers";

import Icon from "src/icons";
import ProgressBar from "src/components/Dock/Checklist/File/ProgressBar";
import * as styles from "./styles";

type Props = {
  value: string | null,
  onUpdate: Function
};

export default function Template({ value, onUpdate }: Props) {
  const dispatch = useDispatch();

  const file = useSelector(({ app }) => (value ? getFile(app, value) : null));
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));

  const fileRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (value) {
      dispatch(resolveFile(value));
    }
  }, [value]);

  const handleFileUploadProgressChange = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  const uploadFile = async e => {
    const fileData = e.target.files[0];

    if (!fileData) {
      return;
    }

    onUpdate(null);
    setUploading(true);

    try {
      const {
        name,
        url: signedURL,
        contentType
      } = await fileApi.getSignedURLForUpload(fileData.name);

      const response = await fileApi.uploadFileToAWS({
        signedURL,
        fileData,
        name,
        contentType,
        handleProgress: handleFileUploadProgressChange
      });

      const versionId =
        response.headers["x-ms-version-id"] ??
        response.headers["x-amz-version-id"];
      const fileObj = {
        name,
        versionId,
        size: fileData.size,
        originalName: fileData.name,
        folderId: null,
        mimeType: fileData.type
      };

      await fileApi.createFile(fileObj);

      dispatch(addFileSuccess(fileObj));

      onUpdate(fileObj.name);
    } finally {
      setUploading(false);
    }
  };

  const handleBla = () => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  const removeFile = () => {
    if (value) dispatch(deleteFile(value));
    onUpdate(null);
  };

  const downloadFileHandler = () => {
    if (file) {
      dispatch(
        downloadFile({
          roomId,
          location: "checklist-builder-pdf-field-settings",
          name: file.name,
          originalName: file.originalName,
          generation: 1,
          versionId: file.versionId
        })
      );
    }
  };

  return (
    <VStack {...styles.TemplateContainer}>
      <HStack>
        <span style={styles.Label}>UPLOAD TEMPLATE</span>
        <Icon type="info" filled width="13" height="13" />
      </HStack>

      {value &&
        (file ? (
          <HStack {...styles.FileItem}>
            <span>{file.originalName}</span>
            <HStack>
              <Box
                {...styles.downloadIconContainer}
                onClick={downloadFileHandler}
              >
                <Icon type="download" {...styles.downloadIcon} />
              </Box>
              <CloseIcon onClick={removeFile} {...styles.removeIcon} />
            </HStack>
          </HStack>
        ) : (
          <span>fetching</span>
        ))}

      {uploading && <ProgressBar progress={uploadProgress} />}

      {!value && !uploading && (
        <Button width="100%" onClick={handleBla}>
          Upload Template
          <input
            type="file"
            id="dropdownFileUpload"
            ref={fileRef}
            onChange={uploadFile}
            style={{ display: "none" }}
          />
        </Button>
      )}
    </VStack>
  );
}
