// @flow

import { connect } from "react-redux";
import React from "react";

import { ButtonOutline } from "./styles";

import {
  List as StyledList,
  Item as StyledItem,
  ListContainer as StyledDasboards
} from "src/styles/splitPane";
import ButtonLoader from "src/components/ButtonLoader";
import { createDashboard, setDashboard } from "src/actions/dashboard";
import { getDashboardsSortedByName } from "src/reducers";
import User from "src/containers/user/NameOrEmail";

import type { AppState, Dashboard } from "src/types";

type Props = {
  dashboards: Array<Dashboard>,
  creating: boolean,
  current: ?number,
  _createDashboard: Function,
  _setDashboard: Function
};

const List = ({
  dashboards,
  creating,
  current,
  _createDashboard,
  _setDashboard
}: Props) => {
  return (
    <StyledList>
      <h2>Dashboard</h2>

      <StyledDasboards>
        {dashboards.map(dashboard => (
          <StyledItem
            key={dashboard.id}
            active={current === dashboard.id}
            onClick={() => _setDashboard(dashboard.id)}
          >
            <h4>{dashboard.title}</h4>
            {dashboard.creator && <User uid={dashboard.creator} />}
          </StyledItem>
        ))}
      </StyledDasboards>
      <ButtonOutline isLoading={creating}>
        <ButtonLoader
          isLoading={creating}
          handleClick={() => _createDashboard("Untitled Dashboard")}
          type="button"
        >
          &#43; Create New
        </ButtonLoader>
      </ButtonOutline>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  creating: app.dashboard.creating,
  current: app.dashboard.current,
  dashboards: getDashboardsSortedByName(app)
});

export default connect(mapStateToProps, {
  _createDashboard: createDashboard,
  _setDashboard: setDashboard
})(List);
