// @flow

import React from "react";

import { Additional, Option } from "./styles";
import { roles } from "src/constants/promptRules";

type Props = {
  setRole: Function
};

const AdditionalOptions = ({ setRole }: Props) => {
  return (
    <Additional>
      Roles
      {roles.map(role => (
        <Option key={role} onClick={() => setRole(role)}>
          {role}
        </Option>
      ))}
    </Additional>
  );
};

export default AdditionalOptions;
