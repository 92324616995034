// @flow

import styled from "@emotion/styled";
import { ListItemMedium } from "src/styles/box";
import * as colors from "src/styles/constants/colors";

export const DeleteButton = styled.button`
  outline: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  border: none;
  padding: 0;
  float: right;
  margin-right: 08px;
  > svg {
    height: 14px;
    width: 14px;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  visibility: hidden;
`;

export const ListItem = styled(ListItemMedium)`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  padding: 2px 8px;
  line-height: 30px;
  :hover,
  :focus {
    ${DeleteButton} {
      background: ${colors.grey2};
    }

    ${MenuBar} {
      visibility: visible;
    }
  }
`;

export const Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  padding: 2px 8px;
  font-size: 10px;
  color: ${colors.inkLighter};
`;
