// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { colors } from "src/styles/constants";

const DropdownMenu = styled.ul`
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  border: solid 1px rgba(205, 210, 217, 0.5);
  padding: 0.5em;
  width: 247px;
  list-style: none;
  margin: 0;
  margin-top: -11px;
  margin-left: -2px;
  z-index: 5;
  &>div: last-child {
    padding: 10px;
    font-size: 13px;
    cursor: pointer;
    color: ${colors.blue};
  }

  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  & > div {
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 7px;
  }
  li {
    display: flex;
    align-items: center;
    padding: 0.4em;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    input[type="checkbox"] + div {
      position: absolute;
      right: 5px;
      top: 8px;
    }

    > * {
      margin: 0 0.5em;
    }

    > :nth-of-type(1) {
      margin-left: 0;
    }

    &:hover {
      ${({ hover }) =>
        hover &&
        css`
          background-color: ${colors.grey};
        `};
    }

    > a {
      display: inline-flex;
      color: ${colors.black};

      > div {
        margin-right: 0.5em;
      }
    }
  }
`;

const DropdownMenuSmall = styled(DropdownMenu)`
  min-width: 100px;
  position: absolute;
  right: 21px;
  top: 16px;
`;

export default DropdownMenu;

export { DropdownMenuSmall };
