// @flow

import React, { useState } from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import * as R from "ramda";

import chartLoader from "src/assets/charts/chartLoader.svg";
import useInterval from "src/hooks/useInterval";
import * as styles from "./styles";

type Props = {
  textStyles?: Object,
  imgStyles?: Object
};

const Loader = ({ textStyles, imgStyles }: Props) => {
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count < 3) {
      setCount(count + 1);
    } else {
      setCount(1);
    }
  }, 300);

  return (
    <Box sx={{ position: "relative" }}>
      <Heading sx={{ ...styles.H6, ...textStyles }} as="h6">
        Loading Chart{R.repeat(".", count)}
      </Heading>
      <Image
        sx={{ ...styles.Image, ...imgStyles }}
        src={chartLoader}
        alt="loading charts..."
      />
    </Box>
  );
};

export default Loader;
