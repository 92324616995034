// @flow

import React, { useState } from "react";
import { WarningIcon } from "@chakra-ui/icons";

import AddButton from "src/components/Buttons/ChakraButton";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";
import { InteractiveToolTip } from "src/components/Unifize";
import CreatorsModal from "src/components/Dock/Checklist/Revision/CreatorsModal";

import {
  useBoolean,
  Text,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";

import type { RoomId, FieldId, ApprovalFieldSettingsV2 } from "src/types";
import type { HandleCreateRevision } from "./index";

type Props = {
  signatureCount: number,
  isCommentRequired: boolean,
  createRevision: HandleCreateRevision,
  disabled: boolean,
  updating: boolean,
  roomId: RoomId,
  fieldId: FieldId,
  settings: ApprovalFieldSettingsV2
};

function Revision({
  createRevision,
  disabled,
  updating,
  isCommentRequired,
  signatureCount,
  fieldId,
  roomId,
  settings
}: Props) {
  const [showCancellers, setShowCancellers] = useState(false);
  const [comment, setComment] = useState("");
  const [isModalOpen, setModalVisibility] = useBoolean();

  const label = signatureCount > 0 ? "Cancel Approval" : "Cancel Request";

  const handleCreateRevision = () => {
    if (comment) {
      createRevision(comment);
    } else {
      createRevision();
    }

    setModalVisibility.off();
  };

  return (
    <>
      {updating ? (
        <ButtonLoader />
      ) : (
        <InteractiveToolTip
          isShown={disabled}
          onClick={() => setShowCancellers(true)}
          message="You are not authorized. Click here to see details."
        >
          <AddButton
            onClick={setModalVisibility.on}
            isDisabled={disabled}
            variant="add"
            rootProps={{ width: "100%" }}
            rightIcon={disabled && <WarningIcon w={4} h={4} color="red.500" />}
          >
            {label}
          </AddButton>
        </InteractiveToolTip>
      )}

      {showCancellers && (
        <CreatorsModal
          title="Approval permissions"
          subtitle="Approval can only be cancelled by"
          emptySubtitle="The approval settings have not been configured."
          isOpen={showCancellers}
          onClose={() => setShowCancellers(false)}
          fieldId={fieldId}
          roomId={roomId}
          settings={settings}
          path="cancellers"
        />
      )}

      <Modal isOpen={isModalOpen} onClose={setModalVisibility.off} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader fontWeight={700} fontSize="lg">
            {label}
          </ModalHeader>

          <ModalBody>
            <Text my={0}>
              Are you sure you want to cancel approval? This will unlock the
              checklist fields, change the status and deprecate the previous
              approval.
            </Text>

            {isCommentRequired && (
              <>
                <br />
                <FormControl isRequired>
                  <FormLabel>Please enter a comment</FormLabel>
                  <Textarea
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder="Enter comment here"
                    resize="vertical"
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button variant="uSecondary" onClick={setModalVisibility.off}>
                No
              </Button>

              <Button
                variant="uDanger"
                onClick={handleCreateRevision}
                isDisabled={isCommentRequired && !comment}
              >
                Yes
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Revision;
