// @flow

import styled from "styled-components";

import { HeaderItem as StyledHeaderItem } from "src/components/Manage/Workflow/Instances/Table/styles";
import * as colors from "src/styles/constants/colors";
import { depthColor } from "src/components/Manage/Workflow/Instances/Table/Row/styles";

export const ChecklistItem = styled(StyledHeaderItem)`
  background-color: ${colors.grey26};
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columnSize};
  width: ${props => props.width};
  white-space: nowrap;
  z-index: 2;
  position: sticky;
  top: 0;
  font-size: 13px
  height: 42px;
`;

export const Row = styled.tr`
  display: grid;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.grey2};
  grid-template-columns: ${props => props.columnSize};
  white-space: nowrap;
  background: ${props => depthColor[props.depth]} !important;
  width: ${props => props.width};
  > div {
    background: ${props => depthColor[props.depth]} !important;
  }
`;
