// @flow

import * as React from "react";

type Props = {};

const FileArticle = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...restProps}
    >
      <g clipPath="url(#a)">
        <path
          d="M21.6 28.8c-3.6 3.6-9.225 3.6-12.825 0-3.6-3.6-3.825-9-.225-12.375L18.675 6.3c2.25-2.25 5.85-2.25 7.875 0 2.25 2.25 2.25 5.85 0 7.875l-7.875 8.1c-.9.9-2.25.9-3.15 0-.9-.9-.9-2.25 0-3.15l4.612-4.612a2.307 2.307 0 1 0-3.263-3.262l-4.499 4.499c-2.7 2.7-2.7 6.975 0 9.45 2.7 2.475 6.975 2.7 9.45 0l8.1-8.1c4.05-4.05 4.05-10.35 0-14.4-4.05-4.05-10.35-4.05-14.4 0L5.4 13.275C.225 18.45.45 26.775 5.625 31.95 8.325 34.65 11.7 36 15.3 36c3.6 0 6.975-1.35 9.45-4.05l8.1-8.1a2.227 2.227 0 0 0-3.15-3.15l-8.1 8.1Z"
          fill="#ECC94B"
          {...restProps}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" {...restProps} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileArticle;
