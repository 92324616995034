// @flow

import React, { useRef, useLayoutEffect } from "react";
import { findDOMNode } from "react-dom";
import {
  ListItem as StyledListItem,
  Text,
  Flex,
  Grid,
  Box,
  HStack
} from "@chakra-ui/react";
import FieldName from "src/containers/checklist/FieldName";
import { ListItemText, ListItemBox } from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

type Props = {
  column: Object,
  hidden: boolean,
  selected: boolean,
  focused: boolean,
  handleField: Function,
  isForm: ?boolean
};

const ListItem = ({
  column,
  selected,
  hidden,
  focused,
  handleField,
  isForm
}: Props) => {
  const itemRef = useRef(null);

  useLayoutEffect(() => {
    if (focused && itemRef.current) {
      // Move element into view when it is focused
      // eslint-disable-next-line react/no-find-dom-node
      const target = findDOMNode(itemRef.current);
      // $FlowFixMe
      target.parentNode.scrollTop = target.offsetTop - 5;
    }
  }, [focused, itemRef]);

  if (hidden) {
    return <></>;
  }

  let background = focused ? "gray.200" : "white";

  if (selected) {
    // Disabled selection color due to possible duplicate id
    // background = "blue.500";
  }

  return (
    <StyledListItem
      _hover={{
        backgroundColor: selected ? "blue.500" : "gray.100",
        color: selected ? "white" : "black"
      }}
      role="option"
      onClick={() => handleField(column)}
      fontSize="sm"
      cursor="pointer"
      color={selected ? "white" : "black"}
      background={background}
      p={[4, 1]}
      tabIndex={focused ? 0 : -1}
      ref={itemRef}
    >
      <Grid gridTemplateColumns={"2fr 1fr 1fr"} sx={ListItemBox}>
        <Text noOfLines={1} sx={ListItemText}>
          {column.name}
        </Text>

        {column.multiValue ? (
          <Text m={0} color={selected ? "white" : "unifize_inkLightest"}>
            Multiple Values
          </Text>
        ) : (
          <div />
        )}

        {isForm ? (
          <Flex>
            {column.linkedOrFormFieldId > 0 && (
              <Text
                sx={ListItemText}
                color={selected ? "white" : "unifize_inkLightest"}
              >
                <HStack>
                  <Box mt={1}>
                    <Icon
                      type="commonChecklist"
                      stroke={
                        selected ? colors.brightLight : colors.inkLightest
                      }
                    />
                  </Box>
                  <Text noOfLines={1}>
                    <FieldName id={column.linkedOrFormFieldId} />
                  </Text>
                </HStack>
              </Text>
            )}
          </Flex>
        ) : (
          column.linkedOrFormFieldId > 0 && (
            <Text
              sx={ListItemText}
              color={selected ? "white" : "unifize_inkLightest"}
            >
              <HStack>
                <Box>
                  <Icon
                    type={
                      column.linkedProcessOrFormType === "form"
                        ? "layout"
                        : "anchor"
                    }
                    fill={selected ? colors.brightLight : colors.inkLightest}
                    height="12px"
                    width="12px"
                  />
                </Box>
                <Text noOfLines={1}>
                  <FieldName id={column.linkedOrFormFieldId} />
                </Text>
              </HStack>
            </Text>
          )
        )}
      </Grid>
    </StyledListItem>
  );
};

export default ListItem;
