// @flow

import styled from "@emotion/styled";
import type { InputProps, StackProps, TextProps } from "@chakra-ui/react";

import { InputBox } from "src/styles/box";
import { SmallText } from "src/styles/input";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import * as colors from "src/styles/constants/colors";

export const Title = styled(InputBox)``;

export const TemplateTitle = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${spacing.space_inset_squish_s};
  padding-right: 0px;
  > span:first-of-type {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 40px;
    padding-right: ${spacing.space_s};
  }
`;

export const Input = styled(SmallText)`
  flex: 1;
`;

export const InputField: InputProps = {
  border: "none",
  my: spacing.space_m
};

export const SeqNo: TextProps = {
  my: spacing.space_m,
  color: "#000000",
  fontSize: "14px"
};

export const InputContainer: StackProps = {
  borderRadius: radius.medium,
  border: `1px solid ${colors.grey4}`,
  color: colors.blackAlpha700,
  cursor: "pointer",
  px: spacing.space_m
};

export const ExternalInviteInfo: TextProps = {
  color: colors.blackAlpha500,
  fontSize: "12px"
};
