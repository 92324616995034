// @flow

import { connect } from "react-redux";

import Loader from "./Loader";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  loading: app.workflow.loadingInstances
});

export default connect(mapStateToProps)(Loader);
