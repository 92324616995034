// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button
} from "@chakra-ui/react";

type Props = {
  onClose: Function,
  isOpen: boolean,
  title: string,
  onDisconnect: Function
};

const DisconnectModal = ({ onClose, isOpen, title, onDisconnect }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={4}>
          Are you sure you want to disconnect the {title} integration? You could
          lose data that is accessed or hosted through {title}.
          <br />
          <br />
          You cannot undo this action. Consider disabling the integration
          instead.
        </ModalBody>
        <ModalFooter>
          <Button mr={4} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              onDisconnect();
              onClose();
            }}
          >
            Disconnect
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DisconnectModal;
