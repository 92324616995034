// @flow

import React from "react";

type Props = {
  width?: number,
  height?: number
};

const ExtraFile = ({ width = 32, height = 38 }: Props) => {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 18 22"
      fill="#1581BA"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1H12.049V6.07148H17V21H1V1Z"
        fill="#1581BA"
      />
      <path d="M11.6665 1V6.33333H16.9998" stroke="white" strokeWidth="1.34" />
      <path
        d="M17 9V6.33333L11.6667 1H1V9"
        stroke="#1581BA"
        strokeWidth="1.34"
        strokeLinecap="square"
      />
      <path
        d="M1 8.33325V20.9999H17V8.33325"
        stroke="#1581BA"
        strokeWidth="1.34"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ExtraFile;
