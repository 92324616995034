// @flow

import styled from "styled-components";

import { ListItem } from "../styles";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const DateItem = styled(ListItem)`
  text-transform: capitalize;
  font-size: 12px;
  justify-content: space-between;
  padding-right: 0;
  :hover {
    background-color: ${colors.brightLight};
  }
`;

export const Selection = styled.div`
  /* position: relative; */
  padding-left: 4px;
  > div:first-of-type {
    display: inline-flex;
    flex-direction: row;
    background-color: ${colors.brightLight};
    border: 1px solid ${colors.grey7};
    padding: ${spacing.space_inset_squish_xs};
    justify-content: space-between;
  }
  > div:last-child {
    right: -258px;
    top: -1px;
  }
`;

export const Value = styled.div`
  padding: ${spacing.space_s} ${spacing.space_s};
  width: 6.7em;
`;

export const Calendar = styled.div`
  border-left: 1px solid ${colors.grey7};
  padding: 0px ${spacing.space_s};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 0.8em;
    width: 0.8em;
  }
`;
