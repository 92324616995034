// @flow

import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { HStack, Text, Portal } from "@chakra-ui/react";
import RelatedModal from "src/containers/chatroom/Related/RelatedModal";
import useBoolean from "src/hooks/useBoolean";
import { getTotalRelatedCount } from "src/reducers";
import Icon from "src/icons";

import type { RoomId } from "src/types";
import * as styles from "./styles";
type Props = {
  roomId: RoomId,
  portalRef: null | React$ElementRef<Portal>,
  closeMoreMenu?: () => void
};

const RelatedConverations = ({ roomId, portalRef, closeMoreMenu }: Props) => {
  const outerRef = useRef(null);
  const relatedConverationsCount = useSelector(({ app }) =>
    getTotalRelatedCount(app, roomId)
  );
  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean();

  return (
    <>
      {relatedConverationsCount > 0 && (
        <HStack
          onClick={() => {
            openModal();
            closeMoreMenu && closeMoreMenu();
          }}
          sx={styles.MenuItem}
          tabIndex={0}
          onKeyPress={e => {
            e.stopPropagation();
            if (e.key === "Enter") {
              openModal();
              closeMoreMenu && closeMoreMenu();
            }
          }}
        >
          <Icon type="relatedIconV2" />
          <Text>Related Conversations ({relatedConverationsCount})</Text>
        </HStack>
      )}

      {isOpen && (
        <RelatedModal
          showRelatedModal={isOpen}
          outerRef={outerRef}
          toggleRelatedModal={closeModal}
          roomId={roomId}
          portalRef={portalRef}
        />
      )}
    </>
  );
};

export default RelatedConverations;
