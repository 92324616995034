// @flow

import React from "react";
import { connect } from "react-redux";

import NavBar from "./filter/NavBar/Index";

import Overlay from "./Overlay";
import Body from "src/components/Body";
import SRW from "src/components/SRW";
import { HomeContainer } from "src/styles";
import type { Location } from "src/types";

type Props = {
  isLite: boolean
};
const Home = ({ isLite = false }: Props) => {
  if (isLite) return <SRW />;
  return (
    <React.Fragment>
      <Overlay />
      <HomeContainer>
        <NavBar />
        <Body />
      </HomeContainer>
    </React.Fragment>
  );
};

const mapStateToProps = ({ location }: { location: Location }) => ({
  isLite: location.query?.authCode ? true : false
});

export default connect(mapStateToProps)(Home);
