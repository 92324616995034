// @flow

import { connect } from "react-redux";
import React from "react";

import { MenuItem, Value } from "./styles";
import defaultStatus, * as status from "src/constants/status";
import { getChatroomStatus } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  value: number,
  handleStatus: Function
};

const Approval = ({ value, handleStatus }: Props) => {
  const pending = defaultStatus[status.PENDING];
  const accepted = defaultStatus[status.ACCEPTED];
  const rejected = defaultStatus[status.REJECTED];

  return (
    <>
      <MenuItem
        tabIndex="0"
        role="button"
        onClick={() => handleStatus(status.PENDING)}
        onKeyPress={() => handleStatus(status.PENDING)}
      >
        <Value color={pending.color} textColor={pending.textColor}>
          {pending.text}
        </Value>
        <span
          dangerouslySetInnerHTML={{
            __html: `${value === status.PENDING ? "&#10004;" : ""}`
          }}
        />
      </MenuItem>
      <MenuItem
        tabIndex="0"
        role="button"
        onClick={() => handleStatus(status.ACCEPTED)}
        onKeyPress={() => handleStatus(status.ACCEPTED)}
      >
        <Value color={accepted.color} textColor={accepted.textColor}>
          {accepted.text}
        </Value>
        <span
          dangerouslySetInnerHTML={{
            __html: `${value === status.ACCEPTED ? "&#10004;" : ""}`
          }}
        />
      </MenuItem>
      <MenuItem
        tabIndex="0"
        role="button"
        onClick={() => handleStatus(status.REJECTED)}
        onKeyPress={() => handleStatus(status.REJECTED)}
      >
        <Value color={rejected.color} textColor={rejected.textColor}>
          {rejected.text}
        </Value>
        <span
          dangerouslySetInnerHTML={{
            __html: `${value === status.REJECTED ? "&#10004;" : ""}`
          }}
        />
      </MenuItem>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomStatus(app, props.roomId)
});

export default connect(mapStateToProps)(Approval);
