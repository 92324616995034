// @flow
import React, { useCallback, useContext } from "react";

import { ModalBody, Spinner } from "@chakra-ui/react";

import type { ImportedProcess, ImportModalAction } from "src/types";
import { dataStages } from "src/constants";
import Checkbox from "src/components/Unifize/Checkbox";

import { importActions as A, ImportStateContext } from "./importReducer";
import ModalButton from "../../ModalButton";
import {
  ImportedTemplate as StyledTemplate,
  ImportedTemplates as Templates,
  Loader,
  modalBody,
  PanelTitle
} from "./styles";

type Props = {
  handleNextProcessSelect: ?() => void | Promise<void>,
  dispatch: ImportModalAction => void
};

const ProcessSelectPanel = ({ handleNextProcessSelect, dispatch }: Props) => {
  const { processes, status } = useContext(ImportStateContext);
  const handleChange = useCallback(
    (e, process: ImportedProcess, index: number) => {
      dispatch({
        type: A.processes,
        payload: {
          processes: [
            ...processes.slice(0, index),
            { ...process, exclude: !e },
            ...processes.slice(index + 1)
          ]
        }
      });
    },
    [processes]
  );
  return (
    <ModalBody sx={modalBody}>
      <PanelTitle>
        The following processes are included in the import.
      </PanelTitle>
      <Templates>
        {processes?.length ? (
          processes.map((process, index) => (
            <StyledTemplate key={`${process.id}_${index}`}>
              <Checkbox
                id={`selectImportProcess${process.id}_${index}`}
                checked={!process.exclude}
                onChange={e => handleChange(e, process, index)}
              />
              {process.title}
            </StyledTemplate>
          ))
        ) : (
          <Loader>
            <Spinner color="blue.500" size="xl" />
          </Loader>
        )}
      </Templates>
      <ModalButton
        label="Next"
        onClick={handleNextProcessSelect}
        loading={status === dataStages.fetching}
        disabled={processes?.length <= 0 || status === dataStages.fetching}
      />
    </ModalBody>
  );
};

export default ProcessSelectPanel;
