// @flow

import { useCallback } from "react";

const useKeyDownHandlers = (callback: Function) => {
  // Shift + Enter
  const handleShiftEnter = useCallback(
    (e: any) => {
      if (callback && e.shiftKey && e.keyCode === 13) callback();
    },
    [callback]
  );

  // Enter
  const handleEnter = useCallback(
    (e: any) => {
      if (callback && e.keyCode === 13) callback();
    },
    [callback]
  );

  // Backspace
  const handleBackSpace = useCallback(
    (e: any) => {
      if (callback && e.keyCode === 8) callback();
    },
    [callback]
  );

  return { handleShiftEnter, handleEnter, handleBackSpace };
};

export default useKeyDownHandlers;
