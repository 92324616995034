// @flow

import styled from "@emotion/styled";

import { Box } from "src/styles/box";
import * as radius from "src/styles/constants/radius";
import * as colors from "src/styles/constants/colors";

export const Date = styled(Box)`
  position: absolute;
  border-radius: ${radius.medium};
  right: 0px;
  padding: 0 1em 0 1em;
  > ul {
    padding-left: 0;
    list-style: none;
    > li {
      display: flex;
      line-height: 22px;
      justify-content: flex-start;
      width: 14em;
    }
  }
`;

export const Label = styled.strong`
  color: ${colors.primary};
  padding-left: 0.8em;
`;

export const Range = styled.div`
  margin: 0.3em 0;
  background: ${colors.grey0};
  border-radius: ${radius.medium};
  height: 3.3em;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
`;

export const Select = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  border: 1px solid ${colors.secondary};
`;

export const Value = styled.span`
  font-size: 8px;
  cursor: pointer;
`;

export const CalendarIcon = styled.div`
  border-left: 1px solid ${colors.secondary};
  padding-left: 0.4em;
  margin-left: 0.4em;
  > svg {
    height: 0.7em;
    width: 0.7em;
  }
`;
