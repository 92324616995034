// @flow

import { connect, useSelector } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import {
  getCurrentUserId,
  getEmbeddedConversationColumns,
  getExpandedFields,
  getChecklistFieldSourceSettings,
  getFieldWidth,
  getChatroomAttributeWidth
} from "src/reducers";

import { EditIconWrapper } from "../styles";
import MultipleConversation from "./MultipleConversation";
import Status from "../Status";
import Owner from "../Owner";
import DueDate from "../DueDate";
import {
  NoValue,
  ConversationWrapper,
  StyledMultiConversation,
  MultiContainer,
  MultiTable,
  MultiConversationRow
} from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import Title from "src/components/chatroom/Name/Title";
import ChecklistItem from "../ChecklistItem";
import { openConversationModal, fetchConversation } from "src/actions/chatroom";
import type {
  AppState,
  UID,
  ColumnId,
  WorkflowInstances,
  ExpandedEmbeddedColumns,
  ExpandedEmbeddedFields
} from "src/types";

type Props = {
  autoNo: string,
  selected: boolean,
  multiple: boolean,
  value: any,
  _openConversationModal: Function,
  _fetchConversation: Function,
  openChecklistEditModal: Function,
  index: number,
  embeddedIndex?: number,
  fieldId: number,
  roomId: string,
  embeddedFields: ?Array<number>,
  hideEdit?: boolean,
  onVersionClick?: Function,
  autoLinkedRooms?: number[],
  currentUserId: UID,
  settings: Object,
  type: string,
  columnId: ColumnId,
  process?: WorkflowInstances,
  embeddedColumns: ExpandedEmbeddedColumns,
  expandedFields: ExpandedEmbeddedFields,
  originalValue: any
};

const Conversation = ({
  autoNo,
  selected,
  multiple,
  value,
  _openConversationModal,
  _fetchConversation,
  openChecklistEditModal,
  index,
  embeddedIndex,
  fieldId,
  roomId,
  embeddedFields,
  hideEdit,
  onVersionClick,
  autoLinkedRooms,
  type,
  columnId,
  settings,
  process,
  embeddedColumns,
  expandedFields,
  originalValue
}: Props) => {
  const sourceProcessSettings = useSelector(({ app }) =>
    getChecklistFieldSourceSettings(app, fieldId)
  );

  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  const { ownerFieldWidth, dueDateFieldWidth, statusFieldWidth } = useSelector(
    ({ app }) => getChatroomAttributeWidth(app, columnId)
  );
  const handleConversationModal = useCallback(
    (id: number) => {
      _fetchConversation(id);
      _openConversationModal(id);
    },
    [_fetchConversation, _openConversationModal]
  );

  // Setting to decide whether or not to show the owner, status and
  // due date columns for linked fields
  const showMetaData =
    typeof sourceProcessSettings !== "string" && sourceProcessSettings.size > 0
      ? sourceProcessSettings.get("showMetaData")
      : false;

  // Setting to decide whether or not to supress the hyperlinks on
  // related conversation titles
  const disableTitleHyperlink = R.has("disableTitleHyperlink", settings)
    ? settings.disableTitleHyperlink
    : false;

  const multipleFlag = multiple || value?.length > 1;
  if ((multipleFlag && value) || (columnId && columnId.includes("-"))) {
    return (
      <MultipleConversation
        autoNo={autoNo}
        value={value}
        originalValue={originalValue}
        selected={selected}
        openChecklistEditModal={openChecklistEditModal}
        rowIndex={index}
        fieldId={fieldId}
        roomId={roomId}
        handleConversationModal={handleConversationModal}
        embeddedIndex={embeddedIndex}
        embeddedFields={embeddedFields}
        hideEdit={hideEdit}
        onVersionClick={onVersionClick}
        autoLinkedRooms={autoLinkedRooms}
        type={type}
        columnId={columnId}
        settings={type === "link" && settings}
        process={process}
        disableTitleHyperlink={disableTitleHyperlink}
      />
    );
  }

  const firstValue = R.head(value || []);

  const embeddedConversations =
    process && process[columnId]?.result?.length > 0
      ? process[columnId]?.result
      : [null];

  const chatroomId = embeddedConversations[0];
  const linkedField = (process &&
    chatroomId &&
    process[columnId]?.entities?.chatrooms?.[chatroomId].chatroom) || {
    status: "",
    owner: "",
    dueDate: "",
    active: false
  };

  return (
    <MultiContainer
      selected={selected}
      borderRequired={expandedFields[columnId] ? true : false}
    >
      <MultiTable>
        <tbody>
          <MultiConversationRow
            expandedField={expandedFields[columnId] ? true : false}
          >
            <thead>
              <StyledMultiConversation
                selected={selected}
                width={fieldWidth}
                expanded={expandedFields[columnId] ? true : false}
              >
                {firstValue?.type ||
                firstValue?.chatroomId ||
                firstValue?.seqNo ? (
                  <ConversationWrapper selected={selected}>
                    <Title
                      id={firstValue.id || firstValue?.chatroomId}
                      title={firstValue.title}
                      canceled={firstValue.canceled}
                      processName={firstValue.processTitle}
                      openConversationModal={handleConversationModal}
                      address={firstValue.address}
                      seqNo={firstValue.autoNo || firstValue.seqNo}
                      disableLink={disableTitleHyperlink}
                    />
                    {onVersionClick &&
                      autoLinkedRooms &&
                      firstValue.versionCount > 1 && (
                        <Icon
                          type="currentRevision"
                          fill={
                            autoLinkedRooms.includes(firstValue.id)
                              ? colors.purple
                              : colors.inkLightest
                          }
                          onClick={() => {
                            onVersionClick(firstValue.id);
                          }}
                        />
                      )}
                  </ConversationWrapper>
                ) : (
                  <ConversationWrapper>
                    <NoValue data-cy="currentChatroomTitle"> </NoValue>
                  </ConversationWrapper>
                )}
                {!hideEdit && (
                  <EditIconWrapper
                    onClick={e =>
                      openChecklistEditModal(
                        e,
                        index,
                        fieldId,
                        roomId,
                        originalValue || value,
                        columnId
                      )
                    }
                  >
                    <Icon type="editPencil" />
                  </EditIconWrapper>
                )}
              </StyledMultiConversation>
            </thead>

            {showMetaData && expandedFields?.[`${columnId}`] && process && (
              <>
                <MultiContainer
                  width={ownerFieldWidth}
                  key={`${index}-${embeddedConversations[0] || "status"}`}
                  borderRequired={true}
                >
                  <Owner
                    embedded={true}
                    selected={selected}
                    id={parseInt(embeddedConversations[0])}
                    value={linkedField.owner}
                    index={index}
                    parentId={null}
                    depth={0}
                    columnId={columnId}
                  />
                </MultiContainer>

                <MultiContainer
                  width={statusFieldWidth}
                  key={`${index}-${embeddedConversations[0] || "owner"}`}
                  borderRequired={true}
                >
                  <Status
                    selected={selected}
                    embedded={true}
                    id={parseInt(embeddedConversations[0])}
                    value={linkedField.status}
                    hide={!embeddedConversations[0]}
                    index={index}
                    parentId={process.id}
                    depth={0}
                    templateId={process.id}
                    columnId={columnId}
                  />
                </MultiContainer>

                <MultiContainer
                  width={dueDateFieldWidth}
                  key={`${index}-${embeddedConversations[0] || "dueDate"}`}
                  borderRequired={true}
                >
                  <DueDate
                    embedded={true}
                    selected={selected}
                    id={parseInt(embeddedConversations[0])}
                    value={linkedField.dueDate}
                    index={index}
                    depth={0}
                    active={linkedField.active}
                    templateId={process.id}
                    columnId={columnId}
                  />
                </MultiContainer>
              </>
            )}

            {/* Embedded fields */}
            {embeddedColumns[columnId] &&
              embeddedColumns[columnId].map((column, columnIndex) => {
                if (process) {
                  const depth = `${column.id}`.split("-").length - 1;
                  let embeddedValue;
                  if (depth === 1) {
                    embeddedValue =
                      process && process[column.id]
                        ? process[column.id]?.[0]
                        : [];
                  } else if (depth === 2) {
                    embeddedValue =
                      process && process[column.id]
                        ? process[column.id]?.[0]?.[0]
                        : [];
                  }
                  if (column.type === "link" && process) {
                    if (depth === 1) {
                      embeddedValue = process[column.id]?.[0] || {
                        entities: { chatrooms: {} },
                        result: []
                      };
                    } else if (depth === 2) {
                      embeddedValue = process[column.id]?.[0]?.[0] || {
                        entities: { chatrooms: {} },
                        result: []
                      };
                    }
                  }
                  return (
                    <ChecklistItem
                      selected={selected}
                      key={`${columnIndex}-${0}`}
                      columnId={column.id}
                      fieldId={parseInt(column.fieldId)}
                      value={embeddedValue}
                      settings={settings}
                      index={index}
                      isPrivate={false}
                      roomId={roomId}
                      process={process}
                      allRevisionsShown={true}
                      shouldRenderEmbeddedFields={true}
                      embeddedIndex={0}
                    />
                  );
                }
              })}
          </MultiConversationRow>
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUserId: getCurrentUserId(app),
  embeddedColumns: getEmbeddedConversationColumns(app),
  expandedFields: getExpandedFields(app)
});

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal,
  _fetchConversation: fetchConversation
})(Conversation);
