// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import Link from "redux-first-router-link";

import {
  AppInfo as StyledAppInfo,
  InfoItem,
  OrgName,
  LogIn,
  WelcomeMessage,
  UnifizeLogo,
  DriveProcess,
  UnifizeIntro
} from "./styles";
import EmailSent from "./EmailSent";
import EmailFailed from "./EmailFailed";
import LoadingState from "src/components/LoadingState";
import Icon from "src/icons";
import type { AppState } from "src/types";
import { getOrgName } from "src/reducers";
import { srwSignUp } from "src/actions/signup";
import { appDomain } from "src/config/firebase";

type Props = {
  orgName: string,
  currentUser: Object,
  path: string,
  canJoin: boolean,
  isExistingUser: boolean,
  _srwSignUp: Function,
  isVerificationEmailSent: boolean
};

const AppInfo = ({
  orgName,
  currentUser,
  path,
  canJoin,
  isExistingUser,
  _srwSignUp,
  isVerificationEmailSent
}: Props) => {
  const { displayName, email } = currentUser;

  const renderSection = useCallback(() => {
    if (isVerificationEmailSent === null)
      return (
        <>
          {(displayName || email) && (
            <WelcomeMessage>
              Welcome back {displayName || email.split("@")[0]}!<br />
              {canJoin && (
                <>
                  {isExistingUser ? "Log in" : "Sign up"} to use the full
                  version of the app
                </>
              )}
            </WelcomeMessage>
          )}

          {canJoin && (
            <>
              {isExistingUser ? (
                <Link to={`${appDomain}${path}`} target="_blank">
                  <LogIn>Log In</LogIn>
                </Link>
              ) : (
                <LogIn onClick={_srwSignUp}>Sign Up</LogIn>
              )}
            </>
          )}
        </>
      );
    if (isVerificationEmailSent) return <EmailSent />;
    return <EmailFailed />;
  });

  if (orgName)
    return (
      <StyledAppInfo>
        <div />
        <InfoItem>
          <OrgName>{orgName}</OrgName>
          {renderSection()}
        </InfoItem>
        <InfoItem>
          <UnifizeLogo>
            <Icon type="unifizeLogo" />
          </UnifizeLogo>
          <DriveProcess>Drive process through conversation</DriveProcess>
          <UnifizeIntro>
            Unifize increases your team{`'`}s efficiency by up to 10x by
            connecting its conversations to process objectives.
          </UnifizeIntro>
        </InfoItem>
      </StyledAppInfo>
    );

  return <LoadingState type="info" />;
};

const mapStateToProps = ({
  app,
  location
}: {
  app: AppState,
  location: Object
}) => ({
  canJoin: app.canJoin,
  orgName: getOrgName(app, parseInt(app.orgs.last, 10)),
  currentUser: app.currentUser,
  path: location.pathname,
  isExistingUser: app.srw.isExistingUser,
  isVerificationEmailSent: app.srw.isVerificationEmailSent
});

export default connect(mapStateToProps, {
  _srwSignUp: srwSignUp
})(AppInfo);
