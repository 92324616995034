// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const defaultTitle = "Unifize";

export default (state: string = defaultTitle, { type }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HOME:
      return defaultTitle;
    case atypes.SIGN_IN:
      return `${defaultTitle} Login`;
    case atypes.SIGN_UP:
      return `${defaultTitle} Signup`;
    case atypes.JOIN:
      return `${defaultTitle} Join`;
    default:
      return state;
  }
};
