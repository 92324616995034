// @flow

import styled from "@emotion/styled";

import { SmallText } from "src/styles/input";
import * as colors from "src/styles/constants/colors";

export const Edit = styled.div``;

export const Input = styled(SmallText)`
  border: 1px solid ${colors.grey6} !important;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 8px;
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
  position: absolute;
  border: 1px solid ${colors.grey6} !important;
  border-radius: 4px;
  background: ${colors.brightLight};
  margin: 0.2em 0px;
  width: 13.6em;
`;

export const ListItem = styled.li`
  padding: 4px 8px;
  cursor: pointer;
  :hover,
  :focus {
    background-color: ${colors.grey2};
    outline: none;
  }
`;

export const Add = styled(ListItem)`
  color: ${colors.secondary};
`;

export const Value = styled.span`
  font-weight: bold;
  cursor: pointer;
  height: 1.5em;
`;
