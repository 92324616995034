// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import { SystemCSSProperties } from "@chakra-ui/react";

import {
  colors as colorsOld,
  spacing as spacingOld,
  size
} from "src/styles/constants";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

import { Button } from "src/styles/buttons";
import { Search } from "src/styles/input";
import { RelativeTruncatedBox, ScrollableBox } from "src/styles/box";
import * as spacing from "src/styles/constants/spacing";
import { UploadButton } from "src/components/chatroom/styles";

const ChatInputContainer = styled.div`
  width: 100%;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ChatInput = styled.div`
  padding: 0 ${spacingOld.spacing2} ${spacingOld.spacing1}
    ${spacingOld.spacing2};
  width: 100%;
  display: flex;
  border-radius: 2px;
  order: 2 !important;
  :focus-within {
  }

  ${UploadButton} {
    width: auto;
  }

  & > button:first-of-type {
    background: ${colorsOld.blue};
    margin-right: 0px;
    border-right: 0px;
    img {
      width: ${spacingOld.spacing2};
      height: ${spacingOld.spacing2};
    }
  }
  svg {
    stroke: ${colorsOld.pureWhite};
    fill: ${colorsOld.pureWhite};
  }
  path {
    fill: ${colorsOld.pureWhite};
  }
  form {
    flex-grow: 1;
    min-height: 44px;
    display: flex;
    cursor: text;
    & > button:first-of-type {
      border-right: solid 1px ${colorsOld.grey} !important;
    }
    &>form>button: first-of-type {
      border-left: solid 1px ${colorsOld.grey} !important;
      border-right: solid 1px ${colorsOld.grey} !important;
    }
  }
`;

export const SendIcon = styled.div`
  top: 0;
  right: 0;
  width: 46px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const UploadIcon = styled.button`
  cursor: pointer;
  width: 48px !important;
  height: 100% !important;
  background: ${colorsOld.blue};
  padding: 0 !important;
  border-radius: ${radius.small} 0px 0px ${radius.small};
`;

const InputIcon = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  border-radius: ${radius.small} 0 0 ${radius.small};
  background-color: ${({ type }) =>
    type === "mail" ? `${colorsOld.glitter}` : `${colorsOld.dullWhite}`};
  display: flex;
  width: 48px;
  min-height: 44px;
  border-left: ${({ type }) =>
    type !== "mail" ? "none" : `solid 1px ${colors.active}`};
  align-items: center;
  justify-content: center;
  button {
    width: ${spacingOld.spacing2};
    height: ${spacingOld.spacing2};
  }
  &:hover {
    svg {
      stroke: ${colorsOld.pureWhite};
      fill: ${colorsOld.pureWhite};
    }
    path {
      fill: ${colorsOld.pureWhite};
    }
  }
`;

const MailIcon = styled.div`
  cursor: pointer;
  width: 48px;
  display: flex;
  background-color: ${colorsOld.pureWhite};
  justify-content: center;
  align-items: center;
  > svg {
    cursor: pointer;
  }
`;

const DeleteOptions = styled.div`
  width: 100%;
  height: 72px;
  border: 1px solid ${colorsOld.aliceBlue};
  background: ${colorsOld.pureWhite};
  display: flex;
  align-items: center;
  padding: ${props => (props.optionsWidth < 600 ? "2.4rem 1rem" : "2.4rem")};
  justify-content: space-between;
`;

const CloseMenu = styled.img`
  width: ${size.regular};
  height: ${size.regular};
  cursor: pointer;
  margin-right: ${props => (props.optionsWidth < 600 ? "1rem" : "4rem")};
`;

const InfoContainer = styled.div`
  font-size: 0.88rem;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  font-size: 0.88rem;
  align-items: center;
  button {
    width: ${props =>
      props.isSrwMobile
        ? "5.625rem"
        : props.optionsWidth < 600
          ? "5rem"
          : "9.375rem"};
    height: 35px;
    :last-child {
      background: ${colors.danger};
      border-color: ${colors.danger};
      margin-left: 1.5em;
    }
    :first-of-type {
      margin-right: 1.5em;
    }
    :disabled {
      background: ${colors.secondary};
      border: 1px solid ${colors.secondary};
      color: ${colors.brightLight};
      font-weight: bold;
    }
  }
`;

const InputButton = styled(Button)`
  background: ${colors.active};
  margin-right: ${spacingOld.spacing2};
  font-size: 0.88rem;
  height: 35px;
  font-weight: bold;
`;

const HideButton = styled(Button)`
  background: ${colorsOld.blue};
  margin-right: ${spacingOld.spacing2};
`;

const DeleteButton = styled(Button)`
  background: ${colorsOld.red};
  margin-left: ${spacingOld.spacing2};
  border: 1px solid ${colorsOld.red};
  :hover,
  :focus {
    border: 1px solid ${colors.grey4};
  }
`;

const Mentions = styled.div`
  position: absolute;
  bottom: 58px;
  left: 2px;
  width: 96%;
  z-index: 2;
  > div:first-of-type {
    position: static !important;
    margin-left: 2.5em;
    margin-bottom: 0.25em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${colors.grey4};
    > ul {
      overflow: auto;
      border-radius: 2px;
      max-height: 20em;
      > li {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 2px;
        background-color: ${props =>
          props.focus ? colors.grey2 : colors.brightLight};

        :hover,
        :focus {
          background-color: ${colors.grey2};
          outline: none;
        }
      }
    }
  }
`;

const Suggestion = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: ${props =>
    props.isFocused ? colors.lightBlue : colors.brightLight};
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const MentionedUser = styled.span`
  color: ${colors.active};
  > span {
    > span {
      color: ${colors.active};
      font-weight: initial;
    }
  }
`;

const Tag = styled.span`
  color: ${colors.grey5};
  font-size: 10px;
  float: right;
`;

export const ListItem = styled.button`
  line-height: 2.125rem;
  width: 100%;
  display: block;
  cursor: pointer;
  padding: ${spacing.space_inset_squish_s};
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: ${props =>
    props.focus ? colors.grey2 : colors.brightLight};

  :hover,
  :focus {
    background-color: ${colors.grey2};
    outline: none;
  }
`;

export {
  ChatInputContainer,
  ChatInput,
  InputIcon,
  MailIcon,
  DeleteOptions,
  CloseMenu,
  InfoContainer,
  ButtonContainer,
  InputButton,
  HideButton,
  DeleteButton,
  Mentions,
  Suggestion,
  MentionedUser,
  Tag
};

export const ForwardModalContainer = styled.form`
  width: 420px;
  height: 90vh;
  background: ${colors.brightLight};
  display: flex;
  flex-direction: column;
`;

export const ForwardModalHeader = styled.h4`
  background: ${colors.unifizeBlue};
  color: ${colors.brightLight};
  margin: 0;
  padding: 1.5em;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ForwardModalBody = styled.div`
  padding-top: 1.2em;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const SearchContainer = styled.div`
  position: relative;
  margin: 0 2em;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  padding-top: 0.7em;
  width: 3.3em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled(Search)`
  padding-left: 3em;
`;

export const ChatroomList = styled.div`
  width: 100%;
  flex: 1;
  min-height: 40vh;
  margin: 1em 0;
  overflow: auto;
`;

export const ChatroomRow = styled.div`
  height: 5.2em;
  display: flex;
  position: relative;
`;

export const RowInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 2em;
  padding-top: 0.3em;
  padding-right: 0.5em;
  border-bottom: 1px solid ${colors.grey2};
  > div:first-of-type {
    display: flex;
    h4 {
      width: 280px !important;
      display: inline-block !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.3em !important;
      height: 1.3em !important;
    }
  }
`;

export const RowStatus = styled.div`
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.secondary};
  padding: 0 0.8em;
`;

export const SelectCircle = styled.div`
  width: 24px;
  height: 24px;
`;

export const SelectionHighlight = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${ifProp("highlight", colors.outline, "transparent")};
  opacity: 0.2;
  cursor: pointer;
`;

export const BreadCrumbsWrapper = styled(RelativeTruncatedBox)`
  margin-left: 30px;
  white-space: nowrap;
  > div > div {
    display: flex;
    align-items: center;
    > a {
      color: ${colors.secondary} !important;
      font-size: 0.813rem;
    }
  }
  width: 250px;
`;

export const ForwardModalFooter = styled.div`
  width: 100%;
  height: 60px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.6em;
  > button {
    width: 110px;
  }
`;

export const ForwardButton = styled(Button)`
  font-weight: bold;
`;

export const Join = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
`;

export const MentionsSuggestion = styled(ScrollableBox)`
  postion: absolute;
  width: 100%;
  > ul {
    max-height: 15em;
  }
`;

export const InputForm = styled.form`
  max-width: 100%;
`;

export const LoadingConversation = styled.div`
  padding: 20px 28px;
  width: 100%;
  > div {
    width: 100%;
    border-radius: ${radius.large};
    background: ${colors.appBackground};
  }
`;

export const MentionAvatar: SystemCSSProperties = {
  borderRadius: radius.medium,
  background: colors.appBackground
};
