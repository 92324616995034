// @flow

import React, { Fragment } from "react";
import ReactProgressiveList from "react-progressive-list";

import SearchItem from "src/containers/chatroom/Item";
import { SearchResult } from "src/styles/dropdown";
import autoPosition from "src/components/autoPosition";
import useRoveFocus from "src/hooks/useRovingFocus";

import type { UID } from "src/types";

type Props = {
  focus: number,
  result: Array<UID>,
  handleSelect: Function
};

const Result = ({ result, focus, handleSelect }: Props) => {
  const { currentFocus, setCurrentFocus } = useRoveFocus(result.length);
  const renderRow = (index: number) => {
    const item = result[index];
    if (item) {
      return (
        <SearchItem
          key={item}
          handleSelect={handleSelect}
          id={item}
          focus={focus === index}
          setCurrentFocus={setCurrentFocus}
          isFocus={currentFocus === index}
          index={index}
        />
      );
    }
  };

  return (
    <Fragment>
      {result.length > 0 ? (
        <SearchResult>
          <ul>
            <ReactProgressiveList
              initialAmount={50}
              progressiveAmount={50}
              role="list"
              renderItem={renderRow}
              renderLoader={() => <div />}
              rowCount={result.length}
            />
          </ul>
        </SearchResult>
      ) : null}
    </Fragment>
  );
};

export default autoPosition(Result, {
  bottomOffset: "36px",
  topOffset: "4px",
  position: "top",
  leftOffset: "-1px",
  rightOffset: "-3px"
});
