// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { colors as colorsOld, spacing, fontSize } from "./constants";
import * as radius from "src/styles/constants/radius";
import * as colors from "src/styles/constants/colors";

import searchIcon from "src/img/search.svg";
import checkbox from "src/img/checkbox.svg";

const Input = styled.input`
  width: 90%;
  padding: 4px 10px;
  min-height: 32px;
  border-radius: 2px;
  background-color: ${colorsOld.pureWhite};
  border: solid 1px ${colorsOld.grey};
`;

const Checkbox = styled.div`
  display: inline-block;
  label {
    display: block;
    padding: 0px;
    cursor: pointer;
    display: inline;
    align-items: center;
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      & + div {
        display: inline-block;
        margin-right: 5px;
        cursor: pointer;
        height: 16px;
        width: 16px;
        border: solid 1px ${colorsOld.silver};
        background: ${colorsOld.pureWhite};
        border-radius: 3px;
        position: relative;
        top: 3px;
      }
    }
    input:checked {
      & + div {
        background-image: url(${checkbox});
        background-repeat: no-repeat;
        height: 16px;
        width: 16px;
        background-size: contain;
        border: none;
        border-radius: 3px;
      }
    }
  }
`;

const Search = styled.input`
  background: white url(${searchIcon}) 11px no-repeat;
  width: 100%;
  margin: 8px auto;
  padding: 8px 39px !important;
  margin-bottom: 3px;
  border-radius: 2px;
  background-color: #f5f6f7;
  border: solid 1px #dddddd;
  ${({ transparent }) =>
    transparent &&
    css`
      background-color: white;
      border: 0;
      border-bottom: solid 1px #e6e8ec;
      border-radius: 0;
      margin: 0 0 !important;
    `};
  ::placeholder {
    color: #969696;
  }
`;

const Chat = styled.div`
  flex-grow: 1;
  border: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  cursor: text;
  background-color: ${colorsOld.pureWhite};
  border: 2px solid ${colorsOld.blue};
  border-radius: 0px ${radius.small} ${radius.small} 0px;
  width: 100%;
  padding: 0;
  > div:first-of-type {
    width: 100%;
    height: 100%;
    > div:first-of-type > div:first-of-type {
      max-height: 20em;
      margin: 0px;
      padding: 0px;
      outline: 0px;
      border: 0px;
      line-height: initial;
      height: auto !important;
    }
  }

  :focus-within,
  :hover {
    outline: none;
    border: 2px solid ${colorsOld.blue};
    > button {
      border: 0;
    }
  }

  textarea {
    border: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    max-height: 20em;
    bottom: 0px;
    overflow: auto;
    resize: none;
    margin: 0px;
    padding: 0px;
    outline: 0px;
    border: 0px;
    line-height: initial;
    height: auto !important

    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  }

  ::placeholder {
    font-size: 0.88rem;
    font-style: italic;
    line-height: 17px;
    color: ${colorsOld.disabledBorderGrey};
  }

  .mention-suggestion {
    position: relative;
    background: ${colors.grey15};
    font-weight: bold;
    opacity: 100%;
    z-index: 1000;
    color: ${colors.active};

    li:focus {
      background-color: ${colors.grey0};
    }
  }
`;

const SelectOption = styled.div``;

const NewOption = styled.div`
  padding: ${spacing.spacing2};
  h2 {
    position: relative;
    font-size: ${fontSize.t1};
    padding-bottom: ${spacing.spacing2};
    border-bottom: 1px solid ${colorsOld.isabelline};
    > svg {
      position: absolute;
      right: ${spacing.spacing2};
      top: -${spacing.spacing0};
      cursor: pointer;
    }
  }

  > div:last-child {
    margin-top: ${spacing.spacing3};
  }

  > div > button:last-child {
    margin-left: 12px;
  }

  input[type="text"] {
    display: block;
    padding: 8px;
    width: 94%;
  }
`;

export default Input;
export { Checkbox, Search, Chat, SelectOption, NewOption };
