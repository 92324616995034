// @flow

import React, { useState, useEffect, useCallback } from "react";
import ReactProgressiveList from "react-progressive-list";

import {
  ChatBlockWrapper as StyledChatBlockWrapper,
  Search as StyledSearch,
  TabContainer,
  TabRow,
  TabItem,
  Selector,
  Categories,
  CategoryDropdown,
  CategoryWrapper,
  ControlWrapper
} from "./styles";

import { Portal, Text } from "@chakra-ui/react";

import IconLabel from "src/components/IconLabel";
import selector from "src/components/selector";

import Icon from "src/components/Icon";
import ChatBlock from "src/containers/chatroom-nav/ChatBlock";
import type { RoomId, RelatedCount } from "src/types";

import { iconSize } from "src/styles/constants/size";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalOverlay
} from "src/components/Modal";

type Props = {
  toggleRelatedModal: Function,
  roomId: RoomId,
  showRelatedModal: boolean,
  dropdown: boolean,
  handleDropdown: Function,
  _searchChatroom: Function,
  relatedConversations: [RoomId],
  parentCount: number,
  childCount: number,
  relatedCount: RelatedCount,
  portalRef: null | React$ElementRef<Portal>
};

const RelatedModal = ({
  toggleRelatedModal,
  roomId,
  showRelatedModal,
  dropdown,
  handleDropdown,
  _searchChatroom,
  relatedConversations,
  parentCount,
  childCount,
  relatedCount,
  portalRef
}: Props) => {
  const [category, setCategory] = useState(0);
  const [relation, setRelation] = useState(0);

  const child = [
    childCount,
    relatedCount.child.overdue,
    relatedCount.child.completed,
    relatedCount.child.pending,
    relatedCount.child.owned
  ];
  const parent = [
    parentCount,
    relatedCount.parent.overdue,
    relatedCount.parent.completed,
    relatedCount.parent.pending,
    relatedCount.parent.owned
  ];

  const childOrParent = [child, parent];

  const closeModal = useCallback(() => {
    toggleRelatedModal({ open: false });
  }, [toggleRelatedModal]);

  const dropdownText = [
    `All Related ${relation === 0 ? "Children" : "Parents"}`,
    "Overdue",
    "Completed",
    "Pending",
    "I'm the Owner"
  ];

  useEffect(() => {
    _searchChatroom("", {
      searchType: "relatedConversations",
      relation,
      category
    });
  }, [_searchChatroom, relation, category]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      _searchChatroom(value, {
        searchType: "relatedConversations",
        relation,
        category
      });
    },
    [_searchChatroom, relation, category]
  );

  const icons = ["related", "dateWarning", "completed", "incomplete", "owner"];

  const renderRow = id => {
    if (id < relatedConversations.length)
      return (
        <StyledChatBlockWrapper key={relatedConversations[id]}>
          <ChatBlock width="full" roomId={`${relatedConversations[id]}`} />
        </StyledChatBlockWrapper>
      );
    return null;
  };

  const selectorVariants = [
    {
      left: "0%"
    },
    {
      left: "50%"
    }
  ];

  return (
    <ModalOverlay
      isOpen={showRelatedModal}
      closeModal={closeModal}
      portalRef={portalRef}
    >
      <Modal isOpen={showRelatedModal}>
        <ModalHeader>
          <Text as="span">Related Conversations</Text>
          <ModalClose closeModal={() => toggleRelatedModal({ open: false })} />
        </ModalHeader>

        <ModalBody>
          <StyledChatBlockWrapper>
            <ChatBlock width="full" roomId={roomId} highlight />
          </StyledChatBlockWrapper>
          <ControlWrapper>
            <TabContainer>
              <TabRow>
                <TabItem
                  selected={relation === 0}
                  onClick={() => setRelation(0)}
                >
                  <IconLabel
                    icon="related"
                    size={iconSize.space_m}
                    label={`Related Children (${child[0]})`}
                    cursor="pointer"
                    category="child"
                  />
                </TabItem>
                <TabItem
                  selected={relation === 1}
                  onClick={() => setRelation(1)}
                >
                  <IconLabel
                    icon="related"
                    size={iconSize.space_m}
                    label={`Related Parents (${parent[0]})`}
                    cursor="pointer"
                    category="parent"
                  />
                </TabItem>
              </TabRow>
              <Selector animate={selectorVariants[relation]} />
            </TabContainer>
            <Categories onClick={handleDropdown}>
              <CategoryWrapper category={icons[category]}>
                <IconLabel
                  icon={icons[category]}
                  size={iconSize.space_m}
                  label={`Showing ${dropdownText[category]} (${childOrParent[relation][category]})`}
                  cursor="pointer"
                />
              </CategoryWrapper>
              <Icon icon="triangleDown" />
              {dropdown && childOrParent[relation][category] > 0 ? (
                <CategoryDropdown>
                  <ul>
                    {childOrParent[relation].map((categoryItem, id) =>
                      categoryItem ? (
                        <li
                          key={dropdownText[id]}
                          onClick={() => setCategory(id)}
                          role="option"
                          aria-selected={id === category}
                          onKeyPress={() => setCategory(id)}
                        >
                          <CategoryWrapper category={icons[id]}>
                            <IconLabel
                              icon={icons[id]}
                              label={`${dropdownText[id]} (${childOrParent[relation][id]})`}
                              size={iconSize.space_m}
                              cursor="pointer"
                            />
                          </CategoryWrapper>
                        </li>
                      ) : null
                    )}
                  </ul>
                </CategoryDropdown>
              ) : null}
            </Categories>
            <StyledSearch
              placeholder="Search...."
              type="text"
              autoFocus
              onChange={handleSearch}
            />
          </ControlWrapper>
          <ReactProgressiveList
            initialAmount={20}
            role="list"
            renderItem={renderRow}
            renderLoader={() => <div />}
            rowCount={relatedConversations.length}
          />
        </ModalBody>
      </Modal>
    </ModalOverlay>
  );
};

export default selector(RelatedModal);
