// @flow

import React from "react";

const Dashboard = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 25V27H35V25H39ZM29 25V31H25V25H29ZM39 33V39H35V33H39ZM29 37V39H25V37H29ZM41 23H33V29H41V23ZM31 23H23V33H31V23ZM41 31H33V41H41V31ZM31 35H23V41H31V35Z"
      fill="#C0C9D1"
    />
  </svg>
);

export default Dashboard;
