// @flow

import React from "react";
import { getRandomHexColor } from "src/utils";

type Props = {
  text?: string,
  size?: "xs" | "sm",
  photoUrl?: string,
  id?: number
};

const avatarSize = {
  xs: "16",
  sm: "32",
  md: "36"
};

const GroupAvatar = ({
  text = "group",
  size = "sm",
  photoUrl,
  id = 0
}: Props) => {
  const groupAvatarText = text.slice(0, 2).toUpperCase();
  const colorCode = getRandomHexColor(id);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={avatarSize[size]}
      height={avatarSize[size]}
      fill="none"
      viewBox="0 0 36 36"
    >
      <rect
        width="26.571"
        height="26.571"
        x="9"
        y="9"
        fill="#fff"
        stroke={photoUrl ? "#919EAB" : colorCode}
        strokeWidth="0.857"
        rx="1.286"
      ></rect>
      <rect
        width="26.571"
        height="26.571"
        x="4.714"
        y="4.714"
        fill="#fff"
        stroke={photoUrl ? "#919EAB" : colorCode}
        strokeWidth="0.857"
        rx="1.286"
      ></rect>
      <rect
        width="26.571"
        height="26.571"
        x="0.429"
        y="0.429"
        fill={photoUrl ? `url(#avatar-pattern-${id})` : colorCode}
        stroke={photoUrl ? "#919EAB" : colorCode}
        strokeWidth="0.857"
        rx="1.286"
      ></rect>
      {photoUrl ? (
        <defs>
          <pattern
            id={`avatar-pattern-${id}`}
            width="1"
            height="1"
            patternContentUnits="objectBoundingBox"
          >
            <use transform="scale(.00163)" xlinkHref={`#group-avatar-${id}`} />
          </pattern>

          <image
            xlinkHref={photoUrl}
            id={`group-avatar-${id}`}
            width="612"
            height="612"
          ></image>
        </defs>
      ) : (
        <text x="7" y="18" fill="white">
          {groupAvatarText}
        </text>
      )}
    </svg>
  );
};

export default GroupAvatar;
