// @flow

import * as R from "ramda";
import { connect } from "react-redux";
import React, { useRef, useState, useLayoutEffect } from "react";

import Header from "./Header";
import Body from "./Body";
import {
  Table as StyledTable,
  TableWrapper
} from "src/components/Manage/Workflow/Instances/Table/styles";
import {
  checklistFieldSizeMap,
  omitedFields
} from "src/constants/processInstanceColumns";
import type { AppState } from "src/types";

type Props = {
  fields: Array<Object>
};

const Table = ({ fields }: Props) => {
  const tableRef = useRef(null);
  const [width, setWidth] = useState("100%");

  useLayoutEffect(() => {
    if (tableRef && tableRef.current) {
      setWidth(`${tableRef.current.scrollWidth || "100%"}px`);
    }
  }, [tableRef, fields, setWidth]);

  const filteredFileds = fields.filter(
    column => !R.includes(column.type, omitedFields)
  );

  const columnSize: string = `${filteredFileds
    .filter(column => !R.includes(column.type, omitedFields))
    .reduce(
      (accumulator: string, column: Object): string =>
        `${accumulator} ${checklistFieldSizeMap[column.type]}`,
      "40px 50px 280px 200px 100px"
    )} 200px 200px`;

  return (
    <TableWrapper>
      <StyledTable columnSize={columnSize}>
        <Header columnSize={columnSize} fields={filteredFileds} width={width} />
        <Body
          columnSize={columnSize}
          fields={filteredFileds}
          numberOfColumns={5 + filteredFileds.length + 1}
          width={width}
        />
      </StyledTable>
    </TableWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  fields: app.form.openedForm.fields || []
});

export default connect(mapStateToProps)(Table);
