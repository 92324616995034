// @flow
import React from "react";
import { ApprovalStatusContainer, ApprovalStatusTextWrapper } from "./styles";
import { statusDisplayText } from "src/constants/approvalStatus";
type Props = {
  status: string
};

const ApprovalStatus = ({ status }: Props) => {
  return status ? (
    <ApprovalStatusContainer status={status}>
      <ApprovalStatusTextWrapper>
        {statusDisplayText[status]}
      </ApprovalStatusTextWrapper>
    </ApprovalStatusContainer>
  ) : (
    <>&#8203;</>
  );
};

export default ApprovalStatus;
