// @flow
import React, { useContext } from "react";

import { ModalBody } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import { dataStages } from "src/constants";
import Checkbox from "src/components/Unifize/Checkbox";

import { ImportStateContext } from "./importReducer";
import {
  modalBody,
  PanelTitle,
  PanelButtons,
  ImportedTemplate,
  ImportedTemplates
} from "./styles";
import ModalButton from "../../ModalButton";

type Props = {
  handleImport: ?() => void | Promise<void>,
  handleBack: () => void
};

const ImportPanel = ({ handleImport, handleBack }: Props) => {
  const { importProcesses, status } = useContext(ImportStateContext);
  return (
    <ModalBody sx={modalBody}>
      <PanelTitle>
        The following processes are included in the import.
      </PanelTitle>
      <ImportedTemplates>
        {importProcesses.map(
          (process, index) =>
            !process?.exclude && (
              <ImportedTemplate key={`importedProcess${process.id}_${index}`}>
                <Checkbox
                  isDisabled={true}
                  checked={true}
                  onChange={() => {}}
                />
                {process.title}
              </ImportedTemplate>
            )
        )}
      </ImportedTemplates>
      <PanelButtons>
        <ModalButton
          label="Back"
          onClick={handleBack}
          loading={false}
          disabled={false}
          color={colors.grey41}
          textColor="#101828"
        />
        <ModalButton
          label="Import"
          onClick={handleImport}
          loading={status === dataStages.fetching}
          disabled={!importProcesses.length || status === dataStages.fetching}
        />
      </PanelButtons>
    </ModalBody>
  );
};

export default ImportPanel;
