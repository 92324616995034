// @flow

import React from "react";
import RouterLink from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import ChatRoomName from "src/containers/chatroom/Name";
import useChatroom from "src/hooks/useChatroom";

import type { RoomId, UnifizeChatRoom } from "src/types";

type Props = {
  nolink: boolean,
  id: RoomId
};

const BreadCrumb = ({ id, nolink }: Props) => {
  const chatroom: UnifizeChatRoom = useChatroom(id);
  const { address = "", templateId = "" } = chatroom || {};

  if (nolink || !address) {
    return (
      <>
        <ChatRoomName id={id} noTitle={false} />
        <span>&gt;</span>
      </>
    );
  }

  return (
    <RouterLink
      key={id}
      title={id}
      to={{
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: { id: address },
        meta: {
          query: {
            templateId
          }
        }
      }}
    >
      <ChatRoomName id={id} noTitle={false} isBreadcrumb />
      <span>&gt;</span>
    </RouterLink>
  );
};

export default BreadCrumb;
