// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Update as StyledUpdate } from "./styles";
import { ButtonOutline } from "src/styles/buttons";
import Icon from "src/icons";

import type { AppState, Updates as UpdatesType } from "src/types";

type Props = {
  updates: UpdatesType
};

const Update = ({ updates }: Props) => {
  const handleUpdate = useCallback(
    (e: any) => {
      e.preventDefault();
      // Hard refresh page
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    },
    [updates]
  );

  if (process.env.VERSION !== updates.newVersion && updates.newVersion) {
    return (
      <StyledUpdate>
        A new version of Unifize has been released.
        <ButtonOutline type="button" onClick={handleUpdate}>
          <Icon type="update" />
          <span>UPDATE NOW</span>
        </ButtonOutline>
      </StyledUpdate>
    );
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  updates: app.updates
});

export default connect(mapStateToProps)(Update);
