// @flow

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import Tile from "./Tile";

type Props = {
  right: boolean,
  value: Array<number>,
  deleteTile: Function,
  editTile: Function
};

const SortableItem = SortableElement(
  ({ value, right, deleteTile, editTile }: Props) => (
    <Tile
      right={right}
      id={value}
      deleteTile={deleteTile}
      editTile={editTile}
    />
  )
);

export default SortableItem;
