// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useCallback } from "react";

import { Button, RemindersContainer } from "./styles";
import Reminder from "./Reminder";
import { getBuilderAutoReminder } from "src/reducers";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import Icon from "src/icons";

const emptyReminder = {
  reminderTypeId: 1,
  remindIn: 0,
  interval: "day",
  allowDisable: true,
  message: ""
};

function Reminders() {
  const values = useSelector(({ app }) => getBuilderAutoReminder(app));
  const dispatch = useDispatch();

  const addNewReminder = useCallback(() => {
    dispatch(
      setWorkflowBuiderAttributes({ reminder: [...values, emptyReminder] })
    );
  }, [dispatch, values]);

  return (
    <RemindersContainer>
      {values.map((value, index) => (
        <Reminder key={index} value={value} index={index} />
      ))}
      <Button type="button" onClick={addNewReminder}>
        <Icon type="squarePlus" />
        <span>Add auto reminder</span>
      </Button>
    </RemindersContainer>
  );
}

export default Reminders;
