// @flow

import React, { Component } from "react";
import Modal from "react-modal";

import { FormBody as StyledFormBody } from "../styles";

import ButtonLoader from "src/components/ButtonLoader";
import { Small as StyledModal } from "src/styles/modal";
import { Secondary } from "src/styles/buttons";
import { Form, SecondaryFooter as Footer } from "src/styles/form";
import { TextInputWithBorders as StyledInput } from "src/styles/input";
import { ErrorText } from "src/styles/auth";

type Props = {
  editDisplayName: Function,
  currentUser: Object,
  toggleProfileModal: Function,
  updateNameError: any,
  closeMenu: Function
};

type State = {
  displayName: string,
  loading: boolean
};

class ProfileModal extends Component<Props, State> {
  state = {
    displayName: "",
    loading: false
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const { displayName } = currentUser;
    this.setState({ displayName });
  }

  componentWillUnmount() {
    this.setState({
      loading: false
    });
  }

  updateName = (e: any) => {
    e.preventDefault();
    const { editDisplayName } = this.props;
    const { displayName } = this.state;
    this.setState({ loading: true });
    editDisplayName({ displayName });
  };

  closeModal = () => {
    const { toggleProfileModal } = this.props;
    toggleProfileModal({ open: false });
  };

  handleChange = (e: any) => {
    this.setState({ displayName: e.target.value });
  };

  closeMenuAndNav = () => {
    const { closeMenu } = this.props;
    closeMenu();
  };

  render() {
    const { updateNameError } = this.props;
    const { displayName, loading } = this.state;
    return (
      <StyledModal id="edit-name-modal" onMouseEnter={this.closeMenuAndNav}>
        <Modal
          isOpen
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.querySelector("#edit-name-modal")}
        >
          <Form onSubmit={this.updateName}>
            <h4>Update Name</h4>
            <StyledFormBody>
              <StyledInput
                type="text"
                placeholder="Enter your name"
                value={displayName}
                onChange={this.handleChange}
                required
              />
              {updateNameError && (
                <ErrorText>{updateNameError.message}</ErrorText>
              )}
            </StyledFormBody>
            <Footer>
              <Secondary type="button" onClick={this.closeModal}>
                Cancel
              </Secondary>
              <ButtonLoader
                type="submit"
                isLoading={!updateNameError && loading}
              >
                Confirm
              </ButtonLoader>
            </Footer>
          </Form>
        </Modal>
      </StyledModal>
    );
  }
}

export default ProfileModal;
