// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const High = createIcon({
  displayName: "HighIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fill="none"
        stroke="#FA5E5B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M15.046 6.923L9.077.953a1.523 1.523 0 0 0-2.154 0l-5.97 5.97a1.523 1.523 0 0 0 0 2.154l5.97 5.97c.595.594 1.56.594 2.154 0l5.97-5.97a1.523 1.523 0 0 0 0-2.154z"
      />
      <path
        fill="none"
        stroke="#FA5E5B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M4.954 8.508L8 5.462l3.046 3.046"
      />
    </>
  )
});

export default High;
