// @flow

import { connect } from "react-redux";
import React, { memo } from "react";
import * as R from "ramda";
import type { List } from "immutable";

import DemoUser from "./DemoUser";
import DirectMessage from "./Contacts/DirectMessage";
import SegmentOrNoConversations from "./SegmentOrNoConversations";
import ChatroomList from "src/components/chatroom-nav/List";
import {
  getCurrentFilter,
  getFilteredChatRooms,
  getFilteredDirectMessages
} from "src/reducers";

import type { AppState, RoomId, CurrentFilter, UID } from "src/types";

type Props = {
  currentFilter: CurrentFilter,
  rooms: List<RoomId>,
  directMessages: Array<UID>
};

const Filtered = ({ rooms, currentFilter, directMessages }: Props) => {
  const { type, text, workflow } = currentFilter;
  const contacts = directMessages.filter(uid => uid && uid !== null);

  return (
    <>
      {rooms.size > 0 ? (
        <>
          <ChatroomList rooms={rooms} workflow={workflow} />
        </>
      ) : (
        <SegmentOrNoConversations
          rooms={rooms}
          workflow={workflow}
          type={type}
          text={text}
        />
      )}

      {(R.includes("direct", type) &&
        (text || "").length > 0 &&
        workflow === null) ||
      (type.length === 1 && R.includes("direct", type)) ? (
        <>
          {contacts.map(uid => (
            <DirectMessage key={uid} uid={uid} />
          ))}
        </>
      ) : null}

      <DemoUser />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentFilter: getCurrentFilter(app),
  rooms: getFilteredChatRooms(app),
  directMessages: getFilteredDirectMessages(app)
});

const checkHashCode = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.rooms &&
    nextProps.rooms &&
    typeof prevProps.rooms.hashCode === "function" &&
    typeof nextProps.rooms.hashCode === "function" &&
    prevProps.rooms.hashCode() === nextProps.rooms.hashCode()
  );
};

export default connect(mapStateToProps)(memo<Props>(Filtered, checkHashCode));
