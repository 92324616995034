// @flow

import React, { useCallback, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import Icon from "src/icons";
import * as styles from "./styles";

type Props = {
  handleClose: Function
};

const Close = ({ handleClose }: Props) => {
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  return (
    <>
      <IconButton sx={styles.IconButton} onClick={handleClose}>
        <Icon
          type="close"
          color={colors.primary}
          height={16}
          width={16}
          cursor="pointer"
        />
      </IconButton>
    </>
  );
};

export default Close;
