// @flow

import React from "react";

type Props = {
  fill?: string,
  pathFill?: string
};

const CaretLeftFilled = ({ fill, pathFill, ...restProps }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g id="MdKeyboardArrowRight">
      <rect
        x="24"
        y="24"
        width="24"
        height="24"
        rx="12"
        transform="rotate(-180 24 24)"
        fill={fill ? fill : "#1581BA"}
      />
      <path
        id="Vector"
        d="M15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6L15.4102 7.41Z"
        fill={pathFill ? pathFill : "white"}
      />
    </g>
  </svg>
);

export default CaretLeftFilled;
