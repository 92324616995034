// @flow

import React from "react";
import { connect } from "react-redux";

import { getSegmentName } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  title: string
};

const SegmentName = ({ title }: Props) => <>{title}</>;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  title: getSegmentName(app, props.id)
});

export default connect(mapStateToProps)(SegmentName);
