// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import { toast } from "react-toastify";

import { getFormTitle } from "src/reducers";
import { deleteForm, searchForm } from "src/actions/forms";
import * as formApi from "src/api/forms";

type Props = {
  formDeletionModal: { isOpen: boolean, formId?: number },
  setFormDeletionModal: Function
};

const FormDeletionModal = ({
  formDeletionModal,
  setFormDeletionModal
}: Props) => {
  const dispatch = useDispatch();

  const modalShown = formDeletionModal.isOpen;
  const formId = formDeletionModal.formId;

  const formTitle = useSelector(
    ({ app }) => formId && getFormTitle(app, formId)
  );

  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => setFormDeletionModal({ isOpen: false });

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      await formApi.deleteForm(id);
      dispatch(deleteForm(id));
      dispatch(searchForm(""));
      toast.success("Form successfully deleted");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal isCentered onClose={handleClose} isOpen={modalShown}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete form</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete {formTitle}?</ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            colorScheme="red"
            mr={3}
            onClick={() => formId && handleDelete(formId)}
          >
            Delete
          </Button>
          <Button isDisabled={loading} variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormDeletionModal;
