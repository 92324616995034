// @flow

import React from "react";

import MyConversationChart from "./MyConversationChart";
import Conversations from "./Conversations";
import StartNew from "./StartNew";
import DashboardChart from "./DashboardChart";

import type { HomescreenTile } from "src/types";

type Props = {
  tile: HomescreenTile
};

const Tile = ({ tile }: Props) => {
  switch (tile.tileType) {
    case "conversations":
      return <Conversations tile={tile} />;
    case "my_conversation_chart":
      return <MyConversationChart tile={tile} />;
    case "start_new":
      return <StartNew tile={tile} />;
    case "dashboard_chart":
      return <DashboardChart tile={tile} />;
    default:
      return <></>;
  }
};

export default Tile;
