// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import {
  Search as StyledSearch,
  Button,
  ButtonOutline
} from "src/components/Manage/Workflow/Instances/styles";
import { GreyInput } from "src/styles/input";
import useTextChange from "src/hooks/useTextChange";
import { setForm } from "src/actions/forms";
import { showNewReportsModal } from "src/actions/reports";
import Icon from "src/icons";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  _setForm: Function,
  _showNewReportsModal: Function
};

const Search = ({ filter, _setForm, _showNewReportsModal }: Props) => {
  const { text, handleChange, getActive } = useTextChange(filter.chatroomTitle);
  const active = getActive(filter.chatroomTitle);

  const handleSearch = useCallback(
    (event: any) => {
      event.preventDefault();
      _setForm({
        query: {
          ...R.omit(["page"], filter),
          chatroomTitle: text
        }
      });
    },
    [text, filter, _setForm]
  );

  return (
    <StyledSearch onSubmit={handleSearch}>
      <GreyInput
        placeholder="Search"
        type="text"
        value={text}
        onChange={handleChange}
      />
      <Button type="submit" active={active} disabled={!active}>
        Search
      </Button>
      <ButtonOutline
        type="button"
        onClick={() =>
          _showNewReportsModal({
            modalType: "create",
            loading: false,
            reportType: "form"
          })
        }
      >
        <Icon type="saveAs" />
        <span>SAVE AS</span>
      </ButtonOutline>
    </StyledSearch>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.workflow.instanceFilter
});

export default connect(mapStateToProps, {
  _setForm: setForm,
  _showNewReportsModal: showNewReportsModal
})(Search);
