// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import { ButtonOutline as StyledButtonOutline } from "src/components/Manage/Workflow/Instances/styles";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const Header = styled.div`
  font-weight: bold;
  font-size: 12.5px;
  margin-bottom: 0.8em;
`;

export const IconButton = styled.span`
  right: -0.3em;
  background-color: ${colors.brightLight};
  > svg {
    height: 1em;
  }
`;

export const ButtonOutline = styled(StyledButtonOutline)`
  position: relative;
  transition: none;
  min-height: 2.8em;
  min-width: 7.4em;
  > svg {
    height: 1.3em;
  }
  :hover,
  :focus {
    transition: none;
    > button,
    ${IconButton} {
      background-color: ${colors.active};
    }
  }
`;

export const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartList = styled.div`
  z-index: 999;
  position: absolute;
  top: 15%;
  left: 37%;
  right: 37%;
  background-color: ${colors.brightLight};
  min-width: 420px;
  min-height: 2em;
  padding: ${spacing.space_l};
  font-size: 12px;
`;

export const List = styled.div`
  max-height: 25em;
  overflow: auto;
  padding-right: 0.2em;
  margin-top: 0.8em;
`;

export const ChartCell = styled.div`
  border: 1px solid ${colors.backgroundText};
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1em;

  > img {
    width: 90px;
    height: 80px;
    margin: 0.3em;
  }
`;

export const ChartBody = styled.div`
  height: 100%;
  padding-top: 0.5em;
  position: relative;
`;

export const Description = styled.div`
  color: ${colors.secondary};
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 3em;
  padding-right: 0.2em;
`;

export const PinnedTo = styled.div`
  color: ${colors.secondary};
  font-size: 12px
  position: absolute;
  bottom: 0.5em !important;
  > strong {
    color: ${colors.primary};
  }
`;

export const Edit = styled.div`
  > svg {
    margin-left: 0.5em;
    height: 1em;
    width: 1em;
  }
`;

export const Delete = styled(Edit)`
  margin-left: 0.6em;
  > svg {
    height: 1.25em;
    width: 1.25em;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
  display: flex;
`;

export const FooterButton = styled(StyledButtonOutline)`
  width: 100% !important;
  text-align: center;
  justify-content: center;
  text-transform: none;
`;

export const CloseButton = styled.button`
  outline: none;
  background-color: ${colors.brightLight};
  border: none;
  float: right;
  > svg {
    height: 1em;
    width: 1em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
    }
  }
`;

export const Report = styled.div`
  display: flex;
  align-items: center;
  width: 98%;
  > div {
    padding-left: 0.4em;
    width: 83%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Title = styled.strong`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 17em;
`;

export const IconWrapper: SystemCSSProperties = {
  alignItems: "center",
  position: "absolute",
  top: "6px",
  right: "12px",
  gap: "1rem",
  h: "1rem"
};

export const Icon: SystemCSSProperties = {
  minW: "auto",
  bg: "none",
  _hover: {
    bg: "none"
  },
  _active: {
    bg: "none"
  }
};
