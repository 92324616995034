// @flow

import React from "react";

import { forbiddenFieldTypes } from "./utils";

import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  HStack,
  IconButton,
  Checkbox
} from "@chakra-ui/react";
import Button from "src/components/Unifize/Button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import * as styles from "./styles";

import type { DataStage } from "src/types";

type Props = {
  checklistFields: Object,
  fieldsToCopy: Object,
  onFieldSelect: Function,
  open: boolean,
  onClose: Function,
  onSuccess: Function,
  onBack: Function,
  fieldValueStatus: DataStage
};

export default function ChecklistFieldsSelectionModal({
  checklistFields,
  fieldsToCopy,
  onFieldSelect,
  open,
  onClose,
  onSuccess,
  onBack,
  fieldValueStatus
}: Props) {
  return (
    <Modal isOpen={open} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalHeader {...styles.modalHeader}>
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={onBack}
            {...styles.backIcon}
          />
          Select checklist fields
        </ModalHeader>

        <ModalBody {...styles.modalBody}>
          {Object.keys(fieldsToCopy).map(fieldId => (
            <Checkbox
              id={fieldId}
              key={fieldId}
              isDisabled={forbiddenFieldTypes.includes(
                checklistFields[fieldId].type
              )}
              isChecked={fieldsToCopy[fieldId]}
              onChange={onFieldSelect}
            >
              {checklistFields[fieldId].label}
            </Checkbox>
          ))}
        </ModalBody>

        <HStack {...styles.modalFooter}>
          <Button
            isLoading={fieldValueStatus === 1 || fieldValueStatus === 3}
            variant="uBlueFilled"
            handleClick={onSuccess}
          >
            Create version
          </Button>
          <Button variant="uSecondary" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
}
