// @flow

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dropdown as StyledDropdown } from "../styles";
import { ListItem, Name, Header, MenuBar, DeleteButton } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPosition from "src/hooks/useAutoPosition";
import { updateSection } from "src/actions/orgSettings";
import { Input } from "src/styles/input";
import { Capitalize } from "src/styles";
import Icons from "src/icons";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";

import type { AppState } from "src/types";

type Props = {
  id: number,
  tiles: Array<number>,
  outerRef: any,
  tileById: Object,
  tileTypes: Object,
  setModal: Function,
  handleClose: Function,
  _setHomeScreenBuilderAttributes: Function,
  _updateSection: Function
};

const Dropdown2 = ({
  id,
  tiles,
  _updateSection,
  tileById,
  tileTypes,
  outerRef,
  handleClose,
  setModal,
  _setHomeScreenBuilderAttributes
}: Props) => {
  const dropdownRef = useRef(null);
  const [search, setSearch] = useState("");
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "3em",
    initialBottom: "auto",
    topOffset: "3em",
    bottomOffset: "3em"
  });
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  const handleDropdown = (event: any, tileId: string) => {
    event.preventDefault();
    event.stopPropagation();
    _updateSection({
      id,
      tiles: R.uniq([...tiles, tileId])
    });

    handleClose();
  };

  const handleSearch = useCallback(
    (event: any) => {
      const newValue = event.target.value;
      setSearch(newValue);
    },
    [setSearch]
  );

  const handleDelete = useCallback(
    (event: any, tile: Object) => {
      event.preventDefault();
      event.stopPropagation();
      setModal(tile);
    },
    [setModal]
  );

  const handleEdit = useCallback(
    (event: any, { id, tile }: { id: number, tile: Object }) => {
      event.preventDefault();
      event.stopPropagation();
      _setHomeScreenBuilderAttributes({
        ...tile.settings,
        title: tile.title,
        subTitle: tile.subTitle,
        tileTypeId: tile.tileTypeId,
        tileType: tileTypes[tile.tileTypeId],
        edit: true,
        loading: false,
        show: true,
        id
      });
    },
    [tileTypes, _setHomeScreenBuilderAttributes]
  );
  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown top={top} bottom={bottom} ref={dropdownRef}>
        <Input
          placeholder="Search tiles"
          autoFocus
          value={search}
          onChange={handleSearch}
        />
        <Header>
          <Name>NAME</Name>
          <div>TYPE</div>
        </Header>
        <ul>
          {Object.values(tileById || {})
            .filter(
              (tile: Object) =>
                R.includes(R.toLower(search), R.toLower(tile.title)) &&
                !R.includes(tile.id, tiles) &&
                (tile.title || "").length > 0
            )
            .sort((a: Object, b: Object): number => {
              const title1 = R.toLower(a.title || "");
              const title2 = R.toLower(b.title || "");
              if (title1 < title2) {
                return -1;
              }
              if (title1 > title2) {
                return 1;
              }
              return 0;
            })
            .map((tile: Object) => (
              <ListItem
                key={tile.id}
                tabIndex="0"
                onClick={event => handleDropdown(event, tile.id)}
                onKeyDown={event => handleDropdown(event, tile.id)}
              >
                <Name>{tile.title}</Name>
                <div>
                  <Capitalize>
                    {(tile.tileType || "").split("_").join(" ")}
                  </Capitalize>
                </div>
                <MenuBar>
                  <DeleteButton
                    type="button"
                    onClick={(event: any) =>
                      handleEdit(event, { id: tile.id, tile })
                    }
                  >
                    <Icons type="editPencil" />
                  </DeleteButton>

                  <DeleteButton
                    type="button"
                    onClick={event => handleDelete(event, tile)}
                  >
                    <Icons type="newDelete" />
                  </DeleteButton>
                </MenuBar>
              </ListItem>
            ))}
        </ul>
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  tileById: app.orgSettings.tileById,
  tileTypes: app.orgSettings.tileTypes
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes,
  _updateSection: updateSection
})(Dropdown2);
