// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import SingleFile from "src/components/Dock/Checklist/File/Single";
import LoadingState from "src/components/LoadingState";
import { getFile as resolveFile, unpinFile } from "src/actions/file";
import { getFile } from "src/reducers";

import type { RoomId, FileName } from "src/types";
import appLocation from "src/constants/location";

type Props = {
  roomId: RoomId,
  fileId: FileName
};

const File = ({ roomId, fileId }: Props) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const fetchedFile = useSelector(({ app }) => getFile(app, fileId));

  useEffect(() => {
    if (!fetchedFile) {
      dispatch(resolveFile(fileId));
      setFetching(true);
    } else {
      setFetching(false);
    }
  }, [fetching, fetchedFile]);

  const deleteFile = useCallback(() => {
    dispatch(unpinFile(fileId));
  }, [fileId]);

  if (fetching) return <LoadingState type="checklistField" />;
  else {
    return (
      <>
        {fetchedFile && (
          <SingleFile
            roomId={roomId}
            key={fetchedFile.name}
            originalName={fetchedFile.originalName}
            handleRemove={() => console.log()}
            handleDelete={deleteFile}
            file={fetchedFile}
            preview={true}
            disabled={false}
            location={appLocation.filesModal}
          />
        )}
      </>
    );
  }
};

export default File;
