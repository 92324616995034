// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import {
  Container as SelectContainer,
  Selection,
  Label as SelectLabel,
  DropdownMenu,
  DropdownItem,
  ItemTitle
} from "src/components/Unifize/Select/MultiSelect/styles";

const ToggleButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.grey35};
  border-radius: ${radius.medium};
  height: 2rem;
  padding: 0.375rem;
  background-color: white;
`;

const Placeholder = styled.span`
  font-size: 14px;
  color: ${props => (props.isSelected ? colors.primary : colors.grey5)};
`;

const ArrowIcon = styled.div`
  height: 24px;
  width: 24px;
`;

export {
  SelectContainer as Container,
  Selection,
  SelectLabel as Label,
  Placeholder,
  ToggleButton,
  DropdownMenu as Menu,
  DropdownItem as MenuItem,
  ItemTitle,
  ArrowIcon
};
