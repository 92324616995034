// @flow

import React from "react";
import { HStack, VStack, Box } from "@chakra-ui/react";
import Icon from "src/icons";

type Props = {
  children: React$Node,
  iconType: string,
  iconSize?: string
};

const ToastTemplate = ({ children, iconType }: Props) => {
  return (
    <HStack gap={2}>
      <Box>
        <Icon type={iconType} fill="#fff" />
      </Box>
      <VStack alignItems={"flex-start"}>{children}</VStack>
    </HStack>
  );
};

export default ToastTemplate;
