// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";

import Instances from "./Instances";
import Templates from "./Templates";
import { getUniqueValues } from "src/actions/workflows";
import { getInstanceFilterId } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  id: ?number,
  _getUniqueValues: Function
};

const Workflow = ({ id, _getUniqueValues }: Props) => {
  useEffect(() => {
    if (id) {
      _getUniqueValues({ workflow: id });
    }
  }, [id, _getUniqueValues]);

  if (id) {
    return <Instances />;
  }

  return <Templates />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: getInstanceFilterId(app)
});

export default connect(mapStateToProps, {
  _getUniqueValues: getUniqueValues
})(Workflow);
