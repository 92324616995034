// @flow

import React, { useState, useEffect } from "react";

import useTimeout from "src/hooks/useTimeout";
import { FilePreviewContainer } from "../styles";
import {
  ImagePreview,
  FilePreview as StyledFilePreview
} from "src/styles/Notification";
import { Loader } from "src/styles/auth";

type Props = {
  preview: ?string
};

// Number of times to retry setting the preview
const MAXIMUM_TRIES = 4;

const FilePreview = ({ preview }: Props) => {
  const [previewURL, setPreviewURL] = useState("");
  const [noOftries, setNoOfTries] = useState(0);
  const [abortTimeout, setAbortTimeout] = useState(true);

  useEffect(() => {
    setPreviewURL(preview);
  }, [preview]);

  useTimeout(
    () => {
      setPreviewURL(preview);
      setAbortTimeout(true);
    },
    abortTimeout ? null : 3000
  );

  // Sometimes the preview URL returns 404
  // Handling it by trying to load the image
  // again after 3 seconds
  const handleError = () => {
    if (noOftries <= MAXIMUM_TRIES) {
      setPreviewURL("");
      setNoOfTries(noOftries + 1);
      // Start the timeout to set preview URL
      setAbortTimeout(false);
    }
  };

  if (preview) {
    return (
      <FilePreviewContainer>
        <StyledFilePreview>
          <ImagePreview src={previewURL} onError={handleError} />
          {!previewURL && <Loader />}
        </StyledFilePreview>
      </FilePreviewContainer>
    );
  }

  return <></>;
};

export default FilePreview;
