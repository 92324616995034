// @flow

import { connect } from "react-redux";
import React from "react";

import { getForm } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  title: string
};

const Name = ({ title }: Props) => <>{title}</>;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  title: (getForm(app, props.id) || {}).title
});

export default connect(mapStateToProps)(Name);
