// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const rehydration = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.REHYDRATION_COMPLETE:
      return true;
    default:
      return state;
  }
};

export default rehydration;
