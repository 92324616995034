// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { Overlay as StyledOverlay } from "src/styles/modal";

const Modalwrapper = styled.div`
  border-radius: ${radius.medium};
  background: ${colors.brightLight};
  padding: ${spacing.space_l};
  min-width: 308px;
  min-height: 82px;
  overflow: auto;
  > div:nth-of-type(2) {
    margin-bottom: 0px;
  }
`;

const Overlay = styled(StyledOverlay)`
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Modalwrapper, Overlay };
