// @flow

import React from "react";

type Props = {
  color?: string
};

const Refresh = ({ color, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <g clipPath="url(#clip0_4222_19712)">
      <path
        d="M13.6485 2.35C12.1976 0.9 10.2064 0 7.995 0C3.57223 0 0 3.58 0 8C0 12.42 3.57223 16 7.995 16C11.7273 16 14.8393 13.45 15.7298 10H13.6485C12.828 12.33 10.6066 14 7.995 14C4.68293 14 1.99124 11.31 1.99124 8C1.99124 4.69 4.68293 2 7.995 2C9.65603 2 11.137 2.69 12.2176 3.78L8.99562 7H16V0L13.6485 2.35Z"
        fill={color || "#50B83C"}
      />
    </g>
    <defs>
      <clipPath id="clip0_4222_19712">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Refresh;
