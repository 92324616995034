// @flow

import React from "react";

type Props = {
  title: string,
  children?: any
};

const Name = ({ title, children = null }: Props) => (
  <>
    {title}
    {children}
  </>
);

export default Name;
