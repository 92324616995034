// @flow

import React from "react";
import * as R from "ramda";
import { SortableElement, SortableHandle } from "react-sortable-hoc";

import { Tag, ListItem } from "./styles";
import Icon from "src/components/Icon";
import * as colors from "src/styles/constants/colors";

type Props = {
  column: string,
  processLabels: Object,
  fields: Object,
  clearSort: Function
};

const DragHandle = SortableHandle(() => <Icon icon="draggable" />);

const SortableItem = SortableElement(
  ({ column, processLabels, fields, clearSort }: Props) => {
    const id = R.head((column || "").split(":") || "");
    return (
      <ListItem>
        <Tag>
          <span>{isNaN(id) ? processLabels[id] : fields[id]}:</span>
          {R.includes("asc", column) ? (
            <strong>Ascending</strong>
          ) : (
            <strong>Descending</strong>
          )}
          <Icon
            icon="close"
            color={colors.active}
            onClick={() => clearSort(column)}
          />
        </Tag>
        <DragHandle />
      </ListItem>
    );
  }
);

export default SortableItem;
