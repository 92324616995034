// @flow

import React from "react";
import * as colors from "src/styles/constants/colors";
type Props = {
  timeline?: string
};

const DateActive = ({ timeline, ...restProps }: Props) => {
  let fill = "";

  switch (timeline) {
    case "future":
      fill = colors.active;
      break;
    case "past":
      fill = colors.danger;
      break;
    case "present":
      fill = colors.success;
      break;
    default:
      fill = colors.active;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <g clipPath="url(#clip0_1744_13177)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.888916 5.19501C0.888916 4.93368 1.10136 4.72212 1.36269 4.72212H14.6374C14.7011 4.71936 14.7648 4.72953 14.8245 4.75202C14.8843 4.77452 14.9388 4.80887 14.9849 4.85302C15.031 4.89716 15.0677 4.95018 15.0928 5.00888C15.1178 5.06758 15.1307 5.13074 15.1307 5.19457C15.1307 5.2584 15.1178 5.32156 15.0928 5.38026C15.0677 5.43896 15.031 5.49198 14.9849 5.53612C14.9388 5.58026 14.8843 5.61462 14.8245 5.63711C14.7648 5.65961 14.7011 5.66978 14.6374 5.66701H1.36269C1.30059 5.66713 1.23908 5.65501 1.18166 5.63136C1.12424 5.6077 1.07204 5.57297 1.02805 5.52914C0.984056 5.48531 0.949125 5.43324 0.925252 5.37591C0.90138 5.31858 0.889033 5.25711 0.888916 5.19501Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.888916 3.30556C0.888916 2.52334 1.52625 1.88867 2.31114 1.88867H13.6889C14.4738 1.88867 15.1111 2.52334 15.1111 3.30556V13.694C15.1111 14.4762 14.4738 15.1109 13.6889 15.1109H2.31114C1.52625 15.1109 0.888916 14.4762 0.888916 13.694V3.30556ZM2.31114 2.83356C2.18595 2.83356 2.06584 2.88311 1.97707 2.97138C1.88829 3.05966 1.83807 3.17948 1.83736 3.30467V13.694C1.83736 13.9544 2.0498 14.166 2.31114 14.166H13.6889C13.8141 14.166 13.9342 14.1165 14.023 14.0282C14.1118 13.9399 14.162 13.8201 14.1627 13.6949V3.30467C14.1622 3.17933 14.1121 3.05928 14.0233 2.97081C13.9345 2.88234 13.8143 2.83267 13.6889 2.83267L2.31114 2.83356Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.20807 0C4.4694 0 4.68184 0.211556 4.68184 0.472V2.36089C4.68184 2.48666 4.63188 2.60728 4.54295 2.69621C4.45401 2.78515 4.33339 2.83511 4.20762 2.83511C4.08185 2.83511 3.96123 2.78515 3.87229 2.69621C3.78336 2.60728 3.7334 2.48666 3.7334 2.36089V0.472C3.7334 0.211556 3.94673 0 4.20807 0ZM11.793 0C12.0543 0 12.2667 0.211556 12.2667 0.472V2.36089C12.2667 2.48666 12.2168 2.60728 12.1278 2.69621C12.0389 2.78515 11.9183 2.83511 11.7925 2.83511C11.6667 2.83511 11.5461 2.78515 11.4572 2.69621C11.3682 2.60728 11.3183 2.48666 11.3183 2.36089V0.472C11.3183 0.211556 11.5316 0 11.793 0ZM8.00007 0C8.26229 0 8.47384 0.211556 8.47384 0.472V2.36089C8.47384 2.48654 8.42393 2.60705 8.33508 2.6959C8.24623 2.78475 8.12572 2.83467 8.00007 2.83467C7.87441 2.83467 7.7539 2.78475 7.66505 2.6959C7.5762 2.60705 7.52629 2.48654 7.52629 2.36089V0.472C7.52629 0.211556 7.73873 0 8.00007 0Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_13177">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DateActive;
