// @flow

import React from "react";

const SaveAlt = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 8.995V13.6617H3.33333V8.995H2V13.6617C2 14.395 2.6 14.995 3.33333 14.995H12.6667C13.4 14.995 14 14.395 14 13.6617V8.995H12.6667ZM8.66667 9.44166L10.3933 7.72166L11.3333 8.66166L8 11.995L4.66667 8.66166L5.60667 7.72166L7.33333 9.44166V2.995H8.66667V9.44166Z"
      fill="#2F80ED"
    />
  </svg>
);

export default SaveAlt;
