// @flow

import { useSelector } from "react-redux";
import React from "react";

import { getRoleTitle } from "src/reducers";

type Props = {
  roleId: number
};

const Title = ({ roleId }: Props) => {
  const roleTitle = useSelector(({ app }) => getRoleTitle(app, roleId));

  return <span>{roleTitle}</span>;
};

export default Title;
