// @flow

import { schema } from "normalizr";
import { systemRolesDispMap } from "src/constants/roleManagement";

export const chatroom = new schema.Entity("chatrooms");
export const arrayOfChatrooms = [chatroom];

export const message = new schema.Entity("message");
export const arrayOfMessages = [message];

export const user = new schema.Entity("user");

export const unifizeUser = new schema.Entity(
  "users",
  {},
  { idAttribute: "uid" }
);
export const arrayOfUnifizeUsers = [unifizeUser];

export const profile = new schema.Entity("profile");
export const arrayOfProfiles = [profile];

export const member = new schema.Entity("member");
export const arrayOfMembers = [member];

export const chatroomRef = new schema.Entity("chatroomRef");

export const group = new schema.Entity("group");
export const arrayOfGroups = [group];

export const roomGroup = new schema.Entity("roomGroup");
export const arrayOfRoomGroups = [roomGroup];

export const chatroomQuery = new schema.Entity("chatroomQuery");
export const arrayOfChatRoomQueries = [chatroomQuery];

export const workflow = new schema.Entity("workflows");
export const arrayOfWorkflows = [workflow];

export const file = new schema.Entity("files", {}, { idAttribute: "name" });
export const arrayOfFiles = [file];

export const folder = new schema.Entity("folders");

export const arrayOfFolder = { folders: [folder], files: [file] };

// ROLE MANAGEMENT
const resourcePermission = new schema.Entity(
  "resourcePermissions",
  {},
  {
    processStrategy: value => {
      return {
        id: value.id,
        resourceId: value["resourceId"],
        resourceGroupId: value["resourceGroupId"],
        verbId: value["verbId"],
        verb: value["verb"],
        resourceName: value["resourceName"]
      };
    }
  }
);

const componentPermission = new schema.Entity(
  "componentPermissions",
  {},
  {
    processStrategy: value => {
      return {
        id: value.id,
        componentId: value["componentId"],
        componentName: value["componentName"],
        inverted: value["inverted"]
      };
    }
  }
);

const role = new schema.Entity(
  "roles",
  {
    resourcePermissions: [resourcePermission],
    componentPermissions: [componentPermission]
  },
  {
    processStrategy: value => {
      const resourcePermissions = [];
      const componentPermissions = [];

      value.permissions.forEach(permission => {
        if (
          !(permission["resource-id"] || permission["resourceId"]) &&
          !(permission["component-id"] || permission["componentId"])
        )
          return;

        if (permission["resource-id"] || permission["resourceId"]) {
          resourcePermissions.push({
            ...permission,
            id: `${
              (permission["resource-id"] || permission["resourceId"]) +
              "-" +
              (permission["verb-id"] || permission["verbId"])
            }`
          });
        }

        if (permission["component-id"] || permission["componentId"]) {
          componentPermissions.push({
            ...permission,
            id: `${permission["component-id"] || permission["componentId"]}`
          });
        }
      });

      return {
        id: value.id,
        members: value.members.map(member => member.uid),
        systemRole: value["system-role"] || value["systemRole"],
        originalTitle: value.title,
        title: systemRolesDispMap[value.title] || value.title,
        resourcePermissions,
        componentPermissions
      };
    }
  }
);

export const roles = [role];
