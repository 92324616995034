// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {};

const TrendlineChart = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <path
        d="M13.419 14H2.18665V2"
        stroke={colors.black300}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        {...restProps}
      />
      <line
        x1="0.3"
        y1="-0.3"
        x2="13.4523"
        y2="-0.3"
        transform="matrix(0.999835 -0.0181788 0.0109099 0.99994 0.25 6)"
        stroke="black"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeDasharray="1 1"
        {...restProps}
      />
    </svg>
  );
};

export default TrendlineChart;
