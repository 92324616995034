// @flow

import * as atypes from "src/constants/actionTypes";
import type { Action, UID } from "src/types";

export const getAllRoles = (): Action => ({
  type: atypes.GET_ALL_ROLES_REQUEST,
  payload: {}
});

export const addMemberToRole = (
  id: number,
  members: Array<string>
): Action => ({
  type: atypes.ADD_ROLE_MEMBER_REQUEST,
  payload: {
    id,
    members
  }
});

export const removeMemberFromRole = (id: number, uid: UID): Action => ({
  type: atypes.REMOVE_ROLE_MEMBER_REQUEST,
  payload: {
    id,
    uid
  }
});

export const getAllPermissions = (): Action => ({
  type: atypes.GET_ALL_PERMISSIONS_REQUEST,
  payload: {}
});

export const assignResourcePermissionToRole = (
  id: number,
  rid: number,
  vid: number
): Action => ({
  type: atypes.SET_RESOURCE_PERMISSION_REQUEST,
  payload: {
    id,
    rid,
    vid
  }
});

export const removeResourcePermissionToRole = (
  id: number,
  rid: number,
  vid: number
): Action => ({
  type: atypes.REMOVE_RESOURCE_PERMISSION_REQUEST,
  payload: {
    id,
    rid,
    vid
  }
});

export const assignComponentPermissionToRole = (
  id: number,
  cid: number
): Action => ({
  type: atypes.SET_COMPONENT_PERMISSION_REQUEST,
  payload: { id, cid }
});

export const removeComponentPermissionToRole = (
  id: number,
  cid: number
): Action => ({
  type: atypes.REMOVE_COMPONENT_PERMISSION_REQUEST,
  payload: { id, cid }
});

export const addCustomRole = (title: string): Action => ({
  type: atypes.ADD_CUSTOM_ROLE_REQUEST,
  payload: {
    title
  }
});

export const deleteCustomRole = (id: number): Action => ({
  type: atypes.DELETE_CUSTOM_ROLE_REQUEST,
  payload: {
    id
  }
});

export const updateCustomRole = (id: number, updatedTitle: string): Action => ({
  type: atypes.UPDATE_CUSTOM_ROLE_REQUEST,
  payload: {
    id,
    updatedTitle
  }
});

export const loadComponentPermissions = (snapshot: Object) => ({
  type: atypes.LOAD_COMPONENT_PERMISSIONS,
  payload: { snapshot }
});

export const searchRoles = (searchQuery: string) => ({
  type: atypes.SEARCH_ROLES,
  payload: { searchQuery }
});
