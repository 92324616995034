// @flow

import React from "react";

const DashboardDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 33H31V23H23V33ZM23 41H31V35H23V41ZM33 41H41V31H33V41ZM33 23V29H41V23H33Z"
      fill="#2F80ED"
    />
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
  </svg>
);

export default DashboardDark;
