// @flow

import React from "react";
import * as R from "ramda";

import { FileReply, FileInfo } from "./styles";
import Icon from "src/components/InlineSVG";

import { getFileType, getFileSize } from "src/utils/file";

import { size } from "src/styles/constants";

import type { UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile
};

const File = ({ file }: Props) => (
  <FileReply>
    <Icon size={size.iconSize.xlarge} icon={getFileType(file?.originalName)} />
    <FileInfo>
      <b>{file?.originalName}</b>
      <div>
        {file &&
          getFileSize(parseInt((R.last(file.versions) || {}).size || 0, 10))}
      </div>
    </FileInfo>
  </FileReply>
);

export default File;
