// @flow

import styled from "styled-components";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

const red25 = "#fffbfa";
const red100 = "#fee4e2";
const red500 = "#d92d20";

const Container = styled.div`
  width: 100%;
  background-color: ${red25};
  border-radius: ${radius.small};
  border: 1px solid ${red100};
  height: 36px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  padding: ${spacing.space_m};
`;

const Label = styled.label`
  font-size: 14px;
  color: ${red500};
  width: fit-content;
`;

const Info = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border: 1px solid ${red500};
  color: ${red500};
`;

export { Container, Label, Info };
