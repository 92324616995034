// @flow

import transit from "transit-js";
import * as R from "ramda";

import fieldType from "src/transit/checklist/field/types";
import fieldWriter from "src/transit/checklist/field/writer";
import fieldReader from "src/transit/checklist/field/reader";
import { backendUrl } from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";

import type { Form } from "src/types";

/**
 * Create a new form
 *
 * @param {Form} form - form builder details
 * @returns {Object} new form details
 */
export const createForm = async (form: Form) => {
  const {
    title,
    fields,
    settings: { color },
    owners
  } = form;

  const encodedFields = R.map(item => ({
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.fieldType],
          transit.makeWriteHandler(fieldWriter[item.fieldType])
        ])
      })
      .write(
        new fieldType[item.fieldType]({
          label: item.fieldName,
          isChecked: item.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item.promptRules
  }))(fields);

  const response = await fetch(`${backendUrl}/checklist`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      title,
      type: "form",
      settings: { color },
      owners,
      fields: encodedFields
    })
  });
  return response.json();
};

/*
 Delete a form
*/
export const deleteForm = (formId: number) =>
  easyFetch._delete(`/checklist/${formId}`);

/**
 * Get form by id
 *
 * @param {number} id - form id
 * @returns {Form}  form details of provided id
 */
export const getFormById = async (id: number) => {
  const response = await fetch(`${backendUrl}/checklist/${id}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  const formData = await response.json();
  const reader = transit.reader("json", { handlers: fieldReader });
  const fields = await R.map(
    field => ({
      ...reader.read(field.attrs),
      id: field.id,
      promptRules: field.promptRules
    }),
    formData.fields
  );

  return { ...formData, fields, id };
};

/**
 * updates the form
 *
 * @param {Object} form - values of form to be updated
 * @returns {Object}  updated form values
 */
export const updateForm = async (form: Form) => {
  const {
    title,
    fields,
    settings: { color },
    owners,
    id
  } = form;

  const encodedFields = R.map(item => ({
    id: item.fieldId,
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.fieldType],
          transit.makeWriteHandler(fieldWriter[item.fieldType])
        ])
      })
      .write(
        new fieldType[item.fieldType]({
          label: item.fieldName,
          isChecked: item.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item.promptRules
  }))(fields);

  await fetch(`${backendUrl}/checklist/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      title,
      settings: { color },
      owners,
      fields: encodedFields
    })
  });
  return { title, fields, id };
};

/**
 * get list of all forms
 *
 * @returns {Array<Form>}  list of all saved forms
 */
export const getAllFormTemplates = async () => {
  const response = await fetch(`${backendUrl}/form`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Get Form instances for given form templates
 *
 * @param  {id} form Id
 * @param  {filter} filters applied
 * @return {Array} return array of form instances
 */
export const getFormInstances = async ({
  id,
  filter
}: {
  id: number,
  filter: string
}) => {
  const url = `${backendUrl}/form/${id}${filter}`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getUniqFormInstanceValues = async ({
  formId
}: {
  formId: number
}) => {
  const url = `${backendUrl}/form/${formId}/unique`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

export const downloadProcessInstance = async ({
  filename,
  formId,
  filter
}: {
  filename: string,
  formId: number,
  filter: string
}) => {
  const response = await fetch(
    `${backendUrl}/form/${formId}/download?${filter}`
  );
  const blob = await response.blob();
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.setAttribute("download", filename);
  a.click();
};
