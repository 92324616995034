// @flow

import React from "react";

import { Loader as StyledLoader } from "./styles";
import ButtonLoader from "src/components/ButtonLoader";

const Loader = () => {
  return (
    <StyledLoader>
      <ButtonLoader type="button" isLoading />
    </StyledLoader>
  );
};

export default Loader;
