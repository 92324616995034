// @flow

import React, { useRef } from "react";

import { NoValue } from "../styles";
import Options from "./Options";
import { Small as StyledOwner } from "src/components/chatroom/Owner/styles";
import ImageLabel from "src/containers/user/ImageLabel/Small";
import { RelativeFlexBox } from "src/styles/box";
import useBoolean from "src/hooks/useBoolean";

import type { UID, ColumnId } from "src/types";

type Props = {
  roomId: number,
  value: ?UID,
  depth: number,
  index: number,
  parentId: ?number,
  selected: boolean,
  columnId: ?ColumnId,
  labelWidth?: string,
  embedded: ?boolean
};

const Single = ({
  value,
  roomId,
  depth,
  index,
  parentId,
  selected,
  columnId,
  labelWidth,
  embedded
}: Props) => {
  const outerRef = useRef(null);
  const {
    value: dropdown,
    toggleBoolean: handleDropdown,
    setFalse: handleClose
  } = useBoolean(false);

  // $FlowFixMe
  const ownerLabelWidth = parseInt(labelWidth?.split("px")[0], 10) - 8 + "px";

  return (
    <RelativeFlexBox ref={outerRef} onClick={handleDropdown}>
      <StyledOwner maxWidth={ownerLabelWidth}>
        {value ? (
          <ImageLabel uid={value} />
        ) : (
          <NoValue
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            {embedded ? "" : "Not Assigned"}
          </NoValue>
        )}
      </StyledOwner>

      {dropdown ? (
        <Options
          value={value}
          roomId={roomId}
          index={index}
          depth={depth}
          parentId={parentId}
          outerRef={outerRef}
          handleClose={handleClose}
          selected={selected}
          columnId={columnId}
        />
      ) : null}
    </RelativeFlexBox>
  );
};

export default Single;
