import * as R from "ramda";
import { normalize } from "normalizr";

import normalizers from "src/normalizers/checklistFieldValue";
import { fieldTransitReader } from "src/transit/checklist/field/reader";

export const defaultFieldValues = {
  file: [],
  pdf: [],
  text: null,
  number: null,
  date: null,
  select: [],
  user: [],
  task: [],
  decision: [],
  group: [],
  workflow: [],
  conversation: [],
  chatPickList: [],
  childConversation: [],
  form: [],
  approval: {
    lockChecklistFields: false,
    config: null,
    completed: false,
    revisions: [],
    status: "not-started"
  },
  revision: [],
  link: []
};

const conversationFieldsFormatter = fieldValue => ({
  val: {
    value: R.pluck("id")(fieldValue.value || [])
  }
});

const formatters = {
  user: fieldValue => {
    const uids = R.reject(R.isNil, R.pluck("uid", fieldValue.value || []));
    const groupIds = R.reject(R.isNil, R.pluck("id", fieldValue.value || []));
    const value = R.concat(uids, groupIds);

    return {
      val: { value }
    };
  },
  conversation: conversationFieldsFormatter,
  chatPickList: conversationFieldsFormatter,
  group: conversationFieldsFormatter,
  task: conversationFieldsFormatter,
  workflow: conversationFieldsFormatter,
  childConversation: conversationFieldsFormatter,
  form: fieldValue => ({
    val: {
      value: R.pluck("id")(fieldValue.value || [])
    }
  }),
  link: fieldValue => ({
    value: normalize(fieldValue.value, normalizers.link)
  })
};

// MAIN formatter for all field types
export const formatChecklistFieldValue = fieldValue => {
  try {
    const fieldTransit = fieldTransitReader.read(fieldValue.type);
    const fieldType = fieldTransit.type;
    const formatter = formatters[fieldType];

    const defaultValue = R.mergeDeepRight(fieldValue, {
      target: fieldValue.target || false,
      val: {
        type: fieldType
      }
    });

    if (!fieldValue.value || !formatter) return defaultValue;
    return R.mergeDeepRight(defaultValue, formatter(fieldValue));
  } catch (error) {
    console.error("format field value", error);
    return fieldValue;
  }
};

export const formatManageViewFieldValue = (fieldValue, fieldType) =>
  fieldValue && fieldType && normalizers[fieldType]
    ? normalize(fieldValue, normalizers[fieldType])
    : fieldValue;
