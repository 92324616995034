// @flow

const options = [
  { key: "seqNo", active: true },
  { key: "chatroomId", active: true },
  { key: "templateId", active: true },
  { key: "createdBy", active: false },
  { key: "creator", active: true }
];

export const size = {
  seqNo: "50px",
  title: "280px",
  priority: "67px",
  status: "110px",
  dueDate: "100px",
  parent: "220px",
  childCount: "80px",
  createdAt: "160px",
  updatedAt: "160px",
  completedAt: "160px",
  completedBy: "160px",
  age: "80px",
  creator: "160px",
  owner: "160px",
  members: "200px",
  description: "230px"
};

export const checklistFieldSizeMap = {
  text: "230px",
  number: "80px",
  date: "160px",
  user: "160px",
  conversation: "220px",
  childConversation: "220px",
  select: "120px",
  pdf: "80px",
  file: "80px",

  // Old checklist types
  chatPickList: "220px",
  workflow: "220px",
  task: "220px",
  group: "220px",
  approval: "220px"
};

export const omitedFields = ["file", "pdf", "section", "subSection", "form"];
export default options;
