// @flow

import { connect, useDispatch } from "react-redux";
import React, { useRef, useCallback } from "react";
import { Line } from "rc-progress";

import { Upload as StyledUpload, Name as StyledName } from "./styles";
import { ButtonLink } from "src/styles/buttons";
import { uploadFileInDropdown } from "src/actions/file";
import * as colors from "src/styles/constants/colors";

import type { AppState } from "src/types";

type Props = {
  name: ?string,
  progress: number,
  error: boolean,
  _uploadFileInDropdown: Function
};

const Upload = ({ progress, name, error, _uploadFileInDropdown }: Props) => {
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileSelector = useCallback(() => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  }, [fileRef]);

  const handleUpload = useCallback(
    (event: any) => {
      const fileData = event.target.files[0];
      _uploadFileInDropdown(fileData, dispatch);
    },
    [dispatch]
  );

  const generateColor = (progress, error) => {
    if (error) {
      return colors.danger;
    }
    if (progress <= 20) return colors.warning;
    if (progress <= 80) return colors.active;
    return colors.success;
  };

  if (progress !== null) {
    return (
      <StyledUpload>
        <StyledName>
          Uploading <strong>{name}</strong>
          <span>{isNaN(progress) ? 0 : Number(progress).toFixed(0)}%</span>
        </StyledName>
        <Line percent={progress} strokeColor={generateColor(progress, error)} />
      </StyledUpload>
    );
  }

  return (
    <StyledUpload tabIndex="0" onClick={handleFileSelector}>
      <ButtonLink type="button">
        <input
          type="file"
          id="dropdownFileUpload"
          ref={fileRef}
          onChange={handleUpload}
        />
        Upload File
      </ButtonLink>
    </StyledUpload>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  progress: app.files.dropdownFileUpload.progress,
  error: app.files.dropdownFileUpload.error,
  name: app.files.dropdownFileUpload.name
});

export default connect(mapStateToProps, {
  _uploadFileInDropdown: uploadFileInDropdown
})(Upload);
