// @flow

import { backendUrl } from "src/config/firebase";
import type { EmailApiPayload } from "src/types";
import * as easyFetch from "src/utils/fetch";

/**
  send email and choose roles for non org members
 */
export const sendEmail = ({ chatroomId, payload }: EmailApiPayload) =>
  easyFetch.post(`/chatroom/${chatroomId}/email`, {
    body: payload
  });

/**
 * This creates an email address for the given chatroom
 * @param chatroomId - Chatroom ID
 */
export const generateChatroomEmail = async (chatroomId: number) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${chatroomId}/email-address`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );
  return response.text();
};
