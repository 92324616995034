// @flow

export const breakpoints = Object.freeze({
  small: ("small": "small"),
  medium: ("medium": "medium"),
  large: ("large": "large")
});

export const breakpointMaxWidths = {
  [breakpoints.small]: 1,
  [breakpoints.medium]: 2,
  [breakpoints.large]: 3
};
