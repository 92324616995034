// @flow

import * as React from "react";

type Props = {
  fill?: string
};

const Section = ({ fill, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...restProps}
  >
    <g
      clipPath="url(#a)"
      stroke={fill || "#D53F8C"}
      strokeWidth={4}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27 1H5v10h22V1ZM27 21H5v10h22V21ZM1 16h30" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={fill || "#fff"} d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Section;
