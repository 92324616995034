// @flow

import React from "react";

import { UserDisplay, IconContainer } from "./styles";
import useEditHover from "./useEditHover";
import ImageLabel from "src/containers/user/ImageLabel/Medium";
import Icon from "src/icons";
import type { UID } from "src/types";

type Props = {
  user: UID,
  removeUser: Function
};

const UserMultiSelect = ({ user, removeUser }: Props) => {
  const { hover, handleMouseEnter, handleMouseLeave } = useEditHover();

  return (
    <UserDisplay
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ImageLabel uid={user} />
      <IconContainer>
        {hover && (
          <Icon type="decrement" handleClick={() => removeUser(user)} />
        )}
      </IconContainer>
    </UserDisplay>
  );
};

export default UserMultiSelect;
