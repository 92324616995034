// @flow

import React from "react";

import Tile from "./Tile";
import { Section as StyledSection } from "./styles";

import type { HomescreenSection } from "src/types";

type Props = {
  section: HomescreenSection
};

const Section = ({ section }: Props) => {
  return (
    <StyledSection>
      <h2>{section.title}</h2>
      <p>{section.description}</p>
      {section.tiles.map(tile => (
        <Tile key={tile.id} tile={tile} />
      ))}
    </StyledSection>
  );
};

export default Section;
