// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import { ChatBlock } from "src/components/chatroom-nav/ChatBlock/styles";

const Contacts = styled.div`
  > h3 {
    padding-left: 1.3em;
    margin: 0.7em 0;
  }
`;

const DirectMessage = styled(ChatBlock)`
  border-bottom: 1px solid ${colors.grey13};
  > div > div > span {
    > span {
      font-size: 0.88rem;
    }
    > div {
      border-radius: 50%;
    }
  }
  > div > div > span:nth-of-type(2) {
    color: ${colors.secondary};
    > span {
      font-size: 0.75rem;
      margin-left: 4em;
    }
  }
`;

const Placeholder = styled.div`
  color: ${colors.activeFilter};
  font-weight: ${typography.semiBold};
`;

const SubWrapper = styled.div`
  padding: ${spacing.space_l};
  > div > span > img {
    border-radius: 50%;
  }
`;

export { Contacts, DirectMessage, Placeholder, SubWrapper };
