// @flow

import { backendUrl } from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";
import type { OrgAttrs, SSOConfigInput } from "src/types";

/**
 * Creates new section for home screen
 * @param req.title of the section
 * @param req.settings.position location of the section
 * @returns newly created section
 *
 */
export const createSection = async (req: {
  title: string,
  settings: {
    position: "center" | "right"
  }
}) => {
  const response = await fetch(`${backendUrl}/section`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

/**
 * Fetch all sections
 *  @returns get list of sections in the org
 */
export const getAllSections = async () => {
  const response = await fetch(`${backendUrl}/section`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Update section
 * @param req.id id of section to be updated
 *
 */
export const updateSection = async (req: Object) => {
  const response = await fetch(`${backendUrl}/section/${req.id}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

export const createTile = async (req: Object) => {
  const response = await fetch(`${backendUrl}/tile`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

export const editTile = async (id: number, req: Object) => {
  const response = await fetch(`${backendUrl}/tile/${id}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

export const updateTile = async (req: Object, id: number) => {
  const response = await fetch(`${backendUrl}/tile/${id}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

export const getTileTypes = async () => {
  const response = await fetch(`${backendUrl}/tiletype`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getAllTiles = async () => {
  const response = await fetch(`${backendUrl}/tile`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getAllSegments = async () => {
  const response = await fetch(`${backendUrl}/segment`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getSegmentTypes = async () => {
  const response = await fetch(`${backendUrl}/criteria`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const createUserSegment = async ({
  title,
  criteria
}: {
  title: string,
  criteria: Array<Object>
}) => {
  const response = await fetch(`${backendUrl}/segment`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      title,
      criteria
    })
  });

  return response.json();
};

export const editUserSegment = async ({
  title,
  criteria,
  id
}: {
  id: number,
  title: string,
  criteria: Array<Object>
}) => {
  const response = await fetch(`${backendUrl}/segment/${id}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      title,
      criteria
    })
  });

  return response.json();
};

export const getPlatformUsage = async () => {
  const response = await fetch(`${backendUrl}/platformusage`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const updateSectionOrder = async (sections: Array<number>) => {
  await fetch(`${backendUrl}/section`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      sections
    })
  });
};

/**
 * Deletes a given section for home screen
 * @param id of the section
 */

export const deleteSection = async (id: number) => {
  await fetch(`${backendUrl}/section/${id}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};

export const toggleHomescreen = async (enabled: boolean) => {
  await fetch(`${backendUrl}/home/setting`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      enabled
    })
  });
};

export const deleteTile = async (id: number) => {
  const response = await fetch(`${backendUrl}/tile/${id}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response;
};

/**
 * Updates the Org details , title or nickName or both
 * @param {number} id of the current Org
 * @param {OrgAttrs} attrs are the values to be updated
 */

export const updateOrgDetails = async (id: number, attrs: OrgAttrs) => {
  const response = await fetch(`${backendUrl}/org/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(attrs)
  });

  return response;
};

/**
 * Adds new domain to an org
 * @param {number} id of the current Org
 * @param {string} domainName is the new domainName to be added
 */

export const addOrgDomain = async (id: number, domainName: string) => {
  const response = await fetch(`${backendUrl}/org/${id}/domain/${domainName}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response;
};

/**
 * Deletes the domain from an org
 * @param {number} id of the current org
 * @param {string} domainName is the domainName to be removed
 */

export const deleteOrgDomain = async (id: number, domainName: string) => {
  const response = await fetch(`${backendUrl}/org/${id}/domain/${domainName}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response;
};

/**
 * Create a new SSO config for an org
 * @param {Object} config - the configuration the user wants to
 * setup SSO with
 */
export const saveSSOConfig = async (config: SSOConfigInput) =>
  easyFetch.put("/sso/config", {
    body: {
      domains: config.domains,
      "entity-id": config.entityId,
      "sso-url": config.ssoUrl,
      certificate: config.certificate
    }
  });

/**
 * Update the existing SSO config for an org
 * @param {Object} config - values of the config the user wants to
 * update
 */
export const updateSSOConfig = async (config: SSOConfigInput) =>
  easyFetch.patch("/sso/config", {
    body: {
      domains: config.domains,
      "entity-id": config.entityId,
      "sso-url": config.ssoUrl,
      certificate: config.certificate
    }
  });

/**
 * Deletes SSO config for an org
 */
export const deleteSSOConfig = async () => easyFetch._delete("/sso/config");

/**
 * Fetches the SSO config for an org
 */
export const getSSOConfig = async () => easyFetch.get("/sso/config");
