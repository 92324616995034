// @flow

import { useSelector } from "react-redux";
import React from "react";

import { getAllGroupIds } from "src/reducers";
import Select from "src/components/Select";
import GroupName from "src/components/Group/Name";

import { Flex, FormLabel } from "@chakra-ui/react";
import { styles } from "./styles";

type Props = {
  handleValueChange: Function,
  value: Array<number>
};

const GroupDropdown = ({ handleValueChange, value }: Props) => {
  const groups = useSelector(({ app }) => getAllGroupIds(app));

  return (
    <Flex {...styles.valueSelectContainer}>
      <FormLabel {...styles.label}>Value</FormLabel>
      <Select
        value={value}
        placeholder="Value"
        onChange={handleValueChange}
        multiple
      >
        {groups.map(group => (
          <GroupName key={group} value={group} id={group} />
        ))}
      </Select>
    </Flex>
  );
};

export default GroupDropdown;
