// @flow

export const chatroomAttributes = [
  {
    id: -10,
    label: "due-date"
  },
  {
    id: -9,
    label: "participant"
  },
  {
    id: -8,
    label: "title"
  },
  {
    id: -7,
    label: "age"
  },
  {
    id: -6,
    label: "children"
  },
  {
    id: -5,
    label: "parent"
  },
  {
    id: -4,
    label: "status"
  },
  {
    id: -3,
    label: "priority"
  },
  {
    id: -2,
    label: "creator"
  },
  {
    id: -1,
    label: "owner"
  }
];

export const chartChatroomAttributes = {
  "-8": {
    id: -8,
    label: "Title"
  },
  "-4": {
    id: -4,
    label: "Status"
  },
  "-3": {
    id: -3,
    label: "Priority"
  },
  "-2": {
    id: -2,
    label: "Creator"
  },
  "-1": {
    id: -1,
    label: "Owner"
  }
};

export const ageChartChatroomAttribute = {
  id: -7,
  name: "Age",
  type: "",
  multiValue: false,
  linkedProcessOrForm: "",
  linkedProcessOrFormId: null,
  linkedProcessOrFormType: "",
  linkedOrFormFieldName: "",
  linkedOrFormFieldId: null
};
