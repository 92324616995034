// @flow

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import { HStack, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";
import Dropdown from "src/components/Dropdown";
import ReactProgressiveList from "react-progressive-list";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";
import {
  getWorkflowTemplates,
  getChatroomType,
  getTemplateId
} from "src/reducers";
import { changeConversationType } from "src/actions/chatroom";
import { conversationTypes } from "src/constants/display";
import SelectedProcessType from "./SelectedProcessType";

import type { RoomId } from "src/types";

import { MenuItem } from "../styles";
import * as styles from "./styles";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalOverlay
} from "src/components/Modal";

import ProcessType from "./ProcessType";

const defaultTypes = [
  { id: -1, title: "Process", invocationCount: null, label: "workflow" },
  { id: -2, title: "Task", invocationCount: null, label: "task" },
  { id: -3, title: "Approval", invocationCount: null, label: "approval" },
  { id: -4, title: "Feed", invocationCount: null, label: "group" }
];

type Props = {
  roomId: RoomId,
  setIsMoreMenuOpen: (value: boolean) => void
};

const ChangeProcessType = ({ roomId, setIsMoreMenuOpen }: Props) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const chatroomType = useSelector(({ app }) => getChatroomType(app, roomId));
  const templateId = useSelector(({ app }) => getTemplateId(app, roomId));
  const templates = useSelector(({ app }) => app.workflow.byId);
  const workflows = useSelector(({ app }) => getWorkflowTemplates(app));

  const filteredResults = useMemo(() => {
    const result = R.filter(
      c => R.includes(R.toLower(query), R.toLower(c.title ?? "")),
      [
        ...defaultTypes,
        ...workflows.map(x => ({
          id: x.id,
          title: x.title,
          invocationCount: x.invocationCount
        }))
      ]
    );
    return result;
  }, [query]);

  const getVal = () => {
    if (chatroomType !== "workflow") {
      return R.head(conversationTypes[chatroomType]);
    }

    const template = templates[`${templateId || ""}`];
    return template ? template.title : `${templateId || ""}`;
  };

  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean();

  const {
    value: dropdownIsOpen,
    setTrue: openDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  useEffect(() => {
    openDropdown();
    !isOpen && closeDropdown();
  }, [isOpen]);

  const handleSelect = useCallback(
    ({ id: item }) => {
      const { id, label } = item;

      if (id > 0) {
        dispatch(changeConversationType(roomId, "workflow", id));
      } else {
        dispatch(changeConversationType(roomId, label));
      }
      setIsMoreMenuOpen(false);
      closeDropdown();
      closeModal();
      setQuery("");
    },
    [dropdownIsOpen, closeDropdown]
  );

  const handleSearch = useCallback(input => {
    setQuery(input);
  }, []);

  const handleKeyboardSelect = () => {};

  const changeConversationTypeEnabled = useComponentPermission(
    componentPermissions.changeConversationType
  );

  if (changeConversationTypeEnabled) {
    return (
      <>
        <HStack
          onClick={() => {
            openModal();
            setIsMoreMenuOpen(false);
          }}
          sx={MenuItem}
          tabIndex={0}
          onKeyPress={e => {
            e.stopPropagation();
            e.key === "Enter" && openModal();
            setIsMoreMenuOpen(false);
          }}
        >
          <Icon type="arrowLink" height={16} width={16} />;
          <Text>Change Process Type</Text>
        </HStack>
        {isOpen && (
          <ModalOverlay isOpen={isOpen} closeModal={closeModal}>
            <Modal isOpen={isOpen}>
              <ModalHeader>
                <Text as="span">Change Process Type</Text>
                <ModalClose closeModal={closeModal} />
              </ModalHeader>
              <ModalBody
                customStyles={{
                  gap: 0
                }}
              >
                <SelectedProcessType
                  isOpen={dropdownIsOpen}
                  closeDropdown={closeDropdown}
                  openDropdown={openDropdown}
                  value={getVal()}
                />
                <Dropdown
                  onItemClick={handleSelect}
                  isOpen={dropdownIsOpen}
                  onOuterClick={closeDropdown}
                  query={query}
                  onQueryChange={handleSearch}
                  handleKeyboardSelect={handleKeyboardSelect}
                  closeDropdown={closeDropdown}
                  customStyles={{
                    DropdownWrapper: dropdownIsOpen
                      ? styles.DropdownWrapper
                      : styles.DropdownHidden,
                    DropdownInputField: styles.DropdownInputField,
                    DropdownMenuWrapper: styles.DropdownMenuWrapper
                  }}
                >
                  {({
                    onItemClick,
                    getItemProps,
                    highlightedIndex,
                    scrollIntoView
                  }) => {
                    return (
                      <ReactProgressiveList
                        initialAmount={8}
                        progressiveAmount={10}
                        role="list"
                        rowCount={filteredResults.length}
                        renderItem={index => {
                          if (filteredResults[index]) {
                            return (
                              <ProcessType
                                item={filteredResults[index]}
                                key={`type-${filteredResults[index]}`}
                                elId={index}
                                index={index}
                                highlightedIndex={highlightedIndex}
                                scrollIntoView={scrollIntoView}
                                {...getItemProps({
                                  item: filteredResults[index],
                                  index,
                                  id: filteredResults[index].id,
                                  onItemClick: onItemClick
                                })}
                              />
                            );
                          }
                        }}
                      />
                    );
                  }}
                </Dropdown>
              </ModalBody>
            </Modal>
          </ModalOverlay>
        )}
      </>
    );
  } else return null;
};

export default ChangeProcessType;
