// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import {
  Breadcrumbs as StyledBreadcrumbs,
  Trail,
  BackButton
} from "src/components/Manage/Workflow/Instances/styles";
import Name from "src/components/Form/Name";
import * as atypes from "src/constants/actionTypes";
import { getInstanceFilter } from "src/reducers";
import Icon from "src/icons";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  reportId?: number
};

const Breadcrumbs = ({ filter, reportId }: Props) => {
  return (
    <StyledBreadcrumbs>
      <Trail active>
        <Link
          to={{
            type: reportId
              ? atypes.SET_REPORTS_REQUEST
              : atypes.SET_FORMS_REQUEST
          }}
        >
          <BackButton>
            <Icon type="back" />
          </BackButton>
          <h4>{reportId ? "Reports" : "Forms"}</h4>
        </Link>
      </Trail>
      <Trail>
        <h4>&gt;</h4>
      </Trail>
      <Trail>
        <h4>
          <Name id={filter.id} />
        </h4>
      </Trail>
    </StyledBreadcrumbs>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getInstanceFilter(app)
});

export default connect(mapStateToProps)(Breadcrumbs);
