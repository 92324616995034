// @flow

import React from "react";

import { Table as StyledTable, TableContainer } from "./styles";
import Header from "./Header";
import Body from "./Body";

const Table = () => {
  return (
    <TableContainer>
      <StyledTable>
        <Header />
        <Body />
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
