// @flow

import * as atypes from "src/constants/actionTypes";
import * as analytics from "src/analytics";

const segmentActions = {
  //  Select next chatroom - Current data is wrong
  [atypes.OPEN_HOME_SCREEN_TILE]: {
    event: "clicked-tile",
    data: {
      type: "analytics.type",
      tileType: "analytics.tileType"
    }
  },
  [atypes.SET_CURRENT_CHATROOM_SUCCESS]: {
    event: "view-chatroom",
    data: {
      chatroom: [analytics.getChatRoom, "id"],
      location: "location"
    }
  },
  [atypes.SET_CURRENT_CHATROOM_FAILURE]: {
    event: "view-chatroom",
    data: {
      chatroom: [analytics.getChatRoom, "id"],
      location: "location",
      failure: true
    }
  },
  [atypes.OPEN_CONVERSATION_MODAL]: {
    event: "view-chatroom"
  },

  [atypes.CREATE_CHATROOM_SUCCESS]: {
    event: "create-chatroom",
    data: {
      relatedChatroom: [analytics.getRelatedChatRoom, "parent"],
      seqNo: "seqNo",
      parent: "parent",
      message: "message",
      chatroomId: "id",
      templateId: "templateId",
      templateName: "processTitle",
      chatroomType: "type",
      location: "analytics.location"
    }
  },
  [atypes.CREATE_CHATROOM_FAILURE]: {
    event: "create-chatroom"
  },
  [atypes.UPDATE_CHATROOM_SUCCESS]: {
    event: "update-chatroom",
    data: {
      failure: false,
      chatroom: [analytics.getChatRoom, "roomId"],
      location: "location",
      action: "action"
    }
  },
  [atypes.UPDATE_CHATROOM_FAILURE]: {
    event: "update-chatroom",
    data: {
      failure: true,
      chatroom: [analytics.getChatRoom, "roomId"],
      location: "location",
      action: "action"
    }
  },
  [atypes.ADD_ROOM_MEMBER_SUCCESS]: {
    event: "add-participant",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      member: "member",
      location: "analytics.location"
    }
  },
  [atypes.ADD_ROOM_MEMBER_REQUEST_FAILURE]: {
    event: "change-due-date",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      member: "member",
      location: "analytics.location"
    }
  },
  [atypes.REMOVE_ROOM_MEMBER_REQUEST_SUCCESS]: {
    event: "remove-participant",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      member: "member",
      location: "analytics.location"
    }
  },
  [atypes.REMOVE_ROOM_MEMBER_REQUEST_FAILURE]: {
    event: "remove-participant",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      member: "member",
      location: "analytics.location"
    }
  },
  [atypes.SHOW_DOCK]: {
    event: "open-activity-panel",
    data: {
      chatroom: [analytics.getCurrentChatRoom, "roomId"]
    }
  },
  [atypes.HIDE_DOCK]: {
    event: "hide-activity-panel",
    data: {
      chatroom: [analytics.getCurrentChatRoom, "roomId"]
    }
  },
  [atypes.DOWNLOAD_FILE_REQUEST]: {
    event: "download-file",
    data: {
      name: "originalName",
      location: "analytics.location"
    }
  },
  [atypes.SHOW_CHECKLIST_BUILDER]: { event: "define-checklist-click" },

  [atypes.SET_CHECKLIST_VALUE_SUCCESS]: {
    event: "set-checklist-value",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      fieldType: "value.type"
    }
  },
  [atypes.SET_CHECKLIST_VALUE_FAILURE]: {
    event: "set-checklist-value-failure",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      fieldType: "value.type"
    }
  },
  [atypes.CREATE_CONVERSATION_FROM_CHECKLIST_SUCCESS]: {
    event: "create-chatroom",
    data: {
      relatedChatroom: [analytics.getRelatedChatRoom, "currentChatroomId"],
      location: "location",
      checklistId: "checklistId",
      checklistFieldId: "checklistFieldId",
      seqNo: "seqNo",
      message: "message",
      chatroomId: "chatroomId",
      templateId: "templateId",
      templateName: "templateTitle",
      chatroomType: "type"
    }
  },

  // MESSAGE RELATED ACTIONS
  [atypes.INSERT_CHAT_SUCCESS]: {
    event: "create-message",
    data: {
      chatroom: [analytics.getChatRoom, "message.roomId"],
      mentionedUsers: [analytics.getMentionedUsers, "message.text"],
      inReplyTo: "message.inReplyTo"
    }
  },
  [atypes.INSERT_CHAT_FAILURE]: {
    event: "create-message",
    data: {
      chatroom: [analytics.getChatRoom, "message.roomId"],
      mentionedUsers: [analytics.getMentionedUsers, "message.text"],
      inReplyTo: "message.inReplyTo"
    }
  },
  [atypes.HIDE_MESSAGE_SUCCESS]: {
    event: "hide-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getSelectedMessages]
    }
  },
  [atypes.HIDE_MESSAGE_FAILURE]: {
    event: "hide-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getSelectedMessages]
    }
  },
  [atypes.UNHIDE_MESSAGE_SUCCESS]: {
    event: "unhide-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      message: "id"
    }
  },
  [atypes.UNHIDE_MESSAGE_FAILURE]: {
    event: "unhide-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      message: "id"
    }
  },
  [atypes.DELETE_MESSAGE_SUCCESS]: {
    event: "delete-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getDeletableMessages]
    }
  },
  [atypes.DELETE_MESSAGE_FAILURE]: {
    event: "delete-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getDeletableMessages]
    }
  },
  [atypes.FORWARD_MESSAGE_SUCCESS]: {
    event: "forward-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getForwardMessagesDetails]
    }
  },
  [atypes.FORWARD_MESSAGE_FAILURE]: {
    event: "forward-message",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      messages: [analytics.getForwardMessagesDetails]
    }
  },

  // END OF MESSAGE RELATED ACTIONS
  [atypes.CREATE_WORKFLOW_SUCCESS]: { event: "define-workflow" },
  [atypes.CREATE_WORKFLOW_FAILURE]: { event: "define-workflow-failure" },
  [atypes.SEND_USER_INVITE_SUCCESS]: { event: "send-user-invite" },

  // FILE RELATED ACTIONS
  [atypes.OPEN_FILE_VIEWER]: { event: "view-file" },
  [atypes.OPEN_FILE_VIEWER]: { event: "view-file" },
  [atypes.UPLOAD_FILE_TO_CHAT_SUCCESS]: {
    event: "upload-file",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      size: "size",
      location: "analytics.location"
    }
  },
  [atypes.UPLOAD_FILE_TO_CHAT_FAILURE]: {
    event: "upload-file",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      size: "size",
      location: "analytics.location",
      failure: true
    }
  },
  [atypes.OPEN_FILE_VIEWER]: {
    event: "view-file",
    data: {
      chatroom: [analytics.getChatRoom, "roomId"],
      size: "size",
      location: "analytics.location",
      name: "originalName"
    }
  },
  [atypes.OPEN_MANAGE_PAGE]: { event: "open-manage" },
  [atypes.OPEN_CONTACTS_PAGE]: { event: "open-contacts" },
  [atypes.VIEW_PROCESS_DETAILS]: {
    event: "view-process-details",
    data: {
      templateId: "id",
      templateName: "title"
    }
  },
  [atypes.VIEW_CHECKLIST_DETAILS]: { event: "view-checklist-details" },
  [atypes.SAVE_PINNED_LIST_SUCCESS]: { event: "save-pinned-list" },
  [atypes.DELETE_PINNED_LIST_REQUEST]: { event: "delete-pinned-list" },
  [atypes.SEND_EMAIL_REQUEST]: { event: "send-email" },
  [atypes.SEND_EMAIL_FAILURE]: { event: "send-email-failure" },
  [atypes.ATTACH_FILE_TO_EMAIL_REQUEST]: { event: "attach-file-to-email" },
  [atypes.ATTACH_FILE_TO_EMAIL_FAILURE]: {
    event: "attach-file-to-email-failure"
  },
  [atypes.SET_DEFAULT_FILTER]: {
    event: "clicked-on-predefined-filters",
    data: {
      name: "name"
    }
  },
  [atypes.SET_SAVED_FILTER]: {
    event: "clicked-on-saved-filters",
    data: {
      name: "name"
    }
  },
  [atypes.COPY_CONVERSATION_EMAIL]: {
    event: "copied-email-id"
  },
  [atypes.NAVIGATE_FROM_DAILY_SUMMARY]: {
    event: "view-filtered-list",
    data: {
      location: "location",
      orgId: "orgId",
      orgName: "orgName",
      status: "status",
      ownedByMe: "ownedByMe",
      critical: "critical",
      new: "new",
      workflow: "workflow",
      unread: "unread"
    }
  }
};

export default segmentActions;
