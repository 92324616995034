// @flow

import React from "react";
import { connect } from "react-redux";

import { Dock as StyledDock, Content } from "../styles";
import Info from "../Info";
import PrivacySection from "../Privacy";
import { getChatroomPrivacy } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  room: RoomId,
  privacy: string,
  activeDock?: string,
  isSrw: boolean,
  isSrwMobile: boolean
};

const Privacy = ({ room, privacy, activeDock, isSrw, isSrwMobile }: Props) => (
  <>
    {isSrwMobile ? (
      <StyledDock open={activeDock}>
        <Info title="Privacy Settings" back isSrw={isSrw} />
        <Content>
          <PrivacySection roomId={room} privacy={privacy} />
        </Content>
      </StyledDock>
    ) : (
      <PrivacySection roomId={room} privacy={privacy} />
    )}
  </>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  privacy: getChatroomPrivacy(app, props.room)
});

export default connect(mapStateToProps)(Privacy);
