// @flow

import React from "react";

import { ArchiveIconContainer } from "./styles";
import Icon from "src/icons";

import * as colors from "src/styles/constants/colors";

const Archived = () => {
  return (
    <ArchiveIconContainer>
      <Icon type="archive" fill={colors.primary} />
    </ArchiveIconContainer>
  );
};

export default Archived;
