// @flow

import React, { useState, useCallback } from "react";
import ReactModal from "react-modal";
import { Set as ImmutableSet } from "immutable";
import type { Set as ImmutableSetType } from "immutable";

import CustomStatus from "./CustomStatus/index";
import {
  StatusForm,
  StatusBody,
  StatusModal as StyledStatusModal,
  SecondaryFooter,
  PopupFormBody,
  ButtonSecondary,
  ButtonPrimary
} from "../styles";
import StatusItem from "./StatusItem";

type Props = {
  _hideStatusModal: Function,
  statusModal: boolean,
  active: string,
  isWorkflow: ?number,
  _setCheckedStatus: Function,
  currentSelectedStatuses: ImmutableSetType<number>,
  _setStandardStatusFilter: Function,
  homeScreen?: boolean
};

const StatusModal = (props: Props) => {
  const {
    statusModal,
    _setStandardStatusFilter,
    _setCheckedStatus,
    active,
    _hideStatusModal,
    isWorkflow,
    currentSelectedStatuses,
    homeScreen
  } = props;
  const disabled = !isWorkflow;
  const [currentStatus, setCurrentStatus] = useState(active);
  const [customStatuses, setSelectedCustomStatuses] = useState(
    currentSelectedStatuses
  );

  const closeStatusModal = useCallback(
    (e: any) => {
      e.preventDefault();
      _hideStatusModal();
    },
    [_hideStatusModal]
  );

  const setCurrentStatusHandler = useCallback(
    (currentStat: string) => {
      setCurrentStatus(currentStat);
      if (currentStatus !== "custom") {
        setSelectedCustomStatuses(ImmutableSet([]));
      }
    },
    [setCurrentStatus]
  );

  const applyCurrentStatus = useCallback(() => {
    _setStandardStatusFilter(currentStatus);
    _setCheckedStatus(customStatuses, currentStatus);
    _hideStatusModal();
  }, [
    _hideStatusModal,
    currentStatus,
    customStatuses,
    _setCheckedStatus,
    _setStandardStatusFilter
  ]);

  return (
    <StyledStatusModal id="status-modal">
      <ReactModal
        isOpen={statusModal}
        onRequestClose={closeStatusModal}
        shouldCloseOnOverlayClick
        parentSelector={() => document.querySelector("#status-modal")}
      >
        <StatusForm>
          <h4>STATUS</h4>
          <PopupFormBody>
            <StatusBody>
              <StatusItem
                secondaryText="Select all pending"
                activeFilterText="pending"
                active={currentStatus === "pending"}
                setCurrentStatusHandler={setCurrentStatusHandler}
                badgeId={-1}
                homeScreen={homeScreen}
              />
              <StatusItem
                secondaryText="Select all complete"
                activeFilterText="closed"
                active={currentStatus === "closed"}
                setCurrentStatusHandler={setCurrentStatusHandler}
                badgeId={-2}
                homeScreen={homeScreen}
              />
              <CustomStatus
                currentStatus={currentStatus}
                isCustomStatusDisabled={disabled}
                setCurrentStatusHandler={setCurrentStatusHandler}
                id={isWorkflow}
                applyCurrentStatus={applyCurrentStatus}
                customStatuses={customStatuses}
                setSelectedCustomStatuses={setSelectedCustomStatuses}
                homeScreen={homeScreen}
              />
            </StatusBody>
          </PopupFormBody>
          <SecondaryFooter>
            <ButtonSecondary type="button" onClick={closeStatusModal}>
              Cancel
            </ButtonSecondary>
            <ButtonPrimary onClick={applyCurrentStatus} type="button">
              Apply
            </ButtonPrimary>
          </SecondaryFooter>
        </StatusForm>
      </ReactModal>
    </StyledStatusModal>
  );
};

export default StatusModal;
