// @flow

import styled from "@emotion/styled";

import { FormGroup as StyledFormGroup } from "src/styles/form";

const FormGroup = styled(StyledFormGroup)`
  width: 23em;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  margin: 1.34em 1.6em;
`;

export { FormGroup };
