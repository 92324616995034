// @flow

// This is for styles similar to dashboard and homescreen builder
// Where there is list of items on left and workarea on the left

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { OpaqueOverlay } from "src/components/Manage/Workflow/styles";

export const Overlay = styled(OpaqueOverlay)`
  overflow: auto;
  padding: 0;
  left: 54px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  overflow: auto;
`;

export const List = styled.div`
  border-right: 1px solid ${colors.grey2};
  padding: 0 ${spacing.space_l};
`;

export const OrgSettingsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space_l};
  overflow: auto;
`;

export const ListContainer = styled.div`
  max-height: 80vh;
  padding-bottom: 0.3rem;
  overflow: auto;
`;

export const Item = styled.div`
  background-color: ${props => (props.active ? colors.blue : colors.highlight)};
  padding: 8px;
  border-radius: 4px;
  border: none;
  margin-bottom: 1em;
  cursor: pointer;

  > h4 {
    color: ${props => (props.active ? colors.brightLight : colors.blue)};
    margin: 0;
  }

  span {
    color: ${props => (props.active ? colors.brightLight : colors.blueDark)};
  }

  :hover {
    transition: all 0.5s;
    background-color: ${colors.blue};
    color: ${colors.brightLight};
    > h4,
    span {
      color: ${colors.brightLight};
    }
  }
`;

export const OrgItem = styled.div`
  background-color: ${props =>
    props.active ? colors.blue : colors.brightLight};
  padding: 0 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  > h4 {
    color: ${props => (props.active ? colors.brightLight : colors.inkLighter)};
  }
`;

export const OrgSettingsHeader = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${colors.inkLight};
`;

export const OrgSettingItem = styled.h4`
  display-block: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 24px;
  margin-top: 4px;
  margin-bottom: 2px;
`;
