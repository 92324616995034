// @flow

import { backendUrl } from "src/config/firebase";

export const createStatus = async (title: string) => {
  const response = await fetch(`${backendUrl}/status`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ title })
  });

  return response.json();
};

/**
 * Delete Status
 * @param {number} templateId template id whose status to delete
 * @param {number} statusId id of status to be deleted
 */
export const deleteStatus = async (templateId: number, statusId: number) => {
  await fetch(`${backendUrl}/workflow/${templateId}/status/${statusId}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};

/**
 * Edit Status
 * @param {number} name new name of status
 * @param {number} statusId id of status to be edited
 */
export const editStatus = async (statusId: number, name: string) => {
  const response = await fetch(`${backendUrl}/status/${statusId}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ title: name })
  });
  return response.json();
};
