// @flow

import styled from "@emotion/styled";
import {
  InputGroup as ChakraGroupInput,
  Button as ChakraButton,
  IconButton as ChakraIconButton
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const Button = styled(ChakraButton)`
  width: 358px;
  height: 38px;
  border: 1px solid ${colors.skyDark};
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;

export const InputGroup = styled(ChakraGroupInput)`
  border-radius: 2px;
  background-color: ${colors.purpleLighter};
  border: 1px ${colors.purpleDark};
`;

export const IconButton = styled(ChakraIconButton)`
  outline: none;
  position: absolute;
  left: 70px;
`;
