// @flow

import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

export const showSignUpModal = (): Action => ({
  type: atypes.SRW_SHOW_SIGNUP_MODAL,
  payload: {}
});

export const hideSignUpModal = (): Action => ({
  type: atypes.SRW_HIDE_SIGNUP_MODAL,
  payload: {}
});

export const setMobileResponsiveness = (): Action => ({
  type: atypes.MOBILE_RESPONSE_WINDOW,
  payload: {}
});
