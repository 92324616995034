// @flow

import * as React from "react";

type Props = {};

const Anchor = ({ ...restProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M0.999787 16C0.802037 16 0.608738 15.9413 0.444328 15.8314C0.279919 15.7216 0.151779 15.5654 0.0761089 15.3827C0.000438906 15.2 -0.0193641 14.999 0.0192035 14.805C0.0577711 14.6111 0.152977 14.4329 0.292787 14.293L5.29279 9.29304C5.48139 9.11088 5.73399 9.01009 5.99619 9.01237C6.25838 9.01465 6.5092 9.11981 6.69461 9.30522C6.88001 9.49063 6.98518 9.74144 6.98746 10.0036C6.98974 10.2658 6.88894 10.5184 6.70679 10.707L1.70679 15.707C1.51929 15.8946 1.26498 16 0.999787 16Z" />
      <path d="M0.965846 10.7921L2.48785 9.26906L2.43285 9.14106C2.42685 9.12506 1.85685 7.55706 2.70785 6.70606L6.73785 2.67606C7.57985 1.83406 9.13785 2.38906 9.13785 2.38806L9.30085 2.45806L10.8228 0.935065L9.88985 0.535065C9.77685 0.491065 7.09985 -0.510935 5.32285 1.26306L1.29285 5.29307C-0.480154 7.06406 0.522846 9.74607 0.580846 9.89406L0.965846 10.7921Z" />
      <path d="M5.20801 15.034L6.73101 13.512L6.85901 13.567C6.87501 13.573 8.44301 14.143 9.29401 13.292L13.324 9.26203C14.166 8.42003 13.611 6.86203 13.612 6.86203L13.542 6.69903L15.065 5.17603L15.465 6.10903C15.509 6.22203 16.511 8.90302 14.737 10.676L10.707 14.706C8.93601 16.479 6.25401 15.476 6.10701 15.418L5.20801 15.034Z" />
      <path d="M9.99979 7.00004C9.80204 7 9.60874 6.94133 9.44433 6.83144C9.27992 6.72156 9.15178 6.5654 9.07611 6.3827C9.00044 6.2 8.98064 5.99896 9.0192 5.80501C9.05777 5.61106 9.15298 5.43289 9.29279 5.29304L14.2928 0.293041C14.4814 0.110883 14.734 0.0100885 14.9962 0.0123669C15.2584 0.0146453 15.5092 0.119814 15.6946 0.305222C15.88 0.49063 15.9852 0.741443 15.9875 1.00364C15.9897 1.26584 15.8889 1.51844 15.7068 1.70704L10.7068 6.70704C10.5193 6.89459 10.265 6.99998 9.99979 7.00004Z" />
    </svg>
  );
};

export default Anchor;
