// @flow

import React from "react";

const DottedSort = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15H10V13H8V15ZM8 19H10V17H8V19ZM8 11H10V9H8V11ZM8 5V7H10V5H8Z"
      fill="#888888"
    />
    <path
      d="M14 15H16V13H14V15ZM14 19H16V17H14V19ZM14 11H16V9H14V11ZM14 5V7H16V5H14Z"
      fill="#888888"
    />
  </svg>
);

export default DottedSort;
