// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

export const Input = styled.input`
  background-color: ${colors.grey28};
  width: 18rem;
  height: 2rem;
  border: none;
  border-radius: ${radius.medium};
  padding: ${spacing.space_l};
`;
