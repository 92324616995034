// @flow

import type { FormControlProps } from "@chakra-ui/react";
import { mobileBreakpoint } from "src/constants";

export const formControl: FormControlProps = {
  width: "400px",
  [mobileBreakpoint]: {
    width: "100%"
  }
};
