// @flow

import React from "react";

import Common from "../Common";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Invite = ({ message }: Props) => {
  const { data } = message;
  const { members } = data;
  return (
    <Common {...message}>
      {" "}
      invited{" "}
      {(members || []).map(uid => (
        <NameOrEmail uid={uid} key={uid} showPending={false} />
      ))}{" "}
      to the chatroom
    </Common>
  );
};

export default Invite;
