// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Flex, Avatar, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { getUser } from "src/reducers";
import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

import type { UID } from "src/types";
import type { SelectOrUnselectParticipant } from "src/components/chatroom/Members/AddUser";

type Props = {
  id: UID,
  unselectParticipant: SelectOrUnselectParticipant
};

const SelectedNewUser = ({ id, unselectParticipant }: Props) => {
  const user = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl } = user;

  return (
    <Flex sx={styles.SelectedUsersAndGroupsWrapper}>
      <Avatar
        sx={styles.UserAvatar}
        size="2xs"
        name={displayName}
        src={photoUrl}
      />
      <Text isTruncated sx={styles.SelectedUserAndGroupName}>
        {displayName}
      </Text>
      <CloseIcon
        onClick={() => unselectParticipant({ type: "user", id })}
        sx={styles.CursorPointer}
        color={colors.dark}
        boxSize={2}
      />
    </Flex>
  );
};

export default SelectedNewUser;
