// @flow

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import { comparisonValues } from "src/constants/charts";
import Line from "./Line";
import Column from "./Column";
import Area from "./Area";
import Rate from "./Rate";

type Props = {
  id: string,
  chartDetails: Object,
  position: number,
  plotType: Function,
  setChartDetails: Function
};

const SortableItem = SortableElement(
  ({ id, position, chartDetails, setChartDetails, plotType }: Props) => {
    if (plotType === comparisonValues.line) {
      return (
        <Line
          key={id}
          id={id}
          chartDetails={chartDetails}
          position={position}
          setChartDetails={setChartDetails}
        />
      );
    }

    if (plotType === comparisonValues.area) {
      return (
        <Area
          key={id}
          id={id}
          chartDetails={chartDetails}
          position={position}
          setChartDetails={setChartDetails}
        />
      );
    }

    if (plotType === comparisonValues.column) {
      return (
        <Column
          key={id}
          id={id}
          chartDetails={chartDetails}
          position={position}
          setChartDetails={setChartDetails}
        />
      );
    }

    if (plotType === comparisonValues.rate) {
      return (
        <Rate
          key={id}
          id={id}
          chartDetails={chartDetails}
          position={position}
          setChartDetails={setChartDetails}
        />
      );
    }
  }
);

export default SortableItem;
