import styled from "@emotion/styled";

import { ButtonSolidToOutline } from "src/styles/buttons";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import * as spacing from "src/styles/constants/spacing";

const NewRoom = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled(ButtonSolidToOutline)`
  font-weight: ${typography.semiBold};
  margin-right: ${spacing.space_xs};
  padding: 0.4em 1.2em 0.4em 1.2em;
  letter-spacing: 1.35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-top: 1px;
  }

  &:hover,
  :focus {
    > svg > path {
      transition: 0.3s ease-in-out;
      stroke: ${colors.active};
    }
  }
  > svg {
    margin-right: 5px;
  }

  > svg > path {
    stroke: white;
  }
`;

export { NewRoom, Button };
