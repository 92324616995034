// @flow

import type { AppState, Action } from "src/types";

import * as atypes from "src/constants/actionTypes";

const memberAddDialog = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_MEMBER_ADD_DIALOG:
      return state === payload.location ? null : payload.location;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.CLOSE_CONVERSATION_MODAL:
      return null;
    default:
      return state;
  }
};

export default memberAddDialog;

export const getMemberAddDialog = (state: AppState) => state.memberAddDialog;
