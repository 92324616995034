// @flow

import styled from "@emotion/styled";

import { colors } from "src/styles/constants";

export const UserWithEmail = styled.div`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  line-height: 2em;
  border-radius: ${({ pos }) => (pos === "nav" ? "50%" : "1px")};
  margin-right: ${({ pos }) => (pos === "nav" ? "8" : "0")}px;
  .wrapper-user {
    display: flex;
    margin: 5px 10px;
    color: black;
    font-size: 13px;
    flex-direction: column;
  }
`;

export const Pending = styled.span`
  color: ${colors.green} !important;
`;

export const UserDisplayName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px;
  border-radius: 2px;
  line-height: 2em;
  div {
    height: 20px;
    width: 20px;
    font-size: 12px;
  }
  span {
    text-align: left;
    font-weight: 300;
    color: ${colors.mediumBlack};
    display: block;
    max-width: 194px;
    overflow: hidden;
    padding-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    button {
      background: ${colors.aliceBlue};
      padding: 0px 5px 0px 8px;
      border: none;
      position: absolute;
      right: 0px;
    }
  }
`;
