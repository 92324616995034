// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { colors, shadow, fontSize, spacing } from "./constants";
import * as newSpacing from "src/styles/constants/spacing";

const AddPopover = styled.div`
  min-width: 224px;
  min-height: 90px;
  border-radius: 4px;
  position: sticky;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  margin-left: 87px;
  background-color: ${colors.pureWhite};
  border: solid 1px rgba(205, 210, 217, 0.5);
  z-index: 555;

  > h4 {
    font-size: 12px;
    font-weight: normal;
    color: ${colors.dullGrey};
    padding: 12px 20px;
    margin: 0px;
    padding-bottom: 2px;
    :not(:first-of-type) {
      padding-top: 0px;
    }
  }
  & > div {
    width: 222px;
    opacity: 0.5;
    border: solid 0.3px ${colors.normalizedGrey};
  }

  ul {
    list-style: none;
    padding: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
    li {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 13px;
      border-radius: 4px;
      padding-top: 3px;
      padding-bottom: 3px;
      cursor: pointer;
      span {
        position: relative;
        left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        color: ${colors.dullGrey};
        & > svg {
          stroke: ${colors.dullGrey};
          path {
            stroke: ${colors.dullGrey};
          }
        }
        & > span {
          top: -6px;
        }
      }

      & > div > div > button {
        color: ${colors.menuItemGrey};
      }
      &:hover {
        background: ${colors.aliceBlue};
        > div:nth-of-type(2) {
          display: block;
          position: absolute;
          div {
            display: block;
            margin-bottom: 5px;
            > div > ul {
              max-height: 12em;
            }
          }
        }
        & > div {
          & > div:nth-of-type(3) {
            display: block;
          }
        }
      }
      & > div {
        max-width: 44px;
        font-size: 13px;
        color: ${colors.menuItemGrey};
        display: grid;
        grid-template-columns: 24px 150px 10px;
        align-items: center;
        margin: 0px;
        cursor: pointer;
      }
    }
  }
`;

const ChatroomOptions = styled(AddPopover)`
  position: absolute;
  bottom: 5.5em;
  margin-left: 1.2em;
  > h4 {
    :not(:first-of-type) {
      padding-top: ${spacing.spacing1};
    }
  }
`;

const SubMenu = styled.div`
  display: block;
  right: -22px;
  background: transparent;
  height: 20px;
  width: 200px;
  position: absolute;
  z-index: 555;

  li {
    padding-left: 5px;
    margin-left: -3px;
  }

  > div > div > ul {
    color: ${colors.menuItemGrey} !important;
  }

  > * {
    display: none;
  }

  &: hover {
    display: block;
    position: absolute;
    div {
      display: block;
      margin-bottom: 5px;
    }
  }
`;

const LeftSubMenu = styled(SubMenu)`
  left: -20px;
  right: auto;
  > div {
    left: -247px;
  }
`;

const StyledLeftSubMenu = styled(LeftSubMenu)`
  > div {
    top: -50px;
  }
`;

const RightSubMenu = styled(SubMenu)`
  left: -3px;
  right: auto;
  > div {
    right: -154px !important;
    left: auto;
    min-height: 100px;
    > div {
      left: 74px;
    }
  }

  li {
    padding-left: 5px !important;
    margin-left: -10px !important;
    width: 100% !important;
  }
`;

const Title = styled.div`
  cursor: pointer;
  svg {
    margin-left: ${spacing.spacing0};
    visibility: hidden;
    cursor: pointer;
  }

  input[type="submit"] {
    cursor: pointer;
    padding: 3px ${spacing.spacing1};
    background: ${colors.blue};
    color: ${colors.pureWhite};
    font-size: ${fontSize.t1};
    border: none;
    margin-left: ${spacing.spacing0};
  }

  :hover {
    svg {
      visibility: visible;
    }
  }
`;

const Description = styled(Title)`
  textarea {
    display: block;
    margin-bottom: 1em;
    width: 95%;
    min-height: 10em;
    resize: vertical;
  }
  input[type="submit"] {
    margin: 0px;
  }
`;

const Parent = styled.div`
  width: 100%;
  > div > div > div {
    padding: ${newSpacing.space_m};
  }
`;

const NewRoomOptions = styled.div`
  top: 53px;
  left: 224px;
  width: 220px;
  min-height: 70px;
  border-radius: 4px;
  box-shadow: ${shadow.bottomShadow};
  background-color: ${colors.pureWhite};
  border-top: 1px solid ${colors.aliceBlue};
  position: absolute;
  z-index: 3;

  li {
    width: 93%;
    > h5 > div {
      display: none;
    }
    :hover {
      > h5 > div {
        display: block;
      }
    }
    :hover,
    :focus {
      outline: none;
      > h5 {
        outline: none;
        background: ${colors.aliceBlue};
      }
    }
  }

  ul {
    padding-top: 0.75rem;
    padding-left: ${spacing.spacing2} !important;
    list-style: none;
    :last-child {
      margin-top: 0px;
    }

    span {
      white-space: nowrap;
      display: block;
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: ${spacing.spacing0};
      line-height: 0.75rem;
    }
  }

  h4 {
    font-size: ${fontSize.t3};
    color: ${colors.dullGrey};
    margin: 0px;
  }

  h5 {
    font-size: ${fontSize.t3};
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: ${spacing.spacing0} 0px;
    border-radius: 2px;
    padding-left: ${spacing.spacing0};
    cursor: pointer;

    > svg:nth-of-type(3) {
      position: absolute;
      right: 24px;
    }
  }
`;

const Workflow = styled.div`
  position: relative;
  left: ${spacing.spacing4};
  top: -${spacing.spacing1};
`;

const Comments = styled.div`
  padding: ${spacing.spacing2};
  ${props =>
    props.isMobile
      ? css`
          background: ${colors.pureWhite};
          min-width: 300px;
        `
      : css``}
  h2 {
    position: relative;
    font-size: ${fontSize.t1};
    padding-bottom: ${spacing.spacing2};
    border-bottom: 1px solid ${colors.isabelline};
    margin: 0px;
    > svg {
      position: absolute;
      right: -4px;
      top: 0px;
      cursor: pointer;
    }
  }
  textarea {
    width: 100%;
    resize: none;
    padding: ${spacing.spacing0};
    font-size: ${fontSize.t2};
    border-radius: 2px;
    margin-bottom: ${spacing.spacing1};
    border: 1px solid ${colors.springGrey};
    :hover,
    :focus {
      border: 1px solid ${colors.defaultGrey};
      outline: none;
    }
  }

  > div {
    button {
      float: right;
      margin: ${spacing.spacing0};
      margin-bottom: ${spacing.spacing3};
    }
  }
`;

export {
  AddPopover,
  SubMenu,
  Description,
  Parent,
  NewRoomOptions,
  Workflow,
  Comments,
  LeftSubMenu,
  RightSubMenu,
  ChatroomOptions,
  StyledLeftSubMenu
};
