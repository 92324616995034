// @flow

import * as R from "ramda";
import { connect, useDispatch } from "react-redux";
import React, { useCallback, useState, useEffect } from "react";

import { RequestAccess as StyledRequestAccess, Button } from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { addRoomMember } from "src/actions/chatroom";
import {
  getCurrentRoomId,
  getCurrentUserId,
  isProcessOwner
} from "src/reducers";

import type { AppState, RoomId, UID } from "src/types";

type Props = {
  currentUser: UID,
  roomId: RoomId,
  pendingRoomMembers: Array<UID>
};

const RequestAccess = ({ currentUser, roomId, pendingRoomMembers }: Props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, [setShow]);

  const handleRequestAccess = useCallback(() => {
    dispatch(
      addRoomMember({
        users: [currentUser],
        groups: null,
        roomId: `${roomId}`,
        request: true
      })
    );
  }, [roomId, currentUser, addRoomMember]);

  const pending = R.includes(currentUser, pendingRoomMembers);

  return (
    <StyledRequestAccess>
      {show && (
        <div>
          <Icon type="lock" color={colors.grey18} height="25" width="25" />
          <h4>
            {pending
              ? "You can access the conversation after a participant gives you access."
              : "This conversation is private"}
          </h4>
          <Button onClick={handleRequestAccess} disabled={pending}>
            {pending ? "Request Sent" : "Request Access"}
          </Button>
        </div>
      )}
    </StyledRequestAccess>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  currentUser: getCurrentUserId(app),
  roomId: getCurrentRoomId(app),
  isProcessOwner: isProcessOwner(app, props.roomId),
  pendingRoomMembers: app.pendingRoomMembers
});

export default connect(mapStateToProps)(RequestAccess);
