// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useCallback } from "react";

import Checkbox from "src/components/Unifize/Checkbox";
import { toggleTemplateRowSelection } from "src/actions/workflows";
import { isTemplateRowSelected } from "src/reducers";
import type { ProcessTemplateId } from "src/types";

type Props = {
  templateId: ProcessTemplateId
};

const TemplateCheckbox = ({ templateId }: Props) => {
  const dispatch = useDispatch();
  const selected: boolean = useSelector(({ app }) =>
    isTemplateRowSelected(app, templateId)
  );

  const handleSelect = useCallback(() => {
    dispatch(toggleTemplateRowSelection(Number(templateId)));
  }, [templateId]);

  return (
    <Checkbox
      id={`select${templateId}`}
      checked={selected}
      onChange={handleSelect}
    />
  );
};

export default TemplateCheckbox;
