// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Flash = createIcon({
  displayName: "FlashIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="none"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M9 5h5l-1 4h5l-7 11 1-7H7l2-8z"
    />
  )
});

export default Flash;
