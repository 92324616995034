// @flow
import { createContext } from "react";

import type {
  ImportModalActionType,
  ImportModalAction,
  ImportModalState
} from "src/types";
import { dataStages } from "src/constants";

const defaultState: ImportModalState = {
  processes: [],
  collisions: [],
  importProcesses: [],
  importResult: [],
  panel: 1,
  status: dataStages.idle
};

const ImportStateContext = createContext<ImportModalState>(defaultState);

const importActions: { [$Keys<ImportModalState>]: ImportModalActionType } = {
  processes: "ALL_PROCESSES",
  importProcesses: "IMPORTS",
  collisions: "COLLISIONS",
  importResult: "IMPORT_RESULT",
  panel: "PANEL",
  status: "STATUS"
};

function importReducer(
  state: ImportModalState,
  action: ImportModalAction
): ImportModalState {
  const { type, payload } = action;
  switch (type) {
    case "ALL_PROCESSES":
      return {
        ...state,
        processes: payload?.processes
      };
    case "IMPORTS":
      return {
        ...state,
        importProcesses: payload?.importProcesses
      };
    case "COLLISIONS":
      return {
        ...state,
        collisions: payload?.collisions
      };
    case "IMPORT_RESULT":
      return {
        ...state,
        importResult: payload?.importResult
      };
    case "PANEL":
      return {
        ...state,
        panel: payload?.panel
      };
    case "STATUS":
      return {
        ...state,
        status: payload?.status
      };
    default:
      return state;
  }
}

export {
  importReducer,
  importActions,
  defaultState as defaultImportState,
  ImportStateContext
};
