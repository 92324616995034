// @flow

import React, { useState, useCallback, Fragment, memo } from "react";
import * as R from "ramda";
import VisibilitySensor from "react-visibility-sensor";

import LastElement from "./LastElement";

type Props = {
  initialAmount: number,
  progressiveAmount: number,
  rowCount: number,
  renderLoader: any,
  renderItem: Function
};

const VirtualList = ({
  initialAmount,
  progressiveAmount,
  renderItem,
  rowCount
}: Props) => {
  const [count, setCount] = useState(initialAmount);

  const incrementCount = useCallback(() => {
    if (count < rowCount) {
      setCount(count + progressiveAmount);
    }
  }, [count, setCount, progressiveAmount, rowCount]);

  const elements = R.times(R.identity, count);
  return (
    <>
      {elements.map((c, index) => {
        if (index === elements.length - 1) {
          return (
            <VisibilitySensor key={c} partialVisibility scrollCheck once>
              {({ isVisible }) => (
                <LastElement
                  isVisible={isVisible}
                  incrementCount={incrementCount}
                >
                  <>{renderItem(index)}</>
                </LastElement>
              )}
            </VisibilitySensor>
          );
        }
        return <Fragment key={c}>{renderItem(index)}</Fragment>;
      })}
    </>
  );
};

export default memo<Props>(VirtualList);
