// @flow

import { NOTIFICATION_STATUS_CHANGED } from "src/constants/actionTypes";

import type { Notification as NotificationType, Action } from "src/types";

const notification = (
  state: NotificationType = { enabled: false },
  { type, payload }: Action
) => {
  switch (type) {
    case NOTIFICATION_STATUS_CHANGED:
      return { ...payload };
    default:
      return state;
  }
};

export const getIsNotificationEnabled = (state: NotificationType) =>
  state.enabled;

export default notification;
