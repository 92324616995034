// @flow

import React from "react";

type Props = {
  fill?: string
};

const Italic = ({ fill, ...restProps }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
    <title>ic_format_italic_24px</title>
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
    </g>
  </svg>
);

export default Italic;
