// @flow

import { connect } from "react-redux";

import {
  searchChatroom as handleSearch,
  resetSearch
} from "src/actions/chatroom";
import Select from "src/components/chatroom/Select";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.chatRooms.searchResults
});

export default connect(mapStateToProps, { handleSearch, resetSearch })(Select);
