// @flow

import React, { useCallback } from "react";

import useFields from "./useFields";
import { AddButton } from "./styles";

import type {
  ChecklistValue,
  RoomId,
  ChecklistField,
  ColumnId
} from "src/types";

type Props = {
  formId: ?number,
  checklistValue: ChecklistValue,
  details: ChecklistField,
  checklistId: number,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: number,
  setChecklistValue: Function,
  locked?: boolean,
  isMandatory?: boolean
};

const Pdf = ({
  formId,
  columnId,
  checklistValue,
  details,
  checklistId,
  roomId,
  fieldId,
  setChecklistValue,
  locked = false,
  isMandatory
}: Props) => {
  const { settings } = useFields({
    checklistValue,
    details
  });

  const buttonText = (settings || {}).buttonText || "Generate PDF";

  const generatePdf = useCallback(() => {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: null,
        type: "pdf",
        checked: true
      },
      progress: true,
      formId,
      columnId
    });
  }, [formId, setChecklistValue, roomId, checklistId, columnId, fieldId]);

  return (
    <AddButton
      onClick={generatePdf}
      disabled={locked}
      isMandatory={isMandatory}
    >
      {buttonText}
    </AddButton>
  );
};

export default Pdf;
