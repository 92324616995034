// @flow

import React from "react";

const CircleCheck = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 1.33333C4.32016 1.33333 1.3335 4.32 1.3335 8C1.3335 11.68 4.32016 14.6667 8.00016 14.6667C11.6802 14.6667 14.6668 11.68 14.6668 8C14.6668 4.32 11.6802 1.33333 8.00016 1.33333ZM6.66683 11.3333L3.3335 8L4.2735 7.06L6.66683 9.44667L11.7268 4.38667L12.6668 5.33333L6.66683 11.3333Z"
      fill="#108043"
    />
  </svg>
);

export default CircleCheck;
