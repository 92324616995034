// @flow

import { select, call, takeEvery, put } from "redux-saga/effects";
import * as atypes from "src/constants/actionTypes";
import type { OrgCloneState } from "src/types";
import { orgCloneWizardSteps } from "src/constants";
import { getLocation, getOrgCloneState } from "src/selectors";
import * as orgCloneActions from "src/actions/orgClone";
import * as authActions from "src/actions/auth";
import * as actions from "src/actions";
import * as authApi from "src/api/auth";
import * as orgCloneApi from "src/api/orgClone";
import forwardHandlers from "./wizard/forwardHandlers";
import backwardHandlers from "./wizard/backwardHandlers";

function* handleGoForwardRequest() {
  const { wizard }: OrgCloneState = yield select(getOrgCloneState);

  // $FlowFixMe
  const stepHandler = forwardHandlers[wizard.step];

  if (stepHandler) {
    yield call(stepHandler);
  }
}

function* watchGoForwardRequest(): any {
  yield takeEvery(
    atypes.ORG_CLONE_WIZARD_GO_FORWARD_REQUEST,
    handleGoForwardRequest
  );
}

function* handleGoBackwardRequest() {
  const { wizard }: OrgCloneState = yield select(getOrgCloneState);

  // $FlowFixMe
  const stepHandler = backwardHandlers[wizard.step];

  if (stepHandler) {
    yield call(stepHandler);
  }
}

function* watchGoBackwardRequest(): any {
  yield takeEvery(
    atypes.ORG_CLONE_WIZARD_GO_BACKWARD_REQUEST,
    handleGoBackwardRequest
  );
}

function* handleFetchTemplateRequest() {
  try {
    const {
      query: { orgId, cloneToken }
    } = yield select(getLocation);

    const res = yield call(orgCloneApi.getOrgDetails, {
      orgId,
      cloneToken
    });

    if (!res.cloneable) {
      throw new Error("Org is not cloneable");
    }

    yield put(
      orgCloneActions.fetchTemplateSuccess({
        title: res.title,
        videoLink: res.videoLink,
        description: res.description
      })
    );
  } catch (error) {
    console.error(error);
    yield put(orgCloneActions.fetchTemplateFailure({ error: error.message }));
  }
}

function* watchFetchTemplateRequest(): any {
  yield takeEvery(
    atypes.ORG_CLONE_FETCH_TEMPLATE_REQUEST,
    handleFetchTemplateRequest
  );
}

function* verifyCloneLink() {
  try {
    const {
      query: { orgId, cloneToken }
    } = yield select(getLocation);

    yield call(orgCloneApi.validateCloneLink, {
      orgId,
      cloneToken
    });
  } catch (error) {
    console.error(error);
    yield put(orgCloneActions.fetchTemplateFailure({ error: error.message }));
  }
}

function* signedInHandler({ payload }) {
  try {
    const { email } = payload;

    const { displayName: name } = yield call(authApi.getCurrentUser);

    yield put(authActions.setCurrentUser({ name, email }));
    yield put(orgCloneActions.setStep(orgCloneWizardSteps.createOrg));
  } catch (error) {
    console.error(error);
  }
}

function* signedOutHandler() {
  const { wizard }: OrgCloneState = yield select(getOrgCloneState);
  const email = wizard.fields.email.value;

  if (email) {
    const isUser = yield call(authApi.isExistingUser, email);
    if (isUser) {
      yield put(orgCloneActions.setStep(orgCloneWizardSteps.signIn));
    } else {
      yield put(orgCloneActions.setStep(orgCloneWizardSteps.signUp));
    }
  } else {
    yield put(orgCloneActions.setStep(orgCloneWizardSteps.email));
  }
}

function* handleAuthStateChange(action) {
  const location = yield select(getLocation);

  if (location.type !== atypes.ORG_CLONE_PAGE) return;

  yield call(verifyCloneLink);

  if (location.query.email) {
    yield put(
      orgCloneActions.updateData({
        prop: ["email"],
        value: location.query.email
      })
    );
  }

  if (action.type === atypes.SIGNED_IN) yield call(signedInHandler, action);
  if (action.type === atypes.SIGNED_OUT) yield call(signedOutHandler, action);

  const { template }: OrgCloneState = yield select(getOrgCloneState);
  if (!template.data) yield put(orgCloneActions.fetchTemplateRequest());
}

function* watchAuthState(): any {
  yield takeEvery([atypes.SIGNED_IN, atypes.SIGNED_OUT], handleAuthStateChange);
}

function* handleLogout() {
  const location = yield select(state => state.location);

  yield put({
    type: atypes.ORG_CLONE_PAGE,
    meta: {
      query: {
        cloneToken: location.query.cloneToken,
        orgId: location.query.orgId
      }
    }
  });

  yield put(actions.logout());
}

function* watchLogoutRequest(): any {
  yield takeEvery(atypes.ORG_CLONE_LOGOUT_REQUEST, handleLogout);
}

export default [
  watchGoForwardRequest(),
  watchGoBackwardRequest(),
  watchAuthState(),
  watchFetchTemplateRequest(),
  watchLogoutRequest()
];
