// @flow

import styled from "@emotion/styled";

import { colors as colorsOld } from "./constants";
import * as colors from "./constants/colors";

const Required = styled.span`
  :after {
    content: "* ";
  }
  color: ${colorsOld.red};
`;

const Placeholder = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
  margin: 3px 5px;
`;

const SubHeading = styled.div`
  color: ${colors.grey5};
  font-size: 0.625rem;
`;

export { Required, Placeholder, SubHeading };
