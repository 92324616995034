// @flow

import React from "react";
import type { PrivacyAutomationData, PrivacyModes } from "src/types";

import { FormControl, FormLabel, Text, VStack } from "@chakra-ui/react";
import UserSelectMultiple from "src/containers/user/SelectMultiple.new";
import { Select, Option } from "src/components/ChakraPro/Select";

const PrivacyModeSelect = Select<PrivacyModes>();

type Props = {
  value: PrivacyAutomationData,
  onChange: Function
};

const options = [
  { id: "noChange", label: "Don't change" },
  { id: "full", label: "Hide everything" },
  { id: "content", label: "Hide only chat content" },
  { id: "none", label: "Make Public" }
];

export default function PrivacyMode({ value, onChange }: Props) {
  return (
    <VStack
      sx={{
        alignItems: "start",
        width: "100%"
      }}
    >
      <FormControl
        as={VStack}
        sx={{
          alignItems: "start"
        }}
      >
        <FormLabel fontSize="sm">Update Privacy</FormLabel>

        <PrivacyModeSelect
          colorScheme="blue"
          value={value.mode}
          onChange={newMode =>
            onChange({
              mode: newMode
            })
          }
          placeholder="Select privacy setting"
          size="sm"
          sx={{ width: "380px" }}
        >
          {options.map(item => (
            <Option value={item.id} key={item.id}>
              <Text textStyle="label" value={item.id}>
                {item.label}
              </Text>
            </Option>
          ))}
        </PrivacyModeSelect>
      </FormControl>

      {["full", "content"].includes(value.mode) && (
        <FormControl sx={{ width: "100%" }}>
          <FormLabel fontSize="sm">Whitelist</FormLabel>
          <UserSelectMultiple
            value={value.whitelistedUsers}
            onChange={(action, user) => onChange({ user, action })}
            inputPlaceholder="Select users"
          />
        </FormControl>
      )}
    </VStack>
  );
}
