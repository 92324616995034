// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PdfHighlighter {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.PdfHighlighter__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__tip-container {
  z-index: 6;
  position: absolute;
}

.PdfHighlighter--disable-selection {
  user-select: none;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/PdfHighlighter.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,OAAO;AACT;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".PdfHighlighter {\n  position: absolute;\n  overflow: auto;\n  width: 100%;\n  height: 100%;\n}\n\n.PdfHighlighter__highlight-layer {\n  position: absolute;\n  z-index: 3;\n  left: 0;\n}\n\n.PdfHighlighter__tip-container {\n  z-index: 6;\n  position: absolute;\n}\n\n.PdfHighlighter--disable-selection {\n  user-select: none;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
