// @flow

import React from "react";
import { connect } from "react-redux";

import Reply from "src/components/chatroom/FileReply";

import { getFile as resolveFile } from "src/actions/file";

import { getFile as fileSelector } from "src/reducers";

import type {
  AppState,
  UnifizeFile,
  FileNotification as Message
} from "src/types";

type Props = {
  file: UnifizeFile,
  getFile: Function,
  message: Message
};

const FileReply = ({ file }: Props) => <Reply {...{ file }} />;

const mapStateToProps = ({ app }: { app: AppState }, { message }: Props) => ({
  file: fileSelector(app, message.data.name)
});

export default connect(mapStateToProps, {
  getFile: resolveFile
})(FileReply);
