// @flow

import React from "react";
import * as R from "ramda";

import { UserSmall as StyledUser, InlineProfileImage } from "src/styles";
import { UserWithEmail } from "src/styles/user";

import type { UnifizeUser } from "src/types";

const User = ({ user, children }: { user: UnifizeUser, children: ?any }) => (
  <>
    {user.photoUrl ? (
      <UserWithEmail>
        {children}
        <InlineProfileImage src={user.photoUrl} />
        <div className="wrapper-user">
          <div>
            <b>{user.displayName}</b>
          </div>
          <div>{user.email}</div>
        </div>
      </UserWithEmail>
    ) : (
      <UserWithEmail key={user.uid}>
        {children}
        <StyledUser>
          {R.head(user.displayName || user.email || "")}
          {R.head(R.split(" ", user.displayName || "")[1] || "")}
        </StyledUser>
        <div className="wrapper-user">
          <div>
            <b>{user.displayName}</b>
          </div>
          <div>{user.email}</div>
        </div>
      </UserWithEmail>
    )}
  </>
);

export default User;
