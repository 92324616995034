// @flow

import React, { useState, useEffect, useReducer } from "react";
import Header from "./Header";
import AppCard from "./AppCard";
import { SearchInput } from "src/components/Unifize";
import {
  setIntegrations,
  installIntegration,
  updateIntegration,
  deleteIntegration
} from "src/actions/integrations";
import type { AppIntegration } from "src/types";
import { apps, initialState } from "src/reducers/integrations";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import {
  PageContainer,
  AppListContainer,
  AppNotFound,
  SearchContainer
} from "./styles";
import { Text, Skeleton, Stack } from "@chakra-ui/react";
import {
  getIntegrations,
  editIntegration,
  removeIntegration
} from "src/api/integrations";
import { toast } from "react-toastify";

const Integrations = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [integrations, dispatch] = useReducer(apps, initialState);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    setItems(integrations);
    setFilteredItems(integrations);
  }, [integrations]);

  const fetchIntegrations = async () => {
    try {
      const response: Array<AppIntegration> = await getIntegrations();
      if (response) {
        dispatch(setIntegrations(response));
        setLoading(false);
      }
    } catch (e) {
      toast.error("Unable to fetch integrations");
      setLoading(false);
      console.error(e);
    }
  };

  const onInstall = (app: string) => {
    dispatch(installIntegration(app));
    const id: ?number = integrations.find(item => item.title === app)?.id;
    const title: ?string = integrations.find(item => item.title === app)
      ?.displayTitle;
    if (title) {
      toast.success(`${title} integration installed`);
    }
    if (id) {
      handleUpdate({ id, status: false });
    }
  };

  const handleUpdate = async ({
    id,
    status,
    display
  }: {
    id: number,
    status: boolean,
    display?: string
  }) => {
    try {
      const response: AppIntegration = await editIntegration(id, status);
      if (response) {
        dispatch(updateIntegration(id, response));
        if (display) {
          if (status) {
            toast.error(`${display} integration disabled`);
          } else {
            toast.success(`${display} integration enabled`);
          }
        }
      }
    } catch (e) {
      toast.error("Updating integration failed");
      console.error(e);
    }
  };

  const handleDisconnect = async ({
    id,
    display
  }: {
    id: number,
    display: string
  }) => {
    try {
      const response = await removeIntegration(id);
      if (response) {
        dispatch(deleteIntegration(id));
        toast.error(`${display} integration disconnected`);
      }
    } catch (e) {
      toast.error("Disconnecting integration failed");
      console.error(e);
    }
  };

  const handleSearch = keyword => {
    const filtered: Array<AppIntegration> = items.filter(app =>
      app.title.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <PageContainer>
      <Header
        title="Apps"
        description="Integrate with your favorite apps and supercharge your workflow"
        showAction={false}
      />
      <SearchContainer>
        <SearchInput placeholder="Search" onInput={handleSearch} />
      </SearchContainer>
      <AppListContainer>
        {filteredItems.map(app => (
          <AppCard
            key={app.id}
            id={app.id}
            display={app.displayTitle}
            title={app.title}
            isDisabled={app.disabled}
            isInstalled={app.installed}
            url={app.authorizationUrl}
            isLoading={isLoading}
            onInstall={onInstall}
            onUpdate={handleUpdate}
            onDisconnect={handleDisconnect}
          />
        ))}
        {!isLoading && filteredItems.length === 0 && (
          <AppNotFound>
            <Text textStyle="label">No apps found</Text>
          </AppNotFound>
        )}
      </AppListContainer>
      {isLoading && (
        <Stack p={spacing.space_m}>
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton
              key={index}
              borderRadius="md"
              height="72px"
              startColor={colors.grey28}
              endColor={colors.grey4}
            />
          ))}
        </Stack>
      )}
    </PageContainer>
  );
};

export default Integrations;
