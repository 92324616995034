// @flow

import * as R from "ramda";

export const getChipWidth = (fieldWidth: number) => {
  if (fieldWidth) {
    if (fieldWidth < 360) return `${fieldWidth / 9}px`;
    else if (fieldWidth < 600) return `${fieldWidth / 9}px`;
    else if (fieldWidth < 900) return `${fieldWidth / 8}px`;
    else return `${fieldWidth / 8}px`;
  }
};

/**
 * Function to filter based on current versions, used
 * as alternative to filterCurrentVersions selector to
 * filter data fetched from server on demand.
 * @param {Object} chatRooms - ChatRooms byId
 * @param {Array} results - List of room ids
 * @param {Array} selectedVersions - Selected versions of room ids
 */
export const filterCurrentVersionsFromServer = (
  chatRooms: Object,
  results: number[],
  selectedVersions: ?(number[])
): number[] => {
  return R.filter(roomId => {
    const room = !R.isEmpty(chatRooms) ? chatRooms.get(roomId) : {};

    if (!room?.currentVersion) return false;

    if (!selectedVersions) return true;

    const versionSibling = selectedVersions.find(versionId =>
      !R.isEmpty(chatRooms)
        ? chatRooms.get(versionId)?.seqNo === room?.seqNo
        : false
    );

    return !versionSibling;
  }, results);
};
