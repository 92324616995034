// @flow

import { connect } from "react-redux";
import React, { useState, useCallback, useRef } from "react";

import { BulkChange as StyledBulkChange } from "./styles";
import Calendar from "src/components/date/Calendar";
import autoPosition from "src/components/autoPosition";
import { bulkUpdateProcess } from "src/actions/workflows";

type Props = {
  _bulkUpdateProcess: Function
};

const CalendarSelector = autoPosition(Calendar, {
  bottomOffset: "0px",
  topOffset: "30px",
  rightOffset: "-200px",
  position: "top"
});

const BulkChange = ({ _bulkUpdateProcess }: Props) => {
  const outerRef = useRef(null);
  const [dropdown, showDropdown] = useState(false);
  const handleDropdown = useCallback(() => {
    showDropdown(!dropdown);
  }, [dropdown, showDropdown]);

  const handleDueDate = useCallback(
    (options: Object) => {
      _bulkUpdateProcess({
        attrs: {
          dueDate: options
        }
      });
    },
    [_bulkUpdateProcess]
  );

  return (
    <StyledBulkChange
      tabIndex="0"
      ref={outerRef}
      onClick={handleDropdown}
      onKeyPress={handleDropdown}
    >
      {dropdown ? (
        <CalendarSelector
          handleChange={handleDueDate}
          outerRef={outerRef.current}
          handleClose={handleDropdown}
        />
      ) : null}
    </StyledBulkChange>
  );
};

export default connect(null, {
  _bulkUpdateProcess: bulkUpdateProcess
})(BulkChange);
