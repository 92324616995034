// @flow

import styled from "@emotion/styled";

import { Table as DefaultTable } from "src/styles/table";
import * as spacing from "src/styles/constants/spacing";

const Table = styled(DefaultTable)`
  tbody {
    max-height: calc(100vh - 16em);
  }
  > tbody > tr > td,
  > thead > tr > td {
    :first-of-type {
      width: 35%;
    }

    :nth-of-type(2) {
      width: 32%;
      > span {
        width: 20vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const UploadedBy = styled.td`
  > span > span > span {
    font-weight: normal;
  }
`;

const Display = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableContainer = styled.div`
  width: calc(100vw - 4em);
  height: calc(100vh - 16em);
  overflow: auto;
  position: relative;
  z-index: 0;
  margin-left: -${spacing.space_l};
`;

export { Table, UploadedBy, TableContainer, Display };
