// @flow

import * as atypes from "src/constants/actionTypes";

import type {
  Action,
  Report,
  ReportModal,
  ExpansionState,
  ProcessTemplateId
} from "src/types";

const setReports = (
  id: number | string,
  meta?: { query?: Object }
): Action => ({
  type: atypes.SET_REPORTS_REQUEST,
  payload: { id },
  meta
});

const createReport = (report: Report, expansionState: ExpansionState) => ({
  type: atypes.CREATE_REPORT_REQUEST,
  payload: {
    report,
    expansionState
  }
});

const updateReport = (report: Report) => ({
  type: atypes.EDIT_REPORT_REQUEST,
  payload: report
});

export const updateReportTitle = () => ({
  type: atypes.UPDATE_REPORT_TITLE_REQUEST,
  payload: {}
});

const deleteReport = (payload: { reportId: string }) => ({
  type: atypes.DELETE_REPORT_REQUEST,
  payload
});

const fetchAllReports = () => ({
  type: atypes.GET_REPORTS_REQUEST,
  payload: {}
});

const navigateToProcess = (payload: Report) => ({
  type: atypes.REPORTS_TO_PROCESS,
  payload
});

const showNewReportsModal = (modalInfo: ReportModal) => ({
  type: atypes.SHOW_REPORTS_SAVE_MODAL,
  payload: { modalInfo }
});

const hideNewReportsModal = () => ({
  type: atypes.HIDE_REPORTS_SAVE_MODAL
});

const setActiveReport = (reportId: string | number) => ({
  type: atypes.SET_ACTIVE_REPORT_REQUEST,
  reportId
});

export const reportModalAttributes = (value: Object) => ({
  type: atypes.SET_REPORT_MODAL_ATTRIBUTES,
  payload: value
});

export const editReportModal = (reportId: string) => ({
  type: atypes.EDIT_REPORT_MODAL,
  payload: {
    reportId
  }
});

export const saveChangesToReport = () => ({
  type: atypes.SAVE_CHANGES_TO_REPORT,
  payload: {}
});

export const resetWorkflowInstanceFilter = () => ({
  type: atypes.RESET_WORKFLOW_INSTANCE_FILTER
});

/**
 * Gets the details of the embedded fields
 * @param {number} templateId - ID of the process template
 */
export const getAllEmbeddedFields = (templateId: ProcessTemplateId) => ({
  type: atypes.GET_EMBEDDED_FIELD_DETAILS,
  payload: {
    templateId
  }
});

export {
  createReport,
  deleteReport,
  updateReport,
  fetchAllReports,
  setReports,
  navigateToProcess,
  showNewReportsModal,
  hideNewReportsModal,
  setActiveReport
};
