// @flow

import { useSelector } from "react-redux";
import React from "react";

import {
  getChecklistFieldPosition,
  getCurrentChecklistBuilder
} from "src/reducers";
import ConditionsBuilder from "../../Conditions";

type Props = {
  selectedFormId: number,
  selectedFormField: number,
  setLocalSettings: Function
};

const Builder = ({
  selectedFormId,
  selectedFormField,
  setLocalSettings
}: Props) => {
  const checklistFieldPosition = useSelector(({ app }) =>
    getChecklistFieldPosition(app)
  );
  const currentFields = useSelector(
    ({ app }) => getCurrentChecklistBuilder(app).fields
  );

  const saveSettings = (settings: any) => {
    const oldSettings = currentFields[checklistFieldPosition].settings;

    const conditionsByForm = oldSettings.conditionsByForm || {};
    const selectedFormConditions =
      oldSettings?.conditionsByForm?.[selectedFormId] || {};

    const newSettings = {
      ...oldSettings,
      conditionsByForm: {
        ...conditionsByForm,
        [selectedFormId]: {
          ...selectedFormConditions,
          [selectedFormField]: { ...settings }
        }
      }
    };

    setLocalSettings(newSettings);
  };
  return (
    <ConditionsBuilder
      saveSettings={saveSettings}
      position={checklistFieldPosition}
      currentFieldId={selectedFormField}
      selectedFormId={selectedFormId}
      selectedFormField={selectedFormField}
    />
  );
};

export default Builder;
