// @flow

import React from "react";
import { useSelector } from "react-redux";
import type { Revision, UID } from "src/types";
import { getCurrentUserId } from "src/reducers";
import { getDateTimeFromString } from "src/utils";
import useBoolean from "src/hooks/useBoolean";

import { VStack, Text, Button, HStack } from "@chakra-ui/react";

import SignatureRecord from "./SignatureRecord";
import { expandedStyles } from "./Card";

type Props = {
  comment: $PropertyType<Revision, "comment">,
  date: string,
  author: UID
};

const Cancellation = ({ author, date, comment }: Props) => {
  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));

  const { value: showDetails, toggleBoolean: toggleDetails } = useBoolean(
    false
  );

  const boxExpandedStyles = showDetails ? expandedStyles : null;

  return (
    <VStack {...boxExpandedStyles} w="100%">
      <HStack
        justifyContent="space-between"
        backgroundColor="unifize_sky"
        w="100%"
        px={2}
        py={1}
      >
        <Text m={0} color="unifize_red400">
          Cancellation - {getDateTimeFromString(date, false)}
        </Text>

        <Button
          onClick={toggleDetails}
          variant="link"
          size="xs"
          bg="transparent"
          color="unifize_blue"
          _focus={{
            boxShadow: null
          }}
        >
          {showDetails ? "Hide" : "Show"}
        </Button>
      </HStack>

      {showDetails && (
        <SignatureRecord
          type="cancellation"
          author={author}
          comment={comment}
          date={date}
          currentUserId={currentUserId}
        />
      )}
    </VStack>
  );
};

export default Cancellation;
