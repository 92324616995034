// @flow

import React from "react";

import {
  SectionInfoWrapper,
  WorkflowTitleWrapper,
  SeqWrapper,
  WorkflowWrapper
} from "../styles";
import SectionedFiles from "./SectionedFiles";
import WorkflowName from "src/containers/workflow/Name";

type Props = {
  searchedGroupFiles: any,
  setOption?: Function,
  chooseFile?: Function,
  roomIds: any,
  searchedGroupedFiles: any
};

const SectionedFileLists = ({
  searchedGroupFiles,
  setOption,
  chooseFile,
  roomIds,
  searchedGroupedFiles
}: Props) =>
  roomIds.map((id: number, index: number) => {
    const templateId = searchedGroupFiles.templateId[index];
    const seqNo = searchedGroupFiles.seqNo[index];
    const fileNotAssositated = templateId === "";

    return (
      <>
        <SectionInfoWrapper>
          <WorkflowWrapper>
            {fileNotAssositated ? "Others" : <WorkflowName id={templateId} />}
          </WorkflowWrapper>
          <SeqWrapper>{seqNo && `#${seqNo}:`}</SeqWrapper>
          <WorkflowTitleWrapper>
            {searchedGroupFiles.title[index]}
          </WorkflowTitleWrapper>
        </SectionInfoWrapper>
        <SectionedFiles
          searchedGroupedFiles={searchedGroupedFiles}
          setOption={setOption}
          chooseFile={chooseFile}
          roomId={id}
          key={id}
        />
      </>
    );
  });

export default SectionedFileLists;
