// @flow

import * as atypes from "src/constants/actionTypes";

import type {
  Action,
  RoomId,
  EmailModalData,
  EmailRecipient,
  ConversationFile
} from "src/types";

export const sendEmail = (skipInvites: boolean): Action => ({
  type: atypes.SEND_EMAIL_REQUEST,
  payload: skipInvites
});

const uploadTempAttachment = (file: Object, dispatch: Function): Action => ({
  type: atypes.ATTACH_FILE_TO_EMAIL_REQUEST,
  payload: { file, dispatch }
});

const clearAttachment = (name: string): Action => ({
  type: atypes.CLEAR_ATTACHMENT,
  payload: { name }
});

const addAttachment = (file: ConversationFile): Action => ({
  type: atypes.ADD_ATTACHMENT,
  payload: file
});

const generateChatroomEmail = (chatroomId: RoomId): Action => ({
  type: atypes.GENERATE_CHATROOM_EMAIL_REQUEST,
  payload: { chatroomId }
});

const clearTempAttachment = (name: string): Action => ({
  type: atypes.CLEAR_TEMP_ATTACHMENT,
  payload: { name }
});

const clearAllTempAttachments = (): Action => ({
  type: atypes.CLEAR_ALL_TEMP_ATTACHMENTS,
  payload: {}
});

const saveEmailData = (data: EmailModalData): Action => ({
  type: atypes.SAVE_EMAIL_MODAL_DATA,
  payload: data
});

export const setEmailData = (payload: {
  prop: string,
  value: ?any
}): Action => ({
  type: atypes.SET_EMAIL_MODAL_DATA,
  payload
});

export const addRecipient = (payload: {
  prop: string,
  value: EmailRecipient
}): Action => ({
  type: atypes.ADD_EMAIL_RECIPIENT,
  payload
});

export const removeRecipient = (payload: {
  prop: string,
  value: EmailRecipient
}): Action => ({
  type: atypes.REMOVE_EMAIL_RECIPIENT,
  payload
});

export const setRecipientRole = (payload: {
  email: string,
  role: string
}): Action => ({
  type: atypes.SET_RECIPIENT_ROLE,
  payload
});

const clearEmailData = (): Action => ({
  type: atypes.CLEAR_EMAIL_MODAL_DATA,
  payload: {}
});

export {
  uploadTempAttachment,
  clearAttachment,
  generateChatroomEmail,
  addAttachment,
  clearTempAttachment,
  clearAllTempAttachments,
  saveEmailData,
  clearEmailData
};
