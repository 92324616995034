// @flow

import { connect } from "react-redux";
import React, { useRef } from "react";

import DeleteModal from "../DeleteModal";
import { MenuBar as StyledMenuBar } from "../styles";
import Dropdown from "./Dropdown";
import { setDashboard } from "src/actions/dashboard";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

import type { AppState } from "src/types";

type Props = {
  id: number,
  _setDashboard: Function
};

const MenuBar = ({ id, _setDashboard }: Props) => {
  const outerRef = useRef(null);
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean(false);
  const { value: showModal, toggleBoolean: toggleModal } = useBoolean(false);

  return (
    <>
      <StyledMenuBar ref={outerRef}>
        <button type="button" onClick={toggleDropdown}>
          <Icon type="ellipsis" boxSize={5} color={colors.primary} />
        </button>
        {dropdown && (
          <Dropdown
            id={id}
            outerRef={outerRef}
            handleClose={toggleDropdown}
            toggleModal={toggleModal}
            _setDashboard={_setDashboard}
          />
        )}
      </StyledMenuBar>
      {showModal && <DeleteModal id={id} handleClose={toggleModal} />}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: app.dashboard.current
});

export default connect(mapStateToProps, {
  _setDashboard: setDashboard
})(MenuBar);
