// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import { Search as StyledSearch } from "./styles";
import { Input } from "src/styles/input";
import { Item as StyledItem } from "src/styles/table";
import { searchDepartment, setContact } from "src/actions/user";
import { searchRoles } from "src/actions/roleManagement";
import { searchGroup } from "src/actions/group";
import GroupName from "src/components/Group/Name";
import DepartmentName from "src/containers/department/Name";
import RoleTitle from "src/components/Role/Title";
import Checkbox from "src/components/Checkbox";

import type { AppState, ContactFilter } from "src/types";

type Props = {
  column: string,
  values: Array<number>,
  filter: ContactFilter,
  _searchDepartment: Function,
  _searchGroup: Function,
  _setContact: Function,
  searchRoles: Function
};

const UniqueValues = ({
  column,
  values = [],
  filter,
  _searchDepartment,
  _searchGroup,
  _setContact,
  searchRoles
}: Props) => {
  const preventDefault = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDepartmentSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      _searchDepartment(value);
    },
    [_searchDepartment]
  );

  const handleGroupSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      _searchGroup(value, { peoplesTable: true });
    },
    [_searchGroup]
  );

  const handleRoleSearch = (e: SyntheticInputEvent<HTMLFormElement>) => {
    searchRoles(e.target.value);
  };

  const handleFilter = useCallback(
    (value: number | string) => {
      if (R.includes(value, filter[column] || [])) {
        const newValue = R.filter(
          oldValue => oldValue !== value,
          filter[column]
        );

        _setContact({ query: { ...filter, [column]: newValue } });
      } else {
        const newValue = [...(filter[column] || []), value];
        _setContact({ query: { ...filter, [column]: newValue } });
      }
    },
    [_setContact, filter, column]
  );

  const seletedValues = filter[column] || [];

  switch (column) {
    case "department":
      return (
        <StyledSearch onClick={preventDefault}>
          <Input
            type="text"
            placeholder="Search Departments"
            onChange={handleDepartmentSearch}
            autoFocus
          />
          <ul>
            {values.map(department => (
              <StyledItem
                key={department}
                onClick={() => handleFilter(department)}
              >
                <Checkbox
                  id={`people${department}`}
                  handleChange={() => handleFilter(department)}
                  checked={R.includes(department, seletedValues)}
                />
                <span>
                  <DepartmentName id={department} />
                </span>
              </StyledItem>
            ))}
          </ul>
        </StyledSearch>
      );
    case "group":
      return (
        <StyledSearch onClick={preventDefault}>
          <Input
            type="text"
            placeholder="Search Groups"
            onChange={handleGroupSearch}
            autoFocus
          />
          <ul>
            {values.map(group => (
              <StyledItem key={group} onClick={() => handleFilter(group)}>
                <Checkbox
                  id={`people${group}`}
                  handleChange={() => handleFilter(group)}
                  checked={R.includes(group, seletedValues)}
                />
                <span>
                  <GroupName id={group} />
                </span>
              </StyledItem>
            ))}
          </ul>
        </StyledSearch>
      );
    case "orgRole":
      return (
        <StyledSearch onClick={preventDefault}>
          <Input
            type="text"
            placeholder="Search Roles"
            onChange={handleRoleSearch}
            autoFocus
          />
          <ul>
            {values.map(role => (
              <StyledItem key={role} onClick={() => handleFilter(role)}>
                <Checkbox
                  id={`role${role}`}
                  handleChange={() => handleFilter(role)}
                  checked={R.includes(role, seletedValues)}
                />
                <RoleTitle roleId={role} />
              </StyledItem>
            ))}
          </ul>
        </StyledSearch>
      );
    case "status":
      return (
        <ul onClick={preventDefault}>
          {values.map(status => (
            <StyledItem key={status} onClick={() => handleFilter(status)}>
              <Checkbox
                id={`status${status}`}
                handleChange={() => handleFilter(status)}
                checked={R.includes(status, seletedValues)}
              />
              {status}
            </StyledItem>
          ))}
        </ul>
      );
    default:
      return <></>;
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.users.contactFilter
});

export default connect(mapStateToProps, {
  _searchDepartment: searchDepartment,
  _searchGroup: searchGroup,
  _setContact: setContact,
  searchRoles
})(UniqueValues);
