// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Normal = createIcon({
  displayName: "NormalIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="none"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M6.923.954L.953 6.923a1.523 1.523 0 0 0 0 2.154l5.97 5.97c.595.594 1.56.594 2.154 0l5.97-5.97a1.523 1.523 0 0 0 0-2.154L9.076.953a1.523 1.523 0 0 0-2.154 0zM5.462 6.477h5.077M5.462 9.523h5.077"
    />
  )
});

export default Normal;
