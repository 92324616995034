// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import CloseMenu from "src/containers/Close";

import type { AppState } from "src/types";

type Props = {
  chartId: number,
  dashboard: number
};

const Close = ({ dashboard, chartId }: Props) => {
  if (!R.isNil(dashboard) || !R.isNil(chartId)) {
    return <></>;
  }

  return <CloseMenu />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  dashboard: app.dashboard.current,
  chartId: app.workflow.additionalFilters.chartId
});

export default connect(mapStateToProps)(Close);
