// @flow

import React, { useState } from "react";

import Modal from "src/containers/filter/NavBar/Modal";

const NavBarModal = () => {
  const [isProfileMenu, setProfileMenu] = useState(false);
  const closeProfileMenu = () => setProfileMenu(false);
  return <Modal closeMenu={closeProfileMenu} />;
};

export default NavBarModal;
