// @flow

import React from "react";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import { toast } from "react-toastify";
import * as R from "ramda";
import { Table } from "@chakra-ui/react";

import { Builder as StyledBuilder } from "src/styles/popover.old";
import { Form } from "src/styles/form.old";
import { Fields as StyledFields } from "src/styles/checklist";
import SortableList from "./SortableList";
import {
  setChecklistBuilderAttributes,
  setChecklistBuilderAttribute
} from "src/actions/checklistBuilder";
import { getCurrentChecklistBuilder } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  current: any,
  setChecklistBuilderAttributes: Function,
  setChecklistBuilderAttribute: Function
};

const Deleted = ({
  current,
  setChecklistBuilderAttributes,
  setChecklistBuilderAttribute
}: Props) => {
  const { deletedFields } = current;

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number,
    newIndex: number
  }) => {
    setChecklistBuilderAttribute({
      id: "deletedFields",
      value: arrayMove(deletedFields, oldIndex, newIndex)
    });
  };

  const restoreField = (event: any, index: number) => {
    event.preventDefault();

    // Removes old deletedBy and deletedAt property
    const restoredField = R.mergeDeepRight(deletedFields[index], {
      deleted: false,
      deletedBy: null,
      deletedAt: null
    });

    // Remove the field from current.deletedFields
    const updatedDeletedFields = deletedFields.filter(
      (deletedField, idx) => idx !== index
    );

    // Append to current.fields
    const presentFields = [...current.fields, restoredField];

    setChecklistBuilderAttributes({
      value: { fields: presentFields, deletedFields: updatedDeletedFields }
    });
  };

  const updateField = (
    value: any,
    position: number,
    type: string,
    callback?: Function
  ) => {
    let formattedValue = value;

    if (type === "fieldName") {
      formattedValue = value.replace(/\0/g);

      if (formattedValue.length !== value.length) {
        toast.info("Some character(s) could not be recognized.");
      }
    }

    const newDeletedFields = current.deletedFields.map((field, idx) => {
      if (idx === position) {
        return {
          ...field,
          [type]: formattedValue
        };
      }

      return field;
    });

    setChecklistBuilderAttribute({
      id: "deletedFields",
      value: [...newDeletedFields]
    });

    callback && callback();
  };

  return (
    <StyledBuilder>
      <Form>
        <StyledFields>
          <Table variant="unstyled" size="sm">
            <SortableList
              items={deletedFields}
              updateField={updateField}
              restoreField={restoreField}
              onSortEnd={onSortEnd}
              useDragHandle
            />
          </Table>
        </StyledFields>
      </Form>
    </StyledBuilder>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: getCurrentChecklistBuilder(app)
});

export default connect(mapStateToProps, {
  setChecklistBuilderAttributes,
  setChecklistBuilderAttribute
})(Deleted);
