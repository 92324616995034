// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Attach = createIcon({
  displayName: "BulbIcon",
  viewBox: "0 0 14 14",
  path: (
    <path
      d="M7.48139 4.4397C7.66969 4.27113 7.97097 4.27481 8.15432 4.44792C8.33768 4.62104 8.33367 4.89802 8.14538 5.06659L5.29139 7.62155C4.83452 8.03055 4.83452 8.68939 5.29139 9.099C5.75534 9.51434 6.51339 9.51434 6.97803 9.099L10.8397 5.64193C11.9461 4.65141 11.9461 3.05068 10.8397 2.06015C9.72615 1.06328 7.91597 1.06328 6.80247 2.06012L2.60551 5.8182C0.850365 7.38945 0.850365 9.9323 2.60551 11.5043C4.36774 13.0819 7.2303 13.0819 8.99334 11.5044L12.8541 8.04729C13.0423 7.8787 13.3436 7.88235 13.527 8.05544C13.7104 8.22853 13.7064 8.50552 13.5181 8.67411L9.65729 12.1313C7.5246 14.0396 4.07326 14.0396 1.94144 12.1311C-0.197285 10.2156 -0.197285 7.10603 1.94149 5.19134L6.13845 1.43326C7.62152 0.10558 10.0206 0.10558 11.5037 1.43326C12.9938 2.76728 12.9938 4.9348 11.5037 6.26882L7.64176 9.72611C6.80739 10.472 5.46081 10.472 4.62716 9.72566C3.78692 8.97236 3.78692 7.74708 4.62741 6.99466L7.48139 4.4397Z"
      fill="#1581BA"
    />
  )
});

export default Attach;
