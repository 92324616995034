// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import { manageViewFieldWidthMap } from "src/constants/processInstanceColumns";

export const TableWrapper = styled.div`
  height: calc(100vh - 224px);
  width: 100%;
  overflow: auto;
  position: relative;
  ::-webkit-scrollbar-track {
    margin-left: 304px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  /* table rows */
  > tr {
    border-bottom: 1px solid ${colors.grey2};
    /* table columns */
    > td {
      border-right: 1px solid ${colors.grey2};
      > table > tbody > tr > td > table > tbody > tr:not(:only-child) {
        border-bottom: 1px solid ${colors.grey2};
        :last-child {
          border-bottom: none;
        }
      }
      /* embedded linked fields */
      > table > tbody > tr:has(th) {
        border-bottom: 1px solid ${colors.grey2};
        :last-child {
          border-bottom: none;
        }
      }
      /* for embedded linked fields on related conversations inside forms */
      > table
        > tbody
        > tr
        > td
        > table
        > tbody
        > tr
        > td
        > table
        > tbody
        > tr:has(th) {
        border-bottom: 1px solid ${colors.grey2};
        :last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

export const NestedTable = styled.table`
  border-collapse: collapse;
  height: 100%;
`;

export const NestedTableContainer = styled.td``;

export const Header = styled.thead`
  display: flex;
  width: fit-content;
  white-space: nowrap;
  z-index: 2;
  position: sticky;
  top: 0;
  height: 42px;
`;

export const HeaderItem = styled.th`
  position: relative;
  min-width: ${props => props.minWidth ?? "50px"};
  width: ${props => props.width};
  border-right: 1px solid ${colors.grey2};
  border-bottom: 1px solid ${colors.grey2};
  padding: ${spacing.space_m};
  background-color: ${colors.grey20};
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  cursor: ${props => (props.isResizing ? "col-resize" : "pointer")};
  height: 40px;
  pointer-events: ${props => (props.isResizing ? "none" : "auto")};
  ${props =>
    props.childColumn &&
    `
      height: 24px;
    `}
  > svg {
    height: 1.3em;
    width: 1.3em;
    padding: 2px;
    border-radius: 2px;
    background-color: ${colors.grey2};
    margin-left: 4px;

    > path {
      fill: ${colors.primary};
    }
  }

  > div:nth-child(1) {
    max-width: 80%;
  }
`;

export const Footer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const PageButton = styled.button`
  border: none;
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
`;

export const PageIndicatorContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const PageInput = styled.input`
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  width: 4rem;
`;

export const ConversationHeaderItem = styled(HeaderItem)`
  ${props =>
    props.fieldExpanded &&
    `
    height: 24px;
  `}
`;

export const ChecklistHeaderIconsContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  > svg {
    height: 1.3em;
    width: 1.3em;
    padding: 2px;
    border-radius: 2px;
    background-color: ${colors.grey2};

    > path {
      fill: ${colors.primary};
    }
  }
`;

export const ChecklistHeader = styled.div`
  max-width: 98%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CenteredHeaderItem = styled(HeaderItem)`
  text-align: center;
  justify-content: center;
`;

export const StickyHeaderItem = styled(HeaderItem)`
  position: sticky;
  left: 0;
  pointer-events: ${props => (props.isResizing ? "none" : "auto")};
`;

export const Selector = styled(StickyHeaderItem)`
  left: 0;
  z-index: 2;
  min-width: 41px;
`;

export const SeqNo = styled(StickyHeaderItem)`
  text-align: center;
  left: 36px;
  z-index: 2;
`;

export const Title = styled(StickyHeaderItem)`
  /* position of seqNo (36px) + width of seqNo (50px) */
  left: 86px;
  z-index: 2;
  max-width: ${props => props.width};
  min-width: ${manageViewFieldWidthMap.title};
`;

export const NoInstances = styled.h1`
  text-align: center;
  height: 200px;
  color: ${colors.secondary};
  position: absolute;
  left: 30%;
  top: 54%;
`;

export const Loader = styled.div`
  > div {
    display: grid;
    grid-template-columns: ${props => props.columnSize};
    white-space: nowrap;
  }
`;

export const LoadingCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  > div {
    top: 6px;
  }

  ${props =>
    props.index === 0 &&
    `
   position: sticky;
  left: 0;
  z-index: 1;
  `}
  ${props =>
    props.index === 1 &&
    `
    position: sticky;
  left: 36px;
  z-index: 1;
  `}
  ${props =>
    props.index === 2 &&
    `
  position: sticky;
  left: 86px;
  z-index: 1;
  `}
`;

export const LabelWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
`;

export const NumberLabelWrapper = styled(LabelWrapper)``;

export const PicklistLabelWrapper = styled(LabelWrapper)``;

export const EmbeddedHeaderItem = styled(HeaderItem)`
  background-color: ${colors.sky};
  border-bottom: 1px solid ${colors.grey2};
  color: ${colors.primary};
  height: 24px;
`;

export const EmbeddedFieldsHeader = styled.th`
  height: 1rem;
  background-color: ${colors.inkLightest};
  color: ${colors.skyLighter};
  font-size: 10px;
  width: 100%;
  text-transform: uppercase;
  padding-left: 8px;
  display: flex;
  align-items: center;
`;

export const FormFieldsHeader = styled(EmbeddedFieldsHeader)`
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width};
  color: black;
`;

export const formFieldContainer = {
  height: "1rem",
  paddingLeft: "8px"
};

export const Resizer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  z-index: 100;
  width: 2.5px;
  background: ${colors.active};
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: ${props => (props.isResizing && !props.isChild ? 1 : 0)};
  :hover {
    opacity: ${props => (props.isChild ? 0 : 1)};
  },
  transition: all;
`;

export const PageLayout: SystemCSSProperties = {
  position: "fixed",
  left: "54px",
  right: 0
};

export const FooterButtonsWrapper: SystemCSSProperties = {
  ...PageLayout,
  position: "sticky",
  bottom: 0,
  w: "100%",
  gap: "1rem",
  bg: colors.skyLighter,
  p: "1rem 1.5rem",
  zIndex: 2
};

export const CancelButton: SystemCSSProperties = {
  padding: "4px 1rem",
  fontSize: "14px",
  backgroundColor: colors.grey33,
  color: colors.dark,
  borderRadius: "4px"
};
