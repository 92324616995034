// @flow

import React from "react";

import { PDFToolbar, Find, ButtonSeparator } from "../styles";

const DEFINED_SCALE_TYPE = [
  "auto",
  "page-fit",
  "page-actual",
  "page-width",
  0.5,
  0.75,
  1,
  1.25,
  1.5,
  2,
  3,
  4
];

const Toolbar = props => {
  const {
    scale,
    numPages,
    pageNumber,
    searchRef,
    pageNumberRef,
    hanldeRotate,
    handleSearch,
    handleZoomIn,
    handleZoomOut,
    handleDownload,
    handleNextPage,
    handleScaleChange,
    handlePreviousPage,
    handlePageNoChange,
    handlePageNoKeyDown,
    handleSearchKeyDown
  } = props;
  return (
    <PDFToolbar>
      <div>
        <button
          className="previous"
          title="Previous Page"
          onClick={handlePreviousPage}
        />
        <ButtonSeparator />
        <button className="next" title="Next Page" onClick={handleNextPage} />
        <input
          type="number"
          title="Page"
          ref={pageNumberRef}
          onBlur={handlePageNoChange}
          onKeyDown={handlePageNoKeyDown}
        />
        of {numPages}
      </div>
      <div>
        <button onClick={handleZoomOut} title="Zoom Out" className="zoomout" />
        <ButtonSeparator />
        <button onClick={handleZoomIn} title="Zoom In" className="zoomin" />
        <select
          title="Zoom"
          onChange={handleScaleChange}
          value={DEFINED_SCALE_TYPE.includes(scale) ? scale : "custom"}
        >
          <option disabled="disabled" hidden="true" value="custom">
            {isNaN(scale) ? "" : (Number(scale) * 100).toFixed(0)} %
          </option>
          <option value="auto">Automatic Zoom</option>
          <option value="page-actual">Actual Size</option>
          <option value="page-fit">Page Fit</option>
          <option value="page-width">Page Width</option>
          <option value="0.5">50%</option>
          <option value="0.75">75%</option>
          <option value="1">100%</option>
          <option value="1.25">125%</option>
          <option value="1.5">150%</option>
          <option value="2">200%</option>
          <option value="3">300%</option>
          <option value="4">400%</option>
        </select>
      </div>
      <div>
        <button
          className="rotateccw"
          onClick={() => hanldeRotate(-90)}
          title="Rotate Anti-Clockwise"
        />
        <ButtonSeparator />
        <button
          className="rotatecw"
          onClick={() => hanldeRotate(90)}
          title="Rotate Clockwise"
        />
      </div>
      <Find>
        <input
          type="text"
          ref={searchRef}
          onBlur={handleSearch}
          onKeyDown={handleSearchKeyDown}
          title="Search"
          placeholder="Search"
        />
      </Find>
      <button className="download" title="Download " onClick={handleDownload} />
    </PDFToolbar>
  );
};

export default Toolbar;
