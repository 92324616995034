// @flow

import { connect } from "react-redux";

import { clearFileUploadState } from "src/actions/file";
import { getUploadStateById } from "src/reducers";

import ProgressBar from "src/components/chatroom/ProgressBar";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  return {
    fileUploadState: getUploadStateById(app, props.roomId),
    ...props,
    isSrwMobile: app.srw.isMobile
  };
};

export default connect(mapStateToProps, {
  clearFileUploadState
})(ProgressBar);
