// @flow
import React from "react";
import type { Node } from "react";

import * as styles from "./styles";

type Props = {
  children: Node,
  customStyles?: { [string]: string | number }
};

const ModalBody = ({ children, customStyles }: Props) => {
  return <div style={{ ...styles.ModalBody, ...customStyles }}>{children}</div>;
};

export default ModalBody;
