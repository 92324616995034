// @flow

import { connect } from "react-redux";

import Name from "src/components/Manage/Builder/Checklist/Name";

import { getChecklistTemplates } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  checklists: getChecklistTemplates(app)
});

export default connect(mapStateToProps)(Name);
