// @flow

import {
  ModalFooterProps,
  ModalHeaderProps,
  ModalContentProps
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const content: ModalContentProps = {
  minWidth: "800px"
};

export const header: ModalHeaderProps = {
  pl: "30px"
};

export const footer: ModalFooterProps = {
  justifyContent: "flex-start",
  gridGap: "12px",
  pl: "30px",
  background: colors.grey0
};
