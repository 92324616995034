// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import FilterItem from "./FilterItem";
import Sort from "./Sort";
import {
  Filters as StyledFilter,
  SectionLabel,
  TernaryButton
} from "src/components/Manage/Workflow/Instances/styles";
import { setForm } from "src/actions/forms";
import { getWorkflowInstanceFilter } from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  setForm: Function
};

const Filters = ({ filter, setForm }: Props) => {
  const filterParams = R.omit(["id", "page", "sort", "search"], filter);
  const sortParams = filter.sort || [];

  const clearAllFilters = () => {
    setForm({
      query: {
        id: filter.id,
        sort: "seqNo:asc",
        page: 1
      }
    });
  };

  return (
    <StyledFilter>
      {!R.isEmpty(filterParams) && (
        <SectionLabel>Filters applied:</SectionLabel>
      )}

      {/* Applied filter criteria */}
      {R.map(
        (column: string) =>
          R.map(
            (value: string) => (
              <FilterItem key={value} column={column} value={value} />
            ),
            filterParams[column] || []
          ),
        R.keys(R.omit(["reportId", "chartId"], filterParams || {}))
      )}

      {!R.isEmpty(sortParams) && <SectionLabel>Sorted by:</SectionLabel>}

      {/* Applied sorting criteria */}
      <Sort sortParams={sortParams} />

      <TernaryButton onClick={clearAllFilters}>Clear All</TernaryButton>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps, {
  setForm
})(Filters);
