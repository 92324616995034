// @flow

import React from "react";
import * as R from "ramda";

import { UserSmall as StyledUser, InlineProfileImage } from "src/styles";
import { UserDisplayName, Pending } from "src/styles/user";

import type { UnifizeUser } from "src/types";

const UserWithDisplayName = ({
  user,
  children,
  remove,
  clickHandler
}: {
  user: UnifizeUser,
  children: ?any,
  remove: ?boolean,
  clickHandler: ?Function
}) => (
  <>
    {user.photoUrl ? (
      <UserDisplayName>
        {children}
        <InlineProfileImage src={user.photoUrl} />
        <span title={user.displayName}>
          {user.displayName}
          {remove ? (
            <button type="button" onClick={clickHandler}>
              X
            </button>
          ) : (
            ""
          )}
        </span>
        {user.invitePending ? <Pending>Pending</Pending> : null}
      </UserDisplayName>
    ) : (
      <UserDisplayName key={user.uid}>
        {children}
        <StyledUser>
          {R.head(user.displayName || user.email || "")}
          {R.head(R.split(" ", user.displayName || "")[1] || "")}
        </StyledUser>
        <span title={user.displayName ? user.displayName : user.email}>
          {user.displayName ? user.displayName : user.email}
          {remove ? (
            <button type="button" onClick={clickHandler}>
              X
            </button>
          ) : (
            ""
          )}
        </span>
        {user.invitePending ? <Pending>Pending</Pending> : null}
      </UserDisplayName>
    )}
  </>
);

export default UserWithDisplayName;
