// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { DateTime as StyledDateInvited } from "./styles";
import { getUser } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const DateInvited = ({ user }: Props) => (
  <StyledDateInvited>
    {user.dateInvited && user.dateInvited.toDate
      ? moment(user.dateInvited.toDate()).format("MMM DD, YYYY h:mm a")
      : null}
  </StyledDateInvited>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps)(DateInvited);
