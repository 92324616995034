// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

type Props = {
  onClose: Function,
  isOpen: boolean,
  id: number,
  _deleteRole: Function
};

const DeleteRoleModal = ({ onClose, isOpen, id, _deleteRole }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor={colors.grey0}>Delete Role</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this role?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              _deleteRole(id);
              onClose();
            }}
          >
            Delete
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRoleModal;
