// @flow

import { connect } from "react-redux";

import FieldName from "src/components/Manage/Builder/Checklist/FieldName";

import { getChecklistFieldDetails } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  field: getChecklistFieldDetails(app, `${props.id}`)
});

export default connect(mapStateToProps)(FieldName);
