// @flow

import React from "react";
import moment from "moment";

import { Time as StyledTime, NoValue } from "./styles";

type Props = {
  time: string
};

const Time = ({ time }: Props) => (
  <StyledTime>
    {time ? moment(time).format("MMM DD, YYYY hh:mm a") : <NoValue>_</NoValue>}
  </StyledTime>
);

export default Time;
