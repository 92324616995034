// @flow

import React from "react";

type Props = {};

const ParentLayers = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="#276749"
      viewBox="0 0 36 36"
      {...restProps}
    >
      <path d="M2.25 4.5h13.5A2.25 2.25 0 0118 6.75v18A2.25 2.25 0 0115.75 27h-9c-.596 0-1.17-.236-1.59-.66l-4.5-4.5A2.244 2.244 0 010 20.25V6.75A2.25 2.25 0 012.25 4.5zM31.5 31.5a2.25 2.25 0 002.25-2.25v-13.5a2.25 2.25 0 00-2.25-2.25h-9V18h6.75v11.25a2.25 2.25 0 002.25 2.25z"></path>
    </svg>
  );
};

export default ParentLayers;
