// @flow

import styled from "@emotion/styled";

import { Tile } from "../Tiles/styles";
import {
  List,
  ListItem as StyledListItem
} from "src/components/user/Analytics/Filters/styles";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { ButtonOutline } from "src/styles/buttons";
import { DeleteButton } from "src/components/Manage/Reports/Instances/Chart/New/Fields/Columns/styles";
import { Input } from "src/components/user/Analytics/Filters/styles";

export const EditButton = styled.button`
  background-color: ${colors.brightLight};
  border: none;
  outline: none;
  cursor: pointer;
  > svg {
    cursor: pointer;
    height: 1.25em;
    width: 1.25em;
    position: relative;
    top: 2px;
  }
`;

export const ListItem = styled(StyledListItem)`
  justify-content: space-between;
  cursor: pointer;
  ${EditButton} {
    visibility: hidden;
  }

  :hover,
  :focus {
    ${EditButton} {
      visibility: visible;
    }
  }
  padding: 0 4px;
`;

export const Dropdown = styled(List)`
  left: 0.8em;
  max-width: 60%;
  > input {
    min-width: auto;
  }

  ${Input} {
    margin-bottom: 1em;
  }
`;

export const Button = styled.button`
  color: ${colors.blue};
  background-color: ${colors.grey25};
  border: none;
  outline: none;
  padding: 4px 8px;
  width: 100%;
  margin-top: 0.8em;
  :hover,
  :focus {
    background-color: ${colors.blue};
    color: ${colors.brightLight};
  }
`;

export const NewUserSegment = styled.div`
  background-color: ${colors.brightLight};
  position: absolute;
  top: -96%;
  left: -87%;
  width: 523px;
  min-height: 202px;
  z-index: 999;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: ${radius.small};
  padding: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  > strong {
    line-height: 23px;
    position: relative;
    top: 2px;
  }
  ${ButtonOutline} {
    padding: 2px 4px;
    text-transform: none;
    font-size: 10px;
    margin-left: 8px;
    width: 50px;
    height: 24px;
    font-size: 16px;
  }
`;

export const Body = styled.div`
  border: 1px solid ${colors.disabled};
  border-radius: ${radius.medium};
  min-height: 156px;
  margin-top: 0.7em;
  padding-bottom: 1em;
`;

export const SubHeader = styled.h5`
  margin: 0;
`;

export const Title = styled.div`
  > input {
    padding: 4px 8px;
    border: 1px solid ${colors.brightLight};
    font-weight: normal;
    font-size: 16px;
    width: 96%;
    margin: 0.5em 0.5em;
  }
`;

export const Footer = styled.div`
  color: ${colors.secondary};
  margin-top: 0.8em;
  cursor: pointer;
  padding: 0 1em;
  :hover,
  :focus {
    color: ${colors.primary};
  }
`;

export const Label = styled.label`
  padding-left: 1em;
`;

export const Criteria = styled.div`
  display: grid;
  grid-template-columns: 56px 1fr 1fr 18px;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0 1em;
  ${DeleteButton} {
    visibility: hidden;
  }

  ${Label} {
    text-align: left;
    padding-left: 0.5em;
  }

  :hover,
  :focus {
    ${DeleteButton} {
      visibility: visible;
    }
  }
`;

export const UserSegmentSelection = styled(Tile)`
  cursor: pointer;
  justify-content: flex-start;
`;
