// @flow

import React from "react";
import * as R from "ramda";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove
} from "react-sortable-hoc";
import { VStack, HStack, Button, Text } from "@chakra-ui/react";
import { DragHandleIcon } from "@chakra-ui/icons";

import type { Stitch as StitchType } from "src/types";

import Icon from "src/icons";
import Stitch from "./Stitch";
import * as styles from "./styles";

const SortableContainer = sortableContainer(({ children }) => (
  <VStack {...styles.StitchItemsStack}>{children}</VStack>
));

const DragHandle = sortableHandle(() => (
  <DragHandleIcon {...styles.DragHandle} />
));

const SortableItem = sortableElement(({ item, onUpdate, onRemove }) => (
  <HStack {...styles.SortableItem}>
    <DragHandle />
    <Stitch data={item} onUpdate={onUpdate} />
    <Icon
      type="deleteTrash"
      onClick={() => onRemove(item.seqNo)}
      style={styles.DeleteIcon}
    />
  </HStack>
));

type Props = {
  stitches: StitchType[],
  setStitches: Function
};

export default function Stitches({ stitches, setStitches }: Props) {
  const addItem = () => {
    setStitches(prevStitches => {
      const nextSeqNo =
        prevStitches.length > 0
          ? prevStitches[prevStitches.length - 1].seqNo + 1
          : 1;
      return R.append(
        {
          seqNo: nextSeqNo,
          templateId: null
        },
        prevStitches
      );
    });
  };

  const removeItem = id => {
    setStitches(R.reject(R.propEq("seqNo", id)));
    setStitches(prevStitches =>
      prevStitches.map((item, index) => ({
        ...item,
        seqNo: (item.seqNo = index + 1)
      }))
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setStitches(prevItems =>
      arrayMove(prevItems, oldIndex, newIndex).map((item, itemIndex) => ({
        ...item,
        seqNo: itemIndex + 1
      }))
    );
  };

  const updateItem = (id, data) => {
    setStitches(prevItems => {
      return prevItems.map(item => {
        if (item.seqNo === id) {
          return data;
        }
        return item;
      });
    });
  };

  if (R.isEmpty(stitches)) {
    return (
      <HStack {...styles.EmptyContainer}>
        <Text variant="inkLightestSm">(Click on Stitch to create a PDF)</Text>

        <Button onClick={addItem} variant="transparentLink">
          + Stitch
        </Button>
      </HStack>
    );
  }

  return (
    <VStack {...styles.StitchesContainer}>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {stitches.map((item, index) => (
          <SortableItem
            key={item.seqNo}
            index={index}
            item={item}
            onUpdate={updateItem}
            onRemove={removeItem}
          />
        ))}
      </SortableContainer>

      <Button onClick={addItem} variant="transparentLink">
        + Stitch
      </Button>
    </VStack>
  );
}
