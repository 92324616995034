// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ifProp } from "styled-tools";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as size from "src/styles/constants/size";
import { colors as oldColors } from "src/styles/constants";

export const ChatHistory = styled.div`
  overflow-y: auto;
  background: transparent;
  flex: 1;
  font-size: 12px;

  ${props =>
    props.flexEnd
      ? css`
          display: flex;
          justify-content: flex-end;
        `
      : css``}
  :after {
    content: "";
    height: ${spacing.space_l};
    display: block;
  }
`;

export const DateBreak = styled.div`
  text-align: center;
  position: relative;
  margin: ${spacing.space_inset_l};
  position: sticky;
  top: ${spacing.space_l};
  border-radius: ${radius.small};
  z-index: 1;
  span {
    margin-top: 0.2em;
    background: ${oldColors.silver};
    color: ${colors.brightLight};
    font-size: 0.75rem;
    padding: 0.4em 0.8em;
    border-radius: 0.8em;
  }
`;

export const MessageText = styled.div`
  position: relative;
  p {
    line-height: 1.43;
    letter-spacing: normal;
    color: ${oldColors.dullGrey};
  }
`;

export const MessageContent = styled.div`
  max-width: ${size.maxChatWidth};
  overflow-x: auto;
  white-space: normal;
  > div > span > p:last-child {
    overflow-wrap: break-word;
    word-break: break-word;
    display: inline;
  }
`;

export const MessageBody = styled.div`
  display: inline;
  user-select: text;
  code {
    font-family: monospace, monospace !important;
  }

  > span > ol {
    margin: 0px;
  }
`;

export const FileInfo = styled.div`
  align-items: center;
  flex-flow: row;
  > svg {
    margin-left: -0.3em;
  }
  > div:nth-of-type(2) {
    margin-left: 0.3em !important;
  }
`;

export const NewMenu = styled.div`
  position: absolute;
  right: 20px;
  top: -15px;
  display: flex;
  svg {
    cursor: pointer;
  }
`;

export const MenuOption = styled.div`
  padding: 8px 4px;
  border-radius: 3px;
  margin-left: 3px;
  border: solid 1px ${colors.grey3};
  background-color: ${colors.brightLight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Reply = styled(MenuOption)`
  position: relative;
  height: 30px;
  width: 30px;
  padding: 0px;
  top: -2px;
`;

export const Forward = styled(MenuOption)`
  position: relative;
  height: 30px;
  width: 30px;
  padding: 0px;
  top: -2px;
`;

export const Delete = styled(MenuOption)`
  height: 30px;
  width: 30px;
  position: relative;
  top: -2px;
  cursor: pointer;
  > img {
    height: 15px;
  }
`;

export const Start = styled(MenuOption)`
  color: ${colors.active};
  font-size: 12px;
  position: relative;
  top: -2px;
  cursor: pointer;
  height: 30px;
`;

export const EmailReply = styled(MenuOption)`
  position: absolute;
  right: 181px;
  top: -2px;
  cursor: pointer;
  padding: 0 0.6em;
  white-space: nowrap;
  height: 30px;
`;

export const EmailForward = styled(EmailReply)`
  right: 276px;
`;

export const Submenu = styled.div`
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: -4px;
`;

export const EmailReplyTo = styled.div`
  padding-bottom: 0.8em;
  position: relative;
  background: ${ifProp(
    "isCurrentUser",
    colors.currentUserHighlight,
    colors.grey0
  )};
  > div {
    background: ${ifProp(
      "isCurrentUser",
      colors.currentUserHighlight,
      colors.grey0
    )};
  }
`;

export const LoadMessageMarker = styled.div`
  width: 100%;
  height: 30px;
  background: ${colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.brightLight};
  font-weight: 500;
`;

export const User = styled.span`
  color: ${colors.active};
  white-space: nowrap;
  > span,
  > span > span {
    font-size: 0.88rem;
    font-weight: normal;
    color: ${colors.active};
  }
`;
