// @flow

import styled from "@emotion/styled";

import { ButtonLink } from "src/styles/buttons";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const AddField = styled(ButtonLink)`
  margin-bottom: ${spacing.space_m};
  display: flex;
  align-items: center;
  span {
    padding-left: ${spacing.space_s};
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.secondary};
    svg > path {
      stroke: ${colors.secondary};
    }
  }
`;

export const InlineFormGroup = styled.div`
  margin-top: ${spacing.space_s};
`;

export const Section = styled.div`
  margin-bottom: 1em;
  > ${InlineFormGroup} {
    margin-bottom: 0.4em;
    display: flex;
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
