// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {};

const LineChart = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <path
        d="M3.24133 12.5C10.2757 12.5 6.96539 2 13.9997 2"
        stroke={colors.ink}
        strokeWidth="0.919137"
        strokeMiterlimit="10"
        strokeLinecap="square"
        {...restProps}
      />
      <path
        d="M13.9998 14H2V2"
        stroke={colors.ink}
        strokeWidth="0.919137"
        strokeMiterlimit="10"
        strokeLinecap="square"
        {...restProps}
      />
    </svg>
  );
};

export default LineChart;
