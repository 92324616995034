// @flow

import { connect } from "react-redux";
import React from "react";

import { Table as StyledTable, TableContainer } from "./styles";
import Header from "./Header";
import Body from "./Body";
import WorkflowDeletionModal from "./WorkflowDeletionModal";

import type { AppState, Workflow } from "src/types";

type Props = {
  sort: string,
  ascending: boolean,
  lastCreated: ?number,
  matches: Array<Workflow>,
  sortBy: Function,
  handleEdit: Function,
  handleDelete: Function
};

const Table = ({
  sort,
  ascending,
  matches,
  sortBy,
  lastCreated,
  handleEdit,
  handleDelete
}: Props) => (
  <>
    <TableContainer>
      <StyledTable cellspacing="0" cellpadding="0">
        <Header sort={sort} ascending={ascending} sortBy={sortBy} />
        <Body
          ascending={ascending}
          sort={sort}
          matches={matches}
          lastCreated={lastCreated}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      </StyledTable>
    </TableContainer>
    <WorkflowDeletionModal />
  </>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  lastCreated: app.workflow.lastCreated
});

export default connect(mapStateToProps)(Table);
