// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {};

const RateChart = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <path
        d="M4.63123 11H2.38123C2.17412 11 2.00623 11.1679 2.00623 11.375V13.625C2.00623 13.8321 2.17412 14 2.38123 14H4.63123C4.83833 14 5.00623 13.8321 5.00623 13.625V11.375C5.00623 11.1679 4.83833 11 4.63123 11Z"
        fill={colors.ink}
        {...restProps}
      />
      <path
        d="M9.13123 7.25H6.88123C6.67412 7.25 6.50623 7.41789 6.50623 7.625V13.625C6.50623 13.8321 6.67412 14 6.88123 14H9.13123C9.33833 14 9.50623 13.8321 9.50623 13.625V7.625C9.50623 7.41789 9.33833 7.25 9.13123 7.25Z"
        fill={colors.ink}
        {...restProps}
      />
      <path
        d="M13.6312 10.25H11.3812C11.1741 10.25 11.0062 10.4179 11.0062 10.625V13.625C11.0062 13.8321 11.1741 14 11.3812 14H13.6312C13.8383 14 14.0062 13.8321 14.0062 13.625V10.625C14.0062 10.4179 13.8383 10.25 13.6312 10.25Z"
        fill={colors.ink}
        {...restProps}
      />
      <path
        d="M7.30348 4.81775C7.51842 4.93727 7.76029 5 8.00623 5C8.25216 5 8.49404 4.93727 8.70898 4.81775L11.0812 6.8C11.0332 6.94523 11.0079 7.09703 11.0062 7.25C11.0021 7.58324 11.1077 7.90859 11.3068 8.17588C11.5058 8.44317 11.7873 8.63756 12.1077 8.72909C12.4282 8.82062 12.7699 8.8042 13.0801 8.68237C13.3903 8.56054 13.6518 8.34005 13.8243 8.05491C13.9968 7.76977 14.0707 7.4358 14.0347 7.10448C13.9987 6.77317 13.8546 6.46291 13.6248 6.22153C13.395 5.98016 13.0922 5.82107 12.7631 5.76879C12.434 5.71651 12.0968 5.77395 11.8035 5.93225L9.43123 3.95C9.47929 3.80477 9.50459 3.65297 9.50623 3.5C9.50623 3.10218 9.34819 2.72064 9.06689 2.43934C8.78558 2.15804 8.40405 2 8.00623 2C7.6084 2 7.22687 2.15804 6.94557 2.43934C6.66426 2.72064 6.50623 3.10218 6.50623 3.5C6.50786 3.65297 6.53316 3.80477 6.58123 3.95L4.20898 5.93225C3.99342 5.81426 3.75195 5.75164 3.50623 5.75C3.20955 5.75 2.91954 5.83797 2.67287 6.0028C2.4262 6.16762 2.23394 6.40189 2.12041 6.67597C2.00688 6.95006 1.97717 7.25166 2.03505 7.54264C2.09293 7.83361 2.23579 8.10088 2.44557 8.31066C2.65534 8.52044 2.92262 8.6633 3.21359 8.72118C3.50456 8.77906 3.80616 8.74935 4.08025 8.63582C4.35434 8.52229 4.58861 8.33003 4.75343 8.08335C4.91825 7.83668 5.00623 7.54667 5.00623 7.25C5.00459 7.09703 4.97929 6.94523 4.93123 6.8L7.30348 4.81775Z"
        fill={colors.ink}
        {...restProps}
      />
    </svg>
  );
};

export default RateChart;
