// @flow

import styled from "@emotion/styled";

const Search = styled.div`
  width: 14em;
  > ul {
    padding-left: 0px;
    max-height: 11em;
    margin-bottom: 0px;
    overflow: auto;
    > li > span:last-child {
      display: block;
      max-width: 10em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  input {
    margin: 6px;
    width: 13em;
    min-width: 13em;
    display: block;
  }
`;

export { Search };
