// @flow
import * as Sentry from "@sentry/react";

const sentryKey = process.env.SENTRY_KEY || "";
const sentryApp = process.env.SENTRY_APP || "";

const sentryDSN = `https://${sentryKey}@sentry.io/${sentryApp}`;

const techsupportEmail = process.env.TECHNICAL_MAIL || "you@unifize.com";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: "production",
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.unifize\.com\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Allow errors from these sources
  allowUrls: [/^https:\/\/app\.unifize\.com\//],

  // Attaches stack traces with all the logged messages
  attachStackTrace: true
});

export default sentryDSN;
export { techsupportEmail };
