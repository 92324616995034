// @flow

import React from "react";

type Props = {
  value: any
};

const Text = ({ value }: Props) => <>{value}</>;

export default Text;
