// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProseMirror > * + * {
  margin-top: 0.75em;
}
.mention {
  color: #4299e1;
  border-radius: 0.4rem;
  box-decoration-break: clone;
}

blockquote {
  border-left: 3px solid #adb5bd;
  padding-left: 1rem;
  quotes: none;
}

[data-tippy-root] {
  transform: translate3d(-0.5px, -58.5px, 0px) !important;
  inset: none !important;
  padding-top: 5px;
  padding-left: 65px;
  padding-right: 65px;
  width: 100%;
}

.ProseMirror {
  padding: 1px !important;
  max-height: 20em;
  overflow: auto;
}

.ProseMirror .input {
  outline: none;
}

.ProseMirror p {
  padding-left: 12px;
  overflow-wrap: anywhere;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror code {
  font-family: monospace, monospace !important;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: monospace, monospace !important;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-family: monospace, monospace !important;
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/chatroom/Input/TextInputWithMentions/tiptap-overrides.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,uDAAuD;EACvD,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,WAAW;EACX,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,4CAA4C;AAC9C;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,4CAA4C;EAC5C,qBAAqB;EACrB,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,4CAA4C;EAC5C,iBAAiB;AACnB","sourcesContent":[".ProseMirror > * + * {\n  margin-top: 0.75em;\n}\n.mention {\n  color: #4299e1;\n  border-radius: 0.4rem;\n  box-decoration-break: clone;\n}\n\nblockquote {\n  border-left: 3px solid #adb5bd;\n  padding-left: 1rem;\n  quotes: none;\n}\n\n[data-tippy-root] {\n  transform: translate3d(-0.5px, -58.5px, 0px) !important;\n  inset: none !important;\n  padding-top: 5px;\n  padding-left: 65px;\n  padding-right: 65px;\n  width: 100%;\n}\n\n.ProseMirror {\n  padding: 1px !important;\n  max-height: 20em;\n  overflow: auto;\n}\n\n.ProseMirror .input {\n  outline: none;\n}\n\n.ProseMirror p {\n  padding-left: 12px;\n  overflow-wrap: anywhere;\n}\n\n.ProseMirror p.is-editor-empty:first-child::before {\n  color: #adb5bd;\n  content: attr(data-placeholder);\n  float: left;\n  height: 0;\n  pointer-events: none;\n}\n\n.ProseMirror code {\n  font-family: monospace, monospace !important;\n}\n.ProseMirror pre {\n  background: #0d0d0d;\n  color: #fff;\n  font-family: monospace, monospace !important;\n  padding: 0.75rem 1rem;\n  border-radius: 0.5rem;\n}\n.ProseMirror pre code {\n  color: inherit;\n  padding: 0;\n  background: none;\n  font-family: monospace, monospace !important;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
