// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import Link from "redux-first-router-link";

import { getChatroomAddress } from "src/reducers";
import { fetchConversation } from "src/actions/chatroom";
import * as atypes from "src/constants/actionTypes";
import ChatroomName from "src/containers/chatroom/Name";

import type { RoomId, ChatroomAddress } from "src/types";

type Props = {
  id: RoomId,
  chatroomAddress: ChatroomAddress,
  disableTitleHyperlink: boolean,
  _fetchConversation: Function
};

const LinkedRoom = ({
  id,
  chatroomAddress,
  disableTitleHyperlink,
  _fetchConversation
}: Props) => {
  useEffect(() => {
    if (!chatroomAddress) {
      _fetchConversation(id);
    }
  }, [id, chatroomAddress]);

  if (disableTitleHyperlink) {
    return <ChatroomName id={id} />;
  }

  if (chatroomAddress && id) {
    return (
      <Link
        to={{
          type: atypes.INITIATE_CURRENT_CHATROOM_SET,
          payload: { id: chatroomAddress }
        }}
      >
        <ChatroomName id={id} />
      </Link>
    );
  }
  return null;
};

const mapStateToProps = ({ app }, { id }) => ({
  chatroomAddress: getChatroomAddress(app, `${id}`)
});

export default connect(mapStateToProps, {
  _fetchConversation: fetchConversation
})(LinkedRoom);
