// @flow

import { connect } from "react-redux";

import Owner from "src/components/chatroom/Owner";
import { setAttribute } from "src/actions/chatroom";
import { setOwnerTrigger } from "src/actions/uiTriggers";
import { getChatroomOwner, getUserSearchResult } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomOwner(app, props.roomId),
  result: getUserSearchResult(app),
  activeDock: app.activeDock.main,
  ownerDropdown: app.uiTriggers.infoOwner
});

export default connect(mapStateToProps, { setAttribute, setOwnerTrigger })(
  Owner
);
