// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Button,
  Flex
} from "@chakra-ui/react";

import * as styles from "../styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

type Props = {
  series?: boolean,
  title: string,
  handleDelete: Function
};

const DeleteModal = ({ series, title, handleDelete }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDelete = () => {
    handleDelete();
    onClose();
  };

  return (
    <>
      <IconButton
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
        sx={{ ...styles.IconButton, mt: series ? "1rem" : 0 }}
        icon={<Icon type="deleteTrash" color={colors.inkLightest} />}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Flex sx={styles.ModalHeader}>
            <ModalHeader sx={{ ml: "1rem", p: 0, flex: "auto" }}>
              Delete {title}
            </ModalHeader>
            <ModalCloseButton sx={styles.ModalCloseButton} />
          </Flex>
          <ModalBody sx={{ p: "1.5rem 1rem" }}>
            Are you sure you want to delete “{title}”{" "}
            {series ? "series" : "chart"}?
          </ModalBody>

          <ModalFooter>
            <Button style={styles.CancelButton} mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onDelete} sx={styles.DeleteButton}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
