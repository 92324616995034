// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";

const Base = styled.div`
  padding-top: 1.8em;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 4.5em;
  height: 100%;
  background-color: ${colors.brightLight};
  z-index: 3;
  border-right: 1px solid ${colors.grey2};
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  height: 0%;
`;

export const CrashWindow = styled.div`
  position: absolute;
  width: 70vw;
  height: 50vh;
  z-index: 10;
`;

export const CrashButton = styled.button`
  opacity: 0;
  &:hover {
    opacity: 100;
  }
`;

export { Base, IconWrapper };
