// @flow

import React from "react";

const ManageDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
    <path
      d="M30 36V35H23.01L23 39C23 40.11 23.89 41 25 41H39C40.11 41 41 40.11 41 39V35H34V36H30ZM40 27H35.99V25L33.99 23H29.99L27.99 25V27H24C22.9 27 22 27.9 22 29V32C22 33.11 22.89 34 24 34H30V32H34V34H40C41.1 34 42 33.1 42 32V29C42 27.9 41.1 27 40 27ZM34 27H30V25H34V27Z"
      fill="#2F80ED"
    />
  </svg>
);

export default ManageDark;
