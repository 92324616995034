// @flow

import React from "react";

const ContactsDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 23H38V21H36V23H28V21H26V23H25C23.89 23 23 23.9 23 25V39C23 40.1 23.89 41 25 41H39C40.1 41 41 40.1 41 39V25C41 23.9 40.1 23 39 23ZM32 26C33.66 26 35 27.34 35 29C35 30.66 33.66 32 32 32C30.34 32 29 30.66 29 29C29 27.34 30.34 26 32 26ZM38 38H26V37C26 35 30 33.9 32 33.9C34 33.9 38 35 38 37V38Z"
      fill="#2F80ED"
    />
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
  </svg>
);

export default ContactsDark;
