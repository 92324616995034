// @flow

import firebase from "firebase/app";
import "firebase/firestore";

import ReduxSagaFirebase from "redux-saga-firebase";

import firebaseConfig from "src/config/firebase";

const connection = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  return firebase.firestore();
};

const initializeApp = () => {
  if (!firebase.apps.length) {
    return firebase.initializeApp(firebaseConfig);
  }
  return firebase.apps[0];
};

const firebaseApp = initializeApp();
const rsf = new ReduxSagaFirebase(firebaseApp);

const increment = firebase.firestore.FieldValue.increment(1);

export { rsf, increment };
export default connection;
