// @flow

import React from "react";

import type { OrgCloneWizardCloneStatus } from "src/types";

import { Text, VStack, CircularProgress } from "@chakra-ui/react";
import ServerErrorImg from "src/img/Server-Error.png";
import * as styles from "./OrgCreationStatus.styles";

type Props = {
  cloneStatus: $NonMaybeType<OrgCloneWizardCloneStatus>,
  orgName: string
};

export default function OrgCreationStatus({ cloneStatus, orgName }: Props) {
  if (cloneStatus.progress === "ongoing")
    return (
      <VStack sx={styles.container}>
        <VStack>
          <CircularProgress isIndeterminate size="52px" />
          <Text textStyle="displayXl">Creating {orgName} Org...</Text>
          <Text textStyle="displaySmall">{cloneStatus.state}</Text>
        </VStack>
      </VStack>
    );

  if (cloneStatus.progress === "completed") {
    return (
      <VStack sx={styles.container}>
        <Text textStyle="displayXl">
          Your org is ready. You will be redirected shortly.
        </Text>
      </VStack>
    );
  }

  return (
    <VStack sx={styles.container}>
      <VStack>
        <img src={ServerErrorImg} />
        <Text textStyle="displayXl">Error creating your org</Text>
        <Text textStyle="displaySmall">
          Someone from Unifize will soon contact you.
        </Text>
      </VStack>
    </VStack>
  );
}
