// @flow

import { useSelector } from "react-redux";
import React from "react";

import {
  getChecklistFieldType,
  getChecklistFieldDetails,
  getAllRecords
} from "src/reducers";
import { FilterItem as StyledFilterItem, CloseButton } from "../styles";
import FieldName from "src/containers/checklist/FieldName";
import * as colors from "src/styles/constants/colors";
import Value from "src/containers/checklist/Value";
import Icon from "src/icons";
import DateField from "./DateField";
import AllRecords from "./AllRecords";
import { conversationFieldTypes } from "src/constants";

type Props = {
  column: string,
  value: any,
  handleClose: Function,
  blanksFilterText: ?string,
  fullColumnId: string
};

const Checklist = ({
  column,
  value,
  handleClose,
  blanksFilterText,
  fullColumnId
}: Props) => {
  const currentFieldType = useSelector(({ app }) =>
    getChecklistFieldType(app, parseInt(column))
  );

  const parentFieldDetails = useSelector(({ app }) =>
    getChecklistFieldDetails(app, fullColumnId.split("-")[0])
  );

  const allRecords = useSelector(({ app }) => getAllRecords(app));

  const allRecordsType =
    // $FlowFixMe
    conversationFieldTypes.includes(parentFieldDetails?.toJS()?.type) ||
    fullColumnId.includes("-")
      ? "embedded"
      : "form";

  return (
    <StyledFilterItem>
      <span>
        <FieldName id={column} />:
      </span>
      <strong>
        {blanksFilterText ? (
          blanksFilterText
        ) : currentFieldType === "date" ? (
          <DateField value={value} />
        ) : (
          <Value id={column} value={value} />
        )}
      </strong>
      {allRecords[fullColumnId.split("-")[0] ?? fullColumnId] && (
        <AllRecords fieldType={allRecordsType} />
      )}
      <CloseButton onClick={handleClose}>
        <Icon type="close" color={colors.active} />
      </CloseButton>
    </StyledFilterItem>
  );
};

export default Checklist;
