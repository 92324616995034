// @flow

import React from "react";

const ImageStack = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0L19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V2Z"
      fill="#2F80ED"
    />
    <path
      d="M23 4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5L0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23V5C24 4.73478 23.8946 4.48043 23.7071 4.29289C23.5196 4.10536 23.2652 4 23 4ZM7 8C7.39556 8 7.78224 8.1173 8.11114 8.33706C8.44004 8.55682 8.69638 8.86918 8.84776 9.23463C8.99913 9.60009 9.03874 10.0022 8.96157 10.3902C8.8844 10.7781 8.69392 11.1345 8.41421 11.4142C8.13451 11.6939 7.77814 11.8844 7.39018 11.9616C7.00222 12.0387 6.60009 11.9991 6.23463 11.8478C5.86918 11.6964 5.55682 11.44 5.33706 11.1111C5.1173 10.7822 5 10.3956 5 10C5 9.46957 5.21071 8.96086 5.58579 8.58579C5.96086 8.21071 6.46957 8 7 8ZM5 19L15 10L20 19H5Z"
      fill="#2F80ED"
    />
  </svg>
);

export default ImageStack;
