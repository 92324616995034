// @flow

import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { getCurrentRoomAccess } from "src/reducers";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

const RestrictedModal = () => {
  const currentRoomRestricted = useSelector(
    ({ app }) =>
      getCurrentRoomAccess(app) === currentRoomAccessStatuses.restricted
  );
  const currentRoomFailedToLoad = useSelector(
    ({ app }) =>
      getCurrentRoomAccess(app) === currentRoomAccessStatuses.failedToLoad
  );

  const [restrictedModalShown, setRestrictedModalShown] = useState(
    currentRoomRestricted
  );
  const [failedToLoadModalShown, setFailedToLoadModal] = useState(
    currentRoomFailedToLoad
  );

  useEffect(() => {
    setRestrictedModalShown(currentRoomRestricted);
  }, [currentRoomRestricted]);

  const closeRestrictedModal = () => setRestrictedModalShown(false);

  useEffect(() => {
    setFailedToLoadModal(currentRoomFailedToLoad);
  }, [currentRoomFailedToLoad]);

  const closeFaieldToLoadModal = () => setFailedToLoadModal(false);

  return (
    <>
      <Modal isOpen={restrictedModalShown} onClose={closeRestrictedModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restricted access</ModalHeader>
          <ModalCloseButton onClick={closeRestrictedModal} />
          <ModalBody>
            The conversation you are trying to access is not open to your
            account. Contact your org admin to get access.
          </ModalBody>

          <ModalFooter>
            <Button variant="uBlueFilled" onClick={closeRestrictedModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={failedToLoadModalShown} onClose={closeFaieldToLoadModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Failed to load the chatroom</ModalHeader>
          <ModalCloseButton onClick={closeRestrictedModal} />
          <ModalBody>
            {
              "Unable to load the chatroom you're trying to access. Please try again in sometime!"
            }
          </ModalBody>

          <ModalFooter>
            <Button variant="uBlueFilled" onClick={closeFaieldToLoadModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RestrictedModal;
