// @flow

import React from "react";

import { NameOrEmail as StyledNameOrEmail, AuthorColor } from "./styles";
import { Pending } from "src/styles/box";

import type { UnifizeUser } from "src/types";

type Props = {
  showPending?: boolean,
  user: UnifizeUser,
  hashColor?: ?string,
  noTooltip?: boolean,
  isCurrentUser?: boolean
};

const NameOrEmail = ({
  user,
  showPending = true,
  hashColor = null,
  noTooltip = false,
  isCurrentUser = false
}: Props) => {
  const renderContent = () => (
    <>
      <AuthorColor hashColor={hashColor}>
        {user.loading
          ? "Loading user..."
          : isCurrentUser
            ? "Me (Always selects the viewer)"
            : user.displayName || user.email}
      </AuthorColor>
      {user.invitePending &&
      !user.disabled &&
      user.orgRole !== "contact" &&
      showPending ? (
        <Pending> Pending </Pending>
      ) : null}
    </>
  );

  if (noTooltip)
    return <StyledNameOrEmail>{renderContent()}</StyledNameOrEmail>;

  return (
    <StyledNameOrEmail
      title={user.displayName || user.email}
      data-cy="nameOrEmailNotification"
    >
      {renderContent()}
    </StyledNameOrEmail>
  );
};

export default NameOrEmail;
