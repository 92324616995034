// @flow

import { useState, useCallback } from "react";

const useEditHover = () => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = useCallback((e: any) => {
    e.stopPropagation();
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback((e: any) => {
    e.stopPropagation();
    setHover(false);
  }, []);

  return {
    hover,
    handleMouseEnter,
    handleMouseLeave
  };
};

export default useEditHover;
