// @flow

import React, { useCallback } from "react";

import SearchItem from "../Item";
import VirtualList from "src/components/VirtualList";

import type { UID } from "src/types";

type Props = {
  focus?: number,
  renderOptions?: ?Function,
  result: Array<UID>,
  handleSelect: Function
};

const Users = ({
  renderOptions = null,
  focus = -1,
  handleSelect,
  result
}: Props) => {
  const renderRow = useCallback(
    (index: number) => {
      const uid = result[index];
      if (uid) {
        return (
          <SearchItem
            key={uid}
            handleSelect={handleSelect}
            item={uid}
            focus={focus === index}
          />
        );
      }
    },
    [result, focus, handleSelect]
  );

  return (
    <>
      {typeof renderOptions === "function" ? renderOptions() : null}
      <VirtualList
        initialAmount={10}
        progressiveAmount={10}
        role="list"
        renderItem={renderRow}
        renderLoader={() => <div />}
        rowCount={result.length}
      />
    </>
  );
};

export default Users;
