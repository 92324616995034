// @flow

import type { InputProps, StackProps } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const InputField: InputProps = {
  width: "100%",
  padding: "0.5rem",
  justifyContent: "space-between",
  alignitems: "flex-start",
  borderRadius: "0.25rem",
  background: "#F9FAFB",
  border: `1px solid ${colors.sky}`,
  _focus: {
    border: "1px solid #3182CE"
  }
};

export const FilesContainer: StackProps = {
  overflow: "hidden"
};
