// @flow

import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";

import { Tooltip, Text } from "@chakra-ui/react";
import { RemoveOption } from "./Search/styles";
import AddData from "./AddData";
import useFields from "./useFields";
import MultiPicklist from "./MultiPicklist/MultiPicklist";
import SelectedMultipicklists from "./MultiPicklist/SelectedMultiPicklists";
import OutsideClickHandler from "src/components/OutsideClickHandler";

import {
  getChecklistFieldBehavior,
  getBehaviorByFormField,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistValue,
  getChecklistFieldDetails,
  getChecklistFormValue,
  getFormFieldMandatoryStatus,
  getIsFieldLocked,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";

import {
  PicklistContainer,
  PicklistSearch,
  SelectMenu,
  MenuOptions,
  MenuOption,
  CreateNewButton,
  CreateNewState,
  CheckSelect,
  IconContainer,
  DisplayContainer,
  Display
} from "./styles";
import Icon from "src/icons";
import useAutoPositionDropdown from "src/hooks/useAutoPositionDropdown";
import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import type {
  FieldId,
  RoomId,
  ColumnId,
  FieldValue,
  HttpMethods
} from "src/types";
import { behaviorToSettings, behaviors } from "src/conditions";

type Props = {
  formId?: ?number,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  handleClose?: Function,
  fromManageView?: ?boolean,
  handleClose?: Function,
  selectedFieldValue?: any,
  setSelectedFieldValue?: any => void,
  isSectionField?: boolean
};

const SelectOption = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose,
  selectedFieldValue,
  setSelectedFieldValue
}: Props) => {
  const dispatch = useDispatch();

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, `${fieldId}`)
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );
  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );

  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const behaviorByFormField = useSelector(({ app }) =>
    getBehaviorByFormField(app, roomFieldFormId || "")
  );
  const behaviorByChecklistField =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, fieldId)) ||
    {};
  const behavior = roomFieldFormId
    ? behaviorByFormField
    : behaviorByChecklistField;

  const dependentOptions = behavior.options;
  const currentBehavior = behavior.current;

  const {
    edit,
    value: extractedValue,
    setValue,
    settings,
    openEdit,
    closeEdit
  } = useFields({
    checklistValue,
    details
  });

  const value = fromManageView ? selectedFieldValue : extractedValue;

  const multiple = settings?.multiple || false;

  useEffect(() => {
    setSelectedFieldValue && setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          dispatch(
            bulkUpdateProcess({
              attrs: {
                [fieldId]: value
              }
            })
          );
        } else {
          setSelectedFieldValue && setSelectedFieldValue(value);
        }
      } else {
        dispatch(
          updateChecklistFromManageView(
            selectedRoomId,
            {
              [fieldId]: value,
              type,
              value
            },
            index,
            fieldId,
            httpMethod,
            extraBody,
            columnId ?? "",
            embeddedIndex
          )
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        dispatch(
          setChecklistValue({
            roomId,
            id: fieldId,
            value,
            progress: true,
            formId,
            columnId
          })
        );
      }
    },
    []
  );

  const { fieldRef, dropdownRef, positionUp } = useAutoPositionDropdown({
    edit
  });
  const [createField: boolean, setCreateField: Function] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState(settings?.options ?? []);

  const resetSearch = useCallback(() => setSearch(""), []);
  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  useEffect(() => {
    if (
      currentBehavior ===
        behaviorToSettings[behaviors.dependentPicklistExclude] &&
      dependentOptions
    ) {
      setOptions(R.without(dependentOptions, options));
    } else if (
      currentBehavior ===
        behaviorToSettings[behaviors.dependentPicklistInclude] &&
      dependentOptions
    ) {
      setOptions(dependentOptions);
    }
  }, [currentBehavior, dependentOptions]);

  const adhoc = settings?.adhoc ?? false;

  const results = R.filter(
    option =>
      !R.isEmpty(option) && R.includes(R.toLower(search), R.toLower(option)),
    options || []
  );
  // Sort options alphabetically if sortBy is alphabetical
  const sortedResults =
    settings?.sortBy === "alphabetical"
      ? R.sort((a, b) => a.localeCompare(b), results)
      : results;

  const multipleResults = R.filter(
    option =>
      !R.isEmpty(option) &&
      !R.includes(option, value && value.length > 0 ? value : ""),
    sortedResults || []
  );

  const setOption = useCallback(
    option => {
      if (value !== option) {
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: option,
            type: "select",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      }

      setValue(option);
      closeEdit();
      resetSearch();
    },
    [value, formId, roomId, fieldId, columnId, setValue]
  );

  const setMultipleOption = useCallback(
    option => {
      const newValue = [...(value || []), option];

      if (value !== option) {
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: newValue,
            type: "select",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      }
      setValue(newValue);

      closeEdit();
      resetSearch();
    },
    [value, formId, roomId, fieldId, columnId, multiple, setValue]
  );

  const setNull = useCallback(() => {
    setChecklistFieldValue({
      roomId,
      id: fieldId,
      value: {
        value: null,
        type: "select",
        checked: false
      },
      progress: true,
      formId,
      columnId
    });
    setValue(null);

    closeEdit();
    resetSearch();
  }, [formId, roomId, columnId, fieldId]);

  const addPicklistOption = useCallback(() => {
    if (search.length > 0) {
      if (multiple) {
        setMultipleOption(search);
      } else {
        setOption(search);
      }

      setOptions(options => R.uniq([...options, search]));
      setCreateField(false);
    } else setCreateField(true);
  }, [search, setOption, roomId]);

  const closeCreateField = useCallback(() => {
    closeEdit();
    setCreateField(false);
  }, []);

  const handleClick = useCallback(() => {
    if (locked) return;
    openEdit();
  }, [locked]);

  const handleRemove = useCallback(
    (option: string) => {
      const newValue = (value || []).filter(pickList => pickList !== option);
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "select",
          checked: true
        },
        progress: true,
        formId,
        columnId
      });
      setValue(newValue);
    },
    [formId, roomId, fieldId, columnId, value, setValue]
  );

  if (createField) {
    return (
      <OutsideClickHandler onClickOutside={closeCreateField}>
        <PicklistContainer>
          <PicklistSearch
            value={search}
            onChange={handleSearch}
            autoFocus
            placeholder="Enter title for new item"
          />
          <CreateNewState
            highlight={search.length > 0}
            onClick={addPicklistOption}
          >
            <span>
              &#43; Create {search.length > 0 ? `item "${search}"` : "new item"}
            </span>
          </CreateNewState>
        </PicklistContainer>
      </OutsideClickHandler>
    );
  }

  if (edit && multiple) {
    return (
      <MultiPicklist
        onClickOutside={closeEdit}
        fieldRef={fieldRef}
        onChange={handleSearch}
        autoFocus
        placeholder="Search picklist"
        positionUp={positionUp}
        dropdownRef={dropdownRef}
        onClick={setNull}
        multipleResults={multipleResults}
        setMultipleOption={setMultipleOption}
        value={value || []}
        adhoc={adhoc}
        search={search}
        addPicklistOption={addPicklistOption}
        fromManageView={fromManageView ?? false}
      />
    );
  }

  if (multiple) {
    return (
      <SelectedMultipicklists
        value={value || []}
        handleRemove={handleRemove}
        openEdit={openEdit}
        disabled={locked}
        isMandatory={isMandatory}
      />
    );
  }

  if (edit) {
    return (
      <OutsideClickHandler
        onClickOutside={closeEdit}
        update={setChecklistFieldValue}
      >
        <PicklistContainer ref={fieldRef}>
          <PicklistSearch
            value={search}
            onChange={handleSearch}
            autoFocus
            placeholder="Search picklist"
          />
          <SelectMenu
            fromManageView={fromManageView ?? false}
            positionUp={positionUp}
            ref={dropdownRef}
          >
            {value && (
              <RemoveOption data-cy="clearPicklist" onClick={setNull}>
                <span>Remove</span>
                <Icon type="checklistRemove" />
              </RemoveOption>
            )}
            <MenuOptions>
              {sortedResults.map(option => (
                <MenuOption
                  data-cy="picklistOption"
                  key={option}
                  onClick={() => setOption(option)}
                >
                  <Tooltip label={option.length > 25 && option}>
                    <span>{option}</span>
                  </Tooltip>
                  <CheckSelect
                    isSelected={
                      Array.isArray(value)
                        ? option === value[0]
                        : option === value
                    }
                  >
                    <Icon type="checkSelect" />
                  </CheckSelect>
                </MenuOption>
              ))}
            </MenuOptions>
            {adhoc && (
              <CreateNewButton onClick={addPicklistOption}>
                &#43; Create{" "}
                {search.length > 0 ? (
                  <span>&nbsp;{`"${search}"`}</span>
                ) : (
                  `new item`
                )}
              </CreateNewButton>
            )}
          </SelectMenu>
        </PicklistContainer>
      </OutsideClickHandler>
    );
  }

  if (R.isNil(value) || R.isEmpty(value)) {
    return (
      <AddData
        disabled={locked}
        type="picklist"
        handleClick={openEdit}
        isSelect={true}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <DisplayContainer
      data-cy="displayContainerSelect"
      isText={false}
      locked={locked}
    >
      <Display
        clickToEdit={true}
        disabled={locked}
        onClick={handleClick}
        isText={false}
        isMandatory={isMandatory}
      >
        <Text m={0} noOfLines={1}>
          {value}
        </Text>
        <IconContainer data-cy="removeIconContainer">
          <Icon
            data-cy="removeSinglePicklistItem"
            type="checklistEdit"
            handleClick={openEdit}
          />
        </IconContainer>
      </Display>
    </DisplayContainer>
  );
};

export default SelectOption;
