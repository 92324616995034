// @flow

import { connect } from "react-redux";
import React from "react";
import usePortal from "react-useportal";

import { Video as StyledVideo, Overlay, CloseButton } from "./styles";
import { closeVideoPlayer } from "src/actions/file";
import Icon from "src/icons";

import type { AppState } from "src/types";

type Props = {
  url: string,
  _closeVideoPlayer: Function
};

const Video = ({ url, _closeVideoPlayer }: Props) => {
  const { Portal } = usePortal();

  if (url) {
    return (
      <Portal>
        <Overlay />
        <StyledVideo>
          <div>
            <CloseButton onClick={_closeVideoPlayer}>
              <Icon type="close" />
            </CloseButton>
            <video src={url} controls autoPlay />
          </div>
        </StyledVideo>
      </Portal>
    );
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  url: app.files.playVideo
});

export default connect(mapStateToProps, {
  _closeVideoPlayer: closeVideoPlayer
})(Video);
