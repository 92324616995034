// @flow

import React from "react";
import { useSelector } from "react-redux";
import { getWorkflowName } from "src/reducers";
import { Button } from "./styles";
import type { WorkflowId } from "src/types";

type Props = {
  id: WorkflowId,
  onClick: Function
};

const WorkflowTile = ({ id, onClick }: Props) => {
  const title: string = useSelector(({ app }) => getWorkflowName(app, id));
  return (
    <>
      {title ? (
        <Button type="button" onClick={() => onClick(id)}>
          {title}
        </Button>
      ) : null}
    </>
  );
};

export default WorkflowTile;
