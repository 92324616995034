// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import { SortableList as StyledSortableList } from "./styles";

type Props = {
  items: Array<string>,
  processLabels: Object,
  fields: Object,
  clearSort: Function
};

const SortableList = SortableContainer(
  ({ items, processLabels, fields, clearSort }: Props) => {
    return (
      <StyledSortableList>
        {items.map((column, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            column={column}
            processLabels={processLabels}
            fields={fields}
            clearSort={clearSort}
          />
        ))}
      </StyledSortableList>
    );
  }
);

export default SortableList;
