// @flow

import React from "react";

const SortDown = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.49023 10L12.4902 5L17.4902 10H7.49023Z" fill="#BBBBBB" />
    <path d="M7.49023 14L12.4902 19L17.4902 14H7.49023Z" fill="black" />
  </svg>
);

export default SortDown;
