//  @flow

import React, { memo } from "react";

import DirectChat from "./DirectChat";
import { Name as StyledName, NoTitleNotification } from "./styles";
import appLocation from "src/constants/location";

type Props = {
  name: string,
  type: string,
  seqNo: number,
  processTitle: ?string,
  canceled: boolean,
  noTitle?: boolean,
  children?: any,
  isBreadcrumb?: boolean,
  location?: string,
  hideProcessTitle?: boolean
};

const Name = ({
  name,
  type,
  processTitle,
  canceled,
  seqNo,
  noTitle = true,
  children = null,
  isBreadcrumb = false,
  location,
  hideProcessTitle = false
}: Props) => {
  let heading = name;
  const titleAndSeq =
    location && location === appLocation.manageView
      ? `${seqNo ? `#${seqNo}:` : ""}`
      : `${seqNo && !hideProcessTitle ? processTitle || "" : ""} ${
          seqNo ? `#${seqNo}:` : ""
        }`;

  if (type === "workflow") {
    heading = `${titleAndSeq}${name ? ` ${name}` : ""}`;
  }

  if (isBreadcrumb && heading?.length > 27) {
    heading = `${heading.substring(0, 27)}...`;
  }

  return (
    <StyledName canceled={canceled} title={heading}>
      {type === "direct" ? <DirectChat title={heading} /> : heading}

      {type === "workflow" && (name || "").length === 0 && noTitle ? (
        <>
          &nbsp;
          <NoTitleNotification>No title</NoTitleNotification>
        </>
      ) : null}
      {children}
    </StyledName>
  );
};

export default memo<Props>(Name);
