// @flow

import { combineReducers } from "redux";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type {
  UserAnalyticsFilter,
  Analytics,
  Action,
  Total,
  Average
} from "src/types";

const filter = (state: UserAnalyticsFilter = {}, { type, meta }) => {
  switch (type) {
    case atypes.SET_USER_ANALYTICS_REQUEST:
      const filter = (meta || {}).query || {};
      // When user refreshes the browser if there is multiple values browser returns array
      // but returns string for single values so it needs to be converted to array
      const multiSelections = R.difference(R.keys(filter), [
        "search",
        "createdAt",
        "completedAt"
      ]);

      const multipleValues = R.mergeAll(
        R.map(
          key => ({
            [key]: R.type(filter[key]) === "Array" ? filter[key] : [filter[key]]
          }),
          multiSelections
        )
      );
      const newFilter = { ...filter, ...multipleValues };
      return { ...newFilter };
    default:
      return state;
  }
};

const initialState = [];

const analytics = (state: Analytics = initialState, { type, payload }) => {
  switch (type) {
    case atypes.GET_USER_ANALYTICS_SUCCESS:
      return payload.result;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_USER_ANALYTICS_REQUEST:
      return initialState;
    default:
      return state;
  }
};

const readCountComputedAt = (
  state: ?string = null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_USER_ANALYTICS_SUCCESS:
      return payload.readCountComputedAt;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_USER_ANALYTICS_REQUEST:
      return null;
    default:
      return state;
  }
};

const total = (state: Total = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_USER_ANALYTICS_TOTAL:
      return payload.total;
    default:
      return state;
  }
};

const average = (state: Average = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_USER_ANALYTICS_TOTAL:
      return payload.average;
    default:
      return state;
  }
};

const showTotal = (state: boolean = true, { type }: Action) => {
  switch (type) {
    case atypes.TOGGLE_USER_ANALYTICS_TOTAL:
      return !state;
    default:
      return state;
  }
};

const showAverage = (state: boolean = true, { type }: Action) => {
  switch (type) {
    case atypes.TOGGLE_USER_ANALYTICS_AVERAGE:
      return !state;
    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  filter,
  total,
  average,
  analytics,
  showTotal,
  showAverage,
  readCountComputedAt
});
