// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { DateTime as StyledDateJoined } from "./styles";
import { getUser } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const DateJoined = ({ user }: Props) => (
  <StyledDateJoined>
    {user.dateJoined && user.dateJoined.toDate
      ? moment(user.dateJoined.toDate()).format("MMM DD, YYYY h:mm A")
      : null}
  </StyledDateJoined>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps)(DateJoined);
