// @flow

import { combineReducers } from "redux";
import { List } from "immutable";
import { createSelector } from "reselect";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import type {
  Action,
  WorkflowChecklists,
  ChecklistHeader,
  ChecklistInstances,
  RoomId,
  FieldId,
  ChecklistValues
} from "src/types";
import { mergeChecklistValues } from "src/utils";
import { linkedFieldDeleteHandler } from "src/utils/checklist";

const initialState = List([]);

const checklists = (
  state: List<ChecklistHeader> = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return initialState;

    case atypes.GET_CHECKLIST_SUCCESS:
      return List(payload.checklists);

    default:
      return state;
  }
};

const values = (state: any = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    case atypes.HIDE_DOCK:
      return {};

    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);
    case atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS:
      return mergeChecklistValues(state, payload);
    case atypes.SET_EMBEDDED_VALUE_SUCCESS: {
      const { embedded } = payload;
      return {
        ...state,
        ...embedded
      };
    }
    case atypes.SET_CHECKLIST_VALUE_SUCCESS:
    case atypes.GET_CHECKLIST_FIELD_VALUE_COMPLETE:
    case atypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS: {
      const { chatroomId: roomId, fieldId } = payload;

      const roomValues = state[roomId] || [];
      const index = R.findIndex(R.propEq("fieldId", fieldId))(roomValues);
      if (index === -1) {
        return {
          ...state,
          [roomId]: [...roomValues, { ...payload }]
        };
      }

      const newRoomValues = [
        ...R.slice(0, index, roomValues),
        payload,
        ...R.slice(index + 1, Infinity, roomValues)
      ];

      return {
        ...state,
        [roomId]: newRoomValues
      };
    }

    case atypes.DELETE_CHECKLIST_VALUE_SUCCESS: {
      const { roomId, id: fieldId } = payload;

      const checklistValues = state[roomId] || [];

      const newChecklistValues = checklistValues.map(item => {
        if (item.fieldId !== fieldId) return item;

        if (payload.value.type !== "link") return item;

        return linkedFieldDeleteHandler({ item, payload });
      });

      return {
        ...state,
        [roomId]: newChecklistValues
      };
    }

    case atypes.REPLACE_CHECKLIST_VALUE: {
      const { chatroomId: roomId } = payload;
      return {
        ...state,
        [roomId]: [payload]
      };
    }

    default:
      return state;
  }
};

const instances = (
  state: Array<ChecklistInstances> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_INSTANCES_SUCCESS:
      return payload.checklist;
    default:
      return state;
  }
};

// Set by approval fields
const lockedFields = (
  state: $PropertyType<WorkflowChecklists, "lockedFields"> = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);

    case atypes.SET_LOCKED_CHECKLIST_FIELDS: {
      const { roomId, fields } = payload;
      return {
        ...state,
        [roomId]: fields
      };
    }

    default:
      return state;
  }
};

// Set by approval fields
const hiddenFields = (
  state: $PropertyType<WorkflowChecklists, "hiddenFields"> = { byRoom: {} },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);

    case atypes.SET_HIDDEN_CHECKLIST_FIELDS: {
      const { roomId, fields } = payload;
      if (R.equals(state[roomId], fields)) {
        return state;
      }
      return {
        ...state,
        [roomId]: fields
      };
    }

    default:
      return state;
  }
};

const workflowChecklists = combineReducers<Object, Action>({
  checklists,
  values,
  instances,
  lockedFields,
  hiddenFields
});

export default workflowChecklists;

export const getCurrentChecklist = (state: WorkflowChecklists) =>
  state.checklists;

export const getFirstChecklist = (state: WorkflowChecklists) =>
  state.checklists.get(0);

const getAllChecklistValues = (state: WorkflowChecklists) => state.values;

export const getChecklistValue = createSelector(
  [
    getAllChecklistValues,
    (state: WorkflowChecklists, id: ?FieldId) => id,
    (state: WorkflowChecklists, id: ?FieldId, roomId: RoomId) => roomId
  ],
  (checklistValues: ChecklistValues, fieldId: ?FieldId, roomId: RoomId) => {
    if (!fieldId || !checklistValues[roomId]) return null;
    return R.find(R.propEq("fieldId", fieldId))(checklistValues[roomId]);
  }
);

export const getChecklistInstances = (state: WorkflowChecklists) =>
  state.instances ? state.instances : [];

const getHiddenFields = state => state.hiddenFields;
const getRoomId = (state, roomId) => roomId;

export const getRoomHiddenFields = createSelector(
  [getHiddenFields, getRoomId],
  (hiddenFields, roomId) => hiddenFields[roomId] || []
);

export const getChecklistFieldVisibility = (
  state: WorkflowChecklists,
  id: number,
  roomId: RoomId
) => !(state.hiddenFields[roomId] || []).includes(id);

export const getLockedFields = (state: WorkflowChecklists) =>
  state.lockedFields;
