// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import { WhiteList as StyledWhiteList, SubHeading } from "./styles";
import UserSelectMultiple from "src/containers/user/SelectMultiple";
import { getPrivacySettings } from "src/reducers";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";

import type { AppState, UID } from "src/types";

type Props = {
  privacySettings: {
    whitelist: Array<UID>
  },
  _setWorkflowBuiderAttributes: Function
};

const WhiteList = ({
  privacySettings,
  _setWorkflowBuiderAttributes
}: Props) => {
  const { whitelist } = privacySettings;

  const handleWhiteListing = useCallback(
    (user: UID) => {
      if (R.includes(user, whitelist)) {
        _setWorkflowBuiderAttributes({
          privacySettings: {
            whitelist: R.reject(R.equals(user), whitelist)
          }
        });
      } else {
        _setWorkflowBuiderAttributes({
          privacySettings: {
            whitelist: [...whitelist, user]
          }
        });
      }
    },
    [_setWorkflowBuiderAttributes, whitelist]
  );

  return (
    <StyledWhiteList>
      <strong>Whitelist</strong>
      <SubHeading>
        Privacy settings don’t apply to whitelisted people
      </SubHeading>
      <UserSelectMultiple
        value={whitelist}
        placeholder="Click here to whitelist people"
        handleChange={handleWhiteListing}
      />
    </StyledWhiteList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  privacySettings: getPrivacySettings(app)
});

export default connect(mapStateToProps, {
  _setWorkflowBuiderAttributes: setWorkflowBuiderAttributes
})(WhiteList);
