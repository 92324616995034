// @flow

import React from "react";

import { HistoryPlaceholder, ChatPlaceholder } from "./styles";
import PulseLoader from "src/components/PulseLoader";

const History = () => (
  <HistoryPlaceholder>
    <PulseLoader w="80%" h="18px" />
    <PulseLoader w="60%" h="18px" />
    <PulseLoader w="30%" h="18px" />
    <PulseLoader w="45%" h="18px" />
    <ChatPlaceholder>
      <div />
      <div>
        <PulseLoader w="calc(100% + 20px)" h="160px" />
      </div>
    </ChatPlaceholder>
  </HistoryPlaceholder>
);

export default History;
