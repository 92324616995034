// @flow

import React from "react";
import { connect } from "react-redux";

import { getChart } from "src/reducers";

import type { AppState, Charts } from "src/types";

type Props = {
  chart: Charts
};

const Title = ({ chart }: Props) => <>{chart.title}</>;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chart: getChart(app, props.id)
});

export default connect(mapStateToProps)(Title);
