// @flow

import React from "react";
import * as orgCloneApi from "src/api/orgClone";

import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import Icons from "src/icons";
import * as styles from "./VerificationEmailNotice.styles";

type Props = {
  email: string,
  orgId: number
};

export default function VerificationEmailNotice({ email, orgId }: Props) {
  return (
    <VStack sx={styles.container}>
      <Icons type="verificationSent" width="60" height="60" />

      <Text textStyle="displayXl">Please verify your email address...</Text>

      <Text textStyle="displaySmall">
        Click on the link we sent to {email} to create your org.
      </Text>

      <HStack spacing={0}>
        <Text textStyle="displaySmall">Didn&apos;t receive an email?</Text>

        <Button
          variant="transparentLink"
          textStyle="displaySmall"
          onClick={() => orgCloneApi.sendVerificationEmail({ email, orgId })}
        >
          Resend email
        </Button>
      </HStack>
    </VStack>
  );
}
