// @flow

import React, { forwardRef } from "react";
import type { Ref } from "react";
import { Box } from "@chakra-ui/react";
type Props = {
  zIndex?: number
};

// eslint-disable-next-line react/display-name
export const Card = forwardRef<any, Props>((props: Props, ref: Ref<any>) => (
  <Box
    ref={ref}
    position="relative"
    zIndex={props.zIndex ? props.zIndex : 1}
    padding={2}
    boxShadow="0 2px 7px 0 rgba(0, 0, 0, 0.1)"
    border="1px solid"
    borderColor="gray.200"
    background="white"
    borderRadius="2px"
    {...props}
  />
));
