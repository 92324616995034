// @flow

import {
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps
} from "@chakra-ui/react";

export const modalHeader: ModalHeaderProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

export const modalBody: ModalBodyProps = {
  pt: 0,
  width: "100%"
};

export const modalFooter: ModalFooterProps = {
  justifyContent: "flex-start"
};
