// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Title as StyledTitle, Image, TitleText } from "./styles";
import Icon from "src/components/Icon";
import { Flex } from "@chakra-ui/react";
import InlineIcons from "src/icons";
import { iconSize } from "src/styles/constants/size";
import * as colors from "src/styles/constants/colors";
import { setGroupModalAttributes } from "src/actions/group";

import type { UID } from "src/types";

type Props = {
  id: number,
  title: string,
  photoUrl: ?string,
  owners: Array<UID>,
  members: Array<UID>,
  locked: boolean,
  _setGroupModalAttributes: Function
};

const Title = ({
  title,
  photoUrl,
  id,
  owners,
  members,
  locked,
  _setGroupModalAttributes
}: Props) => {
  const editGroups = useCallback(() => {
    _setGroupModalAttributes({
      title,
      photoUrl,
      owners,
      members,
      locked,
      id,
      edit: true,
      show: true,
      error: null,
      loading: false
    });
  }, [title, photoUrl, owners, members, locked, id, _setGroupModalAttributes]);

  return (
    <StyledTitle>
      <Flex w="max-content" cursor="pointer" onClick={editGroups}>
        {photoUrl ? <Image src={photoUrl} alt="" /> : null}
        <TitleText title={title}>
          <span>{title}</span>
          {locked ? <InlineIcons type="lock" /> : null}
        </TitleText>
        <Icon icon="edit" size={iconSize.space_s} color={colors.primary} />
      </Flex>
    </StyledTitle>
  );
};

export default connect(null, {
  _setGroupModalAttributes: setGroupModalAttributes
})(Title);
