// @flow

import { backendUrl } from "src/config/firebase";

/**
 * Create dashboard with given title
 * @param {string} title of the dashboard
 * @returns {id} of the dashboard
 */
export const createDashboard = async (title: string) => {
  const response = await fetch(`${backendUrl}/dashboard`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ title })
  });

  return response.json();
};

/**
 * Update dashboard
 * @param {id} of the dashboard
 * @param {Object} value to be updated
 */
export const updateDashboard = async (id: number, values: Object) => {
  await fetch(`${backendUrl}/dashboard/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(values)
  });
};

/**
 * Delete dashboard
 * @param {id} of the dashboard
 * @param {Object} value to be updated
 */
export const deleteDashboard = async (id: number) => {
  const response = await fetch(`${backendUrl}/dashboard/${id}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });

  switch (response.status) {
    case 401:
      throw new Error("No permission to delete");
    default:
  }
};

/**
 * update dashboard
 * @param {id} of the dashboard
 * @return {Object} metadata of the charts in dashboard
 */
export const getDashboard = async (id: number) => {
  const response = await fetch(
    `${backendUrl}/dashboard/${id}?loadCharts=false`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  return response.json();
};

/**
 * Get all dashboards
 * @return {Object} Name and id of the dashboard user has created
 */
export const getAllDashboard = async () => {
  const response = await fetch(`${backendUrl}/dashboard`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Add chart to dashboard
 */
export const addChartToDashboard = async ({
  dashboard,
  chart,
  seqNo
}: {
  dashboard: number,
  chart: number,
  seqNo: number
}) => {
  const response = await fetch(
    `${backendUrl}/dashboard/${dashboard}/chart/${chart}`,
    {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({ seqNo })
    }
  );

  return response.json();
};

/**
 * Delete chart from dashboard
 */
export const deleteChartFromDashboard = async ({
  dashboard,
  chart
}: {
  dashboard: number,
  chart: number
}) => {
  await fetch(`${backendUrl}/dashboard/${dashboard}/chart/${chart}/delete`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};
