// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Tbody } from "@chakra-ui/react";

import type { DeletedChecklistField } from "src/types";

import SortableItem from "./SortableItem";

type Props = {
  items: DeletedChecklistField[],
  updateField: Function,
  restoreField: Function
};

const SortableList = SortableContainer(
  ({ items, updateField, restoreField }: Props) => {
    return (
      <Tbody>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            value={value}
            updateField={updateField}
            restoreField={restoreField}
          />
        ))}
      </Tbody>
    );
  }
);

export default SortableList;
