// @flow

import React from "react";

import MultiSelect from "./MultiSelect";

type Props = {
  value: Array<string>,
  multiple: boolean
};

const Select = ({ value, multiple }: Props) => {
  if ((value || []).length === 0) {
    return (
      <div>
        <i>No Value</i>
      </div>
    );
  }

  if (multiple && value) {
    return <MultiSelect value={value} />;
  }

  return <div>{value[0]}</div>;
};

export default Select;
