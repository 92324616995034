// @flow

import { useLayoutEffect, useState, useRef } from "react";
import { useClientDimensions } from "react-client-dimensions";

import { getDimensionObject } from "src/utils";
import type { ReactRef } from "src/types";

type Props = {
  edit: boolean
};

const useAutoPositionDropdown = ({ edit }: Props) => {
  const fieldRef: ReactRef = useRef(null);
  const dropdownRef: ReactRef = useRef(null);
  const [positionUp, setPositionUp] = useState(false);
  const { vh } = useClientDimensions();

  useLayoutEffect(() => {
    if (edit && fieldRef.current) {
      const { bottom } = getDimensionObject(fieldRef.current);
      if (vh - bottom < 262) setPositionUp(true);
      else setPositionUp(false);
    }
  }, [edit]);

  return { positionUp, fieldRef, dropdownRef };
};

export default useAutoPositionDropdown;
