// @flow

import React from "react";
import * as R from "ramda";
import {
  Container,
  Selection,
  Label,
  SelectedItems,
  SelectedItem,
  RemoveButton,
  InputContainer,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownText,
  ItemTitle,
  ItemSubTitle,
  DropdownItemButton
} from "./styles";
import { Avatar } from "src/components/Unifize";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useCombobox, useMultipleSelection } from "downshift";

type Props = {
  label?: string,
  placeholder?: string,
  data: Array<Object>,
  selected: Array<any>,
  onChange: Function,
  keys: Array<string>,
  showIcon?: boolean,
  showSelectDeselect?: boolean
};

const MultiSelect = ({
  label = "",
  placeholder = "Select items...",
  data = [],
  selected,
  onChange,
  keys,
  showIcon = false,
  showSelectDeselect = false
}: Props) => {
  const identifier: string = keys[0];
  const display: string = keys[1];
  const description: string = keys[2] || "";
  const url: string = keys[3] || "";

  const [inputValue, setInputValue] = React.useState("");

  const filteredItems = React.useMemo(
    () => getFilteredItems(selected, inputValue),
    [selected, inputValue]
  );
  const handleSelectAll = () => {
    const allFieldValues = R.map(({ id }) => id, data);
    return onChange(allFieldValues);
  };
  const handleDeselectAll = () => onChange([]);

  function getFilteredItems(selectedItems, inputValue) {
    const lowerCasedInputValue = inputValue.toLowerCase();
    return data
      .filter(
        item =>
          !selectedItems.includes(item[identifier]) &&
          item[display].toLowerCase().includes(lowerCasedInputValue)
      )
      .sort((a, b) => a[display].localeCompare(b[display]));
  }

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: selected,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onChange(newSelectedItems);
            break;
          default:
            break;
        }
      }
    });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getComboboxProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items: filteredItems,
    inputValue,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem && { isOpen: true, highlightedIndex: 0 })
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (!newSelectedItem) return;
          onChange([...selected, newSelectedItem[identifier]]);
          setInputValue("");
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);
          break;
        default:
          break;
      }
    }
  });
  return (
    <Container>
      <Selection {...getComboboxProps()}>
        <Label {...getLabelProps()}>{label}</Label>
        <SelectedItems>
          {selected.map((id, index) => {
            const item = data.find(item => item[identifier] === id);
            if (!item) {
              return null;
            }
            return (
              <SelectedItem
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  id,
                  index
                })}
                onClick={e => {
                  e.stopPropagation();
                  removeSelectedItem(id);
                }}
              >
                {showIcon && (
                  <Avatar
                    size="sm"
                    src={item[url] || ""}
                    alt={item[display] || ""}
                  />
                )}
                {item[display]}
                <RemoveButton>&#10005;</RemoveButton>
              </SelectedItem>
            );
          })}
          <InputContainer {...getToggleButtonProps()}>
            <Input
              placeholder={placeholder}
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            />
          </InputContainer>
        </SelectedItems>
      </Selection>
      <DropdownMenu {...getMenuProps()}>
        {isOpen && (
          <>
            {showSelectDeselect && (
              <DropdownItemButton
                onClick={handleSelectAll}
                disabled={!filteredItems.length}
              >
                <CheckIcon w={3} h={3} />
                <DropdownText>Select all</DropdownText>
              </DropdownItemButton>
            )}
            {showSelectDeselect && (
              <DropdownItemButton
                onClick={handleDeselectAll}
                disabled={!selected.length}
              >
                <CloseIcon w={2} h={2} size="0.5rem" />
                <DropdownText>Deselect all</DropdownText>
              </DropdownItemButton>
            )}
            {filteredItems.map((item, index) => (
              <DropdownItem
                key={`${item[identifier]}${index}`}
                className={highlightedIndex === index && "highlighted"}
                {...getItemProps({ item, index })}
              >
                {showIcon && (
                  <Avatar size="lg" src={item[url]} alt={item[display]} />
                )}
                <DropdownText>
                  <ItemTitle>{item[display]}</ItemTitle>
                  {description && (
                    <ItemSubTitle>{item[description]}</ItemSubTitle>
                  )}
                </DropdownText>
              </DropdownItem>
            ))}
          </>
        )}
      </DropdownMenu>
    </Container>
  );
};

export default MultiSelect;
