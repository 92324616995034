// @flow

import { connect } from "react-redux";
import React from "react";

import Standard from "./Standard";
import Custom from "./Custom";
import { Status as StyledStatus } from "./styles";
import { getChatroomStatus } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  value: number,
  isOverDue: boolean
};

const Status = ({ value, roomId, isOverDue }: Props) => {
  return (
    <StyledStatus>
      {value < 0 ? (
        <Standard id={value} roomId={roomId} isOverDue={isOverDue} />
      ) : (
        <Custom id={value} roomId={roomId} isOverDue={isOverDue} />
      )}
    </StyledStatus>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomStatus(app, props.roomId)
});

export default connect(mapStateToProps)(Status);
