// @flow
import styled from "@emotion/styled";

// TODO: Remove all !imoortant from styles

export const ModalSmall = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
  }

  .ReactModal__Content {
    top: 49px !important;
    left: 50% !important;
    right: 40px !important;
    bottom: 0px !important;
    width: 300px;
    height: 100px;
    padding: 0px !important;
    position: relative;
    overflow: visible !important;
  }

  textarea {
    font-size: 15px;
    padding-bottom: 0px;
    padding: 5px 10px;
    margin: 5px;
    resize: none;
    height: 40px;
  }

  .SingleDatePickerInput__withBorder {
    border: none;
  }

  .SingleDatePicker_picker__directionLeft {
    top: 51px !important;
  }
  input {
    font-size: 15px;
    padding-bottom: 0px;
    padding-top: 1px;
  }

  .DateInput_fang {
    top: 40px !important;
  }

  h2 {
    margin: 0px;
    margin-bottom: 10px;
    background: #2e4c68;
    color: #f2f2f2;
    transition: all 0.5s;
    position: initial;
    padding: 9px;
    display: block;
    width: 100%;
    &:hover {
      color: #fff;
    }
  }

  input {
    margin-left: 20px;
    margin-top: 8px;
  }
  h2 + div {
    width: 64%;
    font-size: 16px;
    padding-bottom: 3px;
  }

  button {
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    color: #fff;
    background: #2e4c68;
    cursor: pointer;
    position: absolute;
    margin-top: 6px;
  }
`;

export const ModalMd = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
  }

  form {
    padding: 12px;
  }

  .ReactModal__Content {
    top: 49px !important;
    left: 40% !important;
    right: 32% !important;
    bottom: 0px !important;
    width: 300px;
    height: 152px;
    overflow: auto;
    padding: 0px !important;
    position: relative;
  }

  button {
    border: none;
    padding: 5px 10px;
    margin: 10px;
    color: #fff;
    background: #2e4c68;
    cursor: pointer;
  }

  ul {
    line-height: 2em;
  }
  a {
    text-decoration: none;
    font-size: 13px;
    color: #000;
  }

  h2 {
    margin: 0px;
    background: #2e4c68;
    color: #f2f2f2;
    transition: all 0.5s;
    position: initial;
    padding: 9px;
    display: block;
    width: 100%;
    &:hover {
      color: #fff;
    }
  }
`;

export const ModalLg = styled(ModalMd)`
  .ReactModal__Content {
    top: 49px !important;
    left: 40% !important;
    right: 32% !important;
    bottom: 0px !important;
    width: 300px;
    height: 350px;
    overflow: auto;
    padding: 0px !important;
    position: relative;
  }
  h4 {
    padding-left: 10px;
  }
  input[type="text"] {
    width: 90%;
    padding: 5px 10px;
    margin: 10px;
  }
`;

export const Modal = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
  }
`;

export const UploadOptionModal = styled(Modal)`
  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    width: 227px;
    padding: 10px 10px 5px 10px !important;
    transform: translate(-50%, -50%) !important;
  }
`;
