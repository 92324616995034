// @flow

import { connect } from "react-redux";
import React, { Component } from "react";
import Modal from "react-modal";
import type { Set as ImmutableSet } from "immutable";

import Tabs from "../Tabs";
import { Contacts as StyledPeople, Count as StyledCount } from "../styles";
import Table from "./Table";
import Header from "./Header";
import New from "./New";
import { getParent } from "src/utils";
import Close from "src/containers/Close";
import Icon from "src/icons";
import { getCurrentUserId, getRole } from "src/reducers";

import type { AppState, UID, RoomId, ContactFilter } from "src/types";

Modal.setAppElement("#root");

type Props = {
  role: string,
  currentRoom: RoomId,
  searchResults: ImmutableSet<UID>,
  filter: ContactFilter,
  changeCurrentChatroom: Function,
  setContact: Function,
  openContactsPage: Function
};

type State = {
  showAddContact: boolean
};

class People extends Component<Props, State> {
  state = {
    showAddContact: false
  };

  componentDidMount() {
    const { openContactsPage } = this.props;
    openContactsPage();
  }

  toggleAddContact = () => {
    const { role } = this.props;
    if (role !== "demo") {
      this.setState(prevState => ({
        showAddContact: !prevState.showAddContact
      }));
    }
  };

  handleSearch = (value: string) => {
    const { setContact, filter } = this.props;
    setContact({ query: { ...filter, search: value } });
  };

  handleClose = () => {
    const { currentRoom, changeCurrentChatroom } = this.props;
    changeCurrentChatroom(currentRoom);
  };

  render() {
    const { filter, searchResults } = this.props;
    const { showAddContact } = this.state;
    const { search } = filter;

    return (
      <>
        <StyledPeople id="peoples-table">
          <Modal isOpen parentSelector={() => getParent("#peoples-table")}>
            <h2>
              <Icon type="contacts" />
              <span>Contacts</span>
            </h2>
            <Close />
            <Tabs tab="people" />
            <Header
              text={search}
              handleSearch={this.handleSearch}
              toggleAddContact={this.toggleAddContact}
            />
            <StyledCount>{searchResults.size} Results </StyledCount>
            <Table searchResults={searchResults} />
          </Modal>
        </StyledPeople>
        {showAddContact ? (
          <New show={showAddContact} handleClose={this.toggleAddContact} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ app }: { app: AppState }) => ({
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps)(People);
