// @flow

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

import CopyInput from "./CopyInput";
import { StyledEmail } from "./styles";
import { getOrg } from "src/reducers";
import { sanitizeTitle } from "src/utils";

import type { AppState, OrgDetails } from "src/types";

type Props = {
  title: ?string,
  org: OrgDetails
};

const Email = ({ title, org }: Props) => {
  const [sanitizedTitle, setSanitizedTitle] = useState("");

  useEffect(() => {
    const sanitized = sanitizeTitle(title || "");
    setSanitizedTitle(sanitized);
  }, [title]);

  const nickName = org?.nickName || "";

  const value = sanitizedTitle
    ? `${nickName}.${sanitizedTitle.toLowerCase()}@app.unifize.com`
    : "";
  const label = "Emails sent here will start a new instance";
  return (
    <StyledEmail>
      <label title={label}>{label}</label>
      <CopyInput
        placeholder="Email"
        value={value}
        id="templateEmail"
        readOnly
      />
    </StyledEmail>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const largOrg = app.orgs.last;
  return {
    org: getOrg(app, largOrg)
  };
};

export default connect(mapStateToProps)(Email);
