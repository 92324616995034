/** 
 * 
 * Alert Reducer
 * @module reducers/alert

 * Purpose
 * --------
 * Showing and hiding alerts
 * 
 * Why not `modal` slice and when to use this?
 * --------------------------------------------
 * Alerts are meant to just show information. Modals do more.
 * 
 * Why Redux and not component state?
 * -----------------------------------
 * When displaying an alert requires data from an API, updated store data, etc,
 * it's better to use sagas and they need a slice for the alert's visibility.
 * 
 * How does it work?
 * ------------------
 * Each alert to be displayed is given an ID in the `alerts` object
 * at `src/constants/index.js`. When an alert needs to be displayed, the ID is stored in the slice.
 * 
*/

// @flow

import type { ShowAlertActions, HideAlertAction } from "src/actions/alert";
import * as actionTypes from "src/constants/actionTypes";

const initialState = null;

const alert = (
  state: string | null = initialState,
  { type, payload }: ShowAlertActions | HideAlertAction
) => {
  switch (type) {
    case actionTypes.SHOW_ALERT:
      return payload;

    case actionTypes.HIDE_ALERT:
      return initialState;

    default:
      return state;
  }
};

export default alert;
