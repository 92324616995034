// @flow

import * as React from "react";

import {
  Loader as StyledLoader,
  FlexColumnCentered,
  LoadText
} from "src/styles/auth";

type Props = {
  children?: React.Node,
  color?: string,
  style?: Object,
  containerStyle?: Object
};

const Loader = ({ children, color, style, containerStyle }: Props) => {
  return (
    <FlexColumnCentered style={containerStyle}>
      <StyledLoader data-cy="loader" color={color} style={style} />
      {children && <LoadText>{children}</LoadText>}
    </FlexColumnCentered>
  );
};

export default Loader;
