// @flow

import { connect } from "react-redux";

import Direct from "src/components/chatroom/creator/direct";
import { getCurrentRoomId } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProp = ({ app }: { app: AppState }) => ({
  title: app.conversationDialog.title,
  user: app.conversationDialog.owner,
  roomId: getCurrentRoomId(app)
});

export default connect(mapStateToProp)(Direct);
