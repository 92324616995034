// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useCallback, useState } from "react";
import * as R from "ramda";

import NewRoom from "./NewRoom";
import Loader from "./Loader";
import AddConversation from "./AddConversation";
import Dropdown from "./Dropdown";
import SelectionSingle from "./FieldItem";
import useFields from "../useFields";

import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";

import { setChecklistValue, createConversation } from "src/actions/checklist";
import {
  getNextSeqNo,
  bulkUpdateProcess,
  updateChecklistFromManageView
} from "src/actions/workflows";
import {
  getSelectedChecklist,
  getCreatingConversation,
  getChecklistFieldDetails,
  getChecklistValue,
  getChecklistFormValue,
  getWhetherMandatoryField,
  getFormFieldMandatoryStatus,
  getLockedStatus,
  getIsFieldLocked,
  getBehaviorByFormField,
  getChecklistFieldBehavior,
  getCurrentRoomId,
  getWorkflowNextSeqNo,
  isProcessRowSelected,
  getWorkflow
} from "src/reducers";

import appLocation from "src/constants/location";
import type {
  RoomId,
  ColumnId,
  FieldValue,
  FieldId,
  HttpMethods
} from "src/types";

type Props = {
  formId: ?number,
  roomId: RoomId,
  columnId: ?ColumnId,
  fieldId: number,
  checklistId: ?number,
  promptCallback?: ?Function,
  roomFieldFormId: ?string,
  parentConversation: boolean,
  location: ?string,
  fromManageView?: ?boolean
};

const Conversation = ({
  formId,
  roomId,
  fieldId,
  checklistId,
  promptCallback = null,
  roomFieldFormId,
  parentConversation,
  location,
  fromManageView = false
}: Props) => {
  const dispatch = useDispatch();
  const selectedChecklist = useSelector(({ app }) => getSelectedChecklist(app));
  const creatingConversation = useSelector(({ app }) =>
    getCreatingConversation(app)
  );

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const disabled = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const {
    columnId,
    embeddedIndex,
    roomId: selectedRoomId,
    value: selectedValue,
    index
  } = selectedChecklist;

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );
  const originalRoomId = fromManageView ? selectedRoomId : roomId;

  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const behaviorByFormField = useSelector(({ app }) =>
    getBehaviorByFormField(app, roomFieldFormId || "")
  );
  const behaviorByChecklistField =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, fieldId)) ||
    {};

  const currentRoomId = useSelector(({ app }) => getCurrentRoomId(app));
  const seqNo = useSelector(({ app }) => getWorkflowNextSeqNo(app));

  const behavior = roomFieldFormId
    ? behaviorByFormField
    : behaviorByChecklistField;

  const dependentOptions = behavior.options;
  const currentBehavior = behavior.current;

  const {
    settings,
    setValue,
    value: extractedValue
  } = useFields({
    checklistValue,
    details
  });
  const workflowData = useSelector(({ app }) =>
    getWorkflow(app, `${settings?.workflow ?? ""}`)
  );

  const value = fromManageView
    ? selectedValue.map(conv => (typeof conv === "object" ? conv.id : conv))
    : extractedValue;

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const type = (settings || {}).type || "group";
  const workflow = (settings || {}).workflow || null;
  const multiple = (settings || {}).multiple || false;
  const showArchived = settings?.showArchived ?? false;

  const [newRoom, setNewRoom] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClose = useCallback(() => {
    setNewRoom(false);
    setDropdown(false);
  }, [setNewRoom, setDropdown]);

  const handleCreate = useCallback((title: ?string) => {
    let req: Object = {
      title,
      workflow,
      formId,
      type,
      fieldId,
      roomId,
      checklistId,
      multiple,
      selected: value,
      groups: workflowData?.groups ?? []
    };

    handleClose();

    if (parentConversation) {
      req.parent = parseInt(currentRoomId, 10);
    }

    if (seqNo) {
      req.seqNo = seqNo;
    }

    dispatch(createConversation(req, location || appLocation.checklistDock));
  }, []);

  const handleNewRoom = useCallback(() => {
    setNewRoom(true);
    setDropdown(false);

    if (workflow) {
      dispatch(getNextSeqNo(parseInt(workflow, 10)));
    }
  }, [workflow, setNewRoom, setDropdown, getNextSeqNo]);

  const handleRemove = useCallback(() => {
    const newValue = [];

    setValue(newValue);

    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "conversation",
          checked: false
        },
        columnId,
        embeddedIndex,
        progress: true,
        formId
      })
    );
  }, [formId, roomId, fieldId, checklistId, columnId, embeddedIndex]);

  const handleSelect = useCallback(
    (newValue: string) => {
      const updatedValue = [parseInt(newValue, 10)];
      setValue(updatedValue);
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: parseInt(newValue, 10),
            type: "conversation",
            checked: true
          },
          columnId,
          embeddedIndex,
          progress: true,
          formId
        })
      );

      if (promptCallback) promptCallback();

      setDropdown(false);
    },
    [formId, roomId, fieldId, columnId, embeddedIndex]
  );

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationExclude],
    dependentOptions
  );

  if (
    !R.isEmpty(creatingConversation) &&
    creatingConversation.fieldId === fieldId &&
    creatingConversation.roomId === roomId
  ) {
    return <Loader />;
  }
  const { create, select } = settings || {};
  return (
    <>
      {!newRoom && !dropdown && (value || []).length === 0 && (
        <AddConversation
          settings={settings || {}}
          handleDropdown={() => setDropdown(true)}
          disabled={disabled}
          isMandatory={isMandatory}
        />
      )}
      {!newRoom && !dropdown && value && (value || []).length > 0 && (
        <SelectionSingle
          value={value[0]}
          roomId={roomId}
          fieldId={fieldId}
          handleRemove={handleRemove}
          setDropdown={setDropdown}
          fromManageView={fromManageView ?? false}
        />
      )}

      {dropdown && (
        <Dropdown
          roomId={roomId}
          showRemove={Boolean(value && (value || []).length > 0)}
          workflow={workflow}
          type={type}
          selectedValue={value}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={create}
          select={select}
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          fromManageView={fromManageView ?? false}
          showArchived={showArchived}
        />
      )}

      {newRoom && !dropdown && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
};

export default Conversation;
