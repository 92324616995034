// @flow

import React from "react";

import { RoundImageLabel as StyledRoundImageLabel } from "../styles";
import ImageLabel from "./index";

import type { UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  user: UnifizeUser,
  noTooltip?: boolean
};

const Round = ({ uid, user, noTooltip = false }: Props) => (
  <StyledRoundImageLabel>
    <ImageLabel uid={uid} user={user} noTooltip={noTooltip} />
  </StyledRoundImageLabel>
);

export default Round;
