// @flow

import React from "react";
import { Input } from "./styles";

type Props = {
  placeholder: string,
  onInput: Function
};

const SearchInput = ({ placeholder, onInput }: Props) => {
  return (
    <Input onChange={e => onInput(e.target.value)} placeholder={placeholder} />
  );
};

export default SearchInput;
