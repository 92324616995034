// @flow

import React from "react";

import Icons from "src/icons";

import type { CurrentFilter } from "src/types";

type Props = {
  filter: CurrentFilter,
  selected: boolean
};

const NavIcons = ({ filter, selected }: Props) => {
  const suffix = selected ? "Dark" : "";
  const iconName = {
    "Direct Messages": "direct",
    "My Conversations": "conversations"
  };

  return <Icons type={iconName[filter.name] + suffix} />;
};

export default NavIcons;
