// @flow

import React, { useState } from "react";
import { Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";

import * as styles from "../styles";

type Props = {
  domain: string,
  index: number,
  handleRemove: (domain: string) => void
};

const DomainTag = ({ domain, index, handleRemove }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleKeyboardEvents = e => {
    if (e.key === "Enter") handleRemove(domain);
  };

  return (
    <Tag
      sx={styles.DomainTag}
      key={`domain-tag-${index}`}
      name={domain}
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyboardEvents}
    >
      <TagLabel>{domain}</TagLabel>
      <TagCloseButton
        onClick={() => handleRemove(domain)}
        sx={isFocused ? styles.DomainTagFocused : styles.DomainTagClose}
        tabIndex={-1}
      />
    </Tag>
  );
};

export default DomainTag;
