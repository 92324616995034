// @flow

import type {
  AccordionProps,
  AccordionPanelProps,
  StackProps,
  SystemProps
} from "@chakra-ui/react";
import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

export const accordion: AccordionProps = {
  display: "flex",
  flexDirection: "column",
  gridGap: 4
};

export const accordionPanel: AccordionPanelProps = {
  py: 4,
  px: 3,
  display: "flex",
  flexDirection: "column",
  gridGap: 4
};

export const formItem: StackProps = {
  width: "100%",
  alignItems: "start"
};

export const verticalRadioGroupList: StackProps = {
  alignItems: "start"
};

export const numberInput: SystemProps = {
  mx: 1,
  my: 0,
  maxW: "47px"
};

export const numberInputField: SystemProps = {
  maxHeight: "24px"
};

export const SectionLabel = styled.label`
  font-size: 16px;
  color: ${colors.primary};
  font-weight: 600;
  width: fit-content;
  margin-bottom: 2px;
  margin-top: 2px;
`;

export const RadioLabel = styled.span`
  font-size: 14px;
  padding-left: 2px;
  color: ${colors.primary};
`;

export const CustomRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > div:nth-of-type(2) {
    width: 64px;
    padding-left: 4px;
  }
`;
