// @flow

import React from "react";

const HollowPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.071"
      d="M7 13.426A6.426 6.426 0 1 0 7 .574a6.426 6.426 0 0 0 0 12.852z"
      clipRule="evenodd"
    />
    <path
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 3.787v6.426M3.787 7h6.426"
    />
  </svg>
);

export default HollowPlus;
