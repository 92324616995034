// @flow

import React from "react";

import { SelectionMultiple as StyledSelectionMultiple } from "src/styles/dropdown";
import ImageLabel from "src/containers/user/ImageLabel/Remove";

import type { UID } from "src/types";

const SelectionMultiple = ({
  value,
  children,
  removeHandler
}: {
  value: Array<UID>,
  children: any,
  removeHandler: Function
}) => (
  <StyledSelectionMultiple>
    {value.map(uid => (
      <ImageLabel uid={uid} key={uid} removeHandler={removeHandler} />
    ))}
    {children}
  </StyledSelectionMultiple>
);

export default SelectionMultiple;
