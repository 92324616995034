// @flow

import React from "react";

const Assistant = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <rect width="36" height="36" fill="#fff" rx="8" />
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M13.679 16.808c0-1.874.805-3.576 2.102-4.81A7.329 7.329 0 0 1 20.84 10a7.33 7.33 0 0 1 5.059 1.998C27.195 13.232 28 14.934 28 16.808v6.611c0 .778-.664 1.409-1.482 1.409h-2.209c-.744 0-1.353-.58-1.353-1.287 0-.708.61-1.287 1.354-1.287h.726v-5.446c0-1.096-.473-2.093-1.234-2.817a4.295 4.295 0 0 0-2.963-1.173c-1.152 0-2.201.45-2.962 1.173-.762.724-1.234 1.72-1.234 2.817v2.384c0 .778-.664 1.409-1.482 1.409s-1.482-.63-1.482-1.41v-2.383zM8 12.58c0-.778.664-1.41 1.482-1.41h2.208c.745 0 1.354.58 1.354 1.288 0 .708-.609 1.287-1.354 1.287h-.726v5.446c0 1.096.473 2.093 1.234 2.817a4.294 4.294 0 0 0 2.963 1.173c1.152 0 2.201-.45 2.962-1.173.762-.724 1.234-1.721 1.234-2.817v-2.384c0-.778.664-1.41 1.482-1.41s1.482.632 1.482 1.41v2.384c0 1.873-.805 3.576-2.102 4.81A7.328 7.328 0 0 1 15.16 26 7.328 7.328 0 0 1 10.102 24C8.805 22.768 8 21.065 8 19.191V12.58z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1=".906"
        x2="28.093"
        y1="10.843"
        y2="-.621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#84CFF2" />
        <stop offset=".981" stopColor="#4681C2" />
        <stop offset="1" stopColor="#4681C2" />
      </linearGradient>
    </defs>
  </svg>
);

export default Assistant;
