// @flow

import React from "react";

type Props = {
  fill?: string
};

const AlignLeft = ({ fill, ...restProps }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
    <title>ic_format_align_left_24px</title>
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z" />
    </g>
  </svg>
);

export default AlignLeft;
