// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

type Props = {
  label: string,
  isOpen: Function,
  onDelete: Function,
  toggleDeleteFieldModal: Function
};

const DeleteFieldModal = ({
  label,
  isOpen,
  onDelete,
  toggleDeleteFieldModal
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={toggleDeleteFieldModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete <strong>{label}</strong>?
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={toggleDeleteFieldModal}>
            Cancel
          </Button>
          <Button variant="solid" colorScheme="red" onClick={onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFieldModal;
