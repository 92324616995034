// @flow

import React, { Fragment } from "react";

import Owner from "../Owner";
import Details from "../Details";
import Parent from "../Parent";

import type { UID, RoomId } from "src/types";

type Props = {
  description: string,
  owner: ?UID,
  parent: ?RoomId,
  handleOwner: Function,
  handleChange: Function,
  handleParent: Function
};

const Advanced = ({
  description,
  parent,
  owner,
  handleOwner,
  handleChange,
  handleParent
}: Props) => (
  <Fragment>
    <Owner owner={owner} handleOwner={handleOwner} />
    <Details description={description} handleChange={handleChange} />
    <Parent parent={parent} handleParent={handleParent} />
  </Fragment>
);

export default Advanced;
