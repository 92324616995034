// @flow

import React from "react";
import Reminders from "src/components/Dock/Reminders";

import type { RoomId } from "src/types";
type Props = {
  roomId: RoomId,
  isSrwMobile?: boolean,
  location?: string,
  setIsMoreMenuOpen: (value: boolean) => void
};

const RemindersWrapper = ({
  roomId,
  isSrwMobile,
  location,
  setIsMoreMenuOpen
}: Props) => {
  return (
    <Reminders
      isSrwMobile={isSrwMobile}
      roomId={roomId}
      location={location}
      setIsMoreMenuOpen={setIsMoreMenuOpen}
    />
  );
};

export default RemindersWrapper;
