// @flow

import React, { useEffect, useCallback, useRef } from "react";
import AutosizeInput from "react-input-autosize";

import { Edit as StyledEdit } from "./styles";

type Props = {
  value: any,
  handleChange: Function,
  handleSubmit: Function,
  handleKeyPress: Function,
  handleClose: Function
};

const Edit = ({
  value,
  handleSubmit,
  handleKeyPress,
  handleChange,
  handleClose
}: Props) => {
  const parentRef = useRef(null);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutside, false);
    return () => {
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  return (
    <StyledEdit ref={parentRef} onSubmit={handleSubmit}>
      <AutosizeInput
        type="text"
        placeholder="Enter conversation title"
        onKeyDown={handleKeyPress}
        value={value}
        onChange={handleChange}
        autoFocus
      />
    </StyledEdit>
  );
};

export default Edit;
