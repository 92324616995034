// @flow

import { connect } from "react-redux";
import React from "react";

import List from "./List";

import type { AppState, GroupFilter } from "src/types";

type Props = {
  filter: GroupFilter
};

const Groups = ({ filter }: Props) => {
  return <List filter={filter} />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.groups.filter
});

export default connect(mapStateToProps)(Groups);
