// @flow

import { connect } from "react-redux";
import React from "react";
import usePortal from "react-useportal";
import * as R from "ramda";
import type { Map as ImmutableMap } from "immutable";

import Field from "./ChecklistField";
import {
  Modal,
  Overlay
} from "src/components/Manage/Workflow/Instances/EditChecklistModal/styles";
import { closeChecklistManageView } from "src/actions/checklist";
import { getChecklistFieldDetails } from "src/reducers";
import OutsideClickHandler from "src/components/OutsideClickHandler";

import type { AppState } from "src/types";

type Props = {
  details: ImmutableMap<string, any>,
  selectedChecklist: Object,
  _closeChecklistManageView: Function
};

const EditChecklistModal = ({
  details,
  selectedChecklist,
  _closeChecklistManageView
}: Props) => {
  const { Portal } = usePortal();

  const { fieldId, value, roomId, formId } = selectedChecklist;
  const type = details ? details.get("type") : "";

  if (R.isEmpty(selectedChecklist)) {
    return <></>;
  }

  const checklistValue = {
    fieldId: fieldId,
    val: { value, type }
  };

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={_closeChecklistManageView}>
          <Modal>
            <Field
              id={fieldId}
              roomId={`${roomId}`}
              type={type}
              formId={formId}
              selectedValue={value}
              checklistValue={checklistValue}
            />
          </Modal>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const selectedChecklist = app.checklist.selectedChecklist;
  return {
    selectedChecklist,
    details: getChecklistFieldDetails(app, `${selectedChecklist.fieldId}`)
  };
};

export default connect(mapStateToProps, {
  _closeChecklistManageView: closeChecklistManageView
})(EditChecklistModal);
