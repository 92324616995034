// @flow

import * as R from "ramda";
import transit from "transit-js";

import type { TransitMap } from "src/types";

import transitTypes from "./types";

const readHandler = {
  text: (rep: TransitMap) =>
    new transitTypes.text({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  number: (rep: TransitMap) =>
    new transitTypes.number({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  date: (rep: TransitMap) =>
    new transitTypes.date({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  user: (rep: TransitMap) =>
    new transitTypes.user({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  pdf: (rep: TransitMap) =>
    new transitTypes.pdf({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  file: (rep: TransitMap) =>
    new transitTypes.file({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  conversation: (rep: TransitMap) => {
    const settings = !R.isEmpty(rep.get("settings"))
      ? JSON.parse(rep.get("settings"))
      : rep.get("settings");

    if (settings.child) {
      return new transitTypes.childConversation({
        label: rep.get("label"),
        importError: rep.get("importError"),
        autoCheck: rep.get("autoCheck"),
        settings: rep.get("settings")
      });
    }

    return new transitTypes.conversation({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    });
  },
  select: (rep: TransitMap) =>
    new transitTypes.select({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  form: (rep: TransitMap) =>
    new transitTypes.form({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  section: (rep: TransitMap) => {
    const settings = !R.isEmpty(rep.get("settings"))
      ? JSON.parse(rep.get("settings"))
      : rep.get("settings");

    if (parseInt(settings.level || 0, 10) > 0) {
      return new transitTypes.subSection({
        label: rep.get("label"),
        autoCheck: rep.get("autoCheck"),
        settings: rep.get("settings")
      });
    }

    return new transitTypes.section({
      label: rep.get("label"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    });
  },
  approval: (rep: TransitMap) =>
    new transitTypes.approval({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),

  revision: (rep: TransitMap) =>
    new transitTypes.revision({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings")
    }),
  link: (rep: TransitMap) =>
    new transitTypes.link({
      label: rep.get("label"),
      importError: rep.get("importError"),
      autoCheck: rep.get("autoCheck"),
      settings: rep.get("settings"),
      sourceProcessSettings: rep.get("sourceProcessSettings"),
      linkedProcessSettings: rep.get("linkedProcessSettings")
    }),

  // Old Transit Readers
  chatPickList: (rep: TransitMap) =>
    new transitTypes.chatPickList({
      label: rep.get("label"),
      isRequired: rep.get("isRequired"),
      placeholder: rep.get("placeholder"),
      isChecked: rep.get("isChecked"),
      settings: rep.get("settings")
    }),
  task: (rep: TransitMap) =>
    new transitTypes.task({
      label: rep.get("label"),
      isRequired: rep.get("isRequired"),
      placeholder: rep.get("placeholder"),
      isChecked: rep.get("isChecked")
    }),
  group: (rep: TransitMap) =>
    new transitTypes.group({
      label: rep.get("label"),
      isRequired: rep.get("isRequired"),
      placeholder: rep.get("placeholder"),
      isChecked: rep.get("isChecked")
    }),
  workflow: (rep: TransitMap) =>
    new transitTypes.workflow({
      label: rep.get("label"),
      isRequired: rep.get("isRequired"),
      placeholder: rep.get("placeholder"),
      isChecked: rep.get("isChecked"),
      settings: rep.get("settings")
    })
};

export default readHandler;

export const fieldTransitReader = transit.reader("json", {
  handlers: readHandler
});
