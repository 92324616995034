import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

import { Overlay } from "src/styles/modal";
import { Button as StyledButton } from "src/styles/buttons";

export const Overlays = styled(Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainWrapper = styled.div`
  display: grid;
  padding-bottom: 2em;
  background: white;
`;

export const Header = styled.div`
  width: 100%;
  background: ${colors.grey0};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.57em 2em;
`;

export const HeaderText = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  color: ${colors.primary};
  padding-left: 1.29em;
  padding-right: 1.29em;
`;

export const CloseWrapper = styled.div`
  cursor: pointer !important;
  > svg {
    height: 0.9em;
    width: 0.9em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
      stroke-width: 3px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CanvasWrapper = styled.div`
  padding: ${spacing.space_xl};
  padding-top: 1.43em;
  padding-bottom: 0.893749;
`;

export const Button = styled(StyledButton)`
  width: 115px;
  height: 2.57em;
  background: ${colors.activeFilter};
  border-radius: ${spacing.space_xs};
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: none;
  margin-left: ${spacing.space_xl};
`;

export const MessageText = styled.div`
  font-size: 0.88rem;
  line-height: 1.25rem;
  color: ${colors.primary};
  padding-left: ${spacing.space_xl};
  padding-top: ${spacing.space_l};
  padding-right: ${spacing.space_xl};
`;
