// @flow

import React, { useState } from "react";
import { connect } from "react-redux";

import { addCustomRole } from "src/actions/roleManagement";

import { EditablePreview } from "@chakra-ui/react";
import {
  Editable as StyledEditable,
  EditableInput as StyledEditableInput
} from "src/styles/input";

type Props = {
  header: string,
  _addCustomRole: Function
};

const placeholderText = "+ Add a new Role";

const NewRole = ({ _addCustomRole }: Props) => {
  const [value, setValue] = useState(placeholderText);

  const resetValue = () => setValue(placeholderText);

  const handleChange = nextValue => setValue(nextValue);

  const handleSubmit = () => {
    if (value && value !== placeholderText) {
      _addCustomRole(value);
      resetValue();
    }
  };

  return (
    <StyledEditable
      defaultValue={placeholderText}
      value={value}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onCancel={resetValue}
    >
      <EditablePreview />
      <StyledEditableInput py={2} />
    </StyledEditable>
  );
};

export default connect(null, {
  _addCustomRole: addCustomRole
})(NewRole);
