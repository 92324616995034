// @flow

import React, { useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import disconnect from "src/img/disconnect.svg";
import disconnectRed from "src/img/disconnectRed.svg";
import { DisconnectButton as Button } from "./styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  onClick: Function
};

const DisconnectButton = ({ onClick }: Props) => {
  const [isMouseOver, setMouseOver] = useState(false);
  return (
    <Tooltip label="Disconnect" bg={colors.tooltip} hasArrow placement="top">
      <Button
        onClick={onClick}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        src={isMouseOver ? disconnectRed : disconnect}
      />
    </Tooltip>
  );
};

export default DisconnectButton;
