// @flow

import React from "react";

const DirectDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
    <path
      d="M40 22H24C22.9 22 22.01 22.9 22.01 24L22 42L26 38H40C41.1 38 42 37.1 42 36V24C42 22.9 41.1 22 40 22ZM28 34H26V32H28V34ZM28 31H26V29H28V31ZM28 28H26V26H28V28ZM35 34H30V32H35V34ZM38 31H30V29H38V31ZM38 28H30V26H38V28Z"
      fill="#2F80ED"
    />
  </svg>
);

export default DirectDark;
