// @flow

import React from "react";

import IconLabel from "src/components/IconLabel/Remove";
import { iconSize } from "src/styles/constants/size";
import type { Workflow } from "src/types";

type Props = {
  workflow: Workflow,
  removeHandler: Function
};

const Selection = ({ workflow, removeHandler }: Props) => (
  <div>
    <IconLabel
      icon="workflow"
      size={iconSize.space_l}
      label={workflow.title}
      removeHandler={removeHandler}
    />
  </div>
);

export default Selection;
