// @flow

import React from "react";
import { connect } from "react-redux";

import { Chatrooms as StyledChatrooms } from "./styles";
import FilteredConversations from "./Filtered";

const Conversation = () => {
  return (
    <StyledChatrooms>
      <FilteredConversations />
    </StyledChatrooms>
  );
};

export default connect(null)(Conversation);
