// @flow

import * as actions from "src/constants/actionTypes";
import type { Action, AppIntegration } from "src/types";

export const syncIntegrations = (): Action => ({
  type: actions.SYNC_INTEGRATIONS,
  payload: {}
});

export const setIntegrations = (app: Array<AppIntegration>): Action => ({
  type: actions.SET_ALL_INTEGRATIONS,
  payload: app
});

export const installIntegration = (app: string): Action => ({
  type: actions.INSTALL_INTEGRATION_SUCCESS,
  payload: app
});

export const updateIntegration = (
  id: number,
  integration: AppIntegration
): Action => ({
  type: actions.UPDATE_INTEGRATION_SUCCESS,
  payload: { id, response: integration }
});

export const deleteIntegration = (id: number): Action => ({
  type: actions.DELETE_INTEGRATION_SUCCESS,
  payload: { id }
});
