// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";

import { fetchForm, getUniqueValues } from "src/actions/forms";
import Breadcrumbs from "./Breadcrumbs";
import EditChecklistModal from "./EditChecklistModal";
import Search from "./Search";
import Filters from "./Filters";
import Table from "./Table";
import MenuBar from "./MenuBar";
import Close from "src/containers/Close";
import { OpaqueOverlay as StyledInstances } from "src/components/Manage/Workflow/styles";

type Props = {
  id: string,
  _fetchForm: Function,
  _getUniqueValues: Function,
  reportId?: number
};

const Instances = ({ id, _fetchForm, _getUniqueValues, reportId }: Props) => {
  useEffect(() => {
    _fetchForm(id, false);
    _getUniqueValues(id);
  }, []);

  return (
    <StyledInstances>
      <Close />
      <EditChecklistModal />
      <Breadcrumbs reportId={reportId} />
      <Search />
      <Filters />
      <MenuBar reportId={reportId} />
      <Table />
    </StyledInstances>
  );
};

export default connect(null, {
  _fetchForm: fetchForm,
  _getUniqueValues: getUniqueValues
})(Instances);
