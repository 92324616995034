// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import SharepointItem from "./SharepointItem";
import type { SharepointFileCreateOptions } from "src/types";
import { fileCreationMap } from "src/constants/integrations";
import { getIsMs365Active, getIsMs365AdminActive } from "src/reducers";

type Props = {
  onSelect: Function,
  isLoading: boolean,
  createOptions: SharepointFileCreateOptions
};

const SharepointSource = ({ onSelect, isLoading, createOptions }: Props) => {
  const sharepointIcon: Object = require(`src/img/apps/sharepoint.svg`);
  const isMs365Active = useSelector(({ app }) => getIsMs365Active(app));
  const isMs365AdminActive = useSelector(({ app }) =>
    getIsMs365AdminActive(app)
  );
  return (
    <>
      <SharepointItem
        id="sharepoint"
        title="Add from Sharepoint / One Drive"
        isLoading={isLoading}
        isActive={isMs365Active}
        icon={sharepointIcon}
        onClick={() => onSelect("sharepoint")}
      />
      {!R.isEmpty(createOptions) &&
        Array.from(fileCreationMap).map(([key, value]) => (
          <SharepointItem
            key={key}
            id={value.id}
            title={value.title}
            isLoading={isLoading}
            icon={value.icon}
            isActive={isMs365AdminActive}
            onClick={() => onSelect(value.id)}
          />
        ))}
    </>
  );
};

export default SharepointSource;
