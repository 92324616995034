// @flow

import React, { useState, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";

import { Priority as StyledPriority } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import Options from "src/components/chatroom/Priority/Options";
import Item from "src/components/chatroom/Priority/Item";
import { getFieldWidth } from "src/reducers";
import {
  bulkUpdateProcess,
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import Icon from "src/icons";

type Props = {
  id: string,
  value: ?string,
  index: number,
  selected: boolean,
  parentId: number,
  depth: number,
  _setAttribute: Function,
  _bulkUpdateProcess: Function,
  _updateNestedRow: Function
};

const Priority = ({
  id,
  value,
  selected,
  index,
  depth,
  parentId,
  _updateNestedRow,
  _setAttribute,
  _bulkUpdateProcess
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, "priority"));
  const [dropdown, showDropdown] = useState(false);
  const outerRef = useRef(null);

  const handleChange = useCallback(
    (newValue: string) => {
      if (!selected) {
        if (depth > 0) {
          _updateNestedRow(id, { priority: newValue }, index, parentId);
        } else {
          _setAttribute(id, { priority: newValue }, index);
        }
      } else {
        _bulkUpdateProcess({ attrs: { priority: newValue } });
      }
    },
    [id, depth, parentId, _updateNestedRow, selected, _setAttribute]
  );

  return (
    <StyledPriority
      width={fieldWidth}
      selected={selected}
      ref={outerRef}
      tabIndex="0"
      onClick={() => showDropdown(!dropdown)}
      onKeyPress={() => showDropdown(!dropdown)}
    >
      <ValueWrapper showDropDown={true}>
        <Item value={value} />
        <Icon type="editPencil" />
      </ValueWrapper>
      {dropdown ? (
        <Options
          value={value || "normal"}
          selected={selected}
          outerRef={outerRef}
          handleChange={handleChange}
          handleClose={() => showDropdown(false)}
        />
      ) : null}
    </StyledPriority>
  );
};

export default connect(null, {
  _setAttribute: updateChatroomFromManageView,
  _bulkUpdateProcess: bulkUpdateProcess,
  _updateNestedRow: updateNestedRow
})(Priority);
