// @flow

import React from "react";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const PhoneNumber = ({ user }: Props) => (
  <span title={user.phoneNumber}>
    {user.phoneNumber ? user.phoneNumber : "_"}
  </span>
);

export default PhoneNumber;
