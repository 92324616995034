// @flow

import React from "react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

type Props = {
  value: string,
  onChange: Function
};

export default function MessageToSend({ value, onChange }: Props) {
  return (
    <FormControl>
      <FormLabel fontSize="sm">Send Message</FormLabel>
      <Input size="sm" value={value} onChange={e => onChange(e.target.value)} />
    </FormControl>
  );
}
