// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { validateEmail } from "src/utils";
import * as R from "ramda";

import type { ComponentType } from "react";
import { getUser } from "src/reducers";

import type { AppState } from "src/types";

const UnifizeUser = (UserComponent: ComponentType<Object>) => {
  const component = class extends Component<Object> {
    constructor(props) {
      super(props);
    }

    render() {
      const { uid, loading, ...rest } = this.props;
      const isEmail = validateEmail(uid);

      let { user, isCurrentUser } = this.props;

      if (!user || R.isEmpty(user)) {
        if (uid === "unibot") {
          user = { displayName: "Unifize Assistant", loading };
        } else if (isEmail) {
          user = { displayName: uid, loading };
        } else if (loading) {
          user = { displayName: "", loading };
        } else {
          user = { displayName: "Deleted User", loading };
        }
      }

      return (
        <UserComponent
          uid={uid}
          {...rest}
          user={user}
          isCurrentUser={isCurrentUser}
        />
      );
    }
  };

  const mapStateToProps = ({ app }: { app: AppState }, props) => ({
    user: getUser(app, props.uid),
    uid: props.uid,
    loading: app.users.loading
  });

  component.displayName = `UnifizeUser`;
  return connect(mapStateToProps)(component);
};

export default UnifizeUser;
