// @flow

import * as React from "react";

type Props = {};

const HashMark = ({ ...restProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M2.99 15H5.01L5.439 12H9.418L8.99 15H11.01L11.439 12H14V10H11.725L12.3 6H15V4H12.582L13.01 1H10.99L10.561 4H6.582L7.01 1H4.99L4.561 4H2V6H4.275L3.7 10H1V12H3.418L2.99 15ZM6.3 6H10.279L9.7 10H5.725L6.3 6Z" />
    </svg>
  );
};

export default HashMark;
