// @flow

import React from "react";
import { connect } from "react-redux";

import { DemoUser as StyledDemoUser } from "./styles";

import { getRole, getCurrentUserId } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  role: string
};

const DemoUser = ({ role }: Props) => {
  if (role === "demo") {
    return (
      <StyledDemoUser type="button" id="demo-user">
        Start with this solution
      </StyledDemoUser>
    );
  }

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps)(DemoUser);
