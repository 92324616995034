// @flow

import { combineReducers } from "redux";
import * as R from "ramda";
import { createSelector } from "reselect";

import * as atypes from "src/constants/actionTypes";
import { generateById } from "src/utils";
import { generateByFieldId } from "src/utils/charts";

import type {
  Action,
  ChartType,
  NewChartDialog,
  ChartState,
  ChartsById,
  ChartData,
  ChartRefreshingById,
  ChartsDropdownFieldList,
  ChartId
} from "src/types";

const types = (state: Array<ChartType> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_CHART_TYPES_SUCCESS:
      return payload.chatTypes;
    default:
      return state;
  }
};

const initialChartDialog = {
  id: null,
  title: "",
  reportId: null,
  description: "",
  yAxisLabel: "",
  type: null,
  x: null,
  y: null,
  computationField: null,
  hide: false,
  relativeCount: false,
  numberField: null,
  form: null,
  formField: null,
  sort: {
    seriesId: null,
    orderBy: "default"
  },
  columns: {
    created: false,
    completed: false
  },
  timeInterval: "daily",
  timeDiff: 0,
  // Fields managing new chart dialog state
  show: false,
  stage: 0,
  error: null,
  chartName: null,
  edit: false,
  loading: false,
  numberFieldSwitch: false
};

const newChartDialog = (
  state: NewChartDialog = initialChartDialog,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES:
      return { ...state, ...payload };
    case atypes.CLOSE_NEW_CHART_DIALOG:
      return initialChartDialog;
    default:
      return state;
  }
};

const chartData = (
  state: ChartData = { loading: {}, charts: {} },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CHART_LOADING_STATE: {
      const chartIds = payload.chartIds;
      const loadingStates = {};
      chartIds.map(chartId => {
        loadingStates[chartId] = true;
      });
      return {
        ...state,
        loading: loadingStates
      };
    }

    case atypes.GET_CHART_JSON_REQUEST:
    case atypes.GET_REPORTS_CHART_JSON_REQUEST:
    case atypes.CREATE_COMPARISON_CHART_REQUEST:
    case atypes.UPDATE_COMPARISON_CHART_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload?.id]: true
        }
      };

    case atypes.GET_REPORTS_CHART_JSON_SUCCESS:
    case atypes.GET_CHART_JSON_SUCCESS:
    case atypes.REFRESH_CHART_SUCCESS:
      const { id, ...rest } = payload;
      return {
        ...state,
        charts: {
          ...state.charts,
          [id]: rest
        },
        loading: {
          ...state.loading,
          [id]: false
        }
      };

    case atypes.CREATE_CHART_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.id]: false
        }
      };

    case atypes.DELETE_CHART_SUCCESS:
      const targetChartId = payload.id;
      const charts = { ...state.charts };
      delete charts[targetChartId];
      return { ...state, charts };

    case atypes.GET_CHART_JSON_FAILURE:
    case atypes.GET_REPORTS_CHART_JSON_FAILURE:
    case atypes.CREATE_COMPARISON_CHART_FAILURE:
    case atypes.UPDATE_COMPARISON_CHART_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.id]: false
        }
      };

    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    default:
      return state;
  }
};

const chartsById = (
  state: ChartsById = {
    loading: false,
    charts: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        charts: generateById({ items: payload.charts })
      };

    case atypes.GET_CHART_SUCCESS:
    case atypes.UPDATE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        charts: {
          ...state.charts,
          [payload.id]: payload
        }
      };

    case atypes.FETCH_CHART_REQUEST:
      return { ...state, loading: true };

    case atypes.FETCH_CHART_FAILURE:
      return { ...state, loading: false };

    case atypes.FETCH_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        charts: {
          ...state.charts,
          [payload.id]: payload
        }
      };
    case atypes.DELETE_CHART_SUCCESS:
      const charts = { ...state.charts };
      if (charts[payload.id]) {
        delete charts[payload.id];
      }
      return {
        loading: false,
        charts
      };

    default:
      return state;
  }
};

const searchedColumns = (
  state: Array<string> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_COLUMN_NAMES_SUCCESS:
      return payload.result;
    default:
      return state;
  }
};

/*
 * Tracks charts that are getting refreshed - using this to decide
 * whether to show the spinner on the chart
 */
const refreshingById = (
  state: ChartRefreshingById = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.REFRESH_CHART_REQUEST:
      return { ...state, [payload.id]: true };
    case atypes.REFRESH_CHART_SUCCESS:
    case atypes.REFRESH_CHART_ERROR:
      return R.omit([payload.id], state);
    default:
      return state;
  }
};

const primaryFieldList = (
  state: { loading: boolean, fields: ChartsDropdownFieldList } = {
    loading: false,
    fields: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_PRIMARY_FIELD_LIST_REQUEST:
      return { ...state, loading: true };
    case atypes.GET_PRIMARY_FIELD_LIST_SUCCESS:
      const fields = generateByFieldId(payload.fields);
      return { ...state, fields, loading: false };
    case atypes.GET_PRIMARY_FIELD_LIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

const recordCountFieldList = (
  state: { loading: boolean, fields: ChartsDropdownFieldList } = {
    loading: false,
    fields: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_RECORD_COUNT_FIELD_LIST_REQUEST:
      return { ...state, loading: true };
    case atypes.GET_RECORD_COUNT_FIELD_LIST_SUCCESS:
      const fields = generateById({ items: payload.fields, idAsNumber: true });
      return { ...state, fields, loading: false };
    case atypes.GET_RECORD_COUNT_FIELD_LIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

const numericalFieldList = (
  state: { loading: boolean, fields: ChartsDropdownFieldList } = {
    loading: false,
    fields: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_NUMERICAL_FIELD_LIST_REQUEST:
      return { ...state, loading: true };
    case atypes.GET_NUMERICAL_FIELD_LIST_SUCCESS:
      const fields = generateById({ items: payload.fields, idAsNumber: true });
      return { ...state, fields, loading: false };
    case atypes.GET_NUMERICAL_FIELD_LIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

const embeddedNumericalFieldList = (
  state: { loading: boolean, fields: ChartsDropdownFieldList } = {
    loading: false,
    fields: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_REQUEST:
      return { ...state, loading: true };
    case atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_SUCCESS:
      const fields = generateById({ items: payload.fields, idAsNumber: true });
      return { ...state, fields, loading: false };
    case atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

const groupByFieldList = (
  state: { loading: boolean, fields: ChartsDropdownFieldList } = {
    loading: false,
    fields: {}
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_GROUP_BY_FIELD_LIST_REQUEST:
      return { ...state, loading: true };

    case atypes.GET_GROUP_BY_FIELD_LIST_SUCCESS:
      const fields = generateById({ items: payload.fields, idAsNumber: true });
      return { ...state, fields, loading: false };

    case atypes.GET_GROUP_BY_FIELD_LIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  types,
  newChartDialog,
  chartData,
  chartsById,
  searchedColumns,
  refreshingById,
  primaryFieldList,
  recordCountFieldList,
  numericalFieldList,
  embeddedNumericalFieldList,
  groupByFieldList
});

export const getCurrentChartName = (state: ChartState) =>
  state.newChartDialog.chartName;

export const getChart = (state: ChartState, id: string) =>
  state.chartsById.charts[id] || {};

export const getChartLoading = (state: ChartState) => state.chartsById.loading;

export const getChartData = (state: ChartState, id: string) =>
  state.chartData.charts[id] || {};

export const getChartLastGeneratedTime = createSelector(
  getChart,
  chart => chart.lastGeneratedAt
);

export const getIfChartIsRefreshing = (state: ChartState, id: string) =>
  state.refreshingById[id] || false;

export const getChartPrimaryFieldList = (state: ChartState) =>
  state.primaryFieldList.fields;

export const getChartPrimaryFieldListLoading = (state: ChartState) =>
  state.primaryFieldList.loading;

export const getChartRecordCountFieldList = (state: ChartState) =>
  state.recordCountFieldList.fields;

export const getChartRecordCountFieldListLoading = (state: ChartState) =>
  state.recordCountFieldList.loading;

export const getChartFormFieldList = createSelector(
  [getChartRecordCountFieldList],
  recordCountFieldList => {
    const recordArray = R.values(recordCountFieldList) || [];
    const forms = recordArray.filter(item => item.type === "form");
    let resultMap = {};
    for (let i = 0; i < forms.length; i++) {
      const obj = forms[i];
      resultMap[obj.id] = obj;
    }
    return resultMap;
  }
);

export const getChartNumericalFieldList = (state: ChartState) =>
  state.numericalFieldList.fields;

export const getChartNumericalFieldListLoading = (state: ChartState) =>
  state.numericalFieldList.loading;

export const getChartEmbeddedNumericalFieldList = (state: ChartState) =>
  state.embeddedNumericalFieldList.fields;

export const getChartEmbeddedNumericalFieldListLoading = (state: ChartState) =>
  state.embeddedNumericalFieldList.loading;

export const getChartGroupByList = (state: ChartState) =>
  state.groupByFieldList.fields;

export const getChartGroupByListLoading = (state: ChartState) =>
  state.groupByFieldList.loading;

export const getAllCharts = (state: ChartState) => state.chartsById.charts;

export const getChartsByReport = createSelector(
  [getAllCharts, (_, reportId) => reportId],
  (charts, reportId) => {
    const chartsList = R.values(charts);
    return chartsList
      .filter(chart => chart.reportId === Number(reportId))
      .map(chart => {
        return { id: chart.id, title: chart.title };
      });
  }
);

export const getTotalChartsCount = createSelector(
  getChartsByReport,
  chartsByReport => chartsByReport.length
);

export const getAllChartIds = createSelector(getAllCharts, charts => {
  const chartsList = R.values(charts);
  return chartsList.map(chart => {
    return {
      id: chart.id,
      title: chart.title
    };
  });
});

export const getChartDialog = (state: ChartState) => state.newChartDialog;

export const getChartDataLoading = (state: ChartState, chartId: ChartId) =>
  state.chartData.loading?.[chartId];

export const getChartReportId = createSelector(
  getChart,
  chart => chart.reportId
);

export const getNewChartDialogTitle = (state: ChartState) =>
  state.newChartDialog.title;

export const getNewChartDialogId = (state: ChartState) =>
  state.newChartDialog.id;
