// @flow

import React from "react";
import { TableHeader } from "./styles";
import { Th } from "src/styles/table";

const Header = () => (
  <TableHeader>
    <tr>
      <Th>Report Name</Th>
      <Th>Created At</Th>
      <Th>Updated At</Th>
      <Th>Created By</Th>
    </tr>
  </TableHeader>
);

export default Header;
