// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import type { UnifizeFile, RoomId } from "src/types";
import { getFileType, getFilePreviewSupport } from "src/utils/file";
import { getFile, getFilePreviewURL } from "src/reducers";
import {
  getFile as _getFile,
  downloadFile,
  getFilePreview
} from "src/actions/file";
import useFileViewer from "src/hooks/useFileViewer";

import { Flex, HStack, Text, Box, Tooltip } from "@chakra-ui/react";
import Icons from "src/icons";

import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  name: string,
  originalName: string
};

export default function File({ roomId, name, originalName }: Props) {
  const dispatch = useDispatch();

  const isConversationModalOpen = useSelector(
    state => state.app.conversationModal.show
  );
  const file: ?UnifizeFile = useSelector(state => getFile(state.app, name));
  const preview = useSelector(state => getFilePreviewURL(state.app, name));

  const [previewURL, setPreviewURL] = useState("");

  const { openFile } = useFileViewer({
    file,
    modal: isConversationModalOpen,
    roomId
  });

  // Fetch the file data if it isn't there in the store
  useEffect(() => {
    if (!file) {
      dispatch(_getFile(name));
    }
  }, [file]);

  // Fetch the file preview if it isn't there in the store
  useEffect(() => {
    if (
      file &&
      getFilePreviewSupport(file) &&
      (!preview || moment().diff(preview?.date, "minutes") > 10) &&
      name
    ) {
      dispatch(getFilePreview({ name, roomId }));
    }

    if (preview?.url) {
      setPreviewURL(preview.url);
    }
  }, [preview, file, name, roomId]);

  // Sometimes the preview URL returns 404
  // Handling it by trying to load the image
  // again after 3 seconds
  const handlePreviewError = () => {
    setPreviewURL("");
    setTimeout(() => {
      setPreviewURL(preview);
    }, 3000);
  };

  const fileType = getFileType(originalName);

  const handleDownload = () => {
    if (file)
      dispatch(
        downloadFile({
          roomId,
          location: isConversationModalOpen
            ? "conversation-dialog"
            : "chat-history",
          name: file.name,
          originalName: file.originalName,
          generation: file.generation,
          versionId: file.versionId
        })
      );
  };

  return (
    <Flex {...styles.container}>
      <HStack onClick={() => openFile()} sx={{ cursor: "pointer" }}>
        {previewURL ? (
          <img
            src={preview.url}
            onError={handlePreviewError}
            style={{
              width: "50px",
              height: "auto"
            }}
          />
        ) : (
          <Icons type="fileIcons" icon={fileType} {...styles.fileIcon} />
        )}

        <Tooltip label={originalName}>
          <Text {...styles.fileName}>{originalName}</Text>
        </Tooltip>
      </HStack>

      {file && (
        <Box onClick={handleDownload} sx={{ cursor: "pointer" }}>
          <Icons type="download" {...styles.downloadIcon} />
        </Box>
      )}
    </Flex>
  );
}
