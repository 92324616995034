// @flow

import styled from "@emotion/styled";

import { Cell } from "../styles";
import * as spacing from "src/styles/constants/spacing";

const Priority = styled(Cell)`
  cursor: pointer;
  position: relative;
  outline: none !important;
  align-items: start;
  justify-content: center;
  > div {
    justify-content: center;
  }
  > div > svg:first-of-type {
    margin-left: ${spacing.space_m};
  }
  > div > svg:nth-of-type(2) {
    position: relative;
    left: ${spacing.space_m};
    visibility: hidden;
    width: 20px;
    height: 20px;
  }
  :hover {
    > div > svg:nth-of-type(2) {
      visibility: visible;
    }
  }
`;

export { Priority };
