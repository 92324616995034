// @flow

import React from "react";
import { connect } from "react-redux";

import {
  Name as StyledName,
  ReplyMessageContainer,
  ReplyMessageContent,
  CloseIcon
} from "../styles";
import Notification from "./Notification";
import MessageText from "src/components/messages/MessageText";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { Reply as StyledReply } from "src/styles";
import * as colors from "src/styles/constants/colors";
import { getAuthorColor } from "src/utils";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import Icon from "src/icons";

import type { ChatroomAddress } from "src/types";

type Props = {
  currentMessage: Object,
  clearReplyTo: Function,
  currentRoomAddress: ChatroomAddress
};

const Reply = ({ currentMessage, clearReplyTo, currentRoomAddress }: Props) => {
  const { action, author } = currentMessage;

  return (
    <StyledReply>
      <ReplyMessageContainer>
        <div>
          <StyledName hashColor={getAuthorColor(author, currentRoomAddress)}>
            <NameOrEmail uid={author} />
          </StyledName>
          {action ? (
            <Notification message={currentMessage} />
          ) : (
            <ReplyMessageContent>
              <MessageText message={currentMessage} />
            </ReplyMessageContent>
          )}
        </div>
      </ReplyMessageContainer>
      <CloseIcon>
        <Icon
          type="close"
          color={colors.secondary}
          handleClick={clearReplyTo}
          cursor="pointer"
        />
      </CloseIcon>
    </StyledReply>
  );
};

const mapStateToProps = ({ app }) => ({
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps)(Reply);
