// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getFile } from "src/reducers";
import { getFile as resolveFile } from "src/actions/file";

import type { AppState, UnifizeFile } from "src/types";

type Props = {
  name: string,
  _getFile: Function,
  thumbnail: boolean,
  file: UnifizeFile
};

const Display = (props: Props) => {
  const { name, file, _getFile } = props;

  useEffect(() => {
    if (name && R.isEmpty(file || {})) {
      _getFile(name);
    }
  }, [name, file, _getFile]);

  return <div>{props.file?.originalName}</div>;
};

const mapStateToProps = ({ app }: { app: AppState }, { name }) => {
  return {
    file: getFile(app, name)
  };
};

export default connect(mapStateToProps, { _getFile: resolveFile })(Display);
