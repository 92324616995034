// @flow

import styled from "@emotion/styled";

import { Input as DefaultInput, TextInputWithBorders } from "src/styles/input";
import { ListItemMedium } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { SelectMultiple } from "src/styles/dropdown";
import { Button as DefaultButton, Secondary } from "src/styles/buttons";

export const Select = styled(SelectMultiple)`
  position: relative;
  padding: ${spacing.space_m};
  z-index: 2;
`;

export const Input = styled(DefaultInput)`
  flex: 1;
  font-size: 13px;
  ::placeholder {
    color: ${colors.grey5};
    font-weight: ${typography.light};
  }
`;

export const Item = styled(ListItemMedium)`
  max-width: 98%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 6px;
  line-height: 20px;
`;

export const Remove = styled.div`
  display: flex;
  gap: 2rem;
  background: ${colors.brightLight};
  width: 100%;
  height: 2em;
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  :disabled {
    background: ${colors.secondary} !important;
    color: ${colors.brightLight} !important;
  }
`;

export const SelectedStatusWrapper = styled.ul`
  display: grid;
  padding: 0px;
  margin: 0.1em;
`;

export const List = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  user-select: none;
  /* Keep list on top while dragging only when status chande modal
 is closed 
  */
  ${props => !props.statusModal && "z-index: 2"};
  > svg {
    cursor: n-resize;
  }
`;

export const SearchInput = styled(TextInputWithBorders)`
  border: none !important;
  min-width: 70px !important;
  width: 160px !important;
  height: 22px;
  margin-right: ${spacing.space_s};
  padding: 4px;
`;

export const EditSearchInput = styled(SearchInput)`
  width: 140px !important;
  margin: 0px !important;
  font-size: 12px;
  border: 1px solid ${colors.brightLight} !important;
`;

export const InputBlueTickWrapper = styled.div`
  width: 160px;
  height: 24px;
  border: 1px solid ${colors.inkLightest};
  padding-right: 0.1em;
  border-radius: ${radius.small};
  display: flex;
  align-items: center;
`;

export const BlueTickIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.inkLightest};
  border-radius: 2px;
  height: 24px;
  padding-right: 0.1em;
  width: 100%;
  > svg {
    cursor: pointer;
    margin-left: 0.2em;
  }
`;

export const AddNewButton = styled(Secondary)`
  background: ${colors.grey1};
  border: none;
  border-radius: ${radius.medium};
  padding: 0.5em;
  color: ${colors.dark};
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

export const MainIconWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0em 0.25em;
`;

export const StatusChangeModal = styled.div`
  box-shadow: 1px 1px 10px rgba(21, 129, 186, 0.2);
  border-radius: ${radius.medium};
  position: absolute;
  background: ${colors.brightLight};
  right: ${spacing.space_m};
  padding: ${spacing.space_m};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1000;
`;

export const StatusChangeText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 0.3em;
`;

export const StatusChangeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    width: ${spacing.space_l};
    height: ${spacing.space_l};
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  cursor: ${props => (props.defaultStatus ? "initial" : "pointer")};
  :hover {
    ${MainIconWrapper} {
      visibility: visible;
    }
    > span {
      border: ${props =>
        props.defaultStatus
          ? `1px solid ${colors.brightLight}`
          : `1px solid ${colors.inkLightest}`};
    }
  }
  > span {
    line-height: 1rem;
    padding: ${spacing.space_s};
    margin: 0px;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    border-radius: ${radius.small};
    border: 1px solid ${colors.brightLight};
    ${props => props.active && `color: ${colors.dark}`};
    ${props => !props.active && `color: ${colors.darkGreen}`};
  }
`;

export const InputIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MainStatusWrapper = styled.div`
  max-height: 220px;
  overflow: ${props => (props.value <= 7 ? "none" : "auto")};
  overflow-x: hidden;
  position: relative;
`;
