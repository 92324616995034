// @flow

import * as atypes from "src/constants/actionTypes";

import type {
  Action,
  RoomId,
  FieldId,
  ColumnId,
  WorkflowSearchOptions,
  WorkflowId,
  HttpMethods,
  BulkUpdateMode,
  ProcessTemplateId,
  FieldMappings,
  CSVFileDetails,
  InstancesById
} from "src/types";

export const loadWorkflow = (id: number) => ({
  type: atypes.LOAD_WORKFLOW_REQUEST,
  payload: { id }
});

export const updateCurrentWorkflow = (data: Object) => ({
  type: atypes.UPDATE_CURRENT_WORKFLOW,
  payload: data
});

export const resetCurrentWorkflow = () => ({
  type: atypes.RESET_CURRENT_WORKFLOW,
  payload: {}
});

export const createWorkflow = (workflow: Object, modal: ?boolean) => ({
  type: atypes.CREATE_WORKFLOW_REQUEST,
  payload: {
    workflow,
    modal
  }
});

export const deleteWorkflow = (workflow: WorkflowId) => ({
  type: atypes.DELETE_WORKFLOW_REQUEST,
  payload: {
    workflow
  }
});

export const editWorkflow = (workflow: Object, redirect?: boolean) => ({
  type: atypes.EDIT_WORKFLOW,
  payload: {
    current: workflow,
    redirect
  }
});

export const showEditWorkflow = () => ({
  type: atypes.SHOW_EDIT_WORKFLOW,
  payload: {}
});

export const getNextSeqNo = (id: number) => ({
  type: atypes.GET_NEXT_SEQ_NO_REQUEST,
  payload: {
    id
  }
});

export const clearNextSeqNo = () => ({
  type: atypes.CLEAR_NEXT_SEQ_NO,
  payload: {}
});

export const setRecentWorkflows = (workflows: Array<number>) => ({
  type: atypes.SET_RECENT_WORKFLOW_REQUEST,
  payload: {
    workflows
  }
});

export const searchWorkflow = (
  searchString: string,
  settings?: WorkflowSearchOptions = {}
) => ({
  type: atypes.SEARCH_WORKFLOW_REQUEST,
  payload: {
    searchString,
    settings
  }
});

export const resetSearch = () => ({
  type: atypes.RESET_WORKFLOW_SEARCH_REQUEST,
  payload: {}
});

export const setWorkflow = (meta: { query?: string }): Action => ({
  type: atypes.SET_PROCESS_REQUEST,
  payload: {},
  meta
});

export const getUniqueValues = (payload: { worlflow: number }): Action => ({
  type: atypes.GET_PROCESS_UNIQUE_INSTANCE_VALUES_REQUEST,
  payload
});

export const downloadInstance = (
  workflow: number,
  format: "csv" | "xlsx" | "json" | "files" = "csv",
  headers: Array<Object>,
  rows: Array<Object>,
  includeFiles?: boolean
) => ({
  type: atypes.GET_PROCESS_INSTANCE_FILE_REQUEST,
  payload: {
    workflow,
    format,
    headers,
    rows,
    includeFiles
  }
});

export const setWorkflowBuiderAttribute = ({
  id,
  value
}: {
  id: string,
  value: any
}) => ({
  type: atypes.SET_WORKFLOW_BUILDER_ATTRIBUTE,
  payload: {
    id,
    value
  }
});

export const setWorkflowBuiderAttributes = (value: Object) => ({
  type: atypes.SET_WORKFLOW_BUILDER_ATTRIBUTES,
  payload: {
    value
  }
});

export const showWorkflowBuilder = () => ({
  type: atypes.SHOW_WORKFLOW_BUILDER,
  payload: {}
});

export const hideWorkflowBuilder = () => ({
  type: atypes.HIDE_WORKFLOW_BUILDER,
  payload: {}
});

// For segment
export const viewProcessDetails = (id: number, title: String): Action => ({
  type: atypes.VIEW_PROCESS_DETAILS,
  payload: { id, title }
});

export const reOrderProcessColumn = (
  id: string,
  columns: Array<{ key: string, active: boolean }>,
  reportId?: string,
  showToast?: boolean
) => ({
  type: atypes.REORDER_PROCESS_COLUMN,
  payload: {
    id,
    columns,
    reportId,
    showToast
  }
});

export const toggleProcessRowSelection = (row: number) => ({
  type: atypes.TOGGLE_PROCESS_ROW_SELECTION,
  payload: { row }
});

export const clearProcessRowSelection = () => ({
  type: atypes.CLEAR_PROCESS_ROW_SELECTION,
  payload: {}
});

export const toggleTemplateRowSelection = (row: number) => ({
  type: atypes.TOGGLE_TEMPLATE_ROW_SELECTION,
  payload: { row }
});

export const clearTemplateRowSelection = () => ({
  type: atypes.CLEAR_TEMPLATE_ROW_SELECTION,
  payload: {}
});

export type BulkUpdateProcessActionPayload =
  | {|
      attrs: { [fieldId: number]: Object }
    |}
  | {|
      attrs: { [fieldId: number]: Object },
      sourceChatroom: number,
      options: { updateMode: BulkUpdateMode }
    |};

export type BulkUpdateProcessAction = {
  type: typeof atypes.BULK_UPDATE_PROCESS_REQUEST,
  payload: BulkUpdateProcessActionPayload
};

export type BulkUpdateProcessActionCreator = (
  payload: BulkUpdateProcessActionPayload
) => BulkUpdateProcessAction;

export const bulkUpdateProcess: BulkUpdateProcessActionCreator = payload => ({
  type: atypes.BULK_UPDATE_PROCESS_REQUEST,
  payload
});

export const fetchStatusCount = (id: number) => ({
  type: atypes.FETCH_STATUS_COUNT_REQUEST,
  payload: {
    id
  }
});

export const showStatusRemoveWarning = (statusRemoveWarning: {
  count: number,
  id: number
}) => ({
  type: atypes.SHOW_STATUS_REMOVE_WARNING,
  payload: statusRemoveWarning
});

export const hideStatusRemoveWarning = () => ({
  type: atypes.HIDE_STATUS_REMOVE_WARNING,
  payload: {}
});

export const getPrincipalChecklist = (workflow: number) => ({
  type: atypes.GET_PRINCIPAL_CHECKLIST_REQUEST,
  payload: {
    workflow
  }
});

export const searchPrincipalChecklistField = (text: string) => ({
  type: atypes.SEARCH_PRINCIPAL_CHECKLIST_REQUEST,
  payload: {
    text
  }
});

/**
 * Updates the chatroom data from manage view
 * @param {RoomId} roomId - roomId of the room the field is associated to
 * @param {Object} value - the updated value
 * @param {number} index - index of the field in the array of instances
 * @param {ColumnId} columnId - columnId of the linked process its attached to
 */
export const updateChatroomFromManageView = (
  roomId: RoomId,
  value: Object,
  index: number,
  columnId?: ColumnId
) => ({
  type: atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_REQUEST,
  payload: {
    roomId,
    value,
    index,
    columnId
  }
});

export const updateSelectedChecklistRequest = ({
  roomId,
  index,
  id,
  value,
  httpMethod,
  extraBody,
  columnId,
  embeddedIndex,
  formId
}: {
  roomId: RoomId,
  index: number,
  id: string,
  value: Object,
  httpMethod: string,
  extraBody: ?Object,
  columnId: ColumnId,
  embeddedIndex: ?number,
  formId: ?number
}) => ({
  type: atypes.UPDATE_CURRENT_FIELD_REQUEST,
  payload: {
    roomId,
    index,
    id,
    value,
    httpMethod,
    extraBody,
    columnId,
    embeddedIndex,
    formId
  }
});

export const updateChecklistFromManageView = (
  roomId: RoomId,
  value: Object,
  index: number,
  id: FieldId,
  httpMethod?: HttpMethods,
  extraBody?: Object,
  columnId: ColumnId,
  embeddedIndex?: number
) => ({
  type: atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_REQUEST,
  payload: {
    roomId,
    value,
    index,
    id,
    httpMethod,
    extraBody,
    columnId,
    embeddedIndex
  }
});

export const updateNestedRow = (
  roomId: RoomId,
  value: Object,
  index: number,
  parentId: number
) => ({
  type: atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_REQUEST,
  payload: {
    roomId,
    value,
    index,
    parentId
  }
});

export const setManageViewFilter = (filter: Object) => ({
  type: atypes.SET_MANAGE_VIEW_FILTER,
  payload: filter
});

export const updateInstanceFilter = (filter: Object) => ({
  type: atypes.UPDATE_INSTANCE_FILTER,
  payload: filter
});

export const paginateManageView = (page: number) => ({
  type: atypes.PAGINATE_MANAGE_VIEW_FILTER,
  payload: {
    page
  }
});

export const setWorkflowBuilderReminder = (index: number, value: Object) => ({
  type: atypes.SET_WORKFLOW_BUILDER_REMINDER,
  payload: {
    index,
    value
  }
});

export const removeWorkflowReminder = (index: number) => ({
  type: atypes.REMOVE_WORKFLOW_BUILDER_REMINDER,
  payload: {
    index
  }
});

export const clearWorkflowInstances = () => ({
  type: atypes.CLEAR_WORKFLOW_INSTANCES,
  payload: {}
});

export const toggleProcessVersionsVisibility = (seqNo: number) => ({
  type: atypes.TOGGLE_PROCESS_VERSIONS_VISIBILITY,
  payload: {
    seqNo: `${seqNo}`
  }
});

export const updateWorkflowInstanceCurrentVersion = (payload: {
  chatroomId: number,
  seqNo: number,
  index: number
}) => ({
  type: atypes.UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_REQUEST,
  payload
});

export const setEditProcess = (id: number) => ({
  type: atypes.EDIT_PROCESS_REQUEST,
  payload: { id }
});

export const setCreateProcess = () => ({
  type: atypes.CREATE_PROCESS_REQUEST
});

export const setWorkflowChanges = () => ({
  type: atypes.SET_WORKFLOW_CHANGES
});

export const setWorkflowChangesAndRedirectToChatroom = (payload: {
  address: string,
  templateId: number
}) => ({
  type: atypes.SET_WORKFLOW_CHANGES_AND_REDIRECT_TO_CHATROOM,
  payload
});

export const cancelWorkflowChanges = () => ({
  type: atypes.CANCEL_WORKFLOW_CHANGES
});

export const setFieldSettingsChanges = () => ({
  type: atypes.SET_CHECKLIST_FIELD_SETTINGS_CHANGES
});

export const cancelFieldSettingsChanges = () => ({
  type: atypes.CANCEL_CHECKLIST_FIELD_SETTINGS_CHANGES
});

export const setBuilderComplete = (val: boolean) => ({
  type: atypes.SET_WORKFLOW_BUILDER_ATTRIBUTE,
  payload: {
    id: "builderComplete",
    value: val
  }
});

export const toggleFormFieldsVisibility = (
  formId: number,
  fieldId: string
) => ({
  type: atypes.TOGGLE_FORM_FIELDS_VISIBILITY,
  payload: { formId, fieldId }
});

export const setFormFieldsVisibility = (
  formId: number,
  fieldId: string,
  state: boolean
) => ({
  type: atypes.SET_FORM_FIELDS_VISIBILITY,
  payload: { formId, fieldId, state }
});

export const setExpandedField = (
  fieldId: string,
  autoNo: string,
  value: boolean
) => ({
  type: atypes.SET_EXPANDED_FIELD,
  payload: {
    fieldId,
    autoNo,
    value
  }
});

export const getFieldUniqueValues = (columnId: string, type: string) => ({
  type: atypes.GET_FIELD_UNIQUE_VALUES,
  payload: { columnId, type }
});

export const openCsvUploadModal = ({
  templateId
}: {
  templateId: ProcessTemplateId
}) => ({
  type: atypes.OPEN_CSV_UPLOAD_MODAL,
  payload: { templateId }
});

export const storeFieldMapping = ({
  templateId,
  fileName,
  localMappedField,
  file
}: {
  templateId: ProcessTemplateId,
  fileName: string,
  localMappedField: FieldMappings,
  file: CSVFileDetails
}) => ({
  type: atypes.STORE_PROCESS_FIELD_MAPPINGS_REQUEST,
  payload: { templateId, fileName, localMappedField, file }
});

export const filterEmbeddedFields = ({ instances }: InstancesById) => ({
  type: atypes.FILTER_EMBEDDED_FIELDS,
  payload: { instances }
});

export const setUpdatedInstancesById = (instances: InstancesById) => ({
  type: atypes.SET_UPDATED_INSTANCES_BY_ID,
  payload: instances
});

export const setAllRecordsFilter = ({
  columnId,
  value
}: {
  columnId: ColumnId,
  value: boolean
}) => ({
  type: atypes.SET_ALL_RECORDS_FILTER,
  payload: {
    columnId,
    value
  }
});

export const toggleAllRecordsFilter = (columnId: ColumnId) => ({
  type: atypes.TOGGLE_ALL_RECORDS_FILTER,
  payload: {
    columnId
  }
});

export const clearAllRecordsFilter = () => ({
  type: atypes.CLEAR_ALL_RECORDS_FILTER
});
