// @flow

import React from "react";
import Linkify from "react-linkify";
import { Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  CaretUp,
  Count,
  ValueWrapper,
  MultiContainer,
  MultiTable,
  StyledMultiField
} from "./styles";
import useRowExpansion from "src/hooks/useRowExpansion";
import { EditIconWrapper } from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import Icon from "src/icons";
import type { ColumnId } from "src/types";
import { getWorkflowInstancesById, getFieldWidth } from "src/reducers";
import { isLinkedFieldExists } from "src/utils/checklist";

type Props = {
  autoNo: string,
  columnId: ColumnId,
  selected: boolean,
  value: any,
  rowIndex: number,
  openChecklistEditModal: Function,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  type: string,
  embeddedIndex?: number
};

const MultipleField = ({
  autoNo,
  columnId,
  selected,
  value,
  openChecklistEditModal,
  fieldId,
  roomId,
  hideEdit,
  type,
  embeddedIndex
}: Props) => {
  const { toggleExpand, isRowExpanded } = useRowExpansion(
    columnId,
    autoNo,
    roomId
  );
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  const instance = useSelector(({ app }) => getWorkflowInstancesById(app))[
    autoNo
  ];

  const multiple = value.length > 1;

  return (
    <MultiContainer
      selected={selected}
      width={fieldWidth}
      borderRequired={columnId.includes("-")}
      style={{
        overflowX: "hidden"
      }}
    >
      <MultiTable>
        <tbody>
          {Array.from({
            length: isRowExpanded ? Math.max(value.length, 1) : 1
          }).map((_, fieldIndex) => {
            const fieldValue = value[fieldIndex] || " ";
            return (
              <tr key={fieldIndex}>
                <StyledMultiField maxWidth={fieldWidth}>
                  <ValueWrapper selected={selected}>
                    {type === "text" ? (
                      <Linkify>
                        <Text
                          m={0}
                          maxWidth="90%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {fieldValue}
                        </Text>
                      </Linkify>
                    ) : (
                      fieldValue
                    )}
                  </ValueWrapper>

                  {multiple && embeddedIndex == 0 && !isRowExpanded && (
                    <Count onClick={toggleExpand}>+{value.length - 1}</Count>
                  )}

                  {isRowExpanded && embeddedIndex === 0 && (
                    <CaretUp onClick={toggleExpand}>
                      <Icon type="caretUp" />
                    </CaretUp>
                  )}

                  {!hideEdit && isLinkedFieldExists({ instance, columnId }) && (
                    <EditIconWrapper
                      onClick={e => {
                        openChecklistEditModal(
                          e,
                          embeddedIndex,
                          fieldId,
                          roomId,
                          fieldValue,
                          columnId
                        );
                      }}
                    >
                      <Icon type="editPencil" />
                    </EditIconWrapper>
                  )}
                </StyledMultiField>
              </tr>
            );
          })}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

export default MultipleField;
