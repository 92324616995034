// @flow

import styled from "@emotion/styled";
import {
  SelectionName,
  RemoveButton
} from "src/components/Dock/Checklist/Conversation/styles";

import { AddButton } from "src/components/Dock/Checklist/styles";

const StyledAddButton = styled(AddButton)`
  margin-top: 0;
  margin-bottom: 0px;
`;

const StyledSelectionName = styled(SelectionName)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledRemoveButton = styled(RemoveButton)`
  position: inherit;
  width: 2.5em;
`;

const MultiPicklistWrapper = styled.div`
  display: grid;
  grid-gap: 0.67rem;
`;

const Text = styled.span`
  line-height: 1.5rem;
  font-size: 0.88rem;
`;

export {
  StyledAddButton,
  StyledSelectionName,
  RemoveButton,
  MultiPicklistWrapper,
  Text,
  StyledRemoveButton
};
