// @flow

import * as atypes from "src/constants/actionTypes";

export const nativigateToSettings = (section: string) => ({
  type: atypes.SET_SETTINGS_REQUEST,
  payload: {},
  meta: {
    query: {
      section
    }
  }
});

export const setNotificationSettings = (notification: number) => ({
  type: atypes.SET_USER_NOTIFICATION_SETTINGS_REQUEST,
  payload: {
    notification
  }
});
