// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { RoundButton, ClearSort } from "./styles";
import { updateFilter } from "src/actions/filter";
import type { AppState, CurrentFilter } from "src/types";
import { statusNames } from "src/constants/defaultFilter";
import {
  getStatusModal,
  getCurrentFilterActive,
  getCurrentFilter
} from "src/reducers";

import Icons from "src/icons";

type Props = {
  active: string,
  handleClick?: Function,
  _updateFilter: Function,
  currentFilter: CurrentFilter
};

const Status = ({
  active,
  handleClick = () => {},
  _updateFilter,
  currentFilter
}: Props) => {
  // Set the status to default
  const clearStatus = useCallback(
    e => {
      e.stopPropagation();
      _updateFilter({
        ...currentFilter,
        active: "all"
      });
    },
    [_updateFilter, currentFilter]
  );

  const isStatusActive = active !== "all";
  return (
    <RoundButton active={isStatusActive} onClick={handleClick}>
      <Icons type="status" />
      {isStatusActive ? statusNames[active] : "Status"}
      {isStatusActive ? (
        <ClearSort onClick={clearStatus}>
          <Icons type="blueCross" />
        </ClearSort>
      ) : (
        <Icons type="triangleDown" />
      )}
    </RoundButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  active: getCurrentFilterActive(app),
  statusModal: getStatusModal(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter
})(Status);
