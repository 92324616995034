// @flow
import { StackProps } from "@chakra-ui/react";

export const tooltip: StackProps = {
  width: "auto",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 0,
  cursor: "pointer",
  outline: "none",
  _focus: {
    boxShadow: null
  }
};
