// @flow

import React from "react";
import type { Node } from "react";

import * as styles from "./styles";

import type { UseBooleanReturn } from "src/types";
import { Portal } from "@chakra-ui/react";

type Props = {
  children: Node,
  customStyles?: { [string]: string | number },
  closeModal: $PropertyType<UseBooleanReturn, "setFalse">,
  isOpen: $PropertyType<UseBooleanReturn, "value">,
  portalRef?: React$ElementRef<Portal>
};

const ModalOverlay = ({
  customStyles,
  children,
  closeModal,
  isOpen,
  portalRef
}: Props) => {
  return (
    <Portal>
      <div
        style={
          isOpen
            ? { ...styles.ModalOverlay, ...customStyles }
            : { display: "none" }
        }
        onClick={() => closeModal()}
        ref={portalRef}
        data-cy="modalOverlay"
      >
        {children}
      </div>
    </Portal>
  );
};

export default ModalOverlay;
