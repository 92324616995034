// @flow

import React from "react";
import type { UID } from "src/types";
import { getDateTimeFromString } from "src/utils";

import { Box, HStack, Text, VStack, Image, Tooltip } from "@chakra-ui/react";

import UserPhoto from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";

import { recordTypeData } from "./constants";
import type { RecordType } from "./constants";

type Props = {
  id?: string,
  type: RecordType,
  author: UID,
  currentUserId: UID,
  signature?: string,
  comment: ?string,
  date: string
};

function SignatureCard({
  id,
  type,
  author,
  signature,
  date,
  comment,
  currentUserId
}: Props) {
  const isCurrentUser = currentUserId === author;
  const signedAtDateTime = getDateTimeFromString(date, false);

  if (type === "cancellation") {
    return (
      <Box backgroundColor={recordTypeData[type].bg} p={2} w="100%">
        <VStack alignItems="start" spacing={1} w="100%">
          <Text color="unifize_inkLightest" m={0}>
            {recordTypeData[type].label} by:
          </Text>

          <HStack alignItems="center" w="100%" justifyContent="space-between">
            <HStack>
              <UserPhoto uid={author} size="sm" shape="circle" />

              {isCurrentUser ? <span>You</span> : <NameOrEmail uid={author} />}
            </HStack>

            <Text mb={0} color="unifize_inkLighter" justifySelf="flex-end">
              {signedAtDateTime}
            </Text>
          </HStack>

          {comment && (
            <Text
              whiteSpace="pre-wrap"
              mb={1}
              color="unifize_red400"
              fontWeight={400}
            >
              {comment}
            </Text>
          )}
        </VStack>
      </Box>
    );
  }

  return (
    <Box backgroundColor={recordTypeData[type].bg} p={2} w="100%">
      <HStack alignItems="stretch" justifyContent="space-between">
        <VStack alignItems="start" spacing={1}>
          <Text color="unifize_inkLightest" m={0}>
            {recordTypeData[type].label} by:
          </Text>

          <HStack alignItems="center">
            <UserPhoto uid={author} size="sm" shape="circle" />

            <VStack
              alignItems="start"
              justifyContent="space-between"
              spacing={0}
            >
              {isCurrentUser ? <span>You</span> : <NameOrEmail uid={author} />}

              {id && (
                <Tooltip label={id} fontSize="xs">
                  <Text
                    isTruncated
                    maxW="80px"
                    textTransform="uppercase"
                    mb={0}
                    color="unifize_blue"
                  >
                    ID: {id}
                  </Text>
                </Tooltip>
              )}
            </VStack>
          </HStack>
        </VStack>

        <VStack alignItems="flex-end" justifyContent="space-between">
          {signature && (
            <Image src={signature} alt="" maxWidth="100%" maxHeight="29px" />
          )}
          <Text mb={0} color="unifize_inkLighter">
            {signedAtDateTime}
          </Text>
        </VStack>
      </HStack>

      {comment && (
        <Text
          whiteSpace="pre-wrap"
          mb={1}
          color="unifize_red400"
          fontWeight={400}
        >
          {comment}
        </Text>
      )}
    </Box>
  );
}

export default SignatureCard;
