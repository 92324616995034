// @flow

import React from "react";
import { Timestamp as StyledTimestamp } from "./styles";

type Props = {
  timestamp: string
};

const Timestamp = ({ timestamp }: Props) => (
  <StyledTimestamp>
    <span>{timestamp}</span>
  </StyledTimestamp>
);

export default Timestamp;
