// // @flow

import { connect } from "react-redux";
import React from "react";

import {
  OrgSettingsHeader,
  OrgSettingItem,
  List as StyledList,
  OrgItem as StyledItem,
  OrgSettingsList
} from "src/styles/splitPane";

import { setOrgSettings } from "src/actions/orgSettings";
import type { AppState } from "src/types";
import { getUserRole } from "src/reducers";

type Props = {
  current: ?string,
  _setOrgSettings: Function,
  isSSOViewAllowed: boolean,
  userRole: string
};

const listItems = [
  { id: "home-screen", title: "Home Screen", restricted: false },
  { id: "org-details", title: "Org Details", restricted: false },
  { id: "role-management", title: "Role Management", restricted: false },
  { id: "integrations", title: "Apps", restricted: true },
  { id: "sso", title: "SSO" }
];

const List = ({
  current,
  _setOrgSettings,
  userRole,
  isSSOViewAllowed = false
}: Props) => {
  return (
    <StyledList>
      <OrgSettingsHeader>Org Settings</OrgSettingsHeader>

      <OrgSettingsList>
        {listItems.map(item => {
          if (item.restricted && userRole !== "admin") return;
          if (item.id === "sso" && !isSSOViewAllowed) return;
          return (
            <StyledItem
              key={item.id}
              active={current === item.id}
              onClick={() => _setOrgSettings(item.id)}
            >
              <OrgSettingItem>{item.title}</OrgSettingItem>
            </StyledItem>
          );
        })}
      </OrgSettingsList>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: app.orgSettings.current,
  userRole: getUserRole(app)
});

export default connect(mapStateToProps, {
  _setOrgSettings: setOrgSettings
})(List);
