// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import FilterItem from "./FilterItem";
import Sort from "./Sort";
import { Filters as StyledFilter, SectionLabel, TernaryButton } from "./styles";
import { setWorkflow } from "src/actions/workflows";
import { setReports } from "src/actions/reports";

import type { WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  _setWorkflow: Function,
  _setReports: typeof setReports,
  clearSort: Function,
  removeSort: Function,
  removeFilter: Function,
  sortColumns: Array<string>,
  filterParams: Object
};

const Filters = ({
  sortColumns,
  clearSort,
  removeSort,
  filterParams,
  removeFilter
}: Props) => {
  return (
    <StyledFilter>
      {!R.isEmpty(filterParams) && (
        <SectionLabel>Filters applied:</SectionLabel>
      )}

      {/* Applied filter criteria */}
      {R.map(
        (column: string) =>
          R.map((value: string) => {
            return (
              <FilterItem
                key={value}
                column={R.last(column.split("-")) || column}
                fullColumnId={column}
                value={value}
                removeFilter={removeFilter}
              />
            );
          }, filterParams[column] || []),
        R.keys(R.omit(["reportId", "chartId"], filterParams || {}))
      )}

      {!R.isEmpty(sortColumns) && <SectionLabel>Sorted by:</SectionLabel>}

      {/* Applied sorting criteria */}
      <Sort sortParams={sortColumns} removeSort={removeSort} />

      <TernaryButton onClick={clearSort}>Clear All</TernaryButton>
    </StyledFilter>
  );
};

export default connect(null, {
  _setWorkflow: setWorkflow,
  _setReports: setReports
})(Filters);
