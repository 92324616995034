// @flow

import React from "react";

import { NameOrEmail as StyledDepartment } from "./styles";
import DepartmentName from "src/containers/department/Name";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const Department = ({ user }: Props) => (
  <StyledDepartment title={user.department || user.department}>
    <DepartmentName id={user.department} />
  </StyledDepartment>
);

export default Department;
