// @flow

import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";

import { Favourite as StyledFavourite } from "./styles";
import Tooltip from "src/components/Tooltip";
import Icon from "src/icons";
import { updateFavourite } from "src/actions/chatroom";
import type { AppState } from "src/types";

import * as colors from "src/styles/constants/colors";
type Props = {
  uid: string,
  roomId: number,
  favourites: any,
  handleFavourite: Function
};

const Favourite = ({ uid, roomId, favourites, handleFavourite }: Props) => {
  const checked = favourites.includes(roomId);
  const [isFavorite, setFavorite] = useState(checked);
  const [stroke, setStroke] = useState(colors.primary);

  useEffect(() => {
    if (isFavorite !== checked) {
      setFavorite(checked);
    }
  }, [favourites, roomId, checked]);

  const toggleFavourite = useCallback(
    () => handleFavourite(roomId, uid, !checked),
    [checked, roomId, uid, handleFavourite]
  );
  return (
    <Tooltip title="Mark as favourite" align="right">
      <StyledFavourite
        onMouseEnter={() => setStroke(colors.amber)}
        onMouseLeave={() => setStroke(colors.primary)}
        onFocus={() => setStroke(colors.amber)}
        onBlur={() => setStroke(colors.primary)}
        tabIndex="0"
        onKeyPress={toggleFavourite}
      >
        {isFavorite ? (
          <Icon
            handleClick={toggleFavourite}
            type="favourite"
            fill
            stroke={stroke}
          />
        ) : (
          <Icon
            handleClick={toggleFavourite}
            type="favourite"
            stroke={stroke}
          />
        )}
      </StyledFavourite>
    </Tooltip>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  uid: app.currentUser.uid,
  favourites: app.chatRooms.favourites
});

export default connect(mapStateToProps, {
  handleFavourite: updateFavourite
})(Favourite);
