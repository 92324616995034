// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import {
  Groups as StyledGroups,
  GroupHeader,
  Name as StyledName
} from "./styles";
import Name from "src/components/Group/ImageLabel";
import Members from "src/containers/Group/Members";
import { getAddedOn } from "src/reducers";

import type { AppState, AddedOn } from "src/types";

type Props = {
  groups: Array<number>,
  addedOn: AddedOn
};

const Groups = ({ groups, addedOn }: Props) => {
  return (
    <StyledGroups>
      <GroupHeader>
        <tr>
          <th>Group Name</th>
          <th>Participants</th>
          <th>Added on</th>
        </tr>
      </GroupHeader>
      <tbody>
        {(groups || []).map(g => (
          <tr key={g}>
            <td>
              <StyledName>
                <Name id={g} />
              </StyledName>
            </td>
            <td>
              <Members id={g} />
            </td>
            <td>
              {addedOn && addedOn[`${g}`]
                ? moment(addedOn).format("MMM DD, YYYY")
                : null}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledGroups>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  addedOn: getAddedOn(app, props.uid)
});

export default connect(mapStateToProps)(Groups);
