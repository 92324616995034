// @flow

import React, { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";
import { Tr, Td, IconButton, Input, Tag, TagLabel } from "@chakra-ui/react";

import {
  fieldTagStyles,
  inputFieldStyles,
  plusIconStyles
} from "src/components/Manage/Builder/Checklist/styles";
import OldIcon from "src/components/InlineSVG";
import DeleteFieldModal from "src/components/Manage/Builder/Checklist/DeleteFieldModal";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import {
  checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import Tooltip from "src/components/Tooltip";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";
import useBoolean from "src/hooks/useBoolean";
import type { FormBuilderField } from "src/types";

import SettingsModal from "./SettingsModal";

const DragHandle = SortableHandle(() => (
  <IconButton
    display="flex"
    variant="unstyled"
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));

type Props = {
  position: number,
  value: FormBuilderField,
  addFieldBelow: Function,
  removeField: Function,
  updateField: Function
};

const Field = ({
  position,
  value,
  addFieldBelow,
  removeField,
  updateField
}: Props) => {
  // Only if checklist download is allowed, show fieldIds
  // on builder for user
  const checklistDownloadAllowed = useComponentPermission(
    componentPermissions.downloadChecklist
  );

  const [hover, setHover] = useState(false);

  const {
    value: isDeleteModalOpen,
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal
  } = useBoolean(false);

  const {
    value: isSettingsModalOpen,
    setTrue: openSettingsModal,
    setFalse: closeSettingsModal
  } = useBoolean(false);

  const mouseEnterHandler = () => {
    setHover(true);
  };

  const mouseLeaveHandler = () => {
    setHover(false);
  };

  const removeFieldHandler = () => {
    removeField(position);
    closeDeleteModal();
  };

  const updateSettings = newSettings => {
    updateField({
      index: position,
      data: newSettings,
      property: "settings"
    });
  };

  const { fieldName: label, fieldId: id, fieldType: type, settings } = value;

  return (
    <Tr onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
      <Td sx={plusIconStyles}>
        <IconButton
          display="flex"
          opacity={hover ? 1 : 0}
          variant="unstyled"
          aria-label="Add new field"
          onClick={() => addFieldBelow(position)}
          icon={<OldIcon icon="hollowPlusCircle" color={colors.blue500} />}
        />
      </Td>

      <Td p={0}>
        <DragHandle />
      </Td>

      <Td>{position + 1}</Td>

      <Td>
        <Tag
          size="md"
          sx={fieldTagStyles}
          variant="solid"
          bgColor={checklistFieldIcons[type].hexCode}
        >
          <Icon
            type={checklistFieldIcons[type].icon}
            width="16px"
            height="16px"
            fill="#fff"
          />
          <TagLabel ml="4px">{checklistFieldNames[type]}</TagLabel>
        </Tag>
      </Td>

      <Td>
        <Input
          type="text"
          value={label}
          style={inputFieldStyles}
          placeholder="Field Name"
          onChange={e =>
            updateField({
              data: e.target.value,
              index: position,
              property: "fieldName"
            })
          }
          required
        />
      </Td>

      <Td>
        <Tooltip title="Settings" placement="right">
          <IconButton
            display="flex"
            variant="unstyled"
            aria-label="Field Settings"
            onClick={openSettingsModal}
            icon={<Icon type="gear" />}
          />
        </Tooltip>

        <SettingsModal
          isOpen={isSettingsModalOpen}
          onClose={closeSettingsModal}
          fieldType={type}
          fieldName={label}
          position={position}
          currentSettings={settings}
          updateSettings={updateSettings}
        />
      </Td>

      <Td>
        <Tooltip title="Delete field" placement="right">
          <IconButton
            display="flex"
            opacity={hover ? 1 : 0}
            variant="unstyled"
            aria-label="Delete Field"
            onClick={openDeleteModal}
            icon={<Icon type="deleteTrash" fill={colors.redDark} />}
          />
        </Tooltip>

        <DeleteFieldModal
          label={label}
          isOpen={isDeleteModalOpen}
          onDelete={removeFieldHandler}
          toggleDeleteFieldModal={closeDeleteModal}
        />
      </Td>

      <Td>{id && checklistDownloadAllowed ? id : null}</Td>
    </Tr>
  );
};

export default Field;
