// @flow

import React from "react";
import { useSelector } from "react-redux";

import { Cell } from "../styles";
import { ParticipantCount } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import User from "src/containers/user/picture/Small";
import Group from "src/components/user/Group";
import Tooltip from "src/components/Tooltip";
import { size } from "src/constants/processInstanceColumns";
import { getUsersById, getFieldWidth } from "src/reducers";

import type { UID, Group as ParticipantGroup } from "src/types";

type Props = {
  selected: boolean,
  count: number,
  value: Array<UID>,
  groups: Array<ParticipantGroup>
};

const Members = ({ count, selected, value, groups }: Props) => {
  const usersById = useSelector(({ app }) => getUsersById(app));
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, "members"));

  const participants = [
    ...(Array.isArray(value) ? value : []),
    ...(groups && Array.isArray(groups) ? groups.map(group => group.id) : [])
  ];

  return (
    <Cell selected={selected} width={fieldWidth} minWidth={size.members}>
      <ValueWrapper isUserWrapper={true}>
        {(participants || []).map(uid => {
          return typeof uid === "number" ? (
            <Group groupId={parseInt(uid, 10)} />
          ) : (
            <Tooltip
              key={uid}
              title={usersById?.[uid]?.displayName || ""}
              description={usersById?.[uid]?.email || ""}
              placement="top"
            >
              <User uid={uid} key={uid} />
            </Tooltip>
          );
        })}
        <ParticipantCount>{count ? `+${count}` : ""}</ParticipantCount>
      </ValueWrapper>
    </Cell>
  );
};
export default Members;
