// @flow

import * as colors from "src/styles/constants/colors";
import * as status from "src/constants/status";

const statusBorderColor = {
  [status.NEW]: colors.warning,
  [status.OVERDUE]: colors.danger,
  [status.COMPLETED]: colors.success,
  [status.PENDING]: colors.secondary,
  [status.ACTIVE]: colors.secondary,
  [status.ACCEPTED]: colors.success,
  [status.REJECTED]: colors.success,
  [status.CANCELED]: colors.secondary
};

export { statusBorderColor };
