// @flow

import React from "react";
import {
  Container,
  Selection,
  Label,
  Placeholder,
  ToggleButton,
  Menu,
  MenuItem,
  ItemTitle,
  ArrowIcon
} from "./styles";
import Icons from "src/icons";
import { useSelect } from "downshift";

type Props = {
  label?: string,
  placeholder?: string,
  data: Array<Object>,
  selected: string,
  onChange: Function,
  keys: Array<string>
};

const SingleSelect = ({
  label = "",
  placeholder = "",
  data,
  selected,
  onChange,
  keys
}: Props) => {
  const identifier: string = keys[0];
  const display: string = keys[1];

  const handleSelect = selected => {
    if (selected) {
      onChange(selected.selectedItem[identifier]);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: data,
    selectedItem: selected,
    onSelectedItemChange: handleSelect,
    itemToString: item => (item ? item.title : "")
  });

  return (
    <Container>
      <Selection>
        <Label {...getLabelProps()}>{label}</Label>
        <ToggleButton {...getToggleButtonProps()}>
          <Placeholder isSelected={selected}>
            {selected
              ? data.find(item => item[identifier] === selected)?.[display] ||
                ""
              : placeholder}
          </Placeholder>
          <ArrowIcon>
            {isOpen ? <Icons type="caretUp" /> : <Icons type="caretDown" />}
          </ArrowIcon>
        </ToggleButton>
      </Selection>
      <Menu {...getMenuProps()}>
        {isOpen &&
          data.map((item, index) => (
            <MenuItem
              className={
                (highlightedIndex === index ? "highlighted " : "") +
                (selected === item[identifier] ? "selected" : "")
              }
              key={item.id}
              {...getItemProps({ item, index })}
            >
              <ItemTitle>{item[display]}</ItemTitle>
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
};

export default SingleSelect;
