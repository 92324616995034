// @flow

import * as R from "ramda";
import { Crop } from "react-image-crop";

import type { UnifizeFile } from "src/types";

const image = ["jpg", "jpeg", "png", "gif", "heic", "svg", "tiff"];
const word = ["doc", "docx", "odt", "fodt"];
const excel = ["xls", "xlsx", "ods", "fods"];
const powerPoint = ["ppt", "pptx", "odp", "fodp"];
const video = ["mp4", "webm", "ogg", "avi", "amv", "mov"];
const profileImage = ["jpg", "jpeg", "png"];

export const knownFileFormats = { image, word, excel, powerPoint, video };

export const getFileExtension = (name: string) =>
  (name || "").split(".").pop().toLowerCase();

export const getFileType = (name: string) => {
  const extension = getFileExtension(name);

  if (extension === "pdf") {
    return "pdf";
  }
  if (R.includes(extension, image)) {
    return "image";
  }
  if (R.includes(extension, word)) {
    return "word";
  }
  if (R.includes(extension, excel)) {
    return "excel";
  }
  if (R.includes(extension, powerPoint)) {
    return "powerpoint";
  }
  if (R.includes(extension, video)) {
    return "video";
  }
  // Return default as file
  return "file";
};

/**
 * A function to know whether a file supports a preview
 * @param  {string} name - The name of the file with the extension
 * @param  {string} [mimeType] - The mime type of the file
 * @returns boolean - Denotes whether preview is supported for the file
 */
export const getFilePreviewSupport = (file: UnifizeFile): boolean => {
  const { name, source, mimeType } = file;

  if (source === "microsoft365") return true;

  const extension = getFileExtension(name);

  if (extension && (image.includes(extension) || extension === "pdf"))
    return true;

  if (!mimeType) return false;

  if (mimeType === "application/pdf" || mimeType.startsWith("image"))
    return true;

  return false;
};

export const saveFile = async (name: string, blob: any) => {
  const a = document.createElement("a");
  const fileURL = window.URL.createObjectURL(blob);
  a.href = fileURL;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(fileURL);
};

/**
 * Get human readable size of gived bytes
 */
export const getFileSize = (bytes: number) => {
  const sufixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (!bytes && "0 Bytes") || `${(bytes / 1024 ** i).toFixed(2)} ${sufixes[i]}`
  );
};

export const verifyImage = (fileExtension: string) => {
  return R.includes(fileExtension, profileImage);
};

/**
 * Get cropped Blob object of image with react-image-crop lib
 * @param {HTMLImageElement} image - Image from ref or file upload
 * @param {Crop} crop - Crop object from react-image-crop
 * @param {string} fileName
 * @param {string} fileExtension
 * @return {Promise} Promise with the canvas blob
 */
export const getCroppedImgBlob = (
  image: ?HTMLImageElement,
  crop: Crop,
  fileName: string,
  fileExtension: string = "jpeg"
): Promise<any> => {
  if (!image) {
    return new Promise((_, reject) => reject(new Error("No image")));
  }

  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        `image/${fileExtension}`,
        1
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const getFilesWithSrc = (
  files: Array<File & { path: string }>
): Array<Object> => {
  return files.map(file => ({
    name: file.name,
    path: file.path,
    size: file.size,
    src: URL.createObjectURL(file)
  }));
};

/**
 * Returns the source name from which the file was uploaded
 *
 * @param {string | undefined} source - original source of the file
 * @return {string | null} source display name
 *
 */
export const getFileSourceDisplayName = (source: ?string) => {
  if (!source) return "Native";

  if (source === "microsoft365") return "Sharepoint";

  return null;
};

/**
 * Creates an invisible anchor and instantly downloads the document
 * at the link
 *
 * @param {string} url - The URL of the file to be downloaded
 * @param {string} filename - The name of the downloaded file
 */
export const downloadDocument = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  // $FlowFixMe Optional chaining
  document?.body?.appendChild(link);
  link.click();
  // $FlowFixMe Optional chaining
  document?.body?.removeChild(link);
};
