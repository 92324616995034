// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";

const Tooltip = styled.div`
  position: relative;
  display: inline;

  > span {
    white-space: nowrap;
    position: absolute;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: ${typography.medium};
    color: ${colors.brightLight};
    background: ${colors.activeFilter};
    text-align: center;
    visibility: hidden;
    border-radius: 2px;
    padding: 8px;

    :after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -${spacing.space_m};
      width: 0;
      height: 0;
      border-right: ${spacing.space_m} solid ${colors.activeFilter};
      border-top: ${spacing.space_m} solid transparent;
      border-bottom: ${spacing.space_m} solid transparent;
    }
  }

  :hover {
    > span {
      visibility: ${props => (props.selected ? "hidden" : "visible")};
      opacity: 1;
      left: 90%;
      top: 50%;
      margin-top: -1.33em;
      margin-left: 1.25em;
      z-index: 999;
    }
  }
`;

export default Tooltip;
