// @flow

import { all } from "redux-saga/effects";

import fileSagas from "./file";
import uploadSagas from "./upload";
import userSaga from "./user";
import messageSagas from "./messages";
import authSaga from "./auth";
import filterSaga from "./filters";
import chartSaga from "./charts";
import conactsSaga from "./contacts";
import groupsSaga from "./groups";
import signup from "./signup";
import workflowSaga from "./workflow";
import userAnalytics from "./userAnalytics";
import errorSagas from "./error";
import checklistSagas from "./checklist";
import chatroomSaga from "./chatroom";
import promptsSaga from "./prompts";
import orgSagas from "./organization";
import updates from "./updates";
import roomMembers from "./roomMembers";
import roomGroups from "./roomGroups";
import chatroomSync from "./chatroomSync";
import chatroomNavigation from "./chatroomNavigation";
import messageSync from "./messageSync";
import srwSaga from "./srw";
import segment from "./segment";
import statuses from "./statuses";
import membershipSaga from "./membership";
import reports from "./reports";
import settings from "./settings";
import department from "./department";
import workflowBuilder from "./workflowBuilder";
import dashboardSaga from "./dashboard";
import emailSagas from "./email";
import orgSettings from "./orgSettings";
import homescreen from "./homescreen";
import forms from "./forms";
import roleManagement from "./roleManagement";
import conditions from "./conditions";
import oAuth from "./oAuth";
import orgClone from "./orgClone";
import fieldSizeMap from "./fieldSizeMap";
import integrations from "./integrations";

function* rootSaga(): any {
  yield all([
    ...homescreen,
    ...dashboardSaga,
    ...segment,
    ...updates,
    ...authSaga,
    ...signup,
    ...userSaga,
    ...workflowSaga,
    ...chatroomSaga,
    ...promptsSaga,
    ...orgSagas,
    ...fileSagas,
    ...errorSagas,
    ...checklistSagas,
    ...uploadSagas,
    ...emailSagas,
    ...messageSagas,
    ...filterSaga,
    ...conactsSaga,
    ...roomMembers,
    ...roomGroups,
    ...chatroomSync,
    ...chatroomNavigation,
    ...groupsSaga,
    ...messageSync,
    ...userAnalytics,
    ...statuses,
    ...membershipSaga,
    ...srwSaga,
    ...reports,
    ...settings,
    ...department,
    ...workflowBuilder,
    ...chartSaga,
    ...orgSettings,
    ...forms,
    ...roleManagement,
    ...conditions,
    ...oAuth,
    ...orgClone,
    ...fieldSizeMap,
    ...integrations
  ]);
}

export default rootSaga;
