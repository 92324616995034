// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { Flex, IconButton } from "@chakra-ui/react";
import usePortal from "react-useportal";

import {
  ChartList as StyledChartList,
  FooterButton,
  Header,
  ChartCell,
  ChartBody,
  Description as StyledDescription,
  List,
  CloseButton,
  Report as StyledReport,
  Title as StyledTitle
} from "../styles";
import * as styles from "../styles";
import Image from "./Image";
import Title from "./Title";
import Description from "./Description";
import Report from "./Report";
import Icon from "src/icons";
import { Overlay } from "src/styles/modal";
import {
  setNewChartDialogAttributes,
  deleteChart,
  showEditChartModal,
  closeNewChartDialog,
  fetchChart
} from "src/actions/chart";
import {
  getAllCharts,
  getInstanceFilterId,
  getInstanceReportId,
  getChartsByReport,
  getAllChartIds
} from "src/reducers";
import { Input } from "src/styles/input";
import { updateInstanceFilter } from "src/actions/workflows";
import { editChart } from "src/actions/chart";
import { comparisonCharts } from "src/constants/charts.js";
import DeleteModal from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/Series/DeleteModal";

type Props = {
  header?: string,
  newChart?: boolean,
  handleClick: Function,
  _setNewChartDialogAttributes: Function,
  _showEditChartModal: Function,
  handleClose: Function,
  _updateInstanceFilter: Function,
  _closeNewChartDialog: Function,
  _searchChart: Function,
  showAll?: boolean
};

const ChartList = ({
  handleClick,
  _setNewChartDialogAttributes,
  _showEditChartModal,
  _updateInstanceFilter,
  _closeNewChartDialog,
  handleClose,
  header = "Charts for this report",
  newChart = true,
  showAll = false
}: Props) => {
  const dispatch = useDispatch();
  const parentRef = useRef(null);
  const searchRef = useRef(null);
  const { Portal } = usePortal();
  const comparisonChartList = R.values(comparisonCharts);

  const allCharts = useSelector(({ app }) => getAllCharts(app));
  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));
  const reportId = useSelector(({ app }) => getInstanceReportId(app));

  const charts = useSelector(({ app }) => {
    if (showAll) {
      return getAllChartIds(app);
    } else {
      return getChartsByReport(app, Number(reportId));
    }
  });
  const [filteredCharts, setFilteredCharts] = useState([]);

  useEffect(() => {
    setFilteredCharts(charts);
  }, [charts]);

  const handleSearch = useCallback(
    (event: any) => {
      const query = event.target.value;
      const filtered =
        charts.filter(chart =>
          chart.title.toLowerCase().includes(query.toLowerCase())
        ) || [];
      setFilteredCharts(filtered);
    },
    [filteredCharts]
  );

  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    }, 0);
  }, []);

  const handleNewChart = useCallback(() => {
    if (newChart) {
      _updateInstanceFilter({
        chartId: null
      });
    }

    _closeNewChartDialog();

    _setNewChartDialogAttributes({
      show: true
    });

    handleClose();
  }, [
    newChart,
    handleClose,
    _setNewChartDialogAttributes,
    _updateInstanceFilter,
    _closeNewChartDialog
  ]);

  const handleEdit = useCallback(
    (event: SyntheticMouseEvent<HTMLDivElement>, chartId: number) => {
      event.preventDefault();
      event.stopPropagation();
      const chartDetails = allCharts[chartId];
      const { type, reportId } = chartDetails;
      const isComparisonChart = comparisonChartList.includes(type);
      if (isComparisonChart) {
        dispatch(
          editChart({
            id: chartId,
            type,
            reportId,
            workflowId
          })
        );
        dispatch(fetchChart(chartId));
      } else {
        _showEditChartModal(chartId);
      }

      handleClose();
    },
    [_showEditChartModal]
  );

  const handleChartJson = useCallback(
    (id: number) => {
      if (newChart) {
        _updateInstanceFilter({
          chartId: null
        });
      }
      _closeNewChartDialog();

      handleClick(id);
      handleClose();
    },
    [newChart, handleClose, handleClick]
  );

  return (
    <Portal>
      <Overlay />
      <StyledChartList ref={parentRef}>
        <Header>
          {header}
          <CloseButton type="button" onClick={handleClose}>
            <Icon type="close" />
          </CloseButton>
        </Header>
        <Input
          type="text"
          placeholder="Search charts"
          ref={searchRef}
          onChange={handleSearch}
          autoFocus
        />
        <List>
          {filteredCharts.map(chart => (
            <ChartCell key={chart.id} onClick={() => handleChartJson(chart.id)}>
              <Image id={chart.id} />
              <ChartBody>
                <Flex sx={styles.IconWrapper}>
                  <IconButton
                    onClick={(event: any) => handleEdit(event, chart.id)}
                    sx={styles.Icon}
                    icon={<Icon type="edit" />}
                  />

                  <DeleteModal
                    title={chart.title}
                    handleDelete={() => dispatch(deleteChart(chart.id))}
                  />
                </Flex>

                <StyledTitle>
                  <Title id={chart.id} />
                </StyledTitle>

                <StyledDescription>
                  <span>Description: </span>
                  <Description id={chart.id} />
                </StyledDescription>

                <StyledReport>
                  <span>Report:</span>
                  <div>
                    <Report id={chart.id} />
                  </div>
                </StyledReport>
              </ChartBody>
            </ChartCell>
          ))}
        </List>
        {newChart && (
          <FooterButton type="button" onClick={handleNewChart}>
            Create new chart
          </FooterButton>
        )}
      </StyledChartList>
    </Portal>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _showEditChartModal: showEditChartModal,
  _updateInstanceFilter: updateInstanceFilter,
  _closeNewChartDialog: closeNewChartDialog
})(ChartList);
