// @flow

import React from "react";
import { useSelector } from "react-redux";

import { FileWrapper, FilePreview, Name, FileInfo, FileData } from "../styles";
import FileThumbnail from "./FileThumbnail";
import UploadedTime from "./UploadedTime";

import { getFileSize } from "src/utils/file";
import { getFile } from "src/reducers";

import type { RoomId, UnifizeFile } from "src/types";

type Props = {
  roomId: RoomId,
  originalName: string,
  size: number,
  handleClick: Function,
  name: string
};

const FileItem = ({ originalName, roomId, size, handleClick, name }: Props) => {
  const file: ?UnifizeFile = useSelector(({ app }) => getFile(app, name));

  return (
    <FileWrapper onClick={handleClick}>
      <FilePreview>
        <FileThumbnail
          roomId={roomId}
          file={file}
          originalName={originalName}
        />
      </FilePreview>
      <FileData>
        <Name>{originalName}</Name>
        <FileInfo>
          {getFileSize(size)} <UploadedTime name={name} />
        </FileInfo>
      </FileData>
    </FileWrapper>
  );
};

export default FileItem;
