// @flow

import { connect } from "react-redux";

import Workflow from "src/components/chatroom/NewRoom/Workflow";
import * as actions from "src/actions/workflows";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  mostUsedWorkflows: app.workflow.mostUsed
});

export default connect(mapStateToProps, actions)(Workflow);
