// @flow

import { connect } from "react-redux";

import Value from "src/components/Manage/Builder/Checklist/Value";

import { toggleRecentChats } from "src/actions";

import { getChecklistFieldDetails } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  field: getChecklistFieldDetails(app, `${id}`)
});

export default connect(mapStateToProps, {
  toggleRecentChats
})(Value);
