// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import Title from "./Title";
import Criteria from "./Criteria";
import {
  setSegmentUserModalAttributes,
  createUserSegment,
  editUserSegment
} from "src/actions/orgSettings";

import type { AppState, SegmentType } from "src/types";

type Props = {
  isOpen: boolean,
  segmentType: SegmentType,
  criteria: Array<{
    id: number,
    value: Array<number>
  }>,
  tileId: number,
  segmentId: ?number,
  handleClose: Function,
  _setSegmentUserModalAttributes: Function,
  _createUserSegment: Function,
  _editUserSegment: Function
};

const NewUserSegment = ({
  isOpen,
  tileId,
  segmentType,
  criteria,
  segmentId,
  _setSegmentUserModalAttributes,
  _createUserSegment,
  _editUserSegment,
  handleClose
}: Props) => {
  const handleAddNewUser = useCallback(() => {
    const firstType = R.head(R.values(segmentType) || []);

    _setSegmentUserModalAttributes({
      criteria: [
        ...criteria,
        {
          id: firstType.id,
          value: []
        }
      ]
    });
  }, [criteria, _setSegmentUserModalAttributes, segmentType]);

  const handleCriteria = ({ id, index }: { id: number, index: number }) => {
    _setSegmentUserModalAttributes({
      criteria: criteria.map((row, rowIndex) => {
        if (rowIndex === index) {
          return {
            id,
            value: []
          };
        }
        return row;
      })
    });
  };

  const handleDelete = useCallback(
    (rowIndex: number) => {
      _setSegmentUserModalAttributes({
        criteria: criteria.filter((row, index) => index !== rowIndex)
      });
    },
    [criteria, _setSegmentUserModalAttributes]
  );

  const handleCriteriaValue = ({
    value,
    index
  }: {
    value: Array<number>,
    index: number
  }) => {
    _setSegmentUserModalAttributes({
      criteria: criteria.map((row, rowIndex) => {
        if (rowIndex === index) {
          return {
            ...row,
            value: value
          };
        }
        return row;
      })
    });
  };

  const handleSubmit = useCallback(() => {
    if (segmentId) {
      _editUserSegment();
    } else {
      _createUserSegment(tileId);
    }
  }, [segmentId, tileId, _createUserSegment, _editUserSegment]);

  return (
    <Modal size="md" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Show to</ModalHeader>
        <ModalCloseButton bg="white" />
        <ModalBody>
          <Title />
          {criteria.map((item, index) => (
            <Criteria
              id={item.id}
              value={item.value}
              index={index}
              key={index}
              handleChange={handleCriteria}
              handleDelete={handleDelete}
              handleCriteriaValue={handleCriteriaValue}
            />
          ))}
          <Button
            mt="2"
            onClick={handleAddNewUser}
            size="sm"
            variant="link"
            colorScheme="blue"
            bg="white"
          >
            &#43; And user
          </Button>
        </ModalBody>
        <ModalFooter pt="0">
          <Button size="sm" colorScheme="blue" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  segmentId: app.orgSettings.segmentUserModal.id,
  criteria: app.orgSettings.segmentUserModal.criteria,
  segmentType: app.orgSettings.segmentType
});

export default connect(mapStateToProps, {
  _setSegmentUserModalAttributes: setSegmentUserModalAttributes,
  _createUserSegment: createUserSegment,
  _editUserSegment: editUserSegment
})(NewUserSegment);
