// @flow

import React from "react";

import PulseLoader from "src/components/PulseLoader";

const Info = () => (
  <div>
    <PulseLoader w="100%" h="16px" />
    <PulseLoader w="100%" h="32px" />
  </div>
);

export default Info;
