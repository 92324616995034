// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { FormGroup, FilterGroup, TileColor, Content } from "./styles";
import tileColors from "src/styles/constants/tileColors";
import { Input } from "src/styles/input";
import ChartSelect from "src/components/chart/Select";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";

import type { AppState } from "src/types";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const DashboardChart = ({ modal, _setHomeScreenBuilderAttributes }: Props) => {
  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleChart = useCallback(
    (chart: ?number) => {
      _setHomeScreenBuilderAttributes({ chart });
    },
    [_setHomeScreenBuilderAttributes]
  );

  return (
    <Content>
      <FormGroup>
        <label>Title</label>
        <Input
          type="text"
          placeholder="e.g. Pending Conversations"
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="subtitle">
          Sub-title<span>(Optional)</span>
        </label>
        <Input
          id="subtitle"
          type="text"
          placeholder="e.g. You have pending tasks"
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="subtitle">Select a chart</label>
        <ChartSelect value={modal.chart} handleSelect={handleChart} />
      </FormGroup>

      <FormGroup>
        <label>Tile color</label>
        <FilterGroup>
          {tileColors.map(color => (
            <TileColor
              onClick={() =>
                _setHomeScreenBuilderAttributes({ tileColor: color })
              }
              key={color}
              color={color}
              active={modal.tileColor === color}
            />
          ))}
        </FilterGroup>
      </FormGroup>
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes
})(DashboardChart);
