// @flow

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import {
  Prompt as StyledPrompt,
  AssistantIcon,
  AssistantTitle,
  AssistantInfo,
  Dismiss
} from "./styles";
import MessageCard from "src/components/messages/Chat/MessageNotch";
import Checklist from "src/components/messages/Prompt/Checklist";
import Icon from "src/icons";
import {
  getCurrentPromptFields,
  getChatroomChecklists,
  getChecklistFieldDetails,
  getDismissed,
  getChecklistValue
} from "src/reducers";
import { getChecklistFields } from "src/actions/checklist";
import { refetchPromptFields, dismissPrompt } from "src/actions/prompts";
import type {
  AppState,
  RoomId,
  ChecklistValue,
  FieldId,
  ChecklistId
} from "src/types";

type Props = {
  id: FieldId,
  details: ChecklistValue,
  checklistId: ChecklistId,
  currentUser: string,
  currentRoom: RoomId,
  _getChecklistFields: Function,
  _refetchPromptFields: Function,
  done: ?number,
  _dismissPrompt: Function,
  dismissed: ?FieldId,
  checklistValue: ChecklistValue
};

const Prompt = ({
  id,
  details,
  checklistId,
  currentUser,
  currentRoom,
  _getChecklistFields,
  _refetchPromptFields,
  done,
  _dismissPrompt,
  dismissed,
  checklistValue
}: Props) => {
  const [showPrompt, setShowPrompt] = useState(true);

  useEffect(() => {
    if (checklistId) {
      // get chatroom checklist details
      _getChecklistFields(checklistId);
    }
  }, [checklistId, currentRoom]);

  useEffect(() => {
    if (done) {
      _refetchPromptFields(currentRoom);
    }
  }, [done, checklistValue]);

  useEffect(() => {
    setShowPrompt(true);
  }, [currentRoom]);

  useEffect(() => {
    if (!showPrompt && dismissed !== null) setShowPrompt(true);
  }, [dismissed]);

  const dismiss = useCallback(
    (fieldId: number) => {
      setShowPrompt(false);
      _dismissPrompt(currentRoom, fieldId);
    },
    [currentRoom]
  );

  // render prompt only when prompt needs to be shown
  // and after details have been loaded
  if (id && showPrompt && details && dismissed !== id) {
    return (
      <StyledPrompt>
        <AssistantIcon>
          <Icon type="assistant" />
        </AssistantIcon>
        <MessageCard first maxWidth>
          <AssistantTitle>Unifize Assistant</AssistantTitle>
          <AssistantInfo>
            Hey <span>@{currentUser}</span>, can you complete this step?
          </AssistantInfo>
          <Checklist
            id={id}
            field={details}
            roomId={currentRoom}
            setShowPrompt={setShowPrompt}
            checklistId={checklistId}
            done={done}
          />
          <Dismiss onClick={() => dismiss(id)}>Dismiss</Dismiss>
        </MessageCard>
      </StyledPrompt>
    );
  }

  return null;
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const { current } = app.chatRooms;
  const promptFields = getCurrentPromptFields(app, current);
  const { fields, id: checklistId } = promptFields || {};
  const id = (fields || []).length > 0 ? fields[0] : null;
  const details = id !== null ? getChecklistFieldDetails(app, `${id}`) : null;
  const primary = (getChatroomChecklists(app, current) || [])[0];
  const { progress } = primary || {};
  const { done } = progress || {};
  return {
    id,
    details,
    currentUser: app.currentUser.displayName || app.currentUser.email,
    currentRoom: current,
    checklistId,
    done,
    dismissed: getDismissed(app) || null,
    checklistValue: getChecklistValue(app, id, current)
  };
};

export default connect(mapStateToProps, {
  _getChecklistFields: getChecklistFields,
  _refetchPromptFields: refetchPromptFields,
  _dismissPrompt: dismissPrompt
})(Prompt);
