// @flow

import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

const checkEmail = (email: string): Action => ({
  type: atypes.CHECK_SIGN_UP_EMAIL,
  payload: { email }
});

type UserProfile = {
  displayName: string,
  password: string,
  profilePicture: File
};

const createUser = ({
  displayName,
  password,
  profilePicture
}: UserProfile): Action => ({
  type: atypes.CREATE_USER_REQUEST,
  payload: { displayName, password, profilePicture }
});

const validateAuthCode = ({
  authCode,
  email
}: {
  authCode: string,
  email: string
}): Action => ({
  type: atypes.VALIDATE_AUTH_CODE,
  payload: {
    email,
    authCode
  }
});

const setEmail = (email: string): Action => ({
  type: atypes.SET_SIGN_UP_EMAIL,
  payload: {
    email
  }
});

const signUpSuccess = (): Action => ({
  type: atypes.FIREBASE_SIGN_UP_SUCCESS,
  payload: {}
});

const sendVerificationMail = (): Action => ({
  type: atypes.SIGN_UP,
  payload: {
    page: "send-authcode"
  }
});

const sendVerificationMailSuccess = (): Action => ({
  type: atypes.SEND_VERIFICATION_MAIL_SUCCESS,
  payload: {}
});

const emailVerificationRequest = ({
  authCode,
  email
}: {
  authCode: string,
  email: string
}): Action => ({
  type: atypes.EMAIL_VERIFICATION_REQUEST,
  payload: {
    email,
    authCode
  }
});

type OrgSetupDetails = {
  title: string,
  member1: ?string,
  member2: ?string,
  member3: ?string,
  domain: string,
  autoJoin: boolean
};

const setupOrg = ({
  title,
  member1,
  member2,
  member3,
  domain,
  autoJoin
}: OrgSetupDetails): Action => ({
  type: atypes.SETUP_ORG_REQUEST,
  payload: {
    title,
    member1,
    member2,
    member3,
    domain,
    autoJoin
  }
});

const setupOrgSuccess = (): Action => ({
  type: atypes.SETUP_ORG_SUCCESS,
  payload: {}
});

const emailVerificationSent = (): Action => ({
  type: atypes.SIGN_UP,
  payload: {
    page: "emailSent"
  }
});

const setupProfile = (): Action => ({
  type: atypes.SIGN_UP,
  payload: {
    page: "profile"
  }
});

const createUserSuccess = ({
  displayName,
  profilePicture
}: {
  displayName: string,
  profilePicture: File
}): Action => ({
  type: atypes.CREATE_USER_SUCCESS,
  payload: {
    displayName,
    profilePicture
  }
});

// errors

const emailValidationFailed = (error: Object): Action => ({
  type: atypes.EMAIL_VALIDATION_FAILURE,
  payload: error
});

const createUserFailed = (error: Object): Action => ({
  type: atypes.CREATE_USER_FAILURE,
  payload: error
});

const profilePictureUploadFailed = (error: Object): Action => ({
  type: atypes.UPLOAD_PROFILE_PICTURE_FAILURE,
  payload: error
});

const emailVerificationFailed = (error: Object): Action => ({
  type: atypes.EMAIL_VERIFICATION_FAILURE,
  payload: error
});

const setupOrgFailed = (error: Object): Action => ({
  type: atypes.SETUP_ORG_FAILURE,
  payload: error
});

const clearAllErrors = (): Action => ({
  type: atypes.CLEAR_SIGNUP_ERRORS,
  payload: {}
});

const joinExistingOrg = (orgId: number): Action => ({
  type: atypes.JOIN_EXISTING_ORG_REQUEST,
  payload: {
    orgId
  }
});

const join = (payload: {
  email: string,
  displayName: string,
  password: string,
  profilePicture: any,
  orgId: number
}): Action => ({
  type: atypes.JOIN_ORG_REQUEST,
  payload
});

const srwSignUp = (): Action => ({
  type: atypes.SRW_SIGN_UP,
  payload: {}
});

export {
  createUser,
  validateAuthCode,
  setupOrg,
  setupOrgFailed,
  setupOrgSuccess,
  clearAllErrors,
  checkEmail,
  setEmail,
  signUpSuccess,
  sendVerificationMail,
  sendVerificationMailSuccess,
  emailVerificationRequest,
  emailVerificationSent,
  setupProfile,
  emailValidationFailed,
  createUserFailed,
  profilePictureUploadFailed,
  emailVerificationFailed,
  createUserSuccess,
  join,
  joinExistingOrg,
  srwSignUp
};
