// @flow

import React, { useRef, useState, useCallback } from "react";
import Textarea from "react-autosize-textarea";
import { Text } from "@chakra-ui/react";
import Linkify from "react-linkify";

import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import Icon from "src/icons";

import {
  TextField as StyledTextFieldContainer,
  Done as StyledUpdateButton,
  IconContainer,
  RevisionComment
} from "src/components/Dock/Checklist/styles";
import type { ReactRef } from "src/types";

type Props = {
  initialComment: ?string,
  onUpdate: Function,
  onHide: Function,
  isCommentBoxVisible: boolean
};

export default function Comment({
  initialComment,
  onUpdate,
  onHide,
  isCommentBoxVisible
}: Props) {
  const textAreaRef: ReactRef = useRef(null);
  const [versionComment, setVersionComment] = useState(initialComment || "");
  const [edit, setEdit] = useState(!initialComment);

  const reset = () => {
    setVersionComment(initialComment);
    setEdit(false);
    !initialComment && onHide();
  };

  const update = () => {
    onUpdate(versionComment);
    setEdit(false);
  };

  const { handleShiftEnter } = useKeyDownHandlers(update);

  const handleKeyDown = (e: any) => {
    // If escape is pressed cancel editing
    if (e.keyCode === 27) {
      reset();
    } else {
      handleShiftEnter(e);
    }
  };

  const componentDecorator = useCallback(
    (href, text, key) => (
      <a
        href={href}
        key={key}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {text}
      </a>
    ),
    []
  );

  // Hide the comment box
  if (!isCommentBoxVisible) return null;

  if (edit) {
    return (
      <StyledTextFieldContainer>
        <Textarea
          autoFocus
          value={versionComment}
          onChange={e => setVersionComment(e.target.value)}
          onKeyDown={handleKeyDown}
          innerRef={el => {
            textAreaRef.current = el;
          }}
        />
        <StyledUpdateButton onClick={update}>Update</StyledUpdateButton>
      </StyledTextFieldContainer>
    );
  }

  return (
    <RevisionComment
      disabled={false}
      clickToEdit={true}
      isText={false}
      isMandatory={false}
      onClick={() => setEdit(true)}
    >
      <Linkify componentDecorator={componentDecorator}>
        <Text overflowWrap="break-word" whiteSpace="pre-wrap" m={0}>
          {versionComment || ""}
        </Text>
      </Linkify>
      <IconContainer>
        <Icon type="checklistEdit" />
      </IconContainer>
    </RevisionComment>
  );
}
