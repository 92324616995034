// @flow

import React from "react";
import { useSelector } from "react-redux";

import Single from "./Single";
import { Owner as StyledOwner } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import { getFieldWidth } from "src/reducers";

import type { UID, ColumnId } from "src/types";

type Props = {
  id: number,
  index: number,
  value: ?UID,
  depth: number,
  parentId: ?number,
  selected: boolean,
  embedded?: boolean,
  columnId?: ColumnId
};

const Owner = ({
  id,
  value,
  index,
  depth,
  parentId,
  selected,
  embedded = false,
  columnId
}: Props) => {
  const fieldId = columnId ? `${columnId}-owner` : "owner";
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, fieldId));
  return (
    <StyledOwner selected={selected} embedded={embedded} width={fieldWidth}>
      <ValueWrapper showDropdown={true}>
        <Single
          index={index}
          value={value}
          roomId={id}
          depth={depth}
          parentId={parentId}
          selected={selected}
          columnId={columnId}
          labelWidth={fieldWidth}
          embedded={embedded}
        />
      </ValueWrapper>
    </StyledOwner>
  );
};

export default Owner;
