// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Title as StyledTitle } from "./styles";
import Edit from "./Edit";
import { Capitalize } from "src/styles";
import useBoolean from "src/hooks/useBoolean";
import useInput from "src/hooks/useInput";
import { updateSection } from "src/actions/orgSettings";

type Props = {
  position: string,
  value: string,
  id: number,
  _updateSection: Function
};

const Title = ({ position, id, value, _updateSection }: Props) => {
  const { value: newValue, handleChange, setValue } = useInput();
  const { value: edit, toggleBoolean } = useBoolean();

  const handleClose = useCallback(() => {
    if (value !== newValue && newValue.length > 0) {
      _updateSection({
        id,
        title: newValue
      });
    }
    toggleBoolean();
  }, [newValue, id, value, _updateSection, toggleBoolean]);

  const handleEdit = useCallback(() => {
    setValue(value);
    toggleBoolean();
  }, [toggleBoolean, setValue, value]);

  if (edit) {
    return (
      <Edit
        position={position}
        value={newValue}
        handleChange={handleChange}
        handleClose={handleClose}
      />
    );
  }

  return (
    <StyledTitle onClick={handleEdit}>
      <Capitalize>{position} </Capitalize> Section:
      <strong>{value}</strong>
    </StyledTitle>
  );
};

export default connect(null, { _updateSection: updateSection })(Title);
