// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, UID } from "src/types";

const setCurrentOrg = (last: number, uid: string, reload: boolean): Action => ({
  type: atypes.SET_CURRENT_ORG_REQUEST,
  payload: {
    last,
    uid,
    reload
  }
});

export const checkAuthProvider = (orgId: number) => ({
  type: atypes.CHECK_AUTH_PROVIDER,
  payload: {
    orgId
  }
});

type OrgDetails = {
  title: string,
  member1: ?string,
  member2: ?string,
  member3: ?string,
  domainAutoJoin: boolean,
  domainName: ?string
};

const createNewOrg = ({
  title,
  member1,
  member2,
  member3,
  domainAutoJoin,
  domainName
}: OrgDetails): Action => ({
  type: atypes.CREATE_NEW_ORG_REQUEST,
  payload: {
    title,
    member1,
    member2,
    member3,
    domainAutoJoin,
    domainName
  }
});

export const setOrgFileUploadSetting = (snapshot: Object) => ({
  type: atypes.SYNC_ORG_FILE_UPLOAD_SETTING_SUCCESS,
  payload: {
    snapshot
  }
});

export const syncUserOrgSuccess = (snapshot: Object) => ({
  type: atypes.SYNC_USER_ORG_SUCCESS,
  payload: {
    snapshot
  }
});

export const getOrgDetails = (id: number) => ({
  type: atypes.GET_ORG_DETAILS_REQUEST,
  payload: {
    id
  }
});

export const deleteLastOrg = (uid: UID) => ({
  type: atypes.DELETE_LAST_ORG_REQUEST,
  payload: {
    uid
  }
});

export { setCurrentOrg, createNewOrg };
