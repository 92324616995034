// @flow

import React, { useCallback } from "react";

import { FormControl, Grid, Text, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import CriteriaSelect from "./CriteriaSelect";
import SegmentValue from "./SegmentValue";

type Props = {
  id: number,
  value: Array<number>,
  index: number,
  handleChange: Function,
  handleCriteriaValue: Function,
  handleDelete: Function
};

const Criteria = ({
  id,
  value,
  index,
  handleChange,
  handleCriteriaValue,
  handleDelete
}: Props) => {
  const handleSelect = useCallback(
    (newId: number) => {
      handleChange({ id: newId, index });
    },
    [index, handleChange]
  );

  const handleSegmentValue = useCallback(
    (newValue: Array<number>) => {
      handleCriteriaValue({ value: newValue, index });
    },
    [index, handleCriteriaValue]
  );

  const deleteRow = useCallback(() => {
    handleDelete(index);
  }, [index, handleDelete]);

  return (
    <FormControl mb={1} fontSize="xs">
      <Grid templateColumns="56px 1fr 1fr 18px" alignItems="center">
        <Text px="1">{index === 0 ? "User" : "And user"}</Text>
        <CriteriaSelect value={id} handleSelect={handleSelect} />
        <SegmentValue
          type={id}
          value={value}
          handleSegmentValue={handleSegmentValue}
        />
        <IconButton
          type="button"
          onClick={deleteRow}
          aria-label="Delete user"
          bg="white"
          icon={<DeleteIcon />}
        />
      </Grid>
    </FormControl>
  );
};

export default Criteria;
