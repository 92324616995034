// @flow

import React from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { orgCloneWizardSteps } from "src/constants";
import { getOrgCloneState } from "src/selectors";
import * as orgCloneActions from "src/actions/orgClone";
import type { OrgCloneWizardState, OrgCloneState } from "src/types";

import {
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  Image,
  AspectRatio,
  Skeleton
} from "@chakra-ui/react";
import FormContainerWithLogo from "src/components/auth/FormContainerWithLogo";
import Logo from "src/img/logo.svg";
import Stage from "./Stage";
import * as styles from "./styles";

const stagesWhereYouCanGoBack = [
  orgCloneWizardSteps.signUp,
  orgCloneWizardSteps.signIn
];

const stagesWithoutActions = [
  orgCloneWizardSteps.verificationEmailSent,
  orgCloneWizardSteps.creatingOrg
];

export const getNextButtonDisabledState = (
  state: OrgCloneWizardState
): boolean => {
  if (state.step === orgCloneWizardSteps.email) {
    return R.isEmpty(state.fields.email.value);
  }

  if (state.step === orgCloneWizardSteps.signUp) {
    const fields = ["password", "confirmPassword", "firstName"];
    const emptyFields = fields.filter(field =>
      R.isEmpty(state.fields[field].value)
    );

    return !R.isEmpty(emptyFields);
  }

  if (state.step === orgCloneWizardSteps.createOrg) {
    const fields = ["orgName"];

    const emptyFields = fields.filter(field =>
      R.isEmpty(state.fields[field].value)
    );

    return !R.isEmpty(emptyFields);
  }

  return false;
};

const OrgClonePage = () => {
  const dispatch = useDispatch();

  const { wizard, template }: OrgCloneState = useSelector(getOrgCloneState);
  const isNextButtonDisabled = getNextButtonDisabledState(wizard);

  const { stepChangeInProgress, step } = wizard;

  const stageHasActions = !stagesWithoutActions.includes(step);

  const handleGoBack = () => dispatch(orgCloneActions.goBackwardRequest());
  const handleGoForward = () => dispatch(orgCloneActions.goForwardRequest());

  if (template.fetching) {
    return (
      <Flex sx={styles.container}>
        <VStack sx={styles.leftContainer}>
          <Image src={Logo} sx={styles.logo} />

          <Skeleton height="308px" width="443px" mb="28px" />

          <VStack sx={styles.orgTitleAndDescription}>
            <Skeleton height="32px" width="118px" />
            <Skeleton height="28px" width="421px" />
          </VStack>
        </VStack>

        <VStack sx={styles.formContainer}>
          <VStack sx={{ alignItems: "start", gridGap: "40px" }}>
            <VStack sx={{ alignItems: "start" }}>
              <Skeleton height="24px" width="77px" />
              <Skeleton height="32px" width="118px" />
              <Skeleton height="28px" width="165px" />
            </VStack>

            <Skeleton height="48px" width="400px" />
          </VStack>

          <Skeleton height="48px" width="270px" />
        </VStack>
      </Flex>
    );
  }

  if (template.error !== null) {
    return (
      <FormContainerWithLogo>This link is not valid</FormContainerWithLogo>
    );
  }

  return (
    <Flex sx={styles.container}>
      <VStack sx={styles.leftContainer}>
        <Image src={Logo} sx={styles.logo} />

        {template.data.videoLink && (
          <AspectRatio
            sx={{
              width: "80%",
              marginBottom: "28px"
            }}
            ratio={640 / 400}
          >
            <iframe
              src={template.data.videoLink}
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </AspectRatio>
        )}

        <VStack sx={styles.orgTitleAndDescription}>
          <Text textStyle="mobileHeading" sx={styles.orgTitle}>
            {template.data.title}
          </Text>

          <Text textStyle="displaySmall" sx={styles.orgDescription}>
            {template.data.description}
          </Text>
        </VStack>
      </VStack>

      <VStack sx={styles.formContainer}>
        <Stage />

        {stageHasActions && (
          <VStack sx={styles.bottomPart}>
            <HStack sx={styles.actionButtonsContainer}>
              {stagesWhereYouCanGoBack.includes(step) && (
                <Button
                  onClick={handleGoBack}
                  isDisabled={stepChangeInProgress}
                  sx={{
                    height: "100%",
                    width: "30%"
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                colorScheme="blue"
                onClick={handleGoForward}
                isLoading={stepChangeInProgress}
                isDisabled={stepChangeInProgress || isNextButtonDisabled}
                isFullWidth
                sx={{
                  height: "100%"
                }}
              >
                Next
              </Button>
            </HStack>

            <Text>
              By signing up, I agree to the Unifize Privacy Policy and Terms of
              Service.
            </Text>
          </VStack>
        )}
      </VStack>
    </Flex>
  );
};

export default OrgClonePage;
