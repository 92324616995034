import styled from "@emotion/styled";
import { rgba } from "polished";
import { switchProp } from "styled-tools";

import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import * as spacing from "src/styles/constants/spacing";
import * as status from "src/constants/status";

const Badge = styled.span`
  text-align: center;
  white-space: nowrap;
  padding: ${spacing.space_inset_squish_s};
  vertical-align: baseline;
  border-radius: 2px;
  font-size: 62.5%;
  font-weight: ${typography.semiBold};
  text-transform: uppercase;
  color: ${colors.brightLight};
  background-color: ${colors.activeFilter};
`;

const RedBadge = styled(Badge)`
  background-color: ${colors.close};
  font-size: 0.625rem;
  height: 1.6em;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const statusColor = {
  [status.NEW]: colors.warning,
  [status.OVERDUE]: colors.danger,
  [status.COMPLETED]: colors.success,
  [status.PENDING]: colors.secondary,
  [status.ACTIVE]: colors.secondary,
  [status.ACCEPTED]: colors.success,
  [status.REJECTED]: colors.danger,
  [status.CANCELED]: colors.secondary,
  [status.DRAFT]: colors.active
};

const statusTextColor = {
  [status.NEW]: colors.warning,
  [status.OVERDUE]: colors.brightLight,
  [status.COMPLETED]: colors.success,
  [status.PENDING]: colors.secondary,
  [status.ACTIVE]: colors.secondary,
  [status.ACCEPTED]: colors.success,
  [status.REJECTED]: colors.danger,
  [status.CANCELED]: colors.brightLight,
  [status.DRAFT]: colors.active
};

const statusBackgroundColor = {
  [status.NEW]: rgba(colors.warning, 0.2),
  [status.OVERDUE]: colors.danger,
  [status.COMPLETED]: rgba(colors.success, 0.2),
  [status.PENDING]: rgba(colors.secondary, 0.2),
  [status.ACTIVE]: rgba(colors.secondary, 0.2),
  [status.ACCEPTED]: rgba(colors.success, 0.2),
  [status.REJECTED]: rgba(colors.danger, 0.2),
  [status.CANCELED]: colors.secondary,
  [status.DRAFT]: rgba(colors.active, 0.2)
};

const Status = styled(Badge)`
  font-weight: normal;
  line-height: 1.5;
  font-size: 0.625rem;
  background: ${switchProp("status", statusBackgroundColor)};
  color: ${switchProp("status", statusTextColor)};
  border: 1px solid ${switchProp("status", statusColor)};
  margin-right: ${spacing.space_m};
  cursor: pointer;
  text-transform: uppercase;
`;

export { Badge, Status, RedBadge };
