// @flow

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import Section from "./Section";

type Props = {
  value: Array<number>,
  handleOpenModal: Function
};

const SortableItem = SortableElement(({ value, handleOpenModal }: Props) => (
  <Section id={value} handleOpenModal={handleOpenModal} />
));

export default SortableItem;
