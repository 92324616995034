// @flow

import React, { Component } from "react";
import { Text } from "@chakra-ui/react";

import Owner from "../Owner";
import Members from "../Members";
import Parent from "../Parent";

import Title from "src/containers/chatroom/creator/process/Title";
import Advanced from "src/containers/chatroom/creator/process/Advanced";
import { Body as FormBody } from "src/styles/form";
import * as styles from "./styles";

import type { RoomId, UID } from "src/types";

type Email = {
  email: string
};

type Member = UID | Email;

type Props = {
  templateId: number,
  parent: RoomId,
  owner: ?UID,
  currentUser: UID,
  members: Array<Member>,
  groups: Array<number>,
  advanced: boolean,
  details: string,
  dueDate: ?string,
  parent: RoomId,
  setConversationAttribute: Function,
  setConversationAttributes: Function,
  clearConversationDialog: Function,
  hideAdvanced: Function,
  getNextSeqNo: Function,
  clearNextSeqNo: Function,
  handleChange: Function,
  handleOwner: Function,
  handleMembers: Function,
  handleGroups: Function,
  handleDueDate: Function,
  handleParent: Function
};

type State = {
  showParent: boolean
};

class Process extends Component<Props, State> {
  state = {
    showParent: false
  };

  componentDidMount() {
    const { parent, templateId, getNextSeqNo } = this.props;
    if (templateId) {
      getNextSeqNo(templateId);
    }

    this.setState({
      showParent: !!parent
    });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      owner,
      parent,
      currentUser,
      templateId,
      getNextSeqNo,
      setConversationAttribute
    } = this.props;
    if (prevProps.templateId !== templateId) {
      getNextSeqNo(templateId);
      this.setState({ showParent: !!parent });

      if (!owner) {
        setConversationAttribute({ id: "owner", currentUser });
      }
    }
  }

  render() {
    const {
      members,
      groups,
      advanced,
      owner,
      handleChange,
      handleOwner,
      handleMembers,
      handleGroups,
      handleParent,
      parent,
      templateId
    } = this.props;
    const { showParent } = this.state;

    return (
      <FormBody>
        <Title id="title" handleChange={handleChange} templateId={templateId} />

        <Owner owner={owner} handleOwner={handleOwner} />

        <Members
          members={members}
          groups={groups}
          handleMembers={handleMembers}
          handleGroups={handleGroups}
        />
        <Text px={4} sx={styles.ExternalInviteInfo}>
          To invite someone externally, please use their email address.
          eg.john.doe@acme.com
        </Text>

        {showParent && !advanced ? (
          <Parent parent={parent} handleParent={handleParent} />
        ) : null}

        {advanced ? <Advanced {...this.props} /> : null}
      </FormBody>
    );
  }
}

export default Process;
