// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { Dot1, Dot2, Dot3 } from "src/components/ButtonLoader/styles";
import { ScrollableBox } from "src/styles/box";

export const ButtonOutline = styled.div`
  > button {
    width: 100%;
    background: ${props =>
      props.isLoading ? colors.blue : colors.brightLight};
    color: ${props => (props.isLoading ? colors.brightLight : colors.blue)};
    transition: all 0.8 ease;
    text-transform: none;
    border-radius: 4px;
    font-weight: bold;
    :hover,
    :focus {
      outline: none !important;
      box-shadow: none;
      background: ${colors.blue};
      transition: all 0.3s ease;
      color: ${colors.brightLight};
    }
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${colors.grey2};
  padding-left: 2em;
  padding-bottom: 1.5em;
  position: relative;
  > h2 {
    margin-bottom: 0.6em;
  }
`;

export const EditTitle = styled.h2`
  margin-left: -8px;
  > input {
    padding: 0 8px;
    width: 85%;
  }
`;

export const Title = styled.h2`
  padding-left: 8px;
  margin-left: -8px;
`;

export const Creator = styled.div`
  font-size: 0.88rem;
`;

export const MenuBar = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  display: flex;
  align-items: center;
  > button {
    background-color: ${colors.brightLight};
    outline: none;
    border: none;
    margin-left: 0.5em;
  }
`;

export const NoDashboard = styled.h2`
  text-align: center;
  height: 200px;
  color: ${colors.secondary};
  position: absolute;
  left: 50%;
  top: 20%;
`;

export const DeleteModal = styled.div`
  z-index: 999;
  position: absolute;
  top: 15%;
  left: 37%;
  background-color: ${colors.brightLight};
  min-width: 355px;
`;

export const PrimaryButton = styled.span`
  > button {
    outline: none;
    background-color: ${colors.danger};
    border: 1px solid ${colors.danger};
  }
`;

export const LoadingContainer = styled.div`
  outline: none;
  width: 100%;
  height: 70%;
  background-color: ${colors.brightLight};
  border-radius: 10px;
  color: ${colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  ${Dot1},
  ${Dot2},
  ${Dot3} {
    background-color: ${colors.blue};
  }
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  top: 0;
  right: 0;
`;
