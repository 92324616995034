// @flow

import { connect } from "react-redux";

import SearchMultiple from "src/components/user.old/SearchMultiple";

import * as actions from "src/actions";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  userList: app.users.searchResults
});

export default connect(mapStateToProps, actions)(SearchMultiple);
