// @flow

import * as R from "ramda";
import * as atypes from "src/constants/actionTypes";
import type { OrgAttrs, SSOConfig } from "src/types";

export const setOrgSettings = (id: ?string) => ({
  type: atypes.SET_ORG_SETTINGS_REQUEST,
  payload: { id }
});

export const setHomeScreenBuilderAttributes = (values: Object) => ({
  type: atypes.SET_HOME_SCREEN_BUILDER_ATTRIBUTES,
  payload: values
});

export const clearHomeScreenDialogAttributes = () => ({
  type: atypes.CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES,
  payload: {}
});

export const createSection = ({
  title,
  position
}: {
  title: string,
  position: string
}) => ({
  type: atypes.CREATE_SECTION_REQUEST,
  payload: {
    title,
    settings: {
      position
    }
  }
});

export const updateSection = (payload: Object) => ({
  type: atypes.UPDATE_SECTION_REQUEST,
  payload
});

export const updateOrgDetails = (orgId: number, attrs: OrgAttrs) => ({
  type: atypes.UPDATE_ORG_DETAILS_REQUEST,
  payload: {
    orgId,
    attrs
  }
});

export const addOrgDomain = (orgId: number, domain: string) => ({
  type: atypes.ADD_ORG_DOMAIN_REQUEST,
  payload: {
    orgId,
    domain
  }
});

export const deleteOrgDomain = (orgId: number, domain: string) => ({
  type: atypes.DELETE_ORG_DOMAIN_REQUEST,
  payload: {
    orgId,
    domain
  }
});

export const createTile = (homeScreenBuilderDialog: Object) => ({
  type: atypes.CREATE_TILE_REQUEST,
  payload: {
    homeScreenBuilderDialog
  }
});

export const editTile = () => ({
  type: atypes.EDIT_TILE_REQUEST,
  payload: {}
});

export const searchUserSegments = (text: string) => ({
  type: atypes.SEARCH_USER_SEGMENTS_REQUEST,
  payload: { text: R.toLower(text || "") }
});

export const setSegmentUserModalAttributes = (payload: Object) => ({
  type: atypes.SET_SEGMENT_USER_MODAL_ATTRIBUTES,
  payload
});

export const closeSegmentUserModal = () => ({
  type: atypes.CLOSE_SEGMENT_USER_MODAL,
  payload: {}
});

export const createUserSegment = (tileId: number) => ({
  type: atypes.CREATE_USER_SEGMENT_REQUEST,
  payload: {
    tileId
  }
});

export const editUserSegment = () => ({
  type: atypes.EDIT_USER_SEGMENT_REQUEST,
  payload: {}
});

export const updateTile = (payload: Object) => ({
  type: atypes.UPDATE_TILE_REQUEST,
  payload
});

export const updateSectionOrder = (id: Array<number>) => ({
  type: atypes.UPDATE_SECTION_ORDER_REQUEST,
  payload: {
    id
  }
});

export const deleteSection = (id: number) => ({
  type: atypes.DELETE_SECTION_REQUEST,
  payload: {
    id
  }
});

export const toggleHomescreen = (enabled: boolean) => ({
  type: atypes.TOGGLE_HOME_SCREEN_REQUEST,
  payload: {
    enabled
  }
});

export const deleteTile = (id: number) => ({
  type: atypes.DELETE_TILE_REQUEST,
  payload: {
    id
  }
});

export const saveSSOConfig = (config: SSOConfig) => ({
  type: atypes.SAVE_SSO_CONFIG,
  payload: config
});
