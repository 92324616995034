// @flow

import React from "react";

import { FormGroup, Label } from "src/styles/form";
import { Input } from "src/styles/input";

type Props = {
  description: string,
  handleChange: Function
};

const Description = ({ description, handleChange }: Props) => (
  <FormGroup>
    <Label>Description</Label>
    <Input
      type="text"
      value={description}
      id="description"
      placeholder="Enter the Description"
      onChange={handleChange}
      autoComplete="off"
    />
  </FormGroup>
);

export default Description;
