const tileColors = [
  "#ffff",
  "#FA9A88",
  "#FFC58B",
  "#FFEA8A",
  "#BBE5B3",
  "#B7ECEC",
  "#B4E0FA",
  "#B3BCF5",
  "#E3D0FF",
  "#F9fafb"
];

export default tileColors;
