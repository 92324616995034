// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { getAllGroupsById } from "src/reducers";
import type { State } from "src/types";

import MultiSelect2 from "src/components/ChakraPro/MultiSelect2";
import { Text } from "@chakra-ui/react";

type Props = {
  value: number[],
  superset?: number[] | null,
  onChange: Function
};

const GroupsMultiSelect = ({
  value,
  onChange,
  superset,
  ...restProps
}: Props) => {
  const groupsById = useSelector(({ app }: State) => getAllGroupsById(app));
  let allGroupIds: number[] = Object.keys(groupsById).map(item =>
    parseInt(item, 10)
  );
  allGroupIds = superset ? R.intersection(superset, allGroupIds) : allGroupIds;

  const [options, setOptions] = useState<number[]>(allGroupIds);

  const inputValueChangeHandler = inputValue => {
    setOptions(
      allGroupIds.filter(groupId =>
        groupsById[String(groupId)].title
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
    );
  };

  const itemRenderer = groupId => (
    <Text textStyle="label">
      {groupsById[String(groupId)]?.title || "Deleted Group"}
    </Text>
  );

  return (
    <MultiSelect2
      value={value}
      options={options}
      onQueryChange={inputValueChangeHandler}
      itemHandler={onChange}
      itemRenderer={itemRenderer}
      inputPlaceholder="Select groups"
      {...restProps}
    >
      {allGroupIds.map(groupId => (
        <p key={groupId} value={groupId}>
          {groupsById[String(groupId)].title}
        </p>
      ))}
    </MultiSelect2>
  );
};

export default GroupsMultiSelect;
