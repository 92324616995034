/* eslint-disable react/no-unescaped-entities */
// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  Option,
  OptionDanger,
  TypeHeader,
  SubHeader,
  List,
  Type as StyledType
} from "./styles";
import Radio from "src/components/Radio";
import { setNotificationSettings } from "src/actions/settings";

import type { AppState } from "src/types";

type Props = {
  notification: number,
  _setNotificationSettings: Function
};

const Type = ({ notification, _setNotificationSettings }: Props) => {
  const handleType = useCallback(
    (id: number) => {
      if (notification !== id) {
        _setNotificationSettings(id);
      }
    },
    [notification, _setNotificationSettings]
  );

  return (
    <StyledType>
      <Option selected={notification === 1} onClick={() => handleType(1)}>
        <Radio
          id="everything"
          checked={notification === 1}
          handleChange={() => {}}
        />
        <div>
          <TypeHeader>
            <strong>Everything: </strong>
            All updates and messages
          </TypeHeader>
          <SubHeader>
            <strong>For conversations you are a part of</strong>, you will be
            notified via email for all these updates:
          </SubHeader>
          <List>
            <li>
              <strong>All comments</strong> and messages
            </li>
            <li>
              When <strong>you've been added</strong> to a conversation
            </li>
            <li>
              <strong>All status updates,</strong> including when conversations
              are completed
            </li>
            <li>
              When the conversation is <strong>overdue</strong> and other{" "}
              <strong>reminders</strong>
            </li>
            <li>
              <strong>Any owner change</strong> or assignment
            </li>
            <li>
              When you’ve been <strong>@mentioned</strong> in a conversation
            </li>
          </List>
        </div>
      </Option>

      <Option selected={notification === 2} onClick={() => handleType(2)}>
        <Radio
          id="important"
          checked={notification === 2}
          handleChange={() => {}}
        />
        <div>
          <TypeHeader>
            <strong>Important: </strong>
            Status updates, reminders and @mentions
          </TypeHeader>
          <SubHeader>
            <strong>For conversations you are a part of,</strong> you will be
            notified via email for all these updates:
          </SubHeader>
          <List>
            <li>
              <strong>All status updates</strong>, including when conversations
              are completed
            </li>
            <li>
              When <strong>you've been added</strong> to a conversation
            </li>
            <li>
              When the conversation is <strong>overdue</strong> and other
              reminders
            </li>
            <li>
              <strong>Any owner change</strong> or assignment
            </li>
            <li>
              When you’ve been <strong>@mentioned</strong> in a conversation
            </li>
          </List>
        </div>
      </Option>

      <Option selected={notification === 3} onClick={() => handleType(3)}>
        <Radio
          id="critical"
          checked={notification === 3}
          handleChange={() => {}}
        />
        <div>
          <TypeHeader>
            <strong>Critical:</strong> Critical updates and @mentions
          </TypeHeader>
          <SubHeader>
            <strong>For conversations you are a part of</strong>, you will be
            notified via email for all these updates:
          </SubHeader>
          <List>
            <li>
              <strong>You've been assigned as the owner</strong> of a
              conversation
            </li>
            <li>
              You've been <strong>@mentioned</strong> in a conversation
            </li>
          </List>
        </div>
      </Option>

      <OptionDanger selected={notification === 4} onClick={() => handleType(4)}>
        <Radio
          id="nothing"
          checked={notification === 4}
          handleChange={() => {}}
        />
        <div>
          <TypeHeader>
            <strong>Nothing (Not Recommended)</strong>
          </TypeHeader>
          <SubHeader>
            You will not be notified via email. This may result in you missing
            out on very important conversations that you're part of.
          </SubHeader>
        </div>
      </OptionDanger>
    </StyledType>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  notification: app.settings.emailNotification
});

export default connect(mapStateToProps, {
  _setNotificationSettings: setNotificationSettings
})(Type);
