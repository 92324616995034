// @flow

import React from "react";
import usePortal from "react-useportal";

import { PrimaryButton } from "./styles";
import {
  Content as StyledContent,
  Overlay,
  ModalText,
  SecondaryFooter,
  HeadingWrapper,
  Secondary
} from "src/components/Manage/Reports/Modal/styles";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import useClickoutside from "src/hooks/useClickoutside";

type Props = {
  handleClick: Function,
  handleClose: Function,
  heading: string,
  content: string,
  primaryText: string,
  secondaryText: string,
  outerRef: Function
};

const WarningModal = ({
  handleClick,
  handleClose,
  heading,
  content,
  primaryText,
  secondaryText,
  outerRef
}: Props) => {
  useClickoutside({ outerRef, handleClose, disableEnter: true });
  const { Portal } = usePortal();
  return (
    <Portal>
      <Overlay>
        <StyledContent>
          <HeadingWrapper>{heading}</HeadingWrapper>
          <ModalFormBody>
            <ModalText>{content}</ModalText>
          </ModalFormBody>
          <SecondaryFooter>
            <PrimaryButton type="button" onClick={handleClick}>
              {primaryText}
            </PrimaryButton>
            <Secondary type="button" onClick={handleClose}>
              {secondaryText}
            </Secondary>
          </SecondaryFooter>
        </StyledContent>
      </Overlay>
    </Portal>
  );
};

export default WarningModal;
