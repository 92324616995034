// @flow

import React from "react";

type Props = {
  fill?: string
};

const BlockQuote = ({ fill, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...restProps}
  >
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z" />
    </g>
  </svg>
);

export default BlockQuote;
