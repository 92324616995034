// @flow

import React, { Fragment } from "react";
import { connect } from "react-redux";

import Common from "./Common";
import { UserName } from "./styles";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import { getAuthorColor } from "src/utils";
import * as notifications from "src/constants/notifications";
import type { Message, ChatroomAddress } from "src/types";

type Props = {
  message: Message,
  address: ChatroomAddress
};

const Member = ({ message, address }: Props) => {
  const { data, action } = message;
  const { members } = data;
  const userAction = action === notifications.MEMBER_ADD ? "added" : "removed";
  return (
    <Common {...message} blackText>
      {" "}
      {userAction}{" "}
      {members.map((member, i) => (
        <Fragment key={member}>
          {i === members.length - 1 && members.length > 1 && " and "}
          <UserName hashColor={getAuthorColor(member, address)}>
            <strong>
              <NameOrEmail uid={member} showPending={false} />
            </strong>
          </UserName>
          {i !== members.length - 2 &&
            i !== members.length - 1 &&
            members.length > 1 &&
            ", "}
        </Fragment>
      ))}
    </Common>
  );
};

const mapStateToProps = ({ app }) => ({
  address: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps)(Member);
