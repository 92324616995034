// @flow

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

export const PulseContainer = styled.div`
  height: ${props => props.h};
  width: ${props => (props.w ? props.w : "unset")};
  max-width: ${props => props.maxWidth};
  border-radius: ${radius.large};
  margin-bottom: ${spacing.space_l};
  background: ${colors.grey2};
  position: relative;
  overflow: hidden;
`;

const loadingPulse = keyframes`
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
`;

export const Pulse = styled.div`
  width: 100%;
  height: 100%;
  background: #ed5;
  max-width: 100px;
  position: absolute;
  background: linear-gradient(to right, ${colors.grey2} 0%, #ddd 100%);
  animation-duration: 1.75s;
  animation-name: ${loadingPulse};
  animation-iteration-count: infinite;
`;

export const PulseLoader = styled.div`
  animation-duration: 1.75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${loadingPulse};
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: ${props => props.h};
  width: ${props => props.w};
  position: relative;
  border-radius: ${radius.large};
  margin-bottom: ${spacing.space_l};
`;
