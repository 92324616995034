// @flow

import React from "react";
import * as R from "ramda";

import type { UnifizeChatRoom } from "src/types";

import Icons from "src/icons";
import {
  Button,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Text
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

import * as styles from "../styles";

type Props = {
  newVersion: ?string,
  setNewVersion: Function,
  versions: UnifizeChatRoom[],
  processTitle: string,
  selectNewVersion: Function,
  handleClose: Function
};

const Editor = ({
  newVersion,
  setNewVersion,
  versions,
  processTitle,
  selectNewVersion,
  handleClose
}: Props) => {
  return (
    <>
      <ModalBody {...styles.modalBody}>
        <RadioGroup value={newVersion} onChange={setNewVersion}>
          <VStack {...styles.radioOptions}>
            <Radio value="auto">
              <HStack {...styles.centerAlign}>
                <Text {...styles.normalText}>Auto-select current revision</Text>
                <Icons fill={colors.purple} type="currentRevision" />
              </HStack>
            </Radio>

            {versions.map(version => (
              <Radio key={version.id} value={version.id} {...styles.radio}>
                <Text {...styles.normalText}>
                  {processTitle} {`#${version.autoNo || version.seqNo || ""}`}
                  {version.currentVersion && "C"}: {version.title}
                </Text>
                <Text {...styles.versionComment}>{version.versionComment}</Text>
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </ModalBody>

      <ModalFooter {...styles.modalFooter}>
        <Button
          variant="uBlueFilled"
          onClick={selectNewVersion}
          disabled={R.isNil(newVersion)}
        >
          Confirm
        </Button>
        <Button variant="uSecondary" onClick={handleClose}>
          Back
        </Button>
      </ModalFooter>
    </>
  );
};

export default Editor;
