// @flow

import React from "react";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { HStack, Text, Tooltip, Box } from "@chakra-ui/react";
import * as styles from "./styles";

type Props = {
  onClick: Function,
  autoLink: boolean,
  value: number,
  hideName: boolean
};

const TextEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

const RevisionButton = ({ onClick, value, autoLink, hideName }: Props) => {
  return (
    <Tooltip label="Open Revisions" hasArrow>
      <HStack sx={styles.RevisionButton} onClick={() => onClick?.(value)}>
        <Box w="16px" h="1rem">
          <Icon
            type="currentRevision"
            fill={autoLink ? colors.purple : colors.inkLightest}
          />
        </Box>
        {!hideName && <Text sx={TextEllipsis}>Revision</Text>}
      </HStack>
    </Tooltip>
  );
};

export default RevisionButton;
