// @flow

import { SystemCSSProperties } from "@chakra-ui/react";

export const H6: SystemCSSProperties = {
  position: "absolute",
  fontSize: "1.5rem",
  top: "35%",
  left: "35%",
  my: 0
};

export const Image: SystemCSSProperties = {
  display: "block",
  margin: "2rem auto",
  width: "90%"
};
