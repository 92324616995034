// @flow

import React from "react";
import { Radio, Text } from "@chakra-ui/react";

import { ListItem } from "../styles";

type Props = {
  id: string,
  label: string,
  handleSelect: Function,
  currentFilter: string
};

const RadioFilter = ({ id, label, handleSelect, currentFilter }: Props) => {
  return (
    <ListItem role="button" onClick={() => handleSelect(id)}>
      <Radio size="sm" isChecked={id === currentFilter}>
        <Text fontSize="xs" m={0}>
          {label}
        </Text>
      </Radio>
    </ListItem>
  );
};

export default RadioFilter;
