// @flow

import React, { Fragment } from "react";

import { Value as StyledValue } from "./styles";
import Name from "src/components/Status/Name";
import Icon from "src/icons";

type Props = {
  value: Array<number>
};

const Selection = ({ value }: Props) => (
  <StyledValue>
    {value.map((status, index) => (
      <Fragment key={status}>
        <Name id={status} />
        {index === value.length - 1 ? "" : ","}
      </Fragment>
    ))}
    <Icon type="triangleDown" />
  </StyledValue>
);

export default Selection;
