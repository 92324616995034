// @flow

import { toast } from "react-toastify";
import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import * as R from "ramda";
import uuid from "uuid/v4";

import { getColumnName } from "src/utils";
import { getChartName } from "src/utils/charts";
import * as atypes from "src/constants/actionTypes";
import * as chart from "src/api/chart";
import getAppState from "src/selectors";
import { processColumnNames } from "src/constants/display";
import { getFormById } from "./forms";
import {
  getChart,
  getSingleSelectColumnNames,
  getNumericalColumnNames,
  getFormFieldColumnNames,
  getCurrentChecklistFormWithFields,
  getChecklistFieldDetails,
  getCurrentChecklistFormDetails,
  getWorkflowChecklistId,
  getWorkflowName,
  getCurrentEmbeddedFieldDetails,
  getReportFilters
} from "src/reducers";
import { getEmbeddedFieldDetails } from "./checklist";
import {
  chartChatroomAttributes,
  ageChartChatroomAttribute
} from "src/constants/chatroom.js";
import { seriesConfig } from "src/constants/charts";

import type { Action } from "src/types";

function* createChart(): any {
  try {
    const { newChartDialog } = (yield select(getAppState)).charts;
    const { type, chartName } = newChartDialog;
    const defaultValues = seriesConfig.get(chartName);
    const reportId =
      (yield select(getAppState)).workflow.instanceFilter.reportId || null;

    let seriesDetails = {
      ...defaultValues,
      tempId: uuid(),
      y: newChartDialog.y,
      relativeCount: newChartDialog.relativeCount,
      computationField: newChartDialog.computationField
    };

    if (type === "conversation-count" && !newChartDialog.numberFieldSwitch) {
      seriesDetails.computationType = "conversation";
      seriesDetails.computationField = null;
      seriesDetails.operation = "count";
    }

    let request: Object = {
      title: newChartDialog.title,
      description: newChartDialog.description,
      type: newChartDialog.type,
      reportId: parseInt(reportId, 10),
      x: newChartDialog.x,
      pYAxisLabel: newChartDialog.yAxisLabel,
      sort: newChartDialog.sort,
      series: [{ ...seriesDetails }]
    };

    if (type === "time-series") {
      request.columns = newChartDialog.columns;
      request.timeDiff = newChartDialog.timeDiff;
      request.timeIntervalLabel = newChartDialog.timeInterval;
      if (!newChartDialog.numberFieldSwitch) {
        const newRequest = { ...request };
        delete newRequest["sort"];
        delete newRequest["series"];
        request = newRequest;
      }
    }

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        stage: 3
      }
    });

    const newChart = yield call(chart.createChart, request);

    yield put({
      type: atypes.UPDATE_CHART_SUCCESS,
      payload: newChart
    });

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        stage: 2,
        id: newChart.id
      }
    });

    yield put({
      type: atypes.GET_REPORTS_CHART_JSON_REQUEST,
      payload: { id: newChart.id }
    });
  } catch (error) {
    toast.error("Unable to create chart");
    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        loading: false,
        stage: 1,
        error: "Unable to create chart"
      }
    });
    yield put({
      type: atypes.CREATE_CHART_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateChart(): any {
  yield takeLatest(atypes.CREATE_CHART_REQUEST, createChart);
}

function* updateChart(): any {
  try {
    const { newChartDialog } = (yield select(getAppState)).charts;
    const { id, type, chartName } = newChartDialog;
    const chartMetadata = getChart(yield select(getAppState), `${id}`);
    const { series } = chartMetadata || null;
    const defaultValues = seriesConfig.get(chartName);
    const reportId =
      (yield select(getAppState)).workflow.instanceFilter.reportId || null;

    const seriesDetails = {
      ...defaultValues,
      seriesId: series && series[0].seriesId,
      y: newChartDialog.y,
      relativeCount: newChartDialog.relativeCount,
      computationField: newChartDialog.computationField
    };

    if (type === "conversation-count" && !newChartDialog.numberFieldSwitch) {
      seriesDetails.computationType = "conversation";
      seriesDetails.computationField = null;
      seriesDetails.operation = "count";
    }

    let request: Object = {
      id: newChartDialog.id,
      title: newChartDialog.title,
      description: newChartDialog.description,
      type: newChartDialog.type,
      reportId: parseInt(reportId, 10),
      x: newChartDialog.x,
      pYAxisLabel: newChartDialog.yAxisLabel,
      sort: newChartDialog.sort,
      series: [{ ...seriesDetails }]
    };

    if (type === "time-series") {
      request.columns = newChartDialog.columns;
      request.timeDiff = newChartDialog.timeDiff;
      request.timeIntervalLabel = newChartDialog.timeInterval;
      if (!newChartDialog.numberFieldSwitch) {
        const newRequest = { ...request };
        delete newRequest["sort"];
        delete newRequest["series"];
        request = newRequest;
      }
    }

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        stage: 3
      }
    });

    const updatedChart = yield call(chart.editChart, request);
    if (updatedChart) {
      yield put({
        type: atypes.UPDATE_CHART_SUCCESS,
        payload: updatedChart
      });
      toast.success("Chart has been updated");
    }

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        stage: 2,
        id: updatedChart.id
      }
    });

    // Refresh the chart as soon as the chart data has been updated
    yield put({
      type: atypes.REFRESH_CHART_REQUEST,
      payload: { id: updatedChart.id }
    });
  } catch (e) {
    toast.error("Unable to update chart");
  }
}

function* watchUpdateChart(): any {
  yield takeLatest(atypes.UPDATE_CHART_REQUEST, updateChart);
}

function* getReportsChartJson({ payload }: Action): any {
  try {
    const chartMetadata = getChart(yield select(getAppState), `${payload.id}`);
    const { series, sort } = chartMetadata;
    const seriesDetails = series ? series[0] : null;
    let attributes: Object = {
      id: parseInt(chartMetadata.id, 10),
      title: chartMetadata.title,
      reportId: chartMetadata.reportId,
      description: chartMetadata.description,
      yAxisLabel: chartMetadata.pYAxisLabel,
      type: chartMetadata.type,
      sort,
      x: getColumnName(chartMetadata.x),
      y: getColumnName(seriesDetails?.y),
      computationField: seriesDetails?.computationField,
      relativeCount: seriesDetails?.relativeCount,
      form: null,
      formField: null,
      columns: {
        created: false,
        completed: false
      },
      chartName: getChartName(
        String(chartMetadata.type),
        seriesDetails?.computationType
      ),
      timePeriod: {
        timeDiff: 0
      },
      timeInterval: null,
      hide: false,
      loading: true,
      error: null,
      show: payload.dialog !== undefined ? payload.dialog : true,
      edit: true,
      stage: 2
    };

    if (chartMetadata.type === "time-series") {
      attributes.columns = chartMetadata.columns;
      attributes.timeDiff = chartMetadata.timeDiff;
      attributes.timeInterval = chartMetadata.timeIntervalLabel;
    }

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: attributes
    });

    const chartJson = yield call(chart.getChartJson, payload.id);

    yield put({
      type: atypes.GET_REPORTS_CHART_JSON_SUCCESS,
      payload: {
        id: payload.id,
        ...chartJson
      }
    });

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: {
        loading: false,
        error: null,
        show: payload.dialog !== undefined ? payload.dialog : true,
        stage: 2
      }
    });
  } catch (error) {
    yield put({
      type: atypes.CLOSE_NEW_CHART_DIALOG,
      payload: {}
    });

    toast.error("Unable to load chart");
    yield put({
      type: atypes.GET_REPORTS_CHART_JSON_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetReportsChartJson(): any {
  yield takeLatest(atypes.GET_REPORTS_CHART_JSON_REQUEST, getReportsChartJson);
}

function* getChartJson({ payload }: Action): any {
  try {
    const chartJson = yield call(chart.getChartJson, payload.id);

    yield put({
      type: atypes.GET_CHART_JSON_SUCCESS,
      payload: {
        id: payload.id,
        ...chartJson
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_CHART_JSON_FAILURE,
      payload: { error, id: payload?.id }
    });
  }
}

function* watchGetChartJson(): any {
  yield takeEvery(atypes.GET_CHART_JSON_REQUEST, getChartJson);
}

function* refreshChart({ payload }): any {
  try {
    const refreshedChartMetadata = yield call(chart.refreshChart, payload.id);
    if (refreshedChartMetadata) {
      yield put({
        type: atypes.UPDATE_CHART_SUCCESS,
        payload: refreshedChartMetadata
      });
    } else {
      toast.error(
        "Refreshed chart data not available. Please try again in a couple of minutes."
      );
    }

    yield put({
      type: atypes.REFRESH_CHART_SUCCESS,
      payload: { id: payload.id, ...refreshedChartMetadata }
    });

    yield put({
      type: atypes.FETCH_CHART_REQUEST,
      payload: { id: payload.id }
    });
  } catch (e) {
    yield put({
      type: atypes.REFRESH_CHART_ERROR,
      payload: { id: payload.id }
    });
    toast.error(
      "Refreshed chart data not available. Please try again in a couple of minutes."
    );
    console.log(e);
  }
}

function* watchRefreshChart(): any {
  yield takeEvery(atypes.REFRESH_CHART_REQUEST, refreshChart);
}

function* deleteChart({ payload }: Action): any {
  try {
    yield call(chart.deleteChart, payload.id);

    yield put({
      type: atypes.DELETE_CHART_SUCCESS,
      payload: { id: payload.id }
    });

    toast.success("Chart has been deleted");
  } catch (error) {
    toast.error("Unable to delete chart");
  }
}

function* watchDeleteChart(): any {
  yield takeLatest(atypes.DELETE_CHART_REQUEST, deleteChart);
}

function* searchColumnName({ payload }: Action): any {
  try {
    const searchTerm = (payload || {})?.searchTerm || "";
    const selectedForm = (yield select(getAppState)).charts.newChartDialog.form;
    const chartType = (yield select(getAppState)).charts.newChartDialog.type;
    const fieldsById = (yield select(getAppState)).checklist.fields.byId;
    const forbiddenFieldTypes = [
      "text",
      "number",
      "date",
      "pdf",
      "file",
      "section",
      "subSection",
      ""
    ];

    let columns = getSingleSelectColumnNames(yield select(getAppState));

    // If it's for form chart builder, add the selected form's fields
    // to the fields dropdown
    if (
      selectedForm &&
      ["form-count-stacked", "form-count-doughnut"].includes(chartType)
    ) {
      const allowedFormFields = (
        (yield select(getAppState)).checklist.fields.byForms[selectedForm] || []
      )
        .map(fieldId => ({ id: `${fieldId}` }))
        .filter(
          field =>
            !R.includes(
              // $FlowFixMe
              fieldsById.get(field.id)?.get("type") || "",
              forbiddenFieldTypes
            )
        );

      columns = [...allowedFormFields, ...columns];
    } else if (
      ["conversation-count-stacked", "conversation-count-doughnut"].includes(
        chartType
      )
    ) {
      const allForms = getCurrentChecklistFormWithFields(
        yield select(getAppState)
      );
      let allFormFields = [];

      for (let formData of allForms) {
        // Try to get the form fields from the state if it doesn't
        // exist, fetch the data from API
        let formFields =
          (yield select(getAppState)).checklist.fields.byForms[formData.form] ||
          // $FlowFixMe - does not support optional function call
          (yield call(getFormById, {
            type: atypes.FETCH_FORM_REQUEST,
            payload: { id: formData.form }
          }))?.fields.map(field => field.id) ||
          [];
        formFields = formFields.map(field => ({
          id: `${field}`,
          form: formData.form,
          field: formData.field
        }));

        const fieldsById = (yield select(getAppState)).checklist.fields.byId;
        // Add all form fields of the checklist to the fields dropdown
        allFormFields = [...allFormFields, ...formFields].filter(
          field =>
            !R.includes(
              // $FlowFixMe
              fieldsById.get(`${field.id}`)?.get("type") || "",
              forbiddenFieldTypes
            )
        );
      }
      columns = [...allFormFields, ...columns];
    }

    if (payload.fieldType === "number") {
      columns = getNumericalColumnNames(yield select(getAppState));
    } else if (payload.fieldType === "form") {
      columns = getFormFieldColumnNames(yield select(getAppState));
    }

    const result = columns.filter(column => {
      if (!isNaN(column.id)) {
        return R.includes(
          R.toLower(searchTerm),
          // $FlowFixMe
          R.toLower(fieldsById.get(`${column.id}`)?.get("label") || "")
        );
      }

      return R.includes(
        R.toLower(searchTerm),
        R.toLower(processColumnNames[column.id])
      );
    });

    yield put({
      type: atypes.SEARCH_COLUMN_NAMES_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: atypes.SEARCH_COLUMN_NAMES_FAILURE,
      payload: { error }
    });
  }
}

function* watchSearchColumnName(): any {
  yield takeLatest(atypes.SEARCH_COLUMN_NAMES_REQUEST, searchColumnName);
}

function* watchInitialSearchColumnName(): any {
  yield takeLatest(atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS, searchColumnName);
}

function* showEditChartModal({ payload }: Action): any {
  try {
    const chartMetadata = getChart(yield select(getAppState), `${payload.id}`);
    const { series, sort } = chartMetadata;
    const seriesDetails = series ? series[0] : null;
    const filter = (yield select(getAppState)).workflow.instanceFilter;
    let attributes: Object = {
      id: parseInt(chartMetadata.id, 10),
      title: chartMetadata.title,
      reportId: chartMetadata.reportId,
      description: chartMetadata.description,
      yAxisLabel: chartMetadata.pYAxisLabel,
      type: chartMetadata.type,
      sort,
      x: chartMetadata.x,
      y: seriesDetails?.y,
      computationField: seriesDetails?.computationField,
      relativeCount: seriesDetails?.relativeCount,
      form: null,
      formField: null,
      columns: {
        created: false,
        completed: false
      },
      chartName: getChartName(
        String(chartMetadata.type),
        seriesDetails?.computationType
      ),
      timeInterval: null,
      timeDIff: null,
      hide: false,
      loading: false,
      error: null,
      show: true,
      edit: true,
      stage: 1
    };

    if (chartMetadata.type === "time-series") {
      attributes.columns = chartMetadata.columns;
      attributes.timeDiff = chartMetadata.timeDiff;
      attributes.timeInterval = chartMetadata.timeIntervalLabel;
      if (seriesDetails?.computationField) {
        attributes.numberFieldSwitch = true;
      }
    }

    if (chartMetadata.type === "conversation-count") {
      if (seriesDetails?.computationField) {
        attributes.numberFieldSwitch = true;
      }
    }

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: attributes
    });

    if (filter.chartId) {
      yield put({
        type: atypes.SET_REPORTS_REQUEST,
        payload: {
          id: filter.reportId
        },
        meta: {
          query: {
            ...R.omit(["chartId"], filter || [])
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchShowEditChartModal(): any {
  yield takeLatest(atypes.SHOW_EDIT_CHART_MODAL, showEditChartModal);
}

// eslint-disable-next-line require-yield
function* copyChartURL(): any {
  try {
    // $FlowFixMe
    const url = document.getElementById("chartShareURL").href;
    navigator.clipboard.writeText(url);

    toast.success(`Copied your current chart URL`);
  } catch (error) {
    console.error(error);
    toast.error("Unable to copy chart URL");
  }
}

function* watchCopyChartURL(): any {
  yield takeLatest(atypes.COPY_CHART_URL, copyChartURL);
}

function* getAllCharts(): any {
  try {
    const charts = yield call(chart.getCharts);
    yield put({
      type: atypes.GET_CHARTS_SUCCESS,
      payload: {
        charts
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_CHARTS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetAllCharts(): any {
  yield takeLatest(
    [atypes.GET_CHARTS_REQUEST, atypes.GET_ALL_CHARTS_REQUEST],
    getAllCharts
  );
}

function* watchGetCharts(): any {
  yield takeLatest(atypes.GET_CHARTS_REQUEST, getAllCharts);
}

function* watchGetChartsReport(): any {
  yield takeLatest(atypes.SET_REPORTS_REQUEST, getAllCharts);
}

function* watchGetChartsInitial(): any {
  yield takeLatest(atypes.API_AUTH_SUCCESS, getAllCharts);
}

function* expandChart({ payload }: any) {
  try {
    const chartMetadata = getChart(yield select(getAppState), payload.chartId);

    yield put({
      type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
      payload: chartMetadata
    });
  } catch (error) {
    console.error("Unable get chart data");
  }
}

function* watchExpandChart(): any {
  yield takeLatest(atypes.EXPAND_CHART, expandChart);
}

export function* getChartDetails({ payload }: Action): any {
  try {
    const response = yield call(chart.getChart, payload.id);
    // Refresh the chart on homescreen first load if the lastGeneratedAt
    // time is older than an hour
    const currentTime = new Date();
    const lastGeneratedAt = new Date(response.lastGeneratedAt);
    const oneHourAgo = new Date(
      currentTime.setHours(currentTime.getHours() - 1)
    );

    if (lastGeneratedAt < oneHourAgo) {
      yield put({
        type: atypes.REFRESH_CHART_REQUEST,
        payload: {
          id: payload.id
        }
      });
    }
    yield put({
      type: atypes.GET_CHART_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: atypes.GET_CHART_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetChartDetails(): any {
  yield takeLatest(atypes.GET_CHART_REQUEST, getChartDetails);
}

function* getChartFormFields({ payload }: Action): any {
  try {
    const { appState } = payload;

    const allForms = getCurrentChecklistFormDetails(yield select(getAppState));

    let formFields = [];
    for (let formData of allForms) {
      const isFormFields = (yield select(getAppState)).checklist.fields.byForms[
        formData.form
      ];
      let result;
      if (isFormFields) {
        const fieldDetails = isFormFields.map(fieldId => {
          const details = getChecklistFieldDetails(appState, `${fieldId}`);
          // $FlowFixMe
          return Object.fromEntries(details);
        });

        result = fieldDetails.map(field => ({
          id: field.id,
          name: field.label,
          type: field.type,
          multiValue: JSON.parse(field.settings).multiple || false,
          linkedProcessOrForm: formData.formTitle,
          linkedProcessOrFormId: formData.form,
          linkedProcessOrFormType: "form",
          linkedOrFormFieldName: formData.checklistFormLabel,
          linkedOrFormFieldId: formData.checklistFormId
        }));
      } else {
        // $FlowFixMe - does not support optional function call
        const fieldDetails = (yield call(getFormById, {
          type: atypes.FETCH_FORM_REQUEST,
          payload: { id: formData.form }
        }))?.fields.map(field => field);

        result = fieldDetails.map(field => ({
          id: field.id,
          name: field.label,
          type: field.type,
          multiValue: JSON.parse(field.settings).multiple || false,
          linkedProcessOrForm: formData.formTitle,
          linkedProcessOrFormId: formData.form,
          linkedProcessOrFormType: "form",
          linkedOrFormFieldName: formData.checklistFormLabel,
          linkedOrFormFieldId: formData.checklistFormId
        }));
      }

      formFields = [...formFields, ...result];
    }

    yield put({
      type: atypes.GET_CHART_FORM_FIELDS_SUCCESS,
      payload: {}
    });

    return formFields;
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.GET_CHART_FORM_FIELDS_FAILURE,
      payload: { e }
    });
  }
}

function* watchGetChartsFormFields(): any {
  yield takeEvery(atypes.GET_CHART_FORM_FIELDS_REQUEST, getChartFormFields);
}

function* getChartEmbeddedFields({ payload }: Action): any {
  try {
    const { appState, workflowId } = payload;

    const allEmbeddedFields = getCurrentEmbeddedFieldDetails(
      yield select(getAppState)
    );

    let embeddedFields = [];
    for (let embeddedField of allEmbeddedFields) {
      const isEmbeddedField = getChecklistFieldDetails(
        yield select(getAppState),
        `${embeddedField.id}`
      );

      let result;

      if (isEmbeddedField) {
        // $FlowFixMe
        const fieldDetails = Object.fromEntries(isEmbeddedField);

        result = [
          {
            id: fieldDetails.id,
            name: fieldDetails.label,
            type: fieldDetails.type,
            multiValue: JSON.parse(fieldDetails.settings).multiple || false,
            linkedProcessOrForm: getWorkflowName(
              yield select(getAppState),
              embeddedField.linkedProcess
            ),
            linkedProcessOrFormId: embeddedField.linkedProcess,
            linkedProcessOrFormType: "process",
            linkedOrFormFieldName: embeddedField.checklistFieldLabel,
            linkedOrFormFieldId: embeddedField.checklistFieldId
          }
        ];
      } else {
        const checklistId = getWorkflowChecklistId(
          yield select(getAppState),
          `${workflowId}`
        );
        const fieldId = embeddedField.checklistFieldId;

        const fieldDetails = yield call(getEmbeddedFieldDetails, {
          type: atypes.GET_EMBEDDED_FIELDS_REQUEST,
          payload: { fieldId, checklistId }
        });

        result = fieldDetails.map(field => ({
          id: field.id,
          name: field.label,
          type: field.type,
          multiValue: JSON.parse(field.settings).multiple || false,
          linkedProcessOrForm: getWorkflowName(
            appState,
            embeddedField.linkedProcess
          ),
          linkedProcessOrFormId: embeddedField.linkedProcess,
          linkedProcessOrFormType: "process",
          linkedOrFormFieldName: embeddedField.checklistFieldLabel,
          linkedOrFormFieldId: embeddedField.checklistFieldId
        }));
      }

      embeddedFields = [...embeddedFields, ...result];
    }

    yield put({
      type: atypes.GET_CHART_EMBEDDED_FIELDS_SUCCESS,
      payload: {}
    });

    return embeddedFields;
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.GET_CHART_EMBEDDED_FIELDS_FAILURE,
      payload: { e }
    });
  }
}

function* watchGetChartsEmbeddedFields(): any {
  yield takeEvery(
    atypes.GET_CHART_EMBEDDED_FIELDS_REQUEST,
    getChartEmbeddedFields
  );
}

function* getPrimaryFieldList({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const { allFields, workflowId } = payload;

    const forbiddenFieldTypes = [
      "text",
      "number",
      "date",
      "pdf",
      "file",
      "section",
      "subSection",
      "form"
    ];

    const filteredFields = allFields.filter(
      field => !forbiddenFieldTypes.includes(field.type)
    );

    const chatroomAttributes = R.values(chartChatroomAttributes).map(
      ({ id, label }) => ({
        id,
        name: label,
        type: "",
        multiValue: false,
        linkedProcessOrForm: "",
        linkedProcessOrFormId: null,
        linkedProcessOrFormType: "",
        linkedOrFormFieldName: "",
        linkedOrFormFieldId: null
      })
    );

    const restChecklistFields = filteredFields.map(field => ({
      id: field.id,
      name: field.label,
      type: field.type,
      multiValue: field.settings.multiple || false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: "",
      linkedOrFormFieldId: null
    }));

    const formFields = yield call(getChartFormFields, {
      type: atypes.GET_CHART_FORM_FIELDS_REQUEST,
      payload: { appState }
    });

    const embeddedFields = yield call(getChartEmbeddedFields, {
      type: atypes.GET_CHART_EMBEDDED_FIELDS_REQUEST,
      payload: { workflowId, appState }
    });

    const primaryFields = [
      ...chatroomAttributes,
      ...restChecklistFields,
      ...formFields,
      ...embeddedFields
    ];

    const filteredPrimaryFields = primaryFields.filter(
      field => !forbiddenFieldTypes.includes(field.type)
    );

    yield put({
      type: atypes.GET_PRIMARY_FIELD_LIST_SUCCESS,
      payload: {
        fields: filteredPrimaryFields
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_PRIMARY_FIELD_LIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetPrimaryFieldList(): any {
  yield takeEvery(atypes.GET_PRIMARY_FIELD_LIST_REQUEST, getPrimaryFieldList);
}

function* getRecordCountFieldList({ payload }: Action): any {
  try {
    const { allFields, workflowId } = payload;

    const allowedFieldTypes = ["conversation", "childConversation", "link"];

    const filteredFields = allFields.filter(field =>
      allowedFieldTypes.includes(field.type)
    );

    const primaryRecord = {
      id: workflowId,
      name: getWorkflowName(yield select(getAppState), workflowId),
      type: "primaryRecord",
      multiValue: false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: "",
      linkedOrFormFieldId: null
    };

    const restChecklistFields = filteredFields.map(field => ({
      id: field.id,
      name: field.label,
      type: field.type,
      multiValue: field.settings.multiple || false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: "",
      linkedOrFormFieldId: null
    }));

    const allForms = getCurrentChecklistFormDetails(yield select(getAppState));

    const forms = allForms.map(formData => ({
      id: formData.form,
      name: formData.formTitle,
      type: "form",
      multiValue: false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: formData.checklistFormLabel,
      linkedOrFormFieldId: formData.checklistFormId
    }));

    const recordCountFieldList = [
      primaryRecord,
      ...restChecklistFields,
      ...forms
    ];

    yield put({
      type: atypes.GET_RECORD_COUNT_FIELD_LIST_SUCCESS,
      payload: {
        fields: recordCountFieldList
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_RECORD_COUNT_FIELD_LIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetRecordCountFieldList(): any {
  yield takeEvery(
    atypes.GET_RECORD_COUNT_FIELD_LIST_REQUEST,
    getRecordCountFieldList
  );
}

function* getNumericalFields({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const { allFields, workflowId } = payload;

    const allowedFieldTypes = ["number"];

    const filteredFields = allFields.filter(field =>
      allowedFieldTypes.includes(field.type)
    );

    const restChecklistFields = filteredFields.map(field => ({
      id: field.id,
      name: field.label,
      type: field.type,
      multiValue: field.settings.multiple || false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: "",
      linkedOrFormFieldId: null
    }));

    const formFields = yield call(getChartFormFields, {
      type: atypes.GET_CHART_FORM_FIELDS_REQUEST,
      payload: { appState }
    });

    const embeddedFields = yield call(getChartEmbeddedFields, {
      type: atypes.GET_CHART_EMBEDDED_FIELDS_REQUEST,
      payload: { workflowId, appState }
    });

    const numericFields = [
      ...restChecklistFields,
      ...formFields,
      ...embeddedFields
    ];

    const filteredNumericalFields = numericFields.filter(field =>
      allowedFieldTypes.includes(field.type)
    );

    const allNumericFields = [
      ...filteredNumericalFields,
      ageChartChatroomAttribute
    ];

    yield put({
      type: atypes.GET_NUMERICAL_FIELD_LIST_SUCCESS,
      payload: {
        fields: allNumericFields
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_NUMERICAL_FIELD_LIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetNumericalFieldList(): any {
  yield takeEvery(atypes.GET_NUMERICAL_FIELD_LIST_REQUEST, getNumericalFields);
}

function* getEmbeddedNumericalFieldList({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const { workflowId } = payload;

    const allowedFieldTypes = ["number"];

    const formFields = yield call(getChartFormFields, {
      type: atypes.GET_CHART_FORM_FIELDS_REQUEST,
      payload: { appState }
    });

    const embeddedFields = yield call(getChartEmbeddedFields, {
      type: atypes.GET_CHART_EMBEDDED_FIELDS_REQUEST,
      payload: { workflowId, appState }
    });

    const embeddedNumericFields = [...formFields, ...embeddedFields];

    const filteredEmbeddedNumericalFields = embeddedNumericFields.filter(
      field => allowedFieldTypes.includes(field.type)
    );

    const allFields = [
      ...filteredEmbeddedNumericalFields,
      ageChartChatroomAttribute
    ];

    yield put({
      type: atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_SUCCESS,
      payload: {
        fields: allFields
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetEmbeddedNumericalFieldList(): any {
  yield takeEvery(
    atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_REQUEST,
    getEmbeddedNumericalFieldList
  );
}

function* getGroupByFieldList({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const { allFields, workflowId, linkedOrFormFieldId } = payload;

    const allowedFieldTypes = [
      "link",
      "childConversation",
      "conversation",
      "select",
      "user",
      "form"
    ];

    const filteredFields = allFields.filter(field =>
      allowedFieldTypes.includes(field.type)
    );

    const chatroomAttributes = R.values(chartChatroomAttributes).map(
      ({ id, label }) => ({
        id,
        name: label,
        type: "",
        multiValue: false,
        linkedProcessOrForm: "",
        linkedProcessOrFormId: null,
        linkedProcessOrFormType: "",
        linkedOrFormFieldName: "",
        linkedOrFormFieldId: null
      })
    );

    const restChecklistFields = filteredFields.map(field => ({
      id: field.id,
      name: field.label,
      type: field.type,
      multiValue: field.settings.multiple || false,
      linkedProcessOrForm: "",
      linkedProcessOrFormId: null,
      linkedProcessOrFormType: "",
      linkedOrFormFieldName: "",
      linkedOrFormFieldId: null
    }));

    const formFields = yield call(getChartFormFields, {
      type: atypes.GET_CHART_FORM_FIELDS_REQUEST,
      payload: { appState }
    });

    const embeddedFields = yield call(getChartEmbeddedFields, {
      type: atypes.GET_CHART_EMBEDDED_FIELDS_REQUEST,
      payload: { workflowId, appState }
    });

    const linkedAndFormEmbeddedFields = [
      ...formFields,
      ...embeddedFields
    ].filter(field => field.linkedOrFormFieldId === linkedOrFormFieldId);

    const groupByFields = [
      ...restChecklistFields,
      ...linkedAndFormEmbeddedFields
    ];

    const filteredByAllowedFields = groupByFields.filter(field =>
      allowedFieldTypes.includes(field.type)
    );

    const allGroupByFields = [
      ...filteredByAllowedFields,
      ...chatroomAttributes
    ];

    yield put({
      type: atypes.GET_GROUP_BY_FIELD_LIST_SUCCESS,
      payload: {
        fields: allGroupByFields
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.GET_GROUP_BY_FIELD_LIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetGroupByFieldList(): any {
  yield takeEvery(atypes.GET_GROUP_BY_FIELD_LIST_REQUEST, getGroupByFieldList);
}

function* createComparisonChart({ payload }: Action): any {
  try {
    const comparisonChart = yield call(
      chart.createComparisonChart,
      payload.request
    );

    yield put({
      type: atypes.CREATE_COMPARISON_CHART_SUCCESS,
      payload: { id: comparisonChart.id }
    });

    const chartJSON = yield call(chart.getChartJson, comparisonChart.id);

    yield put({
      type: atypes.GET_CHART_JSON_SUCCESS,
      payload: {
        id: comparisonChart.id,
        title: comparisonChart.title,
        reportId: comparisonChart.reportId,
        ...chartJSON
      }
    });

    const filter = getReportFilters(
      yield select(getAppState),
      comparisonChart.reportId
    );

    // Show chart with report
    yield put({
      type: atypes.SET_REPORTS_REQUEST,
      payload: {
        id: comparisonChart.reportId
      },
      meta: {
        query: {
          ...filter,
          chartId: comparisonChart.id
        }
      }
    });

    yield put({
      type: atypes.CREATE_CHART_SUCCESS,
      payload: { id: -1 }
    });

    toast.success("New Chart Created");
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.CREATE_COMPARISON_CHART_FAILURE,
      payload: e
    });
    toast.error("Unable to create chart");
  }
}

function* watchCreateComparisonChart(): any {
  yield takeEvery(
    atypes.CREATE_COMPARISON_CHART_REQUEST,
    createComparisonChart
  );
}

function* updateComparisonChart({ payload }: Action): any {
  try {
    const updatedChart = yield call(
      chart.updateChart,
      payload.id,
      payload.request
    );

    yield put({
      type: atypes.UPDATE_COMPARISON_CHART_SUCCESS,
      payload: {}
    });

    const chartJSON = yield call(chart.refreshChart, updatedChart.id);

    yield put({
      type: atypes.GET_CHART_JSON_SUCCESS,
      payload: {
        id: updatedChart.id,
        title: updatedChart.title,
        reportId: updatedChart.reportId,
        ...chartJSON
      }
    });

    const filter = getReportFilters(
      yield select(getAppState),
      updatedChart.reportId
    );

    // Show chart with report
    yield put({
      type: atypes.SET_REPORTS_REQUEST,
      payload: {
        id: updatedChart.reportId
      },
      meta: {
        query: {
          ...filter,
          chartId: updatedChart.id
        }
      }
    });

    toast.success("Chart has been updated");
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.UPDATE_COMPARISON_CHART_FAILURE,
      payload: e
    });
    toast.error("Unable to update chart");
  }
}

function* watchUpdateComparisonChart(): any {
  yield takeLatest(
    atypes.UPDATE_COMPARISON_CHART_REQUEST,
    updateComparisonChart
  );
}

function* fetchChart({ payload }: Action): any {
  try {
    const fetchedChart = yield call(chart.fetchChart, payload.id);
    const sortedChart = {
      ...fetchedChart,
      series: fetchedChart.series
        ? fetchedChart.series.sort((a, b) => a.seqNo - b.seqNo)
        : []
    };

    yield put({
      type: atypes.FETCH_CHART_SUCCESS,
      payload: sortedChart
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.FETCH_CHART_FAILURE,
      payload: e
    });
    toast.error("Failed to fetch chart");
  }
}

function* watchFetchChart(): any {
  yield takeLatest(atypes.FETCH_CHART_REQUEST, fetchChart);
}

export default [
  watchGetChartDetails(),
  watchGetChartsReport(),
  watchExpandChart(),
  watchGetChartJson(),
  watchRefreshChart(),
  watchGetAllCharts(),
  watchCopyChartURL(),
  watchShowEditChartModal(),
  watchInitialSearchColumnName(),
  watchSearchColumnName(),
  watchDeleteChart(),
  watchGetReportsChartJson(),
  watchUpdateChart(),
  watchCreateChart(),
  watchGetCharts(),
  watchGetChartsInitial(),
  watchGetPrimaryFieldList(),
  watchGetRecordCountFieldList(),
  watchGetNumericalFieldList(),
  watchGetEmbeddedNumericalFieldList(),
  watchGetGroupByFieldList(),
  watchCreateComparisonChart(),
  watchUpdateComparisonChart(),
  watchFetchChart(),
  watchGetChartsFormFields(),
  watchGetChartsEmbeddedFields()
];
