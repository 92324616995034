// @flow

import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import * as styles from "./styles";

type Props = {
  thickness?: number,
  size?: "sm" | "md" | "lg" | "xl",
  color?: string
};

const SpinnerLoader = ({ thickness = 4, size = "xl", color }: Props) => {
  return (
    <Flex sx={styles.SpinnerWrapper}>
      <Spinner
        thickness={`${thickness}px`}
        speed="0.5s"
        emptyColor="unifize_grey4"
        {...(!color ? { color: "unifize_blue" } : { sx: { color } })}
        size={size}
      />
    </Flex>
  );
};

export default SpinnerLoader;
