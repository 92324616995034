// @flow

import React from "react";
import { Input } from "@chakra-ui/react";
import * as styles from "../../Builder/styles";
import { useSelector } from "react-redux";

type Props = {
  formTitle: string,
  setFormData: Function
};

const Title = ({ formTitle, setFormData }: Props) => {
  const { title } = useSelector(store => store.app.form.openedForm);

  const handleChange = e => {
    setFormData(prev => ({ ...prev, title: e.target.value }));
  };

  const handleBlur = () => {
    if (title !== formTitle) {
      setFormData(prev => ({ ...prev, title: formTitle.trim() }));
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Escape" || e.keyCode === 27) {
      setFormData(prev => ({ ...prev, title: title }));
    }
  };

  return (
    <Input
      onChange={handleChange}
      value={formTitle}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      autoFocus={!title}
      sx={styles.processTitleInput}
      placeholder="Untitled"
      size="lg"
      variant="unstyled"
    />
  );
};

export default Title;
