// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import usePrevious from "src/hooks/usePrevious";

import { paginateManageView } from "src/actions/workflows";
import { getProcessFilterPage } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  isVisible: boolean,
  page: number,
  _paginateManageView: Function
};

const LoadMore = ({ isVisible, _paginateManageView, page }: Props) => {
  const prevVisibility = usePrevious(isVisible);

  useEffect(() => {
    if (isVisible && isVisible !== prevVisibility) {
      // Fetching more data since last row is now visible

      _paginateManageView(parseInt(page, 10) + 1);
    }
  }, [isVisible, page, prevVisibility, _paginateManageView]);

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  page: getProcessFilterPage(app)
});

export default connect(mapStateToProps, {
  _paginateManageView: paginateManageView
})(LoadMore);
