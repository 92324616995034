// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const HighIconActive = createIcon({
  displayName: "HighIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        d="M14.9393 6.93932L9.06068 1.06068C8.47488 0.474882 7.52512 0.474882 6.93932 1.06068L1.06068 6.93932C0.474882 7.52512 0.474882 8.47488 1.06068 9.06068L6.93932 14.9393C7.52512 15.5251 8.47488 15.5251 9.06068 14.9393L14.9393 9.06068C15.5251 8.47488 15.5251 7.52512 14.9393 6.93932Z"
        fill="#ED8936"
        stroke="white"
        strokeWidth="0.893362"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99976 8.50103L7.99981 5.50098L10.9999 8.50103"
        stroke="white"
        strokeWidth="0.893362"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
});

export default HighIconActive;
