// @flow

import firebase from "firebase/app";
import "firebase/storage";

import { backendUrl } from "src/config/firebase";

import type { NewGroup, UID } from "src/types";

/**
 * Creates groups with given options
 * @param {NewGroup} options for given group
 * @return {Group} Newly created group
 */
export const createGroup = async (options: NewGroup) => {
  const response = await fetch(`${backendUrl}/group`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options)
  });
  return response.json();
};

/**
 * Get groups in given org
 * @return {Array} Array of groups in given org
 */
export const getGroups = async () => {
  const response = await fetch(`${backendUrl}/group`, {
    credentials: "include",
    method: "GET"
  });

  if (response.status === 200) {
    return response.json();
  }

  throw Error("Error fetching groups");
};

/**
 * Get group with given id
 * @return {Group} details of the group
 */
export const getGroup = async (id: number) => {
  const response = await fetch(`${backendUrl}/group/${id}`, {
    credentials: "include",
    method: "GET"
  });

  if (response.status === 200) {
    return response.json();
  }

  throw Error("Error fetching group");
};

/**
 * Upload group image
 * @param {string} name of the group
 * @param {Object} fileData Group image to be uploaded
 */
export const uploadGroupImage = async (name: string, fileData: Object) => {
  const storageRef = firebase.storage().ref(name);
  const downloadRef = await storageRef.put(fileData);
  const downloadUrl = await downloadRef.ref.getDownloadURL();
  return downloadUrl;
};

/**
 * Edit group with given options
 * @param {Object} options for given group
 * @param {number} id of the group
 */
export const editGroup = async (options: Object, id: number) => {
  await fetch(`${backendUrl}/group/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options)
  });
};

/**
 * Get user groups
 * @param {UID} uid of the user
 * @returns {Array} array of groups with "addedOn" attribute
 */
export const getUserGroups = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/member/${uid}`, {
    credentials: "include",
    method: "GET"
  });

  if (response.status !== 404) {
    return response.json();
  }

  throw new Error({
    message: "User is not member of any group",
    status: response.status
  });
};
