// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";

export const User = styled.span`
  padding: 1em 2em;
  border: 1px solid ${colors.grey12};
  border-right: 1px solid ${colors.grey1};
  border-top: none;
  background-color: ${colors.brightLight};
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 39;

  > span {
    display: flex;
    align-items: center;
    padding-left: 1.5em;
    > img,
    > div {
      height: 1.6em;
      width: 1.6em;
    }
    > span:last-child {
      padding-left: 0.4em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 7.5em;
    }
  }
`;

export const Cell = styled.span`
  text-align: center;
  border-bottom: 1px solid ${colors.grey12};
  padding: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

export const GreyCell = styled(Cell)`
  background: ${colors.highlight};
`;

export const BlueCell = styled(Cell)`
  background: ${colors.highlight};
`;

export const Active = styled.span`
  color: ${colors.active};
`;

export const Total = styled.span`
  position: sticky;
  left: 0;
  z-index: 40;
  bottom: ${props => (props.showAverage ? "3.6em" : "0")};
  padding: 1em 2em;
  border: 1px solid ${colors.grey12};
  border-right: none;
  border-top: none;
  background: ${colors.highlight};
  font-weight: bold;
  text-align: right;
`;

export const Average = styled(Total)`
  bottom: 0;
  background: ${colors.appBackground};
`;

export const OtherTotals = styled(GreyCell)`
  position: sticky;
  z-index: 39;
  bottom: ${props => (props.showAverage ? "4.3em" : "0")};
  padding: 1em 2em;
  border: none;
  border-bottom: 1px solid ${colors.grey12};
`;

export const OtherAverages = styled(OtherTotals)`
  bottom: 0;
  background: ${colors.appBackground};
`;
