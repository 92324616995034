// @flow

import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button
} from "@chakra-ui/react";
import FieldSettingsForm from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/FieldSettings";
import * as styles from "./SettingsModal.styles";

type Props = {
  isOpen: boolean,
  onClose: Function,
  fieldType: string,
  fieldName: string,
  position: number,
  currentSettings: Object,
  updateSettings: Function
};

export default function SettingsModal({
  isOpen,
  onClose,
  fieldType,
  fieldName,
  position,
  currentSettings,
  updateSettings
}: Props) {
  const [localSettings, setLocalSettings] = useState(currentSettings);

  const updateSettingsHandler = () => {
    updateSettings(localSettings);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent sx={styles.content}>
        <ModalHeader sx={styles.header}>{fieldName} settings</ModalHeader>

        <ModalBody>
          <FieldSettingsForm
            currentSettings={currentSettings}
            type={fieldType}
            position={position}
            setLocalSettings={setLocalSettings}
          />
        </ModalBody>

        <ModalFooter sx={styles.footer}>
          <Button variant="uSecondary" onClick={onClose}>
            Cancel Changes
          </Button>
          <Button variant="uPrimary" onClick={updateSettingsHandler}>
            Update Field Settings
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
