// @flow

import styled from "@emotion/styled";

import { Cell } from "../styles";
import { NameOrEmail } from "src/components/user/styles";

export const Owner = styled(Cell)`
  height: auto;
  ${NameOrEmail} {
    max-width: 9em;
  }
`;
