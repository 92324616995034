// @flow

import React from "react";

import { Select } from "@chakra-ui/react";

type Props = {
  value?: string,
  handleSelect: Function
};

const TimeInterval = ({ value, handleSelect }: Props) => (
  <Select value={value} size="sm" onChange={handleSelect}>
    <option value="daily">Daily</option>
    <option value="weekly">Weekly</option>
    <option value="monthly">Monthly</option>
    <option value="annually">Yearly</option>
  </Select>
);

export default TimeInterval;
