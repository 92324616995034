// @flow

import styled from "@emotion/styled";

const Title = styled.td`
  width: 20em;
  :hover {
    > div > svg {
      visibility: visible;
    }
  }
  > div {
    display: flex;
    align-items: center;
    > svg {
      margin-left: 0.4em;
      position: relative;
      top: -1px;
      visibility: hidden;
    }
  }
`;

const TitleText = styled.span`
  > a {
    display: inline-flex;
    > span {
      display: inline-block;
      max-width: 10em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > svg {
      margin-left: 0.4em;
      position: relative;
      top: 2px;
    }
  }
`;

const Image = styled.img`
  display: inline-block;
  margin-right: 0.4em;
  height: 1.7em;
  width: 1.7em;
`;

const Users = styled.td`
  width: 24em;
`;

const Creator = styled.td`
  width: 14em;
`;

export { Title, Users, Creator, Image, TitleText };
