// @flow

import { Flex } from "@chakra-ui/react";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import type { ChecklistBuilderField } from "src/types";

import SortableItem from "./SortableItem";

type Props = {
  items: ChecklistBuilderField[],
  updateField: Function,
  addFields: Function,
  removeField: Function,
  openSettings: Function
};

const SortableList = SortableContainer(
  ({ items, updateField, addFields, removeField, openSettings }: Props) => {
    return (
      <Flex
        flexDirection="column"
        sx={{
          alignItems: "start",
          gap: "1rem",
          mt: 2,
          mb: 8
        }}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            addFields={addFields}
            openSettings={openSettings}
            updateField={updateField}
            removeField={removeField}
          />
        ))}
      </Flex>
    );
  }
);

export default SortableList;
