// @flow

import { connect } from "react-redux";
import React from "react";

import { Overlay as StyledOverlay } from "./styles";

import type { AppState } from "src/types";

type Props = {
  showOverlay: boolean
};

const Overlay = ({ showOverlay }: Props) => {
  return <StyledOverlay show={showOverlay} />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  showOverlay: app.showOverlay
});

export default connect(mapStateToProps)(Overlay);
