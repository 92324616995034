// @flow

import React from "react";
import { Center, Text } from "@chakra-ui/react";

import * as styles from "./styles";

type Props = {
  hasPreviewSupport: boolean,
  isPreviewEnabled: boolean
};

const PreviewUnavailable = ({ hasPreviewSupport, isPreviewEnabled }: Props) => {
  return !hasPreviewSupport && isPreviewEnabled ? (
    <Center {...styles.popover.noPreview.container}>
      <Text {...styles.popover.noPreview.text}>
        No Preview Available
      </Text>
    </Center>
  ) : null;
}

export default PreviewUnavailable;
