// @flow

import React from "react";
import * as R from "ramda";
import type { LocalSettings } from "./index";

import {
  VStack,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel
} from "@chakra-ui/react";

type Props = {
  localSettings: LocalSettings,
  setLocalSettings: ((state: LocalSettings) => LocalSettings) => void
};

export default function Settings({ localSettings, setLocalSettings }: Props) {
  return (
    <VStack>
      <FormControl>
        <FormLabel>Status Type</FormLabel>
        <RadioGroup
          value={localSettings.active ? "pending" : "complete"}
          onChange={value =>
            setLocalSettings(R.mergeDeepLeft({ active: value === "pending" }))
          }
        >
          <Stack direction="column">
            <Radio size="sm" value="pending">
              Map to Pending
            </Radio>
            <Radio size="sm" value="complete">
              Map to Complete
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <Divider />

      <FormControl>
        <FormLabel>Archive behavior</FormLabel>
        <RadioGroup
          value={localSettings.settings.archiveMode}
          onChange={value =>
            setLocalSettings(
              R.mergeDeepLeft({
                settings: {
                  archiveMode: value
                }
              })
            )
          }
        >
          <Stack direction="column">
            <Radio size="sm" value="ignore">
              Ignore archiving
            </Radio>
            <Radio size="sm" value="archive">
              Auto archive
            </Radio>
            <Radio size="sm" value="unarchive">
              Auto unarchive
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </VStack>
  );
}
