// @flow

import React from "react";

import ParticipantsToAdd from "./ParticipantsToAdd";
import ParticipantsToRemove from "./ParticipantsToRemove";
import MessageToSend from "./MessageToSend";
import PrivacyMode from "./PrivacyMode";
import NewStatus from "./NewStatus";
import ArchiveState from "./ArchiveState";
import Unarchive from "./Unarchive";

type Props = {
  action:
    | "addParticipants"
    | "removeParticipants"
    | "sendMessage"
    | "updatePrivacy"
    | "updateStatus"
    | "archive",
  value: any,
  onChange: Function,
  isActive?: boolean,
  onActiveChange?: Function,
  allStatuses?: Array<Object>,
  isNewCurrent?: boolean
};

export default function Automations({
  action,
  value,
  onChange,
  isActive = true,
  onActiveChange = () => {},
  allStatuses = [],
  isNewCurrent
}: Props) {
  switch (action) {
    case "addParticipants":
      return <ParticipantsToAdd value={value} onChange={onChange} />;

    case "removeParticipants":
      return <ParticipantsToRemove value={value} onChange={onChange} />;

    case "sendMessage":
      return <MessageToSend value={value} onChange={onChange} />;

    case "updatePrivacy":
      return <PrivacyMode value={value} onChange={onChange} />;

    case "updateStatus":
      return (
        <NewStatus
          value={value}
          onChange={onChange}
          allStatuses={allStatuses}
          isActive={isActive}
          onActiveChange={onActiveChange}
        />
      );

    case "archive":
      return isNewCurrent ? (
        <Unarchive value={value} onChange={onChange} />
      ) : (
        <ArchiveState value={value} onChange={onChange} />
      );

    default:
      return <p>{action} is not supported</p>;
  }
}
