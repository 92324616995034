// @flow

import React, { useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";

import {
  DateItem as StyledItem,
  Selection as StyledSelection,
  Calendar as StyledCalendar,
  Value
} from "./styles";
import Icon from "src/icons";
import Calendar from "src/components/date/Calendar";
import Checkbox from "src/components/Checkbox";

type Props = {
  label: string,
  id: string,
  value: ?Object,
  handleChange: Function
};

const Item = ({ label, id, value, handleChange }: Props) => {
  const [calendar, showCalendar] = useState(false);
  const [localDate, setLocalDate] = useState(null);
  const outerRef = useRef(null);

  useEffect(() => {
    setLocalDate(value ? value.toDate() : null);
  }, []);

  const handleLocalDate = useCallback(
    (newDate: Date) => {
      setLocalDate(newDate);
      handleChange(newDate);
    },
    [handleChange, setLocalDate]
  );

  const handleCheckbox = useCallback(() => {
    if (value !== null) {
      handleChange(null);
    } else {
      handleChange(localDate || new Date());
      if (!localDate) {
        handleLocalDate(new Date());
      }
    }
  }, [handleChange, handleLocalDate]);

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledItem
      onClick={preventDefault}
      ref={outerRef}
      onKeyDown={preventDefault}
    >
      <span
        tabIndex="0"
        role="button"
        onClick={handleCheckbox}
        onKeyPress={handleCheckbox}
      >
        <Checkbox
          id={id}
          checked={value !== null}
          handleChange={handleCheckbox}
        />
        <span id={id}>{label}</span>
      </span>
      <StyledSelection>
        <div
          tabIndex="0"
          role="button"
          onClick={() => showCalendar(!calendar)}
          onKeyPress={() => showCalendar(!calendar)}
        >
          <Value>
            {localDate
              ? moment(localDate).format("MMM DD, YYYY")
              : "Select date"}
          </Value>
          <StyledCalendar>
            <Icon type="calendar" />
          </StyledCalendar>
        </div>
        {calendar ? (
          <Calendar
            handleChange={handleLocalDate}
            outerRef={outerRef}
            value={localDate}
            handleClose={() => showCalendar(false)}
          />
        ) : null}
      </StyledSelection>
    </StyledItem>
  );
};

export default Item;
