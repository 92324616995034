// @flow

import React from "react";
import { Creator as StyledCreator, CreatorTitle, CreatorIcon } from "./styles";
import { Avatar } from "src/components/Unifize";
import Icon from "src/icons";

type Props = {
  title: string,
  img?: ?string,
  icon?: string,
  hideIcon?: boolean,
  iconColor?: string
};

const Creator = ({
  title,
  img = "",
  icon,
  hideIcon = false,
  iconColor
}: Props) => {
  return (
    <StyledCreator>
      {!hideIcon &&
        (icon ? (
          <CreatorIcon color={iconColor}>
            <Icon type={icon} />
          </CreatorIcon>
        ) : (
          <Avatar src={img} alt={title} />
        ))}

      <CreatorTitle>{title} </CreatorTitle>
    </StyledCreator>
  );
};

export default Creator;
