// @flow

export type IntegrationContent = {
  id: string,
  description: string,
  icon: Object,
  info: IntegrationInfo
};

export type IntegrationInfo = {
  title: string,
  points: Array<string>,
  steps: Array<string>
};

export type FileCreationItem = {
  id: string,
  title: string,
  icon: Object,
  extension: string
};

export type IntegrationMap = Map<string, IntegrationContent>;
export type FileCreationMap = Map<string, FileCreationItem>;

export const integrations: IntegrationMap = new Map([
  [
    "slack",
    {
      id: "slack",
      description:
        "Slack makes all your tools work better by letting you integrate industry-leading software and custom apps right into Slack.",
      icon: require(`src/img/apps/slack.svg`),
      info: {
        title: "",
        points: [
          "The Slack integration is designed to enable free flow conversation on slack to become time-bound process driven conversations on Unifize"
        ],
        steps: [
          "Integrate Unifize to one or more channels on Slack.",
          "Create records on Unifize from comments on Slack.",
          "Forward comments, files and even entire threads into Unifize.",
          "Create end-to-end traceability on both systems with hyperlinks being generated on both systems and user attribution."
        ]
      }
    }
  ],
  [
    "microsoft365",
    {
      id: "microsoft365",
      description:
        "Microsoft 365 brings together productivity apps with cloud services, device management, and advanced security in one, connected experience.",
      icon: require(`src/img/apps/microsoft365.svg`),
      info: {
        title: "No more download, delete, upload to make changes!",
        points: [
          "Edit documents directly using Microsoft online editor",
          "Link the documents you already have in sharepoint",
          "Approve and lock down document revisions in Unifize"
        ],
        steps: [
          "Ask your Office365 Admin to click on the connect button below",
          "Give permission for Unifize to access Sharepoint",
          "Each user will have to connect their account when they first try to access sharepoint via Unifize"
        ]
      }
    }
  ],
  [
    "microsoft365-admin",
    {
      id: "microsoft365-admin",
      description:
        "Microsoft 365 Admin brings together productivity apps with cloud services, device management, and advanced security in one, connected experience.",
      icon: require(`src/img/apps/microsoft365-admin.svg`),
      info: {
        title: "No more download, delete, upload to make changes!",
        points: [
          "Edit documents directly using Microsoft online editor",
          "Link the documents you already have in sharepoint",
          "Approve and lock down document revisions in Unifize"
        ],
        steps: [
          "Ask your Office365 Admin to click on the connect button below",
          "Give permission for Unifize to access Sharepoint",
          "Each user will have to connect their account when they first try to access sharepoint via Unifize"
        ]
      }
    }
  ]
]);

export const fileCreationMap: FileCreationMap = new Map([
  [
    "excel",
    {
      id: "excel",
      title: "Create new Excel file (365)",
      icon: require(`src/img/apps/excel.svg`),
      extension: ".xlsx"
    }
  ],
  [
    "word",
    {
      id: "word",
      title: "Create new Word file (365)",
      icon: require(`src/img/apps/word.svg`),
      extension: ".docx"
    }
  ],
  [
    "powerpoint",
    {
      id: "powerpoint",
      title: "Create new PowerPoint file (365)",
      icon: require(`src/img/apps/powerpoint.svg`),
      extension: ".pptx"
    }
  ]
]);

export const GRAPH_URL = "https://graph.microsoft.com/v1.0/me/drive/root:";
