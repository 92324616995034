// @flow

import React from "react";

import { HStack, VStack } from "@chakra-ui/react";

import Icon from "src/icons";
import { Input } from "src/styles/input";

import * as styles from "./styles";

type Props = {
  value: number | null,
  onUpdate: (value: number | null) => void
};

export default function Template({ value, onUpdate }: Props) {
  const handleTemplateId = event => {
    onUpdate(event.target.value);
  };

  return (
    <VStack {...styles.TemplateContainer}>
      <HStack>
        <span style={styles.Label}>TYPE</span>
        <Icon type="info" filled width="13" height="13" />
      </HStack>

      <Input
        value={value}
        placeholder="Template ID"
        onChange={handleTemplateId}
      />
    </VStack>
  );
}
