// @flow

import React from "react";
import moment from "moment";

import Common from "./Common";

import type { Message } from "src/types";

type Props = {
  message: Message
};

const Duedate = ({ message }: Props) => {
  const { data } = message;
  const { new: newDate, old: oldDate } = data;
  if (newDate && oldDate)
    return (
      <Common {...message}>
        {" "}
        updated the due date to{" "}
        <strong>{moment(newDate.toDate()).format("MMM DD, YYYY")}</strong>
      </Common>
    );
  if (newDate)
    return (
      <Common {...message}>
        {" "}
        set the due date to{" "}
        <strong data-cy="newDueDateNotification">
          {moment(newDate.toDate()).format("MMM DD, YYYY")}
        </strong>
      </Common>
    );
  return <Common {...message}> removed the due date</Common>;
};

export default Duedate;
