// @flow

import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Flex
} from "@chakra-ui/react";
import * as R from "ramda";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import * as styles from "../styles";
import Tooltip from "src/components/Tooltip";
import { trendlineColors } from "src/constants/charts";
import { setTrendline } from "src/utils/charts.js";

type Props = {
  setChartDetails: Function,
  seriesId: string,
  colorHexCode: string
};

const ColorDropdown = ({ setChartDetails, seriesId, colorHexCode }: Props) => {
  const trendlineColorList = R.values(trendlineColors);

  const selectedColor = trendlineColors[colorHexCode];

  return (
    <Menu>
      {({ isOpen }) => {
        return (
          <>
            <MenuButton
              sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
              as={Button}
              rightIcon={
                isOpen ? (
                  <ChevronUpIcon w={6} h={6} />
                ) : (
                  <ChevronDownIcon w={6} h={6} />
                )
              }
            >
              {selectedColor ? (
                <Flex sx={{ alignItems: "center", gap: "4px" }}>
                  <Box
                    sx={{
                      display: "inline-block",
                      bg: selectedColor.hexCode,
                      h: "1rem",
                      w: "1rem",
                      borderRadius: "50%"
                    }}
                  ></Box>
                  <Box as="span">{selectedColor.name}</Box>
                </Flex>
              ) : (
                "Select"
              )}
            </MenuButton>

            <MenuList sx={styles.ColorMenuList}>
              {trendlineColorList.map(color => {
                const { id, name, hexCode } = color;
                return (
                  <Tooltip
                    key={id}
                    sx={{ backgroundColor: "none" }}
                    title={name}
                    placement="top-start"
                  >
                    <MenuItem
                      onClick={() => {
                        setTrendline(
                          setChartDetails,
                          seriesId,
                          "color",
                          hexCode
                        );
                      }}
                      sx={{
                        w: "1.5rem",
                        h: "1.5rem",
                        bg: hexCode,
                        borderRadius: "4px",
                        _hover: {
                          bg: hexCode
                        },
                        _focus: {
                          bg: hexCode
                        }
                      }}
                    ></MenuItem>
                  </Tooltip>
                );
              })}
            </MenuList>
          </>
        );
      }}
    </Menu>
  );
};

export default ColorDropdown;
