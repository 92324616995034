// @flow

import { connect } from "react-redux";
import React from "react";

import { NoInstances as StyledNoValue } from "./styles";

import type { AppState } from "src/types";

type Props = {
  loading: boolean
};

const NoValue = ({ loading }: Props) => {
  if (!loading) {
    return (
      <StyledNoValue>
        There are no processes that match these filters
      </StyledNoValue>
    );
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  loading: app.workflow.loadingInstances
});

export default connect(mapStateToProps)(NoValue);
