// @flow

import styled from "@emotion/styled";

import { Table as DefaultTable } from "src/styles/table";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { Status as Badge } from "src/styles/badge";
import { ButtonLink } from "src/styles/buttons";

const Button = styled(ButtonLink)`
  display: inline-flex;
  align-items: center;
  padding-left: 2.1rem;
  position: relative;
  line-height: normal;

  > span {
    padding-left: ${spacing.space_s};
  }

  svg {
    height: 1em;
    width: 1.3em;
    position: relative;
  }

  g {
    fill: ${colors.active};
  }
`;

const Table = styled(DefaultTable)`
  tbody {
    max-height: calc(100vh - 16em);
  }
  > tbody > tr > td,
  > thead > tr > td {
    :first-of-type {
      max-width: 45vw;
      width: 40vw;
    }
    > div {
      max-width: inherit;
      width: inherit;
    }

    > div > a {
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :nth-of-type(2) {
      width: 13vw;
      > span {
        width: 20vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    :nth-of-type(3),
    :nth-of-type(4),
    :nth-of-type(5) {
      width: 13vw;
    }
  }
`;

const New = styled(Badge)`
  display: inline-block;
  font-weight: normal;
  line-height: 1.5;
  margin-right: ${spacing.space_m};
  margin-left: ${spacing.space_m};
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.6 !important;
  padding: 0 ${spacing.space_default} 0 ${spacing.space_default};
`;

const UploadedBy = styled.td`
  > span > span > span {
    font-weight: normal;
  }
`;

const Display = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableContainer = styled.div`
  width: calc(100vw - 6em);
  height: calc(100vh - 11em);
  overflow: auto;
  position: relative;
  z-index: 0;
  margin-left: -${spacing.space_m};
`;

const StyledActions = styled.div`
  white-space: nowrap;
  display: none;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
`;

const StyledTableRow = styled.tr`
  > td > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &:hover ${StyledActions} {
    display: inline-flex;
  }
`;

const TableHeader = styled.thead`
  > tr > td {
    white-space: nowrap;

    :first-of-type {
      width: 40vw;
      max-width: 45vw;
      position: sticky;
      background: ${colors.brightLight};
      z-index: 30 !important;
    }

    :nth-of-type(2),
    :nth-of-type(3),
    :nth-of-type(4),
    :nth-of-type(5) {
      width: 13vw;
      position: sticky;
      background: ${colors.brightLight};
      z-index: 30 !important;
    }

    :first-of-type {
      left: 0px;
    }

    :nth-of-type(2),
    :nth-of-type(3),
    :nth-of-type(4),
    :nth-of-type(5) {
      box-shadow: 0.1px 2px 6px 0 rgba(0, 0, 0, 0.1);
    }

    > span:first-of-type {
      padding-left: 0.5em;
      position: relative;
      top: -0.2em;
      font-size: 1.1rem;
    }

    > span > svg:last-child {
      height: 7px;
      width: 7px;
      position: relative;
      top: 2px;
    }
  }
`;

const Time = styled.td`
  width: 13vw;
`;

export {
  Time,
  Table,
  UploadedBy,
  TableContainer,
  Display,
  New,
  Button,
  StyledActions,
  StyledTableRow,
  TableHeader
};
