// @flow

import React, { Fragment } from "react";

import DueDate from "../DueDate";
import Details from "../Details";
import Parent from "../Parent";

import type { RoomId } from "src/types";

type Props = {
  description: string,
  dueDate: ?string,
  parent: ?RoomId,
  handleParent: Function,
  handleDueDate: Function,
  handleChange: Function
};

const Advanced = ({
  parent,
  description,
  dueDate,
  handleDueDate,
  handleChange,
  handleParent
}: Props) => (
  <Fragment>
    <DueDate dueDate={dueDate} handleDueDate={handleDueDate} />
    <Details description={description} handleChange={handleChange} />
    <Parent parent={parent} handleParent={handleParent} />
  </Fragment>
);

export default Advanced;
