// @flow

import React from "react";

type Props = {};

const Layout = ({ ...restProps }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path d="M6 16L15 16C15.6 16 16 15.6 16 15V14C16 13.4 15.6 13 15 13L6 13C5.4 13 5 13.4 5 14V15C5 15.6 5.4 16 6 16Z" />
    <path d="M6 10L15 10C15.6 10 16 9.6 16 9V8C16 7.4 15.6 7 15 7L6 7C5.4 7 5 7.4 5 8V9C5 9.6 5.4 10 6 10Z" />
    <path d="M6 4L15 4C15.6 4 16 3.6 16 3V2C16 1.4 15.6 1 15 1L6 1C5.4 1 5 1.4 5 2V3C5 3.6 5.4 4 6 4Z" />
    <path d="M1 16H2C2.6 16 3 15.6 3 15L3 2C3 1.4 2.6 1 2 1H1C0.4 1 0 1.4 0 2L0 15C0 15.6 0.4 16 1 16Z" />
  </svg>
);

export default Layout;
