// @flow

import React from "react";

import User from "src/containers/user/NameOrEmail";
import type { UID } from "src/types";

type Props = {
  currentUserId: UID,
  uid: UID
};

const Me = ({ currentUserId, uid }: Props) =>
  currentUserId === uid ? <span>Me</span> : <User uid={uid} />;

export default Me;
