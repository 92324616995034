// @flow

import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as R from "ramda";
import moment from "moment";

import Common from "../Common";
import LinkedRoom from "../LinkedRoom";
import { StartComment } from "../styles";
import FilePreview from "../FileOrEmail/FilePreview";
import DirectMessage from "./DirectMessage";
import { ImageWrapper, PreviewWrapper, TextWrapper } from "./styles";
import { Name as StyledName } from "src/components/messages/Chat/styles";
import {
  getCurrentUserId,
  getChatroomAddress,
  getCurrentRoomId,
  getChatroomType,
  getChatroomTitle,
  getFile,
  getFilePreviewURL
} from "src/reducers";
import { getFilePreview } from "src/actions/file";
import MessageText from "src/components/messages/MessageText";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { getAuthorColor } from "src/utils";
import { getFilePreviewSupport } from "src/utils/file";
import type {
  Message,
  ChatroomAddress,
  UID,
  RoomId,
  UnifizeFile
} from "src/types";

type Props = {
  roomId: RoomId,
  preview: ?Object,
  message: Message,
  type: string,
  title: string,
  address: ChatroomAddress,
  currentUser: UID,
  currentRoomAddress: RoomId,
  file: ?UnifizeFile,
  _fetchMessageRange: Function
};

const Start = ({
  roomId,
  preview,
  message,
  address,
  type,
  title,
  currentUser,
  currentRoomAddress,
  file
}: Props) => {
  const name = message?.data?.origin?.data?.name;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      file &&
      getFilePreviewSupport(file) &&
      (!preview || moment().diff(preview?.date, "minutes") > 10) &&
      name
    ) {
      dispatch(getFilePreview({ name, roomId }));
    }
  }, [file, name, preview, roomId]);

  const returnComment = useCallback(() => {
    const { origin } = message.data;
    if (origin) {
      const { author, text } = message.data.origin;
      const isCurrentUser = currentUser === author;
      return (
        <StartComment>
          {text ? (
            <>
              <StyledName
                hashColor={getAuthorColor(author, currentRoomAddress)}
              >
                {isCurrentUser ? (
                  <span>You</span>
                ) : (
                  <NameOrEmail uid={author} />
                )}
              </StyledName>
              <MessageText message={{ ...message, text }} />
            </>
          ) : null}

          {origin.data && file && (
            <ImageWrapper>
              <TextWrapper>
                <StyledName
                  hashColor={getAuthorColor(author, currentRoomAddress)}
                >
                  {isCurrentUser ? (
                    <span>You</span>
                  ) : (
                    <NameOrEmail uid={author} />
                  )}
                </StyledName>
                Photo
              </TextWrapper>
              <PreviewWrapper>
                <FilePreview preview={preview?.url} />
              </PreviewWrapper>
            </ImageWrapper>
          )}
        </StartComment>
      );
    }
    return "";
  }, [message]);

  const { data } = message;
  const { id } = data;
  const isCurrentRoom = id === parseInt(roomId, 10);

  if (type === "direct" && isCurrentRoom) {
    const otherUser = R.head(
      R.reject(uid => uid === currentUser, (title || "").split(","))
    );
    return <DirectMessage uid={otherUser} />;
  }

  if (isCurrentRoom)
    return (
      <Common
        {...message}
        comment={returnComment()}
        dataCy="conversationStartNotification"
      >
        {" "}
        started this conversation
      </Common>
    );
  return (
    <Common {...message} comment={returnComment()}>
      {" "}
      started <LinkedRoom id={id} chatroomAddress={address} />
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => ({
  file: getFile(app, message?.data?.origin?.data?.name),
  preview: getFilePreviewURL(app, message?.data?.origin?.data?.name),
  address: getChatroomAddress(app, message.data.id),
  currentUser: getCurrentUserId(app),
  type: getChatroomType(app, message.data.id),
  title: getChatroomTitle(app, message.data.id),
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps)(Start);
