// @flow
import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Error } from "./styles";

const ErrorLine = ({ error = "Unknown error occured" }: { error?: string }) => (
  <Error>
    <InfoOutlineIcon />
    {error}
  </Error>
);

export default ErrorLine;
