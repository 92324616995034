// @flow

import * as React from "react";
import { useRef, useState, useEffect } from "react";
import * as R from "ramda";

import useClickoutside from "src/hooks/useClickoutside";
import useAutoPosition from "src/hooks/useAutoPosition";
import { useDebounce } from "use-debounce";

import { List, ListItem, Checkbox, useStyles, Input } from "@chakra-ui/react";
import { Card } from "src/styles/card";
import * as colors from "src/styles/constants/colors";
import VirtualList from "src/components/VirtualList";

type ItemPredicate = ({
  item: React.Node,
  inputValue: string
}) => boolean;

type Props = {
  multiple: boolean,
  value: string | number | Array<string | number> | null,
  items: React.Node,
  outerRef: any,
  listProps?: Object,
  handleSelect: Function,
  handleClose: Function,
  onSelectAll: ?Function,
  onClearAll: ?Function,
  itemPredicate: ?ItemPredicate,
  customSearchHandler?: Function
};

const Dropdown = ({
  multiple,
  value,
  items,
  outerRef,
  handleSelect,
  handleClose,
  listProps,
  onSelectAll,
  onClearAll,
  itemPredicate,
  customSearchHandler,
  ...restProps
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearchQuery] = useDebounce(inputValue, 600);

  useEffect(() => {
    if (customSearchHandler) {
      customSearchHandler(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  const styles = useStyles();

  const dropdownRef = useRef(null);
  useClickoutside({ outerRef, handleClose, disableEnter: true });

  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "2.3em",
    initialBottom: "auto",
    topOffset: "2.3em",
    bottomOffset: "2em"
  });

  const arrayOfItems = React.Children.toArray(items);

  const filteredItems =
    itemPredicate && inputValue.length > 0
      ? arrayOfItems.filter(item =>
          R.has("value", item.props) && R.has("label", item.props)
            ? itemPredicate({
                item,
                inputValue: inputValue.trim()
              })
            : true
        )
      : arrayOfItems;

  const renderRow = index => {
    const item = filteredItems[index];

    if (!item) {
      return;
    }

    const itemProps = item.props;
    const isSelected = Array.isArray(value)
      ? value.includes(itemProps.value)
      : itemProps.value === value;

    if (multiple) {
      return (
        <ListItem
          key={itemProps.value}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSelect(itemProps.value);
          }}
          {...styles.listItem}
        >
          <Checkbox isChecked={isSelected}>{item}</Checkbox>
        </ListItem>
      );
    }

    return (
      <ListItem
        key={itemProps.value}
        onClick={() => handleSelect(itemProps.value)}
        {...styles.listItem}
        bg={isSelected ? colors.grey4 : "transparent"}
        _hover={{
          bg: isSelected ? colors.grey4 : colors.grey1
        }}
      >
        {item}
      </ListItem>
    );
  };

  return (
    <Card top={top} bottom={bottom} {...styles.dropdownCard} {...restProps}>
      <List {...styles.list} spacing={2} {...listProps}>
        {itemPredicate || customSearchHandler ? (
          <Input
            size="sm"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        ) : null}

        {multiple && onSelectAll && (
          <ListItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onSelectAll();
            }}
            {...styles.listItem}
          >
            Select all
          </ListItem>
        )}

        {multiple && onClearAll && (
          <ListItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClearAll();
            }}
            {...styles.listItem}
          >
            Clear all
          </ListItem>
        )}

        <VirtualList
          initialAmount={20}
          progressiveAmount={20}
          role="list"
          renderItem={renderRow}
          renderLoader={() => <div />}
          rowCount={filteredItems.length}
        />
      </List>
    </Card>
  );
};

export default Dropdown;
