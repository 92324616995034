// @flow

import React from "react";
import { connect } from "react-redux";

import { Overlay as StyledOverlay } from "src/styles";

import type { AppState } from "src/types";

type Props = {
  chatroomOverlay: ?string,
  headerHeight: number,
  participants: ?string,
  memberAddDialog: ?string
};

const Overlay = ({
  headerHeight,
  participants,
  chatroomOverlay,
  memberAddDialog
}: Props) => {
  if (
    participants === "chatroom-header" ||
    chatroomOverlay === "message" ||
    memberAddDialog === "chatroom-header"
  ) {
    return <StyledOverlay height={headerHeight} />;
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  headerHeight: app.header.height,
  participants: app.header.participants,
  memberAddDialog: app.memberAddDialog,
  chatroomOverlay: app.header.chatroomOverlay
});

export default connect(mapStateToProps)(Overlay);
