// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import Link from "redux-first-router-link";
import moment from "moment";

import {
  New,
  Button,
  StyledActions,
  StyledTableRow
} from "src/components/Manage/Reports/Templates/Table/styles";
import Time from "./Time";
import type { AppState, Report } from "src/types";
import * as atypes from "src/constants/actionTypes";
import * as status from "src/constants/status";
import * as colors from "src/styles/constants/colors";
import Icon from "src/icons";
import { getAllReports } from "src/reducers";
import { reportModalAttributes } from "src/actions/reports";
import ImageLabel from "src/containers/user/ImageLabel/Medium";

type Props = {
  allReports: Array<Report>,
  _reportModalAttributes: Function
};

const Body = ({ allReports, _reportModalAttributes }: Props) => {
  const handleEdit = useCallback(
    (report: Report) => {
      _reportModalAttributes({
        modalType: "update",
        loading: false,
        title: report.title,
        id: report.id
      });
    },
    [_reportModalAttributes]
  );

  const handleDelete = useCallback(
    (report: Report) => {
      _reportModalAttributes({
        modalType: "delete",
        loading: false,
        title: report.title,
        id: report.id
      });
    },
    [_reportModalAttributes]
  );

  return allReports && allReports.length > 0 ? (
    <tbody>
      {allReports.map(report => {
        if (!report.id) return null;
        return (
          <StyledTableRow key={report.id}>
            <td>
              <div>
                <Link
                  key={report.id}
                  to={{
                    type: atypes.SET_REPORTS_REQUEST,
                    payload: {
                      id: report.id
                    }
                  }}
                >
                  {report.title}
                </Link>
                {moment()
                  .startOf("day")
                  .diff(
                    moment.unix(report.createdAt.seconds).startOf("day"),
                    "days"
                  ) === 0 ? (
                  <New status={status.NEW}> NEW</New>
                ) : null}
                <StyledActions>
                  <Button onClick={() => handleEdit(report)}>
                    <Icon
                      type="edit"
                      fill={colors.active}
                      color={colors.active}
                    />
                    <span>Edit Report Title</span>
                  </Button>
                  <Button onClick={() => handleDelete(report)}>
                    <Icon type="delete" color={colors.danger} />
                    <span>Delete</span>
                  </Button>
                </StyledActions>
              </div>
            </td>
            <Time seconds={report.createdAt.seconds} />
            <Time seconds={report.updatedAt.seconds} />
            <td>
              <ImageLabel uid={report.creator} />
            </td>
          </StyledTableRow>
        );
      })}
    </tbody>
  ) : null;
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const title = app.workflow.additionalFilters?.title || "";
  return {
    allReports: getAllReports(app, title)
  };
};

export default connect(mapStateToProps, {
  _reportModalAttributes: reportModalAttributes
})(Body);
