// @flow

import React, { useState, useEffect, useCallback } from "react";

import { Search as StyledSearch } from "./styles";
import { GreyInput } from "src/styles/input";

type Props = {
  search: string,
  handleSearch: Function
};

const Search = ({ search, handleSearch }: Props) => {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(search);
  }, [search]);

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
      handleSearch(value || "");
    },
    [text]
  );

  return (
    <StyledSearch>
      <GreyInput
        placeholder="Search"
        type="text"
        value={text}
        onChange={handleChange}
      />
    </StyledSearch>
  );
};

export default Search;
