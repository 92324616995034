// @flow

import { createSelector } from "reselect";
import { combineReducers } from "redux";
import { Map as ImmutableMap, List } from "immutable";

import { memoizeByHashCode } from "src/utils";
import * as atypes from "src/constants/actionTypes";

import type {
  Statuses,
  Action,
  StatusById,
  StatusSearchResult
} from "src/types";

const byId = (
  state: StatusById = ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_STATUSES_SUCCESS:
      return state.merge(payload.statuses);
    default:
      return state;
  }
};

const searchResult = (
  state: StatusSearchResult = List(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_STATUS_SUCCESS:
      return List(payload.result);
    default:
      return state;
  }
};

export default combineReducers<Object, Action>({ byId, searchResult });

export const getSearchedStatus = (state: Statuses) =>
  memoizeByHashCode()(state.searchResult);

export const getStatus = (state: Statuses, id: string) =>
  memoizeByHashCode()(state.byId.get(id));

export const getStatusTitle = createSelector(getStatus, (status: Object) =>
  status ? status.get("title") : ""
);
