// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

import { PulseContainer } from "src/components/PulseLoader/styles";

export const MainPlaceholder = styled.div`
  background: ${colors.appBackground};
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const LoaderContainer = styled.div`
  > button {
    background: ${colors.appBackground};
    border: 0;
    cursor: initial;
    > div > div {
      background: ${colors.active};
    }
    :hover {
      border: 0;
      box-shadow: initial;
    }
  }
`;

export const InfoPlaceholder = styled.div`
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${colors.secondary}4d;
  height: 100vh;
`;

export const HeaderPlaceholder = styled.div`
  background: ${colors.brightLight};
  display: flex;
  flex-direction: column;
  height: 90px;
  justify-content: flex-end;
  padding: 0 28px;
  border-bottom: 1px solid ${colors.muted};
`;

export const ChatblockHeaderPlaceholder = styled(HeaderPlaceholder)`
  height: fit-content;
  width: 100%;
  border-style: solid;
  border-color: ${colors.outline};
  border-width: ${props => (props.highlight ? "0.25em" : "0.09em")};
  border-radius: ${radius.small};
  padding: 8px;
  margin: 0;

  gap: 10px;
  justify-content: space-around;

  ${PulseContainer} {
    margin: 0;
    border-radius: ${radius.small};
  }
`;

export const MetaDataRow = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

export const DockHeaderPlaceholder = styled(HeaderPlaceholder)`
  justify-content: center;
  padding-top: 18px;
`;

export const DockBodyPlaceholder = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

export const HistoryPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 28px 28px 0 28px;
`;

export const InputPlaceholder = styled.div`
  padding: 20px 28px;
  width: 100%;
  > div {
    width: 100%;
    height: 40px;
    border-radius: ${radius.large};
    background: ${colors.brightLight};
  }
`;

export const ChatPlaceholder = styled.div`
  padding-right: 50px;
  display: flex;
  > div:first-of-type {
    width: 34px;
    height: 34px;
    border-radius: ${radius.large};
    background: ${colors.grey2};
    margin-right: 12px;
  }
  > div:nth-of-type(2) {
    flex: 1;
    max-width: 560px;
  }
`;

export const HeaderWidgets = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
  }
`;
