// @flow

import React from "react";
import type { Node } from "react";

import { Button } from "@chakra-ui/react";

type Props = {
  leftIcon: Node,
  onClick: Function,
  children: Node
};

const BlueOutline = ({ leftIcon, onClick, children }: Props) => {
  return (
    <Button
      leftIcon={leftIcon}
      onClick={onClick}
      borderColor="unifize_blue"
      backgroundColor="unifize_brightLight"
      color="unifize_blue"
      size="xs"
      variant="outline"
    >
      {children}
    </Button>
  );
};

export default BlueOutline;
