// @flow

import React, { useState, useRef, useCallback } from "react";
import onClickOutside from "react-onclickoutside";

import { NewRoom as StyledNewRoom } from "./styles";
import AddConversation from "./AddConversation";
import { Input } from "src/styles/input";

import type { ConversationChecklistSettings } from "src/types";

type Props = {
  settings: ConversationChecklistSettings,
  handleClose: Function,
  handleCreate: Function,
  isMandatory: ?boolean
};

const NewRoom = ({
  settings,
  handleClose,
  handleCreate,
  isMandatory
}: Props) => {
  const outerRef = useRef(null);

  NewRoom.handleClickOutside = useCallback(
    (event: any) => {
      if (outerRef && outerRef.current) {
        if (!outerRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  const [text, setText] = useState("");

  const handleChange = useCallback(
    (event: any) => {
      setText(event.target.value);
    },
    [setText]
  );

  const handleKeydown = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleCreate(text);
      }
    },
    [text, handleCreate]
  );

  return (
    <StyledNewRoom ref={outerRef}>
      <Input
        type="text"
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        placeholder="Enter optional title"
        autoFocus
      />
      <AddConversation
        settings={settings || {}}
        handleDropdown={() => handleCreate(text)}
        isActive
        isMandatory={isMandatory}
      />
    </StyledNewRoom>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => NewRoom.handleClickOutside
};

export default onClickOutside(NewRoom, clickOutsideConfig);
