// @flow

import React from "react";
import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { TabArea, Tab } from "src/styles";

type Props = {
  tab: string
};

const Tabs = ({ tab }: Props) => {
  if (tab === "people") {
    return (
      <TabArea>
        <Tab active>People</Tab>
        <Link to={{ type: atypes.SET_GROUPS_REQUEST, payload: {} }}>
          <Tab>Groups</Tab>
        </Link>
      </TabArea>
    );
  }

  return (
    <TabArea>
      <Link
        to={{
          type: atypes.SET_CONTACTS_REQUEST,
          meta: {
            query: {
              sort: ["displayName:asc"],
              status: ["Active", "Pending"]
            }
          }
        }}
      >
        <Tab>People</Tab>
      </Link>
      <Tab active>Groups</Tab>
    </TabArea>
  );
};

export default Tabs;
