// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import Modal from "react-modal";
import * as R from "ramda";

import { New as StyledNew, Form, Label, FormGroup, Footer } from "./styles";
import Picture from "./Picture";
import Lock from "./Lock";
import {
  createGroup,
  setGroupModalAttributes,
  editGroups
} from "src/actions/group";
import { getParent } from "src/utils";
import { Input } from "src/styles/input";
import Button from "src/components/ButtonLoader";
import UserSelectMultiple from "src/containers/user/SelectMultiple";
import Close from "src/components/Close";
import { Error } from "src/styles";
import type { AppState, UID, GroupModal } from "src/types";

type Props = {
  newGroup: GroupModal,
  handleClose: Function,
  _createGroup: Function,
  _editGroups: Function,
  _setGroupModalAttributes: Function
};

const New = ({
  handleClose,
  newGroup,
  _createGroup,
  _editGroups,
  _setGroupModalAttributes
}: Props) => {
  const {
    title,
    members,
    owners,
    locked,
    error,
    image,
    loading,
    edit,
    photoUrl
  } = newGroup;

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (edit) {
        _editGroups();
      } else {
        _createGroup();
      }
    },
    [_createGroup, _editGroups, edit]
  );

  const handleMembers = useCallback(
    (member: UID) => {
      if (R.includes(member, members)) {
        _setGroupModalAttributes({
          members: R.reject(R.equals(member), members)
        });
      } else {
        _setGroupModalAttributes({ members: R.uniq([...members, member]) });
      }
    },
    [members, _setGroupModalAttributes]
  );

  const handleLock = useCallback(() => {
    _setGroupModalAttributes({ locked: !locked });
  }, [locked, _setGroupModalAttributes]);

  const handleOwners = useCallback(
    (owner: UID) => {
      if (R.includes(owner, owners)) {
        _setGroupModalAttributes({ owners: R.reject(R.equals(owner), owners) });
      } else {
        _setGroupModalAttributes({ owners: R.uniq([...owners, owner]) });
      }
    },
    [owners, _setGroupModalAttributes]
  );

  const handleTitle = useCallback(
    (e: any) => {
      const { value } = e.target;
      _setGroupModalAttributes({ title: value });
    },
    [_setGroupModalAttributes]
  );

  const handlePicture = useCallback(
    (file: ?Object) => {
      _setGroupModalAttributes({ image: file, photoUrl: null });
    },
    [_setGroupModalAttributes]
  );

  return (
    <StyledNew id="newgroup">
      <Modal
        isOpen
        parentSelector={() => getParent("#newgroup")}
        shouldCloseOnOverlayClick={false}
      >
        <Form onSubmit={handleSubmit}>
          <h3>{edit ? "Edit Group" : "Create New Group"}</h3>
          <Close handleClose={handleClose} />
          <Picture
            image={image}
            photoUrl={photoUrl}
            handlePicture={handlePicture}
          />
          <FormGroup>
            <Label>Group Name</Label>
            <Input
              placeholder="Enter Group Name"
              value={title}
              onChange={handleTitle}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Add Participants</Label>
            <UserSelectMultiple
              value={members}
              handleChange={handleMembers}
              placeholder="Add Participants"
            />
          </FormGroup>
          <FormGroup>
            <Label>Group Owners</Label>
            <UserSelectMultiple
              value={owners}
              handleChange={handleOwners}
              placeholder="Add owners"
            />
          </FormGroup>
          <FormGroup>
            <Lock locked={locked} handleLock={handleLock} />
          </FormGroup>
          {error ? <Error>{error}</Error> : null}
          <Footer>
            <Button type="submit" isLoading={!error && loading}>
              {edit ? "Save" : "Create Group"}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </StyledNew>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newGroup: app.groups.modal
});

export default connect(mapStateToProps, {
  _createGroup: createGroup,
  _editGroups: editGroups,
  _setGroupModalAttributes: setGroupModalAttributes
})(New);
