// @flow

import React from "react";
import * as R from "ramda";

import Item from "./Item";
import { List } from "src/components/Dock/Checklist/Conversation/styles";
import useClickoutside from "src/hooks/useClickoutside";

type Props = {
  outerRef: any,
  searchedForms: Array<number>,
  selectForm: Function,
  handleClose: Function
};

const Dropdown = ({
  outerRef,
  searchedForms,
  selectForm,
  handleClose
}: Props) => {
  useClickoutside({ outerRef, handleClose });

  return (
    <List>
      <ul tabIndex="-1">
        {R.map(
          id => (
            <Item key={id} id={id} selectForm={selectForm} />
          ),
          searchedForms || []
        )}
      </ul>
    </List>
  );
};

export default Dropdown;
