// @flow

import { combineReducers } from "redux";
import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const invitedUser = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SEND_USER_INVITE_SUCCESS:
      return payload ? payload.user.uid : state;
    case atypes.CLEAR_INVITED_USER:
      return null;
    default:
      return state;
  }
};

const existingUser = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.JOIN_ORG_SUCCESS:
      return payload ? payload.existingUser || false : false;
    default:
      return state;
  }
};

const invitedOrg = (state: ?number = null, { type, payload, meta }: Action) => {
  switch (type) {
    case atypes.JOIN:
      const params = meta ? meta.query : {};
      return parseInt(params.orgId, 10);
    case atypes.JOIN_ORG_REQUEST:
      return payload.orgId;
    case atypes.GET_CURRENT_ORG_SUCCESS:
      return null;
    case atypes.SET_CURRENT_ORG_SRW:
      return null;
    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  invitedUser,
  existingUser,
  invitedOrg
});
