// @flow

import { connect } from "react-redux";
import React, { useCallback, useState } from "react";

import { setReports } from "src/actions/reports";
import Header from "src/components/SearchHeader.old";

import type { AppState } from "src/types";

type Props = {
  reportId: number,
  _setReports: typeof setReports
};

const MenuBar = ({ reportId, _setReports }: Props) => {
  const [text, setText] = useState("");

  const handleSearch = useCallback(
    (newValue: string) => {
      setText(newValue);
      _setReports(reportId, {
        query: {
          title: newValue
        }
      });
    },
    [_setReports]
  );

  return (
    <Header addItem={false} handleChange={handleSearch} text={text} instant />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const title = app.workflow.additionalFilters?.title || "";
  return {
    title
  };
};

export default connect(mapStateToProps, {
  _setReports: setReports
})(MenuBar);
