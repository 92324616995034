// @flow

import React, { Component } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import User from "src/containers/user.old";
import Dropdown from "src/styles/dropdown";
import Checkbox from "src/components/Checkbox.old";
import { Search } from "src/styles/input.old";
import type { UID } from "src/types";

type Props = {
  userList: Array<UID>,
  selectedUser: Array<UID>,
  exclude: ?UID,
  outerRef: any,
  closeUserList: Function,
  toggleMembers: Function,
  searchUsers: Function
};

type State = {
  searchText: string
};

class UserSearchMultiple extends Component<Props, State> {
  input: any;
  debounceTimeout: TimeoutID | null;

  state = {
    searchText: ""
  };

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleClickOutside = (e: MouseEvent) => {
    if (e) {
      const { closeUserList, outerRef } = this.props;
      if (outerRef) {
        if (!outerRef.contains(e.target)) {
          closeUserList();
        }
      }
    }
  };

  debouncedSearch = (value: string) => {
    const { searchUsers } = this.props;
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      searchUsers(value);
    }, 800);
  };

  search = (event: any) => {
    event.preventDefault();
    const { value } = event.target;
    this.setState({ searchText: value });
    this.debouncedSearch(value);
  };

  toggleMembers(e: any, uid: string) {
    e.preventDefault();
    const { toggleMembers } = this.props;
    toggleMembers(uid);
  }

  render() {
    const { userList, exclude, selectedUser } = this.props;
    const { searchText } = this.state;

    return (
      <Dropdown>
        <Search
          type="text"
          value={searchText}
          onChange={this.search}
          ref={input => {
            this.input = input;
          }}
          autoComplete="off"
          placeholder="Search users"
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        <ul>
          {userList
            .filter(uid => uid !== exclude && uid)
            .map(uid => (
              <li
                key={uid}
                tabIndex="0"
                onClick={e => this.toggleMembers(e, uid)}
              >
                <Checkbox
                  id={uid}
                  checked={R.includes(uid, selectedUser)}
                  handleChange={e => this.toggleMembers(e, uid)}
                >
                  <User uid={uid} type="userWithDisplayName" />
                </Checkbox>
              </li>
            ))}

          {userList.length === 0 ? <li>User not found</li> : null}
        </ul>
      </Dropdown>
    );
  }
}

export default onClickOutside(UserSearchMultiple);
