// @flow

import React from "react";

import { useSelector } from "react-redux";
import { NewValue, ValueList } from "../styles";
import LinkedRoom from "src/components/messages/Chat/Notification/LinkedRoom";
import { getChecklistNotificationDiff } from "src/utils";
import { getChatRoomsById } from "src/reducers";

import type { RoomId } from "src/types";

type Props = {
  oldValue: Array<RoomId>,
  newValue: Array<RoomId>,
  disableTitleHyperlink: boolean
};

const ConversationDiffValue = ({
  newValue,
  oldValue,
  disableTitleHyperlink
}: Props) => {
  const diff = getChecklistNotificationDiff(newValue, oldValue || []);
  const chatRooms = useSelector(({ app }) => getChatRoomsById(app));
  const newAddresses = newValue.map(id => chatRooms[id]) || [];

  return (
    <>
      {diff.length > 0 && (
        <NewValue>
          <ValueList>
            {diff.map((val, id) => (
              <LinkedRoom
                id={`${val}`}
                key={id}
                chatroomAddress={newAddresses[id]}
                disableTitleHyperlink={disableTitleHyperlink}
              />
            ))}
          </ValueList>
        </NewValue>
      )}
    </>
  );
};

export default ConversationDiffValue;
