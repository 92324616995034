// @flow

import styled from "@emotion/styled";

import { InlineFormGroup } from "src/styles/form";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";
import * as shadow from "src/styles/constants/shadow";

const Header = styled.div`
  margin: 0px;
  padding: ${spacing.space_l} ${spacing.space_m} ${spacing.space_m}
    ${spacing.space_m};
  box-shadow: ${shadow.defaultShadow};
  border-bottom: 1px solid ${colors.grey0};
`;

const Org = styled.div`
  line-height: 1.125rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${colors.backgroundText};
  flex: 1;
`;

const TitleWrapper = styled.div`
  margin-bottom: ${spacing.space_m};
  margin-top: ${spacing.space_m};
  display: flex;
  min-height: ${spacing.space_xl};
  border: solid ${spacing.space_xs} ${colors.grey12};
  border-radius: ${radius.medium};
  align-items: center;
  cursor: pointer;
`;

const OrgWrapper = styled.div`
  display: flex;
  height: ${spacing.space_xl};
  align-items: center;
`;

const Title = styled.h3`
  margin: 0px;
  display: flex;
  align-items: center;
  min-height: ${spacing.space_xl};
  flex: 2;
  width: 100%;
  padding-right: ${spacing.space_xs};
  justify-content: space-between;

  > svg {
    cursor: pointer;
  }
  flex-direction: row;

  > span {
    font-size: 1.25rem;
  }

  > div {
    display: flex;
    align-items: center;
    > span {
      margin-left: ${spacing.space_m};
      text-transform: capitalize;
      min-width: 2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > div {
      display: flex;
      cursor: pointer;
    }
  }

  > div > span {
    color: ${colors.dark};
  }
`;

const Count = styled.div`
  color: ${colors.secondary};
  font-size: 0.88rem;
  letter-spacing: 0.15px;
  font-weight: ${typography.light};
  align-self: center;
  margin-left: ${spacing.space_s};
  padding-top: ${spacing.space_xs};
`;

const SearchbarIconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
    cursor: pointer;
    
    > div > svg > g {
      stroke: ${colors.secondary};
`;

const Search = styled.div`
  padding: 0px 0px 0px ${spacing.space_s};
  background: ${colors.brightLight};
  border: solid 1px ${colors.grey2};
  border-radius: ${radius.medium};
  height: ${spacing.space_xl};
  display: flex;
  align-items: center;
  justify-content: space-between;

  :focus-within {
    border: ${spacing.space_xs} solid ${colors.activeFilter};
    ${SearchbarIconWrapper} {
      margin-right: -${spacing.space_xs};
    }
  }
`;

const Clear = styled.div`
  > svg {
    cursor: pointer !important;
    padding: 5px;
  }
`;

const SearchWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled.input`
  border: none;
  font-size: 1rem;
  color: ${colors.primary} !important;
  font-weight: ${typography.light} !important;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder {
    font-size: 1rem;
    padding-left: 3px;
  }
`;

const FormGroup = styled(InlineFormGroup)`
  padding: 0px;
`;

export {
  Header,
  Org,
  TitleWrapper,
  Title,
  Search,
  FormGroup,
  OrgWrapper,
  StyledInput,
  SearchWrapper,
  Clear,
  SearchbarIconWrapper,
  Count
};
