// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import usePortal from "react-useportal";

import { DeleteModal as StyledDeleteModal, PrimaryButton } from "./styles";
import { Overlay } from "src/styles/modal";
import Icon from "src/components/Icon";
import * as colors from "src/styles/constants/colors";
import { Form, SecondaryFooter } from "src/styles/form";
import { Secondary } from "src/styles/buttons";
import {
  FormGroup,
  ModalFormBody
} from "src/components/chatroom-nav/filter/styles";
import { deleteDashboard } from "src/actions/dashboard";
import { iconSize } from "src/styles/constants/size";
import ButtonLoader from "src/components/ButtonLoader";

import type { AppState } from "src/types";

type Props = {
  id: number,
  title: string,
  handleClose: Function,
  _deleteDashboard: Function
};

const DeleteModal = ({ id, title, handleClose, _deleteDashboard }: Props) => {
  const { Portal } = usePortal();

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      _deleteDashboard(id);
      handleClose();
    },
    [handleClose]
  );

  return (
    <Portal>
      <Overlay />
      <StyledDeleteModal>
        <Form onSubmit={handleSubmit}>
          <h4>
            Delete Dashboard
            <Icon
              icon="close"
              color={colors.primary}
              onClick={handleClose}
              size={iconSize.space_l}
            />
          </h4>
          <ModalFormBody>
            <FormGroup>
              <div>
                Are you sure you want to delete <strong>{title}</strong>
              </div>
              <div>Pinned charts will not be deleted.</div>
            </FormGroup>
          </ModalFormBody>
          <SecondaryFooter>
            <Secondary type="button" onClick={handleClose}>
              Cancel
            </Secondary>
            <PrimaryButton delete>
              <ButtonLoader
                type="submit"
                isLoading={false}
                color={colors.danger}
              >
                Delete
              </ButtonLoader>
            </PrimaryButton>
          </SecondaryFooter>
        </Form>
      </StyledDeleteModal>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  title: app.dashboard.metaData.title
});

export default connect(mapStateToProps, { _deleteDashboard: deleteDashboard })(
  DeleteModal
);
