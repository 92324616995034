// @flow

import moment from "moment";
import React, { useCallback } from "react";

import Select from "./Select";
import { Range as StyledRange } from "./styles";

type Props = {
  value: ?string,
  handleChange: Function
};

const Range = ({ value, handleChange }: Props) => {
  let start = null;
  let end = null;

  const range = value ? value.split("::") : [];
  if (range.length === 2) {
    start = range[0];
    end = range[range.length - 1];
  }

  const handleStartValue = useCallback(
    newValue => {
      handleChange(`${moment(newValue).format("YYYY-MM-DD")}::${end || ""}`);
    },
    [end, handleChange]
  );

  const handleEndValue = useCallback(
    newValue => {
      handleChange(`${start || ""}::${moment(newValue).format("YYYY-MM-DD")}`);
    },
    [start, handleChange]
  );

  return (
    <StyledRange>
      <Select value={start} handleChange={handleStartValue} />
      <span>to</span>
      <Select value={end} handleChange={handleEndValue} />
    </StyledRange>
  );
};

export default Range;
