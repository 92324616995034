// @flow
import React from "react";

const UploadBlue = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MdOutlineFileUpload">
        <path
          id="Vector"
          d="M14.9999 12.5002V15.0002H4.99992V12.5002H3.33325V15.0002C3.33325 15.9168 4.08325 16.6668 4.99992 16.6668H14.9999C15.9166 16.6668 16.6666 15.9168 16.6666 15.0002V12.5002H14.9999ZM5.83325 7.50016L7.00825 8.67516L9.16658 6.52516V13.3335H10.8333V6.52516L12.9916 8.67516L14.1666 7.50016L9.99992 3.3335L5.83325 7.50016Z"
          fill="#1581BA"
        />
      </g>
    </svg>
  );
};

export default UploadBlue;
