// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  value: boolean,
  handleValue: Function
};

const DataProcess = ({ value, handleValue }: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox id="data" checked={value} handleChange={handleValue} />
      Convert to data process
    </Label>
  </InlineFormGroup>
);

export default DataProcess;
