// @flow

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatRoom } from "src/reducers";
import { fetchConversation } from "src/actions/chatroom";
import type { RoomId } from "src/types";

const useChatroom = (roomId: RoomId) => {
  const dispatch = useDispatch();
  const chatroom = useSelector(({ app }) => getChatRoom(app, roomId));

  useEffect(() => {
    if (!chatroom) {
      dispatch(fetchConversation(roomId));
    }
  }, [chatroom, roomId, dispatch]);

  return chatroom;
};

export default useChatroom;
