// @flow

import * as atypes from "src/constants/actionTypes";
import type {
  OrgCloneWizardFields,
  OrgCloneWizardSteps,
  OrgCloneTemplateData
} from "src/types";

export const updateData = (payload: {
  prop: string[],
  value: string | string[]
}) => ({
  type: atypes.ORG_CLONE_WIZARD_UPDATE_DATA,
  payload
});

export type ClearDataErrorAction = (
  payload: OrgCloneWizardFields[]
) => {
  type: typeof atypes.ORG_CLONE_CLEAR_DATA_ERROR,
  payload: OrgCloneWizardFields[]
};

export const clearDataError: ClearDataErrorAction = payload => ({
  type: atypes.ORG_CLONE_CLEAR_DATA_ERROR,
  payload
});

export const goForwardRequest = () => ({
  type: atypes.ORG_CLONE_WIZARD_GO_FORWARD_REQUEST
});

export const goForwardError = (payload: {
  path?: Array<string | number>,
  error: string
}) => ({
  type: atypes.ORG_CLONE_WIZARD_GO_FORWARD_ERROR,
  payload: {
    path: payload.path || [],
    error: payload.error
  }
});

export const goBackwardRequest = () => ({
  type: atypes.ORG_CLONE_WIZARD_GO_BACKWARD_REQUEST
});

export const setStep = (payload: OrgCloneWizardSteps) => ({
  type: atypes.ORG_CLONE_WIZARD_SET_STEP,
  payload
});

export const fetchTemplateRequest = () => ({
  type: atypes.ORG_CLONE_FETCH_TEMPLATE_REQUEST
});

export const fetchTemplateSuccess = (payload: OrgCloneTemplateData) => ({
  type: atypes.ORG_CLONE_FETCH_TEMPLATE_SUCCESS,
  payload
});

export const fetchTemplateFailure = (payload: { error: string }) => ({
  type: atypes.ORG_CLONE_FETCH_TEMPLATE_FAILURE,
  payload
});

export const logout = () => ({
  type: atypes.ORG_CLONE_LOGOUT_REQUEST
});
