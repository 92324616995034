// @flow

import styled from "@emotion/styled";

import { Overlay as StyledOverlay } from "src/styles/modal";
import {
  SecondaryFooter as StyledSecondaryFooter,
  Form as StyledForm
} from "src/styles/form";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";

export const Content = styled.div`
  z-index: 999;
  background-color: ${colors.brightLight};
  min-width: 355px;
  border-radius: ${radius.medium};
  position: initial;

  @media (max-width: 768px) {
    min-width: 100px;
  }
`;

export const Overlay = styled(StyledOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrimaryButton = styled.span`
  > button {
    height: 100%;
    background-color: ${props =>
      props.delete || props.reminder
        ? colors.danger
        : props.disabled
        ? colors.skyDark
        : colors.blue};
    border: 1px solid transparent;
    font-size: 1rem;
    font-weight: ${typography.semiBold};
    line-height: 1.5rem;
    border-radius: ${radius.medium};
    padding: ${spacing.space_s} 0.56em;
    margin: 0px;
    outline: none;
    margin-left: 0.5em;
    :hover {
      border: 1px solid transparent;
    }
  }
`;

export const Secondary = styled(PrimaryButton)`
  border: none;
  font-size: 1rem;
  font-weight: ${typography.semiBold};
  line-height: 1.5rem;
  border-radius: ${radius.medium};
  padding: ${spacing.space_s} 0.56em;
  outline: none;
  border: none;
  background-color: ${colors.sky};
  color: ${colors.ink};
  cursor: pointer;
  margin-left: 0.5em;
`;

export const ModalText = styled.div`
  font-size: 14px;
  line-height: 1rem;
  color: ${colors.ink};
  margin: ${spacing.space_l} ${spacing.space_m} !important;
`;

export const SecondaryFooter = styled(StyledSecondaryFooter)`
  background-color: ${colors.brightLight};
  border-radius: ${radius.medium};
  justify-content: flex-start;
  padding: 0px ${spacing.space_l} 2em ${spacing.space_s};
`;

export const Form = styled(StyledForm)`
  border-radius: ${spacing.space_m};
  border: none;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.space_l};
  font-size: 20px;
  line-height: 1.5rem;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.15px;
  color: ${colors.primary};
  background-color: ${colors.grey0};
  border-radius: ${radius.medium} ${radius.medium} 0px 0px;
  > svg {
    cursor: pointer;
  }
`;
