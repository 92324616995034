// @flow

import styled from "@emotion/styled";

import { Cell } from "../styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

export const Status = styled(Cell)`
  height: auto;
`;

export const Options = styled.div`
  position: absolute;
  top: 2px;
  z-index: 2;
  > div {
    margin: ${spacing.space_stack_xs};
    border: 1px solid ${colors.grey3};
    width: 190px;
  }
`;

export const Items = styled.div`
  width: 190px;
  border-radius: ${radius.small};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.brightLight};
  position: sticky;
  color: ${colors.secondary};
  padding: ${spacing.space_default} 0;
`;
