// @flow

import React from "react";

type Props = {
  color?: string
};
const Reply = ({ color = "#BBBBBB" }: Props) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7L8 1V5.033C11.781 5.033 16 6.5 16 13C14.031 9.812 11 9 8 9V13L1 7Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Reply;
