import { backendUrl } from "src/config/firebase";
import * as R from "ramda";

const combineOptions = options =>
  R.mergeDeepLeft({
    credentials: "include",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options.body)
  })(options);

/**
 * Parses an object of parameters into an array of key-value pairs.
 * If a parameter's value is an array, the array is flattened
 * so that there are multiple entries for the same key.
 * This is the format followed by our APIs instead of comma separated values.
 *
 * @param {Object} params - The object of parameters to parse.
 * @returns {Array<Array<string, string|number>>} - An array of key-value pairs.
 * If a parameter's value is an array, the array is flattened so that
 * there are multiple entries for the same key.
 *
 * @example
 * const params = {
 *   foo: 'bar',
 *   baz: [1, 2],
 * };
 * const result = parseParams(params);
 * // result is [['foo', 'bar'], ['baz', 1], ['baz', 2]]
 **/
const parseParams = params => {
  let result = [];

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result = R.concat(
        result,
        R.map(item => [key, item], value)
      );
    } else {
      result.push([key, value]);
    }
  });

  return result;
};

const easyFetch = (url, options) => {
  let endpoint = `${backendUrl}${url}`;

  if (options.params) {
    const params = parseParams(options.params);
    const queryString = new URLSearchParams(params).toString();

    if (endpoint.includes("?")) {
      endpoint = `${endpoint}&${queryString}`;
    } else {
      endpoint = `${endpoint}?${queryString}`;
    }
  }

  const requestOptions = combineOptions(options);

  return fetch(endpoint, requestOptions)
    .then(async response => {
      let result = await response.text();

      try {
        result = JSON.parse(result);
      } catch (error) {
        console.error(error);
        result = { result };
      }

      if (response.ok) {
        return result;
      }

      const errorMsg =
        result?.error ||
        result?.result?.error ||
        response.statusText ||
        "Something went wrong";

      return Promise.reject(new Error(errorMsg));
    })
    .catch(async response => {
      let result = "";

      try {
        result = await response.text();
      } catch (error) {
        console.error(error);
      }

      try {
        result = JSON.parse(result);
      } catch {
        result = { result };
      }

      const errorMsg =
        result?.error ||
        result?.result?.error ||
        response.statusText ||
        "Something went wrong";

      return Promise.reject(
        new Error(errorMsg, { cause: { response, result } })
      );
    });
};

export default easyFetch;

export const get = (url, options) =>
  easyFetch(url, { method: "GET", ...options });
export const put = (url, options) =>
  easyFetch(url, { method: "PUT", ...options });
export const post = (url, options) =>
  easyFetch(url, { method: "POST", ...options });
export const patch = (url, options) =>
  easyFetch(url, { method: "PATCH", ...options });
export const _delete = (url, options) =>
  easyFetch(url, { method: "DELETE", ...options });
export const head = (url, options) =>
  easyFetch(url, { method: "HEAD", ...options });
