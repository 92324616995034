// @flow

import * as React from "react";

type Props = {
  fill?: string
};

const Edit3 = ({ fill = "#A0AEC0", ...restProps }: Props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g id="Group 14">
        <path
          id="Path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.56875 2.40625L0.20625 7.76875C0.06875 7.90625 0 8.04375 0 8.25V10.3125C0 10.725 0.275 11 0.6875 11H2.75C2.95625 11 3.09375 10.9312 3.23125 10.7937L8.59375 5.43125L5.56875 2.40625Z"
          fill={fill}
        />
        <path
          id="Path_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7937 2.26875L8.73125 0.20625C8.45625 -0.06875 8.04375 -0.06875 7.76875 0.20625L6.53125 1.44375L9.55625 4.46875L10.7937 3.23125C11.0687 2.95625 11.0687 2.54375 10.7937 2.26875Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Edit3;
