// @flow

import type { FormControlProps, StackProps } from "@chakra-ui/react";
import { mobileBreakpoint } from "src/constants";

export const container: StackProps = {
  width: "100%",
  maxWidth: "572px",
  gridGap: "48px",
  [mobileBreakpoint]: {
    gridGap: "16px"
  }
};

export const inviteeList: StackProps = {
  width: "100%",
  alignItems: "start",
  gridGap: "32px",
  [mobileBreakpoint]: {
    gridGap: "16px"
  }
};

export const formControl: FormControlProps = {
  width: "100%"
};

export const fieldsRow: StackProps = {
  gridGap: "32px",
  width: "100%",
  [mobileBreakpoint]: {
    flexDirection: "column",
    gridGap: "16px"
  }
};
