// @flow

import React, { Component } from "react";

import { SmallPicture as StyledPicture } from "../styles";
import Initial from "./initial/Small";
import Tooltip from "src/components/Tooltip";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser,
  showTooltip?: boolean
};

type State = {
  error: boolean
};

class Small extends Component<Props, State> {
  state = {
    error: false
  };

  setError = () => {
    this.setState({ error: true });
  };

  render() {
    const { user, showTooltip = false } = this.props;
    const { error } = this.state;

    if (showTooltip) {
      return (
        <Tooltip placement="top" title="user">
          <StyledPicture
            src={user.photoUrl}
            onError={this.setError}
            alt={user.displayName}
            title={user.displayName || user.email}
          />
        </Tooltip>
      );
    }

    if (user.photoUrl && !error) {
      return (
        <StyledPicture
          src={user.photoUrl}
          onError={this.setError}
          alt={user.displayName}
          title={user.displayName || user.email}
        />
      );
    }
    return <Initial user={user} />;
  }
}

export default Small;
