// @flow

import React, { useMemo, memo } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getMessageCount, getReadMessageCount } from "src/reducers";
import { Badge } from "src/styles/badge";

import type { AppState } from "src/types";

type Props = {
  totalCount: number,
  readCount: number
};

const UnreadCount = ({ totalCount, readCount }: Props) => {
  // New conversations will have readCount as undefined
  // Check and show total count if readcount is undefined or null
  const unreadCount = useMemo(
    () => (!R.isNil(readCount) ? totalCount - readCount : totalCount),
    [totalCount, readCount]
  );
  return unreadCount > 0 ? (
    <Badge>{unreadCount > 99 ? `99+` : unreadCount}</Badge>
  ) : null;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  totalCount: getMessageCount(app, props.roomId),
  readCount: getReadMessageCount(app, props.roomId)
});

export default connect(mapStateToProps, {})(memo<Props>(UnreadCount));
