// @flow

import { connect } from "react-redux";

import Selection from "src/components/chatroom/Selection";
import {
  getChatroomTitle,
  getChatroomType,
  getSequenceNo,
  getProcessTitle
} from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  id: string
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  name: getChatroomTitle(app, `${props.id}`),
  type: getChatroomType(app, `${props.id}`),
  seqNo: getSequenceNo(app, `${props.id}`),
  processTitle: getProcessTitle(app, `${props.id}`)
});

export default connect(mapStateToProps)(Selection);
