// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import { SortableList as StyledSortableList } from "./styles";

type Props = {
  items: Array<number>,
  handleOpenModal: Function
};

const SortableList = SortableContainer(({ items, handleOpenModal }: Props) => {
  return (
    <StyledSortableList>
      {(items || []).map((value, index) => (
        <SortableItem
          key={`item-${value}`}
          index={index}
          value={value}
          handleOpenModal={handleOpenModal}
        />
      ))}
    </StyledSortableList>
  );
});

export default SortableList;
