// @flow

import styled from "@emotion/styled";

import { InlineBox } from "src/styles/box";
import appLocation from "src/constants/location";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.space_l};
  padding-bottom: ${spacing.space_m};
  padding-right: 0;
  background: ${colors.brightLight};
  position: relative;
  width: 100%;
  border-bottom: solid 1px ${colors.grey3};
  font-size: 12px;
`;

export const BreadCrumbWrapper = styled.div`
  > div {
    font-size: 0.75rem;
    height: initial;
    margin-bottom: ${spacing.space_m};
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 1.5rem;
  margin-bottom: ${spacing.space_m};
  > div:first-of-type {
    flex-grow: 1;
    display: flex;
    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const UrlCopy = styled.div`
  svg {
    cursor: pointer;
    stroke: ${props => (props.copied ? colors.success : colors.primary)};
    position: relative;
    top: 1px;
  }
`;

export const ActionIconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  > div {
    > div > div {
      > button {
        height: 15px;
        padding: 0;
        > svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
`;

export const DockIconWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 50px;
`;

export const UrlIconWrapper = styled.button`
  background: transparent;

  :focus {
    outline: none;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2vw;
  user-select: none;
  min-height: 2.625rem;
  > * {
    padding: 2px;
    :focus {
      background: ${colors.grey32};
    }
  }
`;

export const WrappingInput = styled.div`
  background: ${props =>
    props.isFocused ? colors.highlight : colors.brightLight};
  margin-right: ${spacing.space_l};
  display: inline-block !important;
  min-height: 20px;
  max-height: 60px;
  min-width: 185px;
  max-width: 694px;
  overflow-y: ${props => (props.isFocused ? "auto" : "hidden")};
  font-size: 1rem;
  line-height: 20px;
  position: relative;
  text-decoration: ${props => (props.isCanceled ? "line-through" : "")};
  > div:first-of-type {
    display: inline-flex;
    align-items: flex-end;
    color: ${colors.secondary};
    margin-right: ${spacing.space_s};
    background: ${colors.brightLight};
  }
  > span {
    font-weight: bold;
    outline: none;
  }
`;

export const Favourite = styled(InlineBox)`
  margin-right: ${spacing.space_s};
  display: flex;
`;

export const Ellipsis = styled.div`
  position: absolute;
  background: ${colors.brightLight};
  padding: 0px ${spacing.space_s} 0px 0px;
  top: 22px;
  right: 0px;
`;

export const PrivacyWrapper = styled.div`
  padding: 0.25rem;
  border-radius: ${props =>
    props.location === appLocation.chatroomHeader && "0.25em"};

  display: ${props => (props.active ? "flex" : "none")};
  align-items: center;
  > svg {
    justify-content: center;
    align-items: center;
    width: 1.6em;
    height: 1.4em;
    > path {
      stroke: ${colors.primary};
    }
  }
  :hover {
    background: ${props =>
      props.location === appLocation.chatroomHeader && colors.grey32};
  }
  :focus {
    background: ${props =>
      props.location === appLocation.chatroomHeader && colors.grey32};
  }
`;

export const PrivacyText = styled.div`
  font-size: 0.75rem;
  margin-left: 0.2em;
  padding-top: 0.3em;
  letter-spacing: 0.0125rem;
`;
