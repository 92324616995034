// @flow

import type { SSOConfig } from "src/types";

/**
 * Checks if SSO configuration is already present
 * Returns true if SSO is already configured, false if not
 * @param {SSOConfig} ssoConfig - SSO configuration
 */
export const isSSOConfigured = (ssoConfig: SSOConfig): boolean => {
  const isDomainEmpty = (ssoConfig.domains ?? []).length === 0;
  const isEntityIDEmpty = (ssoConfig.entityId ?? "").length === 0;
  const isSSOUrlEmpty = (ssoConfig.ssoUrl ?? "").length === 0;
  const isCertificateEmpty = (ssoConfig.certificate ?? "").length === 0;

  const areFieldsEmpty =
    isDomainEmpty && isEntityIDEmpty && isSSOUrlEmpty && isCertificateEmpty;

  return !areFieldsEmpty;
};
