// @flow

import React from "react";
import * as colors from "src/styles/constants/colors";

const ServerError = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="79"
      fill="none"
      viewBox="0 0 38 79"
    >
      <path
        fill={colors.grey34}
        d="M2.786 0A2.786 2.786 0 000 2.786v72.448a2.787 2.787 0 002.786 2.786h30.641a4 4 0 004-4V4a4 4 0 00-4-4H2.787zm27.865 40.403H6.966a1.393 1.393 0 010-2.786h23.685a1.393 1.393 0 110 2.786zm0-12.539H6.966a1.393 1.393 0 010-2.786h23.685a1.393 1.393 0 110 2.786zm0-12.539H6.966a1.393 1.393 0 010-2.786h23.685a1.393 1.393 0 110 2.786z"
      ></path>
    </svg>
  );
};

export default ServerError;
