// @flow

import React from "react";
import * as R from "ramda";

import { IconWrapper, MainIconWrapper } from "./styles";
import Icons from "src/icons";
import { PENDING, COMPLETED } from "src/constants/status";

type Props = {
  edit: boolean,
  id: number,
  templateId: number,
  showStatusChangeModal: Function,
  deleteStatus: Function
};

const DeleteAndMapIcons = ({
  edit,
  id,
  templateId,
  showStatusChangeModal,
  deleteStatus
}: Props) => {
  return (
    <MainIconWrapper edit={edit}>
      <IconWrapper
        onClick={e => showStatusChangeModal(e, id)}
        visible={R.not(R.includes(id, [PENDING, COMPLETED]))}
      >
        <Icons type="gear" />
      </IconWrapper>

      <IconWrapper onClick={e => deleteStatus(e, templateId, id)} visible>
        <Icons type="deleteTrash" />
      </IconWrapper>
    </MainIconWrapper>
  );
};

export default DeleteAndMapIcons;
