// @flow

import { connect } from "react-redux";

import SortModal from "src/components/chatroom-nav/filter/SortModal";
import { hideSortByModal, updateFilter } from "src/actions/filter";
import {
  getSortByModal,
  getCurrentFilterSortBy,
  getCurrentFilter
} from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  sortbyModal: getSortByModal(app),
  sort: getCurrentFilterSortBy(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _hideSortByModal: hideSortByModal,
  _updateFilter: updateFilter
})(SortModal);
