export const container = {
  borderRadius: "lg",
  backgroundColor: "white",
  width: "450px"
};

export const cardHeader = {
  p: 4,
  py: 3,
  borderTopRadius: "lg",
  gap: "0.5rem",
  backgroundColor: "unifize_grey0"
};

export const oAuthAppLogo = {
  height: "46px"
};

export const headerText = { fontSize: "xl", my: 0, textAlign: "left" };

export const cardContent = {
  alignItems: "start",
  width: "100%",
  px: 4
};

export const cardContentTitle = {
  my: 0,
  fontSize: "lg",
  fontWeight: 600
};

export const permissionList = { my: 0 };

export const errorAlert = { borderRadius: "md" };

export const cardFooter = {
  width: "100%",
  px: 4,
  pt: 2,
  pb: 4
};
