// @flow

import React from "react";

const DownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill="#000" d="M7.41 8L12 12.58 16.59 8 18 9.41l-6 6-6-6L7.41 8z" />
  </svg>
);

export default DownIcon;
