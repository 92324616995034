// @flow
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  EmailSent as StyledSent,
  VerifiedStatus,
  EmailNotification,
  ResendInfo
} from "./styles";
import Icon from "src/icons";
import { srwSignUp } from "src/actions/signup";
import { sendVerificationEmail } from "src/actions/auth";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import { getLocationPayload } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  email: string,
  resumeSignup: boolean /* User signup is pending or not */,
  _srwSignUp: typeof srwSignUp
};

const EmailSent = ({ email, resumeSignup, _srwSignUp }: Props) => {
  const dispatch = useDispatch();

  const { handleEnter } = useKeyDownHandlers(_srwSignUp);
  const resumeSignupEmail = useSelector(({ location }) =>
    getLocationPayload("email")(location)
  ); /* Get the user email whose sign up is pending from the payload */

  const handleClick = () => {
    if (resumeSignup) {
      dispatch(
        sendVerificationEmail({
          email: resumeSignupEmail,
          resendEmailInvite: true
        })
      );
    } else {
      _srwSignUp();
    }
  };

  return (
    <StyledSent>
      <VerifiedStatus>
        <Icon type="verificationSent" />
        Verification email sent
      </VerifiedStatus>
      <EmailNotification>
        An email has been sent to{" "}
        <strong>{resumeSignup ? resumeSignupEmail : email}</strong>. Please open
        your email and click on the link to get you setup on Unifize.
      </EmailNotification>
      <ResendInfo>
        Click here to{" "}
        <span
          role="button"
          tabIndex="0"
          onClick={handleClick}
          onKeyDown={handleEnter}
        >
          resend
        </span>
      </ResendInfo>
    </StyledSent>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  email: app.srw.query.email
});

export default connect(mapStateToProps, {
  _srwSignUp: srwSignUp
})(EmailSent);
