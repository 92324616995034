// @flow

import * as atypes from "src/constants/actionTypes";

export const triggerClearInput = () => ({
  type: atypes.TRIGGER_CLEAR_INPUT,
  payload: {}
});

export const resetClearInput = () => ({
  type: atypes.RESET_CLEAR_INPUT,
  payload: {}
});
