// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Dropdown as StyledDropdown, Item as StyledItem } from "./styles";
import Checkbox from "src/components/Checkbox";
import Name from "src/components/Status/Name";
import {
  getWorkflowBuilderPendingStatus,
  getWorkflowBuilderCompletedStatus
} from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  value: Array<number>,
  pendingValue: Array<{ id: number, active: boolean }>,
  completedValue: Array<{ id: number, active: boolean }>,
  handleStatus: Function
};

const Dropdown = ({
  value,
  pendingValue,
  completedValue,
  handleStatus
}: Props) => {
  const handleSelect = useCallback(
    (id: number) => {
      if (R.includes(id, value)) {
        handleStatus(value.filter(s => s !== id));
      } else {
        handleStatus([...value, id]);
      }
    },
    [value, handleStatus]
  );

  return (
    <StyledDropdown>
      {pendingValue.map(status => (
        <StyledItem
          active
          key={status.id}
          onClick={() => handleSelect(status.id)}
        >
          <Checkbox
            handleChange={() => {}}
            id={`pendingValue${status.id}`}
            checked={R.includes(status.id, value)}
          />
          <Name id={status.id} />
        </StyledItem>
      ))}

      {completedValue.map(status => (
        <StyledItem
          active={false}
          key={status.id}
          onClick={() => handleSelect(status.id)}
        >
          <Checkbox
            handleChange={() => {}}
            id={`completedValue${status.id}`}
            checked={R.includes(status.id, value)}
          />
          <Name id={status.id} />
        </StyledItem>
      ))}
    </StyledDropdown>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  pendingValue: getWorkflowBuilderPendingStatus(app),
  completedValue: getWorkflowBuilderCompletedStatus(app)
});

export default connect(mapStateToProps)(Dropdown);
