// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding-left: ${spacing.space_l};
  padding-right: ${spacing.space_l};
`;

export const AppListContainer = styled.section`
  margin-top: ${spacing.space_m};
`;

export const AppNotFound = styled.div`
  padding-left: ${spacing.space_l};
`;

export const SearchContainer = styled.section`
  margin-top: ${spacing.space_l};
  padding-left: ${spacing.space_l};
`;

export const CardItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  border-bottom: 1px ${colors.grey2} solid;
  padding: ${spacing.space_inset_l};
`;

export const AppDetails = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
`;

export const AppLogo = styled.img`
  height: 36px;
  width: 36px;
  border-radius: ${radius.medium};
`;

export const AppDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.space_l};
  gap: ${spacing.space_ms};
  max-width: 90%;
`;

export const AppActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.space_lx};
  width: 10%;
`;

export const DisconnectButton = styled.img`
  height: ${spacing.space_lx};
  width: ${spacing.space_lx};
  cursor: pointer;
`;
