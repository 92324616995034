// @flow

import React from "react";

import { NoUnreadWrapper, Subtitle, Heading } from "./styles";

const NoUnread = () => {
  return (
    <NoUnreadWrapper>
      <Heading>
        <div>
          <span role="img" aria-label="party popper">
            &#x1F389;
          </span>
          &nbsp;
          <h3>Congratulations!</h3> &nbsp;
          {"   "}
          <span role="img" aria-label="party popper">
            &#x1F389;
          </span>
        </div>
        <Subtitle>You have 0 unread conversations here.</Subtitle>
      </Heading>
      <Subtitle>
        <div>Try applying other filters.</div>
      </Subtitle>
    </NoUnreadWrapper>
  );
};

export default NoUnread;
