// @flow

import styled from "@emotion/styled";

import { Dropdown } from "../../styles";
import * as colors from "src/styles/constants/colors";
import { Input } from "src/styles/input";

export const Empty = styled.div`
  padding: 8px;
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  position: relative;
  cursor: pointer;
  ${Dropdown} {
    top: 3em;
  }
`;

export const EmptyMessage = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  margin-top: 0.8em;
  ${Input} {
    min-width: auto;
  }
`;
