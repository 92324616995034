// @flow

import styled from "@emotion/styled";

import { AccordionSection } from "../Accordion/styles";
import { RelativeTruncatedBox } from "src/styles/box";
import { Placeholder } from "src/styles/typography";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";

const LastMessage = styled(RelativeTruncatedBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  color: ${colors.primary};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1rem;
  letter-spacing: normal;
  padding-bottom: 0.55em;
  min-width: 0;

  span .mention,
  a {
    color: inherit !important;
  }

  > div {
    display: flex;
    align-items: center;
    > span > span {
      /* Time stamp */
      color: ${props => (props.active ? colors.primary : colors.secondary)};
      ${props => props.direct && "padding-top: 0.6em;"};
    }

    > svg {
      margin-right: 0.3em;
    }
  }

  > span:first-of-type {
    /* Last message */
    ${props => props.direct && "padding-top: 0.6em;"};
    width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${props => (props.hasUnread ? colors.primary : colors.secondary)};
    line-height: 1rem;
    /* Author */
    > span {
      color: ${props => (props.hasUnread ? colors.primary : colors.secondary)};
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: ${typography.semiBold};
      > span {
        color: ${props =>
          props.hasUnread ? colors.primary : colors.secondary};
      }
    }
  }

  max-height: 36px;

  @media (max-width: 1080px) {
    max-height: 54px;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 280px;
`;

export const MessageTextWrapper = styled.div`
  margin-left: 2px;
  width: 100%;
  display: inline-block;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ChatBlockWrapper = styled.div`
  border: 0;
  width: 100%;
`;

const ListPlaceholder = styled(Placeholder)`
  padding: ${spacing.space_inset_l};
`;

const Chatrooms = styled.div`
  list-style-type: none;
  flex: 1;
  height: 100%;
  margin: 0;
  overflow: hidden;
  > ${AccordionSection}:nth-of-type(1) {
    margin-top: 0px !important;
  }
  @-moz-document url-prefix() {
    overflow: auto !important;
  }
  :hover {
    overflow: overlay;
    @-moz-document url-prefix() {
      overflow: auto !important;
    }
  }

  > h3 {
    padding-left: 1.3em;
    margin: 0 0 0.7em 0;
  }
`;

const Loading = styled.h3``;

const NoMessagePlaceholder = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${colors.activeFilter};
  font-weight: ${typography.semiBold};
`;

export const DemoUser = styled.button`
  color: ${colors.primary};
  position: absolute;
  width: 241.74px;
  height: 59.37px;
  left: 70px;
  bottom: 50px;
  background: ${colors.yellow};
  box-shadow: 0px 3.38293px 20.2976px rgba(244, 180, 0, 0.5);
  border-radius: 33.8293px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const ChatroomsContainer = {
  display: "flex",
  flexDirection: "column",
  borderBottom: "solid transparent 0.5rem"
};

export {
  LastMessage,
  ChatBlockWrapper,
  ListPlaceholder,
  Chatrooms,
  Loading,
  NoMessagePlaceholder
};
