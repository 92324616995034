// @flow

import React from "react";

import { GridItem, Grid } from "@chakra-ui/layout";
import { breakpointMaxWidths } from "src/components/Dock/DockLayout/utils";
import type { Breakpoint } from "src/types";

type Props = {
  breakpoint: Breakpoint,
  fieldW: number,
  children: Object
};

export default function RowItem({ breakpoint, fieldW, children }: Props) {
  return (
    <Grid
      templateColumns={`repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`}
      gap={"0"}
    >
      <GridItem
        colSpan={fieldW}
        sx={{ minWidth: `${fieldW * 200}px`, justifySelf: "stretch" }}
      >
        {children}
      </GridItem>
    </Grid>
  );
}
