// @flow

import React from "react";

import { Header as StyledHeader } from "./styles";
import Title from "./Title";
import Status from "./Status";
import Search from "src/components/chatroom-nav/header/Search";
import Org from "src/containers/chatroom-nav/header/Org";
import Filter from "src/components/chatroom-nav/filter";

const Header = () => (
  <StyledHeader>
    <Org />
    <Title />
    <Search />
    <Status />
    <Filter />
  </StyledHeader>
);

export default Header;
