// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";

export const LinkIconWrapper = styled.div`
    > span {
      color: ${colors.active};
    }
    :hover {
      > span > svg {
        visibility: visible !important;
      }
`;
