// @flow

import React from "react";
import { Avatar as AvatarImage, Container } from "./styles";

type Props = {
  src: ?string,
  alt: string,
  size?: "sm" | "md" | "lg"
};

const Avatar = ({ src, alt, size = "md" }: Props) => {
  const getInitials = name => {
    if (!name) return "";
    const nameArray = name.split(" ");
    const initials = nameArray.map(word => word[0]).join("");
    const trimmedInitials = initials.substring(0, 3).replace(/\W/g, "");
    return trimmedInitials.toUpperCase();
  };

  return (
    <Container size={size}>
      {src ? <AvatarImage src={src} alt={alt} /> : getInitials(alt)}
    </Container>
  );
};

export default Avatar;
