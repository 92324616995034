// @flow

import firebase from "firebase/app";
import "firebase/firestore";

import connection from "../db";
import { backendUrl } from "src/config/firebase";

import type { UnifizeChat, RoomId, MessageID } from "src/types";

/**
 * Add a new chat message with the server timestamp into the given
 * chat room
 *
 * @param {Object} db - database object
 * @param {UnifizeChat} message - new chat message which needs to be added
 * @return {UnifizeChat} - The added chat message with the ID populated
 */

export const insertChat = async ({ roomId, id, ...rest }: UnifizeChat) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  const messageRef = await connection()
    .collection("chatrooms")
    .doc(roomId.toString())
    .collection("messages")
    .doc();
  await messageRef.set({ ...rest, timestamp, read: false });
  return { id: messageRef.id, roomId, ...rest };
};

/**
 * Fetch a specific message by id
 *
 * @param  {roomId, messageId}: current chatroom & message id of specific chat message
 * @return {UnifizeChat}
 */
export const fetchMessage = async ({
  roomId,
  messageId
}: {
  roomId: RoomId,
  messageId: string
}) => {
  const docRef = await connection()
    .collection("chatrooms")
    .doc(roomId)
    .collection("messages")
    .doc(messageId)
    .get();
  return {
    ...docRef.data(),
    id: messageId
  };
};

export const fetchMessageRange = async ({
  roomId,
  timestamp
}: {
  roomId: RoomId,
  timestamp: string
}) => {
  const docRef = await connection()
    .collection("chatrooms")
    .doc(roomId)
    .collection("messages")
    .where("timestamp", ">=", timestamp)
    .get();

  const message = {};
  docRef.docs.forEach(doc => {
    message[doc.id] = {
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate(),
      id: doc.id
    };
  });
  return message;
};

/**
 * Delete a specific message by id
 *
 * @param  {roomId, messageId}: current chatroom & message id of specific chat message
 * @return StatusCode
 */
export const deleteMessage = async (
  roomId: string,
  messages: Array<string>
) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/delete`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ messages })
  });
};

/**
 * Hide messages by id
 *
 * @param  {roomId, messageId}: current chatroom & message id of specific chat message
 */
export const hideMessages = async (
  roomId: string,
  messages: Array<MessageID>
) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/hide`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ messages })
  });
};

/**
 * Unhide message by id
 *
 * @param  {messageId}: message id of specific chat message
 */
export const unhideMessage = async (roomId: RoomId, messageId: string) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/hide/${messageId}`, {
    credentials: "include",
    method: "DELETE"
  });
};

/**
 * Forward messages by id
 *
 * @param  {roomId, messageId}: current chatroom & message id of specific chat message
 */
export const forwardMessages = async (
  roomId: string,
  messages: Array<MessageID>,
  chatrooms: Array<RoomId>
) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/forward`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ messages, chatrooms })
  });
};
