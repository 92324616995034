// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserName } from "src/reducers";
import * as orgCloneActions from "src/actions/orgClone";
import { orgCloneWizardSteps } from "src/constants";
import type { OrgCloneWizardSteps, OrgCloneState } from "src/types";
import { getOrgCloneState } from "src/selectors";

import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import Email from "./Email";
import SignUp from "./SignUp";
import OrgInfo from "./OrgInfo";
import VerificationEmailNotice from "./VerificationEmailNotice";
import SignIn from "./SignIn";
import OrgCreationStatus from "./OrgCreationStatus";
import * as styles from "./styles";

type InfoHeaderProps = {
  step: OrgCloneWizardSteps
};

function InfoHeader({ step }: InfoHeaderProps) {
  const dispatch = useDispatch();

  const displayName = useSelector(state => getCurrentUserName(state.app));

  const handleLogout = () => {
    dispatch(orgCloneActions.logout());
  };

  switch (step) {
    case orgCloneWizardSteps.email:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            Step 1 of 3
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            Sign up
          </Text>

          <Text textStyle="displaySmall">
            Speed up your processes by 3x in 30 days
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.signUp:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            Step 2 of 3
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            Create account
          </Text>

          <Text textStyle="displaySmall">
            Speed up your processes by 3x in 30 days
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.signIn:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            Step 2 of 3
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            Sign in
          </Text>

          <Text textStyle="displaySmall">
            You have already used this email to create an accuont.
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.createOrg:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <HStack
            sx={{
              mb: "16px",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <Text textStyle="wizardStage">Step 3 of 3</Text>

            <Button variant="transparentLink" onClick={handleLogout}>
              Sign Out
            </Button>
          </HStack>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            Last Step {displayName}, Invite Your Collaborators
          </Text>

          <Text textStyle="displaySmall">
            No more silos. Invite up to 4 colleagues now. You can invite more
            later.
          </Text>
        </Box>
      );

    default:
      return <div>Stage Info header: {step}</div>;
  }
}

export default function StageContent() {
  const dispatch = useDispatch();

  const location = useSelector(state => state.location);
  const orgId = Number(location.query.orgId);

  const { wizard: orgCloneWizard }: OrgCloneState = useSelector(
    getOrgCloneState
  );
  const { step, cloneStatus } = orgCloneWizard;

  const updateData = (prop: string[]) => value => {
    dispatch(
      orgCloneActions.updateData({
        prop,
        value
      })
    );
  };

  switch (step) {
    case orgCloneWizardSteps.email:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <Email
            email={orgCloneWizard.fields.email}
            onChange={updateData(["email"])}
          />
        </VStack>
      );

    case orgCloneWizardSteps.signUp:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <SignUp
            firstName={orgCloneWizard.fields.firstName}
            lastName={orgCloneWizard.fields.lastName}
            password={orgCloneWizard.fields.password}
            confirmPassword={orgCloneWizard.fields.confirmPassword}
            onChange={updateData}
          />
        </VStack>
      );

    case orgCloneWizardSteps.createOrg:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <OrgInfo wizardData={orgCloneWizard.fields} onChange={updateData} />
        </VStack>
      );

    case orgCloneWizardSteps.verificationEmailSent:
      return (
        <VerificationEmailNotice
          email={orgCloneWizard.fields.email.value}
          orgId={orgId}
        />
      );

    case orgCloneWizardSteps.signIn:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <SignIn
            password={orgCloneWizard.fields.password}
            onChange={updateData(["password"])}
          />
        </VStack>
      );

    case orgCloneWizardSteps.creatingOrg:
      return (
        cloneStatus && (
          <OrgCreationStatus
            cloneStatus={cloneStatus}
            orgName={orgCloneWizard.fields.orgName.value}
          />
        )
      );

    default:
      return <div>Stage: {step}</div>;
  }
}
