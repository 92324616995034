// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as shadow from "src/styles/constants/shadow";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import { ScrollableBox, ListItemMedium, NoValue } from "src/styles/box";
import {
  SmallImageLabel,
  NameOrEmail,
  AuthorColor
} from "src/components/user/styles";
import { ButtonLink as Button } from "src/styles/buttons";

export const LoaderContainer = styled.div`
  width: 92%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  > button {
    padding: 0;
    width: 100%;
    height: 100%;
    background: ${colors.brightLight};
    border: 0;
    cursor: initial;
    :hover {
      border: 0;
      box-shadow: initial;
    }
    > div > div {
      background: ${colors.active};
    }
  }
`;

export const Dropdown = styled.div`
  position: relative;
  width: 100%;
  > div {
    z-index: 4;
    width: 100%;
  }
`;

export const List = styled(ScrollableBox)`
  padding: 0;
  border: none;
  box-shadow: 1px 1px 10px rgba(21, 129, 186, 0.2);
  > ul {
    max-height: 10em;
  }
`;

export const Item = styled(ListItemMedium)`
  display: grid;
  grid-template-columns: 1fr 12px;
  gap: 0.5rem;
  align-items: center;
  padding-right: 10px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Footer = styled.button`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.active};
  cursor: pointer;
  font-size: 0.88rem;
  line-height: 24px;
  border-top: 1px solid ${colors.grey2};
  outline: none;
  box-shadow: ${shadow.bottom};
  background: ${colors.brightLight};
  transition: all 0.25s;
  :hover,
  :focus {
    transition: all 0.25s;
    background-color: ${colors.active};
    color: ${colors.brightLight};
  }

  > strong {
    display: inline-block;
    max-width: 10em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 0.2em;
    color: ${colors.primary};
  }
`;

export const NewRoom = styled.div`
  width: 100%;
  > input {
    margin-bottom: 0.2em;
  }
`;

export const SelectionName = styled.div`
  border: 1px solid ${colors.grey2};
  border-radius: ${radius.small};
  padding: 0.3em 0.6em;
  position: relative;
  width: 100%;

  > a {
    padding-bottom: 0.03em;
    border-bottom: 0.1em solid ${colors.outline};
  }
  > svg {
    position: absolute;
    top: 5px;
    right: 32px;
    cursor: pointer;
  }
  > button {
    visibility: hidden;
    transition: all 0.25s;
    top: 1.5px;
    right: -2px;
  }
  :hover {
    > button {
      transition: all 0.25s;
      visibility: visible;
    }
  }

  cursor: ${props => (props.disabled ? "not-allowed" : "initial")};
`;

export const Title = styled.div`
  font-weight: bold;
  background-color: ${props =>
    props.expand ? colors.highlight : colors.brightLight};
  display: flex;
  padding: 0.25em 0.5em;
  width: 92%;
  margin: 0.2em 0 0.2em 0;
  padding-left: 0 !important;

  > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20em;
    display: inline-block;
  }
`;

export const MetaData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${NoValue}>div {
    width: 4em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${SmallImageLabel} {
    margin: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${NameOrEmail} {
      ${props =>
        !props.hideName &&
        `
        flex: 1;
        display: flex;
        align-items: center;
        min-width: 0;
        margin: auto;
        width: 100%;
        ${AuthorColor} {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      `}
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const WideMetaData = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => (props.fieldWidth < 300 ? "0.75rem" : "1rem")};
  margin-right: 1rem;
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  outline: none;
  background-color: transparent;
  position: absolute;
  right: -9px;
  top: -2px;
  > svg {
    height: 1.6em;
    width: 1.6em;
  }
`;

export const SelectionWithMetaData = styled.div`
  width: 100%;
  border-style: solid;
  border-color: ${props =>
    props.highlight ? colors.indigoLight : colors.outline};
  border-width: ${props => (props.highlight ? "0.25em" : "0.09em")};
  border-radius: ${radius.small};
  padding: 0.3em 0.8em;
  gap: 2;
  div:first-child > button {
    display: ${props => props.isVersionCommentPresent ? "block": "none"};
  }
  &:hover {
    div:first-child > button {
      display: block;
    }
  }
`;

export const TitleRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const MultiSelect = styled.div`
  width: 100%;
  > div {
    margin-bottom: 1em;
  }
`;

export const FieldPreview = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 21em;
  > span {
    padding-left: 0.2em;
    padding-right: 0.5em;
  }

  > span:first-of-type {
    padding-left: 0;
  }
`;

export const ButtonLink = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  > svg {
    height: 0.6em;
    width: 0.6em;
    position: relative;
    left: 0.2em;
    > path {
      fill: ${colors.dark};
    }
  }
`;

export const ExpandedField = styled.div`
  width: 100%;
  margin-top: ${spacing.space_m};
`;

export const Loader = styled.div`
  width: 100%;
  > button {
    width: 100%;
    cursor: not-allowed;
  }
`;

export const NestedFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 0;
  gap: 0.25rem;
`;
