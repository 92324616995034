// @flow

import type { AlertState } from "src/types";
import * as actionTypes from "src/constants/actionTypes";
import { alerts } from "src/constants";

export type ShowAlertActions = {|
  type: "SHOW_ALERT",
  payload: AlertState
|};

export type HideAlertAction = {|
  type: "HIDE_ALERT",
  payload: null
|};

export const showAlert = ({
  id
}: {|
  id: typeof alerts.invalidStatusesInProcessTemplate
|}): ShowAlertActions => ({
  type: actionTypes.SHOW_ALERT,
  payload: {
    id
  }
});

export const hideAlert = (): HideAlertAction => ({
  type: actionTypes.HIDE_ALERT,
  payload: null
});
