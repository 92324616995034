// @flow

import React from "react";
import * as R from "ramda";

import { Initial as StyledInitial } from "../../styles";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const Initial = ({ user }: Props) => (
  <StyledInitial>
    {R.head(user.displayName || user.email || "")}
    {R.head(R.split(" ", user.displayName || "")[1] || "")}
  </StyledInitial>
);

export default Initial;
