// @flow

import * as R from "ramda";
import { normalize } from "normalizr";
import { toast } from "react-toastify";
import {
  call,
  put,
  takeEvery,
  take,
  takeLatest,
  select,
  all
} from "redux-saga/effects";
import { rsf } from "src/db";

import * as user from "src/api/user";
import * as schema from "src/actions/schema";
import * as atypes from "src/constants/actionTypes";
import getAppState, {
  getLastOrg,
  getUser,
  getCurrentUserId,
  getRoleTitles,
  getLocation
} from "src/selectors";
import {
  getUser as getUserState,
  getUsers,
  getUserSearchResult,
  getSearchString,
  getUniqueValues,
  getDepartment,
  getOrgName,
  getAllRoleIds,
  getWorkflowInstances
} from "src/reducers";
import { clearAllErrors } from "src/actions/signup";
import resizeImage from "src/utils/image";
import { sortByNameCaseInsensitive } from "src/utils";
import { userStatus } from "src/constants/users";

import type { Action, UnifizeUser, UserStatus } from "src/types";

function* searchUsers(action: Action) {
  try {
    let users = [];
    const { searchString } = action.payload;
    let removeDisabledUsers = true; // Don't show disabled users by default

    if ((action.payload.settings || {}).removeDisabledUsers !== undefined) {
      removeDisabledUsers = action.payload.settings.removeDisabledUsers;
    }

    const allUsers = (yield select(getAppState)).users.byId;

    switch ((action.payload.settings || {}).searchType) {
      case "uniqueCompletedBy":
        {
          const uniqueValues = getUniqueValues(
            yield select(getAppState),
            "completedBy"
          );
          users = R.map(c => allUsers[`${c || ""}`], uniqueValues);
          removeDisabledUsers = false;
        }
        break;
      case "uniqueOwner":
        const instances = getWorkflowInstances(yield select(getAppState));
        const uniqueValues = R.uniq(
          instances
            .filter(instance => instance.owner)
            .map(instance => instance.owner)
        );
        users = R.map(c => allUsers[`${c || ""}`], uniqueValues);
        break;
      default:
        users = getUsers(yield select(getAppState)).toJS();
    }

    const searchPred = searchTerm => val => {
      if (removeDisabledUsers) {
        if (val.disabled) {
          return false;
        }
      }

      const name = R.propOr("", "displayName")(val);
      const email = R.head(R.split("@", R.propOr("", "email")(val)));
      const fullEmail = R.propOr("", "email")(val);

      const orgRole = R.propOr("", "orgRole")(val);
      const namePartOfEmail = R.head(R.split("@", searchTerm));

      if (
        orgRole === "contact" &&
        (action.payload.settings || {}).allUsers !== true
      )
        return false;
      if ((action.payload.settings || {}).userNameAndEmail) {
        return (
          R.toLower(name).includes(R.toLower(namePartOfEmail)) ||
          R.toLower(email).includes(R.toLower(namePartOfEmail))
        );
      }

      if (name || email) {
        if ((action.payload.settings || {}).startsWith) {
          return (
            R.toLower(name).startsWith(R.toLower(namePartOfEmail)) ||
            R.toLower(email).startsWith(R.toLower(namePartOfEmail))
          );
        }

        return (
          R.toLower(name).includes(R.toLower(searchTerm)) ||
          R.toLower(fullEmail).includes(R.toLower(searchTerm))
        );
      }

      return false;
    };

    const result = R.map(
      R.prop("uid"),
      sortByNameCaseInsensitive(R.filter(searchPred(searchString))(users))
    );

    const payload = normalize(result, schema.arrayOfUnifizeUsers);

    switch ((action.payload.settings || {}).searchType) {
      case "uniqueCreator":
        yield put({
          type: atypes.SEARCH_UNIQUE_CREATOR_SUCCESS,
          payload
        });
        break;
      case "uniqueOwner":
        yield put({
          type: atypes.SEARCH_UNIQUE_OWNER_SUCCESS,
          payload
        });
        break;
      case "uniqueMembers":
        yield put({
          type: atypes.SEARCH_UNIQUE_PARTICIPANT_SUCCESS,
          payload
        });
        break;
      case "uniqueCompletedBy":
        yield put({
          type: atypes.SEARCH_UNIQUE_COMPLETED_BY_SUCCESS,
          payload
        });
        break;
      default:
        yield put({
          type: atypes.SEARCH_USERS_SUCCESS,
          payload: { columnId: action.payload?.settings?.columnId, ...payload }
        });
    }
  } catch (e) {
    yield put({ type: atypes.SEARCH_USERS_FAILURE, payload: e });
  }
}

function* watchSearchUsers(): any {
  yield takeEvery(atypes.SEARCH_USERS_REQUEST, searchUsers);
}

function* uploadprofilePhoto({ payload }): any {
  try {
    const owner = yield select(getCurrentUserId);
    yield call(user.profilePhoto, payload);
    yield call(user.updateProfile);
    yield put({
      type: atypes.UPLOAD_PROFILE_PHOTO_SUCCESS,
      payload: {
        photoUrl: payload,
        uid: owner
      }
    });

    yield put({
      type: atypes.SEARCH_USERS_REQUEST,
      payload: { searchString: "" }
    });
  } catch (e) {
    yield put({ type: atypes.UPLOAD_PROFILE_PHOTO_FAILURE, payload: e });
  }
}

function* watchUploadProfilePhoto(): any {
  yield takeEvery(atypes.UPLOAD_PROFILE_PHOTO, uploadprofilePhoto);
}

function* uploadProfileFile({ payload }): any {
  try {
    if (payload.image) {
      const resizedImage = yield resizeImage(payload.image);
      const userDetails = yield call(user.profilePhotoFile, resizedImage);
      yield put({
        type: atypes.UPLOAD_PROFILE_PHOTO,
        payload: userDetails
      });
    }
  } catch (e) {
    yield put({ type: atypes.UPLOAD_PROFILE_PICTURE_FILE_FAILURE, payload: e });
  }
}

function* watchUploadProfileFile(): any {
  yield takeEvery(atypes.UPLOAD_PROFILE_PICTURE_FILE, uploadProfileFile);
}

function* resetSearch(): any {
  yield put({
    type: atypes.SEARCH_USERS_REQUEST,
    payload: { searchString: "" }
  });
}

function* watchResetSearch(): any {
  yield takeEvery(atypes.RESET_USER_SEARCH, resetSearch);
}

function* getUserProfile({ payload }): any {
  try {
    const userDetails = yield call(user.getOtherUser, payload.uid);
    // Format changed to the one expected by the reducer
    yield put({
      type: atypes.GET_PROFILE_SUCCESS,
      payload: {
        uid: userDetails.uid,
        entities: {
          profile: {
            [userDetails.uid]: {
              id: userDetails.uid,
              displayName: userDetails.displayName,
              email: userDetails.email,
              notificationToken: null,
              photoUrl: userDetails.photoUrl
            }
          }
        },
        result: [userDetails.uid]
      }
    });
  } catch (e) {
    yield put({ type: atypes.GET_PROFILE_FAILURE, payload: e });
  }
}

function* watchGetUserProfile(): any {
  yield takeEvery(atypes.GET_OTHER_USER_PROFILE, getUserProfile);
}

function* inviteUser({ payload }: Action): any {
  try {
    const newUser = yield call(user.invite, payload);
    const orgId = yield select(getLastOrg);
    if (newUser) {
      // $FlowFixMe
      const email: string = orgId === 208 || orgId === 217 ? "" : newUser.email;

      yield put({
        type: atypes.SEND_USER_INVITE_SUCCESS,
        payload: {
          user: {
            ...newUser,
            email
          }
        }
      });

      toast.success(`${payload.email} has been invited`);
    }
  } catch (e) {
    yield put({
      type: atypes.SEND_USER_INVITE_FAILURE,
      payload: { e }
    });

    if ((e || {}).status === 409) {
      toast.success(`${payload.email} has already been invited`);
    } else if ((e || {}).status === 403) {
      toast.error(
        "Adding user failure: The email ID you've entered is blocked by the admin"
      );
    } else {
      toast.error(`Error in inviting user ${payload.email}`);
    }
  }
}

function* watchInvite(): any {
  yield takeEvery(atypes.INVITE_USER_REQUEST, inviteUser);
}

function* syncUsers(): any {
  try {
    const orgId = yield select(getLastOrg);

    const channel = rsf.firestore.channel(`orgs/${orgId}/users`);
    const oldUsers = getUserSearchResult(yield select(getAppState));

    while (true) {
      const snapshot = yield take(channel);
      const users = {};
      const deletedUsers = [];
      for (const { doc, type } of snapshot.docChanges()) {
        if (type === "removed") {
          deletedUsers.push(doc.id);
        } else {
          const userData = doc.data();

          users[doc.id] = {
            ...userData,
            uid: doc.id,
            email: orgId === 208 || orgId === 217 ? "" : userData.email
          };
        }
      }

      if (deletedUsers.length > 0) {
        yield put({
          type: atypes.REMOVE_USERS,
          payload: {
            users: deletedUsers
          }
        });
      }

      yield put({
        type: atypes.SYNC_USERS_SUCCESS,
        payload: {
          users
        }
      });

      if (oldUsers.length === 0) {
        const searchString = getSearchString(yield select(getAppState));

        yield put({
          type: atypes.SEARCH_USERS_REQUEST,
          payload: {
            searchString
          }
        });
      }
    }
  } catch (e) {
    yield put({
      type: atypes.SYNC_USERS_FAILURE,
      payload: {}
    });
  }
}

function* watchSyncUsers(): any {
  yield takeEvery(atypes.SYNC_USERS_REQUEST, syncUsers);
}

function* watchSyncUsersSrw(): any {
  yield takeEvery(atypes.SET_CURRENT_ORG_SRW, syncUsers);
}

function* editDisplayName({ payload }: Action): any {
  try {
    yield put(clearAllErrors());

    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_REQUEST,
      payload
    });
    // Wait for UPDATE_DISPLAY_NAME_SUCCESS
    yield take(atypes.UPDATE_DISPLAY_NAME_SUCCESS);
    // Call API to sync display name on Firestore
    yield call(user.updateProfile);
    yield put({
      type: atypes.HIDE_UPDATE_NAME_LOADING,
      payload: {}
    });

    yield put({
      type: atypes.TOGGLE_DISPLAY_NAME_MODAL,
      payload: { open: false }
    });

    toast.success("Updated name successfully");
  } catch (error) {
    toast.error("Error updating name");
    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchEditDisplayName(): any {
  yield takeEvery(atypes.EDIT_DISPLAY_NAME, editDisplayName);
}

function* getDepartments(): any {
  try {
    const departments = yield call(user.getDepartments);
    yield put({
      type: atypes.GET_DEPARTMENTS_SUCCESS,
      payload: {
        departments
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_DEPARTMENTS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchGetDepartments(): any {
  yield takeEvery(atypes.GET_DEPARTMENTS_REQUEST, getDepartments);
}

function* getDepartmentOnLoad(): any {
  yield put({
    type: atypes.GET_DEPARTMENTS_REQUEST,
    payload: {}
  });
}

function* watchGetDepartmentsOnLoad(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, getDepartmentOnLoad);
}

function* identifyUserForSegment(): any {
  const currentOrgId = yield select(getLastOrg);
  const { uid, displayName, email, phoneNumber } = yield select(getUser);
  // Getting current user from users slice since only it has the department
  const userInfo = getUserState(yield select(getAppState), uid);
  const department = userInfo.department
    ? getDepartment(yield select(getAppState), userInfo.department)
    : {};
  const orgName = getOrgName(yield select(getAppState), currentOrgId);

  if (window.analytics) {
    window.analytics.identify(uid, {
      name: displayName,
      email,
      phoneNumber,
      department: department.name,
      orgId: currentOrgId,
      orgName
    });

    // Group users by their org
    window.analytics.group(currentOrgId, {
      uid,
      name: orgName
    });
  }
}

function* watchSRWAuthSuccess(): any {
  yield all([
    take(atypes.SRW_SERVER_AUTH_SUCCESS),
    take(atypes.SET_ORG_DETAILS_SRW)
  ]);

  yield call(identifyUserForSegment);
}

function* watchIdentifyUserForSegment(): any {
  yield all([
    take(atypes.SYNC_USERS_SUCCESS),
    take(atypes.GET_DEPARTMENTS_SUCCESS),
    take(atypes.GET_CURRENT_ORG_DETAILS_SUCCESS)
  ]);

  yield call(identifyUserForSegment);
}

function* resendInvite({ payload }: Action): any {
  try {
    yield call(user.resendInvite, payload.uid);
    yield put({
      type: atypes.RESEND_INVITE_SUCCESS,
      payload
    });
    toast.success("Invitation has been sent again");
  } catch (error) {
    yield put({
      type: atypes.RESEND_INVITE_FAILURE,
      payload
    });

    toast.error("Unable to send invitation again");
  }
}

function* watchResendInvite(): any {
  yield takeEvery(atypes.RESEND_INVITE_REQUEST, resendInvite);
}

function* getFilteredContacts({ meta }: Action): any {
  try {
    const filter = (meta || {}).query || {};

    const searchString = R.toLower(filter.search || "");
    const app = yield select(getAppState);
    let users = getUsers(app).toJS();
    let groupsById = app.groups.byId || {};
    let groupsByUsers = app.groups.byUsers || {};

    if (R.isEmpty(users)) {
      yield all([
        // take(atypes.SYNC_USERS_SUCCESS),
        // take(atypes.GET_GROUPS_SUCCESS)
        take(atypes.GET_GROUPS_BY_USERS)
      ]);
      const appState = yield select(getAppState);
      users = getUsers(appState).toJS();
      groupsById = appState.groups.byId;
      groupsByUsers = appState.groups.byUsers;
    }

    const containsDisplayName = (search: string) => (u: UnifizeUser) =>
      R.toLower(u.displayName || "").includes(R.toLower(search));

    const containsEmail = (search: string) => (u: UnifizeUser) =>
      R.toLower(R.prop("email", u) || "").includes(R.toLower(search));

    const checkStatus = (status: UserStatus, user: UnifizeUser): boolean => {
      const { disabled = false, invitePending = false }: Object = user;
      switch (status) {
        case "Disabled":
          return disabled;
        case "Pending":
          return invitePending && !disabled;
        case "Active":
          return !invitePending && !disabled;
        default:
          return false;
      }
    };

    const containsStatus =
      ({ status }: { status?: Array<UserStatus> } = {}) =>
      (u: UnifizeUser): boolean => {
        if (!status || status.length === 0) {
          return true;
        }

        return status.some(current => checkStatus(current, u));
      };

    const containsDepartment =
      ({ department }: Object) =>
      (u: UnifizeUser) =>
        R.includes(u.department, department || []) ||
        (department || []).length === 0;

    const containsGroup =
      ({ group }: Object) =>
      (u: UnifizeUser) => {
        const groupFilter = R.map(parseInt, group || []);
        return (
          R.intersection(groupsByUsers[u.uid] || [], groupFilter).length > 0 ||
          groupFilter.length === 0
        );
      };

    const containsRole =
      ({ orgRole = [] }: Object) =>
      (user: UnifizeUser) =>
        R.includes(user.orgRoleId, orgRole) || !orgRole.length;

    const rolesWithTitles = yield select(getRoleTitles);
    const roleTitles = R.pluck("title")(rolesWithTitles);

    // Role of the user should exist in the response of the `/role` API
    const hasVisibleRole = (user: UnifizeUser) => {
      return roleTitles.includes(user.orgRole);
    };

    const usersWithGroup = R.map(user => {
      const groups = R.map(
        g => R.toLower((groupsById[`${g}`] || {}).title || ""),
        groupsByUsers[user.uid] || []
      );

      return {
        ...user,
        group: R.head(groups.sort() || [])
      };
    }, users);

    const searchResult = R.filter(
      R.anyPass([
        containsDisplayName(searchString),
        containsEmail(searchString)
      ])
    )(usersWithGroup);

    const filtered = R.filter(
      R.allPass([
        hasVisibleRole,
        containsDepartment(filter),
        containsGroup(filter),
        containsRole(filter),
        containsStatus(filter)
      ]),
      searchResult
    );

    const sort = filter.sort || [];

    const timeField = ["lastLogin", "dateInvited", "dateJoined"];
    const sortPredicates = R.map(s => {
      const [column, direction] = s.split(":");
      if (direction === "asc") {
        return R.ascend(x => {
          if (R.includes(timeField, column)) {
            return x.column ? x.toDate().getTime() : x.column;
          }

          return R.toLower(`${R.propOr("", column, x)}`);
        });
      }

      return R.descend(x => {
        if (R.includes(timeField, column)) {
          return x.column ? x.toDate().getTime() : x.column;
        }
        return R.toLower(`${R.prop(column, x) || ""}`);
      });
    }, sort);

    const result = R.map(R.prop("uid"), R.sortWith(sortPredicates)(filtered));

    yield put({
      type: atypes.FILTER_CONTACTS_SUCCESS,
      payload: {
        result
      }
    });
  } catch (e) {
    yield put({ type: atypes.FILTER_CONTACTS_FAILURE, payload: e });
  }
}

function* watchGetFilteredContacts(): any {
  yield takeEvery(atypes.SET_CONTACTS_REQUEST, getFilteredContacts);
}

function* getUniqueConactsValues(): any {
  try {
    const app = yield select(getAppState);
    const department = R.map(R.prop("id"), app.departments);
    const group = R.map(parseInt, R.keys(app.groups.byId));
    const allRoles = getAllRoleIds(app);

    yield put({
      type: atypes.GET_UNIQUE_CONTACT_VALUES_SUCCESS,
      payload: {
        department,
        group,
        orgRole: allRoles,
        status: [userStatus.pending, userStatus.active, userStatus.disabled]
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_UNIQUE_CONTACT_VALUES_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchGetUniqueContactsValues(): any {
  yield takeEvery(
    atypes.GET_UNIQUE_CONTACT_VALUES_REQUEST,
    getUniqueConactsValues
  );
}

function* searchDeparment({ payload }: any) {
  try {
    const { departments } = yield select(getAppState);

    const search = R.toLower(payload.search || "");

    const result = R.map(
      R.prop("id"),
      R.filter(
        d => R.includes(search, R.toLower(d.name || "")),
        departments || []
      )
    );

    yield put({
      type: atypes.SEARCH_UNIQUE_DEPARTMENT_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_DEPARTMENT_FAILURE,
      payload: { error }
    });
  }
}

function* watchSearchDepartment(): any {
  yield takeEvery(atypes.SEARCH_DEPARTMENT_REQUEST, searchDeparment);
}

function* getUserProcess({ payload }: Action): any {
  try {
    const processes = yield call(user.getUserProcess, payload.uid);

    yield put({
      type: atypes.GET_USER_PROCESS_SUCCESS,
      payload: processes
    });
  } catch (error) {
    yield put({
      type: atypes.GET_USER_PROCESS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetUserProcess(): any {
  yield takeEvery(atypes.GET_USER_PROCESS_REQUEST, getUserProcess);
}

function* getUserOnboardingStatus({ payload }: Action): any {
  try {
    const { status } = yield call(user.getUserOnboardingStatus, payload.uid);

    let value = 0;

    switch (status) {
      case "INVITE_SENT":
        value = 0;
        break;
      case "INVITE_VALIDATED":
        value = 1;
        break;
      case "INVITE_ACCEPTED":
        value = 3;
        break;
      case "UPDATE_USER_DETAIL":
        value = 4;
        break;
      case "USER_DETAIL_UPDATED":
        value = 7;
        break;
      default:
    }

    yield put({
      type: atypes.GET_USER_ONBOARDING_STATUS_SUCCESS,
      payload: {
        status: value
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_USER_ONBOARDING_STATUS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetUserOnboardingStatus(): any {
  yield takeEvery(
    atypes.GET_USER_ONBOARDING_STATUS_REQUEST,
    getUserOnboardingStatus
  );
}

function* updateOtherUserDetails({ payload }: Action): any {
  try {
    yield call(user.updateOtherUserDetails, payload.uid, payload.value);
    yield put({
      type: atypes.UPDATE_OTHER_USER_DETAILS_SUCCESS,
      payload: {}
    });
  } catch (error) {
    toast.error(error.message || "Unable to update details");

    yield put({
      type: atypes.UPDATE_OTHER_USER_DETAILS_FAILURE,
      payload: { error }
    });
  }
}

function* watchUpdateOtherUserDetails(): any {
  yield takeEvery(
    atypes.UPDATE_OTHER_USER_DETAILS_REQUEST,
    updateOtherUserDetails
  );
}

function* disableUser({ payload }: Action): any {
  try {
    yield call(user.disableUser, payload.uid);
    const { query } = yield select(getLocation);
    yield put({
      type: atypes.SET_CONTACTS_REQUEST,
      meta: { query }
    });
    toast.success("User has been disabled");
  } catch (error) {
    toast.error("Unable to disable user");
    yield put({
      type: atypes.DISABLE_USER_FAILURE,
      payload: { error }
    });
  }
}

function* watchDisableUser(): any {
  yield takeEvery(atypes.DISABLE_USER_REQUEST, disableUser);
}

function* enableUser({ payload }: Action): any {
  try {
    yield call(user.enableUser, payload.uid);
    const { query } = yield select(getLocation);
    yield put({
      type: atypes.SET_CONTACTS_REQUEST,
      meta: { query }
    });
    toast.success("User has been enabled");
  } catch (error) {
    toast.error(error.message || "Unable to enable user");
    yield put({
      type: atypes.ENABLE_USER_FAILURE,
      payload: { error }
    });
  }
}

function* watchEnableUser(): any {
  yield takeEvery(atypes.ENABLE_USER_REQUEST, enableUser);
}

function* makeAdmin({ payload }: Action): any {
  try {
    yield call(user.makeAdmin, payload.uid);
    toast.success("User is admin now");
  } catch (error) {
    toast.error(error.message || "Unable to make user admin");
    yield put({
      type: atypes.MAKE_ADMIN_FAILURE,
      payload: { error }
    });
  }
}

function* watchMakeAdmin(): any {
  yield takeLatest(atypes.MAKE_ADMIN_REQUEST, makeAdmin);
}
function* makeMember({ payload }: Action): any {
  try {
    yield call(user.makeMember, payload.uid);
    toast.success("User is member now");
  } catch (error) {
    toast.error(error.message || "Unable to make user member");
    yield put({
      type: atypes.MAKE_MEMBER_FAILURE,
      payload: { error }
    });
  }
}

function* watchMakeMember(): any {
  yield takeLatest(atypes.MAKE_MEMBER_REQUEST, makeMember);
}

export default [
  watchEnableUser(),
  watchDisableUser(),
  watchMakeAdmin(),
  watchMakeMember(),
  watchUpdateOtherUserDetails(),
  watchGetUserOnboardingStatus(),
  watchGetUserProcess(),
  watchResendInvite(),
  watchSearchDepartment(),
  watchGetUniqueContactsValues(),
  watchGetFilteredContacts(),
  watchSearchUsers(),
  watchUploadProfilePhoto(),
  watchUploadProfileFile(),
  watchGetUserProfile(),
  watchInvite(),
  watchResetSearch(),
  watchSyncUsers(),
  watchEditDisplayName(),
  watchGetDepartments(),
  watchGetDepartmentsOnLoad(),
  watchSyncUsersSrw(),
  watchSRWAuthSuccess(),
  watchIdentifyUserForSegment()
];
