// @flow

import React from "react";

import Name from "src/containers/chatroom/Name";

type Props = {
  value: any
};

const Conversation = ({ value }: Props) => (
  <>{value ? <Name id={`${value}`} /> : null}</>
);

export default Conversation;
