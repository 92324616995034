// @flow

import React, { useState } from "react";

import { Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";

import * as styles from "./styles";

import type { Email } from "src/types";

import type { HandleRemove } from "./index";

type Props = {|
  value: Email,
  handleRemove: HandleRemove,
  name: string
|};

const EmailTag = ({ value, handleRemove, name }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const handleKeyboardEvents = e => {
    if (e.key === "Enter") handleRemove({ id: value });
  };

  return (
    <Tag
      name={name}
      sx={styles.UserOrGroupTag}
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyboardEvents}
    >
      <TagLabel sx={styles.UserAndGroupTagText}>{value.email}</TagLabel>
      <TagCloseButton
        onClick={() => handleRemove({ id: value })}
        sx={
          isFocused ? styles.UserOrGroupCloseFocused : styles.UserOrGroupClose
        }
        tabIndex={-1}
      />
    </Tag>
  );
};

export default EmailTag;
