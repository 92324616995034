// @flow

import localForage from "localforage";

/**
 * Fetch data stored in indexdb for given key
 * @param {string} key - key of the record to fetch
 * @returns {string} value stored in for that key
 */
export const getStorage = async (key: string) => {
  try {
    const value = await localForage.getItem(key);
    return value;
  } catch (error) {
    return null;
  }
};

/**
 * Store data in indexdb for given key
 * @param {string} key - key of the record to fetch
 * @param {any} data - value that iwll be stored in for that key
 */
export const setStorage = async (key: string, data: any) => {
  try {
    await localForage.setItem(key, data);
  } catch (error) {
    console.error(error);
  }
};
