// @flow

import React from "react";

type Props = {
  color?: string
};

const Delete = ({ color = "#888", ...restProps }: Props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.79922 7.55078C2.13059 7.55078 2.39922 7.80262 2.39922 8.11328V15.9882C2.39922 16.9207 3.20459 17.6757 4.19922 17.6757H13.7992C14.7938 17.6757 15.5992 16.9207 15.5992 15.9882V8.11328C15.5992 7.80262 15.8678 7.55078 16.1992 7.55078C16.5306 7.55078 16.7992 7.80262 16.7992 8.11328V15.9882C16.7992 17.542 15.4566 18.8007 13.7992 18.8007H4.19922C2.54185 18.8007 1.19922 17.542 1.19922 15.9882V8.11328C1.19922 7.80262 1.46785 7.55078 1.79922 7.55078Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.73828C0 4.42762 0.268629 4.17578 0.6 4.17578H17.4C17.7314 4.17578 18 4.42762 18 4.73828C18 5.04893 17.7314 5.30077 17.4 5.30077H0.6C0.268629 5.30077 0 5.04893 0 4.73828Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.36328C6 1.05262 6.26863 0.800781 6.6 0.800781H11.4C11.7314 0.800781 12 1.05262 12 1.36328V4.73824C12 5.0489 11.7314 5.30074 11.4 5.30074C11.0686 5.30074 10.8 5.0489 10.8 4.73824V1.92577H7.2V4.73824C7.2 5.0489 6.93137 5.30074 6.6 5.30074C6.26863 5.30074 6 5.0489 6 4.73824V1.36328Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00039 8.67578C9.33176 8.67578 9.60039 8.92762 9.60039 9.23828V14.8632C9.60039 15.1739 9.33176 15.4257 9.00039 15.4257C8.66902 15.4257 8.40039 15.1739 8.40039 14.8632V9.23828C8.40039 8.92762 8.66902 8.67578 9.00039 8.67578Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6 8.67578C12.9314 8.67578 13.2 8.92762 13.2 9.23828V14.8632C13.2 15.1739 12.9314 15.4257 12.6 15.4257C12.2686 15.4257 12 15.1739 12 14.8632V9.23828C12 8.92762 12.2686 8.67578 12.6 8.67578Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40078 8.67578C5.73215 8.67578 6.00078 8.92762 6.00078 9.23828V14.8632C6.00078 15.1739 5.73215 15.4257 5.40078 15.4257C5.06941 15.4257 4.80078 15.1739 4.80078 14.8632V9.23828C4.80078 8.92762 5.06941 8.67578 5.40078 8.67578Z"
      fill={color}
    />
  </svg>
);

export default Delete;
