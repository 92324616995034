// @flow

import * as React from "react";

type Props = {
  fill?: string
};

const Edit2 = ({ fill, ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...restProps}
    >
      <g
        clipPath="url(#a)"
        stroke={fill || "#B794F4"}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.25 34.875h31.5M23.625 1.125l6.75 6.75L11.25 27l-9 2.25 2.25-9L23.625 1.125ZM19.125 5.625l6.75 6.75" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={fill || "#fff"} d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Edit2;
