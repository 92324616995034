// @flow

import connection, { rsf } from "../db";
import { takeEvery, put, select, take } from "redux-saga/effects";
import * as atypes from "src/constants/actionTypes";
import { getUser, getLastOrg } from "src/selectors";

function* userMembership(): any {
  try {
    const { uid } = yield select(getUser);
    const orgId = yield select(getLastOrg);

    const channel = rsf.firestore.channel(
      connection()
        .collection(`userData/${uid}/chatrooms`)
        .where("orgId", "==", orgId)
    );
    while (true) {
      const snapshot = yield take(channel);
      const membership = [];

      for (const { doc, type } of snapshot.docChanges()) {
        if (type === "added") {
          membership.push(parseInt(doc.id, 10));
        } else if (type === "removed") {
          yield put({
            type: atypes.REMOVE_USER_MEMBERSHIP_SUCCESS,
            payload: { membership: parseInt(doc.id, 10) }
          });
        }
      }

      if (membership.length > 0) {
        yield put({
          type: atypes.GET_USER_MEMBERSHIP_SUCCESS,
          payload: { membership }
        });
      }
    }
  } catch (error) {
    yield put({ type: atypes.LOAD_MEMBERSHIP_FAILURE, payload: error });
  }
}

function* whatchUserMembership(): any {
  yield takeEvery(atypes.GET_USER_MEMBERSHIP_REQUEST, userMembership);
}

export default [whatchUserMembership()];
