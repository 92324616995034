// @flow

import React from "react";

import { LargePicture } from "../styles";
import Picture from "src/containers/user/picture";

import type { UID } from "src/types";

type Props = {
  uid: UID
};

const Large = ({ uid }: Props) => (
  <LargePicture>
    <Picture uid={uid} />
  </LargePicture>
);

export default Large;
