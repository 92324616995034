// @flow

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { VStack, Box, Text } from "@chakra-ui/react";

import useFields from "../useFields";
import SingleSelection from "./SingleSelection";
import MultipleSelection from "./MultipleSelection";
import { getUserAndGroupIds, getMultipleSelectedValues } from "./utils";

import {
  getChecklistValue,
  getChecklistFieldDetails,
  getAllMembersAndGroups,
  getChecklistFormValue,
  getSelectedChecklist
} from "src/reducers";

import type { RoomId, FieldId, UID, GroupId } from "src/types";
import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  formId: ?number,
  roomFieldFormId: ?string,
  fieldId: FieldId,
  edit: boolean,
  openEdit: () => void,
  closeEdit: () => void,
  handleRemove: (id: UID | GroupId) => void,
  fromManageView?: boolean,
  selectedFieldValue: any
};

const Users = ({
  edit,
  roomId,
  formId,
  roomFieldFormId,
  fieldId,
  closeEdit,
  openEdit,
  handleRemove,
  fromManageView = false,
  selectedFieldValue
}: Props) => {
  const { roomId: selectedRoomId } = useSelector(({ app }) =>
    getSelectedChecklist(app)
  );

  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const orgUsersAndGroups = useSelector(({ app }) =>
    getAllMembersAndGroups(app)
  );

  const { value: extractedValue, settings } = useFields({
    checklistValue,
    details
  });
  const value = (fromManageView ? selectedFieldValue : extractedValue) ?? [];

  const multiple: boolean = settings && settings.multiple;

  const userAndGroupIds = getUserAndGroupIds(value);
  const multipleSelectedValues = getMultipleSelectedValues(
    orgUsersAndGroups,
    userAndGroupIds
  );

  // If the component receives the entire user object extract the UID
  const singleUserFieldValue =
    value && typeof value[0] === "object"
      ? value[0]?.uid
      : R.find(R.is(String), value) || "";

  if (edit && value.length === 0) {
    return (
      <Box sx={styles.EmptySingleUserSelection}>
        <Text sx={styles.EmptySingleUserSelectionText}>No user selected</Text>
      </Box>
    );
  }

  if (
    multipleSelectedValues.length > 0 ||
    (singleUserFieldValue || []).length > 0
  ) {
    return (
      <VStack sx={styles.SelectionContainer}>
        {multiple ? (
          <MultipleSelection
            usersAndGroups={multipleSelectedValues}
            handleRemove={handleRemove}
          />
        ) : (
          <SingleSelection
            user={singleUserFieldValue}
            closeDropdown={closeEdit}
            toggleDropdown={openEdit}
            edit={edit}
          />
        )}
      </VStack>
    );
  }

  return null;
};

export default Users;
