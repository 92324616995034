// @flow

import { connect } from "react-redux";
import React from "react";
import usePortal from "react-useportal";

import Navigation from "./Navigation";
import Email from "./Email";
import { Settings as StyledSetting } from "./styles";
import Close from "src/containers/Close";

import type { AppState } from "src/types";

type Props = { current: string };

const Settings = ({ current }: Props) => {
  const { Portal } = usePortal();
  return (
    <Portal>
      <StyledSetting>
        <h2>Org Settings</h2>
        <Close />
        <div>
          <Navigation current={current} />
          <Email />
        </div>
      </StyledSetting>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: app.settings.currentSection
});

export default connect(mapStateToProps)(Settings);
