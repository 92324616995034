// @flow

import { Component } from "react";
import onClickOutside from "react-onclickoutside";

interface OnClickOutsideProps {
  onClickOutside: (e: any) => {};
  children: any;
}

class ClickHandlerComponent extends Component<OnClickOutsideProps> {
  handleClickOutside = (e: any) => {
    const { onClickOutside: onClick } = this.props;
    return onClick(e);
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

const OutsideClickHandler = onClickOutside(ClickHandlerComponent);

export default OutsideClickHandler;
