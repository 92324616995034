// @flow

import React, { useState, useRef, useMemo } from "react";
import type { Node } from "react";

import {
  AccordionSection,
  AccordionTitle,
  AccordionIcon,
  AccordionText,
  AccordionContent,
  StatusType,
  Count
} from "./styles";
import Icon from "src/icons";
import Name from "src/components/Status/Name";

type Props = {
  title: number,
  children: Node,
  count: number,
  header: string
};

const Accordion = ({ title, children, count, header }: Props) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef<Element | null>(null);

  const scrollHeight = useMemo(() => {
    if (contentRef && contentRef.current) {
      return `${contentRef.current.scrollHeight}px`;
    }
    return "auto";
  }, [contentRef]);

  const toogleActive = () => {
    setActive(!active);
  };

  return (
    <AccordionSection>
      <AccordionTitle onClick={toogleActive}>
        <AccordionText>
          <div>{header}:</div>
          <StatusType>
            <Name id={title} />
          </StatusType>
          <Count>({count})</Count>
        </AccordionText>
        <AccordionIcon active={active}>
          <Icon type="triangleDown" />
        </AccordionIcon>
      </AccordionTitle>

      <AccordionContent active={active} scrollHeight={scrollHeight}>
        {active ? children : null}
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
