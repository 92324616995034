// @flow

import * as easyFetch from "src/utils/fetch";

/**
 * Grant permission to an application via oAuth.
 *
 * @param  {Object} body - The body of the request.
 * @param  {string} body.app - The name of the application.
 * @param  {string} body.code - OAuth code needed to authorize the request.
 * @param  {string} body.scope - The scope of oAuth permissions required by the application.
 */
export const grantOAuthAppPermisssions = (body: {
  code: string,
  app: string,
  scope: string
}) => easyFetch.post("/oauth/authorize", { body });
