// @flow

import { useSelector } from "react-redux";
import React from "react";
import { Flex } from "@chakra-ui/react";

import Close from "../Close";
import MenuBar from "./MenuBar";
import Visualization from "./CurrentChart";
import { Visualization as StyledVisualization } from "./styles";
import RefreshButton from "src/components/chart/RefreshButton";
import { getNewChartDialogTitle, getNewChartDialogId } from "src/reducers";

type Props = {
  handleClose: Function
};

const Chart = ({ handleClose }: Props) => {
  const title = useSelector(({ app }) => getNewChartDialogTitle(app));
  const chartId = useSelector(({ app }) => getNewChartDialogId(app));

  return (
    <>
      <Flex sx={{ alignItems: "center" }}>
        <MenuBar hideRefresh />

        <Flex sx={{ gap: "1rem", m: "1rem", ml: "auto" }}>
          <RefreshButton chartId={`${chartId}`} />
          <Close handleClose={handleClose} />
        </Flex>
      </Flex>
      <h4 style={{ margin: "0.5rem 1rem 1rem" }}>{title}</h4>

      <StyledVisualization>
        <Visualization chartId={chartId} height="100%" width="100%" />
      </StyledVisualization>
    </>
  );
};

export default Chart;
