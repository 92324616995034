// @flow

import React from "react";

import { InfoPlaceholder } from "./styles";
import PulseLoader from "src/components/PulseLoader";

const Info = () => (
  <InfoPlaceholder>
    <PulseLoader w="180px" h="34px" />
    <PulseLoader w="180px" h="12px" />
  </InfoPlaceholder>
);

export default Info;
