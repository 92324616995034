// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { theme as chakraTheme } from "@chakra-ui/react";
import type { GridProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";
import { highlightBorder } from "src/styles";

import { Input as StyledInput } from "src/styles/input";

export const ChecklistHeader = styled.div`
  display: flex;
  margin-bottom: ${spacing.space_l};
  > svg {
    margin-right: 1.2em;
  }
  > div {
    font-size: 0.88rem;
  }
`;

export const Checklist = styled.div`
  padding-left: ${spacing.space_l};
  padding-right: ${spacing.space_m};
  :nth-of-type(1) {
    padding-top: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.isFromManageView ? "0px" : spacing.space_l)};
  ${props => props.isSectionField && `padding-left: 0px`};
  ${props => props.isSectionField && `padding-right: 0px`};
  ${props => props.isDragActive && highlightBorder};
  ${props => props.isDragActive && `padding: 2px;`};
  > svg {
    margin: 0 ${spacing.space_m} 0 0;
  }
  > div {
    flex: 1;
  }
`;

export const Label = styled.div`
  margin-bottom: ${spacing.space_m};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: ${typography.medium};
  line-height: ${spacing.space_l};
  color: ${colors.unifizeBlue};
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 1.5px;
  ${props => props.isSectionField && `padding-left: ${spacing.space_m}`}
`;

export const ChecklistLabel = styled.div`
  position: relative;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => (props.more ? "auto" : 3)};
  font-weight: ${typography.normal};
  line-height: ${spacing.space_l};
  letter-spacing: 0.5px;
  color: ${colors.inkLight};
  margin-bottom: ${spacing.space_m};
  ${props => props.isSectionField && `padding-left: 0`};
  height: auto;
  overflow: hidden;
`;

export const More = styled.div`
  color: ${colors.blue};
  position: absolute;
  bottom: 0px;
  right: 0.2em;
  background: white;
  padding-left: 0.2em;
  font-weight: ${typography.semiBold};
  cursor: pointer;
`;

export const DisplayContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ isText }) =>
    isText
      ? css`
          min-height: ${spacing.space_xl};
        `
      : css`
          height: ${spacing.space_xl};
        `}
  align-items: center;
  margin-bottom: ${spacing.space_m};

  cursor: ${props => (props.disabled ? "not-allowed" : "initial")};
  pointer-events: ${props => (props.disabled ? "none" : "all")};

  > div > div {
    display: none;
  }

  :hover {
    > div > div {
      display: ${props => props.locked ? "none" : "block"};
    }
  }
`;

export const MultiDisplayContainer = styled(DisplayContainer)``;

export const Display = styled.div`
  position: relative;
  flex: 1;
  border: ${props =>
    props.isMandatory
      ? `1px solid ${colors.redDark}`
      : `1px solid ${colors.grey2}`};
  border-radius: ${radius.small};
  padding: 0.15em 0.6em;
  font-size: 0.88rem;
  font-weight: ${typography.normal};
  line-height: 24px;
  height: 100%;
  cursor: ${props =>
    props.disabled ? "not-allowed" : props.clickToEdit ? "pointer" : "initial"};
  word-break: break-word;
  ${({ isText }) =>
    isText
      ? css`
          white-space: pre-wrap;
        `
      : css`
          > span {
            max-width: 240px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 1.95em;
            display: flex;
            align-items: center;
          }
        `}
`;

export const RevisionComment = styled(Display)`
  margin-bottom: ${spacing.space_m};
  > div {
    display: none;
  }

  :hover {
    > div {
      display: block;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: ${spacing.space_xl};
  margin-bottom: ${spacing.space_m};
  padding: ${spacing.space_s};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
`;

export const TextField = styled(Field)`
  height: initial;
  min-height: ${spacing.space_xl};
  flex-direction: column;
  align-items: flex-end;
  textarea {
    resize: none !important;
    width: 100%;
    line-height: 24px;
    border: 0;
    padding: 0 ${spacing.space_s};
    font-weight: ${typography.semiBold};
    font-size: 0.88rem;
    :disabled {
      cursor: not-allowed;
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const Done = styled.button`
  height: 24px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.active};
  line-height: ${spacing.space_l};
  font-size: 0.88rem;
  font-weight: ${typography.semiBold};
  color: ${colors.brightLight};
  outline: none;
  border-radius: ${radius.small};
`;

export const NumberField = styled(Field)`
  max-width: 285px;
  user-select: none;
`;

export const NumberInput = styled.input`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  outline: none;
  padding: 0 ${spacing.space_s};
  border: 0;
  line-height: 24px;
  font-size: 0.88rem;
  flex: 1;
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const NumberIcons = styled.div`
  display: flex;
  > svg {
    margin: 0 !important;
  }
  > button {
    margin-left: ${spacing.space_m};
  }
`;

export const UserDisplay = styled(Display)`
  padding: 0 ${spacing.space_m};
  display: flex;
  align-items: center;
  > span {
    display: inline-flex;
    height: initial;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateInput = styled.input`
  flex: 1;
  border: 0;
  outline: none;
  font-size: 0.88rem;
  line-height: 24px;
  font-weight: ${typography.normal};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

export const DateDone = styled.div`
  margin-left: ${spacing.space_m};
`;

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${spacing.space_m};
`;

export const Search = styled.input`
  width: 100%;
  height: ${spacing.space_xl};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  padding: ${spacing.space_s} ${spacing.space_m};
  line-height: 24px;
  font-size: 0.88rem;
  font-weight: ${typography.normal};
  outline: none;
`;

export const Dropdown = styled.div`
  width: 100%;
  background: ${colors.brightLight};
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: ${radius.small};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  top: ${props => (props.positionUp ? "initial" : "100%")};
  bottom: ${props => (props.positionUp ? "calc(100% + 1px)" : "initial")};
  max-height: 250px;
  overflow-y: auto;
  padding: ${spacing.space_s} ${spacing.space_m};
`;

export const UserResult = styled(Dropdown)`
  max-height: auto;
  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 10em;
    overflow: auto;
  }
`;

export const UserItemWrapper = styled.div`
  width: 100%;
  height: ${spacing.space_xl};
  display: flex;
  margin-bottom: ${spacing.space_m};
  align-items: center;
  > span > span:last-child > span {
    font-size: 0.88rem;
    font-weight: ${typography.normal};
    line-height: 24px;
  }
  cursor: pointer;
  :hover {
    background: ${colors.grey0};
  }
`;

export const PicklistContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${spacing.space_m};
`;

export const PicklistSearch = styled.input`
  width: 100%;
  height: ${spacing.space_xl};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  padding: ${spacing.space_s} ${spacing.space_m};
  line-height: 24px;
  font-size: 0.88rem;
  font-weight: ${typography.normal};
  outline: none;
`;

export const SelectMenu = styled.div`
  z-index: 3;
position: ${props => props.fromManageView ? "relative" : "absolute"};
  width: 100%;
  background: ${colors.brightLight};
  display: flex;
  flex-direction: column;
  border-radius: ${radius.small};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
`;

export const MenuOptions = styled.div`
  max-height: 230px;
  overflow-y: auto;
`;

export const MenuOption = styled.div`
  width: 100%;
  height: ${spacing.space_xl};
  padding: 0 ${spacing.space_m};
  font-weight: ${typography.normal};
  font-size: 0.88rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  :hover {
    background: ${colors.grey0};
  }
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CreateNewButton = styled.button`
  width: 100%;
  height: ${spacing.space_xl};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.active};
  font-size: 0.88rem;
  line-height: 24px;
  font-weight: ${typography.semiBold};
  border-top: 1px solid ${colors.grey2};
  background: ${colors.brightLight};
  :hover {
    background: ${colors.active};
    color: ${colors.brightLight};
    > span {
      color: ${colors.brightLight};
    }
  }
  > span {
    color: ${colors.primary};
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  background: transparent !important;
  border-width: 0;
  padding: ${spacing.space_l};
`;

export const MultiSearchInput = styled.input`
  width: 100%;
  height: 50px;
  background: transparent !important;
  border-width: 0;
  position: relative;
  padding: ${spacing.space_l};
`;

export const RemoveIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${colors.grey18};
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.67em;
  cursor: pointer;
  > svg {
    cursor: pointer !important;
    width: 10px;
    height: 10px;
  }
`;

export const SelectionMultiple = styled.div``;

export const MultipleItem = styled.div`
  padding: ${spacing.space_l};
  display: flex;
  color: ${colors.active};
  > span {
    display: inline-block;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > span {
      display: initial;
    }
  }
`;

export const AddButton = styled.button`
  width: 100%;
  height: ${spacing.space_xl};
  border-radius: ${radius.small};
  background: ${props =>
    props.isActive ? chakraTheme.colors.blue[50] : colors.grey20};
  color: ${props =>
    props.isActive ? chakraTheme.colors.blue[400] : colors.secondary};
  ${props =>
    props.isActive && `border: 2px solid ${chakraTheme.colors.blue[400]}`};
  transition: 0.1s ease-in-out all;
  font-size: 0.88rem;
  font-weight: ${typography.semiBold};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: ${colors.active};
    color: ${colors.brightLight};
  }
  :disabled {
    cursor: not-allowed;
    background: ${colors.grey28};
    color: ${colors.skyDark};
  }

  ${props => props.isMandatory && `border: 1px solid ${colors.redDark}`};
`;

export const SingleSelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${spacing.space_m};
`;

export const SingleSelect = styled.input`
  width: 100%;
  height: ${spacing.space_xl};
  border-radius: ${radius.small};
  border: 1px solid ${colors.active};
  padding: ${spacing.space_s} ${spacing.space_m};
  outline: none;
  ::placeholder {
    color: ${colors.secondary};
    font-weight: normal;
  }
`;

export const SingleSelection = styled.div`
  flex: 1;
  height: ${spacing.space_xl};
  border: 1px solid ${colors.grey2};
  border-radius: ${radius.small};
  padding: ${spacing.space_s} ${spacing.space_m};
  position: relative;
  > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 235px;
    display: inline-block;
    > span {
      font-size: 0.88rem;
      font-weight: ${typography.normal};
      line-height: 24px;
      color: ${colors.active};
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  > svg {
    position: absolute;
    right: 2px;
  }
`;

export const SingleSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiSelectionContainer = styled(SingleSelectionContainer)`
  margin-bottom: ${spacing.space_m};
`;

export const CreateNewState = styled.button`
  width: 100%;
  height: ${spacing.space_xl};
  outline: none;
  border-radius: ${radius.small};
  background: ${props => (props.highlight ? colors.active : colors.grey18)};
  margin-top: 2px;
  > span {
    display: inline-block;
    font-size: 0.88rem;
    line-height: ${spacing.space_l};
    font-weight: ${typography.semiBold};
    color: ${colors.brightLight};
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0.15em;
  right: 0;
`;

export const CheckSelect = styled.div`
  position: absolute;
  top: 2px;
  right: 16px;
  ${props => (props.isSelected ? "display: block;" : "display: none;")}
`;

export const FileUploadSelectorModal = styled.div`
  box-shadow: 1px 1px 10px rgba(21, 129, 186, 0.2);
  background-color: ${colors.brightLight};
  display: grid;
  padding: ${spacing.space_m} ${spacing.space_l} ${spacing.space_m}
    ${spacing.space_l};
  align-items: center;
  > div:nth-of-type(1) {
    padding-top: ${spacing.space_l};
    padding-bottom: ${spacing.space_m};
    &:hover {
      background: ${colors.grey2};
      padding-bottom: ${spacing.space_m};
    }
  }
  > div:nth-of-type(2) {
    padding-top: ${spacing.space_m};
    padding-bottom: ${spacing.space_l};
    &:hover {
      background: ${colors.grey2};
      padding-top: ${spacing.space_m};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  padding: ${spacing.space_m} ${spacing.space_l};
  &:hover {
    background: ${colors.grey2};
  }
`;

export const SharepointWrapper = styled(IconWrapper)`
  justify-content: space-between;
  align-items: center;
`;

export const IconBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.5em;
  }
`;

export const FileSearchWrapper = styled.div`
  height: 23.33em;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: ${spacing.space_xs};
  padding: ${spacing.space_l} ${spacing.space_m};
  background: ${colors.brightLight};
`;

export const FileListWrapper = styled.div`
  height: 17.92em;
  overflow: scroll;
  margin-top: ${spacing.space_m};
`;

export const FileWrapper = styled.div`
  height: 4.17em;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${colors.grey2};
  }
`;
export const FilePreview = styled.div`
  width: 3.33em;
  height: 3.33em;
  background-color: ${colors.brightLight};
  border-radius: ${spacing.space_xs};
  margin-right: 1.08em;
  display: flex;
  align-items: center;
  justify-content: center;

  > div > svg {
    width: 2.92em;
    height: 3.33em;
  }

  > img {
    width: 3.33em;
    height: 3.33em;
  }
`;
export const Name = styled.div`
  font-size: 0.88rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
`;
export const FileInfo = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${colors.inkLighter};
`;

export const FileData = styled.div`
  display: grid;
`;

export const MainFilesWrapper = styled.div`
  max-width: 100%;
  border-radius: ${spacing.space_xs};
  position: relative;
`;

export const FileThumbnail = styled.div`
  border-right: 1px solid ${colors.inkLightest};
  max-height: 100%;
  min-width: 5.33em;
  width: 5.33em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > div > svg {
    width: 3.75em;
    height: 4.17em;
  }
  > img {
    width: 5.33em;
    height: 5.17em;
    border-radius: 0px;
  }
`;

export const FileDetailsWrapper = styled.div`
  display: grid;
  padding: 0 0.33em;
  width: 100%;
`;

export const RemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NameAndRemoveWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1;
  min-width: 0;
  span {
    width: 80%;
  }
`;

export const NameAndFileIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  flex: 1;
  min-width: 0;
  span {
    width: 80%;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Download = styled.div`
  padding-bottom: 0.33em;
  justify-content: center;
  cursor: pointer;
  display: flex;
  visibility: hidden;
`;

export const DownloadNoPreview = styled(Download)`
  padding-bottom: 0px;
`;

export const SelectedFileWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  border: 1px solid ${colors.sky};
  margin-bottom: 1em;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    border: 1px solid ${colors.inkLightest};
    ${Download}, ${DownloadNoPreview}, ${RemoveWrapper} {
      visibility: visible;
    }
    ${FileThumbnail} {
      border-right: 1px solid {colors.inkLightest};
    }
  }
`;

export const NoPreviewSelectedFileWrapper = styled(SelectedFileWrapper)`
  width: 100%;
  height: 2.67em;
  display: flex;
  align-items: center;
  padding: 0 0.67em;
  justify-content: space-between;
  > ${NameAndRemoveWrapper} > ${Name} {
    margin-left: 0.67em;
  }
  cursor: ${props => (props.disabled ? "not-allowed" : "initial")};
`;

export const FileIconWrapper = styled.div`
  > ${Name} {
    line-height: 0.88rem;
  }
  > svg {
    display: flex;
    align-items: center;
    width: 1.08em;
    height: 1.5em;
  }
`;

export const SectionInfoWrapper = styled.div`
  display: flex;
  background-color: ${colors.grey22};
  padding-left: ${spacing.space_s};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.grey4};
  border-radius: ${radius.medium};
  padding: 1px ${spacing.space_m};
`;

export const Input = styled(StyledInput)`
  border: none;
  padding: ${spacing.space_s} ${spacing.space_s} ${spacing.space_s}
    ${spacing.space_m};
  :hover,
  :focus {
    outline: none;
    border: none;
  }
  ::placeholder {
    padding-left: ${spacing.space_xs};
  }
`;

export const WorkflowTitleWrapper = styled.div`
  width: 17.58em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${spacing.space_s};
`;

export const WorkflowWrapper = styled.div`
  width: 8.33em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SeqWrapper = styled.div`
  padding-left: ${spacing.space_xs};
`;

export const FieldContainer = styled.div`
  ${props => props.isDragActive && highlightBorder};
  ${props => props.isDragActive && `padding: 4px;`};
  padding-left: ${props => props.isSectionField && spacing.space_m};
  padding-bottom: ${props => props.isSectionField && spacing.space_m};
`;

export const SectionGrid: GridProps = {
  width: "100%",
  position: "relative",
  paddingRight: spacing.space_m
};
