// @flow

import React from "react";
import { Container, Label, Info } from "./styles";

type Props = {
  message: string
};

const Banner = ({ message = "" }: Props) => {
  return (
    <Container>
      <Info>!</Info>
      <Label>{message}</Label>
    </Container>
  );
};

export default Banner;
