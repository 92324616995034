// @flow

import { connect } from "react-redux";

import Table from "src/components/file/Manage/Table";
import { getSearchResult } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  searchResults: getSearchResult(app)
});

export default connect(mapStateToProps)(Table);
