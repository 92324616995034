import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

import { Overlay } from "src/styles/modal";

const Overlays = styled(Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorModal = styled.div`
  z-index: 999;
  position: absolute;
  height: 152px;
  width: 520px;
  padding: 0px;
  overflow: visible;
  border: none;
  border-radius: 0px;
  background-color: ${colors.brightLight};
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  background-color: ${colors.grey0};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${spacing.space_xl};
  > h2 {
    margin: 0px;
  }
  > svg {
    stroke: black !important;
    cursor: pointer !important;
    > g {
      stroke: black;
    }
  }
`;

const ErrorBody = styled.div`
  display: flex;
  align-items: center;
  height: 4.6em;
  padding-left: 2.35em;
  font-size: 0.88rem;
  > svg {
    margin-right: ${spacing.space_m};
  }
`;

export { ErrorModal, Overlays, Header, ErrorBody };
