// @flow

import { useState, useRef } from "react";

const useThrottle = (callback: Function, delay: number = 1000) => {
  const [shouldWait, setShouldWait] = useState<boolean>(false);
  const waitingArgsRef = useRef(null);

  const timeout = () => {
    if (waitingArgsRef.current === null) {
      setShouldWait(false);
    } else {
      callback(...waitingArgsRef.current);
      waitingArgsRef.current = null;
      setTimeout(timeout, delay);
    }
  };

  const throttledCallback = (...args: any) => {
    if (shouldWait) {
      waitingArgsRef.current = args;
      return;
    }

    callback(...args);
    setShouldWait(true);

    setTimeout(timeout, delay);
  };

  return throttledCallback;
};

export default useThrottle;
