// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import { SortableList as StyledSortableList } from "./styles";

type Props = {
  right: boolean,
  items: Array<number>,
  deleteTile: Function,
  editTile: Function
};

const SortableList = SortableContainer(
  ({ items, right, deleteTile, editTile }: Props) => {
    return (
      <StyledSortableList>
        {(items || []).map((value, index) => (
          <SortableItem
            right={right}
            key={`item-${value}`}
            index={index}
            value={value}
            deleteTile={deleteTile}
            editTile={editTile}
          />
        ))}
      </StyledSortableList>
    );
  }
);

export default SortableList;
