// @flow

import React from "react";

type Props = {
  color?: string
};

const ShareLink = ({ color, ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <path
        d="M12.6667 11.3092C12.0756 11.3092 11.5467 11.5502 11.1422 11.9277L5.59667 8.59438C5.63556 8.40964 5.66667 8.2249 5.66667 8.03213C5.66667 7.83936 5.63556 7.65462 5.59667 7.46988L11.08 4.16867C11.5 4.57028 12.0522 4.81928 12.6667 4.81928C13.9578 4.81928 15 3.74297 15 2.40964C15 1.07631 13.9578 0 12.6667 0C11.3756 0 10.3333 1.07631 10.3333 2.40964C10.3333 2.60241 10.3644 2.78715 10.4033 2.97189L4.92 6.27309C4.5 5.87149 3.94778 5.62249 3.33333 5.62249C2.04222 5.62249 1 6.69879 1 8.03213C1 9.36546 2.04222 10.4418 3.33333 10.4418C3.94778 10.4418 4.5 10.1928 4.92 9.79116L10.4578 13.1325C10.4189 13.3012 10.3956 13.4779 10.3956 13.6546C10.3956 14.9478 11.4144 16 12.6667 16C13.9189 16 14.9378 14.9478 14.9378 13.6546C14.9378 12.3614 13.9189 11.3092 12.6667 11.3092Z"
        fill={color || "#637381"}
      />
    </svg>
  );
};

export default ShareLink;
