// @flow

import React from "react";
import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { TabArea, Tab } from "src/styles";

type Props = {
  mine: boolean
};

const Tabs = ({ mine }: Props) => {
  if (mine) {
    return (
      <TabArea>
        <Tab active={mine}>My Files</Tab>
        <Link to={{ type: atypes.SET_FILE_REQUEST, payload: {} }}>
          <Tab>All Files</Tab>
        </Link>
      </TabArea>
    );
  }

  return (
    <TabArea>
      <Link
        to={{
          type: atypes.SET_FILE_REQUEST,
          payload: {
            query: {
              mine: true
            }
          }
        }}
      >
        <Tab>My Files</Tab>
      </Link>
      <Tab active={!mine}>All Files</Tab>
    </TabArea>
  );
};

export default Tabs;
