import React from "react";
import { SettingsIcon } from "@chakra-ui/icons";
import * as colors from "src/styles/constants/colors";
import { StyledSettingsIcon } from "./styles";

export default function SettingsPlaceholder() {
  return (
    <StyledSettingsIcon>
      <SettingsIcon alignSelf="center" w={48} h={48} color={colors.grey41} />
    </StyledSettingsIcon>
  );
}
