// @flow
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import type { RoomId, ChecklistId } from "src/types";
import {
  removeHiddenFields,
  addFieldDetails,
  extractVisibleFieldIds
} from "./utils";
import {
  getFieldsBehavior,
  getChecklistFieldsById,
  getRoomHiddenFields,
  getRoomChecklistFields
} from "src/reducers";
import { setVisibleFields } from "src/actions/checklist";

import ChecklistLayout from "src/components/Dock/Checklist/WideChecklist/Layout";

type Props = {
  id: ChecklistId,
  roomId: RoomId
};

export default function WideChecklist({ id, roomId }: Props) {
  const dispatch = useDispatch();
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const behaviorByField = useSelector(({ app }) => getFieldsBehavior(app));
  const hiddenFields = useSelector(({ app }) =>
    getRoomHiddenFields(app, roomId)
  );
  const fields = useSelector(({ app }) => getRoomChecklistFields(app, id));
  const fieldDetails = addFieldDetails({ fields, fieldsById });

  const visibleFields = removeHiddenFields({
    fields: fieldDetails,
    roomId,
    fieldsById,
    behaviorByField,
    hiddenFields
  });

  useEffect(() => {
    const visibleFieldIds = extractVisibleFieldIds(visibleFields);
    dispatch(setVisibleFields(visibleFieldIds));
  }, [visibleFields]);

  return (
    <ChecklistLayout id={id} roomId={roomId} visibleFields={visibleFields} />
  );
}
