// @flow

import React from "react";
import * as R from "ramda";
import type { UID } from "src/types";

import MultiSelect2 from "src/components/ChakraPro/MultiSelect2";
import UserPillMedium from "src/containers/user/ImageLabel/Medium";
import UserPillSmall from "src/containers/user/ImageLabel/Small";

export type Props = {
  value: UID[],
  options: UID[],
  superset?: UID[],
  onChange: Function,
  searchUser: string => void
};

const UserMultipleSelect = ({
  value,
  options,
  superset,
  onChange,
  searchUser,
  ...restProps
}: Props) => {
  return (
    <MultiSelect2
      value={value}
      options={superset ? R.intersection(superset, options) : options}
      itemHandler={onChange}
      onQueryChange={searchUser}
      itemRenderer={item => <UserPillSmall uid={item} />}
      {...restProps}
    >
      {options.map(user => (
        <UserPillMedium key={user} uid={user} value={user} />
      ))}
    </MultiSelect2>
  );
};

export default UserMultipleSelect;
