// @flow

import React from "react";
import { connect } from "react-redux";

import { SelectHighlight, SelectCircle } from "./styles";
import Icon from "src/icons";
import { selectMessage, deselectMessage } from "src/actions/chatmenu";

import type { AppState, MessageID } from "src/types";
import {
  getSelectedAction,
  getIsSrwMobile,
  getSelectedMessages
} from "src/reducers";

type Props = {
  id: MessageID,
  selectedAction: string,
  selectedMessages: Array<MessageID>,
  isSrwMobile: boolean
};

const SelectIndicator = ({
  id,
  selectedAction,
  selectedMessages,
  isSrwMobile
}: Props) => {
  const isMessageSelected = selectedAction && selectedMessages.includes(id);
  return (
    <>
      {isMessageSelected ? <SelectHighlight isSrwMobile={isSrwMobile} /> : null}
      {selectedAction && selectedAction !== "MOBILE" ? (
        <SelectCircle>
          <Icon type="checkmark" highlight={isMessageSelected} />
        </SelectCircle>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  selectedAction: getSelectedAction(app),
  selectedMessages: getSelectedMessages(app),
  isSrwMobile: getIsSrwMobile(app)
});

export default connect(mapStateToProps, {
  select: selectMessage,
  deselect: deselectMessage
})(SelectIndicator);
