// @flow

import styled from "@emotion/styled";

import { SmallText } from "src/styles/input";
import * as colors from "src/styles/constants/colors";

export const Title = styled.div`
  border: 1px solid ${colors.brightLight};
  padding-left: 8px;
  strong {
    padding-left: 0.3em;
    color: ${colors.primary};
    font-size: 18px;
  }
`;

export const Edit = styled.div`
  border: 1px solid ${colors.grey5};
  padding-left: 8px;
  ${SmallText} {
    padding: 0;
    font-size: 18px;
    padding-left: 0.3em;
    min-width: 10px;
  }
`;
