// @flow

import React from "react";

const EditBlue = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.495V14.995H4.5L11.8733 7.62163L9.37333 5.12163L2 12.495ZM13.8067 5.6883C14.0667 5.4283 14.0667 5.0083 13.8067 4.7483L12.2467 3.1883C11.9867 2.9283 11.5667 2.9283 11.3067 3.1883L10.0867 4.4083L12.5867 6.9083L13.8067 5.6883Z"
      fill="#2F80ED"
    />
  </svg>
);

export default EditBlue;
