// @flow

import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import Measure from "react-measure";
import { Text } from "@chakra-ui/react";

import {
  DeleteOptions,
  InfoContainer,
  CloseMenu,
  ButtonContainer,
  InputButton
} from "./styles";

import ButtonLoader from "src/components/ButtonLoader";
import ForwardModal from "src/components/chatroom/Input/ForwardModal";

import { showForwardModal } from "src/actions/modal";
import {
  hideSelectOptions,
  deleteMessage,
  hideMessage
} from "src/actions/chatmenu";

import close from "src/img/closeOptions.svg";

import type { AppState, MessageID, RoomId } from "src/types";

type Props = {
  showSelect: string,
  roomId: RoomId,
  selectedMessages: Array<MessageID>,
  deletableMessages: Array<MessageID>,
  forwardModal: boolean,
  hideSelect: Function,
  deleteMssg: Function,
  hideMssg: Function,
  showForward: Function
};

const SelectionMenu = ({
  showSelect,
  roomId,
  selectedMessages,
  deletableMessages,
  forwardModal,
  hideSelect,
  deleteMssg,
  hideMssg,
  showForward
}: Props) => {
  const [hideIsLoading: boolean, setHideIsLoading: Function] = useState(false);
  const [deleteIsLoading: boolean, setDeleteIsLoading: Function] = useState(
    false
  );
  const [optionsWidth, setOptionsWidth] = useState(0);

  const initiateHide = useCallback(() => {
    setHideIsLoading(true);
    hideMssg(roomId);
  }, [hideMssg, roomId]);

  const initiateDelete = useCallback(() => {
    setDeleteIsLoading(true);
    deleteMssg(roomId);
  }, [deleteMssg, roomId]);
  const handleResize = content => {
    setOptionsWidth(content.bounds.width);
  };

  if (showSelect === "DELETE") {
    return (
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => {
          return (
            <DeleteOptions ref={measureRef} optionsWidth={optionsWidth}>
              <InfoContainer>
                <CloseMenu
                  src={close}
                  onClick={hideSelect}
                  optionsWidth={optionsWidth}
                />
                {selectedMessages.length} Selected
              </InfoContainer>
              <ButtonContainer optionsWidth={optionsWidth}>
                <ButtonLoader
                  handleClick={initiateHide}
                  disabled={!selectedMessages.length || deleteIsLoading}
                  isLoading={hideIsLoading}
                  type="button"
                >
                  Hide{" "}
                  {optionsWidth > 600 && (
                    <Text as="span">{selectedMessages.length} selected</Text>
                  )}
                </ButtonLoader>
                Or
                <ButtonLoader
                  handleClick={initiateDelete}
                  disabled={
                    !deletableMessages.length ||
                    deletableMessages.length !== selectedMessages.length ||
                    hideIsLoading
                  }
                  isLoading={deleteIsLoading}
                  type="button"
                >
                  Delete{" "}
                  {optionsWidth > 600 && (
                    <Text as="span">{deletableMessages.length} recent</Text>
                  )}
                </ButtonLoader>
              </ButtonContainer>
            </DeleteOptions>
          );
        }}
      </Measure>
    );
  }

  if (showSelect === "FORWARD") {
    return (
      <>
        <DeleteOptions optionsWidth={optionsWidth}>
          <InfoContainer>
            <CloseMenu
              src={close}
              onClick={hideSelect}
              optionsWidth={optionsWidth}
            />
            {selectedMessages.length} Selected
          </InfoContainer>
          <InputButton
            onClick={showForward}
            disabled={!selectedMessages.length}
          >
            Forward{" "}
            {optionsWidth > 600 && (
              <Text as="span">{selectedMessages.length} selected</Text>
            )}
          </InputButton>
        </DeleteOptions>
        {forwardModal ? <ForwardModal /> : null}
      </>
    );
  }

  return null;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  selectedMessages: app.chatRooms.selectedMessages,
  deletableMessages: app.chatRooms.deletableMessages,
  forwardModal: app.modal.forward
});

export default connect(mapStateToProps, {
  hideSelect: hideSelectOptions,
  deleteMssg: deleteMessage,
  hideMssg: hideMessage,
  showForward: showForwardModal
})(SelectionMenu);
