// @flow

import { connect, useDispatch } from "react-redux";
import React, { useCallback, useState } from "react";

import Comment from "./Comment";
import Group from "./Group";
import Approval from "./Approval";
import Conversation from "./Conversation";
import Workflow from "./Workflow";

import { setAttribute } from "src/actions/chatroom";

import { getChatroomType, isStatusDisabled } from "src/reducers";

import type { ConversationType, AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  location: string,
  type: ConversationType,
  _handleClose: Function,
  disabled: boolean
};

const Items = ({ roomId, location, type, _handleClose, disabled }: Props) => {
  const [modal, showModal] = useState(false);
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();

  const handleStatus = useCallback(
    (newStatus: number) => {
      setStatus(newStatus);
      showModal(true);
    },
    [status, showModal, setStatus, _handleClose]
  );

  const handleArchive = (value: boolean) => {
    dispatch(
      setAttribute(
        roomId,
        {
          archived: value
        },
        location
      )
    );
    handleClose();
  };

  const handleClose = useCallback(() => {
    showModal(false);
    setStatus(null);
    _handleClose();
  }, [showModal, setStatus, _handleClose]);

  const workflowOrWorkflowFeed = disabled ? (
    <Group roomId={roomId} handleStatus={handleStatus} />
  ) : (
    <Workflow
      roomId={roomId}
      handleStatus={handleStatus}
      handleArchive={handleArchive}
      location={location}
    />
  );

  const getStatus = useCallback(() => {
    switch (type) {
      case "group":
        return <Group roomId={roomId} handleStatus={handleStatus} />;
      case "approval":
        return <Approval roomId={roomId} handleStatus={handleStatus} />;
      case "workflow":
        return workflowOrWorkflowFeed;
      default:
        return <Conversation roomId={roomId} handleStatus={handleStatus} />;
    }
  }, [type, handleStatus]);

  return (
    <>
      {getStatus()}
      {modal ? (
        <Comment
          showModal={modal}
          status={status}
          roomId={roomId}
          location={location}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  type: getChatroomType(app, props.roomId),
  disabled: isStatusDisabled(app, props.roomId)
});

export default connect(mapStateToProps)(Items);
