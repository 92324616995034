// @flow

import React, { useState } from "react";
import usePortal from "react-useportal";
import { useSelector, useDispatch } from "react-redux";
import { hideFileUploadModal, uploadFileToChatroom } from "src/actions/file";
import { getFilesWithSrc, getFileExtension } from "../../utils/file";

import type { RoomId } from "src/types";
import { getFileUploadModalFiles } from "src/reducers";

import * as colors from "src/styles/constants/colors";
import Icons from "src/icons";
import {
  StyledFileIconWrapper,
  previewModalContainer,
  previewImageContainer,
  previewImage,
  addButtonContainer
} from "./styles";
import { getFileType } from "src/utils/file";
import { HStack, Image, Text, Input, Flex, Box } from "@chakra-ui/react";
import {
  Content as StyledContent,
  PrimaryButton,
  Overlay,
  SecondaryFooter,
  HeadingWrapper,
  Secondary,
  Form
} from "src/components/Manage/Reports/Modal/styles";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import ButtonLoader from "src/components/ButtonLoader";
import Upload from "src/containers/chatroom/Upload";

type Props = {
  roomId: RoomId
};

const PreviewModal = ({ roomId }: Props) => {
  const dispatch = useDispatch();
  const { Portal } = usePortal();
  const previewFiles = useSelector(({ app }) =>
    getFileUploadModalFiles(app, roomId)
  );
  const conversationModalRoomId = useSelector(
    ({ app }) => app.conversationModal.roomId
  );
  const [selectedFile, setSelectedFile] = useState(previewFiles[0]?.name);
  const [comments, setComment] = useState({});
  const [files, setFiles] = useState(getFilesWithSrc(previewFiles || []));
  const [fileList, setFileList] = useState(previewFiles);

  const handleCommentChange = event => {
    event.persist();
    setComment(prevComments => ({
      ...prevComments,
      [selectedFile]: event.target.value
    }));
  };

  const closeModal = () => {
    dispatch(
      hideFileUploadModal({
        viewId: roomId
      })
    );
  };

  const uploadFiles = event => {
    event.preventDefault();
    dispatch(
      uploadFileToChatroom(
        dispatch,
        "modal",
        fileList,
        // If currently in conversation modal, use that room's id
        conversationModalRoomId || roomId,
        comments
      )
    );
    closeModal();
  };

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={closeModal}>
          <StyledContent>
            <Form onSubmit={null}>
              <HeadingWrapper>Upload files</HeadingWrapper>
              <HStack gap={2} {...previewModalContainer}>
                {files &&
                  files.map(file => (
                    <>
                      {getFileType(file.name) === "image" ? (
                        <Flex
                          {...previewImageContainer}
                          onClick={() => setSelectedFile(file.name)}
                        >
                          {/* Show default icon for heic files. */}
                          {getFileExtension(file.name) === "heic" ? (
                            <StyledFileIconWrapper>
                              <Box
                                border={
                                  selectedFile === file.name
                                    ? `2px solid ${colors.blue}`
                                    : null
                                }
                              >
                                <Icons
                                  type="fileIcons"
                                  icon={getFileType(file.name)}
                                />
                              </Box>
                            </StyledFileIconWrapper>
                          ) : (
                            <Image
                              src={file.src}
                              {...previewImage}
                              border={
                                selectedFile === file.name
                                  ? `2px solid ${colors.blue}`
                                  : null
                              }
                            />
                          )}
                          <Text>{file.name}</Text>
                        </Flex>
                      ) : (
                        <StyledFileIconWrapper>
                          <Box
                            objectFit="cover"
                            onClick={() => setSelectedFile(file.name)}
                            border={
                              selectedFile === file.name
                                ? `2px solid ${colors.blue}`
                                : null
                            }
                          >
                            <Icons
                              type="fileIcons"
                              icon={getFileType(file.name)}
                            />
                          </Box>
                          <Text>{file.name}</Text>
                        </StyledFileIconWrapper>
                      )}
                    </>
                  ))}
              </HStack>
              <Flex {...addButtonContainer}>
                <Upload
                  location="chatroom"
                  multipleFiles={true}
                  handleFiles={moreFiles => {
                    setFiles(prevFiles => [
                      ...prevFiles,
                      ...getFilesWithSrc(moreFiles)
                    ]);
                    setFileList(prevFileList => [
                      ...prevFileList,
                      ...moreFiles
                    ]);
                  }}
                  render={openFileBrowser => (
                    <PrimaryButton onClick={openFileBrowser}>
                      <ButtonLoader type="button" color={colors.blue}>
                        Add more
                      </ButtonLoader>
                    </PrimaryButton>
                  )}
                />
              </Flex>
              <ModalFormBody>
                <Text mb="8px">Optional comment:</Text>
                <Input
                  placeholder="Enter optional comment here."
                  mb="8px"
                  value={comments[selectedFile] || ""}
                  onChange={handleCommentChange}
                />
              </ModalFormBody>
              <SecondaryFooter>
                <PrimaryButton onClick={uploadFiles}>
                  <ButtonLoader
                    type="submit"
                    color={colors.blue}
                    onClick={uploadFiles}
                    dataCy="file-upload-button"
                  >
                    Upload
                  </ButtonLoader>
                </PrimaryButton>
                <Secondary type="button" onClick={closeModal}>
                  Cancel
                </Secondary>
              </SecondaryFooter>
            </Form>
          </StyledContent>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

export default PreviewModal;
