// @flow

import React from "react";
import usePortal from "react-useportal";

import { StyledReports } from "./styles";
import Table from "./Table";
import MenuBar from "./MenuBar";
import Navigation from "src/components/Manage/Navigation";

const Reports = () => {
  const { Portal } = usePortal();

  return (
    <Portal>
      <StyledReports>
        <Navigation tab="user-reports" />
        <MenuBar />
        <Table />
      </StyledReports>
    </Portal>
  );
};

export default Reports;
