// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const BreadCrumbs = styled.div`
  line-height: 1.6;
  white-space: nowrap;
  display: flex;
  height: 22px;
  font-size: 0.625rem;
  > div:nth-of-type(2) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > div {
    > a > span:nth-of-type(2) {
      color: ${colors.primary};
      padding: 0 2px;
    }
  }
`;

export default BreadCrumbs;
