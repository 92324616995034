// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${colors.brightLight};
  padding: 0;
`;

export const loaderStyles = {
  margin: 0,
  width: "16px",
  height: "16px",
  borderWidth: "2px"
};

export const containerStyle = {
  width: "fit-content",
  paddingLeft: "4px",
  paddingRight: "4px"
};
