// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const FieldHeader = styled.div`
  font-weight: bold;
  font-size: 0.88rem;
  margin-bottom: ${spacing.space_m};
`;

export const Optional = styled.span`
  font-weight: 300;
  font-size: 13px
  color: ${colors.secondary};
`;

export const Fields = styled.div`
  margin: 8px;
  overflow: auto;
  max-height: 81vh;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  > input {
    width: 100%;
  }
`;

export const FieldFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 2em;
`;

export const Switch = styled.div`
  float: right;
  padding-right: 2px;
`;

export const MultipleSelection = styled.div`
  margin-top: 8px;
  > div {
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;
  }
`;

export const AdvanceBtn = styled.button`
  color: #3182ce;
  font-weight: 700;
  cursor: pointer;
  background: none;
  margin: 10px 0px 16px;
`;

export const SortRadio = styled.span`
  color: #454f5b;
  font-size: 0.875rem;
`;

export const LabelInfo = styled.span`
  color: #919eab;
`;
