// @flow

import React from "react";

const Update = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11 16.5L14.6364 12.5H11.9091V3.5H10.0909V12.5H7.36364L11 16.5ZM19.1818 3.5H13.7273V5.49H19.1818V19.52H2.81818V5.49H8.27273V3.5H2.81818C1.81818 3.5 1 4.4 1 5.5V19.5C1 20.6 1.81818 21.5 2.81818 21.5H19.1818C20.1818 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1818 3.5 19.1818 3.5Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Update;
