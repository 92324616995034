// @flow

import { connect } from "react-redux";
import React from "react";
import type { AppState } from "src/types";
import { openFormModal } from "src/actions/forms";

import Row from "./Row";

type Props = {
  allForms: any,
  _fetchForm: Function,
  setFormDeletionModal: Function
};

const Body = ({ allForms, setFormDeletionModal }: Props) => {
  return allForms && allForms.length > 0 ? (
    <tbody>
      {allForms.map(id => (
        <Row id={id} key={id} setFormDeletionModal={setFormDeletionModal} />
      ))}
    </tbody>
  ) : null;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  allForms: app.form.searchResults
});

export default connect(mapStateToProps, {
  _openFormModal: openFormModal
})(Body);
