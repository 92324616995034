// @flow

import styled from "@emotion/styled";
import { rgba } from "polished";

import { Box } from "src/styles/box";
import * as colors from "src/styles/constants/colors";

export const Select = styled.div`
  position: relative;
  border: 1px solid ${colors.grey4};
  padding: 4px 8px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  :focus-within {
    border: 1px solid ${colors.grey5};
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  max-width: 10em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  > span {
    font-size: 10px;
    margin-right: 3px;
  }

  > span:nth-child(2) {
    display: flex;
    align-items: center;
  }

  > svg {
    height: 1em;
    width: 1em;
    position: absolute;
    top: 1px;
    right: 2px;
  }
`;

export const Dropdown = styled(Box)`
  position: absolute;
  min-width: 126px;
  z-index: 1;
  top: 2rem;
  right: 0;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin: 0.4em 0 0.8em 0;
  padding: 2px 8px;
  width: 10em;
  cursor: pointer;

  background-color: ${props =>
    props.active ? rgba(colors.secondary, 0.2) : rgba(colors.success, 0.2)};
  color: ${props => (props.active ? colors.secondary : colors.success)};

  > div > label input[type="checkbox"] + div {
    width: 12px;
    height: 12px;
  }
  > span {
    position: relative;
    top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
