// @flow

import React from "react";

type Props = {};

const PrimaryRecord = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="white"
      viewBox="0 0 16 16"
      {...restProps}
    >
      <path d="M6.5 11H2.75a.75.75 0 110-1.5H6.5a.75.75 0 010 1.5zM13.25 11H9.5a.75.75 0 110-1.5h3.75a.75.75 0 110 1.5zM6.5 14H2.75a.75.75 0 110-1.5H6.5a.75.75 0 110 1.5zM13.25 14H9.5a.75.75 0 110-1.5h3.75a.75.75 0 110 1.5zM13.25 2H2.75a.75.75 0 00-.75.75v4.5a.75.75 0 00.75.75h10.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75zM6.875 5.75h-3a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375h3a.375.375 0 01.375.375v.75a.375.375 0 01-.375.375zm5.25 0h-3a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375h3a.375.375 0 01.375.375v.75a.375.375 0 01-.375.375z"></path>
    </svg>
  );
};

export default PrimaryRecord;
