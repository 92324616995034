// @flow

import React, { useCallback } from "react";

type Props = {
  status?: string
};

const ReadStatus = ({ status }: Props) => {
  const renderDelivered = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        viewBox="0 0 9 9"
      >
        <g fill="#888888" fillRule="nonzero">
          <path d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z" />
          <path d="M8.138 3.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177 4.43-4.177z" />
        </g>
      </svg>
    ),
    []
  );
  const renderUnread = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="6"
        viewBox="0 0 9 6"
      >
        <path
          fill="#888"
          fillRule="nonzero"
          d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z"
        />
      </svg>
    ),
    []
  );
  const renderRead = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        viewBox="0 0 9 9"
      >
        <g fill="#0ABC8A" fillRule="nonzero">
          <path d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z" />
          <path d="M8.138 3.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177 4.43-4.177z" />
        </g>
      </svg>
    ),
    []
  );

  switch (status) {
    case "read":
      return renderRead();
    case "unread":
      return renderUnread();
    case "delivered":
    default:
      return renderDelivered();
  }
};

export default ReadStatus;
