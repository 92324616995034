// @flow

import React, { useEffect } from "react";

type Props = {
  children: any,
  isVisible: boolean,
  incrementCount: Function
};

const LastElement = ({ children, isVisible, incrementCount }: Props) => {
  useEffect(() => {
    if (isVisible) {
      incrementCount();
    }
  }, [isVisible]);

  return <>{children}</>;
};

export default LastElement;
