// @flow

import * as atypes from "src/constants/actionTypes";

import type { UID, Action, DisableUserModal } from "src/types";

const resetSearch = (): Action => ({
  type: atypes.RESET_USER_SEARCH,
  payload: {}
});

const editDisplayName = (payload: { displayName?: string }): Action => ({
  type: atypes.EDIT_DISPLAY_NAME,
  payload
});

const setContact = (meta: { query?: string }): Action => ({
  type: atypes.SET_CONTACTS_REQUEST,
  payload: {},
  meta
});

const getDepartments = () => ({
  type: atypes.GET_DEPARTMENTS_REQUEST,
  payload: {}
});

const createDepartment = (department: string) => ({
  type: atypes.CREATE_DEPARTMENT,
  payload: {
    department
  }
});

const togglePeopleRowSelection = (row: number) => ({
  type: atypes.TOGGLE_PEOPLE_ROW_SELECTION,
  payload: { row }
});

const clearPeopleRowSelection = () => ({
  type: atypes.CLEAR_PEOPLE_ROW_SELECTION,
  payload: {}
});

const getUserGroups = (uid: UID) => ({
  type: atypes.GET_USER_GROUP_REQUEST,
  payload: {
    uid
  }
});

const getUniqueContactsValues = () => ({
  type: atypes.GET_UNIQUE_CONTACT_VALUES_REQUEST,
  payload: {}
});

const searchDepartment = (search: string) => ({
  type: atypes.SEARCH_DEPARTMENT_REQUEST,
  payload: {
    search
  }
});

const resendInvite = (uid: UID) => ({
  type: atypes.RESEND_INVITE_REQUEST,
  payload: { uid }
});

const getUserProcess = (uid: UID) => ({
  type: atypes.GET_USER_PROCESS_REQUEST,
  payload: { uid }
});

const getOnboardingStatus = (uid: UID) => ({
  type: atypes.GET_USER_ONBOARDING_STATUS_REQUEST,
  payload: { uid }
});

const updateOtherUserDetails = (
  uid: UID,
  value: { department?: string | number, phoneNumber?: string }
) => ({
  type: atypes.UPDATE_OTHER_USER_DETAILS_REQUEST,
  payload: {
    uid,
    value
  }
});

const disableUser = (uid: UID) => ({
  type: atypes.DISABLE_USER_REQUEST,
  payload: {
    uid
  }
});

const enableUser = (uid: UID) => ({
  type: atypes.ENABLE_USER_REQUEST,
  payload: {
    uid
  }
});

const makeAdmin = (uid: UID) => ({
  type: atypes.MAKE_ADMIN_REQUEST,
  payload: {
    uid
  }
});

const makeMember = (uid: UID) => ({
  type: atypes.MAKE_MEMBER_REQUEST,
  payload: {
    uid
  }
});

const showDisableUserModal = ({
  uid,
  adminModal,
  memberModal
}: DisableUserModal) => ({
  type: atypes.SHOW_DISABLE_USER_MODAL,
  payload: { uid, adminModal, memberModal }
});

const hideDisableUserModal = ({
  uid,
  adminModal,
  memberModal
}: DisableUserModal) => ({
  type: atypes.HIDE_DISABLE_USER_MODAL,
  payload: { uid, adminModal, memberModal }
});

export {
  makeAdmin,
  makeMember,
  hideDisableUserModal,
  showDisableUserModal,
  enableUser,
  disableUser,
  updateOtherUserDetails,
  getOnboardingStatus,
  getUserProcess,
  resetSearch,
  editDisplayName,
  setContact,
  getDepartments,
  createDepartment,
  resendInvite,
  clearPeopleRowSelection,
  togglePeopleRowSelection,
  getUserGroups,
  getUniqueContactsValues,
  searchDepartment
};
