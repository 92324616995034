// @flow

import * as R from "ramda";
import { toast } from "react-toastify";
import type {
  Action,
  OrgCloneWizardState,
  OrgCloneTemplateState
} from "src/types";
import * as atypes from "src/constants/actionTypes";
import { orgCloneWizardSteps } from "src/constants";
import { combineReducers } from "redux";

const wizardInitialState = {
  step: orgCloneWizardSteps.email,
  error: null,
  stepChangeInProgress: false,
  fields: {
    email: { value: "", error: null },
    password: { value: "", error: null },
    confirmPassword: { value: "", error: null },
    firstName: { value: "", error: null },
    lastName: { value: "", error: null },
    orgName: { value: "", error: null },
    colleagues: [
      { value: "", error: null },
      { value: "", error: null },
      { value: "", error: null },
      { value: "", error: null }
    ]
  },
  cloneStatus: null
};

const wizard = (
  state: OrgCloneWizardState = wizardInitialState,
  { type, payload }: Action
): OrgCloneWizardState => {
  switch (type) {
    case atypes.ORG_CLONE_WIZARD_UPDATE_DATA:
      return R.assocPath(
        ["fields", ...payload.prop, "value"],
        payload.value,
        state
      );

    case atypes.ORG_CLONE_CLEAR_DATA_ERROR:
      return R.mergeDeepRight(state, {
        // $FlowFixMe
        fields: R.mergeAll(
          payload.map(property => ({
            [property]: {
              error: null
            }
          }))
        )
      });

    case atypes.ORG_CLONE_WIZARD_SET_STEP:
      return R.mergeDeepRight(state, {
        step: payload,
        stepChangeInProgress: false,
        error: null
      });

    case atypes.ORG_CLONE_WIZARD_GO_FORWARD_REQUEST:
      return R.mergeDeepRight(state, {
        stepChangeInProgress: true,
        error: null
      });

    case atypes.ORG_CLONE_WIZARD_GO_FORWARD_ERROR: {
      if (R.isEmpty(payload.path)) toast.error(payload.error);

      let newState = R.assocPath(
        [...payload.path, "error"],
        payload.error,
        state
      );

      return R.mergeDeepRight(newState, {
        stepChangeInProgress: false
      });
    }

    case atypes.ORG_CLONE_WIZARD_GO_BACKWARD_REQUEST:
      return R.mergeDeepRight(state, {
        error: null
      });

    case atypes.ORG_CLONE_SET_CLONE_STATUS:
      return R.mergeDeepRight(state, {
        step: orgCloneWizardSteps.creatingOrg,
        cloneStatus: payload
      });

    default:
      return state;
  }
};

const templateInitialState = {
  fetching: true,
  error: null,
  data: null
};

const template = (
  state: OrgCloneTemplateState = templateInitialState,
  { type, payload }: Action
): OrgCloneTemplateState => {
  switch (type) {
    case atypes.ORG_CLONE_FETCH_TEMPLATE_SUCCESS:
      return {
        fetching: false,
        error: null,
        data: payload
      };

    case atypes.ORG_CLONE_FETCH_TEMPLATE_FAILURE:
      return {
        fetching: false,
        error: payload.error,
        data: null
      };

    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  template,
  wizard
});
