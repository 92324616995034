// @flow

import React from "react";

import { Input } from "src/styles/input";
import { StyledNextCount } from "./styles";

type Props = {
  nextCount: ?number,
  handleNextCount: Function
};

const NextCount = ({ nextCount, handleNextCount }: Props) => (
  <StyledNextCount>
    <label>Next Count</label>
    <Input
      placeholder="Next Count"
      type="number"
      min="1"
      value={nextCount}
      id="nextCount"
      onChange={handleNextCount}
      autoComplete="off"
    />
  </StyledNextCount>
);

export default NextCount;
