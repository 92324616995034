// @flow

import { BoxProps, StackProps, IconProps } from "@chakra-ui/react";

export const TemplateContainer: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const Label = {
  fontSize: "10px"
};

export const FileItem: StackProps = {
  width: "100%",
  h: 8,
  backgroundColor: "white",
  border: "1px solid",
  borderColor: "gray.300",
  borderRadius: "2px",
  p: 2,
  justifyContent: "space-between"
};

export const removeIcon: IconProps = {
  p: 1,
  w: 4,
  h: 4,
  cursor: "pointer"
};

export const downloadIconContainer: BoxProps = {
  cursor: "pointer"
};

export const downloadIcon = {
  width: 10,
  height: 10
};
