// @flow

import * as R from "ramda";
import { combineReducers } from "redux";
import { createSelector } from "reselect";

import * as atypes from "src/constants/actionTypes";
import options from "src/constants/processInstanceColumns";

import type {
  Report,
  ReportModal,
  ReportState,
  Action,
  ProcessInstanceColumn,
  ReportById
} from "src/types";

const initialReportModal = {
  modalType: null,
  loading: false,
  title: "",
  id: null
};

const modal = (state: ReportModal = initialReportModal, { payload, type }) => {
  switch (type) {
    case atypes.HIDE_REPORTS_SAVE_MODAL:
      return initialReportModal;
    case atypes.CREATE_REPORT_REQUEST:
    case atypes.DELETE_REPORT_REQUEST:
    case atypes.EDIT_REPORT_REQUEST:
      return { ...state, loading: true };
    case atypes.CREATE_REPORT_FAILURE:
    case atypes.DELETE_REPORT_FAILURE:
    case atypes.EDIT_REPORT_FAILURE:
      return { ...state, loading: false };
    case atypes.SHOW_REPORTS_SAVE_MODAL:
      return { ...state, ...payload.modalInfo };
    case atypes.SET_REPORT_MODAL_ATTRIBUTES:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const byId = (state: ReportById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(R.map(w => ({ [w.id]: w }), payload))
      };
    case atypes.SYNC_DELETED_REPORTS:
      return R.omit([`${payload.reportId}`], state || {});
    case atypes.EDIT_REPORT_SUCCESS:
      return {
        ...state,
        [payload.id]: payload
      };
    case atypes.DELETE_REPORT_SUCCESS:
      return R.omit([`${payload.reportId}`], state || {});
    case atypes.CREATE_REPORT_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const reportsInstanceColumn = (
  state: ProcessInstanceColumn = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.REORDER_PROCESS_COLUMN:
      if (payload.reportId) {
        return { ...state, [payload.reportId]: payload.columns };
      }
      return state;
    case atypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(
          R.map(report => {
            if (report?.settings?.columns) {
              return { [report.id]: report?.settings?.columns };
            }
            return { [report.id]: options };
          }, payload)
        )
      };
    case atypes.CREATE_REPORT_SUCCESS: {
      const reportId = R.keys(payload)[0];
      if (reportId) {
        return { ...state, [reportId]: payload[reportId].settings?.columns };
      }

      return state;
    }
    default:
      return state;
  }
};

const activeReport = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_ACTIVE_REPORT_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const loaded = (state = false, { type }: Action) => {
  switch (type) {
    case atypes.GET_REPORTS_SUCCESS:
      return true;
    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  loaded,
  byId,
  activeReport,
  modal,
  reportsInstanceColumn
});

export const getReport = (state: ReportState, id: string) =>
  state.byId[`${id}`] || {};

export const getReportName = createSelector(
  getReport,
  (report: Report) => report?.title || ""
);

export const getReportType = createSelector(
  getReport,
  (report: Report) => report?.type || ""
);

export const getFormIdOfReport = createSelector(
  getReport,
  (report: Report) => report?.filters?.id || null
);

export const getReportFilters = createSelector(
  getReport,
  (report: Report) => report?.filters || null
);

export const getAllReports = (state: ReportState, title: string) =>
  R.sortWith([R.ascend(report => R.toLower(report.title || ""))])(
    R.filter(
      report => R.includes(R.toLower(title), R.toLower(report.title || "")),
      R.values(state.byId || {}) || []
    )
  );

const getReportsInstanceColumn = (state: ReportState, id: string) =>
  state.reportsInstanceColumn[id];

export const getReportsTableColumns = createSelector(
  getReportsInstanceColumn,
  item => item || options
);

export const getModalReportType = (state: ReportState) =>
  state.modal.reportType;

export const getReportModal = (state: ReportState) => state.modal;
