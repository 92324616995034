// @flow

import React from "react";
import { useSelector } from "react-redux";

import {
  MultiTable,
  MultiContainer,
  StyledMultiField,
  MembersContainer
} from "./styles";
import { EditIconWrapper } from "../styles";
import Icon from "src/icons";
import User from "src/containers/user/picture/Small";
import Group from "src/components/user/Group";
import Tooltip from "src/components/Tooltip";
import { ParticipantCount } from "src/components/Manage/Workflow/Instances/Table/Row/Members/styles";
import useRowExpansion from "src/hooks/useRowExpansion";
import { getUsersById, getFieldWidth } from "src/reducers";

import type { ColumnId } from "src/types";

type Props = {
  selected: boolean,
  userIds: any,
  value: Array<Object>,
  hideEdit: ?boolean,
  columnId: ColumnId,
  autoNo: string,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  embeddedIndex?: number
};

const MultipleUser = ({
  userIds,
  columnId,
  index,
  autoNo,
  embeddedIndex,
  hideEdit,
  openChecklistEditModal,
  roomId,
  fieldId,
  selected
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  const { isRowExpanded } = useRowExpansion(columnId, autoNo, roomId);
  const usersById = useSelector(({ app }) => getUsersById(app));

  return (
    <MultiContainer
      selected={selected}
      width={fieldWidth}
      borderRequired={columnId.includes("-")}
    >
      <MultiTable>
        <tbody>
          {Array.from({
            length: isRowExpanded ? Math.max(userIds.length, 1) : 1
          }).map((_, fieldIndex) => {
            const fieldValue = userIds[fieldIndex] ?? "";
            return (
              <tr key={fieldIndex}>
                <StyledMultiField maxWidth={fieldWidth}>
                  <MembersContainer>
                    {fieldValue.map((_, nestedIndex) => {
                      const UID = fieldValue[nestedIndex] ?? " ";
                      return typeof UID === "number" ? (
                        <Group groupId={parseInt(UID, 10)} />
                      ) : (
                        userIds.map(userId => (
                          <Tooltip
                            key={userId}
                            title={usersById?.[UID]?.displayName || ""}
                            description={usersById?.[UID]?.email || ""}
                            placement="top"
                          >
                            <User uid={userId[nestedIndex]} />
                            <ParticipantCount></ParticipantCount>
                          </Tooltip>
                        ))
                      );
                    })}
                  </MembersContainer>
                  {!hideEdit && (
                    <EditIconWrapper
                      onClick={e => {
                        openChecklistEditModal(
                          e,
                          index,
                          fieldId,
                          roomId,
                          fieldValue,
                          columnId,
                          embeddedIndex
                        );
                      }}
                    >
                      <Icon type="editPencil" />
                    </EditIconWrapper>
                  )}
                </StyledMultiField>
              </tr>
            );
          })}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

export default MultipleUser;
