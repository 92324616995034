// @flow

import React, { useState } from "react";

import { MenuItemWithIcon, HoverExtender, SubMenu, MenuItem } from "../styles";
import AllOrgs from "src/containers/filter/AllOrgs";
import Icon from "src/icons";

const OrgMenu = () => {
  const [orgMenu, setOrgMenu] = useState(false);

  return (
    <MenuItem
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setOrgMenu(true)}
      onMouseLeave={() => setOrgMenu(false)}
    >
      <MenuItemWithIcon>
        <Icon type="change" />
        <span>Change Org</span>
      </MenuItemWithIcon>
      {orgMenu ? <HoverExtender /> : null}
      {orgMenu ? (
        <SubMenu>
          <AllOrgs />
        </SubMenu>
      ) : null}
    </MenuItem>
  );
};

export default OrgMenu;
