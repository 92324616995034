// @flow

import React from "react";

import Tabs from "./Tabs";
import Close from "src/containers/Close";

type Props = {
  mine: boolean
};

const Navigation = ({ mine }: Props) => (
  <div>
    <h4>Files</h4>
    <Tabs mine={mine} />
    <Close />
  </div>
);

export default Navigation;
