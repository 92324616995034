// @flow

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Chat from "../Chat";
import SegmentedList from "./index";
import type { State } from "src/types";
import { getCurrentFilterSortBy } from "src/reducers";
import { pendingAndCompleteIds } from "src/constants/status";

type Props = {
  segmentedStatus: Object,
  currentSortby: string
};

const DefaultSegmentStatus = ({ segmentedStatus, currentSortby }: Props) => {
  const ids =
    currentSortby === "status"
      ? pendingAndCompleteIds
      : R.reverse(pendingAndCompleteIds);

  const renderSegment = (id: number) => (index: number) => {
    const segmentedChats = segmentedStatus[`${id}`];
    if (segmentedChats) {
      const roomId = segmentedStatus[id][index];
      if (roomId) {
        return <Chat key={roomId} role="listitem" roomId={roomId} />;
      }
    }
  };

  return ids.map(id => {
    const count = segmentedStatus[id] ? segmentedStatus[id].length : 0;
    return (
      <SegmentedList
        key={id}
        title={id}
        count={count}
        render={renderSegment(id)}
        header="Status"
      />
    );
  });
};

const mapStateToProps = ({ app }: State) => ({
  segmentedStatus: app.chatRooms.filters.segmentedStatus.byStatus,
  currentSortby: getCurrentFilterSortBy(app)
});
export default connect(mapStateToProps)(DefaultSegmentStatus);
