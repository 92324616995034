// @flow

import { put, select, takeLatest, call, takeEvery } from "redux-saga/effects";

import { getUser } from "src/selectors";
import * as atypes from "src/constants/actionTypes";
import * as api from "src/api/homescreen";

import type { Action } from "src/types";

function* navigateToHomescreen({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          query: { id: payload.id || null },
          page: "homescreen"
        }
      });
    } else {
      yield put({
        type: atypes.SET_HOME_SCREEN_SUCCESS,
        payload
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_HOME_SCREEN_FAILURE,
      payload: { error }
    });
  }
}

function* watchNavigateToHomescreen(): any {
  yield takeLatest(atypes.SET_HOME_SCREEN_REQUEST, navigateToHomescreen);
}

function* fetchHomescreen(): any {
  try {
    const response = yield call(api.getHomescreen);

    yield put({
      type: atypes.GET_HOME_SCREEN_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: atypes.GET_HOME_SCREEN_FAILURE,
      payload: { error }
    });
  }
}

function* watchFetchHomescreen(): any {
  yield takeLatest(atypes.SET_HOME_SCREEN_SUCCESS, fetchHomescreen);
}

function* getMyConversationChartJSON({ payload }: Action): any {
  try {
    const response = yield call(api.getMyConversationChartJSON, payload.filter);

    yield put({
      type: atypes.GET_MY_CONVERSATION_CHART_SUCCESS,
      payload: { tileId: payload.tileId, metaData: response }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_MY_CONVERSATION_CHART_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetMyConversationChartJSON(): any {
  yield takeEvery(
    atypes.GET_MY_CONVERSATION_CHART_REQUEST,
    getMyConversationChartJSON
  );
}

export default [
  watchNavigateToHomescreen(),
  watchFetchHomescreen(),
  watchGetMyConversationChartJSON()
];
