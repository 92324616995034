// @flow

import {
  AvatarProps,
  TextProps,
  StackProps,
  ModalHeaderProps,
  ModalProps,
  IconButtonProps,
  IconProps,
  ButtonProps
} from "@chakra-ui/react";

export const modal: ModalProps = {
  size: "lg",
  motionPreset: "slideInRight",
  scrollBehavior: "outside"
};

export const header: ModalHeaderProps = {
  px: 4
};

export const headerStack: StackProps = {
  spacing: "15px",
  alignItems: "center"
};

export const headerIconButton: IconButtonProps = {
  variant: "unstyled",
  size: "xs",

  _focus: {
    boxShadow: "none"
  }
};

export const headerIcon: IconProps = {
  w: 5,
  h: 5
};

export const headerTitle: TextProps = {
  my: 0,
  fontWeight: 400
};

export const inviteeList: StackProps = {
  width: "100%",
  alignitems: "start"
};

export const invitee: StackProps = {
  width: "100%",
  alignitems: "center",
  justifyContent: "space-between"
};

export const avatarBox: StackProps = {
  spacing: 4
};

export const avatar: AvatarProps = {
  size: "sm"
};

export const emailBox: StackProps = {
  alignItems: "start",
  spacing: 0
};

const commonEmailStyles: TextProps = {
  my: 0,
  isTruncated: true,
  maxWidth: "125px",
  lineHeight: "16px"
};

export const emailPrefix: TextProps = {
  ...commonEmailStyles,
  fontSize: "14px",
  fontWeight: 400,
  color: "unifize_grey8"
};

export const email: TextProps = {
  ...commonEmailStyles,
  fontSize: "12px",
  color: "unifize_grey29"
};

export const cancelButton: ButtonProps = {
  variant: "ghost",
  mr: 3
};

export const okButton: ButtonProps = {
  variant: "uBlueFilled"
};
