// @flow

import React from "react";

const ConversationsDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41 26H39V35H26V37C26 37.55 26.45 38 27 38H38L42 42V27C42 26.45 41.55 26 41 26ZM37 32V23C37 22.45 36.55 22 36 22H23C22.45 22 22 22.45 22 23V37L26 33H36C36.55 33 37 32.55 37 32Z"
      fill="#2F80ED"
    />
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
  </svg>
);

export default ConversationsDark;
