// @flow

import React from "react";

type Props = {
  checked?: boolean,
  handleClick?: Function
};

const Checkbox = ({ checked = false, handleClick = () => {} }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    style={{
      border: "solid 1px #1581ba",
      borderRadius: "2px",
      cursor: "pointer"
    }}
    onClick={handleClick}
  >
    <path
      fill={checked ? "#1581BA" : "#fff"}
      fillRule="evenodd"
      d="M2 0h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0zm1.6 8L7 11.4 12.4 6 11 4.6l-4 4-2-2L3.6 8z"
      clipRule="evenodd"
    />
  </svg>
);

export default Checkbox;
