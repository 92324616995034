// @flow

import { connect } from "react-redux";

import New from "src/components/chatroom-nav/filter/New";
import {
  hideSaveFilterModal,
  savePinnedList,
  editPinnedList,
  hideEditFilterModal
} from "src/actions/filter";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  saveModal: app.chatRooms.filters.saveModal,
  savePinnedListError: app.chatRooms.filters.savePinnedListError,
  edit: app.chatRooms.filters.editFilterModal,
  currentClickedSavedFilter: app.chatRooms.filters.currentClickedSavedFilter
});

export default connect(mapStateToProps, {
  hideSaveFilterModal,
  savePinnedList,
  editPinnedList,
  hideEditFilterModal
})(New);
