// @flow

import { connect } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Separator
} from "./styles";
import { setManageViewFilter } from "src/actions/workflows";
import { getUniqueValues } from "src/reducers";
import BlanksNonBlanks from "./BlanksNonBlanks";

import type { AppState } from "src/types";

type Props = {
  column: string,
  values: Array<number>,
  parentRef: any,
  _setManageViewFilter: Function,
  handleClose: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<string>
};

const CheckItemWithFilter = ({
  parentRef,
  column,
  _setManageViewFilter,
  handleClose,
  setFilter,
  filter,
  toggleSortBy
}: Props) => {
  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: string | number) => {
      event.preventDefault();
      event.stopPropagation();

      if (R.includes(item, filter || [])) {
        setFilter(column, R.reject(R.equals(item), filter));
      } else {
        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter, _setManageViewFilter]
  );

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>Filter</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  values: getUniqueValues(app, props.column)
});

export default connect(mapStateToProps, {
  _setManageViewFilter: setManageViewFilter
})(CheckItemWithFilter);
