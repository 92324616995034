// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { ElementRef, ElementType } from "react";
import type { Header } from "@tanstack/react-table";
import * as R from "ramda";

import { getWorkflowInstanceFilter } from "src/reducers";
import { getEmbeddedFields, getChecklistFields } from "src/actions/checklist";
import {
  LabelWrapper,
  LabelIconWrapper,
  ChecklistHeaderIconsContainer,
  ConversationHeaderItem as StyledConversationHeaderItem
} from "./styles";
import Resizer from "./Resizer";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";
import type { FieldId, ColumnId } from "src/types";

type Props = {
  toggleDropdown: Function,
  parentRef: {
    current: null | ElementRef<ElementType>
  },
  label: string,
  column: ColumnId,
  settingsJSON?: Object,
  _toggleFieldExpansion?: Function,
  fieldExpanded?: boolean,
  fieldType: string,
  childColumn: ?boolean,
  formIndex: ?number,
  fieldId: FieldId,
  header: Header
};

const ConversationHeaderItem = ({
  toggleDropdown,
  parentRef,
  label,
  column,
  settingsJSON = {},
  _toggleFieldExpansion,
  fieldExpanded,
  fieldType,
  childColumn,
  formIndex,
  fieldId,
  header
}: Props) => {
  const dispatch = useDispatch();
  const checklistId = useSelector(({ app }) => {
    const workflowId = getWorkflowInstanceFilter(app).id;
    return app.workflow.byId[childColumn ? settingsJSON?.workflow : workflowId]
      ?.checklists[0];
  });

  const showMetaData = settingsJSON?.showMetaData ?? false;

  // Left border takes up extra 1px, so reduce 1px from the calculated width
  const adjustedWidth = !fieldExpanded
    ? parseInt(`${header.column.getSize()}`.split("px")[0], 10) - 1 + "px"
    : header.column.getSize();

  return (
    <StyledConversationHeaderItem
      width={adjustedWidth}
      onClick={toggleDropdown}
      isResizing={header.column.getIsResizing()}
      ref={parentRef}
      fieldExpanded={fieldExpanded}
      type={fieldType}
      childColumn={childColumn}
      formIndex={formIndex}
    >
      <Tooltip align="down" title={label}>
        <LabelWrapper>{label}</LabelWrapper>
      </Tooltip>
      <ChecklistHeaderIconsContainer>
        {(showMetaData ||
          (settingsJSON?.fields && settingsJSON?.fields?.length > 0)) &&
        (!column.includes("-") || !R.isNil(formIndex)) ? (
          <span
            onClick={e => {
              e.stopPropagation();
              if (!fieldExpanded) {
                dispatch(
                  childColumn
                    ? getChecklistFields(checklistId)
                    : getEmbeddedFields({ fieldId: `${fieldId}`, checklistId })
                );
              }
              _toggleFieldExpansion && _toggleFieldExpansion({ id: column });
            }}
            style={{ paddingTop: "5px" }}
          >
            <Icon type="expansion" />
          </span>
        ) : null}
        <LabelIconWrapper>
          <Icon type="triangleDown" />
        </LabelIconWrapper>
        <Resizer header={header} />
      </ChecklistHeaderIconsContainer>
    </StyledConversationHeaderItem>
  );
};

export default ConversationHeaderItem;
