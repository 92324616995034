// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {};

const AreaChart = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <path
        d="M13.8003 13.04H3.00008C2.9491 13.0399 2.90007 13.0165 2.863 12.9745C2.82593 12.9325 2.80362 12.8751 2.80064 12.814C2.79765 12.7529 2.81421 12.6928 2.84693 12.6459C2.87964 12.599 2.92605 12.5688 2.97667 12.5616C6.26954 12.0966 7.21756 9.82106 8.22018 7.41179C9.2284 4.99436 10.2696 2.49507 13.7769 1.99987C13.8049 1.99566 13.8334 1.99876 13.8604 2.00895C13.8873 2.01914 13.9121 2.03618 13.9331 2.05892C13.9542 2.08142 13.9711 2.10903 13.9827 2.13994C13.9943 2.17084 14.0003 2.20434 14.0003 2.23823V12.7999C14.0003 12.8636 13.9792 12.9247 13.9417 12.9697C13.9042 13.0147 13.8533 13.04 13.8003 13.04Z"
        fill={colors.ink}
        {...restProps}
      />
      <path
        d="M13.8002 14H2.2C2.14696 14 2.09609 13.9747 2.05858 13.9297C2.02107 13.8847 2 13.8236 2 13.76V2.23809C2 2.17442 2.02107 2.11337 2.05858 2.06835C2.09609 2.02334 2.14696 1.99805 2.2 1.99805C2.25305 1.99805 2.30392 2.02334 2.34143 2.06835C2.37894 2.11337 2.40001 2.17442 2.40001 2.23809V13.5199H13.8002C13.8533 13.5199 13.9042 13.5452 13.9417 13.5902C13.9792 13.6352 14.0002 13.6963 14.0002 13.76C14.0002 13.8236 13.9792 13.8847 13.9417 13.9297C13.9042 13.9747 13.8533 14 13.8002 14Z"
        fill={colors.ink}
        {...restProps}
      />
    </svg>
  );
};

export default AreaChart;
