// @flow

import React, { useState, useEffect, useCallback } from "react";
import { VStack } from "@chakra-ui/react";

import Checkbox from "src/components/Checkbox.old";
import Radio from "src/components/Radio";
import type { NumberFieldSettings } from "src/types";
import Accordion from "src/components/Accordion.v2";
import TextInput from "src/components/Unifize/Input/TextInput";

import { ErrorLine, SettingsField, AccordionVStack } from "./styles";

type Props = {
  type: string,
  settings: NumberFieldSettings,
  saveSettings: Function
};

const NumberSettings = ({ settings, saveSettings }: Props) => {
  const [state, setState] = useState<NumberFieldSettings>({
    placeholder: "",
    max: null,
    min: null,
    step: null,
    defaultValue: null,
    suppressDefaultNotification: false,
    format: {
      decimalPlaces: 0,
      roundOff: false
    }
  });

  useEffect(() => {
    if (settings) {
      const {
        placeholder,
        max,
        min,
        step,
        defaultValue,
        suppressDefaultNotification,
        format
      } = settings;
      setState({
        placeholder,
        max,
        min,
        step,
        defaultValue,
        suppressDefaultNotification,
        format: format ?? { decimalPlaces: 0, roundOff: false }
      });
    }
  }, [settings]);

  const handleChange = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "placeholder":
          setState(prevState => ({ ...prevState, [key]: value }));
          break;
        case "step":
          setState(prevState => ({
            ...prevState,
            [key]: parseInt(value, 10)
          }));
          break;
        case "roundOff":
          setState(prevState => ({
            ...prevState,
            format: { ...prevState?.format, [key]: value }
          }));
          break;
        case "decimalPlaces":
          setState(prevState => ({
            ...prevState,
            format: {
              ...prevState?.format,
              [key]: Math.min(parseInt(value.length ? value : 0, 10), 10)
            }
          }));
          break;
        case "suppressDefaultNotification":
          setState(prevState => ({
            ...prevState,
            suppressDefaultNotification: value
          }));
          break;
        default:
          setState(prevState => ({ ...prevState, [key]: parseFloat(value) }));
      }
    },
    [setState]
  );

  useEffect(() => {
    saveSettings(state);
  }, [state]);

  const {
    placeholder,
    max,
    min,
    step,
    format,
    defaultValue,
    suppressDefaultNotification
  } = state;

  return (
    <VStack>
      <Accordion title="Basic settings">
        <AccordionVStack>
          <SettingsField>
            <label htmlFor="placeholder">Placeholder</label>
            <TextInput
              id="placeholder"
              placeholder="Enter placeholder"
              value={placeholder ?? ""}
              onChange={e => handleChange("placeholder", e.target?.value)}
              autoFocus
            />
          </SettingsField>
          <SettingsField>
            <label htmlFor="max">Max</label>
            <TextInput
              type="number"
              id="max"
              placeholder="Enter number"
              value={max ?? ""}
              onChange={e => handleChange("max", e.target?.value)}
            />
          </SettingsField>
          <SettingsField>
            <label htmlFor="min">Min</label>
            <TextInput
              type="number"
              id="min"
              placeholder="Enter number"
              value={min ?? ""}
              onChange={e => handleChange("min", e.target?.value)}
            />
            {min && max && parseInt(min, 10) > parseInt(max, 10) ? (
              <ErrorLine>
                Max value should be more than minimum value.
              </ErrorLine>
            ) : null}
          </SettingsField>
          <SettingsField>
            <label htmlFor="step">Step</label>
            <TextInput
              type="number"
              id="step"
              placeholder="Enter number"
              value={step ?? ""}
              onChange={e => handleChange("step", e.target?.value)}
            />
          </SettingsField>
        </AccordionVStack>
      </Accordion>
      <Accordion title="Trailing zeroes and rounding off">
        <AccordionVStack>
          <SettingsField>
            <label htmlFor="formatDecimalPlaces">
              Minimum places to display after decimal point (Show trailing
              zeroes even if the user enters less digits than specified)
            </label>
            <TextInput
              type="number"
              id="formatDecimalPlaces"
              min={0}
              max={10}
              value={format?.decimalPlaces ?? ""}
              onChange={e => handleChange("decimalPlaces", e.target?.value)}
            />
          </SettingsField>
          <b>
            If the user enters more digits after the decimal point than
            specified above:
          </b>
          <Radio
            id="formatRoundOff1"
            name="rounding"
            checked={!format?.roundOff ?? true}
            handleChange={value => handleChange("roundOff", !value)}
          >
            Don&apos;t round off or truncate
          </Radio>
          <Radio
            id="formatRoundOff2"
            name="rounding"
            checked={format?.roundOff ?? false}
            handleChange={value => handleChange("roundOff", value)}
          >
            Round-off when displaying, but store all digits as entered
          </Radio>
        </AccordionVStack>
      </Accordion>
      <Accordion title="Default value">
        <AccordionVStack>
          <SettingsField>
            <label htmlFor="defaultValue">Default Value</label>
            <TextInput
              type="number"
              id="defaultValue"
              placeholder="Enter a default value"
              value={defaultValue ?? ""}
              onChange={e => handleChange("defaultValue", e.target?.value)}
            />
          </SettingsField>
          <SettingsField>
            <Checkbox
              handleChange={e =>
                handleChange("suppressDefaultNotification", e.target?.checked)
              }
              checked={suppressDefaultNotification}
              id="suppressDefaultNotification"
            >
              Suppress notifications for default values
            </Checkbox>
          </SettingsField>
        </AccordionVStack>
      </Accordion>
    </VStack>
  );
};

export default NumberSettings;
