// @flow

import { StackProps, TextProps } from "@chakra-ui/react";
import styled from "styled-components";

export const container: StackProps = {
  width: "570px",
  px: 4,
  pt: 1,
  spacing: 6,
  alignItems: "start"
};

export const automationActionContainer: StackProps = {
  alignItems: "start",
  width: "100%",
  gap: 1
};

export const label: TextProps = {
  fontSize: "sm",
  my: 0
};

export const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  width: 100%;
  gap: 16px;
`;

export const SingleSplitContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SelectContainer = styled.div`
  display: flex;
  margin: 16px 0 !important;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const HalfContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
`;
