// @flow

import styled from "@emotion/styled";

const PopOver = styled.div`
  position: absolute;
  top: ${props => (props.top ? props.top : "auto")};
  bottom: ${props => (props.bottom ? props.bottom : "auto")};
  left: ${props => (props.left ? props.left : "0px")};
  right: ${props => (props.right ? props.right : "0px")};
`;

export default PopOver;
