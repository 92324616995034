// @flow

import React from "react";

import ChecklistItem from "./ChecklistItem";
import HeaderItem from "./HeaderItem";
import { Header as StyledHeader } from "./styles";
import { Selector } from "src/components/Manage/Workflow/Instances/Table/styles";

type Props = {
  fields: Array<Object>,
  columnSize: string,
  width: string
};

const Header = ({ fields, columnSize, width }: Props) => (
  <StyledHeader columnSize={columnSize} width={width}>
    <Selector />
    <HeaderItem column={"seqNo"} />
    <HeaderItem column={"chatroomId"} />
    <HeaderItem column={"templateId"} />
    <HeaderItem column={"address"} />

    {fields.map(column => (
      <ChecklistItem
        key={column.id}
        column={column.id}
        label={column.label}
        type={column.type}
      />
    ))}

    <HeaderItem column="createdBy" />
    <HeaderItem column={"createdAt"} />
  </StyledHeader>
);

export default Header;
