// @flow

import * as React from "react";
import * as R from "ramda";

import { Box, useStyles, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";

type Props = {
  value: string | number | Array<string | number> | null | React.Node,
  placeholder?: string,
  isOpen: boolean,
  disabled?: boolean,
  nullable: boolean,
  onSelect: Function,
  toggleDropdown: Function
};

const Selected = ({
  value,
  placeholder,
  isOpen,
  disabled,
  onSelect,
  nullable,
  toggleDropdown
}: Props) => {
  const styles = useStyles();

  const selectedText = Array.isArray(value)
    ? `${value.length} fields selected`
    : value;

  const handleRemove = (event: any) => {
    event.stopPropagation();
    onSelect(null);
  };

  const handleKeydown = (event: any) => {
    event.stopPropagation();
    if (event.key === "Delete" || event.key === "Backspace") {
      onSelect(null);
    } else if (!disabled) {
      toggleDropdown();
    }
  };

  return (
    <Box
      tabIndex="0"
      type="button"
      role="select"
      __css={styles.selection}
      backgroundColor={disabled ? "gray.100" : "white"}
      onClick={!disabled && toggleDropdown}
      onKeyDown={handleKeydown}
    >
      {(Array.isArray(value) && value.length === 0) || !value ? (
        <>
          <Text isTruncated>{placeholder}</Text>
          {isOpen ? (
            <ChevronUpIcon w={4} h={4} />
          ) : (
            <ChevronDownIcon w={4} h={4} />
          )}
        </>
      ) : (
        <>
          {R.is(String, selectedText) ? (
            <Text isTruncated>{selectedText}</Text>
          ) : (
            selectedText
          )}
          {nullable ? (
            <CloseIcon p={1} w={4} h={4} onClick={handleRemove} />
          ) : isOpen ? (
            <ChevronUpIcon w={4} h={4} />
          ) : (
            <ChevronDownIcon w={4} h={4} />
          )}
        </>
      )}
    </Box>
  );
};

export default Selected;
