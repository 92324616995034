// @flow

import React from "react";

const EveryOne = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2D9CDB"
      d="M11.933 11.594a1.337 1.337 0 0 0-1.266-.927H10v-2a.667.667 0 0 0-.667-.666h-4V6.667h1.334a.667.667 0 0 0 .666-.666V4.667h1.334A1.333 1.333 0 0 0 10 3.334v-.273a5.322 5.322 0 0 1 1.933 8.533zm-4.6 1.693a5.326 5.326 0 0 1-4.666-5.286c0-.414.053-.814.14-1.194L6 10.001v.666a1.333 1.333 0 0 0 1.333 1.334v1.286zM8 1.334a6.667 6.667 0 1 0 0 13.333A6.667 6.667 0 0 0 8 1.334z"
    />
  </svg>
);

export default EveryOne;
