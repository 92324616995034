// @flow

import { connect } from "react-redux";

import Item from "src/components/workflow/Item";
import { getWorkflow } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  workflow: getWorkflow(app, props.id)
});

export default connect(mapStateToProps)(Item);
