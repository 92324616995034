// @flow

import React, { Component } from "react";
import OpenSeadragon from "openseadragon";

import { ImageViewer as StyledViewer } from "src/styles";

type Props = {
  url: string
};

class ImageViewer extends Component<Props> {
  componentDidMount() {
    const { url } = this.props;
    OpenSeadragon({
      id: "seadragon-image-viewer",
      prefixUrl: "//openseadragon.github.io/openseadragon/images/",
      tileSources: {
        type: "image",
        url
      }
    });
  }

  render() {
    return <StyledViewer id="seadragon-image-viewer" />;
  }
}

export default ImageViewer;
