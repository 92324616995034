// @flow

import React, { Component } from "react";

import { ChecklistSettings } from "src/styles/checklist";
import type { DateFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: DateFieldSettings,
  saveSettings: Function
};

type State = {
  placeholder: ?string
};

class Date extends Component<Props, State> {
  state = {
    placeholder: ""
  };

  componentDidMount() {
    const { settings } = this.props;
    if (settings) {
      this.setState(settings);
    }
  }

  handleChange = (e: any) => {
    const { value } = e.target;
    this.setState({ placeholder: value });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.placeholder !== this.state.placeholder) {
      this.props.saveSettings(this.state);
    }
  }

  render() {
    const { placeholder } = this.state;

    return (
      <ChecklistSettings>
        <form>
          <div>
            <label htmlFor="placeholder">Placeholder</label>
            <input
              type="text"
              id="placeholder"
              value={placeholder}
              onChange={this.handleChange}
              autoFocus
            />
          </div>
        </form>
      </ChecklistSettings>
    );
  }
}

export default Date;
