// @flow

import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useEditor, EditorContent, ReactRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Mention } from "@tiptap/extension-mention";
import tippy from "tippy.js";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Link } from "@tiptap/extension-link";
import Blockquote from "@tiptap/extension-blockquote";
import { Extension } from "@tiptap/core";
import linkifyHtml from "linkify-html";

import { getConversationModalVisibility, getReplyTo } from "src/reducers";
import { clearChatInput, setTyping } from "src/actions/chatroom";
import useThrottle from "src/hooks/useThrottle";
import {
  clearInput,
  formatMentionsInMessage,
  formatMentionsInPlainMessage
} from "./mentions.helpers";
import { MentionsContainer, TextInputContainer, IconContainer } from "./styles";
import "./tiptap-overrides.css";

import Icon from "src/icons";
import MentionList from "./MentionList";
import LinkModal from "./LinkModal";
import type { UID, RoomId, MessageText } from "src/types";

type Props = {
  mentions: Array<Object | UID>,
  handleSendClick: Function,
  roomId: RoomId,
  currentMessage: MessageText
};

const TextInputWithMentions = ({
  mentions,
  handleSendClick,
  roomId,
  currentMessage
}: Props) => {
  const isConversationModalVisible = useSelector(({ app }) =>
    getConversationModalVisibility(app)
  );
  const replyTo = useSelector(({ app }) => getReplyTo(app));

  const dispatch = useDispatch();
  const isPersonalMessage = mentions.length == 2;
  const chatInputBoxRef = useRef<any>(null);
  const [showLinkModal, setLinkModal] = useState<boolean>(false);

  const throttledDispatch = useThrottle(() => {
    dispatch(setTyping(roomId));
  }, 1000);

  function sendMessage(editor) {
    const json = editor ? editor.getJSON() : {};
    const content = json?.content ?? [];
    const html = editor ? editor.getHTML() : "";
    const text = editor ? editor.getText() : "";

    const linkedHTML = linkifyHtml(html, {
      attributes: {
        target: "_blank"
      },
      ignoreTags: ["code"]
    });

    if (text == "") {
      return;
    }

    let formattedMessage = formatMentionsInMessage(content, linkedHTML);
    let plainText = formatMentionsInPlainMessage(content, text);

    // To prevent rerendering of tiptap editor and clearing the input text when replying to a text
    // We set and retrieve the current message id if it is present using data attribute
    const currentMessageId = chatInputBoxRef.current.getAttribute(
      "data-current-message-id"
    );
    handleSendClick(formattedMessage, currentMessageId, plainText);
    dispatch(clearChatInput(roomId));
    clearInput(editor);
  }

  const SendMessageWithEnterKey = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          sendMessage(editor);
          return true;
        },
        "Shift-Enter": ({ editor }) => {
          editor.commands.first(({ commands }) => [
            () => commands.newlineInCode(),
            () => commands.splitListItem("listItem"),
            () => commands.createParagraphNear(),
            () => commands.liftEmptyBlock(),
            () => commands.splitBlock()
          ]);
          return true;
        }
      };
    }
  });

  const LinkExtension = Extension.create({
    addKeyboardShortcuts() {
      return {
        "Control-k": () => {
          if (!showLinkModal) {
            setLinkModal(true);
          }
          return true;
        }
      };
    }
  });

  const mention = Mention.extend({
    addAttributes() {
      return {
        mentionedPerson: {
          default: {}
        }
      };
    }
  });

  const suggestion = {
    allowSpaces: true,
    autofocus: true,
    items: ({ query, editor }) =>
      editor.options.editorProps.items.filter(item =>
        item.display.substring(1).toLowerCase().includes(query.toLowerCase())
      ),
    render: () => {
      let component;
      let popup;

      return {
        onStart: props => {
          const customProps: any = props;
          component = new ReactRenderer(MentionList, {
            props: customProps,
            editor: customProps.editor
          });

          if (!customProps.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: customProps.clientRect,
            appendTo: () => {
              if (isConversationModalVisible) {
                return document.querySelector(".ReactModalPortal #chatInput");
              } else {
                return document.querySelector("#chatInput");
              }
            },
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "top",
            maxWidth: "100vw",
            popperOptions: {
              positionFixed: true
            }
          });
        },

        onUpdate(props) {
          const customProps: any = props;
          component.updateProps(customProps);
          if (!customProps.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: customProps.clientRect
          });
        },

        onKeyDown(props) {
          const customProps: any = props;
          if (customProps.event.key === "Escape") {
            popup[0].hide();
            return true;
          }

          return component.ref.onKeyDown
            ? component.ref.onKeyDown(props)
            : null;
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        }
      };
    }
  };

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        SendMessageWithEnterKey,
        LinkExtension,
        Blockquote,
        Link.configure({
          HTMLAttributes: {
            target: "_blank"
          },
          autolink: false,
          openOnClick: false
        }),
        mention.configure({
          HTMLAttributes: {
            class: "mention"
          },
          suggestion,
          renderLabel({ node }) {
            return node.attrs.mentionedPerson.display;
          }
        }),
        Placeholder.configure({
          placeholder: "Send a response from here"
        })
      ],
      content: ``,
      autofocus: "end"
    },
    [isPersonalMessage, roomId]
  );

  const setLink = useCallback(
    url => {
      if (url === null) {
        return;
      }

      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      let validatedUrl = url;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        validatedUrl = "http://" + url;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: validatedUrl })
        .run();

      editor.commands.focus("end");
    },
    [editor]
  );

  useEffect(() => {
    if (editor) {
      editor.commands.focus();
    }
  }, [editor, replyTo]);

  useEffect(() => {
    if (editor) {
      editor.setOptions({
        editorProps: { items: mentions }
      });
    }
  }, [mentions, editor]);

  useEffect(() => {
    if (editor) {
      const handleContentUpdate = () => {
        if (editor.getText() !== "") {
          throttledDispatch();
        }
      };

      editor.on("update", handleContentUpdate);

      return () => {
        editor.off("update", handleContentUpdate);
      };
    }
  }, [editor, throttledDispatch]);

  if (!editor) {
    return null;
  }

  return (
    <MentionsContainer>
      <TextInputContainer
        ref={chatInputBoxRef}
        data-current-message-id={
          currentMessage && currentMessage.id ? currentMessage.id : null
        }
      >
        <EditorContent data-cy="chatInput" editor={editor} />
      </TextInputContainer>
      <IconContainer>
        <Icon type="send" handleClick={() => sendMessage(editor)} />
      </IconContainer>
      {showLinkModal && (
        <LinkModal
          showModal={showLinkModal}
          setLink={setLink}
          toggleModal={setLinkModal}
        />
      )}
    </MentionsContainer>
  );
};

export default TextInputWithMentions;
