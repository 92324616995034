// @flow
import React, { useContext } from "react";

import { ModalBody } from "@chakra-ui/react";

import { ImportStateContext } from "./importReducer";
import ImportedProcessSummary from "./ImportedProcessSummary";
import {
  modalBody,
  PanelTitle,
  SummaryPanelBody,
  SummaryProcess
} from "./styles";

const ImportSummaryPanel = () => {
  const { importResult: processesSummary } = useContext(ImportStateContext);
  return (
    <ModalBody sx={modalBody}>
      <PanelTitle>Your import is completed, see the summary below.</PanelTitle>
      <SummaryPanelBody>
        {processesSummary.map((process, index) => (
          <SummaryProcess key={`processSummary${process.id}_${index}`}>
            <ImportedProcessSummary
              status={process.status}
              title={process.title}
            />
          </SummaryProcess>
        ))}
      </SummaryPanelBody>
    </ModalBody>
  );
};

export default ImportSummaryPanel;
