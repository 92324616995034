// @flow

import React from "react";
import { Options as StyledOptions, SortItem } from "src/styles/table";

type Props = {
  sortAsc: Function,
  sortDesc: Function
};

const SortBy = ({ sortAsc, sortDesc }: Props) => {
  return (
    <>
      <span>Sort By</span>
      <ul>
        <SortItem onClick={sortAsc}>Ascending</SortItem>
        <SortItem onClick={sortDesc}>Descending</SortItem>
      </ul>
    </>
  );
};

export default SortBy;
