// @flow

import React from "react";

import { NoUnreadWrapper, Heading, NoResultSubtitle } from "./styles";

import Icons from "src/icons";

const NoResultFound = () => {
  return (
    <NoUnreadWrapper>
      <Heading>
        <Icons type="noResult" />
        <div>
          <h3>No matching results</h3>
        </div>
        <NoResultSubtitle>
          <div>There weren’t any conversations matching your filters.</div>
          <div>Try widening your search.</div>
        </NoResultSubtitle>
      </Heading>
    </NoUnreadWrapper>
  );
};

export default NoResultFound;
