import * as colors from "src/styles/constants/colors";

export default {
  privateConversationTitle: {
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 400,
    color: colors.secondary
  },
  modalTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700
  },
  label: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    margin: 0
  },
  formSectionHeader: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    margin: 0
  },
  heading: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    margin: 0
  },
  large: {
    fontSize: "28px",
    lineHeight: "32px",
    fontWeight: 400,
    margin: 0
  },
  caption: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    margin: 0
  },
  wizardStage: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    margin: 0,
    color: colors.inkLighter
  },
  displayMedium: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    margin: 0
  },
  displaySmall: {
    fontSize: {
      base: "14px",
      md: "20px"
    },
    lineHeight: { base: "16px", md: "28px" },
    fontWeight: 400,
    margin: 0
  },
  mobileHeading: {
    fontSize: {
      base: "16px",
      md: "24px"
    },
    lineHeight: "24px",
    fontWeight: 600,
    margin: 0
  },
  displayXl: {
    fontSize: { base: "24px", md: "42px" },
    fontWeight: 400,
    lineHeight: {
      base: "32px",
      md: "44px"
    },
    margin: 0
  },
  popoverHeader: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "24px",
    margin: 0
  },
  popoverMenuItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: 0
  }
};
