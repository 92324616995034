// @flow

import { connect } from "react-redux";

import Advanced from "src/components/chatroom/creator/process/Advanced";
import { getUserSearchResult, getWorkflowSettings } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProp = ({ app }: { app: AppState }, { templateId }) => ({
  dueDate: app.conversationDialog.dueDate,
  description: app.conversationDialog.description,
  members: app.conversationDialog.members,
  users: getUserSearchResult(app),
  settings: getWorkflowSettings(app, templateId) || {}
});

export default connect(mapStateToProp)(Advanced);
