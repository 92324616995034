// @flow

import type { StackProps } from "@chakra-ui/react";
import * as spacing from "src/styles/constants/spacing";

export const WideChecklistContainer: StackProps = {
  width: "100% !important",
  alignItems: "start",
  p: spacing.space_m,
  pl: "0.75rem"
};
