// @flow

import React from "react";
type Props = {
  stroke?: string
};

const UrlCopy = ({ stroke, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...restProps}
  >
    <g clipPath="url(#clip0_2931_1871)">
      <path
        d="M8.62884 6.46191C9.91217 7.64653 9.91217 9.56345 8.62884 10.7481L6.7715 12.4625C6.15576 13.0309 5.32063 13.3502 4.44984 13.3502C3.57904 13.3502 2.74391 13.0309 2.12817 12.4625C1.51243 11.8941 1.1665 11.1233 1.1665 10.3195C1.1665 9.51564 1.51243 8.74475 2.12817 8.17638L3.76034 6.71391"
        stroke={stroke ? stroke : "#2a2a2a"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66177 7.01161C6.13143 6.52206 5.8335 5.85809 5.8335 5.16577C5.8335 4.47345 6.13143 3.80948 6.66177 3.31992L8.26243 1.84238C8.52519 1.59998 8.83709 1.40773 9.18033 1.2766C9.52358 1.14547 9.89144 1.07803 10.2629 1.07813C10.6344 1.07823 11.0022 1.14586 11.3454 1.27718C11.6886 1.4085 12.0003 1.60092 12.2629 1.84346C12.5255 2.086 12.7338 2.37391 12.8759 2.69075C13.0179 3.0076 13.091 3.34716 13.0909 3.69007C13.0908 4.03297 13.0175 4.3725 12.8752 4.68927C12.733 5.00603 12.5245 5.29383 12.2618 5.53623L10.8559 6.79623"
        stroke={stroke ? stroke : "#2a2a2a"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2931_1871">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UrlCopy;
