// @flow

import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";

import { Grid, GridItem } from "@chakra-ui/react";
import Accordion from "./Accordion";

import Field from "./Field";
import SubSection from "./SubSection";
import type { RoomId, FieldId } from "src/types";

import { breakpointMaxWidths } from "src/components/Dock/DockLayout/utils";
import RowItem from "./WideChecklist/RowItem";
import * as styles from "./styles";

import {
  getChecklistFieldDetails,
  getChatRoom,
  getChecklistFieldBehavior,
  getSectionMandatoryFieldCount
} from "src/reducers";

import { openSection, closeSection } from "src/actions/checklist";
import { behaviorToSettings, behaviors } from "src/conditions";
import type { Layout } from "./WideChecklist/utils";
import type { Breakpoint } from "src/types";

type Props = {
  roomId: RoomId,
  checklistId: number,
  id: FieldId,
  fields: any,
  isSectionField: boolean,
  layout?: Layout,
  breakpoint?: Breakpoint
};

const Section = ({
  roomId,
  checklistId,
  id,
  fields,
  isSectionField,
  layout,
  breakpoint
}: Props) => {
  const dispatch = useDispatch();
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${id}`)
  );
  const currentChatRoom = useSelector(({ app }) =>
    getChatRoom(app, `${roomId}`)
  );
  const behavior =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, id)) || {};

  const label = details ? details.get("label") : "";
  const settings = details ? details.get("settings") : "{}";
  // $FlowFixMe
  const fieldHidden = details?.get("hidden") || false;

  const mandatoryFieldCount = useSelector(({ app }) =>
    getSectionMandatoryFieldCount(app, {
      sectionId: id,
      fields,
      checklistId,
      roomId
    })
  );

  const [isOpen, setOpen] = useState(
    R.includes(currentChatRoom.status, JSON.parse(settings).status || [])
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(openSection({ checklistId: String(checklistId), id, roomId }));
    } else {
      dispatch(closeSection({ checklistId: String(checklistId), id, roomId }));
    }
  }, [isOpen]);

  const toggleActive = useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);

  if (
    behavior?.current === behaviorToSettings[behaviors.hideField] ||
    fieldHidden
  )
    return null;
  if (layout && breakpoint) {
    return (
      <Accordion
        title={label}
        isExpanded={isOpen}
        isSectionField={typeof fields[1] === "object"}
        mandatoryFieldCount={mandatoryFieldCount}
        toggleActive={toggleActive}
      >
        <Grid
          templateColumns={`repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`}
          key={id}
          gap={"0"}
          sx={styles.SectionGrid}
        >
          {layout.map(field => {
            if (field.type === "subSection") {
              return (
                <GridItem key={field.id} colSpan={field.w}>
                  <SubSection
                    fields={field.fields}
                    checklistId={checklistId}
                    sectionId={id}
                    id={field.id}
                    isSectionField={isSectionField}
                    roomId={roomId}
                    layout={field.layout}
                    breakpoint={breakpoint}
                  />
                </GridItem>
              );
            }
            return (
              <>
                {field.startOnNewRow ? (
                  <GridItem
                    key={field.id}
                    colSpan={breakpointMaxWidths[breakpoint]}
                    sx={{
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <RowItem breakpoint={breakpoint} fieldW={field.w}>
                      <Field
                        key={field.id}
                        id={field.id}
                        checklistId={id}
                        roomId={roomId}
                        section={field.id}
                        isSectionField={isSectionField}
                      />
                    </RowItem>
                  </GridItem>
                ) : (
                  <GridItem
                    key={field.id}
                    colSpan={field.w}
                    sx={{
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <Field
                      key={field.id}
                      id={field.id}
                      checklistId={id}
                      roomId={roomId}
                      section={field.id}
                      isSectionField={isSectionField}
                    />
                  </GridItem>
                )}
              </>
            );
          })}
        </Grid>
      </Accordion>
    );
  }

  return (
    <Accordion
      title={label}
      isExpanded={isOpen}
      isSectionField={typeof fields[1] === "object"}
      mandatoryFieldCount={mandatoryFieldCount}
      toggleActive={toggleActive}
    >
      {fields.map(field => (
        <>
          {typeof field === "object" ? (
            <SubSection
              fields={field.fields}
              checklistId={checklistId}
              sectionId={id}
              id={field.sectionId}
              isSectionField={isSectionField}
              roomId={roomId}
            />
          ) : (
            <Field
              key={field}
              id={field}
              checklistId={id}
              roomId={roomId}
              section={field}
              isSectionField={isSectionField}
            />
          )}
        </>
      ))}
    </Accordion>
  );
};

export default Section;
