// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, ConversationModal } from "src/types";

const initialState = {
  show: false,
  roomId: null
};

const conversationModal = (
  state: ConversationModal = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_MEMBERS:
    case atypes.SET_NEW_CONVERSATION_ATTRIBUTE:
    case atypes.SET_NEW_CONVERSATION_ATTRIBUTES:
    case atypes.TOGGLE_RELATED_MODAL:
    case atypes.CLOSE_CONVERSATION_MODAL:
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return initialState;
    case atypes.OPEN_CONVERSATION_MODAL:
      return { show: true, roomId: payload.id };
    case atypes.CONVERSATION_DIALOG_WARN_MODAL:
      return { ...state, warn: true };
    case atypes.CLEAR_CONVERSATION_DIALOG_WARN_MODAL:
      return { ...state, warn: false };
    default:
      return state;
  }
};

export const getConversationModalVisibility = (state: ConversationModal) => {
  return state.show;
};

export const getConversationModalId = (state: ConversationModal) => {
  return state.roomId;
};

export default conversationModal;
