// @flow

import React from "react";

import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { Checkbox } from "@chakra-ui/react";
import { ListItem } from "./styles";
import Icon from "src/components/Icon";
import { processColumnNames } from "src/constants/display";
import { iconSize } from "src/styles/constants/size";

type Props = {
  column: Object,
  handleCheckbox: Function,
  isReadOnly: boolean
};

const DragHandle = SortableHandle(() => (
  <Icon icon="draggable" size={iconSize.space_m} />
));

const SortableItem = SortableElement(
  ({ column, handleCheckbox, isReadOnly = false }: Props) => {
    const columnLabel = column.label
      ? column.label
      : processColumnNames[column.key];

    const handleClick = () => {
      if (isReadOnly) {
        return;
      }
      handleCheckbox(column.key);
    };

    return (
      <ListItem>
        <div>{columnLabel}</div>
        <div onClick={handleClick}>
          <Checkbox
            isDisabled={isReadOnly}
            isChecked={isReadOnly ? true : column.active}
          />
        </div>

        <DragHandle />
      </ListItem>
    );
  }
);

export default SortableItem;
