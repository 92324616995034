// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { Filters as StyledFilters, Label, Button, LastUpdated } from "./styles";
import Process from "./Process";
import Department from "./Department";
import Groups from "./Groups";
import { toggleTotal, toggleAverage } from "src/actions/userAnalytics";

import type { AppState } from "src/types";

type Props = {
  showTotal: boolean,
  showAverage: boolean,
  readCountComputedAt: string,
  _toggleTotal: Function,
  _toggleAverage: Function
};

const Filters = ({
  showTotal,
  showAverage,
  readCountComputedAt,
  _toggleTotal,
  _toggleAverage
}: Props) => (
  <StyledFilters>
    <Label>FILTERS:</Label>
    <Process />
    <Department />
    <Groups />
    <Button active={showTotal} type="button" onClick={_toggleTotal}>
      Show Total
    </Button>
    <Button active={showAverage} type="button" onClick={_toggleAverage}>
      Show Average
    </Button>
    <LastUpdated>
      <strong>Last updated:</strong>
      <span>
        {readCountComputedAt
          ? moment(readCountComputedAt).format("MMM DD, YYYY hh:mm a")
          : null}
      </span>
    </LastUpdated>
  </StyledFilters>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  showTotal: app.userAnalytics.showTotal,
  showAverage: app.userAnalytics.showAverage,
  readCountComputedAt: app.userAnalytics.readCountComputedAt
});

export default connect(mapStateToProps, {
  _toggleTotal: toggleTotal,
  _toggleAverage: toggleAverage
})(Filters);
