// @flow

import React from "react";

import { ChatroomTitle as StyledChatroomTitle } from "./styles";
import Name from "src/containers/chatroom/Name/Title";

type Props = {
  id: number
};

const ChatroomTitle = ({ id }: Props) => (
  <StyledChatroomTitle>
    <Name id={id} />
  </StyledChatroomTitle>
);

export default ChatroomTitle;
