// @flow

import React from "react";

import { Typing } from "./styles";

const TypingIndicator = () => (
  <Typing width="50" viewBox="0 0 12 6">
    <g fill="currentColor">
      <circle cx="3" cy="3" r="1" />
      <circle cx="6" cy="3" r="1" />
      <circle cx="9" cy="3" r="1" />
    </g>
  </Typing>
);

export default TypingIndicator;
