// @flow

import React from "react";

import FileOrEmail from "./FileOrEmail";
import ChecklistFields from "./ChecklistFields";
import ConversationStart from "./Conversation/Start";
import ConversationComplete from "./Conversation/Complete";
import ConversationReopen from "./Conversation/Reopen";
import ConversationCancel from "./Conversation/Cancel";
import ConversationStatus from "./Conversation/Status";
import Owner from "./Owner";
import Member from "./Member";
import Duedate from "./Duedate";
import Foster from "./Foster";
import Parent from "./Parent";
import Title from "./Title";
import Priority from "./Priority";
import Privacy from "./Privacy";
import Objective from "./Objective";
import Invite from "./User/Invite";
import ReminderStatusChange from "./ReminderStatusChange";
import ReminderUniBot from "./ReminderUniBot";
import * as notifications from "src/constants/notifications";
import RemoveForm from "./Form/Remove";
import AddForm from "./Form/Add";
import CreateRevision from "./Revision/Create";
import UpdateCurrentVersion from "./Revision/UpdateCurrentVersion";
import SlackNotification from "./Slack";

import type { Message, RoomId } from "src/types";
import ArchiveStatus from "./ArchiveStatus";
import Group from "./Group";

type Props = {
  roomId: RoomId,
  message: Message
};
const Notification = ({ message, roomId }: Props) => {
  const { action } = message;

  switch (action) {
    case notifications.CONVERSATION_START:
      return <ConversationStart message={message} roomId={roomId} />;
    case notifications.CONVERSATION_COMPLETE:
      return <ConversationComplete message={message} />;
    case notifications.CONVERSATION_REOPEN:
      return <ConversationReopen message={message} />;
    case notifications.CONVERSATION_CANCELED:
      return <ConversationCancel message={message} />;
    case notifications.OWNER_UPDATE:
      return <Owner message={message} />;
    case notifications.MEMBER_ADD:
    case notifications.MEMBER_REMOVE:
      return <Member message={message} />;
    case notifications.GROUP_ADD:
    case notifications.GROUP_REMOVE:
      return <Group message={message} />;
    case notifications.DUEDATE_UPDATE:
      return <Duedate message={message} />;
    case notifications.CHECKLIST_FIELD_FILL:
      return <ChecklistFields message={message} />;
    case notifications.FOSTER_PARENT_ADD:
    case notifications.FOSTER_PARENT_REMOVE:
    case notifications.FOSTER_CHILD_ADD:
    case notifications.FOSTER_CHILD_REMOVE:
      return <Foster message={message} />;
    case notifications.PARENT_ADD:
    case notifications.PARENT_REMOVE:
    case notifications.PARENT_UPDATE:
      return <Parent message={message} />;
    case notifications.USER_INVITE:
      return <Invite message={message} />;
    case notifications.USER_JOIN:
      return <></>;
    case notifications.TITLE_UPDATE:
      return <Title message={message} />;
    case notifications.OBJECTIVE_UPDATE:
      return <Objective message={message} />;
    case notifications.FILE_ADD:
    case notifications.EMAIL_SENT:
    case notifications.EMAIL_RECEIVED:
    case notifications.FILE_REMOVE:
      return <FileOrEmail message={message} roomId={roomId} />;
    case notifications.PRIORITY_UPDATE:
      return <Priority message={message} />;
    case notifications.PRIVACY_UPDATE:
      return <Privacy message={message} />;
    case notifications.STATUS_UPDATE:
      return <ConversationStatus message={message} />;
    case notifications.REMINDER_STATUS_CHANGED:
      return <ReminderStatusChange message={message} />;
    case notifications.REMINDER:
      return <ReminderUniBot message={message} />;
    case notifications.FORM_ADD:
      return <AddForm message={message} />;
    case notifications.FORM_REMOVE:
      return <RemoveForm message={message} />;
    case notifications.REVISION_CREATE:
      return <CreateRevision notification={message} />;
    case notifications.CURRENT_VERSION_UPDATE:
      return <UpdateCurrentVersion notification={message} />;
    case notifications.EXTERNAL_APP_CONVERSATION_START:
    case notifications.EXTERNAL_APP_MESSAGE_FORWARD:
      return <SlackNotification notification={message} roomId={roomId} />;

    case notifications.ARCHIVE_STATUS:
      return <ArchiveStatus message={message} />;

    default:
      return <div>{action}</div>;
  }
};

export default Notification;
