// @flow

import React from "react";

import { InputPlaceholder } from "./styles";

const Input = () => (
  <InputPlaceholder>
    <div />
  </InputPlaceholder>
);

export default Input;
