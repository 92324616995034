// @flow

import React, { useState, useRef, useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as R from "ramda";

import TextInput from "./TextInput";
import Mail from "./Mail";
import {
  ChatInputContainer as StyledChatInputContainer,
  ChatInput as StyledChatInput,
  InputIcon,
  UploadIcon,
  InputForm as StyledInputForm
} from "./styles";
import Upload from "src/containers/chatroom/Upload";
import PopoverMenu from "src/components/chatroom/Menu";
import InlineSVG from "src/components/InlineSVG";
import { Chat } from "src/styles/input.old";
import { iconSize } from "src/styles/constants";
import { formatMentions } from "src/utils";
import { createCanvas } from "src/utils/canvasImage.js";
import { focusChatInput } from "src/actions/chatroom";
import { uploadFileToChatroom } from "src/actions/file";
import type { UID, RoomId, MessageText } from "src/types";
import ImagePasteModal from "./ImagePasteModal";
import { createElementFromHTMLStr, isValidBase64 } from "src/utils";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  roomId: RoomId,
  currentMessage: Object,
  clearReply: Function,
  insertMssg: Function,
  currentUser: UID,
  isSrw: boolean,
  triggerClearInput: Function,
  deleteTyping: Function,
  _focusChatInput: Function,
  _uploadFileToChatroom: Function
};

const ChatInput = ({
  isSrw,
  triggerClearInput,
  deleteTyping,
  roomId,
  currentMessage,
  clearReply,
  insertMssg,
  currentUser,
  _focusChatInput,
  _uploadFileToChatroom
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [fileData, setFileData] = useState({});
  const [isInputFocused, setIsInputFocused] = useState(false);

  // stores input text as per roomId
  const [roomChatInput, setRoomChatInput] = useState([]);
  const dispatch = useDispatch();

  const canvasRef = useRef(null);

  const togglePopover = useCallback(
    (cb: Function) => {
      if (cb && R.type(cb) === "Function") {
        cb();
      }
      setIsPopoverOpen(!isPopoverOpen);
    },
    [setIsPopoverOpen, isPopoverOpen]
  );

  const closeModal = useCallback(() => {
    setIsPopoverOpen(false);
  }, [setIsPopoverOpen]);

  const handleInsert = (
    text: string,
    currentMessage: MessageText,
    plainText: string
  ) => {
    if (text) {
      insertMssg({
        roomId,
        author: currentUser,
        text: formatMentions(plainText),
        html: text,
        inReplyTo: currentMessage ? currentMessage : null
      });

      clearReply();
    }
  };

  const clearCurrentChatroomInput = useCallback(() => {
    const index = R.findIndex(R.propEq("roomId", roomId))(roomChatInput);
    const updatedValue = roomChatInput;
    updatedValue[index] = { roomId, input: "" };
    setRoomChatInput([...updatedValue]);
  }, [roomChatInput, roomId]);

  const handleSendClick = (
    input: string,
    currentMessage: MessageText,
    text: string
  ) => {
    handleInsert(R.trim(input || ""), currentMessage, text);
    clearCurrentChatroomInput();
    deleteTyping(roomId);
    triggerClearInput();
  };

  // Upload image on clicking upload button on pasted image modal
  const uploadImageToChatroom = () => {
    _uploadFileToChatroom(
      dispatch,
      "chat-input",
      fileData,
      roomId,
      fileData.name,
      null
    );
  };

  const outerRef = useRef(null);

  const openModalAndShowImage = (e: any) => {
    const { items } = e.clipboardData;

    // Open image modal only if ctrl + v is pressed while on input field
    // being focused
    if (isInputFocused) {
      const isValidImg = isValidBase64(
        createElementFromHTMLStr(e.clipboardData.getData("Text"))?.src
      );

      // Use for..in loop since `items` can be either
      // array or an object
      for (let item in items) {
        // Skip opening paste image modal if not image
        if (
          items[item].type &&
          items[item].type.indexOf("image") === -1 &&
          !isValidImg
        )
          return;
        setImageModal(true);
        createCanvas(e, canvasRef, setFileData);
      }
    }
  };

  // Show modal on pressing ctrl + v
  useEffect(() => {
    if (isInputFocused) {
      window.addEventListener("paste", openModalAndShowImage);
    }
    return () => {
      window.removeEventListener("paste", openModalAndShowImage);
    };
  }, [isInputFocused]);

  const isChatEnabled = useComponentPermission(componentPermissions.chatInput);

  const focusInput = () => {
    _focusChatInput(roomId);
    setIsInputFocused(true);
  };

  if (!isChatEnabled) {
    return null;
  }

  return (
    <StyledChatInputContainer id="chatInput">
      {isPopoverOpen && (
        <PopoverMenu
          roomId={roomId}
          closeModal={closeModal}
          togglePopover={togglePopover}
          outerRef={outerRef}
        />
      )}

      {imageModal && (
        <ImagePasteModal
          setImageModal={setImageModal}
          canvasRef={canvasRef}
          handleUpload={uploadImageToChatroom}
          setIsInputFocused={setIsInputFocused}
        />
      )}

      <StyledChatInput active={isPopoverOpen}>
        {isSrw ? (
          <Upload
            roomId={roomId}
            location="chat-input"
            render={openFileBrowser => (
              <UploadIcon
                type="plus"
                role="button"
                tabIndex={-5}
                onClick={openFileBrowser}
              >
                <InlineSVG icon="plus" size={iconSize.medium} />
              </UploadIcon>
            )}
          />
        ) : (
          <InputIcon
            type="plus"
            role="button"
            tabIndex={-5}
            onClick={togglePopover}
            ref={outerRef}
            data-cy="plusChatIcon"
          >
            <InlineSVG icon="plus" size={iconSize.medium} />
          </InputIcon>
        )}
        <StyledInputForm>
          <Chat onClick={focusInput}>
            <TextInput
              handleSendClick={handleSendClick}
              roomId={roomId}
              currentMessage={currentMessage}
            />
          </Chat>
        </StyledInputForm>
        {!isSrw && <Mail />}
      </StyledChatInput>
    </StyledChatInputContainer>
  );
};

export default connect(null, {
  _focusChatInput: focusChatInput,
  _uploadFileToChatroom: uploadFileToChatroom
})(ChatInput);
