// @flow

import React from "react";

import Custom from "./Custom";
import defaultStatus from "src/constants/status";

type Props = {
  id: number | string
};

const Name = ({ id }: Props) => {
  if (parseInt(id, 10) > 0) {
    return <Custom id={`${id}`} />;
  }

  if (defaultStatus[`${id}`]) {
    return (
      <span title={defaultStatus[`${id}`].text}>
        {defaultStatus[`${id}`].text}
      </span>
    );
  }
  return <span>{id}</span>;
};

export default Name;
