// @flow

import React from "react";
import moment from "moment";
import * as R from "ramda";

import { Time as StyledTime } from "./styles";

type Props = {
  seconds: number | string
};

const Time = ({ seconds }: Props) => (
  <StyledTime>
    {R.type(seconds) === "String"
      ? moment(seconds).format("MMM DD, YYYY hh:mm a")
      : moment(moment.unix(seconds).toString()).format("MMM DD, YYYY hh:mm a")}
  </StyledTime>
);

export default Time;
