// @flow

import React from "react";

import { AllRecordsContainer } from "../styles";
import Icon from "src/icons";

type Props = {
  fieldType: string
};

const AllRecords = ({ fieldType }: Props) => {
  return (
    <AllRecordsContainer>
      <Icon type="records" />
      <span>All {fieldType} records</span>
    </AllRecordsContainer>
  );
};

export default AllRecords;
