// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { colors, fontSize } from "./constants";
import * as themeColors from "src/styles/constants/colors";
import { icons } from "src/styles";

const ButtonReset = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
`;

const Button = styled(ButtonReset)`
  min-width: 89px;
  color: ${({ white }) => (white ? colors.defaultGrey : colors.pureWhite)};
  min-height: 32px;
  border-radius: 3px;
  background-color: ${colors.blue};
  font-weight: bold;

  :disabled {
    cursor: not-allowed;
  }

  ${({ white }) =>
    white &&
    css`
      background-color: #fff !important;
      color: ${colors.mediumBlack};
      font-weight: normal;
      border: 1px solid ${colors.normalizedGrey};
    `};
`;

const ButtonDefault = styled(Button)`
  min-width: 89px;
  min-height: 32px;
  border-radius: 3px;
  background-color: ${colors.pureWhite};
  color: ${colors.mediumBlack};
  font-weight: normal;
  border: 1px solid ${colors.normalizedGrey};
`;

const ImageButton = styled(ButtonReset)`
  color: ${props => (props.active ? colors.pureWhite : colors.blue)};
  box-shadow: ${props =>
    props.active ? "0 2px 4px 0 rgba(0, 0, 0, 0.16)" : ""};
  background-image: url(${props =>
    props.active ? icons[`${props.icon}-light`] : icons[props.icon]});
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  width: 30px;
  height: 30px;
  background-color: ${props =>
    props.active ? colors.blue : colors.aliceBlue} !important;
  border-radius: 50%;
  transition: all 0.25s;
  border: none !important;
`;

const JoinButton = styled(Button)`
  margin: auto;
`;

const TextButton = styled(ButtonReset)`
  background: ${colors.pureWhite} !important;
  color: ${colors.blue} !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: left !important;
  border: none;
  margin: 0px !important;
  padding: 1.2em;
  span {
    padding-left: 24px;
  }
`;

const TextButtonWithImage = styled(TextButton)`
  position: relative;
  padding-left: 0px !important;
  svg {
    position: absolute;
    top: 12px;
  }
`;

const TextButtonWithImageAndBorder = styled(TextButton)`
  flex-grow: 0;
  border: 2px solid ${themeColors.blue500};
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.125rem;
  max-height: 35px;
  max-width: max-content;

  && span {
    padding-left: 5px;
  }
`;

const ButtonWithImage = styled(ButtonReset)`
  font-size: ${fontSize.t2};
  font-weight: bold;
  background: ${colors.blue};
  color: ${colors.pureWhite};
  display: flex;
  align-items: center;
  border: 1px solid ${colors.blue};
  :hover,
  :focus {
    border: 1px solid ${colors.clearBlue};
  }
`;

const RoundIcon = styled(ButtonReset)`
  background: ${props =>
    props.active ? colors.defaultGrey : colors.aliceBlue};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  g {
    stroke: ${props => (props.active ? colors.pureWhite : colors.mediumBlack)};
  }
`;

export {
  ImageButton,
  Button,
  JoinButton,
  ButtonWithImage,
  RoundIcon,
  TextButtonWithImage,
  ButtonDefault,
  TextButtonWithImageAndBorder
};
