// @flow

import transit from "transit-js";
import * as R from "ramda";

import fieldType from "src/transit/checklist/field/types";
import fieldWriter from "src/transit/checklist/field/writer";
import fieldReader from "src/transit/checklist/field/reader";
import { backendUrl } from "src/config/firebase";
import { hasValue } from "src/utils";
import * as easyFetch from "src/utils/fetch";

import type { RoomId, ChecklistId, HttpMethods } from "src/types";

const getAllTemplates = async () => {
  const response = await fetch(`${backendUrl}/checklist`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

const getChecklist = async (roomId: string, signal?: AbortSignal) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}/checklist`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    },
    signal
  });

  return response.json();
};

const createChecklist = async (checklist: Object) => {
  const { fields } = checklist;

  const encodedFields = R.map(item => ({
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.fieldType],
          transit.makeWriteHandler(fieldWriter[item.fieldType])
        ])
      })
      .write(
        new fieldType[item.fieldType]({
          label: item.fieldName,
          isChecked: item.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item.promptRules
  }))(fields);

  const response = await fetch(`${backendUrl}/checklist`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      fields: encodedFields
    })
  });
  return response.json();
};

const editChecklist = async (checklist: Object) => {
  const { title, fields, id } = checklist;

  const encodedFields = R.map(item => ({
    id: item.fieldId,
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.fieldType],
          transit.makeWriteHandler(fieldWriter[item.fieldType])
        ])
      })
      .write(
        new fieldType[item.fieldType]({
          label: item.fieldName,
          isChecked: item.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item.promptRules
  }))(fields);

  await fetch(`${backendUrl}/checklist/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      id,
      title,
      fields: encodedFields
    })
  });
  return { title, fields, id };
};

const getChecklistFields = async (id: number) => {
  const response = await fetch(`${backendUrl}/checklist/${id}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  const transitFields = await response.json();
  const reader = transit.reader("json", { handlers: fieldReader });

  const activeFields = R.reject(
    R.propEq("deleted", true),
    transitFields.fields
  );

  // $FlowFixMe
  const fields = R.map(
    field => ({
      ...reader.read(field.attrs),
      id: field.id,
      target: field.target,
      hidden: field.hidden,
      deleted: field.deleted,
      deletedBy: field?.deletedBy,
      deletedAt: field?.deletedAt,
      promptRules: field.promptRules,
      layout: field.layout || null
    }),
    activeFields
  );

  return fields;
};

const getChecklistFieldValue = async (
  {
    roomId,
    fieldId
  }: {
    roomId: string,
    fieldId: string
  },
  signal?: AbortSignal
) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/checklist-field/${fieldId}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      },
      signal
    }
  );

  if (response.status !== 404) {
    return response.json();
  }

  throw new Error({
    message: "Checklist value not found",
    status: response.status
  });
};

/**
 * Set checklist field value for a field of a checklist
 *
 * @param {string} roomId - Room id for which value is being set
 * @param {string} id - field id for which value is being set
 * @param {Object} value - value being set
 * @param {formId} formId - of the nested form
 * @returns {Object} new field details
 */
const setChecklistFieldValue = async (payload: {
  roomId: string,
  id: string,
  value: Object,
  formId?: ?number,
  httpMethod?: HttpMethods,
  extraBody?: Object
}) => {
  const {
    roomId,
    id,
    value,
    formId,
    httpMethod = "POST",
    extraBody = {}
  } = payload;

  const body = JSON.stringify({
    value: hasValue(value.value) ? value.value : null,
    type: value.type === "childConversation" ? "conversation" : value.type,
    checked: value.checked,
    formId: formId || null,
    ...extraBody
  });

  try {
    const response = await fetch(
      `${backendUrl}/chatroom/${roomId}/checklist-field/${id}${
        httpMethod === "DELETE" ? "/value" : ""
      }`,
      {
        credentials: "include",
        method: httpMethod,
        headers: {
          "Content-type": "application/json"
        },
        body
      }
    );

    return response.status === 204 ? null : response.json();
  } catch (error) {
    const errorJSON = await error.json();

    if (errorJSON.error) {
      throw new Error(errorJSON.error);
    } else {
      throw error;
    }
  }
};

/**
 * Get all checklist field values for a specific checklist
 *
 * @param {string} roomId room id for which checklist values are being fetched
 * @param {number} checklistId checklist for which values are being fetched
 * @returns {Array} checklist field values
 */
const getChecklistFieldValues = async ({
  roomId,
  checklistId
}: {
  roomId: RoomId,
  checklistId: ChecklistId
}) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/checklist/${checklistId}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );
  return response.json();
};

const getChecklistWorkflows = async ({ id }: { id: number }) => {
  const response = await fetch(`${backendUrl}/checklist/${id}/workflows`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

const getChecklistInstances = async ({
  id,
  filter
}: {
  id: string,
  filter: string
}) => {
  const response = await fetch(
    `${backendUrl}/checklist/${id}/values${filter}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  return response.json();
};

/**
 * Get download checklist instance
 *
 * @param  {checklist}
 * @return {Array} return array of ids (of uniq values)
 */
const downloadChecklistInstance = async ({
  checklist,
  filter
}: {
  checklist: number,
  filter: string
}) => {
  const link = document.createElement("a");

  link.href = `${backendUrl}/checklist/${checklist}/values/download?${filter}`;
  if (document.body) {
    document.body.appendChild(link);
  }
  link.click();
  if (document.body) {
    document.body.removeChild(link);
  }
};

/**
 * Get unique checklist values
 *
 * @param  {checklist}
 * @return {Array} return array of unique checklist values
 */
const getUniqueChecklistValues = async ({
  checklist
}: {
  checklist: number
}) => {
  const response = await fetch(
    `${backendUrl}/checklist/${checklist}/values/unique`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  return response.json();
};

/**
 * Get embedded field details
 *
 * @param  {field}
 * @return {Object}
 */
const getEmbeddedFields = async ({
  fieldId,
  checklistId
}: {
  fieldId: number,
  checklistId: number
}) => {
  const response = await fetch(
    `${backendUrl}/checklist/${checklistId}/checklist-field/${fieldId}/embedded-field`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  const transitFields = await response.json();
  const reader = transit.reader("json", { handlers: fieldReader });
  const fields = R.map(
    field => ({
      ...reader.read(field.type),
      id: field.id
    }),
    transitFields
  );

  return fields;
};

/**
 * Get URLs to view signatures
 */
export const getSignatureURLs = () => easyFetch.get(`/signature`);

/**
 * Request OTP from the backend
 *
 * @param {string} email - email to which OTP is to be sent
 */
export const requestOTP = (email: string) =>
  easyFetch.post(`/otp?email=${email}`);

export {
  getAllTemplates,
  getChecklist,
  createChecklist,
  editChecklist,
  getChecklistFields,
  getChecklistFieldValue,
  setChecklistFieldValue,
  getChecklistFieldValues,
  getChecklistWorkflows,
  getChecklistInstances,
  downloadChecklistInstance,
  getUniqueChecklistValues,
  getEmbeddedFields
};
