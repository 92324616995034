// @flow

import React from "react";

const TriangleDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path fill="#2A2A2A" d="M3 6l5 5 5-5H3z" />
  </svg>
);

export default TriangleDown;
