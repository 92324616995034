// @flow

import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Common from "./Common";
import { UserName } from "./styles";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import { getAuthorColor } from "src/utils";
import * as notifications from "src/constants/notifications";
import type { Message } from "src/types";
import GroupMembersModal from "./GroupMembersModal";

type Props = {
  message: Message
};

const Group = ({ message }: Props) => {
  const address = useSelector(({ app }) =>
    getChatroomAddress(app, getCurrentRoomId(app))
  );

  const { data: groupIds, action } = message;
  const groupAddAction = action === notifications.GROUP_ADD;
  const userAction = groupAddAction ? "added" : "removed";

  return (
    <Common {...message} blackText>
      {" "}
      {userAction}{" "}
      {groupIds.map((group, i) => {
        const { id } = group;
        const authorId = id.toString();

        return (
          <Fragment key={id}>
            {i === groupIds.length - 1 && groupIds.length > 1 && " and "}
            <UserName hashColor={getAuthorColor(authorId, address)}>
              <strong>
                <GroupMembersModal id={id} />
              </strong>
            </UserName>
            {i !== groupIds.length - 2 &&
              i !== groupIds.length - 1 &&
              groupIds.length > 1 &&
              ", "}
          </Fragment>
        );
      })}
    </Common>
  );
};

export default Group;
