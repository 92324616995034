// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";
import moment from "moment";
import { Radio, Text } from "@chakra-ui/react";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Separator
} from "../styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import Item from "./Item";
import {
  setManageViewFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import {
  getAllWorkflows,
  getChecklistFieldsById,
  getAllRecords
} from "src/reducers";
import BlanksNonBlanks from "../BlanksNonBlanks";
import RadioFilter from "./RadioFilter";
import LastOrFirstNFilter from "./LastOrNextNFilter";
import { dateRangeFilters, dateRangeToLabelMap } from "../../manageViewFilters";
import { DateRangeFilterContainer } from "./styles";

type Props = {
  parentRef: any,
  column: string,
  _setManageViewFilter: Function,
  handleClose: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<any>
};

const getValue = (type: string, newValue: ?string) => {
  if (newValue === null || newValue === "Inf") {
    return null;
  }

  if (type === "low") {
    return moment(newValue).subtract(1, "days");
  }

  return moment(newValue).add(1, "days");
};

const filterDate = (filter: Array<string>) => {
  return R.filter(
    item =>
      !["null", "notnull", ...dateRangeFilters].includes(item) &&
      !item.startsWith("lastn") &&
      !item.startsWith("nextn"),
    filter
  );
};

const handleValueChange = (dateValue: string, filter: Array<string> = []) => {
  if (
    filter.length === 1 &&
    (filter[0] === "null" || filter[0] === "notnull")
  ) {
    const newValue = filter.push(dateValue);

    return newValue;
  } else if (filter.length) {
    const newValue = R.map(filterItem => {
      if (filterItem !== "null" && filterItem !== "notnull") {
        return dateValue;
      }
      return filterItem;
    }, filter);

    return newValue;
  } else {
    return [dateValue];
  }
};

const Date = ({
  parentRef,
  column,
  _setManageViewFilter,
  handleClose,
  setFilter,
  filter = [],
  toggleSortBy
}: Props) => {
  const dispatch = useDispatch();
  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  let workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const getLabel = useCallback(
    (type: string) => {
      switch (column) {
        case "createdAt":
          return type === "after" ? "Created After" : "Created Before";
        case "updatedAt":
          return type === "after" ? "Updated After" : "Updated Before";
        case "completedAt":
          return type === "after" ? "Completed After" : "Completed Before";
        case "dueDate":
          return type === "after" ? "Due After" : "Due Before";
        default:
          return type === "after" ? "After" : "Before";
      }
    },
    [column]
  );

  const dateValue = filter ? filterDate(filter) : null;
  const lowVal = dateValue?.length
    ? R.head(filterDate(filter)[0].split("::"))
    : null;
  const highVal = dateValue?.length
    ? R.last(filterDate(filter)[0].split("::"))
    : null;

  const handleLowVal = useCallback(
    (newDate: ?string) => {
      if (!newDate && highVal === "Inf") {
        setFilter(column, []);
        return;
      }
      const lowVal = `${
        newDate
          ? moment(newDate).startOf("day").add(1, "days").format("YYYY-MM-DD")
          : "Inf"
      }::${highVal || "Inf"}`;

      setFilter(column, handleValueChange(lowVal, filter));
    },
    [filter, _setManageViewFilter]
  );

  const handleHighVal = useCallback(
    (newDate: ?string) => {
      if (!newDate && lowVal === "Inf") {
        setFilter(column, []);
        return;
      }

      const highVal = `${lowVal || "Inf"}::${
        newDate
          ? moment(newDate)
              .startOf("day")
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          : "Inf"
      }`;

      setFilter(column, handleValueChange(highVal, filter));
    },
    [column, filter, _setManageViewFilter]
  );

  const handleSelect = useCallback(
    (event: Event, item: string | number) => {
      event.preventDefault();
      event.stopPropagation();

      if (R.includes(item, filter || [])) {
        setFilter(column, R.reject(R.equals(item), filter));
      } else {
        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter]
  );

  const handleRadioFilterSelect = (item: string) => {
    const webWorker = new WebWorker(worker);

    // $FlowFixMe - Flow type infer error, type of event listener not clear
    webWorker.addEventListener("message", event => {
      dispatch(filterEmbeddedFields({ instances: event.data }));
    });

    workerData = {
      ...workerData,
      payload: {
        ...filters,
        [column]: [item]
      }
    };

    webWorker.postMessage(workerData);
    setFilter(column, [item]);
  };

  const lowDisplayVal = getValue("low", lowVal);
  const highDisplayVal = getValue("high", highVal);

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>Filter by</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
      <Separator />
      <ul>
        {dateRangeFilters.map(dateRangeFilter => (
          <RadioFilter
            key={dateRangeFilter}
            id={dateRangeFilter}
            label={dateRangeToLabelMap[dateRangeFilter]}
            currentFilter={filter[0]}
            handleSelect={handleRadioFilterSelect}
          />
        ))}
        <LastOrFirstNFilter
          id="lastn"
          currentFilter={filter[0]}
          label="Last"
          handleSelect={handleRadioFilterSelect}
        />
        <LastOrFirstNFilter
          id="nextn"
          currentFilter={filter[0]}
          label="Next"
          handleSelect={handleRadioFilterSelect}
        />
        <DateRangeFilterContainer>
          <Radio
            size="sm"
            isChecked={dateValue?.length}
            onClick={() => {
              !lowDisplayVal && !highDisplayVal
                ? handleLowVal(moment().toDate())
                : null;
            }}
          >
            <Text fontSize="xs" m={0}>
              Date range
            </Text>
          </Radio>
          <Item
            label={getLabel("after")}
            id={`${column}After`}
            value={lowDisplayVal}
            handleChange={handleLowVal}
          />
          <Item
            label={getLabel("before")}
            id={`${column}Before`}
            value={highDisplayVal}
            handleChange={handleHighVal}
          />
        </DateRangeFilterContainer>
      </ul>
    </StyledFilter>
  );
};

export default connect(null, {
  _setManageViewFilter: setManageViewFilter
})(Date);
