// @flow

import { connect } from "react-redux";
import React, { memo } from "react";

import { ChatBlockWrapper } from "./styles";
import ChatBlock from "src/containers/chatroom-nav/ChatBlock";

import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const Chat = ({ roomId }: Props) => {
  return (
    <ChatBlockWrapper key={roomId} role="listitem">
      <ChatBlock key={roomId} roomId={roomId} />
    </ChatBlockWrapper>
  );
};

export default connect(null)(memo<Props>(Chat));
