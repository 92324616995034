// @flow

import React from "react";

type Props = {
  fill?: string
};

const People = ({ fill, ...restProps }: Props) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill={fill || "#fff"}
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g clipPath="url(#prefix__clip0_1791_992)">
      <path d="M8 8a4 4 0 100-8 4 4 0 000 8zM16 13.2a1.988 1.988 0 00-1.163-1.818A16.654 16.654 0 008 10a16.654 16.654 0 00-6.837 1.382A1.99 1.99 0 000 13.2V16h16v-2.8z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_1791_992">
        <path d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default People;
