import { schema } from "normalizr";

// linked field value
const linkedRoom = new schema.Entity(
  "chatrooms",
  {},
  { idAttribute: "originChatroomId" }
);

const linkedFieldValue = [linkedRoom];

// Map of normalizer for each field type
const normalizers = {
  link: linkedFieldValue
};

export default normalizers;
