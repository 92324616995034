// @flow

import styled from "@emotion/styled";

const Close = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export default Close;
