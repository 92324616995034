// @flow

import React from "react";

import { Table as StyledTable, TableContainer } from "./styles";
import Header from "./Header";
import Body from "./Body";

type Props = {
  searchResults: Array<string>
};

const Table = ({ searchResults }: Props) => (
  <TableContainer>
    <StyledTable cellspacing="0" cellpadding="0">
      <Header />
      <Body searchResults={searchResults} />
    </StyledTable>
  </TableContainer>
);

export default Table;
