// @flow

import * as easyFetch from "src/utils/fetch";

export const getCloneLink = (orgId: number, excluded: string[]) =>
  easyFetch.get(`/org/${orgId}/clone-url`, {
    params: {
      excluded
    }
  });

export const getEntities = () => easyFetch.get(`/entity`);

export const sendVerificationEmail = ({
  email,
  orgId
}: {
  email: string,
  orgId: number
}) =>
  easyFetch.post("/signup/user", {
    body: {
      email,
      templateOrgId: orgId,
      source: "clone"
    }
  });

export const cloneOrg = (payload: {
  templateOrgId: number,
  title: string,
  invitees: string[],
  token: string
}) => easyFetch.post("/org", { body: payload });

export const signUp = ({
  email,
  password,
  displayName,
  orgId,
  cloneToken,
  authCode
}: {
  email: string,
  password: string,
  displayName: string,
  orgId: number,
  cloneToken: string,
  authCode: string
}) =>
  easyFetch.post("/user", {
    body: {
      email,
      password,
      displayName,
      authCode
    },
    headers: {
      "x-unifize-org": orgId,
      "x-unifize-clone-token": cloneToken
    }
  });

export const validateCloneLink = ({
  orgId,
  cloneToken
}: {
  orgId: number,
  cloneToken: string
}) =>
  easyFetch.post(`/org/${orgId}/clone-url/validate`, {
    body: {
      token: cloneToken
    }
  });

export const getOrgDetails = ({
  orgId,
  cloneToken
}: {
  orgId: number,
  cloneToken: string
}) =>
  easyFetch.get(`/org/${orgId}`, {
    headers: {
      "x-unifize-org": orgId,
      "x-unifize-clone-token": cloneToken
    }
  });

export const getOrgCloneStatus = ({ orgId }: { orgId: number }) =>
  easyFetch.get(`/org/${orgId}/clone-status`);
