// @flow

import React from "react";

import { AddButton } from "./styles";
import { convertToTitleCase } from "src/utils";

type Props = {
  type?: string,
  isSelect?: boolean,
  disabled: boolean,
  handleClick: Function,
  isMandatory?: boolean
};

const AddData = ({
  type = "",
  handleClick,
  isSelect = false,
  disabled,
  isMandatory
}: Props) => {
  if (isSelect)
    return (
      <AddButton
        data-cy="addButtonSelect"
        onClick={handleClick}
        disabled={disabled}
        isMandatory={isMandatory}
      >
        Select
      </AddButton>
    );
  return (
    <AddButton
      data-cy={`addButton${convertToTitleCase(type ?? "") ?? ""}`}
      onClick={handleClick}
      disabled={disabled}
      isMandatory={isMandatory}
    >
      {isSelect ? "Select" : `\u002B Add ${type || ""}`}
    </AddButton>
  );
};

export default AddData;
