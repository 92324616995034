// @flow

import React, { useState } from "react";

import type { FormBuilderField } from "src/types";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Table, Tbody } from "@chakra-ui/react";

import { Form } from "src/styles/form.old";
import { Builder as StyledBuilder } from "src/styles/popover.old";
import { Fields as StyledFields } from "src/styles/checklist";
import { TextButtonWithImageAndBorder } from "src/styles/buttons.old";
import Icon from "src/components/InlineSVG";
import { iconSize } from "src/styles/constants";
import * as colors from "src/styles/constants/colors";

import Field from "./Field";
import NewFieldModal from "./NewFieldModal";

const FieldItem = SortableElement(
  ({ value, position, addFieldBelow, removeField, updateField }) => (
    <Field
      value={value}
      position={position}
      addFieldBelow={addFieldBelow}
      removeField={removeField}
      updateField={updateField}
    />
  )
);

const FieldList = SortableContainer(
  ({ items, addFieldBelow, removeField, updateField }) => (
    <Tbody>
      {items.map((value, index) => (
        <FieldItem
          value={value}
          addFieldBelow={addFieldBelow}
          removeField={removeField}
          updateField={updateField}
          index={index}
          position={index}
          key={`item-${index}`}
        />
      ))}
    </Tbody>
  )
);

type Props = {
  items: FormBuilderField[],
  addField: Function,
  removeField: Function,
  updateField: Function,
  onSortEnd: Function
};

export default function Fields({
  items,
  onSortEnd,
  addField,
  removeField,
  updateField
}: Props) {
  const [addFieldModalData, setAddFieldModalData] = useState({
    isOpen: false,
    index: null
  });

  const closeAddFieldModal = () => {
    setAddFieldModalData({
      isOpen: false,
      index: null
    });
  };

  const selectFieldType = fieldType => {
    const { index } = addFieldModalData;
    addField({
      fieldType,
      index
    });

    closeAddFieldModal();
  };

  const addFieldBelowField = index => {
    setAddFieldModalData({
      isOpen: true,
      index
    });
  };

  return (
    <StyledBuilder>
      <Form>
        <StyledFields>
          <Table variant="unstyled" size="sm">
            <FieldList
              items={items}
              addFieldBelow={addFieldBelowField}
              removeField={removeField}
              updateField={updateField}
              onSortEnd={onSortEnd}
              useDragHandle
            />
          </Table>
        </StyledFields>
      </Form>
      <TextButtonWithImageAndBorder
        type="button"
        onClick={() =>
          setAddFieldModalData({
            isOpen: true,
            index: null
          })
        }
      >
        <Icon
          icon="hollowPlusCircle"
          color={colors.blue500}
          size={iconSize.big}
        />
        <p>Add Fields</p>
      </TextButtonWithImageAndBorder>

      <NewFieldModal
        isOpen={addFieldModalData.isOpen}
        onClose={closeAddFieldModal}
        onSelect={selectFieldType}
      />
    </StyledBuilder>
  );
}
