// @flow

import { StackProps, IconButtonProps } from "@chakra-ui/react";
import { SystemCSSProperties } from "@chakra-ui/react";

export const actions: StackProps = {
  spacing: 3
};

export const processBuilderLink = {
  lineHeight: 0
};

export const jsonMenuButton: IconButtonProps = {
  height: "16px",
  minWidth: "16px"
};

export const jsonMenuList: SystemCSSProperties = {
  zIndex: 3
};
