// @flow

import { connect } from "react-redux";
import Visualization from "./Visualization";

import { getChartData } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chartData: getChartData(app, props.chartId)
});

export default connect(mapStateToProps)(Visualization);
