// @flow

import React from "react";
import moment from "moment";

import { dateRangeToLabelMap } from "../Table/manageViewFilters";
import { capitalize } from "src/utils";

type Props = {
  value: string
};

const DateField = ({ value }: Props) => {
  if (dateRangeToLabelMap[value]) {
    return <span>{dateRangeToLabelMap[value]}</span>;
  } else if (value.startsWith("lastn") || value.startsWith("nextn")) {
    return (
      <span>{`${capitalize(
        value.replace(/^(last|next)n-/, "$1 ").replace(/-/g, " ")
      )}`}</span>
    );
  }
  const dates = (value || "").split(":").filter(d => d !== "");
  const start = dates.length > 0 ? dates[0] : null;
  const end = dates.length === 2 ? dates[1] : null;

  return (
    <>
      <strong>{start ? moment(start).format("MMM DD, YYYY") : ""}</strong>
      <strong>{start && end ? " to " : ""}</strong>
      <strong>{end ? moment(end).format("MMM DD, YYYY") : ""}</strong>
    </>
  );
};

export default DateField;
