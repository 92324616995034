// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  creatorIsOwner: boolean,
  handleCreatorIsOwner: Function
};

const AddAsOwner = ({ handleCreatorIsOwner, creatorIsOwner }: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="creatorIsOwner"
        checked={creatorIsOwner === undefined ? false : !creatorIsOwner}
        handleChange={handleCreatorIsOwner}
      />
      {/* Add creator as owner */}
      Do not add creator as owner
    </Label>
  </InlineFormGroup>
);

export default AddAsOwner;
