// @flow

const defaultTypes = [
  { id: -1, title: "Direct Messages" },
  { id: -2, title: "Processes", invocationCount: null },
  { id: -3, title: "Tasks", invocationCount: null },
  { id: -4, title: "Approvals", invocationCount: null },
  { id: -5, title: "Feeds", invocationCount: null }
];

export default defaultTypes;
