// @flow

import styled from "@emotion/styled";
import theme from "src/styles/theme";
import { Message } from "src/components/Manage/Builder/Reminders/styles";

export const Container = styled(Message)`
  min-height: 3em;

  border-color: ${theme.colors.gray[200]};
  border-radius: 6px;

  > div:first-of-type {
    min-height: 3em;
    > div:first-of-type > div:first-of-type {
      min-height: 3em;
    }
  }

  textarea {
    min-height: 3em;
  }

  .mention-suggestion {
    z-index: inherit;
  }
`;
