// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import { keyframes } from "@chakra-ui/react";
import type { StackProps } from "@chakra-ui/react";

import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { colors as oldColors } from "src/styles/constants";
import { Button } from "src/styles/buttons";

export const Dock = styled.div`
  background-color: ${colors.brightLight};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DockContainer = styled.div`
  overflow-y: hidden;
  background-color: ${colors.brightLight};
  z-index: 1;
  .leftHandle {
    left: 0 !important;
    z-index: 1;
    top: -20px !important;
    min-height: 100vh !important;
  }
  > div {
    height: 100% !important;
    overflow-y: hidden;
  }
`;

export const Info = styled.div`
  width: 100%;
  height: ${props => props.headerHeight || 90}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  border-bottom: solid 1px ${colors.grey3};
  font-size: 1rem;
  font-weight: ${typography.semiBold};
  margin-bottom: ${props => (props.refreshOption ? "0em" : "0.5em")};
`;

export const InfoTitleContainer = styled.div`
  margin-left: ${spacing.space_l};
  display: flex;
  flex-direction: column;
`;

export const NavContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const BackContainer = styled.div`
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CloseDock = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const Context = styled.div`
  margin-left: -0.7em;
  padding: 0.7em 1.4em;
`;

export const BreadcrumbWrapper = styled.div`
  > div {
    font-size: 0.75rem;
    margin-bottom: 0.7em;
    flex-wrap: wrap;
    height: initial;
  }
`;

export const Workflow = styled.div`
  font-size: 0.88rem;
  color: ${colors.secondary};
  margin-bottom: ${spacing.space_s};
  padding: ${spacing.space_s} ${spacing.space_m};
`;

export const Display = styled.div`
  font-size: 1rem;
  font-weight: ${typography.semiBold};
  cursor: pointer;
  display: inline-block;
  padding: ${spacing.space_m};
  word-break: break-word;
`;

export const Edit = styled.div`
  > textarea {
    width: 100%;
    padding: ${spacing.space_m};
    background: ${colors.highlight};
    margin-bottom: ${spacing.space_m};
    border-radius: ${radius.small};
    outline: none;
    border-width: 0;
    resize: initial;
    font-size: 0.88rem;
  }
`;

export const Objective = styled.div`
  margin: ${spacing.space_l};
`;

export const ObjDisplay = styled.div`
  font-size: 0.88rem;
  padding: ${spacing.space_s};
`;

export const ObjEdit = styled.div`
  > textarea {
    width: 100%;
    padding: ${spacing.space_s};
    background: ${colors.highlight};
    margin-bottom: ${spacing.space_m};
    border-radius: ${radius.small};
    outline: none;
    border-width: 0;
    resize: initial;
    font-size: 0.88rem;
  }
`;

export const SaveContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const SaveButton = styled(Button)``;

export const NoTitle = styled.span``;

export const ItemList = styled.div`
  padding: 0 16px;
  flex: 1;
`;

export const LeaveContainer = styled.div`
  padding: 1.3em;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  > div {
    display: inline-flex;
    cursor: pointer;
    color: ${colors.danger};
    font-size: 0.88rem;
    align-items: center;
    > svg {
      margin-right: 1.8em;
    }
  }
`;

export const Item = styled.div`
  border-top: 1px solid ${colors.sky};
  display: flex;
  height: ${ifProp("parent", "initial", "60px")};
  padding: ${spacing.space_m} 0;
  align-items: center;
  cursor: ${ifProp("email", "initial", "pointer")};
`;

export const ConvoID = styled.input`
  font-size: 0.88rem;
  border-width: 0px;
`;

export const CopyContainer = styled.div`
  cursor: pointer;
`;

export const ItemIcon = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const UserIcon = styled.div`
  > div > div {
    width: 24px;
    height: 24px;
    border-radius: ${radius.medium};
    background-size: 24px;
    font-size: 0.75rem;
  }
`;

export const ItemInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ItemLabel = styled.div`
  font-size: 0.75rem;
  color: ${colors.secondary};
`;

export const ItemValue = styled.div`
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.88rem;
  font-weight: normal;
  > span {
    font-weight: normal;
  }
`;

export const Email = styled.div`
  > h4 {
    margin-bottom: 0.5em;
  }
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: row;
    padding: ${spacing.space_default} 0;
    > input {
      padding: ${spacing.space_inset_stretch_s};
      flex-grow: 1;
      outline: none;
    }
    > button {
      width: 7em;
      font-weight: 500;
      margin: 0 ${spacing.space_m};
    }
  }
  > button {
    margin: auto;
  }
`;

export const ChecklistHeader = styled.div`
  display: flex;
  margin-bottom: 2em;
  > svg {
    margin-right: 1.2em;
  }
  > div {
    font-size: 0.88rem;
  }
`;

export const Checklist = styled.div`
  padding: 1.2em;
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Files = styled.div`
  padding: 1.2em;
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FileHeader = styled.div`
  width: 100%;
  display: flex;
  > svg {
    margin-right: 0.6em;
  }
  margin-bottom: 1.2em;
`;

export const Parent = styled.div`
  width: 230px;
  height: 40px;
  > div {
    width: 230px;
    height: 36px;
    > div:first-of-type {
      height: 100%;
      > div {
        width: 100%;
        height: 100%;
        padding: 0;
        padding-left: 6px;
        display: flex;
        align-items: center;
      }
    }
  }
  input {
    min-width: initial;
    width: 230px;
  }
`;

export const ImageThumbnail = styled.img`
  height: 64px;
  width: 64px;
  border-radius: ${radius.medium};
  align-self: center;
`;

export const FileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    height: 60px;
    width: 50px;
  }
`;

export const File = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 1.2em;
`;

export const Thumbnail = styled.div`
  margin-right: ${spacing.space_m};
  cursor: pointer;
  > svg {
    width: 64px;
    height: 64px;
    object-fit: contain;
    border-radius: ${radius.medium};
  }
`;

export const FileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
`;

export const Name = styled.div`
  width: 190px;
  font-size: 0.88rem;
  color: ${colors.primary};
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Size = styled.div`
  font-size: 0.75rem;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.space_s};
  > div:last-child {
    margin-left: ${spacing.space_s};
  }
`;

export const Author = styled.div`
  > span > span {
    font-weight: initial;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  position: absolute;
  border-radius: ${radius.small};
  box-shadow: 0 4px 10px 0 #00000019;
  background-color: ${colors.brightLight};
  z-index: 2;
  right: 0;
  top: 20px;
  padding: ${spacing.space_m};
  width: 170px;
  > div:last-child {
    color: ${colors.danger};
  }
`;

export const DropdownItem = styled.div`
  color: ${colors.primary};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.space_l} ${spacing.space_s};
  :hover {
    background-color: ${colors.grey7};
  }
`;

export const Privacy = styled.div`
  padding: ${spacing.space_l};
`;

export const Label = styled.div`
  font-size: 0.88rem;
`;

export const SubHeading = styled.div`
  font-size: 0.75rem;
  color: ${colors.secondary};
`;

export const Type = styled.div``;

export const Option = styled.div`
  padding: ${spacing.space_l} ${spacing.space_m};
  border: 1px solid ${oldColors.silver};
  border-radius: ${spacing.space_m};
  margin-top: ${spacing.space_l};

  > div > div > label > div {
    margin-top: ${spacing.space_xs};
    margin-right: ${spacing.space_l};
    width: ${spacing.space_l} !important;
    height: ${spacing.space_l} !important;
    > span {
      width: 10px !important;
      height: 10px !important;
    }
  }
`;

export const Header = styled.div`
  display: inline-flex;
  > div > div > svg {
    width: ${spacing.space_l};
    height: ${spacing.space_l};
    margin-right: 6px;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing.space_m};
  font-size: 0.88rem;
`;

export const TypeHeader = styled.h4`
  margin: 0;
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  z-index: 3;
  background: rgba(42, 42, 42, 0.3);
`;

export const ReminderWrapper = styled(Item)`
  cursor: default;
`;

export const StyledSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  > label {
    padding: 0 2px;
  }
`;

export const fadeIn = keyframes`
 from {
  background: transparent
 }

 to {
  background: ${colors.active}
 }
`;

export const fadeOut = keyframes`
 from {
  background: ${colors.active}
 }

 to {
  background: transparent
 }
`;

export const ChecklistAffordance = {
  height: "100%",
  zIndex: 10,
  opacity: 0.7,
  _hover: {
    animation: `${fadeIn} 1 0.3s`,
    background: colors.active
  }
};

export const AffordanceHandle = {
  width: "0.25rem"
};

export const ResizableContainer = {
  display: "flex",
  zIndex: 0,
  margin: 0,
  position: "relative"
};

export const Reminders: StackProps = {
  width: "100%",
  display: "flex"
};

export const RemindersAlt = {
  cursor: "pointer",
  width: "100%",
  height: "2.5rem",
  padding: "1rem",
  display: "inline-flex",
  alignItems: "center",
  _hover: {
    borderRadius: 0,
    background: colors.grey33
  },
  _focus: {
    borderRadius: 0,
    background: colors.grey33
  }
};

export const RemindersLabel = {
  cursor: "pointer",
  width: "100%",
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem"
};
