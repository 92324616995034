// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";

import { resetHasNewMessage } from "src/actions/chatroom";

import type { AppState } from "src/types";

type Props = {
  hasNewMessage: boolean,
  scrollToBottom: Function,
  _resetHasNewMessage: Function
};

const ScrollToLastMessage = ({
  hasNewMessage,
  scrollToBottom,
  _resetHasNewMessage
}: Props) => {
  useEffect(() => {
    if (hasNewMessage) {
      scrollToBottom();
      _resetHasNewMessage();
    }
  }, [scrollToBottom, hasNewMessage]);

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  hasNewMessage: app.recentChats.newMessage
});

export default connect(mapStateToProps, {
  _resetHasNewMessage: resetHasNewMessage
})(ScrollToLastMessage);
