// @flow

import React from "react";
import { Flex, Text, Avatar } from "@chakra-ui/react";
import { MentionAvatar } from "./styles";
import {
  LightGreyText as Description,
  DisplayName as Title,
  FlexColumn
} from "src/containers/user/styles";

type Props = {
  title: string,
  description: string,
  icon: string
};

const SpecialMention = ({ title, description, icon }: Props) => {
  return (
    <Flex align="center">
      <Avatar sx={MentionAvatar} size="sm" name={title} src={icon} />
      <Flex sx={FlexColumn}>
        <Text isTruncated sx={Title}>
          {title}
        </Text>
        <Text isTruncated sx={Description}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SpecialMention;
