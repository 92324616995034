// @flow

import React, { useRef, useMemo } from "react";
import type { Node } from "react";

import {
  AccordionSection,
  AccordionTitle,
  AccordionContent,
  StatusType
} from "./styles";
import { AccordionIcon, AccordionText } from "src/components/Accordion/styles";
import { RedBadge } from "src/styles/badge";
import Icon from "src/icons";
import { Box } from "@chakra-ui/react";

type Props = {
  title: string,
  children: Node,
  isExpanded: boolean,
  toggleActive: () => void,
  isSectionField: boolean,
  mandatoryFieldCount: number
};

const Accordion = ({
  title,
  children,
  isExpanded,
  toggleActive,
  isSectionField,
  mandatoryFieldCount,
  ...restProps
}: Props) => {
  const contentRef = useRef<Element | null>(null);

  const scrollHeight = useMemo(() => {
    if (contentRef && contentRef.current) {
      return `${contentRef.current.scrollHeight}px`;
    }
    return "auto";
  }, [contentRef]);

  return (
    <AccordionSection {...restProps}>
      <AccordionTitle onClick={toggleActive}>
        <AccordionText>
          <StatusType>{title}</StatusType>
          {mandatoryFieldCount ? (
            <Box paddingLeft={2}>
              <RedBadge>{mandatoryFieldCount}</RedBadge>
            </Box>
          ) : null}
        </AccordionText>
        <AccordionIcon active={isExpanded}>
          <Icon type="downIcon" />
        </AccordionIcon>
      </AccordionTitle>
      <AccordionContent
        active={isExpanded}
        scrollHeight={scrollHeight}
        isSectionField={isSectionField}
      >
        {isExpanded ? children : null}
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
