// @flow

import React, { useState } from "react";
import * as R from "ramda";
import type {
  ArchiveMode,
  WorkflowStatus,
  Rules as RulesType
} from "src/types";
import type { UpdateStatusFunction } from "src/components/Manage/Builder/Status";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/react";
import Settings from "./Settings";
import Rules from "./Rules";
import * as styles from "src/components/Manage/Builder/styles";

export type LocalSettings = {
  active: boolean,
  rules: RulesType,
  settings: {
    archiveMode: ArchiveMode
  }
};

type Props = {
  isOpen: boolean,
  onClose: Function,
  id: number,
  title: string,
  updateStatusData: UpdateStatusFunction,
  data: WorkflowStatus
};

const getLocalSettings = (data: WorkflowStatus): LocalSettings => {
  let localSettings = {
    active: data.active,
    rules: data.rules || {
      version: 2,
      defaultBehavior: {
        state: "enabled",
        tooltip: ""
      },
      blocks: []
    },
    settings: {
      archiveMode: data.settings?.archiveMode || "ignore"
    }
  };

  if (!R.hasPath(["rules", "defaultBehavior", "state"])(localSettings)) {
    localSettings = R.mergeDeepRight(localSettings, {
      rules: {
        defaultBehavior: {
          state: "enabled",
          tooltip: ""
        }
      }
    });
  }

  return localSettings;
};

export default function SettingsModal({
  isOpen,
  onClose,
  id,
  title,
  data,
  updateStatusData
}: Props) {
  const [localSettings, setLocalSettings] = useState<LocalSettings>(
    getLocalSettings(data)
  );

  const handleSave = () => {
    // $FlowFixMe
    updateStatusData(id, localSettings);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <Tabs>
            <TabList>
              <Tab sx={styles.tabContainer}>Status Settings</Tab>
              <Tab sx={styles.tabContainer}>Conditions</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Settings
                  localSettings={localSettings}
                  setLocalSettings={setLocalSettings}
                />
              </TabPanel>

              <TabPanel>
                <Rules
                  localSettings={localSettings}
                  setLocalSettings={setLocalSettings}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter
          sx={{
            justifyContent: "start"
          }}
        >
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
