// @flow

import React from "react";
import type { UID } from "src/types";

import { FormControl, FormLabel } from "@chakra-ui/react";
import UserSelectMultiple from "src/containers/user/SelectMultiple";

type Props = {
  value: UID[],
  onChange: Function
};

export default function ParticipantsToRemove({ value, onChange }: Props) {
  return (
    <FormControl>
      <FormLabel fontSize="sm">Remove participants</FormLabel>
      <UserSelectMultiple value={value} handleChange={onChange} />
    </FormControl>
  );
}
