// @flow

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import NewMultiSelectConversation from "./NewMultiSelectConversation";
import NewRoom from "./NewRoom";
import Dropdown from "./Dropdown";
import SelectionMultiple from "./SelectionMultiple";
import useFields from "../useFields";

import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";
import {
  getSelectedChecklist,
  getRoomFieldValueStatus,
  getChecklistFieldDetails,
  getChecklistValue,
  getWhetherMandatoryField,
  getLockedStatus,
  getBehaviorByFormField,
  getChecklistFieldBehavior,
  getCurrentRoomId,
  getWorkflowNextSeqNo,
  getFormFieldValueStatus,
  getFormFieldMandatoryStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  isProcessRowSelected,
  getWorkflow
} from "src/reducers";
import { setChecklistValue, createConversation } from "src/actions/checklist";
import {
  getNextSeqNo,
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";

import { dataStages } from "src/constants";
import appLocation from "src/constants/location";
import type {
  RoomId,
  ColumnId,
  FieldId,
  FieldValue,
  HttpMethods
} from "src/types";
import { MultiSelect as StyledMultiSelect } from "./styles";

type Props = {
  formId: ?number,
  roomId: RoomId,
  columnId: ?ColumnId,
  fieldId: number,
  checklistId: ?number,
  promptCallback?: ?Function,
  roomFieldFormId?: ?string,
  parentConversation: boolean,
  location: ?string,
  fromManageView?: ?boolean,
  setSelectedFieldValue: ?(any) => void
};

const MultiSelect = ({
  roomId,
  fieldId,
  formId,
  roomFieldFormId,
  promptCallback = null,
  checklistId,
  parentConversation = false,
  location,
  fromManageView = false,
  setSelectedFieldValue
}: Props) => {
  const dispatch = useDispatch();
  const {
    columnId,
    embeddedIndex,
    index,
    roomId: selectedRoomId,
    value: selectedValue
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );

  const checklistFieldValueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const formFieldValueStatus = useSelector(({ app }) =>
    getFormFieldValueStatus(app, fieldId, formId)
  );
  const valueStatus = formId ? formFieldValueStatus : checklistFieldValueStatus;

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const disabled = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const originalRoomId = fromManageView ? selectedRoomId : roomId;

  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const behaviorByFormField = useSelector(({ app }) =>
    getBehaviorByFormField(app, roomFieldFormId || "")
  );
  const behaviorByChecklistField =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, fieldId)) ||
    {};
  const currentRoomId = useSelector(({ app }) => getCurrentRoomId(app));
  const seqNo = useSelector(({ app }) => getWorkflowNextSeqNo(app));

  const behavior = roomFieldFormId
    ? behaviorByFormField
    : behaviorByChecklistField;
  const dependentOptions = behavior.options;
  const currentBehavior = behavior.current;

  const {
    settings,
    setValue,
    value: extractedValue
  } = useFields({
    checklistValue,
    details
  });
  const workflowData = useSelector(({ app }) =>
    getWorkflow(app, `${settings?.workflow ?? ""}`)
  );

  const value = fromManageView
    ? (selectedValue || []).map(conv =>
        typeof conv === "object" ? conv.id : conv
      )
    : extractedValue;

  const fields = (settings || {}).fields || [];
  const type = (settings || {}).type || "group";
  const workflow = (settings || {}).workflow || null;
  const showMetaData = (settings || {}).showMetaData || false;
  const showFieldNames = (settings || {}).showFieldNames || false;
  const multiple = (settings || {}).multiple || false;
  const sortBy = (settings || {}).sortBy || "added";
  const showArchived = settings?.showArchived ?? false;

  const [newRoom, setNewRoom] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          dispatch(
            bulkUpdateProcess({
              attrs: {
                [fieldId]: value
              }
            })
          );
        } else {
          setSelectedFieldValue && setSelectedFieldValue(value);
        }
      } else {
        dispatch(
          updateChecklistFromManageView(
            selectedRoomId,
            {
              [fieldId]: value,
              type,
              value
            },
            index,
            fieldId,
            httpMethod,
            extraBody,
            columnId ?? "",
            embeddedIndex
          )
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        dispatch(
          setChecklistValue({
            roomId,
            id: fieldId,
            value,
            progress: true,
            formId,
            columnId
          })
        );
      }
    },
    []
  );

  const handleClose = useCallback(() => {
    setNewRoom(false);
    setDropdown(false);
  }, [setNewRoom, setDropdown]);

  const handleCreate = useCallback((title: ?string) => {
    let req: Object = {
      title,
      workflow,
      formId,
      type,
      fieldId,
      roomId,
      checklistId,
      multiple,
      selected: value,
      groups: workflowData?.groups ?? []
    };

    handleClose();

    if (parentConversation) {
      req.parent = parseInt(currentRoomId, 10);
    }

    if (seqNo) {
      req.seqNo = seqNo;
    }

    dispatch(createConversation(req, location || appLocation.checklistDock));
  }, []);

  const handleNewRoom = useCallback(() => {
    setNewRoom(true);
    setDropdown(false);

    if (workflow) {
      dispatch(getNextSeqNo(parseInt(workflow, 10)));
    }
  }, [workflow, setNewRoom, setDropdown, getNextSeqNo]);

  const handleRemove = useCallback(
    (id: number) => {
      const newValue = (value || []).filter(
        conversation => conversation !== parseInt(id, 10)
      );

      setValue(newValue);
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "conversation",
          checked: false
        },
        columnId,
        embeddedIndex,
        progress: true,
        formId
      });
    },
    [
      formId,
      roomId,
      fieldId,
      columnId,
      value,
      checklistId,
      setValue,
      setChecklistFieldValue
    ]
  );

  const handleSelect = useCallback(
    (id: number) => {
      const newValue = [...(value || []), parseInt(id, 10)];
      setValue(newValue);
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "conversation",
          checked: true
        },
        columnId,
        embeddedIndex,
        progress: true,
        formId
      });
      if (promptCallback) promptCallback();
      setDropdown(false);
    },
    [
      formId,
      roomId,
      fieldId,
      columnId,
      checklistId,
      value,
      setValue,
      setChecklistFieldValue,
      setDropdown,
      promptCallback
    ]
  );

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationExclude],
    dependentOptions
  );

  const { create, select } = settings || {};
  return (
    <StyledMultiSelect data-cy="multipleConversations">
      <SelectionMultiple
        roomId={roomId}
        fieldId={fieldId}
        value={value}
        showMetaData={showMetaData}
        handleRemove={handleRemove}
        showFieldNames={showFieldNames}
        sortBy={sortBy}
        fields={fields}
        disabled={disabled}
        fromManageView={fromManageView ?? false}
      />

      {dropdown && (
        <Dropdown
          roomId={roomId}
          workflow={workflow}
          type={type}
          selectedValue={value || []}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          create={create}
          select={select}
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={false}
          fromManageView={fromManageView ?? false}
          showArchived={showArchived}
        />
      )}

      {newRoom && !dropdown && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      <NewMultiSelectConversation
        settings={settings || {}}
        roomId={roomId}
        fieldId={fieldId}
        newRoom={newRoom}
        dropdown={dropdown}
        handleDropdown={() => setDropdown(true)}
        disabled={disabled}
        isMandatory={isMandatory}
        updating={valueStatus === dataStages.updating}
      />
    </StyledMultiSelect>
  );
};

export default MultiSelect;
