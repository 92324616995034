// @flow

import * as R from "ramda";

import { createElementFromHTMLStr } from "src/utils";
import type { ReactRef } from "src/types";

/**
 * Converts base64 image to a file object
 * @param {string} base64Image - base64 string
 * @returns {Object} - file object
 */
const convertBase64ToFile = (base64Image: string) => {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new File([uInt8Array], `image.${R.last(imageType.split("/")) || ""}`, {
    type: imageType
  });
};

const retrieveImageFromClipboardAsBlob = (
  pasteEvent,
  callback,
  setFileData
) => {
  const { items } = pasteEvent.clipboardData;

  // If the pasted text is a base64 image, store the src
  const imgSrc: ?string = createElementFromHTMLStr(
    pasteEvent.clipboardData.getData("Text")
  )?.src;

  // Use for..in loop since `items` can be either
  // array or an object
  for (let item in items) {
    // Skip content if not image
    if (items[item].type && items[item].type.indexOf("image") === -1 && !imgSrc)
      return;

    const blob: Object = imgSrc
      ? convertBase64ToFile(imgSrc)
      : // Retrieve image on clipboard as blob
        items[item].getAsFile();

    const urlObj = window.URL || window.webkitURL;
    const filesWithUrl = Object.assign(blob, {
      preview: urlObj.createObjectURL(blob)
    });

    setFileData(filesWithUrl);

    if (typeof callback === "function") {
      callback(blob);
    }
  }
};

export const createCanvas = (
  e: any,
  canvasRef: ReactRef,
  setFileData: Function
) => {
  // Handle the event
  try {
    retrieveImageFromClipboardAsBlob(
      e,
      function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob && canvasRef && canvasRef.current) {
          const ctx = canvasRef.current.getContext("2d");
          // Create an image to render the blob on the canvas
          const img = new Image();
          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image
            const width = this.width > "564" ? "564" : this.width;
            const height = this.height > "364" ? "364" : this.height;

            canvasRef.current.width = width;
            canvasRef.current.height = height;

            // Draw the image
            ctx.drawImage(img, 0, 0);
          };
          // Cross browser support for URL
          const urlObj = window.URL || window.webkitURL;
          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = urlObj.createObjectURL(imageBlob);
        }
      },
      setFileData
    );
  } catch (error) {
    console.log(error);
  }
};
