// @flow

export const checklistFieldMeta = {
  text: ["green.300", "capitalize"],
  number: ["cyan.400", "hashmark"],
  date: ["yellow.600", "calendar"],
  user: ["pink.300", "people"],
  section: ["gray.400", "layout"], // Need UI
  subSection: ["gray.400", "layout"], // Need UI
  conversation: ["blue.300", "layers"],
  childConversation: ["blue.300", "layers"],
  select: ["orange.300", "listNumbers"],
  pdf: ["red.300", "fileArticle"],
  file: ["yellow.400", "attachClip2"],
  form: ["gray.400", "layout"],
  approval: ["purple.300", "edit2"],
  revision: ["purple.300", "edit2"], // Need UI
  chatPickList: ["purple.300", "edit2"], // Need UI
  workflow: ["purple.300", "edit2"], // Need UI
  task: ["purple.300", "edit2"], // Need UI
  group: ["purple.300", "edit2"], // Need UI
  link: ["teal.300", "anchor"]
};
