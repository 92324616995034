// @flow

const chatroomMetaData = [
  "status",
  "address",
  "title",
  "creator",
  "privacy",
  "type",
  "outcome",
  "canceled",
  "parent",
  "count",
  "memberCount",
  "active",
  "owner",
  "dueDate",
  "templateId",
  "description",
  "seqNo",
  "autoNo",
  "versionCount",
  "currentVersion",
  "version",
  "updatedAt",
  "priority",
  "meta",
  "reminders",
  "archived"
];

export default chatroomMetaData;
