// @flow

import React from "react";

const Org = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8L12 3L22 8V10H2V8ZM7 12H4V19H7V12ZM13.5 19V12H10.5V19H13.5ZM2 24V21H22V24H2ZM20 12H17V19H20V12Z"
      fill="#637381"
    />
  </svg>
);

export default Org;
