// @flow

import React from "react";

import {
  FilterItem as StyledFilterItem,
  CloseButton
} from "src/components/Manage/Workflow/Instances/styles";
import FieldName from "src/containers/checklist/FieldName";
import * as colors from "src/styles/constants/colors";
import Value from "src/containers/checklist/Value";
import Icon from "src/icons";

type Props = {
  column: string,
  value: any,
  handleClose: Function
};

const Checklist = ({ column, value, handleClose }: Props) => (
  <StyledFilterItem>
    <span>
      <FieldName id={column} />:
    </span>
    <strong>
      <Value id={column} value={value} />
    </strong>
    <CloseButton onClick={handleClose}>
      <Icon type="close" color={colors.active} />
    </CloseButton>
  </StyledFilterItem>
);

export default Checklist;
