// @flow

import React, { useCallback } from "react";

import Row from "./Row";
import VirtualList from "src/components/VirtualList";

type Props = {
  searchResults: Array<string>
};

const Body = ({ searchResults }: Props) => {
  const renderRow = useCallback(
    (index: number) => {
      const file = searchResults[index];

      if (file) {
        return <Row key={file} role="listitem" file={file} />;
      }
    },
    [searchResults]
  );

  return (
    <tbody>
      <VirtualList
        initialAmount={50}
        progressiveAmount={20}
        role="list"
        renderItem={renderRow}
        renderLoader={() => <div />}
        rowCount={searchResults.length}
      />
    </tbody>
  );
};

export default Body;
