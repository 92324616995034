// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  CloseButton
} from "@chakra-ui/react";
import Button from "src/components/Buttons/ChakraButton";
import { modalContent, modalHeader, closeButton } from "./styles";

type Props = {
  onAuthenticate: Function,
  onClose: Function,
  isOpen: boolean
};

const AuthenticationPopup = ({ onAuthenticate, onClose, isOpen }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent sx={modalContent}>
        <ModalHeader sx={modalHeader}>
          Allow Office 365 integration
          <CloseButton onClick={onClose} sx={closeButton} />
        </ModalHeader>
        <ModalBody>
          You must select a default folder on sharepoint/office365 to upload or
          create files.
        </ModalBody>
        <ModalFooter>
          <Button
            variant="uPrimary"
            onClick={() => {
              onClose();
              onAuthenticate();
            }}
          >
            Authenticate and Select Folder
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AuthenticationPopup;
