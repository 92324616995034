// @flow
import React from "react";
import type { Node } from "react";

import * as styles from "./styles";

type Props = {
  children: Node,
  customStyles?: { [string]: string | number }
};

const ModalHeader = ({ children, customStyles }: Props) => {
  return (
    <div style={{ ...styles.ModalHeader, ...customStyles }}>{children}</div>
  );
};

export default ModalHeader;
