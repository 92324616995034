// @flow

import React from "react";

type Props = {
  fill?: string
};

const Download2 = ({ fill = "#919EAB", ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.5px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <path
      d="M7.99977 1C7.876 1 7.7573 1.04917 7.66979 1.13668C7.58227 1.2242 7.5331 1.3429 7.5331 1.46667V6.6H4.7331C4.64571 6.59994 4.56006 6.62442 4.4859 6.67065C4.41174 6.71689 4.35205 6.78301 4.31363 6.86151C4.27521 6.94 4.2596 7.0277 4.26859 7.11463C4.27757 7.20156 4.31078 7.28422 4.36444 7.3532L7.6311 11.5532C7.67473 11.6094 7.73062 11.6549 7.79452 11.6862C7.85842 11.7175 7.92862 11.7338 7.99977 11.7338C8.07092 11.7338 8.14113 11.7175 8.20502 11.6862C8.26892 11.6549 8.32482 11.6094 8.36844 11.5532L11.6351 7.3532C11.6888 7.28422 11.722 7.20156 11.731 7.11463C11.7399 7.0277 11.7243 6.94 11.6859 6.86151C11.6475 6.78301 11.5878 6.71689 11.5136 6.67065C11.4395 6.62442 11.3538 6.59994 11.2664 6.6H8.46644V1.46667C8.46644 1.3429 8.41727 1.2242 8.32975 1.13668C8.24224 1.04917 8.12354 1 7.99977 1Z"
      fill={fill}
    />
    <path
      d="M14.5333 10.333C14.4096 10.333 14.2909 10.3822 14.2034 10.4697C14.1158 10.5572 14.0667 10.6759 14.0667 10.7997V13.133C14.0667 13.3805 13.9683 13.6179 13.7933 13.793C13.6183 13.968 13.3809 14.0663 13.1333 14.0663H2.86667C2.61913 14.0663 2.38173 13.968 2.2067 13.793C2.03167 13.6179 1.93333 13.3805 1.93333 13.133V10.7997C1.93333 10.6759 1.88417 10.5572 1.79665 10.4697C1.70913 10.3822 1.59043 10.333 1.46667 10.333C1.3429 10.333 1.2242 10.3822 1.13668 10.4697C1.04917 10.5572 1 10.6759 1 10.7997V13.133C1 13.6281 1.19667 14.1029 1.54673 14.4529C1.8968 14.803 2.3716 14.9997 2.86667 14.9997H13.1333C13.6284 14.9997 14.1032 14.803 14.4533 14.4529C14.8033 14.1029 15 13.6281 15 13.133V10.7997C15 10.6759 14.9508 10.5572 14.8633 10.4697C14.7758 10.3822 14.6571 10.333 14.5333 10.333Z"
      fill={fill}
    />
  </svg>
);

export default Download2;
