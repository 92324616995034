// @flow

import { ifProp } from "styled-tools";
import styled from "@emotion/styled";

import { StickyCell, depthColor } from "../styles";
import * as colors from "src/styles/constants/colors";
import { size } from "src/constants/processInstanceColumns";

export const Name = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-start;
  padding-left: 0.3em;
  > a {
    display: flex;
  }
  > a > span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 18.5em;
    text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
  }
`;

export const TitleName = styled(Name)`
  padding-left: 6px;
  width: 100%;
  overflow: hidden;
  > a {
    width: 100%;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > a > span {
    max-width: 17.5em;
    width: 100%;
    overflow: visible;
  }
`;

export const EditButton = styled.button`
  outline: none;
  border: none;
  background-color: ${props =>
    props.selected ? colors.highlight : depthColor[props.depth]};
  width: 0.5em;
  > svg {
    visibility: hidden;
    position: relative;
  }
`;

export const Edit = styled.form`
  > div > input {
    max-width: 188px;
    min-width: 100px;
  }
`;

export const ExpandButton = styled.button`
  border: none;
  outline: none;
  background-color: ${props =>
    props.selected ? colors.highlight : depthColor[props.depth]};
  width: 0px;
  > svg {
    height: 0.7em;
    width: 0.7em;
    position: relative;
  }
`;

export const Title = styled(StickyCell)`
  /* position of seqNo (36px) + width of seqNo (50px) */
  width: 100%;
  left: 86px;
  border: none;
  align-items: start;
  max-height: 100%;
  padding-left: ${props => (props.depth <= 1 ? 8 : props.depth * 10)}px;
  min-width: ${size.title};
  > div > div {
    display: flex;
    align-items: center;
  }

  :hover {
    > div > div > div > a > button > svg {
      visibility: visible;
    }
  }
`;
