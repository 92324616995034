// @flow

import React from "react";

import { IconLabelRemove as StyledIconLabel } from "./styles";
import IconLabel from "./index";
import { iconSize } from "src/styles/constants/size";
import Icon from "src/components/Icon";

type Props = {
  icon: string,
  label: string,
  removeHandler: Function
};

const Remove = ({ icon, label, removeHandler }: Props) => (
  <StyledIconLabel>
    <IconLabel icon={icon} label={label} />
    <button type="button" onClick={removeHandler}>
      <Icon icon="close" size={iconSize.space_m} />
    </button>
  </StyledIconLabel>
);

export default Remove;
