// @flow

import { connect } from "react-redux";
import React, { useCallback, useMemo } from "react";
import * as R from "ramda";

import Item from "./Item";
import Upload from "./Upload";
import { Search as StyledSearch } from "./styles";
import { ListItem } from "src/components/Manage/Reports/Instances/Chart/New/FieldSelect/styles";
import { SearchResult as StyledDropdown } from "src/styles/dropdown";
import useClickoutside from "src/hooks/useClickoutside";

import type { AppState } from "src/types";

type Props = {
  outerRef: any,
  value: Array<string>,
  result: Array<Object>,
  handleSelect: Function,
  handleClose: Function
};

const Dropdown = ({
  outerRef,
  value,
  result,
  handleSelect,
  handleClose
}: Props) => {
  useClickoutside({ outerRef, handleClose });
  const searchResults = useMemo(() => R.difference(result, value || []), [
    result,
    value
  ]);

  const handleField = useCallback(
    column => {
      handleSelect(column);
    },
    [handleSelect]
  );

  return (
    <StyledSearch>
      <StyledDropdown>
        <ul>
          {searchResults.map(file => (
            <ListItem key={file} onClick={() => handleField(file)}>
              <Item file={file} />
            </ListItem>
          ))}
          {searchResults.length === 0 && (
            <ListItem>
              <em>No result</em>
            </ListItem>
          )}
        </ul>
      </StyledDropdown>
      <Upload />
    </StyledSearch>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.files.searchResult
});

export default connect(mapStateToProps)(Dropdown);
