// @flow

import styled from "@emotion/styled";

export const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  > svg {
    right: 0;
  }
`;
