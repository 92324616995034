// @flow

import React from "react";

type Props = {
  color?: string
};

const Down = ({ color }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    fill="none"
    viewBox="0 0 11 6"
  >
    <path
      fill={color || "#888"}
      fillRule="evenodd"
      d="M0 0l5.5 5.843L11 0H0z"
      clipRule="evenodd"
    />
  </svg>
);

export default Down;
