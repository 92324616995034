// @flow

import { connect } from "react-redux";
import React from "react";

import type { AppState, Usage } from "src/types";

type Props = {
  platformUsage: Usage
};

const PlatformUsageName = ({ platformUsage }: Props) => {
  const lowerBound = platformUsage.range.lowerBound.value;
  const upperBound = platformUsage.range.upperBound.value;
  const lowerBoundInclusive = platformUsage.range.lowerBound.inclusive;
  const upperBoundInclusive = platformUsage.range.upperBound.inclusive;

  if (!lowerBound) {
    return (
      <>
        {lowerBoundInclusive ? ">= " : "> "}
        {upperBound > 30
          ? `${upperBound / 30} months`
          : `${upperBound} days`}{" "}
      </>
    );
  }

  if (!upperBound) {
    return (
      <>
        {upperBoundInclusive ? "<= " : "< "}
        {lowerBound > 30
          ? `${lowerBound / 30} months`
          : `${lowerBound} days`}{" "}
      </>
    );
  }

  return (
    <>
      {lowerBoundInclusive ? ">= " : "> "}
      {lowerBound > 30 ? `${lowerBound / 30} months` : `${lowerBound} days`}
      {upperBoundInclusive ? " <= " : " < "}
      {upperBound > 30
        ? `${upperBound / 30} months`
        : `${upperBound} days`}{" "}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  platformUsage: app.orgSettings.platformUsage[props.id]
});

export default connect(mapStateToProps)(PlatformUsageName);
