// @flow

import React, { useCallback, useEffect, useRef } from "react";

import { Dropdown as StyledDropdown } from "../styles";
import { ListItemMedium } from "src/styles/box";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPosition from "src/hooks/useAutoPosition";

type Props = {
  id: number,
  outerRef: any,
  handleClose: Function,
  handleNew: Function,
  handleExisting: Function
};

const Dropdown2 = ({
  outerRef,
  handleNew,
  handleClose,
  handleExisting
}: Props) => {
  const dropdownRef = useRef(null);
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "3em",
    initialBottom: "auto",
    topOffset: "3em",
    bottomOffset: "3em"
  });

  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  const handleDropdown = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      handleNew();
    },
    [handleNew]
  );

  const handleSelectExisting = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      handleExisting();
    },
    [handleExisting]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown top={top} bottom={bottom} ref={dropdownRef}>
        <ul>
          <ListItemMedium onClick={handleSelectExisting}>
            Select from existing tiles
          </ListItemMedium>
          <ListItemMedium onClick={handleDropdown}>
            Create new tile
          </ListItemMedium>
        </ul>
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

export default Dropdown2;
