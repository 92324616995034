// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Button, MenuGroup } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { getSelectedForms } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";
import Form from "./Form";

type Props = {
  checklistFormId: number,
  mappedFieldIndex: number,
  clickHandler: Function,
  formLabel: string
};

const SelectForm = ({
  checklistFormId,
  mappedFieldIndex,
  clickHandler,
  formLabel
}: Props) => {
  const selectedFroms = useSelector(({ app }) =>
    getSelectedForms(app, `${checklistFormId}`)
  );

  const {
    value: isChecklistFormFieldOpen,
    toggleBoolean: toggleChecklistFormField
  } = useBoolean();

  return (
    <MenuGroup
      onClick={toggleChecklistFormField}
      as={Button}
      sx={{ mx: "2rem" }}
      rightIcon={
        isChecklistFormFieldOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
      }
      title={formLabel}
    >
      {selectedFroms &&
        isChecklistFormFieldOpen &&
        selectedFroms.map(form => {
          return (
            <Form
              key={form}
              formId={form}
              mappedFieldIndex={mappedFieldIndex}
              clickHandler={clickHandler}
              checklistFormId={checklistFormId}
            />
          );
        })}
    </MenuGroup>
  );
};

export default SelectForm;
