// @flow

import React from "react";
import type { UID } from "src/types";

import { Checkbox } from "@chakra-ui/react";

type Props = {
  value: UID[],
  onChange: Function
};

export default function ArchiveState({ value, onChange }: Props) {
  return (
    <Checkbox
      size="sm"
      isChecked={value}
      onChange={e => onChange(e.target.checked)}
    >
      Auto archive
    </Checkbox>
  );
}
