// @flow

import React from "react";
import * as R from "ramda";

import { Loader as StyledLoader, LoadingCell } from "./styles";
import PulseLoader from "src/components/PulseLoader";

type Props = {
  rowSize?: number,
  loading: boolean,
  columnSize: string,
  numberOfColumns: number
};

const Loader = ({
  loading,
  rowSize = 8,
  columnSize,
  numberOfColumns
}: Props) => {
  if (loading) {
    return (
      <StyledLoader columnSize={columnSize}>
        {R.times(R.identity, rowSize || 8).map(row => (
          <div key={row}>
            {R.times(R.identity, numberOfColumns).map(id => (
              <LoadingCell key={id} index={id}>
                <PulseLoader w="92%" h="22px" />
              </LoadingCell>
            ))}
          </div>
        ))}
      </StyledLoader>
    );
  }

  return <></>;
};

export default Loader;
