// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Text,
  Flex,
  Image,
  CloseButton
} from "@chakra-ui/react";
import {
  closeButton,
  modalHeader,
  modalContent
} from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/File/styles";
import {
  getCurrentRoomId,
  getTemplateId,
  getChatroomTitle,
  getSequenceNo,
  getWorkflowName,
  isCurrentVersion
} from "src/reducers";
import { sanitizeTitle } from "src/utils";

type Props = {
  onCreate: Function,
  onClose: Function,
  isOpen: boolean,
  selected: Object,
  isLoading: boolean
};

const DocumentCreationPopup = ({
  onCreate,
  onClose,
  isOpen,
  selected,
  isLoading
}: Props) => {
  const { icon, id } = selected;
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const title = useSelector(({ app }) => getChatroomTitle(app, roomId));
  const templateId = useSelector(({ app }) => getTemplateId(app, roomId));
  const workflowName = useSelector(({ app }) =>
    getWorkflowName(app, templateId)
  );
  const seqNo = useSelector(({ app }) => getSequenceNo(app, roomId));
  const currentVersion = useSelector(({ app }) =>
    isCurrentVersion(app, roomId)
  );
  const [name, setName] = useState(title);

  useEffect(() => {
    const sanitizedTitle = sanitizeTitle(
      `${workflowName} #${seqNo}${currentVersion ? "C" : ""}: ${title}`
    ).replace(/\//g, "-");
    setName(sanitizedTitle);
  }, [title]);

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent sx={modalContent}>
        <ModalHeader sx={modalHeader}>
          <Flex alignItems="center" gap="2">
            <Image boxSize="24px" src={icon} alt="microsoft365" />
            <Text> Create new {id} document</Text>
          </Flex>
          <CloseButton onClick={onClose} sx={closeButton} />
        </ModalHeader>
        <ModalBody>
          <Text mb="8px">Enter Title</Text>
          <Input
            autoFocus
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Enter document name"
          />
          <Text mt="12px">
            Note: Your document will be shared with all participants of this
            conversation
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            loadingText="Creating document"
            isDisabled={!name}
            colorScheme="blue"
            onClick={() => {
              onCreate(name);
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentCreationPopup;
