// @flow

import * as R from "ramda";
import { toast } from "react-toastify";
import {
  call,
  all,
  put,
  takeEvery,
  select,
  take,
  takeLatest,
  cancelled
} from "redux-saga/effects";

import getAppState, {
  getLastOrg,
  getUser,
  getFieldSizeMap,
  getCurrentUserId
} from "src/selectors";
import * as reports from "src/api/reports";
import * as workflow from "src/api/workflow";
import * as atypes from "src/constants/actionTypes";
import { rsf } from "src/db";
import {
  getReport,
  getAllReports,
  getInstanceReportId,
  getReportType,
  getWorkflowInstances,
  getExpansionState,
  getFormFieldsVisibility,
  getAllRecords
} from "src/reducers";
import { getWorkflowsWithFormattedValues } from "src/utils";
import { decodeColumnId, encodeColumnId } from "src/utils/reports";
import { findMeIndex } from "src/utils/reports";

import type { Action, Report } from "src/types";

/**
 * Converts data types of report filters from string to respective types and returns the filter
 * @param {Object} filter object
 * @param {Array<Objects>} Array of checklist fields
 * @returns {Object} filter with each filter converted to respective types
 */
const convertReportFilters = (
  filters: Object,
  checklistFields: Array<Object>
) => {
  // Selecting number columns
  let changedTypes = R.pick(["status", "parent"], filters);

  // Selecting number checklist fields
  for (const field of checklistFields) {
    const checklistFilter = filters[`${field.id}`];
    if (
      R.includes(field.type, [
        "conversation",
        "chatPickList",
        "workflow",
        "task",
        "group",
        "childConversation"
      ]) &&
      checklistFilter &&
      R.type(checklistFilter) === "Array"
    ) {
      changedTypes[field.id] = checklistFilter;
    }
  }

  // Converting filters from string to numbers
  const numericFilters = R.keys(changedTypes).map(column => {
    const filter = changedTypes[column];
    if (filter && R.type(filter) === "Array") {
      return {
        [column]: filter.map(id => {
          if (id) {
            return parseInt(id, 10) || id;
          }
          return id;
        })
      };
    }
    return { [column]: filter };
  });

  return {
    ...filters,
    id: parseInt(filters.id, 10),
    ...R.mergeAll(numericFilters || {})
  };
};

/**
 * Changes the "-" in keys in an object to ">"
 * @param {Object} data - value that will be sent to the server
 * @returns {Object} - value with formatted keys
 */
const encodeKeys = (data: Object) => {
  const updatedData = {};
  for (const [key, value] of Object.entries(data || {})) {
    if (key.includes("-")) {
      // Replacing because embedded field filters property names are stored
      // with arrow in firestore and the table implementation in manage view
      // uses dashes
      const replaceWithArrow = key.split("-").join(">");
      updatedData[replaceWithArrow] = value;
    } else {
      updatedData[key] = value;
    }
  }

  return updatedData;
};

/**
 * Changes the ">" in keys in an object to "-"
 * @param {Object} data - value that is received from the server
 * @returns {Object} - value with decoded keys
 */
const decodeKeys = (data: Object) => {
  const updatedData = {};
  for (const [key, value] of Object.entries(data || {})) {
    if (key.includes(">")) {
      // Replacing because embedded field filters property names are stored
      // with arrow in firestore and the table implementation in manage view
      // uses dashes
      const replaceWithArrow = key.split(">").join("-");
      updatedData[replaceWithArrow] = value;
    } else {
      updatedData[key] = value;
    }
  }

  return updatedData;
};

function* createReport({ payload }: any) {
  try {
    const { report, expansionState } = payload;
    const currentUserUid = yield select(getCurrentUserId);
    const checklistFields = (yield select(getAppState)).workflow
      .principalChecklist.fields;
    const fieldSizeMap = (yield select(getAppState)).fieldSizeMap;

    // Converting "-" in keys to ">"
    const encodedExpandedFields = encodeKeys(
      expansionState.expandedEmbeddedFields
    );
    const encodedExpandedRows = expansionState.expandedEmbeddedRows;
    const encodedFormVisibility = expansionState.formFieldsVisibility;

    for (const [key, value] of Object.entries(
      expansionState.expandedEmbeddedRows
    )) {
      encodedExpandedRows[key] = encodeKeys(value);
    }

    for (const [key, value] of Object.entries(
      expansionState.formFieldsVisibility
    )) {
      encodedFormVisibility[key] = encodeKeys(value);
    }

    const updatedReport = {
      ...report,
      // Converting data types of report filters from string to respective types
      filters: {
        ...convertReportFilters(
          R.omit(["deepFilter"], report.filters),
          checklistFields
        ),
        deepFilter: report.filters?.deepFilter || []
      },
      settings: {
        ...report.settings,
        fieldSizeMap,
        expansionState: {
          expandedEmbeddedFields: encodedExpandedFields,
          expandedEmbeddedRows: encodedExpandedRows,
          formFieldsVisibility: encodedFormVisibility
        }
      }
    };

    const result = yield call(reports.createReport, updatedReport);

    const updatedFilters = decodeKeys(result.filters);
    const decodedExpandedRows = {};
    const decodedFormVisibility = {};
    const decodedFieldSizeMap = decodeKeys(
      result?.settings?.fieldSizeMap || {}
    );

    // Convert ">" in keys to "-"
    const decodedExpandedFields = decodeKeys(
      result.settings.expansionState.expandedEmbeddedFields
    );

    for (const [key, value] of Object.entries(
      result.settings.expansionState.expandedEmbeddedRows
    )) {
      decodedExpandedRows[key] = decodeKeys(value);
    }

    for (const [key, value] of Object.entries(result.filters)) {
      let targetIndex = -1;

      if (Array.isArray(value)) {
        targetIndex = findMeIndex(report.filters[key]);
      }

      if (key.includes(">")) {
        // Replacing because embedded field filters property names are stored
        // with arrow in firestore and the table implementation in manage view
        // uses dashes
        const replaceWithDash = key.split(">").join("-");
        updatedFilters[replaceWithDash] = value;
        // Convert the value of the "Me" filter
        if (targetIndex !== -1) {
          updatedFilters[replaceWithDash][targetIndex] = `me-${currentUserUid}`;
        }
      } else {
        updatedFilters[key] = value;
        // Convert the value of the "Me" filter
        if (targetIndex !== -1) {
          updatedFilters[key][targetIndex] = `me-${currentUserUid}`;
        }
      }
    }

    for (const [key, value] of Object.entries(
      result.settings.expansionState.formFieldsVisibility
    )) {
      decodedFormVisibility[key] = decodeKeys(value);
    }

    result["filters"] = updatedFilters;
    result["settings"]["expansionState"] = {
      expandedEmbeddedFields: decodedExpandedFields,
      expandedEmbeddedRows: decodedExpandedRows,
      formFieldsVisibility: decodedFormVisibility
    };
    result["settings"]["fieldSizeMap"] = decodedFieldSizeMap;

    yield put({
      type: atypes.HIDE_REPORTS_SAVE_MODAL,
      payload: {}
    });

    yield put({
      type: atypes.CREATE_REPORT_SUCCESS,
      payload: {
        [result.id]: result
      }
    });

    if (result) {
      yield put({
        type: atypes.SET_REPORTS_REQUEST,
        payload: {
          id: result.id
        }
      });
      toast.success("Report saved successfully.");
    }
  } catch (error) {
    console.error("Reports failure", error);
    console.error("Reports failure", error);
    yield put({
      type: atypes.CREATE_REPORT_FAILURE,
      payload: { error }
    });

    if (error.status !== 404) {
      // Displaying error message sent by the server
      error.json().then(message => {
        toast.error(message.error);
      });
    } else {
      toast.error("Error creating report. Please try again.");
    }
  }
}

function* watchCreateReport(): any {
  yield takeEvery(atypes.CREATE_REPORT_REQUEST, createReport);
}

function* fetchAllReports(): any {
  try {
    const orgId = yield select(getLastOrg);
    const currentUserUid = yield select(getCurrentUserId);
    const reportsChannel = rsf.firestore.channel(`orgs/${orgId}/reports`);

    while (true) {
      const snapshot = yield take(reportsChannel);
      const reports = [];

      for (const { doc, type } of snapshot.docChanges()) {
        if (type === "removed") {
          yield put({
            type: atypes.SYNC_DELETED_REPORTS,
            payload: {
              reportId: doc.id
            }
          });
        } else {
          const data = doc.data();
          let updatedData = {};

          updatedData = { ...data };
          updatedData["filters"] = decodeColumnId(
            data.filters || {},
            currentUserUid
          );
          reports.push(updatedData);
        }
      }

      yield put({
        type: atypes.GET_REPORTS_SUCCESS,
        payload: reports
      });
    }
  } catch (e) {
    toast.error("Error retrieving all reports. Please try again.");
  }
}

function* watchFetchAllReports(): any {
  yield takeEvery(atypes.LOAD_CHATROOMS_SUCCESS, fetchAllReports);
}

function* updateReport({ payload }): any {
  try {
    const checklistFields = (yield select(getAppState)).workflow
      .principalChecklist.fields;
    const currentUserUid = yield select(getCurrentUserId);

    const updatedFilters = payload.filters;
    const result = yield call(reports.updateReport, {
      ...payload,
      // Converting data types of report filters from string to respective types
      filters: convertReportFilters(payload.filters, checklistFields)
    });

    const decodedExpandedFields = decodeKeys(
      payload.settings?.expansionState?.expandedEmbeddedFields || {}
    );
    const decodedExpandedRows = {};
    const decodedFormVisibility = {};

    // Convert ">" in keys to "-"
    for (const [key, value] of Object.entries(
      payload.settings?.expansionState?.expandedEmbeddedRows || {}
    )) {
      decodedExpandedRows[key] = decodeKeys(value);
    }

    for (const [key, value] of Object.entries(
      payload.settings?.expansionState?.formFieldsVisibility || {}
    )) {
      decodedFormVisibility[key] = decodeKeys(value);
    }

    payload["settings"]["expansionState"] = {
      expandedEmbeddedFields: decodedExpandedFields,
      expandedEmbeddedRows: decodedExpandedRows,
      formFieldsVisibility: decodedFormVisibility
    };

    for (const [key, value] of Object.entries(payload.filters)) {
      let targetIndex = -1;

      if (Array.isArray(value)) {
        targetIndex = findMeIndex(payload.filters[key]);
      }

      if (targetIndex !== -1) {
        updatedFilters[key][targetIndex] = `me-${currentUserUid}`;
      }
    }

    if (result.status >= 200 && result.status <= 204) {
      yield put({
        type: atypes.EDIT_REPORT_SUCCESS,
        payload: {
          ...payload,
          filters: updatedFilters
        }
      });
      yield put({
        type: atypes.HIDE_REPORTS_SAVE_MODAL,
        payload: {}
      });
      if (payload.showToast !== false) {
        toast.success("Report updated successfully.");
      }
    }
  } catch (e) {
    toast.error("Error updating the report. Please try again.");
  }
}

function* watchUpdateReport(): any {
  yield takeEvery(atypes.EDIT_REPORT_REQUEST, updateReport);
}

function* updateReportTitle(): any {
  try {
    const reportModal = (yield select(getAppState)).reports.modal;
    const report = getReport(yield select(getAppState), reportModal.id);

    yield put({
      type: atypes.EDIT_REPORT_REQUEST,
      payload: {
        ...report,
        title: reportModal.title
      }
    });
  } catch (error) {
    yield put({
      type: atypes.UPDATE_REPORT_TITLE_FAILURE,
      payload: { error }
    });
  }
}

function* watchUpdateReportTitle(): any {
  yield takeEvery(atypes.UPDATE_REPORT_TITLE_REQUEST, updateReportTitle);
}

function* deleteReport({ payload }): any {
  try {
    yield call(reports.deleteReport, {
      reportId: payload.reportId
    });
    yield put({
      type: atypes.DELETE_REPORT_SUCCESS,
      payload: { reportId: payload.reportId }
    });
    yield put({
      type: atypes.HIDE_REPORTS_SAVE_MODAL,
      payload: {}
    });
    toast.success("Report deleted successfully.");
  } catch (e) {
    const errorResponse = yield e.json();
    toast.error(`Error deleting report: ${errorResponse?.error ?? ""}`);
  }
}

function* watchDeleteReport(): any {
  yield takeEvery(atypes.DELETE_REPORT_REQUEST, deleteReport);
}

function* showReports({ payload, meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    let additionalQuery = (meta || {}).query || {};
    const additionalSort = additionalQuery.sort || [];

    // Check if the sort param is present in query param
    // If it is present convert it to array and use it
    if (additionalSort && R.type(additionalSort) === "String") {
      additionalQuery.sort = [additionalSort];
    } else if (additionalSort && additionalSort.length !== 0) {
      additionalQuery.sort = additionalSort;
    }

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "report",
          query: {
            id: payload.id,
            filter: additionalQuery
          }
        }
      });
    } else if (payload.id) {
      // URL has reportId
      const { loaded } = (yield select(getAppState)).reports;
      if (!loaded) {
        yield take(atypes.GET_REPORTS_SUCCESS);
      }
      const allReports = getAllReports(yield select(getAppState), "");
      const report = getReport(yield select(getAppState), payload.id);

      const expandedEmbeddedFields = decodeKeys(
        report.settings?.expansionState?.expandedEmbeddedFields || {}
      );

      const expandedEmbeddedRows = {};
      for (const [key, value] of Object.entries(
        report.settings?.expansionState?.expandedEmbeddedRows || {}
      )) {
        expandedEmbeddedRows[key] = decodeKeys(value);
      }

      const formFieldsVisibility = {};
      for (const [key, value] of Object.entries(
        report.settings?.expansionState?.formFieldsVisibility || {}
      )) {
        formFieldsVisibility[key] = decodeKeys(value);
      }

      yield put({
        type: atypes.SET_REPORT_EXPANDED_FIELDS,
        payload: expandedEmbeddedFields ?? {}
      });

      yield put({
        type: atypes.SET_REPORT_EXPANDED_ROWS,
        payload: expandedEmbeddedRows ?? {}
      });

      yield put({
        type: atypes.SET_REPORT_FORM_VISIBILITY,
        payload: formFieldsVisibility ?? {}
      });

      if (allReports.length !== 0 && !R.isEmpty(report)) {
        const updatedReportFilters = report.filters;

        Object.keys(report.filters).map(columnId => {
          const filterValues = report.filters[columnId];
          let targetIndex = -1;
          if (Array.isArray(filterValues)) {
            targetIndex = findMeIndex(filterValues);
          }
          if (targetIndex !== -1) {
            updatedReportFilters[columnId][targetIndex] = `me-${uid}`;
          }
        });

        // Go to the selected
        yield put({
          type: atypes.SET_REPORTS_SUCCESS,
          payload: {
            ...updatedReportFilters,
            reportId: payload.id,
            page: 1
          },
          meta: {
            query: additionalQuery
          }
        });
      } else {
        // There are no reports
        yield put({
          type: atypes.SET_REPORTS_SUCCESS,
          payload: {}
        });
      }
    } else {
      // URL does not have url reportId
      yield put({
        type: atypes.SET_REPORTS_SUCCESS,
        payload: {}
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_REPORTS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchShowReports(): any {
  yield takeLatest(atypes.SET_REPORTS_REQUEST, showReports);
}

function* getReportInstances({ payload }: Action): any {
  const abortController = new AbortController();

  try {
    const reportType = getReportType(
      yield select(getAppState),
      payload.reportId
    );

    if (reportType === "form") {
      return;
    }

    const id = payload.id || null;
    const instances = getWorkflowInstances(yield select(getAppState));

    if (id) {
      const { additionalFilters } = (yield select(getAppState)).workflow;

      yield put({
        type: atypes.GET_PRINCIPAL_CHECKLIST_REQUEST,
        payload: {
          workflow: id
        }
      });

      // Wait for principal checklist field data to load
      yield take(atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS);

      const page = parseInt(additionalFilters.page || 1, 10);

      let workflows = yield call(workflow.getProcessInstancesAsync, {
        id,
        signal: abortController.signal
      });

      const { principalChecklist } = (yield select(getAppState)).workflow;

      const workflowsWithFormattedFieldValues = getWorkflowsWithFormattedValues(
        workflows,
        principalChecklist
      );

      if (instances.length > 0 && page > 1) {
        yield put({
          type: atypes.PAGINATE_WORKFLOW_INSTANCES_SUCCESS,
          payload: {
            workflows: workflowsWithFormattedFieldValues
          }
        });
      } else {
        yield put({
          type: atypes.GET_WORKFLOW_INSTANCES_SUCCESS,
          payload: {
            workflows: workflowsWithFormattedFieldValues
          }
        });
      }
      yield put({
        type: atypes.GET_PROCESS_INSTANCE_COUNT_SUCCESS,
        payload: {
          totalCount: 0
        }
      });
    } else {
      yield put({
        type: atypes.CLEAR_PROCESS_ROW_SELECTION,
        payload: {}
      });
    }
  } catch (e) {
    yield put({ type: atypes.GET_WORKFLOW_INSTANCES_FAILURE, payload: e });
    toast.error(`Error fetching process instances displaying old value`);
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* watchGetReportInstances(): any {
  yield takeLatest(atypes.SET_REPORTS_SUCCESS, getReportInstances);
}

function* editReportModal({ payload }): any {
  try {
    const report = getReport(yield select(getAppState), payload.reportId);

    yield put({
      type: atypes.SET_REPORT_MODAL_ATTRIBUTES,
      payload: {
        modalType: "update",
        loading: false,
        title: report.title,
        id: report.id
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchEditReportModal(): any {
  yield takeLatest(atypes.EDIT_REPORT_MODAL, editReportModal);
}

function* saveChangesToReport(): any {
  try {
    const reportId = getInstanceReportId(yield select(getAppState));
    const embeddedExpansionState = getExpansionState(yield select(getAppState));
    const formFieldsVisibility = getFormFieldsVisibility(
      yield select(getAppState)
    );
    const expansionState = {
      ...embeddedExpansionState,
      formFieldsVisibility
    };
    const currentUserUid = yield select(getCurrentUserId);
    const report = getReport(yield select(getAppState), reportId || "");
    const allRecords = getAllRecords(yield select(getAppState));
    const { instanceFilter } = (yield select(getAppState)).workflow;
    const filters = {
      ...instanceFilter,
      page: 1
    };

    const encodedExpandedRows = {};
    const encodedFormVisibility = {};

    // For expandedEmbeddedFields (storing horizontal expansion)
    const encodedExpandedFields = encodeKeys(
      expansionState.expandedEmbeddedFields
    );

    // For expandedEmbeddedRows (storing vertical expansion)
    for (const [key, value] of Object.entries(
      expansionState.expandedEmbeddedRows
    )) {
      encodedExpandedRows[key] = encodeKeys(value);
    }

    // For form expansion
    for (const [key, value] of Object.entries(
      expansionState.formFieldsVisibility
    )) {
      encodedFormVisibility[key] = encodeKeys(value);
    }
    const updatedFilters = encodeColumnId(filters);

    // Save the field widths to Firestore
    const fieldSizeMap = yield select(getFieldSizeMap) || {};
    const updatedFieldSizeMap = encodeKeys(fieldSizeMap);

    const deepFilter = [];
    Object.keys(allRecords).map(filterColumnId => {
      if (allRecords[filterColumnId]) {
        deepFilter.push(filterColumnId);
      }
    });

    const newReportData: Report = {
      ...report,
      settings: {
        ...report.settings,
        fieldSizeMap: updatedFieldSizeMap,
        expansionState: {
          expandedEmbeddedFields: encodedExpandedFields,
          expandedEmbeddedRows: encodedExpandedRows,
          formFieldsVisibility: encodedFormVisibility
        }
      },
      filters: {
        ...updatedFilters,
        deepFilter,
        page: 1
      }
    };

    yield call(reports.updateReport, newReportData);

    const successData = { ...newReportData };

    const decodedExpandedRows = {};
    const decodedFormVisibility = {};

    // Converting ">" in keys back to "-"
    for (const [key, value] of Object.entries(
      successData?.settings?.expansionState?.expandedEmbeddedRows || {}
    )) {
      decodedExpandedRows[key] = decodeKeys(value);
    }

    const updatedSuccessDataFilters = {};
    for (const [key, value] of Object.entries(successData.filters)) {
      let targetIndex = -1;

      if (Array.isArray(value)) {
        targetIndex = findMeIndex(successData.filters[key]);
      }

      if (key.includes(">")) {
        // Replacing because embedded field filters property names are stored
        // with arrow in firestore and the table implementation in manage view
        // uses dashes
        const replaceWithDash = key.split(">").join("-");
        updatedSuccessDataFilters[replaceWithDash] = value;
        if (targetIndex !== -1) {
          updatedSuccessDataFilters[replaceWithDash][targetIndex] =
            `me-${currentUserUid}`;
        }
      } else {
        updatedSuccessDataFilters[key] = value;
        if (targetIndex !== -1) {
          updatedSuccessDataFilters[key][targetIndex] = `me-${currentUserUid}`;
        }
      }
    }

    for (const [key, value] of Object.entries(
      successData?.settings?.expansionState?.formFieldsVisibility || {}
    )) {
      decodedFormVisibility[key] = decodeKeys(value);
    }

    successData["filters"] = decodeColumnId(successData.filters || {});

    successData["settings"]["expansionState"] = {
      expandedEmbeddedFields: decodeKeys(
        successData.settings?.expansionState?.expandedEmbeddedFields || {}
      ),
      expandedEmbeddedRows: decodedExpandedRows,
      formFieldsVisibility: decodedFormVisibility
    };

    yield put({
      type: atypes.EDIT_REPORT_SUCCESS,
      payload: successData
    });

    toast.success("Report has been saved");
  } catch (error) {
    console.error(error);
    toast.error("Unable to save changes to report");
  }
}

function* watchSaveChangesToReport(): any {
  yield takeLatest(atypes.SAVE_CHANGES_TO_REPORT, saveChangesToReport);
}

function* updateEmbeddedFieldDetails({ payload }): any {
  try {
    const templateId = parseInt(payload.templateId, 10) || -1;
    const fields = yield call(workflow.getEmbeddedFieldDetails, templateId);

    const allEmbeddedFields = [];
    const fieldsByForm = [];
    // update the store with embedded fields
    fields.map(field => {
      if (field.type === "link") {
        const formattedFieldDetails = (field.embeddedFields || []).map(
          fieldDetails => ({
            ...{
              ...fieldDetails.config,
              settings: JSON.stringify(fieldDetails.config.settings)
            },
            id: fieldDetails.id,
            type:
              fieldDetails.type === "picklist" ? "select" : fieldDetails.type
          })
        );
        allEmbeddedFields.push(...(formattedFieldDetails || []));
      } else if (field.type === "form") {
        (field.forms || []).forEach(form => {
          const formattedFieldDetails = (form.fields || []).map(formField => ({
            ...{
              ...formField.config,
              settings: JSON.stringify(formField.config.settings)
            },
            id: formField.id,
            type: formField.type === "picklist" ? "select" : formField.type,
            loading: false,
            error: null,
            promptRules: {
              roles: [],
              users: []
            }
          }));

          fieldsByForm.push(
            put({
              type: atypes.FETCH_FORM_SUCCESS,
              payload: form
            })
          );

          allEmbeddedFields.push(...(formattedFieldDetails || []));
        });
      }
    });

    yield all(fieldsByForm);

    yield put({
      type: atypes.GET_EMBEDDED_FIELDS_SUCCESS,
      payload: {
        fields: allEmbeddedFields
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchEmbeddedFields(): any {
  yield takeLatest(
    atypes.GET_EMBEDDED_FIELD_DETAILS,
    updateEmbeddedFieldDetails
  );
}

export default [
  watchSaveChangesToReport(),
  watchEditReportModal(),
  watchUpdateReportTitle(),
  watchGetReportInstances(),
  watchFetchAllReports(),
  watchCreateReport(),
  watchUpdateReport(),
  watchDeleteReport(),
  watchShowReports(),
  watchEmbeddedFields(),
  watchShowReports(),
  watchEmbeddedFields()
];
