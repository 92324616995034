// @flow

import React from "react";
import { connect } from "react-redux";

import ConversationSelector from "./ConversationSelector";
import type { AppState } from "src/types";
import New from "src/containers/chatroom-nav/filter/New";
import SortModal from "src/containers/filter/SortModal";
import StatusModal from "src/containers/filter/StatusModal";
import { getSortByModal, getStatusModal } from "src/reducers";

type Props = {
  isStatusModalOpen: boolean,
  isSortByModalOpen: boolean,
  isConversationModalOpen: boolean
};

const Status = ({
  isStatusModalOpen,
  isSortByModalOpen,
  isConversationModalOpen
}: Props) => (
  <>
    <New />
    {isSortByModalOpen ? <SortModal /> : null}
    {isStatusModalOpen ? <StatusModal /> : null}
    {isConversationModalOpen ? <ConversationSelector /> : null}
  </>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isStatusModalOpen: getStatusModal(app),
  isSortByModalOpen: getSortByModal(app),
  isConversationModalOpen: app.header.conversationModal
});

export default connect(mapStateToProps, {})(Status);
