// @flow

import React, { useState, useCallback, Fragment } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { UserRemove } from "./styles";
import { getGroup } from "src/reducers";
import Icon from "src/icons";
import type { RoomId } from "src/types";
import { removeRoomGroup } from "src/actions/chatroom";
import GroupUsers from "./GroupUsers";
import * as styles from "./styles";

type Props = {
  id: number,
  room: RoomId
};

const Group = ({ id, room }: Props) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const [showMembers, setShowMembers] = useState(false);

  const dispatch = useDispatch();

  const { title, members, photoUrl } = group;
  const removeUser = useCallback(() => {
    dispatch(removeRoomGroup(id, room));
  }, [id, room]);

  return (
    <Fragment>
      <Flex sx={styles.RoomGroupWrapper}>
        <Icon
          type="groupAvatar"
          text={title}
          size="sm"
          photoUrl={photoUrl}
          id={id}
        />
        <Flex sx={styles.RoomGroupTextWrapper}>
          <Text sx={styles.RoomGroupTitle}>{title}</Text>
          <Text sx={styles.LightGreyText}>
            {members.length} {members.length > 1 ? "members" : "member"}
          </Text>
        </Flex>

        <Flex
          onClick={() => setShowMembers(prev => !prev)}
          sx={
            showMembers
              ? styles.ActiveGroupMemberArrowIcon
              : styles.GroupMemberArrowIcon
          }
        >
          {showMembers ? (
            <ChevronUpIcon boxSize={5} />
          ) : (
            <ChevronDownIcon boxSize={5} />
          )}
        </Flex>

        <UserRemove>
          <Icon type="decrement" handleClick={removeUser} />
        </UserRemove>
      </Flex>
      {showMembers && members.map(user => <GroupUsers key={user} id={user} />)}
    </Fragment>
  );
};

export default Group;
