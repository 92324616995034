// @flow

import type { UID, CsvUploadedChecklistField } from "src/types";
import * as atypes from "src/constants/actionTypes";

export const clearCurrentChecklist = () => ({
  type: atypes.CLEAR_CURRENT_CHECKLIST,
  payload: {}
});

export const setChecklistBuilderAttribute = ({
  id,
  value
}: {
  id: string,
  value: any
}) => ({
  type: atypes.SET_CHECKLIST_BUILDER_ATTRIBUTE,
  payload: { id, value }
});

export const setChecklistBuilderAttributes = ({ value }: { value: any }) => ({
  type: atypes.SET_CHECKLIST_BUILDER_ATTRIBUTES,
  payload: { value }
});

export const addFields = (payload: {
  index?: number,
  fields: CsvUploadedChecklistField[]
}) => ({
  type: atypes.ADD_FIELDS_IN_CHECKLIST_BUILDER_REQUEST,
  payload
});

export const updateField = (payload: {
  position: number,
  data:
    | {|
        settings: Object
      |}
    | {|
        label: string
      |}
    | {|
        hidden: boolean
      |}
}) => ({
  type: atypes.UPDATE_FIELD_IN_CHECKLIST_BUILDER,
  payload
});

export const deleteField = (payload: { position: number, uid: UID }) => ({
  type: atypes.DELETE_FIELD_IN_CHECKLIST_BUILDER,
  payload
});

type MoveFieldActionPayload = { oldIndex: number, newIndex: number };

export type MoveFieldActionCreator = (
  payload: MoveFieldActionPayload
) => {
  type: typeof atypes.MOVE_FIELD_IN_CHECKLIST_BUILDER,
  payload: MoveFieldActionPayload
};

export const moveField: MoveFieldActionCreator = payload => ({
  type: atypes.MOVE_FIELD_IN_CHECKLIST_BUILDER,
  payload
});
