// @flow

import styled from "@emotion/styled";
import { ifProp, switchProp, prop } from "styled-tools";
import { rgba } from "polished";

import * as radius from "./constants/radius";
import * as colors from "./constants/colors";
import * as spacing from "./constants/spacing";
import { statusBorderColor } from "src/constants/statusColor";

const Box = styled.div`
  position: relative;
  padding: ${spacing.space_inset_default};
  border-top: 1px solid ${colors.grey4};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${colors.grey4};
  background: ${colors.brightLight};
  border-radius: ${radius.small};
  min-width: 200px;
`;

const ScrollableBox = styled.div`
  /* This padding was added for scrollbar positioning */
  position: relative;
  z-index: 3;
  padding: ${spacing.space_inset_default};
  border-top: 1px solid ${colors.grey4};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${colors.grey4};
  background: ${colors.brightLight};
  border-radius: 2px;
  min-width: ${props => (props.fullWidth ? "395px" : "200px")};

  > ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    overflow: auto;
  }
`;

const ListItemMedium = styled.li`
  line-height: 2.125rem;
  cursor: pointer;
  padding: ${spacing.space_inset_squish_s};
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: ${props =>
    props.focus ? colors.grey2 : colors.brightLight};

  :hover,
  :focus {
    background-color: ${colors.grey2};
    outline: none;
  }
`;

const ListItemWithMenu = styled(ListItemMedium)`
  justify-content: space-between;
  > svg {
    visibility: ${props => (props.active ? "visible" : "hidden")};
  }
  :hover {
    > svg {
      visibility: visible;
    }
  }
`;

const IconLabel = styled.span`
  display: flex;
  align-items: center;
  outline: none;
  white-space: nowrap;

  > svg {
    > g {
      stroke: ${props => (props.color ? props.color : "auto")} !important;
    }
  }

  > span {
    text-transform: capitalize;
    padding-left: ${spacing.space_s};
    color: ${props => (props.color ? props.color : "")};
    cursor: ${props => props.cursor};
  }
`;

const SmallBox = styled.div`
  border: 1px solid ${colors.grey4};
  display: inline-block;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
`;

const SmallBoxWithoutBorders = styled(SmallBox)`
  border: none;
`;

const NoOutline = styled.div`
  outline: none;
`;

const SquareBoxMedium = styled.div`
  border: 1px solid ${colors.grey4};
  background: ${colors.brightLight};
  padding: ${spacing.space_inset_l};
  position: relative;
  z-index: 1;
  border-top: 1px solid ${colors.grey4};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${colors.grey4};
`;

const PictureBox = styled(NoOutline)`
  display: inline-flex;
  align-items: center;
  height: 16px;
  margin: ${spacing.space_list_s};
  border-radius: ${radius.small};
  background: ${colors.grey1};
  padding: 0px;
  > img {
    border-radius: ${radius.medium};
  }
  > span:last-child {
    padding-left: ${spacing.space_s};
  }
`;

const LeftAlignedListItem = styled(ListItemMedium)`
  padding-left: 0px;
`;

const Icon = styled.svg`
  vertical-align: middle;
`;

const Capsule = styled.div`
  display: inline-grid;
  border-radius: ${radius.small};
  grid-template-columns: 1fr auto;
  font-size: 0.75rem;
  text-align: center;
  align-items: center;
  height: 22px;
  cursor: pointer;
  line-height: 12px;
  background: ${colors.brightLight};
  padding: ${spacing.space_xs};

  > div,
  > span {
    display: inline-block;
    height: 18px;
    display: flex;
    align-items: center;
    border-radius: ${radius.small};
    :first-of-type {
      color: ${colors.secondary};
      background: ${colors.brightLight};
      padding: ${spacing.space_list_s};
    }
    :last-child {
      display: block;
      height: 90%;
      margin: 0px;
    }
  }
`;

const GreyBox = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: ${radius.small};
  background: ${colors.grey1};
  height: 18px;
  line-height: 0.8125rem;
  padding: ${spacing.space_inset_xs};
  padding-right: ${spacing.space_m};
  > span {
    padding: 0px;
  }
`;

const InlineBox = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

const RelativeBox = styled.div`
  position: relative;
`;

export const SpacedFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PositionTop = styled(RelativeBox)`
  z-index: 1000;
`;

const RelativeFlexBox = styled(RelativeBox)`
  display: flex;
`;

const Pending = styled.span`
  color: ${colors.success};
`;

const TextContainer = styled(Box)`
  position: relative;
  padding: ${spacing.space_inset_l};
  border: 0;
  box-shadow: none;
`;

const NoValue = styled.div`
  border-radius: 2px;
  line-height: 1.4em;
  align-items: center;
  font-size: 0.875rem;
  height: 1rem;
  display: flex !important;
  > svg {
    margin-right: ${spacing.space_s};
  }
  > div {
    display: inline-block;
    color: ${colors.active};
  }
`;

const CardSmall = styled.div`
  height: 32px;
  margin: ${spacing.space_stack_xs};
  background-color: ${colors.brightLight};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: 70px 1fr;
  align-items: center;
  justify-content: center;
  padding: ${spacing.space_list_m};
  cursor: pointer;
`;

const RelativeTruncatedBox = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-flow: column;
  height: auto;
  border-radius: ${radius.small};
  padding: ${spacing.space_inset_m};
  box-shadow: 1px 1px 0px 0px ${colors.grey7};
`;

const InteractiveCard = styled(Card)`
  cursor: pointer;

  :hover,
  :focus {
    box-shadow: 0 1px 2px 0 ${rgba(colors.primary, 0.5)};
    outline: none;
  }
`;

const FadeInOverflowBox = styled.span`
  padding-left: ${spacing.space_xl};
  background: linear-gradient(
    to right,
    ${rgba(colors.primary, 0)} 0%,
    ${prop("color", colors.brightLight)} 22%
  );
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

const WrappedList = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: ${spacing.space_default};
    cursor: pointer;
  }

  > div:last-child {
    margin-right: none;
  }
`;

const SimpleBox = styled.div`
  border-radius: ${radius.small};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colors.grey4};
  :focus-within {
    outline: none;
    border: 1px solid ${colors.grey5};
  }
`;

const Conversation = styled.div`
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: ${colors.brightLight};
  cursor: pointer;
  border-left: 4px solid
    ${ifProp(
      { hasUnread: true },
      colors.danger,
      switchProp("status", statusBorderColor)
    )};
`;

const MediumBox = styled.div`
  border-radius: ${radius.medium};
  border: solid 1px ${colors.grey7};
  padding: 1em 1.33em;
`;

export {
  Box,
  ScrollableBox,
  ListItemMedium,
  ListItemWithMenu,
  LeftAlignedListItem,
  IconLabel,
  SmallBox,
  SmallBoxWithoutBorders,
  NoOutline,
  SquareBoxMedium,
  PictureBox,
  Icon,
  Capsule,
  GreyBox,
  InlineBox,
  RelativeBox,
  RelativeFlexBox,
  Pending,
  NoValue,
  CardSmall,
  Card,
  InteractiveCard,
  FadeInOverflowBox,
  WrappedList,
  RelativeTruncatedBox,
  TextContainer,
  SimpleBox,
  InputBox,
  Conversation,
  MediumBox,
  PositionTop
};
