// @flow

import { connect } from "react-redux";

import Discussion from "src/components/chatroom/creator/discussion";
import { getCurrentRoomId, getCurrentUserId } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProp = ({ app }: { app: AppState }) => ({
  title: app.conversationDialog.title,
  members: app.conversationDialog.members,
  currentUser: getCurrentUserId(app),
  parent: app.conversationDialog.parent,
  owner: app.conversationDialog.owner,
  roomId: getCurrentRoomId(app)
});

export default connect(mapStateToProp)(Discussion);
