// @flow

import * as React from "react";
import { useRef, useState, useEffect } from "react";
import {
  chakra,
  useMultiStyleConfig,
  Checkbox,
  HStack,
  Text,
  Box
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import Downshift from "downshift";
import * as customStyles from "./styles";

type Props<T> = {|
  value: T[],
  options: T[],
  inputPlaceholder?: string,
  onQueryChange?: string => void,
  itemHandler: Function,
  itemRenderer?: T => React.Node,
  itemToString?: T => string,
  children: React.Node,
  disabled?: boolean
|};

const MultiSelect2 = <T>({
  value,
  options,
  inputPlaceholder,
  onQueryChange,
  itemRenderer,
  itemToString,
  children,
  itemHandler = () => {},
  disabled
}: Props<T>) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const styles = useMultiStyleConfig("CustomSelect");

  const clearInputValue = () => setInputValue("");

  useEffect(() => {
    onQueryChange && onQueryChange(inputValue);
  }, [inputValue]);

  const handleInputKeyDown = ({ event }) => {
    if (event.key === "Backspace" && !event.target.value) {
      itemHandler("removeLast");
      clearInputValue();
    }
  };

  const handleChange = selectedItem => {
    itemHandler("select", selectedItem);
    clearInputValue();
  };

  const handleRemoveItem = item => {
    itemHandler("remove", item);
    clearInputValue();
  };

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true
        };

      default:
        return changes;
    }
  };

  // Convert children to an array of react children
  const childrenArray = React.Children.toArray(children);

  // Remove children who have the value prop and it doesnt exist in the options prop
  const filteredChildren = childrenArray.filter(child => {
    if (child.props.value && !options.includes(child.props.value)) {
      return false;
    }
    return true;
  });

  return (
    <Downshift
      selectedItem={null}
      onChange={handleChange}
      stateReducer={stateReducer}
      disabled={disabled}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, openMenu }) => (
        <div style={customStyles.container}>
          <chakra.div __css={customStyles.selectedItems} _disabled={disabled}>
            {value.map(item => (
              <HStack
                key={itemToString ? itemToString(item) : String(item)}
                sx={customStyles.selectedItem}
              >
                {itemRenderer ? (
                  itemRenderer(item)
                ) : (
                  <Text textStyle="label">{item}</Text>
                )}

                <CloseIcon
                  onClick={() => handleRemoveItem(item)}
                  sx={customStyles.selectedItemCloseIcon}
                  _disabled={disabled}
                />
              </HStack>
            ))}

            <chakra.input
              {...getInputProps({
                ref: inputRef,
                onKeyDown: event =>
                  handleInputKeyDown({
                    event
                  }),
                placeholder: inputPlaceholder || "Select items"
              })}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
              onClick={openMenu}
              disabled={disabled}
              sx={customStyles.queryField}
            />
          </chakra.div>

          <chakra.div
            zIndex="1"
            width="100%"
            style={{
              visibility: isOpen ? "visible" : "hidden",
              ...customStyles.optionsContainer
            }}
          >
            <chakra.ul
              __css={{
                ...styles.menu,
                ...customStyles.optionsList
              }}
              data-focus-visible-added={isOpen}
              {...getMenuProps()}
            >
              {isOpen &&
                filteredChildren.map(child => {
                  // If the child has a value prop, then render it as an option
                  // Else render it as is
                  if (child.props.value) {
                    const { value: item } = child.props;
                    return (
                      <chakra.li
                        key={item}
                        __css={{
                          ...styles.option,
                          ...customStyles.option
                        }}
                        {...getItemProps({
                          item,
                          index: options.findIndex(option => option === item)
                        })}
                      >
                        <Box>
                          <Box pointerEvents={"none"}>
                            <Checkbox isChecked={value.includes(item)} />
                          </Box>
                        </Box>

                        {child}
                      </chakra.li>
                    );
                  }

                  return child;
                })}
            </chakra.ul>
          </chakra.div>
        </div>
      )}
    </Downshift>
  );
};

export default MultiSelect2;
