// @flow

import React from "react";
import { Checkbox } from "@chakra-ui/react";

import type { DownshiftProps } from "downshift";

import { MenuItemContainer, MenuItemLabel } from "../styles";

type Props = {|
  domain: string,
  onItemClick?: (domain: string) => void,
  highlightedIndex?: number,
  index?: number,
  scrollIntoView?: DownshiftProps.scrollIntoView,
  selectedDomains: Array<string>
|};

const Domain = ({
  domain,
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView,
  selectedDomains
}: Props) => {
  const isHighlighted =
    (highlightedIndex === index && typeof highlightedIndex === "number") ||
    (highlightedIndex === null && index === 0);

  const isSelected = selectedDomains.includes(domain);

  const selectDomain = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onItemClick) {
      onItemClick(domain);
    }
  };

  return (
    <MenuItemContainer
      isHighlighted={isHighlighted}
      ref={highlightedIndex === index ? scrollIntoView : null}
      onClick={selectDomain}
    >
      <Checkbox isChecked={isSelected} size="md" />
      <MenuItemLabel>{domain}</MenuItemLabel>
    </MenuItemContainer>
  );
};

export default Domain;
