// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, UID } from "src/types";

const initialState = [];

const pendingRoomMembers = (
  state: Array<UID> = initialState,
  { type, payload }: Action
): Array<UID> => {
  switch (type) {
    case atypes.ROOM_MEMBER_SYNC_SUCCESS:
      return payload.pendingMembers;
    case atypes.UPDATE_PENDING_MEMBERS_SUCCESS:
      return payload.pendingMembers;
    case atypes.CLEAR_PENDING_MEMBERS:
      return [];
    default:
      return state;
  }
};

export default pendingRoomMembers;
