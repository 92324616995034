// @flow

import React from "react";

type Props = {
  fill?: string
};

const CurrentRevisionV2 = ({ fill, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <g clipPath="url(#clip0_2861_7550)">
      <path
        d="M1 8.00098L8.00016 11.5011L15.0152 8.00098"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11.5L8.00016 15.0001L15.0152 11.5"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.63746L8.00716 8.00016L15.0152 4.63746L8.00716 1L1 4.63746Z"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2861_7550">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CurrentRevisionV2;
