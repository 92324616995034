// @flow

import styled from "@emotion/styled";

import { ImageLabel } from "src/components/user/styles";

import {
  colors as colorsOld,
  fontSize as fontSizeOld,
  spacing as spacingOld
} from "src/styles/constants";
import {
  ScrollableBox,
  SmallBox,
  SmallBoxWithoutBorders,
  ListItemMedium
} from "src/styles/box";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import searchIcon from "src/img/search.svg";

// Old styles

const Dropdown = styled.div`
  position: absolute;
  z-index: 222;
  background-color: ${colorsOld.pureWhite};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 0px 10px;
  border: 1px solid ${colorsOld.clearBlack};
  > button {
    border: none !important;
    color: ${colorsOld.blue} !important;
    background: ${colorsOld.pureWhite};
    padding-bottom: ${spacingOld.spacing0};
    cursor: pointer;
    font-weight: 600;
    strong {
      color: ${colorsOld.mediumBlack};
    }
  }

  > input[type="text"] {
    background: white url(${searchIcon}) 11px no-repeat;
    display: block;
    width: 95%;
    margin: 10px auto 10px auto;
    padding: 8px 39px !important;
    border-radius: 2px;
    background-color: ${colorsOld.aliceBlue};
    border: solid 1px ${colorsOld.grey};

    ::placeholder {
      color: ${colorsOld.lightGrey};
    }
  }

  ul {
    border-radius: 2px;
    background-color: ${colorsOld.pureWhite};
    width: 18em;
    min-width: 219px;
    list-style: none;
    margin: auto;
    margin: 0;
    z-index: 5;

    max-height: 18em;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    li {
      display: flex;
      align-items: center;
      padding: 0.5em;
      border-radius: 2px;
      cursor: pointer;
      position: relative;
      > svg:nth-of-type(2) {
        visibility: hidden;
      }
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      > div {
        width: 86%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-height: ${spacingOld.spacing3};
        display: flex;
        align-items: center;
        > label {
          padding-top: 0px;
          display: flex;
          align-items: center;
          width: 100%;
          > span {
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: ${spacingOld.spacing3};
          }
        }
      }
      :last-child {
        margin-bottom: 10px;
      }

      input[type="checkbox"] + div {
        position: absolute;
        top: ${spacingOld.spacing1};
      }

      :hover {
        background: ${colorsOld.aliceBlue};
        > svg:nth-of-type(2) {
          visibility: visible;
        }
        button {
          background: ${colorsOld.aliceBlue};
        }
      }
    }
  }
`;

const Sorting = styled.ul`
  padding-left: 0px;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  list-style: none;

  min-width: 220px;
  min-height: 100px;
  text-align: left;
  background: #fff;
  top: 33px;
  z-index: 999;

  li:last-child {
    margin-bottom: 8px;
  }

  li {
    padding-left: ${spacingOld.spacing2};
    > ul {
      max-height: 133px;
      overflow: auto;
      list-style: none;
      padding-left: 0px;
      margin-left: -16px;
    }
    > h4 {
      color: ${colorsOld.lightGrey};
      font-size: ${fontSizeOld.t3};
      margin-bottom: 6px;
      > button {
        border: none;
        cursor: pointer;
        color: ${colorsOld.blue};
        font-weight: bold;
        font-size: ${fontSizeOld.t3};
        background: ${colorsOld.pureWhite};
        padding-left: 0px;
        margin-right: ${spacingOld.spacing1};
      }
    }
    > h5 {
      color: ${colorsOld.dullGrey};
      font-size: ${fontSizeOld.t3};
      margin: 0px;
      cursor: pointer;
      margin-left: -11px;
      margin-right: 5px;
      border-radius: 2px;
      padding-left: 10px;

      > div {
        display: block;
        padding: 10px 0px;
        > label {
          margin: -10px 0px;
          padding: 10px 0px;
          position: relative;
          span {
            position: relative;
            top: 3px;
            left: 4px;
            color: ${colorsOld.midGrey};
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          input:checked {
            & + div + span {
              color: ${colorsOld.black};
            }
          }
        }
      }
      :hover {
        background: ${colorsOld.grey};
      }
    }

    > hr {
      border: solid 0.5px ${colorsOld.simpleGrey};
      margin-left: -${spacingOld.spacing2};
    }
  }
`;

const SelectItem = styled.div`
  position: relative;
  padding: 1.2em;
  font-size: ${fontSizeOld.t3};
  border-radius: 2px;
  background-color: ${colors.grey16};
  width: 100%;
  cursor: pointer;
  color: ${colors.secondary};
  text-align: left;
  display: block;
  min-height: 2.8em;
  > div {
    padding: 10px;
  }

  > span {
    color: ${colors.primary};
    background-color: ${colorsOld.isabelline};
    padding: ${spacingOld.spacing0};
    padding-right: ${spacingOld.spacing3};

    > svg {
      position: absolute;
      margin-left: 4px;
      background-color: ${colorsOld.isabelline};
    }
  }

  > svg {
    position: absolute;
    right: ${spacingOld.spacing1};
  }
`;

const Small = styled(Dropdown)`
  min-width: 100px;
  position: absolute;
  top: 16px;
  left: 37%;
  width: 20px !important;
  min-width: 12em !important;
  border-radius: 2px;
  ul {
    width: 100%;
    min-width: 100px;
    margin-top: 8px;
  }
`;

// Old styles end

const SearchResult = styled(ScrollableBox)`
  :focus {
    outline: none;
  }
  > ul {
    /** 3.4rem * 5  line-height into number of items to display **/
    max-height: 10.625rem;
  }
`;

const SelectionMultiple = styled.span`
  display: inline-block;
  max-height: 5.5625rem;
  overflow: auto;
  padding: ${spacing.space_inset_squish_xs};
  > div {
    margin: ${spacing.space_inset_squish_xs};
  }
  ${ImageLabel} {
    > span:last-child {
      max-width: 12em;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  input {
    :hover,
    :focus {
      border: none !important;
    }
    height: 2em;
    margin: ${spacing.space_inset_squish_xs};
    padding-left: 0px;
  }
`;

export const SelectMultiple = styled(SmallBox)`
  border-radius: ${radius.small};
  input[type="text"] {
    outline: none !important;
  }
  > div {
    /* This padding was added for scrollbar positioning */
    cursor: text;
    padding-right: ${spacing.space_xs};
  }

  > div:first-of-type {
    > span {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  > div:last-child {
    position: relative;
  }
  :focus-within {
    border: 1px solid ${colors.grey5};
  }

    li > span > span {
      max-width: 182px;
    }
  }
`;

const Select = styled(SmallBox)`
  padding: 1px;
  > div:first-of-type {
    display: inline-flex;
    > div {
      flex-grow: 1;
    }
    > svg {
      position: relative;
      top: 10px;
    }
  }
  > div:first-of-type > input[type="text"] {
    outline: none !important;
    border: none;
    height: 2.7em;
  }
  :focus-within {
    border: 1px solid ${colors.grey5};
  }
  > div:first-of-type > div {
    margin: ${spacing.space_inset_squish_s};
  }
  ${props =>
    props.isWorkflowDeleted ? `border: 1px solid ${colors.red500}` : ""};
`;

const InlineSelect = styled(SmallBoxWithoutBorders)`
  cursor: pointer;
  position: relative;
  width: 195px;
  display: inline-flex;
  align-items: center;
  > div:first-of-type {
    outline: none;
  }
`;

const Item = styled(ListItemMedium)`
  list-style: none;
  text-transform: capitalize;
  line-height: 2em;
`;

export default Dropdown;
export {
  Sorting,
  SelectItem,
  Small,
  SelectionMultiple,
  SearchResult,
  Select,
  InlineSelect,
  Item
};
