// @flow

import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import * as chatroom from "src/api/chatroom";
import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

function* removeGroups(action: Action) {
  try {
    yield call(chatroom.removeGroups, action.payload);
    yield put({
      type: atypes.REMOVE_ROOM_GROUP_SUCCESS,
      payload: action.payload
    });
  } catch (error) {
    console.error(error);
    toast.error(`Error in removing room group`);
    yield put({
      type: atypes.REMOVE_ROOM_GROUP_FAILURE,
      payload: { ...action.payload, error }
    });
  }
}

function* watchRemoveGroups(): any {
  yield takeEvery(atypes.REMOVE_ROOM_GROUP_REQUEST, removeGroups);
}

export default watchRemoveGroups();
