// @flow

import React from "react";
import { connect } from "react-redux";

import { getGroup } from "src/reducers";

import type { AppState, Group } from "src/types";

type Props = {
  group: Group
};

const ImageLabel = ({ group }: Props) => (
  <span>
    {group.photoUrl ? <img src={group.photoUrl} alt={group.title} /> : null}
    <span>{group.title}</span>
  </span>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  group: getGroup(app, props.id)
});

export default connect(mapStateToProps)(ImageLabel);
