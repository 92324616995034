// @flow

import React, { useState, useCallback, useRef } from "react";
import { SortableHandle, SortableElement } from "react-sortable-hoc";

import { FieldSelect as StyledFieldSelect } from "./styles";
import Search from "./Search";
import Selection from "src/containers/checklist/Selection";
import Icon from "src/icons";
import { HStack, IconButton } from "@chakra-ui/react";

const DragHandle = SortableHandle(() => (
  <IconButton
    display="flex"
    variant="unstyled"
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));

type Props = {
  value: ?number,
  fields: Array<number>,
  itemIndex: number,
  handleField: Function,
  removeField: Function
};

const FieldSelect = SortableElement(
  ({ value, fields, handleField, removeField, itemIndex }: Props) => {
    const [dropdown, setDropdown] = useState(false);
    const outerRef = useRef(null);

    const toggleDropdown = useCallback(() => {
      setDropdown(!dropdown);
    }, [setDropdown, dropdown]);

    const handleClose = useCallback(() => {
      setDropdown(false);
    }, [setDropdown]);

    return (
      <HStack>
        <DragHandle />

        <StyledFieldSelect
          tabIndex="0"
          ref={outerRef}
          role="button"
          onClick={toggleDropdown}
        >
          {dropdown ? (
            <Search
              fields={fields}
              outerRef={outerRef}
              handleSelect={fieldId =>
                handleField({ fieldId, index: itemIndex })
              }
              handleClose={handleClose}
            />
          ) : (
            <Selection value={value} removeField={removeField} />
          )}
        </StyledFieldSelect>
      </HStack>
    );
  }
);

export default FieldSelect;
