// @flow

import React from "react";

import ReadStatus from "./ReadStatus";
import { ChatTimestamp as StyledTimestamp } from "./styles";
import Timestamp from "src/components/messages/Timestamp";

type Props = {
  read: boolean,
  delivered: boolean,
  timestamp: Date,
  isEmailReceived?: boolean
};

const ChatTimestamp = ({
  read,
  delivered,
  timestamp,
  isEmailReceived,
  ...restProps
}: Props) => {
  return (
    <StyledTimestamp {...restProps}>
      {/* Dont't show read status for email received */}
      {isEmailReceived ? null : (
        <ReadStatus read={read} delivered={delivered} />
      )}
      <Timestamp timestamp={timestamp} />
    </StyledTimestamp>
  );
};

export default ChatTimestamp;
