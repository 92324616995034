// @flow

import * as React from "react";
import ChatRooms from "src/components/chatroom-nav";
import RoomContent from "src/components/RoomContent";

import { Body as StyledBody } from "src/styles";

const Body = () => (
  <StyledBody>
    <ChatRooms />
    <RoomContent />
  </StyledBody>
);

export default Body;
