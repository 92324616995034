// @flow

import * as colors from "src/styles/constants/colors";

const chatBlocksColors = [
  colors.purpleLight,
  colors.purple,
  colors.purpleDark,
  colors.sky,
  colors.skyDark,
  colors.indigoLight,
  colors.indigo,
  colors.indigoDark,
  colors.tealLight,
  colors.teal,
  colors.tealDark,
  colors.blueLight,
  colors.blue,
  colors.blueDark,
  colors.greenLight,
  colors.greenDark,
  colors.yellowLight,
  colors.yellow,
  colors.yellowDark,
  colors.orangeLight,
  colors.orange,
  colors.orangeDark,
  colors.redLight,
  colors.inkLightest,
  colors.inkLighter,
  colors.inkLight
];

export default chatBlocksColors;
