// @flow
import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import type { UID, Email } from "src/types";

import UserTag from "./UserTag";

import * as styles from "./styles";

interface UseBooleanReturn {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
}

type Props = {
  value: UID | Email,
  toggleDropdown: $PropertyType<UseBooleanReturn, "setTrue">,
  closeDropdown: $PropertyType<UseBooleanReturn, "setFalse">,
  isOpen: $PropertyType<UseBooleanReturn, "value">
};

const Selection = ({ value, toggleDropdown, closeDropdown, isOpen }: Props) => {
  return (
    <HStack
      sx={styles.Selection}
      role="button"
      tabIndex={0}
      onClick={toggleDropdown}
      onFocus={toggleDropdown}
    >
      {value && typeof value === "string" ? (
        <UserTag uid={value} />
      ) : value && value.email ? (
        <Text sx={styles.Email}>{value.email}</Text>
      ) : (
        <></>
      )}
      {isOpen ? (
        <ChevronUpIcon sx={styles.ToggleDropdownIcon} onClick={closeDropdown} />
      ) : (
        <ChevronDownIcon
          sx={styles.ToggleDropdownIcon}
          onClick={toggleDropdown}
        />
      )}
    </HStack>
  );
};

export default Selection;
