// @flow

import * as React from "react";

import { HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import useBoolean from "src/hooks/useBoolean";

import * as styles from "./styles";

type Props = {
  /**
   * The title of the Accordion
   */
  title: string,

  /**
   * The children of the Accordion
   */
  children: React.Node
};

/**
 * An Accordion. Similar to Chakra's `AccordionPanel`
 * component.
 *
 * Why?
 * -----
 * Chakra's `AccordionPanel` component messes with
 * dropdown styles of Chakra Pro Select component.
 * A style override for `AccordionPanel` to fix
 * the issue was not found.
 * This component looks similar to the former but
 * does not mess with the styles of it's children
 * components since it is barebones.
 *
 * @component
 * @example
 * <Accordion title="FAQ 1">
 *  <Text>Description of the FAQ</Text>
 * </Accordion>
 */
const Accordion = ({ title, children }: Props) => {
  const { value: isOpen, toggleBoolean: toggleVisibility } = useBoolean();

  const handleToggle = e => {
    e.stopPropagation();
    toggleVisibility();
  };

  return (
    <VStack sx={styles.container}>
      <HStack onClick={toggleVisibility} sx={styles.header}>
        <Text textStyle="heading">{title}</Text>

        <IconButton
          onClick={handleToggle}
          icon={
            isOpen ? (
              <ChevronUpIcon {...styles.toggleIcon} />
            ) : (
              <ChevronDownIcon {...styles.toggleIcon} />
            )
          }
          variant="unstyled"
        />
      </HStack>

      {isOpen && children}
    </VStack>
  );
};

export default Accordion;
