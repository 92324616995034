// @flow

import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  SortableElement,
  SortableHandle,
  SortableContainer,
  arrayMove
} from "react-sortable-hoc";

import Remove from "./Remove";
import { SelectedStatusWrapper, List } from "./styles";
import Icons from "src/icons";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import type { UpdateStatusFunction } from "src/components/Manage/Builder/Status";

type Props = {
  value: Array<number>,
  search: string,
  statuses: Array<{ id: number, seqNo: number, active: boolean }>,
  handleDeleteStatus: Function,
  setSearch: Function,
  handleSearch: Function,
  handleEditStatus: Function,
  tempName: string,
  setTempName: Function,
  _setWorkflowBuiderAttributes: Function,
  updateStatusData: UpdateStatusFunction
};

const DragHandle = SortableHandle(() => <Icons type="dottedSort" />);

const Statuses = SortableElement(
  ({
    id,
    statuses,
    handleDeleteStatus,
    setSearch,
    handleSearch,
    handleEditStatus,
    search,
    tempName,
    setTempName,
    updateStatusData
  }) => {
    const [statusChangeModal, setStatusChangeModal] = useState(false);
    const data = R.find(R.propEq("id", id))(statuses || []);

    return (
      <List statusModal={statusChangeModal}>
        <DragHandle />
        <Remove
          id={id}
          handleDeleteStatus={handleDeleteStatus}
          setSearch={setSearch}
          handleSearch={handleSearch}
          handleEditStatus={handleEditStatus}
          search={search}
          tempName={tempName}
          setTempName={setTempName}
          statusChangeModal={statusChangeModal}
          setStatusChangeModal={setStatusChangeModal}
          data={data}
          updateStatusData={updateStatusData}
        />
      </List>
    );
  }
);

const Selection = SortableContainer(
  ({
    children,
    search,
    statuses,
    handleDeleteStatus,
    setSearch,
    handleSearch,
    handleEditStatus,
    sortedValue,
    tempName,
    setTempName,
    updateStatusData
  }) => (
    <SelectedStatusWrapper>
      {(sortedValue || []).map((id, index) => {
        return (
          <Statuses
            id={id}
            value={id}
            index={index}
            key={`item-${index}`}
            statuses={statuses}
            handleDeleteStatus={handleDeleteStatus}
            setSearch={setSearch}
            handleSearch={handleSearch}
            handleEditStatus={handleEditStatus}
            search={search}
            tempName={tempName}
            setTempName={setTempName}
            updateStatusData={updateStatusData}
          />
        );
      })}
      {children}
    </SelectedStatusWrapper>
  )
);

const SortableComponent = ({
  value,
  search,
  statuses,
  handleDeleteStatus,
  setSearch,
  handleSearch,
  handleEditStatus,
  tempName,
  setTempName,
  _setWorkflowBuiderAttributes,
  updateStatusData
}: Props) => {
  const handleSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
      const newSort = arrayMove(statuses, oldIndex, newIndex);
      _setWorkflowBuiderAttributes({
        status: newSort
      });
    },
    [statuses, _setWorkflowBuiderAttributes]
  );

  return (
    <Selection
      items={statuses}
      onSortEnd={handleSort}
      useDragHandle
      sortedValue={value}
      statuses={statuses}
      handleDeleteStatus={handleDeleteStatus}
      setSearch={setSearch}
      handleSearch={handleSearch}
      handleEditStatus={handleEditStatus}
      search={search}
      tempName={tempName}
      setTempName={setTempName}
      updateStatusData={updateStatusData}
    />
  );
};

export default connect(null, {
  _setWorkflowBuiderAttributes: setWorkflowBuiderAttributes
})(SortableComponent);
