// @flow
import firebase from "firebase/app";
import "firebase/firestore";

import type { UID } from "../types";

// TODO: Convert this to a class
const db = (firebaseConfig: Object) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const db = firebase.firestore();

  const listenForMessages = (roomId: string, callback: Function) => {
    // .where("timestamp", ">", since) // Loads only new messages
    return db
      .collection("chatrooms")
      .doc(roomId)
      .collection("messages")
      .orderBy("timestamp")
      .onSnapshot(callback);
  };

  const listenForChatRooms = (callback: Function) => {
    return db.collection("chatrooms").onSnapshot(callback);
  };

  /**
   * create user profile
   */
  const createProfile = async ({
    uid,
    displayName
  }: {
    uid: UID,
    displayName: string
  }) => {
    const doc = { displayName };
    await db.collection("profiles").doc(uid).set(doc);

    return doc;
  };

  const listChatRooms = async ({ uid }: { uid: string }) => {
    // TODO: Return the processed object like it is done for
    // listProfiles
    return await db.collection("profiles").doc(uid).collection("members").get();
  };

  const logSignIn = async (email: string, success: boolean = false) => {
    let db = firebase.firestore();
    await db.collection("AuthLog").add({
      email: email,
      success: success,
      date: firebase.firestore.FieldValue.serverTimestamp()
    });
  };

  const requestTechSupport = async (
    name: string,
    countryCode: number,
    phoneNumber: number,
    email: string
  ): Promise<any> => {
    try {
      let db = firebase.firestore();

      db.collection("TechSupportRequests").add({
        name,
        countryCode,
        phoneNumber,
        email
      });
      // $FlowFixMe
    } catch ({ code, message }) {
      throw "Unable to send request to technical support";
    }
  };

  /*
   * Saved search
   */

  return {
    logSignIn,
    requestTechSupport,
    listenForMessages,
    listenForChatRooms,
    createProfile,
    listChatRooms
  };
};

export default db;
