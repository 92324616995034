// @flow

import { connect } from "react-redux";
import React from "react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

import Stage from "./Stage";
import { getInstanceReportId } from "src/reducers";
import { closeNewChartDialog } from "src/actions/chart";
import type { AppState, NewChartDialog } from "src/types";

type Props = {
  show: boolean,
  reportId: string,
  newChartDialog: NewChartDialog,
  handleClose: Function
};

const New = ({ show, reportId, newChartDialog, handleClose }: Props) => {
  return (
    <Modal
      size="xl"
      isOpen={show}
      onClose={handleClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <Stage
          type={newChartDialog.type}
          stage={newChartDialog.stage}
          reportId={reportId}
          handleClose={handleClose}
        />
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  reportId: getInstanceReportId(app),
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, { handleClose: closeNewChartDialog })(
  New
);
