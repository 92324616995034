// @flow

import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

import type { SelectOrUnselectParticipant } from "src/components/chatroom/Members/AddUser";

type Props = {
  unselectParticipant: SelectOrUnselectParticipant,
  newEmail: string
};

const SelectedNewEmail = ({ newEmail, unselectParticipant }: Props) => {
  return (
    <Flex sx={styles.SelectedUsersAndGroupsWrapper}>
      <Text isTruncated sx={styles.SelecedtedEmail}>
        {newEmail}
      </Text>
      <CloseIcon
        onClick={() => unselectParticipant({ type: "user", id: newEmail })}
        sx={styles.CursorPointer}
        color={colors.grey31}
        boxSize={2}
      />
    </Flex>
  );
};

export default SelectedNewEmail;
