// @flow

import React from "react";
import { Text } from "@chakra-ui/react";
import { HeaderContainer } from "./styles";

type HeaderProps = {
  title: string,
  description: string
};

const Header = ({ title, description }: HeaderProps) => {
  return (
    <HeaderContainer>
      <Text textStyle="modalTitle">{title}</Text>
      <Text textStyle="heading">{description}</Text>
    </HeaderContainer>
  );
};

export default Header;
