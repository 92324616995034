// @flow

import { connect } from "react-redux";

import Title from "src/components/chatroom/creator/Title";
import { getWorkflowSettings } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, { templateId }) => ({
  title: app.conversationDialog.title,
  templateTitle: app.conversationDialog.templateTitle,
  seqNo: app.workflow.nextSeqNo,
  workflowSettings: getWorkflowSettings(app, templateId)
});

export default connect(mapStateToProps)(Title);
