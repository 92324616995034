// @flow
import type {
  StackProps,
  IconProps,
  TextProps,
  TagProps,
  AvatarProps,
  InputProps,
  ButtonProps,
  SystemCSSProperties
} from "@chakra-ui/react";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const SelectionContainer: StackProps = {
  border: `1px solid ${colors.grey9}`,
  p: spacing.space_m,
  borderRadius: radius.small
};

export const SelectedItemsContainer: StackProps = {
  width: "100%",
  flexWrap: "wrap",
  gap: spacing.space_m,
  maxHeight: "100px",
  overflow: "scroll"
};

export const InputField: InputProps = {
  fontSize: "13.5287px",
  width: "full",
  border: "none",
  _focus: {
    border: "none"
  }
};

export const EmailInviteButton: ButtonProps = {
  borderRadius: 0,
  width: "100%",
  color: colors.blue500,
  borderColor: colors.blue500
};

export const Email: TextProps = {
  margin: 0,
  fontWeight: 700
};

export const ErrorMessage: TextProps = {
  color: "red"
};

export const UserOrGroupItem: StackProps = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  px: spacing.space_m,
  py: spacing.space_s,
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const UserOrGroupTag: TagProps = {
  borderRadius: 0,
  border: "1px solid transparent",
  _focus: {
    border: `1px solid ${colors.blue500}`
  }
};

export const UserAndGroupTagText: TextProps = {
  fontSize: "11.7652px",
  ml: spacing.space_s
};

export const UserOrGroupClose: TagProps = {
  _hover: {
    backgroundColor: colors.blackAlpha500
  }
};

export const UserOrGroupCloseFocused = {
  ...UserOrGroupClose,
  outline: "none",
  backgroundColor: colors.red500,
  color: colors.brightLight
};

export const UserAndGroupAvatar: AvatarProps = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: radius.medium,
  "& img": {
    borderRadius: radius.medium
  }
};

export const UserOrGroupDetails: StackProps = {
  width: "100%",
  justifyContent: "center",
  alignItems: "center"
};

export const UserOrGroupDetailsHeader: TextProps = {
  alignSelf: "flex-start",
  fontSize: "12px",
  lineHeight: "16px",
  m: 0
};

export const UserOrGroupDetailsBody: TextProps = {
  alignSelf: "flex-start",
  fontSize: "10px",
  color: colors.grey5,
  m: "0 !important"
};

export const ActiveUserOrgroup: SystemCSSProperties = {
  ...UserOrGroupItem,
  backgroundColor: colors.lightBlue
};

export const inActiveUserOrgroup: SystemCSSProperties = {
  ...UserOrGroupItem,
  backgroundColor: "transparent"
};

export const GroupToggleIcon: IconProps = {
  borderRadius: "100",
  _hover: {
    background: "blackAlpha.300"
  }
};

export const GroupDropdown: StackProps = {
  width: "80%",
  mx: spacing.space_m,
  my: "0 !important"
};

export const DropdownList: StackProps = {
  maxHeight: "12.7rem",
  alignItems: "stretch",
  overflow: "overlay",
  minWidth: "16rem",
  scrollbarGutter: "auto | stable && both-edges?"
};
