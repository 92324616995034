// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const New = styled.div`
  z-index: 999;
  position: absolute;
  top: 3%;
  left: 29%;
  background-color: ${colors.brightLight};
  padding: ${spacing.space_m};
  padding-top: 0;
  width: 40%;
  min-width: 613px;
  resize: ${props => (props.resize ? "both" : "none")};
  overflow: auto;
  font-size: 12px;
`;

export const Header = styled.div`
  position: relative;
  padding-left: 0.4em;
  background-color: ${colors.grey0};
  padding: ${spacing.space_inset_l};
  margin: 0 -${spacing.space_m};
  > h4 {
    display: inline-block;
    margin: ${spacing.space_s} ${spacing.space_m};
  }
`;

export const CenterHeader = styled(Header)`
  > h4 {
    text-align: center;
    width: 93%;
    text-transform: capitalize;
  }
`;

export const Heading = styled.h4`
  margin: 0;
  text-align: center;
  text-transform: capitalize;
`;

export const Close = styled.button`
  position: absolute;
  right: 0.3em;
  top: 0.6em;
  cursor: pointer;
  outline: none;
  border: none;
  margin: ${spacing.space_m};
  background-color: ${colors.grey0};
  z-index: 999;
`;

export const Chart = styled.div`
  min-height: 300px;
`;

export const IconButton: SystemCSSProperties = {
  cursor: "pointer",
  bg: "none",
  h: "1rem",
  w: "1rem",
  minW: "auto",
  _hover: {
    bg: "none"
  },
  _active: {
    bg: "none"
  }
};
