// @flow

import styled from "@emotion/styled";

import Modal from "src/styles/modal";
import {
  Form as StyledForm,
  Label as StyledLabel,
  BlockFormGroup
} from "src/styles/form";
import Close from "src/components/Close/styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const New = styled(Modal)`
  > div > div > .ReactModal__Content {
    left: 16% !important;
    right: 22% !important;
    width: 70%;
    top: 4% !important;
    min-height: auto;
    max-height: 92%;
    bottom: auto !important;
    height: auto;
    overflow-y: auto !important;
    &.ReactModal__Content--after-open {
      overflow-y: auto !important;
    }
  }
`;

const FormGroup = styled(BlockFormGroup)`
  padding-left: 0px;
`;

const Label = styled(StyledLabel)`
  padding-left: 0px;
  font-size: 12.5px;
`;

const Form = styled(StyledForm)`
  padding: 0 2em;
  min-height: 100%;
  h3 {
    margin: 0 -2em;
    margin-bottom: 1em;
    position: relative;
    text-transform: capitalize;
    background: ${colors.grey0};
    padding: ${spacing.space_inset_l};
    padding-left: 1.7em;
    font-weight: bold;
  }

  ${Close} {
    top: 1em;
    right: 1em;
  }
`;

const Picture = styled.div`
  border: 1px dashed ${colors.grey6};
  backgroud: transparent;
  width: 8em;
  height: 8em;
  line-height: 1.67;
  color: ${colors.secondary};
  border-radius: 12px;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1em;
  margin-bottom: 0;
  > input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  > span {
    font-weight: bold;
  }
  :hover {
    > span {
      transition: 0.25s;
      color: ${colors.primary};
    }
  }
  img {
    width: 8em;
    height: 8em;
    border-radius: 12px;
  }
`;

const PictureBox = styled.div`
  display: flex;
  align-items: flex-end;

  > span {
    color: ${colors.active};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.8em;
  padding-bottom: 0.5em;
`;

const Lock = styled.div`
  padding: 1em 0.8em;
  cursor: pointer;
  border: 1px solid ${props => (props.locked ? props.secondary : colors.grey4)};
  > h5 {
    margin: 0px;
  }
`;

export { New, Form, Label, Picture, PictureBox, FormGroup, Footer, Lock };
