// @flow

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";
import * as R from "ramda";
import { arrayMove } from "react-sortable-hoc";

import { SecondaryButton } from "../styles";
import { SortMenu } from "./styles";
import SortItem from "./SortItem";
import SortOptions from "./SortOptions";
import { updateInstanceFilter } from "src/actions/workflows";
import { processColumnNames } from "src/constants/display";
import useBoolean from "src/hooks/useBoolean";
import options from "src/constants/processInstanceColumns";
import {
  getPrincipalChecklistFields,
  getWorkflowInstanceFilter
} from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  fields: Object,
  sortParams: Array<string>,
  filter: WorkflowInstanceFilter,
  _updateInstanceFilter: Function,
  removeSort: Function
};

const Sort = ({
  sortParams,
  fields,
  filter,
  _updateInstanceFilter,
  removeSort
}: Props) => {
  const outerRef = useRef(null);
  const {
    value: sortable,
    toggleBoolean: toggleSortable,
    setFalse: hideSortable
  } = useBoolean();

  const handleSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
      const sort = filter.sort || [];
      const newSort = arrayMove(sort, oldIndex, newIndex);
      _updateInstanceFilter({
        sort: newSort,
        page: 1
      });
    },
    [filter, _updateInstanceFilter]
  );

  const processLabels = R.mergeAll(
    R.map(x => ({ [x.key]: processColumnNames[x.key] }), options)
  );

  if (sortParams.length === 0) {
    return <></>;
  }

  if (sortParams.length === 1) {
    const column = R.head(sortParams);

    return <SortItem column={column} clearSort={removeSort} />;
  }
  return (
    <>
      <SecondaryButton ref={outerRef} type="button" onClick={toggleSortable}>
        {sortParams.length} Parameters
      </SecondaryButton>
      {sortable ? (
        <SortMenu>
          <SortOptions
            processLabels={processLabels}
            fields={fields}
            sort={sortParams}
            clearSort={removeSort}
            handleSort={handleSort}
            handleClose={hideSortable}
            outerRef={outerRef}
          />
        </SortMenu>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app),
  fields: getPrincipalChecklistFields(app)
});

export default connect(mapStateToProps, {
  _updateInstanceFilter: updateInstanceFilter
})(Sort);
