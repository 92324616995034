// @flow

import React from "react";

import { CloseButton } from "@chakra-ui/react";

type Props = {
  handleClose: Function
};

const Close = ({ handleClose }: Props) => (
  <CloseButton
    bg="white"
    position="absolute"
    right={6}
    top={5}
    size="md"
    type="button"
    onClick={handleClose}
  />
);

export default Close;
