// @flow

import styled from "@emotion/styled";

import { ImageLabelRemove } from "src/components/user/styles";
import { Box } from "src/styles/box";
import * as colors from "src/styles/constants/colors";

export const Item = styled.div`
  > div {
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14em;
    overflow: hidden;
  }
`;

export const Value = styled(ImageLabelRemove)`
  align-items: center;
  padding-left: 8px;
  > svg {
    height: 0.8em;
    width: 0.8em;
    stroke-width: 2px;
    margin: 0 0.6em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
    }
  }
`;

export const Search = styled.div`
  position: absolute;
  width: 100%;
  top: 0.3em;
`;

export const Upload = styled(Box)`
  width: 100%;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  > button > input[type="file"] {
    width: 0;
    height: 0;
    visibility: hidden;
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > strong {
    display: inline-block;
    max-width: 11em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 0.3em;
  }
  > span {
    position: absolute;
    right: 0.2em;
  }
`;
