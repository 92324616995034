import * as colors from "src/styles/constants/colors";

export const statusColor = {
  approved: colors.green500,
  "not-started": colors.blue300,
  rejected: colors.red500,
  cancelled: colors.pink300,
  started: colors.orange300
};

export const statusDisplayText = {
  approved: "APPROVED",
  "not-started": "NOT STARTED",
  rejected: "REJECTED",
  cancelled: "CANCELLED",
  started: "IN-PROGRESS"
};
