// @flow

import React from "react";

const Participants = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M20 20.0004V18.192C20 15.2018 17.7644 12.7246 14.8572 12.2861"
      stroke="#1581BA"
      strokeWidth="0.857143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8571 20.0004V18.2096C14.8571 14.9432 11.9735 12.2861 8.42857 12.2861C4.88367 12.2861 2 14.9432 2 18.2096V20.0004"
      stroke="#1581BA"
      strokeWidth="0.857143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8579 9.6597C15.0384 9.6897 15.2219 9.7147 15.412 9.7147C17.2327 9.7147 18.7143 8.27256 18.7143 6.50042C18.7143 4.72828 17.2327 3.28613 15.412 3.28613C15.2219 3.28613 15.0384 3.31042 14.8572 3.34113"
      stroke="#1581BA"
      strokeWidth="0.857143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.42868 9.71477C10.5589 9.71477 12.2858 7.98787 12.2858 5.85763C12.2858 3.72739 10.5589 2.00049 8.42868 2.00049C6.29843 2.00049 4.57153 3.72739 4.57153 5.85763C4.57153 7.98787 6.29843 9.71477 8.42868 9.71477Z"
      stroke="#1581BA"
      strokeWidth="0.857143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Participants;
