// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 12px;
`;

const Section = styled.div`
  padding: 0px;
  gap: 2px;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.p`
  font-size: 12px;
  margin: 0px;
  font-weight: bold;
  color: ${colors.grey39};
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Divider = styled.hr`
  color: ${colors.grey41};
  border: 1px solid;
  margin: 0.1rem 1px;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  height: 32px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 360px;
  overflow: scroll;
`;

const CreatorIcon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${props => props.color || "white"};
`;

const CreatorTitle = styled(Title)`
  margin-bottom: 0px;
`;

export {
  Title,
  Section,
  SectionTitle,
  SectionContent,
  Creator,
  ModalContent,
  CreatorTitle,
  CreatorIcon,
  Divider
};
