// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const canJoin = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.CAN_SRW_USER_JOIN:
      return payload.canJoin || false;
    default:
      return state;
  }
};

export default canJoin;
