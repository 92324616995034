// @flow

import React from "react";

const CirclePlus = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66683 5.66166H7.3335V8.32832H4.66683V9.66166H7.3335V12.3283H8.66683V9.66166H11.3335V8.32832H8.66683V5.66166ZM8.00016 2.32832C4.32016 2.32832 1.3335 5.31499 1.3335 8.99499C1.3335 12.675 4.32016 15.6617 8.00016 15.6617C11.6802 15.6617 14.6668 12.675 14.6668 8.99499C14.6668 5.31499 11.6802 2.32832 8.00016 2.32832ZM8.00016 14.3283C5.06016 14.3283 2.66683 11.935 2.66683 8.99499C2.66683 6.05499 5.06016 3.66166 8.00016 3.66166C10.9402 3.66166 13.3335 6.05499 13.3335 8.99499C13.3335 11.935 10.9402 14.3283 8.00016 14.3283Z"
      fill="#2F80ED"
    />
  </svg>
);

export default CirclePlus;
