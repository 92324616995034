// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import Date from "./index";
import { setUserAnalytics } from "src/actions/userAnalytics";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter,
  _setUserAnalytics: Function
};

const Completed = ({ filter, _setUserAnalytics }: Props) => {
  const handleChange = useCallback(
    (completedAt: ?string) => {
      _setUserAnalytics({
        query: {
          ...filter,
          completedAt
        }
      });
    },
    [filter, _setUserAnalytics]
  );

  const { completedAt } = filter;

  return (
    <Date name="completedAt" value={completedAt} handleChange={handleChange} />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps, {
  _setUserAnalytics: setUserAnalytics
})(Completed);
