// @flow

import React, { useCallback, useMemo } from "react";

import {
  SortItems as StyledSortItem,
  Datetext,
  SecondaryText
} from "src/components/chatroom-nav/filter/styles";

import { getSortByDescription, getSortByIcon } from "src/utils/filters";

import Icons from "src/icons";

type Props = {
  date: string,
  filterText: string,
  active: boolean,
  setSort: Function,
  updatedSortBy: string
};

const SortItem = ({
  date,
  filterText,
  active,
  setSort,
  updatedSortBy
}: Props) => {
  const handleSortItemClick = useCallback(() => {
    if (active && filterText === updatedSortBy) {
      setSort(`${filterText}:ascending`);
    } else {
      setSort(filterText);
    }
  }, [setSort, updatedSortBy, filterText, active]);

  const sortByDescription = useMemo(
    () => getSortByDescription(updatedSortBy, active, filterText),
    [updatedSortBy, active, filterText]
  );

  const sortBy = useMemo(() => getSortByIcon(active, updatedSortBy), [
    active,
    updatedSortBy
  ]);
  return (
    <StyledSortItem onClick={handleSortItemClick} active={active}>
      <div>
        <Datetext>{date}</Datetext>
        <SecondaryText>{sortByDescription}</SecondaryText>
      </div>
      <Icons type={sortBy} />
    </StyledSortItem>
  );
};

export default SortItem;
