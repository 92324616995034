// @flow

// Get analytics data

import * as R from "ramda";
import { renameKeys } from "ramda-adjunct";
import * as reducers from "src/reducers";

import type { AppState } from "src/types";

export const getChatRoom = (state: AppState, roomId: string) => {
  try {
    const data = R.pick(
      ["id", "type", "templateId", "processTitle"],
      reducers.getChatRoom(state, roomId)
    );
    return renameKeys({
      id: "chatroomId",
      type: "chatroomType",
      processTitle: "templateName"
    })(data);
  } catch (ex) {
    console.log("Unable to get analytics metadata of chatroom id - ", roomId);
    return {};
  }
};

// Get metadat of current chatroom
export const getCurrentChatRoom = (state: AppState) => {
  try {
    return getChatRoom(state, state.chatRooms.current);
  } catch (ex) {
    console.log("Unable to get analytics metadata of current chatroom", ex);
    return {};
  }
};

export const getRelatedChatRoom = (state: AppState, parentId: string) => {
  if (parentId) {
    try {
      const data = R.pick(
        ["id", "type", "templateId", "processTitle"],
        reducers.getChatRoom(state, parentId)
      );
      data.related = true;
      return renameKeys({
        id: "relatedChatroomId",
        type: "relatedChatroomType",
        templateId: "relatedTemplateId",
        processTitle: "relatedTemplateName"
      })(data);
    } catch (ex) {
      console.log(
        "Unable to get analytics chatroom metadata of related chatroom id - ",
        parentId
      );
      return {};
    }
  } else return {};
};

export const getMentionedUsers = (state: AppState, message: string) => {
  try {
    const mentions = message.match(/<@\w+>/gm);
    // Remove <@> from UID and return only unique mention
    return mentions
      ? {
          mentionedUsers: [
            ...new Set(mentions.map(m => m.substring(2, m.length - 1)))
          ]
        }
      : null;
  } catch (ex) {
    console.log("Unable get mentioned users - ", message, ex);
    return null;
  }
};

export const getSelectedMessages = (state: AppState) => {
  try {
    return { messages: state.chatRooms.selectedMessages };
  } catch (ex) {
    console.log("Unable to get selected messages - ", ex);
    return { messages: [] };
  }
};

export const getDeletableMessages = (state: AppState) => {
  try {
    const messages: Object = state.chatRooms.deletableMessages.map(x => x.id);
    return { messages };
  } catch (ex) {
    console.log("Unable to get deletable selected messages - ", ex);
    return { messages: [] };
  }
};

export const getForwardMessagesDetails = (state: AppState) => {
  try {
    const { selectedMessages, selectedRooms } = state.chatRooms;
    return { messages: selectedMessages, targetChatrooms: selectedRooms };
  } catch (ex) {
    console.log("Unable to get target chatroom - ", ex);
    return {};
  }
};
