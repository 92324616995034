// @flow

import React from "react";
import { useSelector } from "react-redux";
import { MenuItem } from "@chakra-ui/react";

import { getChecklistFieldLabel } from "src/reducers";

type Props = {
  formFieldId: number,
  mappedFieldIndex: number,
  clickHandler: Function,
  checklistFormId: number,
  formId: number
};

const FormFields = ({
  formFieldId,
  mappedFieldIndex,
  clickHandler,
  checklistFormId,
  formId
}: Props) => {
  const fieldLabel = useSelector(({ app }) =>
    getChecklistFieldLabel(app, formFieldId)
  );

  return (
    <MenuItem
      sx={{ px: "3rem" }}
      data-value={formFieldId}
      onClick={e =>
        clickHandler({
          e,
          formFieldId: checklistFormId,
          checklistId: formId,
          mappedFieldIndex
        })
      }
    >
      {fieldLabel}
    </MenuItem>
  );
};

export default FormFields;
