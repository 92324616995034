// @flow

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import { Name as StyledName } from "./styles";
import { SmallText as Input } from "src/styles/input";
import { getUser, getUserRole } from "src/reducers";
import Icon from "src/components/Icon";
import { updateOtherUserDetails } from "src/actions/user";
import { iconSize } from "src/styles/constants/size";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  role: string,
  user: UnifizeUser,
  _updateOtherUserDetails: Function
};

const Name = ({ role, user, _updateOtherUserDetails }: Props) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    setText(user.displayName);
  }, [user, setText]);

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
    },
    [setText]
  );

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const { uid } = user;
      _updateOtherUserDetails(uid, {
        displayName: text
      });

      setEdit(false);
    },
    [user, text, setEdit, _updateOtherUserDetails]
  );

  if (role === "admin" || role === "phantom") {
    return (
      <StyledName onSubmit={handleSubmit}>
        {edit ? (
          <Input
            value={text}
            placeholder="Enter Display Name"
            onChange={handleChange}
          />
        ) : (
          <span>
            <span>{user.displayName}</span>
            <Icon
              icon="edit"
              onClick={() => setEdit(true)}
              size={iconSize.space_m}
            />
          </span>
        )}
      </StyledName>
    );
  }

  return (
    <StyledName>
      <span>
        <span>{user.displayName}</span>
      </span>
    </StyledName>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid),
  role: getUserRole(app)
});

export default connect(mapStateToProps, {
  _updateOtherUserDetails: updateOtherUserDetails
})(Name);
