// @flow

import React from "react";

const Files = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.5.5h11M11.5 6.5v2.143h-7V6.5"
    />
    <path
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 3.5h15v11.786H.5z"
    />
  </svg>
);

export default Files;
