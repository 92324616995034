// @flow

export const container = {
  position: "relative",
  width: "100%"
};

export const selectedItems = {
  width: "100%",
  position: "relative",
  border: "1px solid",
  borderColor: "gray.200",
  p: 1,
  borderRadius: "6px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
  flexWrap: "wrap",
  height: "100%",
  maxHeight: "200px",
  overflow: "auto"
};

export const selectedItem = {
  backgroundColor: "unifize_grey28",
  px: 2,
  py: 1
};

export const selectedItemCloseIcon = {
  cursor: "pointer",
  width: "10.5px",
  height: "10.5px"
};

export const queryField = {
  flex: 1,
  border: "none",
  outline: "none",
  width: "100%",
  minWidth: "100",
  height: "100%",
  _disabled: {
    backgroundColor: "white",
    cursor: "not-allowed"
  }
};

export const removeAllButton = {
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer"
};

export const optionsContainer = {
  position: "absolute",
  top: "calc(100% + 4px)",
  left: 0
};

export const optionsList = {
  paddingInlineStart: 0,
  mt: 0,
  border: "1px",
  borderColor: "gray.300",
  maxHeight: "200px",
  overflow: "auto"
};

export const option = {
  display: "flex",
  gap: "12px"
};
