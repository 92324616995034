// @flow

import * as R from "ramda";
import type { CommonAutomations, ApprovalFieldSettingsV2 } from "src/types";

export const pathsWithMentions = [
  ["automations", "started", 4, "data"],
  ["automations", "approved", 4, "data"],
  ["automations", "rejected", 4, "data"],
  ["automations", "cancelled", 4, "data"]
];

const sanitizeAutomations = (
  automations: CommonAutomations
): CommonAutomations =>
  // $FlowFixMe
  automations.map(automation => {
    if (automation.action === "updateStatus") {
      return R.mergeDeepRight(automation, {
        active: R.isNil(automation.data) ? false : true
      });
    }

    if (automation.action === "updateOwner") {
      return R.mergeDeepRight(automation, {
        active: R.isNil(automation.data) ? false : true
      });
    }

    return automation;
  });

export const sanitizeSettings = (
  settings: ApprovalFieldSettingsV2
): ApprovalFieldSettingsV2 => {
  const events = Object.keys(settings.automations);
  const transforms = [];

  events.forEach(event => {
    const automations = settings.automations[event];

    const sanitizedAutomations = sanitizeAutomations(automations);

    transforms.push(R.assocPath(["automations", event], sanitizedAutomations));
  });

  // Group IDs were stored as strings instead of numbers
  // so these transforms makes sure they're all numbers
  transforms.push(R.over(R.lensPath(["approvers", "groups"]), R.map(Number)));
  transforms.push(R.over(R.lensPath(["cancellers", "groups"]), R.map(Number)));

  let updatedSettings = settings;
  transforms.forEach(transformer => {
    updatedSettings = transformer(updatedSettings);
  });

  // Remove allowedCancellers since it not used anymore.
  if (R.has("allowedCancellers", updatedSettings)) {
    if (updatedSettings.allowedCancellers === "everyone") {
      updatedSettings.cancellers.allParticipants = true;
    } else if (updatedSettings.allowedCancellers === "approvers") {
      updatedSettings.cancellers.approvers = true;
    }

    delete updatedSettings.allowedCancellers;
  }

  return updatedSettings;
};
