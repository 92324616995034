// @flow

import { SystemCSSProperties } from "@chakra-ui/react";

export const container: SystemCSSProperties = {
  alignItems: "center",
  gap: "1rem",
  width: "100%"
};

export const detailsContainer: SystemCSSProperties = {
  width: "100%",
  gap: "1rem"
};

export const positionLabelWrapper: SystemCSSProperties = {
  minWidth: "2rem",
  textAlign: "right"
};

export const positionLabel: SystemCSSProperties = {
  my: 0,
  width: "100%"
};

export const checklistFieldTagStyles: SystemCSSProperties = {
  mr: 0
};

export const checklistInputFieldStyles: SystemCSSProperties = {
  mr: 0
};

export const actionsContainer: SystemCSSProperties = {
  width: "100%",
  alignItems: "center",
  gap: "1rem"
};
