// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getUserStatus } from "src/reducers";
import { StatusContainer } from "./styles";
import type { UID, UserStatus } from "src/types";

type Props = {
  uid: UID
};

const Status = ({ uid }: Props) => {
  const status: UserStatus = useSelector(({ app }) => getUserStatus(app, uid));
  return <StatusContainer>{status}</StatusContainer>;
};

export default Status;
