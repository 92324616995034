// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, MessageID, RoomId } from "src/types";

export const setComponent = (): Action => ({
  type: atypes.SET_CHAT_MENU_COMPONENT,
  payload: {}
});

export const showChatMenu = (message: string): Action => ({
  type: atypes.SHOW_CHAT_MENU,
  payload: { message }
});

export const hideChatMenu = (): Action => ({
  type: atypes.HIDE_CHAT_MENU,
  payload: {}
});

export const toggleChatMenu = (message: string): Action => ({
  type: atypes.TOGGLE_CHATMENU,
  payload: { message }
});

export const setReplyTo = (to: string, roomId: RoomId) => ({
  type: atypes.SET_REPLY_TO,
  payload: {
    to,
    roomId
  }
});

export const clearReplyTo = () => ({
  type: atypes.CLEAR_REPLY_TO
});

export const deleteMessage = (roomId: string): Action => ({
  type: atypes.DELETE_MESSAGE_REQUEST,
  payload: { roomId }
});

export const hideMessage = (roomId: string): Action => ({
  type: atypes.HIDE_MESSAGE_REQUEST,
  payload: { roomId }
});

export const forwardMessage = (roomId: string): Action => ({
  type: atypes.FORWARD_MESSAGE_REQUEST,
  payload: { roomId }
});

export const showDeleteOptions = (roomId: RoomId): Action => ({
  type: atypes.SET_DELETE_OPTIONS,
  payload: { roomId }
});

export const showForwardOptions = (roomId: RoomId): Action => ({
  type: atypes.SET_FORWARD_OPTIONS,
  payload: { roomId }
});

export const clearMessageSelection = () => ({
  type: atypes.CLEAR_MESSAGE_SELECTION
});

export const showMobileOptions = (): Action => ({
  type: atypes.SET_MOBILE_OPTIONS,
  payload: {}
});

export const hideSelectOptions = (): Action => ({
  type: atypes.HIDE_SELECT_OPTIONS,
  payload: {}
});

export const selectMessage = (
  id: MessageID,
  timestamp: Date,
  isOwner: boolean
): Action => ({
  type: atypes.SELECT_MESSAGE,
  payload: { id, timestamp, isOwner }
});

export const deselectMessage = (id: MessageID): Action => ({
  type: atypes.DESELECT_MESSAGE,
  payload: { id }
});

export const unhideMessage = (roomId: RoomId, id: MessageID): Action => ({
  type: atypes.UNHIDE_MESSAGE_REQUEST,
  payload: { roomId, id }
});

export const selectRoom = (id: RoomId): Action => ({
  type: atypes.SELECT_ROOM,
  payload: { id }
});

export const deselectRoom = (id: RoomId): Action => ({
  type: atypes.DESELECT_ROOM,
  payload: { id }
});
