// @flow

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useCallback } from "react";

import { Invite as StyledInvite } from "./styles";
import { ButtonLink as Button } from "src/styles/buttons";

import { getCurrentUserId, getRole } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  role: string,
  text: string,
  handleNewUser: Function,
  handleInvite: Function
};

const Invite = ({ role, text, handleNewUser, handleInvite }: Props) => {
  const handleNewInvite = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (text.length > 0) {
        handleInvite(text);
      } else {
        handleNewUser();
      }
    },
    [handleInvite, handleNewUser, text]
  );

  if (role === "demo") {
    return <></>;
  }

  return (
    <StyledInvite onClick={handleNewInvite}>
      <Button type="button">
        {R.trim(text || "").length > 0 ? (
          <>
            Invite <strong>{text}</strong>
          </>
        ) : (
          "Invite via email"
        )}
      </Button>
    </StyledInvite>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps)(Invite);
