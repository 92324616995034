// @flow

import React from "react";

import { HStack, Box, VStack, Text } from "@chakra-ui/react";

import {
  passwordStrengthLabels,
  passwordStrengthMeterColorsByLevel
} from "src/constants";
import { getPasswordStrength } from "src/utils";
import * as styles from "./PasswordStrengthMeter.styles";

type Props = {
  password: string
};

export default function PasswordStrengthMeter({ password }: Props) {
  const strength = getPasswordStrength(password);
  const strengthMeterColors = passwordStrengthMeterColorsByLevel[strength];

  return (
    <VStack sx={styles.container} spacing={1}>
      <HStack sx={styles.meterContainer}>
        {strengthMeterColors.map((color, index) => (
          <Box key={index} sx={styles.meter} background={color} />
        ))}
      </HStack>

      <Text textStyle="caption" sx={styles.strengthLabel}>
        {passwordStrengthLabels[strength]}
      </Text>
    </VStack>
  );
}
