// @flow

import React from "react";

import { DateTime as StyledLastLogin } from "./styles";
import User from "src/containers/user/LastLoggedIn";

import type { UID } from "src/types";

type Props = {
  uid: UID
};

const LastLoggedIn = ({ uid }: Props) => (
  <StyledLastLogin>
    <User uid={uid} />
  </StyledLastLogin>
);

export default LastLoggedIn;
