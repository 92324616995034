// @flow

import React from "react";

import Common from "./Common";
import { Capitalize } from "src/styles";

import type { Message } from "src/types";

type Props = {
  message: Message
};

const Priority = ({ message }: Props) => {
  const { data } = message;
  const { new: newPriority, old: oldPriority } = data;

  return (
    <Common {...message}>
      {" "}
      updated the priority from{" "}
      <strong>
        <Capitalize>{oldPriority || "normal"}</Capitalize>
      </strong>{" "}
      to{" "}
      <strong>
        <Capitalize data-cy="newPriority">{newPriority || "normal"}</Capitalize>
      </strong>
    </Common>
  );
};

export default Priority;
