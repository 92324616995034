// @flow

import { connect } from "react-redux";
import React from "react";

import Type from "./Type";
import Fields from "./Fields";
import Chart from "./Chart";
import Loader from "./Chart/Loader";
import { closeNewChartDialog } from "src/actions/chart";

type Props = {
  stage: number,
  reportId: string,
  _closeNewChartDialog: Function
};

const Stage = ({ stage, reportId, _closeNewChartDialog }: Props) => {
  switch (stage) {
    case 0:
      return <Type reportId={reportId} handleClose={_closeNewChartDialog} />;
    case 1:
      return <Fields handleClose={_closeNewChartDialog} />;
    case 2:
      return <Chart handleClose={_closeNewChartDialog} />;
    case 3:
      return <Loader handleClose={_closeNewChartDialog} />;
    default:
      return <></>;
  }
};

export default connect(null, {
  _closeNewChartDialog: closeNewChartDialog
})(Stage);
