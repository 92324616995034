// @flow

import React from "react";

type Props = {
  small?: boolean,
  color?: string
};

const Checklist = ({ small = false, color = "#888" }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={small ? "10" : "17"}
    height={small ? "8" : "13"}
    viewBox="0 0 17 13"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.469.5h1.875M1.469 4.5h1.875M1.469 8.5h1.875M1.469 12.5h1.875M6.156.5h9.375M6.156 4.5h5.625M6.156 8.5h9.375M6.156 12.5h4.688" />
    </g>
  </svg>
);

export default Checklist;
