// @flow

import React from "react";

import { DockHeaderPlaceholder } from "./styles";
import PulseLoader from "src/components/PulseLoader";

const PanelHeader = () => (
  <DockHeaderPlaceholder>
    <PulseLoader w="90px" h="18px" />
  </DockHeaderPlaceholder>
);

export default PanelHeader;
