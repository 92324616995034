// @flow

import type { Set as SetType } from "immutable";
import * as atypes from "src/constants/actionTypes";

import type {
  UID,
  Action,
  NewChat,
  UnifizeChat,
  RoomId,
  ChatroomSearchOptions,
  MemberDialog,
  NonOrgParticipant,
  ColumnId
} from "src/types";

export const changeCurrentChatroom = (id: RoomId): Action => ({
  type: atypes.CHANGE_CURRENT_CHATROOM,
  payload: {
    id
  }
});

export const toggleAddFile = (isAddFile: boolean): Action => ({
  type: atypes.TOGGLE_CHATROOM_ADD,
  payload: { isAddFile }
});

export const updateLastRead = (
  roomId: string,
  uid: string,
  orgId: number
): Action => ({
  type: atypes.UPDATE_LAST_READ_REQUEST,
  payload: { roomId, uid, orgId }
});

export const incrementMessageCount = (roomId: string): Action => ({
  type: atypes.INCREMENT_MESSAGE_COUNT,
  payload: { roomId }
});

export const updateFavourite = (
  roomId: string,
  uid: string,
  isFavourite: boolean
): Action => ({
  type: atypes.UPDATE_FAVOURITE_REQUEST,
  payload: { roomId, uid, isFavourite }
});

export const createChatroom = (newChat: NewChat): Action => ({
  type: atypes.CREATE_CHATROOM_REQUEST,
  payload: newChat
});

export const createDirectConversation = (member: UID): Action => ({
  type: atypes.CREATE_DIRECT_CONVERSATION_REQUEST,
  payload: {
    member
  }
});

export const insertChat = (message: UnifizeChat) => ({
  type: atypes.INSERT_CHAT_REQUEST,
  payload: { message }
});

export const sortBy = (sortBy: string) => ({
  type: atypes.SET_SORT_BY,
  payload: {
    sortBy
  }
});

export const getRelatedCount = (roomId: string) => ({
  type: atypes.GET_RELATED_COUNT_REQUEST,
  payload: {
    roomId
  }
});

export const getRelated = (roomId: string) => ({
  type: atypes.GET_RELATED_CONVERSATION_REQUEST,
  payload: {
    roomId
  }
});

export const setAttribute = (
  roomId: string,
  value: Object,
  location: string,
  columnId?: ColumnId
) => ({
  type: atypes.SET_CHATROOM_ATTRIBUTE_REQUEST,
  payload: {
    roomId,
    value,
    analytics: {
      location
    },
    columnId
  }
});

export const inviteOwner = (roomId: string, value: Object) => ({
  type: atypes.INVITE_OWNER_REQUEST,
  payload: {
    roomId,
    value
  }
});

export const addRoomMember = ({
  users,
  groups,
  roomId,
  request = true
}: {
  users: Array<UID | NonOrgParticipant> | UID,
  groups: ?Array<number>,
  roomId: string,
  request: boolean
}) => ({
  type: atypes.ADD_ROOM_MEMBER_REQUEST,
  payload: { users, groups, roomId, request }
});

export const removeRoomMember = (member: UID, roomId: string) => ({
  type: atypes.REMOVE_ROOM_MEMBER_REQUEST,
  payload: { member, roomId }
});

export const removeRoomGroup = (id: number, roomId: string) => ({
  type: atypes.REMOVE_ROOM_GROUP_REQUEST,
  payload: { id, roomId }
});

export const loadRoomMembers = (snapshot: Object) => ({
  type: atypes.LOAD_ROOM_MEMBERS_SUCCESS,
  payload: {
    snapshot
  }
});

export const updatePendingMembers = (snapshot: Object) => ({
  type: atypes.UPDATE_PENDING_MEMBERS_REQUEST,
  payload: {
    snapshot
  }
});

export const loadRoomMembersError = (snapshot: Object) => ({
  type: atypes.LOAD_ROOM_MEMBERS_FAILURE,
  payload: {
    snapshot
  }
});

export const startMembershipSync = (snapshot: Object) => ({
  type: atypes.START_MEMBERSHIP_SYNC,
  payload: { snapshot }
});

export const membershipSyncError = (e: any) => ({
  type: atypes.MEMBERSHIP_SYNC_FAILURE,
  payload: e
});

export const stopMembershipSync = () => ({
  type: atypes.STOP_MEMBERSHIP_SYNC,
  payload: {}
});

export const toggleMembers = () => ({
  type: atypes.TOGGLE_MEMBERS,
  payload: {}
});

export const hideMembers = () => ({
  type: atypes.HIDE_MEMBERS,
  payload: {}
});

export const showMembers = (memberDialog: MemberDialog) => ({
  type: atypes.SHOW_MEMBERS,
  payload: memberDialog
});

export const handleMessages = (
  snapshot: Object,
  roomId: string,
  scroll: ?boolean
) => ({
  type: atypes.HANDLE_MESSAGES,
  payload: {
    snapshot,
    roomId,
    scroll
  }
});

export const syncMessages = (
  roomId: string,
  messageCount: number,
  modal: boolean = false
) => ({
  type: atypes.START_MESSAGE_SYNC,
  payload: {
    roomId,
    messageCount,
    modal
  }
});

export const resyncMessages = (
  roomId: string,
  messageCount: number,
  modal: boolean = false
) => ({
  type: atypes.RESYNC_MESSAGES,
  payload: {
    roomId,
    messageCount,
    modal
  }
});

export const cancelMessageSync = (roomId: string, modal: boolean = false) => ({
  type: atypes.CANCEL_MESSAGE_SYNC,
  payload: {
    roomId,
    modal
  }
});

export const fetchMessage = (messageId: string) => ({
  type: atypes.FETCH_MESSAGE_REQUEST,
  payload: { messageId }
});

export const fetchMessageRange = (message: string) => ({
  type: atypes.FETCH_MESSAGE_RANGE_REQUEST,
  payload: message
});

export const pushRelated = (roomId: string) => ({
  type: atypes.PUSH_RELATED_CONVERSATION,
  payload: {
    roomId
  }
});

export const popRelated = () => ({
  type: atypes.POP_RELATED_CONVERSATION,
  payload: {}
});

export const showRelated = () => ({
  type: atypes.SHOW_RELATED_CONVERSATION,
  payload: {}
});

export const hideRelated = () => ({
  type: atypes.HIDE_RELATED_CONVERSATION,
  payload: {}
});

export const setConversationAttribute = ({
  id,
  value
}: {
  id: string,
  value: any
}) => ({
  type: atypes.SET_NEW_CONVERSATION_ATTRIBUTE,
  payload: {
    id,
    value
  }
});

export const setConversationAttributes = (value: Object) => ({
  type: atypes.SET_NEW_CONVERSATION_ATTRIBUTES,
  payload: {
    value
  }
});

export const clearConversationDialog = () => ({
  type: atypes.CLEAR_NEW_CONVERSATION,
  payload: {}
});

export const searchChatroom = (
  searchString: string,
  settings?: ChatroomSearchOptions = {}
) => ({
  type: atypes.SEARCH_CHATROOM_REQUEST,
  payload: {
    searchString,
    settings
  }
});

export const resetSearch = (settings?: Object = {}) => ({
  type: atypes.RESET_CHATROOM_SEARCH,
  payload: {
    settings
  }
});

export const focusChatInput = (roomId: RoomId) => ({
  type: atypes.FOCUS_CHAT_INPUT,
  payload: { roomId }
});

export const preserveChatInput = (roomId: string, message: string) => ({
  type: atypes.PRESERVE_CHAT_INPUT,
  payload: { roomId, message }
});

export const clearChatInput = (roomId: string) => ({
  type: atypes.CLEAR_CHAT_INPUT,
  payload: { roomId }
});

export const resetChatInputFocus = () => ({
  type: atypes.RESET_CHAT_INPUT_FOCUS,
  payload: {}
});

export const cancelConversation = (
  roomId: RoomId,
  comment: ?string,
  location: string
) => ({
  type: atypes.CANCEL_CHATROOM_REQUEST,
  payload: {
    roomId,
    value: { comment },
    analytics: {
      location
    }
  }
});

export const changeConversationType = (
  roomId: RoomId,
  type: string,
  templateId?: number
) => ({
  type: atypes.CHANGE_CONVERSATION_TYPE_REQUEST,
  payload: {
    roomId,
    type,
    templateId
  }
});

export const openConversationModal = (id: RoomId) => ({
  type: atypes.OPEN_CONVERSATION_MODAL,
  payload: {
    id
  }
});

export const closeConversationModal = () => ({
  type: atypes.CLOSE_CONVERSATION_MODAL,
  payload: {}
});

export const setCurrentChatroom = (id: RoomId) => ({
  type: atypes.SET_CURRENT_CHATROOM_REQUEST,
  payload: {
    id
  }
});

export const getCommonMembers = (chatrooms: Array<number>) => ({
  type: atypes.GET_COMMON_MEMBERS_REQUEST,
  payload: {
    chatrooms
  }
});

export const removeCommonMembers = (options: {
  chatrooms: Array<number>,
  members: Array<UID>
}) => ({
  type: atypes.REMOVE_COMMON_MEMBERS_REQUEST,
  payload: options
});

export const addMultipleRoomMembers = (members: Array<UID>) => ({
  type: atypes.BULK_ADD_ROOM_MEMBERS_REQUEST,
  payload: {
    members
  }
});

export const loadChatroom = (id: string) => ({
  type: atypes.LOAD_CHATROOM_REQUEST,
  payload: { id }
});

export const loadChatroomError = (error: Object) => ({
  type: atypes.LOAD_CHATROOM_FAILURE,
  payload: {
    error
  }
});

export const refetchAllConversation = () => ({
  type: atypes.REFETCH_ALL_CONVERSATIONS_REQUEST,
  payload: {}
});

export const fetchConversation = (id: RoomId) => ({
  type: atypes.FETCH_CHATROOM_DETAILS_REQUEST,
  payload: {
    id
  }
});

export const resetHasNewMessage = () => ({
  type: atypes.RESET_HAS_NEW_MESSAGE,
  payload: {}
});

export const getChildConversations = (roomId: RoomId) => ({
  type: atypes.GET_CHILD_CONVERSATIONS_REQUEST,
  payload: {
    roomId
  }
});

export const showConversationalModalWarn = () => ({
  type: atypes.CONVERSATION_DIALOG_WARN_MODAL,
  payload: {}
});

export const clearConversationalModalWarn = () => ({
  type: atypes.CLEAR_CONVERSATION_DIALOG_WARN_MODAL
});

export const copyConversationEmail = () => ({
  type: atypes.COPY_CONVERSATION_EMAIL,
  payload: {}
});

export const setNewWorkflow = (id: number) => ({
  type: atypes.SET_NEW_WORKFLOW,
  payload: {
    id
  }
});

export const setConversationDialogTitle = (id: string) => ({
  type: atypes.SET_MESSAGE_AS_CONVERSATION_TITLE,
  payload: {
    id
  }
});

export const toggleMemberAddDialog = (location: string) => ({
  type: atypes.TOGGLE_MEMBER_ADD_DIALOG,
  payload: { location }
});

export const setCheckedStatus = (id: SetType<number>) => ({
  type: atypes.SET_CUSTOM_STATUS,
  payload: {
    id
  }
});

export const loadTyping = (snapshot: Object, roomId: RoomId) => ({
  type: atypes.LOAD_TYPING,
  payload: { snapshot, roomId }
});

export const setTyping = (roomId: RoomId) => ({
  type: atypes.SET_TYPING_REQUEST,
  payload: { roomId }
});

export const deleteTyping = (roomId: RoomId) => ({
  type: atypes.DELETE_TYPING_REQUEST,
  payload: { roomId }
});

export const fetchChatroomUpdates = () => ({
  type: atypes.FETCH_CHATROOM_UPDATES_REQUEST,
  payload: {}
});

export const setPreviousChatroom = () => ({
  type: atypes.SET_WATCH_SET_PREVIOUS_CHATROOM,
  payload: {}
});

export const showParticipantLoader = (address: string) => ({
  type: atypes.SHOW_PARTICIPANT_LOADER,
  payload: {
    address
  }
});

export const hideParticipantLoader = (address: string) => ({
  type: atypes.HIDE_PARTICIPANT_LOADER,
  payload: {
    address
  }
});

export const searchChatroomSuccess = (result: Array<RoomId>) => ({
  type: atypes.SEARCH_CHATROOM_SUCCESS,
  payload: { result }
});
