// @flow

import type { UID } from "src/types";
import * as easyFetch from "src/utils/fetch";

/**
 * Fetches all the roles based on the current org
 */
export const getAllRoles = () => easyFetch.get("/role");

/**
 * Assigns role to the user
 * @param {number} id
 * @param {UID} member UID
 * @param {string} email
 */
export const assignUsersToRole = (id: number, members: [UID]) =>
  easyFetch.post(`/role/${id}/member`, {
    body: {
      members
    }
  });

/**
 * Removes user from an assigned role
 * @param {number} id
 * @param {string} memberUID UID
 */
export const removeUserFromRole = (id: number, memberUID: string) =>
  easyFetch._delete(`/role/${id}/member/${memberUID}`);

/**
 * Adds a custom role
 * @param {string} newRole => title of the new Role
 */
export const addCustomRole = (newRole: string) =>
  easyFetch.post("/role", {
    body: {
      title: newRole
    }
  });

/**
 * Deletes a custom role added
 * @param {number} id
 */
export const deleteCustomRole = (id: number) =>
  easyFetch._delete(`/role/${id}`);

/**
 * Updates a custom role title
 * @param {number} id
 * @param {string} updatedTitle
 */
export const updateCustomRole = (id: number, updatedTitle: string) =>
  easyFetch.patch(`/role/${id}`, {
    body: {
      title: updatedTitle
    }
  });

/**
 * Fetches all the permissions
 */
export const getAllPermissions = () => easyFetch.get("/permissions");

/**
 * Assigns resource permissions to role
 * @param {number} id
 * @param {number} rid is resource id
 * @param {number} vid is verb id
 */
export const assignResourcePermissionToRole = (
  id: number,
  rid: number,
  vid: number
) => easyFetch.put(`/role/${id}/resource/${rid}/verb/${vid}`);

/**
 * Removes resource permissions to role
 * @param {number} id
 * @param {number} rid is resource id
 * @param {number} vid is verb id
 */
export const removeResourcePermissionToRole = (
  id: number,
  rid: number,
  vid: number
) => easyFetch._delete(`/role/${id}/resource/${rid}/verb/${vid}`);

/**
 * Assigns component permissions to role
 * @param {number} id
 * @param {number} cid is component id
 */
export const assignComponentPermissionToRole = (id: number, cid: number) =>
  easyFetch.put(`/role/${id}/component/${cid}`);

/**
 * Removes resource permissions to role
 * @param {number} id
 * @param {number} cid is component id
 */
export const removeComponentPermissionToRole = (id: number, cid: number) =>
  easyFetch._delete(`/role/${id}/component/${cid}`);
