// @flow
import React from "react";

import { ModalBody } from "@chakra-ui/react";

import { modalBody } from "./styles";

const ErrorPanel = () => (
  <ModalBody sx={modalBody}>
    <span>An error has occurred.</span>
    <code>Switched to non existent panel.</code>
  </ModalBody>
);

export default ErrorPanel;
