// @flow

import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

import { ChatIndicatorWrapper } from "./styles";
import TypingIndicator from "./TypingAnimation";
import User from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { getAuthorColor } from "src/utils";
import {
  UserInfo,
  ChatCard,
  Name as StyledName
} from "src/components/messages/Chat/styles";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import MessageCard from "src/components/messages/Chat/MessageNotch";

import type { UID, RoomId, AppState, UserTyping } from "src/types";

type Props = {
  uid: UID,
  message: any,
  type: any,
  roomId: RoomId,
  currentRoomAddress: RoomId,
  showSelect: boolean,
  isSrwMobile: boolean,
  _selectMessage: Function,
  _showMobileOptions: Function,
  typing: Array<UserTyping>,
  recomputedTyping: any
};

const TypingItem = ({ currentRoomAddress, uid }: Props) => {
  const indicatorRef = useRef();

  useEffect(() => {
    if (indicatorRef) {
      // $FlowFixMe
      indicatorRef?.current?.scrollIntoView();
    }
  }, [indicatorRef]);

  return (
    <ChatIndicatorWrapper>
      <ChatCard first showSelect={false}>
        <UserInfo ref={indicatorRef}>
          <User uid={uid} size="sm" shape="ellipse" />
        </UserInfo>
        <MessageCard first isCurrentUser={false}>
          <StyledName hashColor={getAuthorColor(uid, currentRoomAddress)}>
            <NameOrEmail uid={uid} />
          </StyledName>
          <TypingIndicator />
        </MessageCard>
      </ChatCard>
    </ChatIndicatorWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps, {})(TypingItem);
