// @flow

import styled from "@emotion/styled";

import { SmallText } from "src/styles/input";
import * as colors from "src/styles/constants/colors";

export const Edit = styled.p`
  padding: 0px;
  cursor: pointer;
  color: ${colors.inkLight};

  > svg {
    width: 0.9em;
    height: 0.9em;
    cursor: pointer;
    visibility: hidden;
  }

  > span {
    border: 1px solid ${colors.brightLight} !important;
  }
  :hover {
    > svg {
      visibility: visible;
    }
  }

  ${SmallText}, > span {
    padding: 4px;
    padding-left: 0.3em;
    min-width: 10px;
    display: inline-block;
    width: auto;
    line-height: 1.15;
  }

  ${SmallText} {
    border: 1px solid ${colors.grey5} !important;
    width: 95%;
  }
`;
