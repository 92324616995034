// @flow

import styled from "@emotion/styled";

import type { BoxProps, TextProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import { fontSize } from "src/styles/constants";

export const Checklist = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: ${props => (props.showPanel ? "pointer" : "initial")};
  > span {
    margin-left: ${spacing.space_s};
  }
`;

export const Label = styled.div`
  display: flex !important;
  align-items: center;
  margin-right: ${spacing.space_s};
`;

export const Info = styled.div`
  white-space: nowrap;
  background: ${colors.grey1};
  padding: 0 ${spacing.space_s};
  display: flex !important;
  align-items: center;
  border-radius: ${radius.small};
  font-size: ${fontSize.t4};
  min-width: 3em;
`;

const ChecklistBox: BoxProps = {
  display: "flex",
  gap: "0.2rem",
  height: "40px",
  justifyContent: "flex-end",
  alignItems: "center",
  marginLeft: "auto",
  padding: "0.5rem !important",
  borderRadius: "1.875rem 0px 0px 1.875rem",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 0px 6px 0px rgba(104, 228, 255, 0.70)"
  }
};

export const ChecklistBoxOpen = {
  ...ChecklistBox,
  backgroundColor: "transparent",
  border: `1px solid ${colors.paleBlue}`,
  color: colors.inkLightest
};

export const Mandatory = {
  ...ChecklistBox,
  backgroundColor: "transparent",
  border: `1px solid ${colors.salmonPink}`,
  color: colors.salmonPink
};

export const ChecklistBoxClosed = {
  ...ChecklistBox,
  backgroundColor: "#E6FFFA",
  border: "1px solid #1581BA",
  color: "#1581BA"
};

export const ChecklistText: TextProps = {
  fontSize: "14px",
  lineHeight: "16px"
};

export const MandatoryCount = {
  ...ChecklistText,
  backgroundColor: colors.salmonPink,
  color: "#fff",
  borderRadius: "16px",
  padding: "1px 0.5rem"
};

export const IconContainer = {
  height: "1.5rem"
};
