// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  isReportPaginated: boolean,
  toggleReportPagination: () => void
};

const isReportPaginated = ({
  isReportPaginated,
  toggleReportPagination
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="isReportPaginated"
        checked={isReportPaginated}
        handleChange={toggleReportPagination}
      />
      Enable pagination in reports
    </Label>
  </InlineFormGroup>
);

export default isReportPaginated;
