// @flow

import React from "react";

type Props = {
  handleClick?: Function
};

const Menu = ({ handleClick = null }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
  >
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="black" />
  </svg>
);

export default Menu;
