// @flow

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

import { getFile as resolveFile } from "src/actions/file";
import { getFile, getCurrentRoomId } from "src/reducers";
import Icon from "src/icons";
import { getFileType, getFileSize } from "src/utils/file";
import useFileViewer from "src/hooks/useFileViewer";

import { FileData } from "src/components/Dock/Checklist/styles";

import {
  FileWrapper,
  FileIconWrapper,
  FileSize,
  Name,
  DownloadIconWrapper
} from "../styles";
import { Center, Spinner } from "@chakra-ui/react";

type Props = {
  id: string,
  handleDownload: Function
};

const File = ({ id, handleDownload }: Props) => {
  const file = useSelector(({ app }) => getFile(app, id));
  const dispatch = useDispatch();

  const roomId = useSelector(({ app }) => getCurrentRoomId(app));

  const modal = useSelector(({ app }) => app.conversationModal.show);

  const { data: viewState, openFile } = useFileViewer({ file, modal, roomId });

  useEffect(() => {
    if (R.isEmpty(file || {})) {
      dispatch(resolveFile(id));
    }
  }, [id, file]);

  let fileSize = 0;
  if (file) {
    const { versions } = file;
    const version = R.last(versions || []) || {};
    const { size } = version;
    fileSize = size;
  }

  return file ? (
    <FileWrapper onClick={() => openFile()}>
      {viewState.fetching ? (
        <Center
          sx={{
            borderRadius: "2px",
            backgroundColor: "gray.50",
            m: "2px",
            width: "32px",
            height: "37px"
          }}
        >
          <Spinner emptyColor="gray.200" color="blue.500" />
        </Center>
      ) : (
        <FileIconWrapper>
          <Icon type="fileIcons" icon={getFileType(file.originalName)} />
        </FileIconWrapper>
      )}

      <FileData>
        <Name>{file.originalName}</Name>
        <FileSize>{getFileSize(fileSize)}</FileSize>
      </FileData>
      <DownloadIconWrapper
        onClick={e => {
          e.stopPropagation();
          handleDownload(file);
        }}
      >
        <Icon type="download" />
      </DownloadIconWrapper>
    </FileWrapper>
  ) : null;
};

export default File;
