// @flow

import React from "react";

const Chart = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 1.5V10.5H23.5C23.5 5.529 19.471 1.5 14.5 1.5Z"
      stroke="#1581BA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 5.5C5.529 5.5 1.5 9.529 1.5 14.5C1.5 19.471 5.529 23.5 10.5 23.5C15.471 23.5 19.5 19.471 19.5 14.5H10.5V5.5Z"
      stroke="#1581BA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chart;
