// @flow

import { useEffect, useRef } from "react";

/**
 * "react-friendly" wrapper around setTimeout
 *
 * Why is this needed?
 * Problems with using window.setTimeout in React:
 *
 * 1. A new timeout will be scheduled whenever this component renders,
 * instead of only once when the component mounts. If our parent
 * component re-renders 10 times, we'll schedule 10 timeouts.
 *
 * 2. Our callback function is stale; it won't have access to current
 * values for state and props.
 *
 * For more context:
 * https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/#explanation
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
const useTimeout = (callback: Function, delay: ?number) => {
  const timeoutRef = useRef<null | number>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
};

export default useTimeout;
