// @flow

import styled from "@emotion/styled";
import { IconLabel } from "src/styles/box";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

const Timestamp = styled(IconLabel)`
  height: 18px;
  > svg {
    padding-left: 6px;
    margin-top: 8px;
  }
  > span {
    color: ${colors.grey5};
    line-height: 1.6;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    padding-left: 0;
    vertical-align: top;
    text-transform: lowercase;
  }
`;

const IconLabelRemove = styled.div`
  display: inline-flex;
  justify-content: space-between;
  background: ${colors.grey1};
  height: 21px;
  > span {
    margin: 0px;
  }
  > button {
    border: none;
    background: ${colors.grey1};
    padding: ${spacing.space_list_m};
    padding-right: ${spacing.space_xs};
    outline: none;
    cursor: pointer;
  }
`;

export { Timestamp, IconLabelRemove };
