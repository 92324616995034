// @flow

import React from "react";

import Common from "./Common";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Owner = ({ message }: Props) => {
  const { data } = message;
  const { new: newOwner, old: oldOwner } = data;
  if (newOwner && oldOwner)
    return (
      <Common {...message}>
        {" "}
        changed the owner from{" "}
        <strong>
          <NameOrEmail uid={oldOwner} showPending={false} />
        </strong>{" "}
        to{" "}
        <strong>
          <NameOrEmail uid={newOwner} showPending={false} />
        </strong>
      </Common>
    );
  if (newOwner)
    return (
      <Common {...message}>
        {" "}
        assigned{" "}
        <strong>
          <NameOrEmail uid={newOwner} />
        </strong>{" "}
        as the owner
      </Common>
    );
  return (
    <Common {...message}>
      {" "}
      removed{" "}
      <strong>
        <NameOrEmail uid={oldOwner} />
      </strong>{" "}
      as the owner
    </Common>
  );
};

export default Owner;
