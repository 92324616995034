// @flow

import React from "react";

import type { UID } from "src/types";

import { FormControl, FormLabel, VStack, HStack } from "@chakra-ui/react";
import { TileColor } from "src/components/OrgSettings/HomeScreen/Modal/styles";
import ProcessOwners from "src/components/Manage/Builder/Template/ProcessOwners";
import {
  purpleLighter,
  indigoLighter,
  grey25,
  grey22,
  grey26,
  yellowLighter
} from "src/styles/constants/colors";

const colors = [
  purpleLighter,
  indigoLighter,
  grey25,
  grey22,
  grey26,
  yellowLighter
];

type Props = {
  setFormColor: Function,
  selectedColor: string,
  owners: UID[],
  handleFormOwners: Function
};

export default function Details({
  setFormColor,
  selectedColor,
  owners,
  handleFormOwners
}: Props) {
  return (
    <VStack
      sx={{
        width: "100%",
        alignItems: "start",
        gap: 16
      }}
    >
      <FormControl>
        <FormLabel>Default form colour</FormLabel>
        <HStack>
          {colors.map(color => (
            <TileColor
              onClick={() => setFormColor(color)}
              key={color}
              color={color}
              active={color === selectedColor}
            />
          ))}
        </HStack>
      </FormControl>

      <FormControl>
        <ProcessOwners
          processOwners={owners}
          handleProcessOwners={handleFormOwners}
          form={true}
        />
      </FormControl>
    </VStack>
  );
}
