import React from "react";

export default function InsertFieldBelow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19V9"
        stroke="#1581BA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14H7"
        stroke="#1581BA"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="6" y="4" width="12" height="2" rx="1" fill="#C4C4C4" />
    </svg>
  );
}
