// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import { getCurrentUserId } from "src/reducers";
import User from "src/containers/user/ImageLabel/Medium";

import type { AppState, UID } from "src/types";

type Props = {
  currentUser: UID,
  title: string
};

const DirectChat = ({ title, currentUser }: Props) => {
  const users = (title || "").split(",") || [];
  const uid = R.head(R.reject(R.equals(currentUser), users) || []);

  if (users.length === 1) {
    return <User uid={users[0]} />;
  }

  if (uid) {
    return <User uid={uid} />;
  }

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps)(DirectChat);
