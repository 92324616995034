// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 0.4;
}

.Highlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(255, 226, 143, 1);
  transition: background 0.3s;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 0.625rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
  background: #c0392b;
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/Highlight.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,kCAAkC;EAClC,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,2CAA2C;EAC3C,YAAY;EACZ,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".Highlight {\n  position: absolute;\n}\n\n.Highlight__emoji {\n  position: absolute;\n  color: black;\n  opacity: 1;\n  font-size: 28px;\n}\n\n.Highlight__parts {\n  opacity: 0.4;\n}\n\n.Highlight__part {\n  cursor: pointer;\n  position: absolute;\n  background: rgba(255, 226, 143, 1);\n  transition: background 0.3s;\n}\n\n.Highlight__popup {\n  background-color: #3d464d;\n  border: 1px solid rgba(255, 255, 255, 0.25);\n  color: white;\n  padding: 0.5rem 0.625rem;\n  border-radius: 3px;\n  max-width: 300px;\n  max-height: 100px;\n  overflow-y: scroll;\n}\n\n.Highlight--scrolledTo .Highlight__part {\n  background: #c0392b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
