// @flow

import React from "react";
import type { OrgCloneWizardFieldData } from "src/types";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
  Box,
  useBoolean,
  Tooltip,
  Stack
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import PasswordStrengthMeter from "src/components/auth/PasswordStrengthMeter";
import * as styles from "./SignUp.styles";

type Props = {
  firstName: OrgCloneWizardFieldData,
  lastName: OrgCloneWizardFieldData,
  password: OrgCloneWizardFieldData,
  confirmPassword: OrgCloneWizardFieldData,
  onChange: Function
};

const tooltip = (
  <Stack spacing={0}>
    <span>At least 8 characters</span>
    <span>A mixture of letters and numbers.</span>
    <span>Inclusion of at least one uppercase alphabet</span>
  </Stack>
);

export default function SignUp({
  firstName,
  lastName,
  password,
  confirmPassword,
  onChange
}: Props) {
  const [
    isStrengthMeterVisible,
    { on: showStrengthMeter, off: hideStrengthMeter }
  ] = useBoolean(false);

  const handleDataChange = path => e => {
    onChange(path)(e.target.value);
  };

  const arePasswordsUnequal =
    password.value.length > 0 &&
    confirmPassword.value.length > 0 &&
    password.value !== confirmPassword.value;

  return (
    <VStack sx={styles.container}>
      <HStack sx={styles.fieldsRow}>
        <FormControl
          isInvalid={password.error}
          isRequired
          sx={styles.formControl}
        >
          <HStack sx={styles.labelContainer}>
            <FormLabel>Password</FormLabel>

            <Tooltip label={tooltip} hasArrow placement="right">
              <InfoIcon color="gray.500" />
            </Tooltip>
          </HStack>

          <Input
            type="password"
            value={password.value}
            onChange={handleDataChange(["password"])}
            onFocus={showStrengthMeter}
            onBlur={hideStrengthMeter}
          />

          <Box sx={styles.strengthMeterContainer}>
            {isStrengthMeterVisible && (
              <PasswordStrengthMeter password={password.value} />
            )}
          </Box>

          <FormErrorMessage>{password.error}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={confirmPassword.error || arePasswordsUnequal}
          isRequired
          sx={styles.formControl}
        >
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            value={confirmPassword.value}
            onChange={handleDataChange(["confirmPassword"])}
          />
          <FormErrorMessage>
            {confirmPassword.error ||
              (arePasswordsUnequal && "Passwords do not match")}
          </FormErrorMessage>
        </FormControl>
      </HStack>

      <HStack sx={styles.fieldsRow}>
        <FormControl
          isInvalid={firstName.error}
          isRequired
          sx={styles.formControl}
        >
          <FormLabel>First Name</FormLabel>
          <Input
            value={firstName.value}
            onChange={handleDataChange(["firstName"])}
          />
          <FormErrorMessage>{firstName.error}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={lastName.error} sx={styles.formControl}>
          <FormLabel>Last Name</FormLabel>
          <Input
            value={lastName.value}
            onChange={handleDataChange(["lastName"])}
            autoComplete="none"
          />
          <FormErrorMessage>{lastName.error}</FormErrorMessage>
        </FormControl>
      </HStack>
    </VStack>
  );
}
