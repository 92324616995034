// @flow

import { ifProp } from "styled-tools";
import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const Link = styled.div`
  text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
`;

const Title = styled.div`
  text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};

  > a {
    color: ${ifProp({ disableLink: true }, colors.primary, colors.active)};
  }
`;

const ImageLabel = styled.span`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
`;

const ImageLabelRemove = styled.div`
  display: inline-flex;
  justify-content: space-between;
  background: ${colors.grey1};
  height: 2em;
  > span {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15em;
    padding: ${spacing.space_xs} ${spacing.space_m};
  }
  > button {
    border: none;
    background: ${colors.grey1};
    padding: ${spacing.space_list_m};
    padding-right: ${spacing.space_xs};
    outline: none;
    cursor: pointer;
  }
`;

const NoTitle = styled.span`
  font-style: oblique;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props => props.width && `max-width: ${props.width};`}
  ${props => props.minWidth && `min-width: ${props.minWidth};`}
`;

export const NoTitleNotification = styled(NoTitle)`
  display: inline;
`;

const DirectChat = styled.span`
  > span {
    font-weight: normal;
    font-size: 13px;
  }
`;

const Private = styled.span`
  color: ${colors.secondary};
  cursor: text;
`;

export {
  Link,
  Title,
  ImageLabel,
  Name,
  ImageLabelRemove,
  NoTitle,
  DirectChat,
  Private
};
