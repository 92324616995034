// @flow

import { connect } from "react-redux";
import React, { useState, useRef } from "react";

import List from "./List";
import { Groups as StyledGroups } from "./styles";
import { RelativeBox } from "src/styles/box";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter
};

const Groups = ({ filter }: Props) => {
  const [dropdown, showDropdown] = useState(false);
  const outerRef = useRef(null);

  const group = (filter || {}).group || [];

  return (
    <RelativeBox>
      <StyledGroups
        ref={outerRef}
        active={dropdown || group.length > 0}
        onClick={showDropdown}
      >
        Groups
      </StyledGroups>
      {dropdown ? (
        <List outerRef={outerRef} handleClose={() => showDropdown(false)} />
      ) : null}
    </RelativeBox>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps)(Groups);
