// @flow
import { ME_FILTER } from "src/constants/users";

type Filter = {
  [columnId: string]: Array<string | number>
};

export const encodeColumnId = (filters: Filter): Filter => {
  const encodedFilters = {};
  for (const [key, value] of Object.entries(filters || {})) {
    if (key.includes("-")) {
      // Replacing because dashes in property names of embedded field filters
      // were being removed when saving in firestore
      const replaceWithArrow = key.split("-").join(">");
      encodedFilters[replaceWithArrow] = value;
    } else {
      encodedFilters[key] = value;
    }
  }

  return encodedFilters;
};

export const decodeColumnId = (
  filters: Filter,
  currentUserUid?: string
): Filter => {
  const decodedFilters = {};
  for (const [key, value] of Object.entries(filters || {})) {
    let targetIndex = -1;

    if (Array.isArray(value)) {
      targetIndex = findMeIndex(filters[key]);
    }

    if (key.includes(">")) {
      // Replacing because embedded field filters property names are stored
      // with arrow in firestore and the table implementation in manage view
      // uses dashes
      const replaceWithDash = key.split(">").join("-");
      decodedFilters[replaceWithDash] = value;
      if (targetIndex !== -1) {
        decodedFilters[replaceWithDash][targetIndex] = `me-${
          currentUserUid ?? ""
        }`;
      }
    } else {
      decodedFilters[key] = value;
      if (targetIndex !== -1) {
        decodedFilters[key][targetIndex] = `me-${currentUserUid ?? ""}`;
      }
    }
  }
  return decodedFilters;
};

/**
 * Convert values for me filter (change current-use to me-uid)
 * @param {string[] | number[]} filterValues - values to filter
 * @return {number} - the target index of the element whose value is "current-use"
 */
export const findMeIndex = (filterValues: Array<string | number>) => {
  let targetIndex = -1;

  targetIndex = filterValues.findIndex(item => {
    if (typeof item === "string") {
      return item === ME_FILTER;
    }
  });

  return targetIndex;
};

/**
 * Convert values for me filter (change me-uid to current-use)
 * @param {string[] | number[]} filterValues - values to filter
 * @return {number} - the target index of the element that contains "me-"
 */
export const findMePrefixIndex = (filterValues: Array<string | number>) => {
  let targetIndex = -1;

  targetIndex = filterValues.findIndex(item => {
    if (typeof item === "string") {
      return item.includes("me-");
    }
  });

  return targetIndex;
};
