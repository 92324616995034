// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { Button as StyledButton } from "src/styles/buttons";

const RequestAccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  > div {
    padding: 1em 6em;
    width: 30em;
    min-height: 10em;
    border-radius: ${radius.large};
    background-color: ${colors.brightLight};
    display: flex;
    flex-direction: column;
    align-items: center;
    > h4 {
      margin: 0px;
      margin-top: 10px;
    }
  }
`;

const Button = styled(StyledButton)`
  font-size: 15px;
  margin-top: 0.4em;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: ${radius.medium};
  height: 3em;
  width: 14em;
  margin-bottom: 1.5em;
  :disabled {
    background-color: ${colors.grey18};
    color: ${colors.brightLight};
  }
`;

export { RequestAccess, Button };
