// @flow

import React from "react";
import { connect } from "react-redux";
import usePortal from "react-useportal";
import { ErrorModal, Overlays, Header, ErrorBody } from "./styles";
import Icons from "src/icons";
import type { AppState } from "src/types";

import { closeDownloadPreventedModal } from "src/actions/file";

type Props = {
  closeModal: Function,
  isDownloadBlocked: boolean
};

const DownloadPreventedModal = ({ closeModal, isDownloadBlocked }: Props) => {
  const { Portal } = usePortal();

  const close = () => {
    closeModal();
  };

  if (!isDownloadBlocked) {
    return <></>;
  }

  return (
    <>
      <Portal>
        <Overlays onClick={close}>
          <ErrorModal>
            <Header>
              <h2>Download prohibited</h2>
              <Icons type="close" />
            </Header>
            <ErrorBody>
              <Icons type="warning" />
              Downloading files is prohibited by the admin.
            </ErrorBody>
          </ErrorModal>
        </Overlays>
      </Portal>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isDownloadBlocked: app.files.downloadBlocked
});

export default connect(mapStateToProps, {
  closeModal: closeDownloadPreventedModal
})(DownloadPreventedModal);
