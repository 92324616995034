// @flow

import { connect } from "react-redux";

import Upload from "src/components/chatroom/Upload";
import { uploadFileToChatroom } from "src/actions/file";
import { uploadTempAttachment } from "src/actions/email";
import { getCurrentRoomId, getIsChatroomUploadingFile } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  roomId: getCurrentRoomId(app),
  isUploading: getIsChatroomUploadingFile(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps, {
  uploadFileToChatroom,
  uploadTempAttachment
})(Upload);
