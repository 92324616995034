// @flow

import React from "react";

const Sort = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill="#888" d="M7 10l5-5 5 5H7zM7 14l5 5 5-5H7z" />
  </svg>
);

export default Sort;
