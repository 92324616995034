// @flow

import { delay, select, call, put, takeEvery } from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";
import * as api from "src/api/oAuth";
import { getLocation } from "src/selectors";
import { toast } from "react-toastify";

function* authorizeOAuth(): any {
  try {
    const location = yield select(getLocation);

    const { appUrl } = yield call(
      api.grantOAuthAppPermisssions,
      location.query
    );

    if (window.opener) {
      const bc = new BroadcastChannel(window.name);
      bc.postMessage({
        success: true,
        app: location.query.app
      });
      bc.close();
    }

    toast.success(
      "The integration has been completed! You will be redirected to Unifize shortly"
    );
    yield delay(1000);

    if (appUrl) {
      window.location.replace(appUrl);
    } else {
      window.location.replace("/");
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.OAUTH_APP_GRANT_PERMISSIONS_FAILURE,
      payload: { error: error.message }
    });
  }
}

function* watchAuthorizeOAuth(): any {
  yield takeEvery(atypes.OAUTH_APP_GRANT_PERMISSIONS_REQUEST, authorizeOAuth);
}

export default [watchAuthorizeOAuth()];
