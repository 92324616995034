// @flow

import * as colors from "src/styles/constants/colors";
import {
  IconButtonProps,
  StackProps,
  GridProps,
  GridItemProps,
  ButtonProps
} from "@chakra-ui/react";

export const commentIcon: IconButtonProps = {
  size: "xs",
  bg: "transparent",
  _hover: {
    bg: "transparent"
  },
  _focus: {
    boxShadow: "none"
  }
};

export const container: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const actionBar: GridProps = {
  my: 1,
  height: "24px",
  width: "100%",
  templateColumns: "repeat(5, 1fr)",
  gap: 1
};

export const actionBarItem: GridItemProps = {
  width: "100%",
  display: "flex"
};

export const viewingNow: GridItemProps = {
  colSpan: 2,
  justifyContent: "flex-start"
};

export const commentButton: GridItemProps = {
  colSpan: 1,
  justifyContent: "center"
};

export const makeCurrent: GridItemProps = {
  colSpan: 2,
  justifyContent: "flex-end"
};

export const updateCurrentVersionConfirmationModal = {
  header: {
    fontWeight: 700,
    fontSize: "lg"
  },
  bodyText: {
    my: 0
  },
  actionBar: {
    spacing: 4
  }
};

export const OwnerContainer = {
  _hover: {
    background: colors.grey35
  },
  borderRadius: "4px"
};

export const CurrentRevision: ButtonProps = {
  cursor: "text",
  border: "none",
  borderRadius: "4px"
};

export const MakeCurrentButton: ButtonProps = {
  _hover: {
    background: colors.grey35
  },
  borderRadius: "4px"
};
