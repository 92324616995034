// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Priority as StyledPriority,
  Separator
} from "./styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import {
  setManageViewFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import {
  getChecklistFieldsById,
  getAllRecords,
  getAllWorkflows
} from "src/reducers";
import Checkbox from "src/components/Checkbox";
import { Capitalize } from "src/styles";
import Icon from "src/icons";

type Props = {
  parentRef: any,
  _setManageViewFilter: Function,
  handleClose: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<string>
};

const column = "priority";

const Priority = ({
  parentRef,
  _setManageViewFilter,
  handleClose,
  setFilter,
  filter,
  toggleSortBy
}: Props) => {
  const dispatch = useDispatch();
  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  let workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };

  const closeModal = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]
  );

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }

    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: string | number) => {
      event.preventDefault();
      event.stopPropagation();

      const webWorker = new WebWorker(worker);

      if (R.includes(item, filter || [])) {
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });
        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: R.reject(R.equals(item), filter)
          }
        };

        webWorker.postMessage(workerData);

        setFilter(column, R.reject(R.equals(item), filter));
      } else {
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });

        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: [...(filter || []), item]
          }
        };

        webWorker.postMessage(workerData);

        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter, _setManageViewFilter]
  );

  const values = ["low", "normal", "high", "critical"];

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>Filter</SubHeading>
      <ul>
        {values.map(value => (
          <StyledPriority
            tabIndex="0"
            role="button"
            key={value}
            onClick={event => handleSelect(event, value)}
          >
            <Checkbox
              id={`priority${value}`}
              checked={R.includes(value, filter || [])}
              handleChange={event => handleSelect(event, value)}
            />
            <Icon type={value} />
            <Capitalize>{value}</Capitalize>
          </StyledPriority>
        ))}
      </ul>
    </StyledFilter>
  );
};

export default connect(null, {
  _setManageViewFilter: setManageViewFilter
})(Priority);
