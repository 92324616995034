// @flow

import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import ReactProgressiveList from "react-progressive-list";

import DayDifference from "./DayDifference";

import {
  ForwardModalContainer,
  ForwardModalHeader,
  ForwardModalBody,
  SearchContainer,
  SearchIcon,
  SearchInput,
  ChatroomList,
  ChatroomRow,
  RowInfo,
  RowStatus,
  BreadCrumbsWrapper,
  SelectCircle,
  ForwardModalFooter,
  SelectionHighlight
} from "./styles";

import Icon from "src/icons";
import ButtonLoader from "src/components/ButtonLoader";
import Title from "src/containers/chatroom/Title/Small";
import BreadCrumbs from "src/containers/BreadCrumbs";
import { Centered as StyledModal } from "src/styles/modal";
import { iconSize } from "src/styles/constants";
import { searchChatroom, resetSearch } from "src/actions/chatroom";
import { hideForwardModal } from "src/actions/modal";
import { selectRoom, deselectRoom, forwardMessage } from "src/actions/chatmenu";
import { getSelectedRoomId } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  chatrooms: Array<number>,
  selectedRooms: Array<RoomId>,
  currentRoom: RoomId,
  hideModal: Function,
  select: Function,
  deselect: Function,
  forwardMessages: Function,
  _searchChatroom: Function,
  _resetSearch: Function
};

const ForwardModal = ({
  chatrooms,
  selectedRooms,
  currentRoom,
  hideModal,
  select,
  deselect,
  forwardMessages,
  _searchChatroom,
  _resetSearch
}: Props) => {
  const [isLoading: boolean, setIsLoading: Function] = useState(false);

  useEffect(() => {
    _resetSearch();
  }, []);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      forwardMessages(currentRoom);
    },
    [forwardMessages]
  );

  const renderRow = useCallback(id => {
    const rowIsSelected = selectedRooms.includes(chatrooms[id]);
    if (chatrooms[id]) {
      return (
        <ChatroomRow key={id} role="listitem">
          <RowInfo>
            <Title roomId={chatrooms[id]} />
            <BreadCrumbsWrapper>
              <BreadCrumbs roomId={chatrooms[id]} />
            </BreadCrumbsWrapper>
          </RowInfo>
          <RowStatus>
            <DayDifference id={chatrooms[id]} />
            <SelectCircle>
              <Icon
                type="checkmark"
                highlight={rowIsSelected}
                id={chatrooms[id]}
                select={select}
                deselect={deselect}
                cursor="pointer"
              />
            </SelectCircle>
          </RowStatus>
          <SelectionHighlight
            highlight={rowIsSelected}
            onClick={() =>
              rowIsSelected ? deselect(chatrooms[id]) : select(chatrooms[id])
            }
          />
        </ChatroomRow>
      );
    }
    return null;
  });

  useEffect(() => {
    _searchChatroom("", { reverse: true });
  }, []);

  const handleSearch = useCallback((e: any) => {
    const { value } = e.target;
    _searchChatroom(value, { reverse: true });
  });

  return (
    <StyledModal id="forward-modal">
      <Modal
        isOpen
        parentSelector={() => document.querySelector("#forward-modal")}
        onRequestClose={hideModal}
      >
        <ForwardModalContainer
          onClick={e => e.stopPropagation()}
          onSubmit={handleSubmit}
        >
          <ForwardModalHeader>
            Forward to
            <Icon
              type="close"
              size={iconSize.small}
              handleClick={hideModal}
              cursor="pointer"
            />
          </ForwardModalHeader>
          <ForwardModalBody>
            <SearchContainer>
              <SearchInput
                type="text"
                placeholder="Search for anything"
                onChange={handleSearch}
                autoFocus
              />
              <SearchIcon>
                <Icon type="search" />
              </SearchIcon>
            </SearchContainer>
            <ChatroomList>
              <ReactProgressiveList
                initialAmount={20}
                role="list"
                renderItem={renderRow}
                renderLoader={() => <div />}
                rowCount={chatrooms.length}
              />
            </ChatroomList>
          </ForwardModalBody>
          <ForwardModalFooter>
            <ButtonLoader
              isLoading={isLoading}
              type="submit"
              disabled={!selectedRooms.length}
            >
              Forward
            </ButtonLoader>
          </ForwardModalFooter>
        </ForwardModalContainer>
      </Modal>
    </StyledModal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  chatrooms: app.chatRooms.searchResults,
  selectedRooms: app.chatRooms.selectedRooms,
  currentRoom: getSelectedRoomId(app)
});

export default connect(mapStateToProps, {
  hideModal: hideForwardModal,
  select: selectRoom,
  deselect: deselectRoom,
  forwardMessages: forwardMessage,
  _searchChatroom: searchChatroom,
  _resetSearch: resetSearch
})(ForwardModal);
