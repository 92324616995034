// @flow

import React from "react";

type Props = {
  width?: number,
  height?: number
};

const Me = ({ width = 22, height = 22 }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 18H6.5C5.948 18 5.5 17.552 5.5 17V16.5C5.5 13.467 7.967 11 11 11C14.033 11 16.5 13.467 16.5 16.5V17C16.5 17.552 16.052 18 15.5 18Z"
      fill="#939393"
    />
    <path
      d="M11 10C9.07 10 7.5 8.43 7.5 6.5C7.5 4.57 9.07 3 11 3C12.93 3 14.5 4.57 14.5 6.5C14.5 8.43 12.93 10 11 10Z"
      fill="#939393"
    />
    <path
      d="M1 8C0.448 8 0 7.552 0 7V3C0 1.346 1.346 0 3 0H7C7.552 0 8 0.448 8 1C8 1.552 7.552 2 7 2H3C2.449 2 2 2.449 2 3V7C2 7.552 1.552 8 1 8Z"
      fill="#939393"
    />
    <path
      d="M21 8C20.448 8 20 7.552 20 7V3C20 2.449 19.551 2 19 2H15C14.448 2 14 1.552 14 1C14 0.448 14.448 0 15 0H19C20.654 0 22 1.346 22 3V7C22 7.552 21.552 8 21 8Z"
      fill="#939393"
    />
    <path
      d="M19 22H15C14.448 22 14 21.552 14 21C14 20.448 14.448 20 15 20H19C19.551 20 20 19.551 20 19V15C20 14.448 20.448 14 21 14C21.552 14 22 14.448 22 15V19C22 20.654 20.654 22 19 22Z"
      fill="#939393"
    />
    <path
      d="M7 22H3C1.346 22 0 20.654 0 19V15C0 14.448 0.448 14 1 14C1.552 14 2 14.448 2 15V19C2 19.551 2.449 20 3 20H7C7.552 20 8 20.448 8 21C8 21.552 7.552 22 7 22Z"
      fill="#939393"
    />
  </svg>
);

export default Me;
