// @flow

import React from "react";
import { useSelector } from "react-redux";

import type { RoomId, FieldId } from "src/types";
import { FieldWrapper } from "./styles";
import useFields from "../useFields";
import {
  getSelectedChecklist,
  getChecklistValue,
  getChecklistFieldDetails
} from "src/reducers";

import Single from "./Single";
import Multi from "./Multi";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId?: ?number,
  fromManageView?: ?boolean,
  roomFieldFormId?: string,
  templateId?: number
};

function LinkedField({
  roomId,
  fieldId,
  formId,
  roomFieldFormId,
  fromManageView
}: Props) {
  const { roomId: editRoomId } = useSelector(({ app }) =>
    getSelectedChecklist(app)
  );

  // Use the roomID of the parent field not the room that's being linked
  const originalRoomId = (fromManageView ? editRoomId : roomId).toString();

  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const { settings } = useFields({
    checklistValue,
    details
  });

  const { multiple } = settings;

  return (
    <FieldWrapper>
      {multiple ? (
        <Multi
          roomId={originalRoomId}
          fieldId={fieldId}
          formId={formId}
          fromManageView={fromManageView}
          roomFieldFormId={roomFieldFormId}
        />
      ) : (
        <Single
          roomId={originalRoomId}
          fieldId={fieldId}
          formId={formId}
          fromManageView={fromManageView}
          roomFieldFormId={roomFieldFormId}
        />
      )}
    </FieldWrapper>
  );
}

export default LinkedField;
