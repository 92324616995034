// @flow

import * as React from "react";

type Props = {
  fill?: string
};

const Archive = ({ fill = "#2F80ED", ...restProps }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.7 1.48L10.7733 0.36C10.5867 0.14 10.3133 0 10 0H2C1.68667 0 1.41333 0.14 1.23333 0.366667L0.306667 1.48C0.113333 1.71333 0 2.00667 0 2.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V2.33333C12 2.00667 11.8867 1.71333 11.7 1.48ZM6 4.33333L9.66667 8H7.33333V9.33333H4.66667V8H2.33333L6 4.33333ZM1.41333 1.33333L1.96 0.666667H9.96L10.58 1.33333H1.41333Z"
        fill={fill}
      />
    </svg>
  );
};

export default Archive;
