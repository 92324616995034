// @flow

import React from "react";
import Modal from "react-modal";

import { NewContacts as StyledNewContacts } from "./styles";
import Invite from "src/containers/chatroom/Invite";

type Props = {
  show: boolean,
  handleClose: Function
};

const New = ({ show, handleClose }: Props) => (
  <StyledNewContacts id="add-contacts">
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      parentSelector={() => document.querySelector("#add-contacts")}
    >
      <Invite handleClose={handleClose} />
    </Modal>
  </StyledNewContacts>
);

export default New;
