// @flow

import React from "react";
import { connect } from "react-redux";

import type { State, NonOrgParticipant } from "src/types";
import { getRoleTitles } from "src/reducers";

import InvitationModal from "src/components/InvitationModal";

type Props = {
  open: boolean,
  roles: Array<{ id: number, title: string, label: string }>,
  participants: NonOrgParticipant[],
  inProgress: boolean,
  onBack: Function,
  onClose: Function,
  setParticipantRole: Function,
  sendWithInvites: Function
};

const ChatroomInvitationModal = ({ sendWithInvites, ...restProps }: Props) => {
  return (
    <InvitationModal
      {...restProps}
      title="Select role for new participants"
      okButton={{
        label: "Send Invites",
        action: sendWithInvites
      }}
    />
  );
};

const mapStateToProps = ({ app }: State) => ({
  roles: getRoleTitles(app)
});

export default connect(mapStateToProps, null)(ChatroomInvitationModal);
