// @flow

import type { StackProps, InputProps } from "@chakra-ui/react";
import { bottom } from "src/styles/constants/shadow";
import { medium } from "src/styles/constants/radius";

export const DropdownMenuWrapper: StackProps = {
  alignItems: "stretch",
  position: "relative",
  backgroundColor: "white",
  boxShadow: bottom,
  width: "full",
  zIndex: "100"
};

export const DropdownInputField: InputProps = {
  width: "auto",
  borderRadius: medium,
  p: "0.5rem",
  m: "0.5rem"
};
