// @flow

import styled from "@emotion/styled";

import { ScrollableBox, ListItemMedium } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import {
  FlexProps,
  TextProps,
  ListProps,
  SystemCSSProperties
} from "@chakra-ui/react";

export const Select = styled.div`
  width: 100%;
  border-radius: ${radius.small};
  min-width: 232px;
  padding: 0;
  background: ${props => (props.disable ? colors.grey2 : colors.brightLight)};
  ${props => props.disable && "cursor: not-allowed;"};
  border: 1px solid ${colors.grey4};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  :focus-within {
    border: 1px solid ${colors.primary};
  }
  > input {
    border: none !important;
  }

  > input,
  > button,
  > span {
    background: ${props =>
      props.disable ? colors.grey2 : colors.brightLight} !important;
    ${props => props.disable && "cursor: not-allowed;"};
  }

  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17em;
  }
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  width: 100%;
  top: ${props => (props.top ? props.top : "3em")};
  bottom: ${props => (props.bottom ? props.bottom : "auto")};
  left: 0;

  > ul {
    max-height: 15em;
  }
`;

export const ListItem = styled(ListItemMedium)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 97%;
  color: ${props => (props.disabled ? colors.secondary : colors.primary)};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const Value = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.brightLight};
  padding: ${spacing.space_inset_default};
  > svg {
    height: 1em;
    width: 1em;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
    }
  }
`;

export const Multiple = styled.span`
  font-size: 10px;
  padding-left: 10px;
  color: ${colors.secondary};
`;

export const ListItemBox: FlexProps = {
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0
};

export const SkeletonContainer: ListProps = {
  padding: 2,
  margin: 0,
  width: "100%"
};

export const DropdownContainer: ListProps = {
  padding: 0,
  margin: 0,
  width: "100%",
  maxHeight: "16em",
  overflow: "auto"
};

export const ListItemText: TextProps = {
  margin: 0,
  paddingLeft: 2
};

export const CloseIconStyle: SystemCSSProperties = {
  padding: 1,
  width: 4,
  height: 4
};
