// @flow

import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import DisplayComponent from "src/components/file/Display";
import { getFile, getTempFile } from "src/reducers";
import { getFile as resolveFile } from "src/actions/file";

import type { AppState, UnifizeFile } from "src/types";

type Props = {
  name: string,
  _getFile: Function,
  thumbnail: boolean,
  file: UnifizeFile,
  tempFile: {
    name: string,
    originalName: string,
    generation: string
  }
};

const Display = (props: Props) => {
  const { file, name, _getFile } = props;
  useEffect(() => {
    if (name && R.isEmpty(file || {})) {
      _getFile(name);
    }
  }, [name, file, _getFile]);

  return <DisplayComponent {...props} />;
};

const mapStateToProps = ({ app }: { app: AppState }, { temp, name }) => {
  if (temp) {
    return {
      tempFile: getTempFile(app, name)
    };
  }
  return {
    file: getFile(app, name)
  };
};

export default connect(mapStateToProps, { _getFile: resolveFile })(Display);
