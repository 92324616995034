// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import { Breadcrumbs as StyledBreadcrumbs, Trail, BackButton } from "./styles";
import Name from "src/containers/workflow/Name";
import { ButtonLink as Button } from "src/styles/buttons";
import * as atypes from "src/constants/actionTypes";
import { getInstanceFilter } from "src/reducers";
import Icon from "src/icons";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter
};

const Breadcrumbs = ({ filter }: Props) => {
  return (
    <StyledBreadcrumbs>
      <Trail active>
        <Link
          to={{
            type: atypes.SET_PROCESS_REQUEST
          }}
        >
          <BackButton>
            <Icon type="back" />
          </BackButton>
          <h4>Processes</h4>
        </Link>
      </Trail>
      <Trail>
        <h4>&gt;</h4>
      </Trail>
      <Trail>
        <h4>
          <Name id={filter.id} />
        </h4>
      </Trail>
      <Trail>
        <Link
          to={{
            type: atypes.EDIT_PROCESS_REQUEST,
            payload: { id: filter.id }
          }}
        >
          <Button type="button">
            <Icon type="edit" />
            Edit <Name id={filter.id} />
          </Button>
        </Link>
      </Trail>
    </StyledBreadcrumbs>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getInstanceFilter(app)
});

export default connect(mapStateToProps)(Breadcrumbs);
