// @flow

import React from "react";

import { EmailReply as StyledReply } from "../styles";
import EmailContent from "src/components/messages/Chat/Notification/FileOrEmail/EmailContent";

type Props = {
  message: any
};

const EmailReply = ({ message }: Props) => {
  return (
    <StyledReply>
      <EmailContent message={message} />
    </StyledReply>
  );
};

export default EmailReply;
