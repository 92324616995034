// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const showOverlay = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_OVERLAY:
      return true;
    case atypes.HIDE_OVERLAY:
      return false;
    default:
      return state;
  }
};

export default showOverlay;
