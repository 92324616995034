// @flow

import React from "react";

import type { Department } from "src/types";

type Props = {
  department: Department
};

const Name = ({ department }: Props) => <span>{department.name}</span>;

export default Name;
