// @flow
import * as R from "ramda";
import type { Editor } from "@tiptap/react";
import type { Mentions } from "src/types";

export const clearInput = (tiptapEditor: Editor) => {
  if (tiptapEditor && tiptapEditor.view.pluginViews.length) {
    tiptapEditor.commands.clearContent();
  }
};

const formatMentions = (mentions: Mentions[], oldMessage: string) => {
  // oldMessage is a string which is formatted as html
  // Mentions formatting doc - https://github.com/unifize/unifize/wiki/Mentions

  let replacedString = oldMessage;

  mentions.forEach(mention => {
    const mentionPattern = `${mention.display}`;
    let replacementPattern = `<@${mention.id}>`;

    if (
      mention.id == "!everyone" ||
      mention.id == "!owner" ||
      mention.id == "!creator" ||
      mention.id.startsWith("#") ||
      mention.id.startsWith("!team") ||
      mention.id.startsWith("!signatories")
    ) {
      replacementPattern = `<${mention.id}>`;
    }

    let startIndex = replacedString.indexOf(mentionPattern);

    while (startIndex !== -1) {
      const endIndex = startIndex + mentionPattern.length;
      replacedString =
        replacedString.slice(0, startIndex) +
        replacementPattern +
        replacedString.slice(endIndex);
      startIndex = replacedString.indexOf(
        mentionPattern,
        startIndex + replacementPattern.length
      );
    }
  });

  return replacedString;
};

export const getAllMentionsFromMessage = (
  editorContent: Array<{
    content?: Array<
      | {
          type: string,
          attrs: {
            mentionedPerson: Mentions
          }
        }
      | any
    >
  }>
) => {
  let allMentionsInMessage: Mentions[] = [];

  R.forEach(({ content = [] }) => {
    R.forEach(entry => {
      if (entry.type == "mention" && entry.attrs.mentionedPerson.id) {
        allMentionsInMessage.push(entry.attrs.mentionedPerson);
      }
    }, content);
  }, editorContent);

  allMentionsInMessage = R.uniqWith<Mentions>(
    (MENTION_A, MENTION_B) => MENTION_A.id == MENTION_B.id,
    allMentionsInMessage
  );

  return allMentionsInMessage;
};

export const formatMentionsInMessage = (
  tiptapEditorContent: Editor,
  oldMessage: string = ""
) => {
  // oldMessage is a string which is formatted as html
  let allMentionsInMessage = getAllMentionsFromMessage(tiptapEditorContent);
  let updatedMessage: string = formatMentions(allMentionsInMessage, oldMessage);
  return updatedMessage;
};

const formatMention = (mention: Mentions) => {
  let replacementPattern = `@[${mention.display}]<@${mention.id}>`;
  if (
    mention.id == "!everyone" ||
    mention.id == "!owner" ||
    mention.id == "!creator" ||
    mention.id.startsWith("#") ||
    mention.id.startsWith("!team") ||
    mention.id.startsWith("!signatories")
  ) {
    replacementPattern = `<${mention.id}>`;
  }
  return replacementPattern;
};

export const formatMentionsInPlainMessage = (
  tiptapEditorContent: Editor,
  oldMessage: string = ""
) => {
  let allMentionsInMessage = getAllMentionsFromMessage(tiptapEditorContent);
  let updatedMessage: string = "";

  let currentIndex = 0;
  while (currentIndex < oldMessage.length) {
    let mentionFound = false;
    for (const mention of allMentionsInMessage) {
      if (oldMessage.slice(currentIndex).startsWith(mention.display)) {
        mentionFound = true;
        const formattedMention = formatMention(mention);
        updatedMessage += `${formattedMention}`;
        currentIndex += mention.display.length;
        break;
      }
    }

    if (!mentionFound) {
      if (oldMessage[currentIndex] === "\n") {
        updatedMessage += "\n";
      } else {
        updatedMessage += oldMessage[currentIndex];
      }
      currentIndex++;
    }
  }

  return updatedMessage;
};
