// @flow

import React from "react";
import { HStack, Text } from "@chakra-ui/react";

import type { DownshiftProps } from "downshift";

import type { OnItemClick } from "src/components/Dropdown";

import * as styles from "./styles";

type Props = {|
  onItemClick?: OnItemClick,
  highlightedIndex?: number,
  index?: number,
  scrollIntoView?: DownshiftProps.scrollIntoView,
  item: Object
|};

const ProcessType = ({
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView,
  item
}: Props) => {
  return (
    <HStack
      sx={{
        ...((highlightedIndex !== null && highlightedIndex === index) ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveProcessType
          : styles.inActiveProcessType),
        ...styles.ProcessTypeContainer
      }}
      ref={highlightedIndex === index ? scrollIntoView : null}
      onClick={() => {
        onItemClick && onItemClick({ id: item });
      }}
    >
      <Text sx={styles.ProcessType} as="span">
        {item.title}
      </Text>

      {item.invocationCount && item.invocationCount > 0 && (
        <Text as="span" sx={styles.InvocationCount}>
          {item.invocationCount}
        </Text>
      )}
    </HStack>
  );
};

export default ProcessType;
