// @flow

import React from "react";

import { NewValue, ValueList } from "../styles";
import NameOrEmail from "src/containers/user/NameOrEmail";
import GroupMembersModal from "../GroupMembersModal";

import type { UsersAndGroups } from "src/types";

type Props = {
  newValue: Array<UsersAndGroups>
};

const NewUserValue = ({ newValue }: Props) => {
  return (
    <>
      {newValue.length > 0 && (
        <NewValue>
          <ValueList>
            {newValue.map(value => {
              if (value.type === "user")
                return <NameOrEmail uid={value.uid} key={value.uid} />;
              else return value.id && <GroupMembersModal id={value.id} />;
            })}
          </ValueList>
        </NewValue>
      )}
    </>
  );
};

export default NewUserValue;
