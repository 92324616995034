// @flow

import React from "react";

type Props = {
  width?: number | string,
  height?: number | string
};

const Excel = ({ width = 30, height = 28 }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0.5L0 3.5V24.5L18 27.5V24.5H28.5C29.328 24.5 30 23.828 30 23V5C30 4.172 29.328 3.5 28.5 3.5H18V0.5ZM15 4.04199V23.958L3 21.96V6.04004L15 4.04199ZM18 6.5H21V9.5H18V6.5ZM24 6.5H27V9.5H24V6.5ZM4.76367 8.44531L7.59082 13.9912L4.5 19.5547H7.10449L8.78906 15.9629C8.90156 15.6194 8.97715 15.3634 9.01465 15.1924H9.03809C9.09959 15.5494 9.1743 15.8032 9.2373 15.9307L10.9072 19.5518H13.5L10.5088 13.9502L13.415 8.44531H10.9834L9.43945 11.7412C9.28945 12.1687 9.18869 12.4979 9.1377 12.7109H9.09961C9.01261 12.3539 8.92643 12.0391 8.81543 11.7676L7.42969 8.44531H4.76367ZM18 12.5H21V15.5H18V12.5ZM24 12.5H27V15.5H24V12.5ZM18 18.5H21V21.5H18V18.5ZM24 18.5H27V21.5H24V18.5Z"
      fill="black"
    />
  </svg>
);

export default Excel;
