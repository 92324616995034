// @flow

import React, { Fragment } from "react";

import Invitation from "../Invitation";
import Users from "./Users";
import { SearchResult } from "src/styles/dropdown";
import autoPosition from "src/components/autoPosition";

import type { UID } from "src/types";

type Props = {
  text: string,
  focus: number,
  result: Array<UID>,
  handleSelect: Function,
  handleInvite: Function,
  noInvite: boolean,
  renderOptions?: ?Function
};

const Result = ({
  text,
  result,
  handleInvite,
  noInvite,
  renderOptions = null,
  focus,
  handleSelect
}: Props) => (
  <Fragment>
    {result.length > 0 ? (
      <SearchResult>
        <ul>
          <Users
            result={result}
            renderOptions={renderOptions}
            focus={focus}
            handleSelect={handleSelect}
          />
        </ul>
      </SearchResult>
    ) : null}
    {noInvite === false ? (
      <Invitation text={text} handleInvite={handleInvite} />
    ) : null}
  </Fragment>
);

export default autoPosition(Result, {
  bottomOffset: "36px",
  topOffset: "4px",
  position: "top",
  leftOffset: "-1px",
  rightOffset: "-3px"
});
