// @flow

import { connect } from "react-redux";
import React, { useRef, useEffect, useCallback } from "react";

import vectors from "./vectors";
import { OnBoarding as StyledOnBoarding, Button } from "./styles";
import { getUser } from "src/reducers";
import { resendInvite } from "src/actions/user";

import type { AppState, UnifizeUser, UID } from "src/types";

type Props = {
  uid: UID,
  currentStage: number,
  user: UnifizeUser,
  _resendInvite: Function
};

const getPixelRatio = context => {
  const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;

  return (window.devicePixelRatio || 1) / backingStore;
};

const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(" ");
  let line = "";
  for (let n = 0; n < words.length; n++) {
    let testLine = line + words[n] + " ";
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;
    if (testWidth > maxWidth) {
      context.fillText(line, x, y);
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
};

const OnBoarding = ({ currentStage, user, uid, _resendInvite }: Props) => {
  const ref = useRef();

  const draw = useCallback(() => {
    let canvas = ref.current;
    // $FlowFixMe
    let c = canvas.getContext("2d");
    let ratio = getPixelRatio(c);

    // $FlowFixMe
    let width = getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);
    // $FlowFixMe
    let height = getComputedStyle(canvas)
      .getPropertyValue("height")
      .slice(0, -2);

    // Used to prevent blurring of items in the canvas
    // $FlowFixMe
    canvas.width = width * ratio; // $FlowFixMe
    canvas.height = height * ratio; // $FlowFixMe
    canvas.style.width = `${width}px`; // $FlowFixMe
    canvas.style.height = `${height}px`;

    // Stage 0,1,2,3, 4.1 and 4.2
    vectors.forEach((data, stage) => {
      const { x1, x2, y1, y2, xtext, ytext, stop, text } = data;
      if (currentStage > stage) {
        c.strokeStyle = "#16c98d";
        c.fillStyle = "#16c98d";
      } else if (currentStage === stage) {
        c.strokeStyle = "#1581ba";
        c.fillStyle = "#1581ba";
      } else {
        c.strokeStyle = "#eee";
        c.fillStyle = "#eee";
      }

      c.beginPath();
      c.lineWidth = 4;
      c.moveTo(x1, y1);
      c.lineTo(x2, y2);
      c.stroke();

      if (stop) {
        if (currentStage > stage) {
          c.strokeStyle = "#16c98d";
          c.fillStyle = "#16c98d";
        } else if (currentStage === stage) {
          c.strokeStyle = "#1581ba";
          c.fillStyle = "#1581ba";
        } else {
          c.strokeStyle = "#c4c4c4";
          c.fillStyle = "#c4c4c4";
        }

        c.beginPath();
        c.lineWidth = 1;
        c.arc(x2, y2, 8, 0, Math.PI * 2, false);
        c.fill();
        c.stroke();

        c.font = "14px Verdana";
        c.textAlign = "center";
        wrapText(c, text, xtext, ytext, 170, 20);

        if (currentStage > stage) {
          c.fillStyle = "rgba(22,201,141,0.5)";
          c.strokeStyle = "rgba(22,201,141,0.5)";
        } else if (currentStage === stage) {
          c.fillStyle = "rgba(21,129,186,0.5)";
          c.strokeStyle = "rgba(21,129,186,0.5)";
        } else {
          c.fillStyle = "rgb(196,196,196,0.4)";
          c.strokeStyle = "rgb(196,196,196,0.4)";
        }

        c.beginPath();
        c.lineWidth = 1;
        c.arc(x2, y2, 13, 0, Math.PI * 2, false);
        c.fill();
        c.stroke();
      }
    });
  }, [currentStage]);

  useEffect(() => {
    draw();
  }, [draw]);

  return (
    <>
      <StyledOnBoarding ref={ref}>
        <h5>Reset Password</h5>
      </StyledOnBoarding>
      {(user || {}).invitePending ? (
        <Button type="button" onClick={() => _resendInvite(uid)}>
          Resend Invite
        </Button>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid),
  currentStage: app.users.onboardingStatus
});

export default connect(mapStateToProps, {
  _resendInvite: resendInvite
})(OnBoarding);
