// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { Dot1, Dot2, Dot3 } from "src/components/ButtonLoader/styles";

export const MenuBar = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;
  align-items: center;
  > button {
    outline: none;
    border: none;
    background-color: ${colors.brightLight};
  }
`;

export const Body = styled.div`
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 2em;
  max-height: 85vh;
  overflow: auto;
`;

export const AddChart = styled.button`
  outline: none;
  border: 2px solid ${colors.highlight};
  min-width: 400px;
  min-height: 450px;
  background-color: ${colors.highlight};
  border-radius: 10px;
  color: ${colors.blue};
  font-size: 1.25rem;
  font-weight: bold;
  :hover,
  :focus {
    border: 2px solid ${colors.blue};
  }
  :disabled {
    cursor: not-allowed;
  }
  ${Dot1},
  ${Dot2},
  ${Dot3} {
    background-color: ${colors.blue};
  }
`;

export const Chart = styled.div`
  outline: none;
  border: 2px solid ${colors.highlight};
  min-width: 400px;
  height: 450px;
  border-radius: 10px;
  :hover,
  :focus {
    border: 2px solid ${colors.selected};
    ${MenuBar} {
      display: flex;
    }
  }
`;

export const Heading = styled.h3`
  text-align: left;
  position: relative;
  margin: 0;
  padding-top: ${spacing.space_m};
  padding-left: ${spacing.space_l};
`;

export const Loader = styled.div`
  position: relative;
  > img {
    display: block;
    margin: 2em auto;
    width: 80%;
  }

  > h3 {
    position: absolute;
    top: 50%;
    left: 45%;
  }
`;

export const H6: SystemCSSProperties = {
  position: "absolute",
  fontSize: "1.5rem",
  top: "35%",
  left: "35%",
  my: 0
};

export const Image: SystemCSSProperties = {
  display: "block",
  margin: "2rem auto",
  width: "90%"
};
