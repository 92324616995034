// @flow

import { rgba } from "polished";

import * as colors from "src/styles/constants/colors";

export const PENDING = -1;
export const COMPLETED = -2;
export const CANCELED = -3;
export const ACCEPTED = -4;
export const REJECTED = -5;

export const OVERDUE = -6;

export const NEW = "new";
export const DRAFT = "draft";
export const ACTIVE = "ACTIVE";

const defaultStatus = Object.freeze({
  [-1]: {
    text: "PENDING",
    color: rgba(colors.secondary, 0.2),
    textColor: colors.primary
  },
  [-2]: {
    text: "COMPLETED",
    color: rgba(colors.success, 0.2),
    textColor: colors.success
  },
  [-3]: {
    text: "CANCELLED",
    color: colors.secondary,
    textColor: colors.brightLight
  },
  [-4]: {
    text: "ACCEPTED",
    color: rgba(colors.success, 0.2),
    textColor: colors.success
  },
  [-5]: {
    text: "REJECTED",
    color: rgba(colors.danger, 0.2),
    textColor: colors.danger
  }
});

export const pendingAndCompleteIds = [PENDING, COMPLETED];

export const defaultStatusConfig = {
  color: rgba(colors.secondary, 0.2),
  textColor: colors.primary
};

export default defaultStatus;
