// @flow

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { Cell, EditIconWrapper } from "../styles";
import {
  NoValue,
  SinglePicklistWrapper,
  MultiContainer,
  StyledMultiField
} from "./styles";
import MultiSelect from "./MultiSelect";
import Icon from "src/icons";
import { getFieldWidth } from "src/reducers";
import type { ColumnId } from "src/types";

type Props = {
  autoNo: string,
  columnId: ColumnId,
  selected: boolean,
  multiple: boolean,
  value: any,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  embeddedIndex?: number
};

const Select = ({
  autoNo,
  columnId,
  multiple,
  selected,
  value,
  openChecklistEditModal,
  index,
  fieldId,
  roomId,
  hideEdit,
  embeddedIndex
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  if (!value) {
    return (
      <MultiContainer
        selected={selected}
        width={fieldWidth}
        borderRequired={true}
      >
        <StyledMultiField>
          <SinglePicklistWrapper>
            <NoValue></NoValue>
          </SinglePicklistWrapper>
          {!hideEdit && (
            <EditIconWrapper
              onClick={e =>
                openChecklistEditModal(
                  e,
                  index,
                  fieldId,
                  roomId,
                  value,
                  columnId,
                  embeddedIndex
                )
              }
            >
              <Icon type="editPencil" />
            </EditIconWrapper>
          )}
        </StyledMultiField>
      </MultiContainer>
    );
  }

  if (
    (value && (multiple || Array.isArray(value[0]))) ||
    (columnId && columnId.includes("-"))
  ) {
    return (
      <MultiSelect
        multiple={multiple}
        columnId={columnId}
        autoNo={autoNo}
        selected={selected}
        value={!Array.isArray(value[0]) ? [value] : value}
        openChecklistEditModal={openChecklistEditModal}
        rowIndex={index}
        fieldId={fieldId}
        roomId={roomId}
        hideEdit={hideEdit}
        embeddedIndex={embeddedIndex}
      />
    );
  }

  return (
    <Cell selected={selected} width={fieldWidth} embedded={true}>
      <SinglePicklistWrapper>
        {R.type(value) === "Array" ? value[0] : value}
      </SinglePicklistWrapper>
      {!hideEdit && (
        <EditIconWrapper
          onClick={e =>
            openChecklistEditModal(e, index, fieldId, roomId, value)
          }
        >
          <Icon type="editPencil" />
        </EditIconWrapper>
      )}
    </Cell>
  );
};

export default Select;
