// @flow

import React from "react";
import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Input,
  FormControl,
  FormLabel
} from "@chakra-ui/react";

import * as styles from "./styles";
import SortDropdown from "./SortDropdown";
import { handleRelativeCount, setChartSort } from "src/utils/charts.js";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const AdvancedSettings = ({ chartDetails, setChartDetails }: Props) => {
  const {
    pYAxisLabel,
    sYAxisLabel,
    sort: { orderBy, seriesId }
  } = chartDetails;
  // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
  const relativeCount = chartDetails.series?.map(
    ({ relativeCount }) => relativeCount
  )[0];

  return (
    <Flex sx={styles.AdvancedSettingsWrapper}>
      <RadioGroup
        onChange={e => setChartSort(setChartDetails, e)}
        value={orderBy}
      >
        <Stack sx={styles.SortRadioStackWrapper} direction="column">
          <Flex sx={{ height: "2rem" }}>
            <Radio value="default">Default</Radio>
          </Flex>

          <Flex sx={styles.SortRadioWrapper}>
            <Radio value="desc">Descending</Radio>
            {orderBy === "desc" && (
              <SortDropdown
                series={chartDetails?.series}
                setChartDetails={setChartDetails}
                seriesId={seriesId}
              />
            )}
          </Flex>

          <Flex sx={styles.SortRadioWrapper}>
            <Radio value="asc">Ascending</Radio>
            {orderBy === "asc" && (
              <SortDropdown
                series={chartDetails?.series}
                setChartDetails={setChartDetails}
                seriesId={seriesId}
              />
            )}
          </Flex>
        </Stack>
      </RadioGroup>

      <Flex sx={styles.FlexColumn}>
        <Text sx={styles.AdvancedSettingsLabel}>
          Multiple Value Calculation
        </Text>
        <Flex sx={styles.FlexColumn}>
          <Text sx={styles.Text}>
            If a field contains multiple values per conversation, select what
            the y-axis totals represent
          </Text>

          <RadioGroup
            onChange={e => handleRelativeCount(setChartDetails, e)}
            value={relativeCount}
          >
            <Stack direction="column" sx={styles.RadioStackWrapper}>
              <Radio value={true}>
                Y-axis shows total conversation count (i.e. proportional weight
                to each other)
              </Radio>
              <Radio value={false}>
                Y-axis shows total value count (i.e. equal weight to each value)
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>

      <FormControl sx={styles.FormLabel}>
        <FormLabel sx={styles.Label}>Primary Y-axis label</FormLabel>
        <Input
          sx={styles.BuilderInput}
          onChange={e =>
            setChartDetails(prev => ({ ...prev, pYAxisLabel: e.target.value }))
          }
          value={pYAxisLabel}
          placeholder="Enter Label"
        />
      </FormControl>

      <FormControl sx={styles.FormLabel}>
        <FormLabel sx={styles.Label}>Secondary Y-axis label</FormLabel>
        <Input
          sx={styles.BuilderInput}
          onChange={e =>
            setChartDetails(prev => ({ ...prev, sYAxisLabel: e.target.value }))
          }
          value={sYAxisLabel}
          placeholder="Enter Label"
        />
      </FormControl>
    </Flex>
  );
};

export default AdvancedSettings;
