// @flow

import React from "react";
import type { Header } from "@tanstack/react-table";

import { Resizer as StyledResizer } from "./styles";
import Tooltip from "src/components/Tooltip";

type Props = {
  header: Header
};

const Resizer = ({ header }: Props) => {
  const resizerOffset = header.getContext().table.getState().columnSizingInfo
    .deltaOffset;

  // Prevent drag handle from going beyond the beginning of the column
  const resizerPosition =
    resizerOffset * -1 > 0
      ? Math.abs(resizerOffset) > header.getSize()
        ? header.getSize() * -1
        : resizerOffset
      : resizerOffset;

  const resizerTransformValue = header.column.getIsResizing()
    ? `translate(${resizerPosition}px, -50%)`
    : "";

  const handleResize = event => {
    event.stopPropagation();
    return header.getResizeHandler()(event);
  };

  return (
    <StyledResizer
      isResizing={header.column.getIsResizing()}
      isEmbedded={header.id && header.id.includes("-")}
      onMouseDown={handleResize}
      onTouchStart={handleResize}
      style={{
        transform: resizerTransformValue
      }}
    >
      <Tooltip
        placement="top"
        title={`Resizing "${header.column.columnDef.header}"`}
      >
        <StyledResizer isChild={true} />
      </Tooltip>
    </StyledResizer>
  );
};

export default Resizer;
