// @flow

// Spacing
export const space_xs = "2px";
export const space_s = "4px";
export const space_m = "8px";
export const space_ms = "12px";
export const space_l = "16px";
export const space_lx = "24px";
export const space_xl = "32px";

// Inset
export const space_inset_default = "8px 8px 8px 8px";
export const space_inset_xs = "2px 2px 2px 2px";
export const space_inset_s = "4px 4px 4px 4px";
export const space_inset_m = "8px 8px 8px 8px";
export const space_inset_l = "16px 16px 16px 16px";
export const space_inset_xl = "32px 32px 32px 32px";

// Inline
export const space_inline_default = "0px 8px 0px 0px";
export const space_inline_s = "0px 4px 0px 0px";
export const space_inline_m = "0px 16px 0px 0px";

// Squished Inset
export const space_inset_squish_xs = "2px 4px 2px 4px";
export const space_inset_squish_s = "4px 8px 4px 8px";
export const space_inset_squish_m = "8px 16px 8px 16px";
export const space_inset_squish_l = "16px 32px 16px 32px";

// Stretched Inset
export const space_inset_stretch_xs = "4px 2px 4px 2px";
export const space_inset_stretch_s = "8px 4px 8px 4px";
export const space_inset_stretch_m = "16px 8px 16px 8px";
export const space_inset_stretch_l = "32px 16px 32px 16px";

// Stack
export const space_stack_default = "0 0 8px 0";
export const space_stack_xs = "0 0 2px 0";
export const space_stack_s = "0 0 4px 0";
export const space_stack_m = "0 0 8px 0";
export const space_stack_l = "0 0 16px 0";
export const space_stack_xl = "0 0 32px 0";

// List
export const space_list_xs = "0 2px 0 2px";
export const space_list_s = "0 4px 0 4px";
export const space_list_m = "0 8px 0 8px";
export const space_list_l = "0 16px 0 16px";
export const space_list_xl = "0 32px 0 32px";

export const space_default = "8px";
