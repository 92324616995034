// @flow

import { connect } from "react-redux";

import { searchWorkflow, resetSearch } from "src/actions/workflows";
import SelectMultiple from "src/components/workflow/SelectMultiple";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.workflow.searchResults
});

export default connect(mapStateToProps, {
  handleSearch: searchWorkflow,
  resetSearch
})(SelectMultiple);
