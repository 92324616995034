// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Low = createIcon({
  displayName: "LowIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fill="none"
        stroke="#BBB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M6.923.954L.953 6.923a1.523 1.523 0 0 0 0 2.154l5.97 5.97c.595.594 1.56.594 2.154 0l5.97-5.97a1.523 1.523 0 0 0 0-2.154L9.076.953a1.523 1.523 0 0 0-2.154 0z"
      />
      <path
        fill="none"
        stroke="#BBB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M4.954 9.015L8 12.062l3.046-3.047M4.954 5.97L8 9.014l3.046-3.046"
      />
    </>
  )
});

export default Low;
