// @flow

import React from "react";

type Props = {
  value: any
};

const Number = ({ value }: Props) => <>{value}</>;

export default Number;
