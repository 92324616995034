// @flow

import React, { useState, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";
import { connect } from "react-redux";

import {
  Participants,
  ParticipantHeader,
  AddParticipant,
  ParticipantList
} from "src/components/chatroom/Members/styles";
import User from "src/components/chatroom/Members/User.js";
import AddUser from "src/components/chatroom/Members/AddUser";
import useClickoutside from "src/hooks/useClickoutside";
import {
  addMemberToRole,
  removeMemberFromRole
} from "src/actions/roleManagement";
import * as styles from "./styles";

type Props = {
  id: number,
  isActive: boolean,
  setIsActive: Function,
  members: Array<string>,
  _addMemberToRole: Function,
  _removeMemberFromRole: Function
};

const Members = ({
  members,
  id,
  isActive,
  setIsActive,
  _addMemberToRole,
  _removeMemberFromRole
}: Props) => {
  const outerRef = useRef(null);
  const menuRef = useRef(null);
  const [showAddUser, setShowAddUser] = useState(false);
  useClickoutside({ outerRef: menuRef, handleClose: () => setIsActive(null) });

  const hasMembers = members.length > 0;
  const memberCountString = `${members.length} user${
    members.length > 1 ? "s" : ""
  }`;

  const isOpen = isActive === id;
  const handleMenu = () => {
    if (hasMembers) {
      setIsActive(prev => (prev === id ? null : id));
    } else {
      setShowAddUser(true);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Text onClick={handleMenu} sx={styles.AddUserText}>
        {!hasMembers ? "Add User" : memberCountString}
      </Text>

      <>
        {isOpen && (
          <Box minW="200px" ref={menuRef} sx={styles.MembersWrapper}>
            <Participants>
              <ParticipantHeader>MEMBERS</ParticipantHeader>
              <ParticipantList>
                {members.map(uid => {
                  return (
                    <User
                      id={id}
                      key={uid}
                      uid={uid}
                      user={uid}
                      onDelete={_removeMemberFromRole}
                    />
                  );
                })}
              </ParticipantList>
            </Participants>

            <AddParticipant
              onClick={() => {
                setIsActive(null);
                setShowAddUser(true);
              }}
            >
              &#43; Add Users
            </AddParticipant>
          </Box>
        )}

        {showAddUser && (
          <AddUser
            style={styles.AddUser}
            id={id}
            onAdd={_addMemberToRole}
            outerRef={outerRef}
            onClose={() => {
              setShowAddUser(false);
            }}
          />
        )}
      </>
    </Box>
  );
};

export default connect(null, {
  _addMemberToRole: addMemberToRole,
  _removeMemberFromRole: removeMemberFromRole
})(Members);
