// @flow

import type { StackProps, TextProps, BoxProps } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const MoreMenuBox: BoxProps = {
  display: "flex",
  alignItems: "center",
  padding: "0.25rem",
  borderRadius: "0.25rem",
  cursor: "pointer",
  gap: "0.25rem",
  "&:hover": {
    background: colors.grey32
  },
  "&:focus": {
    background: colors.grey32
  }
};

const MenuContainer: StackProps = {
  alignItems: "flex-start",
  position: "absolute",
  left: 0,
  top: "2rem",
  width: "20.9375rem",
  borderRadius: "0.25rem",
  background: "#FFF",
  boxShadow: "0 0 0.25rem 0 rgba(0, 0, 0, 0.25)",
  zIndex: 2,
  fontSize: "14px"
};

export const MenuOpen = {
  ...MenuContainer,
  display: "flex"
};
export const MenuClosed = {
  ...MenuContainer,
  display: "none"
};

export const MenuItem: StackProps = {
  cursor: "pointer",
  width: "100%",
  height: "2.5rem",
  padding: "1rem",
  display: "inline-flex",
  alignItems: "center",
  _hover: {
    borderRadius: 0,
    background: colors.grey33
  },
  _focus: {
    borderRadius: 0,
    background: colors.grey33
  }
};

export const CreatedByItem: StackProps = {
  ...MenuItem,
  _hover: {},
  cursor: "default"
};

export const ConversationItem: StackProps = {
  ...MenuItem,
  height: "auto",
  _hover: {},
  cursor: "default",
  paddingTop: 0,
  paddingBottom: "0.5rem"
};

export const JSONMenuItem: StackProps = {
  ...MenuItem,
  height: "auto"
};

export const ConversationCreator: StackProps = {
  border: "solid 1px #EDF2F7",
  width: "100%",
  borderRadius: "0.25rem",
  padding: "0.5rem",
  alignItems: "flex-start"
};

export const ToggleIconContainer: StackProps = {
  marginLeft: "auto !important",
  width: "1rem",
  height: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  _hover: {
    borderRadius: "100px",
    background: colors.skyDark
  }
};

export const DropdownMenuWrapper = {
  width: "100%",
  boxShadow: "none"
};

export const DropdownItem: StackProps = {
  ...MenuItem,
  paddingLeft: "2.5rem"
};

export const LeaveConversationText: TextProps = {
  color: colors.redDark
};
