// @flow

import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { getChatRoom } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  diff: number
};

const DayDifference = ({ diff }: Props) =>
  diff || diff === 0 ? <div>{diff}d</div> : <div />;

const mapStateToProps = (
  { app }: { app: AppState },
  { id }: { id: RoomId }
) => ({
  diff: moment().diff(getChatRoom(app, id).createdAt, "days")
});

export default connect(mapStateToProps)(DayDifference);
