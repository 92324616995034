// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { ScrollableBox } from "src/styles/box";
import { Input as DefaultInput } from "src/styles/input";

export const Filters = styled.div`
  height: 3em;
  margin-bottom: 0.8em;
  background: ${colors.grey11};
  display: flex;
  align-items: center;
  padding-left: 1em;
`;

export const Label = styled.strong`
  font-size: 10px;
`;

export const Button = styled.button`
  background: ${props => (props.active ? colors.active : colors.brightLight)};
  color: ${props => (props.active ? colors.brightLight : colors.active)};
  transition: all 0.8 ease;
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  padding: 0.5em 0.6em;
  font-size: 10px;
  font-weight: 500;
  margin-left: 20px;
  cursor: pointer;
`;

export const List = styled(ScrollableBox)`
  position: absolute !important;
  z-index: 50;
  left: 1.5em;
  top: 2.5em;
  > ul {
    margin: 0.5em 0 0 0.1em;
    max-height: 11em;
  }
`;

export const Input = styled(DefaultInput)`
  border: 1px solid ${colors.active} !important;
  padding: 0.5em 0.8em;
  font-size: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  > span {
    white-space: nowrap;
    display: inline-block;
    max-width: 16em;
    line-height: 2.2em;
    color: ${colors.primary};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > label > div {
    width: 1.3em;
    height: 1.3em;
  }
`;

export const LastUpdated = styled.div`
  margin-left: 1.3em;
  border: 1px solid ${colors.primary};
  border: ${radius.medium};
  > span {
    padding-left: 0.5em;
    font-size: 10px;
  }
`;
