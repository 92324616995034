// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const ProgressContainer = styled.div`
  width: 100%;
  height: 32px;
  background-color: #ebf5fa;
  border-radius: ${spacing.space_xs};
  position: relative;
  margin-bottom: 0.75rem;
`;

const ProgressFill = styled.div`
  min-width: 0px;
  width: ${props => `${props.progress}%`};
  transition: 1s;
  height: 32px;
  background-color: ${colors.activeFilter};
  border-radius: ${spacing.space_xs};
`;

const Text = styled.div`
  position: absolute;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${props => (props.progress > 55 ? colors.brightLight : colors.dark)};
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
`;

export { ProgressContainer, ProgressFill, Text };
