// @flow

import { useSelector, useDispatch } from "react-redux";
import React from "react";

import { Select } from "@chakra-ui/react";
import { getRoleId, getRoleTitles } from "src/reducers";
import { addMemberToRole } from "src/actions/roleManagement";

import { RoleContainer } from "./styles";

import type { UID } from "src/types";

type Props = {
  uid: UID
};

const Role = ({ uid }: Props) => {
  const dispatch = useDispatch();

  const currentRole = useSelector(({ app }) => getRoleId(app, uid));
  const roles = useSelector(({ app }) => getRoleTitles(app));

  const handleRoleChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    e.preventDefault();
    dispatch(addMemberToRole(parseInt(e.target.value, 10), [uid]));
  };

  return (
    <RoleContainer>
      <Select value={currentRole} onChange={handleRoleChange} size="sm">
        {roles.map(role => (
          <option key={role.id} value={role.id}>
            {role.label}
          </option>
        ))}
      </Select>
    </RoleContainer>
  );
};

export default Role;
