// @flow

const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGE_SENDER_ID,
  appId: process.env.APP_ID
};

export const vapidKey = process.env.VAPID_KEY || "";

export const backendUrl = process.env.BACKEND_URL || "";

export const appDomain = process.env.BASE_URL || "http://localhost:8080";

export default config;
