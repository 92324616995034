// @flow

import React from "react";

type Props = {
  color?: string,
  fillColor?: string
};

const Group = ({ color = "#2a2a2a", fillColor }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    viewBox="0 0 20 17"
  >
    <g
      fill={fillColor}
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <path d="M17.6 16v-1.3c0-2.15-2.066-3.93-4.754-4.246M11.2 16v-1.3c0-2.371-2.512-4.3-5.6-4.3-3.088 0-5.6 1.929-5.6 4.3V16M11.396 7.138c.197.034.397.062.604.062 1.985 0 3.6-1.615 3.6-3.6S13.985 0 12 0c-.207 0-.407.027-.605.062" />
      <circle cx="5.6" cy="3.6" r="3.6" />
    </g>
  </svg>
);

export default Group;
