/** 
 * 
 * OAuth Reducer
 * @module reducers/oAuth

 * Purpose
 * --------
 * Store status and error of OAuth authorization API request.
 * 
*/

// @flow

import type { Action } from "src/types";
import * as atypes from "src/constants/actionTypes";

export type State = {
  inProgress: boolean,
  error: null | string
};

const requestStatus = (
  state: State = {
    inProgress: false,
    error: null
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.OAUTH_APP_GRANT_PERMISSIONS_REQUEST:
      return {
        inProgress: true,
        error: null
      };

    case atypes.OAUTH_APP_GRANT_PERMISSIONS_FAILURE:
      return {
        inProgress: false,
        error: payload.error
      };

    default:
      return state;
  }
};

export default requestStatus;
