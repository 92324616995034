// @flow

import React, { Fragment } from "react";

import Hidden from "src/components/messages/Chat/Hidden";
import Deleted from "src/components/messages/Chat/Deleted";
import Notification from "src/components/messages/Chat/Notification";
import Chat from "src/components/messages/Chat";
import type { RoomId } from "src/types";

type Props = {
  messages: Array<Object>,
  roomId: RoomId,
  renderMessageMarker: Function,
  lastMessage: ?string
};

const ChatGroup = ({
  messages,
  roomId,
  renderMessageMarker,
  lastMessage
}: Props) => {
  const [first, ...rest] = messages;
  const { deleted, hidden } = first;

  if (deleted) {
    return (
      <>
        {lastMessage === first.id ? renderMessageMarker() : null}
        <Deleted message={first} />
      </>
    );
  }

  if (hidden) {
    return (
      <>
        {lastMessage === first.id ? renderMessageMarker() : null}
        <Hidden message={first} roomId={roomId} />
      </>
    );
  }

  if (first.action) {
    return (
      <>
        {lastMessage === first.id ? renderMessageMarker() : null}
        <Notification message={first} roomId={roomId} />
      </>
    );
  }
  return (
    <div>
      {lastMessage === first.id ? renderMessageMarker() : null}
      <Chat type="first" roomId={roomId} message={first} />
      {rest.map(msg => (
        <Fragment key={msg.id}>
          {lastMessage === msg.id ? renderMessageMarker() : null}
          <Chat message={msg} roomId={roomId} key={msg.id} />
        </Fragment>
      ))}
    </div>
  );
};

export default ChatGroup;
