// @flow

import { connect } from "react-redux";

import SelectMultiple from "src/components/user/SelectMultiple";
import { getUserSearchResult } from "src/reducers";
import { searchUsers as handleSearch } from "src/actions";
import { resetSearch } from "src/actions/user";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: getUserSearchResult(app)
});

export default connect(mapStateToProps, {
  handleSearch,
  resetSearch
})(SelectMultiple);
