// @flow

import React from "react";

import { OldValue, NewValue } from "../styles";
import NameOrEmail from "src/containers/user/NameOrEmail";

import type { UID } from "src/types";

type Props = {
  oldValue: ?UID,
  newValue: ?UID
};

const User = ({ oldValue, newValue }: Props) => (
  <>
    {oldValue && (
      <OldValue>{oldValue ? <NameOrEmail uid={oldValue} /> : null}</OldValue>
    )}
    {newValue && (
      <NewValue>{newValue ? <NameOrEmail uid={newValue} /> : null}</NewValue>
    )}
  </>
);

export default User;
