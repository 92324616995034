// @flow

import { connect } from "react-redux";

import Item from "src/components/chatroom/Item";
import {
  getChatroomTitle,
  getChatroomType,
  getSequenceNo,
  getProcessTitle,
  getTemplateId
} from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  id: string
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  name: getChatroomTitle(app, props.id),
  type: getChatroomType(app, props.id),
  seqNo: getSequenceNo(app, props.id),
  processTitle: getProcessTitle(app, props.id),
  systemProcess: app.systemProcess,
  templateId: getTemplateId(app, props.id)
});

export default connect(mapStateToProps)(Item);
