// @flow

import styled from "styled-components";

import * as colors from "src/styles/constants/colors";

export const Select = styled.div`
  display: flex;
  align-items: center;
  max-width: 13em;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.active};
  cursor: pointer;
`;
