// @flow

import * as auth from "./auth";
import db from "./db";
import firebaseConfig from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";

const firestoreDB = db(firebaseConfig);

export { firestoreDB as db };
export { auth };

export const rootEndpoint = () => easyFetch.get("/");
