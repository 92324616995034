// @flow

import React from "react";

import { Main as StyledMain } from "./styles";

import Viewer from "./Viewer";
import CurrentChatroom from "src/components/chatroom/Current";

const Main = () => (
  <StyledMain>
    <CurrentChatroom />
    <Viewer />
  </StyledMain>
);

export default Main;
