// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, DisableUserModal } from "src/types";

const disableUserModal = (
  state: ?DisableUserModal = {
    uid: null,
    adminModal: false,
    memberModal: false
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_DISABLE_USER_MODAL:
      return {
        uid: payload.uid,
        adminModal: payload.adminModal,
        memberModal: payload.memberModal
      };
    case atypes.HIDE_DISABLE_USER_MODAL:
      return {
        uid: payload.uid,
        adminModal: payload.adminModal,
        memberModal: payload.memberModal
      };
    default:
      return state;
  }
};

export default disableUserModal;
