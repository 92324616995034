// @flow

import React from "react";
import * as R from "ramda";

import { autoReminderType } from "src/constants/display";
import useClickoutside from "src/hooks/useClickoutside";
import { ListItemMedium as ListItem } from "src/styles/box";
import { Dropdown as StyledDropdown } from "./styles";

type Props = {
  outerRef: any,
  handleChange: Function,
  handleClose: Function
};

const Dropdown = ({ outerRef, handleClose, handleChange }: Props) => {
  useClickoutside({ outerRef, handleClose });

  return (
    <StyledDropdown>
      <ul>
        {R.keys(autoReminderType).map(type => (
          <ListItem key={type} onClick={() => handleChange(type)}>
            {autoReminderType[type]}
          </ListItem>
        ))}
      </ul>
    </StyledDropdown>
  );
};

export default Dropdown;
