// @flow

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import moment from "moment";

import useTimeout from "src/hooks/useTimeout";
import { FileIconWrapper } from "../styles";
import Icons from "src/icons";
import { ImageThumbnail } from "src/components/Dock/styles";
import { getFileType, getFilePreviewSupport } from "src/utils/file";
import { getFileThumbnailURL } from "src/reducers";
import { getFileThumbnail } from "src/actions/file";
import { Loader } from "src/styles/auth";

import type { UnifizeFile, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  originalName: string,
  customWrapper?: boolean,
  file: ?UnifizeFile
};

// Number of times to retry setting the thumbnail
const MAXIMUM_TRIES = 4;

const FileThumbnail = ({
  file,
  originalName,
  roomId,
  customWrapper
}: Props) => {
  const dispatch = useDispatch();

  const thumbnail = useSelector(
    ({ app }) => file && getFileThumbnailURL(app, file.name)
  );

  useEffect(() => {
    if (
      (!thumbnail || moment().diff(thumbnail?.date, "minutes") > 10) &&
      file &&
      getFilePreviewSupport(file)
    ) {
      dispatch(getFileThumbnail({ name: file.name, roomId }));
    }
  }, [file, roomId]);

  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnail?.url);
  const [noOftries, setNoOfTries] = useState(0);
  const [abortTimeout, setAbortTimeout] = useState(true);

  useEffect(() => {
    setThumbnailUrl(thumbnail?.url);
  }, [thumbnail?.url]);

  useTimeout(
    () => {
      setThumbnailUrl(thumbnailUrl);
      setAbortTimeout(true);
    },
    abortTimeout ? null : 3000
  );

  // Sometimes the thumbnail URL returns 404
  // Handling it by trying to load the image
  // again after 3 seconds
  const handleError = () => {
    if (noOftries <= MAXIMUM_TRIES) {
      setThumbnailUrl("");
      setNoOfTries(noOftries + 1);
      // Start the timeout to set thumbnail URL
      setAbortTimeout(false);
    }
  };

  useEffect(() => {
    setThumbnailUrl(thumbnail?.url);
  }, [thumbnail?.url]);

  if (!R.isEmpty(file || {}) && thumbnail) {
    return !thumbnailUrl ? (
      <Loader />
    ) : (
      <ImageThumbnail src={thumbnailUrl} onError={handleError} />
    );
  }
  return customWrapper ? (
    <Icons type="fileIcons" icon={getFileType(originalName)} />
  ) : (
    <FileIconWrapper>
      <Icons type="fileIcons" icon={getFileType(originalName)} />
    </FileIconWrapper>
  );
};

export default FileThumbnail;
