// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const CriticalIconActive = createIcon({
  displayName: "CriticalIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        d="M29.4118 14.4118L17.8382 2.83821C16.6849 1.68492 14.8151 1.68492 13.6618 2.83821L2.08821 14.4118C0.934924 15.5651 0.934924 17.4349 2.08821 18.5882L13.6618 30.1618C14.8151 31.3151 16.6849 31.3151 17.8382 30.1618L29.4118 18.5882C30.5651 17.4349 30.5651 15.5651 29.4118 14.4118Z"
        fill="#FF0000"
        stroke="white"
        strokeWidth="1.9375"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 7.25C16.3358 6.83579 15.6642 6.83579 15.25 7.25L8.5 14C8.08579 14.4142 8.08579 15.0858 8.5 15.5C8.91421 15.9142 9.58579 15.9142 10 15.5L16 9.5L22 15.5C22.4142 15.9142 23.0858 15.9142 23.5 15.5C23.9142 15.0858 23.9142 14.4142 23.5 14L16.75 7.25ZM17.0607 9L17.0607 8L14.9393 8L14.9393 9L17.0607 9Z"
        fill="white"
      />
      <path
        d="M16.75 13.25C16.3358 12.8358 15.6642 12.8358 15.25 13.25L8.5 20C8.08579 20.4142 8.08579 21.0858 8.5 21.5C8.91421 21.9142 9.58579 21.9142 10 21.5L16 15.5L22 21.5C22.4142 21.9142 23.0858 21.9142 23.5 21.5C23.9142 21.0858 23.9142 20.4142 23.5 20L16.75 13.25ZM17.0607 15L17.0607 14L14.9393 14L14.9393 15L17.0607 15Z"
        fill="white"
      />
    </>
  )
});

export default CriticalIconActive;
