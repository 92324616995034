// @flow

import { connect } from "react-redux";
import { getCurrentChecklistBuilderFields } from "src/reducers";

import Field from "src/components/Manage/Builder/Checklist/Field";
import * as actions from "src/actions/checklist";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import { setChecklistBuilderAttribute } from "src/actions/checklistBuilder";

const mapStateToProps = ({ app }, { position }) => ({
  value: getCurrentChecklistBuilderFields(app, true)[position]
});

export default connect(mapStateToProps, {
  ...actions,
  setWorkflowBuiderAttributes,
  setChecklistBuilderAttribute
})(Field);
