// @flow

import { backendUrl } from "src/config/firebase";

/**
 * @param  {{ uid: string }} { email }
 * @returns Object
 */
const signup = async () => {
  const response = await fetch(`${backendUrl}/signup`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response;
};

/**
 * @param  ({authCode, email})
 * @param  ({authCode:string, email:string})
 * @returns Object
 */
const validateSignUpCode = async ({
  authCode,
  email
}: {
  authCode: string,
  email: string
}): Object => {
  const response = await fetch(`${backendUrl}/signup/validate`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ "auth-code": authCode, email })
  });
  if (!response.ok) {
    throw new Error("Email verification failed");
  }
  return response.json();
};

/**
 * @param ({ title, domain, autoJoin, invitees }) payload
 * @param payload.title of the org
 * @param payload.domain of the org
 * @param payload.autoJoin users from same domain
 * @param payload.invitees for the new org
 * @returns Newly created org
 */
const setupOrg = async ({
  title,
  domain,
  autoJoin,
  invitees
}: {
  title: string,
  domain: string,
  autoJoin: boolean,
  invitees?: Array<string>
}): Object => {
  const response = await fetch(`${backendUrl}/signup/org`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      orgName: title,
      domainName: domain || null,
      domainAutoJoin: autoJoin,
      invitees
    })
  });

  return response.json();
};

const validateDomain = async (domainName: string) => {
  const response = await fetch(`${backendUrl}/signup/validate-domain`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      domainName
    })
  });
  return response.json();
};

const joinExistingOrg = async (orgId: number) => {
  const response = await fetch(`${backendUrl}/join/${orgId}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.ok;
};

const sendVerificationEmail = async (
  email: string,
  orgId: number,
  cloneOrgToken: ?string
) => {
  let payload = { email, orgId };
  let headers = {
    "Content-type": "application/json"
  };

  if (cloneOrgToken) {
    payload = {
      ...payload,
      source: "clone"
    };

    headers = {
      ...headers,
      "x-unifize-clone-token": cloneOrgToken
    };
  }

  const response = await fetch(`${backendUrl}/signup/user`, {
    credentials: "include",
    method: "POST",
    headers,
    body: JSON.stringify(payload)
  });
  return response;
};

export {
  signup,
  validateSignUpCode,
  setupOrg,
  validateDomain,
  joinExistingOrg,
  sendVerificationEmail
};
