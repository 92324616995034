// @flow

import { put, takeEvery, select, take, call } from "redux-saga/effects";
import * as actions from "src/constants/actionTypes";
import { getLastOrg } from "src/selectors";
import connection, { rsf } from "src/db";
import type { AppIntegration } from "src/types";

function* syncIntegrations() {
  try {
    let initial = true;
    const orgId = yield select(getLastOrg);
    const updates = rsf.firestore.channel(
      connection().collection(`orgs/${orgId}/integrations`)
    );
    const integrationData: Array<AppIntegration> = [];

    while (true) {
      const snapshot = yield take(updates);
      for (const { doc } of snapshot.docChanges()) {
        if (initial) {
          integrationData.push(doc.data());
        } else {
          const { id } = doc.data();
          yield put({
            type: actions.UPDATE_ACTIVE_INTEGRATIONS,
            payload: {
              id,
              data: doc.data()
            }
          });
        }
      }
      if (initial) {
        yield put({
          type: actions.SET_ACTIVE_INTEGRATIONS,
          payload: {
            data: integrationData
          }
        });
      }

      initial = false;
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchSyncIntegrations(): any {
  yield takeEvery(actions.SYNC_INTEGRATIONS, syncIntegrations);
}

function* watchUserSync(): any {
  yield take(actions.SYNC_USERS_SUCCESS);
  yield call(syncIntegrations);
}

export default [watchSyncIntegrations(), watchUserSync()];
