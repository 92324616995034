// @flow

import { connect, useDispatch } from "react-redux";
import React, { useMemo } from "react";
import * as R from "ramda";
import { getWorkflowBuilderStatus } from "src/reducers";
import { showAlert, hideAlert } from "src/actions/alert";
import { deleteStatus } from "src/actions/statuses";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import { alerts as alertIds } from "src/constants";
import type {
  AppState,
  WorkflowStatusData,
  WorkflowStatuses,
  AlertState
} from "src/types";

import { Box } from "@chakra-ui/react";
import WarningModal from "src/components/Status/WarningModal";
import Select from "src/components/Status/Select";

type Props = {
  statuses: WorkflowStatuses,
  _setWorkflowBuiderAttributes: Function,
  deleteStatus: Function,
  alert: AlertState
};

export type UpdateStatusFunction = (
  id: number,
  data: WorkflowStatusData
) => void;

const allStatuses = R.groupBy(n => (n ? "pending" : "completed"));

const Status = ({
  _setWorkflowBuiderAttributes,
  statuses,
  alert,
  deleteStatus
}: Props) => {
  const dispatch = useDispatch();

  const modal = alert && alert.id === alertIds.invalidStatusesInProcessTemplate;
  const status = useMemo(() => R.map(R.prop("id"), statuses || []), [statuses]);

  const showInvalidStatusesInProcessTemplateAlert = () => {
    dispatch(
      showAlert({
        id: alertIds.invalidStatusesInProcessTemplate
      })
    );
  };

  const updateStatusData: UpdateStatusFunction = (id, data) => {
    const updatedStatuses = statuses.map(status => {
      if (status.id !== id) return status;

      return R.mergeDeepRight(status, data);
    });

    const { completed = [], pending = [] } = allStatuses(
      R.map(R.prop("active"), updatedStatuses || [])
    );

    if (pending.length === 0 || completed.length === 0) {
      showInvalidStatusesInProcessTemplateAlert();
    } else {
      _setWorkflowBuiderAttributes({
        status: updatedStatuses
      });
    }
  };

  return (
    <Box overflow="auto" height="100%">
      {modal && <WarningModal hideModal={() => dispatch(hideAlert())} />}

      <Select
        value={status}
        statuses={statuses}
        handleDeleteStatus={deleteStatus}
        updateStatusData={updateStatusData}
      />
    </Box>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  statuses: getWorkflowBuilderStatus(app),
  alert: app.alert
});

const mapDispatchToProps = {
  deleteStatus,
  _setWorkflowBuiderAttributes: setWorkflowBuiderAttributes
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
