// @flow

import React from "react";

type Props = {
  fill?: string
};

const AlignJustify = ({ fill, ...restProps }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
    <title>ic_format_align_justify_24px</title>
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M3 21h18v-2H3v2zm0-4h18v-2H3v2zm0-4h18v-2H3v2zm0-4h18V7H3v2zm0-6v2h18V3H3z" />
    </g>
  </svg>
);

export default AlignJustify;
