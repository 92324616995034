// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewer {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ffffff;
  color: #fff;
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  background: linear-gradient(
    rgb(249, 248, 247),
    rgb(251, 250, 248) 46px,
    rgb(251, 251, 249) 120px,
    rgb(248, 247, 245) 35%,
    rgb(249, 248, 246)
  );
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.3125rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 0.625rem;
  cursor: pointer;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}

a {
  color: #1581ba;
}

input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  background: #fff;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\\201c""\\201d";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}

table {
  border-collapse: collapse;
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB;;;;;;GAMC;AACH;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,oCAAoC;EACpC,2CAA2C;AAC7C;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,cAAc;AAChB;;AAEA;;;;EAIE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".pdf-viewer {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  background-color: #ffffff;\n  color: #fff;\n}\n\n.sidebar {\n  overflow: auto;\n  color: rgb(119, 119, 119);\n  background: linear-gradient(\n    rgb(249, 248, 247),\n    rgb(251, 250, 248) 46px,\n    rgb(251, 251, 249) 120px,\n    rgb(248, 247, 245) 35%,\n    rgb(249, 248, 246)\n  );\n}\n\n.sidebar__highlights {\n  list-style: \"none\";\n  padding: 0;\n}\n\n.highlight__location {\n  margin-top: 0.3125rem;\n  text-align: right;\n  font-size: 10px;\n}\n\n.highlight__image {\n  overflow: auto;\n  max-width: 300px;\n  border: 1px dashed;\n}\n\n.sidebar__highlight {\n  padding: 0.625rem;\n  cursor: pointer;\n  transition: background 140ms ease-in;\n  border-bottom: 1px solid rgb(119, 119, 119);\n}\n\n.sidebar__highlight:hover {\n  background: rgba(58, 56, 52, 0.08);\n}\n\na {\n  color: #1581ba;\n}\n\ninput[type=\"text\"],\ninput[type=\"number\"],\ninput[type=\"password\"],\ntextarea {\n  background: #fff;\n}\n\nblockquote {\n  padding: 0;\n  margin: 0;\n  quotes: \"\\201c\"\"\\201d\";\n}\nblockquote:before {\n  content: open-quote;\n}\nblockquote:after {\n  content: close-quote;\n}\n\ntable {\n  border-collapse: collapse;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
