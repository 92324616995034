// @flow

import styled from "@emotion/styled";

import { Table as DefaultTable } from "src/styles/table";
import { Small } from "src/styles/modal";
import * as colors from "src/styles/constants/colors";

const Table = styled(DefaultTable)`
  tbody {
    max-height: calc(100vh - 16em);
  }

  > thead > tr > td {
    white-space: nowrap;

    :first-of-type,
    :nth-of-type(2) {
      position: sticky;
      z-index: 30 !important;
    }

    :first-of-type {
      width: 56px;
      left: 0px;
    }

    :nth-of-type(2) {
      width: 220px;
      left: 64px;
    }

    :nth-of-type(3) {
      width: 10vw;
    }

    :nth-of-type(4) {
      width: 8vw;
    }
    :nth-of-type(5) {
      width: 11vw;
    }
    :nth-of-type(6) {
      width: 32em;
    }

    :nth-of-type(7),
    :nth-of-type(8),
    :nth-of-type(9) {
      width: 20em;
    }
  }
`;

const NewContacts = styled(Small)`
  form {
    top: 0px;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    background: transparent !important;
    border: none !important;
  }
`;

export { Table, NewContacts };
