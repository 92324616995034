// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ifProp } from "styled-tools";
import { StackProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { colors as oldColors } from "src/styles/constants";
import { RelativeTruncatedBox } from "src/styles/box";
import { Value } from "src/components/chatroom/Status/styles";

export const ChatBlock = styled.div`
  cursor: pointer;
  max-width: ${props => (props.width ? "100%" : "396px")};
  height: 96px;
  ${ifProp(
    { active: true },
    css`
      border: 3px solid ${colors.activeFilter};
    `
  )}
  ${ifProp(
    { active: false },
    css`
      border: 3px solid ${colors.brightLight};
      border-bottom: 1px solid ${colors.grey13};
    `
  )}
  
  ${props => props.tempBorder && ` border: 3px solid ${colors.orange}`};

  > div:last-child {
    margin-bottom: 0;
  }
  :hover {
    background-color: ${colors.grey0};
  }
`;

export const SubWrapper = styled.div`
  padding: ${spacing.space_inset_l};
  /* Remove top padding if warning label is there */
  ${props => props.hasWarning && ` padding-top: 0px;`};
`;

export const Labels = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const BreadCrumbWrapper = styled(RelativeTruncatedBox)`
  white-space: nowrap;
`;

export const Status = styled.div`
  > div {
    ${props =>
      props.overdue && !props.disabled && `background-color: ${colors.close}`};
    ${props =>
      !props.active &&
      !props.disabled &&
      `background-color: ${colors.currentUser}`};
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0px;
    min-height: 16px;
    border-radius: ${spacing.space_xs};
    margin: 0px;

    ${Value} {
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0px;
      padding: 0px 6px;
      ${props => props.overdue && !props.disabled && `display: flex`};
      align-items: center;
      justify-content: ${props => (props.overdue ? "space-between" : "center")};
      ${props =>
        (!props.active || props.cancelled) && `justify-content: center`};
      border-radius: ${spacing.space_xs};
      line-height: 1rem;
      ${props => props.overdue && `color:${colors.brightLight}`};
      ${props => !props.active && `color: ${colors.success}`};
      ${props => props.cancelled && `color: ${colors.brightLight}`};
      ${props =>
        props.disabled && !props.cancelled && `color: ${colors.primary}`};
    }
  }
`;

export const TitleWrapper = styled(RelativeTruncatedBox)`
  background: inherit;
  max-height: 36px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${spacing.space_stack_s};

  > span {
    display: flex;
    width: 2em;
    height: ${spacing.space_l};
    align-items: center;
    justify-content: center;
    padding: 0px;
    line-height: 0.75rem;
    font-size: 10px;
    border-radius: 4px;
  }

  @media (max-width: 1080px) {
    max-height: 60px;
  }
`;

export const Checklist = styled.div`
  display: flex;
  align-items: center;
  color: ${oldColors.setupBlack};
  line-height: 1.6;
`;

export const ChecklistIcon = styled.div`
  background: ${colors.secondary};
  display: inline-flex;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.2em;
  align-items: center;
  justify-content: center;
  margin-right: 0.4em;
`;

export const OverDue = styled.div`
  background: ${colors.danger};
  color: ${colors.brightLight};
  height: 16px;
  font-size: 10px;
  line-height: 1.062rem !important;
  padding: 0px 8px;
  border-radius: ${radius.small};
`;

export const SequanceWrapper = styled.div`
  display: flex;
  line-height: 1rem;
  margin: 0px;
  width: 134px;
  min-width: 100px;
  height: ${spacing.space_l};
  align-items: center;
  justify-content: flex-start;
  color: ${colors.inkLighter};
  > div {
    margin-right: 0.25em;
    display: flex;
  }
`;

export const ConversationTypeWrapper = styled.div`
  max-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ColorIndicator = styled.div`
  width: ${spacing.space_l};
  height: ${spacing.space_l};
  margin-right: 0.25em;
  border-radius: 2px;
`;

export const StatusAndOwnerWrapper = styled.div`
  display: flex;
`;

export const WarningMessageWrapper = styled.div`
  width: 100%;
  height: ${spacing.space_l};
  background: rgba(242, 153, 74, 0.2);
  color: ${colors.orangeRed};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const currentversionIndicator: StackProps = {
  ml: 2,
  spacing: 1
};
