// @flow

import React, { useCallback } from "react";

import {
  Type as StyledType,
  Option,
  SubHeading,
  Header,
  Heading
} from "./styles";
import Icon from "src/icons";
import Radio from "src/components/Radio";
import * as colors from "src/styles/constants/colors";
import type { RoomId } from "src/types";

type Props = {
  privacy: string,
  roomId: RoomId,
  setAttribute: Function
};

const Type = ({ privacy, roomId, setAttribute }: Props) => {
  const handleType = useCallback((privacyOption, e) => {
    e.preventDefault();
    setAttribute(roomId, { privacy: privacyOption });
  }, []);

  return (
    <StyledType>
      <Option
        selected={privacy === "content"}
        onClick={e => handleType("content", e)}
      >
        <Header>
          <Radio
            id="hideChatContent"
            checked={privacy === "content"}
            handleChange={() => {}}
          />
          <div>
            <Heading>
              <Icon type="lock" color={colors.primary} />
              Hide only chat content
            </Heading>
            <SubHeading>
              Only the contents of the conversation will be hidden. The
              conversation title, status and owner will be visible to other
              users.
            </SubHeading>
          </div>
        </Header>
      </Option>

      <Option
        selected={privacy === "full"}
        onClick={e => handleType("full", e)}
      >
        <Header>
          <Radio
            id="hideEverything"
            checked={privacy === "full"}
            handleChange={() => {}}
          />
          <div>
            <Heading>
              <Icon type="hidden" />
              Hide everything
            </Heading>
            <SubHeading>
              Conversations will be visible only to the participants, admin(s),
              and whitelisted users.
            </SubHeading>
          </div>
        </Header>
      </Option>
    </StyledType>
  );
};

export default Type;
