// @flow

import React from "react";

import { Item as StyledItem } from "./styles";
import Name from "src/containers/file/NameWithoutFetch";

type Props = {
  file: string
};

const Item = ({ file }: Props) => {
  return (
    <StyledItem>
      <Name name={file} />
    </StyledItem>
  );
};

export default Item;
