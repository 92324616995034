// @flow

import React from "react";

import type { Message } from "src/types";

import Container from "src/components/messages/Chat/Notification/Common";
import Icon from "src/icons";

type Props = {
  message: Message
};

const ArchiveStatus = ({ message }: Props) => {
  const action = message.data.archived ? "archived" : "unarchived";

  return (
    <Container {...message}>
      <span> {action} this conversation </span>
      <Icon
        type={message.data.archived ? "archive" : "unarchive"}
        fill="black"
      />
    </Container>
  );
};

export default ArchiveStatus;
