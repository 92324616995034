// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { Set, OrderedSet } from "immutable";
import * as R from "ramda";
import * as RA from "ramda-adjunct";
import type { OrderedSet as OrderedSetType } from "immutable";
import * as atypes from "src/constants/actionTypes";
import { getAllGroups, getAllGroupsById } from "src/reducers/groups";
import { EMPTY_OBJECT } from "src/reducers";
import { userStatus } from "src/constants/users";

import type {
  AppState,
  Action,
  UID,
  UsersById,
  UserState,
  ContactFilter,
  Group,
  UniqueContactsValue,
  UnifizeChatRoom,
  UnifizeUser
} from "src/types";

const searchResults = (
  // https://github.com/immutable-js/immutable-js/issues/1644
  // $FlowFixMe
  state: OrderedSetType<Object> = OrderedSet([]),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.RESET_USER_SEARCH_SUCCESS:
    case atypes.GET_USERS_SUCCESS:
      // $FlowFixMe
      if (state.hashCode() !== OrderedSet(payload.users).hashCode()) {
        return state.clear().union(payload.users);
      }
      return state;
    case atypes.SEARCH_USERS_SUCCESS:
      // $FlowFixMe
      if (state.hashCode() !== OrderedSet(payload.result).hashCode()) {
        return state.clear().union(payload.result);
      }
      return state;
    case atypes.CLEAR_USERS_SEARCH_RESULTS:
      if (state.size !== 0) {
        return Set();
      }
      return state;
    default:
      return state;
  }
};

const contacts = (
  // https://github.com/immutable-js/immutable-js/issues/1644
  // $FlowFixMe
  state: OrderedSetType<Object> = OrderedSet([]),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_USERS_SUCCESS:
      return state.clear().union(payload.users);
    case atypes.FILTER_CONTACTS_SUCCESS:
      return state.clear().union(payload.result);
    case atypes.SEND_USER_INVITE_SUCCESS:
      return state.add(payload.user.uid);
    default:
      return state;
  }
};

const initialContactsFilter = {
  search: ""
};

const contactFilter = (
  state: ContactFilter = initialContactsFilter,
  { type, meta }: Action
) => {
  switch (type) {
    case atypes.SET_CONTACTS_REQUEST:
      const filter = (meta || {}).query || {};
      // When user refreshes the browser if there is multiple values browser returns array
      // but returns string for single values so it needs to be converted to array
      const multiSelections = R.difference(R.keys(filter), ["id", "search"]);

      const multipleValues = R.mergeAll(
        R.map(
          key => ({
            [key]: R.type(filter[key]) === "Array" ? filter[key] : [filter[key]]
          }),
          multiSelections
        )
      );
      const newFilter = { ...filter, ...multipleValues };
      return { ...newFilter };
    case atypes.SET_FILE_REQUEST:
      return initialContactsFilter;
    default:
      return state;
  }
};

const searchString = (state: string = "", { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CONTACTS_SUCCESS:
      return (payload.query || {}).search || "";
    case atypes.SET_CONTACTS_REQUEST:
      return "";
    case atypes.SET_FILE_REQUEST:
      return "";
    case atypes.SEARCH_USERS_REQUEST:
      return payload.searchString;
    case atypes.RESET_USER_SEARCH_SUCCESS:
      return "";
    default:
      return state;
  }
};

const byId = (state: UsersById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.INVITE_OWNER_SUCCESS:
      return { ...state, [payload.uid]: payload };
    case atypes.ADD_ROOM_MEMBER_SUCCESS:
    case atypes.INVITE_USER_FROM_CHECKLIST_SUCCESS:
      return { ...payload, ...state };
    case atypes.SYNC_USERS_SUCCESS:
      return { ...state, ...payload.users };
    case atypes.UPLOAD_PROFILE_PHOTO_SUCCESS:
    case atypes.UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        [payload.uid]: {
          ...state[payload.uid],
          photoUrl: payload.photoUrl
        }
      };
    case atypes.REMOVE_USERS:
      return R.omit(payload.users, state);
    case atypes.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        ...payload.entities.users
      };
    case atypes.SEND_USER_INVITE_SUCCESS:
      return {
        ...state,
        [payload.user.uid]: { ...payload.user, invitePending: true }
      };
    default:
      return state;
  }
};

const allIds = (state: Set<UID> = Set(), { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_USERS_SUCCESS:
      return state.union(R.keys(payload.users));
    case atypes.REMOVE_USERS: {
      const { users } = payload;
      return state.filter(user => !users.includes(user));
    }
    case atypes.SEARCH_USERS_SUCCESS:
      return state.union(payload.result);
    default:
      return state;
  }
};

const selectedRows = (state: Array<UID> = [], { payload, type }: Action) => {
  switch (type) {
    case atypes.TOGGLE_PEOPLE_ROW_SELECTION:
      return R.includes(payload.row, state)
        ? R.reject(R.equals(payload.row), state)
        : [...state, payload.row];
    case atypes.CLEAR_PEOPLE_ROW_SELECTION:
      return [];
    default:
      return state;
  }
};

const uniqueContactsValue = (
  state: UniqueContactsValue = {},
  { payload, type }: Action
) => {
  switch (type) {
    case atypes.GET_UNIQUE_CONTACT_VALUES_SUCCESS:
      return payload;
    case atypes.SEARCH_UNIQUE_DEPARTMENT_SUCCESS:
      return { ...payload, department: payload.result };
    case atypes.SEARCH_UNIQUE_GROUPS_SUCCESS:
      return { ...payload, group: payload.result };
    case atypes.SEARCH_ROLES_SUCCESS:
      return { ...payload, orgRole: payload.result };
    default:
      return state;
  }
};

const userProcesses = (
  state: Array<UnifizeChatRoom> = [],
  { payload, type }: Action
) => {
  switch (type) {
    case atypes.GET_USER_PROCESS_REQUEST:
      return [];
    case atypes.GET_USER_PROCESS_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const onboardingStatus = (state: number = 0, { payload, type }: Action) => {
  switch (type) {
    case atypes.GET_USER_ONBOARDING_STATUS_SUCCESS:
      return payload.status;
    case atypes.GET_USER_ONBOARDING_STATUS_REQUEST:
      return 0;
    default:
      return state;
  }
};

const resendInvite = (state: Array<UID> = [], { payload, type }: Action) => {
  switch (type) {
    case atypes.RESEND_INVITE_REQUEST:
      if (R.includes(payload.uid, state)) {
        return state;
      }
      return [...state, payload.uid];
    case atypes.RESEND_INVITE_SUCCESS:
    case atypes.RESEND_INVITE_FAILURE:
      return state.filter(uid => uid !== payload.uid);
    default:
      return state;
  }
};

const loading = (state: boolean = true, { type }: Action) => {
  switch (type) {
    case atypes.SYNC_USERS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const users = combineReducers<Object, Action>({
  loading,
  resendInvite,
  onboardingStatus,
  userProcesses,
  searchResults,
  contacts,
  searchString,
  contactFilter,
  byId,
  allIds,
  selectedRows,
  uniqueContactsValue
});

export default users;

export const getAllIds = (state: UserState) => state.allIds;
export const getUsersById = (state: UserState) => state.byId;

export const getSelectedPeople = (state: UserState, uid: UID) =>
  R.includes(uid, state.selectedRows);

export const getUser = (state: UserState, uid: UID) =>
  state.byId[uid] || EMPTY_OBJECT;

export const getUserStatus = (state: UserState, uid: UID) => {
  const user = state.byId[uid];

  const isDisabled = user.disabled ?? false;
  const isPending = user.invitePending ?? false;

  switch (true) {
    case isDisabled:
      return userStatus.disabled;
    case isPending:
      return userStatus.pending;
    default:
      return userStatus.active;
  }
};

export const getUsersFromIds = createSelector(
  [getUsersById, (state: UserState, idList: Array<UID>) => idList],
  (usersById: UsersById, idList: Array<UID>) => {
    return R.map(id => usersById[id], idList || []);
  }
);

export const getUserEmail = createSelector(getUser, (user: UnifizeUser) => {
  if (user) {
    return user.email || "";
  }
  return "";
});

export const isUserDisabled = createSelector(
  getUser,
  (user: UnifizeUser) => user.disabled
);

export const getRole = createSelector(
  getUser,
  (user: UnifizeUser) => user.orgRole
);

export const getRoleId = createSelector(
  getUser,
  (user: UnifizeUser) => user.orgRoleId
);

export const getUsers = createSelector(
  [getAllIds, getUsersById],
  (ids, users) => R.map(id => users[id], ids)
);

export const getUserSearchResult = createSelector(
  (state: UserState) => state.searchResults,
  users => users.toJS()
);

const getActiveUsers = state => {
  return R.values(state.byId).filter(
    user => user?.disabled !== true && user?.orgRole !== "contact"
  );
};

export const getUserMentions = createSelector(
  [state => getActiveUsers(state)],
  (users: Array<UnifizeUser>) => [
    ...users.map((user: UnifizeUser) => {
      return {
        id: user?.uid,
        type: "user",
        display: `@${user?.displayName || user?.email}`
      };
    })
  ]
);

// Get sorted list of all users and groups for mentions
export const getUserAndGroupMentions = createSelector(
  [state => getActiveUsers(state.users), state => getAllGroups(state.groups)],
  (users: Array<UnifizeUser>, groups: Array<Group>) => [
    {
      id: "!everyone",
      display: "@All"
    },
    ...[
      ...users.map((user: UnifizeUser) => {
        return {
          id: user?.uid,
          type: "user",
          display: `@${user?.displayName || user?.email}`
        };
      }),
      ...groups.map((group: Group) => {
        return {
          id: `!team^${group.id}`,
          type: "group",
          display: `@${group.title}`
        };
      })
    ].sort((a, b) => a.display.localeCompare(b.display))
  ]
);

// Get users that are part of a direct message (current user & recipient)
export const getDirectMessageMentions = (members: Array<UID>) =>
  createSelector(getUsersById, (users: UsersById) =>
    members.map<{ id: UID, display: string }>((user: UID) => {
      return {
        id: user,
        display: `@${users[user]?.displayName || users[user]?.email}`
      };
    })
  );

export const getProcessBuilderMentions = createSelector(
  [state => getActiveUsers(state.users), state => getAllGroups(state.groups)],
  (users: Array<UnifizeUser>, groups: Array<Group>) => [
    {
      id: "!everyone",
      display: "@All"
    },
    {
      id: "!owner",
      display: "@Owner"
    },
    {
      id: "!creator",
      display: "@Creator"
    },
    ...[
      ...users.map((user: UnifizeUser) => {
        return {
          id: user?.uid,
          type: "user",
          display: `@${user?.displayName || user?.email}`
        };
      }),
      ...groups.map((group: Group) => {
        return {
          id: `!team^${group.id}`,
          type: "group",
          display: `@${group.title}`
        };
      })
    ].sort((a, b) => a.display.localeCompare(b.display))
  ]
);

export const getUserIds = (state: UserState) => R.keys(state.byId);

export const getUsersWithRoles = (state: UserState, roles: string[]) =>
  RA.omitBy(user => !roles.includes(user.orgRole), state.byId);

export const getUsersWithRoleIds = (state: UserState, roles: number[]) =>
  RA.omitBy(user => !roles.includes(user.orgRoleId), state.byId);

export const getSearchString = (state: UserState) => state.searchString;

export const getPeopleTablesort = (state: UserState) => {
  const getSort: Function = (sort: string) =>
    (sort || "").replace(":asc", "").replace(":desc", "");
  // $FlowFixMe
  return R.map(getSort, state.contactFilter.sort || []);
};

export const getUserDisplayNames = (state: UserState): Object =>
  R.mergeAll(
    Object.keys(state.byId).map((uid: UID): Object => ({
      [R.toLower(state.byId[uid].displayName || "")]: uid
    }))
  );

export const getUserEmailsFromIds = (
  state: UserState,
  idList: UID[] = []
): Object => {
  // $FlowFixMe
  const filteredUsers: UnifizeUser[] = R.reject(
    R.isNil,
    getUsersFromIds(state, idList)
  );

  const users = filteredUsers.map(user => ({
    [user.uid]: user.email
  }));

  return R.mergeAll(users);
};

export const getUserIdsByEmail = createSelector(getUsersById, users =>
  R.mergeAll(R.values(users).map(user => ({ [user.email]: user.uid })))
);

export const getUserAndGroupIds = (
  state: UserState,
  ids: Array<number | string>
) => ids;

export const getUserCount = createSelector(
  (state: AppState, ids: Array<number | string>) =>
    getUserAndGroupIds(state.users, ids),
  (state: AppState) => getAllGroupsById(state.groups),
  (ids, groupsById) => {
    // Using a Set would be the ideal solution but it
    // isn't working here for some reason
    const members = R.pipe(
      R.reduce((acc, id) => {
        if (R.type(id) === "Number") {
          const groupMembers = groupsById[id]?.members || [];
          return R.concat(acc, groupMembers);
        } else if (R.type(id) === "String") {
          return R.append(id, acc);
        }
        return acc;
      }, []),
      R.uniq
    )(ids);

    return members.length;
  }
);
