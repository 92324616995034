// @flow

import React from "react";

import NameWithRemove from "./NameWithRemove";
import { SelectionMultiple as StyledSelectionMultiple } from "src/styles/dropdown";

const SelectionMultiple = ({
  value,
  children,
  removeHandler
}: {
  value: Array<number>,
  children: any,
  removeHandler: Function
}) => (
  <StyledSelectionMultiple>
    {(value || []).map(workflow => (
      <NameWithRemove
        id={workflow}
        workflow={workflow}
        key={workflow}
        removeHandler={removeHandler}
      />
    ))}
    {children}
  </StyledSelectionMultiple>
);

export default SelectionMultiple;
