// @flow

import * as React from "react";

type Props = {
  fill?: string
};

const Capitalize = ({ fill, ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...restProps}
    >
      <path
        d="M16.875 1.625h18M21.375 10.625h13.5M21.375 19.625h13.5M1.125 28.625h33.75M14.625 19.625l-6.75-18-6.75 18M3.656 12.875h8.438"
        stroke={fill || "#68D391"}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Capitalize;
