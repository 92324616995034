// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const SubScript = styled.span`
  color: ${colors.secondary};
  font-size: 10px;
`;

export const TopSection = styled.div`
  text-align: center;
  padding-top: 1em;
  font-size: 10px;
  > div {
    position: relative;
  }
`;

export const Button = styled.button`
  color: ${colors.active};
  background: ${colors.brightLight};
  outline: none;
`;

export const Th = styled.div`
  font-size: 10px;
  text-align: center;
  box-shadow: 7px 6px 10px 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 0.3em;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: center;
    margin-left: -1.2em;
    > span {
      white-space: nowrap;
    }
  }
`;

export const User = styled.div`
  z-index: 41;
  position: sticky;
  border-right: 1px solid ${colors.grey1};
  background: ${colors.brightLight};
  left: 0;
  top: 0;
  height: 84px;
  box-shadow: -6px 6px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  > ${Th} {
    box-shadow: none;
    padding-bottom: 1.2em;
  }
`;

export const SubSection = styled.div`
  display: grid;
  z-index: 40;
  position: sticky;
  background: ${colors.brightLight};
  top: 0;
`;

export const Created = styled(SubSection)`
  grid-column-end: span 4;
  grid-template-columns: minmax(60px, 1fr) minmax(80px, 1fr) minmax(90px, 1fr) minmax(
      90px,
      1fr
    );
  ${TopSection} {
    grid-column-end: span 4;
  }
  ${Th} {
    background: ${colors.brightLight};
  }
`;

export const Pending = styled(SubSection)`
  grid-column-end: span 5;
  grid-template-columns:
    minmax(61px, 1fr) minmax(90px, 1fr) minmax(82px, 1fr) minmax(110px, 1fr)
    minmax(100px, 1fr);
  display: grid;
  background: ${colors.grey0};
  ${TopSection} {
    grid-column-end: span 5;
  }
  ${Th} {
    background: ${colors.grey0};
  }
`;

export const Completed = styled(SubSection)`
  grid-column-end: span 4;
  grid-template-columns: minmax(70px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) minmax(
      100px,
      1fr
    );
  display: grid;
  ${TopSection} {
    grid-column-end: span 4;
  }
  ${Th} {
    background: ${colors.brightLight};
  }
`;

export const LastActive = styled(SubSection)`
  background: ${colors.highlight};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.8em;
  box-shadow: 7px 6px 10px 0 rgba(0, 0, 0, 0.05);
  ${Th} {
    box-shadow: none;
    background: ${colors.highlight};
  }
`;
