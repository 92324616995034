// @flow

import { connect } from "react-redux";
import React from "react";

import { Title as StyledTitle } from "../styles";
import Edit from "./Edit";
import useBoolean from "src/hooks/useBoolean";

import type { AppState } from "src/types";

type Props = {
  title: string,
  id: number
};

const Title = ({ title, id }: Props) => {
  const { value: edit, toggleBoolean: toggleEdit, setFalse } = useBoolean(
    false
  );

  return (
    <>
      {edit ? (
        <Edit value={title} id={id} handleClose={setFalse} />
      ) : (
        <StyledTitle
          tabIndex="0"
          role="button"
          onClick={toggleEdit}
          onKeyPress={toggleEdit}
        >
          {title}
        </StyledTitle>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  title: app.dashboard.metaData.title,
  id: app.dashboard.metaData.id
});

export default connect(mapStateToProps)(Title);
