// @flow

import React from "react";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";
import {
  CaretUp,
  Count
} from "src/components/Manage/Workflow/Instances/Table/Row/ChecklistItem/styles";
import { Select as StyledSelect } from "./styles";

type Props = {
  value: Array<string>
};

const MultiSelect = ({ value }: Props) => {
  const { value: expand, toggleBoolean: toggleExpand } = useBoolean();

  if (expand) {
    return (
      <div>
        {value.map((id, index) => (
          <StyledSelect key={id}>
            {id}
            {index === 0 && (
              <CaretUp onClick={toggleExpand}>
                <Icon type="caretUp" />
              </CaretUp>
            )}
          </StyledSelect>
        ))}
      </div>
    );
  }

  return (
    <StyledSelect onClick={toggleExpand}>
      {value[0]}
      {value.length > 1 && <Count>+{value.length - 1}</Count>}
    </StyledSelect>
  );
};

export default MultiSelect;
