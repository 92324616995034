// @flow

import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import * as R from "ramda";

import Item from "./Item";
import FetchedItem from "./FetchedItem";
import { List as StyledList } from "./styles";
import VirtualList from "src/components/VirtualList";
import { RemoveOption } from "../Search/styles";
import Icon from "src/icons";
import {
  getFilteredChatroomExcluding,
  filterCurrentVersions
} from "src/reducers";
import { filterCurrentVersionsFromServer } from "./utils";

import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  showRemove: boolean,
  groupVersions: boolean,
  selectedValue: Array<number>,
  handleSelect: Function,
  handleRemove?: Function,
  selectNewRoomVersions?: Function,
  hideSelectedVersions?: boolean,
  dependentInclude?: ?Array<number>,
  dependentExclude?: ?Array<number>,
  alwaysShowRevisionIcon: boolean,
  chatRooms?: Object,
  isDataProcess?: boolean
};

const List = ({
  roomId,
  showRemove,
  selectedValue,
  hideSelectedVersions = false,
  groupVersions = false,
  selectNewRoomVersions,
  handleSelect,
  handleRemove,
  dependentInclude,
  dependentExclude,
  alwaysShowRevisionIcon,
  chatRooms = {},
  isDataProcess = false
}: Props) => {
  const rooms = R.uniq([parseInt(roomId, 10), ...selectedValue]);
  const results = useSelector(state =>
    getFilteredChatroomExcluding(state.app, rooms)
  );
  const filteredByVersions = useSelector(state =>
    filterCurrentVersions(
      state.app,
      results,
      hideSelectedVersions ? selectedValue : null
    )
  );

  // Function to filter by version when chatRooms
  // are not present in state or isDataProcess is true.
  const filteredByVersionsFromServer =
    isDataProcess && !R.isEmpty(chatRooms)
      ? filterCurrentVersionsFromServer(
          chatRooms,
          results,
          hideSelectedVersions ? selectedValue : null
        )
      : [];

  const result = groupVersions
    ? isDataProcess
      ? filteredByVersionsFromServer
      : filteredByVersions
    : results;

  const filteredResults: Array<number> = useMemo(() => {
    if (dependentInclude?.length) {
      return R.uniq(dependentInclude || []);
    } else if (dependentExclude?.length) {
      return R.uniq(R.without(dependentExclude || [], result));
    }

    return R.uniq(result);
  }, [result, dependentInclude, dependentExclude]);
  // $FlowFixMe - Type coercion to string

  const renderRow = useCallback(
    (index: number) => {
      const room = filteredResults[index];
      if (room) {
        return isDataProcess ? (
          <FetchedItem
            key={room}
            id={room}
            chatRooms={chatRooms}
            handleSelect={handleSelect}
            selectNewRoomVersions={selectNewRoomVersions}
            alwaysShowRevisionIcon={alwaysShowRevisionIcon}
          />
        ) : (
          <Item
            key={room}
            id={room}
            handleSelect={handleSelect}
            selectNewRoomVersions={selectNewRoomVersions}
            alwaysShowRevisionIcon={alwaysShowRevisionIcon}
          />
        );
      }
      return <></>;
    },
    [result, handleSelect, selectNewRoomVersions]
  );

  return (
    <StyledList data-cy="conversationDropdown">
      <ul tabIndex="-1">
        {showRemove && handleRemove && (
          <RemoveOption data-cy="clearConversation" onClick={handleRemove}>
            Remove
            <Icon type="checklistRemove" />
          </RemoveOption>
        )}

        <VirtualList
          initialAmount={20}
          progressiveAmount={20}
          role="list"
          renderItem={renderRow}
          renderLoader={() => <div />}
          rowCount={filteredResults.length}
        />
      </ul>
    </StyledList>
  );
};

export default List;
