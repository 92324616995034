// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import { ScrollableBox } from "src/styles/box";
import { Overlay as StyledOverlay } from "src/styles/modal";
import { Form as StyledForm } from "src/styles/form.old";

export const Form = styled(StyledForm)`
  padding-top: 73px;
`;

export const New = styled(ScrollableBox)`
  padding: ${spacing.space_inset_squish_m};
  margin-top: ${spacing.space_xs};
  > span {
    > div {
      color: ${colors.secondary};
    }
  }
`;

export const Modal = styled.div`
  > div {
    position: absolute;
    overflow: auto;
    padding: 0px;
    z-index: 1002;
    top: 78px;
    left: 11%;
    right: 32%;
    bottom: 0px;
    width: 85%;
    height: 80%;
    border-radius: 0px;
  }
`;

export const Overlay = styled(StyledOverlay)`
  z-index: 1000;
`;

export const InChatPrompts = styled.td`
  text-align: left !important;
  width: 230px !important;
  ${props => !props.hide && `visibility: hidden`};
`;

export const ParentConversation = styled.span`
  padding-left: 8px;
  white-space: nowrap;
  label {
    color: ${colors.active} !important;
  }
`;

export const StyledTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex-grow: 0;
`;

export const fieldTagStyles = {
  width: "170px",
  height: "32px",
  mr: "96px",
  "border-radius": "4px"
};

export const inputFieldStyles = {
  width: "495px",
  height: "32px",
  mr: "215px",
  borderRadius: "4px"
};

export const plusIconStyles = {
  marginLeft: "0.5rem",
  padding: 0
};

// Add new fields section

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Card = styled.div`
  width: 15rem;
  padding: 1.875rem;
  border: ${props =>
    !props.active ? `2px solid #cbd5e0` : `2px solid ${colors.blue500}`};
  border-radius: 8px;
  margin: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.15s ease;

  :hover {
    cursor: pointer;
    background: ${colors.grey28};
  }
`;

const Title = styled.h2`
  color: #1a202c;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Subtitle = styled.h4`
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1a202c;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Field = {
  Container,
  Card,
  Title,
  Subtitle,
  Icon
};
