// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const ParticipantCount = styled.span`
  padding-left: ${spacing.space_s};
  font-size: 10px;
  color: ${colors.secondary};
  font-weight: bold;
`;
