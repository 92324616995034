// @flow

import { SystemCSSProperties } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const ChartOverlay: SystemCSSProperties = {
  position: "fixed",
  left: "54px",
  right: 0,
  bg: colors.brightLight,
  overflowY: "auto",
  flexDirection: "column",
  height: "100vh"
};

export const Header: SystemCSSProperties = {
  alignItems: "center",
  fontSize: "2xl",
  fontWeight: "bold",
  position: "sticky",
  top: 0,
  p: "1rem",
  gap: "1rem",
  bg: colors.grey0,
  "> svg": {
    cursor: "pointer"
  }
};

export const ChartWrapper: SystemCSSProperties = {
  padding: "1.5rem",
  gap: "2rem",
  flexWrap: "wrap"
};

export const ChartImage: SystemCSSProperties = {
  cursor: "pointer",
  padding: "1rem",
  border: `1px solid ${colors.grey34}`,
  borderRadius: "5px",

  _hover: {
    border: `1px solid ${colors.active}`
  }
};
