// @flow

import React from "react";
import { connect } from "react-redux";

import type { AppState, ChecklistBuilderField } from "src/types";
import ConditionsBuilder from "../Conditions";
import {
  getChecklistFieldPosition,
  getCurrentChecklistBuilder
} from "src/reducers";

type Props = {
  checklistFieldPosition: number,
  currentFields: ChecklistBuilderField[],
  setLocalSettings: Function
};

const Conditions = ({
  checklistFieldPosition,
  currentFields,
  setLocalSettings
}: Props) => {
  const saveSettings = (settings: any) => {
    const prevSettings = currentFields[checklistFieldPosition].settings;
    const newSettings = {
      ...prevSettings,
      ...settings
    };

    setLocalSettings(newSettings);
  };

  const currentFieldId = currentFields.filter(
    (field, index) => index === checklistFieldPosition
  )[0].id;

  return (
    <div>
      <ConditionsBuilder
        saveSettings={saveSettings}
        position={checklistFieldPosition}
        currentFieldId={currentFieldId}
      />
    </div>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentFields: getCurrentChecklistBuilder(app).fields,
  checklistFieldPosition: getChecklistFieldPosition(app)
});

export default connect(mapStateToProps)(Conditions);
