// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ifProp } from "styled-tools";

import * as typography from "src/styles/constants/typography";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

export const Title = styled.form`
  display: inline-flex;
  font-weight: bold;
  align-items: flex-start;
  align-items: center;
  > div:first-of-type {
    font-size: 0.625rem;
    align-items: flex-end;
    margin-right: ${spacing.space_m};
  }
  > h4 {
    display: inline-block;
    white-space: nowrap;
    display: flex;
    margin: 0px;
    padding: 0 4px;
    > span:first-of-type {
      padding-right: ${spacing.space_s};
    }
    text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
    > div {
      text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
    }
  }
`;

export const OptionalTitle = styled.div`
  display: inline-block;
  max-width: 42vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: ${spacing.space_list_s};
  border: 1px solid ${colors.grey3};
  font-size: 0.88rem;
  line-height: 1.25rem;

  :hover {
    border: 1px solid ${colors.secondary};
    outline: none;
  }
`;

export const EditTitle = styled(Title)`
  input[type="text"] {
    height: 12px;
    border-radius: ${radius.small};
    background: ${colors.brightLight};
    border: 1px solid ${colors.grey4};
    max-width: 46vw;
    outline: none;
  }
`;

export const Small = styled.div`
  background: inherit;
  display: inline-block;
  min-width: 192px;
  width: 100%;
  grid-template-columns: 2em 1fr;
  font-weight: bold;
  text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
  color: ${colors.primary};
  font-size: 0.88rem;
  padding-right: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProcessIcon = styled.div`
  display: inline-block;
  width: 1.6em;
  height: 1em;
  position: relative;
  > svg {
    position: absolute;
    top: 1px;
  }
`;

const DirectEmail = css`
  position: absolute;
  top: 20px;
  left: 40px;

  > span {
    color: ${colors.secondary};
    line-height: 1rem;
    font-size: 0.75rem;
  }
`;

const NameAndImage = css`
  span:nth-of-type(3) {
    font-weight: normal;
    font-size: 0.75rem;
    color: ${colors.secondary};
  }
  > img {
    width: 2.3em;
    height: 2.3em;
    border-radius: 50%;
  }

  > span > span {
    top: 1px;
    font-size: 14px;
    font-weight: ${typography.semiBold};

    &:nth-of-type(2) {
      left: 4.4em;
    }
  }
`;

export const TitleHeader = styled.div`
  color: initial;
  display: inline;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  vertical-align: bottom;
  height: 3.6em; /* exactly three lines */
  text-overflow: -o-ellipsis-lastline;
  margin: 0;
  margin-right: ${spacing.space_xs};
  text-decoration: ${ifProp({ canceled: true }, "line-through", "none")};
  max-width: 150px;
  line-height: 1rem;
  font-weight: ${typography.semiBold};
  font-size: 0.88rem;

  > div {
    ${DirectEmail}
  }

  > svg {
    margin-right: 0.6em;
  }

  > span {
    ${NameAndImage}
    > div {
      border-radius: 50%;
    }
  }
`;

export const NoTitle = styled.span`
  font-style: normal;
  color: ${colors.secondary};
`;
