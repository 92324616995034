// @flow

import styled from "@emotion/styled";

import { ButtonLink } from "src/styles/buttons";
import * as spacing from "src/styles/constants/spacing";

export const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Count = styled.h3`
  display: inline-block;
  margin: ${spacing.space_m} 0;
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(ButtonLink)`
  display: inline-block;
  margin-right: ${spacing.space_xl};
  font-size: 10px;
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${spacing.space_s};
    height: 1em;
    width: 1em;
  }
`;

export const InstanceCount = styled(Count)`
  margin: 0;
`;

export const styles = {
  menuButton: {
    borderColor: "unifize_blue",
    backgroundColor: "unifize_brightLight",
    color: "unifize_blue",
    size: "xs",
    variant: "outline"
  }
};
