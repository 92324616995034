// @flow

import React from "react";

type Props = {
  width?: string,
  height?: string
};

const VerificationSent = ({ width, height, ...restProps }: Props) => (
  <svg
    width={width || "64"}
    height={height || "64"}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M12 22L2 27.8V62H62V27.8L52 22"
      stroke="#2B6CB0"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 33.4V2H52V33.2"
      stroke="#2B6CB0"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 27.8L62 62"
      stroke="#2B6CB0"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.9992 27.8L31.1992 44.4"
      stroke="#2B6CB0"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 14H42"
      stroke="#1A202C"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 24H42"
      stroke="#1A202C"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerificationSent;
