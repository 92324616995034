// @flow

import { connect } from "react-redux";
import React, { useCallback, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Input,
  FormHelperText
} from "@chakra-ui/react";
import FieldSelect from "../FieldSelect";
import Switch from "./Switch";
import MultipleSelection from "./MultipleSelection";
import {
  setNewChartDialogAttributes,
  createChart,
  updateChart
} from "src/actions/chart";

import type { AppState, NewChartDialog } from "src/types";
import SortBySelector from "./SortBySelector";
import { AdvanceBtn, LabelInfo } from "./styles";
import { dark, inkLight } from "src/styles/constants/colors";

type Props = {
  newChartDialog: NewChartDialog,
  _editChart: Function,
  _createChart: Function,
  _setNewChartDialogAttributes: Function
};

const NumericalBarChart = ({
  newChartDialog,
  _editChart,
  _createChart,
  _setNewChartDialogAttributes
}: Props) => {
  const [showAdvance, setShowAdvance] = useState(false);

  const handleYaxisLabel = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        yAxisLabel: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handlePrimaryField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        x: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleNumericalField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        computationField: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleGroupByField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        y: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleFields = useCallback(() => {
    _setNewChartDialogAttributes({
      loading: true
    });
    if (newChartDialog.edit) {
      _editChart();
    } else {
      _createChart();
    }
  }, [_setNewChartDialogAttributes, _editChart, _createChart, newChartDialog]);

  const handleShowAdvanceToggle = e => {
    // Since the button is inside a form, using this to prevent the
    // form from submitting
    e.preventDefault();
    setShowAdvance(!showAdvance);
  };
  return (
    <>
      <FormControl id="primaryField" mb={3}>
        <FormLabel fontWeight={600}>
          Primary field
          <LabelInfo>(x-axis)</LabelInfo>
        </FormLabel>
        <FormHelperText color={inkLight}>
          Enter the title of your chart
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.x}
          dependentField={newChartDialog.relativeCount}
          handleSelect={handlePrimaryField}
        />
      </FormControl>
      <FormControl id="numericalField" mb={3}>
        <FormLabel mr="0">
          Numeric checklist field
          <Switch value={newChartDialog.numberFieldSwitch || false} />
        </FormLabel>
        <FormHelperText color={inkLight}>
          This field will contain the numerical data to add, like $ values,
          total time, or units
        </FormHelperText>
        <FieldSelect
          disable={!(newChartDialog.numberFieldSwitch || false)}
          value={newChartDialog.computationField}
          dependentField={newChartDialog.computationField}
          handleSelect={handleNumericalField}
          fieldType="numeric"
          numberField
        />
      </FormControl>
      <FormControl id="groupByField" mb={3}>
        <FormLabel fontWeight={600}>
          Group by field
          <LabelInfo>(Optional)</LabelInfo>
        </FormLabel>
        <FormHelperText color={inkLight}>
          Group by field type is applied on primary fields to create a chart
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.y}
          dependentField={newChartDialog.x}
          handleSelect={handleGroupByField}
        />
      </FormControl>
      <AdvanceBtn onClick={handleShowAdvanceToggle}>
        Advanced settings
        {showAdvance ? (
          <ChevronUpIcon marginLeft="1.625rem" color={dark} />
        ) : (
          <ChevronDownIcon marginLeft="1.625rem" color={dark} />
        )}
      </AdvanceBtn>
      {showAdvance && (
        <>
          <FormControl id="multipleValue" mb={3}>
            <FormLabel fontWeight={600}>Multiple Value Calculation</FormLabel>
            <FormHelperText color={inkLight}>
              If a field contains multple values per conversation, select what
              the y-axis totals represent
            </FormHelperText>
            <MultipleSelection value={newChartDialog.relativeCount} />
          </FormControl>
          <FormControl id="sortBy" mb={3}>
            <FormLabel fontWeight={600}>Sort By</FormLabel>
            <SortBySelector
              setNewChartDialogAttributes={_setNewChartDialogAttributes}
              value={newChartDialog.sort.orderBy || "default"}
            />
          </FormControl>
          <FormControl id="yAxisLabel" mb={3}>
            <FormLabel fontWeight={600}>Y-axis label</FormLabel>
            <FormHelperText color={inkLight}>
              Label for Y-axis of the chart
            </FormHelperText>
            <Input
              type="text"
              value={newChartDialog.yAxisLabel}
              onChange={handleYaxisLabel}
              placeholder="Enter Label"
              size="sm"
            />
          </FormControl>
        </>
      )}
      <Box mb={3} mt={6}>
        {newChartDialog.edit ? (
          <Button
            onClick={handleFields}
            disabled={
              newChartDialog.x === null || newChartDialog.title.length === 0
            }
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Save changes
          </Button>
        ) : (
          <Button
            onClick={handleFields}
            disabled={
              newChartDialog.x === null || newChartDialog.title.length === 0
            }
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Create chart
          </Button>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _createChart: createChart,
  _editChart: updateChart
})(NumericalBarChart);
