// @flow

// Unifize colors
export const unifizeBlue = "#2e4c68";
export const blue = "#1581ba";
export const lightBlue = "#f1f5f9";
export const green = "#0abc8a";
export const orange = "#fc7a57";
export const dullWhite = "#F6F9FD";

export const webBlack = "#000000";
export const mediumBlack = "#2a2e36";
export const black = "#2a2a2a";
export const clearBlack = "rgba(205, 210, 217, 0.5)";
export const grey = "#dddddd";
export const springGrey = "#e7e8ec";
export const darkGrey = "#acbbc9";
export const greenGrey = "#e9ebec";
export const lightGrey = "#969696";
export const mediumGrey = "#696c72";
export const defaultGrey = "#888888";
export const manatee = "#989ca6";
export const silver = "#bbbbbb";
export const aliceBlue = "#f5f6f7";
export const pureWhite = "#FFFFFF";
export const strongGrey = "#B3B3B3";
export const dullGrey = "#4b4b4b";
export const normalizedGrey = "#d2d2d2";
export const menuItemGrey = "rgba(75,75,75,0.8)";

// TODO: Check if color is used in more than one place - used in components/Input/styles.js
// If not, does it make sense to have this from a consistency point of view?
export const glitter = "#E8F0F8";

// TODO: Remove Old color values below
export const simpleGrey = "#e6e6e6";
export const boldGreen = "#97db6b";
export const clearBlue = "#7fc3eb";
export const midGrey = "#807e82";
export const Gray98 = "#fafafa";
// Additional colours
export const red = "#ff0000";
export const opaqueGrey = "#e6e6e69e";
export const brightBlue = "#3F46AD";
export const paleBlue = "#d9e7f4";

export const isabelline = "#E9EBEC";
export const sunsetOrange = "#FA5E5B";

// Colors with alpha
export const transparentBlack = "rgba(0, 0, 0, 0.5)";
export const transparent = "rgba(0,0,0,0)";

// New colors
export const error = "#FA5E5B";
export const Success = "#16C98D";

// Login styles To-do: Move to style guide
export const backgroundWithGradient =
  "linear-gradient(to bottom, #2e4c68, rgba(46, 76, 104, 0.7))";
export const loginFormBoxShadowColor = "rgba(42, 42, 42, 0.08)";

// Setup Org
export const setupFormBoxShadowColor = "rgba(0, 0, 0, 0.08)";
export const disabledBorderGrey = "#979797";
export const setupBlack = "#2b2c2e";
export const setupBackground = "#f1f5fa";

export const primary = "#2A2A2A";
export const brightLight = "#ffffff";
