// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

export const setOwnerTrigger = (trigger: Function): Action => ({
  type: atypes.SET_OWNER_TRIGGER,
  payload: trigger
});

export const setOwnerTriggerFromInfoPanel = (trigger: boolean): Action => ({
  type: atypes.SET_OWNER_TRIGGER_FROM_INFO_PANEL,
  payload: trigger
});

export const setParticipantTrigger = (trigger: Function): Action => ({
  type: atypes.SET_PARTICIPANT_TRIGGER,
  payload: trigger
});

export const setPriorityTrigger = (trigger: Function): Action => ({
  type: atypes.SET_PRIORITY_TRIGGER,
  payload: trigger
});

export const setStatusTrigger = (trigger: Function): Action => ({
  type: atypes.SET_STATUS_TRIGGER,
  payload: trigger
});
