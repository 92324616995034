// @flow

import { StackProps, BoxProps } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const container: StackProps = {
  alignItems: "start",
  height: "100vh"
};

export const footer: StackProps = {
  width: "100%",
  backgroundColor: colors.skyLighter,
  px: 5,
  py: 4
};

export const content: BoxProps = {
  width: "100%",
  height: "100%",
  overflow: "auto"
};

export const formContainer: StackProps = {
  py: 4,
  pl: 5,
  alignItems: "start",
  width: "450px"
};
