// @flow

import React, { useCallback } from "react";

import Icon from "src/icons";

type Props = {
  read: boolean,
  delivered: boolean
};

const ReadStatus = ({ read, delivered }: Props) => {
  const getStatus = useCallback(() => {
    if (read) return "read";
    if (delivered) return "delivered";
    return "unread";
  }, [read, delivered]);

  return <Icon type="readStatus" status={getStatus()} />;
};

export default ReadStatus;
