// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { Modal as StyledModal, DomainName, Label } from "./styles";
import ButtonLoader from "src/components/ButtonLoader";
import { Secondary } from "src/styles/buttons";
import {
  Form as StyledForm,
  SecondaryFooter as Footer,
  ModalFormBody,
  FormGroup,
  InlineLabel
} from "src/styles/form";

import { ErrorText } from "src/styles/auth";

import { Input as StyledInput } from "src/styles/input";

import { createNewOrg } from "src/actions/organization";
import { toggleCreateOrgModal } from "src/actions/modal";
import Checkbox from "src/components/Checkbox.old";

import { getSignUpError, getModalState } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  createNewOrg: Function,
  toggleCreateOrgModal: Function,
  setupOrgError: any,
  closeMenu: Function
};

type State = {
  title: string,
  member1: string,
  member2: string,
  member3: string,
  domainName: ?string,
  domainAutoJoin: boolean,
  loading: boolean
};

class NewOrgModal extends Component<Props, State> {
  state = {
    title: "",
    member1: "",
    member2: "",
    member3: "",
    domainName: null,
    domainAutoJoin: true,
    loading: false
  };

  createOrg = (e: any) => {
    e.preventDefault();
    const { createNewOrg } = this.props;
    const {
      title,
      member1,
      member2,
      member3,
      domainAutoJoin,
      domainName
    } = this.state;
    this.setState({ loading: true }, () =>
      createNewOrg({
        title,
        member1,
        member2,
        member3,
        domainAutoJoin,
        domainName
      })
    );
  };

  closeModal = () => {
    const { toggleCreateOrgModal } = this.props;
    toggleCreateOrgModal({ open: false });
  };

  handleChange = (e: Object) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  closeMenuAndNav = () => {
    const { closeMenu } = this.props;
    closeMenu();
  };

  handleCheck = (e: any) => {
    const { checked } = e.target;
    this.setState({ domainAutoJoin: checked });
  };

  render() {
    const { setupOrgError } = this.props;
    const {
      title,
      member1,
      member2,
      member3,
      loading,
      domainName,
      domainAutoJoin
    } = this.state;
    return (
      <StyledModal id="create-org-modal" onMouseEnter={this.closeMenuAndNav}>
        <Modal
          isOpen
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.querySelector("#create-org-modal")}
        >
          <StyledForm onSubmit={this.createOrg}>
            <h4>Create New Org</h4>
            <ModalFormBody>
              <FormGroup>
                <InlineLabel>Team Name</InlineLabel>
                <StyledInput
                  type="text"
                  name="title"
                  placeholder="Enter team name"
                  value={title}
                  onChange={this.handleChange}
                  required
                />
                {setupOrgError && (
                  <ErrorText>{setupOrgError.message}</ErrorText>
                )}
              </FormGroup>
              <FormGroup>
                <InlineLabel>Invite your team members</InlineLabel>
              </FormGroup>
              <FormGroup>
                <InlineLabel>Team member (optional)</InlineLabel>
                <StyledInput
                  type="text"
                  name="member1"
                  placeholder="Team member (optional)"
                  value={member1}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup>
                <InlineLabel>Team member (optional)</InlineLabel>
                <StyledInput
                  type="text"
                  name="member2"
                  placeholder="Team member (optional)"
                  value={member2}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <InlineLabel>Team member (optional)</InlineLabel>
                <StyledInput
                  type="text"
                  name="member3"
                  placeholder="Team member (optional)"
                  value={member3}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <InlineLabel>
                  Domain name
                  {!domainAutoJoin ? " (optional)" : null}
                </InlineLabel>
                <StyledInput
                  type="text"
                  name="domainName"
                  placeholder="Domain name (optional)"
                  value={domainName}
                  required={domainAutoJoin}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <>
                  <Checkbox
                    id="domainAutoJoin"
                    checked={domainAutoJoin}
                    handleChange={e => this.handleCheck(e)}
                  />
                  <Label>
                    {domainName ? (
                      <>
                        <span>Allow anyone with</span>
                        <DomainName title={domainName}>
                          {domainName}
                        </DomainName>{" "}
                        <span>email to join this team.</span>
                      </>
                    ) : (
                      <>
                        <span>
                          Allow anyone with given domain join this team
                        </span>
                      </>
                    )}
                  </Label>
                </>
              </FormGroup>
            </ModalFormBody>
            <Footer>
              <Secondary type="button" onClick={this.closeModal}>
                Cancel
              </Secondary>
              <ButtonLoader
                type="submit"
                disabled={(title || "").length === 0}
                isLoading={!setupOrgError && loading}
              >
                Create
              </ButtonLoader>
            </Footer>
          </StyledForm>
        </Modal>
      </StyledModal>
    );
  }
}

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  setupOrgError: getSignUpError("setupOrg")(app),
  showCreateOrgModal: getModalState("createOrg")(app),
  ...props
});

export default connect(mapStateToProps, {
  toggleCreateOrgModal,
  createNewOrg
})(NewOrgModal);
