// @flow
import React from "react";

import Standard from "./StatusBadge";
import Icons from "src/icons";
import {
  StatusItemWrapper,
  SecondaryText
} from "src/components/chatroom-nav/filter/styles";

type Props = {
  secondaryText: string,
  active: boolean,
  setCurrentStatusHandler: Function,
  activeFilterText: string,
  badgeId: number,
  disabled?: boolean,
  homeScreen?: boolean
};

const StatusItem = ({
  secondaryText,
  active,
  activeFilterText,
  setCurrentStatusHandler,
  badgeId,
  disabled,
  homeScreen
}: Props) => (
  <StatusItemWrapper
    active={active}
    onClick={() => setCurrentStatusHandler(activeFilterText)}
    onkeypress={() => setCurrentStatusHandler(activeFilterText)}
    disabled={disabled}
    homeScreen={homeScreen}
  >
    <div>
      {badgeId ? <Standard id={badgeId} /> : "CUSTOM"}
      <SecondaryText>{secondaryText}</SecondaryText>
    </div>
    {active ? (
      <Icons type="activeRadioButton" />
    ) : (
      <Icons type="defaultRadioButton" />
    )}
  </StatusItemWrapper>
);

export default StatusItem;
