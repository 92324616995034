// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";

import Single from "./Single";
import useFields from "../useFields";

import {
  getChecklistFieldValue,
  setChecklistValue
} from "src/actions/checklist";
import {
  getChecklistValue,
  getChecklistFieldDetails,
  getRoomFieldValueStatus,
  getLockedStatus
} from "src/reducers";

import { dataStages } from "src/constants";
import httpMethods from "src/constants/httpMethods";
import type { RoomId, ColumnId, FieldId } from "src/types";

export type AddForm = (templateId: number) => void;
export type RemoveForm = (templateId: number) => void;

type Props = {
  fieldId: FieldId,
  checklistId: number,
  roomId: RoomId,
  formId?: number,
  columnId?: ColumnId,
  embeddedIndex?: number,
  fromManageView?: boolean
};

const Form = ({ fieldId, roomId, checklistId, formId }: Props) => {
  const dispatch = useDispatch();
  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const valueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const locked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );

  const { value, settings } = useFields({
    checklistValue,
    details
  });

  const multiple = (settings || {}).multiple || false;
  const selectedForms = (settings || {}).selectedForms || [];

  const fetching =
    !valueStatus ||
    [dataStages.idle, dataStages.fetching].includes(valueStatus);

  // For manage view, since the API does not have form field values
  useEffect(() => {
    if (R.isNil(valueStatus)) {
      dispatch(getChecklistFieldValue(roomId, fieldId, true));
    }
  }, [valueStatus]);

  const addForm: AddForm = templateId => {
    dispatch(
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: {
            templateId
          },
          type: "form",
          checked: true
        },
        progress: true,
        formId,
        httpMethod: httpMethods.patch
      })
    );
  };

  const removeForm: RemoveForm = templateId => {
    dispatch(
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: templateId,
          type: "form",
          checked: true
        },
        progress: true,
        formId,
        httpMethod: httpMethods.delete
      })
    );
  };

  if (!multiple) {
    return (
      <Single
        fetching={fetching}
        selectedForms={selectedForms}
        value={value}
        index={0}
        fieldId={fieldId}
        roomId={roomId}
        checklistId={checklistId}
        disabled={locked}
        addForm={addForm}
        removeForm={removeForm}
      />
    );
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      {(value || []).length > 0 ? (
        (value || [])
          .sort((a, b) => a - b)
          .map((id, index) => (
            <Single
              key={id}
              fetching={fetching}
              selectedForms={selectedForms}
              value={value}
              index={index}
              fieldId={fieldId}
              roomId={roomId}
              checklistId={checklistId}
              multiple
              last={index === value.length - 1}
              disabled={locked}
              addForm={addForm}
              removeForm={removeForm}
            />
          ))
      ) : (
        <Single
          fetching={fetching}
          selectedForms={selectedForms}
          value={value || []}
          fieldId={fieldId}
          index={0}
          roomId={roomId}
          checklistId={checklistId}
          multiple
          disabled={locked}
          addForm={addForm}
          removeForm={removeForm}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getChecklistFieldValue
};

export default connect(null, mapDispatchToProps)(Form);
