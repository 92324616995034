// @flow
import React from "react";

const DownloadBlue = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MdOutlineFileDownload">
        <path
          id="Vector"
          d="M14.9999 12.5002V15.0002H4.99992V12.5002H3.33325V15.0002C3.33325 15.9168 4.08325 16.6668 4.99992 16.6668H14.9999C15.9166 16.6668 16.6666 15.9168 16.6666 15.0002V12.5002H14.9999ZM14.1666 9.16683L12.9916 7.99183L10.8333 10.1418V3.3335H9.16658V10.1418L7.00825 7.99183L5.83325 9.16683L9.99992 13.3335L14.1666 9.16683Z"
          fill="#1581BA"
        />
      </g>
    </svg>
  );
};

export default DownloadBlue;
