// @flow

import { Set } from "immutable";
import uuid from "uuid/v4";
import axios from "axios";
import * as easyFetch from "src/utils/fetch";

import { rsf } from "../db";

import { backendUrl } from "src/config/firebase";

import type { UnifizeFile, RoomId, ProcessTemplateId } from "src/types";

type FileRequestData = {
  name: string,
  url?: string,
  size: number,
  generation?: number,
  originalName: string,
  folderId: ?number,
  mimeType: string
};

export const getFile = async (name: string) => {
  const response = await fetch(`${backendUrl}/file/${name}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  const result = await response.json();
  return result;
};

/**
 * New api to fetch details of a file
 * @param {string} chatroomId - id of current chatroom
 * @param {string} name - name of file
 * @return {Object} file details object
 */
export const getFileV2 = async (chatroomId: string, name: string) => {
  const response = await easyFetch.get(`/chatroom/${chatroomId}/file/${name}`);
  const result = await response.json();
  return result;
};

export const uploadFileToStorage = async (name: string, fileData: Object) => {
  const fileRef = await rsf.storage.uploadFile(name, fileData);
  const downloadUrl = await fileRef.ref.getDownloadURL();
  return {
    name: fileRef.metadata.fullPath,
    url: downloadUrl,
    size: fileRef.metadata.size,
    generation: fileRef.metadata.generation
  };
};

export const createFile = async (fileData: FileRequestData) => {
  const response = await fetch(`${backendUrl}/file`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(fileData)
  });
  const result = await response.json();
  return result;
};

export const updateFileVersion = async (
  fileName: string,
  generation: number,
  url: string,
  size: number,
  description?: string
) => {
  await fetch(`${backendUrl}/file/${fileName}/${generation}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ url, size, description })
  });
};

export const pinFile = async (
  chatRoomId: string,
  name: string,
  description?: string
) => {
  await fetch(`${backendUrl}/chatroom/${chatRoomId}/file`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ name, description })
  });
  return { name, description, chatroomId: chatRoomId };
};

export const unpinFile = async (roomId: RoomId, name: string) =>
  fetch(`${backendUrl}/chatroom/${roomId}/file/${name}`, {
    credentials: "include",
    method: "DELETE"
  });

export const uploadFile = async (
  fileData: Object,
  folderId: number | null,
  file?: UnifizeFile,
  description?: string
) => {
  const storageFileName = file ? file.name : uuid();
  const response = await uploadFileToStorage(storageFileName, fileData);
  const fileDetails = {
    ...response,
    folderId,
    originalName: fileData.name,
    mimeType: fileData.type
  };
  if (file) {
    await updateFileVersion(
      file.name,
      response.generation,
      fileDetails.url,
      fileDetails.size,
      description
    );
    return fileDetails;
  }
  return createFile(fileDetails);
};

export const uploadFileToChatRoom = async (
  fileData: Object,
  roomId: string,
  description?: string,
  file?: UnifizeFile
) => {
  const fileResponse = await uploadFile(fileData, null, file, description);
  if (!file) {
    await pinFile(roomId, fileResponse.name, description);
    return {
      file: fileResponse,
      pin: { name: fileResponse.name, description, chatroomId: roomId }
    };
  }
  return fileResponse;
};

export const getFileBlob = async (name: string, generation: number) => {
  const response = await fetch(`${backendUrl}/file/${name}/${generation}`, {
    credentials: "include",
    method: "GET"
  });
  const fileBlob = await response.blob();
  return fileBlob;
};

export const createFolder = async (folderData: {
  name: string,
  parentId: number | null
}) => {
  const response = await fetch(`${backendUrl}/folder`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(folderData)
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const renameFolder = async (folderId: number, name: string) => {
  const response = await fetch(`${backendUrl}/folder/${folderId}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ name })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const moveFolder = async (folderId: number, parentId: number | null) => {
  const response = await fetch(`${backendUrl}/folder/${folderId}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ parentId })
  });
  return response;
};

export const renameFile = async (fileId: string, name: string) => {
  const response = await fetch(`${backendUrl}/file/${fileId}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ name })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const moveFile = async (name: string, folders: Set<number>) => {
  const response = await fetch(`${backendUrl}/file/${name}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ folders })
  });
  return response;
};

export const deleteFile = async (name: string) => {
  const response = await fetch(`${backendUrl}/file/${name}`, {
    credentials: "include",
    method: "DELETE"
  });
  return response;
};

export const fetchFolder = async (id: number | null) => {
  const url = id ? `${backendUrl}/folder/${id}` : `${backendUrl}/folder`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const deleteFolder = async (folderId: number) => {
  const response = await fetch(`${backendUrl}/folder/${folderId}`, {
    credentials: "include",
    method: "DELETE"
  });
  return response;
};

export const fetchChatRoomFile = async (roomId: number) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}/file`, {
    credentials: "include",
    method: "GET"
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

/**
 * Search files in the org
 * @param {string} searchText String to search files with
 * @return Array<UnifizeFile>
 */
export const searchFile = async (searchText: string) => {
  const response = await fetch(`${backendUrl}/file?title=${searchText || ""}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const restoreVersion = async (name: string, generation: number) => {
  const response = await fetch(`${backendUrl}/file/${name}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ generation })
  });
  return response;
};

export const uploadFileToTempStorage = async (
  fileName: string,
  fileData: UnifizeFile
) => {
  const snapshot = await rsf.storage.uploadFile(`temp/${fileName}`, fileData);
  return {
    name: fileName,
    originalName: fileData.name,
    generation: snapshot.metadata.generation
  };
};

/**
 * Fetch full quality image download url
 * @param {string} name - name of the file in uuid format
 * @param {number} generation - file generation id if available
 * @param {string} versionId - version ID of the file if available
 * @param {string} format - param to fetch file in specific format
 * @return {string} file url
 */
export const fetchFileDownloadURL = async (
  name: string,
  generation: number,
  versionId: ?string,
  format: string
) => {
  const formatParam = format ? `?format=${format}` : "";

  const url = `${backendUrl}/file-url/${name}/${
    versionId ? `${versionId}${formatParam}` : generation
  }`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });

  const jsonResponse = await response.json();

  return jsonResponse.url;
};

/**
 * New api to fetch file download url
 * @param {string} name - name of the file in uuid format
 * @param {number} generation - file generation id if available
 * @param {string} versionId - version ID of the file if available
 * @param {string} format - param to fetch file in specific format
 * @return {Object} object containing file url
 */
export const fetchFileDownloadUrlV2 = async ({
  roomId,
  name,
  generation,
  versionId,
  format
}: {
  roomId: RoomId,
  name: string,
  generation: number,
  versionId: ?string,
  format: string
}) => {
  const formatParam = format ? `?format=${format}` : "";

  const result = await easyFetch.get(
    `/chatroom/${roomId}/download-url/${name}/${
      versionId ? `${versionId}${formatParam}` : generation
    }`
  );
  return result;
};

export const fetchFileUrlV2 = ({
  roomId,
  name,
  generation,
  versionId
}: {
  roomId: RoomId,
  name: string,
  generation: number,
  versionId: ?string
}) =>
  easyFetch.get(
    `/chatroom/${roomId}/file-url/${name}/${versionId ? versionId : generation}`
  );

/**
 * add files to checklist
 * @param {string} query search query text
 * @return {Object} file data
 */
export const searchOrgFiles = async (query: string) => {
  const url = `${backendUrl}/file?title=${query}`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

/**
 * get all org files
 * @return {Object} list of org files
 */
export const fetchOrgFiles = async () => {
  const url = `${backendUrl}/file`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

/**
 * add files to checklist
 * @param {string} chatRoomId roomId of filel uploaded from
 * @param {Object} firebaseResponse response from firebase
 * @param {number} fieldId fieldId from file uploaded from
 * @param {boolean} multiple
 * @return {Object} response
 */
export const addFileToChecklist = async (
  chatRoomId: string,
  fileResponse: Object,
  fieldId: number,
  multiple: boolean,
  formId: ?number
) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${chatRoomId}/checklist-field/${fieldId}`,
    {
      credentials: "include",
      method: "PATCH",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        type: "file",
        checked: true,
        value: fileResponse,
        formId
      })
    }
  );
  return response.json();
};

/**
 * Uploads files to checklist
 * @param {Object} fileData uploaded file data
 * @param {string} roomId roomId of file uploaded from
 * @param {Object} firebaseResponse response from firebase
 * @param {number} fieldId fieldId from file uploaded from
 * @param {Array} value array of file names
 * @param {boolean} multiple
 * @return {Object} response
 */
export const uploadFileToChecklist = async (
  fileData: Object,
  roomId: string,
  fileMetaData: Object,
  fieldId: number,
  multiple: boolean,
  formId: ?number
) => {
  const updatedValue = await addFileToChecklist(
    roomId,
    fileMetaData,
    fieldId,
    multiple,
    formId
  );
  return updatedValue;
};

export const getSignedURLForUpload = async (originalName: string) => {
  const response = await fetch(
    `${backendUrl}/upload?originalName=${encodeURIComponent(originalName)}`,
    {
      credentials: "include",
      method: "GET"
    }
  );
  return response.json();
};

export const uploadFileToAWS = async ({
  signedURL,
  contentType,
  fileData,
  handleProgress
}: {
  signedURL: string,
  contentType: string,
  fileData: Object,
  handleProgress: Function
}) => {
  const response = await axios.put(`${signedURL}`, fileData, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": contentType || fileData?.type,
      "Content-Disposition": `attachment; filename="${encodeURIComponent(
        fileData?.name
      )}"`
    },
    onUploadProgress: handleProgress
  });
  return response;
};

export const getSignedURLForEmailAttachment = async ({
  originalName
}: {
  originalName: string
}) => {
  const response = await fetch(
    `${backendUrl}/upload/email/attachment?originalName=${encodeURIComponent(
      originalName
    )}`,
    {
      credentials: "include",
      method: "GET"
    }
  );
  return response.json();
};

/**
 * Get preview url for given file
 * @param {name} name of the file
 */
export const getPreview = async ({
  name,
  roomId
}: {
  name: string,
  roomId: RoomId
}) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/preview-url/${name}`,
    {
      credentials: "include",
      method: "GET"
    }
  );

  return response.json();
};

/**
 * Get thumbnail url for given file
 * @param {name} name of the file
 */
export const getThumbnail = async ({
  name,
  roomId
}: {
  name: string,
  roomId: RoomId
}) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/thumbnail-url/${name}`,
    {
      credentials: "include",
      method: "GET"
    }
  );

  return response.json();
};

/**
 * Get custom signature upload URL
 * @param {string} name - file name of signature
 */
export const getSignatureUploadURL = (name: string) =>
  easyFetch.get(`/signature/upload-url?name=${encodeURIComponent(name)}`);

export const createSignature = () => easyFetch.post(`/signature`);

/**
 * Get presigned URL for process import
 * @param {number} templateId - process template id
 * @param {string} name - file name
 */

export const getPresignedURLForProcessImport = ({
  templateId,
  fileName
}: {
  templateId: ProcessTemplateId,
  fileName: string
}) =>
  easyFetch.get(
    `/import/upload-url/${templateId}?originalName=${encodeURIComponent(fileName)}`
  );

/**
 * Get edit url for sharepoint checkist files
 * @param {name} name of the file
 */
export const getSharepointEditUrl = async ({
  name,
  roomId
}: {
  name: string,
  roomId: RoomId
}) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/edit-url/${name}`,
    {
      credentials: "include",
      method: "GET"
    }
  );

  return response.json();
};
