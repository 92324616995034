// @flow

import { connect } from "react-redux";
import React from "react";
import { FormControl, Input } from "@chakra-ui/react";

import { setSegmentUserModalAttributes } from "src/actions/orgSettings";
import type { AppState } from "src/types";

type Props = {
  title: string,
  _setSegmentUserModalAttributes: Function
};

const Title = ({ title, _setSegmentUserModalAttributes }: Props) => {
  const handleTitle = (event: any) => {
    _setSegmentUserModalAttributes({ title: event.target.value });
  };

  return (
    <FormControl id="chartTitle" isRequired mb={3}>
      <Input
        data-testid="segment-input"
        type="text"
        value={title}
        onChange={handleTitle}
        placeholder="Title"
        size="sm"
        autoFocus
      />
    </FormControl>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  title: app.orgSettings.segmentUserModal.title
});

export default connect(mapStateToProps, {
  _setSegmentUserModalAttributes: setSegmentUserModalAttributes
})(Title);
