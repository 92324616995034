// @flow

import React from "react";

type Props = {};

const ChecklistRemove = ({ ...restProps }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#BBBBBB"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8.46 11.88L9.87 10.47L12 12.59L14.12 10.47L15.53 11.88L13.41 14L15.53 16.12L14.12 17.53L12 15.41L9.88 17.53L8.47 16.12L10.59 14L8.46 11.88ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" />
    </svg>
  );
};

export default ChecklistRemove;
