// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const NewValue = styled.span`
  color: ${colors.primary};
  display: inline-block;
  max-width: 10em !important;
  padding-left: 0.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
