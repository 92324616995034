// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 10px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
}

.Tip__card textarea {
  font-size: 16px;
  width: 200px;
  height: 70px;
}

.Tip__card input[type="submit"] {
  margin-top: 5px;
  font-size: large;
}

.Tip__card .header {
  display: flex;
  color: #4b4b4b;
}

.Tip__card .header .close {
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.Tip {
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/Tip.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,2CAA2C;EAC3C,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".Tip__compact {\n  cursor: pointer;\n  background-color: #3d464d;\n  border: 1px solid rgba(255, 255, 255, 0.25);\n  color: white;\n  padding: 5px 10px;\n  border-radius: 3px;\n}\n\n.Tip__card {\n  padding: 10px;\n  background: #fff;\n  background-clip: padding-box;\n  border: 1px solid #e8e8e8;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);\n}\n\n.Tip__card textarea {\n  font-size: 16px;\n  width: 200px;\n  height: 70px;\n}\n\n.Tip__card input[type=\"submit\"] {\n  margin-top: 5px;\n  font-size: large;\n}\n\n.Tip__card .header {\n  display: flex;\n  color: #4b4b4b;\n}\n\n.Tip__card .header .close {\n  right: 10px;\n  position: absolute;\n  cursor: pointer;\n}\n\n.Tip {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
