// @flow

import styled from "@emotion/styled";

import { Overlay as DefaultOverlay } from "src/styles/modal";
import { Form } from "src/styles/form";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

export const StatusRemove = styled(Form)`
  position: fixed;
  top: 120px;
  left: 30%;
  z-index: 1002;
  min-width: 79px;
  width: 29%;
`;

export const Overlay = styled(DefaultOverlay)`
  z-index: 1000;
`;

export const Body = styled.div`
  padding: 0 0.7em;
  min-height: 57px;
  background: ${colors.brightLight};
  min-height: 80px;
  margin: ${spacing.space_inset_default};
`;

export const Footer = styled.div`
  > a {
    display: block;
    padding: ${spacing.space_inset_squish_m};
    background: ${colors.active};
    color: ${colors.brightLight};
    border: 1px solid ${colors.active};
    border-radius: ${radius.small};
    cursor: pointer;
    text-align: center;
    width: 91%;
    margin: auto;
    :hover,
    :focus {
      border: 1px solid ${colors.outline};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    }
  }
  > button {
    text-align: center;
    display: block;
    margin: 8px auto;
    padding: 0.7em 3em;
  }
`;
