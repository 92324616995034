// @flow

import React from "react";

import { Dropdown as StyledDropdown } from "../styles";
import Icon from "src/icons";
import { ListItemMedium } from "src/styles/box";
import useClickoutside from "src/hooks/useClickoutside";

type Props = {
  outerRef: any,
  id: number,
  toggleModal: Function,
  _setDashboard: Function,
  handleClose: Function
};

const Dropdown = ({
  outerRef,
  id,
  toggleModal,
  _setDashboard,
  handleClose
}: Props) => {
  useClickoutside({ outerRef, handleClose });
  return (
    <StyledDropdown>
      <ul>
        <ListItemMedium onClick={() => _setDashboard(id)}>
          <Icon type="reload" />
          <span>Refresh dashboard</span>
        </ListItemMedium>

        <ListItemMedium onClick={toggleModal}>
          <Icon type="deleteTrash" />
          <span>Delete dashboard</span>
        </ListItemMedium>
      </ul>
    </StyledDropdown>
  );
};

export default Dropdown;
