// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

export const searchStatus = (searchString: string): Action => ({
  type: atypes.SEARCH_STATUS_REQUEST,
  payload: {
    searchString
  }
});

export const createStatus = (
  title: string,
  active: boolean,
  locked: boolean
): Action => ({
  type: atypes.CREATE_STATUS_REQUEST,
  payload: {
    title,
    active,
    locked
  }
});

export const deleteStatus = (templateId: number, id: number): Action => ({
  type: atypes.DELETE_STATUS_REQUEST,
  payload: {
    templateId,
    id
  }
});

export const editStatus = (statusId: number, name: string): Action => ({
  type: atypes.EDIT_STATUS_REQUEST,
  payload: {
    statusId,
    name
  }
});
