// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";

import { Centered } from "src/styles/modal";
import { Form } from "src/styles/form";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { colors as colorsOld } from "src/styles/constants";

export const PromptRules = styled(Centered)`
  display: flex;
  margin-bottom: 2rem;
`;

export const ModalContainer = styled(Form)``;

export const CloseIcon = styled.div`
  display: inline-block;
  position: absolute;
  right: ${spacing.space_l};
  cursor: pointer;
`;

export const PromptsContainer = styled.div`
  padding: ${spacing.space_l} 50px ${spacing.space_l} ${spacing.space_l};
  font-size: 0.88rem;
  line-height: 20px;
`;

export const NewRule = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  color: ${colors.active};
  height: 24px;
  margin-top: ${spacing.space_s};
  margin-bottom: ${spacing.space_xl};
  cursor: pointer;
  > div {
    margin-top: 2px;
    margin-left: 12px;
    height: 14px;
    line-height: 14px;
    vertical-align: middle;
  }
`;

export const RuleRow = styled.div`
  margin-bottom: ${spacing.space_l};
  display: flex;
  position: relative;
`;

export const Label = styled(RuleRow)`
  margin-top: ${spacing.space_xl};
  font-weight: 600;
`;

export const WhoColumn = styled.div`
  width: 185px;
  position: relative;
`;

export const WhenColumn = styled.div`
  margin-left: ${spacing.space_l};
  width: 280px;
  position: relative;
`;

const inputStyles = `
  width: 100%;
  height: 36px;
  border-radius: ${radius.small};
  border: 1px solid ${colorsOld.silver}
  padding: ${spacing.space_m};
  outline: none;
  cursor: pointer;
`;

export const Roles = styled.div`
  > div {
    height: 36px;
    padding: 0;
    width: 187px;
    > div:first-of-type > input {
      height: 34px !important;
      min-width: initial;
      width: 185px;
    }
  }
  span {
    font-weight: initial !important;
  }
`;

export const SelectedRole = styled.div`
  ${inputStyles}
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Rules = styled.div`
  ${inputStyles}
  font-size: 13px;
  border-color: ${ifProp("highlight", colors.primary, null)};
  position: relative;
`;

export const RemoveRule = styled.div`
  position: absolute;
  height: 100%;
  right: -${spacing.space_xl};
  display: flex;
  align-items: center;
`;

export const CaretDown = styled.div`
  position: absolute;
  right: 0;
  height: 36px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  > svg {
    margin-top: ${spacing.space_xs};
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  padding: ${spacing.space_m};
  width: 100%;
  background: ${colors.brightLight};
  box-shadow: 0 ${spacing.space_s} ${spacing.space_m} 0 #00000014;
  border: solid 1px #cdd2d980;
  border-radius: ${radius.small};
  left: 0;
  top: calc(100% + 3px);
  z-index: 2;
`;

export const DropdownItem = styled.div`
  width: 100%;
  height: 24px;
  padding: ${spacing.space_s};
  border-radius: ${radius.small};
  color: ${colors.primary};
  :hover {
    background: ${colors.grey0};
  }
`;

export const Additional = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 20px;
  color: ${colors.secondary};
  padding-bottom: ${spacing.space_s};
  margin-bottom: ${spacing.space_m};
  border-bottom: ${spacing.space_xs} solid #e6e8ec;
`;

export const Option = styled.div`
  padding: ${spacing.space_s};
  color: ${colors.primary};
  font-weight: initial;
  text-transform: capitalize;
  :hover {
    background: ${colors.grey0};
  }
`;

export const Selection = styled.div`
  display: inline-block;
  padding: ${spacing.space_m};
  height: 30px;
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  text-transform: capitalize;
  padding-right: ${spacing.space_xl};
  background: ${colorsOld.silver};
  color: ${colors.primary};
  margin-left: ${spacing.space_xs};
  > span {
    font-weight: initial;
  }
`;

export const RemoveSelection = styled.div`
  position: absolute;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  cursor: pointer;
`;
