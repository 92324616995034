// @flow

import React from "react";

import { Tooltip } from "@chakra-ui/react";
import {
  StyledAddButton,
  StyledRemoveButton,
  StyledSelectionName,
  MultiPicklistWrapper,
  Text
} from "./styles";
import Icon from "src/icons";

type Props = {
  value: Array<string>,
  handleRemove: Function,
  openEdit: Function,
  disabled: boolean,
  isMandatory: ?boolean
};

const SelectedMultiPicklists = ({
  value,
  handleRemove,
  openEdit,
  disabled,
  isMandatory
}: Props) => {
  const formattedValue = value ? (Array.isArray(value) ? value : [value]) : [];
  return (
    <MultiPicklistWrapper data-cy="displayContainerSelect">
      {formattedValue.length > 0 &&
        formattedValue.map(option => {
          return (
            option && (
              <StyledSelectionName
                data-cy="picklistItem"
                key={option}
                disabled={disabled}
              >
                <Tooltip label={option.length > 25 && option}>
                  <Text>{option} </Text>
                </Tooltip>
                <StyledRemoveButton
                  data-cy="removePicklistItem"
                  type="button"
                  onClick={() => !disabled && handleRemove(option)}
                >
                  <Icon type="decrement" />
                </StyledRemoveButton>
              </StyledSelectionName>
            )
          );
        })}
      <StyledAddButton
        data-cy="addButtonSelect"
        type="button"
        onClick={openEdit}
        disabled={disabled}
        isMandatory={formattedValue.length === 0 && isMandatory}
      >
        &#43; Add
      </StyledAddButton>
    </MultiPicklistWrapper>
  );
};

export default SelectedMultiPicklists;
