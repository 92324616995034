// @flow
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";

import { getWorkflowSelectedRows } from "src/reducers";
import type { WorkflowById, DataStage } from "src/types";
import { exportSelectedProcesses } from "src/api/workflow";
import { downloadDocument } from "src/utils/file";
import { dataStages } from "src/constants";
import * as errors from "src/constants/errorMessage";

import { TemplateTitle, Templates } from "./styles";
import ModalButton from "../../ModalButton";
import ErrorLine from "./ErrorLine";

type Props = {
  isOpen: boolean,
  onClose: () => void
};

const ExportModal = ({ isOpen, onClose }: Props) => {
  const templates: Array<number> = useSelector(
    ({ app }) => getWorkflowSelectedRows(app) || []
  );
  const allTemplates: WorkflowById = useSelector(
    ({ app }) => app.workflow.byId
  );
  const [status, setStatus] = useState<DataStage>(dataStages.idle);
  const [error, setError] = useState<?string>(null);

  const exportTemplates = async () => {
    setStatus(dataStages.fetching);
    try {
      const result = await exportSelectedProcesses(templates);
      let filename = result.headers.get("Content-Disposition") ?? "";
      filename = filename.match(/filename=(.*)/) ?? [];
      setStatus(dataStages.idle);
      const resultJSON = await result.json();

      const blob = new Blob([JSON.stringify(resultJSON, null, 2)], {
        type: "application/transit+json"
      });

      const url = URL.createObjectURL(blob);
      downloadDocument(
        url,
        filename[1]
          ? `${filename[1].replaceAll('"', "")}`
          : `Export${templates.length && templates[0]}_to_${templates.length && templates[templates.length - 1]}.json`
      );
      URL.revokeObjectURL(url);
      setStatus(dataStages.fetched);
    } catch (e) {
      console.error("Error during export:", e);
      setStatus(dataStages.error);
      e?.status === 401
        ? setError(errors.EXPORT_NOT_ALLOWED)
        : setError(errors.UNKNOWN_ERROR);
    }
  };

  useEffect(() => {
    if (status === dataStages.fetched) {
      onClose();
    }
  }, [status]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={status === dataStages.fetching ? () => {} : onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent sx={{ height: "30rem", maxWidth: "38rem" }}>
        <ModalHeader sx={{ backgroundColor: "#F2F4F7" }}>
          Export process configurations
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: "1rem",
            fontSize: 16
          }}
        >
          <span>The following processes are included in the export.</span>
          <Templates>
            {templates.map((template, index) =>
              !allTemplates[String(template)].deleted ? (
                <TemplateTitle key={`template${template}-${index}`}>
                  {allTemplates[String(template)].title}
                </TemplateTitle>
              ) : null
            )}
          </Templates>
          {status === dataStages.error ? (
            <ErrorLine error={error ?? errors.UNKNOWN_ERROR} />
          ) : (
            <ModalButton
              label="Export"
              onClick={exportTemplates}
              loading={status === dataStages.fetching}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExportModal;
