// @flow

import React, { useRef } from "react";
import { connect } from "react-redux";

import Common from "./Common";
import { ToogleText } from "./styles";
import WarningModal from "src/components/Modal/Warning";
import { setAttribute } from "src/actions/chatroom";
import { getChatroomReminders } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";

import type { Message, AppState, UID } from "src/types";

type Props = {
  message: Message,
  _setAttribute: Function,
  reminders: boolean,
  reportModal: boolean,
  currentUser: UID
};

const ReminderStatusChange = ({
  message,
  _setAttribute,
  reminders,
  currentUser
}: Props) => {
  const { data, roomId } = message;
  const { new: on, old: off } = data;
  const outerRef = useRef();
  const {
    value: modal,
    setTrue: showModal,
    setFalse: hideModal
  } = useBoolean();

  const turnOnReminders = () => {
    _setAttribute(roomId, { reminders: true });
  };

  const turnOffReminders = () => {
    _setAttribute(roomId, { reminders: false });
    hideModal();
  };

  const isOwner = currentUser === message.author;
  const verb = isOwner ? "have" : "has";

  if (off)
    return (
      <Common {...message}>
        {" "}
        <span>
          {verb} turned off reminders for this conversations.{" "}
          {!!on === !!reminders ? (
            <ToogleText onClick={turnOnReminders}>Turn On</ToogleText>
          ) : null}
        </span>
      </Common>
    );

  if (on)
    return (
      <>
        {modal && (
          <WarningModal
            handleClose={hideModal}
            handleClick={turnOffReminders}
            heading="Turn off reminders"
            content="Are you sure you want to turn off reminders?"
            primaryText="Turn off"
            secondaryText="Cancel"
            outerRef={outerRef}
          />
        )}
        <Common {...message}>
          {" "}
          {verb} turned on reminders for this conversations.{" "}
          {!!on === !!reminders ? (
            <ToogleText onClick={showModal}>Turn Off</ToogleText>
          ) : null}
        </Common>
      </>
    );

  return (
    <Common {...message}>
      {" "}
      {verb} turned off reminders for this conversations.
    </Common>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { message }) => ({
  currentUser: app.currentUser.uid,
  reminders: getChatroomReminders(app, message.roomId)
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute
})(ReminderStatusChange);
