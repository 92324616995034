// @flow

import styled from "@emotion/styled";

import { Overlay as DefaultOverlay } from "src/styles";
import * as colors from "src/styles/constants/colors";

export const Video = styled.div`
  position: absolute;
  top: 10%;
  left: 20%;
  height: auto;
  z-index: 999;
  > div {
    position: relative;
    padding: 3em;
    background: ${colors.brightLight};

    > video {
      outline: none;
      width: 50em;
      max-height: 70vh;
    }
  }
`;

export const CloseButton = styled.button`
  outline: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  border: none;
  position: absolute;
  top: 1em;
  right: 0.8em;

  > svg {
    height: 1em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
    }
  }
`;

export const Overlay = styled(DefaultOverlay)`
  z-index: 999;
`;
