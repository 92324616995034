// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { getGroup } from "src/reducers";
import Icon from "src/icons";

import type { Group, GroupId } from "src/types";

import * as styles from "./styles";

import type { HandleRemove } from "./index";

type Props = {|
  id: GroupId,
  handleRemove: HandleRemove,
  name: string
|};

const GroupTag = ({ id, handleRemove, name }: Props) => {
  const group: Group = useSelector(({ app }) => getGroup(app, id));

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const handleKeyboardEvents = e => {
    if (e.key === "Enter") handleRemove({ id });
  };

  const { title, photoUrl } = group;
  return (
    <Tag
      name={name}
      sx={styles.UserOrGroupTag}
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyboardEvents}
    >
      <Icon
        type="groupAvatar"
        text={title}
        size="xs"
        id={id}
        photoUrl={photoUrl}
      />
      <TagLabel sx={styles.UserAndGroupTagText}>{title}</TagLabel>
      <TagCloseButton
        onClick={() => handleRemove({ id })}
        sx={
          isFocused ? styles.UserOrGroupCloseFocused : styles.UserOrGroupClose
        }
        tabIndex={-1}
      />
    </Tag>
  );
};

export default GroupTag;
