import * as R from "ramda";
import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "@chakra-ui/pro-theme";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";

import textStyles from "./textStyles";

let unifizeColors = {};

const disabledStyles = {
  opacity: 0.4,
  background: "unifize_active"
};

for (let color in colors) {
  unifizeColors[`unifize_${color}`] = colors[color];
}

const customTheme = {
  fontSizes: {
    // For font-size of 10px
    micro: "0.625rem"
  },

  textStyles,

  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px"
  },

  components: {
    // There are some breaking style changes in the pro component.
    // The changes were found in the role management table
    ...R.dissoc("Popover", proTheme.components),
    Heading: {
      variants: {
        tableCell: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          margin: 0
        }
      }
    },
    Button: {
      variants: {
        uPrimary: {
          bgColor: "unifize_active",
          color: "white",
          _disabled: disabledStyles,
          _hover: {
            _disabled: disabledStyles
          }
        },

        uBlueFilled: {
          bg: "unifize_blue",
          color: "white",
          _disabled: {
            opacity: 1,
            bg: "unifize_skyDark",
            color: "unifize_grey25"
          }
        },
        uBlueOutlined: {
          bg: "white",
          border: "1px",
          borderColor: "blue.500",
          color: "blue.500"
        },
        uSecondary: {
          bgColor: "unifize_sky",
          color: "unifize_ink"
        },
        uDanger: {
          bgColor: "unifize_red500",
          color: "white"
        },
        add: {
          width: "100%",
          borderRadius: radius.small,
          fontWeight: typography.semiBold,
          bgColor: "unifize_grey20",
          color: "unifize_secondary",
          _hover: {
            bgColor: "unifize_active",
            color: "unifize_brightLight",
            _disabled: {
              bgColor: "unifize_grey28",
              color: "unifize_skyDark"
            }
          },
          _disabled: {
            bgColor: "unifize_grey28",
            color: "unifize_skyDark"
          }
        },
        transparentLink: {
          bg: "transparent",
          color: "unifize_blue",
          _focus: {
            boxShadow: null
          }
        },
        transparent: {
          bg: "transparent",
          _focus: {
            boxShadow: null
          }
        },
        link: {
          bg: "transparent"
        }
      },
      defaultProps: {
        size: "sm"
      }
    },
    Text: {
      variants: {
        inkLightestSm: {
          color: "unifize_inkLightest",
          margin: 0,
          fontSize: "sm"
        },
        ink400: {
          color: "unifize_ink",
          margin: 0
        },
        redDarkSm: {
          color: "unifize_redDark",
          margin: 0,
          fontSize: "sm"
        }
      }
    },
    CustomSelect2: {
      parts: ["selection", "dropdownCard", "list", "listItem"],
      baseStyle: {
        selection: {
          border: "1px solid",
          borderColor: "gray.300",
          borderRadius: "2px",
          p: 2,
          h: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "sm",
          cursor: "pointer",
          __focus: {
            borderColor: "blue.400",
            boxShadow: "xs"
          }
        },
        dropdownCard: {
          w: "100%",
          position: "absolute",
          backgroundColor: "white"
        },
        list: {
          p: 0,
          m: 0,
          w: "100%",
          maxHeight: "16em",
          overflow: "auto"
        },
        listItem: {
          cursor: "pointer",
          padding: [2, 1],
          _hover: {
            bg: colors.grey1
          }
        }
      }
    },
    Menu: {
      parts: ["item", "list"],
      baseStyle: {
        item: {
          backgroundColor: "white"
        },
        list: {
          border: "1px solid",
          borderColor: "gray.300"
        }
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: "blue"
      }
    }
  },
  colors: {
    ...proTheme.colors,
    ...unifizeColors,
    brand: proTheme.colors.blue
  }
};

const theme = extendTheme(customTheme, proTheme);

export default theme;
