// @flow

import React from "react";

import { Checkbox as StyledCheckbox } from "src/styles/input";

type Props = {
  id?: string,
  checked?: boolean,
  disabled?: boolean
};

const Checkbox = ({ id, checked = false, disabled = false }: Props) => {
  return (
    <StyledCheckbox disabled={disabled}>
      {disabled ? (
        <label tabIndex="0" role="checkbox" aria-checked={checked}>
          <input type="checkbox" checked={checked} id={id} readOnly />
          <div />
        </label>
      ) : (
        <label htmlFor={id} tabIndex="0" role="checkbox" aria-checked={checked}>
          <input type="checkbox" checked={checked} readOnly />
          <div />
        </label>
      )}
    </StyledCheckbox>
  );
};

export default Checkbox;
