// @flow

import React from "react";
import { Text } from "@chakra-ui/react";

import * as styles from "./styles";

import type { DownshiftProps } from "downshift";
import type { OnItemClick } from "src/components/Dropdown";

type Props = {
  item: {
    seriesId: string,
    title: string
  },
  onItemClick?: OnItemClick,
  highlightedIndex: number,
  index: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const SortDropdownRow = ({
  item,
  onItemClick,
  highlightedIndex,
  index
}: Props) => {
  return (
    <Text
      sx={
        (highlightedIndex !== null && highlightedIndex === index) ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveDropdownItem
          : styles.InActiveDropdownItem
      }
      onClick={() => onItemClick && onItemClick({ id: item.seriesId, item })}
    >
      {item.title}
    </Text>
  );
};

export default SortDropdownRow;
