// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import type { CurrentFilter, State } from "src/types";
import { updateFilter } from "src/actions/filter";
import { getCurrentFilter } from "src/reducers";
import { fetchChatroomUpdates } from "src/actions/chatroom";

import { RoundButton } from "./styles";
import { blue } from "src/styles/constants/colors";
import Icons from "src/icons";

type Props = {
  filterByArchived: boolean,
  updateFilter: Function,
  currentFilter: CurrentFilter
};

const Archived = ({ filterByArchived, updateFilter, currentFilter }: Props) => {
  const toggleArchiveFilter = useCallback(() => {
    if (filterByArchived) {
      updateFilter({
        ...currentFilter,
        archived: false
      });
    } else {
      updateFilter({
        ...currentFilter,
        archived: true
      });
    }
  }, [updateFilter, filterByArchived, currentFilter]);

  return (
    <RoundButton
      type="button"
      onClick={toggleArchiveFilter}
      onKeyPress={toggleArchiveFilter}
      active={filterByArchived}
    >
      <Icons type="archive" fill={blue} />
      Archived
    </RoundButton>
  );
};

const mapStateToProps = ({ app }: State) => ({
  filterByArchived: app.chatRooms.filters.current.archived,
  currentFilter: getCurrentFilter(app)
});

const mapDispatchToProps = {
  updateFilter,
  fetchChatroomUpdates
};

export default connect(mapStateToProps, mapDispatchToProps)(Archived);
