// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as size from "src/styles/constants/size";
import { ScrollableBox, ListItemMedium } from "src/styles/box";
import { ModalFormBody } from "src/styles/form";
import { Input } from "src/styles/input";

const OptionContainer = styled.div`
  width: 100%;
  padding: 0 ${spacing.space_s};
  margin: ${spacing.space_m} 0;
`;

const Option = styled.div`
  width: 4.33em;
  height: 4.33em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    transition: 250ms ease-in-out;
    background-color: ${props =>
      props.selected ? "none" : "rgba(47, 128, 237, 0.15)"};
    cursor: pointer;
    > svg > path {
      transition: 250ms ease-in-out;
      fill: ${colors.activeFilter};
    }
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const Menu = styled(ScrollableBox)`
  width: ${size.menuWidth};
  position: absolute;
  left: 100%;
  color: ${colors.primary};
  top: 0px;
`;

const ProfileMenu = styled(Menu)`
  width: 233px;
  ul {
    overflow: visible;
    > li :nth-of-type(2) {
      position: relative;
    }
  }
`;

const OrgMenu = styled(Menu)`
  ul {
    > li {
      color: ${colors.primary};
    }
  }
`;

const MenuItem = styled(ListItemMedium)`
  cursor: pointer;
  text-transform: initial;
  font-weight: initial;
  position: relative;
  > span {
    padding-left: 0.4em;
  }
`;

const SubMenu = styled(ScrollableBox)`
  width: ${size.menuWidth};
  max-height: 250px;
  position: absolute;
  top: 0;
  left: 104%;
  overflow-y: auto;
`;

const MenuItemWithIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    padding-left: 0.4em;
  }
`;

const FormBody = styled(ModalFormBody)`
  margin: 0 20px;
`;

const ArrowIcon = styled.img`
  width: 10px;
  height: 10px;
`;

const HoverExtender = styled.div`
  width: 60px;
  height: 130px;
  position: absolute !important;
  right: -20px;
`;

const OrgBlocksIcon = styled.img`
  width: ${spacing.space_xl};
`;

const UserSubMenu = styled(SubMenu)``;

const SearchInput = styled(Input)`
  min-width: auto;
`;

export {
  SearchInput,
  OptionContainer,
  Option,
  Info,
  ProfileMenu,
  OrgMenu,
  MenuItem,
  SubMenu,
  MenuItemWithIcon,
  FormBody,
  ArrowIcon,
  HoverExtender,
  OrgBlocksIcon,
  UserSubMenu
};
