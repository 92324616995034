// @flow

import { connect } from "react-redux";

import Creator from "src/components/chatroom/creator";
import {
  getCurrentRoomId,
  getCurrentUserId,
  getWorkflowSettings
} from "src/reducers";
import { setError, clearError } from "src/actions";
import { setRecentWorkflows } from "src/actions/workflows";
import {
  createChatroom,
  setConversationAttribute,
  setConversationAttributes,
  clearConversationDialog,
  createDirectConversation
} from "src/actions/chatroom";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => {
  const templateId = app.conversationDialog.templateId || "";
  return {
    currentRoom: getCurrentRoomId(app),
    error: app.conversationDialog.error,
    type: app.conversationDialog.type,
    loading: app.conversationDialog.loading,
    members: app.conversationDialog.members,
    groups: app.conversationDialog.groups,
    currentUser: getCurrentUserId(app),
    templateTitle: app.conversationDialog.templateTitle,
    workflowSettings: getWorkflowSettings(app, `${templateId}`) || {},
    dueDate: app.conversationDialog.dueDate
  };
};

export default connect(mapStateToProps, {
  createChatroom,
  setError,
  clearError,
  setRecentWorkflows,
  setConversationAttribute,
  setConversationAttributes,
  clearConversationDialog,
  createDirectConversation
})(Creator);
