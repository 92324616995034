// @flow

import styled from "@emotion/styled";

import { ButtonOutline } from "src/styles/buttons";
import { ScrollableBox } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import { Input } from "src/styles/input";
import { Checkbox } from "src/styles/input.old";
import { ListItemMedium } from "src/styles/box";

export const Columns = styled.div`
  position: relative;
  > ${ButtonOutline} {
    text-transform: none;
    padding: 0.4em 0.5em;
    margin-top: 0.4em;
  }
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  bottom: 27px;
`;

export const CaretRight = styled.div`
  display: inline-block;
  padding-left: 1em;
  > svg {
    position: relative;
    top: 3px;
  }
`;

export const ColumnItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  margin-bottom: 0.5em;
  align-items: center;
  > div:first-of-type {
    border: 1px solid ${colors.grey4};
    padding: 0.4em 0.7em;
    display: inline-block;
    margin-right: 0.2em;
    color: ${props => (props.active ? colors.primary : colors.secondary)};
    position: relative;
  }
`;

export const StatusColumnItem = styled(ColumnItem)`
  grid-template-columns: 1fr 0.5fr 0.7fr 20px;
  ${Input} {
    min-width: auto;
    padding: 5.4px 12px;
  }
`;

export const DeleteButton = styled.button`
  outline: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  border: none;
  padding: 0;
  > svg {
    width: 18px;
    height: 18px;
  }
`;

export const Status = styled.div`
  border: 1px solid ${colors.grey4};
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 0.2em;
  color: ${props => (props.active ? colors.primary : colors.secondary)};
  position: relative;
  cursor: pointer;
  span {
    font-size: 10px;
  }
  > svg {
    position: absolute;
    right: 2px;
    width: 18px;
    height: 18px;
  }
`;

export const StatusDropdown = styled(ScrollableBox)`
  position: absolute;
  bottom: 33px;
  left: 0;
  min-width: 126px;
  > ul {
    max-height: 16em;
    overflow: auto;
    white-space: nowrap;
  }

  > ul > ${ListItemMedium} {
    padding: 4px;
    line-height: 20px;
    position: relative;

    ${Checkbox} {
      label input[type="checkbox"] + div {
        height: 14px;
        width: 14px;
      }
    }

    > div {
      position: absolute;
      right: 2px;
    }
  }
`;
