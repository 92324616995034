// @flow

import React, { useState } from "react";
import { connect } from "react-redux";
import { FormControl, Input } from "@chakra-ui/react";
import { getCurrentOrg } from "src/reducers";
import { addOrgDomain } from "src/actions/orgSettings";
import type { AppState } from "src/types";

type Props = {
  _addOrgDomain: Function,
  setIsAddDomainVisible: Function,
  currentOrgDetails: Object
};

const AddDomain = ({
  _addOrgDomain,
  currentOrgDetails,
  setIsAddDomainVisible
}: Props) => {
  const { id } = currentOrgDetails;
  const [value, setValue] = useState("");

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleOnClick = (orgId: number, domainName: string) => {
    _addOrgDomain(orgId, domainName);
    setIsAddDomainVisible(false);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleOnClick(id, value);
      }}
    >
      <FormControl>
        <Input
          pr="8.2rem"
          placeholder="Enter new domain"
          value={value}
          onChange={handleChange}
          autofocus={true}
          required
        />
      </FormControl>
    </form>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentOrgDetails: getCurrentOrg(app)
});

export default connect(mapStateToProps, {
  _addOrgDomain: addOrgDomain
})(AddDomain);
