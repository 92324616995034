// @flow

import React from "react";

type Props = {
  fill?: string
};

const CurrentRevision = ({ fill, ...restProps }: Props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M0.904785 7L7.00002 10.0476L13.1082 7"
      stroke={fill || "#1581BA"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.904785 10.0477L7.00002 13.0953L13.1082 10.0477"
      stroke={fill || "#1581BA"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.904785 4.07202L7.00612 7.00002L13.1082 4.07202L7.00612 0.904785L0.904785 4.07202Z"
      stroke={fill || "#1581BA"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CurrentRevision;
