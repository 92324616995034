// @flow

import { connect } from "react-redux";

import Selection from "src/components/workflow/Selection";
import { getWorkflow } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  workflow: getWorkflow(app, props.id)
});

export default connect(mapStateToProps)(Selection);
