// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const Main = styled.main`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: inherit;
  border-left: solid 1px ${colors.grey3};
  background-color: ${colors.appBackground};
  background-blend-mode: lighten;

  & > div:last-child {
    position: absolute;
    bottom: 0px;
    right: 12px;
    left: auto;
  }
`;

export { Main };
