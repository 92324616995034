// @flow

import { connect } from "react-redux";

import Selection from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/Conversation/FieldSelect/Selection";

import { getChecklistFieldDetails } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  field: getChecklistFieldDetails(app, `${props.value}`)
});

export default connect(mapStateToProps)(Selection);
