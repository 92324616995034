// @flow

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setChartLoadingState, getChartJsonSuccess } from "src/actions/chart";
import { getChartJson } from "src/api/chart";

import type { ChartId } from "src/types";

const useFetchChartJSON = ({ charts = [] }: { charts: Array<any> }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setChartLoadingState(charts.map(chart => chart?.id)));
    const fetchChartsSequentially = async () => {
      const unresolvedPromises = new Map<ChartId, Promise<any>>();
      for (const chart of charts) {
        if (!chart?.id) return;
        try {
          const chartDataPromise = getChartJson(chart.id);
          // Keep track of the promises that get timed out
          unresolvedPromises.set(chart.id, chartDataPromise);

          // Timeout and move on and try to fetch the next chart
          const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error("Timeout"));
            }, 20000);
          });

          const response = await Promise.race([
            chartDataPromise,
            timeoutPromise
          ]);

          unresolvedPromises.delete(chart.id);

          // Update redux store with the valid data
          dispatch(getChartJsonSuccess(chart.id, response));
        } catch (err) {
          // Take care of the timed-out API calls
          if (err.message === "Timeout") {
            try {
              const resolvedResponse = await unresolvedPromises.get(chart.id);
              dispatch(getChartJsonSuccess(chart.id, resolvedResponse));
            } catch (error) {
              console.error("Could not load chart: ", chart.id, error);
            }
          }
          console.error(
            "Fetching chart details failed for chart: ",
            chart.id,
            err
          );
        }
      }
    };

    fetchChartsSequentially();
  }, []);
};

export default useFetchChartJSON;
