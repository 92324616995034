// @flow

import React from "react";
import { Text, Link } from "@chakra-ui/react";

const AccessDenied = () => {
  return (
    <>
      <Text fontSize="3xl" as="b">
        Access Denied!!
      </Text>

      <Link href="https://www.unifize.com/about-us/#contact-us" target="_blank">
        Contact us
      </Link>
    </>
  );
};

export default AccessDenied;
