// @flow

import styled from "@emotion/styled";

export const Text = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
`;
