// @flow

import React from "react";

const HomeDark = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C2.20914 6 4 7.79086 4 10V54C4 56.2091 2.20914 58 0 58V6Z"
      fill="#2F80ED"
    />
    <path d="M31 40V34H35V40H40V32H43L33 23L23 32H26V40H31Z" fill="#2F80ED" />
  </svg>
);

export default HomeDark;
