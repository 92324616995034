// @flow

import type {
  StackProps,
  IconProps,
  TextProps,
  SystemCSSProperties
} from "@chakra-ui/react";

import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import { iconSize } from "src/styles/constants/size";
import * as colors from "src/styles/constants/colors";
import { paleBlue } from "src/styles/constants/colors.old";
import * as shadow from "src/styles/constants/shadow";

export const ProcessTypeContainer: StackProps = {
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const SelectedProcessType: StackProps = {
  justifyContent: "space-between",
  border: `solid 1px ${colors.grey4}`,
  borderRadius: radius.medium,
  padding: spacing.space_s,
  position: "sticky",
  _focus: {
    borderColor: colors.blue500
  }
};

export const ProcessType: StackProps = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  px: spacing.space_s,
  py: spacing.space_s
};

export const ToggleDropdownIcon: IconProps = {
  width: iconSize.space_l,
  height: iconSize.space_l,
  _hover: {
    cursor: "pointer"
  }
};

export const ActiveProcessType: SystemCSSProperties = {
  ...ProcessType,
  backgroundColor: colors.lightBlue
};

export const inActiveProcessType: SystemCSSProperties = {
  ...ProcessType,
  backgroundColor: "transparent"
};

export const InvocationCount: TextProps = {
  backgroundColor: paleBlue,
  paddingX: "0.25rem",
  borderRadius: 200
};

export const DropdownWrapper = {
  boxShadow: shadow.light,
  borderRadius: 0,
  marginX: "1px",
  maxHeight: "18rem",
  overflow: "scroll"
};

export const DropdownHidden: StackProps = {
  ...DropdownWrapper,
  boxShadow: "none"
};

export const DropdownInputField = {
  width: "96%",
  margin: "0.5rem"
};

export const DropdownMenuWrapper = {
  boxShadow: "none"
};
