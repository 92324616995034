// @flow

import { connect } from "react-redux";

import Manage from "src/components/file/Manage";
import { getCurrentRoomId } from "src/reducers";
import { changeCurrentChatroom } from "src/actions/chatroom";
import { searchFile, showManageFile } from "src/actions/file";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentRoom: getCurrentRoomId(app),
  filter: app.files.manageFilesFilter
});

export default connect(mapStateToProps, {
  changeCurrentChatroom,
  searchFile,
  showManageFile
})(Manage);
