// @flow

import { connect } from "react-redux";

import LastModified from "src/components/file/LastModified";
import { getFile } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  file: getFile(app, props.name)
});

export default connect(mapStateToProps, {
  getFile
})(LastModified);
