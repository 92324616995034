// @flow

import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import { validateEmail } from "src/utils";
import type {
  Action,
  EmailModal,
  ConversationFile,
  NonOrgParticipant
} from "src/types";

const initialState: EmailModal = {
  stage: null,
  sending: false,
  error: null,
  data: {
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    message: "",
    headers: null
  },
  nonOrgRecipients: {},
  attachments: [],
  tempAttachments: {}
};

const email = (state: EmailModal = initialState, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_EMAIL_MODAL_STAGE:
      return { ...state, stage: payload };

    case atypes.SEND_EMAIL_REQUEST:
      return { ...state, error: null, sending: true };

    case atypes.SAVE_EMAIL_MODAL_DATA:
      return { ...state, data: payload };

    case atypes.SET_EMAIL_MODAL_DATA:
      return R.mergeDeepRight(state, {
        data: { [payload.prop]: payload.value }
      });

    case atypes.ADD_EMAIL_RECIPIENT: {
      const { prop, value } = payload;

      const isNonOrgMember = R.is(Object, value);

      if (isNonOrgMember && !validateEmail(value.email)) {
        return state;
      }

      const { data: prevData, nonOrgRecipients: prevNonOrgRecipients } = state;
      let newNonOrgRecipients = prevNonOrgRecipients;

      if (isNonOrgMember) {
        const { email, role } = value;

        if (!R.has(email, prevNonOrgRecipients)) {
          newNonOrgRecipients = R.mergeDeepRight(prevNonOrgRecipients, {
            [email]: role || null
          });
        }
      }

      return R.mergeDeepRight(state, {
        data: {
          [prop]: R.uniq(R.append(value, prevData[prop]))
        },
        nonOrgRecipients: newNonOrgRecipients
      });
    }

    case atypes.REMOVE_EMAIL_RECIPIENT: {
      const { prop } = payload;
      const isNonOrgMember = R.is(Object, payload.value);
      const value = isNonOrgMember ? payload.value.email : payload.value;

      let newState: EmailModal = R.mergeDeepRight(state, {
        data: {
          [prop]: R.filter(
            item =>
              R.is(Object, item) ? item.email !== value : item !== value,
            state.data[prop]
          )
        }
      });

      if (!isNonOrgMember) return newState;

      delete newState.nonOrgRecipients[value];

      return newState;
    }

    case atypes.SET_RECIPIENT_ROLE: {
      const { email, role } = payload;

      const newState: EmailModal = R.mergeDeepRight(state, {
        nonOrgRecipients: {
          [email]: role
        }
      });

      return newState;
    }

    case atypes.SEND_EMAIL_SUCCESS:
    case atypes.CLEAR_EMAIL_MODAL_DATA:
      return initialState;

    case atypes.SEND_EMAIL_FAILURE:
      return {
        ...state,
        sending: false,
        error: "Error in sending the email"
      };

    case atypes.ADD_ATTACHMENT: {
      const newAttachments: ConversationFile[] = R.uniqBy(
        item => item.name,
        R.append(payload, state.attachments)
      );
      return R.mergeDeepRight(state, {
        attachments: newAttachments
      });
    }

    case atypes.CLEAR_ATTACHMENT: {
      const { name } = payload;
      const { attachments } = state;
      return {
        ...state,
        attachments: R.filter(file => file.name !== name, attachments)
      };
    }

    case atypes.UPDATE_TEMP_ATTACHMENT_PROGRESS: {
      const { name, progress } = payload;
      return R.mergeDeepRight(state, {
        tempAttachments: {
          [name]: {
            progress,
            uploading: true,
            meta: null
          }
        }
      });
    }

    case atypes.ADD_TEMP_ATTACHMENT_SUCCESS: {
      const { name, meta } = payload;
      return R.mergeDeepRight(state, {
        tempAttachments: {
          [name]: {
            progress: 100,
            uploading: false,
            meta
          }
        }
      });
    }

    case atypes.ADD_TEMP_ATTACHMENT_FAILURE:
    case atypes.CLEAR_TEMP_ATTACHMENT: {
      // $FlowFixMe
      return R.dissocPath(["tempAttachments", payload.name], state);
    }

    default:
      return state;
  }
};

export const getTempAttachment = (state: EmailModal, id: string) =>
  state.tempAttachments[id];

export const hasRecipients = ({ data: { to, cc, bcc } }: EmailModal) =>
  !R.all(R.isEmpty, [to, cc, bcc]);

export const hasNonOrgRecipients = (state: EmailModal) =>
  !R.isEmpty(state.nonOrgRecipients);

export const uploadingAttachment = (state: EmailModal) =>
  R.any(R.propEq("uploading", true), R.values(state.tempAttachments));

export const getNonOrgRecipients = (state: EmailModal): NonOrgParticipant[] =>
  R.toPairs(state.nonOrgRecipients).map(([email, role]) => ({ email, role }));

export default email;
