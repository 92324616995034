// @flow

import styled from "@emotion/styled";

import Radio from "src/components/Radio/styles";
import * as colors from "src/styles/constants/colors";

export const Settings = styled.div`
  z-index: 3;
  padding-left: 40px;
  position: absolute;
  top: 0px;
  left: 50px;
  right: 0px;
  bottom: 0px;
  padding-left: 25px;
  padding-right: 15px;
  padding-top: 8px;
  border: none;
  background-color: ${colors.brightLight};
  > div:last-child {
    display: grid;
    grid-template-columns: 158px 1fr;
    margin-left: -12px;
  }
`;

export const Navigation = styled.ul`
  padding-left: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #e9ebec;
  padding: 4px 12px;
  > strong {
    position: relative;
    top: 1px;
    padding-left: 4px;
    font-size: 15px;
  }
`;

export const Email = styled.div`
  padding-left: 2em;
`;

export const Header = styled.h2`
  margin-top: 0.7em;
  margin-bottom: 0.2em;
`;

export const SubHeader = styled.h4`
  margin-top: 0;
`;

export const Option = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 1em;
  border: solid 1px ${props => (props.selected ? colors.active : colors.grey6)};
  background: ${props => (props.selected ? colors.grey16 : colors.brightLight)};
  transition: all 0.25s;
  display: grid;
  grid-template-columns: 2em 1fr;
  ${SubHeader} {
    margin-bottom: 0.6em;
  }

  :hover {
    ${Radio} > label {
      > input[type="radio"] + div {
        > span {
          background: ${colors.grey9};
        }
      }
    }
  }
`;

export const OptionDanger = styled(Option)`
  color: ${colors.danger};
`;

export const TypeHeader = styled.h3`
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.4em;
`;

export const List = styled.ul`
  margin: 0;
  font-size: 13px;
  line-height: 2em;
`;

export const Type = styled.div`
  max-height: 74vh;
  overflow: auto;
`;
