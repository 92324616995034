// @flow

import styled from "@emotion/styled";

import { ChatCard } from "src/components/messages/Chat/styles";
import * as colors from "src/styles/constants/colors";

export const DirectMessage = styled(ChatCard)`
  margin-left: 0;
  > div {
    padding-left: 1em;
    > h4 {
      margin: 0px;
      font-size: 12.5px;
      > span {
        margin-left: -2px;
        > span {
          color: ${colors.active};
        }
      }
    }
  }
`;

export const StatusValue = styled.div`
  display: inline-flex;
  margin-left: 0.4em;
  position: relative;
  > div {
    cursor: text;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PreviewWrapper = styled.div`
  width: 3.43em;
  height: 3.43em;
  > div > div {
    flex-direction: row;
    > img {
      width: 3.43em;
      height: 3.43em;
      margin: 0px;
      object-fit: cover;
    }
  }
`;

export const TextWrapper = styled.div`
  display: grid;
`;
