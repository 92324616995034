// @flow

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormHelperText
} from "@chakra-ui/react";

import { useDebounce } from "use-debounce";
import ChartFields from "./ChartFields";
import { setNewChartDialogAttributes } from "src/actions/chart";

import type { AppState } from "src/types";
import { LabelInfo } from "./styles";
import { inkLight } from "src/styles/constants/colors";
type Props = {
  title: string,
  description: string,
  handleClose: Function,
  _setNewChartDialogAttributes: Function
};

const Fields = ({
  title,
  description,
  handleClose,
  _setNewChartDialogAttributes
}: Props) => {
  const [localTitle, setLocalTitle] = useState(title);
  const [localDescription, setLocalDescription] = useState(description);

  const [debouncedTitle] = useDebounce(localTitle, 400);
  const [debouncedDescription] = useDebounce(localDescription, 400);

  // Update Redux state when debouncedTitle is updated
  useEffect(() => {
    _setNewChartDialogAttributes({
      title: debouncedTitle
    });
  }, [debouncedTitle]);

  // Update Redux state when debouncedDescription is updated
  useEffect(() => {
    _setNewChartDialogAttributes({
      description: debouncedDescription
    });
  }, [debouncedDescription]);

  return (
    <>
      <ModalHeader>
        Fields of the chart
        <ModalCloseButton bg="white" onClick={handleClose} />
      </ModalHeader>
      <ModalBody>
        <form>
          <FormControl id="chartTitle" isRequired mb={3}>
            <FormLabel fontWeight="600">Chart title</FormLabel>
            <FormHelperText color={inkLight}>
              Enter the title of your chart
            </FormHelperText>
            <Input
              type="text"
              placeholder="e.g. Pending Inquiries for Steam Turbines"
              size="sm"
              value={localTitle}
              onChange={e => setLocalTitle(e.target.value)}
              autoFocus
              required
            />
          </FormControl>

          <FormControl id="description" mb={3}>
            <FormLabel fontWeight="600">
              Description
              <LabelInfo>(Optional)</LabelInfo>
            </FormLabel>
            <FormHelperText color={inkLight}>
              What does this chart represent?
            </FormHelperText>
            <Input
              type="text"
              placeholder="e.g. Graphical representation of the pending enquiries for Steam Turbines categorised by owner"
              size="sm"
              value={localDescription}
              onChange={e => setLocalDescription(e.target.value)}
            />
          </FormControl>

          <ChartFields />
        </form>
      </ModalBody>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  title: app.charts.newChartDialog.title,
  description: app.charts.newChartDialog.description
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Fields);
