// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Related = createIcon({
  displayName: "RelatedIcon",
  viewBox: "0 0 18 18",
  path: (
    <path
      fill="none"
      stroke="#1581ba"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.5 3.5h9v4h-9zM6.5 11.5h9v4h-9zM.5.5v13h3M.5 5.5h3"
    />
  )
});

export default Related;
