// @flow

import { connect } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import type { Set as ImmutableSet } from "immutable";

import Row from "./Row";
import { clearPeopleRowSelection } from "src/actions/user";

import type { UID } from "src/types";

type Props = {
  searchResults: ImmutableSet<UID>,
  handleEdit: Function,
  _clearPeopleRowSelection: Function
};

const Body = ({
  searchResults,
  handleEdit,
  _clearPeopleRowSelection
}: Props) => {
  const [stickiness, setStickiness] = useState(false);

  useEffect(() => {
    return () => {
      _clearPeopleRowSelection();
    };
  }, [_clearPeopleRowSelection]);

  const toggleStickiness = useCallback(() => {
    setStickiness(!stickiness);
  }, [stickiness, setStickiness]);

  return (
    <tbody>
      {searchResults.map(user => (
        <Row
          user={user}
          key={user}
          stickiness={stickiness}
          toggleStickiness={toggleStickiness}
          handleEdit={handleEdit}
        />
      ))}
    </tbody>
  );
};

export default connect(null, {
  _clearPeopleRowSelection: clearPeopleRowSelection
})(Body);
