// @flow

import React from "react";

import { Navigation as StyledNavigation, Item } from "./styles";
import Icon from "src/icons";

type Props = {
  current: string
};

const Navigation = ({ current }: Props) => {
  return (
    <StyledNavigation>
      <Item active={current}>
        <Icon type="bell" />
        <strong>Notifications</strong>
      </Item>
    </StyledNavigation>
  );
};

export default Navigation;
