// @flow

import React from "react";

const svg = {
  incomplete: (
    <g stroke="#888">
      <circle cx="8" cy="8" r="7.5" />
      <path d="M4.5 7.5L7 10l5-5" />
    </g>
  ),
  completed: (
    <g>
      <circle cx="8" cy="8" r="7.5" fill="#0ABC8A" stroke="#0ABC8A" />
      <path stroke="#FFF" d="M4.5 7.5L7 10l5-5" />
    </g>
  ),
  accepted: (
    <g>
      <circle cx="8" cy="8" r="7.5" fill="#0ABC8A" stroke="#0ABC8A" />
      <path stroke="#FFF" d="M4.5 7.5L7 10l5-5" />
    </g>
  ),
  rejected: (
    <g>
      <circle cx="8" cy="8" r="7.467" fill="#E53D01" stroke="#E53D01" />
      <path stroke="#FFF" d="M11.2 4.8l-6.4 6.4M4.8 4.8l6.4 6.4" />
    </g>
  ),
  cancel: (
    <g>
      <circle cx="8" cy="8" r="7.467" fill="#E53D01" stroke="#E53D01" />
      <path stroke="#FFF" d="M11.2 4.8l-6.4 6.4M4.8 4.8l6.4 6.4" />
    </g>
  ),
  process: (
    <g>
      <path d="M3.5 7.5h11M11 4l3.5 3.5L11 11M.5.5v14M6.5.5v4M6.5 10.5v4" />
    </g>
  ),
  workflow: (
    <g>
      <path d="M3.5 7.5h11M11 4l3.5 3.5L11 11M.5.5v14M6.5.5v4M6.5 10.5v4" />
    </g>
  ),
  task: (
    <g>
      <path d="M11.5 1.5h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h1" />
      <path d="M4.5.5h5v2h-5zM4 9l2 2 4-4" />
    </g>
  ),
  conversation: (
    <g>
      <path d="M11.719.469L14.53 3.28h-3.004a3.75 3.75 0 0 0-2.803 1.26l-5.262 5.92A3.752 3.752 0 0 1 .66 11.719H.469M.469 3.281H.66a3.75 3.75 0 0 1 2.803 1.26l.964 1.084M11.719 14.531l2.812-2.812h-3.004a3.75 3.75 0 0 1-2.803-1.26l-.963-1.084" />
    </g>
  ),
  discussion: (
    <g>
      <path d="M11.719.469L14.53 3.28h-3.004a3.75 3.75 0 0 0-2.803 1.26l-5.262 5.92A3.752 3.752 0 0 1 .66 11.719H.469M.469 3.281H.66a3.75 3.75 0 0 1 2.803 1.26l.964 1.084M11.719 14.531l2.812-2.812h-3.004a3.75 3.75 0 0 1-2.803-1.26l-.963-1.084" />
    </g>
  ),
  approval: (
    <g>
      <path d="M.5 5.5v-5h5M14.5 5.5v-5h-5" />
      <path d="M.5.5l7 7v8M14.5.5l-5 5" />
    </g>
  ),
  group: (
    <g fill="none" fillRule="nonzero" stroke="#979797">
      <path d="M15 15.5C15 7.492 8.508 1 .5 1M12 15.5C12 9.149 6.851 4 .5 4M9 15.5A8.5 8.5 0 0 0 .5 7M6 15.5A5.5 5.5 0 0 0 .5 10" />
    </g>
  ),
  feed: (
    <g fill="none" fillRule="nonzero" stroke="#979797">
      <path d="M15 15.5C15 7.492 8.508 1 .5 1M12 15.5C12 9.149 6.851 4 .5 4M9 15.5A8.5 8.5 0 0 0 .5 7M6 15.5A5.5 5.5 0 0 0 .5 10" />
    </g>
  ),
  chat: (
    <g>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 1.8a.8.8 0 0 1 .8-.8h10.4a.8.8 0 0 1 .8.8V9a.8.8 0 0 1-.8.8H7.934L4.2 13V9.8H1.8A.8.8 0 0 1 1 9V1.8z"
      />
    </g>
  ),
  time: (
    <g>
      <path
        fill="#BBB"
        stroke="#BBB"
        strokeWidth=".3"
        d="M8.33 1A7.335 7.335 0 0 0 1 8.33a7.335 7.335 0 0 0 7.33 7.33 7.335 7.335 0 0 0 7.33-7.33A7.335 7.335 0 0 0 8.33 1zm0 .682a6.643 6.643 0 0 1 6.647 6.648 6.643 6.643 0 0 1-6.647 6.647A6.643 6.643 0 0 1 1.682 8.33 6.643 6.643 0 0 1 8.33 1.682zm0 1.363a.34.34 0 0 0-.341.341V8.33c0 .126.068.234.17.293l3.984 2.3c.164.095.375.041.47-.122a.34.34 0 0 0-.129-.463L8.67 8.132V3.386a.34.34 0 0 0-.34-.34z"
      />
    </g>
  ),
  ellipsis: (
    <g fill="#B3B3B3" fillRule="evenodd">
      <path d="M2 4C.893 4 0 3.107 0 2s.893-2 2-2 2 .893 2 2-.893 2-2 2zM8 4c-1.107 0-2-.893-2-2s.893-2 2-2 2 .893 2 2-.893 2-2 2zM14 4c-1.107 0-2-.893-2-2s.893-2 2-2 2 .893 2 2-.893 2-2 2z" />
    </g>
  ),
  ellipsisLarge: (
    <g fill="#222" fillRule="evenodd">
      <path d="M1.778 3.556A1.775 1.775 0 0 1 0 1.778C0 .794.794 0 1.778 0c.983 0 1.778.794 1.778 1.778 0 .983-.795 1.778-1.778 1.778zM7.704 3.556a1.775 1.775 0 0 1-1.778-1.778C5.926.794 6.72 0 7.704 0 8.687 0 9.48.794 9.48 1.778c0 .983-.794 1.778-1.777 1.778zM13.63 3.556a1.775 1.775 0 0 1-1.778-1.778C11.852.794 12.646 0 13.63 0c.983 0 1.777.794 1.777 1.778 0 .983-.794 1.778-1.777 1.778z" />
    </g>
  ),
  remove: (
    <g stroke="none" fill="#888" fillRule="evenodd">
      <path d="M2 6v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6H2z" />
      <path
        fillRule="nonzero"
        d="M12 3V1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h16V3h-4zm-2 0H6V2h4v1z"
      />
    </g>
  ),
  user: (
    <g>
      <circle cx="8" cy="6" r="2.5" />
      <path d="M12.953 13.621a5.491 5.491 0 0 0-9.906 0" />
      <circle cx="8" cy="8" r="7.5" />
    </g>
  ),
  related: (
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6.5 3.409h9v4h-9zM6.5 11.409h9v4h-9zM.5.41v13h3M.5 5.41h3" />
    </g>
  ),
  direct: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
    >
      <path d="M9.844.467H1.406A.936.936 0 0 0 .47 1.4V7c0 .515.42.933.937.933h1.875v2.8l2.813-2.8h3.75A.936.936 0 0 0 10.78 7V1.4a.936.936 0 0 0-.937-.933z" />
      <path d="M5.595 11.336H8.74l3.145 3.13v-3.13h2.097c.578 0 1.048-.468 1.048-1.044V4.03c0-.576-.47-1.043-1.048-1.043h-1.049" />
    </g>
  ),
  close: (
    <g>
      <path d="M11.5.5l-11 11M.5.5l11 11" />
    </g>
  ),
  checklist: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.469.5h1.875M.469 4.5h1.875M.469 8.5h1.875M.469 12.5h1.875M5.156.5h9.375M5.156 4.5h5.625M5.156 8.5h9.375M5.156 12.5h4.688" />
    </g>
  ),
  dateWarning: (
    <g strokeWidth=".857" transform="translate(.5 1)">
      <circle cx="6" cy="6" r="7.5" fill="#E53D01" stroke="#E53D01" />
      <path stroke="#FFF" d="M6 2.571V6h3.429" />
    </g>
  ),
  attach: (
    <g transform="translate(0 4)">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 10.712734,-0.02623967 7.1692302,3.6265118 a 1.9732085,2.0890738 0 0 0 0,3.0085155 2.1031803,2.2266775 0 0 0 2.9184578,0 l 4.793598,-4.9412212 a 3.944054,4.1756456 0 0 0 0,-6.0170328 4.2063608,4.4533549 0 0 0 -5.836917,0 L 3.8360438,1.047069 a 5.9172625,6.2647195 0 0 0 0,9.025549 c 2.4174758,2.491877 6.3367162,2.491877 8.7553762,0 l 4.791231,-4.9399714"
      />
    </g>
  ),
  favouriteFill: (
    <g>
      <path
        fill="#2E4C68"
        stroke="#2E4C68"
        d="M7.031 0l2.173 4.402 4.858.706-3.515 3.427.83 4.84L7.03 11.09l-4.345 2.284.83-4.839L0 5.108l4.858-.706z"
      />
    </g>
  ),
  favourite: (
    <g>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 0l1.854 3.95L13 4.583l-3 3.075.708 4.342L7 9.95 3.292 12 4 7.658 1 4.583l4.146-.633z"
      />
    </g>
  ),
  participant: (
    <g>
      <path
        fill="#888"
        fillRule="nonzero"
        d="M4.5 4C5.338 4 6 3.398 6 2.682v-.364C6 1.602 5.338 1 4.5 1S3 1.602 3 2.318v.364C3 3.398 3.662 4 4.5 4zm0 1C3.13 5 2 3.973 2 2.682v-.364C2 1.027 3.13 0 4.5 0S7 1.027 7 2.318v.364C7 3.973 5.87 5 4.5 5zM9 7.285v1.26H0v-1.26c0-.893.643-1.662 1.553-1.888C2.55 5.15 3.553 5 4.5 5c.947 0 1.95.15 2.947.397C8.357 5.623 9 6.392 9 7.285zm-1 0c0-.42-.317-.8-.794-.917C6.282 6.138 5.355 6 4.5 6s-1.782.139-2.706.368c-.477.118-.794.498-.794.917v.26h7v-.26z"
      />
    </g>
  ),
  caretDown: (
    <g>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0 1l5.5 5L11 1"
      />
    </g>
  ),
  received: (
    <g fill="#888" stroke="none" fillRule="nonzero">
      <path d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z" />
      <path d="M8.138 3.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177 4.43-4.177z" />
    </g>
  ),
  read: (
    <g fill="#0ABC8A" stroke="none" fillRule="nonzero">
      <path d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z" />
      <path d="M8.138 3.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177 4.43-4.177z" />
    </g>
  ),
  sent: (
    <path
      fill="#888"
      stroke="none"
      fillRule="nonzero"
      d="M3.708 4.313L8.138.136a.5.5 0 1 1 .686.728l-4.773 4.5a.5.5 0 0 1-.686 0l-2.651-2.5a.5.5 0 1 1 .686-.728l2.308 2.177z"
    />
  ),
  back: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#111"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.5 5.5H.5M5.5.5l-5 5 5 5" />
    </g>
  ),
  save: (
    <g fill="none" fillRule="nonzero" stroke="#1581ba" strokeLinecap="round">
      <path d="M.5 1v12M6.5 8.5L.5 13M6.5 8.5l6 4.5M12.5 1v12M12.5 1H.5M10.5 3.5h-8M8 5.5H2.5" />
    </g>
  ),
  list: (
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.5.5H10M15.5.5h-2M.5 5.5h2M5.5 5.5h10M.5 10.5h10M13.5 10.5h2" />
    </g>
  ),
  doubleChat: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
    >
      <path d="M9.844.467H1.406A.936.936 0 0 0 .47 1.4V7c0 .515.42.933.937.933h1.875v2.8l2.813-2.8h3.75A.936.936 0 0 0 10.78 7V1.4a.936.936 0 0 0-.937-.933z" />
      <path d="M5.595 11.336H8.74l3.145 3.13v-3.13h2.097c.578 0 1.048-.468 1.048-1.044V4.03c0-.576-.47-1.043-1.048-1.043h-1.049" />
    </g>
  ),
  searchBox: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <circle cx="7.5" cy="7.5" r="4.125" />
      <path d="M15 15l-4.125-4.125M7.5 15H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v4.5" />
    </g>
  ),
  triangleDown: (
    <g>
      <path fill="#B4B4B4" fillRule="nonzero" d="M8 0L4 4 0 0z" />
    </g>
  ),
  owner: (
    <svg width={15} height={15} viewBox="0 0 8 8">
      <g fill="none" fillRule="evenodd" stroke="#000">
        <path strokeLinejoin="round" d="M0 6.308h7.25" />
        <path d="M7.5 1.805L6.48 3.504 4 .747 1.52 3.504.5 1.805v5.528c0 .114.043.167.033.167h6.934c-.01 0 .033-.053.033-.167V1.805z" />
      </g>
    </svg>
  ),
  rightArrow: (
    <g>
      <path
        fill="#989CA6"
        fillRule="nonzero"
        d="M.83 14.549c-.44.446-.44 1.17 0 1.616.439.447 1.151.447 1.59 0l6.75-6.857c.44-.446.44-1.17 0-1.616L2.42.835a1.113 1.113 0 0 0-1.59 0c-.44.446-.44 1.17 0 1.616L6.784 8.5.83 14.549z"
      />
    </g>
  ),
  triangleRight: (
    <g transform="translate(-893.000000, -389.000000)">
      <g transform="translate(883.000000, 389.000000)">
        <g
          transform="translate(15.000000, 10.000000) rotate(-90.000000) translate(-15.000000, -10.000000) translate(5.000000, 5.000000)"
          fillRule="nonzero"
        >
          <polygon id="Shape" points="20 0 10 10 0 0" />
        </g>
      </g>
    </g>
  ),
  navigateTo: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M13 11.154V1H2.846M13 1L1 13" />
    </g>
  ),
  plus: (
    <g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 8.84h8.024V0h2.363v8.84h8.024v2.28h-8.024V20H8.024v-8.88H0z"
      />
    </g>
  ),
  plusCircle: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <circle cx="6" cy="6" r="6" strokeWidth="1.071" />
      <path d="M6 3v6M3 6h6" />
    </g>
  ),
  edit: (
    <g fill="#888" fillRule="evenodd">
      <path d="M8.1 3.5L.3 11.3c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3l7.8-7.8-4.4-4.4zM15.7 3.3l-3-3c-.4-.4-1-.4-1.4 0L9.5 2.1l4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4z" />
    </g>
  ),
  editBlue: (
    <g fill="#2F80ED" fillRule="evenodd">
      <path d="M8.1 3.5L.3 11.3c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3l7.8-7.8-4.4-4.4zM15.7 3.3l-3-3c-.4-.4-1-.4-1.4 0L9.5 2.1l4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4z" />
    </g>
  ),
  download: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.5 4.5v11M12.5 10.5l-5 5-5-5M14.5.5H.5" />
    </g>
  ),
  reload: (
    <g fill="none" fillRule="nonzero" stroke="#979797">
      <path d="M6.47 2.077C3.45 2.077 1 4.452 1 7.381c0 1.98 1.12 3.707 2.778 4.618" />
      <path d="M5.34 1.464l1.447.612-1.373.755" />
      <g>
        <path d="M5.53 12.423c3.02 0 5.47-2.375 5.47-5.304 0-1.98-1.12-3.707-2.778-4.618" />
        <path d="M6.765 13.036l-1.447-.612 1.373-.755" />
      </g>
    </g>
  ),
  launch: (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M13 11.154V1H2.846M13 1L1 13" />
    </g>
  ),
  mail: (
    <g stroke="#969696">
      <path d="M14.5 13.833h-13a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z" />
      <path d="M2.5 3.833l5.5 4.5 5.5-4.5" />
    </g>
  ),
  mailLight: (
    <g stroke="#fff">
      <path d="M14.5 13.833h-13a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z" />
      <path d="M2.5 3.833l5.5 4.5 5.5-4.5" />
    </g>
  ),
  copy: (
    <g>
      <path
        d="M8.99501 7.00513C10.369 8.37913 10.369 10.6061 8.99501 11.9801L7.00501 13.9701C5.63101 15.3441 3.40401 15.3441 2.03001 13.9701C0.656012 12.5961 0.656012 10.3691 2.03001 8.99513L3.77801 7.29713"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00511 8.99501C5.63111 7.62101 5.63111 5.39401 7.00511 4.02001L8.99511 2.03001C10.3691 0.656012 12.5961 0.656012 13.9701 2.03001C15.3441 3.40401 15.3441 5.63101 13.9701 7.00501L12.2221 8.70301"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  draggable: (
    <g width="20" height="11" fill="none">
      <line y1="0.5" x2="20" y2="0.5" stroke="black" />
      <line y1="5.5" x2="20" y2="5.5" stroke="black" />
      <line y1="10.5" x2="20" y2="10.5" stroke="#2A2A2A" />
    </g>
  )
};

const getSVG = (icon: string) => svg[icon];

export { svg };
export default getSVG;
