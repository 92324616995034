// @flow

import React from "react";
import moment from "moment";

import { Timestamp as StyledTimestamp } from "src/styles";

type Props = {
  timestamp: Date
};

const Datestamp = ({ timestamp }: Props) => {
  return (
    <StyledTimestamp>{moment(timestamp).format("MMM Do")}</StyledTimestamp>
  );
};

export default Datestamp;
