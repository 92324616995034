// @flow

import type {
  PopoverHeaderProps,
  PopoverBodyProps,
  IconProps,
  IconButtonProps,
  StackProps
} from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";

export const popover: {
  header: {
    root: PopoverHeaderProps,
    backButton: IconProps,
    closeButton: IconButtonProps
  },
  body: {
    root: PopoverBodyProps,
    sourcePicker: StackProps,
    sharepoint: {
      adminIntegrator: {
        root: StackProps
      }
    }
  }
} = {
  header: {
    root: {
      as: HStack,
      sx: {
        justifyContent: "space-between",
        backgroundColor: "unifize_grey0",
        px: 2,
        py: "6px"
      }
    },

    backButton: {
      sx: {
        width: 4,
        minWidth: 4,
        height: 4,
        svg: {
          w: 5,
          h: 10
        }
      }
    },

    closeButton: {
      sx: {
        width: 4,
        minWidth: 4,
        height: 4,
        svg: {
          w: 9.33,
          h: 9.33
        }
      }
    }
  },

  body: {
    root: {
      sx: {
        p: 0
      }
    },

    sourcePicker: {
      root: {
        sx: {
          width: "100%",
          alignItems: "start"
        }
      }
    },

    sharepoint: {
      adminIntegrator: {
        root: {
          sx: {
            width: "100%",
            justifyContent: "space-between",
            backgroundColor: "yellow.50",
            border: "1px solid",
            borderColor: "yellow.200",
            px: 4,
            py: 2
          }
        }
      }
    }
  }
};
