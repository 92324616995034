// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import UserComponent from "src/components/user.old/user";
import UserWithDisplayName from "src/components/user.old/UserWithDisplayName";
import ProfileImage from "src/components/user.old/ProfileImage";

import { getUser } from "src/reducers";

import * as actions from "src/actions/user";

import type { AppState, UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  children: ?any,
  type: ?string,
  active?: boolean,
  authenticated: boolean,
  remove: ?boolean,
  pos: string,
  user: UnifizeUser,
  getUser: Function,
  clickHandler?: Function
};

class User extends Component<Props> {
  static defaultProps = {
    active: false
  };

  render() {
    const { user, type, pos, children, clickHandler, remove } = this.props;
    if (user && user !== null) {
      switch (type) {
        case "userWithDisplayName":
          return (
            <UserWithDisplayName
              user={user}
              // eslint-disable-next-line react/no-children-prop
              children=""
              pos={pos}
              remove={remove}
              clickHandler={clickHandler}
            />
          );
        case "profileImage":
          return <ProfileImage user={user} active={this.props.active} />;
        default:
          // eslint-disable-next-line react/no-children-prop
          return <UserComponent user={user} children={children} pos={pos} />;
      }
    }
    return <span />;
  }
}

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps, actions)(User);
