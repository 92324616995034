// @flow

import { connect } from "react-redux";
import React from "react";

import { MyConversationChart as StyledConversation, SubTitle } from "./styles";
import Chart from "./Chart";
import { getChart } from "src/actions/chart";
import { getChart as getChartData } from "src/reducers";

import type { AppState, HomescreenTile } from "src/types";
import { useEffect } from "react";

type Props = {
  chart: Object,
  chartData: Object,
  tile: HomescreenTile,
  _getChart: Function
};

const DashboardChart = ({ chart, tile, _getChart }: Props) => {
  useEffect(() => {
    _getChart(tile.settings.chart);
  }, [tile]);

  return (
    <StyledConversation color={tile.settings.tileColor}>
      <h4>{tile.title}</h4>
      <SubTitle>{tile.subTitle}</SubTitle>
      <Chart
        chartId={tile.settings.chart}
        key={chart.id}
        title={chart.title}
        description={chart.description}
        reportId={chart.reportId}
        x={chart.x}
        y={chart.y}
        yAxisLabel={chart.yAxisLabel}
        typeId={chart.type}
      />
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chart: getChartData(app, props.tile.settings.chart)
});

export default connect(mapStateToProps, {
  _getChart: getChart
})(DashboardChart);
