// @flow

import React from "react";

import { ParentWrapper } from "./styles";
import ChatroomSelect from "src/containers/chatroom/select";
import { FormGroup, Label } from "src/styles/form";

import type { RoomId } from "src/types";

type Props = {
  parent: ?RoomId,
  handleParent: Function
};

const Parent = ({ parent, handleParent }: Props) => (
  <FormGroup data-cy="parentLabelContainer">
    <Label>Parent</Label>
    <ParentWrapper>
      <ChatroomSelect value={parent} handleChange={handleParent} />
    </ParentWrapper>
  </FormGroup>
);

export default Parent;
