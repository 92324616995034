// @flow

import { takeEvery, put, select } from "redux-saga/effects";
import * as R from "ramda";

import { getReportsById } from "src/selectors";
import * as atypes from "src/constants/actionTypes";

function* updateFieldSizeOnReportCreation({ payload }): any {
  const reportId = Object.keys(payload)[0];
  const report = payload[reportId];
  const fieldSizeMap = report?.settings?.fieldSizeMap;

  if (R.has("fieldSizeMap", report.settings)) {
    yield put({
      type: atypes.UPDATE_FIELD_SIZE_FROM_REPORT,
      payload: fieldSizeMap
    });
  }
}

function* watchReportCreationSuccess(): any {
  yield takeEvery(
    atypes.CREATE_REPORT_SUCCESS,
    updateFieldSizeOnReportCreation
  );
}

function* updateFieldSizeOnReportLoad({ payload }): any {
  const reportId = payload.reportId;
  const reportsById = yield select(getReportsById);
  const report = reportsById[reportId];

  if (R.has("fieldSizeMap", report?.settings)) {
    const fieldSizeMap = report.settings.fieldSizeMap || {};

    const updatedFieldSizeMap = {};
    for (const [key, value] of Object.entries(fieldSizeMap)) {
      if (key.includes(">")) {
        // Replacing because embedded field filters property names are stored
        // with arrow in firestore and the table implementation in manage view
        // uses dashes
        const replaceWithArrow = key.split(">").join("-");
        updatedFieldSizeMap[replaceWithArrow] = value;
      } else {
        updatedFieldSizeMap[key] = value;
      }
    }
    yield put({
      type: atypes.UPDATE_FIELD_SIZE_FROM_REPORT,
      payload: updatedFieldSizeMap
    });
  } else {
    yield put({
      type: atypes.UPDATE_FIELD_SIZE_FROM_REPORT,
      payload: {}
    });
  }
}

function* watchSetReportSuccess(): any {
  yield takeEvery(atypes.SET_REPORTS_SUCCESS, updateFieldSizeOnReportLoad);
}

export default [watchReportCreationSuccess(), watchSetReportSuccess()];
