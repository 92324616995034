// @flow

import React from "react";
import { Tooltip as ChakraToolTip, Box } from "@chakra-ui/react";
import type { SystemCSSProperties } from "@chakra-ui/react";

const Content = ({ children, contentSx, ...rest }: Object, ref) => (
  <Box ref={ref} sx={{ ...contentSx }} {...rest} bg="none">
    {children}
  </Box>
);

const TooltipContent = React.forwardRef(Content);

type Props = {
  children: any,
  title: string,
  description?: string,
  placement?: string,
  hide?: boolean,
  hasArrow?: boolean,
  sx?: SystemCSSProperties,
  contentSx?: SystemCSSProperties
};

const Tooltip = ({
  children,
  title,
  description = "",
  placement,
  hide,
  hasArrow = true,
  sx = {},
  contentSx = {},
  ...props
}: Props) => {
  return (
    <ChakraToolTip
      {...props}
      sx={{
        display: hide && "none",
        backgroundColor:
          (title?.length ?? 0) > 0 ? "unifize_unifizeBlue" : "transparent",
        ...sx
      }}
      hasArrow={hasArrow}
      label={
        <>
          <Box fontWeight="bold">{title}</Box>
          {description !== "" && (
            <div>
              <i>{description}</i>
            </div>
          )}
        </>
      }
      aria-label={title}
      fontSize="xs"
      placement={placement}
    >
      <TooltipContent sx={contentSx}>{children}</TooltipContent>
    </ChakraToolTip>
  );
};

export default Tooltip;
