// @flow

import React from "react";

type Props = {
  width?: number | string,
  height?: number | string,
  handleClick?: Function
};

const ChecklistEdit = ({
  handleClick = () => {},
  width = 24,
  height = 24
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    style={{ marginLeft: "1px", cursor: "pointer" }}
  >
    <path
      d="M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
      fill="#1581BA"
    />
  </svg>
);

export default ChecklistEdit;
