// @flow

import { connect } from "react-redux";
import React, { useRef, useCallback } from "react";

import ChartList from "src/components/Manage/Reports/Instances/Chart/ChartList";
import { addChartToDashboard } from "src/actions/dashboard";

import type { AppState } from "src/types";

type Props = {
  seqNo: number,
  dashboard: number,
  handleClose: Function,
  _addChartToDashboard: Function
};

const Charts = ({
  dashboard,
  seqNo,
  handleClose,
  _addChartToDashboard
}: Props) => {
  const outerRef = useRef(null);

  const handleSelect = useCallback(
    (chart: number) => {
      _addChartToDashboard({ chart, dashboard, seqNo });
      handleClose();
    },
    [_addChartToDashboard, handleClose, seqNo + 1, dashboard]
  );

  return (
    <ChartList
      showAll
      handleClick={handleSelect}
      header="Available charts"
      handleClose={handleClose}
      parentRef={outerRef}
      newChart={false}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  dashboard: app.dashboard.current,
  seqNo: app.dashboard.metaData.charts.length || 1
});

export default connect(mapStateToProps, {
  _addChartToDashboard: addChartToDashboard
})(Charts);
