// @flow

import { connect } from "react-redux";
import React, { useEffect, useCallback, useState } from "react";
import onClickOutside from "react-onclickoutside";

import {
  List as StyledDropdown,
  Input,
  ListItem
} from "src/components/user/Analytics/Filters/styles";
import { searchWorkflow } from "src/actions/workflows";
import Name from "src/containers/workflow/Name";

import type { AppState } from "src/types";

type Props = {
  outerRef: any,
  searchResults: Array<number>,
  handleSelect: Function,
  handleClose: Function,
  _searchWorkflow: Function
};

const ProcessDropdown = ({
  outerRef,
  searchResults,
  handleSelect,
  handleClose,
  _searchWorkflow
}: Props) => {
  const [text, setText] = useState("");

  ProcessDropdown.handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef) {
        if (outerRef.current && !outerRef.current.contains(e.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, true);
    return () => {
      document.removeEventListener("keydown", closeModal, true);
    };
  }, []);

  const handleProcessSelect = useCallback(
    (e: any, workflow: number) => {
      e.preventDefault();
      handleSelect(workflow);
    },
    [handleSelect]
  );

  useEffect(() => {
    _searchWorkflow("");
  }, [_searchWorkflow]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
      _searchWorkflow(value);
    },
    [text, setText, _searchWorkflow]
  );

  return (
    <StyledDropdown>
      <Input
        type="text"
        value={text}
        placeholder="Search process"
        onChange={handleSearch}
        autoFocus
      />
      <ul>
        {searchResults.map(id => (
          <ListItem onClick={e => handleProcessSelect(e, id)} key={id}>
            <span>
              <Name id={id} />
            </span>
          </ListItem>
        ))}
      </ul>
    </StyledDropdown>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  searchResults: app.workflow.searchResults
});

const clickOutsideConfig = {
  handleClickOutside: () => ProcessDropdown.handleClickOutside
};

export default connect(mapStateToProps, {
  _searchWorkflow: searchWorkflow
})(onClickOutside(ProcessDropdown, clickOutsideConfig));
