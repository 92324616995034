// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import Row from "./Row";
import { getFilteredGroups } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  groups: Array<string>
};

const Body = ({ groups }: Props) => {
  return (
    <tbody tabIndex="0">
      {R.map(group => {
        return <Row key={group} id={Number(group)} />;
      }, groups)}
    </tbody>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  groups: getFilteredGroups(app)
});

export default connect(mapStateToProps)(Body);
