// @flow

import React from "react";

const DropdownCaret = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      viewBox="0 0 9 5"
    >
      <path
        fill="#888"
        fillRule="nonzero"
        d="M7.903.183a.656.656 0 0 1 .909 0 .613.613 0 0 1 0 .884l-3.857 3.75a.656.656 0 0 1-.91 0L.188 1.067a.613.613 0 0 1 0-.884.656.656 0 0 1 .91 0L4.5 3.491 7.903.183z"
      />
    </svg>
  );
};

export default DropdownCaret;
