// @flow

import type { FormControlProps, StackProps, BoxProps } from "@chakra-ui/react";
import { mobileBreakpoint } from "src/constants";

export const container: StackProps = {
  gridGap: "71px",
  [mobileBreakpoint]: {
    gridGap: "16px",
    width: "100%",
    alignItems: "start"
  }
};

export const formControl: FormControlProps = {
  width: "270px",
  [mobileBreakpoint]: {
    width: "100%"
  }
};

export const fieldsRow: StackProps = {
  alignItems: "start",
  gridGap: "32px",
  [mobileBreakpoint]: {
    flexDirection: "column",
    gridGap: "16px",
    width: "100%"
  }
};

export const labelContainer: StackProps = {
  justifyContent: "space-between",
  width: "100%"
};

export const strengthMeterContainer: BoxProps = { height: "4px" };
