// @flow

import React from "react";

import { Selector as StyledSelector } from "./styles";
import Checkbox from "src/components/Checkbox";

import type { UID } from "src/types";

type Props = {
  selected: boolean,
  stickiness: boolean,
  uid: UID,
  handleSelection: Function
};

const Selector = ({ selected, stickiness, uid, handleSelection }: Props) => {
  return (
    <StyledSelector
      tabIndex="0"
      stickiness={stickiness}
      onClick={handleSelection}
      onKeyPress={handleSelection}
    >
      <Checkbox
        id={`group${uid}`}
        checked={selected}
        handleChange={handleSelection}
      />
    </StyledSelector>
  );
};

export default Selector;
