// @flow

import styled from "@emotion/styled";

import { ScrollableBox, LeftAlignedListItem } from "src/styles/box";
import { Initial } from "src/components/user/styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const Filter = styled(ScrollableBox)`
  position: absolute;
  z-index: 4;
  top: 40px;
  padding-top: 0;
  width: 395px;
  > ul {
    max-height: 10.5em;
    margin-top: 0.5em;
    ${props =>
      props.fullWidth && "> li > span { max-width: none !important; }"};
  }
`;

export const ListItem = styled(LeftAlignedListItem)`
  padding: 4px 8px;
  border-radius: 2px;
  line-height: 20px;
`;

export const SubHeading = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 0em 0.5em;
  margin-top: 0.8em;
  text-align: left;
`;

export const Priority = styled(ListItem)`
  > svg {
    height: 0.8em;
    width: 0.8em;
    margin-right: 8px;
  }
`;

export const Status = styled(ListItem)``;

export const User = styled(ListItem)`
  > div:nth-of-type(2),
  > img:last-child {
    margin-left: 0.3em;
  }

  >span: last-child {
    max-width: 11em;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    :last-child {
      margin-left: 0.5em;
    }
  }

  ${Initial} {
    width: 23px;
    height: 23px;
    margin-left: 0 !important;
  }
`;

export const Chatroom = styled(ListItem)`
  > span {
    display: inline-block;
    max-width: 15em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ListLoading = styled.div`
  max-width: 15em;
  padding: ${spacing.space_inset_squish_s};
  cursor: default;
`;

export const Separator = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background-color: ${colors.highlight};
`;

export const BlanksNonBlanks = styled(ListItem)``;

export const SelectAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0 8px 8px;
`;

export const PendingText = styled.span`
  color: ${colors.grey18};
`;

export const CompleteText = styled.span`
  color: ${colors.green};
`;

export const CancelledText = styled.span`
  color: ${colors.inkLight};
`;

export const ArchivedText = styled.span`
  color: ${colors.ink};
`;

export const AllRecordsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  > span {
    font-weight: bold;
  }
`;

export const FilterToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
