// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { colors } from "src/styles/constants";

const Overlay = styled.div`
  ${({ show }) =>
    show &&
    css`
      &:after {
        display: block;
        position: absolute;
        background: ${colors.mediumBlack};
        opacity: 0.1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        content: "";
      }
    `};
`;

export { Overlay };
