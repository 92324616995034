// @flow

import styled from "@emotion/styled";

export const FormHeading = styled.h4`
  margin: 0;
  margin-top: 0.3em;
`;

export const ApprovalTypeInfo = { minWidth: 0, maxWidth: "15rem" };
