// @flow

import { connect } from "react-redux";

import BreadCrumbs from "src/components/breadcrumbs/Long";
import { getBreadCrumbs } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  roomId: string
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  breadCrumb: getBreadCrumbs(app, props.roomId)
});

export default connect(mapStateToProps)(BreadCrumbs);
