// @flow

import React, { useCallback, useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import { InlineFormGroup, Label } from "src/styles/form";
import { TextArea } from "src/styles/input";

type Props = {
  description: string,
  handleChange: Function
};

const Details = ({ description, handleChange }: Props) => {
  const [text, setText] = useState(description || "");
  const [value] = useDebounce(text, 500);

  const handleText = useCallback(
    e => {
      setText(e.target.value);
    },
    [setText]
  );

  // Updating redux state when local state is changed after time interval
  useEffect(() => {
    handleChange("description", value);
  }, [value]);

  return (
    <InlineFormGroup>
      <Label htmlFor="checkbox">Details / Objective</Label>
      <TextArea
        value={text}
        id="description"
        onChange={handleText}
        placeholder="Enter Details / Objective Here"
      />
    </InlineFormGroup>
  );
};

export default Details;
