// @flow

import { useDispatch, useSelector } from "react-redux";
import {
  toggleRowExpansion,
  togglePicklistExpansion,
  resetPicklistExpansion
} from "src/actions/checklist";
import {
  getExpandedRowsLength,
  getExpandedRows,
  getExpandedPicklists
} from "src/reducers";
import type { ColumnId } from "src/types";

/**
 * Detect and trigger vertical embedded field expansion
 *
 * @param {ColumnId} columnId of the linked field
 * @param {string} autoNo of the instance containing the linked field
 * @param {string} roomId of linked field's chatroom
 * @returns {{
 *   toggleExpand: Function,
 *   isRowExpanded: boolean,
 *   expandedRowsLength: number,
 *   maxRows: number,
 *   togglePicklist: Function,
 *   expandedPicklists: Object,
 *   resetPicklist: Function,
 *   getPicklistLength: Function,
 *   getEmbeddedLinkedFieldLength: Function
 * }} - An object containing various functions and state variables related to row expansion.
 */
const useRowExpansion: Function = (
  columnId: ColumnId,
  autoNo: string,
  roomId: string
) => {
  const dispatch = useDispatch();
  const parentId = columnId.split("-")[0];
  const expandedRows = useSelector(({ app }) => getExpandedRows(app));
  const expandedPicklists = useSelector(({ app }) => getExpandedPicklists(app));

  // get the max number of child rows inside current row after expansion
  const expandedRowsLength = useSelector(({ app }) =>
    getExpandedRowsLength(app, autoNo, parentId)
  );

  // check whether the row is expanded
  const isRowExpanded =
    expandedRows[autoNo] && expandedRows[autoNo][`${parentId}-${roomId}`]
      ? expandedRows[autoNo][`${parentId}-${roomId}`]
      : false;

  const maxRows =
    (expandedRows[autoNo] && expandedRows[autoNo].maxRows[0]) || 1;

  const toggleExpand = () => {
    dispatch(
      toggleRowExpansion({
        instanceId: autoNo,
        id: `${parentId}-${roomId}`,
        maxRows: expandedRowsLength
      })
    );
  };

  const getPicklistLength = ({
    instanceId,
    index
  }: {
    instanceId: string,
    index: number
  }) => {
    if (expandedPicklists[instanceId] && expandedPicklists[instanceId][index]) {
      return expandedPicklists[instanceId][index].isOpen
        ? expandedPicklists[instanceId][index].length
        : 0;
    }
    return 0;
  };

  const togglePicklist = ({
    instanceId,
    index,
    length
  }: {
    instanceId: string,
    index: number,
    length: number
  }) => {
    dispatch(
      togglePicklistExpansion({
        instanceId: instanceId,
        index: index,
        length: length
      })
    );
  };

  const resetPicklist = ({ instanceId }: { instanceId: string }) => {
    dispatch(
      resetPicklistExpansion({
        instanceId: instanceId
      })
    );
  };

  return {
    toggleExpand,
    isRowExpanded,
    expandedRowsLength,
    maxRows,
    togglePicklist,
    expandedPicklists,
    resetPicklist,
    getPicklistLength
  };
};

export default useRowExpansion;
