// @flow

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import Link from "redux-first-router-link";

import type { Message } from "src/types";
import * as atypes from "src/constants/actionTypes";
import { getChatRoom } from "src/reducers";

import Container from "src/components/messages/Chat/Notification/Common";
import ChatroomName from "src/containers/chatroom/Name";

type Props = {
  notification: Message
};

const Create = ({ notification }: Props) => {
  const revisionId = R.path(["data", "id"], notification);
  const revision = useSelector(
    ({ app }) => revisionId && getChatRoom(app, `${revisionId}`)
  );

  // Old notification format
  if (!revisionId)
    return (
      <Container {...notification}>
        {" "}
        created <strong>V{notification.data.version}</strong> of this
        conversation
      </Container>
    );

  // Either the revision is getting synced or
  // The current user doesn't have access to it
  if (!revision) return null;

  return (
    <Container {...notification}>
      {" "}
      created a revision{" "}
      <Link
        to={{
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: revision.address
          },
          meta: {
            query: {
              templatedId: revision.templateId
            }
          }
        }}
        title={revision.title}
      >
        <ChatroomName id={revisionId} />
      </Link>
    </Container>
  );
};
export default Create;
