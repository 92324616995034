// @flow

import React from "react";

import { Label as StyledLabel, WhoColumn, WhenColumn } from "./styles";

const Label = () => (
  <StyledLabel>
    <WhoColumn>Prompt whom</WhoColumn>
    <WhenColumn>When</WhenColumn>
  </StyledLabel>
);

export default Label;
