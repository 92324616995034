// @flow

import React from "react";
import { arrayMove } from "react-sortable-hoc";

import SortableList from "./SortableList";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const Series = ({ chartDetails, setChartDetails }: Props) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setChartDetails(prevChart => {
      const series = prevChart.series;
      const updatedSeries = arrayMove(series, oldIndex, newIndex);
      return { ...prevChart, series: updatedSeries };
    });
  };

  return (
    <SortableList
      chartDetails={chartDetails}
      setChartDetails={setChartDetails}
      onSortEnd={onSortEnd}
      useDragHandle
    />
  );
};

export default Series;
