// @flow

import { connect } from "react-redux";
import React from "react";
import { Text } from "@chakra-ui/react";

import { iconSize } from "src/styles/constants/size";
import IconLabel from "src/components/IconLabel";
import * as styles from "./styles";

import type { AppState, SystemProcess } from "src/types";

type Props = {
  type: string,
  heading: string,
  templateId: ?number,
  systemProcess: SystemProcess
};

const ConversationIcon = ({
  type,
  heading,
  templateId,
  systemProcess
}: Props) => {
  if (type === "workflow" && systemProcess.task == templateId) {
    return (
      <IconLabel icon={"task"} label={heading} size={iconSize.space_s} handle />
    );
  }

  if (type === "workflow") {
    return <Text sx={styles.NewConversationHeader}>{heading}</Text>;
  }

  return (
    <IconLabel
      icon={type || "feed"}
      label={heading}
      size={iconSize.space_s}
      handle
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  systemProcess: app.systemProcess,
  templateId: app.conversationDialog.templateId
});

export default connect(mapStateToProps)(ConversationIcon);
