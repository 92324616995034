// @flow

import React from "react";
import * as R from "ramda";

import SegmentName from "./SegmentName";
import { UserSegmentSelection as StyledUserSegmentSelection } from "./styles";

type Props = {
  right: boolean,
  value: Array<number>,
  toggleDropdown: Function
};

const UserSegmentSelection = ({ right, value, toggleDropdown }: Props) => {
  const first = R.head(value || []);

  return (
    <StyledUserSegmentSelection right={right} onClick={toggleDropdown}>
      <SegmentName id={first} /> &nbsp;
      {value.length > 1 && <b>{value.length - 1} others </b>}
    </StyledUserSegmentSelection>
  );
};

export default UserSegmentSelection;
