// @flow

import { connect } from "react-redux";

import RelatedModal from "src/components/chatroom/related/RelatedModal";
import { getParentsCount, getChildrenCount } from "src/reducers";
import { searchChatroom } from "src/actions/chatroom";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  relatedConversations: app.chatRooms.relatedConversationSearch,
  parentCount: getParentsCount(app, props.roomId),
  childCount: getChildrenCount(app, props.roomId),
  relatedCount: app.chatRooms.relatedCount
});

export default connect(mapStateToProps, {
  _searchChatroom: searchChatroom
})(RelatedModal);
