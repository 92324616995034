// @flow

import styled from "@emotion/styled";

import { SubHeading as StyledSubHeading } from "src/styles/typography";
import Radio from "src/components/Radio/styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const Private = styled.div`
  width: 100%;
  padding-bottom: 5.5rem;
  height: 70vh;
  overflow: auto;
`;

export const PrivacyContainer = styled.div`
  width: 87.5%;
  margin: 20px 0 0 27px;
  padding: 10px 0;
`;

export const Switch = styled.div`
  display: flex;
  justify-content: space-between;
  > label {
    padding: 0 2px;
  }
`;

export const SubHeading = styled(StyledSubHeading)`
  padding-left: 2px;
`;

export const Type = styled.div`
  > div:first-of-type {
    margin-top: 8px;
  }
`;

export const Option = styled.div`
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
  border: solid 1px ${props => (props.selected ? colors.active : colors.grey6)};
  background: ${props => (props.selected ? colors.grey16 : colors.brightLight)};
  transition: all 0.25s;
  display: grid;
  grid-template-columns: 2em 1fr;
  #search {
    background: ${props =>
      props.selected ? colors.grey17 : colors.brightLight};
  }
  :hover {
    ${Radio} > label {
      > input[type="radio"] + div {
        > span {
          background: ${colors.grey9};
        }
      }
    }
  }
`;

export const WhiteList = styled.div`
  padding-top: ${spacing.space_l};
`;

export const Header = styled.div`
  display: inline-block;
  font-weight: bold;
  > svg {
    margin-right: 0.4em;
  }
`;
