// @flow

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";

import { EditTitle as StyledTitle } from "../styles";
import { editDashboardTitle } from "src/actions/dashboard";
import { Input } from "src/styles/input";
import useInput from "src/hooks/useInput";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  id: number,
  value: string,
  handleClose: Function,
  _editDashboardTitle: Function
};

const Edit = ({ id, value, handleClose, _editDashboardTitle }: Props) => {
  const { value: title, handleChange } = useInput(value);
  const outerRef = useRef(null);

  const handleClickOutside = () => {
    if (title !== value && title.length !== 0) {
      _editDashboardTitle({ id, title });
    }
    handleClose();
  };

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter" && title !== value && title.length !== 0) {
        _editDashboardTitle({ id, title });
        handleClose();
      }
    },
    [title, value, id, handleClose, _editDashboardTitle]
  );
  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledTitle>
        <Input
          ref={outerRef}
          type="text"
          value={title}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          autoFocus
        />
      </StyledTitle>
    </OutsideClickHandler>
  );
};

export default connect(null, {
  _editDashboardTitle: editDashboardTitle
})(Edit);
