// @flow

import React from "react";
import * as R from "ramda";

import { FormHeading } from "./styles";
import ChecklistContent from "./Content";
import FormName from "src/components/Form/Name";

type Props = {
  forms: Object,
  timestamp: string
};

const Forms = ({ forms, timestamp }: Props) => {
  const formIds = R.keys(forms);

  return (
    <>
      {formIds.map(formId => {
        const form = forms[formId];
        return (
          <div key={formId}>
            <FormHeading>
              <FormName id={form.templateId} />
              {"    "}
              {form.address && <>({form.address})</>}
            </FormHeading>
            <ChecklistContent timestamp={timestamp} data={form} />
          </div>
        );
      })}
    </>
  );
};

export default Forms;
