// @flow

import React, { useCallback } from "react";

import Organize from "../Organize";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";
import { RelativeBox } from "src/styles/box";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";

const Customize = () => {
  const { value: customize, toggleBoolean } = useBoolean();

  const toggleCustomize = useCallback(
    (event: any) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      toggleBoolean();
    },
    [toggleBoolean]
  );

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <BlueOutlineButton
        leftIcon={<Icon type="editblue" />}
        onClick={toggleCustomize}
      >
        Customize View
      </BlueOutlineButton>
      {customize && (
        <RelativeBox onClick={preventDefault}>
          <Organize handleClose={toggleCustomize} />
        </RelativeBox>
      )}
    </>
  );
};

export default Customize;
