// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { Box } from "@chakra-ui/react";

export const Organize = styled(Box)`
  text-align: left;
  padding: 16px 20.8px;
  background-color: ${colors.brightLight};
  width: 500px;
  border-radius: ${radius.medium};
  position: initial;
`;

export const SortHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 4px;
  margin-bottom: 0.25rem;
  > div:first-of-type {
    display: inline-block;
    width: 60%;
  }
`;

export const SortableList = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin: 0 0 8px 0;
  max-height: 32em;
  overflow: auto;
  li {
    display: flex;
    align-items: center;
    padding: ${spacing.space_xs} ${spacing.space_s};
    > div:nth-of-type(2) {
      position: relative;
      top: -${spacing.space_xs};
    }

    :nth-of-type(odd) {
      background: ${colors.grey15};
    }
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
  height: 32px;
  > div:first-of-type {
    display: inline-block;
    width: 60%;
    color: ${colors.primary};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > svg {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px
  margin-top: 20px;
  > button {
    padding: 4px 20px;
  }
`;

export const Heading = styled.h3`
  color: ${colors.primary};
  margin: 0px;
`;

export const SubHeading = styled.div`
  color: ${colors.grey5};
  font-size: 10px;
`;
