// @flow

import React from "react";

import { ImageLabelRemove as StyledImageLabel } from "../styles";
import ImageLabelRemove from "src/containers/user/ImageLabel/Small";
import { iconSize } from "src/styles/constants/size";
import Icon from "src/components/Icon";

import type { UID } from "src/types";

type Props = {
  uid: UID,
  removeHandler: Function
};

const Remove = ({ uid, removeHandler }: Props) => (
  <StyledImageLabel>
    <ImageLabelRemove uid={uid} />
    <button type="button" onClick={e => removeHandler(e, uid)}>
      <Icon icon="close" size={iconSize.space_m} />
    </button>
  </StyledImageLabel>
);

export default Remove;
