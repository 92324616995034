// @flow

import React from "react";

import { Flex, Text } from "@chakra-ui/react";
import Tooltip from "src/components/Tooltip";
import RelatedIcon from "src/icons/Related";

type Props = {
  related: number
};

const Pill = ({ related }: Props) => (
  <Tooltip title="Related conversations">
    <Flex cursor="pointer">
      <RelatedIcon h={4} w={5} />
      <Text my={0} ml={1} p={0}>
        {related}
      </Text>
    </Flex>
  </Tooltip>
);

export default Pill;
