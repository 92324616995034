// @flow

import { connect } from "react-redux";
import React, { useState, useEffect, useCallback, useRef } from "react";
import onClickOutside from "react-onclickoutside";
import FocusTrap from "focus-trap-react";
import { useDebouncedCallback } from "use-debounce";

import {
  Options as StyledOptions,
  CurrentOwner
} from "src/components/chatroom/Owner/styles";
import {
  bulkUpdateProcess,
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import { NoValue, CardSmall } from "src/styles/box";
import { SearchResult } from "src/styles/dropdown";
import Item from "src/components/user/Item";
import Invitation from "src/components/user/Invitation";
import ImageLabel from "src/components/user/ImageLabel/Remove";
import { iconSize } from "src/styles/constants/size";
import { TextInputWithBorders as SearchInput } from "src/styles/input";
import IconLabel from "src/components/IconLabel";
import { getUserSearchResult } from "src/reducers";
import { searchUsers } from "src/actions";

import type { AppState, UID, RoomId, ColumnId } from "src/types";

type Props = {
  result: Array<UID>,
  outerRef: any,
  value: ?UID,
  roomId: RoomId,
  index: number,
  depth: number,
  parentId: ?number,
  selected: boolean,
  handleClose: Function,
  columnId: ColumnId,
  _setAttribute: Function,
  _searchUsers: Function,
  _updateNestedRow: Function,
  _bulkUpdateProcess: Function
};

const Options = ({
  outerRef,
  handleClose,
  _searchUsers,
  roomId,
  index,
  parentId,
  _setAttribute,
  value,
  result,
  depth,
  _updateNestedRow,
  selected,
  _bulkUpdateProcess,
  columnId
}: Props) => {
  Options.handleClickOutside = useCallback((e: any) => {
    if (outerRef.current) {
      if (!outerRef.current.contains(e.target)) {
        handleClose();
      }
    }
  }, []);

  useEffect(() => {
    _searchUsers("", { removeDisabledUsers: false });
  }, []);

  const [search, setSearch] = useState("");
  const ownerRef = useRef(null);
  const [debouncedSearchUsers] = useDebouncedCallback(_searchUsers, 400);

  const handleSearch = useCallback(
    (e: any) => {
      const { value: valueLocal } = e.target;
      setSearch(valueLocal);
      debouncedSearchUsers(valueLocal, { removeDisabledUsers: false });
    },
    [debouncedSearchUsers]
  );

  const handleSelect = (owner: UID) => {
    if (value !== owner) {
      if (selected) {
        _bulkUpdateProcess({
          attrs: {
            owner
          }
        });
      } else {
        if (depth > 0) {
          _updateNestedRow(roomId, { owner }, index, parentId);
        } else {
          _setAttribute(roomId, { owner }, index, columnId);
        }
      }
    }
    handleClose();
  };

  const handleRemove = useCallback(() => {
    _setAttribute(roomId, { owner: null }, index, columnId);
  }, [index, _setAttribute]);

  const handleKeyDown = (event: any) => {
    // Close Search when esc is pressed
    if (event.keyCode === 27) {
      event.stopPropagation();
      handleClose();
    }
  };

  return (
    <FocusTrap>
      <StyledOptions
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
        ref={ownerRef}
      >
        <CardSmall>
          <IconLabel
            icon="owner"
            label="Owner"
            size={iconSize.space_m}
            cursor="pointer"
          />
          {value ? (
            <CurrentOwner>
              <ImageLabel uid={value} removeHandler={handleRemove} />
            </CurrentOwner>
          ) : (
            <NoValue>Not Assigned</NoValue>
          )}
        </CardSmall>
        <SearchInput
          type="text"
          id="search"
          placeholder="Search Users"
          autoComplete="off"
          value={search}
          onChange={handleSearch}
          autoFocus
          onClick={e => {
            e.stopPropagation();
          }}
        />
        {result.length > 0 ? (
          <SearchResult>
            <ul>
              {result.map(item => (
                <Item key={item} handleSelect={handleSelect} item={item} />
              ))}
            </ul>
          </SearchResult>
        ) : null}
        <Invitation handleInvite={() => {}} roomId={roomId} />
      </StyledOptions>
    </FocusTrap>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Options.handleClickOutside
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: getUserSearchResult(app)
});

const mapDispatchToProps = {
  _setAttribute: updateChatroomFromManageView,
  _searchUsers: searchUsers,
  _updateNestedRow: updateNestedRow,
  _bulkUpdateProcess: bulkUpdateProcess
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(Options, clickOutsideConfig));
