import styled from "@emotion/styled";

import * as colors from "./colors";

export const bold = "700";
export const semiBold = "600";
export const medium = "500";
export const normal = "400";
export const light = "300";

export const T1 = styled.span`
  font-size: 1.5rem;
  line-height: 1.25rem;
`;

export const T2 = styled.span`
  font-size: 1.25rem;
  line-height: 1.876rem;
`;

export const T3 = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const T4 = styled.span`
  font-size: 0.88rem;
  line-height: 1.375rem;
`;

export const T5 = styled.span`
  font-size: 0.625rem;
  line-height: 1;
`;

export const A = styled.a`
  text-decoration: none;
  color: ${colors.active};
`;
