// @flow

import * as atypes from "src/constants/actionTypes";
import { put, takeEvery, select } from "redux-saga/effects";

import { getUser } from "src/selectors";
import type { Action } from "src/types";

function* showContacts({ payload, meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "contacts",
          query
        }
      });
    } else {
      yield put({
        type: atypes.SET_CONTACTS_SUCCESS,
        payload
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_CONTACTS_FAILURE,
      payload: { error }
    });
  }
}

function* watchShowContacts(): any {
  yield takeEvery(atypes.SET_CONTACTS_REQUEST, showContacts);
}

export default [watchShowContacts()];
