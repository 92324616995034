// @flow

import React from "react";

import { SmallImageLabel as StyledSmallSmallImageLabel } from "../styles";
import ImageLabel from "./index";

import type { UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  user: UnifizeUser,
  noTooltip?: boolean,
  hideName?: boolean,
  location?: string,
  isActive?: boolean
};

const Small = ({
  uid,
  user,
  noTooltip = false,
  hideName,
  location,
  isActive
}: Props) => (
  <StyledSmallSmallImageLabel hideName={hideName}>
    <ImageLabel
      uid={uid}
      user={user}
      noTooltip={noTooltip}
      hideName={hideName}
      location={location}
      isActive={isActive}
    />
  </StyledSmallSmallImageLabel>
);

export default Small;
