// @flow

import { connect } from "react-redux";

import Members from "src/components/Group/Users";
import { getGroup } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  users: getGroup(app, props.id).members
});

export default connect(mapStateToProps)(Members);
