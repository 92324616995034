// @flow

import { connect } from "react-redux";
import React, { useMemo, useCallback } from "react";

import { setUserAnalytics } from "src/actions/userAnalytics";
import { Input, Flex } from "@chakra-ui/react";
import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter,
  _setUserAnalytics: Function
};

const Header = ({ filter, _setUserAnalytics }: Props) => {
  const handleSearch = useCallback(
    (newValue: string) => {
      _setUserAnalytics({
        query: {
          ...filter,
          user: newValue
        }
      });
    },
    [_setUserAnalytics]
  );

  const text: string = useMemo(() => (filter || {}).user || "", [filter]);

  return (
    <Flex
      justifyContent="space-between"
      my="6"
      px="3"
      value={text}
      onChange={handleSearch}
      alignItems="center"
    >
      <Input
        type="text"
        size="sm"
        placeholder="Search"
        width="30%"
        minWidth="100px"
      />
    </Flex>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps, {
  _setUserAnalytics: setUserAnalytics
})(Header);
