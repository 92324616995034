// @flow

import React, { useRef } from "react";

import Dropdown from "./Dropdown";
import useBoolean from "src/hooks/useBoolean";
import { autoReminderType } from "src/constants/display";
import { Select as StyledSelect, Selection } from "./styles";

type Props = {
  value: number,
  handleChange: Function
};

const ReminderTypeSelect = ({ value, handleChange }: Props) => {
  const outerRef = useRef(null);
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean();

  return (
    <StyledSelect ref={outerRef} onClick={toggleDropdown}>
      {dropdown && (
        <Dropdown
          handleClose={toggleDropdown}
          outerRef={outerRef}
          handleChange={handleChange}
        />
      )}

      {value ? (
        <Selection title={autoReminderType[value]}>
          {autoReminderType[value]}
        </Selection>
      ) : (
        <Selection>Select value</Selection>
      )}
    </StyledSelect>
  );
};

export default ReminderTypeSelect;
