// @flow

export const recordTypeData = {
  approval: {
    bg: "unifize_grey26",
    label: "Approved"
  },
  rejection: {
    bg: "unifize_mildRed",
    label: "Rejected"
  },
  cancellation: {
    bg: "unifize_sky",
    label: "Cancelled"
  },
  partialApproval: {
    bg: "unifize_grey25",
    label: "Partial Approval"
  }
};

export type RecordType = $Keys<typeof recordTypeData>;
