// @flow

import React, { useCallback, useState } from "react";
import type { Set as ImmutableSet } from "immutable";

import Info from "../Info";
import { Table as StyledTable } from "../styles";
import { TableContainer } from "./styles";
import Header from "./Header";
import Body from "./Body";

import type { UID } from "src/types";

type Props = {
  searchResults: ImmutableSet<UID>
};

const Table = ({ searchResults }: Props) => {
  const [editUser, setEdit] = useState(null);
  const [showSort, setSort] = useState(null);

  const handleEdit = useCallback(
    (uid: UID) => {
      setEdit(uid);
    },
    [setEdit]
  );

  const toggleSortMenu = useCallback(
    (e: any, column: string) => {
      if (showSort !== column) {
        setSort(column);
      } else {
        setSort(null);
      }
    },
    [showSort, setSort]
  );

  const closeSortMenu = useCallback(() => {
    setSort(null);
  }, [setSort]);

  const handleClose = useCallback(() => {
    setEdit(null);
  }, [setEdit]);

  return (
    <TableContainer>
      <StyledTable cellspacing="0" cellpadding="0">
        <Header
          toggleSortMenu={toggleSortMenu}
          showSort={showSort}
          handleClose={closeSortMenu}
        />
        <Body searchResults={searchResults} handleEdit={handleEdit} />
      </StyledTable>
      {editUser ? <Info uid={editUser} handleClose={handleClose} /> : null}
    </TableContainer>
  );
};

export default Table;
