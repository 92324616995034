// @flow

import React from "react";

type Props = {
  fill?: string
};

const Preview = ({ fill = "#919EAB", ...restProps }: Props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M1.625 4.875H0V1.625C0 1.19402 0.171205 0.780698 0.475951 0.475951C0.780698 0.171205 1.19402 0 1.625 0L4.875 0V1.625H1.625V4.875Z"
      fill={fill}
    />
    <path
      d="M13 4.875H11.375V1.625H8.125V0H11.375C11.806 0 12.2193 0.171205 12.524 0.475951C12.8288 0.780698 13 1.19402 13 1.625V4.875Z"
      fill={fill}
    />
    <path
      d="M11.375 13H8.125V11.375H11.375V8.125H13V11.375C13 11.806 12.8288 12.2193 12.524 12.524C12.2193 12.8288 11.806 13 11.375 13Z"
      fill={fill}
    />
    <path
      d="M4.875 13H1.625C1.19402 13 0.780698 12.8288 0.475951 12.524C0.171205 12.2193 0 11.806 0 11.375V8.125H1.625V11.375H4.875V13Z"
      fill={fill}
    />
  </svg>
);

export default Preview;
