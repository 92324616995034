// @flow

import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Display from "src/containers/file/Display";
import { downloadFile } from "src/actions/file";
import { getCurrentRoomId } from "src/reducers";

import { File } from "./styles";
import { getFile } from "src/reducers";

import type { AppState, UnifizeFile } from "src/types";

type Props = {
  name: string,
  file: UnifizeFile,
  thumbnail?: boolean
};

const Open = ({ name, file, thumbnail = true }: Props) => {
  const dispatch = useDispatch();
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));

  if (file) {
    return (
      <File
        onClick={() =>
          dispatch(
            downloadFile({
              roomId,
              location: "chat-history",
              name: file.name,
              originalName: file.originalName,
              generation: file.generation,
              versionId: file.versionId
            })
          )
        }
        download={file.originalName}
      >
        <Display name={name} thumbnail={thumbnail} />
      </File>
    );
  }
  return null;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  file: getFile(app, props.name)
});

export default connect(mapStateToProps)(Open);
