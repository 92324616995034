// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import {
  ScrollableBox,
  PictureBox,
  LeftAlignedListItem,
  ListItemWithMenu
} from "src/styles/box";
import * as size from "src/styles/constants/size";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import * as radius from "src/styles/constants/radius";
import { SystemCSSProperties } from "@chakra-ui/react";

const OptionContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  top: 2px;
  > div:first-of-type {
    margin: ${spacing.space_stack_xs};
  }
`;

const MembersList = styled(PictureBox)`
  position: relative;
  display: flex;
`;

export const MemberContainer = styled.div`
  position: ${props => (props.isSrwMobile ? "initial" : "relative")};
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${spacing.space_s};
  gap: 4px;
  width: 45px;
  > svg {
    width: 17px;
    height: 14px;
  }
  :hover {
    background-color: ${colors.grey32};
    border-radius: ${radius.medium};
  }
  :focus {
    background-color: ${colors.grey32};
    border-radius: ${radius.medium};
  }
`;

export const MobileDropdown = styled.div`
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  top: 90px;
  > div {
    position: initial;
    z-index: 2;
  }
`;

const MemberSelection = styled(ScrollableBox)`
  padding: ${spacing.space_inset_l};
  min-width: 270px;
  > div:first-of-type {
    font-weight: bold;
  }
`;

const SubMenu = styled(ScrollableBox)`
  min-width: 218px;
  ul {
    overflow: hidden;
  }
  span {
    max-width: 174px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SubMenuItem = styled(LeftAlignedListItem)`
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Item = styled(ListItemWithMenu)`
  > span > span {
    max-width: 13em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Options = styled(ScrollableBox)`
  width: ${size.menuWidth};
  > ul {
    max-height: 17em;
  }

  > span:first-of-type {
    display: block;
    color: ${colors.primary};
    padding: ${spacing.space_inset_squish_s};
  }
`;

export {
  OptionContainer,
  MembersList,
  MemberSelection,
  Members,
  SubMenu,
  SubMenuItem,
  Item,
  Options
};

export const Dropdown = styled.div`
  position: absolute;
  left: ${props => (props.isInfoPanelOpen ? "-100px" : 0)};
  top: 39px;
  width: 325px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const Box = styled.div`
  width: 100%;
  background: ${colors.brightLight};
  border: 1px solid rgba(205, 210, 217, 0.5);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

export const Participants = styled(Box)`
  margin-bottom: ${spacing.space_xs};
`;

export const ParticipantHeader = styled.div`
  font-weight: 500;
  font-size: 0.6875rem;
  letter-spacing: 1.5px;
  line-height: 17px;
  margin: ${spacing.space_l};
`;

export const AddParticipant = styled(Box)`
  height: ${spacing.space_xl};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.88rem;
  line-height: ${spacing.space_l};
  color: ${colors.active};
  cursor: pointer;
  font-weight: 500;
`;

export const ParticipantList = styled.div`
  max-height: ${props => (props.isSrwMobile ? "30vh" : "250px")};
  overflow-y: auto;
  margin-bottom: ${spacing.space_l};
`;

export const UserItem = styled.div`
  display: flex;
  margin: 0.5rem 1rem;
  ${props =>
    props.addUserMenu
      ? css`
          cursor: pointer;
          :focus {
            background: ${colors.grey15};
          }
          :hover {
            background: ${colors.grey15};
          }
        `
      : css``}
`;

export const UserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${spacing.space_m};
  > div {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const DisplayName = styled.div`
  font-size: 0.75rem;
  line-height: 18px;
`;

export const Email = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${colors.grey5};
`;

export const ImageContainer = styled.div`
  > div > div {
    width: 36px;
    height: 36px;
  }
`;

export const UserRemove = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
`;

export const AddParticipantHeader = styled(ParticipantHeader)`
  font-size: 0.88rem;
  font-weight: ${typography.bold};
  position: relative;
  margin: 0;
  letter-spacing: 0;
  line-height: 20px;
`;

export const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  > svg {
    width: 14px;
    height: 14px;
  }
`;

export const EmailOrNameTitle = styled.div`
  font-weight: ${typography.semiBold};
  font-size: 10px;
  line-height: ${spacing.space_l};
  letter-spacing: 1.5px;
  color: ${colors.grey8};
  margin-bottom: ${spacing.space_m};
`;

export const SearchContainer = styled.div`
  border: 1px solid ${colors.secondary};
  border-radius: ${radius.small};
  padding: ${spacing.space_m};
  max-height: 8.5rem;
  overflow-y: auto;
`;

export const AddParticipantDropdown = styled(Box)`
  max-height: 38rem;
  position: absolute;
  top: 39px;
  left: 0px;
  width: 380px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  z-index: 2;
  ${props => props.isInfoPanelOpen && "left: -100px"};

  @media (max-height: 600px) {
    max-height: 25rem;
    ${SearchContainer} {
      max-height: 6rem;
    }
  }
`;

export const Selected = styled.div`
  margin-bottom: ${spacing.space_m};
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Search = styled.input`
  outline: none;
  font-size: 0.88rem;
  line-height: 16px;
  border: 0;
  width: 100%;
  ::-webkit-input-placeholder {
    color: ${colors.secondary};
    font-style: italic;
  }
`;

export const AddButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${colors.active};
  color: ${colors.brightLight};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
`;

export const SelectedUser = styled.span`
  display: inline-flex;
  align-items: center;
  background: ${colors.grey12};
  padding: ${spacing.space_xs} ${spacing.space_s};
  border-radius: ${radius.small};
  font-size: 0.88rem;
  line-height: ${spacing.space_l};
  color: ${colors.unifizeBlue};
  margin: 0 ${spacing.space_s} ${spacing.space_s} 0;
  > span {
    width: max-content;
    margin-right: ${spacing.space_m};
  }
`;

export const SearchDropdown: SystemCSSProperties = {
  my: 0,
  border: "1px solid rgba(205, 210, 217, 0.5)",
  borderRadius: radius.small,
  padding: `${spacing.space_l} 0`,
  maxHeight: "15rem",
  overflowY: "auto",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "@media (max-height: 600px)": {
    maxHeight: "7rem"
  }
};

export const UsersAndGroupsWrapper: SystemCSSProperties = {
  display: "flex",
  alignItems: "center",
  px: "1rem",
  py: "0.5rem",
  cursor: "pointer",
  _hover: { bg: colors.grey33 }
};

export const ActiveParticipant: SystemCSSProperties = {
  ...UsersAndGroupsWrapper,
  bg: colors.lightBlue,
  _hover: { bg: colors.lightBlue }
};

export const UserAvatar: SystemCSSProperties = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: "4px",
  "& img": {
    borderRadius: "3px"
  }
};

export const UserAndGroupName: SystemCSSProperties = {
  fontSize: "xs",
  m: 0,
  ml: "0.5rem",
  maxWidth: "8rem"
};

export const GroupMemberArrowIcon: SystemCSSProperties = {
  height: "1.5rem",
  width: "1.5rem",
  cursor: "pointer",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: colors.skyDark,
    borderRadius: "50%"
  }
};

export const ActiveGroupMemberArrowIcon: SystemCSSProperties = {
  ...GroupMemberArrowIcon,
  backgroundColor: colors.skyDark,
  borderRadius: "50%"
};

export const SelectedUsersAndGroupsWrapper: SystemCSSProperties = {
  bg: colors.grey1,
  p: "5px",
  align: "center",
  borderRadius: "5px",
  alignItems: "center"
};

export const SelectedUserAndGroupName: SystemCSSProperties = {
  fontSize: "xs",
  m: 0,
  mx: "0.5rem",
  maxWidth: "8rem"
};

export const SelecedtedEmail: SystemCSSProperties = {
  ...SelectedUserAndGroupName,
  maxWidth: "auto"
};

export const RoomGroupWrapper: SystemCSSProperties = {
  alignItems: "center",
  mx: "1rem",
  my: "0.5rem"
};

export const RoomGroupTextWrapper: SystemCSSProperties = {
  flex: 1,
  flexDirection: "column"
};

export const RoomGroupTitle: SystemCSSProperties = {
  my: "0",
  mx: "0.5rem"
};

export const LightGreyText: SystemCSSProperties = {
  ...RoomGroupTitle,
  color: colors.grey5,
  fontSize: "10px",
  lineHeight: "12px"
};

export const CursorPointer: SystemCSSProperties = {
  cursor: "pointer"
};

export const GroupUsersWrapper: SystemCSSProperties = {
  mx: "2rem",
  my: "0.5rem",
  alignItems: "center",
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const FlexColumn: SystemCSSProperties = {
  flexDirection: "column"
};

export const StyledRemoveAvatar: SystemCSSProperties = {
  ...UserAvatar,
  borderRadius: "8px"
};

export const AddParticipantHeaderWrapper: SystemCSSProperties = {
  alignItems: "center",
  mb: "1rem"
};

export const CloseButton: SystemCSSProperties = {
  marginLeft: "auto",
  backgroundColor: "transparent"
};

export const EmailExampleText: SystemCSSProperties = {
  color: "rgba(0,0,0, 64%)",
  my: "1rem",
  fontSize: "0.75rem",
  whiteSpace: "normal"
};

export const ErrorText: SystemCSSProperties = {
  color: colors.danger,
  mb: 0
};

export const AddEmail: SystemCSSProperties = {
  ...CursorPointer,
  m: 0,
  color: colors.active,
  border: `1px solid ${colors.active}`,
  borderTop: "none",
  padding: "0.5rem 1rem",
  borderRadius: radius.small,
  textAlign: "center",
  fontWeight: typography.semiBold,
  fontSize: "0.88rem",
  background: colors.brightLight
};

export const NewGroup: SystemCSSProperties = {
  alignItems: "center",
  flex: 1
};
