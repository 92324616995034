// @flow

import { connect } from "react-redux";

import SelectMultiple from "src/components/user/SelectMultiple.new";
import type { Props as SelectMultipleUsersProps } from "src/components/user/SelectMultiple.new";
import { getUserSearchResult } from "src/reducers";
import { searchUsers as handleSearch } from "src/actions";
import { resetSearch } from "src/actions/user";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  options: getUserSearchResult(app)
});

export default connect<SelectMultipleUsersProps>(mapStateToProps, {
  searchUser: handleSearch,
  resetSearch
})(SelectMultiple);
