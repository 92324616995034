// @flow

import React from "react";

const Objective = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2A2A2A"
      d="M7.15 5.252a.5.5 0 1 1-.3.954A4.5 4.5 0 1 0 9.793 9.15a.5.5 0 1 1 .954-.3 5.5 5.5 0 1 1-3.599-3.598z"
    />
    <path
      fill="#2A2A2A"
      fillRule="evenodd"
      d="M15.853 4.854a.5.5 0 0 0-.195-.828l-2.763-.92-.921-2.764a.5.5 0 0 0-.828-.195l-2 2a.5.5 0 0 0-.12.511l1 3a.5.5 0 0 0 .315.317l3 1a.5.5 0 0 0 .512-.12l2-2.001zm-4.958.251l-.823-2.47 1.21-1.21.743 2.233a.5.5 0 0 0 .316.317l2.233.744-1.21 1.21-2.47-.824z"
      clipRule="evenodd"
    />
    <path
      fill="#2A2A2A"
      d="M5.853 10.854a.5.5 0 1 1-.707-.707l9-9a.5.5 0 0 1 .707.707l-9 9z"
    />
  </svg>
);

export default Objective;
