// @flow

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";

import { Chart as StyledChart, ExpandIcon } from "./styles";
import Icon from "src/icons";
import Visualization from "src/components/Manage/Reports/Instances/Chart/New/Chart/Visualization";
import {
  getPrincipalChecklistFieldNames,
  getReport,
  getChartData,
  getChartDataLoading
} from "src/reducers";
import { showView } from "src/actions";
import { getChartJSON, setNewChartDialogAttributes } from "src/actions/chart";
import { openHomeScreenTile } from "src/actions/homescreen";
import { setReports } from "src/actions/reports";
import Loader from "src/components/Dashboard/Body/Loader";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

import type { AppState, Report } from "src/types";

type Props = {
  title: string,
  typeId: number,
  description: string,
  chartId: number,
  dashboard: number,
  yAxisLabel: string,
  x: number,
  y: ?number,
  metaData: string,
  reportId: number,
  report: Report,
  isChartLoading: boolean,
  _deleteChartFromDashboard: Function,
  _setReports: typeof setReports,
  _showView: Function,
  _getChartJSON: Function,
  _setNewChartDialogAttributes: Function,
  _openHomeScreenTile: Function
};

const Chart = ({
  typeId,
  reportId,
  title,
  chartId,
  description,
  x,
  y,
  yAxisLabel,
  report,
  metaData,
  isChartLoading,
  _setNewChartDialogAttributes,
  _setReports,
  _showView,
  _getChartJSON,
  _openHomeScreenTile
}: Props) => {
  const [isChartVisible, setIsChartVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isChartVisible && R.isEmpty(metaData)) {
      _getChartJSON(chartId);
    }
  }, [chartId, _getChartJSON, isChartVisible]);

  const handleReport = useCallback(() => {
    _showView("manage");
    _setNewChartDialogAttributes({
      title,
      type: typeId,
      description,
      loading: false,
      error: null,
      show: false,
      stage: 2,
      id: chartId,
      field1: x,
      field2: y,
      yAxisLabel
    });
    _setReports(reportId, {
      query: {
        chartId
      }
    });

    _openHomeScreenTile({ type: "clicked-tile", tileType: "Dashboard chart" });
  }, [
    title,
    typeId,
    description,
    chartId,
    reportId,
    setReports,
    x,
    y,
    yAxisLabel,
    report,
    _setNewChartDialogAttributes,
    _showView,
    _openHomeScreenTile
  ]);

  const expandChartEnabled = useComponentPermission(
    componentPermissions.expandChart
  );

  return (
    <InView
      onChange={inView => {
        setIsChartVisible(inView);
      }}
    >
      <StyledChart>
        {expandChartEnabled && (
          <ExpandIcon onClick={handleReport}>
            <Icon type="fullscreen" />
          </ExpandIcon>
        )}

        {isChartLoading && <Loader />}

        {!isChartLoading && metaData && !R.isEmpty(metaData) && (
          <Visualization
            height="400"
            width="98%"
            chartId={chartId}
            chartData={metaData}
          />
        )}
      </StyledChart>
    </InView>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  fieldsById: getPrincipalChecklistFieldNames(app),
  report: getReport(app, props.reportId),
  metaData: getChartData(app, props.chartId),
  isChartLoading: getChartDataLoading(app, props.chartId)
});

export default connect(mapStateToProps, {
  _setReports: setReports,
  _showView: showView,
  _getChartJSON: getChartJSON,
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _openHomeScreenTile: openHomeScreenTile
})(Chart);
