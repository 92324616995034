// @flow

import React from "react";
import { Flex, Stack, Skeleton } from "@chakra-ui/react";

const ReportTableLoader = () => {
  const totalColumns = [];
  for (let i = 0; i < 5; i++) {
    totalColumns.push(
      <Stack sx={{ w: "100%" }}>
        <Skeleton height="2rem" />
        <Skeleton height="2rem" />
        <Skeleton height="2rem" />
        <Skeleton height="2rem" />
        <Skeleton height="2rem" />
      </Stack>
    );
  }

  return (
    <Flex sx={{ mx: "0.5rem", gap: "0.75rem", flexDirection: "column" }}>
      <Skeleton height="16px" />
      <Flex sx={{ gap: "1rem" }}>{totalColumns}</Flex>
    </Flex>
  );
};

export default ReportTableLoader;
