// @flow

import React, { Component } from "react";

import Title from "../Title";
import Owner from "../Owner";
import Members from "../Members";
import Parent from "../Parent";
import Advanced from "src/containers/chatroom/creator/discussion/Advanced";
import { Body as FormBody } from "src/styles/form";

import type { UID, RoomId, GroupId } from "src/types";
type Email = {
  email: string
};

type Props = {
  title: string,
  currentUser: UID,
  owner: ?UID,
  members: Array<UID | Email>,
  groups: Array<GroupId>,
  parent: ?RoomId,
  roomId: RoomId,
  advanced: boolean,
  setConversationAttribute: Function,
  setConversationAttribute: Function,
  handleChange: Function,
  handleMembers: Function,
  handleOwner: Function,
  handleParent: Function,
  handleGroups: (group: GroupId) => void
};

type State = {
  showParent: boolean
};

class Discussion extends Component<Props, State> {
  state = {
    showParent: false
  };

  componentDidMount() {
    const { parent, currentUser, owner, setConversationAttribute } = this.props;
    setConversationAttribute({ id: "members", value: [currentUser] });

    if (!owner) {
      setConversationAttribute({ id: "owner", value: currentUser });
    }
    this.setState({
      showParent: !!parent
    });
  }

  render() {
    const {
      title,
      members,
      advanced,
      owner,
      handleChange,
      handleMembers,
      handleOwner,
      parent,
      handleParent,
      groups,
      handleGroups
    } = this.props;
    const { showParent } = this.state;

    return (
      <FormBody>
        <Title title={title} handleChange={handleChange} />
        <Owner owner={owner} handleOwner={handleOwner} />
        <Members
          members={members}
          groups={groups}
          handleMembers={handleMembers}
          handleGroups={handleGroups}
        />
        {showParent && !advanced ? (
          <Parent parent={parent} handleParent={handleParent} />
        ) : null}

        {advanced ? <Advanced {...this.props} /> : null}
      </FormBody>
    );
  }
}

export default Discussion;
