// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const Users = styled.div`
  display: flex;
  align-items: center;
  > div,
  > img {
    display: inline-block;
    margin-right: 0.1em;
    :last-child {
      margin-right: 0px;
    }
  }
`;

const ParticipantCount = styled.span`
  padding-left: ${spacing.space_s};
  font-size: 10px;
  color: ${colors.secondary};
  font-weight: bold;
`;

export { Users, ParticipantCount };

export const FlexColumn: SystemCSSProperties = {
  flexDirection: "column"
};

export const Name: SystemCSSProperties = {
  fontSize: "10px",
  m: 0,
  ml: "0.5rem",
  maxWidth: "8rem"
};

export const LightGreyText: SystemCSSProperties = {
  my: "0",
  mx: "0.5rem",
  color: colors.grey5,
  fontSize: "8px",
  lineHeight: "12px"
};

export const UserAvatar: SystemCSSProperties = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: "8px"
};

export const GroupMember: SystemCSSProperties = {
  display: "flex",
  alignItems: "center",
  my: "0.5rem"
};

export const ArrowIcon: SystemCSSProperties = {
  height: "1.5rem",
  width: "1.5rem",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: colors.skyDark,
    borderRadius: "50%"
  }
};

export const ActiveArrowIcon: SystemCSSProperties = {
  ...ArrowIcon,
  backgroundColor: colors.skyDark,
  borderRadius: "50%"
};
