// @flow

import { connect } from "react-redux";

import type { AppState } from "src/types";
import { getCurrentChecklistBuilder } from "src/reducers";

import FieldSettings from "./FieldSettings";

const mapStateToProps = ({ app }: { app: AppState }) => {
  const { checklistFieldSettings } = app.workflow.builderDialog;
  const currentFields = getCurrentChecklistBuilder(app).fields;

  return {
    type: checklistFieldSettings.type,
    currentSettings: currentFields[checklistFieldSettings.position].settings,
    position: checklistFieldSettings.position
  };
};

export default connect(mapStateToProps)(FieldSettings);
