// @flow

import React from "react";

const Expansion = () => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8334 8H14.1667V10.5H11.6667V12.1667H15.8334V8ZM5.83337 5.5H8.33337V3.83333H4.16671V8H5.83337V5.5ZM17.5 0.5H2.50004C1.58337 0.5 0.833374 1.25 0.833374 2.16667V13.8333C0.833374 14.75 1.58337 15.5 2.50004 15.5H17.5C18.4167 15.5 19.1667 14.75 19.1667 13.8333V2.16667C19.1667 1.25 18.4167 0.5 17.5 0.5ZM17.5 13.8417H2.50004V2.15833H17.5V13.8417Z"
      fill="#888888"
    />
  </svg>
);

export default Expansion;
