// @flow

import React from "react";
import { connect } from "react-redux";

import { Flex } from "@chakra-ui/react";
import { Title as StyledTitle, TitleWrapper, Count } from "./styles";
import Icons from "src/icons";
import type { CurrentFilter, AppState } from "src/types";

import {
  getCurrentFilter,
  getChatroomTitle,
  getToogleUnread,
  getFilteredChatroomCount,
  getCurrentActiveFilterName
} from "src/reducers";
import { clear as clearActiveFilter } from "src/actions/activeFilter";
import { hideFilterDialog } from "src/actions/filter";
import { toggleFilterModal } from "src/actions/header";
import { setHomescreen } from "src/actions/homescreen";
import { showView } from "src/actions";

type Props = {
  filter: CurrentFilter,
  _clearActiveFilter: Function,
  _hideFilterDialog: Function,
  _showView: Function,
  _toggleFilterModal: Function,
  _setHomescreen: Function,
  count: number,
  currentFilterName: string
};

const Title = ({
  _clearActiveFilter,
  _hideFilterDialog,
  _toggleFilterModal,
  _showView,
  _setHomescreen,
  filter,
  count,
  currentFilterName
}: Props) => {
  const { name } = filter;
  const isMyConversations = name === "My Conversations";

  const resetFilter = (event: any) => {
    event.stopPropagation();
    if (filter.homescreen) {
      _setHomescreen();
      _showView("homescreen");
    } else {
      _clearActiveFilter();
      _hideFilterDialog();
      _showView("My Conversations");
    }
  };

  const showConversation = () => {
    _toggleFilterModal();
  };

  return (
    <TitleWrapper onClick={showConversation}>
      <StyledTitle>
        <div>
          {!isMyConversations ? (
            <Flex alignItems="center" onClick={resetFilter}>
              <Icons type="backArrow" />
            </Flex>
          ) : null}
          <span>
            {currentFilterName.length > 30
              ? currentFilterName.slice(0, 25) + "..."
              : currentFilterName}
          </span>
          <Count>({count})</Count>
        </div>
        <Icons type="downIcon" />
      </StyledTitle>
    </TitleWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  filter: getCurrentFilter(app),
  title: getChatroomTitle(app, id),
  unread: getToogleUnread(app),
  count: getFilteredChatroomCount(app),
  currentFilterName: getCurrentActiveFilterName(app)
});

export default connect(mapStateToProps, {
  _clearActiveFilter: clearActiveFilter,
  _hideFilterDialog: hideFilterDialog,
  _toggleFilterModal: toggleFilterModal,
  _showView: showView,
  _setHomescreen: setHomescreen
})(Title);
