// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

const sizes = {
  sm: "16px",
  md: "24px",
  lg: "36px"
};

const fontSize = {
  sm: "10px",
  md: "14px",
  lg: "18px"
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => sizes[props.size]};
  height: ${props => sizes[props.size]};
  border-radius: ${radius.medium};
  background-color: ${colors.grey4};
  font-size: ${props => fontSize[props.size]};
  color: ${colors.unifizeBlue};
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${radius.medium};
  color: black;
`;

export { Container, Avatar };
