// @flow

import React, { useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import AutosizeInput from "react-input-autosize";

import { Edit as StyledEdit } from "../Title/styles";
import { Text } from "./styles";
import { Cell } from "../styles";
import { NoTitle as NoDescription } from "src/components/chatroom/Name/styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import Icon from "src/components/Icon";
import { iconSize } from "src/styles/constants/size";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import {
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import { getFieldWidth } from "src/reducers";

type Props = {
  selected: boolean,
  id: string,
  index: number,
  description: string,
  parentId: number,
  depth: number,
  _setAttribute: Function,
  _updateNestedRow: Function
};

const Description = ({
  selected,
  id,
  index,
  parentId,
  depth,
  description,
  _setAttribute,
  _updateNestedRow
}: Props) => {
  const [edit, showEdit] = useState(false);
  const [newDescription, setNewDescription] = useState(description || "");
  const fieldWidth = useSelector(({ app }) =>
    getFieldWidth(app, "description")
  );

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.keyCode === 27 || e.keyCode === 9) {
        setNewDescription(description || "");
        showEdit(false);
      }
    },
    [description, setNewDescription, showEdit]
  );

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      setNewDescription(value);
    },
    [setNewDescription]
  );

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      showEdit(false);
      if (depth > 0) {
        _updateNestedRow(id, { description: newDescription }, index, parentId);
      } else {
        _setAttribute(id, { description: newDescription }, index);
      }
    },
    [
      depth,
      parentId,
      _updateNestedRow,
      newDescription,
      showEdit,
      index,
      _setAttribute
    ]
  );

  return (
    <Cell selected={selected} width={fieldWidth}>
      <ValueWrapper>
        {edit ? (
          <OutsideClickHandler onClickOutside={handleSubmit}>
            <StyledEdit onSubmit={handleSubmit}>
              <AutosizeInput
                type="text"
                value={newDescription}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                autoFocus
              />
            </StyledEdit>
          </OutsideClickHandler>
        ) : (
          <>
            {description ? (
              <Text>{description}</Text>
            ) : (
              <NoDescription>No Description</NoDescription>
            )}

            <Icon
              icon="edit"
              size={iconSize.space_s}
              onClick={() => showEdit(true)}
            />
          </>
        )}
      </ValueWrapper>
    </Cell>
  );
};

export default connect(null, {
  _setAttribute: updateChatroomFromManageView,
  _updateNestedRow: updateNestedRow
})(Description);
