// @flow

import React from "react";

const Header = () => (
  <thead>
    <tr>
      <td>File name</td>
      <td>Size</td>
      <td>Last modified</td>
      <td>Uploaded by</td>
    </tr>
  </thead>
);

export default Header;
