// @flow

import React from "react";

import AddUserSegment from "./AddUserSegment";

type Props = {
  tileId: number,
  right: boolean
};

const UserSegments = ({ tileId, right }: Props) => (
  <AddUserSegment right={right} tileId={tileId} />
);

export default UserSegments;
