// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";

import { Options as StyeldOptions, Item } from "./styles";
import { createDirectConversation } from "src/actions/chatroom";

import type { UID } from "src/types";

type Props = {
  uid: UID,
  handleEdit: Function,
  _createDirectConversation: Function,
  handleClose: Function
};

const Options = ({
  uid,
  handleEdit,
  _createDirectConversation,
  handleClose
}: Props) => {
  Options.handleClickOutside = useCallback(() => {
    handleClose();
  }, []);

  return (
    <StyeldOptions>
      <ul>
        <Item onClick={handleEdit}>View Profile</Item>
        <Item onClick={() => _createDirectConversation(uid)}>
          Direct Message
        </Item>
      </ul>
    </StyeldOptions>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Options.handleClickOutside
};

export default connect(null, {
  _createDirectConversation: createDirectConversation
})(onClickOutside(Options, clickOutsideConfig));
