// @flow

import React from "react";

type Props = {
  fill?: string
};

const RelatedIconV2 = ({ fill, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <g clipPath="url(#clip0_2861_7562)">
      <rect
        x="6.6001"
        y="3.7998"
        width="8.4"
        height="3.73333"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="6.6001"
        y="11.2666"
        width="8.4"
        height="3.73333"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1V13.1333H3.8"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.66602H3.8"
        stroke={fill || "#1581BA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2861_7562">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RelatedIconV2;
