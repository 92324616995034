// @flow

import React from "react";

import Common from "../Common";
import LinkedRoom from "../LinkedRoom";
import StatusValue from "./StatusValue";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Status = ({ message }: Props) => {
  const { data, roomId } = message;
  const { id, comment, new: newStatus } = data;
  const isCurrentRoom = id === parseInt(roomId, 10);
  if (isCurrentRoom)
    return (
      <Common {...message} comment={comment}>
        {" "}
        updated status of this conversation to
        <StatusValue value={newStatus} roomId={id} />
      </Common>
    );
  return (
    <Common {...message} comment={comment}>
      {" "}
      updated status of the conversation <LinkedRoom
        id={`${data.roomId}`}
      /> to <StatusValue value={newStatus} roomId={data.roomId} />
    </Common>
  );
};

export default Status;
