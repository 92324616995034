import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";

export const StyledFiles = styled.div`
  display: grid;
  grid-template-columns: 22% 70%;
  margin: 0 auto;
  width: 90%;
  align-items: flex-start;
  margin-top: 16px;
  > label {
    padding: 8px;
    padding-left: 0.3em;
  }
`;

export const StyledAdvanced = styled.div`
  margin-left: -${spacing.space_l};
  padding: ${spacing.space_l};
  padding-bottom: 1rem;
  overflow: auto;
  height: 100%;
`;

export const DueDaysWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 42%;
  align-items: center;
  margin-left: 1.3em;
  padding-bottom: 1em;
  > input {
    min-width: 50px;
  }
`;

export const StyledCopyInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 2em;
`;

export const StyledEmail = styled.div`
  display: grid;
  grid-template-columns: 22% 70%;
  margin-left: 2em;
  align-items: center;
  margin-top: ${spacing.space_l};
  margin-bottom: 2rem;
  > input {
    height: 32px;
  }
  > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledNextCount = styled.div`
  display: grid;
  grid-template-columns: 22% 70%;
  margin-left: 2em;
  align-items: center;
`;
