// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getCurrentChecklistBuilderFields } from "src/reducers";
import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { Checkbox, MultiSelect } from "src/components/Unifize";
import Radio from "src/components/Radio";
import { RadioLabel } from "../styles";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function,
  position: number
};

const ContingentPrevious = ({ settings, saveSettings, position }: Props) => {
  const checklistFields = useSelector(({ app }) =>
    getCurrentChecklistBuilderFields(app)
  );

  const approvalFields = checklistFields.filter(
    (field, fieldPosition) =>
      fieldPosition !== position && field.type === "approval"
  );

  return (
    <Accordion title="Contingent on previous approval">
      <AccordionContent>
        <MultiSelect
          label="Contingent on previous approval(s)"
          placeholder="Select approval fields from checklist"
          data={approvalFields}
          selected={settings?.contingentApprovals || []}
          keys={["id", "label"]}
          onChange={selected => saveSettings(["contingentApprovals"], selected)}
        />

        <Radio
          id="hide"
          value="hide"
          isDisabled={settings?.contingentApprovals.length === 0}
          checked={settings?.inactiveBehavior === "hide"}
          handleChange={() => saveSettings(["inactiveBehavior"], "hide")}
        >
          <RadioLabel>Hide when inactive</RadioLabel>
        </Radio>
        <Radio
          id="disable"
          value="disable"
          isDisabled={settings?.contingentApprovals.length === 0}
          checked={settings?.inactiveBehavior === "disable"}
          handleChange={() => saveSettings(["inactiveBehavior"], "disable")}
        >
          <RadioLabel> Disable when inactive</RadioLabel>
        </Radio>
        <Checkbox
          checked={settings?.canCancelContingentApprovals}
          isDisabled={settings?.contingentApprovals.length === 0}
          label="Disable previous approvals while field is approved or in progress"
          onChange={value =>
            saveSettings(["canCancelContingentApprovals"], value)
          }
        />
      </AccordionContent>
    </Accordion>
  );
};

export default ContingentPrevious;
