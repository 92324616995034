// @flow

import styled from "@emotion/styled";

const FieldWrapper = styled.div`
  height: auto;
  max-height: 330px;
  overflow: "visible";
`;

export default FieldWrapper;
