// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { ScrollableBox } from "src/styles/box";
import { SpacedFlex } from "src/styles/box";

export const CriteriaSelect = styled.div`
  position: relative;
  border: 1px solid ${colors.grey4};
  padding: 0.4em 0.8em;
  cursor: pointer;
  margin-left: 0.5em;
  ${SpacedFlex} {
    > svg {
      height: 0.8em;
      width: 0.8em;
    }
  }
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  left: 0;
  min-width: auto;
  width: 100%;
  top: 30px;
`;
