// @flow

import moment from "moment";
import React, { memo, useMemo, useContext } from "react";
import { Text, Flex } from "@chakra-ui/react";

import { Value as StyledValue } from "./styles";
import Icon from "src/icons";
import type { ReactRef } from "src/types";
import appLocation from "src/constants/location";
import { ChecklistFieldContext } from "src/components/Dock/Checklist/Field";
type Props = {
  value: ?string,
  active: ?boolean,
  fieldRef: ReactRef,
  location?: string,
  dropdown?: boolean,
  setDropdown?: Function
};

const Value = ({
  value,
  active,
  fieldRef,
  location,
  dropdown,
  setDropdown
}: Props) => {
  const { fieldWidth } = useContext(ChecklistFieldContext);

  const timeline = useMemo(() => {
    if (active === false) {
      return "present";
    }

    if (!value) {
      return "noValue";
    }

    const difference = moment().diff(value, "days");

    if (difference === 0 || difference === -1) {
      return "present";
    }

    if (difference < 0) {
      return "future";
    }

    return "past";
  }, [active, value]);

  const newValue = useMemo(() => {
    if (!value) {
      return "Due";
    }

    const today = moment().startOf("day");
    const momentValue = moment(value).startOf("day");
    const yearDifference = Math.abs(today.diff(momentValue, "year"));

    if (yearDifference > 0) {
      return `${yearDifference}yr`;
    }

    const daysDifference = today.diff(momentValue, "days");

    if (momentValue.isSame(today, "week") && daysDifference < -1) {
      return momentValue.format("ddd");
    }

    switch (daysDifference) {
      case 0:
        return "Today";
      case -1:
        return "Tomorrow";
      case 1:
        return "Yesterday";
      default:
        return momentValue.format("MMM DD");
    }
  }, [value]);

  return (
    <StyledValue
      tabIndex={0}
      timeline={timeline}
      ref={fieldRef}
      location={location}
      onKeyPress={() => setDropdown && setDropdown(!dropdown)}
      maxW={fieldWidth > 0 && fieldWidth < 600 && "60px"}
    >
      <Flex w={4} align="center">
        {location === appLocation.chatroomHeader && dropdown ? (
          <Icon type="dateActive2" timeline={timeline} />
        ) : (
          <Icon type="dateActive" timeline={timeline} />
        )}
      </Flex>
      <Text as="span">{newValue}</Text>
    </StyledValue>
  );
};

export default memo<Props>(Value);
