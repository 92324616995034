// @flow

import { connect } from "react-redux";
import React from "react";

import Loader from "./RowLoader";
import NoValue from "./NoValue";
import Row from "./Row";

import type { AppState, FormInstance } from "src/types";

type Props = {
  columnSize: string,
  width: string,
  numberOfColumns: number,
  instances: Array<FormInstance>,
  fields: Array<Object>
};

const Body = ({
  columnSize,
  numberOfColumns,
  instances,
  fields,
  width
}: Props) => (
  <>
    {instances.map((form, index) => (
      <Row
        width={width}
        key={form.id}
        form={form}
        index={index}
        last={index === instances.length - 28}
        columnSize={columnSize}
        numberOfColumns={numberOfColumns}
        fields={fields}
        roomId={form.chatroomId}
      />
    ))}

    <Loader columnSize={columnSize} numberOfColumns={numberOfColumns} />

    {instances.length === 0 && <NoValue />}
  </>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  instances: app.form.instances
});

export default connect(mapStateToProps)(Body);
