// @flow

import React from "react";
import { Increment as StyledIncrement } from "./styles";

type Props = {
  handleClick?: Function
};

const Increment = ({ handleClick = () => {} }: Props) => {
  return (
    <StyledIncrement
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
        fill="#bbb"
        style={{ transition: "0.1s ease-in-out fill" }}
      />
    </StyledIncrement>
  );
};

export default Increment;
