// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";

import Visualization from "src/components/Manage/Reports/Instances/Chart/New/Chart/Visualization";
import Loader from "./Loader";
import { getReportsChartJSON } from "src/actions/chart";
import { getChartData } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  chartId: string,
  chartData: Object,
  _getReportsChartJSON: Function
};

const ChartBody = ({ chartId, chartData, _getReportsChartJSON }: Props) => {
  useEffect(() => {
    if (R.isEmpty(chartData)) {
      _getReportsChartJSON(chartId, false);
    }
  }, [chartId]);

  if (R.isEmpty(chartData)) {
    return <Loader />;
  }

  return (
    <>
      <Visualization
        height="50%"
        width="90%"
        chartId={chartId}
        chartData={chartData}
      />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  chartData: getChartData(app, props.chartId)
});

export default connect(mapStateToProps, {
  _getReportsChartJSON: getReportsChartJSON
})(ChartBody);
