// @flow

import React from "react";

type Props = {
  fill?: string
};

const Comment = ({ fill, ...restProps }: Props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M13.6599 1.66665C13.6599 0.933313 13.0666 0.333313 12.3333 0.333313H1.66659C0.933252 0.333313 0.333252 0.933313 0.333252 1.66665V9.66665C0.333252 10.4 0.933252 11 1.66659 11H10.9999L13.6666 13.6666L13.6599 1.66665ZM10.3333 6.33331H7.66659V8.99998H6.33325V6.33331H3.66659V4.99998H6.33325V2.33331H7.66659V4.99998H10.3333V6.33331Z"
      fill={fill || "#2F80ED"}
    />
  </svg>
);

export default Comment;
