// @flow

import moment from "moment";
import * as atypes from "src/constants/actionTypes";

import type { CurrentFilter, SavedFilter } from "src/types";

const setText = (text: string) => ({
  type: atypes.SET_QUERY_TEXT,
  payload: {
    text
  }
});

const removeType = (type: string) => ({
  type: atypes.REMOVE_QUERY_TYPE,
  payload: {
    type
  }
});

const setActive = (active: boolean) => ({
  type: atypes.SET_QUERY_ACTIVE,
  payload: {
    active
  }
});

const setSingleType = (type: string, name: string) => ({
  type: atypes.SET_SINGLE_QUERY_TYPE,
  payload: {
    type,
    name
  }
});

const clearText = () => ({
  type: atypes.CLEAR_QUERY_TEXT
});

const setType = (type: string) => ({
  type: atypes.SET_QUERY_TYPE,
  payload: {
    type
  }
});

const setDueDate = (dueDate: number) => ({
  type: atypes.SET_QUERY_DUE_DATE,
  payload: {
    dueDate
  }
});

const setMine = (mine: string, name: string) => ({
  type: atypes.SET_MINE,
  payload: {
    mine,
    name
  }
});

const setUnread = (unread: boolean) => ({
  type: atypes.SET_UNREAD,
  payload: {
    unread
  }
});

const updateFilter = (filter: Object) => ({
  type: atypes.TRIGGER_FILTER_UPDATE,
  payload: filter
});

export const setStandardStatusFilter = (active: Object) => ({
  type: atypes.TRIGGER_FILTER_UPDATE,
  payload: {
    active
  }
});

const saveRelatedConversation = (parent: string) => ({
  type: atypes.SET_QUERY_RELATED_CONVERSATION,
  payload: {
    parent
  }
});

const setMessageFilter = (messages: Array<string>, fieldId: number) => ({
  type: atypes.SET_MESSAGE_FILTER,
  payload: {
    messages,
    fieldId
  }
});

const setFavouriteFilter = (favourite: boolean) => ({
  type: atypes.SET_FAVOURITE_FILTER,
  payload: {
    favourite
  }
});

const setApproved = (approved: boolean) => ({
  type: atypes.SET_APPROVED,
  payload: {
    approved
  }
});

const setUltimateParent = (ultimateParent: boolean) => ({
  type: atypes.SET_ULTIMATE_PARENT,
  payload: {
    ultimateParent
  }
});

const setOwner = (owner: string) => ({
  type: atypes.SET_OWNER,
  payload: {
    owner
  }
});

const setNewChats = (newChats: boolean) => ({
  type: atypes.SET_NEW_CHATS,
  payload: {
    newChats
  }
});

const setWorkflowFilter = (workflow: Object, name: string) => ({
  type: atypes.SET_WORKFLOW_FILTER,
  payload: {
    workflow,
    name
  }
});

const updateSuggestedWorkflows = (id: number) => ({
  type: atypes.UPDATE_SUGGESTED_WORKFLOWS_REQUEST,
  payload: { id }
});

const setFilter = (filter: CurrentFilter, type?: string, name: string) => ({
  type: atypes.SET_QUERY_FILTER,
  payload: {
    filter,
    type,
    name
  }
});

const clearType = () => ({
  type: atypes.CLEAR_FILTER_TYPE,
  payload: {}
});

const deletePinnedList = (filter: SavedFilter) => ({
  type: atypes.DELETE_PINNED_LIST_REQUEST,
  payload: filter
});

const showFilterDialog = () => ({
  type: atypes.SHOW_FILTER_DIALOG,
  payload: {}
});

const hideFilterDialog = () => ({
  type: atypes.HIDE_FILTER_DIALOG,
  payload: {}
});

const toggleFilterDialog = () => ({
  type: atypes.TOGGLE_FILTER_DIALOG,
  payload: {}
});

const showSaveFilterModal = () => ({
  type: atypes.SHOW_SAVE_PINNED_LIST,
  payload: {}
});

const hideSaveFilterModal = () => ({
  type: atypes.HIDE_SAVE_PINNED_LIST,
  payload: {}
});

const showSortByModal = () => ({
  type: atypes.SHOW_SORT_BY_MODAL,
  payload: {}
});

const hideSortByModal = () => ({
  type: atypes.HIDE_SORT_BY_MODAL,
  payload: {}
});

const showStatusModal = () => ({
  type: atypes.SHOW_STATUS_MODAL,
  payload: {}
});

const hideStatusModal = () => ({
  type: atypes.HIDE_STATUS_MODAL,
  payload: {}
});

const savePinnedList = (name: string) => ({
  type: atypes.SAVE_PINNED_LIST_REQUEST,
  payload: {
    name
  }
});

const showEditFilterModal = () => ({
  type: atypes.SHOW_EDIT_FILTER_MODAL
});

const hideEditFilterModal = () => ({
  type: atypes.HIDE_EDIT_FILTER_MODAL,
  payload: {}
});

const editPinnedList = (name: string) => ({
  type: atypes.EDIT_PINNED_LIST_REQUEST,
  payload: {
    name
  }
});

const setCurrentClickedFilterName = (name: string) => ({
  type: atypes.SHOW_TO_BE_EDITED_FILTER_NAME,
  payload: {
    name
  }
});

const updateStandardLastRead = (templateId: number) => ({
  type: atypes.SET_STANDARD_FILTER_LAST_READ_REQUEST,
  payload: { [templateId]: moment(Date.now()) }
});

const updateCustomLastRead = (pinnedList: string) => ({
  type: atypes.SET_CUSTOM_FILTER_LAST_READ_REQUEST,
  payload: { [pinnedList]: moment(Date.now()) }
});

export {
  setType,
  removeType,
  setText,
  clearText,
  clearType,
  setDueDate,
  setMine,
  saveRelatedConversation,
  setMessageFilter,
  setActive,
  setSingleType,
  setFavouriteFilter,
  setApproved,
  setUltimateParent,
  setOwner,
  setNewChats,
  setFilter,
  setWorkflowFilter,
  deletePinnedList,
  showFilterDialog,
  hideFilterDialog,
  toggleFilterDialog,
  showSaveFilterModal,
  hideSaveFilterModal,
  showSortByModal,
  hideSortByModal,
  showStatusModal,
  hideStatusModal,
  savePinnedList,
  setUnread,
  updateStandardLastRead,
  updateCustomLastRead,
  editPinnedList,
  showEditFilterModal,
  hideEditFilterModal,
  updateSuggestedWorkflows,
  setCurrentClickedFilterName,
  updateFilter
};
