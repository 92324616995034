// @flow

import React from "react";

const CaretRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="13"
    fill="none"
    viewBox="0 0 7 13"
    data-testid="caretRight"
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1l2.5 2.75L6 6.5 1 12"
    />
  </svg>
);

export default CaretRight;
