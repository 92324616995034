// @flow

import React from "react";
import { useSelector } from "react-redux";

import TimestampComponent from "src/components/IconLabel/Timestamp";

import { getTime } from "src/utils";

import { getUpdationTime } from "src/reducers";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const Timestamp = ({ roomId }: Props) => {
  const updatedAt = useSelector(({ app }) => getUpdationTime(app, roomId));
  const timestamp = getTime(updatedAt);
  return <TimestampComponent timestamp={timestamp || ""} />;
};

export default Timestamp;
