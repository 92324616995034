// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Parent as StyledParent } from "src/components/Dock/styles";
import { getParent } from "src/reducers";
import {
  bulkUpdateProcess,
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import Select from "src/containers/chatroom/select";
import type { AppState, RoomId } from "src/types";

type Props = {
  chatRoomId: RoomId,
  parent: any,
  _setAttribute: Function,
  index: number,
  closePortal: Function,
  depth: number,
  parentId: number,
  selected: boolean,
  id: number,
  _updateNestedRow: Function,
  _bulkUpdateProcess: Function,
  selectedRows: number[]
};

const Parent = ({
  chatRoomId,
  parent,
  _setAttribute,
  index,
  _bulkUpdateProcess,
  _updateNestedRow,
  closePortal,
  id,
  depth,
  parentId,
  selectedRows = [],
  selected
}: Props) => {
  const shouldBulkUpdate = selectedRows.length > 0;
  const handleChange = useCallback(
    (newParent: string) => {
      if (shouldBulkUpdate) {
        _bulkUpdateProcess({ attrs: { parent: parseInt(newParent, 10) } });
      } else {
        if (!selected) {
          if (depth > 0) {
            _updateNestedRow(
              chatRoomId,
              { parent: parseInt(newParent, 10) },
              index,
              parentId
            );
          } else {
            _setAttribute(
              chatRoomId,
              { parent: parseInt(newParent, 10) },
              index
            );
          }
        }
      }

      if (newParent) {
        closePortal();
      }
    },
    [
      chatRoomId,
      _setAttribute,
      id,
      depth,
      parentId,
      parent,
      _updateNestedRow,
      selected,
      _setAttribute
    ]
  );

  return (
    <StyledParent parent={parent === null}>
      <Select
        value={parent}
        handleChange={handleChange}
        placeholder="Select Parent"
      />
    </StyledParent>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { chatRoomId }) => {
  return {
    parent: getParent(app, chatRoomId),
    selectedRows: app.workflow.selectedRows
  };
};

export default connect(mapStateToProps, {
  _setAttribute: updateChatroomFromManageView,
  _bulkUpdateProcess: bulkUpdateProcess,
  _updateNestedRow: updateNestedRow
})(Parent);
