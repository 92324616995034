// @flow

import React from "react";
import { ListItem, Box, Flex } from "@chakra-ui/react";
import Flash from "src/icons/Flash";

type Props = {
  title: string,
  handleClick: Function
};

const QuickFilterItem = ({ title, handleClick }: Props) => {
  return (
    <ListItem
      py="2"
      px="3"
      _hover={{
        background: "gray.100"
      }}
      onClick={handleClick}
      cursor="pointer"
    >
      <Flex justifyContent="flex-start" alignItems="center">
        <Flash h={7} w={7} />
        <Box ml="2">
          <strong>{title}</strong>
        </Box>
      </Flex>
    </ListItem>
  );
};

export default QuickFilterItem;
