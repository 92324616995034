// @flow

import React from "react";
import { connect } from "react-redux";

import { getFile } from "src/reducers";

import type { AppState, UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile
};

const Display = ({ file }: Props) => <div>{file?.originalName}</div>;

const mapStateToProps = ({ app }: { app: AppState }, { name }) => ({
  file: getFile(app, name)
});

export default connect(mapStateToProps)(Display);
