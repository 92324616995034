// @flow

import React from "react";

import { Th } from "src/styles/table";

const Header = () => {
  return (
    <thead>
      <tr>
        <Th>Group name</Th>
        <Th>Participants</Th>
        <Th>Group owners</Th>
        <Th>Created by</Th>
      </tr>
    </thead>
  );
};

export default Header;
