// @flow

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef
} from "react";
import type { AbstractComponent } from "react";
import { useSelector } from "react-redux";
import UserMention from "./UserMention";
import { Box, Flex } from "@chakra-ui/react";
import {
  MentionListContainer,
  MentionListWrapper,
  MentionButton,
  NotFoundContainer
} from "./styles";
import { getUsersById } from "src/reducers";
import type { UsersById } from "src/types";
import type { Mentions } from "src/types";
import Group from "src/components/Group";
import { getGroupMentionId } from "src/utils";

type Props = any;
type Ref = any;

type ComponentType = AbstractComponent<Props, Ref>;

const MentionList = (props: Props, ref: Ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showList, setShowList] = useState(true);

  const filteredUsersAndGroups: Mentions[] = props.items;

  const selectedUserRef = useRef<HTMLButtonElement | null>(null);

  const allUsersAndGroups: UsersById = useSelector(({ app }) =>
    getUsersById(app)
  );

  const getUserDetails = userId => {
    return allUsersAndGroups[userId];
  };

  const selectItem = index => {
    const selectedUser = props.items[index];

    if (selectedUser) {
      props.command({ mentionedPerson: selectedUser });
    }
  };

  const upHandler = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const downHandler = () => {
    setSelectedIndex(prevIndex =>
      prevIndex < props.items.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  const focusToSelectedUser = () => {
    if (selectedUserRef.current) {
      selectedUserRef.current.scrollIntoView({ block: "end" });
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        focusToSelectedUser();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        focusToSelectedUser();
        return true;
      }

      if (event.code === "Space") {
        if (props.items.length == 1) {
          enterHandler();
          return false;
        }

        if (!props.items.length) {
          setShowList(false);
          return false;
        }
      }

      if (event.key === "Enter") {
        if (!props.items.length) {
          return false;
        }

        enterHandler();
        return true;
      }

      return false;
    }
  }));

  const removeAsperandSymbol = (displayNameWithAsperand: string) => {
    return displayNameWithAsperand.substring(1);
  };

  const _renderSuggestions = suggestion => {
    const userType = suggestion.type;
    const isAll = suggestion.id == "!everyone";
    const isOwner = suggestion.id == "!owner";
    const isCreator = suggestion.id == "!creator";
    const isSignatory = suggestion.id == "!signatories";

    if (
      userType == "group" &&
      (!isAll || !isOwner || !isCreator || !isSignatory)
    ) {
      return <Group id={Number(getGroupMentionId(suggestion.id))} />;
    }

    return (
      <UserMention
        userDisplayName={removeAsperandSymbol(suggestion.display)}
        userEmail={getUserDetails(suggestion.id)?.email}
        userUID={suggestion.id}
        userPhotoUrl={getUserDetails(suggestion.id)?.photoUrl ?? ""}
      />
    );
  };

  return showList ? (
    <Box>
      <Box sx={MentionListContainer}>
        <Flex sx={MentionListWrapper}>
          {filteredUsersAndGroups.length ? (
            filteredUsersAndGroups.map((suggestion, index) => (
              <MentionButton
                key={suggestion.id}
                type={suggestion.type}
                index={index}
                isSelected={index === selectedIndex}
                onClick={() => selectItem(index)}
                ref={index === selectedIndex ? selectedUserRef : null}
                data-cy="mentionButton"
              >
                {_renderSuggestions(suggestion)}
              </MentionButton>
            ))
          ) : (
            <NotFoundContainer>No results found</NotFoundContainer>
          )}
        </Flex>
      </Box>
    </Box>
  ) : null;
};

const ForwardedMentionList: ComponentType = forwardRef(MentionList);
export default ForwardedMentionList;
