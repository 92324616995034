// @flow

import * as R from "ramda";
import type {
  AppState,
  UsersById,
  GroupsById,
  Group,
  UnifizeUser
} from "src/types";
import { put, call, select, takeLatest, takeEvery } from "redux-saga/effects";
import getAppState from "src/selectors";
import * as reducers from "src/reducers";
import * as atypes from "src/constants/actionTypes";
import * as api from "src/api/workflow";
import { revisionDefaultSettings } from "src/utils/morpheus";
import { filterFields } from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/Revision/utils";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import { convert as convertV2ToV1 } from "src/components/Status/SettingsModal/v2tov1Converter";
import * as morpheus from "src/utils/morpheus";
import { pathsWithMentions } from "../components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/utils";
import { dataStages, defaultChecklistLayout } from "../constants";

const rejectedKeys = ["checklistFields"];

const resolveMentionsInText = ({
  message,
  users,
  groups
}: {
  message: string,
  users: UsersById,
  groups: GroupsById
}) => {
  // $FlowFixMe
  const mentions: Array<string> = R.match(
    // eslint-disable-next-line no-useless-escape
    /\<[@|!](.*?)\>/g,
    message
  );

  if (mentions.length > 0) {
    const resolvedMentios = R.reduce(
      (acc: string, user: string) => {
        const uid = (user || "")
          .replace("<@", "")
          .replace("<!", "")
          .replace(">", "");

        switch (uid) {
          case "everyone":
            return (acc || "").replace("<!everyone>", `@[@All]<@!everyone>`);
          case "owner":
            return (acc || "").replace("<!owner>", `@[@Owner]<@!owner>`);
          case "creator":
            return (acc || "").replace("<!creator>", `@[@Creator]<@!creator>`);
          case "signatories":
            return (acc || "").replace(
              "<!signatories>",
              `@[@Signatories]<@!signatories>`
            );
          default:
            if (uid.startsWith("team")) {
              const groupId: string = uid.split("^")[1];
              const mentionedGroup: Group = groups[groupId] || {};
              const title: string = mentionedGroup.title || "";
              return (acc || "").replace(user || "", `@[${title}]<@${uid}>`);
            } else {
              const mentionedUser: UnifizeUser = users[uid] || {};
              const name: string =
                mentionedUser.displayName || mentionedUser.email || "";
              return (acc || "").replace(user || "", `@[${name}]<@${uid}>`);
            }
        }
      },
      message,
      mentions
    );

    return (resolvedMentios || "")
      .replace("<!everyone>", "<@!everyone>")
      .replace("<!owner>", "<@!owner>")
      .replace("<!creator>", "<@!creator>")
      .replace("<!signatories>", "<@!signatories>");
  }

  return message;
};

function* editWorkflowBuilder({ payload }: any): any {
  try {
    // Get checklist data from workflow API
    const workflow = yield call(api.getWorkflow, payload.id);
    const users: UsersById = (yield select(getAppState)).users.byId;
    const groups: GroupsById = (yield select(getAppState)).groups.byId;

    const statuses = (workflow.status || []).map(item => {
      return R.mergeDeepRight(item, {
        rules: {
          blocks: convertV2ToV1(item?.rules?.blocks || []),
          defaultBehavior: item?.rules?.defaultBehavior
        }
      });
    });

    yield put({
      type: atypes.SET_WORKFLOW_BUILDER_ATTRIBUTES,
      payload: {
        value: {
          ...R.omit(rejectedKeys, workflow),
          status: statuses,
          settings: workflow.settings || {},
          header: "Edit Process Template",
          loading: dataStages.fetched,
          layout: workflow.layout ? workflow.layout : defaultChecklistLayout,

          // Converting mentions from format <@UID> to @[display_name]<@uid>
          // since it is the format required by react-mentions
          reminder: (workflow.reminder || []).map(reminder => {
            if (reminder.message) {
              const message = resolveMentionsInText({
                message: reminder.message,
                users,
                groups
              });
              return {
                ...reminder,
                message
              };
            } else {
              return reminder;
            }
          }),
          edit: true
        }
      }
    });

    // Parse the stringified JSON in the checklist field settings
    const parsedChecklistFields = R.map(field => {
      let settings = {};

      if (!R.isNil(field.settings)) {
        try {
          settings = JSON.parse(field.settings);
        } catch (error) {
          console.error({ field, error });
        }
      }

      if (morpheus[field.type]) {
        settings = morpheus[field.type](settings);
      }

      if (field.type === "approval") {
        pathsWithMentions.forEach(path => {
          // $FlowFixMe
          const text: string = R.path(path, settings);
          const textWithMentionsResolved = resolveMentionsInText({
            message: text,
            users,
            groups
          });
          // $FlowFixMe
          settings = R.assocPath(path, textWithMentionsResolved, settings);
        });
      }

      return {
        ...field,
        settings
      };
    }, workflow.checklistFields);

    const deletedFields = R.filter(
      field => field.deleted,
      parsedChecklistFields
    );

    const presentFields = R.filter(
      field => !field.deleted,
      parsedChecklistFields
    );

    const stringifiedChecklistPresentFields = R.map(
      field => ({ ...field, settings: JSON.stringify(field.settings) }),
      presentFields
    );

    // Store fields in byId
    yield put({
      type: atypes.GET_CHECKLIST_FIELDS_SUCCESS,
      payload: {
        fields: stringifiedChecklistPresentFields,
        checklistId: workflow.checklists[0]
      }
    });

    yield put({
      type: atypes.SET_CURRENT_CHECKLIST,
      payload: {
        fields: presentFields,
        deletedFields
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchEditWorkflowBuilder(): any {
  yield takeLatest(atypes.EDIT_PROCESS_REQUEST, editWorkflowBuilder);
}

const getDefaultSettings = (fieldType: string, state: AppState) => {
  switch (fieldType) {
    case "pdf":
      return {
        preview: "latest",
        pdf: [
          {
            seqNo: 0,
            templateId: null
          }
        ]
      };

    case "revision": {
      const copyableFields = R.pluck(
        "id",
        filterFields(
          reducers.getCurrentChecklistBuilderFields(state),
          forbiddenFieldTypes
        )
      );

      return {
        ...revisionDefaultSettings,
        copyableFields,
        authorizedToCreate: {
          users: []
        }
      };
    }

    case "file":
      return {
        multiple: true,
        preview: true
      };

    case "link":
      return {
        multiple: true,
        select: true,
        create: true
      };

    case "select":
      return {
        adhoc: true
      };

    case "text":
      return {
        multiline: true
      };

    case "form":
      return {
        multiple: true
      };

    case "conversation":
    case "childConversation":
      return {
        multiple: true,
        select: true,
        create: true,
        autoFillRelated: true
      };

    case "approval": {
      const fields = R.pluck(
        "id",
        filterFields(reducers.getCurrentChecklistBuilderFields(state), [
          "section",
          "subSection",
          "revision"
        ])
      );

      return morpheus.approval({
        users: [],
        min: 1,
        // $FlowFixMe
        lockedFields: fields,
        requireAll: false,
        afterApproval: {
          changeStatusTo: null,
          lockStatus: false,
          allowRevision: false
        },
        afterRevision: {
          changeStatusTo: null
        }
      });
    }

    default:
      return {};
  }
};

function* watchAddFieldsRequest(): any {
  yield takeEvery(
    atypes.ADD_FIELDS_IN_CHECKLIST_BUILDER_REQUEST,
    function* (action) {
      const appState = yield select(getAppState);

      const fields = action.payload.fields.map(field => ({
        label: field.label || "",
        type: field.fieldType,
        settings:
          field.settings || getDefaultSettings(field.fieldType, appState),
        hidden: false,
        deleted: false,
        deletedBy: null,
        deletedAt: null
      }));

      yield put({
        type: atypes.ADD_FIELDS_IN_CHECKLIST_BUILDER,
        payload: { ...action.payload, fields }
      });
    }
  );
}

function* watchUpdateWorkflowBuilder(): any {
  yield takeEvery(atypes.UPDATE_WORKFLOW_BUILDER, editWorkflowBuilder);
}

export default [
  watchEditWorkflowBuilder(),
  watchAddFieldsRequest(),
  watchUpdateWorkflowBuilder()
];
