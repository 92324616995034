// @flow

import React from "react";
import { connect } from "react-redux";

import { HiddenMessage, UnhideOption } from "./styles";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { unhideMessage } from "src/actions/chatmenu";

import type { UID, RoomId } from "src/types";

type Props = {
  message: any,
  roomId: RoomId,
  currentUser: UID,
  unhide: Function
};

const Hidden = ({ message, roomId, currentUser, unhide }: Props) => {
  const { id, hiddenBy } = message;
  return (
    <div>
      <HiddenMessage isCurrentUser={currentUser === hiddenBy}>
        {currentUser === hiddenBy ? `You` : <NameOrEmail uid={hiddenBy} />} hid
        a message.{" "}
        <UnhideOption onClick={() => unhide(roomId, id)}>Unhide</UnhideOption>
      </HiddenMessage>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  currentUser: app.currentUser.uid,
  roomId: app.chatRooms.current
});

export default connect(mapStateToProps, {
  unhide: unhideMessage
})(Hidden);
