// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Input,
  FormHelperText
} from "@chakra-ui/react";
import FieldSelect from "../FieldSelect";
import MultipleSelection from "./MultipleSelection";
import {
  setNewChartDialogAttributes,
  createChart,
  updateChart
} from "src/actions/chart";
import { inkLight } from "src/styles/constants/colors";

import type { AppState, NewChartDialog } from "src/types";

type Props = {
  newChartDialog: NewChartDialog,
  _createChart: Function,
  _editChart: Function,
  _setNewChartDialogAttributes: Function,
  name: string
};

const CountBarChart = ({
  newChartDialog,
  _createChart,
  _editChart,
  _setNewChartDialogAttributes,
  name
}: Props) => {
  const { title, x, computationField } = newChartDialog;
  const isDisabled =
    title.length === 0 ||
    x === null ||
    (name === "form-count-doughnut" && computationField === null);
  const handleYaxisLabel = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        yAxisLabel: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handlePrimaryField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        x: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleFields = useCallback(() => {
    _setNewChartDialogAttributes({
      loading: true
    });
    if (newChartDialog.edit) {
      _editChart();
    } else {
      _createChart();
    }
  }, [_setNewChartDialogAttributes, _editChart, _createChart, newChartDialog]);

  const handleFormFieldSelect = (value: string = "") => {
    _setNewChartDialogAttributes({
      computationField: value
    });
  };

  return (
    <>
      {name === "form-count-doughnut" ? (
        <FormControl id="form" mb={3}>
          <FormLabel fontWeight={600}>Select form</FormLabel>
          <FormHelperText color={inkLight}>
            The count will represent the total of all the instances of this form
          </FormHelperText>
          <FieldSelect
            isForm
            value={newChartDialog.computationField}
            handleSelect={handleFormFieldSelect}
            fieldType="form"
          />
        </FormControl>
      ) : null}

      <FormControl id="primaryField" mb={3}>
        <FormLabel>Primary field</FormLabel>
        <FormHelperText>
          Primary fields are the main underlying data for a chart
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.x}
          dependentField={newChartDialog.y}
          handleSelect={handlePrimaryField}
        />
      </FormControl>

      <FormControl id="yAxisLabel" mb={3}>
        <FormLabel>Y-axis label</FormLabel>
        <FormHelperText>Label for Y-axis of the chart</FormHelperText>
        <Input
          type="text"
          value={newChartDialog.yAxisLabel}
          onChange={handleYaxisLabel}
          placeholder="Enter Label"
          size="sm"
        />
      </FormControl>

      <FormControl id="multipleValue" mb={3}>
        <FormLabel>Multiple Value Calculation</FormLabel>
        <FormHelperText>
          If a field contains multiple values per conversation, select what the
          y-axis totals represent
        </FormHelperText>
        <MultipleSelection value={newChartDialog.relativeCount} />
      </FormControl>

      <Box mb={3} mt={6}>
        {newChartDialog.edit ? (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Save changes
          </Button>
        ) : (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Create and save
          </Button>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _createChart: createChart,
  _editChart: updateChart
})(CountBarChart);
