// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import type { List as ImmutableList } from "immutable";
import * as R from "ramda";

import Chat from "./Chat";
import DefaultSegmentStatus from "./SegmentedList/DefaultSegmentStatus";
import DefaultSegmentPriority from "./SegmentedList/DefaultSegmentPriority";
import CustomSegmentStatus from "./SegmentedList/CustomSegmentStatus";
import VirtualList from "src/components/VirtualList";
import {
  getCurrentFilterSortBy,
  getCurrentFilterWorkflow,
  getCurrentFilter
} from "src/reducers";
import { showCustomStatusSegments } from "src/utils/filters";

import type { RoomId, AppState, CurrentFilter } from "src/types";

type Props = {
  rooms: ImmutableList<RoomId>,
  currentFilterSortBy: string,
  currentWorkflowId: number,
  workflow: number,
  currentFilter: CurrentFilter
};

class List extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { rooms, currentFilter } = this.props;

    if (
      typeof prevProps.rooms.hashCode === "function" &&
      typeof rooms.hashCode === "function" &&
      prevProps.rooms.hashCode() !== rooms.hashCode()
    ) {
      try {
        // eslint-disable-next-line react/no-find-dom-node
        const current: Object = findDOMNode(this);

        // Scroll to top if only theres a change in current filters
        if (current && !R.equals(prevProps.currentFilter, currentFilter)) {
          current.scrollIntoView();
        }
      } catch (e) {}
    }
  }

  renderRow = (index: number) => {
    const { rooms } = this.props;
    const roomId = rooms.get(index);
    if (roomId) {
      return <Chat key={roomId} role="listitem" roomId={roomId} />;
    }
  };

  render() {
    const {
      rooms,
      currentFilterSortBy,
      currentWorkflowId,
      workflow
    } = this.props;

    const {
      defaultSegment,
      customSegment,
      prioritySegment
    } = showCustomStatusSegments(currentFilterSortBy, currentWorkflowId);

    return (
      <>
        {defaultSegment && <DefaultSegmentStatus />}
        {customSegment && <CustomSegmentStatus workflow={workflow} />}
        {prioritySegment && <DefaultSegmentPriority />}
        {defaultSegment === false &&
          customSegment === false &&
          prioritySegment === false && (
            <VirtualList
              initialAmount={20}
              progressiveAmount={20}
              role="list"
              renderItem={this.renderRow}
              renderLoader={() => <div />}
              rowCount={rooms.size}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentFilterSortBy: getCurrentFilterSortBy(app),
  segmentedStatus: app.chatRooms.filters.segmentedStatus.byStatus,
  currentWorkflowId: getCurrentFilterWorkflow(app),
  currentFilter: getCurrentFilter(app)
});
export default connect(mapStateToProps)(List);
