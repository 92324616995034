// @flow

import { connect } from "react-redux";
import React from "react";

import IconLabel from "src/components/IconLabel/Remove";
import { iconSize } from "src/styles/constants/size";
import { getWorkflowName } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  id: number,
  title: string,
  removeHandler: Function
};

const NameWithRemove = ({ id, title, removeHandler }: Props) => (
  <IconLabel
    icon="workflow"
    size={iconSize.space_l}
    label={title}
    removeHandler={e => removeHandler(e, id)}
  />
);

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  title: getWorkflowName(app, id)
});

export default connect(mapStateToProps)(NameWithRemove);
