// @flow

import styled from "@emotion/styled";

import { Select as StyledSelect } from "src/styles/dropdown";

const Select = styled(StyledSelect)`
  height: 100%;
  > div:first-of-type {
    display: flex;
    > div > div {
      white-space: nowrap;
      & > span > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 184px;
        cursor: pointer;
      }
    }
  }
`;

export { Select };
