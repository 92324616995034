// @flow

import { combineReducers } from "redux";
import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

type SrwState = {
  isSingleResponse: boolean,
  isMobile: boolean,
  query: Object,
  signUpModal: boolean,
  isExistingUser: boolean,
  isVerificationEmailSent: boolean
};

const isSingleResponse = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.SINGLE_RESPONSE_WINDOW:
      return true;
    default:
      return state;
  }
};

const isMobile = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.MOBILE_RESPONSE_WINDOW:
      return true;
    default:
      return state;
  }
};

const query = (state: any = {}, { type, payload }) => {
  switch (type) {
    case atypes.SRW_AUTH_REQUEST:
      return payload;
    default:
      return state;
  }
};

const signUpModal = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.SRW_SHOW_SIGNUP_MODAL:
      return true;
    case atypes.SRW_HIDE_SIGNUP_MODAL:
      return false;
    default:
      return state;
  }
};

const isExistingUser = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.SRW_SET_EXISTING_USER:
      return true;
    default:
      return state;
  }
};

const isVerificationEmailSent = (state: ?boolean = null, { type, payload }) => {
  switch (type) {
    case atypes.SRW_SIGN_UP_SUCCESS: {
      if (payload.response.status === 200) return true;
      return false;
    }
    default:
      return state;
  }
};

export const getIsSingleResponse = (state: SrwState) => state.isSingleResponse;
export const getIsSrwMobile = (state: SrwState) => state.isMobile;

const srw = combineReducers<Object, Action>({
  isSingleResponse,
  isMobile,
  query,
  signUpModal,
  isExistingUser,
  isVerificationEmailSent
});

export default srw;
