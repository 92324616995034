// @flow

import { connect } from "react-redux";
import React, { useState, useRef } from "react";

import List from "./List";
import { Process as StyledProcess } from "./styles";
import { RelativeBox } from "src/styles/box";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter
};

const Process = ({ filter }: Props) => {
  const [dropdown, showDropdown] = useState(false);
  const outerRef = useRef(null);

  const template = (filter || {}).template || [];

  return (
    <RelativeBox>
      <StyledProcess
        active={dropdown || template.length > 0}
        ref={outerRef}
        onClick={showDropdown}
      >
        Process
      </StyledProcess>
      {dropdown ? (
        <List outerRef={outerRef} handleClose={() => showDropdown(false)} />
      ) : null}
    </RelativeBox>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps)(Process);
