// @flow

import { connect } from "react-redux";
import React, { useRef, useCallback } from "react";
import * as R from "ramda";

import { AddUserSegment as StyledAddUserSegment } from "../styles";
import UserSegmentSelection from "./UserSegmentSelection";
import UserSegmentDropdown from "./UserSegmentDropdown";
import useBoolean from "src/hooks/useBoolean";
import { RelativeBox } from "src/styles/box";
import { updateTile, closeSegmentUserModal } from "src/actions/orgSettings";
import { getTile } from "src/reducers";
import NewUserSegment from "./NewUserSegment";

import type { AppState, HomescreenSection } from "src/types";

type Props = {
  right: boolean,
  showModal: ?number,
  tile: HomescreenSection,
  tileId: number,
  _updateTile: Function,
  _closeSegmentUserModal: Function
};

const AddUserSegment = ({
  right,
  showModal,
  tileId,
  tile,
  _updateTile,
  _closeSegmentUserModal
}: Props) => {
  const { value: showDropdown, toggleBoolean } = useBoolean();
  const outerRef = useRef(null);
  const value = tile.segments || [];

  const handleSelect = useCallback(
    (id: number) => {
      let newValue = value;
      if (R.includes(id, value)) {
        newValue = R.reject(R.equals(id), value);
      } else {
        newValue = [...value, id];
      }

      _updateTile({ ...tile, segments: newValue });
    },
    [tile, value, _updateTile]
  );

  return (
    <RelativeBox ref={outerRef}>
      {(value || []).length > 0 ? (
        <UserSegmentSelection
          right={right}
          value={value || []}
          toggleDropdown={toggleBoolean}
        />
      ) : (
        <StyledAddUserSegment type="button" onClick={toggleBoolean}>
          &#43; Add user segment
        </StyledAddUserSegment>
      )}

      {showDropdown && (
        <UserSegmentDropdown
          value={value}
          outerRef={outerRef}
          tileId={tileId}
          handleClose={toggleBoolean}
          handleSelect={handleSelect}
        />
      )}

      <NewUserSegment
        isOpen={showModal === tileId}
        tileId={tileId}
        handleClose={_closeSegmentUserModal}
      />
    </RelativeBox>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  tile: getTile(app, props.tileId),
  showModal: app.orgSettings.segmentUserModal.show
});

export default connect(mapStateToProps, {
  _updateTile: updateTile,
  _closeSegmentUserModal: closeSegmentUserModal
})(AddUserSegment);
