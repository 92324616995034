// @flow

import React from "react";

type Props = {};

const Calendar = ({ ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    fill="#BBB"
    viewBox="0 0 10 10"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      d="M9.375 1.25h-1.25V0h-1.25v1.25h-3.75V0h-1.25v1.25H.625A.625.625 0 0 0 0 1.875v1.25h10v-1.25a.625.625 0 0 0-.625-.625z"
      clipRule="evenodd"
    />
    <path d="M0 3.75v5.625C0 9.75.25 10 .625 10h8.75c.375 0 .625-.25.625-.625V3.75H0z" />
  </svg>
);

export default Calendar;
