// @flow

import React from "react";

import { Lock as StyledLock } from "./styles";

type Props = {
  locked: boolean,
  handleLock: Function
};

const Lock = ({ locked, handleLock }: Props) => (
  <StyledLock
    locked={locked}
    tabIndex="0"
    role="button"
    onClick={handleLock}
    onKeyPress={handleLock}
  >
    <h5>Lock Group</h5>
    <div>Only the group owner can make changes to a locked group</div>
  </StyledLock>
);

export default Lock;
