// @flow

import styled from "@emotion/styled";

import { Button } from "src/styles/buttons";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";

export const PrimaryButton = styled(Button)`
  background-color: ${colors.danger};
  border: none;
  font-size: 1rem;
  font-weight: ${typography.semiBold};
  border-radius: ${radius.medium};
  padding: ${spacing.space_s} 0.56em;
  outline: none;
  margin-right: 0px !important;
  :hover {
    border: none;
  }
`;

export default PrimaryButton;
