// @flow

import { FlexProps, TextProps } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const chatCard = {
  paddingLeft: "0.8em"
};

export const avatar = {
  borderRadius: "8px",
  color: "white",
  backgroundColor: "transparent"
};

export const messageCardContent = {
  alignItems: "start",
  width: "100%"
};

export const slackMessageContainer = {
  width: "100%",
  alignItems: "start",
  px: 2,
  py: 1,
  border: "1px solid",
  borderColor: "gray.200",
  borderRadius: "6px"
};

export const threadContainer = {
  alignItems: "start"
};

export const threadList = {
  alignItems: "start",
  pl: 2,
  maxHeight: "200px",
  overflow: "auto"
};

export const timestamp = {
  position: "initial",
  alignSelf: "end"
};

export const container: FlexProps = {
  border: "1px",
  borderColor: "unifize_sky",
  borderRadius: "6px",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  px: 2,
  py: 2,
  gridGap: "1rem"
};

export const fileIcon = {
  width: "18px",
  height: "18px"
};

export const fileName: TextProps = {
  my: 0,
  isTruncated: true,
  maxWidth: "450px",
  fontSize: "14px"
};

export const fileSize: TextProps = {
  my: 0,
  fontSize: "14px"
};

export const downloadIcon = {
  size: "10px",
  cursor: "pointer",
  color: colors.inkLighter
};
