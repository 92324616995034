// @flow

import React from "react";

const Owner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 10.5h15M14.5 13.5h-13a1 1 0 0 1-1-1v-11l3 4 4.5-4 4.5 4 3-4v11a1 1 0 0 1-1 1z"
    />
  </svg>
);

export default Owner;
