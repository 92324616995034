// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const StyledReports = styled.div`
  z-index: 2;
  padding-left: 4rem;
  position: absolute;
  top: 0px;
  left: 50px;
  right: 0px;
  bottom: 0px;
  padding-left: 25px;
  padding-right: 15px;
  padding-top: 8px;
  border: none;
  background-color: ${colors.brightLight};
  font-size: 12px;
`;
export { StyledReports };
