// @flow

import React, { useRef } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { v4 as uuid } from "uuid";

import useBoolean from "src/hooks/useBoolean";
import Dropdown from "src/components/Dropdown";
import ComparisonRow from "./ComparisonRow";
import * as styles from "../styles";
import { comparisonValues } from "src/constants/charts";
import { handleAddComparison, keyboardHandler } from "src/utils/charts.js";

import type { DownshiftProps } from "downshift";

type Props = {
  setChartDetails: Function
};

const comparisonValueList = [
  {
    id: uuid(),
    typeLabel: "Line",
    typeSubLabel: "",
    name: "",
    type: comparisonValues.line
  },
  {
    id: uuid(),
    typeLabel: "Area",
    typeSubLabel: "",
    name: "",
    type: comparisonValues.area
  },
  {
    id: uuid(),
    typeLabel: "Column",
    typeSubLabel: "",
    name: "",
    type: comparisonValues.column
  },
  {
    id: uuid(),
    typeLabel: "Rate",
    typeSubLabel: "Percentage of PPM of another value",
    name: "",
    type: comparisonValues.rate
  }
];

const AddComparisonDropdown = ({ setChartDetails }: Props) => {
  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  const downshiftRef = useRef<DownshiftProps>(null);

  const handleSelect = ({
    item
  }: {
    item: {
      id: string,
      typeLabel: string,
      typeSubLabel: string,
      type: string
    }
  }) => {
    handleAddComparison(setChartDetails, uuid(), item.type);

    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: comparisonValueList[selectedIndex] });
  };

  return (
    <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
      <Button
        onClick={toggleDropdown}
        variant="outline"
        onKeyDown={e =>
          keyboardHandler({
            event: e,
            ref: downshiftRef,
            onSelect: onKeyboardSelect,
            onClose: closeDropdown
          })
        }
        sx={styles.AddComparisonButton}
      >
        &#43; Add Comparison
      </Button>

      {isOpen && (
        <Dropdown
          onItemClick={handleSelect}
          onOuterClick={closeDropdown}
          isOpen={isOpen}
          ref={downshiftRef}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return comparisonValueList.map((list, index) => (
              <ComparisonRow
                item={comparisonValueList[index]}
                key={list.id}
                elId={index}
                index={index}
                highlightedIndex={highlightedIndex}
                scrollIntoView={scrollIntoView}
                {...getItemProps({
                  item: comparisonValueList[index],
                  index,
                  id: comparisonValueList[index].id,
                  onItemClick: onItemClick
                })}
              />
            ));
          }}
        </Dropdown>
      )}
    </Flex>
  );
};

export default AddComparisonDropdown;
