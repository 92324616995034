// @flow

import { connect } from "react-redux";

import SearchMultiple from "src/components/chatroom/SearchMultiple";
import { createChatroom } from "src/actions/chatroom";
import { getChatrooms } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  rooms: getChatrooms(app)
});

export default connect(mapStateToProps, { createChatroom })(SearchMultiple);
