// @flow

import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import Menu from "./Menu";
import { Creator as StyledCreator } from "./styles";
import User from "src/containers/user/ImageLabel/Medium";
import Icon from "src/components/Icon";
import * as colors from "src/styles/constants/colors";
import type { Workflow } from "src/types";

type Props = {
  workflow: Workflow,
  handleEdit: Function,
  handleDelete: Function
};

type State = {
  showMenu: boolean
};

class Creator extends Component<Props, State> {
  state = {
    showMenu: false
  };

  handleClickOutside = (e: MouseEvent) => {
    if (e) {
      this.setState({ showMenu: false });
    }
  };

  toggleMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }));
  };

  render() {
    const { workflow, handleEdit, handleDelete } = this.props;
    const { showMenu } = this.state;

    return (
      <StyledCreator onClick={this.toggleMenu}>
        {workflow.creator && <User uid={workflow.creator} />}
        <Icon icon="ellipsis" color={colors.ternary} />

        {showMenu ? (
          <Menu
            workflow={workflow}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ) : null}
      </StyledCreator>
    );
  }
}

export default onClickOutside(Creator);
