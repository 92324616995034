// @flow

import React, { useState } from "react";
import { connect } from "react-redux";

import { deleteCustomRole, updateCustomRole } from "src/actions/roleManagement";

import {
  HStack,
  Text,
  Tooltip,
  useDisclosure,
  EditablePreview
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Editable as StyledEditable,
  EditableInput as StyledEditableInput
} from "src/styles/input";
import DeleteRoleModal from "../Modal/DeleteRole";
import { Th as StyledTh } from "src/styles/chakraTable";
import * as colors from "src/styles/constants/colors";

type Prop = {
  id: number,
  header: string,
  systemRole: boolean,
  _deleteCustomRole: Function,
  _updateCustomRole: Function
};

const Header = ({
  header,
  systemRole,
  id,
  _deleteCustomRole,
  _updateCustomRole
}: Prop) => {
  const [isIconVisible, setIsIconVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState(header);

  const handleDelete = () => {
    _deleteCustomRole(id);
  };

  const resetValue = () => setValue(header);
  const handleChange = nextValue => setValue(nextValue);

  const handleUpdate = () => {
    if (value && value !== header) {
      _updateCustomRole(id, value);
    } else {
      resetValue();
    }
  };

  return (
    <StyledTh
      width="153px"
      minWidth="153px"
      maxWidth="153px"
      textAlign="center"
      onMouseEnter={() => setIsIconVisible(true)}
      onMouseLeave={() => setIsIconVisible(false)}
    >
      {systemRole ? (
        <Tooltip label={header}>
          <Text isTruncated color={colors.ink} fontWeight={600}>
            {header}
          </Text>
        </Tooltip>
      ) : (
        <HStack alignItems="center" justifyContent="space-between">
          <StyledEditable
            defaultValue={header}
            value={value}
            onSubmit={handleUpdate}
            onChange={handleChange}
            onCancel={resetValue}
          >
            <Tooltip label={header}>
              <EditablePreview maxWidth="100px" isTruncated />
            </Tooltip>
            <StyledEditableInput py={2} />
          </StyledEditable>

          {isIconVisible && !systemRole ? (
            <DeleteIcon cursor="pointer" onClick={onOpen} />
          ) : null}

          <DeleteRoleModal
            isOpen={isOpen}
            onClose={onClose}
            id={id}
            _deleteRole={handleDelete}
          />
        </HStack>
      )}
    </StyledTh>
  );
};

export default connect(null, {
  _deleteCustomRole: deleteCustomRole,
  _updateCustomRole: updateCustomRole
})(Header);
