// @flow

import React from "react";

const CircularArrows = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M7.99984 4.995V6.995L10.6665 4.32833L7.99984 1.66167V3.66167C5.05317 3.66167 2.6665 6.04833 2.6665 8.995C2.6665 10.0417 2.97317 11.015 3.49317 11.835L4.4665 10.8617C4.1665 10.3083 3.99984 9.66833 3.99984 8.995C3.99984 6.78833 5.79317 4.995 7.99984 4.995ZM12.5065 6.155L11.5332 7.12833C11.8265 7.68833 11.9998 8.32167 11.9998 8.995C11.9998 11.2017 10.2065 12.995 7.99984 12.995V10.995L5.33317 13.6617L7.99984 16.3283V14.3283C10.9465 14.3283 13.3332 11.9417 13.3332 8.995C13.3332 7.94833 13.0265 6.975 12.5065 6.155Z"
        fill="#2F80ED"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.994995)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CircularArrows;
