// @flow

import React from "react";

const CircleDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" fill="#BBBBBB" />
    <path d="M7 10L12 15L17 10H7Z" fill="white" />
  </svg>
);

export default CircleDown;
