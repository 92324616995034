// @flow

import { useSelector } from "react-redux";
import { getComponentPermission } from "src/reducers";

import type { ComponentPermissionName } from "src/types";

const useComponentPermission = (componentName: ComponentPermissionName) => {
  const componentShown = useSelector(({ app }) =>
    getComponentPermission(app, componentName)
  );

  return componentShown;
};

export default useComponentPermission;
