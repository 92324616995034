// @flow

import * as colors from "./colors.old";
import * as size from "./size.old";
import * as shadow from "./shadow.old";
import * as spacing from "./spacing.old";
import * as fontSize from "./fontSize.old";

const { iconSize } = size;

export { colors, size, shadow, spacing, fontSize, iconSize };
