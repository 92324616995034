// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

export const showDock = (dockContent: string): Action => ({
  type: atypes.SHOW_DOCK,
  payload: {
    dockContent
  }
});

export const showSrwDock = (dockContent: string): Action => ({
  type: atypes.SHOW_SRW_DOCK,
  payload: {
    dockContent
  }
});

export const hideDock = (): Action => ({
  type: atypes.HIDE_DOCK,
  payload: {}
});

export const hideSrwDock = (): Action => ({
  type: atypes.HIDE_SRW_DOCK,
  payload: {}
});

export const toggleDock = (dockContent: string): Action => ({
  type: atypes.TOGGLE_DOCK,
  payload: {
    dockContent
  }
});

export const setHeaderHeight = (height: number): Action => ({
  type: atypes.SET_HEADER_HEIGHT,
  payload: height
});
