import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

export const Button = styled.button`
  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  word-wrap: break-word;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
  margin: ${props => props.margin || 0};
  overflow: visible;
  text-transform: none;
  border: ${props => props.border || "none"};
  cursor: pointer;
  display: inline-flex;
  gap: ${props => props.gap || "0.5rem"};
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  align-self: ${props => props.alignSelf || "stretch"};
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: ${props => props.position || "relative"};
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: ${props => props.width || "auto"};
  line-height: 1.2;
  border-radius: ${props => props.borderRadius || "0.5rem"};
  font-weight: ${props => props.fontWeight || "500"};
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: ${props => props.height || "2rem"};
  min-width: ${props => props.width || "118.16px"};
  font-size: 0.875rem;
  padding-inline: ${props => props.padding || "0.75rem 0.75rem"};
  background: ${props => props.bgColor || colors.blue};
  color: ${props => props.textColor || colors.brightLight};
`;

export const AnimatedButton = styled(Button)`
  cursor: not-allowed;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  > svg {
    animation: rotate 0.6s linear infinite;
  }
`;
