// @flow

import React from "react";

import { Users as StyledUsers } from "./styles";
import Members from "src/components/Group/Users";

import type { UID } from "src/types";

type Props = {
  users: Array<UID>
};

const Users = ({ users }: Props) => (
  <StyledUsers>
    <Members users={users} />
  </StyledUsers>
);

export default Users;
