// @flow

import React, { useMemo } from "react";

import StyledStatus from "./styles";
import statusConstants from "src/constants/status";

type Props = {
  id: number
};

const Standard = ({ id }: Props) => {
  const status = useMemo(() => {
    return statusConstants[`${id}`] || {};
  }, [id]);

  return (
    <StyledStatus color={status.color} textColor={status.textColor}>
      {status.text}
    </StyledStatus>
  );
};

export default Standard;
