// @flow

import React from "react";

const Unreads = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#F2994A"
      d="M13.333 1.334H2.667c-.734 0-1.334.6-1.334 1.333v12L4 12.001h9.333c.734 0 1.334-.6 1.334-1.334v-8c0-.733-.6-1.333-1.334-1.333zm0 9.333H3.447l-.78.78v-8.78h10.666v8zm-6-7.333h1.334v4H7.333v-4zm0 5.333h1.334v1.334H7.333V8.667z"
    />
    <circle cx="14" cy="2" r="2" fill="#F00" />
  </svg>
);

export default Unreads;
