// @flow

import React from "react";

import { Panel } from "../SRW/styles";
import Info from "./Info";
import Header from "./Header";
import History from "./History";
import MobileHistory from "./MobileHistory";
import Input from "./Input";
import PanelBody from "./PanelBody";
import PanelHeader from "./PanelHeader";
import Chatblock from "./Chatblock";
import ChecklistField from "./ChecklistField";

type Props = {
  type: string
};

const LoadingState = ({ type, ...restProps }: Props) => {
  switch (type) {
    case "info":
      return <Info />;
    case "header":
      return <Header />;
    case "history":
      return <History />;
    case "mobileHistory":
      return <MobileHistory />;
    case "chatblock":
      return <Chatblock {...restProps} />;
    case "input":
      return <Input />;
    case "panelBody":
      return <PanelBody />;
    case "panelHeader":
      return <PanelHeader />;
    case "panel":
      return (
        <Panel>
          <PanelHeader />
          <PanelBody />
        </Panel>
      );
    case "checklistField":
      return <ChecklistField />;
    default:
      return <div />;
  }
};

export default LoadingState;
