// @flow

import { connect } from "react-redux";

import { getModalState, getTotalRelatedCount } from "src/reducers";
import { toggleRelatedModal } from "src/actions/modal";
import Related from "src/components/chatroom/related";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  related: getTotalRelatedCount(app, props.roomId),
  showRelatedModal: getModalState("showRelatedModal")(app)
});

export default connect(mapStateToProps, { toggleRelatedModal })(Related);
