// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Image } from "@chakra-ui/react";

import { getValueFromQuery } from "src/reducers";
import DualYAxis from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/DualYAxis.svg";
import StackedArea from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/StackedArea.svg";
import MultiSeries from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/MultiSeries.svg";
import { comparisonCharts } from "src/constants/charts";
import * as styles from "./styles";

const ChartImage = () => {
  const builder = useSelector(({ location }) =>
    getValueFromQuery("builder")(location)
  );

  const getSrc = key => {
    switch (key) {
      case comparisonCharts.DualYAxis:
        return DualYAxis;

      case comparisonCharts.StackedArea:
        return StackedArea;

      case comparisonCharts.MultiSeries:
        return MultiSeries;

      default:
        break;
    }
  };

  return <Image sx={styles.ChartImage} src={getSrc(builder)} />;
};

export default ChartImage;
