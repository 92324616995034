// @flow

import React from "react";
import Link from "redux-first-router-link";
import moment from "moment";

import {
  Header,
  Navigation as StyledNavigation,
  InActive,
  Active
} from "./styles";
import Close from "src/containers/Close";
import * as atypes from "src/constants/actionTypes";

type Props = {
  tab: string
};

const lastWeek = `${moment()
  .subtract(7, "days")
  .startOf("day")
  .format("YYYY-MM-DD")}::${moment().startOf("day").format("YYYY-MM-DD")}`;

const Navigation = ({ tab }: Props) => (
  <StyledNavigation>
    <Header>Manage</Header>

    {tab !== "workflow" ? (
      <InActive>
        <Link to={{ type: atypes.SET_PROCESS_REQUEST, payload: {} }}>
          Processes
        </Link>
      </InActive>
    ) : (
      <Active>Processes</Active>
    )}

    {tab !== "user-analytics" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_USER_ANALYTICS_REQUEST,
            payload: {},
            meta: {
              query: {
                createdAt: lastWeek,
                completedAt: lastWeek,
                sort: ["pendingUnread:desc"]
              }
            }
          }}
        >
          User Analytics
        </Link>
      </InActive>
    ) : (
      <Active>User Analytics</Active>
    )}

    {tab !== "user-reports" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_REPORTS_REQUEST,
            payload: {}
          }}
        >
          Reports
        </Link>
      </InActive>
    ) : (
      <Active>Reports</Active>
    )}
    {tab !== "forms" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_FORMS_REQUEST,
            payload: {}
          }}
        >
          Forms
        </Link>
      </InActive>
    ) : (
      <Active>Forms</Active>
    )}
    <Close />
  </StyledNavigation>
);

export default Navigation;
