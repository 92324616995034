// @flow

import React from "react";

import { Checkbox } from "@chakra-ui/react";

type Props = {
  value: boolean,
  onChange: Function
};

export default function Unarchive({ value, onChange }: Props) {
  return (
    <Checkbox
      size="sm"
      isChecked={value === false}
      onChange={() => {
        onChange(!value);
      }}
    >
      Unarchive
    </Checkbox>
  );
}
