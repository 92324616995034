// @flow

import React from "react";

import { Suggestion as StyledSuggestion } from "./styles";
import User from "src/containers/user/ImageLabel/Curved";
import Owner from "./Owner";
import Group from "src/components/Group";
import { getGroupMentionId } from "src/utils";

import type { Mentions } from "src/types";

type Props = {
  focused: boolean,
  mention: Mentions
};

const UserAndGroup = ({ focused, mention, ...parentProps }: Props) => {
  const { id, type } = mention;

  const mentionId = type === "user" ? id : getGroupMentionId(id);

  return (
    <StyledSuggestion
      tabIndex="0"
      role="button"
      isFocused={focused}
      {...parentProps}
    >
      {type === "user" ? (
        <>
          <User id={mentionId} />
          <Owner uid={mentionId} />
        </>
      ) : (
        <Group id={Number(mentionId)} />
      )}
    </StyledSuggestion>
  );
};

export default UserAndGroup;
