// @flow

import { connect } from "react-redux";
import React from "react";

import { SaveChanges as StyledSaveChanges } from "./styles";
import Icon from "src/icons";
import { ButtonOutline } from "src/components/Manage/Workflow/Instances/styles";
import { saveChangesToReport, showNewReportsModal } from "src/actions/reports";
import Chart from "src/components/Manage/Reports/Instances/Chart";

type Props = {
  _saveChangesToReport: Function,
  _showNewReportsModal: Function
};

const SaveAndChartButtons = ({
  _saveChangesToReport,
  _showNewReportsModal
}: Props) => {
  return (
    <StyledSaveChanges>
      <Chart />
      <ButtonOutline
        type="button"
        onClick={() =>
          _showNewReportsModal({
            modalType: "clone",
            title: "",
            loading: false
          })
        }
      >
        <Icon type="saveAs" />
        <span>Save As</span>
      </ButtonOutline>

      <ButtonOutline onClick={_saveChangesToReport} type="button">
        <Icon type="saveAs" />
        <span>Save Changes</span>
      </ButtonOutline>
    </StyledSaveChanges>
  );
};

export default connect(null, {
  _saveChangesToReport: saveChangesToReport,
  _showNewReportsModal: showNewReportsModal
})(SaveAndChartButtons);
