// @flow

import React from "react";

import { Table as StyledTable, TableContainer } from "./styles";
import Header from "./Header";
import Body from "./Body";

const Table = () => (
  <TableContainer>
    <StyledTable cellspacing="0" cellpadding="0">
      <Header />
      <Body />
    </StyledTable>
  </TableContainer>
);

export default Table;
