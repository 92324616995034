// @flow

import styled from "@emotion/styled";

import { colors as colorsOld } from "src/styles/constants";

const Form = styled.div`
  flex: 1;
  padding: ${props => (props.form ? "0px" : "12px 0")};
  font-size: 13px;
  color: ${colorsOld.lightGrey};
  input {
    padding-left: 12px;
    font-size: 13px;
    border-radius: 2px;
    background-color: ${colorsOld.pureWhite};
    border: solid 1px ${colorsOld.grey};
  }

  input:read-only {
    background: ${colorsOld.aliceBlue};
  }

  input[type="text"],
  input[type="number"] {
    width: 100%;
    height: 32px;
  }
  input[type="checkbox"] {
    margin-right: 8px;
  }
  textarea {
    width: 100%;
  }
  input[type="submit"] {
    padding: 9px 31px;
    margin: 3px 6px 3px 0px;
    color: ${colorsOld.pureWhite};
    border-radius: 3px;
    background-color: ${colorsOld.blue};
    cursor: pointer;
    &:hover {
      transition: 0.2s all;
      background-color: ${colorsOld.unifizeBlue};
    }
  }

  input[type="submit"]:disabled {
    background-color: ${colorsOld.aliceBlue};
    color: ${colorsOld.mediumBlack};
    cursor: not-allowed;
  }

  > button {
    padding: 9px 12px;
    margin: 3px 6px 3px 0px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    border: solid 1px ${colorsOld.silver};
    color: rgba(75, 75, 75, 0.8);
    background-color: ${colorsOld.pureWhite};
  }

  .DateInput_input {
    border: none;
  }

  label {
    font-size: 13px;
    color: ${colorsOld.lightGrey};
    align-items: center;
    text-align: left;
  }
  input::placeholder {
    color: ${colorsOld.lightGrey};
  }
  hr {
    opacity: 0.5;
    border: solid 0.5px ${colorsOld.simpleGrey};
  }
  b {
    padding-left: 5px;
    color: ${colorsOld.black};
  }

  // Hiding calendar icon
  .SingleDatePickerInput_calendarIcon.SingleDatePickerInput_calendarIcon_1,
  .DateInput_screenReaderMessage {
    display: none;
  }

  // Making the input of react calendar full width
  .SingleDatePicker.SingleDatePicker_1,
  .SingleDatePicker.SingleDatePicker_1 > div,
  .DateInput.DateInput_1,
  .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    width: 100%;
  }
`;

const AttachItem = styled.div`
  padding-left: 12px;
  font-size: 13px;
  border-radius: 2px;
  background-color: ${colorsOld.pureWhite};
  border: solid 1px ${colorsOld.grey};
  width: 100%;
  height: 32px;
  padding-top: 9px;
  cursor: pointer;
  margin-top: 9px !important;
  text-align: left;
  display: block;
  > div {
    padding: 10px;
  }
  > svg {
    float: right;
    position: relative;
    top: -2px;
  }
`;

const AttachedItem = styled.div`
  padding-left: 12px;
  font-size: 13px;
  border-radius: 2px;
  background-color: ${colorsOld.pureWhite};
  border: solid 1px ${colorsOld.grey};
  width: 100%;
  min-height: 32px;
  justify-content: flex-start;
  & > div {
    border-radius: 2px;
    background-color: ${colorsOld.aliceBlue};
    padding-right: 25px;
    display: grid;
    grid-template-columns: 27px 1fr 20px;
  }
  button {
    background: ${colorsOld.aliceBlue};
    padding: 0px 5px 0px 8px;
    border: none;
    position: absolute;
    right: 0px;
  }
`;

const AttachedItemMultiple = styled(AttachedItem)`
  display: block;
  max-height: 84px;
  overflow: auto;
  span {
    max-width: 142px;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div {
    display: block;
    max-width: 194px;
    display: flex;
    & > div,
    span {
      display: inline-block;
    }
  }
`;

export { Form, AttachItem, AttachedItem, AttachedItemMultiple };
