// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter
};

const CompletedDate = ({ filter }: Props) => {
  const { completedAt } = filter;

  const lastWeek = `${moment()
    .subtract(7, "days")
    .startOf("day")
    .format("YYYY-MM-DD")}::${moment().startOf("day").format("YYYY-MM-DD")}`;

  const lastMonth = `${moment()
    .startOf("month")
    .format("YYYY-MM-DD")}::${moment().endOf("month").format("YYYY-MM-DD")}`;

  if (completedAt === lastWeek) {
    return <>Last 7 days</>;
  }

  if (completedAt === lastMonth) {
    return <>Last month</>;
  }

  const ranges = (completedAt || "").split("::");

  if (ranges.length >= 2) {
    return (
      <>
        {moment(ranges[0]).format("MMM DD, YYYY")} -
        {moment(ranges[ranges.length - 1]).format("MMM DD, YYYY")}
      </>
    );
  }

  return <>Choose date</>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps)(CompletedDate);
