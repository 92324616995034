// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import Items from "./Items";
import { isStatusDisabled } from "src/reducers";
import {
  Options as StyledOptions,
  SubHeading
} from "src/components/chatroom/Status/styles";
import {
  bulkUpdateProcess,
  updateChatroomFromManageView,
  updateNestedRow,
  clearProcessRowSelection
} from "src/actions/workflows";
import { setAttribute } from "src/actions/chatroom";
import Group from "src/components/chatroom/Status/Group";

import type { AppState, RoomId, ColumnId } from "src/types";

type Props = {
  show: boolean,
  roomId: RoomId,
  selected: boolean,
  index: number,
  depth: number,
  parentId: number,
  columnId: ?ColumnId,
  handleClose: Function,
  _setAttribute: Function,
  _setChatroomAttribute: Function,
  _bulkUpdateProcess: Function,
  _updateNestedRow: Function,
  _clearProcessRowSelection: Function,
  disabled: boolean
};

const Single = ({
  show,
  roomId,
  depth,
  parentId,
  index,
  selected,
  columnId,
  handleClose,
  _setAttribute,
  _setChatroomAttribute,
  _bulkUpdateProcess,
  _updateNestedRow,
  _clearProcessRowSelection,
  disabled
}: Props) => {
  const handleStatus = useCallback(
    (status: number) => {
      if (selected) {
        _bulkUpdateProcess({
          attrs: {
            status
          }
        });
      } else {
        if (depth > 0) {
          _updateNestedRow(roomId, { status }, index, parentId);
        } else {
          _setAttribute(
            roomId,
            {
              status
            },
            index,
            columnId
          );
        }
      }
      handleClose();
    },
    [
      index,
      depth,
      selected,
      parentId,
      _updateNestedRow,
      _setAttribute,
      _bulkUpdateProcess,
      handleClose
    ]
  );

  const handleArchive = useCallback(
    (value: boolean) => {
      if (selected) {
        _bulkUpdateProcess({
          attrs: {
            archived: value
          }
        });
        _clearProcessRowSelection();
      } else {
        _setChatroomAttribute(
          roomId,
          {
            archived: value
          },
          location,
          columnId
        );
      }
      handleClose();
    },
    [
      selected,
      _bulkUpdateProcess,
      _setChatroomAttribute,
      _clearProcessRowSelection,
      handleClose
    ]
  );

  if (show) {
    return (
      <StyledOptions>
        <ul>
          <SubHeading>UPDATE STATUS:</SubHeading>
          {disabled ? (
            <Group roomId={roomId} handleStatus={handleStatus} />
          ) : (
            <Items
              roomId={roomId}
              handleStatus={handleStatus}
              handleArchive={handleArchive}
            />
          )}
        </ul>
      </StyledOptions>
    );
  }
  return null;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  disabled: isStatusDisabled(app, props.roomId)
});

export default connect(mapStateToProps, {
  _setAttribute: updateChatroomFromManageView,
  _setChatroomAttribute: setAttribute,
  _bulkUpdateProcess: bulkUpdateProcess,
  _clearProcessRowSelection: clearProcessRowSelection,
  _updateNestedRow: updateNestedRow
})(Single);
