// @flow

import React, { useCallback, useState } from "react";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";

import { useSelector } from "react-redux";
import {
  getConversationModalVisibility,
  getConversationModalId,
  getSelectedAction
} from "src/reducers";

import {
  NewMenu as StyledNewMenu,
  Reply,
  Forward,
  Delete,
  Start,
  Submenu as StyledSubMenu
} from "../styles";

import SubMenu from "../Submenu";
import Email from "./Email";

import Icon from "src/icons";
import { colors } from "src/styles/constants";
import {
  setReplyTo,
  showDeleteOptions,
  showForwardOptions,
  selectMessage
} from "src/actions/chatmenu";
import { focusChatInput } from "src/actions/chatroom";
import { getCurrentRoomId } from "src/reducers";
import useComponentPermission from "src/hooks/useComponentPermission";
import type { MessageID, EmailModalData, UID, RoomId } from "src/types";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  id: string,
  timestamp: Date,
  isOwner: boolean,
  showDelete: Function,
  setReply: Function,
  showForward: Function,
  select: Function,
  selectedMessages: Array<MessageID>,
  selectedAction: string,
  email: ({ from: UID } & EmailModalData) | null,
  userEmail: string,
  isSrw: boolean,
  author?: string,
  addRecipient: Function,
  _focusChatInput: typeof focusChatInput,
  roomId: RoomId
};

const MoreMenu = ({
  id,
  showDelete,
  setReply,
  timestamp,
  isOwner,
  showForward,
  select,
  selectedMessages,
  selectedAction,
  email = null,
  isSrw,
  author = "",
  _focusChatInput,
  roomId
}: Props) => {
  const [subMenu, setSubMenu] = useState(false);

  MoreMenu.handleClickOutside = () => {
    setSubMenu(false);
  };

  const isConversationModalVisible = useSelector(({ app }) =>
    getConversationModalVisibility(app)
  );
  const modalId = useSelector(({ app }) => getConversationModalId(app));

  const initiateForward = useCallback(
    (roomId: string) => {
      if (!selectedMessages.includes(id)) {
        select(id, timestamp, isOwner);
      }
      if (selectedAction !== "FORWARD") {
        showForward(roomId);
      }
    },
    [select, id, timestamp, isOwner, showForward, selectedMessages]
  );

  const initiateDelete = useCallback(
    (roomId: string) => {
      if (!selectedMessages.includes(id)) {
        select(id, timestamp, isOwner);
      }
      if (selectedAction !== "DELETE") {
        showDelete(roomId);
      }
    },
    [select, id, timestamp, isOwner, showDelete, selectedMessages]
  );

  const chatroomCreationEnabled = useComponentPermission(
    componentPermissions.newConversation
  );

  const isReplyEnabled = useComponentPermission(componentPermissions.chatInput);
  const isDeleteEnabled = useComponentPermission(
    componentPermissions.chatDelete
  );

  const handleReply = (id: string) => {
    if (isConversationModalVisible) {
      setReply(id, modalId);
      _focusChatInput(modalId);
    } else {
      setReply(id, roomId);
      _focusChatInput(roomId);
    }
  };

  const handleForward = () => {
    if (isConversationModalVisible) {
      initiateForward(modalId);
    } else {
      initiateForward(roomId);
    }
  };

  const handleDelete = () => {
    if (isConversationModalVisible) {
      initiateDelete(modalId);
    } else {
      initiateDelete(roomId);
    }
  };

  return (
    <StyledNewMenu>
      {author !== "unibot" && isReplyEnabled && (
        <Reply
          onClick={() => handleReply(id)}
          onKeyDown={() => handleReply(id)}
          role="button"
          tabIndex="0"
        >
          <Icon type="reply" color={colors.dullGrey} />
        </Reply>
      )}

      {author !== "unibot" && (
        <Forward
          onClick={handleForward}
          onKeyDown={handleForward}
          role="button"
          tabIndex="0"
        >
          <Icon type="forward" hollow color={colors.dullGrey} />
        </Forward>
      )}

      {isDeleteEnabled && (
        <Delete
          onClick={handleDelete}
          onKeyDown={handleDelete}
          role="button"
          tabIndex="0"
        >
          <Icon type="delete" />
        </Delete>
      )}

      {!isSrw && author !== "unibot" && chatroomCreationEnabled && (
        <Start
          onClick={() => setSubMenu(!subMenu)}
          onKeyDown={() => setSubMenu(!subMenu)}
          role="button"
          tabIndex="0"
        >
          Start {">"}
        </Start>
      )}

      {subMenu && (
        <StyledSubMenu>
          <SubMenu id={id} setSubmenu={setSubMenu} />
        </StyledSubMenu>
      )}

      {!isSrw && email && <Email email={email} id={id} />}
    </StyledNewMenu>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => MoreMenu.handleClickOutside
};

const mapStateToProps = ({ app }, { email, id }: Props) => {
  return {
    selectedMessages: app.chatRooms.selectedMessages,
    selectedAction: getSelectedAction(app),
    isSrw: app.srw.isSingleResponse,
    roomId: getCurrentRoomId(app),
    email,
    id
  };
};

const mapDispatchToProps = {
  showDelete: showDeleteOptions,
  setReply: setReplyTo,
  showForward: showForwardOptions,
  select: selectMessage,
  _focusChatInput: focusChatInput
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(MoreMenu, clickOutsideConfig));
