// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { EditIconWrapper } from "../styles";
import {
  CaretUp,
  Count,
  MultiTable,
  MultiContainer,
  NestedMultiTable,
  StyledMultiField,
  NestedMultiRow,
  MultiPicklistWrapper
} from "./styles";
import useRowExpansion from "src/hooks/useRowExpansion";
import Icon from "src/icons";
import { getFieldWidth } from "src/reducers";
import { checklistFieldSizeMap } from "src/constants/processInstanceColumns";
import type { ColumnId } from "src/types";

type Props = {
  autoNo: string,
  columnId: ColumnId,
  selected: boolean,
  value: any,
  openChecklistEditModal: Function,
  fieldId: number,
  roomId: string,
  rowIndex: number,
  hideEdit?: boolean,
  multiple: boolean,
  embeddedIndex?: number
};

const MultipleSelect = ({
  autoNo,
  columnId,
  value,
  rowIndex,
  openChecklistEditModal,
  fieldId,
  roomId,
  hideEdit,
  embeddedIndex,
  selected
}: Props) => {
  const multipleSelectFieldId =
    columnId && columnId.includes("-") ? columnId : `${fieldId}`;
  const { isRowExpanded } = useRowExpansion(
    multipleSelectFieldId,
    autoNo,
    roomId
  );
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  const [isPicklistExpanded, setIsPicklistExpanded] = useState(
    Array(value.length).fill(false)
  );

  return (
    <MultiContainer
      selected={selected}
      width={fieldWidth}
      borderRequired={columnId.includes("-")}
    >
      <MultiTable borderRequired={true} fullHeight={true}>
        <tbody>
          {Array.from({
            length: isRowExpanded ? Math.max(value.length, 1) : 1
          }).map((_, fieldIndex) => {
            const fieldValue = value[fieldIndex] || [];
            return (
              <tr key={fieldIndex}>
                <MultiContainer width={checklistFieldSizeMap.select}>
                  <NestedMultiTable>
                    <tbody>
                      {Array.from({
                        length: isPicklistExpanded[fieldIndex]
                          ? Math.max(fieldValue.length, 1)
                          : 1
                      }).map((_, nestedIndex) => {
                        const nestedValue = fieldValue[nestedIndex] || " ";
                        return (
                          <NestedMultiRow
                            key={`${nestedIndex}-${fieldIndex}`}
                            bottomBorder={true}
                          >
                            <StyledMultiField maxWidth={fieldWidth}>
                              <MultiPicklistWrapper>
                                {nestedValue}
                              </MultiPicklistWrapper>
                              {!isPicklistExpanded[fieldIndex] &&
                                fieldValue.length > 1 && (
                                  <Count
                                    onClick={() =>
                                      setIsPicklistExpanded(prev => {
                                        const updated = [...prev];
                                        updated[fieldIndex] = true;
                                        return updated;
                                      })
                                    }
                                  >
                                    <> +{fieldValue.length - 1}</>
                                  </Count>
                                )}
                              {nestedIndex === 0 &&
                                isPicklistExpanded[fieldIndex] && (
                                  <CaretUp
                                    onClick={() =>
                                      setIsPicklistExpanded(prev => {
                                        const updated = [...prev];
                                        updated[fieldIndex] = false;
                                        return updated;
                                      })
                                    }
                                  >
                                    <Icon type="caretUp" />
                                  </CaretUp>
                                )}
                              {!hideEdit && (
                                <EditIconWrapper
                                  onClick={e =>
                                    openChecklistEditModal(
                                      e,
                                      rowIndex,
                                      fieldId,
                                      roomId,
                                      fieldValue,
                                      columnId,
                                      embeddedIndex
                                    )
                                  }
                                >
                                  <Icon type="editPencil" />
                                </EditIconWrapper>
                              )}
                            </StyledMultiField>
                          </NestedMultiRow>
                        );
                      })}
                    </tbody>
                  </NestedMultiTable>
                </MultiContainer>
              </tr>
            );
          })}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

export default MultipleSelect;
