// @flow

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import type { Map } from "immutable";
import * as R from "ramda";

import Accordion from "./SubSectionAccordion";

import { Grid, GridItem } from "@chakra-ui/react";
import Field from "./Field";
import NestedField from "./NestedField";
import type { RoomId, AppState, FieldId, UnifizeChatRoom } from "src/types";

import {
  getChecklistFieldDetails,
  getChatRoom,
  getSectionMandatoryFieldCount,
  getChecklistFieldBehavior,
  getFormFieldHidden
} from "src/reducers";

import { breakpointMaxWidths } from "src/components/Dock/DockLayout/utils";
import RowItem from "./WideChecklist/RowItem";

import { openSubSection, closeSubSection } from "src/actions/checklist";
import { behaviorToSettings, behaviors } from "src/conditions";

import type { Layout } from "./WideChecklist/utils";
import type { Breakpoint, ChecklistId } from "src/types";

type Props = {
  details: Map<string, any>,
  roomId: RoomId,
  id: FieldId,
  sectionId: FieldId,
  fields: any,
  currentChatRoom: UnifizeChatRoom,
  isSectionField: boolean,
  formId?: number,
  checklistId?: number,
  hidden: ?boolean,
  hasMandatoryFields: boolean,
  openSubSection: Function,
  closeSubSection: Function,
  layout: Layout,
  breakpoint?: Breakpoint
};

const SubSection = ({
  roomId,
  details,
  id,
  sectionId,
  fields,
  currentChatRoom,
  isSectionField,
  formId,
  checklistId,
  hasMandatoryFields,
  hidden,
  layout,
  breakpoint
}: Props) => {
  const dispatch = useDispatch();

  const settings = details ? details.get("settings") : "{}";

  const [open, setOpen] = React.useState(
    R.includes(currentChatRoom.status, JSON.parse(settings).status || [])
  );

  const label = details ? details.get("label") : "";

  const toggleActive = () => {
    setOpen(prev => !prev);
  };

  useEffect(() => {
    if (!checklistId) {
      return;
    }

    if (open && sectionId) {
      dispatch(
        openSubSection({
          checklistId: String(checklistId),
          id,
          sectionId,
          roomId
        })
      );
    } else {
      dispatch(
        closeSubSection({
          checklistId: String(checklistId),
          id,
          sectionId,
          roomId
        })
      );
    }
  }, [open]);

  if (hidden) {
    return null;
  }
  if (layout && breakpoint) {
    return (
      <Accordion
        title={label}
        isExpanded={open}
        isSectionField={isSectionField}
        toggleActive={toggleActive}
        hasMandatoryFields={hasMandatoryFields}
      >
        <Grid
          templateColumns={`repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`}
          key={id}
          gap={"0"}
          sx={{
            width: "100%",
            padding: "0.5rem 0",
            paddingRight: "0"
          }}
        >
          {layout.map(field => {
            return formId ? (
              <GridItem key={field.id} colSpan={field.w}>
                <NestedField
                  key={field.id}
                  id={field.id}
                  formId={formId}
                  roomId={roomId}
                  checklistId={checklistId}
                />
              </GridItem>
            ) : (
              <>
                {field.startOnNewRow ? (
                  <GridItem
                    key={field.id}
                    colSpan={breakpointMaxWidths[breakpoint]}
                    sx={{
                      width: "100%",
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <RowItem breakpoint={breakpoint} fieldW={field.w}>
                      <Field
                        key={field.id}
                        id={field.id}
                        checklistId={checklistId}
                        roomId={roomId}
                        isSectionField={isSectionField}
                      />
                    </RowItem>
                  </GridItem>
                ) : (
                  <GridItem
                    key={field.id}
                    colSpan={field.w}
                    sx={{
                      width: "100%",
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <Field
                      key={field.id}
                      id={field.id}
                      checklistId={checklistId}
                      roomId={roomId}
                      isSectionField={isSectionField}
                    />
                  </GridItem>
                )}
              </>
            );
          })}
        </Grid>
      </Accordion>
    );
  }
  return (
    <Accordion
      title={label}
      isExpanded={open}
      toggleActive={toggleActive}
      isSectionField={isSectionField}
      hasMandatoryFields={hasMandatoryFields}
    >
      {fields.map(field => (
        <>
          {formId ? (
            <NestedField
              key={field}
              id={field}
              formId={formId}
              roomId={roomId}
              checklistId={checklistId}
            />
          ) : (
            <Field
              key={field}
              id={field}
              checklistId={id}
              roomId={roomId}
              section={field}
              isSectionField={isSectionField}
            />
          )}
        </>
      ))}
    </Accordion>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  {
    id,
    roomId,
    fields,
    formId,
    checklistId
  }: {
    id: FieldId,
    roomId: RoomId,
    fields: Array<FieldId | Object>,
    formId?: number,
    sectionId: FieldId,
    checklistId: ChecklistId
  }
) => {
  const details = getChecklistFieldDetails(app, `${id}`);
  // $FlowFixMe
  const fieldHidden = details?.get("hidden") || false;
  const roomFieldFormId = formId ? `${roomId}-${id}-${formId}` : "";

  const hiddenByConditions = formId
    ? getFormFieldHidden(app, roomFieldFormId)
    : getChecklistFieldBehavior(app, roomId, id)?.current ===
      behaviorToSettings[behaviors.hideField];

  return {
    details,
    currentChatRoom: getChatRoom(app, `${roomId}`),
    hidden: hiddenByConditions || fieldHidden,
    hasMandatoryFields: !!getSectionMandatoryFieldCount(app, {
      sectionId: id,
      fields,
      checklistId,
      roomId
    })
  };
};

export default connect(mapStateToProps)(SubSection);
