// @flow

import styled from "@emotion/styled";
import type { FlexProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";
import { Secondary as StyledSecondary } from "src/styles/buttons";

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.brightLight};
  margin-bottom: 16px;
  border: 1px solid ${colors.sky};
  padding: 0 4px 8px 4px;
  height: 4rem;

  h4 {
    margin-top: 4px;
  }
`;

export const popupHeader = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
};

export const closeIcon = {
  background: "none",
  paddingTop: "0.3em"
};

export const Content = styled.div`
  z-index: 999;
  position: absolute;
  top: 25%;
  left: 37%;
  background-color: ${colors.brightLight};
  min-width: 29.58em;
`;

export const PrimaryButton = styled.span`
  outline: none;
  background-color: ${colors.brightLight};
  border: 1px solid ${colors.close};
  padding: ${spacing.space_m} ${spacing.space_l};
  color: ${colors.close};
  font-weight: ${typography.semiBold};
  border-radius: ${spacing.space_xs};
  cursor: pointer;
`;

export const Secondary = styled(StyledSecondary)`
  background-color: ${colors.close};
  border: none;
  color: ${colors.brightLight};
  font-weight: ${typography.semiBold};
  font-size: 0.75rem;
  box-shadow: none;
`;

export const DeleteTextContainer: FlexProps = {
  "> span": {
    fontSize: "0.75rem"
  }
};
