// @flow

import { useSelector } from "react-redux";
import React from "react";

import { getAllUserIds } from "src/reducers";
import Select from "src/components/Select";
import Username from "src/components/user/NameOrEmailText";
import User from "src/containers/user.old";

import { Flex, FormLabel } from "@chakra-ui/react";
import { styles } from "./styles";

type Props = {
  handleValueChange: Function,
  value: Array<number>
};

const UserDropdown = ({ handleValueChange, value }: Props) => {
  const users = useSelector(({ app }) => getAllUserIds(app)).toArray();

  return (
    <Flex {...styles.valueSelectContainer}>
      <FormLabel {...styles.label}>Value</FormLabel>
      <Select
        value={value}
        placeholder="Value"
        onChange={handleValueChange}
        multiple
      >
        {users.map(user => (
          <Flex {...styles.userItemContainer} key={user} value={user}>
            <User type="profileImage" uid={user} />
            <Username uid={user} />
          </Flex>
        ))}
      </Select>
    </Flex>
  );
};

export default UserDropdown;
