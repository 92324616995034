// @flow

import React from "react";
import { connect } from "react-redux";

import * as R from "ramda";
import AddConversation from "./AddConversation";
import Loader from "./Loader";

import type { AppState, RoomId, CreatingConversation } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: number,
  settings: any,
  newRoom: boolean,
  dropdown: boolean,
  creatingConversation: CreatingConversation,
  handleDropdown: Function,
  disabled: boolean,
  isMandatory: ?boolean,
  updating: boolean
};

const NewMultiSelectConversation = ({
  fieldId,
  roomId,
  settings,
  creatingConversation,
  handleDropdown,
  newRoom,
  dropdown,
  disabled,
  isMandatory,
  updating
}: Props) => {
  if (newRoom || dropdown) return null;

  if (
    updating ||
    (!R.isEmpty(creatingConversation) &&
      creatingConversation.fieldId === fieldId &&
      creatingConversation.roomId === roomId)
  ) {
    return <Loader />;
  }

  return (
    <>
      {!newRoom && !dropdown && (
        <AddConversation
          settings={settings}
          handleDropdown={handleDropdown}
          disabled={disabled}
          isMandatory={isMandatory}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  creatingConversation: app.checklist.creatingConversation
});

export default connect(mapStateToProps)(NewMultiSelectConversation);
