// @flow

import React from "react";

const DefaultQuickFilter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path fill="#888" d="M14 22h4v-2h-4v2zM7 10v2h18v-2H7zm3 7h12v-2H10v2z" />
  </svg>
);

export default DefaultQuickFilter;
