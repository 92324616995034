// @flow

import React from "react";

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <path fill="#D8D8D8" d="M14.531 14.531L11.72 11.72" />
      <circle cx="6.094" cy="6.094" r="5.625" />
    </g>
  </svg>
);

export default Search;
