// @flow

import React from "react";

import Icon from "src/icons";

type Props = {
  order: string
};

const SortIcon = ({ order }: Props) => {
  switch (order) {
    case "asc":
      return <Icon type="sortUp" />;
    case "desc":
      return <Icon type="sortDown" />;
    default:
      return <Icon type="sort" />;
  }
};
export default SortIcon;
