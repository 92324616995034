// @flow

import styled from "styled-components";

import { Box } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const SortOptions = styled(Box)`
  position: absolute;
  width: 273px;
  padding: 2rem;
  z-index: 40;
  top: 11px;
  left: -108px;
`;

const SortHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space_m};
`;

const SortableList = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin: 0px;
  max-height: 15em;
  overflow: auto;
  li {
    margin-top: ${spacing.space_m};
  }
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  > svg {
    cursor: pointer;
  }
`;

export const Heading = styled.h4`
  color: ${colors.primary};
  margin: 0px;
`;

export const SubHeading = styled.div`
  color: ${colors.grey5};
  font-size: 1rem;
`;

export const Tag = styled.span`
  background-color: ${colors.grey12};
  padding: ${spacing.space_inset_squish_s};
  padding-right: 2em;
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-right: ${spacing.space_l};
  > span {
    color: ${colors.active};
    padding-right: ${spacing.space_s};
  }
  svg {
    position: absolute;
    top: 5px;
    right: -1px;
    height: 1.8em;
    width: 1.8em;
  }
`;

export { SortHeader, SortOptions, SortableList, ListItem };
