// @flow

import * as atypes from "src/constants/actionTypes";

export const createDashboard = (title: string) => ({
  type: atypes.CREATE_DASHBOARD_REQUEST,
  payload: {
    title
  }
});

export const setDashboard = (id: number) => ({
  type: atypes.SET_DASHBOARD_REQUEST,
  payload: {
    id
  }
});

export const editDashboardTitle = (payload: { id: number, title: string }) => ({
  type: atypes.EDIT_DASHBOARD_TITLE_REQUEST,
  payload
});

export const addChartToDashboard = (payload: {
  dashboard: number,
  chart: number,
  seqNo: number
}) => ({
  type: atypes.ADD_CHART_TO_DASHBOARD_REQUEST,
  payload
});

export const deleteDashboard = (dashboard: number) => ({
  type: atypes.DELETE_DASHBOARD_REQUEST,
  payload: { dashboard }
});

export const deleteChartFromDashboard = (payload: {
  dashboard: number,
  chart: number
}) => ({
  type: atypes.DELETE_CHART_FROM_DASHBOARD_REQUEST,
  payload
});
