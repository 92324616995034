// @flow

import React from "react";
import { createIcon } from "@chakra-ui/icons";

const Bulb = createIcon({
  displayName: "BulbIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="none"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.5 4.5v2m-5.657.343l1.414 1.414m9.9-1.414l-1.414 1.414M17.5 12.5h1m-14 0h1m10 0a4 4 0 1 0-6 3.445V19.5h4v-3.555a3.982 3.982 0 0 0 2-3.445z"
    />
  )
});

export default Bulb;
