// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";
type Props = {
  timeline?: string
};

const DateActive = ({ timeline, ...restProps }: Props) => {
  let fill = "";

  switch (timeline) {
    case "future":
      fill = colors.active;
      break;
    case "past":
      fill = colors.danger;
      break;
    case "present":
      fill = colors.success;
      break;
    default:
      fill = colors.active;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <g clipPath="url(#clip0_1744_13184)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.888672 3.30556C0.888672 2.52334 1.52601 1.88867 2.31089 1.88867H13.6887C14.4736 1.88867 15.1109 2.52334 15.1109 3.30556V13.694C15.1109 14.4762 14.4736 15.1109 13.6887 15.1109H2.31089C1.52601 15.1109 0.888672 14.4762 0.888672 13.694V3.30556Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.20807 0C4.4694 0 4.68184 0.211556 4.68184 0.472V2.36089C4.68184 2.48666 4.63188 2.60728 4.54295 2.69621C4.45401 2.78515 4.33339 2.83511 4.20762 2.83511C4.08185 2.83511 3.96123 2.78515 3.87229 2.69621C3.78336 2.60728 3.7334 2.48666 3.7334 2.36089V0.472C3.7334 0.211556 3.94673 0 4.20807 0ZM11.793 0C12.0543 0 12.2667 0.211556 12.2667 0.472V2.36089C12.2667 2.48666 12.2168 2.60728 12.1278 2.69621C12.0389 2.78515 11.9183 2.83511 11.7925 2.83511C11.6667 2.83511 11.5461 2.78515 11.4572 2.69621C11.3682 2.60728 11.3183 2.48666 11.3183 2.36089V0.472C11.3183 0.211556 11.5316 0 11.793 0ZM8.00007 0C8.26229 0 8.47384 0.211556 8.47384 0.472V2.36089C8.47384 2.48654 8.42393 2.60705 8.33508 2.6959C8.24623 2.78475 8.12572 2.83467 8.00007 2.83467C7.87441 2.83467 7.7539 2.78475 7.66505 2.6959C7.5762 2.60705 7.52629 2.48654 7.52629 2.36089V0.472C7.52629 0.211556 7.73873 0 8.00007 0Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66675 4.8892C2.66675 4.64358 2.82586 4.44475 3.02159 4.44475H12.9639C13.0116 4.44215 13.0593 4.45171 13.1041 4.47285C13.1488 4.494 13.1897 4.52628 13.2242 4.56777C13.2587 4.60926 13.2862 4.65909 13.305 4.71426C13.3237 4.76943 13.3334 4.82879 13.3334 4.88878C13.3334 4.94877 13.3237 5.00813 13.305 5.0633C13.2862 5.11847 13.2587 5.1683 13.2242 5.20979C13.1897 5.25128 13.1488 5.28356 13.1041 5.30471C13.0593 5.32585 13.0116 5.33541 12.9639 5.33281H3.02159C2.97508 5.33292 2.92901 5.32153 2.886 5.2993C2.843 5.27706 2.80391 5.24442 2.77096 5.20322C2.738 5.16203 2.71184 5.1131 2.69396 5.05922C2.67608 5.00534 2.66684 4.94756 2.66675 4.8892Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_13184">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DateActive;
