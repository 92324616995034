// @flow

import { StackProps, SystemCSSProperties } from "@chakra-ui/react";

export const overallOptionsList: StackProps = {
  alignItems: "start",
  gap: 4,
  mt: 4
};

export const consumptionOptionsList: StackProps = {
  alignItems: "start"
};

export const formLabel: SystemCSSProperties = {
  fontSize: "12px",
  lineHeight: "16px",
  color: "unifize_grey29",
  mb: 1
};
