// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const Section = styled.div`
  border-bottom: 1px solid ${colors.grey2};
  margin: 0 0.5em;
`;

export const SubHeader = styled.div`
  color: ${colors.secondary};
  padding: 6px 13px;
`;

export const Value = styled.div`
  padding: 0 13px 10px 13px;
  font-size: 12.5px;
`;

export const RefreshWrapper = styled.div`
  width: 100%;
  padding: ${spacing.space_s};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.yellowLight};
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const RefreshMessage = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
`;

export const RefreshButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

// Workaround for adding padding-bottom to work on firefox
export const ChecklistWrapper = styled.div`
  height: 100%;
  display: block;
  ${props => props.isChecklistOpen && ` padding-bottom: 10em`};
`;

export const NoFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 4;
`;

export const Link = {
  color: "unifize_blue",
  width: "100%",
  fontSize: "14px",
  textAlign: "center",
  textDecorationLine: "underline",
  lineHeight: "20px"
};
