// @flow

import React from "react";

import EmailReply from "./EmailReply";
import FileReply from "src/containers/chatroom/FileReply";
import ChecklistFields from "src/components/messages/Chat/Notification/ChecklistFields/Content";
import * as notifications from "src/constants/notifications";

type Props = {
  message: any
};

const Notification = ({ message }: Props) => {
  const { action, data, timestamp } = message;
  switch (action) {
    case notifications.FILE_ADD:
      return <FileReply message={message} />;
    case notifications.EMAIL_SENT:
      return <EmailReply message={message} />;
    case notifications.CHECKLIST_FIELD_FILL:
      return <ChecklistFields data={data} timestamp={timestamp} />;
    default:
      return <div>{action}</div>;
  }
};

export default Notification;
