// @flow

import React from "react";

type Props = {};

const CaretRightFilled = ({ ...restProps }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <rect width="24" height="24" rx="12" fill="#DEEAEE" />
    <path
      d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z"
      fill="#919EAB"
    />
  </svg>
);

export default CaretRightFilled;
