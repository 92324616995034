// @flow

import * as R from "ramda";
import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { arrayMove } from "react-sortable-hoc";

import {
  Settings as StyledConversation,
  Label,
  FormGroup,
  Sort as StyledSort
} from "../styles";
import FieldName from "src/containers/checklist/FieldName";
import SelectConversationType from "./SelectConversationType";
import Workflow from "./Workflow";
import { InlineFormGroup, Section } from "./styles";
import Checkbox from "src/components/Checkbox.old";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import Radio from "src/components/Radio";
import {
  getPrincipalChecklist,
  searchPrincipalChecklistField
} from "src/actions/workflows";
import type { ConversationFieldSettings, AppState } from "src/types";

type Props = {
  type: string,
  fieldName: string,
  settings: ConversationFieldSettings,
  saveSettings: Function,
  _getPrincipalChecklist: Function,
  fieldsOfEmbeddedProcess: Array<number>,
  searchPrincipalChecklistField: Function
};

const Conversation = ({
  settings,
  _getPrincipalChecklist,
  saveSettings,
  fieldsOfEmbeddedProcess,
  searchPrincipalChecklistField
}: Props) => {
  const [type, setType] = useState("group");
  const [workflow, setWorkflow] = useState(null);
  const [title, setTitle] = useState("");
  const [fields, setFields] = useState([]);
  const [multiple, setMultiple] = useState(false);
  const [showFieldNames, setShowFieldNames] = useState(false);
  const [showMetaData, setShowMetaData] = useState(false);
  const [autoFillRelated, setAutoFillRelated] = useState(false);
  const [sortBy, setSortBy] = useState("added");
  const [select, setSelect] = useState(false);
  const [create, setCreate] = useState(false);
  const [selectExistingBy, setSelectExistingBy] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [disableTitleHyperlink, setDisableTitleHyperlink] = useState(false);

  useEffect(() => {
    if (settings) {
      setType(settings.type || "group");
      setWorkflow(settings.workflow || null);
      setTitle(settings.title || "");
      setMultiple(settings.multiple || false);
      setShowFieldNames(settings.showFieldNames || false);
      setShowMetaData(settings.showMetaData || false);
      setFields(settings.fields || []);
      setAutoFillRelated(settings.autoFillRelated || false);
      setSortBy(settings.sortBy || "added");
      setSelect(settings.select === undefined ? true : settings.select);
      setCreate(settings.create === undefined ? true : settings.create);
      setSelectExistingBy(settings.selectExistingBy || "");
      setDisableTitleHyperlink(
        R.has("disableTitleHyperlink", settings)
          ? settings.disableTitleHyperlink
          : false
      );

      if (settings.workflow) {
        _getPrincipalChecklist(settings.workflow);
      }
      setShowArchived(settings?.showArchived ?? false);
    }
  }, [
    setSelect,
    setCreate,
    setSortBy,
    setAutoFillRelated,
    setType,
    setWorkflow,
    setTitle,
    setMultiple,
    setFields,
    setShowMetaData,
    setShowFieldNames,
    _getPrincipalChecklist,
    setShowArchived,
    setDisableTitleHyperlink
  ]);

  // Save new settings
  useEffect(() => {
    saveSettings({
      type,
      workflow,
      title,
      fields,
      multiple,
      showFieldNames,
      showMetaData,
      autoFillRelated,
      sortBy,
      select,
      create,
      selectExistingBy,
      showArchived,
      disableTitleHyperlink
    });
  }, [
    type,
    workflow,
    title,
    fields,
    multiple,
    showFieldNames,
    showMetaData,
    autoFillRelated,
    sortBy,
    select,
    create,
    selectExistingBy,
    showArchived,
    disableTitleHyperlink
  ]);

  const handleType = useCallback(
    (event: any) => {
      const { value } = event.target;
      setType(value);
    },
    [setType]
  );

  const handleTemplate = useCallback(
    (newWorkflow: Object) => {
      if (newWorkflow) {
        setWorkflow(newWorkflow.id);
        _getPrincipalChecklist(newWorkflow.id);
      } else {
        setWorkflow(null);
        setFields([]);
      }
    },
    [setWorkflow, setFields, _getPrincipalChecklist]
  );

  const addField = useCallback(() => {
    // $FlowFixMe
    setFields([...fields, null]);
  }, [fields, setFields]);

  const handleField = useCallback(
    ({ fieldId, index }: { fieldId: ?number, index: number }) => {
      if (fieldId) {
        setFields(prevFields => R.update(index, fieldId, prevFields));
      }
    },
    [fields, setFields]
  );

  const removeField = useCallback(
    (field: number) => {
      if (field) {
        setFields(R.reject(R.equals(field), fields));
      }
    },
    [fields, setFields]
  );

  const onSortFields = ({ oldIndex, newIndex }) => {
    setFields(prevFields => arrayMove(prevFields, oldIndex, newIndex));
  };

  const handleSort = useCallback(
    (newSort: string) => {
      setSortBy(newSort);
    },
    [setSortBy]
  );

  const handleCreate = useCallback(() => {
    setCreate(true);
    setSelect(false);
  }, [setCreate, setSelect]);

  const handleSelect = useCallback(() => {
    setSelect(true);
    setCreate(false);
  }, [setSelect, setCreate]);

  const handleSelectAndCreate = useCallback(() => {
    setCreate(true);
    setSelect(true);
  }, [setCreate, setSelect]);

  return (
    <StyledConversation>
      <FormGroup>
        <Label>Type</Label>
        <SelectConversationType value={type} handleChange={handleType} />
      </FormGroup>

      {type === "workflow" && (
        <Workflow
          workflow={workflow}
          handleTemplate={handleTemplate}
          addField={addField}
          fields={fields}
          handleField={handleField}
          removeField={removeField}
          onSortFields={onSortFields}
        />
      )}

      <Section>
        <InlineFormGroup title="Only allow creating new conversations">
          <Radio
            id="createConversation"
            checked={create && !select}
            handleChange={() => handleCreate()}
          />
          <span>Only allow creating new conversations</span>
        </InlineFormGroup>

        <InlineFormGroup title="Only allow selecting existing conversations">
          <Radio
            id="selectConversation"
            checked={!create && select}
            handleChange={() => handleSelect()}
          />
          <span>Only allow selecting existing conversations</span>
        </InlineFormGroup>

        <InlineFormGroup title="Allow both selecting and creating">
          <Radio
            id="selectAndCreateConversation"
            checked={create && select}
            handleChange={() => handleSelectAndCreate()}
          />
          <span>Allow both selecting and creating</span>
        </InlineFormGroup>
      </Section>

      <InlineFormGroup>
        <Checkbox
          handleChange={() => setMultiple(!multiple)}
          checked={multiple}
          id="multiple"
        />
        <span>Allow multiple</span>
      </InlineFormGroup>
      <InlineFormGroup>
        <Checkbox
          checked={showArchived}
          handleChange={e => setShowArchived(e.target.checked)}
          id="showArchived"
        >
          Allow archived conversations
        </Checkbox>
      </InlineFormGroup>
      <InlineFormGroup>
        <Checkbox
          handleChange={() => setShowFieldNames(!showFieldNames)}
          checked={showFieldNames}
          id="showFieldNames"
        />
        <span>Show field names in preview</span>
      </InlineFormGroup>
      <InlineFormGroup>
        <Checkbox
          handleChange={() => setShowMetaData(!showMetaData)}
          checked={showMetaData}
          id="showMetaData"
        />
        <span>Show status, due date</span>
      </InlineFormGroup>

      <InlineFormGroup>
        <Checkbox
          handleChange={() => setAutoFillRelated(!autoFillRelated)}
          checked={autoFillRelated}
          id="autoFillRelated"
        />
        <span>Auto fill related conversation</span>
      </InlineFormGroup>

      <FormGroup>
        <InlineFormGroup>
          <Checkbox
            handleChange={() =>
              setDisableTitleHyperlink(!disableTitleHyperlink)
            }
            checked={disableTitleHyperlink}
            id="disableTitleHyperlink"
          />
          <span>Supress title hyperlink to the record</span>
        </InlineFormGroup>
      </FormGroup>

      {multiple && (
        <StyledSort>
          <strong>SortBy:</strong>
          <div>
            <Radio
              id="sortFilesByDate"
              checked={sortBy === "created"}
              handleChange={() => handleSort("created")}
            />
            <label>Created date</label>
          </div>

          <div>
            <Radio
              id="sortFilesByName"
              checked={sortBy === "added"}
              handleChange={() => handleSort("added")}
            />
            <label>Added Date</label>
          </div>
        </StyledSort>
      )}

      <FormControl>
        <FormLabel>Select existing by</FormLabel>
        <Select
          size="sm"
          placeholder="-"
          w={48}
          value={selectExistingBy}
          onChange={e => setSelectExistingBy(e.target.value)}
          onClick={() => searchPrincipalChecklistField("")}
        >
          <option value="title">Title</option>
          {fieldsOfEmbeddedProcess.map(field => (
            <FieldName id={field} as="option" key={field} />
          ))}
        </Select>
      </FormControl>
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    fieldsOfEmbeddedProcess: app.workflow.principalChecklistSearchResult
  };
};

export default connect(mapStateToProps, {
  _getPrincipalChecklist: getPrincipalChecklist,
  searchPrincipalChecklistField: searchPrincipalChecklistField
})(Conversation);
