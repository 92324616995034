// @flow

import * as easyFetch from "src/utils/fetch";

/**
 * API to fetch all available integrations
 * @return {Array} list of app integrations
 */
export const getIntegrations = async () => easyFetch.get("/integration");

/**
 * API to disconnect an active app integration
 */
export const removeIntegration = async (id: number) =>
  easyFetch._delete(`/integration/${id}`);

/**
 * API to update status of an app integration (enable / disable)
 * @param {number} id - id of the app integration
 * @param {boolean} status - currently enabled or not
 * @return {Object} Updated app data
 */
export const editIntegration = async (id: number, status: boolean) =>
  easyFetch.patch(`/integration/${id}`, {
    body: { disabled: status }
  });
