// @flow

import styled from "@emotion/styled";

import { iconSize } from "src/styles/constants/size";
import { SquareBoxMedium } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as oldSpacing from "src/styles/constants/spacing.old";

const Date = styled.div`
  position: relative;
  width: 100%;
  height: ${oldSpacing.spacing4};
  border: 1px solid ${colors.grey4};
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? colors.primary : colors.secondary)};
  > div:last-child {
    padding: 4px;
    width: 100%;
  }
`;

const DateSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    > div > svg {
      visibility: visible;
    }
  }

  > div {
    height: 90%;
    > svg {
      position: relative;
      left: 6px;
      top: 1px;
      visibility: hidden;
    }
  }
  > svg {
    height: ${iconSize.space_m};
    width: ${iconSize.space_m};
    position: relative;
    top: 2px;
  }
`;

const CloseIcon = styled.img`
  margin-left: ${spacing.space_default};
`;

const Calendar = styled(SquareBoxMedium)`
  position: absolute;
  top: ${props => (props.positionUp ? "initial" : "100%")};
  bottom: ${props => (props.positionUp ? "calc(100% + 35px)" : "initial")};
  right: 0px;
  font-size: 10px;
  width: 256px;
  padding: ${oldSpacing.spacing1} ${oldSpacing.spacing2};
  cursor: default;
  z-index: 5;
  button {
    background: ${colors.brightLight};
    border: none;
    outline: none;
    user-select: none;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow {
    font-size: 24px;
    font-weight: 200;
  }

  .react-calendar__navigation__label {
    font-size: 13px;
    font-weight: 300;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${colors.ternary} !important;
    font-family: Lato;
  }

  .react-calendar__month-view__days__day {
    font-size: 13px;
    color: ${colors.primary};
  }

  .react-calendar__tile {
    border: 2px solid ${colors.brightLight};
    height: 31.7px;
    width: 31.7px;
    padding: 0px;
    text-align: center;
    border-radius: 2px;
    :hover,
    :focus {
      border: 2px solid ${colors.grey2};
      background: ${colors.grey2};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    height: 31.7px;
    width: 31.7px;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: ${colors.grey5};

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile--active {
    border: 2px solid ${colors.active} !important;
    color: ${colors.brightLight} !important;
    background: ${colors.active} !important;
    border-radius: ${radius.small};
  }

  .react-calendar__century-view__decades__decade {
    font-size: 9px;
  }

  .react-calendar__month-view__weekdays {
    padding-top: ${spacing.space_m};
  }

  .react-calendar__navigation__label {
    color: ${colors.secondary};
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:hover {
    border-radius: ${radius.small};
    border: 2px solid ${colors.active};
  }
`;

export const ButtonActive = styled.button`
  padding: 0;
  cursor: pointer;
  color: ${colors.active};
`;

export const ButtonPrimary = styled(ButtonActive)`
  color: ${colors.primary};
  margin-right: ${oldSpacing.spacing2};
`;

export const ButtonWarning = styled(ButtonActive)`
  color: ${colors.danger};
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacing.space_l};
  font-family: Lato;
  font-size: 13px;
  cursor: default;
  > div {
    display: flex;
    justify-content: flex-end;
  }
`;

const Selected = styled.div`
  display: inline-flex;
  flex-direction: row;
  background-color: ${colors.grey0};
  padding: ${spacing.space_inset_squish_xs};
`;

const SelectedDate = styled.div`
  color: ${colors.primary};
`;

export {
  Date,
  Calendar,
  Footer,
  Selected,
  DateSelected,
  CloseIcon,
  SelectedDate
};
