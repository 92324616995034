// @flow

import { connect } from "react-redux";

import Title from "src/components/chatroom/Name/Title";
import { openConversationModal } from "src/actions/chatroom";
import {
  getChatroomTitle,
  getCanceled,
  getChatroomAddress,
  getTemplateId,
  getChatroomType
} from "src/reducers";

import type { AppState, RoomId } from "src/types";

const mapStateToProps = (
  { app }: { app: AppState },
  props: { id: RoomId }
) => ({
  type: getChatroomType(app, `${props.id}`),
  title: getChatroomTitle(app, `${props.id}`),
  canceled: getCanceled(app, `${props.id}`),
  address: getChatroomAddress(app, `${props.id}`),
  templateId: getTemplateId(app, props.id)
});

export default connect(mapStateToProps, { openConversationModal })(Title);
