// @flow

import React from "react";
import { connect } from "react-redux";

import { Text } from "@chakra-ui/react";
import Workflow from "./Workflow";
import type { AppState } from "src/types";

type Props = {
  emptySearchField: Function,
  searchedQuickFilterWorkflows: Array<number>,
  hideConversation: Function,
  conversationTypes: Array<number>
};

const QuickFilteredList = ({
  emptySearchField,
  searchedQuickFilterWorkflows,
  hideConversation,
  conversationTypes
}: Props) => {
  return (
    <div>
      {conversationTypes?.length === 0 ? null : (
        <Text
          fontSize="xs"
          color="gray.500"
          mb="1"
          pl="4"
          letterSpacing="1.5px"
        >
          QUICK FILTERED LISTS
        </Text>
      )}

      {(searchedQuickFilterWorkflows || []).map(workflowId => (
        <Workflow
          workflowId={workflowId}
          emptySearchField={emptySearchField}
          key={workflowId}
          hideConversation={hideConversation}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  conversationTypes: app.header.searchedConversationTypes,
  customPinned: app.chatRooms.filters.saved,
  searchedPinned: app.header.searchedPinnedLists,
  view: app.view,
  searchedQuickFilterWorkflows:
    app.chatRooms.filters.searchedQuickFilterWorkflows
});

export default connect(mapStateToProps)(QuickFilteredList);
