// @flow

import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useRef } from "react";
import Switch from "react-switch";
import { MentionsInput, Mention } from "react-mentions";

import {
  Reminder as StyledReminder,
  Days,
  Row,
  Message,
  IconButton
} from "./styles";
import ReminderTypeSelect from "./ReminderTypeSelect";
import * as colors from "src/styles/constants/colors";
import {
  setWorkflowBuilderReminder,
  removeWorkflowReminder
} from "src/actions/workflows";
import { getProcessBuilderMentions } from "src/reducers";
import Icon from "src/icons";
import renderSuggestion from "src/components/chatroom/Input/RenderSuggestion";

import type { AppState, Reminder as ReminderType } from "src/types";

type Props = {
  index: number,
  value: ReminderType
};

const Reminder = ({ index, value }: Props) => {
  const dispatch = useDispatch();
  const users = useSelector(({ app }: { app: AppState }) =>
    getProcessBuilderMentions(app)
  );
  const inputRef = useRef(null);

  const allowDisable = value.allowDisable || false;
  const message = value.message || "";
  const remindIn = value.remindIn;
  const reminderTypeId = value.reminderTypeId || 1;

  const handleMessage = useCallback(
    (event: any) => {
      dispatch(
        setWorkflowBuilderReminder(index, {
          message: event.target.value || ""
        })
      );
    },
    [index, dispatch]
  );

  const handleDays = useCallback(
    (event: any) => {
      const { value } = event.target;
      // Prevent entering value less then zero
      const newValue = value === "" ? value : value < 0 ? 0 : value;

      dispatch(
        setWorkflowBuilderReminder(index, {
          remindIn: newValue ? parseInt(newValue || 0, 10) : newValue
        })
      );
    },
    [index, setWorkflowBuilderReminder, dispatch]
  );

  const handleReminders = useCallback(
    (newValue: string) => {
      dispatch(
        setWorkflowBuilderReminder(index, {
          reminderTypeId: newValue ? parseInt(newValue || 0, 10) : newValue
        })
      );
    },
    [index, dispatch]
  );

  const handleDisable = useCallback(() => {
    dispatch(
      setWorkflowBuilderReminder(index, {
        allowDisable: !allowDisable
      })
    );
  }, [index, dispatch, setWorkflowBuilderReminder, allowDisable]);

  const removeReminder = useCallback(() => {
    dispatch(removeWorkflowReminder(index || 0));
  }, [index, dispatch, removeWorkflowReminder]);

  const handleFocus = useCallback(() => {
    if (inputRef && inputRef.current) {
      const element = inputRef.current;
      element.focus();
    }
  }, [inputRef]);

  return (
    <StyledReminder>
      Auto remind in
      <Days type="number" value={remindIn} onChange={handleDays} min="0" />
      days
      <ReminderTypeSelect
        value={reminderTypeId}
        handleChange={handleReminders}
      />
      <Row>
        Allow participants to disable
        <Switch
          onChange={handleDisable}
          uncheckedIcon={false}
          checkedIcon={false}
          checked={allowDisable}
          onColor={colors.checked}
          offColor={colors.grey6}
          height={18}
          width={31}
        />
      </Row>
      <Row>Reminder message</Row>
      <Message onClick={handleFocus}>
        <MentionsInput
          value={message}
          onChange={handleMessage}
          placeholder="Type reminder message here..."
          allowSuggestionsAboveCursor
          inputRef={inputRef}
        >
          <Mention
            trigger="@"
            data={users}
            className="mention-suggestion"
            appendSpaceOnAdd
            renderSuggestion={renderSuggestion}
            markup={`@[__display__]<@__id__>`}
          />
        </MentionsInput>
      </Message>
      <IconButton type="button" onClick={removeReminder}>
        <Icon type="deleteTrash" />
      </IconButton>
    </StyledReminder>
  );
};

export default Reminder;
