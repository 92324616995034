// @flow

import React from "react";
import * as R from "ramda";

import { getFileSize } from "src/utils/file";

import type { UnifizeFile, UnifizeFileVersion } from "src/types";

type Props = {
  file: UnifizeFile
};

const Size = ({ file }: Props) => {
  const getLatestVersion = (version: Array<UnifizeFileVersion>) => {
    if (version) {
      const latest = R.last(version);
      if (latest) {
        return latest;
      }
      return {};
    }
    return {};
  };

  if (file) {
    return (
      <span>
        {getFileSize(parseInt(getLatestVersion(file.versions).size || 0, 10))}
      </span>
    );
  }

  return <></>;
};

export default Size;
