// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import SortableItem from "./SortableItem";
import * as R from "ramda";
import { SortableList as StyledSortableList } from "./styles";

type Props = {
  items: Array<{ key: string, active: boolean }>,
  handleCheckbox: Function,
  fixedColumns?: Array<string>
};

const SortableList = SortableContainer(
  ({ items, handleCheckbox, fixedColumns = [] }: Props) => {
    return (
      <StyledSortableList>
        {items.map((column, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              column={column}
              handleCheckbox={handleCheckbox}
              isReadOnly={R.includes(column.key, fixedColumns)}
            />
          );
        })}
      </StyledSortableList>
    );
  }
);

export default SortableList;
