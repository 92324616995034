// @flow

import { connect } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";
import moment from "moment";

import { Filter as StyledFilter, ListItem, SubHeading } from "../styles";
import Item from "./Item";
import { getWorkflowInstanceFilter } from "src/reducers";
import { setForm } from "src/actions/forms";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  parentRef: any,
  column: string,
  filter: WorkflowInstanceFilter,
  _setForm: Function,
  handleClose: Function
};

const getValue = (type: string, newValue: ?string) => {
  if (newValue === null || newValue === "Inf") {
    return null;
  }

  if (type === "low") {
    return moment(newValue).subtract(1, "days");
  }

  return moment(newValue).add(1, "days");
};

const Date = ({ parentRef, column, filter, _setForm, handleClose }: Props) => {
  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = useCallback(
    (ascending: boolean) => {
      if (ascending) {
        const newColumn = R.uniq([
          `${column}:asc`,
          ...R.reject(R.equals(`${column}:desc`), filter.sort || [])
        ]);

        _setForm({
          query: {
            ...filter,
            sort: newColumn,
            page: 1
          }
        });
      } else {
        const newColumn = R.uniq([
          `${column}:desc`,
          ...R.reject(R.equals(`${column}:asc`), filter.sort || [])
        ]);

        _setForm({
          query: {
            ...filter,
            sort: newColumn,
            page: 1
          }
        });
      }
    },
    [filter, _setForm]
  );

  const getLabel = useCallback(
    (type: string) => {
      switch (column) {
        case "createdAt":
          return type === "after" ? "Created After" : "Created Before";
        case "updatedAt":
          return type === "after" ? "Updated After" : "Updated Before";
        case "completedAt":
          return type === "after" ? "Completed After" : "Completed Before";
        case "dueDate":
          return type === "after" ? "Due After" : "Due Before";
        default:
          return "";
      }
    },
    [column]
  );

  const value = filter[column];
  const lowVal = value ? R.head(R.head(value).split("::")) : null;
  const highVal = value ? R.last(R.head(value).split("::")) : null;

  const handleLowVal = useCallback(
    (newDate: ?string) => {
      _setForm({
        query: {
          ...filter,
          [column]: `${
            newDate
              ? moment(newDate)
                  .startOf("day")
                  .add(1, "days")
                  .format("YYYY-MM-DD")
              : "Inf"
          }::${highVal || "Inf"}`,
          page: 1
        }
      });
    },
    [filter, _setForm]
  );

  const handleHighVal = useCallback(
    (newDate: ?string) => {
      _setForm({
        query: {
          ...filter,
          [column]: `${lowVal || "Inf"}::${
            newDate
              ? moment(newDate)
                  .startOf("day")
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")
              : "Inf"
          }`,
          page: 1
        }
      });
    },
    [column, filter, _setForm]
  );

  const lowDisplayVal = getValue("low", lowVal);
  const highDisplayVal = getValue("high", highVal);

  return (
    <StyledFilter>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <SubHeading>Filter by</SubHeading>
      <ul>
        <Item
          label={getLabel("after")}
          id={`${column}After`}
          value={lowDisplayVal}
          handleChange={handleLowVal}
        />
        <Item
          label={getLabel("before")}
          id={`${column}Before`}
          value={highDisplayVal}
          handleChange={handleHighVal}
        />
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps, {
  _setForm: setForm
})(Date);
