// @flow

import React, { useState } from "react";

import {
  Table as StyledTable,
  TableContainer
} from "src/components/Manage/Reports/Templates/Table/styles";
import Header from "./Header";
import Body from "./Body";
import FormDeletionModal from "./FormDeletionModal";

const Table = () => {
  const [formDeletionModal, setFormDeletionModal] = useState<{
    isOpen: boolean,
    formId?: number
  }>({ isOpen: false });

  return (
    <>
      <TableContainer>
        <StyledTable cellspacing="0" cellpadding="0">
          <Header />
          <Body setFormDeletionModal={setFormDeletionModal} />
        </StyledTable>
      </TableContainer>
      <FormDeletionModal
        formDeletionModal={formDeletionModal}
        setFormDeletionModal={setFormDeletionModal}
      />
    </>
  );
};

export default Table;
