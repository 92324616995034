// @flow

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

import { Button } from "@chakra-ui/react";

import FormTextInput from "./Form/FormTextInput";
import FormTextarea from "./Form/FormTextarea";
import FormMultiSelect from "./Form/FormMultiSelect";
import GeneratedFields from "./GeneratedFields";

import { isSSOConfigured } from "./utils";
import * as orgSettingsApi from "src/api/orgSettings";
import { saveSSOConfig } from "src/actions/orgSettings";
import { getSSOConfig } from "src/reducers";

import {
  SSOPage,
  SSOHeader,
  SSOHeaderContainer,
  StyledForm,
  FieldContainer,
  Label,
  ButtonRow
} from "./styles";
import * as styles from "./styles";

import type {
  SSOPutResponse,
  SSOGetResponse,
  SSOPatchResponse,
  SSOConfig as SSOConfiguration
} from "src/types";

const SSOConfig = () => {
  const certificatePlaceholder = "Add certificate";

  const certificateHelperMessage =
    "Must start with ‘-----BEGIN CERTIFICATE-----’ and end with ‘-----END CERTIFICATE-----’";

  const dispatch = useDispatch();

  // Will be used later, when composite permissions are on production
  // const userPermissions: UserPermissions = useSelector(({ app }) =>
  //   getUserPermissions(app)
  // ) || { componentPermissions: [], resourcePermissions: [] };

  // const isSavingConfigAllowed = !!userPermissions.resourcePermissions.find(
  //   permissionId =>
  //     permissionId === `119-${verb.put}` || permissionId === `119-${verb.patch}`
  // );

  const existingSSOConfig: SSOConfiguration = useSelector(({ app }) =>
    getSSOConfig(app)
  );

  const entityRef = useRef<null | HTMLInputElement>(null);
  const ssoUrlRef = useRef<null | HTMLInputElement>(null);
  const certificateRef = useRef<null | HTMLTextAreaElement>(null);

  const [entity, setEntity] = useState<string>(
    existingSSOConfig.entityId ?? ""
  );
  const [ssoUrl, setSsoUrl] = useState<string>(existingSSOConfig.ssoUrl ?? "");
  const [certificate, setCertificate] = useState<string>(
    existingSSOConfig.certificate
  );
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const [selectedDomains, setSelectedDomains] = useState<Array<string>>(
    existingSSOConfig.domains ?? []
  );

  // Will be used later - with the disable toggle button
  const [areFieldsFilled, setAreFieldsFilled] = useState<boolean>(false);

  // Will be used later - with the disable wizard
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);

  useEffect(() => {
    orgSettingsApi.getSSOConfig().then((config: SSOGetResponse) => {
      // Update the fields with values received from backend
      setSelectedDomains(config.domains ?? []);
      setEntity(config.idpEntityId ?? "");
      setSsoUrl(config.ssoUrl ?? "");
      setCertificate(config.certificate ?? "");

      // Update the store with the values received from backend
      dispatch(
        saveSSOConfig({
          domains: config.domains ?? existingSSOConfig.domains,
          entityId: config.idpEntityId ?? existingSSOConfig.entityId,
          ssoUrl: config.ssoUrl ?? existingSSOConfig.ssoUrl,
          certificate: config.certificate ?? existingSSOConfig.certificate,
          serviceProviderEntityId:
            config.spEntityId ?? existingSSOConfig.serviceProviderEntityId,
          callbackUrl: config.callbackUrl ?? existingSSOConfig.callbackUrl
        })
      );
    });
  }, []);

  useEffect(() => {
    // Enable the SSO toggle button only if all fields
    // are filled
    if (
      !(
        R.isEmpty(selectedDomains) ||
        R.isEmpty(entityRef?.current?.value) ||
        R.isEmpty(ssoUrlRef?.current?.value) ||
        R.isEmpty(certificateRef?.current?.value)
      )
    ) {
      setAreFieldsFilled(true);
    } else {
      setAreFieldsFilled(false);
    }
  }, [entity, ssoUrl, certificate, selectedDomains]);

  const handleDomains = (domain: string) => {
    if (R.includes(domain, selectedDomains)) {
      setSelectedDomains(prev => {
        const updated = R.reject(R.equals(domain), prev);
        return updated;
      });
    } else {
      setSelectedDomains(prev => {
        const updated = R.uniq([...prev, domain]);
        return updated;
      });
    }
  };

  // Save config in the backend and update the redux store
  const saveConfig = async () => {
    try {
      if (isSSOConfigured(existingSSOConfig)) {
        const updatedSSOConfig: SSOPatchResponse =
          await orgSettingsApi.updateSSOConfig({
            domains: selectedDomains,
            entityId: entity,
            ssoUrl,
            certificate,
            isEnabled
          });

        dispatch(
          saveSSOConfig({
            ...existingSSOConfig,
            domains: selectedDomains,
            entityId: entity,
            ssoUrl,
            certificate,
            callbackUrl: updatedSSOConfig.callbackUrl,
            serviceProviderEntityId: updatedSSOConfig.spEntityId
          })
        );
      } else {
        const updatedSSOConfig: SSOPutResponse =
          await orgSettingsApi.saveSSOConfig({
            domains: selectedDomains,
            entityId: entity,
            ssoUrl,
            certificate,
            isEnabled
          });

        dispatch(
          saveSSOConfig({
            ...existingSSOConfig,
            domains: selectedDomains,
            entityId: entity,
            ssoUrl,
            certificate,
            callbackUrl: updatedSSOConfig.callbackUrl,
            serviceProviderEntityId: updatedSSOConfig.spEntityId
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSave = async e => {
    e.preventDefault();

    // Update the entire config
    await saveConfig();
  };

  // To be used later
  const handleSSOToggle = () => {
    // Show the wizard to confirm disabling SSO
    if (isEnabled) {
      setIsWizardOpen(true);
    }
    setIsEnabled(prev => !prev);
  };

  const checkForUnsavedChanges = () => {
    const isDomainsUnsaved = !R.equals(
      selectedDomains,
      existingSSOConfig.domains
    );
    const isEntityIdUnsaved = !R.equals(entity, existingSSOConfig.entityId);
    const isSSOUrlUnsaved = !R.equals(ssoUrl, existingSSOConfig.ssoUrl);
    const isCertificateUnsaved = !R.equals(
      certificate,
      existingSSOConfig.certificate
    );

    return (
      isDomainsUnsaved ||
      isEntityIdUnsaved ||
      isSSOUrlUnsaved ||
      isCertificateUnsaved
    );
  };

  const doUnsavedChangesExist = checkForUnsavedChanges();

  return (
    <SSOPage>
      <SSOHeaderContainer>
        <div>
          <SSOHeader>SSO</SSOHeader>
        </div>
      </SSOHeaderContainer>
      <StyledForm>
        <FieldContainer>
          <Label htmlFor="domain">
            Domain <span>*</span>
          </Label>
          <FormMultiSelect
            domains={selectedDomains}
            handleDomains={handleDomains}
            handleGroups={() => {}}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor="entity-id">
            Entity ID <span>*</span>
          </Label>
          <FormTextInput
            inputRef={entityRef}
            id="entity-id"
            name="entity-id"
            placeholder="E.g. https://saml.exmaple.com/entityID"
            onChange={e => {
              setEntity(e.target.value);
            }}
            value={entity}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor="sso-url">
            SSO URL <span>*</span>
          </Label>
          <FormTextInput
            inputRef={ssoUrlRef}
            id="sso-url"
            name="sso-url"
            placeholder="E.g. https://mocksaml.com/api/saml/sso"
            onChange={e => {
              setSsoUrl(e.target.value);
            }}
            value={ssoUrl}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor="certificate">
            Certificate <span>*</span>
          </Label>
          <FormTextarea
            id="certificate"
            name="certificate"
            placeholder={certificatePlaceholder}
            helperMessage={certificateHelperMessage}
            textareaRef={certificateRef}
            onChange={e => {
              setCertificate(e.target.value);
            }}
            value={certificate}
          />
        </FieldContainer>

        <GeneratedFields />

        <ButtonRow>
          <Button
            variant="uPrimary"
            type="submit"
            isLoading={false}
            loadingText="Saving"
            onClick={handleSave}
            isDisabled={!doUnsavedChangesExist || !areFieldsFilled}
            sx={styles.saveButton}
          >
            Save
          </Button>
        </ButtonRow>
      </StyledForm>
    </SSOPage>
  );
};

export default SSOConfig;
