// @flow

import React from "react";

import { ChatblockHeaderPlaceholder, MetaDataRow } from "./styles";
import PulseLoader from "src/components/PulseLoader";

type Props = {
  hideMetaData?: boolean
};

const Chatblock = ({ hideMetaData = false }: Props) => {
  if (hideMetaData) {
    return (
      <ChatblockHeaderPlaceholder>
        <PulseLoader w="50%" h="16px" />
      </ChatblockHeaderPlaceholder>
    );
  }

  return (
    <ChatblockHeaderPlaceholder>
      <PulseLoader w="50%" h="16px" />
      <MetaDataRow>
        <PulseLoader w="71px" h="16px" />
        <PulseLoader w="71px" h="16px" />
        <PulseLoader w="71px" h="16px" />
      </MetaDataRow>
    </ChatblockHeaderPlaceholder>
  );
};

export default Chatblock;
