// @flow

import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import type { State, RoomId, UnifizeChatRoom } from "src/types";
import {
  getProcessTitle,
  getMututalVersionsAndCurrentVersionId
} from "src/reducers";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  IconButton
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import Viewer from "./Viewer";
import Editor from "./Editor";
import * as styles from "../styles";

type Props = {
  roomId: number,
  autoLink: boolean,
  defaultEdit: boolean,
  selectedRooms: RoomId[],
  versions: UnifizeChatRoom[],
  processTitle: string,
  currentVersionId: number,
  open: boolean,
  onClose: Function,
  onSuccess: Function
};

function MultiSelectModal({
  defaultEdit = true,
  autoLink,
  selectedRooms,
  versions,
  processTitle,
  currentVersionId,
  open,
  onClose,
  onSuccess
}: Props) {
  const [viewMode, setViewMode] = useState(!defaultEdit);
  const [newVersions, setNewVersions] = useState([]);
  const [linkType, setLinkType] = useState(autoLink ? "auto" : "manual");

  useEffect(() => {
    if (viewMode) {
      setNewVersions(autoLink ? [`${currentVersionId}`] : selectedRooms);
    } else {
      setNewVersions(
        autoLink
          ? []
          : R.isEmpty(selectedRooms)
          ? [`${currentVersionId}`]
          : selectedRooms
      );
    }
  }, [viewMode]);

  const handleVersionsChange = value => {
    setNewVersions(value);
    setLinkType("manual");
  };

  const handleSuccess = () => {
    onSuccess(
      linkType === "auto"
        ? {
            chatroom: currentVersionId,
            linkAllVersions: true
          }
        : {
            chatroom: newVersions.map(id => Number(id))
          }
    );
    onClose();
  };

  const enterEditMode = () => setViewMode(false);

  const handleClose = () => {
    if (defaultEdit || viewMode) {
      onClose();
    } else {
      setViewMode(true);
    }
  };

  return (
    <Modal isOpen={open} {...styles.modal}>
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalHeader {...styles.modalHeader}>
          {viewMode ? "All Revisions" : "Select Revisions"}

          <IconButton
            {...styles.closeIconButton}
            icon={<CloseIcon {...styles.closeIcon} />}
            onClick={onClose}
          />
        </ModalHeader>

        {viewMode ? (
          <Viewer
            autoLink={autoLink}
            versions={versions}
            selectedVersions={newVersions}
            processTitle={processTitle}
            onEnterEditMode={enterEditMode}
          />
        ) : (
          <Editor
            linkType={linkType}
            setLinkType={setLinkType}
            newVersions={newVersions}
            handleVersionsChange={handleVersionsChange}
            versions={versions}
            processTitle={processTitle}
            handleSuccess={handleSuccess}
            handleClose={handleClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ app }: State, { roomId }: Props) => {
  const processTitle = getProcessTitle(app, `${roomId}`);

  const { versions, currentVersionId } = getMututalVersionsAndCurrentVersionId(
    app,
    `${roomId}`
  );

  return {
    versions,
    processTitle,
    currentVersionId
  };
};

export default connect(mapStateToProps)(MultiSelectModal);
