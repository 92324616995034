// @flow

import * as React from "react";

import { Modalwrapper, Overlay } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  closePortal: Function,
  isOpen: boolean,
  Portal: any,
  children?: React.Node,
  targetRef?: any,
  height?: number,
  isParent?: boolean
};

const EditModal = ({
  Portal,
  isOpen,
  closePortal,
  children,
  targetRef,
  height,
  isParent
}: Props) =>
  isOpen ? (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={closePortal}>
          <Modalwrapper ref={targetRef} height={height} isParent={isParent}>
            {children}
          </Modalwrapper>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  ) : (
    <></>
  );

export default EditModal;
