// @flow

import React from "react";

import { HeaderPlaceholder, HeaderWidgets } from "./styles";
import PulseLoader from "src/components/PulseLoader";

const Header = () => (
  <HeaderPlaceholder>
    <PulseLoader w="100%" h="18px" />
    <HeaderWidgets>
      <PulseLoader w="100px" h="18px" />
      <PulseLoader w="100px" h="18px" />
      <PulseLoader w="100px" h="18px" />
      <PulseLoader w="100px" h="18px" />
    </HeaderWidgets>
  </HeaderPlaceholder>
);

export default Header;
