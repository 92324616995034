// @flow

import React, { useEffect, useRef, useCallback } from "react";

import { SearchResult } from "./styles";
import IconLabel from "src/components/IconLabel";
import { iconSize } from "src/styles/constants/size";
import type { RoomId, SystemProcess } from "src/types";

type Props = {
  id: RoomId,
  systemProcess: SystemProcess,
  templateId: number,
  focus: boolean,
  name: string,
  type: string,
  processTitle: string,
  seqNo: number,
  handleSelect: Function,
  setCurrentFocus: Function,
  isFocus: boolean,
  index: number
};

const Item = ({
  name,
  processTitle,
  type,
  seqNo,
  templateId,
  systemProcess,
  handleSelect,
  id,
  setCurrentFocus,
  isFocus,
  index
}: Props) => {
  const parentRef = useRef(null);

  const selectItem = useCallback(
    (e: any) => {
      e.stopPropagation();
      // setting focus to that element when it is selected
      setCurrentFocus(index);
      handleSelect(id);
    },
    [setCurrentFocus, index, handleSelect, id]
  );

  useEffect(() => {
    if (isFocus && parentRef.current) {
      // Move element into view when it is focused
      parentRef.current.focus();
    }
  }, [isFocus]);

  let heading = name;
  let iconType = type || "group";

  if (type === "workflow") {
    heading = `${processTitle || ""}${seqNo ? ` #${seqNo || ""}` : ""}${
      name ? `: ${name} ` : ""
    }`;
  }

  if (type === "workflow" && templateId === systemProcess.task) {
    iconType = "task";
  }

  return (
    <SearchResult
      tabIndex={isFocus ? 0 : -1}
      onClick={selectItem}
      onKeyPress={selectItem}
      ref={parentRef}
    >
      <IconLabel
        icon={iconType}
        label={heading}
        size={iconSize.space_l}
        cursor="pointer"
      />
    </SearchResult>
  );
};

export default Item;
