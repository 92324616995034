// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Text, useDisclosure } from "@chakra-ui/react";

import { getGroup } from "src/reducers";
import MembersModal from "src/components/Modal/Members";
import { GroupTitle } from "./styles";

type GroupMembersModalProps = {
  id: number
};

const GroupMembersModal = ({ id }: GroupMembersModalProps) => {
  const { members, title } = useSelector(({ app }) => getGroup(app, id)) || {
    title: "",
    members: []
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <span>
      <Text as="span">{title}</Text>{" "}
      <Text as="span" onClick={onOpen} sx={GroupTitle}>
        &#40;
        {members.length} {members.length > 1 ? "members" : "member"}
        &#41;
      </Text>
      <MembersModal id={id} isOpen={isOpen} onClose={onClose} />
    </span>
  );
};

export default GroupMembersModal;
