// @flow

import { backendUrl } from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";

import type { ChartId, ChartConfig } from "src/types.js";

/**
 * Create new chart
 * @param {Charts} req attributes for creating new chart
 */
export const createChart = async (req: Object) => {
  const response = await fetch(`${backendUrl}/chart`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

/**
 * Fetch chart
 * @param {ChartId} id for fetching chart
 */
export const fetchChart = async (id: ChartId) => easyFetch.get(`/chart/${id}`);

/**
 * Update chart
 * @param {ChartId} id of chart
 * @param {ChartConfig} chart attributes for updating chart
 */
export const updateChart = async (id: ChartId, req: ChartConfig) =>
  easyFetch.put(`/chart/${id}`, {
    body: req
  });

export const editChart = async (req: Object) => {
  const response = await fetch(`${backendUrl}/chart/${req.id}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(req)
  });

  return response.json();
};

export const getChart = async (id: ChartId) => {
  const response = await fetch(`${backendUrl}/chart/${id}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * API to get available charts
 * @return {Array<ChartType>} Array of chart types
 */
export const getCharts = async () => {
  const response = await fetch(`${backendUrl}/chart`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Get chart json
 * @param {ChartId} - id of the chart
 * @return {Object} - chart metadata
 */
export const getChartJson = async (id: ChartId) =>
  easyFetch.get(`/lory/chart/${id}/json`);

/**
 * Queue chart for refresh
 * @param {ChartId} - id of the chart
 */
export const refreshChart = async (id: ChartId) =>
  easyFetch.get(`/lory/chart/${id}/json?refresh=true`);

/**
 * Delete chart
 * @param {ChartId} id of chart to delete
 */
export const deleteChart = async (id: ChartId) => {
  await fetch(`${backendUrl}/chart/${id}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};

/**
 * Create Comparison chart
 * @param {Object} request body of chart to create
 */
export const createComparisonChart = async (reqBody: ChartConfig) =>
  easyFetch.post("/chart", {
    body: reqBody
  });
