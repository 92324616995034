// @flow

import React from "react";

type Props = {
  size?: number
};

const ContactUs = ({ size = 60 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 60 60"
      fill="none"
    >
      <path
        d="M50 10H15C12.2425 10 10 12.2425 10 15V27.5H15V20L31 32C31.4329 32.3242 31.9592 32.4994 32.5 32.4994C33.0408 32.4994 33.5671 32.3242 34 32L50 20V42.5H30V47.5H50C52.7575 47.5 55 45.2575 55 42.5V15C55 12.2425 52.7575 10 50 10ZM32.5 26.875L16.665 15H48.335L32.5 26.875Z"
        fill="#1581BA"
      />
      <path
        d="M5 30H22.5V35H5V30ZM10 37.5H25V42.5H10V37.5ZM17.5 45H27.5V50H17.5V45Z"
        fill="#2D3748"
      />
    </svg>
  );
};

export default ContactUs;
