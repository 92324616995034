// @flow

import React from "react";
import { connect } from "react-redux";

import { getGroupTitle } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  group: ?string
};

const Name = ({ group }: Props) => <span>{group}</span>;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  group: getGroupTitle(app, props.id)
});

export default connect(mapStateToProps)(Name);
