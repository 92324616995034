// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const SelectCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid ${colors.active};
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
    > path {
      transition: 0.3s ease-in-out fill;
    }
  }
`;

export const Increment = styled.svg`
  margin-left: 4px;
  cursor: pointer;
  > path {
    fill: #bbb;
  }
  :hover {
    > path {
      fill: #0abc8a !important;
    }
  }
`;
