// @flow

import type {
  StackProps,
  IconProps,
  TextProps,
  ButtonProps,
  BoxProps,
  TagProps,
  AvatarProps,
  SystemCSSProperties
} from "@chakra-ui/react";

import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const OwnerDropdownContainer: BoxProps = {
  ".DropdownInputFieldContainer": {
    pt: "1rem",
    px: "0.5rem"
  }
};

export const Selection: StackProps = {
  justifyContent: "space-between",
  border: `solid 1px ${colors.grey4}`,
  borderRadius: radius.medium,
  padding: spacing.space_m,
  _focus: {
    borderColor: colors.blue500
  }
};

export const UserTag: TagProps = {
  backgroundColor: "transparent",
  padding: 0
};

export const UserAvatar: AvatarProps = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: radius.medium,
  "& img": {
    borderRadius: radius.medium
  }
};

export const UserAvatarTag = {
  ...UserAvatar,

  borderRadius: radius.medium,
  "& img": {
    borderRadius: radius.medium
  }
};

export const UserTagText: TextProps = {
  ml: spacing.space_s,
  fontSize: "14px"
};

export const ToggleDropdownIcon: IconProps = {
  width: "24px",
  height: "24px",
  _hover: {
    cursor: "pointer"
  }
};

export const RemoveUser: StackProps = {
  width: "100%",
  paddingX: spacing.space_m,
  cursor: "pointer"
};

export const RemoveUserIcon: SystemCSSProperties = {
  fill: colors.disabled
};

export const RemoveUserIconHover: SystemCSSProperties = {
  fill: colors.blue500
};

export const RemoveUserText: TextProps = {
  color: colors.disabled
};

export const RemoveUserTextHover: TextProps = {
  color: colors.blue500
};

export const UserItem: StackProps = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  px: spacing.space_m,
  py: spacing.space_s,
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const ActiveUserOrgroup: SystemCSSProperties = {
  ...UserItem,
  backgroundColor: colors.lightBlue
};

export const inActiveUserOrgroup: SystemCSSProperties = {
  ...UserItem,
  backgroundColor: "transparent"
};

export const UserDetails: StackProps = {
  width: "100%",
  justifyContent: "center",
  alignItems: "center"
};

export const UserDetailsHeader: TextProps = {
  alignSelf: "flex-start",
  fontSize: "12px",
  lineHeight: "16px",
  m: 0
};

export const UserDetailsBody: TextProps = {
  alignSelf: "flex-start",
  fontSize: "10px",
  color: colors.grey5,
  m: "0 !important"
};

export const EmailInviteButton: ButtonProps = {
  borderRadius: 0,
  width: "100%",
  color: "#2B6CB0",
  borderColor: "#2B6CB0"
};

export const ErrorMessage: TextProps = {
  color: "red",
  width: "full",
  textAlign: "left",
  p: "0.5rem"
};

export const InputTogglePropmt: BoxProps = {
  borderRadius: radius.medium,
  border: `1px solid ${colors.grey4}`,
  color: colors.blackAlpha500,
  cursor: "pointer",
  padding: spacing.space_m,
  _focus: {
    borderColor: colors.blue500
  }
};

export const Email: TextProps = {
  margin: 0,
  fontWeight: 700
};

export const DropdownList: StackProps = {
  maxHeight: "10.2rem",
  alignItems: "stretch",
  overflow: "overlay",
  scrollbarGutter: "auto | stable && both-edges?"
};
