// @flow

import React, { Component } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import Workflow from "src/containers/chatroom/NewRoom/Workflow";
import { NewRoomOptions as StyledNewRoomOptions } from "src/styles/chatroom";
import { conversationTypes } from "src/constants/display";

type Props = {
  type: string,
  workflow: ?number,
  outerRef: Object,
  handleClose: Function,
  setModalType: Function
};

const conversations = R.keys(conversationTypes);

class Options extends Component<Props> {
  handleClickOutside = (e: any) => {
    if (e) {
      const { handleClose, outerRef } = this.props;
      if (outerRef) {
        if (!outerRef.contains(e.target)) {
          handleClose();
        }
      }
    }
  };

  setModalType = (type: string) => {
    const { setModalType, handleClose } = this.props;
    setModalType(type);
    handleClose();
  };

  getConversations = (conversation: Array<string>) => {
    const { workflow } = this.props;
    if (workflow === null) {
      // Filtering workflow and direct conversation from start conversation menu
      return R.reject(c => c === "workflow", conversations);
    }
    return R.reject(c => c === "workflow", conversation);
  };

  render() {
    const { type, setModalType } = this.props;

    return (
      <StyledNewRoomOptions>
        <Workflow
          type={type}
          handleSelect={setModalType}
          handleConversation={setModalType}
        />
      </StyledNewRoomOptions>
    );
  }
}

export default onClickOutside(Options);
