// @flow

const vectors = [
  {
    x1: 44,
    x2: 120,
    y1: 120,
    y2: 120,
    xtext: 120,
    ytext: 170,
    stop: true,
    text: "Invite Sent"
  },
  {
    x1: 128,
    x2: 320,
    y1: 120,
    y2: 120,
    xtext: 320,
    ytext: 170,
    stop: true,
    text: "Invite Clicked"
  },
  {
    x1: 328,
    x2: 520,
    y1: 120,
    y2: 120,
    xtext: 520,
    ytext: 170,
    stop: true,
    text: "Email ID and Password Registration"
  },
  {
    x1: 528,
    x2: 720,
    y1: 120,
    y2: 120,
    xtext: 720,
    ytext: 170,
    stop: true,
    text: "Phone and Dept. Registration"
  },
  {
    x1: 733,
    x2: 780,
    y1: 120,
    y2: 120,
    xtext: 120,
    ytext: 170,
    stop: false,
    text: ""
  },
  {
    x1: 780,
    x2: 870,
    y1: 120,
    y2: 60,
    xtext: 945,
    ytext: 65,
    text: "SignIn (Mobile)",
    stop: true
  },
  {
    x1: 780,
    x2: 870,
    y1: 120,
    y2: 170,
    xtext: 940,
    ytext: 175,
    text: "SignIn (Web)",
    stop: true
  }
];

export default vectors;
