// @flow

import { connect } from "react-redux";
import React, { useRef } from "react";

import {
  HeaderItem as StyledHeaderItem,
  SeqNo as StyledSeqNo,
  Title
} from "src/components/Manage/Workflow/Instances/Table/styles";
import Sort from "./Filter/SortAndFilter";
import { ChecklistItem as StyledChecklistItem } from "./styles";
import Date from "./Filter/Date";
import User from "./Filter/User";
import Chatroom from "./Filter/Chatroom";
import Workflow from "./Filter/Workflow";
import { formColumnNames } from "src/constants/display";
import useBoolean from "src/hooks/useBoolean";
import { getInstanceFilterId } from "src/reducers";
import Icon from "src/icons";

import type { AppState } from "src/types";

type Props = {
  id: string,
  column: string
};

const HeaderItem = ({ column }: Props) => {
  const parentRef = useRef(null);
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean();

  const label = formColumnNames[column];
  switch (column) {
    case "seqNo":
      return (
        <StyledSeqNo onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Sort
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledSeqNo>
      );
    case "templateId":
      return (
        <Title onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Workflow
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </Title>
      );
    case "createdAt":
    case "updatedAt":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Date
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
    case "owner":
    case "creator":
    case "completedBy":
    case "createdBy":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <User
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
    case "parent":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Chatroom
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
    case "address":
      return (
        <StyledChecklistItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Sort
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledChecklistItem>
      );
    case "chatroomId":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Chatroom
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
    default:
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <span>{label}</span>
          <Icon type="triangleDown" />
          {dropdown && (
            <Sort
              column={column}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: getInstanceFilterId(app)
});

export default connect(mapStateToProps, {})(HeaderItem);
