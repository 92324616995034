// @flow

import React from "react";
import * as R from "ramda";

import { FilterItem as StyledFilterItem, CloseButton } from "../styles";
import ChecklistName from "src/components/Manage/Builder/Checklist/Name/PrincipalChecklist";
import { processColumnNames } from "src/constants/display";
import * as colors from "src/styles/constants/colors";
import Icon from "src/icons";

type Props = {
  column: string,
  clearSort: Function
};

const SortItem = ({ column, clearSort }: Props) => {
  // Check if the column is checklist field if it is not display built in column names
  // else resolve checklist field names
  const columnName = R.head(column.split(":") || "");

  if (isNaN(columnName)) {
    return (
      <StyledFilterItem>
        <span>{processColumnNames[columnName]}:</span>
        <strong>
          {R.includes("asc", column) ? "Ascending" : "Descending"}
        </strong>
        <CloseButton onClick={() => clearSort(column)}>
          <Icon type="close" color={colors.active} />
        </CloseButton>
      </StyledFilterItem>
    );
  }

  return (
    <StyledFilterItem>
      <span>
        <ChecklistName fieldId={columnName} />:
      </span>
      <strong>{R.includes("asc", column) ? "Ascending" : "Descending"}</strong>
      <CloseButton onClick={() => clearSort(column)}>
        <Icon type="close" color={colors.active} />
      </CloseButton>
    </StyledFilterItem>
  );
};

export default SortItem;
