// @flow

import { rsf } from "../db";
import { takeEvery, put, take } from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";

function* syncUpdates(): any {
  try {
    // $FlowFixMe
    const channel = rsf.firestore.channel(`app/${process.env.BUILD}/updates`);
    while (true) {
      const snapshot = yield take(channel);
      for (const doc of snapshot.docs) {
        if (doc.id === "web") {
          const { version: newVersion, critical } = doc.data();
          yield put({
            type: atypes.GET_UPDATES_SUCCESS,
            payload: { newVersion, critical }
          });
        }
      }
    }
  } catch (error) {
    yield put({
      type: atypes.GET_UPDATES_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSyncUpdates(): any {
  yield takeEvery(atypes.LOAD_CHATROOMS_SUCCESS, syncUpdates);
}

export default [watchSyncUpdates()];
