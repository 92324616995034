// @flow

import React from "react";

import User from "src/containers/user/ImageLabel/Medium";

import type { UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile
};

const UploadedBy = ({ file }: Props) => {
  if (file) {
    return <User uid={file.owner} />;
  }

  return <></>;
};

export default UploadedBy;
