// @flow

import React, { useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from "@chakra-ui/react";

type Props = {
  shown: boolean,
  title: string,
  body: string,
  cancelBtnText?: string,
  submitBtnText?: string,
  hideCancelBtn?: boolean,
  onClose: Function,
  onSubmit: Function
};

const AlertModal = ({
  shown,
  title,
  body,
  cancelBtnText,
  submitBtnText,
  onClose,
  onSubmit,
  hideCancelBtn
}: Props) => {
  const cancelRef = useRef();

  if (shown) {
    return (
      <AlertDialog
        isOpen={shown}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              {hideCancelBtn !== true ? (
                <Button ref={cancelRef} onClick={onClose}>
                  {cancelBtnText || "Cancel"}
                </Button>
              ) : null}
              <Button colorScheme="red" onClick={onSubmit} ml={3}>
                {submitBtnText || "Submit"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  return null;
};

export default AlertModal;
