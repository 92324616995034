// @flow

import * as R from "ramda";
import type {
  ConditionBlock as ConditionBlockV1,
  Condition as ConditionV1,
  RuleBlock,
  ConditionV2
} from "src/types";
import { fields as v1Fields } from "src/conditions";

export const behaviorStatesBySchemaVersion = [
  { v1: "hidden", v2: "hidden" },
  {
    v1: "locked",
    v2: "locked"
  },
  {
    v1: "shown",
    v2: "enabled"
  },
  {
    v1: "dependentPicklistInclude",
    v2: "include"
  },
  {
    v1: "dependentPicklistExclude",
    v2: "exclude"
  },
  {
    v1: "dependentLinkedFieldInclude",
    v2: "include"
  },
  {
    v1: "dependentLinkedFieldExclude",
    v2: "exclude"
  },
  {
    v1: "dependentFormInclude",
    v2: "include"
  },
  {
    v1: "dependentFormExclude",
    v2: "exclude"
  }
];

export const v2Operators = {
  equalTo: "equal-to",
  notEqualTo: "not-equal-to",
  lessThan: "less-than",
  greaterThan: "greater-than",
  in: "in",
  notIn: "not-in"
};

const getV2Behavior = (behavior: Object) => {
  let v2Behavior = {
    state: behavior?.current
      ? behaviorStatesBySchemaVersion.find(R.propEq("v1", behavior?.current))
          ?.v2 || "enabled"
      : "enabled"
  };

  const props = ["options", "mandatory", "tooltip"];

  props.forEach(prop => {
    if (R.has(prop)(behavior)) {
      v2Behavior = R.mergeDeepRight(v2Behavior, {
        [prop]: behavior[prop]
      });
    }
  });

  return v2Behavior;
};

const getV2Condition = (condition: ConditionV1): ConditionV2 => {
  let v2Condition = {};

  let transforms = [];

  if (condition.field === v1Fields.checklistField) {
    transforms.push(
      R.mergeDeepLeft({
        entity: "checklist-field",
        entityId: condition.checklistFieldId,
        field: null
      })
    );
  } else if (condition.field === v1Fields.owner) {
    transforms.push(
      R.mergeDeepLeft({
        entity: "chatroom",
        entityId: null,
        field: "owner"
      })
    );
  } else if (condition.field === v1Fields.dueDate) {
    transforms.push(
      R.mergeDeepLeft({
        entity: "chatroom",
        entityId: null,
        field: "due-date"
      })
    );
  } else if (condition.field === v1Fields.status) {
    transforms.push(
      R.mergeDeepLeft({
        entity: "chatroom",
        entityId: null,
        field: "status"
      })
    );
  } else if (condition.field === v1Fields.currentUser) {
    transforms.push(
      R.mergeDeepLeft({
        entity: "user",
        entityId: null
      })
    );
  }

  if (condition.type === "isEmpty") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.equalTo,
        value: null
      })
    );
  } else if (condition.type === "isFilled") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.notEqualTo,
        value: null
      })
    );
  } else if (condition.type === "isApproved") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.equalTo,
        value: "approved"
      })
    );
  } else if (condition.type === "isAnyOf") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.in,
        value: condition.value
      })
    );
  } else if (condition.type === "isNoneOf") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.notIn,
        value: condition.value
      })
    );
  } else if (condition.type === "isGreaterThan") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.greaterThan,
        value: condition.value
      })
    );
  } else if (condition.type === "isLessThan") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.lessThan,
        value: condition.value
      })
    );
  } else if (condition.type === "isEqualTo") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.equalTo,
        value: condition.value
      })
    );
  } else if (condition.type === "isCurrentUser") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.equalTo,
        value: "current-user"
      })
    );
  } else if (condition.type === "isOverdue") {
    transforms.push(
      R.mergeDeepLeft({
        op: v2Operators.greaterThan,
        value: "current-date"
      })
    );
  } else if (condition.type === "isOfRole") {
    transforms.push(
      R.mergeDeepLeft({
        field: "role",
        op: v2Operators.in,
        value: condition.value
      })
    );
  } else if (condition.type === "isNotOfRole") {
    transforms.push(
      R.mergeDeepLeft({
        field: "role",
        op: v2Operators.notIn,
        value: condition.value
      })
    );
  } else if (condition.type === "isPartOf") {
    transforms.push(
      R.mergeDeepLeft({
        field: "group",
        op: v2Operators.in,
        value: condition.value
      })
    );
  }

  transforms.forEach(transform => {
    v2Condition = transform(v2Condition);
  });

  return v2Condition;
};

export const convert = (blocks: ConditionBlockV1[]): RuleBlock[] => {
  let updatedBlocks = [];

  blocks.forEach(block => {
    let updatedBlock = {
      conditions: block.conditions
        .filter(item => !R.isEmpty(item.field) && R.is(String, item.type))
        .map(conditionBlock => getV2Condition(conditionBlock)),
      behavior: getV2Behavior(block.behavior)
    };

    if (R.has("id")(block)) {
      updatedBlock = {
        ...updatedBlock,
        id: block.id
      };
    }

    updatedBlocks.push(updatedBlock);
  });

  return updatedBlocks;
};
