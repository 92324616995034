// @flow

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const fade = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.45;
  }
`;

const Typing = styled.svg`
  color: dimgray;
  circle:nth-of-type(1) {
    animation: ${fade} 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 0ms infinite
      alternate-reverse;
  }
  circle:nth-of-type(2) {
    animation: ${fade} 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 400ms infinite
      alternate-reverse;
  }
  circle:nth-of-type(3) {
    animation: ${fade} 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 800ms infinite
      alternate-reverse;
  }
`;

const ChatIndicatorWrapper = styled.div`
  padding: 0 0.8em;
`;

export { Typing, ChatIndicatorWrapper };
