// @flow

import { connect } from "react-redux";
import React from "react";
import { Avatar } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";

import { getUser } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  shape?: string,
  size?: string,
  email: string,
  photoUrl: string,
  displayName: string
};

export const Photo = ({
  shape = "circle",
  size = "sm",
  email,
  photoUrl,
  displayName
}: Props) => {
  let borderRadius = "2px";

  switch (shape) {
    case "circle":
      borderRadius = "50%";
      break;
    case "curve":
      borderRadius = "4px";
      break;
    case "ellipse":
      borderRadius = "8px";
      break;
    default:
      break;
  }

  return (
    <Avatar
      name={displayName || email}
      src={photoUrl}
      size={size}
      borderRadius={borderRadius}
      bg={colors.unifizeBlue}
      color="white"
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  ...getUser(app, props.uid)
});

export default connect(mapStateToProps)(Photo);
