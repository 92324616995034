// @flow

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { AgeDropdown as StyledDropdDown, ListItem } from "./styles";
import Name from "./PlatformUsageName";
import Checkbox from "src/components/Checkbox";
import useClickoutside from "src/hooks/useClickoutside";

import type { AppState, PlatformUsage } from "src/types";

type Props = {
  outerRef: any,
  value: Array<number>,
  platformUsage: PlatformUsage,
  handleSelect: Function,
  handleClose: Function
};

const AgeDropdown = ({
  outerRef,
  value,
  platformUsage,
  handleSelect,
  handleClose
}: Props) => {
  useClickoutside({ outerRef, handleClose });

  return (
    <StyledDropdDown>
      <ul>
        {R.keys(platformUsage).map(id => (
          <ListItem key={id} onClick={() => handleSelect(parseInt(id, 10))}>
            <Name id={id} />
            <Checkbox
              id={`age${id}`}
              checked={R.includes(parseInt(id, 10), value)}
              handleChange={() => {}}
            />
          </ListItem>
        ))}
      </ul>
    </StyledDropdDown>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  platformUsage: app.orgSettings.platformUsage
});

export default connect(mapStateToProps)(AgeDropdown);
