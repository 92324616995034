// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import ReactSwitch from "react-switch";

import { Switch as StyledSwitch } from "./styles";
import * as colors from "src/styles/constants/colors";
import { setNewChartDialogAttributes } from "src/actions/chart";

type Props = {
  value: any,
  _setNewChartDialogAttributes: Function
};

const Switch = ({ value, _setNewChartDialogAttributes }: Props) => {
  const handleSwitch = useCallback(
    (checked: boolean) => {
      if (!checked) {
        _setNewChartDialogAttributes({
          numberFieldSwitch: checked,
          computationField: null
        });
      } else {
        _setNewChartDialogAttributes({
          numberFieldSwitch: checked
        });
      }
    },
    [_setNewChartDialogAttributes]
  );

  return (
    <StyledSwitch>
      <ReactSwitch
        onChange={handleSwitch}
        uncheckedIcon={false}
        checkedIcon={false}
        checked={value}
        onColor={colors.checked}
        offColor={colors.grey6}
        className={value ? "off" : "on"}
        height={18}
        width={31}
      />
    </StyledSwitch>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Switch);
