// @flow

import React from "react";

const CloseFilter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#a)">
      <path
        fill="#bbbbbb"
        d="M12 4.806L11.194 4 8 7.194 4.806 4 4 4.806 7.194 8 4 11.194l.806.806L8 8.806 11.194 12l.806-.806L8.806 8 12 4.806z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseFilter;
