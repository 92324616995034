// @flow

import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { copyToClipboard } from "src/utils";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentOrg } from "src/reducers";
import { toggleCloneOrgModal } from "src/actions/modal";

import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  Checkbox,
  VStack
} from "@chakra-ui/react";
import * as styles from "./styles";

import * as orgCloneApi from "src/api/orgClone";

type EntityApiData = {
  fetching: boolean,
  error: ?string,
  data: ?Array<{
    entity: string,
    excludable: boolean,
    title: string
  }>
};

const CloneOrgModal = () => {
  const dispatch = useDispatch();
  const currentOrg = useSelector(({ app }) => getCurrentOrg(app));
  const [entityApiData, setEntityApiData] = useState<EntityApiData>({
    fetching: false,
    error: null,
    data: null
  });
  const [generatingCloneLink, setGeneratingCloneLink] = useState(false);
  const [entitiesToExclude, setEntitiesToExclude] = useState<string[]>([]);

  const copyButtonDisabled =
    entityApiData.fetching ||
    entityApiData.error ||
    !entityApiData.data ||
    generatingCloneLink;

  const handleEntityChange = (entity, checked) => {
    if (checked) {
      setEntitiesToExclude(R.without([entity]));
    } else {
      setEntitiesToExclude(R.append(entity));
    }
  };

  const handleGenerateCloneOrgClink = async () => {
    try {
      setGeneratingCloneLink(true);
      const res = await orgCloneApi.getCloneLink(
        currentOrg.id,
        entitiesToExclude
      );
      copyToClipboard(res.url);
      toast.success("Copied clone link!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to copy clone link");
    } finally {
      setGeneratingCloneLink(false);
    }
  };

  const fetchOrgCloneEntities = async () => {
    setEntityApiData({ fetching: true, error: null, data: null });

    try {
      const res = await orgCloneApi.getEntities();
      const entities = R.filter(R.propEq("excludable", true), res);
      setEntityApiData({ fetching: false, error: null, data: entities });
    } catch (error) {
      setEntityApiData({
        fetching: false,
        error: error.message,
        data: null
      });
    }
  };

  useEffect(() => {
    fetchOrgCloneEntities();
  }, []);

  const handleClose = () => {
    dispatch(toggleCloneOrgModal({ open: false }));
  };

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Clone Org</ModalHeader>

        <ModalBody as={VStack} sx={styles.ModalBody}>
          {entityApiData.fetching && <Spinner {...styles.Spinner} />}

          {entityApiData.error && (
            <Alert status="error">
              <AlertIcon />
              {entityApiData.error}
            </Alert>
          )}

          {entityApiData.data &&
            entityApiData.data.map(entity => (
              <Checkbox
                key={entity.entity}
                defaultIsChecked
                onChange={e =>
                  handleEntityChange(entity.entity, e.target.checked)
                }
              >
                {entity.title}
              </Checkbox>
            ))}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="uBlueFilled"
            mr={3}
            onClick={handleGenerateCloneOrgClink}
            isDisabled={copyButtonDisabled}
            isLoading={generatingCloneLink}
          >
            Copy Link
          </Button>

          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CloneOrgModal;
