// @flow

import type { StackProps } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const container: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const header: StackProps = {
  cursor: "pointer",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: colors.purpleLighter,
  borderRadius: "6px",
  px: 2,
  py: 1
};

export const toggleIcon = {
  width: 6,
  height: 6
};
