// @flow

import React, { useState, useRef, useLayoutEffect } from "react";
import Link from "redux-first-router-link";
import { connect } from "react-redux";

import { ForwardedContainer, ForwardedInfoText } from "./styles";
import Icon from "src/icons";
import { getChatroomAddress, getFile, getTemplateId } from "src/reducers";
import ChatRoomName from "src/containers/chatroom/Name";
import * as colors from "src/styles/constants/colors";

import type { AppState, ChatroomAddress } from "src/types";
import * as atypes from "src/constants/actionTypes";

type Props = {
  message: any,
  originAddress: ChatroomAddress,
  file: any,
  templateId: ?number
};

const Forward = ({ message, originAddress, file, templateId }: Props) => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { origin } = message;

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [targetRef, file]);

  return (
    <ForwardedContainer ref={targetRef} width={dimensions.width}>
      <ForwardedInfoText>
        <Icon type="forward" color={colors.secondary} />
        &nbsp;&nbsp;Forwarded from&nbsp;
      </ForwardedInfoText>
      <div>
        <Link
          title={origin.roomId}
          to={{
            type: atypes.SET_CURRENT_CHATROOM_REQUEST,
            payload: { id: originAddress },
            meta: {
              query: {
                templateId
              }
            }
          }}
        >
          <ChatRoomName id={origin.roomId} />
        </Link>
      </div>
    </ForwardedContainer>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { message }) => {
  const { origin } = message;
  return {
    originAddress: getChatroomAddress(app, origin.roomId),
    file: getFile(app, message?.data?.name),
    templateId: getTemplateId(app, origin.roomId)
  };
};

export default connect(mapStateToProps)(Forward);
