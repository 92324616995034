// @flow

import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useDisclosure, VStack, Text, HStack } from "@chakra-ui/react";
import SortableList from "./SortableList";
import { StyledBuilder } from "src/styles/popover.old";
import { getCurrentChecklistBuilder, getCurrentUserId } from "src/reducers";
import {
  addFields as addFieldsAction,
  updateField as updateFieldAction,
  deleteField as deleteFieldAction,
  moveField as moveFieldAction
} from "src/actions/checklistBuilder";
import type { MoveFieldActionCreator } from "src/actions/checklistBuilder";
import { TextButtonWithImageAndBorder } from "src/styles/buttons.old";
import Icon from "src/components/InlineSVG";
import EmptyChecklistBuilderImg from "src/img/EmptyChecklistBuilder.svg";
import { iconSize } from "src/styles/constants";
import * as colors from "src/styles/constants/colors";
import type {
  AppState,
  UID,
  CurrentChecklistBuilder,
  ChecklistFieldSettings
} from "src/types";
import SettingsBuilder from "./SettingsBuilder";
import AddFieldModal from "./AddFieldModal";
import AddFieldsFromCSVButton from "./AddFieldsFromCSVButton";

type Props = {
  current: CurrentChecklistBuilder,
  uid: UID,
  createChecklist: Function,
  addFieldsAction: Function,
  updateFieldAction: Function,
  deleteFieldAction: Function,
  moveFieldAction: MoveFieldActionCreator,
  checklistFieldSettings: ChecklistFieldSettings
};

const Checklist = ({
  checklistFieldSettings,
  current,
  uid,
  addFieldsAction,
  updateFieldAction,
  deleteFieldAction,
  moveFieldAction
}: Props) => {
  const [showSettings, setShowSettings] = useState(false);
  const [fieldType, setFieldType] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onFieldCardPress = type => {
    setFieldType(type);
  };

  const addFieldHandler = params => {
    if (fieldType) {
      addFieldsAction({ fields: params?.fields || [{ label: "", fieldType }] });
    }

    setFieldType(null);
    onClose();
  };

  const closeAddFieldModal = () => {
    setFieldType(null);
    onClose();
  };

  const updateField = useCallback(
    (value: any, position: number, type: string) => {
      let formattedValue = value;

      if (type === "fieldName") {
        formattedValue = value.replace(/\0/g);

        if (formattedValue.length !== value.length) {
          toast.info("Some character(s) could not be recognized.");
        }
      }

      updateFieldAction({
        position,
        data: {
          [type]: formattedValue
        }
      });
    },
    []
  );

  const removeField = useCallback((e: any, position: number) => {
    e.stopPropagation();

    deleteFieldAction({ position, uid });
  }, []);

  const toggleShowSettings = useCallback(() => {
    setShowSettings(prevState => !prevState);
  }, []);

  const { fields } = current;

  const isChecklistEmpty = fields.length === 0;

  return (
    <>
      <StyledBuilder>
        {!isChecklistEmpty ? (
          <>
            <SortableList
              items={fields}
              addFields={addFieldsAction}
              updateField={updateField}
              removeField={removeField}
              openSettings={toggleShowSettings}
              onSortEnd={moveFieldAction}
              useDragHandle
            />

            <TextButtonWithImageAndBorder
              data-cy="addFields"
              type="button"
              onClick={onOpen}
            >
              <Icon
                icon="hollowPlusCircle"
                color={colors.blue500}
                size={iconSize.big}
              />
              <p>Add Fields</p>
            </TextButtonWithImageAndBorder>
          </>
        ) : (
          <VStack
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img src={EmptyChecklistBuilderImg} />

            <Text
              sx={{
                textAlign: "center"
              }}
            >
              The checklist has not been configured yet. <br />
              Use the Add Field button to start creating or upload an excel
              sheet.
            </Text>

            <HStack
              sx={{
                gap: "50px"
              }}
            >
              <TextButtonWithImageAndBorder
                data-cy="addFields"
                type="button"
                onClick={onOpen}
              >
                <Icon
                  icon="hollowPlusCircle"
                  color={colors.blue500}
                  size={iconSize.big}
                />
                <p>Add Fields</p>
              </TextButtonWithImageAndBorder>

              <AddFieldsFromCSVButton />
            </HStack>
          </VStack>
        )}
      </StyledBuilder>

      <AddFieldModal
        isOpen={isOpen}
        onClose={closeAddFieldModal}
        onFieldCardPress={onFieldCardPress}
        onSave={addFieldHandler}
        fieldType={fieldType}
      />

      {showSettings && (
        <SettingsBuilder
          closeSettings={toggleShowSettings}
          checklistFieldSettings={checklistFieldSettings}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: getCurrentChecklistBuilder(app),
  uid: getCurrentUserId(app),
  checklistFieldSettings: app.workflow.builderDialog.checklistFieldSettings
});

const mapDispatchToProps = {
  addFieldsAction,
  updateFieldAction,
  deleteFieldAction,
  moveFieldAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
