// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";

export const File = styled.span`
  cursor: pointer;
  color: ${colors.active};
  text-transform: none !important;
`;
