// @flow

import React from "react";

import { FormControl, FormLabel } from "@chakra-ui/react";
import SelectMultipleInvite from "src/components/user/SelectMultipleInvite";

import type { UID, Email, GroupId } from "src/types";

type Member = UID | Email;

type Props = {
  members: Array<Member>,
  groups?: Array<GroupId>,
  handleMembers: (member: UID | Email) => void,
  handleGroups?: (group: GroupId) => void
};

const Members = ({ members, handleMembers, groups, handleGroups }: Props) => (
  <FormControl p={4}>
    <FormLabel onClick={e => e.preventDefault()}>Participants</FormLabel>
    <SelectMultipleInvite
      members={members}
      groups={groups}
      handleMembers={handleMembers}
      handleGroups={handleGroups}
    />
  </FormControl>
);

export default Members;
