// @flow

import React from "react";

import { TableHeader } from "./styles";
import { Th } from "src/styles/table";
import HeaderCheckbox from "./Row/HeaderCheckbox";

type Props = {
  sort: string,
  ascending: boolean,
  sortBy: Function
};

const Header = ({ sort, ascending, sortBy }: Props) => {
  const getArrow = (element: string) => {
    if (element === sort) {
      if (ascending) {
        return <> &uarr;</>;
      }
      return <> &darr;</>;
    }
  };
  return (
    <TableHeader>
      <tr>
        <Th tabIndex="0" active={sort === "title"}>
          <HeaderCheckbox>
            <th
              onClick={() => sortBy("title")}
              onKeyPress={() => sortBy("title")}
              role="button"
            >
              Process Name
              {getArrow("title")}
            </th>
          </HeaderCheckbox>
        </Th>
        <Th
          onClick={() => sortBy("activeCount")}
          onKeyPress={() => sortBy("activeCount")}
          role="button"
          tabIndex="0"
          active={sort === "activeCount"}
        >
          Active Instances
          {getArrow("activeCount")}
        </Th>
        <Th
          onClick={() => sortBy("invocationCount")}
          onKeyPress={() => sortBy("invocationCount")}
          role="button"
          tabIndex="0"
          active={sort === "invocationCount"}
        >
          Total Instances
          {getArrow("invocationCount")}
        </Th>
        <Th
          onClick={() => sortBy("createdAt")}
          onKeyPress={() => sortBy("createdAt")}
          role="button"
          tabIndex="0"
          active={sort === "createdAt"}
        >
          Created At
          {getArrow("createdAt")}
        </Th>
        <Th
          onClick={() => sortBy("updatedAt")}
          onKeyPress={() => sortBy("updatedAt")}
          role="button"
          tabIndex="0"
          active={sort === "updatedAt"}
        >
          Updated At
          {getArrow("updatedAt")}
        </Th>
        <Th
          onClick={() => sortBy("creator")}
          onKeyPress={() => sortBy("creator")}
          role="button"
          tabIndex="0"
          active={sort === "creator"}
        >
          Created by
          {getArrow("creator")}
        </Th>
      </tr>
    </TableHeader>
  );
};

export default Header;
