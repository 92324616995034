// @flow

import { connect } from "react-redux";
import React from "react";

import Dropdown from "./Dropdown";
import { CriteriaSelect as StyledCriteriaSelect } from "./styles";
import useBoolean from "src/hooks/useBoolean";
import { userSegmentType } from "src/constants/display";
import Icons from "src/icons";
import { SpacedFlex } from "src/styles/box";

import type { AppState, SegmentType } from "src/types";

type Props = {
  value: number,
  segmentType: SegmentType,
  handleSelect: Function
};

const CriteriaSelect = ({ value, segmentType, handleSelect }: Props) => {
  const { value: dropdown, toggleBoolean } = useBoolean();

  return (
    <StyledCriteriaSelect onClick={toggleBoolean}>
      <SpacedFlex>
        <span>{userSegmentType[segmentType[`${value}`]?.title]}</span>
        <Icons type="caretDown" />
      </SpacedFlex>

      {dropdown && (
        <Dropdown
          segmentType={segmentType}
          handleSelect={handleSelect}
          handleClose={toggleBoolean}
        />
      )}
    </StyledCriteriaSelect>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  segmentType: app.orgSettings.segmentType
});

export default connect(mapStateToProps)(CriteriaSelect);
