// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  senderNotParticipant: boolean,
  handleInviteSenderToConversation: Function
};

const InviteSenderToConversation = ({
  handleInviteSenderToConversation,
  senderNotParticipant
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="senderNotParticipant"
        checked={senderNotParticipant}
        handleChange={handleInviteSenderToConversation}
      />
      Do not invite sender to conversation
    </Label>
  </InlineFormGroup>
);

export default InviteSenderToConversation;
