// @flow

import styled from "styled-components";

import { ScrollableBox, LeftAlignedListItem } from "src/styles/box";
import { Initial } from "src/components/user/styles";

export const Filter = styled(ScrollableBox)`
  position: absolute;
  z-index: 4;
  top: 40px;
  padding-top: 0;
  > ul {
    max-height: 10.5em;
    margin-top: 0.5em;
  }
`;

export const ListItem = styled(LeftAlignedListItem)`
  padding: 4px 8px;
  border-radius: 2px;
  line-height: 20px;
`;

export const SubHeading = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 0em 0.5em;
  margin-top: 0.8em;
  text-align: left;
`;

export const Priority = styled(ListItem)`
  > svg {
    height: 0.8em;
    width: 0.8em;
    margin-right: 8px;
  }
`;

export const Status = styled(ListItem)``;

export const User = styled(ListItem)`
  > div:nth-of-type(2),
  > img:last-child {
    margin-left: 0.3em;
  }

  >span: last-child {
    max-width: 11em;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    :last-child {
      margin-left: 0.5em;
    }
  }

  ${Initial} {
    width: 2.3em;
    height: 2.3em;
    margin-left: 0 !important;
  }
`;

export const Chatroom = styled(ListItem)`
  > span {
    display: inline-block;
    max-width: 15em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
