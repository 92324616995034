// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";

import { FormControl as ChakraFormControl } from "@chakra-ui/react";

import { colors, spacing } from "src/styles/constants";
import * as newSpacing from "src/styles/constants/spacing";
import * as newColors from "src/styles/constants/colors";

export const PrimitiveField = styled.div`
  input,
  textarea {
    padding: 8px;
    font-size: 0.88rem;
    border-color: transparent;
    width: 100%;
  }
  textarea,
  input[type="number"],
  input[type="date"] {
    min-height: 27px;
    background-color: ${newColors.grey19};
  }
  textarea {
    resize: initial;
  }
`;

export const ChecklistField = styled.div`
  background: ${colors.pureWhite};
  padding: ${spacing.spacing1};
  padding-right: 0px;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-column-gap: ${spacing.spacing1};
  margin-bottom: ${spacing.spacing0};
  box-shadow: 0 0 4px 0 rgba(42, 42, 42, 0.08);
  > div:last-child {
    background: ${colors.aliceBlue};
    margin-top: -${spacing.spacing1};
    margin-bottom: -${spacing.spacing1};
    display: flex;
    justify-content: center;
    padding-top: 3em;
    box-shadow: 0 0 6px 0 rgba(42, 42, 42, 0.08);
  }
`;

export const ChecklistFieldLabel = styled.label`
  padding-bottom: ${spacing.spacing0};
  display: block;
  margin-top: ${spacing.spacing0};
  font-weight: bold;
  position: relative;
  > svg {
    position: absolute;
    right: 0px;
    top: -${spacing.spacing0};
    cursor: pointer;
  }
`;

export const ChecklistBuilderFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  border-top: solid 0.5px ${colors.simpleGrey};
  padding-top: 12px;
  padding-right: 12px;
  background: ${colors.pureWhite};
  > button:last-child {
    margin-left: ${spacing.spacing0};
  }
`;

export const Fields = styled.div`
  min-height: 100%;
`;

export const ChecklistSettings = styled.div`
  padding: ${spacing.spacing1};
  max-width: 25rem;

  ${props =>
    props.form &&
    `
      border: 1px solid #ccc;
      text-align: left;
      box-shadow: 1px 5px #f2f2f2;
      position: absolute;
      top: 0px;
      right: -95px;
      line-height: 0.75rem;
      padding-bottom: 8px;
      z-index: 999;
      background: ${colors.pureWhite};
  `}

  > form {
    > div {
      padding-bottom: 0.2rem;
      input {
        margin: ${newSpacing.space_m} 0px;
      }
      textarea {
        margin: 0.5em 0em;
      }
    }
  }

  textarea {
    background: ${colors.pureWhite};
    border: 1px solid ${colors.isabelline};
    padding: ${spacing.spacing1};
    width: 100%;
  }

  input[type="text"],
  input[type="number"] {
    border: 1px solid rgb(221, 221, 221);
    width: 100%;
    height: 32px;
    padding-left: 12px;
    border-radius: 2px;
  }

  select {
    padding-left: 12px;
    font-size: 13px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #dddddd;
    height: 32px;
    width: 70%;
  }
`;

export const Multiline = styled(PrimitiveField)`
  .DraftEditor-root {
    width: 100%;
    min-height: 27px;
    background-color: ${colors.isabelline};
    max-width: 200px;
    padding: ${spacing.spacing0};
    line-height: 1.5em;
    max-height: 100px;
    overflow: auto;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: ${colors.dullGrey};
  }
`;

export const SelectOption = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.pureWhite};
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 0.6em;
  cursor: pointer;
  border: ${ifProp("highlight", `1px solid ${colors.manatee}`, "")};
`;

export const DropDown = styled.div`
  position: absolute;
  right: 0.6em;
  > svg {
    width: 0.6em;
    height: 0.6em;
  }
`;

export const Options = styled.div`
  position: absolute;
  background-color: ${colors.pureWhite};
  width: 100%;
  padding: 0.6em;
  top: calc(100% + 4px);
  left: 0;
`;

export const Option = styled.div`
  padding: 0.3em;
  :hover {
    background-color: ${colors.simpleGrey};
  }
`;

export const FormControl = styled(ChakraFormControl)`
  font-size: 14px;

  label {
    font-size: 14px;
  }
`;

export const RawChecklistSettings = styled.div`
  border: 1px solid #ccc;
  text-align: left;
  box-shadow: 1px 5px #f2f2f2;
  position: absolute;
  top: 0px;
  line-height: 0.75rem;
  padding-bottom: 8px;
  z-index: 999;
  background: ${colors.pureWhite};
  font-size: 14px;
  > * p {
    font-size: 14px;
    margin: 0;
  }
`;
