// @flow

import styled from "styled-components";

const Header = styled.div`
  height: 62px;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const CloseIcon = styled.span`
  position: absolute;
  right: 16px;
  top: 8px;
  height: 30px;
  width: 30px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    cursor: pointer !important;
    width: 24px;
    height: 24px;
  }
`;

const ModalBody = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
`;

export { Header, Title, CloseIcon, ModalBody };
