// @flow

import React, { Component } from "react";
import * as R from "ramda";

import selector from "../selector";
import Result from "./result";
import Selection from "./SelectionMultiple";
import Invite from "src/containers/chatroom/Invite";
import { SelectMultiple as StyledSelectMultiple } from "src/styles/dropdown";
import { SmallText as SearchInput } from "src/styles/input";

import type { UID, RoomId } from "src/types";

type Props = {
  focus: number,
  dropdown: boolean,
  roomId: RoomId,
  value: Array<UID>,
  result: Array<UID>,
  autoFocus?: boolean,
  showInvite?: boolean,
  placeholder?: string,
  handleChange: Function,
  handleDropdown: Function,
  handleClose: Function,
  setFocus: Function,
  handleSearch: Function,
  resetSearch: Function
};

type State = {
  search: string,
  invite: string,
  showInvite: boolean
};

class SelectMultiple extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false,
    showInvite: false,
    placeholder: "Search People"
  };

  state = {
    search: "",
    invite: "",
    showInvite: false
  };

  componentDidUpdate(prevProps: Props) {
    const { showInvite } = this.props;
    if (prevProps.showInvite !== showInvite && showInvite) {
      this.setState({ showInvite });
    }
  }

  handleInvite = () => {
    const { handleDropdown } = this.props;
    this.setState(prevState => ({
      showInvite: !prevState.showInvite,
      search: "",
      invite: prevState.search
    }));
    handleDropdown();
  };

  handleSelect = (item: UID) => {
    const { handleChange, setFocus, resetSearch } = this.props;
    this.setState({ search: "" });
    setFocus(0);
    handleChange(item);
    if (this.searchRef) {
      this.searchRef.focus();
      resetSearch();
    }
  };

  handleDropdown = () => {
    const { handleDropdown } = this.props;
    if (this.searchRef) {
      this.searchRef.focus();
    }
    handleDropdown();
  };

  handleSearch = (e: any) => {
    const { value, id } = e.target;
    const { dropdown, handleDropdown, handleSearch, resetSearch } = this.props;
    this.setState({ [id]: value });
    if (!dropdown) {
      handleDropdown();
      resetSearch();
    }
    handleSearch(value);
  };

  removeHandler = (e: any, item: UID) => {
    e.stopPropagation();
    this.handleSelect(item);
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  onKeyDown = (e: any) => {
    if (e) {
      const { result, value } = this.props;
      const { search, showInvite } = this.state;

      // Backspace
      if (e.keyCode === 8 && R.isEmpty(search)) {
        const lastValue = R.last(value);
        if (lastValue) {
          this.removeHandler(e, lastValue);
        }
      }

      // Detect ESC
      if (e.keyCode === 27) {
        const { dropdown, handleClose } = this.props;
        if (dropdown || showInvite) {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }
      }

      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus } = this.props;

        e.preventDefault();
        e.stopPropagation();

        const filteredResult = R.reject(r => R.includes(r, value), result);

        if (filteredResult[focus]) {
          e.preventDefault();
          this.handleSelect(filteredResult[focus]);
        }
      }
    }
  };

  render() {
    const {
      roomId,
      dropdown,
      focus,
      value,
      placeholder,
      autoFocus,
      result,
      setFocus,
      handleClose
    } = this.props;
    const { search, invite, showInvite } = this.state;
    const filteredResult = R.reject(r => R.includes(r, value), result);
    return (
      <StyledSelectMultiple
        ref={node => {
          this.outerRef = node;
        }}
        onClick={this.handleDropdown}
      >
        <div>
          <Selection value={value} removeHandler={this.removeHandler}>
            <SearchInput
              type="text"
              id="search"
              value={search}
              ref={node => {
                this.searchRef = node;
              }}
              placeholder={placeholder}
              onChange={this.handleSearch}
              autoComplete="off"
              onKeyDown={this.onKeyDown}
              autoFocus={autoFocus}
            />
          </Selection>
        </div>
        <div>
          {!dropdown || showInvite ? null : (
            <Result
              text={search}
              focus={focus}
              result={filteredResult}
              outerRef={this.outerRef}
              setFocus={setFocus}
              handleSelect={this.handleSelect}
              handleClose={handleClose}
              handleInvite={this.handleInvite}
            />
          )}
        </div>

        {showInvite ? (
          <Invite
            text={invite}
            outerRef={this.outerRef}
            handleClose={this.handleInvite}
            roomId={roomId}
          />
        ) : null}
      </StyledSelectMultiple>
    );
  }
}

export default selector(SelectMultiple);
