// @flow

import React from "react";

import { size as sizes, colors } from "src/styles/constants";

import svg from "src/utils/svg.old";

type Props = {
  icon: string,
  size?: string,
  fill?: string,
  background?: string,
  color?: string,
  onClick?: Function
};

const InlineSVG = ({
  size = sizes.iconSize.big,
  icon,
  color = colors.defaultGrey,
  background = "transparent",
  fill = "none",
  onClick = () => {}
}: Props) => (
  <svg
    viewBox="-4 -4 24 24"
    preserveAspectRatio="xMinYMin slice"
    height={size}
    width={size}
    size={size}
    stroke={color}
    fill={fill}
    onClick={onClick}
  >
    {svg(icon)}
  </svg>
);

export default InlineSVG;
