// @flow

import React from "react";

import { Menu as StyledMenu } from "./styles";

import type { Workflow } from "src/types";

type Props = {
  workflow: Workflow,
  handleEdit: Function,
  handleDelete: Function
};

const Menu = ({ workflow, handleEdit, handleDelete }: Props) => (
  <StyledMenu hover>
    {workflow?.settings?.editable !== false && (
      <li onClick={() => handleEdit(workflow)}>Edit Process</li>
    )}
    <li onClick={() => handleDelete(workflow)}>Delete Process</li>
  </StyledMenu>
);

export default Menu;
