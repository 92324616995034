// @flow

import * as React from "react";
import { useSelector } from "react-redux";

import { getIsSrwMobile } from "src/reducers";

import ActiveMain from "src/components/Main";
import Dock from "src/containers/CurrentRoomDock";

const RoomContent = () => {
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  return (
    <>
      <ActiveMain />
      {isSrwMobile && <Dock />}
    </>
  );
};

export default RoomContent;
