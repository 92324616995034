// @flow

import { useSelector, useDispatch } from "react-redux";
import React from "react";

import { toggleWorkflowDeletionModal } from "src/actions/modal";
import { deleteWorkflow } from "src/actions/workflows";
import { getWorkflowTitle } from "src/reducers";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

const WorkflowDeletionModal = () => {
  const dispatch = useDispatch();

  const modalShown = useSelector(
    ({ app }) => app.modal.workflowDeletion.isOpen
  );
  const workflowId = useSelector(
    ({ app }) => app.modal.workflowDeletion.workflowId
  );
  const workflowTitle = useSelector(({ app }) =>
    getWorkflowTitle(app, workflowId)
  );

  const handleClose = () => dispatch(toggleWorkflowDeletionModal(false));

  const handleDelete = () => {
    dispatch(deleteWorkflow(workflowId));
    handleClose();
  };

  return (
    <Modal isCentered onClose={handleClose} isOpen={modalShown}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete process</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{`Are you sure you want to delete ${workflowTitle}? All associated conversations will be cancelled.`}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkflowDeletionModal;
