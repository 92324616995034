// @flow

import React from "react";

import { ListItem, Flex, Box } from "@chakra-ui/react";
import Icons from "src/icons";

type Props = {
  handleClick: Function,
  title: string,
  iconType?: ?string
};

const List = ({ title, handleClick, iconType = null }: Props) => (
  <ListItem
    py="2"
    px="3"
    _hover={{
      background: "gray.100"
    }}
    onClick={handleClick}
    cursor="pointer"
  >
    <Flex alignItems="center" justifyContent="flex-start">
      {iconType && <Icons type={iconType} />}
      <Box ml={iconType ? 2 : 1}>
        <strong>{title}</strong>
      </Box>
    </Flex>
  </ListItem>
);

export default List;
