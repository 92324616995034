// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from "@chakra-ui/react";
import Icon from "src/icons";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import { downloadInstance } from "src/actions/workflows";
import { getComponentPermission, getInstanceFilterId } from "src/reducers";
import { componentPermissions } from "src/constants/roleManagement";
import * as colors from "src/styles/constants/colors";
import { styles } from "./styles";

type Props = {
  getHeaderGroups: Function,
  getRowModel: Function
};

type ExportFormat = "csv" | "xlsx" | "files" | "json";

const ExportMenu = ({ getHeaderGroups, getRowModel }: Props) => {
  const dispatch = useDispatch();
  const id = useSelector(({ app }) => getInstanceFilterId(app));
  const exportEnabled: boolean = useSelector(({ app }) =>
    getComponentPermission(app, componentPermissions.manageViewExport)
  );

  const handleDownload = (
    format: ExportFormat,
    includeFiles: boolean = false
  ) => {
    dispatch(
      downloadInstance(
        id,
        format,
        getHeaderGroups()[0]?.headers || [],
        getRowModel().rows,
        includeFiles
      )
    );
  };

  if (!exportEnabled) return null;
  return (
    <HStack spacing={1}>
      <BlueOutlineButton
        leftIcon={<Icon type="saveAlt" />}
        onClick={() => handleDownload("csv")}
      >
        Download
      </BlueOutlineButton>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Download options"
          icon={<Icon type="down" color={colors.blue} />}
          {...styles.menuButton}
        />
        <MenuList>
          <MenuItem onClick={() => handleDownload("csv")}>
            Download as CSV
          </MenuItem>
          <MenuItem onClick={() => handleDownload("xlsx")}>
            Download as XLSX
          </MenuItem>
          <MenuItem onClick={() => handleDownload("files", true)}>
            Download with files
          </MenuItem>
          <MenuItem onClick={() => handleDownload("json")}>
            Download as JSON (beta)
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default ExportMenu;
