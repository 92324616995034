import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";
import {
  Table as ChakraTable,
  Tr as ChakraTr,
  Td as ChakraTd,
  Th as ChakraTh
} from "@chakra-ui/react";

export const Table = styled(ChakraTable)`
  border-collapse: separate;
  border-spacing: 0;

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  thead th:first-of-type {
    left: 0;
    z-index: 2;
  }
`;

export const Tr = styled(ChakraTr)`
  border-color: ${colors.grey2};
`;

export const Td = styled(ChakraTd)`
  border-right: 1px solid;
  border-color: ${colors.grey2};
  background-color: ${colors.brightLight};
`;

export const Th = styled(ChakraTh)`
  border-right: 1px solid;
  border-color: ${colors.grey2};
  background-color: ${colors.grey28};
`;

export const StickyTd = styled(Td)`
  position: sticky;
  left: 0;
  z-index: 1;
`;
