// @flow

import { connect } from "react-redux";

import Name from "src/components/workflow/Name";

import { getWorkflowName } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  title: getWorkflowName(app, id)
});

export default connect(mapStateToProps)(Name);
