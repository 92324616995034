// @flow

import { connect } from "react-redux";

import Name from "src/components/chatroom/Name";
import {
  getChatroomTitle,
  getChatroomType,
  getSequenceNo,
  getProcessTitle,
  getCanceled
} from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  return {
    name: props.name || getChatroomTitle(app, `${props.id}`),
    type: props.type || getChatroomType(app, `${props.id}`),
    seqNo: getSequenceNo(app, `${props.id}`, props.seqNo),
    processTitle: props.processTitle || getProcessTitle(app, `${props.id}`),
    canceled: props.canceled || getCanceled(app, `${props.id}`)
  };
};

export default connect(mapStateToProps)(Name);
