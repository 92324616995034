// @flow

import React from "react";
import { Flex } from "@chakra-ui/react";
import { SortableContainer } from "react-sortable-hoc";

import * as styles from "../styles";
import SortableItem from "./SortableItem";
import AddComparisonDropdown from "./AddComparisonDropdown";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const SortableList = SortableContainer(
  ({ chartDetails, setChartDetails }: Props) => {
    return (
      <Flex sx={styles.SortableItemWrapper}>
        {
          // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
          chartDetails.series?.map((chart, index) => {
            const { seriesId, tempId, plotType } = chart;
            return (
              <SortableItem
                key={seriesId || tempId}
                id={seriesId || tempId}
                chartDetails={chartDetails}
                plotType={plotType}
                index={index}
                position={index + 1}
                setChartDetails={setChartDetails}
              />
            );
          })
        }

        <AddComparisonDropdown setChartDetails={setChartDetails} />
      </Flex>
    );
  }
);

export default SortableList;
