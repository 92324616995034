// @flow

import * as atypes from "src/constants/actionTypes";

import type { Group, UID } from "src/types";

export const createGroup = () => ({
  type: atypes.CREATE_GROUP_REQUEST,
  payload: {}
});

export const getGroups = () => ({
  type: atypes.GET_GROUPS_REQUEST,
  payload: {}
});

export const setGroupModalAttributes = (options: Object) => ({
  type: atypes.SET_GROUP_MODAL_ATTRIBUTES,
  payload: options
});

export const closeGroupModal = () => ({
  type: atypes.CLOSE_GROUP_MODAL,
  payload: {}
});

export const editGroups = () => ({
  type: atypes.EDIT_GROUP_REQUEST,
  payload: {}
});

export const setCurrentGroup = (group: Group) => ({
  type: atypes.SET_CURRENT_GROUP,
  payload: { group }
});

export const setGroups = (query: Object) => ({
  type: atypes.SET_GROUPS_REQUEST,
  payload: {},
  meta: {
    query
  }
});

export const selectGroupDetailsRow = (row: UID) => ({
  type: atypes.TOGGLE_GROUP_DETAILS_SELECTION,
  payload: { row }
});

export const clearGroupDetailsSelection = () => ({
  type: atypes.CLEAR_GROUP_DETAILS_SELECTION,
  payload: {}
});

export const editGroupMembers = (
  members: Array<UID>,
  remove?: boolean = false
) => ({
  type: atypes.EDIT_GROUP_MEMBERS_REQUEST,
  payload: {
    members,
    remove
  }
});

export const searchGroup = (search: string, settings?: Object = {}) => ({
  type: atypes.SEARCH_GROUPS_REQUEST,
  payload: {
    search,
    settings
  }
});
