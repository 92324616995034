// @flow

import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import { Header, Title, CloseIcon, ModalBody } from "./styles";
import * as colors from "src/styles/constants/colors";
import Icons from "src/icons";

type Props = {
  isOpen: boolean,
  onClose: Function,
  children: any,
  title: string
};

const Modal = ({ isOpen, onClose, children, title }: Props) => {
  return (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Header>
          <Title>{title}</Title>
          <CloseIcon onClick={() => onClose()}>
            <Icons type="close" color={colors.primary} />
          </CloseIcon>
        </Header>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
