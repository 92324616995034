// @flow

import * as React from "react";

type Props = {};

const FileArticle = ({ ...restProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M14 0H2C1.4 0 1 0.4 1 1V15C1 15.6 1.4 16 2 16H14C14.6 16 15 15.6 15 15V1C15 0.4 14.6 0 14 0ZM13 14H3V2H13V14Z" />
      <path d="M8 3H4V7H8V3Z" />
      <path d="M12 4H9V5H12V4Z" />
      <path d="M12 6H9V7H12V6Z" />
      <path d="M12 8H4V9H12V8Z" />
      <path d="M12 10H4V11H12V10Z" />
      <path d="M9 12H4V13H9V12Z" />
    </svg>
  );
};

export default FileArticle;
