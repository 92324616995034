// @flow

import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { useCallback } from "react";

import { HeaderSelector as StyledSelector } from "./styles";
import Checkbox from "src/components/Unifize/Checkbox";
import {
  clearTemplateRowSelection,
  toggleTemplateRowSelection
} from "src/actions/workflows";
import {
  allTemplateRowsSelected,
  isAnyTemplateRowSelected,
  getAllTemplateIds
} from "src/reducers";

const HeaderCheckbox = ({ children }: { children?: React.Node }) => {
  const dispatch = useDispatch();
  const rowSelected: boolean = useSelector(({ app }) =>
    isAnyTemplateRowSelected(app)
  );
  const allSelected: boolean = useSelector(({ app }) =>
    allTemplateRowsSelected(app)
  );
  const allTemplates: Array<number> = useSelector(({ app }) =>
    getAllTemplateIds(app)
  );

  const handleSelect = useCallback(() => {
    if (rowSelected || allSelected) {
      dispatch(clearTemplateRowSelection());
    } else {
      allTemplates.forEach(t => dispatch(toggleTemplateRowSelection(t)));
    }
  }, [rowSelected, allSelected, allTemplates]);

  return (
    <StyledSelector>
      <Checkbox
        id="selectAllWorkflowTemplate"
        checked={allSelected}
        onChange={handleSelect}
        indeterminate={!allSelected && rowSelected}
      />
      {children}
    </StyledSelector>
  );
};

export default HeaderCheckbox;
