// @flow

import { connect } from "react-redux";

import Upload from "src/components/chatroom/Upload";
import {
  uploadFileToChatroom,
  uploadFileToChecklist,
  uploadCustomSignature
} from "src/actions/file";
import { uploadTempAttachment } from "src/actions/email";
import { getIsChatroomUploadingFile } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  isUploading: getIsChatroomUploadingFile(app, props.roomId)
});

export default connect(mapStateToProps, {
  uploadFileToChatroom,
  uploadTempAttachment,
  uploadFileToChecklist,
  uploadCustomSignature
})(Upload);
