// @flow

import React from "react";

const BlueTick = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99984 0.666626C4.39984 0.666626 0.666504 4.39996 0.666504 8.99996C0.666504 13.6 4.39984 17.3333 8.99984 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99996C17.3332 4.39996 13.5998 0.666626 8.99984 0.666626ZM7.33317 13.1666L3.1665 8.99996L4.3415 7.82496L7.33317 10.8083L13.6582 4.48329L14.8332 5.66663L7.33317 13.1666Z"
      fill="#2F80ED"
    />
  </svg>
);

export default BlueTick;
