// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {};

const ColumnChart = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={colors.inkLightest}
      viewBox="0 0 16 16"
      {...restProps}
    >
      <path d="M14.774 14.391H1.226A.231.231 0 011 14.155c0-.131.101-.237.226-.237h13.548c.125 0 .226.106.226.237 0 .13-.1.236-.226.236zM4.388 5.87h-2.71a.231.231 0 00-.226.236v6.154c0 .131.101.237.226.237h2.71a.231.231 0 00.225-.237V6.106c0-.131-.1-.237-.225-.237zM9.355 1.61h-2.71a.231.231 0 00-.225.236v10.415c0 .131.1.237.226.237h2.71a.231.231 0 00.225-.237V1.846c0-.13-.1-.237-.226-.237zM14.323 8.238h-2.71a.231.231 0 00-.225.237v3.787c0 .131.1.237.226.237h2.71a.231.231 0 00.225-.237V8.475a.231.231 0 00-.226-.237z"></path>
    </svg>
  );
};

export default ColumnChart;
