// @flow

import React from "react";

import Header from "./header";
import Conversations from "./Conversations";

import { ChatRooms as StyledChatRooms } from "src/styles";
import * as styles from "./styles";

const ChatRooms = () => (
  <StyledChatRooms>
    <div style={styles.ChatroomsContainer}>
      <Header />
      <Conversations />
    </div>
  </StyledChatRooms>
);

export default ChatRooms;
