// @flow

import { StackProps } from "@chakra-ui/react";

export const container: StackProps = {
  width: "100%",
  height: "100%",
  justifyContent: {
    base: "start",
    md: "center"
  }
};
