// @flow

import React, { PureComponent } from "react";
import moment from "moment";

import { Timestamp as StyledTimestamp } from "src/styles";

type Props = { timestamp: Date };

// Using PureComponent to avoid re-rendering
class Timestamp extends PureComponent<Props> {
  render() {
    const { timestamp } = this.props;
    return (
      <StyledTimestamp>{moment(timestamp).format("hh:mm a")}</StyledTimestamp>
    );
  }
}

export default Timestamp;
