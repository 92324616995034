// @flow

import { useSelector } from "react-redux";
import React from "react";
import { Tr, Td, Tag, TagLabel, Input, IconButton } from "@chakra-ui/react";

import { getChecklistFieldType, getChecklistFieldLabel } from "src/reducers";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";
import {
  checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import * as colors from "src/styles/constants/colors";

import { fieldTagStyles, inputFieldStyles } from "../../styles";

type Props = {
  fieldId: number,
  seqNo: number,
  selectFormField: Function
};

const FormField = ({ fieldId, seqNo, selectFormField }: Props) => {
  const type = useSelector(({ app }) => getChecklistFieldType(app, fieldId));
  const label = useSelector(({ app }) => getChecklistFieldLabel(app, fieldId));

  return (
    <Tr>
      <Td>{seqNo}</Td>
      <Td>
        <Tag
          size="md"
          sx={fieldTagStyles}
          variant="solid"
          bgColor={checklistFieldIcons[type].hexCode}
        >
          <Icon
            type={checklistFieldIcons[type].icon}
            width="16px"
            height="16px"
            fill="#fff"
          />
          <TagLabel ml="4px">{checklistFieldNames[type]}</TagLabel>
        </Tag>
      </Td>
      <Td>
        <Input
          type="text"
          value={label}
          style={inputFieldStyles}
          placeholder="Field Name"
          isReadOnly
        />
      </Td>
      <Td>
        <Tooltip title="Conditions" placement="right">
          <IconButton
            display="flex"
            variant="unstyled"
            aria-label="Conditions"
            onClick={() => selectFormField(fieldId)}
            icon={<Icon type="conditions" fill={colors.blue} />}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default FormField;
