// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import {
  getUserEmail,
  getUsersById,
  getUsersWithRoles,
  getMessageData,
  getRolesWithComponentPermission
} from "src/reducers";

import { addRecipient, setEmailData, saveEmailData } from "src/actions/email";
import { setEmailModalStage } from "src/actions/modal";
import * as emailModalStages from "src/constants/emailModalStages";

import { capitalize } from "src/utils";
import { EmailReply, EmailForward } from "../styles";
import type { UsersById, EmailModalData, UID, UnifizeChat } from "src/types";

type Props = {
  id: string,
  email: ({ from: UID } & EmailModalData) | null,
  openEmailModal: Function,
  saveEmailData: Function,
  userEmail: string,
  extraInvitees: UsersById,
  usersById: UsersById,
  addRecipient: Function,
  setEmailData: Function,
  messageData: UnifizeChat
};

const Email = ({
  email,
  openEmailModal,
  saveEmailData,
  userEmail,
  extraInvitees,
  usersById,
  addRecipient,
  setEmailData,
  messageData
}: Props) => {
  const initiateEmailReply = useCallback(() => {
    if (!email) return;

    setEmailData({
      prop: "subject",
      value: !email.subject.startsWith("Re:")
        ? `Re: ${email.subject}`
        : email.subject
    });

    if (messageData.data) {
      // For more info refer the link below
      // https://github.com/unifize/unifize-server/pull/1385

      const { messageId } = messageData.data;

      let references = messageData.data.references;

      // If there are no references then the message
      // to which the user is replying becomes the first
      // reference and the thread begins
      // Else the message to which the user is replying to
      // gets added to the list of references so that the
      // reply to the email stays in the current email thread
      if (R.isNil(references)) {
        references = messageId;
      } else {
        references = `${references},${messageId}`;
      }

      setEmailData({
        prop: "headers",
        value: {
          inReplyTo: messageId,
          references
        }
      });
    }

    const userId = email.from;

    addRecipient({
      prop: "to",
      value: extraInvitees[userId]
        ? {
            email: extraInvitees[userId].email,
            role: extraInvitees[userId].orgRole
          }
        : usersById[userId]
          ? userId
          : { email: userId }
    });

    openEmailModal();
  }, [email, saveEmailData, openEmailModal, messageData]);

  const initiateEmailForward = useCallback(() => {
    if (!email) return;

    const subject = `Fwd: ${email.subject}`;

    let message =
      "----------------------------------------------------------------------\n\n";

    message += `From: ${userEmail},\n`;

    [("to", "cc", "bcc", "subject")].forEach(prop => {
      const value = email[prop];
      message += value
        ? `${capitalize(prop)}: ${
            Array.isArray(value) ? R.join(", ", value) : value
          },\n`
        : "";
    });

    if (email.message) message += `\n${email.message}`;

    const emailData = {
      to: [],
      cc: [],
      bcc: [],
      subject,
      message
    };

    saveEmailData(emailData);
    openEmailModal();
  }, [email, saveEmailData, openEmailModal]);

  return (
    <>
      <EmailReply onClick={initiateEmailReply}>Reply as Email</EmailReply>
      <EmailForward onClick={initiateEmailForward}>
        Forward as Email
      </EmailForward>
    </>
  );
};

const mapStateToProps = ({ app }, { email, id }: Props) => {
  let userEmail = "";
  if (email && email.from) userEmail = getUserEmail(app, email.from);
  return {
    userEmail,
    messageData: getMessageData(app, id),
    usersById: getUsersById(app),
    extraInvitees: getUsersWithRoles(app, [
      ...getRolesWithComponentPermission(app, "prompt-invite"),
      "contact"
    ])
  };
};

const mapDispatchToProps = {
  openEmailModal: () => setEmailModalStage(emailModalStages.WRITE),
  setEmailData,
  addRecipient,
  saveEmailData
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
