// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  isProcessTablePaginated: boolean,
  toggleProcessTablePagination: () => void
};

const isProcessTablePaginated = ({
  isProcessTablePaginated,
  toggleProcessTablePagination
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="isProcessTablePaginated"
        checked={isProcessTablePaginated}
        handleChange={toggleProcessTablePagination}
      />
      Enable pagination in process table
    </Label>
  </InlineFormGroup>
);

export default isProcessTablePaginated;
