// @flow

import * as R from "ramda";

import { backendUrl } from "src/config/firebase";
import { toHomeScreenURL } from "src/utils";

/**
 * Get homescreen data for given user
 *  @returns homescreen data
 */
export const getHomescreen = async () => {
  const response = await fetch(`${backendUrl}/home`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getMyConversationChartJSON = async (filter: Object) => {
  const urlObject = {
    ...R.omit(["exclude", "workflow"], filter),
    [filter.exclude ? "excludedProcess" : "includedProcess"]: filter.workflow
  };
  const urlParams = toHomeScreenURL(urlObject);

  const response = await fetch(
    `${backendUrl}/conversation-chart?${urlParams}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  return response.json();
};
