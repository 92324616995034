// @flow

import React from "react";

type Props = {};

const CommonChecklist = ({ ...restProps }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      stroke="#90CDF4"
      {...restProps}
    >
      <rect
        width="12.5"
        height="7.833"
        x="1.75"
        y="3.75"
        strokeWidth="1.5"
        rx="0.917"
      ></rect>
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M4.911 6.083L4.911 9.25"
      ></path>
    </svg>
  );
};

export default CommonChecklist;
