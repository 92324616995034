// @flow

import React from "react";
import { connect } from "react-redux";

import { getUser } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const User = ({ user }: Props) => {
  return <div>{user.displayName || user.email}</div>;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.id)
});

export default connect(mapStateToProps)(User);
