// @flow

import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@chakra-ui/react";
import * as R from "ramda";
import Input from "./Input";
import {
  Search as StyledSearch,
  SearchWrapper,
  Clear,
  SearchbarIconWrapper
} from "./styles";
import {
  setText,
  clearText,
  toggleFilterDialog,
  updateFilter
} from "src/actions/filter";
import {
  getFilterText,
  getIsQuickFilterOpen,
  getCurrentFilter
} from "src/reducers";
import { initialFilterState } from "src/reducers/chatRooms";

import type { AppState, CurrentFilter } from "src/types";

import Icons from "src/icons";
import { iconSize } from "src/styles/constants/size";

type Props = {
  text: string,
  _setText: Function,
  _clearText: Function,
  _toggleFilterDialog: Function,
  _updateFilter: Function,
  isQuickFilterOpen: boolean,
  currentFilter: CurrentFilter
};

const Search = ({
  text,
  _setText,
  _clearText,
  _toggleFilterDialog,
  _updateFilter,
  isQuickFilterOpen,
  currentFilter
}: Props) => {
  const textInput = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const handleSearch = (value: string) => {
    _setText(value);
    _updateFilter({
      ...currentFilter,
      text: value
    });
  };

  const clear = () => {
    _clearText();
    textInput.current && textInput.current.focus();
  };

  const onFocus = () => {
    textInput.current && textInput.current.focus();
    // If quickfilter already open don't toggle quick filter
    if (!isQuickFilterOpen) {
      _toggleFilterDialog();
    }
  };

  const isQuickFilter = R.equals(currentFilter, initialFilterState);

  const showActiveFilter = !isQuickFilter || isHovered;

  return (
    <StyledSearch>
      <SearchWrapper>
        <Icons type="searchLense" />
        <Input
          title={text}
          handleSearch={handleSearch}
          outerRef={textInput}
          onFocus={onFocus}
        />
      </SearchWrapper>
      <SearchbarIconWrapper>
        <Clear onClick={clear}>
          {text ? <Icons type="close" size={iconSize.space_l} /> : <span />}
        </Clear>

        <Box
          onClick={_toggleFilterDialog}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Icons
            onClick={_toggleFilterDialog}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            type={showActiveFilter ? "activeQuickFilter" : "defaultQuickFilter"}
          />
        </Box>
      </SearchbarIconWrapper>
    </StyledSearch>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  text: getFilterText(app),
  isQuickFilterOpen: getIsQuickFilterOpen(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _setText: setText,
  _clearText: clearText,
  _toggleFilterDialog: toggleFilterDialog,
  _updateFilter: updateFilter
})(Search);
