// @flow
import React from "react";
import { useSelector } from "react-redux";
import {
  getChatroomType,
  getRoomMembers,
  getUserMentions,
  getGroupMentions
} from "src/reducers";
import type { RoomId, UID, MessageText } from "src/types";
import TextInputWithMentions from "./TextInputWithMentions";

type Props = {
  roomId: RoomId,
  handleSendClick: Function,
  currentMessage: MessageText
};

const TextInput = ({ roomId, handleSendClick, currentMessage }: Props) => {
  const conversationType = useSelector(({ app }) =>
    getChatroomType(app, roomId)
  );

  const members = useSelector(({ app }) => getRoomMembers(app));
  const users = useSelector(({ app }) => getUserMentions(app));
  const groups = useSelector(({ app }) => getGroupMentions(app));
  const direct = members.map((uid: UID) => {
    return {
      id: uid,
      type: "user",
      display: `${users.find(user => user.id === uid)?.display}`
    };
  });

  const all = [
    {
      id: "!everyone",
      display: "@All"
    },
    ...[...users, ...groups].sort((a, b) => a.display.localeCompare(b.display))
  ];

  return (
    <TextInputWithMentions
      mentions={conversationType === "direct" ? direct : all}
      handleSendClick={handleSendClick}
      roomId={roomId}
      currentMessage={currentMessage}
    />
  );
};

export default TextInput;
