// @flow

import styled from "@emotion/styled";

import { Small } from "src/styles/modal";
import { Form as StyledForm, ModalFormBody } from "src/styles/form";

const Updates = styled(Small)``;

const Form = styled(StyledForm)`
  > h4 {
    text-transform: none;
  }
  svg {
    position: absolute;
    top: -5px;
    right: -6px;
  }
`;

const FormBody = styled(ModalFormBody)`
  padding: 0 0.7em;
`;

export { Updates, Form, FormBody };
