// @flow

import * as R from "ramda";
import { toggleListItem, multiSelectHandler } from "src/utils";

const formFieldTypes = {
  event: "event",
  direct: "direct",
  number: "number",
  checkbox: "checkbox",
  multiSelect: "multiSelect",
  multiSelect2: "multiSelect2"
};

export type FormFieldTypes = $Values<typeof formFieldTypes>;

type Props = {
  data: Object,
  path: (string | number)[],
  type: FormFieldTypes,
  onChange: Function,
  render: Function
};

export const FormField = ({ data, path, onChange, type, render }: Props) => {
  const changeHandlers = {
    event: e => onChange(type, path, e.target.value),
    direct: value => onChange(type, path, value),
    number: value => onChange(type, path, Number(value)),
    checkbox: e => onChange(type, path, e.target.checked),
    multiSelect: value => onChange(type, path, value),
    multiSelect2: (action, item) => onChange(type, path, { action, item })
  };

  return render({ value: R.path(path, data), onChange: changeHandlers[type] });
};

export const fieldUpdater = (
  prev: any,
  type: FormFieldTypes,
  path: (string | number)[],
  data: any
) => {
  let newSettings = R.clone(prev);

  switch (type) {
    case formFieldTypes.event:
    case formFieldTypes.direct:
    case formFieldTypes.number:
    case formFieldTypes.checkbox:
      // $FlowFixMe
      newSettings = R.assocPath(path, data, prev);
      break;

    case formFieldTypes.multiSelect:
      // $FlowFixMe
      newSettings = R.assocPath(
        path,
        toggleListItem({
          list: R.path(path, prev),
          item: data
        }),
        prev
      );
      break;

    case formFieldTypes.multiSelect2:
      // $FlowFixMe
      newSettings = R.assocPath(
        path,
        multiSelectHandler({
          action: data.action,
          list: R.path(path, prev),
          item: data.item
        }),
        prev
      );
      break;

    default:
      break;
  }

  return newSettings;
};
