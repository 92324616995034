// @flow

import React from "react";

import Header from "./Header";
import Body from "./Body";
import { Table as StyledTable, TableContainer } from "./styles";

const Table = () => {
  return (
    <TableContainer>
      <StyledTable>
        <Header />

        <Body />
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
