// @flow

import type {
  StackProps,
  FlexProps,
  TextProps,
  ImageProps
} from "@chakra-ui/react";
import { mobileBreakpoint } from "src/constants";
import LeftPaneBg from "src/img/org-clone-page/left-pane-bg.svg";

export const container: FlexProps = {
  width: "100vw",
  height: "100vh",
  overflow: "auto",
  flexDirection: "row",
  [mobileBreakpoint]: {
    flexDirection: "column"
  }
};

export const leftContainer: StackProps = {
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  width: "100%",
  flex: 1,
  background: `url(${LeftPaneBg})}`,
  backgroundSize: "contain"
};

export const logo: ImageProps = {
  width: "125.5px",
  marginTop: "54px",
  marginBottom: "125px",
  [mobileBreakpoint]: {
    marginBottom: "25px"
  }
};

export const orgTitleAndDescription: StackProps = {
  width: "70%",
  textAlign: "center",
  marginBottom: "18px"
};

export const orgTitle: TextProps = {
  color: "white"
};

export const orgDescription: TextProps = {
  color: "white"
};

export const formContainer: StackProps = {
  height: "100%",
  width: "100%",
  alignItems: "start",
  justifyContent: "space-between",
  flex: 1,
  pt: "43px",
  pb: "96px",
  pl: "72px",
  pr: "50px",
  [mobileBreakpoint]: {
    px: "20px",
    py: "40px",
    gridGap: "40px"
  }
};

export const bottomPart: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const actionButtonsContainer: StackProps = {
  width: "270px",
  height: "48px",
  [mobileBreakpoint]: {
    width: "100%"
  }
};
