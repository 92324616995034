// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, MenuGroup, SkeletonText } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { getForm, getFormChecklistFields } from "src/reducers";
import { fetchForm } from "src/actions/forms";
import useBoolean from "src/hooks/useBoolean";
import FormFields from "./FormFields";

type Props = {
  formId: number,
  mappedFieldIndex: number,
  clickHandler: Function,
  checklistFormId: number
};

const Form = ({
  formId,
  mappedFieldIndex,
  clickHandler,
  checklistFormId
}: Props) => {
  const { value: isFormOpen, toggleBoolean: toggleForm } = useBoolean();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formDetails = useSelector(({ app }) => getForm(app, formId));
  const formFields = useSelector(({ app }) =>
    getFormChecklistFields(app, `${formId}`)
  );

  const formClickHandler = async () => {
    try {
      toggleForm();
      setLoading(true);
      if (formFields.length === 0) {
        await dispatch(fetchForm(formId, false));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MenuGroup
      onClick={formClickHandler}
      as={Button}
      sx={{ mx: "2.5rem" }}
      isDisabled={loading}
      rightIcon={isFormOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      title={formDetails.title}
    >
      {isFormOpen &&
        (loading || formFields.length === 0 ? (
          <SkeletonText mx="2.5rem" noOfLines={2} />
        ) : (
          <>
            {formFields.map(field => (
              <FormFields
                key={field}
                formFieldId={field}
                mappedFieldIndex={mappedFieldIndex}
                clickHandler={clickHandler}
                checklistFormId={checklistFormId}
                formId={formId}
              />
            ))}
          </>
        ))}
    </MenuGroup>
  );
};

export default Form;
