// @flow

import { connect } from "react-redux";

import Search from "src/components/workflow/Search";
import { clearError } from "src/actions";
import * as actions from "src/actions/workflows";
import { getWorkflowIdsForNew } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  error: app.error.inlineWorkflow,
  workflows: getWorkflowIdsForNew(app)
});

export default connect(mapStateToProps, { ...actions, clearError })(Search);
