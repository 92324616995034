// @flow

import { connect } from "react-redux";
import React from "react";

import { getPrincipalChecklistFieldName } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  title: string
};

const Name = ({ title }: Props) => <>{title}</>;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  title: getPrincipalChecklistFieldName(app, props.fieldId)
});

export default connect(mapStateToProps)(Name);
