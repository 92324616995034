// @flow

import * as React from "react";

import { Spinner } from "@chakra-ui/react";

import { Text, HStack } from "@chakra-ui/react";
import { SharepointWrapper, IconBox } from "../styles";

type Props = {
  isLoading: boolean,
  id: string,
  title: string,
  onClick: Function,
  icon: string,
  isActive: boolean
};

const SharepointItem = ({
  isLoading,
  id,
  title,
  onClick,
  icon,
  isActive
}: Props) => {
  return (
    <SharepointWrapper disabled={isLoading || !isActive} onClick={onClick}>
      <HStack>
        <IconBox>
          <img src={icon} />
        </IconBox>
        <Text textStyle="popoverMenuItem">{title}</Text>
      </HStack>
      {id === "sharepoint" && isLoading && (
        <Spinner color="blue.500" size="sm" />
      )}
    </SharepointWrapper>
  );
};

export default SharepointItem;
