// @flow

import styled from "@emotion/styled";

import { Table as DefaultTable } from "src/styles/table";

const TableContainer = styled.div`
  width: calc(100vw - 4em);
  height: calc(100vh - 16em);
  overflow: auto;
  position: relative;
  z-index: 0;
`;

const Table = styled(DefaultTable)`
  > thead {
    > td:first-of-type {
      width: 20em;
    }
  }
  > tbody {
    max-height: calc(100vh - 14em);
    td {
      white-space: nowrap;
    }
  }
`;

export { TableContainer, Table };
