// @flow

import * as R from "ramda";
import * as atypes from "src/constants/actionTypes";

import type { Department, Action } from "src/types";

const departments = (
  state: Array<Department> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SERVER_AUTH_SUCCESS:
      if (payload.department) {
        return [{ [payload.department]: [payload.departmentName] }];
      }
      return state;
    case atypes.GET_DEPARTMENTS_SUCCESS:
    case atypes.SYNC_DEPARTMENTS_SUCCESS:
      return payload.departments;
    case atypes.CREATE_DEPARTMENT:
      return [payload.department, ...state];
    default:
      return state;
  }
};

export default departments;

export const getDepartment = (state: Array<Department>, id: ?number) =>
  R.head(
    R.filter(
      (department: Department) => `${department.id}` === `${id || ""}`,
      state
    ) || []
  ) || {
    name: null
  };
