// @flow

import styled from "@emotion/styled";
import { rgba } from "polished";

import { ScrollableBox } from "src/styles/box";
import appLocation from "src/constants/location";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

export const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.color ? props.color : colors.primary)};
  color: ${props =>
    props.textColor ? props.textColor : rgba(colors.secondary, 0.2)};
  font-size: 0.625rem;
  padding: 2px 8px;
  border-radius: ${radius.small};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  text-align: center;
  text-transform: uppercase;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  ${props =>
    props.location === appLocation.chatroomHeader &&
    ` min-width: 4.375rem;
      max-width: ${props.vw > 1200 ? "12.5rem" : "4.375rem"};
      padding: 2px 0.75rem; 
      width: unset;
  `};
`;

export const Status = styled.div`
  position: relative;
`;

export const Options = styled(ScrollableBox)`
  z-index: 3;
  position: absolute;
  font-size: 0.625rem;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  padding: 1.6em;
  padding-bottom: 0.8em;
  width: 12.5rem;
  max-height: 75vh;
  overflow: auto;
`;

export const SubHeading = styled.li`
  color: ${colors.unifizeBlue};
  letter-spacing: 1px;
`;

export const MenuItem = styled.li`
  margin: 1.25em 0;
  display: flex;
  > ${Value} {
    width: 92%;
    > span {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  align-items: center;
  justify-content: space-between;
`;

export const DueStatus = styled.div`
  > div {
    width: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: ${spacing.space_s};
    ${props => props.completed && `color: ${colors.success}`};
  }
`;

export const StatusTextContainer = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ArchiveIconContainer = styled.span`
  width: auto !important;
  display: inline !important;
  line-height: 75%;
  padding: 0 4px;
  margin: 0;
`;
