// @flow

import * as atypes from "src/constants/actionTypes";

import type { RoomId } from "src/types";

export const sendUserInviteRequest = (payload: {
  email: string,
  orgRole: string,
  chatRoomId: RoomId,
  displayName: string
}) => ({
  type: atypes.INVITE_USER_REQUEST,
  payload
});

export const clearInvitedUser = () => ({
  type: atypes.CLEAR_INVITED_USER,
  payload: {}
});

export const inviteUserFromChecklist = (payload: {
  email: string,
  displayName: string,
  fieldId: number,
  roomId: RoomId,
  checklistId: number,
  multiple: boolean
}) => ({
  type: atypes.INVITE_USER_FROM_CHECKLIST_REQUEST,
  payload
});
