// @flow

import React from "react";
import moment from "moment";

import { Cell, GreyCell, BlueCell, Active } from "./styles";
import User from "./User";

import type { UserAnalyticsRow } from "src/types";

type Props = {
  ...UserAnalyticsRow
};

const Row = ({
  uid,
  createdAll,
  createdOverdue,
  createdCompleted,
  createdPending,
  pendingAll,
  pendingOverdue,
  pendingUnread,
  pendingOwner,
  pendingCritical,
  completedAll,
  completedOwner,
  completedCreator,
  completedBy,
  lastActive
}: Props) => {
  return (
    <>
      <User uid={uid} />
      <Cell>{createdAll}</Cell>
      <Cell>{createdOverdue}</Cell>
      <Cell>{createdCompleted}</Cell>
      <Cell>{createdPending}</Cell>

      <GreyCell>{pendingAll}</GreyCell>
      <GreyCell>{pendingOverdue}</GreyCell>
      <GreyCell>
        <Active>{pendingUnread}</Active>
      </GreyCell>
      <GreyCell>{pendingOwner}</GreyCell>
      <GreyCell>{pendingCritical}</GreyCell>

      <Cell>{completedAll}</Cell>
      <Cell>{completedOwner}</Cell>
      <Cell>{completedCreator}</Cell>
      <Cell>{completedBy}</Cell>
      <BlueCell>
        {lastActive ? moment(lastActive).format("MMM DD, YYYY hh:mm a") : "_"}
      </BlueCell>
    </>
  );
};

export default Row;
