// @flow

import React, { useState, useCallback } from "react";

import { Rules as StyledRules } from "./styles";
import Dropdown from "./Dropdown";
import rules from "src/constants/promptRules";

type Props = {
  item: any,
  itemId: number,
  updateItem: Function
};

const Rules = ({ item, itemId, updateItem }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const updateRuleId = useCallback(
    val => {
      if (item.uid) {
        updateItem({ uid: item.uid, ruleId: val }, itemId);
      } else if (item.role) {
        updateItem({ role: item.role, ruleId: val }, itemId);
      }
      setShowDropdown(false);
    },
    [item, updateItem]
  );
  return (
    <StyledRules onClick={() => setShowDropdown(true)} highlight={showDropdown}>
      {rules[item.ruleId - 1]}
      {showDropdown ? (
        <Dropdown
          setShowDropdown={setShowDropdown}
          updateRuleId={updateRuleId}
        />
      ) : null}
    </StyledRules>
  );
};

export default Rules;
