// @flow

import React from "react";
import { connect } from "react-redux";

import { Tag } from "./styles";
import { getCurrentRoomOwner } from "src/reducers";

import type { AppState, UID } from "src/types";

type Props = {
  uid: UID,
  owner: UID
};

const Owner = ({ uid, owner }: Props) => (
  <>{uid === owner ? <Tag>OWNER</Tag> : null}</>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  owner: getCurrentRoomOwner(app)
});

export default connect(mapStateToProps)(Owner);
