// @flow

import React from "react";
import { HStack, Text, Portal } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getChatroomPrivacy } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";
import PrivacyModal from "./PrivacyModal";

import type { RoomId } from "src/types";
import * as styles from "../styles";

type Props = {
  roomId: RoomId,
  isSrw?: boolean,
  portalRef: null | React$ElementRef<Portal>,
  closeMoreMenu?: () => void
};

const PrivacyItem = ({ roomId, isSrw, portalRef, closeMoreMenu }: Props) => {
  const privacy = useSelector(({ app }) => getChatroomPrivacy(app, roomId));
  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean();

  return (
    <>
      <HStack
        onClick={() => {
          openModal();
          closeMoreMenu && closeMoreMenu();
        }}
        sx={styles.MenuItem}
        tabIndex={0}
        onKeyPress={e => {
          e.stopPropagation();
          if (e.key === "Enter") {
            openModal();
            closeMoreMenu && closeMoreMenu();
          }
        }}
        data-cy="privacy"
      >
        <>
          <Icon type="lock" width="16px" height="16px" />
          <Text>Privacy</Text>
          {privacy === "full" ? (
            <Text>(Hide everything)</Text>
          ) : (
            privacy === "content" && <Text>(Hide only chat content)</Text>
          )}
        </>
      </HStack>
      <PrivacyModal
        roomId={roomId}
        isSrw={isSrw}
        portalRef={portalRef}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default PrivacyItem;
