// @flow

const rules = [
  "Everytime they view the conversation",
  "When they get added to the conversation",
  "When they close the conversation",
  "When the previous step is completed"
];

export default rules;

export const roles = ["participant", "creator", "owner"];
