// @flow

import * as React from "react";
import { VStack, Box, Input, InputProps } from "@chakra-ui/react";
import Downshift from "downshift";
import type {
  DownshiftRef,
  GetItemPropsOptions,
  OnInputValueChange,
  DownshiftProps
} from "downshift";
import type { UseBooleanReturn } from "src/types";
import { keyboardHandler } from "src/utils/charts";
import * as styles from "./styles";

export type OnItemClick = (args: {
  id: string | number
}) => void;

type DropdownChildrenProps = {
  onItemClick?: OnItemClick,
  getItemProps: GetItemPropsOptions,
  highlightedIndex: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

type Props = {|
  children: (props: DropdownChildrenProps) => React.Node,
  onItemClick?: OnItemClick,
  query?: string,
  onQueryChange?: OnInputValueChange,
  isOpen: $PropertyType<UseBooleanReturn, "value">,
  onOuterClick?: DownshiftProps.onOuterClick,
  inputFieldSize?: InputProps.size,
  handleKeyboardSelect?: DownshiftProps.onSelect,
  placeholder?: string,
  customStyles?: {
    DropdownWrapper: { [string]: string | number },
    DropdownInputField: { [string]: string | number },
    DropdownMenuWrapper: { [string]: string | number }
  },
  position?: string
|};

const Dropdown = React.forwardRef<DownshiftProps, Props>(
  (
    {
      children,
      onItemClick,
      query,
      onQueryChange,
      isOpen,
      onOuterClick,
      inputFieldSize = "sm",
      handleKeyboardSelect,
      placeholder,
      position = "relative",
      customStyles
    }: Props,
    ref: DownshiftRef
  ) => {
    const stateReducer = (state, changes) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownArrowUp: {
          if (changes.itemsLength) {
            let nextIndex =
              state.highlightedIndex === null
                ? changes.itemsLength - 1
                : state.highlightedIndex;

            nextIndex = 0 < state.highlightedIndex ? nextIndex - 1 : 0;
            return {
              ...changes,
              highlightedIndex: nextIndex
            };
          } else return changes;
        }

        case Downshift.stateChangeTypes.keyDownArrowDown: {
          if (changes.itemsLength) {
            let nextIndex =
              state.highlightedIndex === null ? 1 : state.highlightedIndex + 1;
            nextIndex =
              nextIndex >= changes.itemsLength
                ? changes.itemsLength - 1
                : nextIndex;
            return {
              ...changes,
              highlightedIndex: nextIndex
            };
          } else return changes;
        }

        case Downshift.stateChangeTypes.keyDownEnter: {
          return { ...state, highlightedIndex: changes.highlightedIndex };
        }

        default:
          return changes;
      }
    };

    const scrollIntoView = node => {
      if (node) node.scrollIntoView({ block: "center" });
    };

    return (
      <Downshift
        ref={ref}
        isOpen={isOpen}
        onOuterClick={onOuterClick}
        stateReducer={stateReducer}
        scrollIntoView={scrollIntoView}
        onSelect={handleKeyboardSelect}
        onInputValueChange={onQueryChange}
        inputValue={query}
        initialHighlightedIndex={0}
        defaultHighlightedIndex={0}
      >
        {({
          getRootProps,
          getMenuProps,
          getInputProps,
          getItemProps,
          highlightedIndex
        }) => {
          return (
            <Box position={position} {...getRootProps()}>
              {isOpen && (
                <VStack
                  spacing="0"
                  sx={{
                    ...styles.DropdownMenuWrapper,
                    ...customStyles?.DropdownMenuWrapper
                  }}
                  {...getMenuProps()}
                >
                  {query !== undefined && onQueryChange && (
                    <Input
                      placeholder={placeholder || "Search"}
                      sx={styles.DropdownInputField}
                      size={inputFieldSize}
                      {...getInputProps({})}
                      autoFocus
                    />
                  )}
                  {children({
                    onItemClick: onItemClick && onItemClick,
                    getItemProps,
                    highlightedIndex,
                    scrollIntoView,
                    keyboardHandler
                  })}
                </VStack>
              )}
            </Box>
          );
        }}
      </Downshift>
    );
  }
);

Dropdown.displayName = "Dropdown";

export default Dropdown;
