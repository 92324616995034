// @flow

import React from "react";

const Ascend = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill="#BBB" d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />
    <path fill="#2F80ED" d="M5 6.99L9 3l4 3.99h-3V14H8V6.99H5z" />
  </svg>
);

export default Ascend;
