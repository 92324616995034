// @flow

import React from "react";

const OrgDark = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6L12 1L22 6V8H2V6ZM7 10H4V17H7V10ZM13.5 17V10H10.5V17H13.5ZM2 22V19H22V22H2ZM20 10H17V17H20V10Z"
      fill="#8A6116"
    />
  </svg>
);

export default OrgDark;
