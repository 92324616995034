// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import { Input } from "src/styles/input";

import { ChatMessageContainer } from "src/components/messages/Chat/styles";

export const Prompt = styled.div`
  margin-top: 1.6em;
  padding: 0 0.8em;
  display: flex;
  flex-direction: row;
`;

export const ReminderPrompt = styled(ChatMessageContainer)`
  display: flex;
  flex-direction: row;
  margin-top: ${ifProp("first", `1em`, "0.67em")};
  padding: ${ifProp("showSelect", "0 0 0 3em")};
`;

export const AssistantIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 0.5em;
  background: ${colors.brightLight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AssistantTitle = styled.div`
  font-size: 14px
  font-weight: bold;
  color: ${colors.primary}e6;
`;

export const AssistantInfo = styled.div`
  font-size: 12px
  color: ${colors.secondary};
  line-height: 2em;
  > span {
    color: ${colors.active};
  }
`;

export const Checklist = styled.div`
  width: 100%;
  background: ${colors.grey0};
  border-radius: ${radius.small};
  padding: 0.6em;
  > div:first-of-type {
    position: relative;
    font-size: 13px
    color: ${colors.primary};
    margin-bottom: 0.6em;
    > svg {
      position: absolute;
      right: 0;
    }
  }
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  > div {
    width: 100%;
  }
`;

export const Text = styled(Input)``;

export const Dismiss = styled.div`
  font-size: 13px
  font-weight: bold;
  line-height: 20px;
  color: ${colors.active};
  margin-top: ${spacing.space_m};
  cursor: pointer;
`;

export const Toast = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
