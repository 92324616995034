// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

export const toggleTotal = () => ({
  type: atypes.TOGGLE_USER_ANALYTICS_TOTAL,
  payload: {}
});

export const toggleAverage = () => ({
  type: atypes.TOGGLE_USER_ANALYTICS_AVERAGE,
  payload: {}
});

export const setUserAnalytics = (meta: { query?: string }): Action => ({
  type: atypes.SET_USER_ANALYTICS_REQUEST,
  payload: {},
  meta
});
