// @flow

import { connect } from "react-redux";
import React, { useMemo } from "react";
import ColorHash from "color-hash";

import {
  GroupName as StyledGroupName,
  Initial as StyledInitial
} from "./styles";
import { getGroup } from "src/reducers";
import { customHash } from "src/utils";

import type { AppState, Group } from "src/types";

type Props = {
  group: Group
};

const GroupName = ({ group }: Props) => {
  const color = useMemo(() => {
    const colorHashObj = new ColorHash({
      hash: customHash,
      lightness: [0.2, 0.3, 0.4, 0.5]
    });
    return colorHashObj.hex(group.title || "");
  }, [group]);

  return (
    <StyledGroupName title={group.title}>
      {group.photoUrl ? (
        <img src={group.photoUrl} alt="" />
      ) : (
        <StyledInitial color={color} />
      )}
      <span>{group.title}</span>
    </StyledGroupName>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  group: getGroup(app, props.id)
});

export default connect(mapStateToProps)(GroupName);
