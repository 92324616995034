// @flow

import styled from "@emotion/styled";

import { ListItemMedium, SmallBox } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

export const FieldSelect = styled.div`
  margin-bottom: ${spacing.space_s};
`;

export const Item = styled(ListItemMedium)`
  max-width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: initial;
`;

export const Selection = styled.div`
  width: 100%;
  border-radius: ${radius.small};
  min-width: 232px;
  padding: ${spacing.space_inset_default};
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
`;

export const Result = styled(SmallBox)`
  position: absolute;
  z-index: 1;
`;

export const CloseButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  float: right;
  margin-top: 4px;
  > svg {
    height: 0.8em;
    width: 0.8em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
      stroke-width: 3px;
    }
  }
`;
