// @flow

import React from "react";
import { Org as StyledOrg, OrgWrapper } from "./styles";
import NewRoom from "src/containers/chatroom/NewRoom";

const Org = ({ orgTitle }: { orgTitle: string }) => (
  <OrgWrapper>
    <StyledOrg>{orgTitle}</StyledOrg>
    <NewRoom />
  </OrgWrapper>
);

export default Org;
