// @flow

import React from "react";

type Props = {
  fill?: string
};

const AlignRight = ({ fill, ...restProps }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
    <title>ic_format_align_right_24px</title>
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z" />
    </g>
  </svg>
);

export default AlignRight;
