// @flow

import { connect } from "react-redux";

import Invite from "src/components/chatroom/Invite";
import { sendUserInviteRequest } from "src/actions/invite";
import { getRoleTitles } from "src/reducers";

import type { State } from "src/types";

const mapStateToProps = ({ app }: State) => ({
  roles: getRoleTitles(app)
});

export default connect(mapStateToProps, {
  sendUserInviteRequest
})(Invite);
