// @flow

import React from "react";

import { StatusValue as StyledStatusValue } from "./styles";
import Standard from "src/components/chatroom/Status/Standard";
import Custom from "src/components/chatroom/Status/Custom";

import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  value: number
};

const StatusValue = ({ roomId, value }: Props) => (
  <StyledStatusValue data-cy="statusNotification">
    {value < 0 ? (
      <Standard id={value} roomId={roomId} hideArchive />
    ) : (
      <Custom id={value} roomId={roomId} hideArchive />
    )}
  </StyledStatusValue>
);

export default StatusValue;
