// @flow
import React from "react";

import DomainTag from "./DomainTag";

import { TagContainer } from "../styles";

type Props = {
  domains: Array<string>,
  handleRemove: (domain: string) => void,
  selectionRef: any
};

const Selection = ({ domains, handleRemove, selectionRef }: Props) => {
  return (
    <TagContainer ref={selectionRef}>
      {domains.map((domain, index) => {
        if (typeof domain === "string") {
          return (
            <DomainTag
              key={index}
              domain={domain}
              index={index}
              handleRemove={handleRemove}
            />
          );
        }
      })}
    </TagContainer>
  );
};

export default Selection;
