// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import {
  MultiSelect,
  TextInput,
  SingleSelect,
  Checkbox
} from "src/components/Unifize";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import { revisionPrivacyModes } from "src/constants";
import {
  getAllUsers,
  getCurrentChecklistBuilderFields,
  getWorkflowBuilderStatuses
} from "src/reducers";
import { FullContainer, HalfContainer } from "./styles";
import type { UserMention } from "src/types";

type Props = {
  action:
    | "addParticipants"
    | "removeParticipants"
    | "addParticipantsFromFields"
    | "sendMessage"
    | "updatePrivacy"
    | "archive"
    | "updateStatus",
  value: any,
  status: boolean,
  onChange: Function,
  onStatusChange: Function,
  isNewCurrent?: boolean
};

const Automations = ({
  action,
  value,
  status = false,
  onChange,
  onStatusChange,
  isNewCurrent = false
}: Props) => {
  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );
  const userFields = checklistFields.filter(field => field.type === "user");
  const statuses = useSelector(({ app }) => getWorkflowBuilderStatuses(app));
  const users: Array<UserMention> = useSelector(({ app }) => getAllUsers(app));

  switch (action) {
    case "addParticipants":
      return (
        <MultiSelect
          label="Add participants"
          placeholder="Search users"
          data={users}
          selected={value}
          showIcon
          keys={["id", "name", "email", "photoUrl"]}
          onChange={value => onChange(value)}
        />
      );

    case "removeParticipants":
      return (
        <MultiSelect
          label="Remove participants"
          placeholder="Search users"
          data={users}
          selected={value}
          showIcon
          keys={["id", "name", "email", "photoUrl"]}
          onChange={value => onChange(value)}
        />
      );

    case "addParticipantsFromFields":
      return (
        <MultiSelect
          label="Add users from these field(s)"
          placeholder="Search fields"
          data={userFields}
          selected={value || []}
          keys={["id", "label"]}
          onChange={value => onChange(value)}
        />
      );

    case "updatePrivacy":
      return (
        <FullContainer>
          <SingleSelect
            label="Update privacy"
            placeholder="Select privacy mode"
            data={revisionPrivacyModes}
            selected={value.mode}
            keys={["id", "label"]}
            onChange={selected =>
              onChange({ mode: selected, whitelistedUsers: [] })
            }
          />
          {value.mode == "full" || value.mode == "content" ? (
            <MultiSelect
              label="Whitelist"
              placeholder="Search users"
              data={users}
              selected={value.whitelistedUsers}
              keys={["id", "name", "email", "photoUrl"]}
              onChange={selected =>
                onChange({
                  mode: value.mode,
                  whitelistedUsers: selected
                })
              }
            />
          ) : null}
        </FullContainer>
      );

    case "sendMessage":
      return (
        <TextInput
          value={value}
          onChange={e => onChange(e.target.value)}
          label="Send message"
        />
      );

    case "archive":
      return (
        <FullContainer>
          {isNewCurrent ? (
            <Checkbox
              checked={value === false}
              label="Unarchive"
              onChange={value => {
                onChange(!value);
                onStatusChange(!value);
              }}
            />
          ) : (
            <Checkbox
              checked={value}
              label="Auto archive"
              onChange={value => {
                onChange(value);
                onStatusChange(value);
              }}
            />
          )}
        </FullContainer>
      );

    case "updateStatus":
      return (
        <HalfContainer>
          <Checkbox
            checked={status}
            label="Change status to"
            onChange={value => onStatusChange(value)}
          />
          <SingleSelect
            placeholder="Select status"
            data={statuses}
            selected={value}
            keys={["id", "text"]}
            onChange={selected => onChange(selected)}
          />
        </HalfContainer>
      );
    default:
      return null;
  }
};

export default Automations;
