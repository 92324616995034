// @flow

import { connect } from "react-redux";
import React from "react";

import Value from "src/containers/checklist/Value";
import FieldName from "src/containers/checklist/FieldName";
import { getChecklistValue } from "src/reducers";

import type { AppState, RoomId, ChecklistValue } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: number,
  value: ChecklistValue
};

const ChecklistPreview = ({ fieldId, value }: Props) => {
  if (value && value.val) {
    return (
      <>
        <span>
          <FieldName id={fieldId} />:
        </span>
        <b>
          <Value id={fieldId} value={value.val.value} />
        </b>
      </>
    );
  }

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChecklistValue(app, props.fieldId, props.roomId)
});

export default connect(mapStateToProps)(ChecklistPreview);
