// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Flex, Avatar, Text } from "@chakra-ui/react";

import { getUser } from "src/reducers";
import type { UID } from "src/types";
import * as styles from "./styles";

type Props = {
  id: UID
};

const GroupUsers = ({ id }: Props) => {
  const user = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;

  return (
    <Flex sx={styles.GroupUsersWrapper}>
      <Avatar
        sx={styles.UserAvatar}
        size="sm"
        name={displayName}
        src={photoUrl}
      />
      <Flex sx={styles.FlexColumn}>
        <Text isTruncated sx={styles.UserAndGroupName}>
          {displayName}
        </Text>
        <Text isTruncated sx={styles.LightGreyText}>
          {email}
        </Text>
      </Flex>
    </Flex>
  );
};

export default GroupUsers;
