// @flow

import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";

import { Updates as StyledUpdates, Form, FormBody } from "./styles";
import { getParent } from "src/utils";
import { SecondaryFooter as Footer } from "src/styles/form";
import { Button } from "src/styles/buttons";

import type { AppState, Updates as UpdatesType } from "src/types";

type Props = {
  updates: UpdatesType
};

const Updates = ({ updates }: Props) => {
  const [showModal, setModal] = useState(false);

  useEffect(() => {
    if (
      process.env.VERSION !== updates.newVersion &&
      updates.newVersion &&
      updates.critical
    ) {
      setModal(true);
    }
  }, [updates, setModal]);

  const handleUpdate = useCallback(
    (e: any) => {
      e.preventDefault();
      // Hard refresh page
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    },
    [updates]
  );

  return (
    <StyledUpdates id="app-updates">
      <Modal
        isOpen={showModal}
        parentSelector={() => getParent("#app-updates")}
      >
        <Form onSubmit={handleUpdate}>
          <h4>Update has been relased</h4>
          <FormBody>
            There is a new version of the app available, click refresh to view
            it.
          </FormBody>
          <Footer>
            <Button type="submit">Refresh</Button>
          </Footer>
        </Form>
      </Modal>
    </StyledUpdates>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  updates: app.updates
});

export default connect(mapStateToProps)(Updates);
