// @flow

import React from "react";

const AlarmClock = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.25 3.98212C13.1515 3.98236 13.054 3.96304 12.963 3.92527C12.872 3.8875 12.7894 3.83205 12.72 3.76212L10.22 1.26212C10.0876 1.11994 10.0154 0.931894 10.0189 0.737593C10.0223 0.543292 10.101 0.357907 10.2384 0.220494C10.3758 0.083081 10.5612 0.00436904 10.7555 0.000940822C10.9498 -0.00248739 11.1379 0.0696358 11.28 0.202116L13.78 2.70212C13.8848 2.807 13.9561 2.94058 13.985 3.08598C14.0139 3.23138 13.999 3.38208 13.9423 3.51904C13.8856 3.656 13.7896 3.77309 13.6664 3.85551C13.5432 3.93793 13.3983 3.98199 13.25 3.98212Z"
      fill="white"
    />
    <path
      d="M3.98129 0.749344C3.98153 0.847852 3.96221 0.945428 3.92445 1.03641C3.88668 1.12739 3.83122 1.20996 3.76129 1.27934L1.26129 3.77934C1.11912 3.91182 0.93107 3.98395 0.736769 3.98052C0.542468 3.97709 0.357083 3.89838 0.21967 3.76097C0.082257 3.62355 0.00354506 3.43817 0.000116847 3.24387C-0.00331137 3.04957 0.0688118 2.86152 0.201292 2.71934L2.70129 0.219345C2.80618 0.114588 2.93976 0.0432634 3.08516 0.014379C3.23055 -0.0145054 3.38125 0.000345469 3.51822 0.0570569C3.65518 0.113768 3.77226 0.209796 3.85468 0.333012C3.9371 0.456228 3.98116 0.601104 3.98129 0.749344Z"
      fill="white"
    />
    <path
      d="M7.00001 1.98212C6.0111 1.98212 5.0444 2.27536 4.22216 2.82477C3.39991 3.37418 2.75905 4.15507 2.38061 5.0687C2.00217 5.98233 1.90316 6.98766 2.09608 7.95757C2.28901 8.92747 2.76521 9.81839 3.46448 10.5177C4.16374 11.2169 5.05465 11.6931 6.02456 11.886C6.99446 12.079 7.9998 11.98 8.91343 11.6015C9.82706 11.2231 10.608 10.5822 11.1574 9.75997C11.7068 8.93772 12 7.97102 12 6.98212C11.9984 5.65652 11.4711 4.38567 10.5338 3.44834C9.59645 2.511 8.3256 1.9837 7.00001 1.98212ZM9.50001 7.48212H7.00001C6.8674 7.48212 6.74022 7.42944 6.64646 7.33567C6.55269 7.2419 6.50001 7.11473 6.50001 6.98212V4.48212C6.50001 4.34951 6.55269 4.22233 6.64646 4.12856C6.74022 4.0348 6.8674 3.98212 7.00001 3.98212C7.13262 3.98212 7.2598 4.0348 7.35356 4.12856C7.44733 4.22233 7.50001 4.34951 7.50001 4.48212V6.48212H9.50001C9.63262 6.48212 9.7598 6.5348 9.85356 6.62856C9.94733 6.72233 10 6.84951 10 6.98212C10 7.11473 9.94733 7.2419 9.85356 7.33567C9.7598 7.42944 9.63262 7.48212 9.50001 7.48212Z"
      fill="white"
    />
  </svg>
);

export default AlarmClock;
