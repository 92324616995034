// @flow

import * as R from "ramda";
import React from "react";

import { Dropdown as StyledDropdown } from "./styles";
import { ListItemMedium } from "src/styles/box";
import { userSegmentType } from "src/constants/display";

import type { SegmentType } from "src/types";

type Props = {
  segmentType: SegmentType,
  handleSelect: Function
};

const Dropdown = ({ segmentType, handleSelect }: Props) => (
  <StyledDropdown>
    <ul>
      {(R.values(segmentType) || []).map(type => (
        <ListItemMedium key={type.id} onClick={() => handleSelect(type.id)}>
          {userSegmentType[type.title]}
        </ListItemMedium>
      ))}
    </ul>
  </StyledDropdown>
);

export default Dropdown;
