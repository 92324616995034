import * as R from "ramda";

const priority = ["low", "normal", "high", "critical"];

export const priorityOrder = {
  "priority:ascending": priority,
  priority: R.reverse(priority)
};

export const priorityPrecedenceMap = {
  low: 1,
  normal: 2,
  high: 3,
  critical: 4
};

export default priority;
