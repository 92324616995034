// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, Updates } from "src/types";

const initialState = {
  newVersion: null,
  critical: false
};

const updates = (state: Updates = initialState, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_UPDATES_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default updates;
