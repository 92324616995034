// @flow

import type { SystemCSSProperties } from "@chakra-ui/react";

export const modalContent = {
  maxHeight: "300px"
};

export const modalBody: SystemCSSProperties = {
  pb: 6
};

export const signatoriesList = {
  alignItems: "start"
};

export const userDetailsContainer = {
  alignItems: "start"
};

export const userName = {
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: 600,
  m: 0
};

export const userEmail = {
  fontSize: "10px",
  lineHeight: "12px",
  fontWeight: 400,
  m: 0
};
