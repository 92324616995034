// @flow

import React from "react";

import { FormGroup } from "./styles";
import UserSelect from "src/containers/user/select";
import { Body as FormBody } from "src/styles/form";

import type { UID } from "src/types";

type Props = {
  user: ?UID,
  handleOwner: Function
};

const Direct = ({ user, handleOwner }: Props) => (
  <FormBody>
    <FormGroup>
      <UserSelect value={user} handleChange={handleOwner} autoFocus />
    </FormGroup>
  </FormBody>
);

export default Direct;
