// @flow

import { connect } from "react-redux";
import React, { useState } from "react";

import Groups from "./Groups";
import OnBoarding from "./OnBoarding";
import Processes from "./Processes";
import { Tabs, Tab } from "./styles";

import { getUserGroups } from "src/reducers";

import type { AppState, UID, UnifizeChatRoom } from "src/types";

type Props = {
  uid: UID,
  groups: Array<number>,
  process: Array<UnifizeChatRoom>
};

const Table = ({ uid, process, groups }: Props) => {
  const [tab, setTab] = useState("groups");

  return (
    <>
      <Tabs>
        <Tab
          active={tab === "groups"}
          tabIndex="0"
          role="tab"
          onClick={() => setTab("groups")}
          onKeyPress={() => setTab("groups")}
        >
          Groups ({(groups || []).length})
        </Tab>
        <Tab
          active={tab === "processes"}
          tabIndex="0"
          role="tab"
          onClick={() => setTab("processes")}
          onKeyPress={() => setTab("processes")}
        >
          Processes ({(process || []).length})
        </Tab>
        <Tab
          active={tab === "onboarding"}
          tabIndex="0"
          role="tab"
          onClick={() => setTab("onboarding")}
          onKeyPress={() => setTab("onboarding")}
        >
          Onboarding Progress
        </Tab>
      </Tabs>
      {tab === "groups" ? <Groups groups={groups} /> : null}
      {tab === "processes" ? <Processes process={process} /> : null}
      {tab === "onboarding" ? <OnBoarding uid={uid} /> : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  groups: getUserGroups(app, props.uid),
  process: app.users.userProcesses
});

export default connect(mapStateToProps)(Table);
