// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, Reply } from "src/types";

const initialState: Reply = {
  to: "",
  roomId: ""
};

const reply = (state: Reply = initialState, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_REPLY_TO:
      return { to: payload.to, roomId: payload.roomId };
    case atypes.CLEAR_REPLY_TO:
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reply;
