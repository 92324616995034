// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Type as StyledType, Option, SubHeading, Header } from "./styles";
import WhiteList from "./WhiteList";
import Icon from "src/icons";
import Radio from "src/components/Radio";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import * as colors from "src/styles/constants/colors";

type Props = {
  _setWorkflowBuiderAttributes: Function,
  privacy: string
};

const Type = ({ privacy, _setWorkflowBuiderAttributes }: Props) => {
  const handleType = useCallback(
    (type: string) => {
      _setWorkflowBuiderAttributes({ privacy: type });
    },
    [_setWorkflowBuiderAttributes]
  );

  return (
    <StyledType>
      <Option
        selected={privacy === "content"}
        onClick={() => handleType("content")}
      >
        <Radio
          id="hideChatContent"
          checked={privacy === "content"}
          handleChange={() => handleType("content")}
        />
        <div>
          <Header>
            <Icon type="lock" color={colors.primary} />
            Hide only chat content
          </Header>
          <SubHeading>
            Only the contents of the conversation will be hidden, other data
            like notifications will be visible to all
          </SubHeading>

          {privacy === "content" ? <WhiteList /> : null}
        </div>
      </Option>

      <Option selected={privacy === "full"} onClick={() => handleType("full")}>
        <Radio
          id="hideEverything"
          checked={privacy === "full"}
          handleChange={() => handleType("full")}
        />
        <div>
          <Header>
            <Icon type="hidden" />
            Hide everything
          </Header>
          <SubHeading>
            Conversations will be visible only to the participants, admin and
            whitelisted people
          </SubHeading>

          {privacy === "full" ? <WhiteList /> : null}
        </div>
      </Option>
    </StyledType>
  );
};

export default connect(null, {
  _setWorkflowBuiderAttributes: setWorkflowBuiderAttributes
})(Type);
