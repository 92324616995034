// @flow

import styled from "@emotion/styled";

import {
  Box,
  ListItemMedium,
  RelativeBox as StyledRelativeBox
} from "src/styles/box";
import * as colors from "src/styles/constants/colors";

export const Options = styled(Box)`
  width: 11em !important;
  min-width: 10em;
  padding: 4px 0 4px 0;
  position: absolute;
  z-index: 50;
  top: 0;
  > ul {
    margin: 0;
    padding: 0;
    width: 100%;
    > li:last-child {
      color: ${colors.danger};
    }
  }
`;

export const RelativeBox = styled(StyledRelativeBox)`
  z-index: 5 !important;
`;

export const Item = styled(ListItemMedium)`
  line-height: 1.5rem;
  font-size: 0.65625rem;
  padding: 0.125rem 0.5rem 0.25rem 0.875rem;
  color: ${colors.primary};
  font-weight: bold;
`;

export const InvitingUser = styled.div`
  > button {
    margin-left: 0.5em;
    padding: 4px 8px;
    font-size: 0.5625rem;
    background: ${props =>
      props.loading ? colors.active : colors.brightLight};
    color: ${props => (props.loading ? colors.brightLight : colors.active)};
    transition: all 0.8 ease;
    text-transform: uppercase;
    :hover,
    :focus {
      outline: none !important;
      box-shadow: none;
      background: ${colors.active};
      transition: all 0.3s ease;
      color: ${colors.brightLight};
    }
  }
`;
