// @flow

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import Field from "src/containers/checklist/Field";

type Props = {
  position: number,
  updateField: Function,
  openSettings: Function,
  addFields: Function,
  removeField: Function
};

const SortableItem = SortableElement(
  ({ position, updateField, removeField, addFields, openSettings }: Props) => (
    <Field
      position={position}
      addFields={addFields}
      openSettings={openSettings}
      updateField={updateField}
      removeField={removeField}
    />
  )
);

export default SortableItem;
