// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { FormGroup, Body } from "src/styles/form";

export const StyledProcessOwners = styled(FormGroup)`
  > label > svg {
    margin-left: ${spacing.space_s};
  }
  ${props =>
    props.form &&
    `
      display: initial;
      width: 0%;
      margin: 0px;
    `};
`;

export const FormBody = styled(Body)`
  display: grid;
  grid-template-columns: ${props => (props.advanced ? "35em 30em" : "35em")};
  max-height: calc(100vh - 12rem);
  align-items: flex-start;
  label {
    color: ${colors.primary};
  }
`;

export const Form = styled.form`
  min-width: 355px;
  padding-bottom: 5.5rem;

  h4 {
    position: relative;
    text-transform: capitalize;
    background: ${colors.grey0};
    margin: 0px;
    padding: ${spacing.space_inset_l};
    font-weight: 500;

    > svg:last-child {
      position: absolute;
      top: ${spacing.space_l};
      right: ${spacing.space_l};
      cursor: pointer;
    }
  }
`;

export const Template = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
