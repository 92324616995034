// @flow

import React from "react";

const DueDate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    fill="none"
    viewBox="0 0 17 18"
  >
    <path
      fillRule="evenodd"
      stroke="#2A2A2A"
      d="M15.5 2.5h-2v-2h-2v2h-6v-2h-2v2h-2a1 1 0 0 0-1 1v2h16v-2a1 1 0 0 0-1-1z"
      clipRule="evenodd"
    />
    <path stroke="#2A2A2A" d="M.5 7.1v9c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-9H.5z" />
  </svg>
);

export default DueDate;
