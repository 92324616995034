// @flow

import { connect } from "react-redux";

import Approval from "src/components/chatroom/creator/approval";
import { getCurrentUserId } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProp = ({ app }: { app: AppState }) => ({
  title: app.conversationDialog.title,
  owner: app.conversationDialog.owner,
  members: app.conversationDialog.members,
  parent: app.conversationDialog.parent,
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProp)(Approval);
