// @flow

import * as React from "react";

type Props = {};

const Layers = ({ ...restProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M15 14H9C8.448 14 8 13.552 8 13V5C8 4.448 8.448 4 9 4H13C13.265 4 13.52 4.105 13.707 4.293L15.707 6.293C15.895 6.48 16 6.735 16 7V13C16 13.552 15.552 14 15 14Z" />
      <path d="M2 2C1.448 2 1 2.448 1 3V9C1 9.552 1.448 10 2 10H6V8H3V3C3 2.448 2.552 2 2 2Z" />
    </svg>
  );
};

export default Layers;
