// @flow

import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { GroupDropdown as StyledDropdDown, ListItemGroup } from "./styles";
import Name from "src/components/Group/Name";
import { searchGroup } from "src/actions/group";
import { Input } from "src/styles/input";
import Checkbox from "src/components/Checkbox";
import useClickoutside from "src/hooks/useClickoutside";

import type { AppState } from "src/types";

type Props = {
  outerRef: any,
  value: Array<number>,
  result: Array<number>,
  _searchGroup: Function,
  handleSelect: Function,
  handleClose: Function
};

const GroupDropdown = ({
  outerRef,
  value,
  result,
  _searchGroup,
  handleSelect,
  handleClose
}: Props) => {
  useClickoutside({ outerRef, handleClose });

  useEffect(() => {
    _searchGroup("");
  }, [_searchGroup]);

  const handleSearch = useCallback(
    (event: any) => {
      _searchGroup(event.target.value);
    },
    [_searchGroup]
  );

  return (
    <StyledDropdDown>
      <Input onChange={handleSearch} placeholder="Select Group" autoFocus />
      <ul>
        {result.map(group => (
          <ListItemGroup
            key={group}
            onClick={() => handleSelect(parseInt(group, 10))}
          >
            <Name id={group} />
            <Checkbox
              id={`group${group}`}
              checked={R.includes(parseInt(group, 10), value)}
              handleChange={() => {}}
            />
          </ListItemGroup>
        ))}
      </ul>
    </StyledDropdDown>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.groups.searchResult
});

export default connect(mapStateToProps, {
  _searchGroup: searchGroup
})(GroupDropdown);
