// @flow

import React from "react";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { Checkbox } from "src/components/Unifize";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

const ApprovalComments = ({ settings, saveSettings }: Props) => {
  return (
    <Accordion title="Approval comments">
      <AccordionContent>
        <Checkbox
          checked={settings?.requireComment}
          label="Make comments during approval mandatory"
          onChange={value => saveSettings(["requireComment"], value)}
        />
        <Checkbox
          checked={settings?.requireRejectionComment}
          label="Make comments while rejecting mandatory"
          onChange={value => saveSettings(["requireRejectionComment"], value)}
        />
        <Checkbox
          checked={settings?.requireCancellationComment}
          label="Make comments while cancelling mandatory"
          onChange={value =>
            saveSettings(["requireCancellationComment"], value)
          }
        />
      </AccordionContent>
    </Accordion>
  );
};

export default ApprovalComments;
