// @flow

import { SystemCSSProperties } from "@chakra-ui/react";

export const AddUserText: SystemCSSProperties = {
  cursor: "pointer",
  m: 0,
  userSelect: "none",
  textAlign: "center",
  _hover: {
    textDecoration: "underline"
  }
};

export const MembersWrapper: SystemCSSProperties = {
  position: "absolute",
  zIndex: 2,
  top: "1.5rem",
  right: "-4rem"
};

export const AddUser: SystemCSSProperties = {
  zIndex: 2,
  top: "1.5rem",
  left: "-6rem"
};
