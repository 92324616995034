// @flow

import styled from "@emotion/styled";

import { InlineCheckboxFormGroup } from "src/styles/form";
import { ListItemMedium } from "src/styles/box";
import { colors as colorsOld } from "src/styles/constants";

const SearchResult = styled(ListItemMedium)`
  > span > span {
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

// These styles have been added as intermediate while
// migrating to new ones dont

const Advanced = styled.div`
  > label label {
    display: block;
    margin-top: 0px !important;
    padding-top: 0px;
  }
`;

const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  max-width: 350px;
  padding-top: 10px;
  :nth-of-type(4) {
    align-items: flex-start;
  }

  > button:nth-of-type(3) {
    margin-top: 19px;
  }
  > span {
    padding-top: 8px;
    padding-right: 5px;
  }
  > div {
    color: ${colorsOld.lightGrey};
    > *:first-of-type {
      margin-top: 0px;
      padding-top: 0px !important;
    }
    > *:only-child {
      margin-top: 9px;
    }
  }
`;

const InlineFormGroup = styled(InlineCheckboxFormGroup)`
  padding: 0px;
  label {
    cursor: pointer;
  }
`;

export { SearchResult, Advanced, FormGroup, InlineFormGroup };
