// @flow

import React, { useState } from "react";

import {
  MenuItemWithIcon,
  HoverExtender,
  UserSubMenu,
  MenuItem
} from "../styles";
import AllUsers from "src/components/filter/AllUsers";
import Icon from "src/icons";

const ViewAs = () => {
  const [userMenu, setUserMenu] = useState(false);

  return (
    <MenuItem
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setUserMenu(true)}
      onMouseLeave={() => setUserMenu(false)}
    >
      <MenuItemWithIcon>
        <Icon type="profile" />
        <span>View As</span>
      </MenuItemWithIcon>
      {userMenu ? <HoverExtender /> : null}
      {userMenu ? (
        <UserSubMenu>
          <AllUsers />
        </UserSubMenu>
      ) : null}
    </MenuItem>
  );
};

export default ViewAs;
