// @flow

import React from "react";
import { connect } from "react-redux";

import { OldValue, NewValue } from "../styles";
import LinkedRoom from "src/components/messages/Chat/Notification/LinkedRoom";
import { getChatroomAddress } from "src/reducers";
import type { ChatroomAddress } from "src/types";

type Props = {
  oldValue: any,
  newValue: any,
  oldChatroomAddress: ChatroomAddress,
  newChatroomAddress: ChatroomAddress,
  disableTitleHyperlink: boolean
};

const Conversation = ({
  oldValue,
  newValue,
  oldChatroomAddress,
  newChatroomAddress,
  disableTitleHyperlink
}: Props) => (
  <>
    {oldValue && (
      <OldValue>
        {oldValue ? (
          <LinkedRoom
            id={`${oldValue}`}
            chatroomAddress={oldChatroomAddress}
            disableTitleHyperlink={disableTitleHyperlink}
          />
        ) : null}
      </OldValue>
    )}
    {newValue && (
      <NewValue>
        {newValue ? (
          <LinkedRoom
            id={`${newValue}`}
            chatroomAddress={newChatroomAddress}
            disableTitleHyperlink={disableTitleHyperlink}
          />
        ) : null}
      </NewValue>
    )}
  </>
);

const mapStateToProps = ({ app }, { newValue, oldValue }) => ({
  newChatroomAddress: newValue ? getChatroomAddress(app, newValue) : null,
  oldChatroomAddress: oldValue ? getChatroomAddress(app, oldValue) : null
});

export default connect(mapStateToProps)(Conversation);
