// @flow

import type { ConversationType } from "src/types";

import * as colors from "src/styles/constants/colors";
import { chatBlockColors } from "src/constants/display";

const ChatblockColor = (conversationType: ConversationType, color: string) => {
  if (conversationType === "workflow") {
    return color;
  }
  return chatBlockColors[conversationType] || colors.sky;
};

export default ChatblockColor;
