// @flow

import React from "react";

import Breadcrumbs from "src/components/Manage/Reports/Instances/Breadcrumbs";
import ExpandedChart from "./Chart/Expand";
import Close from "src/components/Manage/Reports/Instances/Close";
import { OpaqueOverlay as StyledInstances } from "src/components/Manage/Workflow/styles";
import Table from "src/components/Manage/Workflow/Instances/Table";

const Instance = () => {
  return (
    <StyledInstances>
      <Close />
      <Breadcrumbs />
      <ExpandedChart />
      <Table />
    </StyledInstances>
  );
};

export default Instance;
