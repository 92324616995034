// @flow

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { SystemCSSProperties } from "@chakra-ui/react";

export const UserAvatar: SystemCSSProperties = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,
  borderRadius: radius.medium,
  "& img": {
    borderRadius: "3px"
  }
};

export const FlexColumn: SystemCSSProperties = {
  flexDirection: "column"
};

export const DisplayName: SystemCSSProperties = {
  fontSize: "xs",
  m: 0,
  ml: "0.5rem",
  maxWidth: "8rem"
};

export const LightGreyText: SystemCSSProperties = {
  my: "0",
  mx: "0.5rem",
  color: colors.grey5,
  fontSize: "10px",
  lineHeight: "12px"
};
