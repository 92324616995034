// @flow

import React, { useState, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import StatusSelect from "./StatusSelect";
import { ChecklistSettings } from "src/styles/checklist";
import type { SectionFieldSettings } from "src/types";
import * as styles from "./styles";

type Props = {
  type: string,
  settings: SectionFieldSettings,
  saveSettings: Function
};

const Section = ({ settings, saveSettings }: Props) => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (settings) {
      setStatus(settings.status || []);
    }
  }, [settings, setStatus]);

  return (
    <ChecklistSettings>
      <Box sx={styles.SectionContainer}>
        <Flex sx={styles.SectionWrapper}>
          <Text mr={2}>Open by default for</Text>
          <StatusSelect
            value={status}
            handleStatus={setStatus}
            saveSettings={saveSettings}
          />
        </Flex>
      </Box>
    </ChecklistSettings>
  );
};

export default Section;
