// @flow

import { connect } from "react-redux";
import React from "react";

import Chart from "./Chart";
import useFetchChartJSON from "src/hooks/useFetchChartJSON";

import type { AppState } from "src/types";

type Props = {
  charts: Array<any>,
  dashboard: number,
  _setChartLoadingState: (chartIds: Array<number>) => void
};

const Display = ({ charts, dashboard }: Props) => {
  // Fetch chart data and update the redux state
  useFetchChartJSON({ charts });

  return (
    <>
      {(charts || []).map(chart => (
        <Chart
          chartId={chart.id}
          dashboard={dashboard}
          key={chart.id}
          title={chart.title}
          description={chart.description}
          reportId={chart.reportId}
          x={chart.x}
          y={chart.y}
          yAxisLabel={chart.yAxisLabel}
          typeId={chart.type}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  charts: app.dashboard.metaData.charts,
  dashboard: app.dashboard.metaData.id
});

export default connect(mapStateToProps)(Display);
