// @flow

import { useState, useCallback } from "react";

const useTextChange: Function = (initialValue: ?string) => {
  const [text, setText] = useState(initialValue || "");

  const handleChange = useCallback(
    (event: any) => {
      setText(event.target.value);
    },
    [setText]
  );

  const getActive = useCallback((newValue: ?string) => {
    return text !== (newValue || "");
  });

  return {
    text,
    setText,
    handleChange,
    getActive
  };
};

export default useTextChange;
