// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Text,
  Button,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertDescription
} from "@chakra-ui/react";

import { getOrgName } from "src/reducers";
import { getOAuthAppName, getOAuthState } from "src/selectors";
import { grantOAuthAppPermisssions } from "src/actions/oAuth";
import type { State as OAuthState } from "src/reducers/oAuth";

import FormContainerWithLogo from "src/components/auth/FormContainerWithLogo";
import LoaderWithText from "src/components/Loader";
import * as styles from "./styles";

const integrations: {
  [appName: string]: {
    img?: string,
    displayName: string,
    permissions?: string[]
  }
} = {
  slack: {
    img:
      "https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-6gwiyo/Slack_Mark.svg?auto=webp&format=svg",
    displayName: "Slack",
    permissions: [
      "Create conversations on Unifize on your behalf.",
      "Forward messages to Unifize on your behalf."
    ]
  },
  microsoft365: {
    img:
      "https://news.microsoft.com/wp-content/uploads/prod/2022/05/Microsoft-logo_rgb_c-gray-768x344.png",
    displayName: "Microsoft 365"
  },
  "microsoft365-admin": {
    img:
      "https://news.microsoft.com/wp-content/uploads/prod/2022/05/Microsoft-logo_rgb_c-gray-768x344.png",
    displayName: "Microsoft 365 Admin"
  }
};

const OAuthAppPermissionPage = () => {
  const dispatch = useDispatch();

  const appName = useSelector(getOAuthAppName);
  const oAuthState: OAuthState = useSelector(getOAuthState);
  const currentOrgName = useSelector(({ app }) =>
    getOrgName(app, parseInt(app.orgs.last, 10))
  );

  const integrationData = integrations[appName];

  if (!currentOrgName)
    return (
      <FormContainerWithLogo>
        <LoaderWithText>
          <span data-cy="loading">Fetching user and org details</span>
        </LoaderWithText>
      </FormContainerWithLogo>
    );

  if (!integrationData)
    return (
      <FormContainerWithLogo>
        <Text>Integration not found!</Text>
      </FormContainerWithLogo>
    );

  return (
    <FormContainerWithLogo>
      <VStack sx={styles.container}>
        <VStack sx={styles.cardHeader}>
          {integrationData.img && (
            <img
              src={integrationData.img}
              alt={`${appName}-logo`}
              style={styles.oAuthAppLogo}
            />
          )}

          <Text sx={styles.headerText}>
            <strong>{integrationData.displayName}</strong> is requesting
            permission to access your Unifize account on{" "}
            <strong>{currentOrgName}</strong>
          </Text>
        </VStack>

        <VStack sx={styles.cardContent}>
          {integrationData.permissions && (
            <>
              <Text sx={styles.cardContentTitle}>
                What can {integrationData.displayName} do?
              </Text>

              <UnorderedList sx={styles.permissionList}>
                {integrationData.permissions.map(permission => (
                  <ListItem key={permission}>{permission}</ListItem>
                ))}
              </UnorderedList>
            </>
          )}

          {oAuthState.error && (
            <Alert status="error" sx={styles.errorAlert}>
              <AlertIcon />
              <AlertDescription>{oAuthState.error}</AlertDescription>
            </Alert>
          )}
        </VStack>

        <HStack sx={styles.cardFooter}>
          <Button
            variant="uBlueFilled"
            onClick={() => dispatch(grantOAuthAppPermisssions())}
            disabled={oAuthState.inProgress}
            isLoading={oAuthState.inProgress}
            loadingText="Allowing"
          >
            Allow
          </Button>

          <Button
            disabled={oAuthState.inProgress}
            onClick={() => {
              window.location.replace("/");
            }}
          >
            Deny
          </Button>
        </HStack>
      </VStack>
    </FormContainerWithLogo>
  );
};

export default OAuthAppPermissionPage;
