// @flow

import styled from "styled-components";

import * as colors from "src/styles/constants/colors";

export const ImportedTemplate = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
`;

export const ImportedTemplates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: scroll;
  max-height: 16rem;
`;

export const modalBody = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  fontSize: 16
};

export const PanelTitle = styled.span`
  padding: 0.5rem 0;
`;

export const Loader = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const PanelBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  max-height: 18rem;
  font-size: 14px;
`;

export const CollidedTemplate = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  :nth-child(odd) {
    background-color: ${colors.skyLighter};
  }
  :nth-child(even) {
    background-color: white;
  }
`;

export const CollidedTemplateRadio = styled.span`
  display: flex;
  gap: 1rem;
  cursor: pointer;

  label,
  span {
    font-size: 14px; // TODO src/components/Radio is styled for 16px
  }
`;

export const PanelButtons = styled.div`
  display: flex;
  gap: 1rem;
  align-self: end;
`;

export const SummaryPanelBody = styled(PanelBody)`
  gap: 1rem;
`;

export const SummaryProcess = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  padding: 0.5rem 0;
`;

export const SummaryProcessTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
