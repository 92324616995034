// @flow

import React from "react";
import { connect } from "react-redux";
import {
  assignResourcePermissionToRole,
  removeResourcePermissionToRole,
  assignComponentPermissionToRole,
  removeComponentPermissionToRole
} from "src/actions/roleManagement";

import { Checkbox } from "@chakra-ui/react";
type Props = {
  id: number,
  resourceId: number | null,
  componentId: number | null,
  verbId: number,
  isChecked: Boolean,
  isLocked: Boolean,
  assignResourcePermissionToRole: Function,
  removeResourcePermissionToRole: Function,
  assignComponentPermissionToRole: Function,
  removeComponentPermissionToRole: Function
};

const PermissionCheckBox = ({
  id,
  resourceId,
  componentId,
  verbId,
  isChecked,
  isLocked,
  assignResourcePermissionToRole,
  removeResourcePermissionToRole,
  assignComponentPermissionToRole,
  removeComponentPermissionToRole
}: Props) => {
  const handleChange = () => {
    if (resourceId) {
      if (isChecked) {
        removeResourcePermissionToRole(id, resourceId, verbId);
      } else {
        assignResourcePermissionToRole(id, resourceId, verbId);
      }
    }

    if (componentId) {
      if (isChecked) {
        removeComponentPermissionToRole(id, componentId);
      } else {
        assignComponentPermissionToRole(id, componentId);
      }
    }
  };

  return (
    <Checkbox
      isChecked={isChecked}
      isDisabled={isLocked}
      onChange={handleChange}
    />
  );
};

export default connect(null, {
  assignResourcePermissionToRole,
  removeResourcePermissionToRole,
  assignComponentPermissionToRole,
  removeComponentPermissionToRole
})(PermissionCheckBox);
