// @flow

import { useSelector } from "react-redux";
import React from "react";

import CountBarChart from "./CountBarChart";
import CountDougnout from "./CountDougnout";
import NumericalBarChart from "./NumericalBarChart";
import TimeseriesBarChart from "./TimeseriesBarChart";
import { getCurrentChartName } from "src/reducers";

const ChartFields = () => {
  const name = useSelector(({ app }) => getCurrentChartName(app));
  switch (name) {
    case "conversation-count-stacked":
    case "form-count-stacked":
      return <CountBarChart name={name} />;
    case "conversation-count-doughnut":
    case "form-count-doughnut":
      return <CountDougnout name={name} />;
    case "numeric-field-stacked":
      return <NumericalBarChart />;
    case "time-series-stacked":
      return <TimeseriesBarChart />;
    default:
      return <></>;
  }
};

export default ChartFields;
