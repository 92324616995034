// @flow

import styled from "@emotion/styled";

import { colors, shadow, spacing, fontSize } from "./constants";
import * as radius from "src/styles/constants/radius";

export const Default = styled.div`
  position: relative;
  margin: 10px 0;
  width: 100%;
  padding: 12px 16px 16px 16px;
  border-radius: 4px;
  color: black;
  box-shadow: ${shadow.defaultShadow};
  background-color: ${colors.pureWhite};
  border: solid 1px ${colors.grey};
  min-width: 10em;
  &:hover {
    background-color: ${colors.aliceBlue};
  }
  > div:first-of-type {
    > div:first-of-type {
      > div:first-of-type {
        width: 36px;
        height: 36px;
        background: ${colors.unifizeBlue};
        margin-right: 9px;
        line-height: 2em;
        font-size: ${fontSize.t1};
        border-radius: 2px;
        svg {
          position: relative;
          top: 4px;
          left: 4px;
        }
      }
      > div:nth-of-type(2) {
        > span {
          font-size: 14px;
          font-weight: bold;
          color: ${colors.black};
        }
        > div {
          opacity: 0.5;
          margin-top: 2px;
          font-size: 13px;
          color: ${colors.dullGrey};
        }
      }
      img {
        width: 36px;
        height: 36px;
        border-radius: 0px;
      }
      display: flex;
      align-items: center;
    }
    > span {
      position: absolute;
      right: 9px;
      top: 9px;
    }
  }
  > div:nth-of-type(2) {
    border-radius: 4px;
    background-color: ${colors.greenGrey};
    margin-top: 8px;
    margin-left: 46px;
    padding: 1px;
    > div:first-of-type {
      display: flex;
      align-items: ${({ child }) => (child ? "center" : "center")};
      > div:first-of-type {
        line-height: 2em;
        border-radius: 2px;
      }
      > b {
        margin-left: 12px;
        font-size: 14px;
        color: ${colors.black};
      }
    }
  }
`;

export const Type = styled.div`
  border-radius: 2px;
  margin-left: 46px;
  padding-bottom: 1px;
  background-color: ${colors.greenGrey};
  > div:first-of-type {
    display: flex;
    background-color: ${colors.greenGrey};
    padding: 12px;
    align-items: center;
    border-bottom: solid 1px ${colors.pureWhite};
    > div:nth-of-type(2) {
      margin-left: 9px;
      font-size: ${fontSize.t2};
      font-weight: bold;
      color: ${colors.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > div:nth-of-type(2) {
        opacity: 0.4;
        font-family: Lato;
        font-size: 12px;
        color: #4b4b4b;
      }
    }
    > div:nth-of-type(3) {
      align-items: flex-end;
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      > div {
        > span {
          display: flex;
          align-items: center;
          color: #888888;
        }
      }
    }
  }
  > div:nth-of-type(2) {
    > ul {
      padding: 12px;
      margin: 12px;
      border-radius: 2px;
      list-style: none;
      background: ${colors.pureWhite};
      input {
        margin-right: 8px;
      }
      > div {
        font-size: ${fontSize.t3};
        font-weight: bold;
        line-height: 1.31;
        cursor: pointer;
        margin-top: 8px;
        color: ${colors.blue};
      }
      > li {
        font-size: ${fontSize.t3};
        color: #4b4b4b;
        display: flex;
        align-items: center;
        > span:nth-of-type(4) {
          text-decoration: line-through !important;
          color: ${colors.black};
          margin: 0 4px;
        }
        > span:nth-of-type(5) {
          color: ${colors.black};
          margin: 0 4px;
        }
      }
    }
  }
`;

export const FileNotification = styled.div`
  padding: 0 0.8em;
`;

export const NewConversation = styled.div``;

export const FilePreview = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  > button {
    cursor: pointer;
    background-color: ${colors.transparent};
    border: none;
    border-bottom: ${spacing.spacing2} solid ${colors.isabelline};
    border-left: ${spacing.spacing1} solid ${colors.transparent};
    border-right: ${spacing.spacing1} solid ${colors.transparent};
    margin: 0 auto;
    display: block;
    height: 0px;
    position: relative;
    margin-top: -16px;
    top: -12px;
    padding: 0;
    > div {
      display: inline-flex;
      font-weight: bold;
      color: ${colors.blue};
      font-size: 12px;
      line-height: 1em;
      > svg {
        margin-left: 2px;
      }
    }
  }
  >button: focus {
    outline: none;
  }
`;
export const ImagePreview = styled.img`
  display: block;
  margin: 1em ${spacing.spacing0} 1em ${spacing.spacing0};
  border-radius: ${radius.small};
  object-fit: contain;
`;

export const Files = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border-bottom: solid 1px ${colors.springGrey};
    padding-bottom: 8px;
    padding-top: 4px;
    > div:nth-of-type(2) {
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: fit-content;
      > div:first-of-type {
        display: flex;
        justify-content: space-between;
        > span:nth-of-type(1) {
          font-size: ${fontSize.t3};
          color: ${colors.black};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 270px;
          display: block;
        }
        > span:nth-of-type(2) {
          border-radius: 1px;
          background-color: rgba(187, 187, 187, 0.3);
          font-size: 10px;
          padding: 1px 4px;
          position: relative;
          top: -1px;
          color: ${colors.mediumBlack};
          margin-left: 2px;
        }
      }
      > div:nth-of-type(2) {
        font-size: 11px;
        margin-top: 1px;
        line-height: 1.09;
        color: ${colors.manatee};
        display: flex;
        > span {
          margin-right: 2px;
        }
        > span:nth-of-type(2) {
          font-size: 11px !important;
          line-height: 1.09;
          color: ${colors.manatee};
          margin-left: 2px;
        }
      }
    }
  }
`;

export const Checklists = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
`;
