// @flow

import React, { lazy, Suspense } from "react";

import StatusRemove from "src/components/workflow/StatusRemove";
import DownloadPreventedModal from "src/components/DownloadPreventedModal";
import Updates from "src/components/Updates";
import Video from "src/components/Video";
import BlockModal from "src/containers/BlockProcessBuilderModal";
import RestrictedPageModal from "src/containers/RestrictedPageModal";

const ChatroomDialog = lazy(() => import("src/containers/chatroom/Dialog"));
const DisableUserModal = lazy(() => import("src/components/user/Disable"));
const SaveReport = lazy(() => import("src/components/Manage/Reports/Modal"));

const SecondaryModals = () => (
  <>
    <Suspense fallback={<></>}>
      <ChatroomDialog />
    </Suspense>

    <Updates />
    <DownloadPreventedModal />
    <StatusRemove />
    <Video />
    <RestrictedPageModal />
    <BlockModal />
    <Suspense fallback={<></>}>
      <DisableUserModal />
    </Suspense>

    <Suspense fallback={<></>}>
      <SaveReport />
    </Suspense>
  </>
);

export default SecondaryModals;
