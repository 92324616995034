// @flow

import React from "react";
import * as R from "ramda";

import { Chatroom as StyledChatroom, ListLoading } from "./styles";
import Checkbox from "src/components/Checkbox";
import Name from "src/containers/chatroom/Name";

import type { RoomId } from "src/types";

type Props = {
  chatRooms?: Array<Object>,
  handleSelect: Function,
  filter: Array<RoomId>,
  isLoading: boolean
};

const FetchedConversations = ({
  chatRooms = [],
  handleSelect,
  filter,
  isLoading = false
}: Props) => {
  if (isLoading) return <ListLoading>Loading...</ListLoading>;

  return (
    <ul>
      {chatRooms.map(value => (
        <StyledChatroom
          tabIndex="0"
          role="button"
          key={value.id}
          onClick={event => handleSelect(event, value.id)}
        >
          <Checkbox
            id={`chatroom${value.id}`}
            checked={R.includes(value.id, filter || [])}
          />
          <Name
            type={value?.type}
            name={value?.title}
            seqNo={value?.seqNo}
            canceled={value?.cancelled}
            processTitle={value?.processTitle}
            hideProcessTitle
            id={value.id}
          />
        </StyledChatroom>
      ))}
    </ul>
  );
};

export default FetchedConversations;
