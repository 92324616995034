// @flow

import React, { Fragment } from "react";

import Owner from "../Owner";
import DueDate from "../DueDate";
import Details from "../Details";
import Parent from "../Parent";

import type { RoomId, UID } from "src/types";

type Props = {
  description: string,
  dueDate: ?string,
  parent: RoomId,
  owner: ?UID,
  handleOwner: Function,
  handleDueDate: Function,
  handleChange: Function,
  handleParent: Function
};

const Advanced = ({
  description,
  dueDate,
  parent,
  owner,
  handleOwner,
  handleDueDate,
  handleChange,
  handleParent
}: Props) => (
  <Fragment>
    <Owner owner={owner} handleOwner={handleOwner} />
    <DueDate dueDate={dueDate} handleDueDate={handleDueDate} />
    <Details description={description} handleChange={handleChange} />
    <Parent parent={parent} handleParent={handleParent} />
  </Fragment>
);

export default Advanced;
