// @flow

import React from "react";
import moment from "moment";
import * as R from "ramda";
import type { SlackFile, SlackMentions } from "./index";

import {
  VStack,
  HStack,
  Link,
  Text,
  Avatar,
  Box,
  useBoolean
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import MessageText from "src/components/messages/MessageText";
import File from "./File";
import * as styles from "./Message.styles";

import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  author: string,
  messageLink: string,
  channel?: string,
  timestamp: Date,
  content: string,
  showAvatar?: boolean,
  profilePicURL: string,
  files: SlackFile[],
  mentions: SlackMentions
};

export default function Message({
  roomId,
  author,
  messageLink,
  channel,
  timestamp,
  content,
  profilePicURL,
  showAvatar = false,
  files = [],
  mentions = {}
}: Props) {
  const [isFileListOpen, { toggle: toggleFileList }] = useBoolean(
    !R.isEmpty(files)
  );

  const formattedMentions = Object.keys(mentions).reduce(
    (prev, curr) => ({
      ...prev,
      [curr.toUpperCase()]: mentions[curr].realName
    }),
    {}
  );

  return (
    <VStack sx={styles.container}>
      <HStack>
        {showAvatar && (
          <Avatar
            name={author}
            src={profilePicURL}
            size="sm"
            sx={styles.avatar}
          />
        )}

        <VStack spacing={0.5} sx={styles.metaDataContainer}>
          <HStack spacing={1} sx={styles.authorAndChannel}>
            <Link isExternal href={messageLink} sx={styles.author}>
              {author}
            </Link>

            {channel && <Text sx={styles.channel}>from #{channel}</Text>}
          </HStack>

          <Text textStyle="label" sx={styles.timestamp}>
            {moment(timestamp).format("MMM DD, YYYY hh:mm A")}
          </Text>
        </VStack>
      </HStack>

      {!R.isEmpty(content) && (
        <MessageText message={{ text: content }} mentions={formattedMentions} />
      )}

      {!R.isEmpty(files) && (
        <HStack spacing={0.5}>
          <Text textStyle="label">
            {files.length} {files.length === 1 ? "file" : "files"}
          </Text>

          <Box onClick={toggleFileList} sx={{ cursor: "pointer" }}>
            {isFileListOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Box>
        </HStack>
      )}

      {isFileListOpen && (
        <VStack
          sx={{
            width: "100%",
            alignItems: "start"
          }}
        >
          {files.map(file => (
            <File
              name={file.name}
              originalName={file.originalName}
              key={file.name}
              roomId={roomId}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
}
