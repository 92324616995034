// @flow

import { SystemCSSProperties } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const SelectedChartBuilderWrapper: SystemCSSProperties = {
  position: "fixed",
  top: 0,
  left: "54px",
  right: 0,
  bottom: 0,
  h: "100vh",
  flexDirection: "column",
  bg: colors.brightLight,
  overflowY: "auto"
};

export const ChartTitle: SystemCSSProperties = {
  border: "none",
  padding: "1rem",
  fontSize: "1.5rem",
  lineHeight: "2rem",
  fontWeight: 700
};

export const Tab: SystemCSSProperties = {
  bg: colors.brightLight,
  borderBottom: `2px solid ${colors.grey35}`,
  borderColor: colors.grey35
};

export const ChartImage: SystemCSSProperties = {
  width: "10rem",
  height: "7rem",
  border: `1px solid ${colors.active}`,
  p: "6px",
  borderRadius: "2px"
};

export const BuilderInput: SystemCSSProperties = {
  borderRadius: "2px",
  borderColor: colors.skyDark,
  p: "0.5rem",
  h: "2rem"
};

export const Label: SystemCSSProperties = {
  fontSize: "1rem",
  fontWeight: "600",
  m: 0
};

export const FormLabel: SystemCSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
};

export const FormLabelWrapper: SystemCSSProperties = {
  gap: "0.5rem",
  alignItems: "center",
  pb: "0.5rem"
};

export const Details: SystemCSSProperties = {
  flexDirection: "column",
  gap: "2rem"
};

export const FlexColumn: SystemCSSProperties = {
  flexDirection: "column",
  gap: "1rem"
};

export const DropdownButton: SystemCSSProperties = {
  w: "100%",
  h: "2rem",
  borderRadius: "2px",
  bg: colors.brightLight,
  justifyContent: "left",
  border: `1px solid ${colors.skyDark}`,
  fontSize: "1rem",
  p: "0.5rem",
  textAlign: "left",
  _hover: {
    bg: colors.brightLight
  },
  "> span": {
    ml: "auto"
  }
};

export const DropdownItem: SystemCSSProperties = {
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  p: "0.5rem 1rem",
  "> p ": {
    flexGrow: 1,
    width: "100%"
  },
  "> div": {
    flexGrow: 1,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: colors.blue200,
    fontWeight: 500
  },
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const FieldName: SystemCSSProperties = {
  color: colors.dark,
  my: 0
};

export const FieldDetail: SystemCSSProperties = {
  color: colors.blue200,
  fontWeight: 500,
  my: 0
};

export const SelectedFieldDetail: SystemCSSProperties = {
  ...FieldDetail,
  color: colors.inkLightest,
  fontSize: "14px"
};

export const FieldType: SystemCSSProperties = {
  ...FieldDetail,
  display: "flex",
  alignItems: "center",
  gap: "4px"
};

export const SelectedFieldType: SystemCSSProperties = {
  ...SelectedFieldDetail,
  display: "flex",
  alignItems: "center",
  gap: "4px"
};

export const Skeleton: SystemCSSProperties = {
  width: "100%",
  height: "20px"
};

export const SortDropdown: SystemCSSProperties = {
  ...DropdownButton,
  w: "10rem",

  " + div": {
    width: "10rem"
  }
};

export const SortDropdownExpanded: SystemCSSProperties = {
  ...SortDropdown,
  bg: colors.brightLight,
  border: `1px solid ${colors.inkLightest}`
};

export const DropdownExpanded: SystemCSSProperties = {
  ...DropdownButton,
  bg: `${colors.brightLight} !important`,
  border: `1px solid ${colors.inkLightest}`
};

export const ValueInput: SystemCSSProperties = {
  border: "none",
  padding: "4px",
  fontSize: "1.25rem",
  lineHeight: "1.75rem",
  fontWeight: 600
};

export const SeriesContainer: SystemCSSProperties = {
  flexDirection: "column",
  gap: "1rem",
  bg: colors.purpleLighter,
  p: "1rem",
  w: "100%",
  borderRadius: "2px"
};

export const IconButton: SystemCSSProperties = {
  mt: "1rem",
  minW: "auto",
  height: "2.25rem",
  display: "flex",
  alignItems: "center",
  bg: "none",
  _hover: {
    bg: "none"
  },
  _focus: {
    bg: "none"
  },
  _active: {
    bg: "none"
  }
};

export const InputGroup: SystemCSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  "> *:first-child": {
    height: "1.5rem"
  }
};

export const SeriesExpandButton: SystemCSSProperties = {
  bg: colors.purpleLight,
  h: "24px",
  w: "24px",
  minW: "24px",
  borderRadius: "50%"
};

export const AddComparisonButton: SystemCSSProperties = {
  borderRadius: "4px",
  borderColor: colors.purpleLight,
  color: colors.purple,
  w: "calc(100% - 5rem)",
  _hover: {
    bg: colors.purpleLighter
  },
  " + div": {
    w: "calc(100% - 5rem)",
    mt: 1
  }
};

export const Button: SystemCSSProperties = {
  borderRadius: "4px"
};

export const CancelButton: SystemCSSProperties = {
  padding: "4px 1rem",
  fontSize: "14px",
  backgroundColor: colors.grey33,
  color: colors.dark,
  borderRadius: "4px"
};

export const TabsWrapper: SystemCSSProperties = {
  flexDirection: "column",
  pb: "4rem",
  fontSize: "14px"
};

export const DropdownList: SystemCSSProperties = {
  maxHeight: "18rem",
  flexDirection: "column",
  overflowY: "auto"
};

export const ActiveDropdownItem: SystemCSSProperties = {
  ...DropdownItem,
  bg: colors.lightBlue,
  m: 0,
  _hover: {
    backgroundColor: colors.lightBlue,
    cursor: "pointer"
  }
};

export const InActiveDropdownItem: SystemCSSProperties = {
  ...DropdownItem,
  bg: "transparent",
  m: 0
};

export const DisableDropdownItem: SystemCSSProperties = {
  ...DropdownItem,
  opacity: 0.4,
  pointerEvents: "none",
  userSelect: "none",
  cursor: "not-allowed"
};

export const LightGreyText: SystemCSSProperties = {
  color: colors.inkLighter
};

export const DisableGroupBy: SystemCSSProperties = {
  alignItems: "center",
  gap: "0.5rem",
  "> *": {
    cursor: "not-allowed",
    color: colors.inkLightest,
    userSelect: "none"
  }
};

export const SeriesLabel: SystemCSSProperties = {
  fontSize: "1rem",
  fontWeight: 400,
  m: 0
};

export const SortableItemWrapper: SystemCSSProperties = {
  flexDirection: "column",
  gap: "2rem"
};

export const TrendlineColorWrapper: SystemCSSProperties = {
  ...FormLabel,
  maxW: "11.25rem",
  marginLeft: "auto"
};

export const ColorMenuList: SystemCSSProperties = {
  display: "flex",
  gap: "0.5rem",
  minW: "auto",
  maxW: "11.25rem",
  flexWrap: "wrap",
  p: "0.5rem"
};

export const DeleteButton: SystemCSSProperties = {
  ...Button,
  bg: colors.red500,
  color: colors.brightLight,
  _hover: {
    bg: colors.red500
  }
};

export const ModalHeader: SystemCSSProperties = {
  alignItems: "center",
  height: "3.75rem",
  bg: colors.grey0,
  borderRadius: "4px 4px 0 0"
};

export const ModalCloseButton: SystemCSSProperties = {
  position: "static",
  m: "0.75rem 1rem 0.7rem auto",
  py: 0,
  bg: "none"
};

export const PageLayout: SystemCSSProperties = {
  position: "fixed",
  left: "54px",
  right: 0
};

export const GeneratedChart: SystemCSSProperties = {
  ...PageLayout,
  height: "100vh",
  bg: colors.brightLight,
  overflow: "auto",
  px: "0.5rem"
};

export const FooterButtonsWrapper: SystemCSSProperties = {
  ...PageLayout,
  position: "fixed",
  bottom: 0,
  w: "100%",
  gap: "1rem",
  bg: colors.skyLighter,
  p: "1rem 1.5rem",
  zIndex: 2
};

export const RadioStackWrapper: SystemCSSProperties = {
  gap: "1rem",
  "> *": {
    my: "0 !important"
  }
};

export const SortRadioStackWrapper: SystemCSSProperties = {
  ...RadioStackWrapper,
  gap: "0.5rem"
};

export const AdvancedSettingsWrapper: SystemCSSProperties = {
  gap: "1.5rem",
  flexDirection: "column"
};

export const SortRadioWrapper: SystemCSSProperties = {
  gap: "1rem",
  maxWidth: "18rem",
  height: "2rem"
};

export const AdvancedSettingsLabel: SystemCSSProperties = {
  ...Label,
  lineHeight: "1.5rem"
};

export const Text: SystemCSSProperties = {
  fontSize: "1rem",
  lineHeight: "1.25rem",
  my: 0
};
