// @flow

import { connect } from "react-redux";
import React from "react";

import { getReportName } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  title: string
};

const Name = ({ title }: Props) => {
  return <>{title}</>;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  title: getReportName(app, `${props.id}`)
});

export default connect(mapStateToProps)(Name);
