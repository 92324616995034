// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";

import { getRoleTitles } from "src/reducers";
import { getAllRoles } from "src/actions/roleManagement";
import Select from "src/components/Select";
import Loader from "src/components/Loader";

import { Flex, FormLabel, Text } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";
import { styles } from "./styles";

type Props = {
  handleValueChange: Function,
  value: Array<number>
};

const RoleDropdown = ({ handleValueChange, value }: Props) => {
  const roles = useSelector(({ app }) => getRoleTitles(app));

  const dispatch = useDispatch();

  useEffect(() => {
    if (R.isEmpty(roles)) {
      dispatch(getAllRoles());
    }
  }, []);

  return (
    <Flex {...styles.valueSelectContainer}>
      <FormLabel {...styles.label}>Value</FormLabel>
      <Select
        value={value}
        placeholder="Value"
        onChange={handleValueChange}
        multiple
      >
        {R.isEmpty(roles) ? (
          <Loader color={colors.primary} />
        ) : (
          roles.map(role => (
            <Text key={role.id} value={role.id} variant="ink400">
              {role.label}
            </Text>
          ))
        )}
      </Select>
    </Flex>
  );
};

export default RoleDropdown;
