// @flow

import * as atypes from "src/constants/actionTypes";

import type { Error, Action } from "src/types";

const initialState = {};

const error = (state: Error = initialState, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_ERROR:
      return {
        ...state,
        [payload.type]: payload.error
      };
    case atypes.GET_PROFILE_FAILURE:
      return { ...state, profile: "Error in fetching failure" };
    case atypes.UPLOAD_PROFILE_PHOTO_FAILURE:
      return { ...state, profile: "Error in uploading profile photo" };
    case atypes.UPLOAD_PROFILE_PICTURE_FILE_FAILURE:
      return {
        ...state,
        profile: "Error in uploading in profile photo in firebase"
      };
    case atypes.LOAD_WORKFLOW_FAILURE:
      return {
        ...state,
        workflow: "Error in loading Processes"
      };
    case atypes.CREATE_WORKFLOW_FAILURE:
      return {
        ...state,
        workflow: "Error in creating Process"
      };
    case atypes.CREATE_INLINE_WORKFLOW_FAILURE:
      return {
        ...state,
        inlineWorkflow: "Error Creating Draft Process"
      };
    case atypes.EDIT_WORKFLOW_FAILURE:
      return {
        ...state,
        workflow: "Edit Process failure"
      };
    case atypes.SEARCH_USERS_FAILURE:
      return {
        ...state,
        searchUser: "Error in searching users"
      };
    case atypes.LISTEN_FILE_CHANGE_FAILURE:
      return {
        ...state,
        files: "Error in listening for file changes"
      };
    case atypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        files: "Error in uploading filles"
      };
    case atypes.DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        files: "Error in downloading file"
      };
    case atypes.DELETE_FILE_FAILURE:
      return {
        ...state,
        files: "Error in deleting file"
      };
    case atypes.RENAME_FILE_FAILURE:
      return {
        ...state,
        files: "Error in renaming file"
      };
    case atypes.MOVE_FILE_FAILURE:
      return {
        ...state,
        files: "Error in moving file"
      };
    case atypes.CLONE_FILE_FAILURE:
      return {
        ...state,
        files: "Error in cloning file"
      };
    case atypes.PIN_FILE_FAILURE:
      return {
        ...state,
        files: "Error in pinning file"
      };
    case atypes.FETCH_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in fetching folder"
      };
    case atypes.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in creating folder"
      };
    case atypes.DELETE_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in deleting folder"
      };
    case atypes.RENAME_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in renaming folder"
      };
    case atypes.MOVE_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in moving folder"
      };
    case atypes.FETCH_BROWSE_FOLDER_FAILURE:
      return {
        ...state,
        folder: "Error in fetching browser folder"
      };
    case atypes.ADD_ROOM_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        member: "Error in adding room member"
      };
    case atypes.REMOVE_ROOM_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        member: "Error in removing room members"
      };
    case atypes.CLEAR_ERROR:
      return {
        ...state,
        [payload.type]: null
      };
    case atypes.CREATE_CHATROOM_FAILURE:
      return {
        ...state,
        chatroom: "Error in creating chatroom"
      };
    case atypes.INIT_USER_FAILURE:
      return {
        ...state,
        initUser: payload
      };
    case atypes.OPEN_FILE_VIEWER_FAILURE:
      return {
        ...state,
        file: "Error in viewing file"
      };
    case atypes.CLEAR_ALL_ERRORS:
      return {};
    default:
      return state;
  }
};

export default error;
