// @flow

import React from "react";
import type { OrgCloneWizardFieldData } from "src/types";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from "@chakra-ui/react";
import * as styles from "./Email.styles";

type Props = {
  password: OrgCloneWizardFieldData,
  onChange: Function
};

export default function SignIn({ password, onChange }: Props) {
  return (
    <FormControl isInvalid={password.error} isRequired sx={styles.formControl}>
      <FormLabel>Password</FormLabel>
      <Input
        value={password.value}
        onChange={e => onChange(e.target.value)}
        type="password"
      />
      <FormErrorMessage>{password.error}</FormErrorMessage>
    </FormControl>
  );
}
