// @flow

import React from "react";

import useFetch from "src/hooks/useFetch";

import { Text, Image, HStack, VStack } from "@chakra-ui/react";

import * as styles from "./styles";

type Props = {
  data: Object
};

export default function Approval({ data }: Props) {
  const { approved, id, signature, comment } = data;

  const { response, loading } = useFetch(`/signature/signed-url/${signature}`, {
    method: "GET"
  });

  const approvalType = approved ? "Approval" : "Rejection";

  return (
    <VStack minWidth={0} alignItems="start" spacing={3} py={2}>
      <HStack sx={styles.ApprovalTypeInfo}>
        <Text
          sx={{ flexShrink: 0 }}
          color="unifize_active"
          fontWeight={600}
          my={0}
        >
          {approvalType} ID:
        </Text>
        <Text
          isTruncated
          textTransform="uppercase"
          my={0}
          color="unifize_active"
          fontWeight={600}
        >
          {id}
        </Text>
      </HStack>

      {loading ? (
        "img loading..."
      ) : (
        <Image
          src={response?.url}
          alt=""
          objectFit="cover"
          maxWidth="100px"
          height="auto"
        />
      )}

      {comment && (
        <Text whiteSpace="pre-wrap" mb={0} fontWeight={400}>
          {comment}
        </Text>
      )}
    </VStack>
  );
}
