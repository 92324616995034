// @flow

import React from "react";
import { connect } from "react-redux";

import ChartImage from "../ChartImage";

import { getChart } from "src/reducers";

import type { AppState, Charts } from "src/types";

type Props = {
  chart: Charts
};

const Image = ({ chart }: Props) => <ChartImage type={chart.type} />;

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chart: getChart(app, props.id)
});

export default connect(mapStateToProps)(Image);
