// @flow
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactProgressiveList from "react-progressive-list";
import { Box } from "@chakra-ui/react";

import Dropdown from "src/components/Dropdown";
import Downshift from "downshift";
import useBoolean from "src/hooks/useBoolean";
import Domain from "./Domain";
import Selection from "./Selection";

import { getAllOrgDomains } from "src/reducers";

import type { DownshiftProps } from "downshift";
import * as styles from "../styles";
import { MultiSelectContainer, DomainInput, DropdownList } from "../styles";

type Props = {
  domains: Array<string>,
  handleDomains: (domain: string) => void,
  dropdownPosition?: string
};

const SelectInviteMultiple = ({
  domains,
  handleDomains,
  dropdownPosition = "relative"
}: Props) => {
  const inputRef = useRef(null);
  const selectionRef = useRef(null);
  const isInitialRender = useRef(true);
  const prevDomains = useRef(domains);
  const downshiftRef = useRef<DownshiftProps>(null);
  const [query, setQuery] = useState<string>("");

  const options = useSelector(({ app }) => getAllOrgDomains(app));

  const scrollToSelected = (type: string) => {
    const container = selectionRef.current;
    if (container) {
      const hasOverflow = container.scrollHeight > container.clientHeight;
      const lastIndex = domains.length - 1;
      const name = `${type}-${lastIndex}`;
      const element = container.querySelector(`span[name="${name}"]`);
      if (element && hasOverflow) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
  };

  const filteredOptions = options.filter(domain => {
    return domain.includes(query);
  });

  const {
    value: isOpen,
    setTrue: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  const handleSelect = useCallback(
    (domain: string) => {
      if (typeof domain === "string") handleDomains(domain);
      setQuery("");
    },
    [handleDomains, setQuery]
  );

  const unSelectItem = useCallback(
    (domain: string) => {
      handleSelect(domain);
      setQuery("");
    },
    [handleSelect, setQuery]
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      if (domains !== prevDomains.current) {
        scrollToSelected("member");
      }
    }
    isInitialRender.current = false;
    prevDomains.current = domains;
  }, [domains]);

  const handleKeyboard = useCallback(
    e => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (downshiftRef.current != null) {
          downshiftRef.current.internalSetState({
            itemsLength: options.length,
            type: Downshift.stateChangeTypes.keyDownArrowDown
          });
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (downshiftRef.current != null) {
          downshiftRef.current.internalSetState({
            itemsLength: options.length,
            type: Downshift.stateChangeTypes.keyDownArrowUp
          });
        }
      } else if (e.key === "Enter") {
        if (isOpen) {
          e.preventDefault();
          if (downshiftRef.current != null) {
            const highlightedIndex =
              downshiftRef.current.state.highlightedIndex;
            handleSelect(options[highlightedIndex]);

            downshiftRef.current.internalSetState({
              highlightedIndex: highlightedIndex,
              type: Downshift.stateChangeTypes.keyDownEnter
            });
          }
        }
      } else if (e.key === "Backspace") {
        if (domains.length > 0 && query.length === 0) {
          const domainToRemove = domains[domains.length - 1];
          unSelectItem(domainToRemove);
        }
      } else if (e.key === "Escape") closeDropdown();
      else if (e.key === "Tab") closeDropdown();
      else {
        toggleDropdown();
      }
    },
    [handleSelect, options, unSelectItem]
  );

  const onQueryChange = event => {
    setQuery(event.target.value);
  };

  return (
    <Box>
      <MultiSelectContainer
        onClick={() => {
          toggleDropdown();
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        {domains.length > 0 && (
          <Selection
            domains={domains}
            handleRemove={unSelectItem}
            selectionRef={selectionRef}
          />
        )}

        <DomainInput
          id="domain"
          name="domain"
          ref={inputRef}
          size="sm"
          value={query}
          onChange={event => onQueryChange(event)}
          onKeyDown={e => handleKeyboard(e)}
          placeholder="Search domains"
          onFocus={toggleDropdown}
        />
      </MultiSelectContainer>

      {filteredOptions.length > 0 ? (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
          inputFieldSize="sm"
          position={dropdownPosition}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return (
              <DropdownList sx={styles.DropdownList}>
                <ReactProgressiveList
                  initialAmount={10}
                  progressiveAmount={10}
                  role="list"
                  rowCount={filteredOptions.length}
                  renderItem={index => {
                    if (filteredOptions[index]) {
                      return (
                        <Domain
                          key={`domain-${index}`}
                          index={index}
                          highlightedIndex={highlightedIndex}
                          selectedDomains={domains}
                          scrollIntoView={scrollIntoView}
                          {...getItemProps({
                            item: filteredOptions[index],
                            domain: filteredOptions[index],
                            onItemClick: onItemClick
                          })}
                        />
                      );
                    }
                  }}
                />
              </DropdownList>
            );
          }}
        </Dropdown>
      ) : null}
    </Box>
  );
};

export default SelectInviteMultiple;
