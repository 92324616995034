// @flow

import React from "react";

import { Value as StyledValue, Item as StyledItem } from "./styles";
import Icon from "src/icons";
import { SelectionMultiple as StyledSelectionMultiple } from "src/styles/dropdown";
import Display from "src/containers/file/Name";

type Props = {
  value: Array<string>,
  children: any,
  handleSelect: Function
};

const Selection = ({ value, children, handleSelect }: Props) => {
  return (
    <StyledSelectionMultiple>
      {(value || []).map((file: string | Object) => (
        <StyledValue
          key={file}
          type="button"
          onClick={() => handleSelect(file)}
        >
          <StyledItem>
            <Display name={file} />
          </StyledItem>
          <Icon type="close" />
        </StyledValue>
      ))}

      {children}
    </StyledSelectionMultiple>
  );
};

export default Selection;
