// @flow

import React from "react";
import * as R from "ramda";

import type { Node } from "react";
import ChatGroup from "src/components/messages/ChatGroup";

import type { UnifizeChat } from "src/types";

import { getAbsoluteTimeDifference } from "src/utils";

type Props = {
  roomId: string,
  chats: Array<UnifizeChat>,
  renderMessageMarker: Function,
  lastMessage: ?string
};

const ByDay = ({
  chats,
  roomId,
  renderMessageMarker,
  lastMessage
}: {
  chats: Array<Object>
} & Props): Node =>
  R.groupWith(
    (a: Object, b: Object) =>
      a.author === b.author &&
      a.type !== "NOTIFICATION" &&
      b.type !== "NOTIFICATION" &&
      !a.deleted &&
      !b.deleted &&
      !a.hidden &&
      !b.hidden &&
      !a.origin &&
      !b.origin &&
      !a.inReplyTo &&
      !b.inReplyTo &&
      getAbsoluteTimeDifference(a.timestamp, b.timestamp) <= 10,
    chats
  ).map(c => {
    const { id } = c[0];
    return (
      <ChatGroup
        key={id}
        roomId={roomId}
        messages={c}
        renderMessageMarker={renderMessageMarker}
        lastMessage={lastMessage}
      />
    );
  });

export default ByDay;
