// @flow

import React from "react";

import {
  RuleRow,
  WhoColumn,
  WhenColumn,
  RemoveRule,
  CaretDown
} from "./styles";
import Rules from "./Rules";
import Roles from "./Roles";
import Icon from "src/icons";
import { colors } from "src/styles/constants";

type Props = {
  item: any,
  itemId: number,
  removeItem: Function,
  updateItem: Function
};

const Item = ({ item, itemId, removeItem, updateItem }: Props) => (
  <RuleRow>
    <WhoColumn>
      <Roles item={item} updateItem={updateItem} itemId={itemId} />
      <CaretDown>
        <Icon type="dropdownCaret" />
      </CaretDown>
    </WhoColumn>
    <WhenColumn>
      <Rules item={item} updateItem={updateItem} itemId={itemId} />
      <CaretDown>
        <Icon type="dropdownCaret" />
      </CaretDown>
    </WhenColumn>
    <RemoveRule>
      <Icon
        type="close"
        color={colors.lightGrey}
        cursor="pointer"
        size="1.4em"
        handleClick={() => removeItem(itemId)}
      />
    </RemoveRule>
  </RuleRow>
);

export default Item;
