// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";

import Type from "./Type";
import {
  Private as StyledPrivate,
  SubHeading,
  PrivacyContainer,
  Switch as StyledSwitch
} from "./styles";
import { Label } from "src/styles/form";
import * as colors from "src/styles/constants/colors";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import type { Privacy, AppState } from "src/types";

type Props = {
  privacy: Privacy,
  setWorkflowBuiderAttributes: Function
};

const Private = ({ privacy, setWorkflowBuiderAttributes }: Props) => {
  const handlePrivacy = useCallback(
    (checked: boolean) => {
      if (checked) {
        setWorkflowBuiderAttributes({ privacy: "full" });
      } else {
        setWorkflowBuiderAttributes({ privacy: "none" });
      }
    },
    [setWorkflowBuiderAttributes]
  );

  return (
    <StyledPrivate>
      <PrivacyContainer>
        <StyledSwitch>
          <Label>Make private</Label>
          <Switch
            onChange={handlePrivacy}
            uncheckedIcon={false}
            checkedIcon={false}
            checked={privacy !== "none"}
            onColor={colors.checked}
            offColor={colors.grey6}
            className={privacy !== "none" ? "on" : "off"}
            height={18}
            width={31}
          />
        </StyledSwitch>

        <SubHeading>
          Only particiapnts can access these conversations
        </SubHeading>
        <SubHeading>
          Private conversations can only be edited by the Process Owner
        </SubHeading>

        {privacy !== "none" ? <Type privacy={privacy} /> : null}
      </PrivacyContainer>
    </StyledPrivate>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  privacy: app.workflow.builderDialog.privacy
});

export default connect(mapStateToProps, { setWorkflowBuiderAttributes })(
  Private
);
