// @flow

import * as React from "react";

type Props = {};

const SvgComponent = ({ ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...restProps}
  >
    <path
      d="M4.286 18 18 24.857 31.743 18M4.286 24.857 18 31.714l13.743-6.857"
      stroke="#BADA55"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M4.286 11.412 18.014 18l13.73-6.588-13.73-7.126-13.728 7.126Z"
      stroke="#BADA55"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
