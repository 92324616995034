// @flow

import { backendUrl } from "src/config/firebase";

/**
 * Fetching user notification settings
 * @returns { id: number, title: string }
 */
export const getUserNotificationSettings = async () => {
  const response = await fetch(`${backendUrl}/settings/notifications`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.json();
};

/**
 * Fetching user notification settings
 * @param id of the notification setting
 */
export const setUserNotificationSettings = async (settings: {
  email: { id: number }
}) => {
  await fetch(`${backendUrl}/settings/notifications`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(settings)
  });
};
