// @flow

import React from "react";

import Suggestion from "./Suggestion";

const renderSuggestion = (
  entry: Object,
  search: string,
  highlightedDisplay: number,
  index: number,
  focused: boolean
) => {
  return <Suggestion mention={entry} focused={focused} />;
};

export default renderSuggestion;
