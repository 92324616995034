// @flow
import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import * as styles from "./styles";
import type { UseBooleanReturn } from "src/types";

type Props = {
  value: string,
  openDropdown: $PropertyType<UseBooleanReturn, "setTrue">,
  closeDropdown: $PropertyType<UseBooleanReturn, "setFalse">,
  isOpen: $PropertyType<UseBooleanReturn, "value">
};

const SelectedProcessType = ({
  value,
  isOpen,
  openDropdown,
  closeDropdown
}: Props) => {
  return (
    <HStack sx={styles.SelectedProcessType} role="button" tabIndex={0}>
      <Text as="span">{value}</Text>
      {isOpen ? (
        <ChevronUpIcon sx={styles.ToggleDropdownIcon} onClick={closeDropdown} />
      ) : (
        <ChevronDownIcon
          sx={styles.ToggleDropdownIcon}
          onClick={openDropdown}
        />
      )}
    </HStack>
  );
};

export default SelectedProcessType;
