// @flow

import styled from "@emotion/styled";

import { ScrollableBox, ListItemMedium } from "src/styles/box";

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  top: 30px;
  left: 0;
  > ul {
    max-height: 18em;
  }
`;

export const AgeDropdown = styled(Dropdown)`
  left: 7px;
  width: 96%;
`;

export const GroupDropdown = styled(Dropdown)`
  left: 6px;
  width: 95%;
  > input {
    min-width: auto;
    margin-bottom: 10px;
  }
`;

export const Value = styled.span``;

export const ListItem = styled(ListItemMedium)`
  display: flex;
  justify-content: space-between;
  padding: 2px 8px;
`;

export const ListItemGroup = styled(ListItem)`
  > span {
    display: block;
    width: 116px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
