// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import type { Map } from "immutable";

import {
  getChecklistLoaderState,
  getChecklistFieldsBySections
} from "src/reducers";
import type { AppState, RoomId } from "src/types";
import WideChecklist from "./WideChecklist";

type Props = {
  fields: Map<string, Array<number>>,
  checklistId: number,
  roomId: RoomId
};

const Checklist = ({ fields, checklistId, roomId }: Props) => {
  const fieldsArray = fields.get(`${checklistId}`);
  let sectionFields = [];

  // Filters section ids from fieldsArray
  const check = useCallback(
    (n: number) => {
      if (typeof n === "object") {
        sectionFields = [...sectionFields, ...n.fields];
      }
    },
    [sectionFields]
  );

  (fieldsArray || []).forEach(check);

  const filteredFieldsArray = (fieldsArray || []).filter(
    item => !sectionFields.includes(item)
  );

  return (
    <WideChecklist
      id={checklistId}
      roomId={roomId}
      fields={filteredFieldsArray}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    fetchingFields: getChecklistLoaderState(app),
    fields: getChecklistFieldsBySections(app)
  };
};

export default connect(mapStateToProps, null)(Checklist);
