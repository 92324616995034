// @flow

import { connect } from "react-redux";
import React from "react";

import { Creator as StyledCreator } from "./styles";
import User from "src/containers/user/NameOrEmail";

import type { AppState, UID } from "src/types";

type Props = {
  creator: UID
};

const Creator = ({ creator }: Props) => (
  <StyledCreator>Created by: {creator && <User uid={creator} />}</StyledCreator>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  creator: app.dashboard.metaData.creator
});

export default connect(mapStateToProps)(Creator);
