// @flow

import styled from "@emotion/styled";

export const TableContainer = styled.div`
  width: calc(100vw - 6em);
  height: calc(100vh - 14em);
  overflow: auto;
  position: relative;
  z-index: 0;
  scroll-behavior: smooth;

  ::-webkit-scrollbar-track-piece {
    margin-top: 84px;
    margin-left: 190px;
  }
`;

export const Table = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    minmax(190px, 2fr) minmax(60px, 1fr) minmax(80px, 1fr) minmax(90px, 1fr)
    minmax(90px, 1fr) minmax(61px, 1fr) minmax(90px, 1fr) minmax(95px, 1fr) minmax(
      110px,
      1fr
    )
    minmax(100px, 1fr) minmax(70px, 1fr) minmax(100px, 1fr) minmax(95px, 1fr) minmax(
      100px,
      1fr
    )
    minmax(130px, 1fr);
`;
