// @flow

import styled from "@emotion/styled";

import { FileIconWrapper } from "../Dock/Checklist/styles";

export const StyledFileIconWrapper = styled(FileIconWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div > svg {
    width: 300px;
    height: 300px;
  }
`;

export const previewModalContainer = {
  overflow: "auto",
  margin: 2,
  width: ["100%", "570px"]
};

export const previewImageContainer = {
  direction: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
};

export const previewImage = {
  boxSize: "300px",
  objectFit: "cover"
};

export const addButtonContainer = {
  justifyContent: "center",
  mt: "16px"
};
