// @flow

import React, { useCallback } from "react";

import {
  Roles as StyledRoles,
  Selection,
  RemoveSelection,
  SelectedRole
} from "./styles";
import AdditionalOptions from "./AdditionalOptions";
import NameOrEmail from "src/containers/user/NameOrEmail";
import UserSelect from "src/containers/user/select";
import Icon from "src/icons";
import type { PromptRule } from "src/types";

type Props = {
  item: PromptRule,
  itemId: number,
  updateItem: Function
};

const Roles = ({ item, itemId, updateItem }: Props) => {
  const handleUser = useCallback(
    val => {
      updateItem({ uid: val, ruleId: item.ruleId }, itemId);
    },
    [updateItem, itemId, item]
  );

  const { uid, role } = item;

  if (uid) {
    return (
      <SelectedRole>
        <Selection>
          <NameOrEmail uid={uid} />
          <RemoveSelection
            onClick={() => updateItem({ uid: "", ruleId: item.ruleId }, itemId)}
          >
            <Icon type="close" size="1em" cursor="pointer" />
          </RemoveSelection>
        </Selection>
      </SelectedRole>
    );
  }

  if (role) {
    return (
      <SelectedRole>
        <Selection>
          {role}
          <RemoveSelection
            onClick={() =>
              updateItem({ role: "", ruleId: item.ruleId }, itemId)
            }
          >
            <Icon type="close" size="1em" cursor="pointer" />
          </RemoveSelection>
        </Selection>
      </SelectedRole>
    );
  }

  return (
    <StyledRoles>
      <UserSelect
        value={uid}
        handleChange={handleUser}
        noInvite
        renderOptions={() => (
          <AdditionalOptions
            setRole={val =>
              updateItem({ role: val, ruleId: item.ruleId }, itemId)
            }
          />
        )}
      />
    </StyledRoles>
  );
};

export default Roles;
