// @flow

import { connect } from "react-redux";
import React, { memo } from "react";

import {
  LastMessage as StyledMessage,
  NoMessagePlaceholder,
  MessageWrapper,
  MessageTextWrapper
} from "./styles";
import { getLastMessageText, getLastMessageAuthor } from "src/reducers";
import Me from "src/containers/user/Me";
import Timestamp from "src/containers/messages/Timestamp";

import type { AppState, RoomId, UID } from "src/types";

type Props = {
  author: UID,
  text: string,
  roomId: RoomId,
  isCurrentChatroom: boolean,
  hasUnread: boolean,
  direct: boolean
};

const LastMessage = ({
  text,
  author,
  roomId,
  isCurrentChatroom,
  hasUnread,
  direct
}: Props) => {
  return (
    <StyledMessage
      active={isCurrentChatroom}
      hasUnread={hasUnread}
      direct={direct}
    >
      {text && author ? (
        <MessageWrapper>
          <Me uid={author} />
          <span>{": "}</span>
          <MessageTextWrapper>{text}</MessageTextWrapper>
        </MessageWrapper>
      ) : (
        direct && (
          <NoMessagePlaceholder>Send a direct message</NoMessagePlaceholder>
        )
      )}
      <div>
        <Timestamp roomId={roomId} />
      </div>
    </StyledMessage>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  author: getLastMessageAuthor(app, props.roomId),
  text: getLastMessageText(app, props.roomId)
});

export default connect(mapStateToProps)(memo(LastMessage));
