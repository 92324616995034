// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { Small } from "src/styles/modal";
import { SystemCSSProperties, SpinnerProps } from "@chakra-ui/react";

const Modal = styled(Small)`
  > div > div > .ReactModal__Content {
    top: 73px !important;
  }
`;

const DomainName = styled.strong`
  color: ${colors.primary};
  display: inline-block;
  max-width: 9.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 0.4em;
`;

const Label = styled.label`
  display: inline-flex;
`;

export const ModalBody: SystemCSSProperties = {
  display: "flex",
  flexDir: "column",
  alignItems: "start"
};

export const Spinner: SpinnerProps = {
  thickness: "3px",
  speed: "0.65s",
  emptyColor: "gray.200",
  color: "blue.500",
  size: "lg",
  sx: {
    margin: "auto"
  }
};

export { Modal, DomainName, Label };
