// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import Row from "./Row";
import Total from "./Row/Total";
import Average from "./Row/Average";
import VirtualList from "src/components/VirtualList";

import type { AppState, Analytics } from "src/types";

type Props = {
  showTotal: boolean,
  showAverage: boolean,
  analytics: Analytics
};

const Body = ({ analytics, showAverage, showTotal }: Props) => {
  const renderRow = useCallback(
    (index: number) => {
      const row = analytics[index];
      if (row) {
        return <Row key={row.uid} {...row} />;
      }
      return <></>;
    },
    [analytics]
  );
  return (
    <>
      <VirtualList
        initialAmount={50}
        progressiveAmount={20}
        role="list"
        renderItem={renderRow}
        renderLoader={() => <div />}
        rowCount={analytics.length}
      />
      {showTotal ? <Total showAverage={showAverage} /> : null}
      {showAverage ? <Average /> : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  analytics: app.userAnalytics.analytics,
  showTotal: app.userAnalytics.showTotal,
  showAverage: app.userAnalytics.showAverage
});

export default connect(mapStateToProps)(Body);
