// @flow

import styled from "@emotion/styled";
import * as colors from "src/styles/constants/colors";
import { colors as colorsOld } from "src/styles/constants";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { Mentions as StyledMentions } from "src/components/chatroom/Input/styles";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: ${props => props.height}px;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
  padding: ${spacing.space_m} ${spacing.space_l};
  background: ${colors.brightLight};
`;

export const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  > svg {
    width: 36px;
  }
  > div:nth-of-type(2) {
    margin-left: ${spacing.space_m};
    max-width: initial;
  }
`;

export const MenuTooltip = styled.div`
  position: absolute;
  background: ${colors.unifizeBlue};
  padding: ${spacing.space_l};
  font-size: 14px;
  line-height: 18px;
  color: ${colors.brightLight};
  display: flex;
  border-radius: ${radius.small};
  box-shadow: 1px 1px 10px rgba(21, 129, 186, 0.2);
  top: ${spacing.space_xl};
  left: -${spacing.space_m};
  z-index: 1;
  > svg {
    width: 20px;
    margin-left: ${spacing.space_m};
    cursor: pointer;
  }
`;

export const Pointer = styled.div`
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${colors.unifizeBlue};
  top: -12px;
  left: ${spacing.space_m};
`;

export const FlexWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space_m};
  height: 32px;
  align-items: center;
  justify-content: space-between;
`;

export const AppInfoWrapper = styled.div`
  position: relative;
  > div:nth-of-type(2) {
    width: 100vw;
    height: ${props => props.height}px;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const HistoryWrapper = styled.div`
  flex: 1;
  background: ${colors.appBackground};
  overflow-y: auto;
`;

export const ChatInputWrapper = styled.div`
  background: ${colors.appBackground};
  padding: ${spacing.space_l};
  display: grid;
  grid-template-columns: 46px 1fr;
`;

export const ChatInput = styled.div`
  border-radius: ${radius.small};
  flex: 1;
  > div:last-child {
    border: 1px solid ${colors.active};
    border-left: none;
  }
`;

export const Input = styled.div`
  flex-grow: 1;
  border: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: ${colorsOld.pureWhite};
  border: 2px solid ${colorsOld.blue};
  border-radius: ${radius.small};
  width: 100%;
  > div:first-of-type {
    width: 100%;
    height: 100%;
    > div:first-of-type > div:first-of-type {
      max-height: 20em;
      margin: 0px;
      padding: 0px;
      outline: 0px;
      border: 0px;
      line-height: initial;
      height: auto !important;
    }
  }

  :focus-within,
  :hover {
    outline: none;
    border: 2px solid ${colorsOld.blue};
    > button {
      border: 0;
    }
  }

  textarea {
    border: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    max-height: 20em;
    bottom: 0px;
    overflow: auto;
    resize: none;
    margin: 0px;
    padding: 0px;
    outline: 0px;
    border: 0px;
    line-height: initial;
    height: auto !important

    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  }

  ::placeholder {
    font-size: 14px;
    font-style: italic;
    line-height: 17px;
    color: ${colorsOld.disabledBorderGrey};
  }

  .mention-suggestion {
    position: relative;
    background: ${colors.grey15};
    font-weight: bold;
    opacity: 100%;
    z-index: 1;
    color: ${colors.active};

    li:focus {
      background-color: ${colors.grey0};
    }
  }
`;

export const UploadIcon = styled.button`
  outline: none;
  height: 100% !important;
  width: 48px !important;
  background: ${colors.active};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  border-radius: 2px 0px 0px 2px;
`;

export const PanelWrapper = styled.div`
  width: 100vw;
  height: ${props => props.height}px;
  > div {
    height: ${props => props.height}px;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    > div:nth-of-type(2) {
      flex: 1;
    }
  }
`;

export const SelectMenu = styled.div`
  width: 100%;
  height: 48px;
  margin: 17px 0;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteMenu = styled(SelectMenu)`
  justify-content: space-between;
  padding: 0 ${spacing.space_l};
  > div:first-of-type {
    border: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const MenuOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  border: 1px solid ${colors.grey2};
  background: ${colors.grey0};
  margin-right: ${spacing.space_m};
`;

export const Mentions = styled(StyledMentions)`
  left: -${spacing.space_l};
  width: 100%;
`;
