// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Selector as StyledSelector, StyledCheckboxWrapper } from "./styles";
import Checkbox from "src/components/Checkbox";
import { toggleProcessRowSelection } from "src/actions/workflows";
import { isProcessRowSelected } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  roomId: number,
  selected: boolean,
  _toggleProcessRowSelection: Function
};

const Selector = ({ roomId, selected, _toggleProcessRowSelection }: Props) => {
  const handleSelect = useCallback(() => {
    _toggleProcessRowSelection(Number(roomId));
  }, [roomId, _toggleProcessRowSelection]);

  return (
    <StyledSelector selected={selected} onClick={handleSelect}>
      <StyledCheckboxWrapper>
        <Checkbox
          id={`select${roomId}`}
          checked={selected}
          handleChange={handleSelect}
        />
      </StyledCheckboxWrapper>
    </StyledSelector>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  selected: isProcessRowSelected(app, props.roomId)
});

export default connect(mapStateToProps, {
  _toggleProcessRowSelection: toggleProcessRowSelection
})(Selector);
