// @flow

import React from "react";

import Common from "./Common";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Objective = ({ message }: Props) => {
  const { data } = message;
  const { old: oldMssg, new: newMssg } = data;
  if (oldMssg && newMssg) {
    return (
      <Common {...message}>
        {" "}
        updated the objective to: <strong>{newMssg}</strong>
      </Common>
    );
  }
  if (newMssg) {
    return (
      <Common {...message}>
        {" "}
        added objective: <strong>{newMssg}</strong>
      </Common>
    );
  }
  return <Common {...message}> removed objective</Common>;
};

export default Objective;
