// @flow

import { StackProps, GridProps, IconProps } from "@chakra-ui/react";

export const root = {
  px: 2,
  py: 3
};

export const EmptyContainer: StackProps = {
  mb: 4
};

export const StitchesContainer: StackProps = {
  width: "max-content",
  alignItems: "center",
  mb: 4
};

export const StitchItemsStack: StackProps = {
  alignItems: "start"
};

export const StitchContainer: GridProps = {
  width: "660px",
  backgroundColor: "unifize_purpleLighter",
  p: 2,
  templateColumns: "repeat(2, 1fr)",
  gap: 4
};

export const SortableItem: StackProps = {
  alignItems: "start"
};

export const StitchItem: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const StitchLabel = {
  fontSize: "10px"
};

export const DragHandle: IconProps = {
  cursor: "pointer"
};

export const DeleteIcon = {
  cursor: "pointer"
};
