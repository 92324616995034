// @flow

import React from "react";

import File from "./File";
import Icon from "src/icons";
import { FileIcon } from "src/components/Dock/styles.js";
import FileHoverPreview from "src/components/file/HoverPreview";

import { FILE_PREVIEW_LENGTH } from "src/constants/manageView";
import { StyledThumbnail, ExtraFileContainer, ExtraFileCount } from "./styles";

type Props = {
  value: any,
  roomId: string,
  settings: string,
  fieldId: number,
  openChecklistEditModal: Function
};

const FileCellContents = ({
  value,
  roomId,
  settings,
  fieldId,
  openChecklistEditModal
}: Props) => {
  if (!value) return <p></p>;

  if (value.length <= FILE_PREVIEW_LENGTH) {
    return value.map((fileObj, index) => (
      <File roomId={roomId} file={fileObj} key={index} settings={settings} />
    ));
  }

  return value.slice(0, FILE_PREVIEW_LENGTH).map((fileObj, index) => {
    if (index === 4) {
      return (
        <ExtraFileContainer key={index}>
          <StyledThumbnail
            onClick={e => {
              openChecklistEditModal(
                e,
                index,
                fieldId,
                roomId,
                value,
                null,
                true
              );
            }}
          >
            <FileIcon
              style={{
                position: "relative"
              }}
            >
              <Icon key={index} type="extraFile" />
              <ExtraFileCount>
                +{value.length - FILE_PREVIEW_LENGTH + 1}
              </ExtraFileCount>
            </FileIcon>
          </StyledThumbnail>
        </ExtraFileContainer>
      );
    }

    return (
      <File key={index} roomId={roomId} file={fileObj} settings={settings} />
    );
  });
};

export default FileCellContents;
