// @flow

import type { SystemProcess, Action } from "src/types";

import * as atypes from "src/constants/actionTypes";

const systemProcess = (
  state: SystemProcess = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_SYSTEM_PROCESS:
      return payload;
    default:
      return state;
  }
};

export default systemProcess;
