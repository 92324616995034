// @flow
import React from "react";
import { Text, HStack } from "@chakra-ui/react";
import Icon from "src/icons";

import * as styles from "./styles";

type Props = {
  handleRemove: () => void
};

const RemoveSingleUser = ({ handleRemove }: Props) => {
  return (
    <HStack
      data-cy="removeSingleUser"
      sx={styles.RemoveUser}
      onClick={handleRemove}
    >
      <Text sx={styles.RemoveUserText}>Remove selected user</Text>
      <Icon type="checklistRemove" style={styles.RemoveUserIcon} />
    </HStack>
  );
};

export default RemoveSingleUser;
