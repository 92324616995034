// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  creatorIsParticipant: boolean,
  handleCreatorIsParticipant: Function
};

const AddAsParticipant = ({
  handleCreatorIsParticipant,
  creatorIsParticipant
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="creatorIsParticipant"
        checked={
          creatorIsParticipant === undefined ? false : !creatorIsParticipant
        }
        handleChange={handleCreatorIsParticipant}
      />
      {/* Add creator as participant */}
      Do not add creator as participant
    </Label>
  </InlineFormGroup>
);

export default AddAsParticipant;
