// @flow

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";

import Pill from "./Pill";
import RelatedModal from "src/containers/chatroom/Related/RelatedModal";
import { RelativeFlexBox } from "src/styles/box";
import { showConversationalModalWarn } from "src/actions/chatroom";

import type { AppState, RoomId } from "src/types";

type Props = {
  related: number,
  roomId: RoomId,
  conversationModal: boolean,
  showRelatedModal: boolean,
  toggleRelatedModal: Function,
  _showConversationalModalWarn: Function
};

const Related = ({
  related,
  roomId,
  conversationModal,
  showRelatedModal,
  toggleRelatedModal,
  _showConversationalModalWarn
}: Props) => {
  const outerRef = useRef(null);

  const handleRelatedModal = useCallback(() => {
    if (conversationModal) {
      _showConversationalModalWarn();
    } else {
      toggleRelatedModal({ open: !showRelatedModal });
    }
  }, [conversationModal, showRelatedModal, toggleRelatedModal]);

  if (related) {
    return (
      <RelativeFlexBox ref={outerRef} onClick={handleRelatedModal}>
        <Pill related={related} />
        {showRelatedModal ? (
          <RelatedModal
            showRelatedModal={showRelatedModal}
            outerRef={outerRef}
            toggleRelatedModal={toggleRelatedModal}
            roomId={roomId}
          />
        ) : null}
      </RelativeFlexBox>
    );
  }
  return null;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  conversationModal: app.conversationModal.show
});

export default connect(mapStateToProps, {
  _showConversationalModalWarn: showConversationalModalWarn
})(Related);
