// @flow

import { connect } from "react-redux";

import Org from "src/components/chatroom-nav/header/Org";

import { getOrgName } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  orgTitle: getOrgName(app, parseInt(app.orgs.last, 10))
});

export default connect(mapStateToProps)(Org);
