// @flow

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${props => (props?.height ? props?.height : "20px")};

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  visibility: hidden;
  height: 0;
  width: 0;
`;

export const Label = styled.label`
  position: relative;
  font-size: 14px;
  padding-left: 4px;
  cursor: inherit;
`;
