// @flow

import React from "react";

const Descend = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill="#BBB" d="M8 6.99V14h2V6.99h3L9 3 5 6.99h3z" />
    <path fill="#2F80ED" d="M19 17.01L15 21l-4-3.99h3V10h2v7.01h3z" />
  </svg>
);

export default Descend;
