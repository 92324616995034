// @flow

import React from "react";

const ShareDots = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.715C11.4933 11.715 11.04 11.915 10.6933 12.2283L5.94 9.46166C5.97333 9.30832 6 9.15499 6 8.99499C6 8.83499 5.97333 8.68166 5.94 8.52832L10.64 5.78832C11 6.12166 11.4733 6.32832 12 6.32832C13.1067 6.32832 14 5.43499 14 4.32832C14 3.22166 13.1067 2.32832 12 2.32832C10.8933 2.32832 10 3.22166 10 4.32832C10 4.48832 10.0267 4.64166 10.06 4.79499L5.36 7.53499C5 7.20166 4.52667 6.99499 4 6.99499C2.89333 6.99499 2 7.88832 2 8.99499C2 10.1017 2.89333 10.995 4 10.995C4.52667 10.995 5 10.7883 5.36 10.455L10.1067 13.2283C10.0733 13.3683 10.0533 13.515 10.0533 13.6617C10.0533 14.735 10.9267 15.6083 12 15.6083C13.0733 15.6083 13.9467 14.735 13.9467 13.6617C13.9467 12.5883 13.0733 11.715 12 11.715Z"
      fill="#2F80ED"
    />
  </svg>
);

export default ShareDots;
