// @flow

import React from "react";

const Owner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    fill="none"
    viewBox="0 0 14 16"
  >
    <path
      fill="#2A2A2A"
      fillRule="evenodd"
      d="M7 1a3 3 0 0 0-3 3v2h8.5A1.5 1.5 0 0 1 14 7.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 14.5v-7A1.5 1.5 0 0 1 1.5 6H3V4a4 4 0 0 1 7.834-1.143.5.5 0 0 1-.958.286A3 3 0 0 0 7 1zM1.5 7h11a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5zM6 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
      clipRule="evenodd"
    />
  </svg>
);

export default Owner;
