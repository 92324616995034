// @flow

import React from "react";

import { User as StyledUser } from "./styles";
import Checkbox from "src/components/Checkbox";
import ImageLabel from "src/containers/user/ImageLabel";

import type { UID } from "src/types";

type Props = {
  uid: UID
};

const User = ({ uid }: Props) => {
  return (
    <StyledUser>
      <Checkbox id={uid} handleChange={() => {}} />
      <span>
        <ImageLabel uid={uid} />
      </span>
    </StyledUser>
  );
};

export default User;
