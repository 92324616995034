// @flow

import React from "react";

import { PulseContainer, Pulse } from "./styles";

type Props = {
  w?: string,
  h: string,
  maxWidth?: string
};

const PulseLoader = ({ w, h, maxWidth }: Props) => {
  return (
    <PulseContainer w={w} h={h} maxWidth={maxWidth}>
      <Pulse />
    </PulseContainer>
  );
};

export default PulseLoader;
