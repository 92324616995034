// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { ListItem, Flex, Box } from "@chakra-ui/react";
import type { AppState } from "src/types";
import BulbIcon from "src/icons/Bulb";
import {
  setFilter,
  setWorkflowFilter,
  clearType,
  updateSuggestedWorkflows
} from "src/actions/filter";
import { hideFilterModal } from "src/actions/header";
import { showView } from "src/actions";
import { clear } from "src/actions/activeFilter";
import { getWorkflowName } from "src/reducers";

type Props = {
  workflowTitle: string,
  workflowId: number,
  _hideFilterModal: Function,
  emptySearchField: Function,
  _showView: Function,
  _clearActiveFilter: Function,
  _updateSuggestedWorkflows: Function,
  _setWorkflowFilter: Function,
  _clearType: Function
};

const SuggestedListItem = ({
  workflowTitle,
  _hideFilterModal,
  _clearActiveFilter,
  _showView,
  _setWorkflowFilter,
  _updateSuggestedWorkflows,
  _clearType,
  emptySearchField,
  workflowId
}: Props) => {
  const handleSelect = useCallback(
    (id: number) => {
      _clearType();
      _clearActiveFilter();
      _setWorkflowFilter(id, workflowTitle);
      _showView(workflowTitle);
      _updateSuggestedWorkflows(id);
      _hideFilterModal();
      emptySearchField();
    },
    [
      _clearType,
      _clearActiveFilter,
      _setWorkflowFilter,
      workflowTitle,
      _showView,
      _updateSuggestedWorkflows,
      _hideFilterModal,
      emptySearchField
    ]
  );

  return (
    <ListItem
      py="2"
      px="3"
      _hover={{
        background: "gray.100"
      }}
      cursor="pointer"
      onClick={() => handleSelect(workflowId)}
    >
      <Flex alignItems="flex-end">
        <BulbIcon h={7} w={7} />
        <Box ml="2">
          <strong>{workflowTitle}</strong>
        </Box>
      </Flex>
    </ListItem>
  );
};
const mapStateToProps = ({ app }: { app: AppState }, { workflowId }) => ({
  workflowTitle: getWorkflowName(app, workflowId)
});

export default connect(mapStateToProps, {
  _setFilter: setFilter,
  _hideFilterModal: hideFilterModal,
  _showView: showView,
  _setWorkflowFilter: setWorkflowFilter,
  _updateSuggestedWorkflows: updateSuggestedWorkflows,
  _clearType: clearType,
  _clearActiveFilter: clear
})(SuggestedListItem);
