// @flow

import { connect } from "react-redux";
import React from "react";
import usePortal from "react-useportal";

import List from "./List";
import Current from "./Current";
import Loader from "./Loader";
import { NoDashboard } from "./styles";
import { Overlay, Container as StyledDashboard } from "src/styles/splitPane";
import Close from "src/containers/Close";

import type { AppState } from "src/types";

type Props = {
  id: number,
  loadingDashboard: boolean
};

const Dashboard = ({ id, loadingDashboard }: Props) => {
  const { Portal } = usePortal();
  return (
    <Portal>
      <Close />
      <Overlay>
        <StyledDashboard>
          <List />

          {loadingDashboard ? (
            <Loader id={loadingDashboard} />
          ) : (
            <>
              {id ? (
                <Current />
              ) : (
                <NoDashboard>No Dashboard selected</NoDashboard>
              )}
            </>
          )}
        </StyledDashboard>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: app.dashboard.current,
  loadingDashboard: app.dashboard.loadingDashboard
});

export default connect(mapStateToProps)(Dashboard);
