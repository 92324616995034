// @flow
import React from "react";

import Header from "src/components/SearchHeader.old";

type Props = {
  text: string,
  handleSearch: Function,
  handleCreate?: Function
};

const TemplateHeader = ({ text, handleSearch, handleCreate }: Props) => (
  <Header
    text={text}
    handleChange={handleSearch}
    handleCreate={handleCreate}
    button="New Process Template"
    instant
    showJSONImportExport
  />
);

export default TemplateHeader;
