// @flow

import React from "react";
import { FormLabel, Flex } from "@chakra-ui/react";

import ChartImage from "./ChartImage";
import * as styles from "./styles";
import PrimaryField from "./PrimaryField";
import Series from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/Series";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const Details = ({ chartDetails, setChartDetails }: Props) => {
  return (
    <Flex sx={styles.Details}>
      <Flex tabIndex="-1" sx={styles.FlexColumn}>
        <FormLabel sx={styles.Label}>Chart Type</FormLabel>
        <ChartImage />
      </Flex>

      <PrimaryField
        chartDetails={chartDetails}
        setChartDetails={setChartDetails}
      />

      <Series chartDetails={chartDetails} setChartDetails={setChartDetails} />
    </Flex>
  );
};

export default Details;
