// @flow

import React from "react";

type Props = {};

const ArrowLink = ({ ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <path
      d="M4.57129 8H13.9999"
      stroke="#1581BA"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 5L14 8L11 11"
      stroke="#1581BA"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2L2 14"
      stroke="#1581BA"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.14258 2.00014V5.42871"
      stroke="#1581BA"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.14258 10.5714V14"
      stroke="#1581BA"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLink;
