// @flow
import * as colors from "src/styles/constants/colors";

export const ModalOverlay = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  margin: 0,
  backgroundColor: "rgba(0, 0, 0, 0.30)",
  zIndex: 3
};

export const Modal = {
  position: "relative",
  margin: "4rem auto",
  display: "flex",
  flexDirection: "column",
  width: "480px",
  height: "480px",
  backgroundColor: "#fff",
  overflow: "hidden",
  fontSize: "12px"
};

export const ModalHeader = {
  display: "flex",
  alignItems: "center",
  width: "30rem",
  padding: "0.5rem 1rem",
  fontWeight: 700,
  borderRadius: "0.25rem 0.25rem 0px 0px",
  backgroundColor: colors.grey0,
  color: "#000",
  fontSize: "20px",
  margin: "auto 0px"
};

export const ModalClose = {
  marginLeft: "auto",
  padding: "0.5rem"
};

export const ModalBody = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  padding: "1rem",
  backgroundColor: "#fff",
  overflow: "overlay",
  borderBottom: "white solid 1rem",
  scrollbarGutter: "stable",
  paddingRight: "0.5rem"
};
