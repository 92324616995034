/* eslint-disable no-prototype-builtins */
// @flow

import styled from "@emotion/styled";

import { ButtonOutline, Button as DefaultButton } from "src/styles/buttons";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const Header = styled.div`
  margin-bottom: 1.4em;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;

const PrimaryButton = styled(DefaultButton)`
  background: ${props =>
    props.active ? colors.active : colors.secondary} !important;
  outline: none !important;
  border: ${props =>
    props.active ? colors.active : colors.secondary} !important;
`;

const Button = styled(ButtonOutline)`
  position: relative;
  padding: ${spacing.space_inset_squish_m} !important;
  padding-left: ${props =>
    props.hasOwnProperty("paddingLeftRequired") && !props.paddingLeftRequired
      ? "0.6em"
      : "2em"} !important;
  padding-right: 0.6em !important;
  white-space: nowrap;
  color: ${props => (props.color ? props.color : colors.active)} !important;
  background: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : colors.brightLight} !important;
  svg {
    position: absolute;
    top: ${props => (props.translate ? "0.58em" : "0.33em")};
    left: ${props => (props.translate ? "6px" : "0.2em")};
    position: absolute;
  }

  :hover,
  :focus {
    backgroundColor: ${props =>
      props.backgroundColor ? props.backgroundColor : colors.active} !important,
    g,
    rect,
    path {
      stroke: ${colors.brightLight};
    }
`;

const ButtonActive = styled(Button)`
  background: ${colors.active} !important;
  color: ${colors.brightLight} !important;
  margin-right: 0.8em !important;
  g,
  rect,
  path {
    stroke: ${colors.brightLight} !important;
    background: ${props => props.buttonColor};
  }
`;

const Search = styled.form`
  > input {
    width: 35em;
    margin-right: ${spacing.space_l};
  }
  > button {
    width: 8em;
  }
`;

const InstantSearch = styled.div`
  > input {
    width: 35em;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export {
  Header,
  Button,
  Search,
  InstantSearch,
  PrimaryButton,
  ButtonActive,
  Buttons
};
