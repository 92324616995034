// @flow

import React from "react";

import { ImageLabel as StyledImageLabel } from "../styles";
import ImageLabel from "./index";

import type { UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  user: UnifizeUser
};

const Medium = ({ uid, user }: Props) => (
  <StyledImageLabel>
    <ImageLabel uid={uid} user={user} />
  </StyledImageLabel>
);

export default Medium;
