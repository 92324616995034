// @flow

import { useSelector } from "react-redux";
import React from "react";

import { getFormTitle } from "src/reducers";

type Props = {
  formId: number
};

const FormDropdownOption = ({ formId }: Props) => {
  const formTitle = useSelector(({ app }) => getFormTitle(app, formId));

  return <option value={formId}>{formTitle}</option>;
};

export default FormDropdownOption;
