// @flow

import styled from "@emotion/styled";

const Default = styled.div``;

const Builder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 1rem;
  padding-bottom: 2rem;
`;

const StyledBuilder = styled(Builder)`
  padding-left: 0;
`;

const Conditions = styled(Default)`
  padding: 16px !important;
  display: none;
  display: block;
  margin-bottom: 4rem;

  h1 {
    font-size: 20px;
    font-weight: 700;
  }
`;

export default Default;
export { Builder, StyledBuilder, Conditions };
