// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  fill?: string
};

const Eye = ({ ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    {...restProps}
  >
    <path
      d="M12.8845 4C7.92891 4 3.69686 7.11 1.98224 11.5C3.69686 15.89 7.92891 19 12.8845 19C17.84 19 22.0721 15.89 23.7867 11.5C22.0721 7.11 17.84 4 12.8845 4ZM12.8845 16.5C10.149 16.5 7.92891 14.26 7.92891 11.5C7.92891 8.74 10.149 6.5 12.8845 6.5C15.6199 6.5 17.84 8.74 17.84 11.5C17.84 14.26 15.6199 16.5 12.8845 16.5ZM12.8845 8.5C11.2392 8.5 9.91113 9.84 9.91113 11.5C9.91113 13.16 11.2392 14.5 12.8845 14.5C14.5297 14.5 15.8578 13.16 15.8578 11.5C15.8578 9.84 14.5297 8.5 12.8845 8.5Z"
      fill={colors.inkLightest}
    />
  </svg>
);

export default Eye;
