// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Status as StyledStatus,
  Separator,
  PendingText,
  CompleteText,
  CancelledText,
  ArchivedText
} from "./styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import {
  setManageViewFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import { getChecklistFieldsById } from "src/reducers";
import Checkbox from "src/components/Checkbox";
import AllRecordsFilter from "./AllRecordsFilter";
import Name from "src/components/Status/Name";
import SelectAll from "./SelectAll";
import {
  getWorkflowStatus,
  getWorkflowPendingStatuses,
  getWorkflowCompleteStatuses,
  getChecklistFieldLinkedSettings,
  getCustomWorkflowStatuses,
  getAllRecords
} from "src/reducers";
import { getAllRecordsLabel } from "src/utils/filters";
import { UpperCase } from "src/styles";
import { CANCELED } from "src/constants/status";

import type { AppState, ColumnId, WorkflowId } from "src/types";

type Props = {
  column?: ColumnId,
  values: Array<{ id: number, active: boolean }>,
  parentRef: any,
  _setManageViewFilter: Function,
  handleClose: Function,
  pendingStatuses: Array<number>,
  completeStatuses: Array<number>,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<any>,
  getCustomWorkflowStatuses: (workflowId: WorkflowId) => Array<any>,
  fieldLinkedSettings: Object
};

const Status = ({
  column = "status",
  parentRef,
  values,
  _setManageViewFilter,
  handleClose,
  pendingStatuses,
  completeStatuses,
  setFilter,
  filter = [],
  toggleSortBy,
  getCustomWorkflowStatuses,
  fieldLinkedSettings
}: Props) => {
  const dispatch = useDispatch();
  const workflow = useSelector(({ app }) => app.workflow);
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  const allRecordsColumnId = column.includes("-")
    ? column.split("-")[0]
    : column;

  let workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: number | string) => {
      event.preventDefault();
      event.stopPropagation();

      const webWorker = new WebWorker(worker);

      if (R.includes(item, filter)) {
        // $FlowFixMe
        const status = R.reject(R.equals(item), filter);
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });
        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: status
          }
        };

        setFilter(column, status);
      } else {
        const status = [...filter, item];

        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });

        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: status
          }
        };

        webWorker.postMessage(workerData);

        setFilter(column, status);
      }
    },
    [filter, _setManageViewFilter]
  );

  const handleIncludingCancelled = event => {
    if (filter.length === 0) {
      onSelectAll();
    } else {
      handleSelect(event, -3);
    }
  };

  const handleIncludingArchived = event => {
    handleSelect(event, "includeArchived");
  };

  const workflowId =
    typeof fieldLinkedSettings === "string"
      ? JSON.parse(fieldLinkedSettings)["workflow"]
      : fieldLinkedSettings.get("workflow") || "";

  const mappedToPending = R.difference(pendingStatuses, filter).length == 0;
  const mappedToComplete = R.difference(completeStatuses, filter).length == 0;

  // Get the custom workflow statues from store, if status is an embedded field
  const statuses = column.includes("-")
    ? getCustomWorkflowStatuses(workflowId).map(R.prop("id")) || []
    : values.map(R.prop("id"));

  const ids = [...statuses, CANCELED];

  const handleMappedToStatus = (
    mappedStatuses: Array<number>,
    active: boolean
  ) => {
    if (active) {
      const status = R.difference(filter, mappedStatuses);

      setFilter(column, status);
    } else {
      const status = R.union(filter, mappedStatuses);

      setFilter(column, status);
    }
  };

  const onSelectAll = () => {
    setFilter(column, ids);
  };

  const onSelectNone = () => {
    setFilter(column, []);
  };

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>Filter</SubHeading>
      <SelectAll onSelectAll={onSelectAll} onSelectNone={onSelectNone} />
      <StyledStatus
        onClick={() => handleMappedToStatus(pendingStatuses, mappedToPending)}
      >
        <Checkbox checked={mappedToPending} />
        <span>
          Mapped to <PendingText>Pending</PendingText>
        </span>
      </StyledStatus>
      <StyledStatus
        onClick={() => handleMappedToStatus(completeStatuses, mappedToComplete)}
      >
        <Checkbox checked={mappedToComplete} />
        <span>
          Mapped to <CompleteText>Complete</CompleteText>
        </span>
      </StyledStatus>
      <StyledStatus onClick={handleIncludingArchived}>
        <Checkbox checked={R.includes("includeArchived", filter)} />
        <span>
          Include <ArchivedText>Archived</ArchivedText>
        </span>
      </StyledStatus>
      <StyledStatus onClick={handleIncludingCancelled}>
        <Checkbox checked={R.includes(-3, filter)} />
        <span>
          Include <CancelledText>Cancelled</CancelledText>
        </span>
      </StyledStatus>
      <Separator />
      {column.includes("-") && (
        <AllRecordsFilter
          label={`All ${getAllRecordsLabel(
            allRecordsColumnId,
            fieldsById
          )} records`}
          isAllRecords={allRecords[allRecordsColumnId]}
          columnId={allRecordsColumnId}
          workerData={workerData}
        />
      )}
      <ul>
        {ids.map(value => (
          <StyledStatus
            tabIndex="0"
            role="button"
            key={value}
            onClick={event => handleSelect(event, value)}
          >
            <Checkbox
              id={`status${value}`}
              checked={R.includes(value, filter)}
              handleChange={event => handleSelect(event, value)}
            />
            <UpperCase>
              <Name id={value} />
            </UpperCase>
          </StyledStatus>
        ))}
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const fieldLinkedSettings = getChecklistFieldLinkedSettings(
    app,
    props.column ? parseInt(`${props.column.split("-")[0]}`, 10) : -1
  );

  return {
    values: getWorkflowStatus(app, props.id),
    fieldLinkedSettings,
    getCustomWorkflowStatuses: getCustomWorkflowStatuses(app),
    pendingStatuses: getWorkflowPendingStatuses(app, props.id),
    completeStatuses: getWorkflowCompleteStatuses(app, props.id)
  };
};

export default connect(mapStateToProps, {
  _setManageViewFilter: setManageViewFilter
})(Status);
