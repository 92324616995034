// @flow

import React from "react";
import { Flex, Spacer, Heading } from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import * as colors from "src/styles/constants/colors";

type Prop = {
  title: string,
  isLocked: boolean
};

const PermissionTitle = ({ title, isLocked }: Prop) => {
  return (
    <Flex alignItems="center">
      <Heading
        variant="tableCell"
        color={isLocked ? colors.inkLightest : colors.ink}
      >
        {title}
      </Heading>
      <Spacer />
      {isLocked ? <LockIcon w={5} h={6} color={colors.inkLightest} /> : null}
    </Flex>
  );
};

export default PermissionTitle;
