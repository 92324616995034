// @flow

import * as R from "ramda";
import type {
  RuleBlock,
  ConditionBlock as RuleBlockV1,
  ConditionV2,
  Condition as ConditionV1,
  BehaviorV2
} from "src/types";
import { behaviorStatesBySchemaVersion, v2Operators } from "./v1tov2Converter";
import { fields as v1Fields } from "src/conditions";

const v2tov1FieldMap = {
  owner: v1Fields.owner,
  "due-date": v1Fields.dueDate,
  status: v1Fields.status,
  user: v1Fields.currentUser
};

const getV1Condition = (condition: ConditionV2): ConditionV1 => {
  let v1Condition = {};

  let transforms = [];

  if (condition.entity === "checklist-field") {
    transforms.push(
      R.mergeDeepLeft({
        field: v1Fields.checklistField,
        checklistFieldId: condition.entityId
      })
    );
  } else if (condition.entity === "chatroom") {
    if (condition.field && R.has(condition.field)(v2tov1FieldMap))
      transforms.push(
        R.mergeDeepLeft({
          // $FlowFixMe
          field: v2tov1FieldMap[condition.field],
          checklistFieldId: null
        })
      );
  } else if (condition.entity === "user") {
    transforms.push(
      R.mergeDeepLeft({
        field: v2tov1FieldMap[condition.entity],
        checklistFieldId: null
      })
    );
  }

  if (condition.op === v2Operators.equalTo) {
    if (condition.value === null) {
      transforms.push(
        R.mergeDeepLeft({
          type: "isEmpty",
          value: null
        })
      );
    } else if (condition.value === "approved") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isApproved",
          value: null
        })
      );
    } else if (condition.value === "current-user") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isCurrentUser",
          value: null
        })
      );
    } else {
      transforms.push(
        R.mergeDeepLeft({
          type: "isEqualTo",
          value: condition.value
        })
      );
    }
  } else if (condition.op === v2Operators.notEqualTo) {
    if (condition.value === null) {
      transforms.push(
        R.mergeDeepLeft({
          type: "isFilled",
          value: null
        })
      );
    }
  } else if (condition.op === v2Operators.in) {
    if (condition.field === "role") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isOfRole",
          value: condition.value
        })
      );
    } else if (condition.field === "group") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isPartOf",
          value: condition.value
        })
      );
    } else {
      transforms.push(
        R.mergeDeepLeft({
          type: "isAnyOf",
          value: condition.value
        })
      );
    }
  } else if (condition.op === v2Operators.notIn) {
    if (condition.field === "role") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isNotOfRole",
          value: condition.value
        })
      );
    } else {
      transforms.push(
        R.mergeDeepLeft({
          type: "isNoneOf",
          value: condition.value
        })
      );
    }
  } else if (condition.op === v2Operators.greaterThan) {
    if (condition.value === "current-user") {
      transforms.push(
        R.mergeDeepLeft({
          type: "isOverdue",
          value: null
        })
      );
    } else {
      transforms.push(
        R.mergeDeepLeft({
          type: "isGreaterThan",
          value: condition.value
        })
      );
    }
  } else if (condition.op === v2Operators.lessThan) {
    transforms.push(
      R.mergeDeepLeft({
        type: "isLessThan",
        value: condition.value
      })
    );
  }

  transforms.forEach(transform => {
    v1Condition = transform(v1Condition);
  });

  return v1Condition;
};

// TODO: only supports status, not checklist fields
const getV1Behavior = (behavior: BehaviorV2): Object => {
  let v1Behavior = {
    current:
      behaviorStatesBySchemaVersion.find(R.propEq("v2", behavior.state))?.v1 ||
      "shown"
  };

  const props = ["options", "mandatory", "tooltip"];

  props.forEach(prop => {
    if (R.has(prop)(behavior)) {
      v1Behavior = R.mergeDeepRight(v1Behavior, {
        [prop]: behavior[prop]
      });
    }
  });

  return v1Behavior;
};

// TODO: behavior only supports status, not checklist fields
export const convert = (blocks: RuleBlock[]): RuleBlockV1[] => {
  let v1Blocks = [];

  blocks.forEach(block => {
    let updatedBlock = {
      conditions: block.conditions.map(getV1Condition),
      behavior: getV1Behavior(block.behavior)
    };

    if (R.has("id")(block)) {
      updatedBlock = {
        ...updatedBlock,
        id: block.id
      };
    }

    v1Blocks.push(updatedBlock);
  });

  return v1Blocks;
};
