// @flow

import React, { Fragment } from "react";

import SearchItem from "src/containers/workflow/Item";
import { SearchResult } from "src/styles/dropdown";
import autoPosition from "src/components/autoPosition";

import type { UID } from "src/types";

type Props = {
  focus: number,
  result: Array<UID>,
  handleSelect: Function
};

const Result = ({ result, focus, handleSelect }: Props) => (
  <Fragment>
    {result.length > 0 ? (
      <SearchResult>
        <ul>
          {result.map((item, i) => (
            <SearchItem
              key={item}
              handleSelect={handleSelect}
              id={item}
              focus={focus === i}
            />
          ))}
        </ul>
      </SearchResult>
    ) : null}
  </Fragment>
);

export default autoPosition(Result, {
  bottomOffset: "36px",
  topOffset: "4px",
  position: "top",
  leftOffset: "-1px",
  rightOffset: "-3px"
});
