// @flow

import React from "react";

import { FormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  statusDisabled: boolean,
  handleStatus: Function
};

const DisableStatus = ({ statusDisabled, handleStatus }: Props) => (
  <FormGroup>
    <Label>
      <Checkbox
        id="disabledStatus"
        checked={statusDisabled}
        handleChange={handleStatus}
      />
      Convert to feed
    </Label>
  </FormGroup>
);

export default DisableStatus;
