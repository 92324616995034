// @flow

import firebase from "firebase/app";
import "firebase/firestore";

import * as R from "ramda";

import connection from "src/db";
import type { UID, FilterTimeMap, SuggestedWorkflows } from "src/types";

/**
 * This api saves the filter to the backend
 * @param {Object} filter The filter to be saved in the backend
 * @returns {Object} containing the saved filter
 */
export const putFilter = async ({ uid, filters }: Object) => {
  const ref = connection().collection(`userData/${uid}/appData`).doc("pinned");

  ref.set({ filters });
  return filters;
};

/*
 * API to set last read time of standard / custom pinned lists
 *
 * @param {string} key - "custom" or "standard"
 * @param {UID} uid - User's UID
 * @param {orgId} number - Org ID in which the operation is performed
 * @return void
 */
const setLastRead = async (
  key: string,
  uid: UID,
  orgId: number,
  lastReadObj: FilterTimeMap
) => {
  // Convert moment objects into Firebase timestamp
  const lastRead = R.map(
    momentObj => firebase.firestore.Timestamp.fromDate(momentObj.toDate()),
    lastReadObj
  );
  await connection()
    .collection(`userData/${uid}/appData/settings/${orgId}`)
    .doc("pinned")
    .set({ [key]: lastRead }, { merge: true });
};

export const setStandardLastRead: Function = R.partial(setLastRead, [
  "standard"
]);

export const setCustomLastRead: Function = R.partial(setLastRead, ["custom"]);

/**
 * Set suggested list
 *
 * @param {UID} uid of user
 * @param {number} orgId current org of the user
 * @param {SuggestedWorkflows} workflows Suggested filter to be saved
 * @return {Array} return array of ids (of uniq values)
 */
export const setSuggestedWorkflows = async (
  uid: UID,
  orgId: number,
  workflows: SuggestedWorkflows
) => {
  const suggestedProcesses = await connection()
    .collection(`userData/${uid}/appData/settings/${orgId}`)
    .doc("suggestedProcesses")
    .set({ ids: workflows });

  return suggestedProcesses;
};

/**
 * Get suggested list
 *
 * @param {UID} uid of user
 * @param {number} orgId current org of the user
 * @return {Array} return array of ids (of uniq values)
 */
export const getSuggestedWorkflows = async (uid: UID, orgId: number) => {
  const suggestedProcesses = await connection()
    .collection(`userData/${uid}/appData/settings/${orgId}`)
    .doc("suggestedProcesses")
    .get();

  const result = suggestedProcesses.data();
  return result?.ids;
};
