// @flow

import React from "react";

import Icon from "src/components/Icon";
import { IconLabel as StyledIconLabel } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import { iconSize } from "src/styles/constants/size";

type Props = {
  icon: string,
  color?: string,
  size?: string,
  label: string,
  cursor?: string,
  fontColor?: string
};

const IconLabel = ({
  icon,
  color = colors.primary,
  label,
  size = iconSize.space_s,
  cursor = "default",
  fontColor = colors.primary
}: Props) => (
  <StyledIconLabel cursor={cursor}>
    <Icon icon={icon} color={color} size={size} />
    <span style={{ color: fontColor }}>{label}</span>
  </StyledIconLabel>
);

export default IconLabel;
