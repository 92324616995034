// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { EmailReplyTo } from "../styles";
import Message from "./Message";
import File from "./File";

import EmailContent from "src/components/messages/Chat/Notification/FileOrEmail/EmailContent";
import ChecklistFields from "src/components/messages/Chat/Notification/ChecklistFields/Content";
import { getMessageData } from "src/reducers";
import { fetchMessage } from "src/actions/chatroom";
import type { AppState } from "src/types";
import * as notifications from "src/constants/notifications";

type Props = {
  currentMessage: Object,
  id: string,
  _fetchMessage: Function,
  isCurrentUser: boolean
};

const ReplyTo = ({
  currentMessage,
  id,
  _fetchMessage,
  isCurrentUser
}: Props) => {
  useEffect(() => {
    if (id && !currentMessage) {
      _fetchMessage(id);
    }
  }, []);

  if (currentMessage) {
    if (currentMessage.action === notifications.FILE_ADD) {
      return <File message={currentMessage} isCurrentUser={isCurrentUser} />;
    }
    if (
      currentMessage.action === notifications.EMAIL_SENT ||
      currentMessage.action === notifications.EMAIL_RECEIVED
    )
      return (
        <EmailReplyTo isCurrentUser={isCurrentUser}>
          <EmailContent message={currentMessage} />
        </EmailReplyTo>
      );
    if (currentMessage.action === notifications.CHECKLIST_FIELD_FILL)
      return (
        <ChecklistFields
          data={currentMessage.data}
          timestamp={currentMessage.timestamp}
          isCurrentUser
        />
      );

    return (
      <Message currentMessage={currentMessage} isCurrentUser={isCurrentUser} />
    );
  }
  return null;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  currentMessage: getMessageData(app, props.id)
});

export default connect(mapStateToProps, { _fetchMessage: fetchMessage })(
  ReplyTo
);
