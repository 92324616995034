// @flow

import React, { useCallback } from "react";

import { InstantSearch as StyledInstantSearch } from "./styles";
import { GreyInput } from "src/styles/input";

type Props = {
  search: string,
  handleSearch: Function
};

const InstantSearch = ({ search, handleSearch }: Props) => {
  const handleChange = useCallback((e: any) => {
    const { value } = e.target;
    handleSearch(value || "");
  }, []);

  return (
    <StyledInstantSearch>
      <GreyInput
        placeholder="Search"
        type="text"
        value={search}
        onChange={handleChange}
      />
    </StyledInstantSearch>
  );
};

export default InstantSearch;
