// @flow

import React from "react";
import * as R from "ramda";

import { Users as StyledUsers, ParticipantCount } from "./styles";
import User from "src/containers/user/picture/Small";

import type { UID } from "src/types";

type Props = {
  users: Array<UID>
};

const Users = ({ users }: Props) => (
  <StyledUsers>
    {R.take(5, users || []).map(uid => (
      <User uid={uid} key={uid} />
    ))}
    <ParticipantCount>
      {(users || []).length > 5 ? `+${(users || []).length - 5}` : ""}
    </ParticipantCount>
  </StyledUsers>
);

export default Users;
