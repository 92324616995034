// @flow

import { connect } from "react-redux";
import type { State } from "src/types";

import AlertModal from "src/components/Modal/Alert";

const mapStateToProps = ({ app }: State) => {
  const { message, canLeave } = app.modal.blockNavigation;
  if (message?.title !== "Restricted page") return { shown: false };

  return {
    shown: true,
    ...message,
    onSubmit: () => canLeave && canLeave(false)
  };
};

export default connect(mapStateToProps)(AlertModal);
