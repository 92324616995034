// @flow

import React from "react";
import * as R from "ramda";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/AccordionContent";
import Automations from "../Automations";

import type { RevisionFieldSettingsv2 } from "src/types";

type Props = {
  settings: RevisionFieldSettingsv2,
  saveSettings: Function
};

const MarkedCurrent = ({ settings, saveSettings }: Props) => {
  const handleAutomation = (
    action:
      | "addParticipants"
      | "removeParticipants"
      | "addParticipantsFromFields"
      | "sendMessage"
      | "updatePrivacy"
      | "updateStatus"
      | "archive",
    data
  ) => {
    saveSettings(current => {
      const actionValue = current.automations["newCurrent"];
      const index = R.findIndex(R.propEq("action", action))(actionValue);
      const currentAction = actionValue[index];
      const updatedAction = {
        ...currentAction,
        data
      };

      const mergeObj = {
        automations: {
          ["newCurrent"]: R.update(index, updatedAction, actionValue)
        }
      };

      return R.mergeDeepRight(current, mergeObj);
    });
  };

  const handleStatus = (
    action:
      | "addParticipants"
      | "removeParticipants"
      | "addParticipantsFromFields"
      | "sendMessage"
      | "updatePrivacy"
      | "updateStatus"
      | "archive",
    active
  ) => {
    saveSettings(current => {
      const actionValue = current.automations["newCurrent"];
      const index = R.findIndex(R.propEq("action", action))(actionValue);
      const currentAction = actionValue[index];
      const updatedAction = {
        ...currentAction,
        active
      };

      const mergeObj = {
        automations: {
          ["newCurrent"]: R.update(index, updatedAction, actionValue)
        }
      };

      return R.mergeDeepRight(current, mergeObj);
    });
  };

  return (
    <Accordion title="For conversation being marked current">
      <AccordionContent>
        {settings.automations.newCurrent.map((automation, index) => (
          <Automations
            key={index}
            isNewCurrent
            value={automation.data}
            status={automation.active || false}
            action={automation.action}
            onChange={value => handleAutomation(automation.action, value)}
            onStatusChange={value => handleStatus(automation.action, value)}
          />
        ))}
      </AccordionContent>
    </Accordion>
  );
};

export default MarkedCurrent;
