// @flow

import { connect } from "react-redux";

import Loader from "src/components/Manage/Workflow/Instances/Table/Loader";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  loading: app.form.loadingInstances
});

export default connect(mapStateToProps)(Loader);
