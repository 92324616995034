// @flow

import React from "react";

type Props = {
  fill?: string
};

const AlignCenter = ({ fill, ...restProps }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...restProps}>
    <title>ic_format_align_center_24px</title>
    <g className="nc-icon-wrapper" fill={fill || "#212b36"}>
      <path d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z" />
    </g>
  </svg>
);

export default AlignCenter;
