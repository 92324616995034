// @flow

import { connect } from "react-redux";
import React from "react";

import { TitleWrapper } from "./styles";

import { getChatroomMembership } from "src/reducers";
import SmallTitle from "src/containers/chatroom/Title/Small";
import UnreadCount from "src/containers/chatroom-nav/ChatBlock/UnreadCount";

import type { AppState } from "src/types";

type Props = {
  roomId: any,
  membership: any
};

const Title = ({ roomId, membership }: Props) => (
  <TitleWrapper>
    <SmallTitle roomId={roomId} />
    {membership ? <UnreadCount roomId={roomId} /> : null}
  </TitleWrapper>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  membership: getChatroomMembership(app, props.roomId)
});

export default connect(mapStateToProps)(Title);
