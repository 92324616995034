// @flow

import Raven from "raven-js";

import { takeEvery } from "redux-saga/effects";

import type { Action } from "src/types";

/*
 * Saga for logging all action which ends with _FAILURE or _ERROR
 *
 */

function* logErrorToService({ type, payload }: Action) {
  try {
    if ((payload || {}).status === 404) {
      console.log("resource not found");
    } else {
      yield Raven.captureException(type, { extra: payload });
    }
  } catch (error) {
    console.log("Error occured while logging error", error);
  }
}

function* watchFailure(): any {
  yield takeEvery(
    action => /_FAILURE$|_ERROR$/gi.test(action.type),
    logErrorToService
  );
}

export default [watchFailure()];
