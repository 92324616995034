// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const Modal = styled.div`
  > div > .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 20;
  }

  > div > div > .ReactModal__Content {
    overflow: auto;
    padding: 0px !important;
    position: relative;
    top: 78px !important;
    left: 11% !important;
    right: 32% !important;
    bottom: 0px !important;
    width: 85%;
    height: 80%;
    border-radius: 0px !important;
    > div {
      width: 100%;
      height: 100%;
    }
  }

  > div > .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: ${colors.overlay} !important;
  }
`;

const Small = styled(Modal)`
  > div > .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 50;
  }
  > div > div > .ReactModal__Content {
    overflow: visible !important;
    height: auto;
    width: 400px;
    bottom: auto !important;
    top: 163px !important;
    left: 36% !important;
    border-radius: 4px !important;
  }

  > div > .ReactModal__Content.ReactModal__Content--after-open {
    overflow: visible !important;
  }
`;

const Conversation = styled.div`
  position: absolute;
  margin: auto;
  z-index: 1000;
  top: ${props => (props.left ? "54px" : "60px")} !important;
  left: ${props => (props.left ? "75px" : "40%")} !important;
  width: 561px;
  min-height: 185px;
`;

const RelatedModal = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Content {
    overflow: auto;
    padding: 0px !important;
    position: initial !important;
    width: 460px;
    height: 620px;
    > div {
      width: 100%;
    }
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: ${colors.overlay} !important;
  }
`;

const Centered = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0px !important;
    top: 0px !important;
    border: 0px solid transparent;
    background-color: transparent !important;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: ${colors.overlay} !important;
  }
`;

const FullScreen = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 2;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    overflow: hidden !important;
  }

  > div > div > .ReactModal__Content.ReactModal__Content--after-open {
    top: 0px !important;
    left: 30px !important;
    right: 0px !important;
    bottom: 0px !important;
    padding-left: 25px !important;
    padding-top: 8px !important;
    border: none !important;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${colors.overlay};
  z-index: 998;
`;

export default Modal;
export { Conversation, Small, RelatedModal, FullScreen, Centered };
