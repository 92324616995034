// @flow

import * as R from "ramda";
import { approvalFieldStatuses } from "src/constants";

import type { ApprovalFieldValue, UID } from "src/types";

/**
 * Returns if the field is disabled or locked for a user
 * @param {ApprovalFieldValue} value - value of the appproval field
 * @param {UID[]} allowedSignatories - users allowed to accept/reject
 * the approval
 * @param {boolean} canCurrentUserCancel - if the current user has
 * permission to cancel the approval request
 * @param {boolean} locked - if the field is locked
 * @param {UID} currentUserId - UID of the currently logged in user
 *
 * @return {boolean} - whether the field is disabled/locked for the user
 */
export const getDisabledState = ({
  value,
  allowedSignatories,
  canCurrentUserCancel,
  locked,
  currentUserId
}: {
  value: ApprovalFieldValue,
  allowedSignatories: UID[],
  canCurrentUserCancel: boolean,
  locked: boolean,
  currentUserId: UID
}) => {
  if (value.status === approvalFieldStatuses.approved)
    return locked || !canCurrentUserCancel;

  return locked || !allowedSignatories.includes(currentUserId);
};

export const getLastApprovalSignatureCount = (value: ApprovalFieldValue) => {
  const lastRevision = R.last(value.revisions);

  if (!lastRevision) return 0;

  const lastApproval = R.last(lastRevision.approvals);

  if (!lastApproval) return 0;

  return lastApproval.signatures.length;
};
