// @flow

import React from "react";

import { Selection as StyledSelection, RemoveIcon } from "./styles";
import Icon from "src/icons";

type Props = {
  name: string,
  type: string,
  processTitle: string,
  seqNo: number,
  removeHandler: Function
};

const Selection = ({
  name,
  processTitle,
  type,
  seqNo,
  removeHandler
}: Props) => {
  let heading = name;

  if (type === "workflow") {
    heading = `${processTitle || ""}${seqNo ? ` #${seqNo || ""}` : ""}${
      name ? `: ${name} ` : ""
    }`;
  }

  return (
    <StyledSelection>
      <div data-cy="chatroomTitle">{heading}</div>
      <RemoveIcon onClick={removeHandler}>
        <Icon type="close" />
      </RemoveIcon>
    </StyledSelection>
  );
};

export default Selection;
