// @flow

import React from "react";
import moment from "moment";
import * as R from "ramda";

import type { UnifizeFile, UnifizeFileVersion } from "src/types";

type Props = {
  file: UnifizeFile
};

const LastModified = ({ file }: Props) => {
  const getLatestVersion = (version: Array<UnifizeFileVersion>) => {
    if (version) {
      const latest = R.last(version);
      if (latest) {
        return latest;
      }
      return {};
    }
    return {};
  };

  if (file) {
    const { uploadTime } = getLatestVersion(file.versions);
    const timeStamp = uploadTime
      ? moment(uploadTime).format("MMM DD, YYYY hh:mm A")
      : "";

    return <span>{timeStamp}</span>;
  }

  return <></>;
};

export default LastModified;
