// @flow

import React from "react";

import NameOrEmail from "../NameOrEmail";
import Picture from "../picture";
import Icon from "src/icons";

import type { UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  user: UnifizeUser,
  noTooltip?: boolean,
  hideName?: boolean,
  location?: string,
  isActive?: boolean,
  isCurrentUser?: boolean
};

const ImageLabel = ({
  uid,
  user,
  noTooltip =false,
  location,
  hideName = false,
  isCurrentUser = false
}: Props) => {
  return (
    <>
      {isCurrentUser ? (
        <Icon type="me" />
      ) : (
        <Picture uid={uid} user={user} location={location} />
      )}
      {!hideName && (
        <NameOrEmail
          uid={uid}
          user={user}
          noTooltip={noTooltip}
          isCurrentUser={isCurrentUser}
        />
      )}
    </>
  );
};

export default ImageLabel;
