// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const signupCode = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.USER_SIGNUP_FAILURE:
      return null;
    case atypes.USER_SIGNUP_SUCCESS:
      return payload.code;
    default:
      return state;
  }
};

export default signupCode;
