// @flow

import { TabProps, TabPanelsProps, TabPanelProps } from "@chakra-ui/react";

export const tabs: TabProps = {
  height: "100%"
};

export const tabPanels: TabPanelsProps = {
  height: "calc(100% - 175px)"
};

export const tabPanel: TabPanelProps = {
  height: "100%"
};
