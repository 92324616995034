// @flow

import { connect } from "react-redux";

import Input from "src/components/chatroom/Input/Chat";
import { insertChat, deleteTyping } from "src/actions/chatroom";
import { clearReplyTo } from "src/actions/chatmenu";
import { triggerClearInput } from "src/actions/input";
import { getCurrentUserId } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app),
  isSrw: app.srw.isSingleResponse
});

export default connect(mapStateToProps, {
  clearReply: clearReplyTo,
  insertMssg: insertChat,
  triggerClearInput,
  deleteTyping
})(Input);
