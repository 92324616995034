// @flow

import React from "react";

const Iown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#1581BA"
      d="M8 3.933a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8zm0 6c1.98 0 4.067.973 4.067 1.4v.733H3.933v-.733c0-.427 2.087-1.4 4.067-1.4zm0-7.267a2.666 2.666 0 1 0 0 5.333 2.666 2.666 0 1 0 0-5.333zm0 6c-1.78 0-5.333.893-5.333 2.667v2h10.666v-2c0-1.774-3.553-2.667-5.333-2.667z"
    />
  </svg>
);

export default Iown;
