// @flow

import { connect } from "react-redux";
import React from "react";

import type { Set } from "immutable";
import User from "src/containers/filter/User";
import { getCurrentUser } from "src/reducers";
import { ListItemMedium as StyledItem } from "src/styles/box";
import { loginAs } from "src/actions/auth";

import type { AppState, UnifizeUser, UID } from "src/types";

type Props = {
  users: Set<UID>,
  currentUser: UnifizeUser,
  _loginAs: Function
};

const AllUsers = ({ users, currentUser, _loginAs }: Props) => {
  return (
    <ul>
      {users.keySeq().map(id =>
        id !== currentUser.uid ? (
          <div
            tabIndex="0"
            role="button"
            key={id}
            onClick={() => _loginAs(id)}
            onKeyPress={() => _loginAs(id)}
          >
            <StyledItem>
              <User id={id} />
            </StyledItem>
          </div>
        ) : null
      )}
    </ul>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  users: app.users.allIds,
  currentUser: getCurrentUser(app)
});

export default connect(mapStateToProps, {
  _loginAs: loginAs
})(AllUsers);
