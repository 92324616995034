// @flow

import React from "react";

import unifizeUser from "src/containers/user";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const NameOrEmailText = ({ user }: Props) => (
  <>{user.loading ? "Loading user..." : user.displayName || user.email}</>
);

export default unifizeUser(NameOrEmailText);
