// @flow

import React from "react";

const Save = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#9B51E0"
      d="M11.333 2H4.667c-.734 0-1.327.6-1.327 1.333L3.333 14 8 12l4.667 2V3.333c0-.733-.6-1.333-1.334-1.333zm0 10L8 10.547 4.667 12V3.333h6.666V12z"
    />
  </svg>
);

export default Save;
