// @flow

import React from "react";

import {
  FormWrapper,
  OuterWithLogoAndNav,
  LogoWrapper,
  UnifizeLogo
} from "src/styles/auth";

const ContainerWithLogo = ({ children }: { children: any }) => (
  <FormWrapper>
    <OuterWithLogoAndNav>
      <LogoWrapper>
        <UnifizeLogo />
      </LogoWrapper>
      {children}
    </OuterWithLogoAndNav>
  </FormWrapper>
);

export default ContainerWithLogo;
