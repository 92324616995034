// @flow

import React from "react";

import {
  UploadedBy as StyledUploadedBy,
  Display as StyledDisplay
} from "./styles";
import Display from "src/containers/file/Display";
import Size from "src/containers/file/Size";
import LastModified from "src/containers/file/LastModified";
import UploadedBy from "src/containers/file/UploadedBy";

type Props = {
  file: string
};

const Row = ({ file }: Props) => {
  return (
    <tr key={file}>
      <StyledDisplay>
        <Display name={file} />
      </StyledDisplay>
      <td>
        <Size name={file} />
      </td>
      <td>
        <LastModified name={file} />
      </td>

      <StyledUploadedBy>
        <UploadedBy name={file} />
      </StyledUploadedBy>
    </tr>
  );
};

export default Row;
