// @flow

import React from "react";
import * as R from "ramda";
import type { LocalSettings } from "./index";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  Radio,
  RadioGroup,
  VStack,
  Flex,
  HStack,
  Input
} from "@chakra-ui/react";
import Icons from "src/icons";
import Condition from "src/components/Manage/Builder/Checklist/Conditions/Condition";
import Behavior from "src/components/Manage/Builder/Checklist/Conditions/Behavior";
import { styles as conditionStyles } from "src/components/Manage/Builder/Checklist/Conditions/styles";

type Props = {
  localSettings: LocalSettings,
  setLocalSettings: ((state: LocalSettings) => LocalSettings) => void
};

export default function Rules({ localSettings, setLocalSettings }: Props) {
  const { rules } = localSettings;

  const handleDefaultBehaviorChange = newState => {
    setLocalSettings(
      R.mergeDeepLeft({
        rules: {
          defaultBehavior: { state: newState }
        }
      })
    );
  };

  const handleDefaultDisabledTooltipChange = e => {
    setLocalSettings(
      R.mergeDeepLeft({
        rules: {
          defaultBehavior: { tooltip: e.target.value }
        }
      })
    );
  };

  const setConditionBlocks = updatedBlocks => {
    setLocalSettings(
      R.mergeDeepLeft({
        rules: {
          blocks: updatedBlocks
        }
      })
    );
  };

  const addRuleBlock = () => {
    setLocalSettings(prevState =>
      R.mergeDeepLeft({
        rules: {
          blocks: R.append({
            conditions: [
              {
                field: "",
                checklistFieldId: null,
                type: null,
                value: []
              }
            ],
            behavior: {
              current: "shown"
            }
          })(prevState.rules.blocks)
        }
      })(prevState)
    );
  };

  const updateCondition = (
    condition,
    blockIndex,
    conditionIndex,
    resetBehavior?: boolean = true
  ) => {
    const conditions = R.update(
      conditionIndex,
      {
        ...rules.blocks[blockIndex].conditions[conditionIndex],
        ...condition
      },
      rules.blocks[blockIndex].conditions
    );
    const updatedConditionBlocks = R.update(
      blockIndex,
      {
        conditions,
        behavior: resetBehavior ? null : rules.blocks[blockIndex].behavior
      },
      rules.blocks
    );

    setConditionBlocks(updatedConditionBlocks);
  };

  const addCondition = (blockIndex: number) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      {
        ...rules.blocks[blockIndex],
        conditions: [
          ...rules.blocks[blockIndex].conditions,
          {
            field: "",
            checklistFieldId: null,
            type: null,
            value: []
          }
        ]
      },
      rules.blocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  const updateBehavior = (blockIndex, behavior) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      { ...rules.blocks[blockIndex], behavior },
      rules.blocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  const removeBlock = (blockIndex: number) => {
    setConditionBlocks(
      rules.blocks.filter(function (item, index) {
        return index !== blockIndex;
      })
    );
  };

  const removeCondition = (blockIndex: number, conditionIndex: number) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      {
        ...rules.blocks[blockIndex],
        conditions: rules.blocks[blockIndex].conditions.filter(
          (condition, index) => index !== conditionIndex
        )
      },
      rules.blocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  return (
    <VStack sx={{ width: "100%", alignItems: "start", gap: 24, pt: 2 }}>
      <RadioGroup
        value={rules.defaultBehavior?.state}
        onChange={handleDefaultBehaviorChange}
      >
        <VStack sx={{ width: "100%", alignItems: "start" }}>
          <Radio value="hidden">Hide until condition met</Radio>
          <Radio value="enabled">Enable until condition met</Radio>
          <VStack>
            <Radio value="locked">Disable until condition met</Radio>

            <Input
              value={rules.defaultBehavior?.tooltip || ""}
              onChange={handleDefaultDisabledTooltipChange}
              isDisabled={rules.defaultBehavior?.state !== "locked"}
              size="sm"
              placeHolder="Enter tooltip text"
            />
          </VStack>
        </VStack>
      </RadioGroup>

      <VStack sx={{ width: "100%", alignItems: "start" }}>
        {rules.blocks.map((conditionBlock, index) => (
          <Flex key={index} {...conditionStyles.conditionContainer}>
            <HStack justifyContent="flex-end" spacing={2}>
              <CopyToClipboard text={JSON.stringify({ rules })}>
                <Button variant="transparentLink">Copy</Button>
              </CopyToClipboard>

              <span
                style={conditionStyles.iconContainer}
                onClick={() => removeBlock(index)}
              >
                <Icons type="removeCircle" />
              </span>
            </HStack>
            {conditionBlock.conditions.map((condition, conditionIndex) => (
              <Condition
                key={condition.field}
                field={condition.field}
                fieldId={condition.checklistFieldId}
                blockIndex={index}
                conditionIndex={conditionIndex}
                updateCondition={updateCondition}
                type={condition.type}
                value={condition.value}
                removeCondition={removeCondition}
              />
            ))}
            <Button
              variant="link"
              {...conditionStyles.addButton}
              onClick={() => addCondition(index)}
            >
              + Add condition (AND)
            </Button>
            <Behavior
              behavior={conditionBlock.behavior?.current}
              mandatory={!!conditionBlock.behavior?.mandatory}
              options={conditionBlock.behavior?.options}
              fieldId={null}
              updateBehavior={updateBehavior}
              blockIndex={index}
              currentFieldType="status"
              settings={null}
              isFormField={false}
              tooltip={conditionBlock.behavior?.tooltip || ""}
            />
          </Flex>
        ))}

        <Button isFullWidth onClick={addRuleBlock}>
          &#43; Add New Condition
        </Button>
      </VStack>
    </VStack>
  );
}
