// @flow

import { put, takeEvery, select, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";

import * as atypes from "src/constants/actionTypes";
import getAppState, { getUser } from "src/selectors";
import * as api from "src/api/dashboard";
import * as R from "ramda";
import { sortByTitleCaseInsensitive } from "src/reducers/dashboard";

import type { Action } from "src/types";

function* showDashboard({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          query: { id: payload.id || null },
          page: "dashboard"
        }
      });
    } else {
      // Fetch all dashboards
      const dashboards = yield call(api.getAllDashboard);
      yield put({
        type: atypes.GET_ALL_DASHBOARD_SUCCESS,
        payload: { dashboards }
      });

      if (payload.id) {
        yield put({
          type: atypes.SET_DASHBOARD_SUCCESS,
          payload
        });
      } else {
        // If user tries to visit /dashboard
        if (dashboards.length === 0) {
          // User has no dashboards then go to /dashboard
          yield put({
            type: atypes.SET_DASHBOARD_SUCCESS,
            payload
          });
        } else {
          // Go to the first dashboard
          const firstDashboard = R.head(sortByTitleCaseInsensitive(dashboards));
          yield put({
            type: atypes.SET_DASHBOARD_REQUEST,
            payload: {
              id: firstDashboard.id
            }
          });
        }
      }
    }
  } catch (error) {
    yield put({
      type: atypes.SET_DASHBOARD_FAILURE,
      payload: { error }
    });
  }
}

function* watchShowDashboard(): any {
  yield takeEvery(atypes.SET_DASHBOARD_REQUEST, showDashboard);
}

function* createDashboard({ payload }: Action): any {
  try {
    const dashboard = yield call(api.createDashboard, payload.title);
    yield put({
      type: atypes.CREATE_DASHBOARD_SUCCESS,
      payload: dashboard
    });

    yield put({
      type: atypes.SET_DASHBOARD_REQUEST,
      payload: {
        id: dashboard.id
      }
    });

    toast.success("created dashboard");
  } catch (error) {
    toast.error("Unable to create dashboard");
    yield put({
      type: atypes.CREATE_DASHBOARD_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateDashboard(): any {
  yield takeLatest(atypes.CREATE_DASHBOARD_REQUEST, createDashboard);
}

function* getCurrentDashboard(): any {
  try {
    const { current } = (yield select(getAppState)).dashboard;

    if (current) {
      const currentDashboard = yield call(api.getDashboard, current);

      yield put({
        type: atypes.GET_DASHBOARD_SUCCESS,
        payload: currentDashboard
      });
    }

    yield put({
      type: atypes.LOADED_DASHBOARD,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.GET_ALL_DASHBOARD_FAILURE,
      payload: {}
    });

    yield put({
      type: atypes.LOADED_DASHBOARD,
      payload: {}
    });
  }
}

function* watchGetCurrentDashboard(): any {
  yield takeLatest(atypes.SET_DASHBOARD_SUCCESS, getCurrentDashboard);
}

function* editDashboardTitle({ payload }: Action): any {
  const oldTitle = (yield select(getAppState)).dashboard.metaData.title;

  try {
    yield put({
      type: atypes.EDIT_DASHBOARD_TITLE_OPTIMISTIC,
      payload: {
        id: payload.id,
        title: payload.title
      }
    });

    yield call(api.updateDashboard, payload.id, {
      title: payload.title
    });
  } catch (error) {
    yield put({
      type: atypes.EDIT_DASHBOARD_TITLE_FAILURE,
      payload: {
        id: payload.id,
        title: oldTitle
      }
    });
    toast.error("Unable to change title of the report");
  }
}

function* watchEditDashboardTitle(): any {
  yield takeLatest(atypes.EDIT_DASHBOARD_TITLE_REQUEST, editDashboardTitle);
}

function* addChartToDashboard({ payload }: Action): any {
  try {
    const chart = yield call(api.addChartToDashboard, payload);
    yield put({
      type: atypes.ADD_CHART_TO_DASHBOARD_SUCCESS,
      payload: {
        chart
      }
    });
    toast.success("Chart has been added to dashboard");
  } catch (error) {
    yield put({
      type: atypes.ADD_CHART_TO_DASHBOARD_FAILURE,
      payload: {}
    });
    toast.error("Unable to add chart to dashboard");
  }
}

function* watchAddChartToDashboard(): any {
  yield takeLatest(atypes.ADD_CHART_TO_DASHBOARD_REQUEST, addChartToDashboard);
}

function* deleteDashboard({ payload }: Action): any {
  try {
    yield call(api.deleteDashboard, payload.dashboard);
    yield put({
      type: atypes.DELETE_DASHBOARD_SUCCESS,
      payload
    });

    yield put({
      type: atypes.SET_DASHBOARD_REQUEST,
      payload: {}
    });

    toast.success("Dashboard has been deleted");
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unable to delete dashboard");
  }
}

function* watchDeleteDashboard(): any {
  yield takeLatest(atypes.DELETE_DASHBOARD_REQUEST, deleteDashboard);
}

function* deleteChartFromDashboard({ payload }: Action): any {
  try {
    yield call(api.deleteChartFromDashboard, payload);
    yield put({
      type: atypes.DELETE_CHART_FROM_DASHBOARD_SUCCESS,
      payload
    });
    toast.success("Chart removed from the dashboard");
  } catch (error) {
    console.log(error);
    toast.error("Unable to remove chart");
  }
}

function* watchDeleteChartFromDashboard(): any {
  yield takeLatest(
    atypes.DELETE_CHART_FROM_DASHBOARD_REQUEST,
    deleteChartFromDashboard
  );
}

export default [
  watchDeleteChartFromDashboard(),
  watchDeleteDashboard(),
  watchAddChartToDashboard(),
  watchEditDashboardTitle(),
  watchShowDashboard(),
  watchCreateDashboard(),
  watchGetCurrentDashboard()
];
