// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ifProp } from "styled-tools";

import task from "../img/task.svg";
import taskDark from "../img/taskDark.svg";
import group from "../img/group.svg";
import conversation from "../img/conversation.svg";
import workflows from "../img/workflows.svg";

import date from "../img/date.svg";
import favourite from "../img/favourite.svg";
import favouriteDark from "../img/favouriteDark.svg";
import favouriteSolid from "../img/favouriteSolid.png";
import search from "../img/search.svg";
import related from "../img/related.svg";
import searchWhite from "../img/search-white.png";
import close from "../img/close.png";
import attachment from "../img/attachment.svg";
import clone from "../img/clone.png";
import cloudUpload from "../img/cloud-upload.png";
import download from "../img/download.png";
import folder from "../img/folder.png";
import forward from "../img/forward.png";
import organise from "../img/organise.png";
import pin from "../img/pin.svg";
import funnel from "../img/funnel.svg";

import revision from "../img/revision.png";
import viewFile from "../img/view-file.png";
import pdf from "../img/pdf.svg";
import word from "../img/word.svg";
import excel from "../img/excel.svg";
import powerpoint from "../img/powerpoint.png";
import image from "../img/image.svg";
import back from "../img/back.png";
import arrowForward from "../img/arrow-forward.png";
import trash from "../img/trash.png";
import redo from "../img/redo.png";
import undo from "../img/undo.png";
import arrowUp from "../img/arrow-up.png";
import arrowDown from "../img/arrow-down.png";
import doubleTick from "../img/doubleTick.svg";
import smallDoubleTick from "../img/doubleTick.svg";
import workflow from "../img/workflow.svg";
import hierarchy from "../img/hierarchy.png";
import contacts from "../img/contacts.svg";
import mention from "../img/mention.svg";
import av from "../img/av.png";
import settings from "../img/settings.png";
import admin from "../img/admin.png";
import drag from "../img/drag.png";
import workflowLight from "../img/workflow-light.png";
import checklistLight from "../img/checklist-light.svg";
import remove from "../img/remove.png";
import link from "../img/link.png";
import attachmentLight from "../img/attachment-light.svg";
import file from "../img/file.png";
import fullscreen from "../img/fullscreen.png";
import completed from "../img/completed.svg";
import bluePlus from "../img/bluePlus.png";
import whiteDoubleTick from "../img/white-double-tick.png";
import addFolder from "../img/folder-add.png";
import incomplete from "../img/incomplete.svg";
import rejected from "../img/rejected.svg";
import savedMessage from "../img/savedMessages.svg";
import tasks from "../img/tasks.svg";
import tasksDark from "../img/taskDark.svg";
import tasksLight from "../img/tasks-light.svg";
import searches from "../img/searches.svg";
import searchesLight from "../img/searches-light.svg";
import squarePlus from "../img/squarePlus.svg";
import dateCompleted from "../img/date-completed.svg";
import dateWarning from "../img/date-warning.svg";
import approvals from "../img/approval.svg";
import approvalsLight from "../img/approval-light.svg";
import newchat from "../img/newchat.svg";
import newchatLight from "../img/newchat-light.svg";
import groups from "../img/groups.svg";
import groupsLight from "../img/groups-light.svg";
import cross from "../img/cross.svg";
import everything from "../img/everything.svg";
import everythingDark from "../img/everything.svg";
import mychats from "../img/mychats.svg";
import mychatsDark from "../img/mychatsDark.svg";
import clock from "../img/clock.svg";
import ellipsis from "../img/ellipsis.svg";
import gmail from "../img/gmail.svg";
import yahoo from "../img/yahoo.png";
import outlook from "../img/outlook.png";
import greenDoubleTick from "../img/greenDoubleTick.png";
import { colors, size, spacing, fontSize } from "./constants";
import * as newColors from "./constants/colors";
import * as newSpacing from "./constants/spacing";
import chat from "src/img/chat.svg";
import workflowsLight from "src/img/workflows-light.svg";
import chatLight from "src/img/chat-light.svg";
import integrity from "src/img/integrity.png";

/**
 * A lookup table for all our icons so that we can resolve the names
 * passed as params to components into the images.
 *
 * TODO: There is considerable repetition, the same things appear in the
 * import and the table below. There must be a way to access the imports
 * directly from inside the `styled' templates below. But eval() reports
 * that it is unable to resolve the strings into imports.
 *
 *  @type {Object}
 */
export const icons = {
  integrity,
  funnel,
  favourite,
  favourites: favourite,
  "favourites-dark": favouriteDark,
  favouriteSolid,
  searchWhite,
  home: searchWhite,
  search,
  group,
  attachment,
  clone,
  cloudUpload,
  download,
  folder,
  forward,
  hierarchy,
  organise,
  pin,
  revision,
  pdf,
  cross,
  word,
  excel,
  powerpoint,
  image,
  close,
  back,
  arrowForward,
  trash,
  incomplete,
  viewfile: viewFile,
  redo,
  undo,
  arrowUp,
  arrowDown,
  date,
  conversation,
  doubleTick,
  smallDoubleTick,
  task,
  "task-dark": taskDark,
  workflow,
  contacts,
  "attachment-light": attachmentLight,
  approvals,
  decision: approvals,
  "approvals-light": approvalsLight,
  av,
  settings,
  admin,
  related,
  reply: contacts,
  drag,
  remove,
  "workflow-light": workflowLight,
  "checklist-light": checklistLight,
  TASK_CREATE: task,
  DECISION_CREATE: approvals,
  TASK_COMPLETE: task,
  CONVERSATION_COMPLETE: related,
  CONVERSATION_START: conversation,
  CONVERSATION_CREATE: conversation,
  WORKFLOW_CREATE: workflow,
  WORKFLOW_COMPLETE: workflow,
  DECISION_COMPLETE: approvals,
  link,
  file,
  fullscreen,
  completed,
  bluePlus,
  whiteDoubleTick,
  addFolder,
  rejected,
  mention,
  savedMessage,
  tasks,
  mytasks: tasks,
  "tasks-dark": tasksDark,
  "tasks-light": tasksLight,
  workflows,
  "workflows-dark": workflows,
  "workflows-light": workflowsLight,
  searches,
  "searches-light": searchesLight,
  squarePlus,
  dateCompleted,
  dateWarning,
  newchat,
  "newchat-light": newchatLight,
  groups,
  "groups-light": groupsLight,
  everything,
  "everything-dark": everythingDark,
  mychats,
  "mychats-dark": mychatsDark,
  mydecisions: approvals,
  "mydecisions-dark": approvals,
  clockIcon: clock,
  ellipsis,
  gmail,
  yahoo,
  outlook,
  greenDoubleTick,
  chat,
  "chat-light": chatLight
};

export const Container = styled.div`
  font-family: "Lato", sans-serif;
  font-size: ${size.regular};
  overflow: auto;
  display: grid;
  transition: all 0.2s ease-in;
  height: 100vh;

  grid-template-columns: 40px auto;
`;

export const HomeContainer = styled(Container)`
  overflow-y: hidden;
`;

export const Body = styled.div`
  height: 100vh;
  width: 100%;
  grid-column: 2 / span 1;
  display: grid;
  grid-template-columns: 26.25rem minmax(400px, 1fr) auto;
`;

export const HiddenInput = styled.div`
  background: transparent;
  color: transparent;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  display: flex;
  top: 0px;
  left: 0px;
  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const User = styled.div`
  width: 3em;
  height: 3em;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-right: 0px;
  text-transform: lowercase;
  background: ${colors.unifizeBlue};
  border: none;
  font-size: 1rem;
  color: ${colors.pureWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const NavProfileImage = styled(User)`
  background-color: transparent;
  width: 3.37em;
  height: 3.37em;
  margin-bottom: 0.4em;
  img {
    width: ${props => (props.active ? "1.8em" : "2em")};
    height: ${props => (props.active ? "1.8em" : "2em")};
    border: 3px solid
      ${props => (props.active ? newColors.blue : "transparent")};
    background-color: ${props =>
      props.active ? newColors.blue : newColors.brightLight};
  }
`;

export const NoProfilePic = styled.div`
  width: ${props => (props.active ? "1.8em" : "2em")};
  height: ${props => (props.active ? "1.8em" : "2em")};
  border: 3px solid ${props => (props.active ? newColors.blue : "transparent")};

  background-color: ${colors.unifizeBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InlineProfileImage = styled(User)`
  width: 25px;
  height: 32px;
  box-sizing: border-box;
  font-size: 1.375rem;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
`;

export const SmallProfileImage = styled(User)`
  margin: 1em auto;
  width: 3em;
  height: 3em;
  border-radius: 0px;
  padding: 1em 0 0 0;
  font-size: 1.375rem;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3em;
  ~ input {
    display: none;
  }
`;

export const UserSmall = styled(User)`
  margin: 0px;
  width: 2em;
  height: 2em;
  padding: 0 !important;
  border-radius: 1px;
`;

export const Logout = styled.div`
  text-align: center;
  margin: 1em auto;
`;

export const ChatRooms = styled.div`
  height: 100vh;
  margin-left: ${newSpacing.space_l};
  box-shadow: 10px 5px 5px ${newColors.inkLightest};
  overflow-y: clip;
  -ms-overflow-style: none; // IE 10+
  box-sizing: content-box;
  > div:first-of-type {
    background-color: ${colors.pureWhite};
    height: 100%;
  }
  & > h4 {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
  }
`;

// ------------------------------------------------------------------

// Chat Components
export const Icon = styled.div`
  background-image: url(${({ icon }) => icons[icon]});
  background-repeat: no-repeat;
  height: ${props =>
    size.iconSize[props.size] ? size.iconSize[props.size] : "14px"};
  width: ${props =>
    size.iconSize[props.size] ? size.iconSize[props.size] : "19px"};
  background-size: contain;
  margin: ${props =>
    props.icon === "conversation" ? "0px 8.5px 0px 13.5px" : "0px"};
  cursor: ${props => (props.cursor !== undefined ? props.cursor : "default")};
`;

export const MessageCount = styled.span`
  height: 16px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: ${colors.orange};
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: 600;
`;

export const Error = styled.span`
  color: ${colors.error};
  display: block;
  padding: ${spacing.spacing1} ${spacing.spacing1};
`;

export const Success = styled.div`
  color: ${colors.Success};
`;

export const Chat = styled.div`
  position: relative;
  margin: 10px 0;
  margin-top: ${ifProp("origin", "0")};
  flex: 1;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(42, 42, 42, 0.08);
  background-color: #ffffff;
  border: solid 1px #e7e8ec;
  min-width: 10em;
  textarea {
    outline: none !important;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open,
  .ReactModal__Content.ReactModal__Content--after-open {
    position: static !important;
    padding: 0px !important;
    border: 0px !important;
    margin: 0px !important;
  }
`;

export const ReplyTo = styled.div`
  cursor: pointer;
  > div > div:nth-of-type(2) {
    color: ${colors.dullGrey} !important;
    font-size: 0.75rem !important;
  }
  > div:first-of-type {
    flex-grow: 1;
    background: ${ifProp(
      "isCurrentUser",
      newColors.currentUserHighlight,
      colors.greenGrey
    )};
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 12px !important;
    display: block !important;
    position: relative;
    margin-left: 0px;
    margin-top: 2px;
    > div:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:first-of-type {
        font-size: 14px;
        flex-grow: 1;
        min-height: 16px !important;
        font-weight: bold;
        color: ${colors.black};
      }
      > span:nth-of-type(2) {
        opacity: 0.4;
        font-family: Lato;
        font-size: 11px !important;
        position: relative;
        top: -2px;
        color: ${colors.dullGrey} !important;
      }
    }
    > div:nth-of-type(2) {
      margin-left: 0;
      margin-top: 4px;
      > div {
        display: flex;
        width: 100%;
        > div:nth-of-type(2) {
          margin-left: 8px;
          font-size: 13px;
          line-height: 1.31;
          color: ${colors.dullGrey};
          flex-grow: 1;
          height: 16px !important;
        }
      }
    }
  }
`;

export const Reply = styled.div`
  width: calc(100% - 36px);
  margin: 0 16px;
  padding: 0.8em 5.6em;
  position: relative;
  background: ${colors.pureWhite};
  border-top-left-radius: 0.8em;
  border-top-right-radius: 0.8em;
  max-height: 69vh;
  overflow: auto;
`;

export const Timestamp = styled.span`
  display: flex;
  opacity: 0.4;
  margin-left: 0.4em;
  min-width: 58px;
  text-align: right;
  font-family: Lato;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b4b4b;
  position: relative;
  top: 1px;
`;

/**
 * Modals
 */

export const Modal = styled.div`
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(255, 255, 255); /* Fallback color */
  //background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const ModalStickyHeader = styled.div`
  height: 4em;
  padding: 5px;
  top: -20px;
  margin-top: -20px;
  position: sticky;
  z-index: 100;
  display: flex;
  background: ${colors.pureWhite};
  justify-content: flex-end;
  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid #888;
    `} .close-button {
    cursor: pointer;
  }
  > div:last-child {
    position: absolute;
    right: -8px;
    top: 5px;
  }
`;

export const IconNotification = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.blue};
  > svg {
    position: absolute;
  }
`;

export const IconBlock = styled(Icon)`
  background-position: center;
  cursor: pointer;
`;

export const FileIcon = styled(Icon)`
  background-position: left;
  background-size: contain;
`;

export const FolderIcon = styled(Icon)`
  background-position: left;
  background-size: contain;
`;

export const UploadButton = styled.div`
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const ImageThumbnail = styled.img`
  height: 1.5625rem;
  width: 2.5em;
  align-self: center;
`;

export const PinModal = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    width: 227px;
    padding: 10px 10px 5px 10px !important;
    border: 1px solid ${colors.unifizeBlue} !important;

    transform: translate(-50%, -50%) !important;
  }
`;

export const PinFile = styled.div`
  textarea {
    margin-top: 1em;
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 5px;
    background-color: #ffffff;
    resize: none;
    border: solid 1px #2e4c68;
  }

  div:nth-last-child(1) {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
`;

export const PinMessage = styled.div`
  display: flex;
  background-color: ${colors.simpleGrey};
  padding: 1em;
  border-radius: 5px;
  border: solid 0.5px ${colors.unifizeBlue};

  div:nth-last-child(1) {
    font-size: ${size.xxLarg};
    color: ${colors.unifizeBlue};
    text-align: center;
  }
`;

export const LinkButton = styled.button`
  color: ${colors.unifizeBlue};
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${colors.unifizeBlue};
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ImageViewer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  // Image toolbar z-index should be higher than header
  .openseadragon-canvas + div {
    z-index: 101;
  }
`;

export const EnableNotificationMessage = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 30px;
  padding: 6px;
  z-index: 100;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: ${colors.pureWhite};
  background-color: ${colors.blue};
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    margin-left: 1em;
    > button {
      padding-top: 3px;
      font-weight: bold;
      font-size: 0.813rem;
    }
  }

  button {
    border: none;
    color: ${colors.pureWhite};
    background-color: ${colors.blue};
    float: right;
    cursor: pointer;
  }
`;

export const DotMenu = styled.button`
  line-height: 0;
  font-weight: bold;
  font-size: 2em;
  position: absolute;
  right: 0;
  color: rgba(75, 75, 75, 0.8);
  background-color: transparent;
  border: none !important;
  cursor: pointer;
  width: 20px;
  padding: 0 0.5em 0.5em;
`;

export const AppCrash = styled.div`
  padding: 1em;
  text-align: center;
  code {
    display: block;
    height: 500px;
    width: 500px;
    margin: auto;
    margin-top: 20px;
    overflow: auto;
    text-align: left;
    overflow: auto;
    font-family: monospace;
  }
`;

export const Select = styled.div`
  position: relative;
  width: 100%;

  font-size: 13px !important;
  > div {
    margin: 0px 3px;
  }
  > div:first-of-type {
    width: 100%;
    background: ${colors.pureWhite};
    display: flex;
    padding: 7px 6px;
    border-radius: 4px;
    border: solid 1px rgba(205, 210, 217, 0.5);
    min-width: 95px;
    justify-content: space-between;
  }
  > div:last-child {
    > ul {
      position: absolute;
      z-index: 2;
      width: 100%;
      top: 32px;
      left: 0;
      min-width: 95px;
    }
    > div {
      position: absolute;
      z-index: 2;
      width: 100%;
      top: 32px;
      left: 0;
      min-width: 95px;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      border: solid 1px rgba(205, 210, 217, 0.5);
      > div {
        padding: 8px 7px;
        color: #6f6f6f;
        border-radius: 4px;
      }
      > div:hover {
        color: #fff;
        background: #1581ba;
      }
    }
  }
`;

export const Menu = styled.div`
  .ReactModal__Overlay.ReactModal__Overlay--after-open,
  .ReactModal__Content.ReactModal__Content--after-open {
    position: static !important;
    padding: 0px !important;
    border: 0px !important;
    margin: 0px !important;
  }
`;

export const NewRoom = styled.div`
  height: 16px;
  border-radius: 2px;
  background-color: ${colors.orange};
  font-size: 8px;
  font-weight: bold;
  padding: 5px 7px;
  color: ${colors.pureWhite} !important;
`;

export const TabArea = styled.div`
  padding-left: 1em;
  display: flex;
  margin-top: 17px;
  > div:first-of-type {
    margin-left: 0px !important;
  }
  > a:first-of-type {
    > div {
      margin-left: 0px !important;
    }
  }
`;

export const Tab = styled.div`
  margin: 0px ${spacing.spacing1};

  color: ${props => (props.active ? colors.blue : colors.black)};
  border-bottom: ${props =>
    props.active ? `2px solid ${colors.blue}` : "none"};
  font-weight: bold;
  transition: all 1s;
  font-size: 1.25em;
  padding-bottom: 0.3em;
  :hover {
    transition: all 1s;
    outline: none;
    color: ${colors.blue};
  }
  a {
    color: ${colors.black};
  }
`;

export const ListItem = styled.div`
  position: relative;
  margin: 5px 0px;
  padding: 10px;
  > svg {
    cursor: pointer;
    position: relative;
    top: -2px;
    left: 8px;
  }
`;

export const FileRow = styled.tr`
  > td:first-of-type {
    div {
      display: inline-block;
    }

    > div > label > div:nth-of-type(2) {
      position: relative;
      top: -7px;
    }

    > div > label > div:nth-of-type(3) {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }

    > div > label > span {
      width: 230px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
    }
  }

  > td:nth-of-type(2) > div {
    width: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UserField = styled.div`
  width: 88%;
  margin: auto;
`;

export const Count = styled.span`
  display: inline-block;
  font-size: ${fontSize.t4};
  text-align: center;
  margin-left: 8px;
  border-radius: 50%;
  background: ${colors.paleBlue};
  color: ${colors.mediumBlack};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 2.3em;
  height: 2.3em;
  padding: 0.3em;
`;

export const UpperCase = styled.span`
  text-transform: uppercase;
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const CrashDetails = styled.ul`
  width: 275px;
  margin: auto;
  text-align: left;
  margin-bottom: 20px;
  list-style: none;
  > li {
    display: flex;
    white-space: nowrap;
    align-items: center;
    > span {
      display: inline-block;
      font-size: 16px;
      font-family: monospace !important;
      padding-left: 8px;
    }
  }
`;

export const highlightBorder = css`
  border: 3px dashed ${colors.blue};
`;

export const Current = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ChatArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 20%;
  ${props => props.isDragActive && highlightBorder};
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  position: relative;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  background: rgba(42, 42, 42, 0.3);
`;

export const FullOverlay = styled(Overlay)`
  height: 100%;
`;

export const CloseButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  float: right;
  margin-top: 4px;
  > svg {
    height: 0.8em;
    width: 0.8em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
      stroke-width: 3px;
    }
  }
`;
