// @flow
import { connect } from "react-redux";
import React, { useState, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import ReactProgressiveList from "react-progressive-list";

import { searchUsers } from "src/actions";
import Dropdown from "src/components/Dropdown";
import { getUserSearchResult } from "src/reducers";
import type { AppState, UID, UsersById, Email } from "src/types";
import useBoolean from "src/hooks/useBoolean";
import Selection from "./Selection";

import { Text, Button, Box, VStack } from "@chakra-ui/react";
import RemoveUser from "./RemoveUser";
import { validateEmail } from "src/utils";

import User from "./User";
import * as styles from "./styles";

type Props = {
  userEmails: UsersById,
  users: Array<UID>,
  value: ?UID | ?Email,
  handleChange: Function,
  _searchUsers: Function
};

const SelectInvite = ({ users, value, handleChange, _searchUsers }: Props) => {
  const [query, setQuery] = useState("");

  const {
    value: isOpen,
    setTrue: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  const [debouncedSearchUsers] = useDebouncedCallback(_searchUsers, 400);

  const handleSearch = useCallback(
    input => {
      setQuery(input);
      debouncedSearchUsers(input);
    },
    [debouncedSearchUsers]
  );

  const filteredUsers = users.filter(id => id !== value);

  const handleSelect = useCallback(
    ({ id }) => {
      handleChange(id);
      closeDropdown();
      setQuery("");
      debouncedSearchUsers("");
    },
    [handleChange, closeDropdown, setQuery, debouncedSearchUsers]
  );

  const handleRemove = useCallback(() => {
    handleChange(null);
    debouncedSearchUsers("");
  }, [handleChange, debouncedSearchUsers]);

  const handleInvite = useCallback(
    (email: string) => {
      handleChange({ email }, false);
      closeDropdown();
      setQuery("");
      debouncedSearchUsers("");
    },
    [handleChange, closeDropdown, debouncedSearchUsers, setQuery]
  );

  const handleKeyboardSelect = useCallback(
    selectedItem => {
      if (selectedItem) handleSelect({ id: selectedItem });
      else closeDropdown();
    },
    [handleSelect, users]
  );

  return (
    <Box sx={styles.OwnerDropdownContainer}>
      {value ? (
        <Selection
          value={value}
          toggleDropdown={toggleDropdown}
          isOpen={isOpen}
          closeDropdown={closeDropdown}
        />
      ) : (
        <Box
          sx={styles.InputTogglePropmt}
          onClick={toggleDropdown}
          tabIndex={0}
          onFocus={toggleDropdown}
        >
          e.g. john.doe@xyz.com or John Doe
        </Box>
      )}
      <Dropdown
        onItemClick={handleSelect}
        isOpen={isOpen}
        onOuterClick={closeDropdown}
        query={query}
        onQueryChange={handleSearch}
        handleKeyboardSelect={handleKeyboardSelect}
        closeDropdown={closeDropdown}
      >
        {({ onItemClick, getItemProps, highlightedIndex, scrollIntoView }) => {
          return (
            <>
              {value ? <RemoveUser handleRemove={handleRemove} /> : <></>}
              {validateEmail(query) && users.length === 0 ? (
                <Button
                  sx={styles.EmailInviteButton}
                  colorScheme="blue"
                  variant="outline"
                  onClick={() => handleInvite(query)}
                >
                  Invite {`${query}`} via email
                </Button>
              ) : users.length === 0 ? (
                <Text sx={styles.ErrorMessage}>No results for {query}</Text>
              ) : (
                <VStack spacing="0" sx={styles.DropdownList}>
                  <ReactProgressiveList
                    initialAmount={10}
                    progressiveAmount={10}
                    role="list"
                    rowCount={filteredUsers.length}
                    renderItem={index => {
                      if (filteredUsers[index]) {
                        return (
                          <User
                            item={filteredUsers[index]}
                            key={`user-${filteredUsers[index]}`}
                            elId={index}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: filteredUsers[index],
                              index,
                              id: filteredUsers[index],
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      }
                    }}
                  />
                </VStack>
              )}
            </>
          );
        }}
      </Dropdown>
    </Box>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  users: getUserSearchResult(app)
});

export default connect(mapStateToProps, {
  _searchUsers: searchUsers
})(SelectInvite);
