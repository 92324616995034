// @flow

import React from "react";

type Props = {
  handleClick?: Function,
  fill?: boolean,
  stroke?: string
};

const Favourite = ({ handleClick = () => {}, fill = false, stroke }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      onClick={handleClick}
    >
      <path
        fillRule="evenodd"
        fill={fill ? "#f1a208" : ""}
        stroke={fill ? "#f1a208" : stroke ? stroke : "#2A2A2A"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 .5l2.318 4.938 5.182.791-3.75 3.843.885 5.428L8 12.938 3.365 15.5l.885-5.428L.5 6.23l5.182-.791L8 .5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Favourite;
