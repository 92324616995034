// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";

import { ScrollableBox, ListItemMedium } from "src/styles/box";

const textColor = {
  "#F6F0FD": "#50248F",
  "#F4F5FA": "#202E78",
  "#EBF5FA": "#084E8A",
  "#E0F5F5": "#00848E",
  "#E3F1DF": "#108043",
  "#FCF1CD": "#8A6116"
};

export const SelectionSingle = styled.div`
  border: 1px solid ${props => (props.color ? props.color : colors.greenLight)};
  padding: 8px;
  margin-bottom: 1em;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  position: relative;
  margin-bottom: 0.8em;

  > h4 {
    margin: 0;
    color: ${props =>
      props.color ? textColor[props.color.toUpperCase()] : colors.greenDark};
    background-color: ${props => (props.color ? props.color : colors.grey26)};
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  > svg {
    height: 1.6em;
    width: 1.6em;
  }
`;

export const Address = styled.span`
  color: ${colors.secondary};
  font-size: 10px;
  padding-left: 4px;
  position: relative;
  top: -1px;
`;

export const Preview = styled.span`
  font-size: 12px;
  color: ${colors.inkLight};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0px;
  ${ListItemMedium} {
    padding: 0 8px;
    color: ${colors.primary};
  }
`;

export const AddButton = styled.div`
  > button {
    width: 100%;
    margin-bottom: ${spacing.space_m};
    border-radius: ${radius.small};
    border: ${props =>
      props.isMandatory ? `1px solid ${colors.redDark}` : "none"};
    background: ${props => (props.isLoading ? colors.active : colors.grey20)};
    color: ${props =>
      props.isLoading ? colors.brightLight : colors.secondary};
    transition: 0.1s ease-in-out all;
    font-size: 14px;
    font-weight: ${typography.semiBold};
    cursor: ${props => (props.isLoading ? "not-allowed" : "pointer")};
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      background: ${colors.active};
      color: ${colors.brightLight};
    }
    :disabled {
      cursor: not-allowed;
      background: ${colors.grey28};
      color: ${colors.skyDark};
    }
  }
`;

export const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  text-align: start;

  li {
    line-height: initial;
  }
`;
