// @flow

import { useSelector } from "react-redux";
import React from "react";

import Title from "./Title";
import Users from "./Users";
import Creator from "./Creator";
import { Row as StyledRow } from "src/styles/table";
import { getGroup } from "src/reducers";

type Props = {
  id: number
};

const Row = ({ id }: Props) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const { members, owners, creator } = group;

  return (
    <StyledRow>
      <Title {...group} />
      <Users users={members} />
      <Users users={owners} />
      <Creator user={creator} />
    </StyledRow>
  );
};

export default Row;
