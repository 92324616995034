// @flow

import styled from "@emotion/styled";

import * as colors from "./constants/colors";
import * as spacing from "./constants/spacing";
import * as radius from "./constants/radius";
import * as shadow from "./constants/shadow";

const Button = styled.button`
  padding: ${spacing.space_inset_squish_m};
  background: ${colors.active};
  color: ${colors.brightLight};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  cursor: pointer;
  :hover,
  :focus {
    border: 1px solid ${colors.outline};
    box-shadow: ${shadow.defaultShadow};
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.secondary};
    background: ${colors.grey1};
    border: 1px solid ${colors.grey4};
  }
`;

const Secondary = styled.button`
  padding: ${spacing.space_inset_squish_m};
  background: ${colors.brightLight};
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
  border-radius: ${radius.small};
  :focus {
    outline: 1px solid ${colors.grey2};
  }
  :hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.secondary};
    background: ${colors.grey1};
    border: 1px solid ${colors.grey4};
  }
`;

const Ternary = styled.button`
  color: ${colors.grey5};
  outline: 1px solid ${colors.grey5};
  background: transparent;
  font-size: 0.625rem;
  padding: 0.3rem 0.25rem;
`;

const ButtonLink = styled.button`
  background: transparent;
  padding: 0px;
  color: ${colors.active};
  :focus {
    outline: none;
    text-decoration: underline;
    text-decoration-color: ${colors.active};
    text-underline-position: under;
  }
`;

const ButtonOutline = styled(Button)`
  background: ${colors.brightLight};
  color: ${colors.blue};
  transition: all 0.8 ease;
  text-transform: uppercase;
  :hover,
  :focus {
    outline: none !important;
    box-shadow: none;
    background: ${colors.blue};
    transition: all 0.3s ease;
    color: ${colors.brightLight};
  }
`;

export const CopyButton = styled.button`
  border: none;
  padding: 0;
  outline: none;
  background: ${colors.brightLight};
`;

const ButtonSolidToOutline = styled(Button)`
  outline: none !important;
  box-shadow: none;
  background: ${colors.active};
  transition: all 0.3s ease;
  color: ${colors.brightLight};
  text-transform: uppercase;
  :hover,
  :focus {
    background: ${colors.brightLight};
    color: ${colors.active};
    transition: all 0.8 ease;
  }
`;

export {
  Button,
  Secondary,
  ButtonLink,
  ButtonOutline,
  Ternary,
  ButtonSolidToOutline
};
