// @flow

export const CONVERSATION_START = "CONVERSATION_START";
export const CONVERSATION_COMPLETE = "CONVERSATION_COMPLETE";
export const CONVERSATION_REOPEN = "CONVERSATION_REOPEN";
export const CONVERSATION_CANCELED = "CONVERSATION_CANCELED";

export const OWNER_UPDATE = "OWNER_UPDATE";

export const MEMBER_ADD = "MEMBER_ADD";
export const MEMBER_REMOVE = "MEMBER_REMOVE";

export const GROUP_ADD = "GROUP_ADD";
export const GROUP_REMOVE = "GROUP_REMOVE";

export const DUEDATE_UPDATE = "DUEDATE_UPDATE";

export const CHECKLIST_FIELD_FILL = "CHECKLIST_FIELD_FILL";

export const FOSTER_PARENT_ADD = "FOSTER_PARENT_ADD";
export const FOSTER_PARENT_REMOVE = "FOSTER_PARENT_REMOVE";
export const FOSTER_CHILD_ADD = "FOSTER_CHILD_ADD";
export const FOSTER_CHILD_REMOVE = "FOSTER_CHILD_REMOVE";

export const PARENT_ADD = "PARENT_ADD";
export const PARENT_REMOVE = "PARENT_REMOVE";
export const PARENT_UPDATE = "PARENT_UPDATE";

export const USER_INVITE = "USER_INVITE";
export const USER_JOIN = "USER_JOIN";

export const FILE_ADD = "FILE_ADD";
export const FILE_REMOVE = "FILE_REMOVE";

export const TITLE_UPDATE = "TITLE_UPDATE";

export const OBJECTIVE_UPDATE = "OBJECTIVE_UPDATE";

export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_RECEIVED = "EMAIL_RECEIVED";
export const PRIORITY_UPDATE = "PRIORITY_UPDATE";
export const PRIVACY_UPDATE = "PRIVACY_UPDATE";

export const STATUS_UPDATE = "STATUS_UPDATE";

export const REMINDER_STATUS_CHANGED = "REMINDER_STATUS_CHANGED";

export const REMINDER = "REMINDER";

export const FORM_ADD = "FORM_ADD";
export const FORM_REMOVE = "FORM_REMOVE";

export const REVISION_CREATE = "REVISION_CREATE";
export const CURRENT_VERSION_UPDATE = "CURRENT_VERSION_UPDATE";

export const EXTERNAL_APP_CONVERSATION_START =
  "EXTERNAL_APP_CONVERSATION_START";
export const EXTERNAL_APP_MESSAGE_FORWARD = "EXTERNAL_APP_MESSAGE_FORWARD";

export const ARCHIVE_STATUS = "ARCHIVE_STATUS";
