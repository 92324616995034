// @flow

import React from "react";

const Share = () => (
  <svg
    viewBox="-4 -4 24 24"
    preserveAspectRatio="xMinYMin slice"
    height="20px"
    width="20px"
    stroke="#888888"
    fill="none"
  >
    <g>
      <path
        d="M8.99501 7.00513C10.369 8.37913 10.369 10.6061 8.99501 11.9801L7.00501 13.9701C5.63101 15.3441 3.40401 15.3441 2.03001 13.9701C0.656012 12.5961 0.656012 10.3691 2.03001 8.99513L3.77801 7.29713"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00511 8.99501C5.63111 7.62101 5.63111 5.39401 7.00511 4.02001L8.99511 2.03001C10.3691 0.656012 12.5961 0.656012 13.9701 2.03001C15.3441 3.40401 15.3441 5.63101 13.9701 7.00501L12.2221 8.70301"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Share;
