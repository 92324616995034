// @flow

import React, { memo } from "react";
import { connect } from "react-redux";

import { OwnerWrapper, Owner } from "./styles";

import Round from "src/containers/user/ImageLabel/Round";
import Tooltip from "src/components/Tooltip";
import Icons from "src/icons";
import { getChatroomOwner } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  value: any,
  active: boolean
};

const PillContainer = ({ value, active }: Props) => {
  if (value) {
    return (
      <OwnerWrapper active={active}>
        <Tooltip title="Owner">
          <Owner>
            <Round uid={value} noTooltip />
          </Owner>
        </Tooltip>
      </OwnerWrapper>
    );
  }

  return (
    <OwnerWrapper>
      <Icons type="noOwner" />
      <div>No Owner</div>
    </OwnerWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }) => ({
  value: getChatroomOwner(app, roomId)
});

export default connect(mapStateToProps)(memo(PillContainer));
