// @flow

import React from "react";
import moment from "moment";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const LastLoggedIn = ({ user }: Props) => (
  <span>
    {user.lastLogin && user.lastLogin.toDate
      ? moment(user.lastLogin.toDate()).format("MMM DD, YYYY hh:mm a")
      : null}
  </span>
);

export default LastLoggedIn;
