// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const OpaqueOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 54px;
  right: 0px;
  bottom: 0px;
  padding-left: 8px;
  padding-right: 16px;
  padding-top: 8px;
  border: none;
  background-color: ${colors.brightLight};
  max-height: 100vh;
  overflow: auto;
  font-size: 12px;
`;
