// @flow

const spacing0 = "8px";
const spacing1 = "12px";
const spacing2 = "16px";
const spacing3 = "24px";
const spacing4 = "32px";

const menuWidth = "250px";
const headerHeight = "53px";
const sideMenuSmall = "70px";
const dockPanel = "337px";

export {
  spacing0,
  spacing1,
  spacing2,
  spacing3,
  spacing4,
  menuWidth,
  headerHeight,
  sideMenuSmall,
  dockPanel
};
