// @flow

import React from "react";

const SaveAs = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M20.5 2.5a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-16a2 2 0 0 1 2-2"
    />
    <path
      stroke="#1581BA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M16.5 12.5l-4-3-4 3v-10h8v10z"
    />
  </svg>
);

export default SaveAs;
