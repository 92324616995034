// @flow

import React, { useState } from "react";
import { connect } from "react-redux";
import DomainContainer from "./DomainContainer";
import AddDomain from "./AddDomain";
import Tooltip from "src/components/Tooltip";
import { FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { getCurrentOrg } from "src/reducers";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import type { AppState } from "src/types";

type Props = {
  currentOrgDetails: Object
};

const OrgDomains = ({ currentOrgDetails }: Props) => {
  const [isAddDomainVisible, setIsAddDomainVisible] = useState(false);
  const { allDomains = [] } = currentOrgDetails;
  return (
    <FormControl as="div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <FormLabel color={colors.inkLighter} textTransform="uppercase" mr={1}>
          <p>Org Domain</p>
        </FormLabel>
        <Tooltip
          placement="top"
          title="Please contact support to add or remove domains"
        >
          <Icon type="info" size={16} viewBoxSize={22} />
        </Tooltip>
      </div>
      <Stack direction="column" spacing={2} align="flex-start">
        {allDomains.map(domain => {
          return <DomainContainer key={domain} domain={domain} />;
        })}
        {isAddDomainVisible ? (
          <AddDomain setIsAddDomainVisible={setIsAddDomainVisible} />
        ) : null}
      </Stack>
    </FormControl>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentOrgDetails: getCurrentOrg(app)
});

export default connect(mapStateToProps)(OrgDomains);
