// @flow

import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody
} from "@chakra-ui/react";
import * as styles from "./styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  message: string,
  isShown: boolean,
  onClick: Function,
  children: any,
  position?: "bottom" | "top",
  color?: string
};

const InteractiveToolTip = ({
  message,
  isShown,
  children,
  onClick,
  position = "bottom",
  color = colors.red500
}: Props) => {
  return (
    <Popover trigger={isShown ? "hover" : null} placement={position}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent color="white" background={color} sx={styles.tooltip}>
        <PopoverBody onClick={onClick}>{message}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default InteractiveToolTip;
