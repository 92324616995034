// @flow

import styled from "@emotion/styled";

import StyledModal from "src/styles/modal";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { ButtonOutline as HollowButton } from "src/styles/buttons";

const Modal = styled(StyledModal)`
  z-index: 999;
  position: absolute;
  top: 100px !important;
  left: 20% !important;
  background-color: ${colors.brightLight};
  width: 60em;
`;

const UserInfoModal = styled(Modal)`
  z-index: 998;
`;

const Info = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-column-gap: 25px;
`;

const Label = styled.label`
  font-weight: normal;
  color: ${colors.secondary};
  display: block;
  margin-top: 5px;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2.6em 0.5em 2.6em;
  width: 100%;
`;

const Tab = styled.div`
  cursor: ${props => (props.active ? "default" : "pointer")};
  color: ${colors.active};
  font-weight: ${props => (props.active ? "bold" : "normal")};
  width: 33.33%;
  text-align: center;
  border-bottom: ${props =>
    props.active
      ? `2px solid ${colors.active}`
      : `2px solid ${colors.brightLight}`};
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 2.6em 0.5em 2.6em;
  tbody {
    max-height: 16em;
    display: block;
    overflow: auto;
    width: 92.6%;
    margin-bottom: 1em;
  }
`;

const Groups = styled(Table)`
  tbody {
    td {
      padding-left: 1em;
      width: 18.5em;
    }
  }
`;

const Processes = styled(Table)``;

const Header = styled.thead`
  display: block;
  > tr > th {
    background-color: ${colors.highlight};
    text-align: left;
    padding-left: 1em;
    padding-top: 0.4em;
  }
`;

const GroupHeader = styled(Header)`
  > tr > th {
    width: 18.5em;
  }
`;

const ProcessHeader = styled(Header)`
  > tr > th {
    :first-of-type {
      width: 14em;
    }
    :nth-of-type(2) {
      width: 10.5em;
    }
    :nth-of-type(3) {
      width: 11em;
    }
    :nth-of-type(4),
    :last-child {
      width: 10em;
    }
  }
`;

const Name = styled.div`
  > span {
    display: flex;
    align-items: center;
    > img {
      height: 1.25em;
      width: 1.25em;
      margin-right: 0.5em;
    }
  }
`;

const OnBoarding = styled.canvas`
  width: 97%;
  display: block;
  margin: 0px 1em;
  height: 14em;
`;

const ChatroomTitle = styled.td`
  padding-left: 0.9em;
  width: 14em;
  > div {
    > a {
      display: block;
      max-width: 13em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 0.8em;
    }
  }
`;

const Members = styled.td`
  padding-left: 0.9em;
  width: 10.5em;
`;

const User = styled.td`
  padding-left: 0.4em;
  width: 11em;
  > span {
    background: ${colors.brightLight} !important;
    > span {
      max-width: 6.6em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const Time = styled.td`
  font-size: 10px;
  padding-left: 1.1em;
  white-space: nowrap;
`;

const Button = styled(HollowButton)`
  display: block;
  margin: auto;
  margin-bottom: 1.4em;
  width: 13em;
  border-radius: ${radius.medium};
`;

const ButtonOutline = styled(HollowButton)`
  display: flex;
  align-items: center;
  color: ${props => (props.colors ? props.colors : colors.active)};
  transition: all 0.8 ease;
  font-weight: bold;
  border: 1px solid ${props => (props.colors ? props.colors : colors.active)};
  margin-top: 8px;
  width: 100%;
  :hover,
  :focus {
    background: ${props => (props.colors ? props.colors : colors.active)};
    > span > span {
      color: ${colors.brightLight};
    }
  }
  > span > span {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
    color: ${props => (props.colors ? props.colors : colors.active)};
    position: relative;
    top: 1px;
  }
`;

const Department = styled.span``;

const ButtonGroup = styled.div`
  position: absolute;
  top: ${props => (props.showChangeRoleButton ? "21%" : "27%")};
  right: 30px;
`;

export {
  ButtonGroup,
  ButtonOutline,
  Department,
  Button,
  Time,
  User,
  ChatroomTitle,
  Members,
  Modal,
  Info,
  Label,
  Tabs,
  Tab,
  Groups,
  GroupHeader,
  Name,
  Processes,
  OnBoarding,
  ProcessHeader,
  UserInfoModal
};
