// @flow

import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import Message from "./Message";
import SelectIndicator from "./SelectIndicator";
import { ChatMessageContainer, ChatMessage, FlexSpacer } from "./styles";
import MoreMenu from "src/components/messages/MoreMenu";
import * as actions from "src/actions";
import {
  selectMessage,
  deselectMessage,
  hideSelectOptions,
  showMobileOptions
} from "src/actions/chatmenu";
import {
  getSelectedAction,
  getCurrentChatroomMembership,
  getCurrentUser,
  getSelectedMessages,
  getIsSrwMobile
} from "src/reducers";

import type { AppState, MessageID, UnifizeUser } from "src/types";

type Props = {
  message: Object,
  type: String,
  isMember: boolean,
  currentUser: UnifizeUser,
  selectedAction: string,
  isSrwMobile: boolean,
  _selectMessage: Function,
  _deselectMessage: Function,
  selectedMessages: Array<MessageID>,
  _hideSelectOptions: Function,
  _showMobileOptions: Function
};

const Chat = ({
  message,
  type,
  isMember,
  currentUser,
  selectedAction,
  isSrwMobile,
  _selectMessage,
  _deselectMessage,
  selectedMessages,
  _hideSelectOptions,
  _showMobileOptions
}: Props) => {
  const [menu, setMenu] = useState(false);
  const { id, timestamp, author } = message;

  // Render action menu when user is deleting or forwarding message(s)
  const showMenuActions = useCallback(() => {
    const currentActionFilter =
      selectedAction.length == 0 ||
      (selectedAction !== "DELETE" && selectedAction !== "FORWARD");

    if (currentActionFilter) setMenu(true);
    else setMenu(false);
  }, [menu, selectedAction]);

  const isMessageSelected = selectedMessages.includes(id);
  const isOwner = currentUser.uid === author;

  const toggleMenu = useCallback(() => {
    if (selectedAction) {
      if (selectedAction === "MOBILE") {
        if (!isMessageSelected) {
          _hideSelectOptions();
          _showMobileOptions();
          _selectMessage(id, timestamp, isOwner);
        } else _deselectMessage(id);
      } else if (!isMessageSelected) _selectMessage(id, timestamp, isOwner);
      else _deselectMessage(id);
    }
  }, [selectedAction, id, timestamp, isOwner, isMessageSelected]);

  const handleMouseLeave = () => {
    setMenu(false);
  };

  return (
    <ChatMessageContainer
      onMouseEnter={showMenuActions}
      onMouseLeave={handleMouseLeave}
      isSrwMobile={isSrwMobile}
      onClick={toggleMenu}
    >
      <ChatMessage id={id} showSelect={selectedAction}>
        <Message message={message} type={type} />
        <FlexSpacer />
        {menu && isMember && !isSrwMobile ? (
          <MoreMenu id={id} timestamp={timestamp} isOwner={isOwner} />
        ) : null}
        <SelectIndicator id={id} timestamp={timestamp} isOwner={isOwner} />
      </ChatMessage>
    </ChatMessageContainer>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUser(app),
  isMember: getCurrentChatroomMembership(app),
  selectedAction: getSelectedAction(app),
  selectedMessages: getSelectedMessages(app),
  isSrwMobile: getIsSrwMobile(app)
});

export default connect(mapStateToProps, {
  ...actions,
  _selectMessage: selectMessage,
  _deselectMessage: deselectMessage,
  _hideSelectOptions: hideSelectOptions,
  _showMobileOptions: showMobileOptions
})(Chat);
