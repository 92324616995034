// @flow

import React, { memo, useMemo } from "react";
import { connect } from "react-redux";
import { getDueDate as getDate } from "src/utils";

import ChatBlockComponent from "src/components/chatroom-nav/ChatBlock";
import {
  getDueDate,
  getChatroomType,
  getChatroomAddress,
  getMessageCount,
  getReadMessageCount,
  getCanceled,
  getChatroomStatus,
  getActiveState,
  getIsCurrentRoom,
  getTemplateId,
  getSequenceNo,
  getActiveStatus,
  getWorkflowColor,
  isStatusDisabled,
  getToogleUnread,
  isCurrentVersion
} from "src/reducers";
import { getStatus } from "src/utils";
import type {
  AppState,
  RoomId,
  UID,
  ChatroomAddress,
  ConversationType
} from "src/types";

type Props = {
  type: ConversationType,
  color: string,
  dueDate: ?string,
  orgId: number,
  active: boolean,
  status: number,
  totalCount: number,
  readCount: ?number,
  canceled: ?boolean,
  membership: boolean,
  owner: UID,
  isCurrentChatroom: boolean,
  currentChatRoom: RoomId,
  roomId: RoomId,
  address: ChatroomAddress,
  seqNo: ?number,
  templateId: ?number,
  activeStatus: boolean,
  disabled: boolean,
  unread: boolean,
  isCurrentVersion: boolean
};

const ChatBlock = ({
  type,
  color,
  dueDate,
  active,
  status,
  currentChatRoom,
  roomId,
  owner,
  totalCount,
  isCurrentChatroom,
  readCount,
  canceled,
  disabled,
  isCurrentVersion,
  ...rest
}: Props) => {
  const dueDateValue: string | Object = getDate(dueDate);
  const statusValue = useMemo(
    () =>
      getStatus({
        status,
        dueDateValue,
        active
      }),
    [status, active, dueDateValue]
  );

  const unreadCount = useMemo(
    () => (readCount ? totalCount - readCount : totalCount),
    [readCount, totalCount]
  );
  return (
    <>
      <ChatBlockComponent
        status={statusValue}
        color={color}
        dueDate={dueDateValue}
        active={isCurrentChatroom}
        roomId={roomId}
        hasUnread={unreadCount > 0}
        type={type}
        disabled={disabled}
        isCurrentVersion={isCurrentVersion}
        {...rest}
      />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const templateId = getTemplateId(app, `${props.roomId}`);
  const id = getChatroomStatus(app, props.roomId);
  return {
    templateId,
    isCurrentChatroom: getIsCurrentRoom(app, props.roomId),
    dueDate: getDueDate(app, props.roomId),
    canceled: getCanceled(app, props.roomId),
    type: getChatroomType(app, props.roomId),
    status: getChatroomStatus(app, props.roomId),
    active: getActiveState(app, props.roomId),
    address: getChatroomAddress(app, props.roomId),
    totalCount: getMessageCount(app, props.roomId),
    readCount: getReadMessageCount(app, props.roomId),
    seqNo: getSequenceNo(app, props.roomId),
    isCurrentVersion: isCurrentVersion(app, props.roomId),
    activeStatus: getActiveStatus(app, templateId, id),
    color: getWorkflowColor(app, templateId),
    disabled: isStatusDisabled(app, props.roomId),
    unread: getToogleUnread(app)
  };
};

export default connect(mapStateToProps)(memo<Props>(ChatBlock));
