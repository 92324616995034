// @flow

import React, { useState } from "react";

import { getFileType } from "src/utils/file";
import { FileIcon, ImageThumbnail } from "src/styles";
import { Display as StyledDisplay } from "src/styles/file";
import type { UnifizeFile } from "src/types";

type Props = {
  thumbnail: boolean,
  file: UnifizeFile,
  tempFile: {
    name: string,
    originalName: string,
    generation: string
  }
};

const Display = ({ thumbnail, file, tempFile }: Props) => {
  const [error, setError] = useState(false);

  const renderThumbnail = () => {
    if (file.thumbnail && thumbnail && !error) {
      return (
        <ImageThumbnail src={file.thumbnail} onError={() => setError(true)} />
      );
    }
    return (
      <FileIcon
        icon={getFileType(file.originalName)}
        className="icon"
        cursor="pointer"
      />
    );
  };
  if (file) {
    return (
      <StyledDisplay>
        {renderThumbnail()}
        <span title={file.originalName}>{file.originalName}</span>
      </StyledDisplay>
    );
  }

  if (tempFile) {
    const { originalName } = tempFile;
    return (
      <StyledDisplay>
        <FileIcon icon={getFileType(originalName)} className="icon" />
        <span title={originalName}>{originalName}</span>
      </StyledDisplay>
    );
  }

  return null;
};

export default Display;
