// @flow

import React from "react";

import type { ChecklistField } from "src/types";

type Props = {
  id: number,
  field: ChecklistField,
  as: string
};

const FieldName = ({ field, as, id }: Props) => {
  if (as === "option")
    return <option value={id}>{field ? field.get("label") : ""}</option>;
  return <span>{field ? field.get("label") : ""}</span>;
};

export default FieldName;
