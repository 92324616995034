// @flow

import React from "react";

const NoOwnerActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1744_13110)">
      <path
        d="M0 1.77778V14.2222C0 15.2 0.791111 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0H1.77778C0.791111 0 0 0.8 0 1.77778ZM10.6667 5.33333C10.6667 6.80889 9.47556 8 8 8C6.52444 8 5.33333 6.80889 5.33333 5.33333C5.33333 3.85778 6.52444 2.66667 8 2.66667C9.47556 2.66667 10.6667 3.85778 10.6667 5.33333ZM2.66667 12.4444C2.66667 10.6667 6.22222 9.68889 8 9.68889C9.77778 9.68889 13.3333 10.6667 13.3333 12.4444V13.3333H2.66667V12.4444Z"
        fill="#A09E9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1744_13110">
        <rect width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NoOwnerActive;
