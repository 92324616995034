// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { Checkbox, MultiSelect } from "src/components/Unifize";
import {
  getAllActiveUsers,
  getAllGroups,
  getCurrentChecklistBuilderFields,
  getAllRolesById
} from "src/reducers";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

const WhoCanApprove = ({ settings, saveSettings }: Props) => {
  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );
  const userFields = checklistFields.filter(field => field.type === "user");
  const users = useSelector(({ app }) => getAllActiveUsers(app));
  const groups = useSelector(({ app }) => getAllGroups(app));
  const rolesById = useSelector(({ app }) => getAllRolesById(app)).roles;
  const roles = Object.values(rolesById);
  return (
    <Accordion title="Who can approve">
      <AccordionContent sx={{ gap: 2 }}>
        <Checkbox
          checked={settings?.approvers?.allParticipants}
          label="All participants"
          onChange={value =>
            saveSettings(["approvers", "allParticipants"], value)
          }
        />
        <Checkbox
          checked={settings?.approvers?.owner}
          label="Owner"
          onChange={value => saveSettings(["approvers", "owner"], value)}
        />
        <Checkbox
          checked={settings?.approvers?.preventRequester}
          label="Prevent requester from approving"
          onChange={value =>
            saveSettings(["approvers", "preventRequester"], value)
          }
        />
        <MultiSelect
          label="Specific users"
          placeholder="Search users"
          data={users}
          selected={settings?.approvers?.users || []}
          showIcon
          keys={["id", "name", "email", "photoUrl"]}
          onChange={value => saveSettings(["approvers", "users"], value)}
        />
        <MultiSelect
          label="Roles"
          placeholder="Search roles"
          data={roles}
          selected={settings?.approvers?.roles || []}
          showIcon
          keys={["id", "title", "photoUrl"]}
          onChange={value => saveSettings(["approvers", "roles"], value)}
        />
        <MultiSelect
          label="Groups"
          placeholder="Search groups"
          data={groups}
          selected={settings?.approvers?.groups || []}
          showIcon
          keys={["id", "name", "photoUrl"]}
          onChange={value => saveSettings(["approvers", "groups"], value)}
        />
        <MultiSelect
          label="Users from these field(s)"
          placeholder="Search field"
          data={userFields}
          selected={settings?.approvers?.fields || []}
          keys={["id", "label"]}
          onChange={value => saveSettings(["approvers", "fields"], value)}
        />
      </AccordionContent>
    </Accordion>
  );
};

export default WhoCanApprove;
