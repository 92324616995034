// @flow

import React from "react";

const OwnerV2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    {" "}
    <rect
      x="0.714355"
      y="0.714355"
      width="20.5714"
      height="20.5714"
      rx="10.2857"
      fill="#919EAB"
    />{" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.67031 6.98414C6.67031 4.78343 8.48021 3 10.7136 3C12.947 3 14.7582 4.78343 14.7582 6.98414C14.7582 9.18618 12.947 10.9696 10.7136 10.9696C8.48021 10.9696 6.67031 9.18618 6.67031 6.98414ZM10.7431 19.2859C13.4133 19.2859 15.8297 18.2325 17.5715 16.5379V15.3357C17.5715 13.8821 16.344 12.7023 14.8286 12.7023H6.60003C5.08461 12.7023 3.85718 13.8821 3.85718 15.3357V16.4773C5.60301 18.2062 8.04278 19.2859 10.7431 19.2859Z"
      fill="white"
    />{" "}
  </svg>
);

export default OwnerV2;
