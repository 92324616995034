// @flow

import { connect } from "react-redux";

import ProfileModal from "src/components/filter/Profile/ProfileModal";
import { editDisplayName } from "src/actions/user";
import { getSignUpError } from "src/reducers";
import { toggleProfileModal } from "src/actions/modal";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  updateNameError: getSignUpError("displayName")(app),
  ...props
});

export default connect(mapStateToProps, {
  editDisplayName,
  toggleProfileModal
})(ProfileModal);
