// @flow

import React, { useEffect, memo } from "react";
import {
  Input,
  Flex,
  InputGroup,
  IconButton,
  Checkbox,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { SortableHandle } from "react-sortable-hoc";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import * as colors from "src/styles/constants/colors";
import ComparisonType from "./ComparisonType";
import useBoolean from "src/hooks/useBoolean";
import Tooltip from "src/components/Tooltip";
import ColorDropdown from "./ColorDropdown";
import SummaryType from "./SummaryType";
import DeleteModal from "./DeleteModal";
import * as styles from "../styles";
import GroupBy from "./GroupBy";
import Icon from "src/icons";
import {
  getComputationRootChecklistFieldId,
  getChartFieldId,
  setChartSeries,
  handleTrendline,
  setTrendline,
  handleSeriesDelete
} from "src/utils/charts.js";

const DragHandle = SortableHandle(() => (
  <IconButton
    sx={styles.IconButton}
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));
type Props = {
  id: string,
  position: number,
  chartDetails: Object,
  setChartDetails: Function
};

const Column = ({ id, position, chartDetails, setChartDetails }: Props) => {
  const { value: isCollapsed, toggleBoolean } = useBoolean(true);

  const currentSeries = chartDetails.series.find(item => {
    if (item.seriesId) {
      return item.seriesId === id;
    } else {
      return item.tempId === id;
    }
  });
  const { title, hide, operation, computationField, y } = currentSeries;

  // $FlowFixMe - Flow does not yet support method or property calls in optional chains
  const currentTrendline = chartDetails.trendlines?.find(
    item => item.seriesId === id
  );
  const isTrendline = Boolean(currentTrendline);

  const linkedOrFormFieldId =
    computationField && getComputationRootChecklistFieldId(computationField);
  const computationFieldId =
    computationField && getChartFieldId(computationField);
  const groupByFieldId = y && getChartFieldId(y);

  useEffect(() => {
    setChartSeries({
      setChartDetails,
      seriesId: id,
      key: "seqNo",
      value: position
    });
  }, [position]);

  return (
    <Flex sx={{ gap: "1rem", justifyContent: "center" }}>
      <DragHandle />

      <Flex sx={styles.SeriesContainer}>
        <InputGroup sx={styles.InputGroup}>
          <Tooltip
            sx={{ backgroundColor: "none" }}
            title="Column"
            placement="top"
          >
            <Icon
              type="column"
              fill={colors.purple}
              width="24px"
              height="24px"
            />
          </Tooltip>

          <Input
            onChange={e =>
              setChartSeries({
                setChartDetails,
                seriesId: id,
                key: "title",
                value: e.target.value
              })
            }
            value={title}
            sx={styles.ValueInput}
            variant="unstyled"
            placeholder="Untitled Series"
          />

          <IconButton
            onClick={() =>
              setChartSeries({
                setChartDetails,
                seriesId: id,
                key: "hide",
                value: !hide
              })
            }
            sx={{ bg: "none" }}
            icon={
              <Icon
                type={hide ? "hidden" : "eye"}
                width="24px"
                height="24px"
                fill={colors.inkLightest}
              />
            }
          />

          <IconButton
            onClick={toggleBoolean}
            sx={styles.SeriesExpandButton}
            icon={
              isCollapsed ? (
                <ChevronUpIcon w={6} h={6} />
              ) : (
                <ChevronDownIcon w={6} h={6} />
              )
            }
            variant="unstyled"
          />
        </InputGroup>

        {isCollapsed && (
          <>
            <SummaryType
              seriesId={id}
              operation={operation}
              setChartDetails={setChartDetails}
            />

            {operation && (
              <ComparisonType
                seriesId={id}
                chartDetails={chartDetails}
                operation={operation}
                setChartDetails={setChartDetails}
                computationFieldId={computationFieldId}
              />
            )}

            {computationField && (
              <GroupBy
                linkedOrFormFieldId={linkedOrFormFieldId}
                chartDetails={chartDetails}
                setChartDetails={setChartDetails}
                seriesId={id}
                groupByFieldId={groupByFieldId}
              />
            )}

            {computationField ? (
              <Checkbox
                onChange={e => handleTrendline(setChartDetails, e, id)}
                isChecked={isTrendline}
              >
                Add Trendline
              </Checkbox>
            ) : (
              <Tooltip
                sx={{ backgroundColor: "none" }}
                title="Select above fields to enable this"
                placement="top-start"
                hasArrow={false}
              >
                <Checkbox isDisabled>Add Trendline</Checkbox>
              </Tooltip>
            )}

            {isTrendline && (
              <Flex sx={{ gap: "1rem" }}>
                <FormControl sx={styles.FormLabel}>
                  <FormLabel sx={styles.SeriesLabel}>Trendline label</FormLabel>
                  <Input
                    onChange={e =>
                      setTrendline(setChartDetails, id, "label", e.target.value)
                    }
                    value={currentTrendline?.label}
                    sx={styles.BuilderInput}
                    placeholder="Enter trendline label"
                  />
                </FormControl>

                <FormControl sx={styles.TrendlineColorWrapper}>
                  <FormLabel sx={styles.SeriesLabel}>Trendline Color</FormLabel>
                  <ColorDropdown
                    setChartDetails={setChartDetails}
                    seriesId={id}
                    colorHexCode={currentTrendline?.color}
                  />
                </FormControl>
              </Flex>
            )}
          </>
        )}
      </Flex>

      <DeleteModal
        series
        title={title}
        handleDelete={() => handleSeriesDelete(setChartDetails, id)}
      />
    </Flex>
  );
};

export default memo<Props>(Column);
