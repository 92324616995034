// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const MentionListContainer: SystemCSSProperties = {
  background: " #fff",
  borderRadius: "0.5rem",
  boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1)",
  color: "rgba(0, 0, 0, 0.8)",
  fontSize: "12px",
  position: "relative",
  width: "100%",
  maxHeight: "250px",
  overflowX: "scroll",
  alignItems: "center"
};

export const UserMentionSubtext: SystemCSSProperties = {
  color: colors.grey5,
  fontSize: "10px",
  margin: "0px",
  padding: "0px"
};

export const MentionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  flex-grow: 1;
  width: 100%;
`;

export const TextInputContainer = styled.div`
  width: 100%;
  overflow-wrap: break-word;
  flex-grow: 1;
  font-size: small;
  display: flex;
  width: 100%;
  overflow: hidden;
  & > div {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

export const MentionListWrapper: SystemCSSProperties = {
  flexDirection: "column"
};

export const MentionWrapper: SystemCSSProperties = {
  gap: "0.416rem",
  alignItems: "center"
};

export const AvatarContainer: SystemCSSProperties = {
  width: "30px",
  height: "30px",
  justifyContent: "center",
  alignItems: "center"
};

export const AvatarWrapper: SystemCSSProperties = {
  height: "27.34px",
  width: "27.34px",
  borderRadius: "6px"
};

export const UserDetailsContainer: SystemCSSProperties = {
  justifyContent: "space-between",
  alignItems: "center",
  w: "100%"
};

export const UserDetailsWrapper: SystemCSSProperties = {
  flexDirection: "column",
  justifyContent: "center"
};

export const MentionButton = styled.button`
  border: 1px solid transparent;
  display: block;
  background-color: ${props => (props.isSelected ? "#ebf8ff" : "white")};
  margin: 0;
  padding-top: ${props =>
    props.type === "group" || props.index === 0 ? "0.2rem" : "0"};
  padding-bottom: ${props => (props.type === "group" ? "0.2rem" : "0")};
  padding-left: 10px;
  padding-right: 23px;
  text-align: left;
  width: 100%;
  font-size: 10px;

  img {
    border-radius: 6px !important;
  }

  &:hover {
    background-color: ${colors.grey33};
  }
`;

export const NotFoundContainer = styled.div`
  color: ${colors.red500};
  margin-top: 0.75em;
  padding: 0 1em 0 1em;
`;
