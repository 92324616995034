// @flow

import React from "react";

import Common from "../Common";
import Name from "src/components/Form/Name";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const AddForm = ({ message }: Props) => {
  const templateId = message?.data?.templateId;

  return (
    <Common {...message}>
      {" "}
      removed a form{" "}
      <strong>
        <Name id={templateId} />
      </strong>{" "}
      from the checklist
    </Common>
  );
};

export default AddForm;
