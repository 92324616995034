// @flow

import { put, takeEvery, take } from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

function* setChatroomAttribute({ payload }: Action): any {
  const responseAction = yield take([
    atypes.SET_CHATROOM_ATTRIBUTE_SUCCESS,
    atypes.SET_CHATROOM_ATTRIBUTE_FAILURE
  ]);

  // Get different type of action from payload
  const { active, owner, dueDate, priority } = payload.value;

  if (responseAction.payload.failure) {
    // Dispatch approriate failure action
    // Dispatch close or reopen failure based on the active
    if (active !== undefined && !active) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FAILURE,
        payload: {
          roomId: payload.roomId,
          action: "close-chatroom",
          location: payload.analytics.location
        }
      });
    } else if (active) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FAILURE,
        payload: {
          roomId: payload.roomId,
          action: "reopen-chatroom",
          location: payload.analytics.location
        }
      });
    }

    // Dispatch owner update
    if (owner !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FAILURE,
        payload: {
          roomId: payload.roomId,
          action: "change-owner",
          location: payload.analytics.location
        }
      });
    }

    // Dispatch due date update
    if (dueDate !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FAILURE,
        payload: {
          roomId: payload.roomId,
          action: "change-due-date",
          location: payload.analytics.location
        }
      });
    }

    // Dispatch priority update
    if (priority !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FAILURE,
        payload: {
          roomId: payload.roomId,
          action: "change-priority",
          location: payload.analytics.location
        }
      });
    }
  } else {
    // Dispatch approriate success action
    // Dispatch close or reopen success based on the active
    if (active !== undefined && !active) {
      yield put({
        type: atypes.UPDATE_CHATROOM_SUCCESS,
        payload: {
          roomId: payload.roomId,
          action: "close-chatroom",
          location: payload.analytics.location
        }
      });
    } else if (active) {
      yield put({
        type: atypes.UPDATE_CHATROOM_SUCCESS,
        payload: {
          roomId: payload.roomId,
          action: "reopen-chatroom",
          location: payload.analytics.location
        }
      });
    }

    // Dispatch owner updateupdate
    if (owner !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_SUCCESS,
        payload: {
          roomId: payload.roomId,
          action: "change-owner",
          location: payload.analytics.location
        }
      });
    }

    // Dispatch due date updateupdate
    if (dueDate !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_SUCCESS,
        payload: {
          roomId: payload.roomId,
          action: "change-due-date",
          location: payload.analytics.location
        }
      });
    }
    // Dispatch priority updateupdate
    if (priority !== undefined) {
      yield put({
        type: atypes.UPDATE_CHATROOM_SUCCESS,
        payload: {
          roomId: payload.roomId,
          action: "change-priority",
          location: payload.analytics.location
        }
      });
    }
  }
}

function* watchSetChatroomAttribute(): any {
  yield takeEvery(atypes.SET_CHATROOM_ATTRIBUTE_REQUEST, setChatroomAttribute);
}

function* viewFilter({ payload }: Action): any {
  if (payload.type === "default") {
    yield put({
      type: atypes.SET_DEFAULT_FILTER,
      payload: {
        name: payload.filter.name
      }
    });
  } else if (payload.type === "saved") {
    yield put({
      type: atypes.SET_SAVED_FILTER,
      payload: {
        name: payload.filter.name
      }
    });
  }
}

function* watchViewFilter(): any {
  yield takeEvery(atypes.SET_QUERY_FILTER, viewFilter);
}

export default [watchSetChatroomAttribute(), watchViewFilter()];
