// @flow

import { connect } from "react-redux";

import Advanced from "src/components/chatroom/creator/approval/Advanced";
import { getUserSearchResult } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProp = ({ app }: { app: AppState }) => ({
  dueDate: app.conversationDialog.dueDate,
  owner: app.conversationDialog.owner,
  description: app.conversationDialog.description,
  users: getUserSearchResult(app)
});

export default connect(mapStateToProp)(Advanced);
