// @flow

import React from "react";
import type { UnifizeUser } from "src/types";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Avatar,
  VStack,
  Text
} from "@chakra-ui/react";
import * as styles from "./styles";

type Props = {
  isOpen: boolean,
  onClose: Function,
  title: string,
  signatories: UnifizeUser[]
};

export default function SignatoriesModal({
  isOpen,
  onClose,
  title,
  signatories = []
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent sx={styles.modalContent}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody sx={styles.modalBody}>
          <VStack sx={styles.signatoriesList} spacing={4}>
            {signatories.map(user => (
              <HStack key={user?.uid}>
                <Avatar
                  name={user?.displayName}
                  src={user?.photoUrl}
                  size="sm"
                />

                <VStack sx={styles.userDetailsContainer} spacing={0}>
                  <Text sx={styles.userName}>{user?.displayName}</Text>
                  <Text sx={styles.userEmail}>{user?.email}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
