// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAllRoles, getAllPermissions } from "src/actions/roleManagement";

import { Box } from "@chakra-ui/react";
import Title from "./Title";
import RoleTable from "./RoleTable";

type Props = {
  _getAllRoles: Function,
  _getAllPermissions: Function
};

const RoleManagement = ({ _getAllRoles, _getAllPermissions }: Props) => {
  useEffect(() => {
    _getAllRoles();
    _getAllPermissions();
  }, []);

  return (
    <Box mt={4}>
      <Title />
      <RoleTable />
    </Box>
  );
};

export default connect(null, {
  _getAllRoles: getAllRoles,
  _getAllPermissions: getAllPermissions
})(RoleManagement);
