// @flow

import { useLayoutEffect, useState } from "react";
import { getDimensionObject } from "src/utils";

import type { ReactRef } from "src/types";

type Props = {
  initialTop: ?string,
  initialBottom: ?string,
  topOffset: string,
  bottomOffset: string,
  outerRef: ReactRef,
  initialRight?: string
};

const useAutoPosition = ({
  outerRef,
  initialTop,
  initialBottom,
  topOffset,
  bottomOffset,
  initialRight
}: Props) => {
  const [top, setTop] = useState(initialTop || "auto");
  const [bottom, setBottom] = useState(initialBottom || "auto");
  const [rightOffset, setRight] = useState(initialRight || "auto");

  useLayoutEffect(() => {
    if (outerRef.current) {
      const { bottom, right } = getDimensionObject(outerRef.current);
      if (window.innerWidth - right < 100) setRight(window.innerWidth - right);

      if (bottom > window.innerHeight) {
        setTop("auto");
        setBottom(bottomOffset);
      } else {
        setTop(topOffset);
        setBottom("auto");
      }
    }
  }, [outerRef]);

  return { top, bottom, rightOffset };
};

export default useAutoPosition;
