// @flow

import * as React from "react";

import { VStack, Text } from "@chakra-ui/react";

import { Select, Option } from "src/components/ChakraPro/Select";
import * as styles from "./styles";

type Props = {
  value: null | number,
  options: Array<{
    id: number,
    text: string,
    textColor: string
  }>,
  onChange: Function
};

const CustomSelect = Select<number>();

export default function StatusSelect({ value, options, onChange }: Props) {
  return (
    <VStack sx={styles.formItem}>
      <Text textStyle="label">Change status to</Text>

      <CustomSelect
        colorScheme="blue"
        value={value}
        onChange={onChange}
        placeholder="Select status"
        size="sm"
        sx={{ width: "380px" }}
      >
        <Option value={null}>
          <Text textStyle="label" value={null}>
            None
          </Text>
        </Option>

        {options.map(item => (
          <Option value={item.id} key={item.id}>
            <Text
              textStyle="label"
              key={item.id}
              value={item.id}
              color={item.textColor}
            >
              {item.text}
            </Text>
          </Option>
        ))}
      </CustomSelect>
    </VStack>
  );
}
