// @flow

import { connect } from "react-redux";

import Reply from "src/components/messages/Chat/Reply";

import { getMessageData, getReplyTo } from "src/reducers";

import { focusChatInput } from "src/actions/chatroom";
import { clearReplyTo } from "src/actions/chatmenu";

import type { AppState, Reply as ReplyType } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => {
  const replyTo: ReplyType = getReplyTo(app);
  const { to } = replyTo;
  return {
    currentMessage: getMessageData(app, to)
  };
};

export default connect(mapStateToProps, { clearReplyTo, focusChatInput })(
  Reply
);
