// @flow
import React from "react";

import type { Node } from "react";

import * as styles from "./styles";

type Props = {
  children: Node,
  customStyles?: { [string]: string | number },
  isOpen: boolean
};

const Modal = ({ children, customStyles, isOpen }: Props) => {
  return (
    <div
      style={
        isOpen ? { ...styles.Modal, ...customStyles } : { display: "none" }
      }
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  );
};

export default Modal;
