// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { HStack, VStack, Text } from "@chakra-ui/react";

import { getCurrentChecklistBuilder } from "src/reducers";

import Select from "src/components/Select";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

type Props = {
  value: number | null,
  onUpdate: (value: number | null) => void
};

const getLabel = ({ value, fileFields, deletedFields }) => {
  if (R.isNil(value)) return "";

  const field = fileFields.find(R.propEq("id", value));

  if (field) return field.label;

  const deletedField = deletedFields.find(R.propEq("id", value));

  return (
    <p>
      {deletedField.label}{" "}
      <span style={{ color: colors.danger }}>(Deleted field)!</span>
    </p>
  );
};

export default function Native({ value, onUpdate }: Props) {
  const currentChecklist = useSelector(({ app }) =>
    getCurrentChecklistBuilder(app)
  );

  const checklistFields = currentChecklist.fields.filter(R.has("id"));
  const deletedFields = currentChecklist.deletedFields;

  const fileFields = checklistFields.filter(field => field.type === "file");

  const label = getLabel({ value, fileFields, deletedFields });

  return (
    <VStack {...styles.TemplateContainer}>
      <HStack>
        <span style={styles.Label}>
          CHECKLIST FIELD WHERE NATIVE FILE IS LOCATED
        </span>
        <Icon type="info" filled width="13" height="13" />
      </HStack>

      <Select
        value={value}
        onChange={onUpdate}
        label={label}
        placeholder="Select checklist field to upload"
        width="100%"
      >
        {fileFields.map(item => (
          <Text key={item.id} value={item.id} my={0}>
            {item.label}
          </Text>
        ))}
      </Select>
    </VStack>
  );
}
