// @flow

import React from "react";
import { connect } from "react-redux";

import TitleComponent from "src/components/chatroom/Title/Small";
import {
  getDueDate,
  getChatroomType,
  getChatroomTitle,
  getSequenceNo,
  getCanceled,
  getTemplateId
} from "src/reducers";
import type { AppState, RoomId } from "src/types";

type Props = {
  type: string,
  title: string,
  dueDate: ?string,
  templateId: ?number,
  seqNo: ?number,
  active: ?boolean,
  outcome: ?boolean,
  canceled: ?boolean,
  roomId: RoomId
};

const Title = ({
  type,
  active,
  outcome,
  dueDate,
  canceled,
  roomId,
  ...rest
}: Props) => (
  <TitleComponent type={type} roomId={roomId} canceled={canceled} {...rest} />
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  dueDate: getDueDate(app, props.roomId),
  canceled: getCanceled(app, props.roomId),
  type: getChatroomType(app, props.roomId),
  title: getChatroomTitle(app, props.roomId),
  templateId: getTemplateId(app, props.roomId),
  seqNo: getSequenceNo(app, props.roomId)
});

export default connect(mapStateToProps, {})(Title);
