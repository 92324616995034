// @flow

import * as atypes from "src/constants/actionTypes";

import type { TemplateStatus, Action } from "src/types";

const templateStatus = (
  state: TemplateStatus = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_WORKFLOWS_STATUS_SUCCESS:
      return {
        ...state,
        ...payload.status
      };
    default:
      return state;
  }
};

export default templateStatus;

export const getActiveStatus = (
  state: TemplateStatus,
  id: string,
  statusId: string
) => (state[`${id}`] ? state[`${id}`][`${statusId}`]?.active : true);

export const getStatusArchiveMode = (
  state: TemplateStatus,
  id: string,
  statusId: string
) => (state[`${id}`] ? state[`${id}`][`${statusId}`]?.archiveMode : "ignore");

export const getTemplateStatus = (state: TemplateStatus, id: string) =>
  state[id];

export const getAllTemplateStatus = (state: TemplateStatus) => state;
