// @flow

import React, { useRef } from "react";

import Options from "./Options/Single";
import Standard from "src/components/chatroom/Status/Standard";
import Custom from "src/components/chatroom/Status/Custom";
import { Status as StyledStatus } from "src/components/chatroom/Status/styles";
import useDropdown from "src/hooks/useDropdown";

import type { RoomId, ColumnId } from "src/types";

type Props = {
  index: number,
  selected: boolean,
  roomId: RoomId,
  value: number,
  depth: number,
  parentId: number,
  columnId: ?ColumnId,
  fieldWidth?: string
};

const Status = ({
  value,
  roomId,
  selected,
  index,
  depth,
  parentId,
  columnId,
  fieldWidth
}: Props) => {
  const outerRef = useRef(null);
  const { dropdown, toggleDropdown } = useDropdown(outerRef);

  // $FlowFixMe - Optional chaining not supported
  const statusLabelWidth = parseInt(fieldWidth?.split("px")[0], 10) - 18 + "px";

  return (
    <StyledStatus>
      <div
        ref={outerRef}
        tabIndex="0"
        role="button"
        onClick={toggleDropdown}
        style={{
          maxWidth: statusLabelWidth,
          overflow: "hidden"
        }}
      >
        {value < 0 ? (
          <Standard id={value} roomId={roomId} />
        ) : (
          <Custom id={value} roomId={roomId} />
        )}
      </div>

      <Options
        selected={selected}
        roomId={roomId}
        index={index}
        handleClose={toggleDropdown}
        show={dropdown}
        depth={depth}
        parentId={parentId}
        columnId={columnId}
      />
    </StyledStatus>
  );
};

export default Status;
