// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { Button } from "src/styles/buttons";
import { Small } from "src/styles/modal";

const ApprovalBar = styled.div`
  height: 42px;
  width: 100%;
  background: ${colors.active};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.9em 1.8em;
  > span:first-of-type {
    color: ${colors.brightLight};
    span {
      color: ${colors.brightLight} !important;
    }
  }
`;

const Request = styled.span``;

const Accept = styled(Button)`
  background: ${colors.brightLight};
  color: ${colors.success};
  border: 1px solid ${colors.brightLight};
  padding: ${spacing.space_s} ${spacing.space_l} ${spacing.space_s}
    ${spacing.space_l};
  outline: none;
  :hover {
    transition: all 0.25s;
    background: ${colors.success};
    color: ${colors.brightLight};
    border: 1px solid ${colors.success};
  }
`;

const Deny = styled(Button)`
  background: ${colors.brightLight};
  color: ${colors.danger};
  border: 1px solid ${colors.brightLight};
  margin-left: 2em;
  padding: ${spacing.space_s} ${spacing.space_l} ${spacing.space_s}
    ${spacing.space_l};
  outline: none;
  :hover {
    transition: all 0.25s;
    background: ${colors.danger};
    color: ${colors.brightLight};
    border: 1px solid ${colors.danger};
  }
`;

const Respond = styled(Button)`
  background: ${colors.brightLight};
  color: ${colors.active};
  border: 1px solid ${colors.brightLight};
  padding: ${spacing.space_s} ${spacing.space_l} ${spacing.space_s}
    ${spacing.space_l};
`;

const Approvals = styled(Small)`
  > div > div > .ReactModal__Content {
    width: 27em;
    > form {
      min-width: 26.5em;
    }
  }
`;

const Body = styled.div`
  background: ${colors.brightLight};
  min-height: 10em;
  padding: ${spacing.space_inset_l};
  > div {
    margin-top: 1.5em;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.space_l};
  ${Accept} {
    font-size: 0.75rem;
    border: 1px solid ${colors.success};
  }
  ${Deny} {
    margin-left: 0.7em;
    font-size: 0.75rem;
    border: 1px solid ${colors.danger};
  }
`;

export { ApprovalBar, Request, Accept, Deny, Respond, Approvals, Body, Item };
