// @flow

import type {
  SystemCSSProperties,
  TextProps,
  StackProps,
  PopoverContentProps,
  PopoverBodyProps,
  CenterProps
} from "@chakra-ui/react";

export const iconBtn: SystemCSSProperties = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center"
};

export const actionsBar: StackProps = {
  spacing: 2,
  sx: {
    display: "none"
  },
  _groupHover: {
    display: "flex",
    justifySelf: "end !important"
  }
};

export const compressedView: {
  container: StackProps,
  fileName: TextProps
} = {
  container: {
    role: "group",
    spacing: 2,
    sx: {
      border: "1px solid",
      borderColor: "unifize_inkLightest",
      borderRadius: "2px",
      width: "100%",
      maxWidth: "100%",
      marginBottom: "0.5rem",
      height: "2.67em",
      p: "0 0.67em",
      justifyContent: "space-between"
    }
  },

  fileName: {
    textStyle: "label",
    sx: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }
};

export const ExpandedViewContainer: StackProps = {
  border: "1px solid",
  borderRadius: "2px",
  width: "100%",
  height: "4rem",
  pr: "0.25rem",
  cursor: "pointer"
};

export const expandedView: {
  fileData: StackProps,
  fileName: TextProps
} = {
  fileData: {
    spacing: 1,
    sx: {
      alignItems: "start",
      py: 0,
      width: "100%",
      height: "fit-content"
    }
  },

  fileName: {
    textStyle: "label",
    sx: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }
};

export const popover: {
  content: PopoverContentProps,
  body: PopoverBodyProps,
  fileName: TextProps,
  fileData: StackProps,
  noPreview: {
    container: CenterProps,
    text: TextProps
  }
} = {
  content: {
    sx: {
      backgroundColor: "unifize_unifizeBlue"
    }
  },

  body: {
    sx: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      color: "white"
    }
  },

  fileName: {
    sx: {
      fontSize: "12px",
      lineHeight: "13.33px",
      m: 0,
      mb: "6px"
    }
  },

  fileData: {
    sx: {
      alignItems: "center",
      mb: 5,
      p: {
        fontSize: "10px",
        m: 0,
        lineHeight: "6px",
        letterSpacing: "0.175862px",
        color: "blue.100"
      }
    }
  },

  noPreview: {
    container: {
      sx: {
        backgroundColor: "unifize_whiteAlpha200",
        borderRadius: "6px",
        width: "100%",
        height: "53px"
      }
    },
    text: {
      sx: {
        fontSize: "22px",
        lineHeight: "26.4px"
      }
    }
  }
};
