// @flow

import React from "react";

import { Creator as StyledCreator } from "./styles";
import User from "src/containers/user/NameOrEmail";

import type { UID } from "src/types";

type Props = {
  user: UID
};

const Creator = ({ user }: Props) => (
  <StyledCreator>
    <User uid={user} />
  </StyledCreator>
);

export default Creator;
