// @flow

import { backendUrl } from "src/config/firebase";

/**
 * Fetching user analytics
 */

const getUserAnalytics = async ({ filter }: { filter: Object }) => {
  const response = await fetch(`${backendUrl}/report/user${filter}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export { getUserAnalytics };
