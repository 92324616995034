// @flow

import React from "react";

import { Box, Heading, Text } from "@chakra-ui/react";

type Props = {
  heading1: string,
  heading2: string
};

const SubHeader = ({ heading1, heading2 }: Props) => (
  <Box mb="2">
    <Heading size="16px" m="0" mb="1">
      {heading1}
    </Heading>
    <Text m="0" fontSize="xs">
      {heading2}
    </Text>
  </Box>
);

export default SubHeader;
