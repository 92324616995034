// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

export const Navigation = styled.div`
  margin: 1.5em 0 0 0.7em;
`;

export const Header = styled.strong`
  font-size: 1.3em;
  padding-right: 1.6em;
`;

export const InActive = styled.strong`
  color: ${colors.secondary};
  display: inline-block;
  margin: 0 1em;
  font-size: 0.75rem;
  > a {
    color: ${colors.secondary};
    transition: color 0.5s;
    :hover {
      transition: color 0.5s;
      color: ${colors.active};
    }
  }
`;

export const Active = styled.strong`
  color: ${colors.active};
  display: inline-block;
  margin: 0 1em;
  font-size: 0.75rem;
  border-bottom: 2px solid ${colors.active};
`;
