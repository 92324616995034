// @flow

import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import type {
  AppState,
  RoomId,
  FieldId,
  UID,
  Approval,
  ApprovalFieldValue
} from "src/types";
import { getCurrentUserId } from "src/reducers";
import { getDateTimeFromString } from "src/utils";
import useBoolean from "src/hooks/useBoolean";

import { VStack, Text, Button, HStack } from "@chakra-ui/react";

import { getMinApprovers } from "src/utils/checklist";
import { recordTypeData } from "./constants";
import type { RecordType } from "./constants";

import SignatureRecord from "./SignatureRecord";
import SignatureButtonGroup from "./SignatureButtonGroup";

type Props = {|
  currentUserId: string,
  id: string,
  settings: Object,
  completed: boolean,
  approval: Approval,
  deprecated: ?boolean,
  handleSign: Function,
  signatories: UID[],
  roomId: RoomId,
  fieldId: FieldId,
  disabled: boolean,
  value: ApprovalFieldValue
|};

export const expandedStyles = {
  padding: 2,
  border: "2px",
  borderColor: "purple.200"
};

function ApprovalCard({
  currentUserId,
  settings,
  value,
  approval,
  deprecated,
  handleSign,
  signatories,
  roomId,
  fieldId,
  disabled
}: Props) {
  const { signatures } = approval;
  const hasOutcome = approval.outcome !== null;

  const { value: showDetails, toggleBoolean: toggleDetails } = useBoolean(
    !hasOutcome && !deprecated
  );

  const hasUserSigned = Boolean(
    signatures.find(item => item.signer === currentUserId)
  );
  const requiredSignatures = getMinApprovers(settings, value);

  const completedSignatures = signatures.length;

  const boxExpandedStyles = showDetails ? expandedStyles : null;

  const recordType: RecordType =
    deprecated && R.isNil(approval.outcome)
      ? "partialApproval"
      : approval.outcome === false
      ? "rejection"
      : "approval";

  const lastSignature = R.last(approval.signatures);
  const recordDate =
    recordType === "partialApproval"
      ? lastSignature
        ? lastSignature.signedAt
        : null
      : approval.approvedAt;

  return (
    <VStack {...boxExpandedStyles} w="100%">
      {(hasOutcome || recordType === "partialApproval") && (
        <HStack
          justifyContent="space-between"
          backgroundColor={recordTypeData[recordType].bg}
          w="100%"
          px={2}
          py={1}
        >
          <Text
            m={0}
            textDecoration={deprecated ? "line-through" : null}
            color="unifize_red400"
          >
            {recordTypeData[recordType].label} -{" "}
            {getDateTimeFromString(recordDate, false)}
          </Text>

          <Button
            onClick={toggleDetails}
            variant="link"
            size="xs"
            bg="transparent"
            color="unifize_blue"
            _focus={{
              boxShadow: null
            }}
          >
            {showDetails ? "Hide" : "Show"}
          </Button>
        </HStack>
      )}

      {showDetails &&
        approval.signatures.map(signature => (
          <SignatureRecord
            key={signature.id}
            id={signature.id}
            type={recordType}
            author={signature.signer}
            signature={signature.signature}
            comment={signature.comment}
            date={signature.signedAt}
            currentUserId={currentUserId}
          />
        ))}

      {!hasOutcome && recordType !== "partialApproval" && (
        <SignatureButtonGroup
          hasUserSigned={hasUserSigned}
          done={completedSignatures}
          total={requiredSignatures}
          handleSign={handleSign}
          disabled={disabled}
          signatories={signatories}
          roomId={roomId}
          fieldId={fieldId}
          settings={settings}
        />
      )}

      {recordType === "partialApproval" && showDetails && (
        <Button
          sx={{
            bg: recordTypeData[recordType].bg,
            color: "unifize_inkLighter",
            width: "100%",
            textAlign: "center",
            borderRadius: "xs",
            cursor: "inherit",
            _hover: {
              bg: recordTypeData[recordType].bg,
              color: "unifize_inkLighter"
            }
          }}
        >
          Incomplete
        </Button>
      )}
    </VStack>
  );
}

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUserId: getCurrentUserId(app)
});

export default connect(mapStateToProps, null)(ApprovalCard);
