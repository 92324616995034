// @flow

import React from "react";

import { OpaqueOverlay as StyledInstances } from "../styles";
import Breadcrumbs from "./Breadcrumbs";
import Table from "./Table";
import Close from "src/containers/Close";
import FileViewer from "src/components/Main/Viewer.js";

const Instance = () => (
  <StyledInstances>
    <Close />
    <Breadcrumbs />
    <Table />
    <FileViewer />
  </StyledInstances>
);

export default Instance;
