// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";

const AccordionTitle = styled.div`
  position: sticky;
  top: 0;
  padding: ${spacing.space_s} ${spacing.space_l};
  background-color: ${colors.highlight};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  z-index: 1;
  line-height: 1rem;
`;

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.space_m};
`;

const AccordionIcon = styled.span`
  margin-left: auto;
  > svg {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    transform: rotate(360deg);
    ${props => props.active && "transform: rotate(180deg)"}
  }
`;

const AccordionContent = styled.div`
  background-color: ${colors.brightLight};
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: ${props => (props.active ? props.scrollHeight : "0px")};
`;

const AccordionText = styled.div`
  display: flex;
  font-weight: ${typography.medium};
  color: ${colors.active};
  letter-spacing: 1.5px;
  font-size: 0.625rem;
  text-transform: uppercase;
`;

const StatusType = styled.div`
  color: ${colors.primary};
  margin-left: ${spacing.space_s};
`;

const Count = styled.div`
  color: ${colors.secondary};
  font-size: 0.625rem;
  margin-left: ${spacing.space_s};
  font-weight: ${typography.medium};
`;

export {
  AccordionSection,
  AccordionTitle,
  AccordionIcon,
  AccordionContent,
  AccordionText,
  StatusType,
  Count
};
