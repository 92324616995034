// @flow

import { useSelector } from "react-redux";
import React from "react";

import Single from "./Single";
import MultiSelect from "./MultiSelect.js";
import useFields from "../useFields.js";
import {
  getChecklistValue,
  getChecklistFormValue,
  getChecklistFieldDetails,
  getSelectedChecklist
} from "src/reducers";

import type { RoomId, ColumnId, ChecklistId } from "src/types";

type Props = {
  formId?: ?number,
  parentConversation?: boolean,
  roomId: RoomId,
  columnId?: ?ColumnId,
  fieldId: number,
  checklistId?: ?ChecklistId,
  promptCallback?: ?Function,
  location?: string,
  roomFieldFormId?: ?string,
  fromManageView?: ?boolean,
  selectedFieldValue?: any,
  setSelectedFieldValue?: any => void
};

const Conversation = ({
  formId,
  parentConversation = false,
  roomId,
  columnId,
  fieldId,
  checklistId,
  location,
  roomFieldFormId,
  fromManageView = false,
  selectedFieldValue,
  setSelectedFieldValue
}: Props) => {
  const { roomId: selectedRoomId } = useSelector(({ app }) =>
    getSelectedChecklist(app)
  );
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, selectedRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const { settings } = useFields({
    checklistValue,
    details
  });
  const multiple = (settings || {}).multiple || false;

  if (!multiple) {
    return (
      <Single
        formId={formId}
        roomId={roomId}
        location={location}
        columnId={columnId}
        fieldId={fieldId}
        checklistId={checklistId}
        roomFieldFormId={roomFieldFormId}
        parentConversation={parentConversation}
        fromManageView={fromManageView}
      />
    );
  }

  return (
    <MultiSelect
      formId={formId}
      roomId={roomId}
      location={location}
      columnId={columnId}
      fieldId={fieldId}
      checklistId={checklistId}
      roomFieldFormId={roomFieldFormId}
      parentConversation={parentConversation}
      fromManageView={fromManageView}
      selectedFieldValue={selectedFieldValue}
      setSelectedFieldValue={setSelectedFieldValue}
    />
  );
};
export default Conversation;
