/* eslint-disable no-extra-boolean-cast */
// @flow

import React from "react";
import { connect } from "react-redux";

import { Stack, Radio, RadioGroup } from "@chakra-ui/react";
import { setNewChartDialogAttributes } from "src/actions/chart";

type Props = {
  value: ?boolean,
  _setNewChartDialogAttributes: Function
};

const MultipleSelection = ({ value, _setNewChartDialogAttributes }: Props) => {
  const handleMultipleSelection = (value: string) => {
    _setNewChartDialogAttributes({
      relativeCount: value === "on"
    });
  };

  return (
    <RadioGroup
      onChange={handleMultipleSelection}
      value={`${!!value ? "on" : "off"}`}
    >
      <Stack>
        <Radio id="proportion" value="on" size="sm">
          Y-axis shows total conversation count (i.e. proportional weight to
          each value)
        </Radio>

        <Radio id="equalValue" value="off" size="sm">
          Y-axis shows total value count (i.e. equal weight to each value)
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(MultipleSelection);
