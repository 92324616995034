// @flow

import React from "react";
import { useSelector } from "react-redux";
import { HStack, Text, Avatar, VStack } from "@chakra-ui/react";
import { getUser } from "src/reducers";

import type { DownshiftProps } from "downshift";
import type { UID, UnifizeUser } from "src/types";

import type { OnItemClick } from "src/components/Dropdown";

import * as styles from "./styles";

type Props = {|
  id: UID,
  onItemClick?: OnItemClick,
  highlightedIndex?: number,
  index?: number,
  scrollIntoView?: DownshiftProps.scrollIntoView
|};

const User = ({
  id,
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView
}: Props) => {
  const user: UnifizeUser = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;
  return (
    <HStack
      sx={
        (highlightedIndex !== null && highlightedIndex === index) ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveUserOrgroup
          : styles.inActiveUserOrgroup
      }
      ref={highlightedIndex === index ? scrollIntoView : null}
      onClick={() => {
        onItemClick && onItemClick({ id });
      }}
    >
      <Avatar
        sx={styles.UserAvatar}
        src={photoUrl}
        name={displayName}
        size="sm"
      />
      <VStack sx={styles.UserDetails}>
        <Text sx={styles.UserDetailsHeader}>{displayName}</Text>
        <Text sx={styles.UserDetailsBody}>{email}</Text>
      </VStack>
    </HStack>
  );
};

export default User;
