// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { ScrollableBox, ListItemMedium } from "src/styles/box";

export const Select = styled.div`
  border-radius: ${radius.small};
  padding: 0;
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  position: relative;
  align-items: center;
  cursor: pointer;
  min-width: auto;
  width: 10.3em;
  display: inline-flex;
  padding: 0.4em;
  margin-left: 0.4em;
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  width: 100%;
  top: 2.5em;
  right: 0;
  z-index: 1001;

  > ul {
    max-height: 15em;
  }
  ${ListItemMedium} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 97%;
    line-height: 0.625rem;
    padding-top: 1em;
    padding-bottom: 1em;
  }
`;
export const Selection = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 97%;
`;
