// @flow

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import type { DeletedChecklistField } from "src/types";
import Field from "./Field";

type Props = {
  value: DeletedChecklistField,
  position: number,
  updateField: Function,
  restoreField: Function
};

const SortableItem = SortableElement(
  ({ value, position, updateField, restoreField }: Props) => (
    <Field
      value={value}
      index={position}
      position={position}
      updateField={updateField}
      restoreField={restoreField}
    />
  )
);

export default SortableItem;
