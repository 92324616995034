// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Lato-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Lato-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Lato-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Lato-Semibold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Lato-Semibold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Lato-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Lato-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ubuntu-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  src: local("Lato Medium"), local("Lato-Medium"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: local("Lato Semibold"), local("Lato-Semibold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff");
}

/* ubuntu-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff");
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB;;0DAEkD;AACpD;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB;;yDAE+C;AACjD;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB;;0DAEmD;AACrD;;AAEA,uBAAuB;AACvB;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB;;0DAE+C;AACjD","sourcesContent":["/* ubuntu-regular - latin */\n@font-face {\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 400;\n  src: local(\"Lato Regular\"), local(\"Lato-Regular\"),\n    url(\"../fonts/Lato-Regular.woff2\") format(\"woff2\"),\n    url(\"../fonts/Lato-Regular.woff\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 500;\n  src: local(\"Lato Medium\"), local(\"Lato-Medium\"),\n    url(\"../fonts/Lato-Medium.ttf\") format(\"ttf\"),\n    url(\"../fonts/Lato-Medium.ttf\") format(\"ttf\");\n}\n\n@font-face {\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 600;\n  src: local(\"Lato Semibold\"), local(\"Lato-Semibold\"),\n    url(\"../fonts/Lato-Semibold.woff2\") format(\"woff2\"),\n    url(\"../fonts/Lato-Semibold.woff\") format(\"woff\");\n}\n\n/* ubuntu-700 - latin */\n@font-face {\n  font-family: \"Lato\";\n  font-style: normal;\n  font-weight: 700;\n  src: local(\"Lato Bold\"), local(\"Lato-Bold\"),\n    url(\"../fonts/Lato-Bold.woff2\") format(\"woff2\"),\n    url(\"../fonts/Lato-Bold.woff\") format(\"woff\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
