// @flow

import { connect } from "react-redux";

import DirectMessages from "src/components/filter/Item";
import defaultFilter from "src/constants/defaultFilter";

import { showView } from "src/actions";
import { setFilter } from "src/actions/filter";
import { set as setActiveFilter } from "src/actions/activeFilter";
import {
  fetchChatroomUpdates,
  setPreviousChatroom
} from "src/actions/chatroom";
import { getWorkflowBuilderShown } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    view: app.view,
    filter: defaultFilter.directmessage,
    show: getWorkflowBuilderShown(app)
  };
};

export default connect(mapStateToProps, {
  setFilter,
  setActiveFilter,
  showView,
  fetchChatroomUpdates,
  setPreviousChatroom
})(DirectMessages);
