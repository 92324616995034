// @flow

import Link from "redux-first-router-link";
import React, { useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import * as R from "ramda";

import RefreshButton from "src/components/chart/RefreshButton";
import { MenuBar as StyledMenuBar, IconButton } from "./styles";
import Icon from "src/icons";
import * as atypes from "src/constants/actionTypes";
import {
  setNewChartDialogAttributes,
  showEditChartModal,
  copyChartUrl,
  editChart,
  fetchChart
} from "src/actions/chart";
import { getInstanceReportId, getInstanceFilterId } from "src/reducers";
import { setReports } from "src/actions/reports";
import { comparisonCharts } from "src/constants/charts.js";
import { getChart } from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  hideRefresh?: boolean,
  filter: WorkflowInstanceFilter,
  chartId: string,
  reportId: number,
  _setNewChartDialogAttributes: Function,
  _showEditChartModal: Function,
  _copyChartUrl: typeof copyChartUrl
};

const MenuBar = ({
  hideRefresh,
  filter,
  chartId,
  reportId,
  _setNewChartDialogAttributes,
  _showEditChartModal,
  _copyChartUrl
}: Props) => {
  const dispatch = useDispatch();

  const chartDetails = useSelector(
    ({ app }) => chartId && getChart(app, chartId)
  );
  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));

  const comparisonChartList = R.values(comparisonCharts);
  const handleExpand = useCallback(() => {
    dispatch(
      setReports(reportId, {
        query: {
          ...filter,
          chartId
        }
      })
    );

    _setNewChartDialogAttributes({ show: false });
  }, [filter, chartId, _setNewChartDialogAttributes]);

  const preventNavigation = useCallback(() => {
    _copyChartUrl();
    return false;
  }, [_copyChartUrl]);

  const handleEdit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { type } = chartDetails;
    const isComparisonChart = comparisonChartList.includes(type);
    if (isComparisonChart) {
      dispatch(
        editChart({
          id: Number(chartId),
          type,
          reportId: String(reportId),
          workflowId
        })
      );
      dispatch(fetchChart(Number(chartId)));
    } else {
      _showEditChartModal(chartId);
    }
  };

  return (
    <StyledMenuBar>
      <Link
        id="chartShareURL"
        onClick={preventNavigation}
        to={{
          type: atypes.SET_REPORTS_REQUEST,
          payload: {
            id: reportId
          },
          meta: {
            query: {
              ...filter,
              chartId
            }
          }
        }}
      >
        <IconButton type="button">
          <Icon type="shareLink" />
        </IconButton>
      </Link>

      <IconButton type="button" onClick={handleEdit}>
        <Icon type="edit" />
      </IconButton>

      <IconButton type="button" onClick={handleExpand}>
        <Icon type="fullscreen" />
      </IconButton>

      {!hideRefresh && (
        <Box position="absolute" top="1.3em" right="3.5em">
          <RefreshButton chartId={chartId} />
        </Box>
      )}
    </StyledMenuBar>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  chartId: app.charts.newChartDialog.id,
  filter: app.workflow.additionalFilters,
  title: app.charts.newChartDialog.title,
  reportId: getInstanceReportId(app)
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _showEditChartModal: showEditChartModal,
  _copyChartUrl: copyChartUrl
})(MenuBar);
