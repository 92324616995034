// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Tag, TagLabel, Avatar } from "@chakra-ui/react";
import { getUser } from "src/reducers";

import type { UID, UnifizeUser } from "src/types";

import * as styles from "./styles";

type Props = {|
  uid: UID
|};

const UserTag = ({ uid }: Props) => {
  const user: UnifizeUser = useSelector(({ app }) => getUser(app, uid));
  const { displayName, photoUrl } = user;
  return (
    <Tag sx={styles.UserTag}>
      <Avatar
        sx={styles.UserAvatarTag}
        src={photoUrl}
        name={displayName}
        size="2xs"
      />
      <TagLabel sx={styles.UserTagText}>{displayName}</TagLabel>
    </Tag>
  );
};

export default UserTag;
