// @flow

import styled from "@emotion/styled";

import * as colors from "./constants/colors";
import * as spacing from "./constants/spacing";
import { ScrollableBox, ListItemMedium } from "src/styles/box";

const Table = styled.table`
  width: 99.2%;
  border-collapse: collapse;
  thead {
    background-color: ${colors.brightLight};
  }

  td {
    border-right: 1px solid ${colors.grey2};
    border-bottom: 1px solid ${colors.grey2};
    padding: ${spacing.space_m};
    color: ${colors.primary};
    min-height: 40px;
  }

  tbody {
    outline: none;
    td {
      padding: ${spacing.space_inset_l};
    }
  }

  thead tr:nth-of-type(1) td {
    position: sticky;
    top: 0;
    z-index: 4;

    background-color: ${colors.grey20};
    border-right: 1px solid ${colors.grey2};
    border-bottom: 1px solid ${colors.grey2};
    border-top: 1px solid ${colors.grey2};
  }
`;

const Th = styled.td`
  outline: none;
  cursor: pointer;
  font-weight: ${props => (props.active ? "bold" : "normal")} !important;
  font-size: 0.813rem;
  user-select: none;
  :focus {
    color: ${colors.primary} !important;
  }
`;

const THContent = styled.div`
  display: flex;
  justify-content: space-between;

  > svg {
    height: 1.3em;
    width: 1.3em;
    padding: 2px;
    border-radius: 2px;
    background-color: ${colors.grey2};
    margin-left: 4px;

    > path {
      fill: ${colors.primary};
    }
  }
`;

const Row = styled.tr`
  > td {
    background: ${props =>
      props.selected ? colors.grey0 : colors.brightLight} !important;
  }
`;

const Options = styled(ScrollableBox)`
  position: absolute;
  top: 3.1em;
  font-weight: normal;
  color: ${colors.grey5};
  left: ${spacing.space_m};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  min-width: 158px;
  font-size: 0.75rem;
  > span {
    margin: 0em 0.5em;
    font-size: 0.75rem;
    font-weight: bold;
  }
  ul {
    max-height: 11em;
  }
  > ul {
    :not(:last-child) {
      border-bottom: 1px solid ${colors.grey2};
      padding-bottom: ${spacing.space_m};
      margin-bottom: ${spacing.space_m};
    }
  }
`;

const Item = styled(ListItemMedium)`
  text-transform: capitalize;
  color: ${colors.primary};
  padding-top: 0px;
  padding-bottom: 0px;
  > span:nth-of-type(2),
  > div:nth-of-type(2) {
    padding-left: ${spacing.space_s};
    display: flex;
    align-items: center;
  }
  svg {
    height: 0.8em;
    width: 0.8em;
    margin-right: ${spacing.space_m};
  }
`;

const SortItem = styled(ListItemMedium)`
  line-height: 1.25rem;
`;

export { Table, Th, Row, Options, Item, SortItem, THContent };
