// @flow

import React from "react";

const DockMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="16"
    fill="none"
    viewBox="0 0 4 16"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M0 1.846C0 .825.825 0 1.846 0c1.022 0 1.846.825 1.846 1.846a1.844 1.844 0 0 1-1.846 1.846A1.844 1.844 0 0 1 0 1.846zM0 8c0-1.022.825-1.846 1.846-1.846 1.022 0 1.846.824 1.846 1.846a1.844 1.844 0 0 1-1.846 1.846A1.844 1.844 0 0 1 0 8zM0 14.154c0-1.022.825-1.846 1.846-1.846 1.022 0 1.846.824 1.846 1.846A1.844 1.844 0 0 1 1.846 16 1.844 1.844 0 0 1 0 14.154z"
      clipRule="evenodd"
    />
  </svg>
);

export default DockMenu;
