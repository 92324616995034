// @flow

import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { VStack } from "@chakra-ui/react";

import useFields from "../useFields";
import UserDropdown from "./UserDropdown";
import Users from "./Users";

import {
  bulkUpdateProcess,
  updateChecklistFromManageView
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import {
  getAllMembersAndGroups,
  getChecklistValue,
  getChecklistFieldDetails,
  getChecklistFormValue,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";
import httpMethods from "src/constants/httpMethods";
import { getUserAndGroupIds, getMultipleSelectedValues } from "./utils";

import * as styles from "./styles";
import type {
  RoomId,
  FieldId,
  ColumnId,
  FieldValue,
  HttpMethods
} from "src/types";

type Props = {
  formId?: ?number,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  handleClose?: Function,
  fromManageView?: ?boolean,
  handleClose?: Function,
  location?: string,
  selectedFieldValue?: any,
  setSelectedFieldValue?: any => void
};

const ChecklistUsers = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose,
  location,
  selectedFieldValue,
  setSelectedFieldValue
}: Props) => {
  const dispatch = useDispatch();

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );

  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const orgUsersAndGroups = useSelector(({ app }) =>
    getAllMembersAndGroups(app)
  );

  const {
    edit,
    value: extractedValue,
    settings,
    openEdit,
    closeEdit
  } = useFields({
    checklistValue,
    details
  });

  const value = fromManageView ? selectedFieldValue : extractedValue;

  const multiple: boolean = settings && settings.multiple;

  useEffect(() => {
    setSelectedFieldValue && setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        } else {
          setSelectedFieldValue && setSelectedFieldValue(value);
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          httpMethod,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const userAndGroupIds = getUserAndGroupIds(value);
  const multipleSelectedValues = getMultipleSelectedValues(
    orgUsersAndGroups,
    userAndGroupIds
  );

  const shouldApplySpacing = multiple && multipleSelectedValues.length > 0 && 4;

  const handleRemove = useCallback(
    id => {
      let formattedValue = id;
      if (typeof formattedValue === "object") {
        formattedValue =
          formattedValue.type === "group"
            ? formattedValue.id
            : formattedValue.uid;
      }
      if (typeof formattedValue === "string") {
        dispatch(
          setChecklistFieldValue({
            roomId,
            id: fieldId,
            value: {
              value: {
                uid: formattedValue,
                type: "user"
              },
              type: "user",
              checked: false
            },
            progress: true,
            formId,
            columnId,
            httpMethod: httpMethods.delete
          })
        );
      } else if (typeof formattedValue === "number") {
        dispatch(
          setChecklistFieldValue({
            roomId,
            id: fieldId,
            value: {
              value: {
                id: formattedValue,
                type: "group"
              },
              type: "user",
              checked: false
            },
            progress: true,
            formId,
            columnId,
            httpMethod: httpMethods.delete
          })
        );
      }
    },
    [value]
  );

  return (
    <VStack
      align="stretch"
      spacing={shouldApplySpacing}
      sx={styles.ChecklistUsersContainer}
    >
      <Users
        roomId={roomId}
        formId={formId}
        fieldId={fieldId}
        roomFieldFormId={roomFieldFormId}
        edit={edit}
        openEdit={openEdit}
        closeEdit={closeEdit}
        handleRemove={handleRemove}
        selectedFieldValue={selectedFieldValue}
        fromManageView={fromManageView ?? false}
      />

      <UserDropdown
        fieldId={fieldId}
        edit={edit}
        openEdit={openEdit}
        closeEdit={closeEdit}
        roomId={roomId}
        roomFieldFormId={roomFieldFormId}
        location={location}
        formId={formId}
        columnId={columnId}
        selectedFieldValue={selectedFieldValue}
        fromManageView={fromManageView ?? false}
        handleClose={handleClose}
        setSelectedFieldValue={setSelectedFieldValue}
      />
    </VStack>
  );
};

export default ChecklistUsers;
