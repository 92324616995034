// @flow

import { connect } from "react-redux";

import {
  setConversationAttribute,
  setConversationAttributes,
  clearConversationDialog
} from "src/actions/chatroom";
import process from "src/components/chatroom/creator/process";
import { getNextSeqNo, clearNextSeqNo } from "src/actions/workflows";
import { getCurrentUserId } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  parent: app.conversationDialog.parent,
  templateId: app.conversationDialog.templateId,
  members: app.conversationDialog.members,
  dueDate: app.conversationDialog.dueDate,
  description: app.conversationDialog.description,
  owner: app.conversationDialog.owner,
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  setConversationAttribute,
  setConversationAttributes,
  clearConversationDialog,
  getNextSeqNo,
  clearNextSeqNo
})(process);
