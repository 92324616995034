// @flow

import styled from "@emotion/styled";

import { Table as DefaultTable } from "src/styles/table";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

const TableContainer = styled.div`
  width: calc(100vw - 6em);
  height: calc(100vh - 11em);
  overflow: auto;
  position: relative;
  z-index: 0;
  margin-left: -${spacing.space_m};
`;

const Table = styled(DefaultTable)`
  tbody > tr > td:nth-of-type(4),
  tbody > tr > td:nth-of-type(5) {
    min-width: 12em;
  }
`;

const TableHeader = styled.thead`
  > tr > td {
    :first-of-type {
      width: 22vw;
    }
    :nth-of-type(2),
    :nth-of-type(3) {
      width: 10vw;
      text-align: center;
    }
    :nth-of-type(4),
    :nth-of-type(5) {
      width: 12vw;
    }
    :nth-of-type(6) {
      width: 15vw;
    }
  }
`;

const Row = styled.tr`
  background: ${props => (props.last ? colors.highlight : colors.brightLight)};
  transition: all 0.8s ease;
`;

export { Table, Row, TableHeader, TableContainer };
