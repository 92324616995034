// @flow
import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import { rgba } from "polished";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

export const ChatCard = styled.div`
  width: 100%;
  margin-top: ${ifProp("first", `1em`, "0.67em")};
  padding: ${ifProp("showSelect", "0 0 0 3em")};
  display: flex;
  align-items: flex-start;
`;

export const ChatMessageContainer = styled.div`
  position: relative;
  width: 100%;
  :hover {
    background: ${props =>
      props.isSrwMobile ? "initial" : rgba(colors.outline, 0.1)};
  }
`;

export const ChatMessage = styled.div`
  font-weight: normal;
  padding: 0 0.8em;
  display: flex;
  max-width: 670px;
`;

export const MenuIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const FlexSpacer = styled.div`
  flex: 1;
`;

export const UserInfo = styled.div`
  display: inline-flex;
  > div > div {
    width: 36px;
    height: 36px;
    border-radius: 0.5em;
  }
`;

export const MessageCard = styled.div`
  background: ${ifProp(
    "isCurrentUser",
    colors.currentUser,
    colors.brightLight
  )};
  margin-left: ${ifProp("first", "0.8em", "3.4em")};
  padding: 0.8em;
  padding-bottom: ${ifProp("singleLine", "1.35em")};
  font-size: 0.88rem;
  padding-bottom: ${ifProp("isChecklist", "1.4em")};
  border-radius: 0 0.5em 0.5em 0.5em;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: calc(100% - 50px);
`;

export const Name = styled.div`
  font-weight: bold;
  line-height: 1.43;
  span {
    color: ${ifProp(
      "hashColor",
      props => props.hashColor,
      colors.primary
    )} !important;
  }
`;

export const ChatTimestamp = styled.div`
  width: 80px;
  position: absolute;
  bottom: ${spacing.space_m};
  right: ${spacing.space_m};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > svg {
    margin-top: ${spacing.space_s};
    width: 0.7em;
    height: 0.7em;
  }
`;

export const ForwardedMessage = styled.div`
  background: ${ifProp(
    "isCurrentUser",
    colors.currentUserHighlight,
    colors.grey0
  )};
  padding: 0.8em;
  margin-bottom: 1.8em;
  border-radius: 0.2em;
`;

export const FileInfo = styled.div`
  align-items: center;
  > svg {
    margin-left: -0.3em;
  }
  > div:nth-of-type(2) {
    margin-left: 0.3em !important;
  }
`;

export const NewMenu = styled.div`
  position: absolute;
  right: 20px;
  top: -15px;
  display: flex;
  svg {
    cursor: pointer;
  }
`;

export const MenuOption = styled.div`
  padding: 8px 4px;
  border-radius: 3px;
  margin-left: 3px;
  border: solid 1px ${colors.grey3};
  background-color: ${colors.brightLight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Reply = styled(MenuOption)`
  position: relative;
  height: 30px;
  width: 30px;
  padding: 0px;
  top: -2px;
`;
export const Forward = styled(MenuOption)`
  position: relative;
  height: 30px;
  width: 30px;
  padding: 0px;
  top: -2px;
`;

export const Pin = styled(MenuOption)`
  padding: 3px 2px 4px 3px;
  height: 30px;
  width: 30px;
  position: relative;
  top: -2px;
`;

export const Start = styled(MenuOption)`
  color: ${colors.active};
  font-size: 12px;
  position: relative;
  top: -2px;
  cursor: pointer;
  height: 30px;
`;

export const FileDownload = styled(MenuOption)`
  position: absolute;
  top: -2px;
  right: 147px;
  cursor: pointer;
  height: 30px;
  padding: 0 0.6em;
  > a {
    display: flex;
    > span {
      margin-left: 0.3em;
      color: ${colors.primary};
    }
  }
`;

export const Submenu = styled.div`
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: -4px;
`;

export const Delete = styled(MenuOption)`
  height: 30px;
  width: 30px;
  position: relative;
  top: -2px;
  cursor: pointer;
  > img {
    height: 15px;
  }
`;

export const ForwardedInfoText = styled.div`
  display: inline-flex;
  color: ${colors.secondary};
  white-space: nowrap;
  > svg {
    flex: initial important!;
  }
`;

export const ForwardedContainer = styled.div`
  opacity: 1 !important;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.4em;
  overflow: hidden;
  width: 100%;
  > div:last-child > a {
    display: flex;
    align-items: center;
    > span {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      max-width: ${props => (props.width >= 350 ? "300px" : "100px")}!important;
      display: inline-block;
      margin-right: 2em;
    }
  }
`;

export const SelectHighlight = styled.span`
  position: absolute;
  background: ${colors.outline}4d;
  width: 100%;
  height: 100%;
  left: 0;
  top: ${props =>
    props.isSrwMobile ? spacing.space_s : `-${spacing.space_s}`};
  box-sizing: content-box;
  padding: ${props => (props.isSrwMobile ? 0 : "4px")} 0;
`;

export const SelectCircle = styled.div`
  width: 2.4em;
  height: 2.4em;
  position: absolute;
  top: calc(50% - 1.2em);
  left: 0.8em;
`;

export const DeletedMessage = styled.div`
  position: relative;
  display: inline-block;
  padding: 0.8em;
  background-color: ${ifProp(
    "isCurrentUser",
    colors.currentUser,
    colors.brightLight
  )};
  font-style: italic;
  color: ${colors.oblique};
  margin: 1.6em 1.6em 0 1.6em;
  line-height: 1.43;
  box-shadow: 0 1px 2px 0 rgba(42, 42, 42, 0.08);
  border-radius: 0.8em;
  > div {
    bottom: 1em; /* align timestamp for deleted message */
  }
`;

export const HiddenMessage = styled(DeletedMessage)``;

export const UnhideOption = styled.span`
  color: ${colors.active};
  font-weight: bold;
  font-style: initial;
  margin-left: 1.6em;
  cursor: pointer;
`;

export const MessageNotchContainer = styled.div`
  position: absolute;
  left: -16.5px;
  top: -0.3px;
`;

export const ReplyMessageContainer = styled.div`
  position: relative;
  background: ${colors.grey0};
  padding: 0.8em;
  border-radius: 0.2em;
  font-size: 0.88rem;
`;

export const ReplyMessageContent = styled.div`
  font-weight: initial;
  font-size: 0.75rem;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 22px;
  height: 100%;
  top: 0px;
  display: flex;
  align-items: center;
`;

export const EmailReply = styled.div`
  > div {
    background: initial;
  }
`;
