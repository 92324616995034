// @flow

import React from "react";

import MultiSelect from "./MultiSelect";
import ChatroomName from "./Name";

type Props = {
  value: Array<number>,
  multiple: boolean,
  handleClose: Function
};

const Conversation = ({ value, multiple, handleClose }: Props) => {
  if ((value || []).length === 0) {
    return (
      <div>
        <i>No Value</i>
      </div>
    );
  }

  if (multiple && value) {
    return <MultiSelect value={value} handleClose={handleClose} />;
  }

  return (
    <div>
      <ChatroomName id={value[0]} handleClose={handleClose} />{" "}
    </div>
  );
};

export default Conversation;
