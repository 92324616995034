// @flow

import React from "react";

type Props = {
  width?: number,
  height?: number,
  fill?: string
};

const Records = ({ width, height, fill }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width ?? "9"}`}
      height={`${height ?? "8"}`}
      viewBox="0 0 9 8"
      fill="none"
    >
      <path
        d="M3.19987 8L7.99987 8C8.31987 8 8.5332 7.78667 8.5332 7.46667V6.93333C8.5332 6.61333 8.31987 6.4 7.99987 6.4L3.19987 6.4C2.87987 6.4 2.66654 6.61333 2.66654 6.93333V7.46667C2.66654 7.78667 2.87987 8 3.19987 8Z"
        fill={fill ?? "#637381"}
      />
      <path
        d="M3.19987 4.79993L7.99987 4.79993C8.31987 4.79993 8.5332 4.58659 8.5332 4.26659V3.73326C8.5332 3.41326 8.31987 3.19993 7.99987 3.19993L3.19987 3.19993C2.87987 3.19993 2.66654 3.41326 2.66654 3.73326V4.26659C2.66654 4.58659 2.87987 4.79993 3.19987 4.79993Z"
        fill={fill ?? "#637381"}
      />
      <path
        d="M3.19987 1.59998L7.99987 1.59998C8.31987 1.59998 8.5332 1.38664 8.5332 1.06664V0.533309C8.5332 0.213309 8.31987 -2.45571e-05 7.99987 -2.45571e-05L3.19987 -2.45571e-05C2.87987 -2.45571e-05 2.66654 0.213309 2.66654 0.533309V1.06664C2.66654 1.38664 2.87987 1.59998 3.19987 1.59998Z"
        fill={fill ?? "#637381"}
      />
      <path
        d="M0.532943 8H1.06628C1.38628 8 1.59961 7.78667 1.59961 7.46667L1.59961 0.533333C1.59961 0.213333 1.38628 0 1.06628 0H0.532943C0.212943 0 -0.000390768 0.213333 -0.000390768 0.533333L-0.000390768 7.46667C-0.000390768 7.78667 0.212943 8 0.532943 8Z"
        fill={fill ?? "#637381"}
      />
    </svg>
  );
};

export default Records;
