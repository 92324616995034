// @flow

import React from "react";
import * as R from "ramda";

import DateSelect from "src/components/date/Select";
import { FormGroup, Label } from "src/styles/form";

type Props = {
  dueDate: ?string,
  handleDueDate: Function,
  settings?: Object
};

const DueDate = ({ dueDate, handleDueDate, settings }: Props) => {
  const { dueIn } = settings || {};

  const currentDate = new Date();
  let dueDateAsPerSettings = currentDate.setDate(
    currentDate.getDate() + Number(dueIn || "0")
  );
  dueDateAsPerSettings =
    R.isEmpty(dueIn) || R.isNil(dueIn) ? "" : new Date(dueDateAsPerSettings);

  return (
    <FormGroup>
      <Label>Due Date</Label>
      <DateSelect
        value={dueDate ? dueDate : dueDateAsPerSettings}
        placeholder="Choose Date"
        handleChange={handleDueDate}
      />
    </FormGroup>
  );
};

export default DueDate;
