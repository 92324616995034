// @flow

import React, { useState } from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  filled: boolean,
  fillColor?: string,
  size?: number,
  viewBoxSize?: number
};

const Info = ({
  filled,
  fillColor = "#282828",
  size = 24,
  viewBoxSize = 24,
  ...restProps
}: Props) => {
  const [hover, setHover] = useState(false);

  const customStyles = {
    fill: hover ? colors.ink : fillColor
  };

  if (filled) {
    return (
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <path
          d="M8 1.33333C4.31999 1.33333 1.33333 4.32 1.33333 8C1.33333 11.68 4.31999 14.6667 8 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8C14.6667 4.32 11.68 1.33333 8 1.33333ZM8.66666 11.3333H7.33333V7.33333H8.66666V11.3333ZM8.66666 6H7.33333V4.66667H8.66666V6Z"
          fill="black"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...restProps}
    >
      <g
        style={customStyles}
        fill={fillColor}
        fillRule="nonzero"
        transform="matrix(1 0 0 -1 .5 18)"
      >
        <path d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm0-1.2A7.8 7.8 0 1 0 9 1.2a7.8 7.8 0 0 0 0 15.6z" />
        <circle cx="9" cy="13.2" r="1.2" />
        <path d="M8.4 4.2a.6.6 0 1 1 1.2 0v5.4a.6.6 0 1 1-1.2 0V4.2z" />
      </g>
    </svg>
  );
};

export default Info;
