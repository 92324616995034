// @flow

import React from "react";
import * as R from "ramda";

import { VStack, Checkbox, Text, Box } from "@chakra-ui/react";
import Select from "src/components/Select";

type Props = {
  value: number,
  onChange: Function,
  isActive: boolean,
  onActiveChange: Function,
  allStatuses: Array<Object>
};

const findStatusById = (statuses, id): Object =>
  R.find(R.propEq("id", id))(statuses);

export default function NewStatus({
  value,
  onChange,
  isActive,
  onActiveChange,
  allStatuses
}: Props) {
  return (
    <VStack w="100%" alignItems="start">
      <Checkbox
        size="sm"
        isChecked={isActive}
        onChange={e => onActiveChange(e.target.checked)}
      >
        Change status to
      </Checkbox>
      <Box w="100%">
        <Select
          value={value || -1}
          label={findStatusById(allStatuses, value || -1)?.text}
          onChange={onChange}
          placeholder="Select status"
        >
          {allStatuses.map(item => (
            <Text my={0} key={item.id} value={item.id} color={item.textColor}>
              {item.text}
            </Text>
          ))}
        </Select>
      </Box>
    </VStack>
  );
}
