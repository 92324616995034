//@flow
import React from "react";

import {
  WarningTwoIcon,
  CheckCircleIcon,
  SmallCloseIcon
} from "@chakra-ui/icons";

import type { ImportedProcessStatus } from "src/types";
import { bulkImportStatus } from "src/constants";

import { SummaryProcessTitle } from "./styles";

/**
 * Returns a styled status element for the given JSON processs import
 * status
 * @param {ImportedProcessStatus} - A JSON-import status
 */
const getJSONImportStatus = (status: ImportedProcessStatus) => {
  switch (status) {
    // FIXME: replace colors with constants
    case bulkImportStatus.success:
      return <span style={{ color: "#039855" }}>Import successful</span>;
    case bulkImportStatus.failed:
      return <span style={{ color: "#D92D20" }}> Import failed</span>;
    case bulkImportStatus.error:
      return <span style={{ color: "#F79009" }}>Imported with error(s)</span>;
    default:
      return "Unknown";
  }
};

/**
 * Returns styled Chakra icons for the given JSON process import status
 * @param {ImportedProcessStatus} - A JSON-import status
 */
const getJSONImportStatusIcon = (status: ImportedProcessStatus) => {
  switch (status) {
    case bulkImportStatus.success:
      return <CheckCircleIcon color="green.500" />;
    case bulkImportStatus.failed:
      return (
        <SmallCloseIcon color="white" bgColor="red.500" borderRadius="full" />
      );
    case bulkImportStatus.error:
      return <WarningTwoIcon color="yellow.500" />;
    default:
      return <SmallCloseIcon />;
  }
};

const ImportedProcessSummary = ({
  status,
  title
}: {
  status: ImportedProcessStatus,
  title: string
}) => (
  <>
    <SummaryProcessTitle>
      {getJSONImportStatusIcon(status)}
      {title}
    </SummaryProcessTitle>
    {getJSONImportStatus(status)}
  </>
);

export default ImportedProcessSummary;
