// @flow

import { combineReducers } from "redux";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type { Action, Header } from "src/types";

import defaultTypes from "src/constants/conversationTypes";

const height = (state: number = 0, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_HEADER_HEIGHT:
      return payload;
    default:
      return state;
  }
};

const status = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.TOGGLE_STATUS_DROPDOWN:
      return !state;
    case atypes.HIDE_DOCK:
    case atypes.SHOW_DOCK:
      return null;
    default:
      return state;
  }
};

const participants = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_PARTICIPANT_DROPDOWN:
      return state !== null ? null : payload.location;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.CLOSE_CONVERSATION_MODAL:
      return null;
    default:
      return state;
  }
};

const chatroomOverlay = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHATROOM_OVERLAY:
      return state === payload.location ? null : payload.location;
    default:
      return state;
  }
};

const conversationModal = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.HIDE_FILTER_MODAL:
      return false;
    case atypes.SHOW_FILTER_MODAL:
      return true;
    case atypes.TOGGLE_FILTER_MODAL:
      return !state;
    default:
      return state;
  }
};

const searchedConversationTypes = (
  state: Array<number> = R.map(R.prop("id"), defaultTypes),
  { type, payload = {} }: Action
) => {
  switch (type) {
    case atypes.GET_SEARCHED_CONVERSATION_TYPES:
      return payload.searchedConversationTypes;
    default:
      return state;
  }
};

const setInputSearchQuery = (state: string = "", { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CONVERSATION_TYPE_SEARCH:
      return payload.text;
    default:
      return state;
  }
};

const searchedPinnedLists = (state = [], { type, payload = {} }: Action) => {
  switch (type) {
    case atypes.GET_SEARCHED_PINNED_LISTS:
      return payload.result;
    default:
      return state;
  }
};

const navigatedFromHeader = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.NAVIGATED_FROM_HEADER:
      return true;
    case atypes.HIDE_DOCK:
      return false;
    default:
      return state;
  }
};

const header = combineReducers<Object, Action>({
  height,
  status,
  participants,
  conversationModal,
  searchedConversationTypes,
  searchedPinnedLists,
  setInputSearchQuery,
  chatroomOverlay,
  navigatedFromHeader
});

export default header;

export const getHeaderParticipants = (state: Header) => state.participants;

export const getIsNavigatedFromHeader = (state: Header) =>
  state.navigatedFromHeader;
