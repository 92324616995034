// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import { InvitingUser as StyledInvitingUser } from "./styles";
import ButtonLoader from "src/components/ButtonLoader";

import type { UID, AppState } from "src/types";

type Props = {
  text: string,
  uid: UID,
  resendInvite: Array<UID>,
  handleClick: Function
};

const InvitingUser = ({ text, uid, resendInvite, handleClick }: Props) => {
  const loading = R.includes(uid, resendInvite);

  return (
    <StyledInvitingUser loading={loading}>
      <ButtonLoader isLoading={loading} type="button" handleClick={handleClick}>
        {text}
      </ButtonLoader>
    </StyledInvitingUser>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  resendInvite: app.users.resendInvite
});

export default connect(mapStateToProps)(InvitingUser);
