// @flow

import React from "react";

const Contacts = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.84 3.22C17.79 3.1 17.73 2.99 17.66 2.88C17.52 2.67 17.33 2.48 17.12 2.34C17.01 2.27 16.9 2.21 16.78 2.16C16.54 2.06 16.28 2 16 2H15V0H13V2H5V0H3V2H2C1.58 2 1.2 2.13 0.88 2.34C0.67 2.48 0.48 2.67 0.34 2.88C0.27 2.99 0.21 3.1 0.16 3.22C0.0600001 3.46 0 3.72 0 4V18C0 19.1 0.89 20 2 20H16C16.28 20 16.54 19.94 16.78 19.84C16.9 19.79 17.01 19.73 17.12 19.66C17.33 19.52 17.52 19.33 17.66 19.12C17.87 18.8 18 18.41 18 18V4C18 3.72 17.94 3.46 17.84 3.22ZM2 18V4H16V18H2ZM9 11.88C6.97 11.88 3 12.96 3 15.46V17H15V15.47C15 12.96 11.03 11.88 9 11.88ZM5.31 15C6 14.44 7.69 13.88 9 13.88C10.31 13.88 12.01 14.44 12.69 15H5.31ZM9 11C10.65 11 12 9.65 12 8C12 6.35 10.65 5 9 5C7.35 5 6 6.35 6 8C6 9.65 7.35 11 9 11ZM9 7C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9C8.45 9 8 8.55 8 8C8 7.45 8.45 7 9 7Z"
      fill="#C0C9D1"
    />
  </svg>
);

export default Contacts;
