// @flow

import { SystemCSSProperties } from "@chakra-ui/react";
import { grey0 } from "src/styles/constants/colors";

export const modalContent: SystemCSSProperties = {
  borderRadius: "4px",
  minHeight: "255px",
  minWidth: "561px"
};

export const modalHeader: SystemCSSProperties = {
  backgroundColor: grey0,
  borderRadius: "8px 8px 0px 0px",
  minHeight: "56px",
  maxHeight: "56px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 2,
  paddingLeft: 4
};

export const closeButton: SystemCSSProperties = {
  backgroundColor: grey0
};

export const folderInput: SystemCSSProperties = {
  borderRadius: "4px 0px 0px 4px",
  height: "2rem",
  overflow: "scroll"
};

export const folderButton: SystemCSSProperties = {
  height: "2rem",
  width: "6rem",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0px 4px 4px 0px"
};
