// @flow

import type { StackProps, BoxProps, TextProps } from "@chakra-ui/react";

export const container: StackProps = {
  alignItems: "end",
  width: "100%"
};

export const meterContainer: StackProps = {
  width: "100%",
  height: "4px",
  mt: 2
};

export const meter: BoxProps = {
  height: "100%",
  width: "100%",
  flex: 1
};

export const strengthLabel: TextProps = {
  color: "unifize_grey31"
};
