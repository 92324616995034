// @flow

import styled from "@emotion/styled";

import { Cell } from "../styles";
import * as colors from "src/styles/constants/colors";

export const EditButton = styled.button`
  outline: none;
  border: none;
  background-color: ${props =>
    props.selected ? colors.highlight : "transparent"};
  > svg {
    visibility: hidden;
    position: relative;
    top: 0px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }
`;

export const Parent = styled(Cell)`
  justify-content: space-between;
  :hover {
    ${EditButton} {
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
