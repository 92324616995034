// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  hideAutoNumber: boolean,
  handleAutoNumber: Function
};

const DisableAutoNumber = ({ hideAutoNumber, handleAutoNumber }: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="hideAutoNumber"
        checked={hideAutoNumber}
        handleChange={handleAutoNumber}
      />
      Disable auto number
    </Label>
  </InlineFormGroup>
);

export default DisableAutoNumber;
