// @flow

import React from "react";

import { ListItem, Box } from "@chakra-ui/react";
import defaultTypes from "src/constants/conversationTypes";

type Props = {
  id: number,
  handleSelect: Function
};

const DefaultConversationType = ({ id, handleSelect }: Props) => (
  <>
    {defaultTypes.map(val => {
      if (val.id === id) {
        return (
          <ListItem
            key={val.id}
            onClick={() => handleSelect(val.id)}
            py="2"
            px="3"
            cursor="pointer"
            _hover={{
              background: "gray.100"
            }}
          >
            <Box ml="2">
              <strong>{val.title}</strong>
            </Box>
          </ListItem>
        );
      }
    })}
  </>
);
export default DefaultConversationType;
