// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";
import * as shadow from "src/styles/constants/shadow";

import {
  FormGroup as StyledFormGroup,
  SecondaryFooter as StyledFooter,
  ModalFormBody as StyledFormBody,
  InlineFormGroup
} from "src/styles/form";

import Modal from "src/styles/modal";

import { Button as StyledButton, Secondary } from "src/styles/buttons";

const ModalFormBody = styled(StyledFormBody)`
  min-height: fit-content;
`;

const SmallFormGroup = styled(StyledFormGroup)`
  grid-template-columns: 72px 1fr;
  align-items: center;
`;

const Footer = styled(StyledFooter)`
  display: flex;
  justify-content: space-between;
`;

const FormGroup = styled(InlineFormGroup)`
  padding: 0px;
`;

const RoundButton = styled(StyledButton)`
  padding: ${spacing.space_inset_squish_s};
  border: 1px solid ${props => (props.active ? colors.active : colors.disabled)};
  border-radius: ${radius.medium};
  background-color: ${colors.brightLight};
  color: ${props => (props.active ? colors.activeFilter : colors.primary)};
  ${props => props.disabled && `color: ${colors.disabled}`}
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: ${spacing.space_m};
  margin-top: ${spacing.space_m};

  :hover {
    border: 1px solid
      ${props => (props.active ? colors.activeFilter : colors.disabled)};
    box-shadow: none;
  }

  :focus {
    border: 1px solid
      ${props => (props.active ? colors.activeFilter : colors.disabled)};
    box-shadow: none;
  }

  svg:first-of-type {
    margin-right: 0.25em;
    > path {
      fill: ${props => (props.active ? colors.activeFilter : null)};
      ${props => props.disabled && `fill: ${colors.disabled}`}
    }
    > circle {
      fill: ${colors.activeFilter};
      ${props => props.disabled && `fill: ${colors.disabled}`}
    }
  }
`;

const Arrow = styled.div`
  display: flex;
  > svg {
    margin-left: 0.1em;
  }
`;

const ClearSort = styled.div`
  color: ${colors.activeFilter};
  margin-left: 5px;
  display: flex;
`;

const RoundButtonGroup = styled(FormGroup)`
  display: flex;
  width: 100%;
  margin: 0;
  justify-content: space-between;
`;

const Button = styled(StyledButton)`
  max-height: 30px;
  border: 1px solid ${colors.ternary};
  border-radius: none;
  background-color: ${props =>
    props.active ? colors.grey2 : colors.brightLight};
  color: ${props => (props.active ? colors.active : colors.secondary)};
  flex-grow: 1;
  outline: none;
  min-width: 5em;
  text-align: center;
`;

const ButtonPrimary = styled(StyledButton)`
  font-size: 0.88rem;
  font-weight: bold;
  padding: ${spacing.space_m} 1.64em;
  border-radius: ${radius.small};
  border: none;
  outline: none;
  &:hover {
    border: none;
  }
`;

const ButtonSecondary = styled(Secondary)`
  background-color: ${colors.disabled};
  color: ${colors.brightLight};
  font-size: 0.88rem;
  font-weight: bold;
  margin-right: 1.07em;
  padding: ${spacing.space_m} 1.64em;
  border-radius: ${radius.small};
  border: none;
`;

const PopupModal = styled(Modal)`
  > div > .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 50;
  }
  > div > div > .ReactModal__Content {
    overflow: visible !important;
    height: auto;
    width: 333px;
    bottom: auto !important;
    top: 136px !important;
    left: 254px !important;
    border-radius: 8px !important;
  }

  > div > .ReactModal__Content.ReactModal__Content--after-open {
    overflow: visible !important;
  }
`;

const StatusModal = styled(PopupModal)`
  > div > div > .ReactModal__Content {
    overflow: visible !important;
    height: auto;
    width: 333px;
    bottom: auto !important;
    top: 170px !important;
    left: 56px !important;
    border-radius: 8px !important;
  }
`;

const PopupForm = styled.div`
  min-width: 333px;
  min-height: 392px;
  border-radius: ${radius.large};
  background-color: ${colors.brightLight};
  box-shadow: ${shadow.light};
  display: grid;
  z-index: 12;
  h4 {
    font-size: 0.88rem;
    font-weight: bold;
    letter-spacing: 1.35px;
    line-height: 1.14px;
    color: ${colors.primary};
    display: flex;
    margin: 0;
    padding: 2em ${spacing.space_l};
    justify-content: space-between;
    align-items: center;
  }
`;

const StatusForm = styled(PopupForm)`
  min-width: 360px;
  min-height: 313px;
  h4 {
    padding: 1.714em ${spacing.space_l} ${spacing.space_m} ${spacing.space_l};
    line-height: 0;
  }
`;

const SecondaryFooter = styled.div`
  background: ${colors.brightLight};
  display: flex;
  justify-content: flex-end;
  padding: 1.33em;
  box-shadow: ${shadow.top};
  align-self: flex-end;
  border-radius: 0px 0px ${radius.large} ${radius.large};
`;

const PopupFormBody = styled.div`
  display: grid;
`;

const SortItems = styled.div`
  padding: ${spacing.space_m} ${spacing.space_l};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.active ? colors.grey2 : colors.brightLight};
  cursor: pointer;
`;

const Datetext = styled.div`
  font-size: 10px;
  font-weight: ${typography.medium};
  letter-spacing: 1.5px;
`;

const SecondaryText = styled.div`
  color: ${colors.secondary};
  letter-spacing: 0.4px;
`;

const StatusItemWrapper = styled.div`
  border: solid
    ${props =>
      props.active ? `${colors.activeFilter} 2px` : `${colors.secondary} 1px`};
  border-radius: ${radius.medium};
  width: ${props => `${props.homeScreen ? 21.33 : 27.33}em`};
  height: ${props => `${props.homeScreen ? 4.5 : 5.6}em`};
  margin-right: ${props => `${props.homeScreen ? 10 : 0}px`};
  margin-bottom: ${spacing.space_l};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.14em;
  cursor: pointer;
`;

const CustomStatusWrapper = styled(StatusItemWrapper)`
  color: ${props => (props.disabled ? `${colors.disabled}` : `${colors.dark}`)};
  ${props =>
    props.active &&
    `
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${colors.secondary};
`};

  ${props => props.disabled && `border-color : ${colors.disabled};`};
  ${props => props.disabled && `cursor: default`};
  border-radius: ${props => (props.active ? `0px` : `${radius.medium}`)};
  margin-right: ${props => `${props.homeScreen ? 10 : 0}px`};
  padding: 1.14em;
  > div > ${SecondaryText} {
    color: ${props =>
      props.disabled ? `${colors.disabled}` : `${colors.secondary}`};
  }
`;

const StatusBody = styled.div`
  display: grid;
  justify-content: center;
  padding: ${spacing.space_l} ${spacing.space_m} ${spacing.space_l}
    ${spacing.space_l};
  max-height: 280px;
  overflow: auto;

  > div:last-child {
    margin-bottom: 0;
  }
`;

const CustomStatusMainWrapper = styled.div`
  border: ${props =>
    props.active ? `2px solid ${colors.activeFilter}` : "none"};
  border-radius: ${radius.medium};
  margin-right: ${props => `${props.homeScreen ? 10 : 0}px`};
`;

const CustomStatusItemWrapper = styled.div`
  padding: 0em 1.17em;
  display: ${props => (props.active ? "flex" : "none")};
  justify-content: space-between;
  margin-bottom: ${spacing.space_l};
  align-items: center;
  cursor: pointer;
`;

const UnreadCount = styled.div`
  margin-left: 0.4em;
  font-size: 0.75rem;
  color: ${colors.activeFilter};
`;

export {
  SmallFormGroup,
  Footer,
  FormGroup,
  RoundButton,
  RoundButtonGroup,
  ModalFormBody,
  Button,
  PopupModal,
  StatusModal,
  PopupForm,
  SecondaryFooter,
  PopupFormBody,
  SortItems,
  Datetext,
  SecondaryText,
  ButtonPrimary,
  ButtonSecondary,
  StatusItemWrapper,
  StatusBody,
  StatusForm,
  ClearSort,
  Arrow,
  CustomStatusMainWrapper,
  CustomStatusItemWrapper,
  CustomStatusWrapper,
  UnreadCount
};
