// @flow

import React, { useState, useEffect } from "react";

import { Label } from "src/styles/form";
import { Input } from "src/styles/input";
import { DueDaysWrapper } from "./styles";

type Props = {
  handleDueInDays: Function,
  dueIn: ?number
};

const DueInDays = ({ handleDueInDays, dueIn }: Props) => {
  const [days, setDays] = useState(dueIn || null);

  const handleDays = (e: any) => {
    const { value } = e.target;
    setDays(value === "" ? null : value);
  };

  useEffect(() => {
    handleDueInDays(days);
  }, [days]);

  return (
    <DueDaysWrapper>
      <Label>
        SLA in days (Auto fill due date when conversation is created){" "}
      </Label>
      <Input
        placeholder="Days"
        type="number"
        min="0"
        value={days || ""}
        id="days"
        onChange={handleDays}
        autoComplete="off"
      />
    </DueDaysWrapper>
  );
};

export default DueInDays;
