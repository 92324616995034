// @flow

import React from "react";

type Props = {
  fill?: string
};

const Bell = ({ fill = "#888", ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17px"
    height="17px"
    viewBox="0 0 16 16"
    fill="none"
    {...restProps}
  >
    <path
      d="M9.60039 12.4004C9.60039 13.2843 8.88439 14.0003 8.00039 14.0003C7.11639 14.0003 6.40039 13.2843 6.40039 12.4004"
      stroke={fill}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10.7998H14"
      stroke={fill}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1998 10.7996H13.5998C12.7158 10.7996 11.5998 10.0836 11.5998 9.19964V5.59982C11.5998 3.61192 9.9878 2 7.9998 2C6.0118 2 4.3998 3.61192 4.3998 5.59982V9.19964C4.3998 10.0836 3.6838 10.7996 2.7998 10.7996"
      stroke={fill}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bell;
