// @flow

import uuid from "uuid";
import * as R from "ramda";

const defaultParams = {
  sdk: "8.0",
  entry: {
    oneDrive: {
      files: {}
    }
  },
  authentication: {},
  messaging: {
    origin: process.env.BASE_URL
  },
  typesAndSources: {
    mode: "files",
    pivots: {
      recent: true,
      oneDrive: true,
      sharedLibraries: true
    }
  }
};

/**
 * Combines an arbitrary set of paths ensuring and normalizes the slashes
 *
 * @param paths 0 to n path parts to combine
 */
const combine = (...paths) => {
  return paths
    .map(path => path.replace(/^[\\|/]/, "").replace(/[\\|/]$/, ""))
    .join("/")
    .replace(/\\/g, "/");
};

class Picker {
  url = null;
  accessTokens = null;

  win: any = null;
  port: any = null;

  windowListener = null;
  portListener = null;

  focus = () => {
    if (this.win && !this.win?.closed) this.win.focus();
  };

  isActive = () => {
    return Boolean(!this.win?.closed);
  };

  destroy = () => {
    window.removeEventListener("message", this.windowListener);

    if (this.port) {
      this.port.removeEventListener("message", this.portListener);
    }

    if (this.win) {
      this.win.close();
    }
  };

  constructor({
    url,
    accessTokens,
    multiple,
    onPick,
    mode
  }: {
    url: string,
    accessTokens: Object,
    multiple: boolean,
    onPick: Function,
    mode: "files" | "folders" | "all"
  }) {
    this.url = url;
    this.accessTokens = accessTokens;

    const params = R.mergeDeepRight(defaultParams, {
      messaging: {
        channelId: uuid.v4()
      },
      selection: {
        mode: multiple ? "multiple" : "single"
      },
      typesAndSources: {
        mode: mode
      }
    });

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(params)
    });

    const baseUrl = combine(
      url,
      `_layouts/15/FilePicker.aspx?${queryString.toString()}`
    );

    this.win = window.open("", "Picker", "width=800,height=600");

    const form = this.win.document.createElement("form");
    form.setAttribute("action", baseUrl);
    form.setAttribute("method", "POST");
    this.win.document.body.append(form);

    form.submit();

    this.portListener = (message: any) => {
      pickerEventListener({
        message,
        accessTokens,
        onPick,
        win: this.win,
        port: this.port,
        reset: this.destroy
      });
    };

    this.windowListener = (event: any) => {
      if (event.source && event.source === this.win) {
        const message = event.data;

        if (
          message.type === "initialize" &&
          message.channelId === params.messaging.channelId
        ) {
          const port = event.ports[0];

          this.port = port;

          port.addEventListener("message", this.portListener);

          port.start();

          port.postMessage({
            type: "activate"
          });
        }
      }
    };

    window.addEventListener("message", this.windowListener);
  }
}

export default Picker;

const pickerEventListener = ({
  message,
  accessTokens,
  onPick,
  win,
  port,
  reset
}) => {
  if (!win || !port) return;

  switch (message.data.type) {
    case "command": {
      port.postMessage({
        type: "acknowledge",
        id: message.data.id
      });

      const command = message.data.data;

      switch (command.command) {
        case "authenticate": {
          if (typeof accessTokens !== "undefined" && accessTokens !== null) {
            port.postMessage({
              type: "result",
              id: message.data.id,
              data: {
                result: "token",
                token: accessTokens[command.resource]
              }
            });
          } else {
            console.error(
              `Could not get auth token for command: ${JSON.stringify(command)}`
            );
          }
          break;
        }

        case "close": {
          reset();
          break;
        }

        case "pick": {
          onPick(command.items);
          port.postMessage({
            type: "result",
            id: message.data.id,
            data: {
              result: "success"
            }
          });

          break;
        }

        default: {
          console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);

          port.postMessage({
            result: "error",
            error: {
              code: "unsupportedCommand",
              message
            },
            isExpected: true
          });
          break;
        }
      }

      break;
    }
  }
};
