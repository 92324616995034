// @flow

import React from "react";
type Props = {
  stroke?: string
};
const Email = ({ stroke, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="15"
    fill="none"
    viewBox="0 0 19 15"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      stroke={stroke ? stroke : "#2A2A2A"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.3 14.5H1.7c-.662 0-1.2-.523-1.2-1.167V1.667C.5 1.023 1.038.5 1.7.5h15.6c.662 0 1.2.523 1.2 1.167v11.666c0 .644-.538 1.167-1.2 1.167z"
      clipRule="evenodd"
    />
    <path
      stroke={stroke ? stroke : "#2A2A2A"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.9 4l6.6 5.25L16.1 4M2.9 11l1.8-1.75M16.1 11l-1.8-1.75"
    />
  </svg>
);

export default Email;
