// @flow

import { combineReducers } from "redux";
import type {
  Action,
  AppIntegration,
  UnifizeIntegrations,
  ActiveIntegration
} from "src/types";
import * as actions from "src/constants/actionTypes";

export const initialState: Array<AppIntegration> = [];

export const apps = (
  state: Array<AppIntegration> = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case actions.SET_ALL_INTEGRATIONS: {
      return payload;
    }
    case actions.INSTALL_INTEGRATION_SUCCESS: {
      const updatedState: Array<AppIntegration> = state.map(app => {
        if (app.title === payload) {
          return { ...app, installed: true };
        } else {
          return app;
        }
      });
      return updatedState;
    }
    case actions.UPDATE_INTEGRATION_SUCCESS: {
      const { id, response } = payload;
      const updatedState: Array<AppIntegration> = state.map(app => {
        if (app.id === id) {
          return response;
        } else {
          return app;
        }
      });
      return updatedState;
    }
    case actions.DELETE_INTEGRATION_SUCCESS: {
      const { id } = payload;
      const updatedState: Array<AppIntegration> = state.map(app => {
        if (app.id === id) {
          return { ...app, installed: false };
        } else {
          return app;
        }
      });
      return updatedState;
    }
    default:
      return state;
  }
};

const active = (
  state: Array<ActiveIntegration> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case actions.SET_ACTIVE_INTEGRATIONS: {
      const { data } = payload;
      return data;
    }
    case actions.UPDATE_ACTIVE_INTEGRATIONS: {
      const { id, data } = payload;
      const updated = state;
      const index = state.findIndex(integration => integration.id === id);
      if (index !== -1) {
        state[index] = data;
      } else {
        updated.push(data);
      }
      return updated;
    }
    default:
      return state;
  }
};

export const getAppIntegrations = (integrations: UnifizeIntegrations) =>
  integrations.active;

export const getMs365Status = (
  integrations: UnifizeIntegrations
): Array<ActiveIntegration> =>
  integrations.active.filter(app => app.title === "microsoft365") || [];

export const getMs365AdminStatus = (
  integrations: UnifizeIntegrations
): Array<ActiveIntegration> =>
  integrations.active.filter(app => app.title === "microsoft365-admin") || [];

export const getIsMs365Active = (
  integrations: UnifizeIntegrations
): boolean => {
  const integration =
    integrations.active.filter(app => app.title === "microsoft365") || [];
  if (integration.length > 0) {
    return !integration[0].disabled && integration[0].installed;
  } else return false;
};
export const getIsMs365AdminActive = (
  integrations: UnifizeIntegrations
): boolean => {
  const integration =
    integrations.active.filter(app => app.title === "microsoft365-admin") || [];
  if (integration.length > 0) {
    return !integration[0].disabled && integration[0].installed;
  } else return false;
};

const integrations = combineReducers<Object, Action>({ active });

export default integrations;
