// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  hideProcessInNew: boolean,
  handleHidingProcessInNew: Function
};

const InviteSenderToConversation = ({
  hideProcessInNew,
  handleHidingProcessInNew
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="hideProcessInNew"
        checked={hideProcessInNew}
        handleChange={handleHidingProcessInNew}
      />
      Do not show this process in + NEW
    </Label>
  </InlineFormGroup>
);

export default InviteSenderToConversation;
