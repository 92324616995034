// @flow

import * as R from "ramda";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { systemRolesDispOrder } from "src/constants/roleManagement";
import * as atypes from "src/constants/actionTypes";
import type {
  Action,
  RoleManagementState,
  Roles,
  RoleIds,
  ComponentPermissions,
  ComponentPermissionName,
  PermissionsState,
  RoleState,
  Permission
} from "src/types";
import { toKebabCase } from "src/utils";

const role = (
  state: RoleState = {
    allRoles: {
      entities: {
        resourcePermissions: {},
        componentPermissions: {},
        roles: {}
      },
      result: []
    }
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_ALL_ROLES_REQUEST: {
      return state;
    }
    case atypes.GET_ALL_ROLES_SUCCESS:
    case atypes.ADD_ROLE_MEMBER_SUCCESS:
    case atypes.REMOVE_ROLE_MEMBER_SUCCESS:
    case atypes.ADD_CUSTOM_ROLE_SUCCESS:
    case atypes.DELETE_CUSTOM_ROLE_SUCCESS:
      return {
        ...payload
      };

    default:
      return state;
  }
};

const permissions = (
  state: PermissionsState = { allPermissions: [] },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_ALL_PERMISSIONS_REQUEST: {
      return state;
    }
    case atypes.GET_ALL_PERMISSIONS_SUCCESS: {
      return {
        ...payload
      };
    }

    default:
      return state;
  }
};

// Component permissions for current user
const componentPermissions = (
  state: ComponentPermissions = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_COMPONENT_PERMISSIONS_SUCCESS: {
      return payload;
    }
    default:
      return state;
  }
};

const componentPermissionsSynced = (
  state: boolean = false,
  { type }: Action
) => {
  switch (type) {
    case atypes.SYNC_COMPONENT_PERMISSIONS_SUCCESS:
      return true;
    default:
      return state;
  }
};

export const getRolesById = (
  state: RoleManagementState
): { system: RoleIds, custom: RoleIds } => {
  const data = state.role?.allRoles || { entities: {}, result: [] };

  const roleIds = data.result;
  const allRoles = data.entities?.roles || {};

  const result = {
    system: [],
    custom: []
  };

  roleIds.forEach(id => {
    if (allRoles[id].systemRole) {
      result.system.push(id);
    } else {
      result.custom.push(id);
    }
  });

  result.system = result.system.sort(
    (id1, id2) =>
      systemRolesDispOrder.indexOf(allRoles[id1].title) -
      systemRolesDispOrder.indexOf(allRoles[id2].title)
  );

  return result;
};

export const getRoles = (state: RoleManagementState): Roles => {
  const data = state.role?.allRoles || { entities: {}, result: [] };
  const roles = data.entities?.roles || {};

  return roles;
};

export const getAllRolesById = (state: RoleManagementState) => {
  const data = state.role?.allRoles || { entities: {}, result: [] };
  return data.entities;
};

export const getAllRoleIds = (state: RoleManagementState) => {
  const allRoles = state.role?.allRoles;

  return allRoles?.result || [];
};

export const getRoleTitle = (state: RoleManagementState, id: number) => {
  const allRoles = state.role?.allRoles;
  const rolesById = allRoles?.entities?.roles || {};

  return rolesById[id]?.title;
};

export const getRoleTitles = (
  state: RoleManagementState
): Array<{ id: number, title: string, label: string }> => {
  const data = state.role?.allRoles || { entities: {}, result: [] };

  const roleIds = data.result;
  const allRoles = data.entities?.roles || {};

  return roleIds.map(id => ({
    id,
    title: allRoles[id].originalTitle,
    label: allRoles[id].title
  }));
};

export const getPermissions = (state: RoleManagementState): Array<Permission> =>
  state.permissions?.allPermissions || [];

/**
 * First check if the permission exists in the
 * data from firestore. If not look for the
 * permission in the response of /permissions API
 */
export const getComponentPermission = (
  state: RoleManagementState,
  component: ComponentPermissionName
) => {
  if (R.hasPath([component], state.componentPermissions))
    return state.componentPermissions[component];

  const componentPermission = state.permissions.allPermissions.find(
    R.propEq("componentName", toKebabCase(component))
  );

  // If componentPermission does not exist, return true
  return R.path(["inverted"], componentPermission) ?? true;
};

export const getComponentPermissionsSynced = (state: RoleManagementState) =>
  state.componentPermissionsSynced;

// example use: fn(state, "prompt-invite") => ["vendor", "collaborator"]
export const getRolesWithComponentPermission = (
  state: RoleManagementState,
  componentName: string
): string[] => {
  const { result, entities } = state?.role?.allRoles || {
    entities: {},
    result: []
  };

  if (R.isEmpty(entities) || R.isEmpty(result)) {
    return [];
  }

  const componentPermissions: any = Object.values(
    entities?.componentPermissions || {}
  );

  if (componentPermissions.length === 0) {
    return [];
  }

  const { id: componentId } =
    componentPermissions.find(item => item.componentName === componentName) ||
    {};

  if (!componentId) {
    return [];
  }

  return result
    .filter(roleId =>
      entities.roles[roleId].componentPermissions.includes(componentId)
    )
    .map(roleId => entities.roles[roleId].originalTitle);
};

const getRoleIds = (_state: RoleManagementState, roleIds: RoleIds) => roleIds;

export const getMembersByRole = createSelector(
  [getRoles, getRoleIds],
  (roles, roleIds) => {
    if (!roleIds || roleIds.length < 1) return [];

    return roleIds.reduce((members, id) => {
      const role = roles[id];
      if (role) {
        members = members.concat(role.members);
      }
      return members;
    }, []);
  }
);

const roles = combineReducers<Object, Action>({
  role,
  permissions,
  componentPermissions,
  componentPermissionsSynced
});

export default roles;
