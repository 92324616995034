// @flow

import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import Chat from "../Chat";
import SegmentedList from "./index";
import { getWorkflowStatus, getCurrentFilterSortBy } from "src/reducers";
import type { State } from "src/types";

type Props = {
  statuses: Object,
  segmentedStatus: Object,
  currentSortby: string
};

const CustomSegmentStatus = ({
  statuses,
  segmentedStatus,
  currentSortby
}: Props) => {
  const status = currentSortby === "status" ? statuses : R.reverse(statuses);
  const renderSegment = (id: number) => (index: number) => {
    const segmentedChats = segmentedStatus[`${id}`];
    if (segmentedChats) {
      const roomId = segmentedChats[index];
      if (roomId) {
        return <Chat key={roomId} role="listitem" roomId={roomId} />;
      }
    }
  };

  return status.map(item => {
    const count = segmentedStatus[item.id]
      ? segmentedStatus[item.id].length
      : 0;
    return (
      <SegmentedList
        key={item.id}
        title={item.id}
        count={count}
        render={renderSegment(item.id)}
      />
    );
  });
};

const mapStateToProps = ({ app }: State, { workflow }) => ({
  segmentedStatus: app.chatRooms.filters.segmentedStatus.byStatus,
  statuses: getWorkflowStatus(app, workflow),
  currentSortby: getCurrentFilterSortBy(app)
});
export default connect(mapStateToProps)(CustomSegmentStatus);
