// @flow

import { connect } from "react-redux";

import StatusModal from "src/components/chatroom-nav/filter/StatusModal";
import { hideStatusModal, setStandardStatusFilter } from "src/actions/filter";
import { setCheckedStatus } from "src/actions/chatroom";
import { getCustomStatuses } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  statusModal: app.chatRooms.filters.statusModal,
  active: app.chatRooms.filters.current.active,
  isWorkflow: app.chatRooms.filters.current.workflow,
  currentSelectedStatuses: getCustomStatuses(app)
});

export default connect(mapStateToProps, {
  _hideStatusModal: hideStatusModal,
  _setCheckedStatus: setCheckedStatus,
  _setStandardStatusFilter: setStandardStatusFilter
})(StatusModal);
