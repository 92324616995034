// @flow

import { connect } from "react-redux";

import Files from "src/components/chatroom/Files";
import { showDock, showSrwDock } from "src/actions/dock";
import { getChatroomFileCount } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  count: getChatroomFileCount(app, props.roomId)
});

export default connect(mapStateToProps, {
  showDock,
  showSrwDock
})(Files);
