// @flow

import * as R from "ramda";
import { createSelector } from "reselect";

import { getSelectedChecklist, getChatroomVersions } from "src/reducers";

import { roomProps } from "src/constants";
import httpMethods from "src/constants/httpMethods";

export const getCurrentLinkedFieldValue = createSelector(
  [
    getSelectedChecklist,
    getChatroomVersions,
    (_state, value) => value,
    (_state, _value, type) => type,
    (_state, _value, _type, httpMethod) => httpMethod
  ],
  (currentValue, getRoomVersions, payloadValue, type, httpMethod) => {
    let { value: updatedValue } = R.clone(currentValue);
    const { value } = payloadValue;

    if (type === "link") {
      if (httpMethod === httpMethods.patch) {
        if (value.linkAllVersions) {
          const { versions, currentVersionId } = getRoomVersions(
            value.chatroom
          );

          //  - Get all mutual versions of this room
          let versionIds = R.pluck("id", versions);

          //  - Remove them from value
          versionIds.forEach(versionId => {
            updatedValue = R.dissocPath(["entities", "chatrooms", versionId])(
              updatedValue
            );
          });

          versionIds = versionIds.map(item => parseInt(item, 10));
          updatedValue.result = R.without(versionIds, updatedValue.result);

          // 2. add current version
          let currentVersionRoom = versions.find(
            R.propEq("id", String(currentVersionId))
          );
          currentVersionRoom = R.pick(roomProps, currentVersionRoom);
          currentVersionRoom = R.mergeDeepLeft({ fields: [] })(
            currentVersionRoom
          );

          updatedValue = R.assocPath(
            ["entities", "chatrooms", String(currentVersionId)],
            {
              chatroom: currentVersionRoom,
              linkAllVersions: true,
              originChatroomId: currentVersionId
            }
          )(updatedValue);

          // $FlowFixMe - updatedValue.result isn't compatible with the type of variable expected by R.append function
          updatedValue.result = R.append(currentVersionId)(updatedValue.result);
        } else if (!R.isEmpty(value.chatroom)) {
          const { versions } = getRoomVersions(value.chatroom);

          const versionIds = versions.map(item => parseInt(item.id, 10));

          // Remove existing versions in `updatedValue
          const existingVersionIds = R.intersection(
            updatedValue.result,
            versionIds
          );

          existingVersionIds.forEach(versionId => {
            updatedValue = R.dissocPath([
              "entities",
              "chatrooms",
              String(versionId)
            ])(updatedValue);
          });

          updatedValue.result = R.without(
            existingVersionIds,
            updatedValue.result
          );

          // Add new versions in `value`
          updatedValue.result = R.concat(updatedValue.result, value.chatroom);

          value.chatroom.forEach(roomId => {
            let room = versions.find(R.propEq("id", String(roomId)));
            room = R.pick(roomProps, room);
            room = R.mergeDeepLeft({ fields: [] })(room);

            updatedValue = R.assocPath(
              ["entities", "chatrooms", String(roomId)],
              {
                chatroom: room,
                linkAllVersions: false,
                originChatroomId: roomId
              }
            )(updatedValue);
          });
        }
      } else if (httpMethod === httpMethods.delete) {
        const { originChatroomId } = value;

        // Remove `originChatroomId` from `updatedValue.result`
        updatedValue.result = R.without([originChatroomId])(
          updatedValue.result
        );

        // Remove `originChatroomId` from `updatedValue.entities.chatroom`
        updatedValue = R.dissocPath([
          "entities",
          "chatrooms",
          String(originChatroomId)
        ])(updatedValue);
      }
    } else {
      updatedValue = value;
    }

    return updatedValue;
  }
);
