// @flow

import * as R from "ramda";
import React, { Fragment } from "react";

import NameOrEmail from "src/components/user/NameOrEmailText";
import Group from "src/components/Group/Name";
import { ME_FILTER } from "src/constants/users";
import type { UID, GroupId, Email } from "src/types";

type Props = {
  value: any
};

const User = ({ value }: Props) => {
  if (value && R.type(value) === "Array") {
    return (
      <>
        {value.map((uid: UID | GroupId | Email, index: number) => (
          <Fragment key={index}>
            {index !== 0 ? "," : ""}
            {typeof uid === "string" &&
              (uid.includes("me-") || uid === ME_FILTER ? (
                "Me"
              ) : (
                <NameOrEmail uid={uid} />
              ))}
            {typeof uid === "number" && <Group id={uid} />}
          </Fragment>
        ))}
      </>
    );
  }

  if (value) {
    return value.includes("me-") || value === ME_FILTER ? (
      "Me"
    ) : (
      <NameOrEmail uid={value} />
    );
  }

  return <></>;
};

export default User;
