// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import File from "./File";
import { OldValue, NewValue, ValueList, Name } from "../styles";
import { FileData } from "src/components/Dock/Checklist/styles";
import {
  getFilteredFiles,
  getCurrentRoomId,
  getConversationModalId
} from "src/reducers";
import { downloadFile } from "src/actions/file";
import location from "src/constants/location";
import type { UnifizeFile } from "src/types";

type Props = {
  oldValue: Array<string>,
  newValue: ?Array<string>
};

const Files = ({ newValue = [], oldValue }: Props) => {
  const dispatch = useDispatch();
  const oldFiles: ?Array<UnifizeFile> | [] = useSelector(({ app }) =>
    getFilteredFiles(app, oldValue)
  );
  const oldFilenames =
    oldFiles && Array.isArray(oldFiles) && oldFiles.length !== 0
      ? oldFiles.map(ofile => ofile && ofile.originalName)
      : null;
  const modal = useSelector(({ app }) => app.conversationModal.show);
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const modalId = useSelector(({ app }) => getConversationModalId(app));

  const handleDownload = (file: Object) => {
    dispatch(
      downloadFile({
        roomId: modal ? modalId : roomId,
        location: modal ? location.conversationDialog : location.chatHistory,
        name: file.name,
        originalName: file.originalName,
        generation: file.generation,
        versionId: file.versionId
      })
    );
  };

  return (
    <>
      <OldValue>
        <ValueList>
          {oldFilenames &&
            oldFilenames.map(
              (of, i) =>
                of && (
                  <FileData key={of + i}>
                    <Name>{of}</Name>
                  </FileData>
                )
            )}
        </ValueList>
      </OldValue>

      <NewValue>
        <ValueList>
          {newValue &&
            newValue.map(fileId => (
              <File key={fileId} handleDownload={handleDownload} id={fileId} />
            ))}
        </ValueList>
      </NewValue>
    </>
  );
};

export default Files;
