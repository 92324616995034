// @flow

import React from "react";

import AttachRooms from "src/components/chatroom/SelectMultiple.old";
import { FormGroup, Label } from "src/styles/form";

type Props = {
  notificationGroups: Array<number>,
  handleNotifyGroups: Function
};

const NotifyGroups = ({ notificationGroups, handleNotifyGroups }: Props) => (
  <FormGroup>
    <Label>Notify Groups</Label>
    <AttachRooms
      placeholder="Select conversation"
      value={notificationGroups}
      handleChange={handleNotifyGroups}
    />
  </FormGroup>
);

export default NotifyGroups;
