// @flow

import * as React from "react";

import Loader from "./Loader";

import { BaseButton } from "./styles";

type Props = {
  isLoading?: boolean,
  type: string,
  children?: any,
  disabled?: boolean,
  handleClick?: Function,
  dataCy?: string
};

const ButtonLoader = ({
  isLoading = false,
  type,
  children = null,
  disabled = false,
  handleClick = () => {},
  dataCy = ""
}: Props) =>
  isLoading ? (
    <Loader />
  ) : (
    <BaseButton
      data-cy={dataCy}
      type={type}
      disabled={disabled}
      onClick={handleClick}
    >
      {children || null}
    </BaseButton>
  );

export default ButtonLoader;
