// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import {
  Header,
  Title as StyledTitle,
  Creator as StyledCreator,
  LoadingContainer as StyledLoadingContainer
} from "./styles";
import User from "src/containers/user/NameOrEmail";
import {
  Dot1,
  Dot2,
  Dot3,
  Loader as StyledLoader
} from "src/components/ButtonLoader/styles";

import { getDashboard } from "src/reducers";

import type { AppState, Dashboard } from "src/types";

type Props = {
  id: number,
  dashboard: Dashboard
};

const Loader = ({ dashboard }: Props) => (
  <div>
    {dashboard && !R.isEmpty(dashboard) && (
      <Header>
        <StyledTitle>{dashboard.title}</StyledTitle>
        <StyledCreator>
          Created by: {dashboard.creator && <User uid={dashboard.creator} />}
        </StyledCreator>
      </Header>
    )}

    <StyledLoadingContainer>
      <StyledLoader>
        <Dot1 />
        <Dot2 />
        <Dot3 />
      </StyledLoader>
    </StyledLoadingContainer>
  </div>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  dashboard: getDashboard(app, props.id)
});

export default connect(mapStateToProps)(Loader);
