// @flow

import React from "react";

import ProcessRow from "./ProcessRow";
import { Processes as StyledProcesses, ProcessHeader } from "./styles";
import VirtualList from "src/components/VirtualList";

import type { UnifizeChatRoom } from "src/types";

type Props = {
  process: Array<UnifizeChatRoom>
};

const Processes = ({ process }: Props) => {
  const renderRow = (index: number) => {
    const item = process[index];
    if (item) {
      return <ProcessRow key={item.id} conversation={item} />;
    }
  };

  return (
    <StyledProcesses>
      <ProcessHeader>
        <tr>
          <th>Process Name</th>
          <th>Participants</th>
          <th>Owner</th>
          <th>Date Created</th>
          <th>Date Completed</th>
        </tr>
      </ProcessHeader>
      <tbody>
        <VirtualList
          initialAmount={50}
          progressiveAmount={50}
          role="list"
          renderItem={renderRow}
          renderLoader={() => <div />}
          rowCount={process.length}
        />
      </tbody>
    </StyledProcesses>
  );
};

export default Processes;
