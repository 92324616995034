// @flow

import React, { useState } from "react";
import * as R from "ramda";
import { Box, Text, Flex } from "@chakra-ui/react";

import Icon from "src/icons";
import * as styles from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/styles.js";
import Tooltip from "src/components/Tooltip";
import * as colors from "src/styles/constants/colors";
import {
  checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";

import type { DownshiftProps } from "downshift";
import type { OnItemClick } from "src/components/Dropdown";
import type { ChartField } from "src/types";

type Props = {
  selectedField: ChartField,
  isPrimaryField?: boolean,
  isComputation?: boolean,
  isGroupBy?: boolean,
  seriesId?: number | string,
  chartDetails: Object,
  item: ChartField,
  onItemClick?: OnItemClick,
  highlightedIndex: number,
  index: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const DropdownRow = ({
  selectedField,
  isPrimaryField,
  isComputation,
  isGroupBy,
  seriesId,
  chartDetails,
  item,
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView
}: Props) => {
  const {
    name,
    type,
    multiValue,
    linkedProcessOrForm,
    linkedProcessOrFormType,
    linkedOrFormFieldName
  } = item;

  const { isMultiValue } = chartDetails;

  const currentSeries = chartDetails.series.find(item => {
    if (item.seriesId) {
      return item.seriesId === seriesId;
    } else {
      return item.tempId === seriesId;
    }
  });

  const someMultiValueSeries = chartDetails.series.some(
    item => item?.seriesMultiValue
  );
  const disablePrimaryField = someMultiValueSeries && multiValue;

  const multivalueSeriesRow = currentSeries?.seriesMultiValue || isMultiValue;
  const disableSeriesField = multivalueSeriesRow && multiValue;

  const currentMultiValueField =
    (isPrimaryField && selectedField?.multiValue) ||
    (isComputation && selectedField?.multiValue) ||
    (isGroupBy && selectedField?.multiValue);

  const disableRow = currentMultiValueField
    ? false
    : isPrimaryField
    ? disablePrimaryField
    : disableSeriesField;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const activeIndex =
    (highlightedIndex !== null && highlightedIndex === index) ||
    (highlightedIndex === null && index === 0);

  return (
    <Flex
      sx={
        disableRow
          ? styles.DisableDropdownItem
          : activeIndex
          ? styles.ActiveDropdownItem
          : styles.InActiveDropdownItem
      }
      ref={highlightedIndex === index ? scrollIntoView : null}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onItemClick && onItemClick({ id: item.id, item })}
    >
      <Text sx={styles.FieldName} isTruncated>
        {name}
      </Text>
      {(activeIndex || isHovered) && (
        <>
          {type && (
            <Text sx={styles.FieldType} isTruncated>
              <Icon
                type={
                  type === "primaryRecord"
                    ? "primaryRecord"
                    : checklistFieldIcons[type].icon
                }
                width="16px"
                height="16px"
                fill={colors.blue200}
              />
              <Box as="span">
                {type === "primaryRecord"
                  ? "Primary Record"
                  : checklistFieldNames[type]}
              </Box>
            </Text>
          )}

          <Text sx={styles.FieldDetail} isTruncated>
            {multiValue ? "Multiple Values" : ""}
          </Text>

          {R.isEmpty(linkedProcessOrForm) ? (
            <Text sx={{ my: 0 }} isTruncated>
              {linkedProcessOrForm}
            </Text>
          ) : (
            <Tooltip
              sx={{ backgroundColor: "none" }}
              title={linkedProcessOrForm}
              placement="top-start"
            >
              <Text sx={styles.FieldType} isTruncated>
                <Icon
                  type={
                    linkedProcessOrFormType === "form" ? "layout" : "anchor"
                  }
                  width="16px"
                  height="16px"
                  fill={colors.blue200}
                />

                <Box as="span">{linkedProcessOrForm}</Box>
              </Text>
            </Tooltip>
          )}

          {R.isEmpty(linkedOrFormFieldName) ? (
            <Text sx={styles.FieldType}>{linkedOrFormFieldName}</Text>
          ) : (
            <Tooltip
              sx={{ backgroundColor: "none" }}
              title={linkedOrFormFieldName}
              placement="top-start"
            >
              <Text sx={styles.FieldType}>
                <Icon type="commonChecklist" stroke={colors.blue200} />

                <Box as="span" isTruncated>
                  {linkedOrFormFieldName}
                </Box>
              </Text>
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};

export default DropdownRow;
