// @flow

import React, { memo } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { DirectChat as StyledDirectChat, Private } from "./styles";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { getCurrentUserId } from "src/reducers";
import * as colors from "src/styles/constants/colors";

import type { AppState, UID } from "src/types";

type Props = {
  title: string,
  currentUser: UID
};

const DirectChat = ({ title, currentUser }: Props) => {
  const users = (title || "").split(",") || [];
  const uid = R.head(R.reject(R.equals(currentUser), users) || []);

  if (users.length === 1) {
    return (
      <StyledDirectChat>
        <NameOrEmail hashColor={colors.active} uid={users[0]} />
      </StyledDirectChat>
    );
  }

  if (uid) {
    return (
      <StyledDirectChat>
        <NameOrEmail hashColor={colors.active} uid={uid} />
      </StyledDirectChat>
    );
  }

  return <Private> from private conversation</Private>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps)(memo<Props>(DirectChat));
