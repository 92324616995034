// @flow

import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import { SearchResult } from "./styles";
import ImageLabel from "src/containers/user/ImageLabel/Medium";

import type { UID } from "src/types";

type Props = {
  focus?: boolean,
  item: UID,
  handleSelect: Function
};

class Item extends Component<Props> {
  static defaultProps = {
    focus: false
  };

  componentDidUpdate(prevProps: Props) {
    const { focus } = this.props;
    if (prevProps.focus !== focus) {
      if (focus) {
        // eslint-disable-next-line react/no-find-dom-node
        const target = findDOMNode(this);
        // $FlowFixMe
        target.parentNode.scrollTop = target.offsetTop - 5;
      }
    }
  }

  selectItem = (e: any) => {
    const { item, handleSelect } = this.props;
    e.stopPropagation();
    e.preventDefault();
    handleSelect(item);
  };

  render() {
    const { focus, item } = this.props;
    return (
      <SearchResult
        tabIndex="0"
        onClick={this.selectItem}
        onKeyDown={this.selectItem}
        focus={focus}
        aria-selected={focus}
      >
        <ImageLabel uid={item} />
      </SearchResult>
    );
  }
}

export default Item;
