// @flow

import React from "react";

import { Selection as StyledSelection, CloseButton } from "./styles";
import type { ChecklistField } from "src/types";
import Icon from "src/icons";

type Props = {
  value: ?number,
  removeField: Function,
  field: ChecklistField
};

const Selection = ({ value, removeField, field }: Props) => {
  if (!value) {
    return (
      <StyledSelection data-cy="selectEmbeddedField">
        <div>Select checklist field</div>
      </StyledSelection>
    );
  }

  return (
    <StyledSelection data-cy="selectEmbeddedField">
      <span>{field ? field.get("label") : "Deleted field"}</span>
      <CloseButton type="button" onClick={() => removeField(value)}>
        <Icon type="close" />
      </CloseButton>
    </StyledSelection>
  );
};

export default Selection;
