// @flow

import React from "react";

import SelectMultipleInvite from "src/components/user/SelectMultipleInvite";
import { FormGroup, Label } from "src/styles/form";

import type { UID, Email, GroupId } from "src/types";

type Props = {
  members: Array<UID | Email>,
  groups: Array<GroupId>,
  handleMembers: (UID | Email) => void,
  handleGroups?: GroupId => void
};

const Members = ({ members, groups, handleMembers, handleGroups }: Props) => {
  return (
    <FormGroup>
      <Label>Participants</Label>
      <SelectMultipleInvite
        members={members}
        groups={groups}
        handleMembers={handleMembers}
        handleGroups={handleGroups}
        dropdownPosition="absolute"
        hideEmailInvite
      />
    </FormGroup>
  );
};

export default Members;
