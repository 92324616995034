// @flow

import React from "react";

import Type from "./Type";
import { Header, SubHeader, Email as StyledEmail } from "./styles";

const Email = () => (
  <StyledEmail>
    <Header>Email Notifications</Header>
    <SubHeader>Select your prefered notification settings</SubHeader>
    <Type />
  </StyledEmail>
);

export default Email;
