// @flow

import { connect } from "react-redux";
import React from "react";

import Loader from "../Loader";
import ChildRow from "./ChildRow";
import { getNestedRows } from "src/reducers";
import { getChildConversations } from "src/actions/chatroom";

import type { AppState, WorkflowInstances } from "src/types";

type Props = {
  width: String,
  columnSize: string,
  depth: number,
  parentId: number,
  rowSize: number,
  numberOfColumns: number,
  parentTitle: string,
  nestedRows: Array<WorkflowInstances>,
  processColumns: Array<{ key: string, active: boolean }>
};

const Row = ({
  width,
  columnSize,
  nestedRows,
  parentId,
  depth,
  rowSize,
  parentTitle,
  processColumns,
  numberOfColumns
}: Props) => {
  if (!nestedRows) {
    return (
      <Loader
        loading
        rowSize={rowSize || 0}
        columnSize={columnSize}
        numberOfColumns={numberOfColumns}
      />
    );
  }

  return (
    <>
      {nestedRows.map((process, index) => (
        <ChildRow
          width={width}
          key={process.chatroomId}
          process={process}
          parentTitle={parentTitle}
          numberOfColumns={numberOfColumns}
          parentId={parentId}
          processColumns={processColumns}
          columnSize={columnSize}
          depth={depth}
          index={index}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  nestedRows: getNestedRows(app, `${props.parentId}`)
});

export default connect(mapStateToProps, {
  _getChildConversations: getChildConversations
})(Row);
