// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";

export const Search = styled.div`
  position: absolute;
  top: ${props => (props.positionUp ? "initial" : "calc(100% + 2px)")};
  bottom: ${props => (props.positionUp ? "calc(100% + 2px)" : "initial")};
  background: ${colors.brightLight};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: ${radius.small};
  width: 100%;
  z-index: 1;
`;

export const Item = styled.div`
  width: 100%;
  height: ${spacing.space_xl};
  padding: 0 ${spacing.space_m};
  display: flex;
  align-items: center;
  font-size: 0.88rem;
  line-height: 1.5rem;
  cursor: pointer;
  :hover {
    background: ${colors.grey0};
  }
`;

export const Result = styled(Item)`
  > span {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const NoResult = styled(Result)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.secondary};
  cursor: initial;
  :hover {
    background: initial;
  }
`;

export const RemoveOption = styled(Item)`
  color: ${colors.secondary};
`;

export const ListContainer = styled.div`
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
`;

export const CreateNewButton = styled.button`
  width: 100%;
  height: ${spacing.space_xl};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.active};
  cursor: pointer;
  font-size: 0.88rem;
  font-weight: ${typography.semiBold};
  line-height: 24px;
  border-top: 1px solid ${colors.grey2};
  outline: none;
  background: ${colors.brightLight};
  :hover {
    background: ${colors.active};
    color: ${colors.brightLight};
    > span > span {
      color: ${colors.brightLight};
    }
  }
  > span {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CreateNewConv = styled.span`
  color: ${colors.primary};
`;

export const CreateNewState = styled.button`
  width: 100%;
  height: ${spacing.space_xl};
  background: ${colors.active};
  color: ${colors.brightLight};
  font-size: 0.88rem;
  line-height: ${spacing.space_l};
  font-weight: ${typography.semiBold};
  outline: none;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
