// @flow

import styled from "@emotion/styled";
import { switchProp, ifProp } from "styled-tools";
import { Box } from "src/styles/box";

import * as size from "src/styles/constants/size";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";
import appLocation from "src/constants/location";
import { RelativeFlexBox } from "src/styles/box";

export const AddNewUser = styled.div`
  position: relative;
  > input {
    border: 1px solid ${colors.grey4};
  }
`;

export const AddNewUserButton = styled.button`
  position: absolute;
  display: block;
  width: 100%;
  padding: 0.5em;
  outline: none;
  border: 1px solid ${colors.active};
  cursor: pointer;
  background-color: ${colors.active};
  color: ${colors.brightLight};
  :disabled {
    border: 1.5px solid ${colors.secondary};
    cursor: not-allowed;
    background-color: ${colors.brightLight};
    color: ${colors.secondary};
  }
`;

export const Invite = styled(Box)`
  cursor: pointer;
  text-align: center;
  border: 2px solid ${colors.active};
  padding: 0.5em 0.4em;
  > button {
    font-weight: bold;
    display: flex;
    margin: auto;

    > strong {
      padding-left: 0.2em;
      max-width: 14em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      color: ${colors.primary};
    }
  }
`;

export const StyledAddNewUser = styled(AddNewUser)`
  top: 1.5em;
`;

const Small = styled.div`
  position: relative;
  padding-right: ${spacing.space_xs};
  cursor: pointer;
  width: 100%;
  ${props =>
    props.maxWidth && `max-width: ${props.maxWidth}; overflow-x: hidden`}
  > span {
    display: flex;
    align-items: center;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const Options = styled.div`
  position: absolute;
  width: ${size.menuWidth};
  border-radius: ${radius.small};
  top: -2px;
  z-index: 2;
  > div,
  input {
    border-radius: ${radius.small};
  }
  > input {
    margin: ${spacing.space_stack_xs};
    outline: 1px solid ${colors.grey5};
    border-radius: ${radius.small};
  }
`;

const CurrentOwner = styled.div`
  > div > span {
    margin-right: 0px;
    max-width: 11.5em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export { Options, CurrentOwner, Small };

export const OwnerWrapper = styled.div`
  width: 110px;
  display: flex;
  color: ${colors.secondary};
  > svg {
      margin-right: ${spacing.space_s};
    }
  }
  > div {
    color: ${props => (props.active ? colors.primary : colors.secondary)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const RelativeBoxWrapper = styled(RelativeFlexBox)`
  > div:first-of-type > div {
    display: flex;
    align-items: center;
  }
`;

export const Owner = styled.div`
  position: ${props => (props.isSrwMobile ? "initial" : "relative")};
  padding: ${props =>
    (props.location === appLocation.chatroomHeader ||
      props.location === appLocation.activityPanel) &&
    "0.25rem"};
  border-radius: 0.25rem;
  width: ${props => props.location === appLocation.activityPanel && "100%"};
  background: ${props => (props.isActive ? colors.grey32 : "none")};
  :hover {
    background: ${colors.grey32};
  }
  :focus {
    background: ${colors.grey32};
    border-radius: 0.25rem;
  }
`;

export const MobileDropdown = styled.div`
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  > div {
    position: initial;
  }
`;

export const PillContainer = styled.div`
  cursor: pointer;
`;

export const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: ${props => (props.isSrwMobile ? "80vw" : "252px")};
  z-index: 4;
  top: ${ifProp(
    { position: "activity-panel" },
    "25px",
    switchProp("position", {
      "activity-panel": props => props.top,
      message: "45px"
    })
  )};

  bottom: ${ifProp(
    { position: "activity-panel" },
    "none",
    switchProp("position", {
      "activity-panel": props => props.bottom,
      message: "auto"
    })
  )};

  right: ${switchProp("position", {
    "activity-panel": "-64px",
    message: "auto"
  })};
  left: ${ifProp(
    { position: "activity-panel" },
    props => (props.rightOffset > 0 ? props.rightOffset - 100 + "px" : "-60px"),
    "none"
  )};
`;

export const Search = styled.input`
  background: ${colors.brightLight};
  border: 1px solid ${colors.secondary};
  border-radius: ${radius.small};
  padding: ${spacing.space_m};
  margin-bottom: ${spacing.space_s};
`;

export const SearchDropdown = styled.div`
  background: ${colors.brightLight};
  border: 1px solid rgba(205, 210, 217, 0.5);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: ${radius.small};
  padding-top: 0.7em;
  padding-bottom: ${spacing.space_m};
`;

export const SearchHeader = styled.div`
  font-weight: ${typography.medium};
  font-size: 10px;
  text-transform: uppercase;
  line-height: ${spacing.space_l};
  letter-spacing: 1.5px;
  padding: 0 ${spacing.space_l};
  margin-bottom: ${spacing.space_s};
`;

export const SearchResults = styled.div`
  max-height: ${props => (props.isSrwMobile ? "40vh" : "209px")};
  overflow-y: auto;
`;

export const AddButton = styled.button``;

export const Remove = styled.button`
  display: block;
  outline: none;
  background-color: ${colors.grey0};
  color: ${colors.secondary};
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-bottom: 0.5em;
  padding: 0.5em 0.6em;
  > svg {
    height: 1.5em;
    width: 1.5em;
    margin-left: 0.5em;
    > path {
      fill: ${colors.grey6};
    }
  }
`;
