// @flow

import React from "react";
import * as R from "ramda";
import { Text, Box } from "@chakra-ui/react";

import * as styles from "../styles";

import type { DownshiftProps } from "downshift";
import type { OnItemClick } from "src/components/Dropdown";

type Props = {
  item: {
    id: string,
    operation: string,
    label: string,
    subLabel: string
  },
  onItemClick?: OnItemClick,
  highlightedIndex: number,
  index: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const SummaryTypeDropdownRow = ({
  item,
  onItemClick,
  highlightedIndex,
  index
}: Props) => {
  return (
    <Text
      onClick={() => onItemClick && onItemClick({ id: item.id, item })}
      sx={
        (highlightedIndex !== null && highlightedIndex === index) ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveDropdownItem
          : styles.InActiveDropdownItem
      }
    >
      <Box as="span" sx={{ pr: "0.5rem" }}>
        {item.label}
      </Box>
      {!R.isEmpty(item.subLabel) && (
        <Box sx={styles.LightGreyText} as="span">
          ({item.subLabel})
        </Box>
      )}
    </Text>
  );
};

export default SummaryTypeDropdownRow;
