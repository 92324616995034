// @flow

import * as atypes from "src/constants/actionTypes";
import type { Action, RoomId, FieldId } from "src/types";

export const removePromptField = (roomId: RoomId, fieldId: number): Action => ({
  type: atypes.REMOVE_PROMPT_FIELD,
  payload: { roomId, fieldId }
});

export const refetchPromptFields = (roomId: RoomId): Action => ({
  type: atypes.REFETCH_PROMPT_FIELD,
  payload: { roomId }
});

export const dismissPrompt = (room: RoomId, field: FieldId): Action => ({
  type: atypes.DISMISS_PROMPT_REQUEST,
  payload: { room, field }
});

export const undoDismiss = (room: RoomId): Action => ({
  type: atypes.UNDO_DISMISS_PROMPT_REQUEST,
  payload: room
});
