// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import type { FieldId, DataStage, RoomId } from "src/types";
import {
  getFirstChecklist,
  getChecklistFieldsBySections,
  getChecklistFieldsById,
  getRoomFieldValueStatus,
  getNavigateUserToNewRevision
} from "src/reducers";
import {
  getFilteredFields,
  getMetaDataInitialState,
  metaDataLabels,
  getChecklistFieldsToCopyInitialState
} from "./utils";
import { pickTruthyKeys } from "src/utils";

import Button from "src/components/Unifize/Button";

import {
  Text,
  Button as ChakraButton,
  Modal as ChakraModal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  HStack,
  VStack,
  Checkbox,
  Textarea
} from "@chakra-ui/react";
import ChecklistFieldsSelectionModal from "./ChecklistFieldsSelectionModal";
import * as styles from "./styles";

type Props = {
  open: boolean,
  onClose: Function,
  onSuccess: Function,
  defaultCopyableFields: FieldId[],
  roomId: RoomId,
  fieldId: FieldId
};

function Modal({
  open,
  onClose,
  onSuccess,
  defaultCopyableFields,
  roomId,
  fieldId
}: Props) {
  const [selectFieldsToCopy, setSelectFieldsToCopy] = useState(false);
  const [comment, setComment] = useState("");
  const [metaData, setMetaData] = useState(getMetaDataInitialState());
  const [copyChecklistData, setCopyChecklistData] = useState(true);

  const { fieldsBySection, allFieldsById } = useSelector(({ app }) => {
    const { id: checklistId } = getFirstChecklist(app);
    const fieldsBySection = getChecklistFieldsBySections(app).get(
      `${checklistId}`
    );
    const allFieldsById = getChecklistFieldsById(app);

    return { fieldsBySection, allFieldsById };
  });

  const fieldValueStatus: DataStage = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const navigateToNewRevision: boolean = useSelector(({ app }) =>
    getNavigateUserToNewRevision(app, fieldId)
  );

  const filteredFields = getFilteredFields(fieldsBySection, allFieldsById);

  const [checklistFieldsToCopy, setChecklistFieldsToCopy] = useState(
    getChecklistFieldsToCopyInitialState(
      filteredFields,
      defaultCopyableFields,
      allFieldsById
    )
  );

  const handleMetaDataChange = e => {
    setMetaData(prev => ({ ...prev, [e.target.id]: e.target.checked }));
  };

  const handleChecklistFieldsToCopyChange = e => {
    setChecklistFieldsToCopy(prev => ({
      ...prev,
      [e.target.id]: e.target.checked
    }));
  };

  const createRevision = () => {
    onSuccess({
      comment,
      clone: {
        metadata: pickTruthyKeys(metaData),
        checklistFields: pickTruthyKeys(checklistFieldsToCopy).map(fieldId =>
          Number(fieldId)
        )
      }
    });

    // If the user has "Navigate to new revision upon creation" option
    // enabled then close the modal only after the field has been
    // updated
    if (navigateToNewRevision) {
      if (fieldValueStatus > 3) {
        onClose();
      }
    } else {
      // Close the modal immediately
      onClose();
    }
  };

  useEffect(() => {
    setChecklistFieldsToCopy(prev => {
      const newFields = {};
      Object.keys(prev).forEach(fieldId => {
        newFields[fieldId] = defaultCopyableFields.includes(Number(fieldId))
          ? copyChecklistData
          : false;
      });
      return newFields;
    });
  }, [copyChecklistData]);

  if (selectFieldsToCopy) {
    return (
      <ChecklistFieldsSelectionModal
        checklistFields={allFieldsById.toJS()}
        fieldsToCopy={checklistFieldsToCopy}
        fieldValueStatus={fieldValueStatus}
        onFieldSelect={handleChecklistFieldsToCopyChange}
        open
        onClose={onClose}
        onSuccess={createRevision}
        onBack={() => setSelectFieldsToCopy(false)}
      />
    );
  }

  return (
    <ChakraModal
      isOpen={open}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalHeader {...styles.modalHeader}>Create new revision</ModalHeader>

        <ModalBody {...styles.modalBody}>
          <VStack {...styles.metadataList}>
            <Text {...styles.label1}>
              Select the data to copy to new revision
            </Text>
            {Object.keys(metaData).map(key => (
              <Checkbox
                isChecked={metaData[key]}
                key={key}
                id={key}
                onChange={handleMetaDataChange}
              >
                {metaDataLabels[key]}
              </Checkbox>
            ))}
            <HStack justifyContent="space-between" width="100%">
              <Checkbox
                isChecked={copyChecklistData}
                onChange={({ target }) => setCopyChecklistData(target.checked)}
              >
                Checklist and form data
              </Checkbox>
              <ChakraButton
                {...styles.link}
                onClick={() => setSelectFieldsToCopy(true)}
              >
                Select fields
              </ChakraButton>
            </HStack>
          </VStack>

          <VStack {...styles.commentContainer}>
            <Text {...styles.label1}>Revision notes</Text>
            <Text {...styles.label2}>Why are you making this revision?</Text>
            <Textarea
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Enter optional comment here."
              {...styles.textarea}
            />
          </VStack>
        </ModalBody>

        <HStack {...styles.modalFooter}>
          <Button
            isLoading={fieldValueStatus === 1 || fieldValueStatus === 3}
            variant="uBlueFilled"
            handleClick={createRevision}
          >
            Create revision
          </Button>
          <ChakraButton variant="uSecondary" onClick={onClose}>
            Cancel
          </ChakraButton>
        </HStack>
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
