// @flow

import * as atypes from "src/constants/actionTypes";
import type { ColumnId } from "src/types";

/**
 * Creates action for updating the column width
 * @param {string} columnId - column id of the column
 * @param {string} width - updated width of the column
 */
export const updateColumnWidth = (columnId: ColumnId, width: string) => ({
  type: atypes.UPDATE_COLUMN_WIDTH,
  payload: {
    columnId,
    width
  }
});
