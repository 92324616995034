// @flow

import React, { useEffect, useCallback } from "react";

import { Select as StyledSelect, Value } from "./styles";
import Selection from "./Selection";
import Dropdown from "./Dropdown";
import useBoolean from "src/hooks/useBoolean";
import Icon from "src/icons";

type Props = {
  value: Array<number>,
  handleStatus: Function,
  saveSettings: Function
};

const StatusSelect = ({ value, handleStatus, saveSettings }: Props) => {
  const { value: dropdown, toggleBoolean } = useBoolean(false);

  const toggleDropdown = useCallback(() => {
    toggleBoolean();
  }, [value, toggleBoolean]);

  useEffect(() => {
    saveSettings({ status: value });
  }, [value]);

  return (
    <StyledSelect onClick={toggleDropdown}>
      {value.length === 0 ? (
        <Value>
          <span>Select a Status</span>
          <span>
            <Icon type="triangleDown" />
          </span>
        </Value>
      ) : (
        <Selection value={value} toggleDropdown={toggleDropdown} />
      )}
      {dropdown && <Dropdown value={value} handleStatus={handleStatus} />}
    </StyledSelect>
  );
};

export default StatusSelect;
