// @flow

import React from "react";

type Props = {};

const ListNumbers = ({ ...restProps }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path d="M15.3125 4H5.6875C5.30783 4 5 3.55225 5 3C5 2.44775 5.30783 2 5.6875 2H15.3125C15.6922 2 16 2.44775 16 3C16 3.55225 15.6922 4 15.3125 4Z" />
    <path d="M15.3125 9H5.6875C5.30783 9 5 8.55225 5 8C5 7.44775 5.30783 7 5.6875 7H15.3125C15.6922 7 16 7.44775 16 8C16 8.55225 15.6922 9 15.3125 9Z" />
    <path d="M15.3125 14H5.6875C5.30783 14 5 13.5527 5 13C5 12.4473 5.30783 12 5.6875 12H15.3125C15.6922 12 16 12.4473 16 13C16 13.5527 15.6922 14 15.3125 14Z" />
    <path d="M1.03955 4.39526V3.92098L1.55582 3.87899C1.63981 3.87313 1.65185 3.84904 1.65185 3.75919V1.66447C1.65185 1.59253 1.63394 1.55639 1.57372 1.53849L1.05159 1.43042L1.11767 0.938232H2.5581V3.7592C2.5581 3.85523 2.56396 3.87313 2.65412 3.87899L3.14631 3.92099V4.39527H1.03955V4.39526Z" />
    <path d="M0.72754 9.77376V9.2155L1.5319 8.42936C2.13802 7.84115 2.39616 7.60124 2.39616 7.25912C2.39616 7.03678 2.28809 6.875 1.97005 6.875C1.63965 6.875 1.50163 6.98308 1.50163 7.40918L0.685547 7.33692C0.691407 6.38281 1.41765 6.22656 2.04785 6.22656C2.97819 6.22656 3.30827 6.62272 3.30827 7.18685C3.30827 7.7513 2.91829 8.12338 2.48014 8.5433L1.80176 9.19173H2.68425C2.74414 9.19173 2.76823 9.17968 2.78027 9.11946L2.86426 8.6455H3.50033V9.77376H0.72754V9.77376Z" />
    <path d="M2.75325 13.2675C3.26366 13.3456 3.54557 13.6516 3.54557 14.1916C3.54557 14.984 2.9515 15.356 2.0931 15.356C1.48698 15.356 0.946938 15.1402 0.640625 14.804L1.12076 14.276C1.34896 14.498 1.60091 14.6718 2.02116 14.6718C2.35742 14.6718 2.6455 14.552 2.6455 14.1318C2.6455 13.7655 2.41731 13.6155 2.04525 13.6155C1.89518 13.6155 1.78124 13.6275 1.60091 13.6575V13.0575L1.89518 13.0214C2.32715 12.9674 2.5612 12.7574 2.5612 12.3733C2.5612 12.1451 2.45931 11.9592 2.12304 11.9592C1.79915 11.9592 1.64323 12.0673 1.64323 12.4934L0.820958 12.4211C0.820958 11.4729 1.53515 11.3108 2.16536 11.3108C3.08951 11.3108 3.44368 11.665 3.44368 12.3072C3.44368 12.8114 3.13151 13.1295 2.75325 13.2434V13.2675Z" />
  </svg>
);

export default ListNumbers;
