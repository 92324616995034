// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { arrayMove } from "react-sortable-hoc";

import SortableList from "./SortableList";
import { updateSectionOrder } from "src/actions/orgSettings";

type Props = {
  sectionIds: Array<number>,
  handleOpenModal: Function,
  _updateSectionOrder: Function
};

const Sections = ({
  sectionIds,
  handleOpenModal,
  _updateSectionOrder
}: Props) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      _updateSectionOrder(arrayMove(sectionIds, oldIndex, newIndex));
    },
    [sectionIds, _updateSectionOrder]
  );

  return (
    <SortableList
      items={sectionIds}
      useDragHandle
      onSortEnd={onSortEnd}
      handleOpenModal={handleOpenModal}
    />
  );
};

export default connect(null, { _updateSectionOrder: updateSectionOrder })(
  Sections
);
