// @flow

import React from "react";

type Props = {
  color: string
};

const Status = ({ color }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={color || "#FFB2B2"}
      d="M12.667 2H12V.665h-1.333v1.333H5.333V.666H4v1.333h-.667c-.74 0-1.326.6-1.326 1.334L2 12.666c0 .733.593 1.333 1.333 1.333h9.334C13.4 14 14 13.4 14 12.666V3.333c0-.734-.6-1.334-1.333-1.334zm0 10.666H3.333V5.333h9.334v7.333zm-8-6H8v3.333H4.667V6.666z"
    />
  </svg>
);

export default Status;
