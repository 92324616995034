// @flow

import React from "react";

import { OldValue, ValueList } from "../styles";
import LinkedRoom from "src/components/messages/Chat/Notification/LinkedRoom";

import type { RoomId, ChatroomAddress } from "src/types";

type Props = {
  oldValue: Array<RoomId>,
  oldAddresses: Array<ChatroomAddress>,
  disableTitleHyperlink: boolean
};

const OldConversationValues = ({
  oldValue,
  oldAddresses,
  disableTitleHyperlink
}: Props) => {
  return (
    <OldValue>
      <ValueList>
        {oldValue.map((val, id) => (
          <LinkedRoom
            id={`${val}`}
            key={id}
            chatroomAddress={oldAddresses[id]}
            disableTitleHyperlink={disableTitleHyperlink}
          />
        ))}
      </ValueList>
    </OldValue>
  );
};

export default OldConversationValues;
