// @flow

import * as R from "ramda";
import type { UnifizeChatRoom, RoomId } from "src/types";

/**
 * Check whether the given argument is a valid Unifize chatroom.
 * @param {object | null} chatroom - The chatroom to check.
 */
export const isValidChatroom = (chatroom: ?Object = {}) =>
  // $FlowFixMe
  R.all(R.has(R.__, chatroom))([
    "status",
    "id",
    "address",
    "title",
    "type",
    "seqNo"
  ]);

// $FlowFixMe
export const sortByAutoNumber = R.sort<UnifizeChatRoom>((roomA, roomB) => {
  if (!roomA.seqNo || !roomB.seqNo) return Number(roomA.id) - Number(roomB.id);

  // If autoNo is not present or if the chatroom has multiple versions
  // (contains "/") rely on the seqNo for sorting instead of using the
  // autoNo
  if (
    !roomA.autoNo ||
    !roomB.autoNo ||
    (roomA.autoNo && !roomA.autoNo.includes("/")) ||
    (roomB.autoNo && !roomB.autoNo.includes("/"))
  )
    return Number(roomA.seqNo) - Number(roomB.seqNo);

  let [seqA, versionA] = roomA.autoNo.split("/").map(Number);
  let [seqB, versionB] = roomB.autoNo.split("/").map(Number);

  if (seqA !== seqB) return seqA - seqB;

  return versionA - versionB;
});

// $FlowFixMe
export const reverseSortById = R.sort<UnifizeChatRoom>(
  (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
);

/**
 * Resolve  chatroom ids from linked field chatroom map
 * @param {object} roomsById - chatroom map
 * @returns {array} - returns an array of room ids
 */
export function extractChatroomIds(roomsById: Object) {
  const chatroomIds: Array<RoomId> = [];
  for (const key in roomsById) {
    chatroomIds.push(roomsById[key].chatroom.id);
  }
  return chatroomIds;
}
