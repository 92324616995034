// @flow

import React, { Component } from "react";
import ReactModal from "react-modal";

import { FormGroup, ModalFormBody } from "./styles";
import { Small as StyledModal } from "src/styles/modal";
import { Form, SecondaryFooter } from "src/styles/form";

import { Input } from "src/styles/input";
import { ErrorText } from "src/styles/auth";

import { Secondary } from "src/styles/buttons";

import ButtonLoader from "src/components/ButtonLoader";

type Props = {
  savePinnedList: Function,
  hideSaveFilterModal: Function,
  saveModal: boolean,
  savePinnedListError: boolean,
  editPinnedList: Function,
  edit: boolean,
  hideEditFilterModal: Function,
  currentClickedSavedFilter: string
};

type State = {
  name: string,
  loading: boolean
};

class New extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { edit, currentClickedSavedFilter } = this.props;
    this.state = {
      name: edit ? currentClickedSavedFilter : "",
      loading: false
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { saveModal, edit, currentClickedSavedFilter } = this.props;
    if (prevProps.saveModal !== saveModal || prevProps.edit !== edit) {
      this.setState({
        loading: false,
        name: edit ? currentClickedSavedFilter : ""
      });
    }
  }

  handleChange = (e: any) => {
    const { value } = e.target;
    this.setState({ name: value });
  };

  closeSaveFilterModal = (e: any) => {
    e.preventDefault();
    const { hideSaveFilterModal, hideEditFilterModal } = this.props;

    this.setState({ name: "", loading: false }, () => {
      hideSaveFilterModal();
      hideEditFilterModal();
    });
  };

  saveFilter = (e: any) => {
    e.preventDefault();
    const { savePinnedList, editPinnedList, edit } = this.props;
    const { name } = this.state;

    this.setState({ loading: true }, () => {
      if (edit) {
        return editPinnedList(name);
      }
      savePinnedList(name);
    });
  };

  render() {
    const { saveModal, savePinnedListError: modalError, edit } = this.props;
    const { name, loading } = this.state;
    return (
      <StyledModal id="filters-modal">
        <ReactModal
          isOpen={saveModal}
          onRequestClose={this.closeSaveFilterModal}
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.querySelector("#filters-modal")}
        >
          <Form onSubmit={this.saveFilter}>
            <h4>{edit ? "Edit Filter " : "Save Filter"}</h4>
            <ModalFormBody>
              <FormGroup>
                <Input
                  placeholder="Enter Filter Name"
                  value={name}
                  onChange={this.handleChange}
                  autoFocus
                  required
                />
                {modalError && (
                  <ErrorText>
                    {" "}
                    {edit ? "Error editing filter" : "Filter Cannot be saved"}
                  </ErrorText>
                )}
              </FormGroup>
            </ModalFormBody>
            <SecondaryFooter>
              <Secondary type="button" onClick={this.closeSaveFilterModal}>
                Cancel
              </Secondary>
              <ButtonLoader type="submit" isLoading={!modalError && loading}>
                Confirm
              </ButtonLoader>
            </SecondaryFooter>
          </Form>
        </ReactModal>
      </StyledModal>
    );
  }
}

export default New;
