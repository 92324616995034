// @flow

import React, { useState, useRef, useCallback } from "react";

import { AddSubSection as StyledAddSubSection, RelativeBox } from "../styles";
import Dropdown1 from "./Dropdown1";
import Dropdown2 from "./Dropdown2";
import Dropdown3 from "./Dropdown3";
import Modal from "./Modal";

type Props = {
  id: number,
  tiles: Array<number>,
  handleOpenModal: Function
};

const AddSubSection = ({ id, tiles, handleOpenModal }: Props) => {
  const [dropdown, setDropdown] = useState(null);
  const outerRef = useRef(null);
  const [modal, setModal] = useState(null);

  const toggleDropdown = useCallback(() => {
    if (dropdown) {
      setDropdown(null);
    } else {
      setDropdown(1);
    }
  }, [dropdown, setDropdown]);

  const handleClose = useCallback(() => {
    setDropdown(null);
  }, [setDropdown]);

  return (
    <RelativeBox ref={outerRef}>
      <StyledAddSubSection
        active={!!dropdown}
        type="button"
        onClick={toggleDropdown}
      >
        &#43; Add new tile
      </StyledAddSubSection>
      {dropdown === 1 && (
        <Dropdown1
          id={id}
          outerRef={outerRef}
          handleClose={handleClose}
          handleNew={() => setDropdown(2)}
          handleExisting={() => setDropdown(3)}
        />
      )}
      {dropdown === 2 && (
        <Dropdown2
          id={id}
          outerRef={outerRef}
          handleClose={handleClose}
          handleOpenModal={handleOpenModal}
        />
      )}
      {dropdown === 3 && (
        <Dropdown3
          id={id}
          tiles={tiles}
          outerRef={outerRef}
          handleClose={handleClose}
          modal={modal}
          setModal={setModal}
        />
      )}

      {modal && <Modal tile={modal} handleClose={() => setModal(null)} />}
    </RelativeBox>
  );
};

export default AddSubSection;
