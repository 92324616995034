// @flow

import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import uuid from "uuid/v4";

import { EditIconWrapper } from "../styles";
import {
  CaretUp,
  Count,
  MultiContainer,
  StyledMultiConversation,
  ConversationWrapper,
  MultiTable,
  MultiConversationRow
} from "./styles";
import Title from "src/components/chatroom/Name/Title";
import ChecklistItem from "../ChecklistItem";
import Status from "../Status";
import Owner from "../Owner";
import DueDate from "../DueDate";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { openConversationModal } from "src/actions/chatroom";
import { toggleRowExpansion } from "src/actions/checklist";
import {
  getEmbeddedConversationColumns,
  getExpandedFields,
  getChecklistFieldSourceSettings,
  getFieldWidth,
  getChatroomAttributeWidth
} from "src/reducers";
import useRowExpansion from "src/hooks/useRowExpansion";
import type {
  AppState,
  ColumnId,
  FieldId,
  ExpandedEmbeddedFields,
  ExpandedEmbeddedColumns,
  WorkflowInstances
} from "src/types";

type Props = {
  autoNo: string,
  selected: boolean,
  value: any,
  _openConversationModal: Function,
  openChecklistEditModal: Function,
  _toggleRowExpansion: Function,
  fieldId: number,
  roomId: string,
  rowIndex: number,
  embeddedIndex?: number,
  handleConversationModal: Function,
  embeddedFields: ?Array<number>,
  hideEdit?: boolean,
  onVersionClick?: Function,
  autoLinkedRooms?: number[],
  type: string,
  columnId: ColumnId,
  _getChecklistFieldType: ({ fieldId: FieldId }) => string,
  settings: string,
  expandedFields: ExpandedEmbeddedFields,
  embeddedColumns: ExpandedEmbeddedColumns,
  process: WorkflowInstances,
  originalValue: any,
  sourceProcessSettings: any,
  disableTitleHyperlink: boolean
};

const MultipleConversation = ({
  autoNo,
  value,
  selected,
  openChecklistEditModal,
  fieldId,
  roomId,
  rowIndex,
  embeddedIndex,
  handleConversationModal,
  hideEdit,
  onVersionClick,
  autoLinkedRooms,
  columnId,
  settings,
  expandedFields,
  embeddedColumns,
  process,
  originalValue,
  sourceProcessSettings,
  disableTitleHyperlink
}: Props) => {
  const { toggleExpand, isRowExpanded } = useRowExpansion(
    `${columnId}`,
    autoNo,
    roomId
  );

  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  const { ownerFieldWidth, dueDateFieldWidth, statusFieldWidth } = useSelector(
    ({ app }) => getChatroomAttributeWidth(app, columnId)
  );

  // Setting to decide whether or not to show the owner, status and
  // due date columns for linked fields
  const showMetaData =
    typeof sourceProcessSettings !== "string" && sourceProcessSettings.size > 0
      ? sourceProcessSettings.get("showMetaData")
      : false;

  const multipleConversationsValue = value || [];

  const [isEmbeddedLinkedFieldExpanded, setIsEmbeddedLinkedFieldExpanded] =
    useState(false);

  const isExpanded = columnId.includes("-")
    ? isEmbeddedLinkedFieldExpanded
    : isRowExpanded;

  const embeddedConversations =
    process[columnId]?.result?.length > 0 ? process[columnId]?.result : [null];

  return (
    <MultiContainer
      selected={selected}
      borderRequired={
        expandedFields[columnId] || columnId.includes("-") ? true : false
      }
    >
      <MultiTable>
        <tbody>
          {Array.from({
            length: isExpanded
              ? Math.max(multipleConversationsValue.length, 1)
              : 1
          }).map((_, fieldIndex) => {
            const fieldValue = multipleConversationsValue[fieldIndex] || " ";
            const chatroomId = embeddedConversations[fieldIndex];
            const linkedField = (chatroomId &&
              process[columnId]?.entities?.chatrooms?.[chatroomId]
                .chatroom) || {
              status: "",
              owner: "",
              dueDate: "",
              active: false
            };

            return (
              <MultiConversationRow
                key={fieldIndex}
                expandedField={expandedFields[columnId] ? true : false}
              >
                <StyledMultiConversation
                  selected={selected}
                  width={fieldWidth}
                  expanded={expandedFields[columnId] ? true : false}
                >
                  {fieldValue?.type ||
                  fieldValue?.chatroom ||
                  fieldValue?.chatroomId ? (
                    <ConversationWrapper selected={selected}>
                      <Title
                        id={fieldValue.id || fieldValue?.chatroom}
                        title={fieldValue.title}
                        canceled={fieldValue.canceled}
                        processName={fieldValue.processTitle}
                        openConversationModal={handleConversationModal}
                        address={fieldValue.address}
                        seqNo={fieldValue.autoNo || fieldValue.seqNo}
                        disableLink={disableTitleHyperlink}
                      />

                      {onVersionClick &&
                        autoLinkedRooms &&
                        fieldValue.versionCount > 1 && (
                          <Icon
                            type="currentRevision"
                            fill={
                              autoLinkedRooms.includes(fieldValue.id)
                                ? colors.purple
                                : colors.inkLightest
                            }
                            onClick={() => onVersionClick(fieldValue.id)}
                          />
                        )}
                    </ConversationWrapper>
                  ) : (
                    <> </>
                  )}
                  {!hideEdit && (
                    <EditIconWrapper
                      onClick={e =>
                        openChecklistEditModal(
                          e,
                          fieldIndex,
                          fieldId,
                          roomId,
                          originalValue || value,
                          columnId,
                          embeddedIndex
                        )
                      }
                    >
                      <Icon type="editPencil" />
                    </EditIconWrapper>
                  )}
                  {fieldIndex === 0 &&
                    !isExpanded &&
                    multipleConversationsValue.length > 1 && (
                      <Count
                        onClick={
                          columnId.includes("-")
                            ? () => setIsEmbeddedLinkedFieldExpanded(true)
                            : toggleExpand
                        }
                      >
                        +{multipleConversationsValue.length - 1}
                      </Count>
                    )}
                  {fieldIndex === 0 && isExpanded && (
                    <CaretUp
                      onClick={
                        columnId.includes("-")
                          ? () => setIsEmbeddedLinkedFieldExpanded(false)
                          : toggleExpand
                      }
                    >
                      <Icon type="caretUp" />
                    </CaretUp>
                  )}
                </StyledMultiConversation>

                {showMetaData && expandedFields?.[`${columnId}`] && (
                  <>
                    <MultiContainer
                      width={ownerFieldWidth}
                      key={`${uuid()}-${
                        embeddedConversations[fieldIndex] || "status"
                      }`}
                      borderRequired={true}
                    >
                      <Owner
                        selected={selected}
                        id={parseInt(embeddedConversations[fieldIndex])}
                        value={linkedField.owner}
                        index={rowIndex}
                        parentId={null}
                        depth={0}
                        embedded={true}
                        columnId={columnId}
                      />
                    </MultiContainer>

                    <MultiContainer
                      width={statusFieldWidth}
                      key={`${uuid()}-${
                        embeddedConversations[fieldIndex] || "status"
                      }`}
                      borderRequired={true}
                    >
                      <Status
                        selected={selected}
                        embedded={true}
                        id={parseInt(embeddedConversations[fieldIndex])}
                        value={linkedField.status}
                        hide={!embeddedConversations[fieldIndex]}
                        index={rowIndex}
                        parentId={process.id}
                        depth={0}
                        templateId={process.id}
                        columnId={columnId}
                      />
                    </MultiContainer>

                    <MultiContainer
                      width={dueDateFieldWidth}
                      key={`${uuid()}-${
                        embeddedConversations[fieldIndex] || "dueDate"
                      }`}
                      borderRequired={true}
                    >
                      <DueDate
                        embedded={true}
                        selected={selected}
                        id={parseInt(embeddedConversations[fieldIndex])}
                        hide={!embeddedConversations[fieldIndex]}
                        value={linkedField.dueDate}
                        index={rowIndex}
                        depth={0}
                        active={linkedField.active}
                        templateId={process.id}
                        columnId={columnId}
                      />
                    </MultiContainer>
                  </>
                )}

                {columnId &&
                  embeddedColumns[columnId] &&
                  embeddedColumns[columnId].map((column, columnIndex) => {
                    // calculate the depth of the embedded field
                    const depth = `${column.id}`.split("-").length - 1;
                    let embeddedValue;
                    if (depth === 1) {
                      embeddedValue = process
                        ? process[column.id]?.[fieldIndex]
                        : [];
                    } else if (depth === 2) {
                      embeddedValue = process
                        ? process[column.id]?.[rowIndex]?.[fieldIndex]
                        : [];
                    }
                    if (column.type === "link" && process) {
                      if (depth === 1) {
                        embeddedValue = process[column.id]?.[fieldIndex] || {
                          entities: { chatrooms: {} },
                          result: []
                        };
                      } else if (depth === 2) {
                        embeddedValue = process[column.id]?.[rowIndex]?.[
                          fieldIndex
                        ] || {
                          entities: { chatrooms: {} },
                          result: []
                        };
                      }
                    }
                    return (
                      <ChecklistItem
                        selected={selected}
                        key={`${columnIndex}-${fieldIndex}`}
                        columnId={column.id}
                        fieldId={parseInt(column.fieldId)}
                        value={embeddedValue}
                        settings={settings}
                        index={rowIndex}
                        isPrivate={false}
                        roomId={roomId}
                        process={process}
                        allRevisionsShown={true}
                        shouldRenderEmbeddedFields={true}
                        embeddedIndex={fieldIndex}
                      />
                    );
                  })}
              </MultiConversationRow>
            );
          })}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => {
  return {
    embeddedColumns: getEmbeddedConversationColumns(app),
    expandedFields: getExpandedFields(app),
    sourceProcessSettings: getChecklistFieldSourceSettings(app, props.fieldId)
  };
};

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal,
  _toggleRowExpansion: toggleRowExpansion
})(MultipleConversation);
