// @flow

import * as R from "ramda";

import type { ChartConfig } from "src/types";
import Downshift from "downshift";

/**
 * Generate field spec for charts.
 * @param {number} id - selected field id
 * @param {string} type - selected field type
 * @param {string} linkedProcessOrFormType - selected field is linked to a process or a form to check whether it is a form field or embedded field
 * @param {number} linkedProcessOrFormId - ID of the linked process or form
 * @param {number} linkedOrFormFieldId - Root checklist form or linked (parent conversation, child conversation and linked) field ID.
 * @return {Array} array of field IDs
 */

export const generateFieldId = ({
  id,
  type,
  linkedProcessOrFormType,
  linkedProcessOrFormId,
  linkedOrFormFieldId
}: {
  id: number,
  type: string,
  linkedProcessOrFormType: string,
  linkedProcessOrFormId: number,
  linkedOrFormFieldId: number
}) => {
  if (R.isEmpty(linkedProcessOrFormType)) {
    if (type === "form") {
      if (linkedOrFormFieldId) {
        return [linkedOrFormFieldId, [id]];
      } else {
        return [id];
      }
    } else {
      return [id];
    }
  } else {
    if (linkedProcessOrFormType === "form") {
      return [linkedOrFormFieldId, [linkedProcessOrFormId, id]];
    } else {
      return [linkedOrFormFieldId, id];
    }
  }
};

/**
 * Update chart series state.
 * @param {(newChart: ChartConfig) => void} setChartDetails - Chart state setter function
 * @param {number | string} seriesId - series ID
 * @param {string} key - Series state key which needs to update
 * @param {any} value - New state value
 * @param {boolean} multiValue - Multi valued field
 */

export const setChartSeries = ({
  setChartDetails,
  seriesId,
  key,
  value,
  multiValue
}: {
  setChartDetails: (newChart: ChartConfig) => void,
  seriesId: number | string,
  key: string,
  value: any,
  multiValue?: boolean
}) => {
  setChartDetails(prevChart => {
    const series = prevChart.series;

    const foundSeries = series.find(item => {
      // Existing series
      if (item.seriesId) {
        return item.seriesId === seriesId;
      } else {
        // New series
        return item.tempId === seriesId;
      }
    });

    if (foundSeries) {
      const updatedItem =
        multiValue !== undefined
          ? {
              ...foundSeries,
              [key]: value,
              seriesMultiValue: multiValue
            }
          : {
              ...foundSeries,
              [key]: value
            };

      const mappedSeries = series.map(item => {
        // Existing series
        if (item.seriesId) {
          return item.seriesId === seriesId ? updatedItem : item;
        } else {
          // New series
          return item.tempId === seriesId ? updatedItem : item;
        }
      });
      return { ...prevChart, series: mappedSeries };
    } else {
      return prevChart;
    }
  });
};

/**
 * Update ComputationType series state.
 * @param {(newChart: ChartConfig) => void} setChartDetails - Chart state setter function
 * @param {number | string} seriesId - series ID
 * @param {string} operation - Series operation state
 * @param {string} fieldType - selected field type
 */

export const handleComputationType = ({
  setChartDetails,
  seriesId,
  operation,
  fieldType
}: {
  setChartDetails: (newChart: ChartConfig) => void,
  seriesId: number | string,
  operation: string,
  fieldType?: string
}) => {
  setChartDetails(prevChart => {
    const series = prevChart.series;

    // Find the series with the matching id
    // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
    const foundSeries = series?.find(item => {
      // Existing series
      if (item.seriesId) {
        return item.seriesId === seriesId;
      } else {
        // New series
        return item.tempId === seriesId;
      }
    });

    if (foundSeries) {
      // Mapping of operation and fieldType to computationType
      const mapping = {
        count: {
          computationType: ""
        },
        sum: {
          computationType: "number-field"
        },
        avg: {
          computationType: "number-field"
        },
        "avg-sum": {
          computationType: "number-field"
        }
      };

      // Special cases for fieldTypes
      const fieldTypeMapping = {
        primaryRecord: "conversation",
        link: "related-conversation",
        conversation: "related-conversation",
        childConversation: "related-conversation",
        form: "form"
      };

      // Determine computationType based on operation and fieldType
      const { computationType } = mapping[operation] || {};
      const specialComputationType = fieldType && fieldTypeMapping[fieldType];

      // Updated item with the computed values
      // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
      const mappedSeries = series?.map(item => {
        // Existing series
        if (item.seriesId) {
          return item.seriesId === seriesId
            ? {
                ...item,
                computationType: specialComputationType || computationType
              }
            : item;
        } else {
          // New series
          return item.tempId === seriesId
            ? {
                ...item,
                computationType: specialComputationType || computationType
              }
            : item;
        }
      });
      return { ...prevChart, series: mappedSeries };
    } else {
      return prevChart;
    }
  });
};

export const getChartFieldId = (field: Array<any>) => {
  const flattenField = R.flatten(field);
  return flattenField[flattenField.length - 1];
};

export const getComputationRootChecklistFieldId = (field: Array<any>) => {
  if (field.length >= 1) {
    return field[0];
  }
};

const trendlineInitialState = {
  seriesId: null,
  label: "",
  color: null
};

export const handleTrendline = (
  setChartDetails: Function,
  e: SyntheticInputEvent<HTMLInputElement>,
  seriesId: number | string
) => {
  if (e.target.checked) {
    setChartDetails(prevChart => {
      const trendlines = prevChart.trendlines;
      return {
        ...prevChart,
        trendlines: [...trendlines, { ...trendlineInitialState, seriesId }]
      };
    });
  } else {
    setChartDetails(prevChart => {
      const trendlines = prevChart.trendlines;
      return {
        ...prevChart,
        trendlines: trendlines.filter(item => item.seriesId !== seriesId)
      };
    });
  }
};

export const setTrendline = (
  setChartDetails: Function,
  seriesId: number | string,
  key: string,
  value: any
) => {
  setChartDetails(prevChart => {
    const trendlines = prevChart.trendlines;

    const foundTrendline = trendlines.find(item => item.seriesId === seriesId);

    if (foundTrendline) {
      const updatedItem = {
        ...foundTrendline,
        [key]: value
      };

      const mappedTrendlines = trendlines.map(item =>
        item.seriesId === seriesId ? updatedItem : item
      );
      return { ...prevChart, trendlines: mappedTrendlines };
    } else {
      return prevChart;
    }
  });
};

export const handleSeriesDelete = (
  setChartDetails: Function,
  seriesId: number | string
) => {
  setChartDetails(prevChart => {
    const series = prevChart.series;

    // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
    const foundSeries = series?.find(item => {
      // Existing series
      if (item.seriesId) {
        return item.seriesId === seriesId;
      } else {
        // New series
        return item.tempId === seriesId;
      }
    });

    if (foundSeries) {
      const filteredSeries = series.filter(item => {
        // Existing series
        if (item.seriesId) {
          return item.seriesId !== seriesId;
        } else {
          // New series
          return item.tempId !== seriesId;
        }
      });
      return { ...prevChart, series: filteredSeries };
    } else {
      return prevChart;
    }
  });
};

export const handleRelativeCount = (setChartDetails: Function, e: string) => {
  const relativeCount = e === "true" ? true : false;

  setChartDetails(prevChart => ({
    ...prevChart,
    series: prevChart.series.map(item => ({
      ...item,
      relativeCount
    }))
  }));
};

export const setChartSort = (setChartDetails: Function, e: string) => {
  setChartDetails(prevChart => ({
    ...prevChart,
    sort: {
      ...prevChart.sort,
      orderBy: e,
      seriesId: null
    }
  }));
};

export const setChartSortSeriesId = (
  setChartDetails: Function,
  seriesId: number | string
) => {
  setChartDetails(prevChart => ({
    ...prevChart,
    sort: {
      ...prevChart.sort,
      seriesId
    }
  }));
};

export const intialSeriesState = {
  tempId: null,
  y: null,
  title: "",
  seqNo: null,
  computationType: "",
  computationField: null,
  operation: "",
  rateSeries: null,
  rateOperation: null,
  plotType: "",
  axisType: "",
  hide: false,
  relativeCount: false
};

export const handleAddComparison = (
  setChartDetails: (newChart: ChartConfig) => void,
  id: string,
  plotType: string
) => {
  const isPrimary = plotType === "column" || plotType === "area";
  setChartDetails(prevChart => {
    const series = prevChart.series;
    const updatedSeries = [
      ...series,
      {
        ...intialSeriesState,
        tempId: id,
        plotType,
        rateOperation: plotType === "rate" ? "percentage" : null,
        axisType: isPrimary ? "primary" : "secondary"
      }
    ];
    return { ...prevChart, series: updatedSeries };
  });
};

export const getChartName = (type: ?string, computationType?: string) => {
  if (type === "conversation-count" && computationType === "number-field") {
    return "numeric-field-stacked";
  } else if (type === "conversation-count") {
    return "conversation-count-stacked";
  } else if (type === "form-count") {
    return "form-count-stacked";
  } else if (type === "time-series") {
    return "time-series-stacked";
  } else return type;
};

export const getModifiedChartData = ({
  type,
  chartData,
  chartId,
  onDataPlotClick
}: {
  type: string,
  chartData: Object,
  chartId: number | string,
  onDataPlotClick: Function
}) => {
  let modifiedChartData = {};
  switch (type) {
    case "scrollmsstackedcolumn2dlinedy":
      modifiedChartData = {
        ...chartData,
        dataSource: {
          ...chartData.dataSource,
          lineset:
            chartData.dataSource?.lineset?.length === 0
              ? [{}]
              : (chartData.dataSource?.lineset || []).map(series => ({
                  ...series,
                  data: (series?.data || []).map(data => ({
                    ...data,
                    link: `j-openchart-${JSON.stringify({
                      ...data.filter,
                      chartId
                    })}`
                  }))
                })),
          dataset:
            chartData.dataSource?.dataset?.length === 0
              ? [{}]
              : (chartData.dataSource?.dataset || []).map(series => ({
                  ...series,
                  dataset: (series?.dataset || []).map(value => ({
                    ...value,
                    data: (value?.data || []).map(data => ({
                      ...data,
                      link: `j-openchart-${JSON.stringify({
                        ...data.filter,
                        chartId
                      })}`
                    }))
                  }))
                }))
        },
        events: {
          dataplotClick: onDataPlotClick
        }
      };
      break;
    case "doughnut2d":
      modifiedChartData = {
        ...chartData,
        dataSource: {
          ...chartData.dataSource,
          data: (chartData.dataSource?.data || []).map(value => ({
            value: value.value,
            label: value.label,
            link: `j-openchart-${JSON.stringify({
              ...value.filter,
              chartId
            })}`
          }))
        },
        events: {
          dataplotClick: onDataPlotClick
        }
      };
      break;
    case "stackedarea2dlinedy":
    case "scrollcombidy2d":
      modifiedChartData = {
        ...chartData,
        dataSource: {
          ...chartData.dataSource,
          dataset:
            chartData.dataSource?.dataset?.length === 0
              ? [{}]
              : (chartData.dataSource?.dataset || []).map(series => ({
                  ...series,
                  data: (series?.data || []).map(data => ({
                    ...data,
                    link: `j-openchart-${JSON.stringify({
                      ...data.filter,
                      chartId
                    })}`
                  }))
                }))
        },
        events: {
          dataplotClick: onDataPlotClick
        }
      };
      break;
    default:
      modifiedChartData = {
        ...chartData,
        x: {
          ...chartData.dataSource,
          dataset: (chartData.dataSource?.dataset || []).map(series => ({
            ...series,
            data: (series?.data || []).map(value => ({
              value: value.value,
              label: value.label,
              link: `j-openchart-${JSON.stringify({
                ...value.filter,
                chartId
              })}`
            }))
          }))
        },
        events: {
          dataplotClick: onDataPlotClick
        }
      };
  }
  return modifiedChartData;
};

/**
 * Handles keyboard events for a chart fields.
 *
 * @param {Object} params - An object containing parameters.
 * @param {Event} params.event - The keyboard event object.
 * @param {React.RefObject} params.ref - The reference to the dropdown/select component.
 * @param {Function} params.onSelect - A function to call when an item is selected.
 * @param {Function} params.onClose - A function to close the dropdown.
 */
export const keyboardHandler = ({
  event: e,
  ref,
  onSelect,
  onClose
}: {
  event: Object,
  ref: Object,
  onSelect: Function,
  onClose?: Function
}) => {
  switch (e.key) {
    case "ArrowDown":
      e.preventDefault();
      if (ref.current != null) {
        ref.current.internalSetState({
          itemsLength: ref.current.items.length,
          type: Downshift.stateChangeTypes.keyDownArrowDown
        });
      }
      break;

    case "ArrowUp":
      if (ref.current != null) {
        ref.current.internalSetState({
          itemsLength: ref.current.items.length,
          type: Downshift.stateChangeTypes.keyDownArrowUp
        });
      }
      break;

    case "Enter":
      e.preventDefault();
      const highlightedIndex = ref.current.state.highlightedIndex;
      onSelect(highlightedIndex);

      ref.current.internalSetState({
        highlightedIndex: highlightedIndex,
        type: Downshift.stateChangeTypes.keyDownEnter
      });
      break;

    case "Tab":
      onClose && onClose();
      break;

    default:
      break;
  }
};

/**
 * Convert list of fields into a map making sure duplicate fields
 * are nested inside a key called 'duplicates'.
 * @param {Array} items - array of fields
 * @return {Object} genrated map including nested items
 */
export const generateByFieldId = (items: Array<Object>): Object => {
  const result = items.reduce((acc, item) => {
    const key = item.id;

    if (!acc[key]) {
      acc[key] = {
        ...item,
        id: Number(key),
        duplicates: []
      };
    } else {
      acc[key].duplicates.push(item);
    }

    return acc;
  }, {});

  return result;
};

/**
 * Flatten chart fields map including nested fields.
 * @param {Object} items - fields map object
 * @return {Object} flattened array of field objects
 */
export const flattenChartFields = (fields: Object): Array<Object> =>
  R.flatten(
    R.map(
      field => R.concat([field], R.propOr([], "duplicates", field)),
      Array.from(R.values(fields))
    )
  );

/**
 * Find chart field data from fields map.
 * @param {Array} value - field value
 * @param {Object} fields - fields map object
 * @return {Object} result field item
 */
export const findFieldData = (value: Array<number>, fields: Object) => {
  const fieldId = getChartFieldId(value);
  const fieldData = fields && fields[fieldId];
  if (!fieldData) return {};

  if ((fieldData?.duplicates?.length || 0) <= 0 || value.length <= 1) {
    return fieldData;
  }

  const allFields = [...fieldData.duplicates, fieldData];
  return allFields.find(item => item.linkedOrFormFieldId === value[0]);
};
