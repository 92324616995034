// @flow

import React from "react";

import Item from "src/components/Manage/Reports/Instances/Chart/ChartList/Title";
import { Value as StyledValue } from "src/components/Manage/Reports/Instances/Chart/New/FieldSelect/styles";
import Icon from "src/icons";

type Props = {
  value: number,
  handleSelect: Function
};

const Selection = ({ value, handleSelect }: Props) => {
  if (value) {
    return (
      <StyledValue type="button" onClick={() => handleSelect(null)}>
        <Item id={value} />
        <Icon type="close" />
      </StyledValue>
    );
  }
  return <StyledValue>Select Field</StyledValue>;
};

export default Selection;
