// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { Set, Map, List } from "immutable";
import * as R from "ramda";

import * as aTypes from "../constants/actionTypes";

import {
  getChatroomTitle,
  getTemplateId,
  getSeqNoForSearchedGroupedFiles
} from "src/reducers";
import { fieldTransitReader } from "src/transit/checklist/field/reader";

import type {
  Action,
  UnifizeFile,
  FileState,
  Folder,
  FolderById,
  FileViewer,
  ActiveFileMenu,
  FileUploadState,
  RoomId,
  ManageFilesFilter,
  AppState,
  FilePreviews,
  FileThumbnails,
  FieldValueDetails
} from "src/types";

type FolderState = List<Folder>;

const updateState = ({ state, files }) =>
  Map({
    ...R.mergeAll(
      R.map(
        file => ({
          [file.name]: {
            ...file,
            versionId:
              file.versionId || R.last(file?.versions || [])?.versionId || null,
            uploadTime:
              file.uploadTime ||
              R.last(file?.versions || [])?.uploadTime ||
              null
          }
        }),
        files
      )
    ),
    ...state.toJS()
  });

const isFileField = fieldValue => {
  try {
    const fieldTransit = fieldTransitReader.read(fieldValue.type);
    const fieldType = fieldTransit.type;
    return fieldType === "file" || fieldType === "pdf";
  } catch (error) {
    console.error(error);
    return false;
  }
};

const byId = (
  state: Map<string, UnifizeFile> = Map(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_SUCCESS:
    case aTypes.ADD_FILE_SUCCESS:
      return state.set(payload.name, payload);
    case aTypes.GET_FILE_BATCH_SUCCESS:
      return state.merge(Map(payload));
    case aTypes.DELETE_FILE_SUCCESS:
      return state.delete(payload.name);
    case aTypes.SEARCH_FILE_SUCCESS:
      return updateState({ state, files: payload.result || [] });

    case aTypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS: {
      const values: FieldValueDetails[] = R.flatten(R.values(payload));
      const files = R.flatten(
        R.pluck("value")(R.filter(isFileField)(R.flatten(values)))
      );

      return updateState({ state, files });
    }

    case aTypes.SET_CHECKLIST_VALUE_SUCCESS:
    case aTypes.GET_CHECKLIST_FIELD_VALUE_COMPLETE:
    case aTypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS: {
      if (!isFileField(payload)) return state;

      return updateState({ state, files: payload.value || [] });
    }

    case aTypes.GET_CHECKLIST_FORM_VALUES: {
      const values: FieldValueDetails[] = R.values(payload);
      const files = R.flatten(R.pluck("value")(R.filter(isFileField)(values)));

      return updateState({ state, files });
    }

    default:
      return state;
  }
};

const allIds = (state: Set<string> = Set(), { type, payload }: Action) => {
  switch (type) {
    case aTypes.GET_FILE_BATCH_SUCCESS:
      return state.union(R.keys(payload || {}));
    case aTypes.ADD_FILE_SUCCESS:
      return state.add(payload.name);
    case aTypes.DELETE_FILE_SUCCESS:
      return state.delete(payload.name);
    default:
      return state;
  }
};

const uploadStateByRoomId = (
  state: Map<RoomId, FileUploadState> = Map({}),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_TO_CHAT_REQUEST: {
      const { roomId, filename, data } = payload;
      if (R.type(data) === "Array") {
        return state.set(
          `${roomId}`,
          R.mergeAll(
            data.map(file => ({
              [file.name]: { progress: 0 }
            }))
          )
        );
      }

      return state.set(`${roomId}`, {
        [filename]: { progress: 0 }
      });
    }
    case aTypes.CLEAR_FILE_UPLOAD_STATE:
    case aTypes.PIN_FILE_TO_CHAT_SUCCESS: {
      const { roomId } = payload;
      const currentState = state.get(`${roomId}`);
      delete currentState[payload.name];
      return state.set(`${roomId}`, { ...currentState });
    }
    case aTypes.PIN_FILE_TO_CHAT_FAILURE:
    case aTypes.CREATE_FILE_FAILURE:
    case aTypes.UPLOAD_FILE_TO_CHAT_FAILURE:
      {
        const { roomId, error } = payload;
        const currentState = state.get(`${roomId}`);
        if (!R.isNil(currentState)) {
          return state.set(`${roomId}`, {
            ...currentState,
            [payload.name]: {
              error
            }
          });
        }
      }
      break;
    case aTypes.UPLOAD_FILE_TO_CHAT_PROGRESS:
      {
        const { roomId, progress } = payload;
        const currentState = state.get(`${roomId}`);
        if (!R.isNil(currentState))
          return state.set(`${roomId}`, {
            ...currentState,
            [payload.name]: {
              progress
            }
          });
      }
      break;
    default:
      return state;
  }
};

const isUploading = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_REQUEST:
    case aTypes.UPLOAD_FILE_TO_CHAT_REQUEST:
    case aTypes.UPLOAD_FILE_TO_CHECKLIST_REQUEST:
    case aTypes.UPLOAD_CUSTOM_SIGNATURE_REQUEST:
      return true;
    case aTypes.UPLOAD_FILE_SUCCESS:
    case aTypes.UPLOAD_FILE_FAILURE:
    case aTypes.UPLOAD_FILE_TO_CHAT_SUCCESS:
    case aTypes.UPLOAD_FILE_TO_CHAT_FAILURE:
    case aTypes.UPLOAD_FILE_TO_CHECKLIST_SUCCESS:
    case aTypes.UPLOAD_FILE_TO_CHECKLIST_FAILURE:
    case aTypes.UPLOAD_CUSTOM_SIGNATURE_SUCCESS:
    case aTypes.UPLOAD_CUSTOM_SIGNATURE_FAILURE:
      return false;
    default:
      return state;
  }
};

const isDownloading = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.DOWNLOAD_FILE_REQUEST:
      return true;
    case aTypes.DOWNLOAD_FILE_SUCCESS:
    case aTypes.DOWNLOAD_FILE_FAILURE:
      return false;
    default:
      return state;
  }
};

const folderRoute = (
  state: FolderState = List.of({
    id: 0,
    name: "All Files",
    parentId: null,
    owner: ""
  }),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.EXPAND_FOLDER:
      return state.push(payload);
    case aTypes.GOTO_FOLDER:
      return state.slice(0, state.findIndex(d => d.id === payload.id) + 1);
    case aTypes.GOTO_ROOT_FOLDER:
      return state.slice(0, 1);
    case aTypes.GOTO_MY_DRIVE:
      return state.slice(0, 1).push(payload);
    default:
      return state;
  }
};

const browseFolder = (
  state: List<Folder> = List(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.COPY_FILE_STATE:
      return List(payload.folders);
    case aTypes.FETCH_BROWSE_FOLDER_SUCCESS:
      return List(payload.folders);
    case aTypes.FETCH_BROWSE_FOLDER_FAILURE:
      return state;
    case aTypes.MOVE_FILE_SUCCESS:
    case aTypes.MOVE_FILE_FAILURE:
    case aTypes.MOVE_FOLDER_SUCCESS:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return List();
    default:
      return state;
  }
};

const browseFolderFile = (
  state: Set<string> = Set(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.COPY_FILE_STATE:
      return Set(payload.files);
    case aTypes.FETCH_BROWSE_FOLDER_SUCCESS:
      return Set(payload.files.map(file => file.name));
    case aTypes.FETCH_BROWSE_FOLDER_FAILURE:
      return state;
    case aTypes.MOVE_FILE_SUCCESS:
    case aTypes.MOVE_FILE_FAILURE:
    case aTypes.MOVE_FOLDER_SUCCESS:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return Set();
    default:
      return state;
  }
};

const browseFolderRoute = (
  state: FolderState = List(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.COPY_FILE_STATE:
      return payload.folderRoute;
    case aTypes.BROWSE_IN_FOLDER:
      return state.push(payload);
    case aTypes.BROWSE_BACK_FOLDER:
      return state.pop();
    case aTypes.MOVE_FILE_SUCCESS:
    case aTypes.MOVE_FILE_FAILURE:
    case aTypes.MOVE_FOLDER_SUCCESS:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return List();
    default:
      return state;
  }
};

const currentFile = (state: any = null, { type, payload }: Action) => {
  switch (type) {
    case aTypes.MOVE_FILE_SUCCESS:
    case aTypes.MOVE_FILE_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
    case aTypes.STOP_PIN_FILE_PROCESS:
    case aTypes.PIN_FILE_SUCCESS:
      return null;
    case aTypes.START_MOVE_FILE_PROCESS:
    case aTypes.START_PIN_FILE_PROCESS:
      return payload;
    default:
      return state;
  }
};

const isMoving = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.START_MOVE_FILE_PROCESS:
      return true;
    case aTypes.MOVE_FILE_SUCCESS:
    case aTypes.MOVE_FILE_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return false;
    default:
      return state;
  }
};

const folderById = (state: FolderById = {}, { type, payload }: Action) => {
  switch (type) {
    case aTypes.FETCH_FOLDER_SUCCESS:
      return payload.entities.folders ? payload.entities.folders : {};
    case aTypes.CREATE_FOLDER_SUCCESS:
      return { ...state, [payload.id]: payload };
    case aTypes.MOVE_FOLDER_SUCCESS:
      return R.filter(folder => folder.id !== payload.id, state);
    case aTypes.DELETE_FOLDER_SUCCESS:
      return R.filter(folder => folder.id !== payload.id, state);
    case aTypes.FETCH_FOLDER_FAILURE:
    case aTypes.CREATE_FOLDER_FAILURE:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.DELETE_FOLDER_FAILURE:
      return state;
    default:
      return state;
  }
};

const folderFile = (state: Set<string> = Set(), { type, payload }: Action) => {
  switch (type) {
    case aTypes.FETCH_FOLDER_SUCCESS:
      return Set(payload.result.files);
    case aTypes.DELETE_FILE_SUCCESS:
      return state.delete(payload.name);
    default:
      return state;
  }
};

const folderAllIds = (
  state: Set<number> = Set(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.FETCH_FOLDER_SUCCESS:
      return Set(payload.result.folders);
    case aTypes.CREATE_FOLDER_SUCCESS:
      return state.add(payload.id);
    case aTypes.MOVE_FOLDER_SUCCESS:
      return state.delete(payload.id);
    case aTypes.DELETE_FOLDER_SUCCESS:
      return state.delete(payload.id);
    case aTypes.FETCH_FOLDER_FAILURE:
    case aTypes.CREATE_FOLDER_FAILURE:
      return state;
    default:
      return state;
  }
};

const currentFolder = (state: any = null, { type, payload }: Action) => {
  switch (type) {
    case aTypes.MOVE_FOLDER_SUCCESS:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return null;
    case aTypes.START_MOVE_FOLDER_PROCESS:
      return payload;
    default:
      return state;
  }
};

const isMovingFolder = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.START_MOVE_FOLDER_PROCESS:
      return true;
    case aTypes.MOVE_FOLDER_SUCCESS:
    case aTypes.MOVE_FOLDER_FAILURE:
    case aTypes.STOP_MOVE_FILE_PROCESS:
      return false;
    default:
      return state;
  }
};

const isCloning = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.CLONE_FILE_REQUEST:
      return true;
    case aTypes.CLONE_FILE_SUCCESS:
    case aTypes.CLONE_FILE_FAILURE:
      return false;
    default:
      return state;
  }
};

const activeFileMenu = (
  state: ActiveFileMenu = { name: null, type: "context" },
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.SHOW_FILE_MENU:
      return payload;
    case aTypes.HIDE_FILE_MENU:
      return { ...state, name: null, type: null };
    default:
      return state;
  }
};

const activeFolderMenu = (
  state: number | null = null,
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.SHOW_FOLDER_MENU:
      return payload.id;
    case aTypes.HIDE_FOLDER_MENU:
      return null;
    default:
      return state;
  }
};

const isPinning = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.START_PIN_FILE_PROCESS:
      return true;
    case aTypes.STOP_PIN_FILE_PROCESS:
    case aTypes.PIN_FILE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const viewerInitialState = { type: "", isOpen: false, url: "", name: "" };

const viewer = (
  state: FileViewer = viewerInitialState,
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.OPEN_FILE_VIEWER:
      return {
        type: payload.type,
        isOpen: true,
        url: payload.url
      };
    case aTypes.CLOSE_FILE_VIEWER:
      return { type: "", isOpen: false, url: "", name: "" };
    default:
      return state;
  }
};

const downloadBlocked = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.DOWNLOAD_FILE_FAILURE:
      return true;
    case aTypes.CLOSE_DOWNLOAD_PREVENTED_MODAL:
      return false;
    default:
      return state;
  }
};

const chatroomFiles = (
  state: Set<string> = Set(),
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.PIN_FILE_SUCCESS:
      return state.union([payload.name]);
    case aTypes.PIN_FILE_TO_CHAT_SUCCESS:
      return state.union([payload.name]);
    default:
      return state;
  }
};

const activeTab = (state: string = "file", { type, payload }: Action) => {
  switch (type) {
    case aTypes.FILE_TAB_CHANGE:
      return payload.name;
    default:
      return state;
  }
};

const searchResult = (state: Array<string> = [], { type, payload }: Action) => {
  switch (type) {
    case aTypes.SEARCH_RESULTS_FILE_SUCCESS:
      return R.map(R.prop("name"), payload.result);
    default:
      return state;
  }
};

const searchText = (state: string = "", { type, payload }: Action) => {
  switch (type) {
    case aTypes.SEARCH_FILE_REQUEST:
      return payload.searchText;
    default:
      return state;
  }
};

const filter = (state: string = "All", { type, payload }: Action) => {
  switch (type) {
    case aTypes.FILE_FILTER_CHANGE:
      return payload.filter;
    default:
      return state;
  }
};

const myDrive = (state: Folder | null = null, { type, payload }: Action) => {
  switch (type) {
    case aTypes.SET_MY_DRIVE:
      return payload;
    default:
      return state;
  }
};

const notificationMenu = (
  state: { show: boolean, name: string } = { show: false, name: "" },
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.SHOW_FILE_NOTIFICATION_MENU:
      return { show: true, name: payload.name };
    case aTypes.HIDE_FILE_NOTIFICATION_MENU:
      return { ...state, show: false };
    default:
      return state;
  }
};

const currentRevisionFile = (
  state: UnifizeFile | null = null,
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.START_FILE_REVISION:
      return payload.file;
    case aTypes.CANCEL_FILE_REVISION:
    case aTypes.UPLOAD_FILE_SUCCESS:
      return null;
    default:
      return state;
  }
};

const sortBy = (
  state: "name" | "viewed" | "modified" = "name",
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.CHANGE_FILE_SORTING:
      return payload.sortBy;
    default:
      return state;
  }
};

const isSortMenuOpen = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case aTypes.TOGGLE_FILE_SORTMENU:
      return payload.isOpen;
    case aTypes.CHANGE_FILE_SORTING:
      return false;
    default:
      return state;
  }
};

const manageFilesFilter = (
  state: ManageFilesFilter = {},
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.SET_FILE_SUCCESS:
      return payload.query || {};
    case aTypes.SET_FILE_REQUEST:
      return {};
    default:
      return state;
  }
};

const playVideo = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case aTypes.PLAY_VIDEO_FILE:
      return payload.url;
    case aTypes.CLOSE_VIDEO_PLAYER:
      return null;
    default:
      return state;
  }
};

const conversationFiles = (state = [], { type, payload }: Action) => {
  switch (type) {
    case aTypes.SEARCH_FILES_SUCCESS:
      return payload.searchedFiles;
    default:
      return state;
  }
};

const dropdownFileUploadProgress = (
  state: ?number = null,
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_SUCCESS:
    case aTypes.CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR:
      return null;
    case aTypes.DROPDOWN_FILE_UPLOAD_PROGRESS:
      return payload.progress;
    default:
      return state;
  }
};

const dropdownFileUploadError = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_FAILURE:
      return true;
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_SUCCESS:
    case aTypes.CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR:
      return false;
    default:
      return state;
  }
};

const dropdownFileUploadName = (
  state: ?string = null,
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_REQUEST:
      return payload.name;
    case aTypes.CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR:
      return null;
    default:
      return state;
  }
};

const uploadedFileId = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case aTypes.UPLOAD_FILE_IN_DROPDOWN_SUCCESS:
      return payload.name;
    case aTypes.CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR:
      return null;
    default:
      return state;
  }
};

const filePreviews = (state: FilePreviews = {}, { type, payload }: Action) => {
  switch (type) {
    case aTypes.GET_FILE_PREVIEW_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const fileThumbnails = (
  state: FileThumbnails = {},
  { type, payload }: Action
) => {
  switch (type) {
    case aTypes.GET_FILE_THUMBNAIL_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const dropdownFileUpload = combineReducers({
  name: dropdownFileUploadName,
  progress: dropdownFileUploadProgress,
  error: dropdownFileUploadError,
  id: uploadedFileId
});

const file = combineReducers<Object, Action>({
  filePreviews,
  fileThumbnails,
  dropdownFileUpload,
  conversationFiles,
  playVideo,
  byId,
  allIds,
  folderRoute,
  isUploading,
  uploadStateByRoomId,
  isDownloading,
  folderFile,
  folderById,
  folderAllIds,
  browseFolder,
  browseFolderFile,
  browseFolderRoute,
  currentFile,
  isMoving,
  currentFolder,
  isMovingFolder,
  isCloning,
  activeFileMenu,
  activeFolderMenu,
  isPinning,
  viewer,
  chatroomFiles,
  activeTab,
  searchResult,
  searchText,
  filter,
  myDrive,
  notificationMenu,
  currentRevisionFile,
  sortBy,
  isSortMenuOpen,
  manageFilesFilter,
  downloadBlocked
});

const getFilesById = (state: FileState) => state.byId;

const getFileAllIds = (state: FileState) => state.allIds;

const getSearchFileAllIds = (state: FileState) =>
  state.searchText ? state.searchResult : state.allIds;

const getFoldersById = (state: FileState) => state.folderById;

const getFolderAllIds = (state: FileState) => state.folderAllIds;

const getBrowseFile = (state: FileState) => state.browseFolderFile;

const getSortBy = (state: FileState) => state.sortBy;

const sortFiles = (files, arrangeBy) => {
  if (arrangeBy === "name") {
    return files.sortBy(f => f.originalName);
  }

  if (arrangeBy === "modified") {
    return files.sortBy(f => -f.versions.last().uploadTime);
  }
  // TODO: SortBy recent view
  return files;
};

const filterFiles = (files, filterType, arrangeBy) =>
  sortFiles(
    filterType === "All" ? files : files.filter(f => f.owner === filterType),
    arrangeBy
  );

const isFileInFolder = (fileFolders, folder) =>
  R.includes(folder ? folder.id : null, fileFolders);

const getChatroomFileIds = (state: FileState) =>
  state.searchText ? state.searchResult : state.chatroomFiles;

const getRoomFiles = createSelector(
  [getFilesById, getChatroomFileIds],
  (files, ids) => R.map(id => files.get(id), ids)
);

/* 
Get all files after appling search
*/
const getAllFiles = createSelector(
  [getSearchFileAllIds, getFilesById],
  (ids, files) => ids.map(id => files.get(id)).filter(f => f)
);

const getFilter = (state: FileState) => state.filter;

/*
Get all files after applying filter and search
*/
const getFiles = createSelector(
  [getAllFiles, getFilter, getSortBy],
  (files, filterType, arrangeBy) => filterFiles(files, filterType, arrangeBy)
);

const getFile = (state: FileState, id: string) => state.byId.get(id);

/**
 * Returns an array of UnifizeFiles based on the given ids.
 * @param {state}: The FileState of the app
 * @param {ids}: An array of UnifizeFile ids
 * @return An array of UnifizeFiles fetched from the given valid ids, undefined otherwise
 */
const getFilteredFiles = (
  state: FileState,
  ids: Array<string>
): ?Array<UnifizeFile> | Array<empty> => ids.map(id => state.byId.get(id));

const getFilePreviewURL = (state: FileState, name: string) =>
  state.filePreviews[name];

const getFileThumbnailURL = (state: FileState, name: string) =>
  state.fileThumbnails[name];

const getIsUploading = (state: FileState) => state.isUploading;

const getIsDownloading = (state: FileState) => state.isDownloading;

const getIsMoving = (state: FileState) => state.isMoving;

const getCurrentFile = (state: FileState) => state.currentFile;

const getFolders = createSelector(
  [getFoldersById, getFolderAllIds],
  (folders, ids) => R.map(id => folders[id], ids)
);

const getFolder = (state: FileState, id: number) => state.folderById[id];

const getFolderRoute = (state: FileState) => state.folderRoute;

const getFolderPath = (state: FolderState) =>
  state.size > 1
    ? [
        "files",
        state
          .shift()
          .map(d => d.id)
          .join("/files/"),
        "files"
      ].join("/")
    : "files";

const getBrowseFolders = (state: FileState) => state.browseFolder;

const getBrowseFolderFiles = createSelector(
  [getFilesById, getBrowseFile],
  (files, fileNames) => R.map(name => files.get(name), fileNames)
);

const getBrowseFolderRoute = (state: FileState) => state.browseFolderRoute;

const getLastFolder = (state: FileState) => state.folderRoute.last();

const getCurrentFolderFiles = createSelector(
  [getFiles, getLastFolder],
  (files, folder) =>
    folder && folder.id > 0
      ? files.filter(f => isFileInFolder(f.folders, folder))
      : files
);

const getFolderFiles = (state: FileState) => state.folderFile;

const getCurrentFolder = (state: FileState) => state.currentFolder;

const getIsMovingFolder = (state: FileState) => state.isMovingFolder;

const getIsCloning = (state: FileState) => state.isCloning;

const getFileMenu = (state: FileState) => state.activeFileMenu;

const getActiveFolderMenu = (state: FileState) => state.activeFolderMenu;

const getIsPinning = (state: FileState) => state.isPinning;

const getViewer = (state: FileState) => state.viewer;

const getChatroomFiles = createSelector(
  [getRoomFiles, getFilter],
  (files, filterType) => filterFiles(files, filterType)
);

const getActiveTab = (state: FileState) => state.activeTab;

const getNotificationMenu = (state: FileState) => state.notificationMenu;

const getCurrentRevisionFile = (state: FileState) => state.currentRevisionFile;

const getActiveFileMenu = createSelector(getFileMenu, activeMenu => activeMenu);

const getIsSortMenuOpen = (state: FileState) => state.isSortMenuOpen;

const getUploadStateById = (state: FileState, roomId: string) =>
  state.uploadStateByRoomId.get(`${roomId}`);

const getIsChatroomUploadingFile = (state: FileState, roomId: string) =>
  state.uploadStateByRoomId.has(`${roomId}`) &&
  !R.isEmpty(state.uploadStateByRoomId.get(`${roomId}`));

const getSearchResult = (state: FileState) => state.searchResult;

const getSearchedGroupedFiles = (
  state: AppState,
  roomId: RoomId
): {
  title: string[],
  templateId: number[],
  seqNo: number[]
} => {
  const searchedGroupedFiles = R.groupBy(searchedFile =>
    String(searchedFile.chatroomId)
  )(state.files.conversationFiles);

  // If current room exists in the list
  // then keep it at the beginning
  let roomIds = R.keys(searchedGroupedFiles);

  roomIds = roomIds.find(R.equals(roomId))
    ? R.uniq(R.prepend(roomId, roomIds))
    : roomIds;

  return {
    title: roomIds.map((id: string) => getChatroomTitle(state, id)),
    templateId: roomIds.map((id: string) => getTemplateId(state, id)),
    seqNo: roomIds.map((id: string) =>
      getSeqNoForSearchedGroupedFiles(state, id)
    )
  };
};

export {
  getFilesById,
  getSearchedGroupedFiles,
  getFileAllIds,
  getAllFiles,
  getFiles,
  getFilteredFiles,
  getFile,
  getIsUploading,
  getIsDownloading,
  getIsMoving,
  getCurrentFile,
  getFolder,
  getFolders,
  getFolderRoute,
  getFolderPath,
  getBrowseFolders,
  getBrowseFolderFiles,
  getBrowseFolderRoute,
  getLastFolder,
  getCurrentFolderFiles,
  getFolderFiles,
  getCurrentFolder,
  getIsMovingFolder,
  getIsCloning,
  getFileMenu,
  getActiveFolderMenu,
  getIsPinning,
  getViewer,
  getChatroomFiles,
  getActiveTab,
  getNotificationMenu,
  getCurrentRevisionFile,
  getActiveFileMenu,
  getFilter,
  getSortBy,
  getIsSortMenuOpen,
  getUploadStateById,
  getIsChatroomUploadingFile,
  getSearchResult,
  getFilePreviewURL,
  getFileThumbnailURL
};

export default file;
