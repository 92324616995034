// @flow

import * as R from "ramda";
import { select, call, put, all, delay } from "redux-saga/effects";

import { getCurrentUserId, getOrgCloneState } from "src/selectors";
import type { OrgCloneState } from "src/types";
import * as orgCloneActions from "src/actions/orgClone";
import * as organizationActions from "src/actions/organization";

import * as authApi from "src/api/auth";
import * as orgCloneApi from "src/api/orgClone";

import { validateEmail, getPasswordStrength } from "src/utils";
import { orgCloneWizardSteps, passwordStrengths } from "src/constants";
import * as atypes from "src/constants/actionTypes";

function* emailStageHandler(): any {
  try {
    const orgCloneState: OrgCloneState = yield select(getOrgCloneState);
    const location = yield select(state => state.location);

    const { cloneToken } = location.query;
    const orgId = Number(location.query.orgId);

    const email = orgCloneState.wizard.fields.email.value;

    const isEmailValid = validateEmail(email);

    // If email is valid then check if email belongs to user
    if (isEmailValid) {
      // Clear errors
      yield put(orgCloneActions.clearDataError(["email"]));

      const isUser = yield call(authApi.isExistingUser, email);

      if (isUser) {
        yield put(orgCloneActions.setStep(orgCloneWizardSteps.signIn));
      } else {
        yield call(orgCloneApi.sendVerificationEmail, {
          email,
          orgId,
          cloneToken
        });

        yield put(
          orgCloneActions.setStep(orgCloneWizardSteps.verificationEmailSent)
        );
      }
    } else {
      // If email is invalid then show error
      yield put(
        orgCloneActions.goForwardError({
          path: ["fields", "email"],
          error: "Invalid email"
        })
      );
    }
  } catch (error) {
    console.error(error);
    const errorMessage = error.message || "Something went wrong";

    yield put(
      orgCloneActions.goForwardError({
        error: errorMessage
      })
    );
  }
}

function* signUpHandler(): any {
  const orgCloneState: OrgCloneState = yield select(getOrgCloneState);
  const location = yield select(state => state.location);

  const { cloneToken, authCode } = location.query;
  const orgId = Number(location.query.orgId);

  const { fields: data } = orgCloneState.wizard;

  const fields = ["password", "confirmPassword", "firstName"];

  const emptyFields = fields.filter(field => R.isEmpty(data[field].value));

  // Clear existing errors
  yield put(orgCloneActions.clearDataError(fields));

  // If any field is empty then throw an error and return
  if (!R.isEmpty(emptyFields)) {
    yield all(
      emptyFields.map(field =>
        put(
          orgCloneActions.goForwardError({
            path: ["fields", field],
            error: "Field is required"
          })
        )
      )
    );

    return;
  }

  const passwordStrength = getPasswordStrength(data.password.value);
  if (passwordStrength !== passwordStrengths.strong) {
    yield put(
      orgCloneActions.goForwardError({
        path: ["fields", "password"],
        error: "Password is not strong"
      })
    );

    return;
  }

  // If passwords don't match then throw an error and return
  if (data.password.value !== data.confirmPassword.value) {
    yield put(
      orgCloneActions.goForwardError({
        path: ["fields", "confirmPassword"],
        error: "Passwords do not match"
      })
    );

    return;
  }

  try {
    const displayName = `${data.firstName.value} ${data.lastName.value}`.trim();
    const res = yield call(orgCloneApi.signUp, {
      email: data.email.value,
      password: data.password.value,
      displayName,
      orgId,
      cloneToken,
      authCode
    });

    yield call(authApi.signInWithCustomAuthToken, res.customToken);
  } catch (error) {
    console.error(error);

    const errorMessage =
      error.message || "Could not create user. Please try again";

    yield put(
      orgCloneActions.goForwardError({
        error: errorMessage
      })
    );
  }
}

function* createOrgHandler(): any {
  try {
    const orgCloneState: OrgCloneState = yield select(getOrgCloneState);

    const data = orgCloneState.wizard.fields;

    const invalidInvitees = data.colleagues
      .map((colleague, index) => {
        if (R.isEmpty(colleague.value) || validateEmail(colleague.value))
          return { index, invalid: false };

        return { index, invalid: true };
      })
      .filter(item => item.invalid);

    if (!R.isEmpty(invalidInvitees)) {
      yield all(
        invalidInvitees.map(item =>
          put(
            orgCloneActions.goForwardError({
              path: ["fields", "colleagues", item.index],
              error: "Invalid email"
            })
          )
        )
      );

      return;
    }

    const location = yield select(state => state.location);

    const orgId = Number(location.query.orgId);

    const getInvitees = R.pipe(R.pluck("value"), R.reject(R.isEmpty));

    const payload = {
      templateOrgId: orgId,
      title: data.orgName.value,
      invitees: getInvitees(data.colleagues),
      token: location.query.cloneToken
    };

    const res = yield call(orgCloneApi.cloneOrg, payload);

    const clonedOrgId = res.id;

    let orgCloneInProgress = true;
    let cloneStatusResponse = null;

    while (orgCloneInProgress) {
      cloneStatusResponse = yield call(orgCloneApi.getOrgCloneStatus, {
        orgId: clonedOrgId
      });

      if (cloneStatusResponse.progress !== "ongoing") {
        orgCloneInProgress = false;
      } else {
        yield put({
          type: atypes.ORG_CLONE_SET_CLONE_STATUS,
          payload: {
            progress: cloneStatusResponse.progress,
            state: cloneStatusResponse.state
          }
        });
        yield delay(500);
      }
    }

    if (!cloneStatusResponse) throw new Error();

    yield put({
      type: atypes.ORG_CLONE_SET_CLONE_STATUS,
      payload: {
        progress: cloneStatusResponse.progress,
        state: cloneStatusResponse.state
      }
    });

    if (cloneStatusResponse.progress === "failed") throw new Error();

    const uid = yield select(getCurrentUserId);

    yield put(organizationActions.setCurrentOrg(clonedOrgId, uid, true));
  } catch (error) {
    console.error(error);
    const errorMessage = error.message || "Could not create org";

    yield put(
      orgCloneActions.goForwardError({
        error: errorMessage
      })
    );
  }
}

function* signInHandler(): any {
  const { wizard }: OrgCloneState = yield select(getOrgCloneState);

  try {
    const email = wizard.fields.email.value;
    const password = wizard.fields.password.value;

    yield call(authApi.signInUser, email, password);
  } catch (error) {
    console.error(error);
    const errorMessage = error.message || "Could not sign in";
    yield put(
      orgCloneActions.goForwardError({
        error: errorMessage
      })
    );
  }
}

const handlers = {
  [orgCloneWizardSteps.email]: emailStageHandler,
  [orgCloneWizardSteps.signUp]: signUpHandler,
  [orgCloneWizardSteps.createOrg]: createOrgHandler,
  [orgCloneWizardSteps.signIn]: signInHandler
};

export default handlers;
