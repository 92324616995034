// @flow

import React, { useRef, useMemo } from "react";
import type { Node } from "react";

import {
  AccordionSection,
  StatusType,
  AccordionTitle,
  AccordionContent
} from "./styles";
import { AccordionIcon, AccordionText } from "src/components/Accordion/styles";
import Icon from "src/icons";

type Props = {
  title: string,
  children: Node,
  isExpanded: boolean,
  toggleActive: Function,
  isSectionField: boolean,
  hasMandatoryFields: boolean
};

const Accordion = ({
  title,
  children,
  isExpanded,
  toggleActive,
  isSectionField,
  hasMandatoryFields
}: Props) => {
  const contentRef = useRef<Element | null>(null);

  const scrollHeight = useMemo(() => {
    if (contentRef && contentRef.current) {
      return `${contentRef.current.scrollHeight}px`;
    }
    return "auto";
  }, [contentRef]);

  return (
    <AccordionSection isSectionField={isSectionField}>
      <AccordionTitle
        onClick={toggleActive}
        isSectionField={isSectionField}
        hasMandatoryFields={hasMandatoryFields}
      >
        <AccordionText>
          <StatusType hasMandatoryFields={hasMandatoryFields}>
            {title}
          </StatusType>
        </AccordionText>
        <AccordionIcon active={isExpanded}>
          <Icon type="downIcon" />
        </AccordionIcon>
      </AccordionTitle>
      <AccordionContent
        active={isExpanded}
        scrollHeight={scrollHeight}
        isSectionField={isSectionField}
      >
        {isExpanded ? children : null}
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
