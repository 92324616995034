// @flow

import React from "react";

import { AddNewUserButton as Button } from "./styles";
import { TextWithputBorders as SearchInput } from "src/styles/input";
import useInput from "src/hooks/useInput";
import useClickoutside from "src/hooks/useClickoutside";

type Props = {
  outerRef: any,
  handleInvite: Function,
  handleClose: Function
};

const AddNewUser = ({ outerRef, handleInvite, handleClose }: Props) => {
  const { value: email, handleChange } = useInput("");
  useClickoutside({ outerRef, handleClose });

  return (
    <>
      <SearchInput
        type="text"
        placeholder="e.g. john.doe@xyz.com"
        autoComplete="off"
        value={email}
        onChange={handleChange}
        autoFocus
      />
      <Button
        type="button"
        disabled={email.length === 0}
        onClick={() => handleInvite(email)}
      >
        &#43; Add{" "}
      </Button>
    </>
  );
};

export default AddNewUser;
