// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { FormGroup, SecondaryFooter } from "src/styles/form";
import { SystemCSSProperties } from "@chakra-ui/react";

export const ProcessOverlay = styled.div`
  z-index: 2;
  position: fixed;
  top: 0px;
  left: 54px;
  right: 0px;
  bottom: 0px;
  border: none;
  background-color: ${colors.brightLight};
  max-height: 100vh;
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding-left: 0;
  width: max-content;
  border-bottom: 1px solid #e2e8f0;
`;

export const NavItem = styled.li`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${props => (props.active ? "#2a69ac" : "#000")};
  border-bottom: ${props =>
    props.active ? "2px solid #2A69AC" : "1px solid #E2E8F0"};
  cursor: pointer;

  &:hover {
    color: #2a69ac;
    border-bottom: 2px solid #2a69ac;
  }
`;

export const NavItemBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0.625rem 1.25rem;
`;

export const StyledFooter = styled.footer`
  background: ${colors.grey0};
  z-index: 3;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 16px 30px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ProcessBuilderFooter = styled(StyledFooter)`
  justify-content: space-between;
`;

export const WrappingInput = styled.div`
  position: relative;

  > div:first-of-type {
    display: inline-flex;
    align-items: flex-end;
    color: ${colors.secondary};
    margin-right: ${spacing.space_s};
    background: ${colors.brightLight};
  }

  > span {
    font-weight: bold;
    outline: none;
  }
`;

export const SettingsContainer = styled.div`
  margin-bottom: 6.875rem;
`;

export const tabListContainer = {
  "border-bottom": "2px solid #E2E8F0",
  "padding-left": 0,
  width: "100%",
  position: "relative",
  top: "4px",
  right: "0px",
  bottom: "0px"
};

export const tabContainer = {
  "background-color": "#fff",
  "font-size": "0.875rem",
  "font-weight": 600,
  padding: "0.625rem 1.25rem",
  outline: "none",
  "border-bottom": "2px solid #E2E8F0",
  textTransform: "capitalize"
};

export const tabsContainer = {
  height: "480px"
};

export const ProcessBuilder = styled.div`
  z-index: 999;
  position: absolute;
  min-height: 185px;
  padding: 0px;
  overflow: visible;
  border: none;
  border-radius: 0px;
  margin-left: "40%";
  left: auto;
  right: 0px;
  top: 0px;
  bottom: auto;
  font-size: 12px;
`;

export const ToggleAdvanced = styled(FormGroup)`
  > button {
    padding-left: 0.6em;
    text-align: left;
    font-size: 0.75rem;
  }
`;

export const Footer = styled(SecondaryFooter)`
  > button:last-child {
    width: 7em;
  }
`;

export const InlineFormGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const processTitleInput: SystemCSSProperties = {
  border: "none",
  padding: "1rem",
  fontSize: "1.5rem",
  lineHeight: "2rem",
  fontWeight: 700
};
