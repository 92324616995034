// @flow

import React from "react";
import moment from "moment";

type Props = {
  value: any
};

const Date = ({ value }: Props) => (
  <>{value ? moment(value).format("MMM DD, YYYY") : null}</>
);

export default Date;
