// @flow

import React from "react";
import * as R from "ramda";

import Checkbox from "src/components/Checkbox";
import Name from "src/components/Status/Name";
import { BlanksNonBlanks as StyledBlanksNonBlanks } from "./styles";

type Props = {
  handleSelect: Function,
  filter: ?Array<string>
};

const BlanksNonBlanks = ({ handleSelect, filter }: Props) => (
  <>
    <StyledBlanksNonBlanks onClick={event => handleSelect(event, "null")}>
      <Checkbox checked={R.includes("null", filter || [])} />
      <Name id={"Blank"} />
    </StyledBlanksNonBlanks>
    <StyledBlanksNonBlanks onClick={event => handleSelect(event, "notnull")}>
      <Checkbox checked={R.includes("notnull", filter || [])} />
      <Name id={"Non-Blank"} />
    </StyledBlanksNonBlanks>
  </>
);

export default BlanksNonBlanks;
