// @flow
import * as atypes from "src/constants/actionTypes";

import type { ActiveFilter } from "src/types";

const set = (payload: ActiveFilter) => ({
  type: atypes.SET_ACTIVE_FILTER,
  payload
});

const clear = () => ({
  type: atypes.CLEAR_ACTIVE_FILTER,
  payload: {}
});

export { set, clear };
