// @flow

import uuid from "uuid/v4";
import { useCallback, useRef, useEffect } from "react";
import { getWindowProperties } from "src/utils";

const useOAuthIntegrationWindow = ({
  app,
  callback
}: {
  app: string,
  callback?: Function
}) => {
  const authWindowDataRef = useRef<{
    win: any,
    channel: BroadcastChannel
  } | null>(null);

  const resetAuthWindowData = useCallback(() => {
    if (!authWindowDataRef.current) return;

    const { win: authWindow, channel } = authWindowDataRef.current;

    authWindow.close();
    window.focus();

    channel.close();

    authWindowDataRef.current = null;
  }, []);

  const handleCallback = useCallback(() => {
    callback && callback(app);
  }, []);

  const start = (url: string) => {
    resetAuthWindowData();

    const windowId = uuid();

    const channel = new BroadcastChannel(windowId);

    // TODO: needs to allow user defined listeners
    channel.onmessage = (event: any) => {
      if (event.data.app === app && event.data.success) {
        resetAuthWindowData();
        handleCallback();
      }
    };

    const windowProperties = getWindowProperties(800, 600);
    const authWindow = window.open(url, windowId, windowProperties);
    authWindowDataRef.current = { win: authWindow, channel };
  };

  useEffect(() => {
    return () => {
      resetAuthWindowData();
    };
  }, [resetAuthWindowData, app]);

  return { ref: authWindowDataRef, start, reset: resetAuthWindowData };
};

export default useOAuthIntegrationWindow;
