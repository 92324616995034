// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { Button as DefaultButton } from "src/styles/buttons";
import { MenuBar } from "src/components/Dashboard/Body/styles";
import { ButtonLink } from "src/styles/buttons";
import { Loader } from "src/components/Dashboard/Body/styles";
import { LoadingContainer as StyledLoadingContainer } from "src/components/Dashboard/styles";

export const Homescreen = styled.div`
  padding: 0 1.5em;
  padding-right: 0.5em;
  clear: both;
  > h2 {
    margin: 0 -1.5em;
    padding: 0.6em 1.5em;
  }

  ${StyledLoadingContainer} {
    min-height: 70vh;
  }
`;

export const Header = styled.h1`
  margin-bottom: 0;
`;

export const Section = styled.div`
  margin-bottom: 1rem;

  > ul:first-of-type {
    max-height: 90vh;
    overflow: auto;
  }
  > h2 {
    margin-bottom: 0;
  }
  > p {
    margin-top: 0.5em;
    padding-left: 2px;
  }
`;

export const Tile = styled.div`
  background-color: ${props => props.color};
  width: 100%;
  min-height: 80px;
  margin-bottom: 1em;
  border-radius: 8px;
  padding: 1em;
  > h4:first-of-type {
    margin: 0;
  }
`;

export const Button = styled(DefaultButton)`
  background: transparent;
  color: ${colors.activeFilter};
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 12.5px;
  :hover,
  :focus {
    background-color: ${colors.activeFilter};
    color: ${colors.brightLight};
    border: 1px solid ${colors.activeFilter};
    box-shadow: none;
  }
`;

export const ChatroomName = styled.div`
  color: ${colors.primary};
  font-size: 13px;
`;

export const Conversation = styled(Tile)`
  font-size: 14px;
  min-height: 80px;
  ${props => props.clickable && "cursor: pointer;"}
  > ul {
    max-height: 15em;
    margin-bottom: 0.3em;
    margin-left: 0;
    padding-left: 1.3em;
    margin-top: 4px;
  }
  ${Button} {
    display: block;
    width: auto;
    margin-top: 8px;
  }
  ${ButtonLink} {
    padding-left: 1.3em;
    font-size: 13px;
    font-weight: bold;
  }
  > p {
    margin-bottom: 0;
  }
`;

export const StartNew = styled(Tile)`
  > h4:first-of-type {
    margin-bottom: 0.6em;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    align-items: flex-start;
    min-height: 50px;

    ${Button} {
      height: 3em;
    }

    > button:nth-of-type(1) {
      grid-column: ${props => `span ${props.tile1}`};
    }

    > button:nth-of-type(2) {
      grid-column: ${props => `span ${props.tile2}`};
    }

    > button:nth-of-type(3) {
      grid-column: ${props => `span ${props.tile3}`};
    }

    > button:nth-of-type(4) {
      grid-column: ${props => `span ${props.tile4}`};
    }

    > button:nth-of-type(3),
    > button:nth-of-type(4) {
      margin-bottom: 10px;
    }
  }
`;

export const MyConversationChart = styled(Tile)`
  position: relative;
  > div {
    height: 35em;
  }
  ${Loader}> img {
    height: 30em;
    width: 30em;
  }
`;

export const SubTitle = styled.p`
  margin-top: 4px;
  padding-left: 4px;
  font-size: 12px;
  color: ${colors.grey21};
`;

export const SectionContainer = styled.div`
  max-height: 98vh;
  margin-top: 8px;
  overflow: auto;
  padding-right: 12px;
`;

export const Chart = styled.div`
  outline: none;
  min-width: 400px;
  height: 450px;
  border-radius: 10px;
  ${Loader}>img {
    height: 30em;
    width: 30em;
  }

  ${MenuBar} {
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 1;
    visibility: visible;
    > button {
      background: transparent;
    }
  }
  :hover,
  :focus {
    ${MenuBar} {
      visibility: visible;
    }
  }
`;

export const ListItem = styled.li`
  font-size: 14px;
`;

export const Empty = styled.div`
  width: 100%;
  > img {
    display: block;
    margin: auto;
    background-color: ${colors.grey28};
    border-radius: 50%;
    padding: 30px;
    height: 300px;
  }
  > h2 {
    text-align: center;
  }
  > p:last-child {
    text-align: center;
  }
`;

export const ExpandIcon = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
  background: none;
`;
