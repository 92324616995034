// @flow

import React from "react";

import UserSelect from "src/containers/user/select";
import { FormGroup, Label } from "src/styles/form";

import type { UID } from "src/types";

type Props = {
  owner: ?UID,
  handleOwner: Function
};

const Owner = ({ owner, handleOwner }: Props) => (
  <FormGroup>
    <Label>Owner</Label>
    <UserSelect value={owner} handleChange={handleOwner} />
  </FormGroup>
);

export default Owner;
