// @flow

import React from "react";

const Back = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    fill="none"
    viewBox="0 0 14 11"
  >
    <path
      stroke="#111"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 5.5H.5M5.5.5l-5 5 5 5"
    />
  </svg>
);

export default Back;
