// @flow

import { connect } from "react-redux";
import moment from "moment";
import * as R from "ramda";

import { getFile } from "src/reducers";

import type { AppState, UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile,
  name: string
};

const UploadedTime = ({ file }: Props) => {
  let time = "";
  if (file) {
    if (file.versions) {
      const { versions } = file;
      const version = R.last(versions || []) || {};
      const { uploadTime } = version;
      if (uploadTime && uploadTime) {
        time = moment(uploadTime).format("MMM DD, YYYY");
      }
    }
  }

  return time;
};

const mapStateToProps = ({ app }: { app: AppState }, { name }) => ({
  file: getFile(app, name)
});

export default connect(mapStateToProps)(UploadedTime);
