// @flow

import { put, select } from "redux-saga/effects";
import * as orgCloneActions from "src/actions/orgClone";
import { orgCloneWizardSteps } from "src/constants";
import * as atypes from "src/constants/actionTypes";

function* signInHandler(): any {
  const location = yield select(state => state.location);

  yield put({
    type: atypes.ORG_CLONE_PAGE,
    meta: {
      query: {
        cloneToken: location.query.cloneToken,
        orgId: location.query.orgId
      }
    }
  });

  yield put(orgCloneActions.setStep(orgCloneWizardSteps.email));
}

function* signUpHandler(): any {
  const location = yield select(state => state.location);

  yield put({
    type: atypes.ORG_CLONE_PAGE,
    meta: {
      query: {
        cloneToken: location.query.cloneToken,
        orgId: location.query.orgId
      }
    }
  });

  yield put(orgCloneActions.setStep(orgCloneWizardSteps.email));
}

const handlers = {
  [orgCloneWizardSteps.signIn]: signInHandler,
  [orgCloneWizardSteps.signUp]: signUpHandler
};

export default handlers;
