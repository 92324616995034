// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { ListItem, Box } from "@chakra-ui/react";
import Name from "src/containers/workflow/Name";
import type { AppState } from "src/types";
import { getWorkflowName } from "src/reducers";
import { hideFilterModal } from "src/actions/header";
import {
  setWorkflowFilter,
  clearType,
  updateSuggestedWorkflows
} from "src/actions/filter";
import { clear } from "src/actions/activeFilter";
import { showView } from "src/actions";

type Props = {
  id: number,
  _setWorkflowFilter: Function,
  processName: string,
  _hideFilterModal: Function,
  _clearType: Function,
  _clearActiveFilter: Function,
  emptySearchField: Function,
  _showView: Function,
  _updateSuggestedWorkflows: Function
};

const Listitem = ({
  id,
  _setWorkflowFilter,
  processName,
  _hideFilterModal,
  _clearType,
  _clearActiveFilter,
  emptySearchField,
  _showView,
  _updateSuggestedWorkflows
}: Props) => {
  const handleSelect = useCallback(
    (workflowId: number) => {
      _clearType();
      _clearActiveFilter();
      _setWorkflowFilter(workflowId, processName);
      _showView(processName);
      _updateSuggestedWorkflows(workflowId);
      _hideFilterModal();
      emptySearchField();
    },
    [
      _clearType,
      _clearActiveFilter,
      _setWorkflowFilter,
      _showView,
      processName,
      _updateSuggestedWorkflows,
      _hideFilterModal,
      emptySearchField
    ]
  );

  return (
    <ListItem
      onClick={() => handleSelect(id)}
      py="2"
      px="3"
      cursor="pointer"
      _hover={{
        background: "gray.100"
      }}
    >
      <Box ml="2">
        <strong>
          <Name id={id} />
        </strong>
      </Box>
    </ListItem>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  processName: getWorkflowName(app, id)
});

export default connect(mapStateToProps, {
  _setWorkflowFilter: setWorkflowFilter,
  _hideFilterModal: hideFilterModal,
  _clearActiveFilter: clear,
  _clearType: clearType,
  _showView: showView,
  _updateSuggestedWorkflows: updateSuggestedWorkflows
})(Listitem);
