// @flow

import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

export const toggleStatusDropdown = () => ({
  type: atypes.TOGGLE_STATUS_DROPDOWN,
  payload: {}
});

export const toggleParticipants = (location: string): Action => ({
  type: atypes.TOGGLE_PARTICIPANT_DROPDOWN,
  payload: {
    location
  }
});

export const toggleChatroomOverlay = (location: string): Action => ({
  type: atypes.TOGGLE_CHATROOM_OVERLAY,
  payload: {
    location
  }
});

export const showFilterModal = (): Action => ({
  type: atypes.SHOW_FILTER_MODAL,
  payload: {}
});

export const hideFilterModal = (): Action => ({
  type: atypes.HIDE_FILTER_MODAL,
  payload: {}
});

export const toggleFilterModal = (): Action => ({
  type: atypes.TOGGLE_FILTER_MODAL,
  payload: {}
});

export const searchQuery = (text: string): Action => ({
  type: atypes.SET_CONVERSATION_TYPE_SEARCH,
  payload: { text }
});

export const navigatedFromHeader = (): Action => ({
  type: atypes.NAVIGATED_FROM_HEADER,
  payload: {}
});
