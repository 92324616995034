// @flow

import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import { Select } from "@chakra-ui/react";
import { Invite as StyledInvite, styles } from "./styles";
import { validateEmail } from "src/utils";
import { RelativeBox } from "src/styles/box";
import { Button, Secondary as SecondaryButton } from "src/styles/buttons";
import { BlockFormGroup, InlineLabel as Label, Footer } from "src/styles/form";
import { Input } from "src/styles/input";
import { roleTitles } from "src/constants/roleManagement";

import type { RoomId } from "src/types";

type Props = {
  text?: string,
  roomId: RoomId,
  outerRef: any,
  handleClose: Function,
  sendUserInviteRequest: Function,
  roles: Array<{ id: number, title: string, label: string }>
};

type State = {
  displayName: string,
  email: string,
  role: string
};

class Invite extends Component<Props, State> {
  static defaultProps = {
    text: ""
  };

  state = {
    displayName: "",
    email: "",
    role: roleTitles.member
  };

  componentDidMount() {
    const { text } = this.props;
    if (text && validateEmail(text)) {
      this.setState({ email: text });
    } else {
      this.setState({ displayName: text });
    }
  }

  handleKeyDown = (event: any) => {
    // Handle Enter
    if (event.keyCode === 13) {
      this.inviteUser(event);
    }
  };

  handleClickOutside = (e: any) => {
    const { outerRef, handleClose } = this.props;
    if (outerRef) {
      if (!outerRef.contains(e.target)) {
        handleClose();
      }
    }
  };

  handleChange = (e: any) => {
    const { value, id } = e.target;
    this.setState({ [id]: value });
  };

  handleRoleChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    e.preventDefault();
    this.setState({ role: e.target.value });
  };

  inviteUser = (e: any) => {
    e.preventDefault();
    const { roomId, sendUserInviteRequest, handleClose } = this.props;
    const { email, displayName, role } = this.state;
    handleClose();
    sendUserInviteRequest({
      email,
      orgRole: role,
      chatRoomId: parseInt(roomId, 10),
      displayName
    });
  };

  preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { handleClose, roles } = this.props;
    const { email, displayName, role } = this.state;

    return (
      <RelativeBox onClick={this.preventDefault} onKeyDown={this.handleKeyDown}>
        <StyledInvite>
          <BlockFormGroup>
            <Label htmlFor="displayName">Full Name (optional)</Label>
            <Input
              id="displayName"
              type="text"
              onChange={this.handleChange}
              value={displayName}
              placeholder="Full Name"
              autoComplete="off"
              autoFocus
            />
          </BlockFormGroup>

          <BlockFormGroup>
            <Label htmlFor="email">Email Address</Label>
            <Input
              id="email"
              type="email"
              onChange={this.handleChange}
              value={email}
              autoComplete="off"
              placeholder="email@company.com"
            />
          </BlockFormGroup>
          <BlockFormGroup>
            <Label>Select role</Label>
            <Select
              value={role}
              onChange={this.handleRoleChange}
              size="sm"
              {...styles.roleSelect}
            >
              {roles.map(role => (
                <option key={role.id} value={role.title}>
                  {role.label}
                </option>
              ))}
            </Select>
          </BlockFormGroup>
          <Footer>
            <SecondaryButton type="button" onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <Button
              type="button"
              onClick={this.inviteUser}
              disabled={email.length === 0}
            >
              Send Invite
            </Button>
          </Footer>
        </StyledInvite>
      </RelativeBox>
    );
  }
}

export default onClickOutside(Invite);
