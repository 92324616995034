// @flow

import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";

import { getProcessBuilderMentions } from "src/reducers";
import type { State } from "src/types";

import renderSuggestion from "src/components/chatroom/Input/RenderSuggestion";
import { Container } from "./MentionInput.styles";

type Props = {
  value: string,
  onChange: Function
};

const MentionInput = ({ value, onChange }: Props) => {
  const inputRef = useRef(null);

  const defaultMentions = useSelector(({ app }: State) =>
    getProcessBuilderMentions(app)
  );

  const mentions = [
    {
      id: "!signatories",
      display: "@Signatories"
    },
    ...defaultMentions
  ];

  const handleFocus = useCallback(() => {
    if (inputRef && inputRef.current) {
      const element = inputRef.current;
      element.focus();
    }
  }, [inputRef]);

  return (
    <Container onClick={handleFocus}>
      <MentionsInput
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={mentions}
          className="mention-suggestion"
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          markup={`@[__display__]<@__id__>`}
        />
      </MentionsInput>
    </Container>
  );
};

export default MentionInput;
