// @flow

import React, { Component } from "react";

import SelectUserMultiple from "src/components/user/SelectMultipleInline";
import Checkbox from "src/components/Checkbox.old";
import { ChecklistSettings } from "src/styles/checklist";
import type { UID, UserFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: UserFieldSettings,
  saveSettings: Function
};

type State = {
  users: Array<UID>,
  multiple: ?boolean
};

class User extends Component<Props, State> {
  state = {
    users: [],
    multiple: false
  };

  componentDidMount() {
    const { settings } = this.props;
    if (settings) {
      this.setState(settings);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.users !== this.state.users ||
      prevState.multiple !== this.state.multiple
    ) {
      this.props.saveSettings(this.state);
    }
  }

  handleChecked = (e: any) => {
    const { id, checked } = e.target;
    this.setState({ [id]: checked });
  };

  handleUsers = (users: Array<string>) => {
    this.setState({ users });
  };

  render() {
    const { multiple, users } = this.state;

    return (
      <ChecklistSettings>
        <form>
          <div>
            <label htmlFor="user">User</label>
            <SelectUserMultiple
              id="users"
              addAttribute={this.handleUsers}
              value={users}
            />
          </div>

          <div>
            <Checkbox
              handleChange={this.handleChecked}
              id="multiple"
              checked={Boolean(multiple)}
            >
              Multiple
            </Checkbox>
          </div>
        </form>
      </ChecklistSettings>
    );
  }
}

export default User;
