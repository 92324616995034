// @flow

import React from "react";

type Props = {
  size?: number
};

const Spinner = ({ size = 20 }: Props) => (
  <svg
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3740_1291)">
      <path
        d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
        stroke="url(#paint0_linear_3740_1291)"
        strokeWidth="3.33333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47703 0 0 4.47703 0 10H3.33333C3.33333 6.31797 6.31797 3.33333 10 3.33333C10.9205 3.33333 11.6667 2.58714 11.6667 1.66667C11.6667 0.746192 10.9205 0 10 0Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3740_1291"
        x1="6.35908"
        y1="12.29"
        x2="14.1049"
        y2="4.63049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Spinner;
