// @flow

import React from "react";
import { connect } from "react-redux";

import { setChecklistBuilderAttribute } from "src/actions/checklistBuilder";
import PromptRules from "../PromptRules";
import type { AppState, ChecklistBuilderField } from "src/types";

type Props = {
  checklistFieldPosition: number,
  currentFields: ChecklistBuilderField[],
  setChecklistBuilderAttribute: Function
};

const Prompts = ({
  setChecklistBuilderAttribute,
  currentFields,
  checklistFieldPosition
}: Props) => {
  const promptRules = currentFields[checklistFieldPosition]?.promptRules;

  const saveSettings = (rules: any) => {
    const newFields = currentFields.map((field, idx) => {
      if (idx === checklistFieldPosition) {
        return {
          ...field,
          promptRules: rules
        };
      }

      return field;
    });

    setChecklistBuilderAttribute({ id: "fields", value: [...newFields] });
  };

  return <PromptRules promptRules={promptRules} saveSettings={saveSettings} />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentFields: app.checklistBuilder.current.fields,
  checklistFieldPosition:
    app.workflow.builderDialog.checklistFieldSettings.position
});

export default connect(mapStateToProps, { setChecklistBuilderAttribute })(
  Prompts
);
