// @flow

import React from "react";
import { Button } from "src/components/Manage/Workflow/Instances/MenuBar/styles.js";
import { SelectAllContainer } from "./styles";

type Props = {
  onSelectAll: Function,
  onSelectNone: Function
};

const SelectAll = ({ onSelectAll, onSelectNone }: Props) => {
  return (
    <SelectAllContainer>
      <Button type="button" onClick={onSelectAll}>
        <span>Select All</span>
      </Button>
      <Button type="button" onClick={onSelectNone}>
        <span>Select None</span>
      </Button>
    </SelectAllContainer>
  );
};

export default SelectAll;
