// @flow

import { connect, useSelector } from "react-redux";
import React from "react";

import { Status as StyledStatus } from "./styles";
import ChatroomStatus from "./Status";
import { getChatroomHideStatus, getFieldWidth } from "src/reducers";
import { ValueWrapper } from "../ChecklistItem/styles";

import type { AppState, ColumnId } from "src/types";

type Props = {
  id: string,
  selected: boolean,
  hide: ?boolean,
  value: number,
  index: number,
  depth: number,
  parentId: number,
  embedded?: boolean,
  columnId?: ColumnId
};

const Status = ({
  id,
  selected = false,
  hide,
  value,
  index,
  parentId,
  depth,
  embedded = false,
  columnId
}: Props) => {
  const fieldId = columnId ? `${columnId}-status` : "status";
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, fieldId));

  return (
    <StyledStatus width={fieldWidth} selected={selected} embedded={embedded}>
      <ValueWrapper showDropdown={true}>
        {hide ? (
          <i
            style={{
              maxWidth: fieldWidth,
              overflow: "hidden"
            }}
          >
            {embedded ? "" : "Disabled"}
          </i>
        ) : (
          <ChatroomStatus
            selected={selected}
            roomId={`${id}`}
            value={value}
            depth={depth}
            parentId={parentId}
            index={index}
            columnId={columnId}
            fieldWidth={fieldWidth}
          />
        )}
      </ValueWrapper>
    </StyledStatus>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  showStatus: getChatroomHideStatus(app, props.templateId || "")
});

export default connect(mapStateToProps)(Status);
