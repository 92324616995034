// @flow

import { SystemCSSProperties } from "@chakra-ui/react";

export const ModalContent: SystemCSSProperties = {
  maxWidth: "65.75rem",
  height: "30rem"
};

export const ModalBody: SystemCSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  my: 4
};

export const SelectCSVButton: SystemCSSProperties = {
  cursor: "pointer",
  mx: "auto"
};

export const SubmitButton: SystemCSSProperties = {
  mt: 4,
  mx: "auto"
};

export const Dropdown: SystemCSSProperties = {
  maxHeight: "20rem",
  overflowY: "auto"
};

export const ChecklistFields: SystemCSSProperties = {
  px: "2rem"
};
