// @flow

import styled from "styled-components";

export const Conversation = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

export const Name = styled.div`
  cursor: pointer;
  a {
    display: block;
    max-width: 13em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
