// @flow

import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from "react";
import * as R from "ramda";
import type { DownshiftProps } from "downshift";
import { useSelector, useDispatch } from "react-redux";
import ReactProgressiveList from "react-progressive-list";
import {
  Box,
  Button,
  Skeleton,
  VStack,
  Flex,
  Spinner,
  Input
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  getPrincipalChecklistFieldsList,
  getChartPrimaryFieldListLoading,
  getChartPrimaryFieldList,
  getValueFromQuery,
  getLocationPayload
} from "src/reducers";
import { getPrimaryFieldList } from "src/actions/chart";
import * as styles from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/styles.js";
import DropdownRow from "./DropdownRow";
import useBoolean from "src/hooks/useBoolean";
import Dropdown from "src/components/Dropdown";
import useDebounce from "src/hooks/useDebounce";
import { generateFieldId } from "src/utils/charts.js";
import SelectedField from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/SelectedField";
import { DropdownInputField } from "src/components/Dropdown/styles";

type Props = {
  chartDetails: Object,
  setChartDetails: Function,
  primaryFieldId: number
};

const PrimaryFieldDropdown = ({
  chartDetails,
  setChartDetails,
  primaryFieldId
}: Props) => {
  const downshiftRef = useRef<DownshiftProps>(null);
  const dispatch = useDispatch();
  const allFields = useSelector(({ app }) =>
    getPrincipalChecklistFieldsList(app)
  );
  const workflowId = useSelector(({ location }) =>
    getValueFromQuery("workflowId")(location)
  );

  const primaryFieldData = useSelector(({ app }) =>
    getChartPrimaryFieldList(app)
  );

  const loading = useSelector(({ app }) =>
    getChartPrimaryFieldListLoading(app)
  );

  const editId = useSelector(({ location }) =>
    getLocationPayload("id")(location)
  );

  const memoizedFieldList = useMemo(() => R.values(primaryFieldData), [
    primaryFieldData
  ]);

  const selectedField = primaryFieldData[primaryFieldId];

  const [query, setQuery] = useState("");
  const [filteredFields, setFilteredFields] = useState(memoizedFieldList);

  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  useEffect(() => {
    if (selectedField?.multiValue) {
      setChartDetails(prev => ({
        ...prev,
        isMultiValue: selectedField?.multiValue
      }));
    }

    if (!R.isEmpty(memoizedFieldList)) {
      setFilteredFields(memoizedFieldList);
    }

    if (editId && R.isEmpty(primaryFieldData)) {
      dispatch(getPrimaryFieldList(allFields, workflowId));
    }
  }, [primaryFieldData, memoizedFieldList, selectedField?.multiValue]);

  const filteredResults = query => {
    const result = memoizedFieldList.filter(({ name }) =>
      name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFields(result);
  };

  const debouncedSearch = useDebounce(filteredResults, 300);

  const handleSearch = useCallback(
    input => {
      setQuery(input);
      debouncedSearch(input);
    },
    [debouncedSearch]
  );

  const handleSelect = ({ item: field }) => {
    const {
      linkedProcessOrFormType,
      id,
      type,
      multiValue,
      linkedOrFormFieldId,
      linkedProcessOrFormId
    } = field;

    const selectedFieldId = generateFieldId({
      id,
      type,
      linkedProcessOrFormType,
      linkedProcessOrFormId,
      linkedOrFormFieldId
    });

    setChartDetails(prev => ({
      ...prev,
      x: selectedFieldId,
      isMultiValue: multiValue
    }));

    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: filteredFields[selectedIndex] });
  };

  return (
    <Box>
      <Button
        onClick={() => {
          toggleDropdown();
          R.isEmpty(memoizedFieldList) &&
            dispatch(getPrimaryFieldList(allFields, workflowId));
        }}
        sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
        rightIcon={
          isOpen ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )
        }
      >
        {loading ? (
          <Spinner size="sm" />
        ) : selectedField ? (
          <SelectedField selectedField={selectedField} />
        ) : (
          "Select Field"
        )}
      </Button>

      {isOpen && (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
          onKeyboardSelect={onKeyboardSelect}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView,
            keyboardHandler
          }) => {
            return (
              <Flex sx={styles.DropdownList}>
                <Input
                  placeholder="Search"
                  sx={DropdownInputField}
                  value={query}
                  onChange={event => handleSearch(event.target.value)}
                  onKeyDown={e =>
                    keyboardHandler({
                      event: e,
                      ref: downshiftRef,
                      onSelect: onKeyboardSelect,
                      onClose: closeDropdown
                    })
                  }
                  autoFocus
                />
                {loading ? (
                  <VStack sx={{ m: "0 0.5rem 0.5rem" }}>
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                  </VStack>
                ) : (
                  <ReactProgressiveList
                    initialAmount={12}
                    progressiveAmount={5}
                    role="list"
                    rowCount={filteredFields.length}
                    renderItem={index => {
                      if (filteredFields[index]) {
                        return (
                          <DropdownRow
                            isPrimaryField
                            selectedField={selectedField}
                            chartDetails={chartDetails}
                            item={filteredFields[index]}
                            key={filteredFields[index].id}
                            elId={index}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: filteredFields[index],
                              id: filteredFields[index].id,
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      }
                    }}
                  />
                )}
              </Flex>
            );
          }}
        </Dropdown>
      )}
    </Box>
  );
};

export default PrimaryFieldDropdown;
