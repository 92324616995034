// @flow

import React from "react";

import { ProgressContainer, ProgressFill, Text } from "./styles";

type Props = {
  progress: number
};

const ProgressBar = ({ progress, ...restProps }: Props) => {
  return (
    <ProgressContainer {...restProps}>
      <Text progress={progress}>Uploading file...</Text>
      <ProgressFill progress={progress} />
    </ProgressContainer>
  );
};

export default ProgressBar;
