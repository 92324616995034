// @flow
import React from "react";
import { connect } from "react-redux";

import { EmailSent as StyledSent, VerifiedStatus, ResendInfo } from "./styles";
import Icon from "src/icons";
import { srwSignUp } from "src/actions/signup";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";

type Props = {
  _srwSignUp: Function
};

const EmailReceived = ({ _srwSignUp }: Props) => {
  const { handleEnter } = useKeyDownHandlers(_srwSignUp);

  return (
    <StyledSent>
      <VerifiedStatus>
        <Icon type="verificationFailed" />
        Sign Up failed!
      </VerifiedStatus>
      <ResendInfo>
        Click here to{" "}
        <span
          role="button"
          tabIndex="0"
          onClick={_srwSignUp}
          onKeyDown={handleEnter}
        >
          resend
        </span>
      </ResendInfo>
    </StyledSent>
  );
};

export default connect(null, {
  _srwSignUp: srwSignUp
})(EmailReceived);
