// @flow

import { connect } from "react-redux";

import AllOrgs from "src/components/filter/AllOrgs";

import { getAllOrgDetails, getCurrentUser } from "src/reducers";

import * as actions from "src/actions/organization";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  orgs: getAllOrgDetails(app),
  last: app.orgs.last,
  currentUser: getCurrentUser(app)
});

export default connect(mapStateToProps, actions)(AllOrgs);
