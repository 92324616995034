// @flow

import React, { useState } from "react";
import {
  CardItemContainer,
  AppActions,
  AppLogo,
  AppDescription,
  AppDetails
} from "./styles";
import { Text } from "@chakra-ui/react";
import DisconnectButton from "./DisconnectButton";
import { Switch } from "src/components/Unifize";
import { integrations } from "src/constants/integrations";
import * as colors from "src/styles/constants/colors";
import InfoModal from "./InfoModal";
import DisableModal from "./DisableModal";
import DisconnectModal from "./DisconnectModal";
import useOAuthIntegration from "src/hooks/useOAuthIntegrationWindow";

type AppCardProps = {
  id: number,
  display: string,
  title: string,
  isDisabled: boolean,
  isInstalled: boolean,
  url: string,
  onInstall: Function,
  onUpdate: Function,
  onDisconnect: Function
};

const AppCard = ({
  id,
  display,
  title,
  isDisabled,
  isInstalled,
  url,
  onInstall,
  onUpdate,
  onDisconnect
}: AppCardProps) => {
  const [isDisableModalVisible, setDisableModalVisible] = useState(false);
  const [isDisconnectModalVisible, setDisconnectModalVisible] = useState(false);
  const [isInfoModalVisible, setInfoModalVisible] = useState(false);

  const popup = useOAuthIntegration({
    app: title,
    callback: onInstall
  });

  const handleInstall = () => {
    setInfoModalVisible(false);
    popup.start(url);
  };

  const handleSwitch = () => {
    if (!isInstalled) {
      setInfoModalVisible(true);
    } else if (isInstalled && isDisabled) {
      onUpdate({ id, status: false, display });
    } else {
      setDisableModalVisible(true);
    }
  };

  const getLogo = () => {
    const { icon }: Object = integrations.get(title);
    return icon;
  };

  const getDescription = () => {
    const { description }: Object = integrations.get(title);
    return description;
  };

  return (
    <CardItemContainer>
      <AppDetails>
        <AppLogo src={getLogo()} />
        <AppDescription>
          <Text textStyle="label" color={colors.ink}>
            {display}
          </Text>
          <Text noOfLines={1} textStyle="label" color={colors.grey44}>
            {getDescription()}
          </Text>
        </AppDescription>
      </AppDetails>
      <AppActions>
        {isInstalled && (
          <DisconnectButton
            onClick={() => setDisconnectModalVisible(prev => !prev)}
          />
        )}
        <Switch
          isChecked={isInstalled && !isDisabled}
          onChange={handleSwitch}
        />
      </AppActions>
      <DisableModal
        isOpen={isDisableModalVisible}
        onClose={() => setDisableModalVisible(false)}
        onDisable={() => onUpdate({ id, status: true, display })}
        title={display}
      />
      <DisconnectModal
        isOpen={isDisconnectModalVisible}
        onClose={() => setDisconnectModalVisible(false)}
        onDisconnect={() => onDisconnect({ id, display })}
        title={display}
      />
      <InfoModal
        isOpen={isInfoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        onInstall={handleInstall}
        title={display}
        name={title}
      />
    </CardItemContainer>
  );
};

export default AppCard;
