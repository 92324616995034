// @flow
import React from "react";

import doughnut2d from "src/assets/charts/doughnut2d.png";
import stackedcolumn2d from "src/assets/charts/stackedcolumn2d.png";
import comparisonChart from "src/assets/charts/comparison_chart.png";

const ChartImage = ({ type }: { type: string }) => {
  switch (type) {
    case "form-count-stacked":
    case "number-field-stacked":
    case "time-series-stacked":
      return <img src={stackedcolumn2d} alt="Bar chart" />;
    case "conversation-count-doughnut":
    case "form-count-doughnut":
      return <img src={doughnut2d} alt="Pie chart" />;
    case "column-area-line":
    case "stacked-area-line":
    case "ms-stacked-column-line":
      return <img src={comparisonChart} alt="Combination chart" />;
    default:
      return <img src={stackedcolumn2d} alt="Bar chart" />;
  }
};

export default ChartImage;
