// @flow

import React from "react";
import * as R from "ramda";

import { InputContainer, Textarea, HelperMessage } from "../styles";

type Props = {
  id: string,
  name?: string,
  placeholder?: string,
  helperMessage?: string,
  errorMessage?: string,
  textareaRef?: { current: null | HTMLTextAreaElement }
};

const FormTextarea = ({
  id,
  name = "",
  placeholder = "",
  helperMessage = "",
  textareaRef,
  ...rest
}: Props) => {
  return (
    <InputContainer>
      <Textarea
        {...(textareaRef ? { ref: textareaRef } : {})}
        {...rest}
        id={id}
        name={name}
        placeholder={placeholder}
      />
      {!R.isEmpty(helperMessage) && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}
    </InputContainer>
  );
};

export default FormTextarea;
