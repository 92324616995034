// @flow

import {
  Text,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  VStack,
  HStack,
  Checkbox
} from "@chakra-ui/react";
import React from "react";

import { checklistMaxWidths } from "./utils";
import type { Layout as LayoutType } from "src/types";

const maxWidthOptions = [
  {
    value: checklistMaxWidths["1x"],
    label: "1x"
  },
  {
    value: checklistMaxWidths["2x"],
    label: "2x"
  },
  {
    value: checklistMaxWidths["3x"],
    label: "3x"
  },
  {
    value: checklistMaxWidths["max"],
    label: "Max Width"
  }
];

type Props = {
  value: LayoutType,
  onUpdate: Function
};

const Layout = ({ value, onUpdate }: Props) => {
  return (
    <VStack
      sx={{
        alignItems: "start"
      }}
      spacing={4}
    >
      <Text textStyle="heading">2D Auto Layout</Text>

      <FormControl
        as={VStack}
        spacing={3}
        sx={{
          alignItems: "start"
        }}
      >
        <FormLabel>
          Maximum field width (smaller widths allow more fields on a row)
        </FormLabel>

        <RadioGroup
          value={value.maxWidth}
          onChange={newMaxWidth => onUpdate({ maxWidth: newMaxWidth })}
        >
          <HStack spacing={6}>
            {maxWidthOptions.map(option => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
      </FormControl>

      <Checkbox
        isChecked={value.startOnNewRow}
        onChange={e => onUpdate({ startOnNewRow: e.target.checked })}
      >
        Force start on a new row
      </Checkbox>
    </VStack>
  );
};

export default Layout;
