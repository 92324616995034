// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Input,
  FormHelperText
} from "@chakra-ui/react";
import FieldSelect from "../FieldSelect";
import TimeInterval from "./TimeInterval";
import TimePeriod from "./TimePeriod";
import Columns from "./Columns";
import Switch from "./Switch";
import {
  setNewChartDialogAttributes,
  createChart,
  updateChart
} from "src/actions/chart";

import type { AppState, NewChartDialog } from "src/types";

type Props = {
  newChartDialog: NewChartDialog,
  _editChart: Function,
  _createChart: Function,
  _setNewChartDialogAttributes: Function
};

const TimeseriesBarChart = ({
  newChartDialog,
  _editChart,
  _createChart,
  _setNewChartDialogAttributes
}: Props) => {
  const handleYaxisLabel = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        yAxisLabel: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleNumericalField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        computationField: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleFields = useCallback(() => {
    _setNewChartDialogAttributes({
      loading: true
    });
    if (newChartDialog.edit) {
      _editChart();
    } else {
      _createChart();
    }
  }, [_setNewChartDialogAttributes, _editChart, _createChart, newChartDialog]);

  const handleTimeInterval = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        timeInterval: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );

  return (
    <>
      <FormControl id="numericalField" mb={3}>
        <FormLabel mr="0">
          Numeric checklist field
          <Switch value={newChartDialog.numberFieldSwitch || false} />
        </FormLabel>
        <FormHelperText>
          This field will contain the numerical data to add, like $ values,
          total time, or units
        </FormHelperText>
        <FieldSelect
          disable={!(newChartDialog.numberFieldSwitch || false)}
          value={newChartDialog.computationField}
          dependentField={newChartDialog.computationField}
          handleSelect={handleNumericalField}
          fieldType="numeric"
          numberField
        />
      </FormControl>

      <FormControl id="timeInterval" mb={3} isRequired>
        <FormLabel>Time interval (Frequency)</FormLabel>
        <FormHelperText>
          The interval at which the numerical value selected above will repeat
        </FormHelperText>
        <TimeInterval
          value={newChartDialog.timeInterval}
          handleSelect={handleTimeInterval}
        />
      </FormControl>

      <FormControl id="timePeriod" mb={3} isRequired>
        <FormLabel>Time period</FormLabel>
        <FormHelperText>
          Select a time frame or a date range for the chart
        </FormHelperText>
        <TimePeriod
          timePeriod={newChartDialog.timeDiff}
          timeInterval={newChartDialog.timeInterval}
        />
      </FormControl>

      <FormControl id="yAxisLabel" mb={3}>
        <FormLabel>Y-axis label</FormLabel>
        <FormHelperText>Label for Y-axis of the chart</FormHelperText>
        <Input
          type="text"
          value={newChartDialog.yAxisLabel}
          onChange={handleYaxisLabel}
          placeholder="Enter Label"
          size="sm"
        />
      </FormControl>

      <FormControl id="column" mb={3} isRequired>
        <FormLabel>Column</FormLabel>
        <FormHelperText>
          Show a column for each of the following:
        </FormHelperText>
        <Columns value={newChartDialog.columns} />
      </FormControl>

      <Box mb={3} mt={6}>
        {newChartDialog.edit ? (
          <Button
            onClick={handleFields}
            disabled={
              newChartDialog.title.length === 0 ||
              !(
                newChartDialog.columns?.created ||
                newChartDialog.columns?.completed
              ) ||
              newChartDialog.timeDiff == null
            }
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Save changes
          </Button>
        ) : (
          <Button
            onClick={handleFields}
            disabled={
              newChartDialog.title.length === 0 ||
              !(
                newChartDialog.columns?.created ||
                newChartDialog.columns?.completed
              ) ||
              newChartDialog.timeDiff === null
            }
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            Create and save
          </Button>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _createChart: createChart,
  _editChart: updateChart
})(TimeseriesBarChart);
