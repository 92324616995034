// @flow

import styled from "@emotion/styled";

import {
  ModalProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  TextProps,
  StackProps,
  ButtonProps,
  RadioProps,
  IconProps,
  IconButtonProps
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const modal: ModalProps = {
  isCentered: true,
  scrollBehavior: "inside"
};

export const modalContent: ModalContentProps = {
  maxHeight: "400px"
};

export const modalHeader: ModalHeaderProps = {
  bgColor: "unifize_grey0",
  p: 4,
  height: "56px",
  fontSize: "xl",
  fontWeight: 700,
  m: 0,
  borderTopRadius: "4px",

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

export const closeIconButton: IconButtonProps = {
  background: "transparent",
  color: "black",

  _hover: {
    background: "transparent",
    color: "black"
  },

  _focus: {
    boxShadow: "none"
  }
};

export const closeIcon: IconProps = {
  background: "transparent",
  color: "black"
};

export const modalBody: ModalBodyProps = {
  px: 4,
  pt: 4
};

export const centerAlign: StackProps = {
  alignItems: "center",
  spacing: 1
};

export const autoLinkText: StackProps = {
  spacing: 1
};

export const boldText: TextProps = {
  my: 0,
  fontWeight: 700,
  fontSize: "14px"
};

export const normalText: TextProps = {
  my: 0,
  fontSize: "14px"
};

export const versionComment: TextProps = {
  my: 0,
  fontSize: "12px",
  color: "unifize_inkLighter"
};

export const modalFooter: ModalFooterProps = {
  justifyContent: "flex-start",
  gridGap: 4,
  px: 4
};

export const editButton: ButtonProps = {
  variant: "transparentLink",
  px: 0
};

export const radioOptions: StackProps = {
  alignItems: "start",
  spacing: 3
};

export const radio: RadioProps = {
  alignItems: "start"
};

export const removeLinkBody: StackProps = {
  alignItems: "center",
  spacing: 3
};

export const removeLinkModalIcon = {
  width: "16",
  size: "16",
  fill: colors.inkLightest
};

export const removeLinkModalFooter: StackProps = {
  spacing: 4
};

export const multiEditFirstOption: StackProps = ({ linkType }) => ({
  alignItems: "center",
  padding: [1.5, 2],
  borderRadius: "4px",
  width: "100%",
  border: `${linkType === "auto" ? "2" : "1"}px solid`,
  borderColor: linkType === "auto" ? "unifize_activeFilter" : "unifize_skyDark"
});

export const multiEditRemainingOptions: StackProps = ({ linkType }) => ({
  alignItems: "start",
  padding: [1.5, 2],
  borderRadius: "4px",
  width: "100%",
  border: `${linkType === "manual" ? "2" : "1"}px solid`,
  borderColor:
    linkType === "manual" ? "unifize_activeFilter" : "unifize_skyDark"
});

export const multiEditCheckboxGroup: StackProps = {
  alignItems: "start",
  pl: 6
};

export const FieldWrapper = styled.div`
  width: 100%;
`;
