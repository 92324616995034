// @flow

import React from "react";
import { connect } from "react-redux";

import Common from "./Common";
import LinkedRoom from "./LinkedRoom";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import type { Message, RoomId, ChatroomAddress } from "src/types";

type Props = {
  currentRoom: RoomId,
  roomAddress: ChatroomAddress,
  message: Message
};

const Title = ({ currentRoom, roomAddress, message }: Props) => {
  const { data } = message;
  const { new: newTitle, old: oldTitle, roomId } = data;
  let showLink = false;
  let notifMssg = "";
  if (roomId === currentRoom) notifMssg = "the";
  else [notifMssg, showLink] = ["related conversation", true];

  if (newTitle && oldTitle)
    return (
      <Common {...message}>
        {" "}
        updated {notifMssg} title to{" "}
        {showLink ? (
          <LinkedRoom id={roomId} chatroomAddress={roomAddress} />
        ) : (
          <strong>{newTitle}</strong>
        )}{" "}
        from {oldTitle}
      </Common>
    );
  if (newTitle)
    return (
      <Common {...message}>
        {" "}
        added {notifMssg} title{" "}
        {showLink ? (
          <LinkedRoom id={roomId} chatroomAddress={roomAddress} />
        ) : (
          <strong>{newTitle}</strong>
        )}
      </Common>
    );
  return (
    <Common {...message}>
      {" "}
      removed {notifMssg} title <strong>{oldTitle}</strong>
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => {
  const { data } = message;
  const { roomId } = data;
  return {
    currentRoom: parseInt(getCurrentRoomId(app), 10),
    roomAddress: getChatroomAddress(app, roomId)
  };
};

export default connect(mapStateToProps)(Title);
