// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

const Radio = styled.div`
  display: inline-block;
  padding-left: 4px;
  > label {
    cursor: pointer;
    ${({ disabled }) =>
      disabled &&
      `cursor: not-allowed;
      opacity: 0.5;
    `}
    outline: none;
    > span:last-child {
      padding-left: 0.4em;
    }
    > input[type="radio"] {
      display: none;
    }
    :hover {
      > input[type="radio"] + div {
        > span {
          background: ${colors.grey9};
        }
      }
    }
    > input[type="radio"] + div {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${colors.grey6};
      border-radius: 50%;
      height: 1em;
      width: 1em;
      position: relative;
      top: -1px;
      > span {
        display: inline-block;
        height: 0.5em;
        width: 0.5em;
        border-radius: 50%;
      }
    }
    > input[type="radio"]:checked + div {
      border: 1px solid ${colors.active};
      > span {
        background: ${colors.active} !important;
      }
    }
  }
`;

export default Radio;
