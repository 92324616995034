// @flow

import React from "react";
import { FormControl, FormLabel, Input, Flex } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import Tooltip from "src/components/Tooltip";
import * as styles from "./styles";
import Icon from "src/icons";
import PrimaryFieldDropdown from "src/components/PrimaryFieldDropdown";
import { getChartFieldId } from "src/utils/charts.js";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const PrimaryField = ({ chartDetails, setChartDetails }: Props) => {
  const { x } = chartDetails;
  const primaryFieldId = x && getChartFieldId(x);

  return (
    <Flex sx={styles.FlexColumn}>
      <FormControl>
        <Flex sx={styles.FormLabelWrapper}>
          <FormLabel sx={styles.Label}>Description</FormLabel>
        </Flex>
        <Input
          onChange={e =>
            setChartDetails(prevState => ({
              ...prevState,
              description: e.target.value
            }))
          }
          value={chartDetails.description}
          sx={styles.BuilderInput}
          placeholder="e.g. Graphical representation of the pending enquiries for Steam Turbines categorised by owner"
        />
      </FormControl>

      <FormControl isRequired>
        <Flex sx={styles.FormLabelWrapper}>
          <FormLabel sx={styles.Label}>Primary field</FormLabel>
          <Tooltip
            sx={{ backgroundColor: "none" }}
            title="This will be the x-axis series"
            placement="top-start"
          >
            <Icon
              type="info"
              fillColor={colors.inkLightest}
              width="16px"
              height="16px"
            />
          </Tooltip>
        </Flex>

        <PrimaryFieldDropdown
          chartDetails={chartDetails}
          setChartDetails={setChartDetails}
          primaryFieldId={primaryFieldId}
        />
      </FormControl>
    </Flex>
  );
};

export default PrimaryField;
