// @flow

import React from "react";

const SortBy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#16C98D"
      d="M2 12h4v-1.333H2V12zm0-8v1.333h12V4H2zm0 4.667h8V7.333H2v1.334z"
    />
  </svg>
);

export default SortBy;
