// @flow

import React from "react";

const Upload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2A2A2A"
      d="M8.533 15.5c0 .276-.238.5-.533.5-.295 0-.533-.224-.533-.5v-11c0-.276.238-.5.533-.5.295 0 .533.224.533.5v11z"
    />
    <path
      fill="#2A2A2A"
      d="M3.044 9.854a.559.559 0 0 1-.754 0 .478.478 0 0 1 0-.708l5.333-5a.559.559 0 0 1 .754 0l5.333 5a.478.478 0 0 1 0 .708.559.559 0 0 1-.754 0L8 5.207 3.044 9.854zM15.467 0c.294 0 .533.224.533.5s-.239.5-.533.5H.533C.24 1 0 .776 0 .5S.239 0 .533 0h14.934z"
    />
  </svg>
);

export default Upload;
