// @flow

import React from "react";

import { HistoryPlaceholder, ChatPlaceholder } from "./styles";
import PulseLoader from "src/components/PulseLoader";

const History = () => (
  <HistoryPlaceholder>
    <PulseLoader maxWidth="450px" h="18px" />
    <PulseLoader w="240px" h="18px" />
    <PulseLoader w="150px" h="18px" />
    <PulseLoader w="200px" h="18px" />
    <ChatPlaceholder>
      <div />
      <div>
        <PulseLoader w="100%" h="160px" />
      </div>
    </ChatPlaceholder>
  </HistoryPlaceholder>
);

export default History;
