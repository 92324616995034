// @flow

import { useCallback, useEffect } from "react";

const useClickoutside = ({
  outerRef,
  handleClose,
  disableEnter
}: {
  outerRef: any,
  disableEnter?: boolean,
  handleClose: Function
}) => {
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Enter" && !disableEnter) {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  const clickOutside = useCallback(
    (event: any) => {
      if (outerRef && outerRef.current) {
        if (!outerRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, true);
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("keydown", closeModal, true);
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);
};

export default useClickoutside;
