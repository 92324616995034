// @flow

export const iconSize = {
  small: "0.75em",
  normal: "1em",
  medium: "1.25em",
  large: "1.75em",
  big: "2em",
  xbig: "2.5em",
  xlarge: "3em",
  huge: "4em",
  massive: "8em"
};

export const xSmall = "9px";
export const small = "10px";
export const regular = "12px";
export const large = "14px";
export const xLarg = "16px";
export const xxLarg = "18px";

export const filterNavWidth = "10em";
export const asideWidth = "35em";
export const chatRoomMenuWidth = "15em";
export const mainWidth = "27em";
export const dockWidth = "60px";
export const dockContentWidth = "30em";
