// @flow

import { connect } from "react-redux";

import Name from "src/components/department/Name";
import { getDepartment } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  department: getDepartment(app, props.id)
});

export default connect(mapStateToProps)(Name);
