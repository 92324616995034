// @flow

import React from "react";

import { NameOrEmail as StyledEmail } from "./styles";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const Email = ({ user }: Props) => (
  <StyledEmail title={user.email}>
    <span>{user.loading ? "Loading user..." : user.email}</span>
  </StyledEmail>
);

export default Email;
