// @flow

const pica = require("pica")({ features: ["js", "ww", "cib"] });

const resizedCanvas = document.createElement("canvas");
resizedCanvas.height = 128;
resizedCanvas.width = 128;

const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

const resizeImage = async (originalImage: Object): Promise<File> => {
  const { filename } = originalImage;
  const { filetype } = originalImage;
  const result = await pica.resize(originalImage, resizedCanvas, {
    unsharpRadius: 0.6,
    unsharpThreshold: 2
  });
  const blob = pica.toBlob(result, filetype, 0.9);
  const resizeImageFile = blobToFile(blob, filename);
  return resizeImageFile;
};

export default resizeImage;
