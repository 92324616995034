// @flow

import React from "react";

type Props = {};

const MoreMenu = ({ ...restProps }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M13.6 8.5H2.4C2.1788 8.5 2 8.2765 2 8C2 7.7235 2.1788 7.5 2.4 7.5H13.6C13.8212 7.5 14 7.7235 14 8C14 8.2765 13.8212 8.5 13.6 8.5Z"
      fill="#1581BA"
    />
    <path
      d="M13.6 4H2.4C2.1788 4 2 3.7765 2 3.5C2 3.2235 2.1788 3 2.4 3H13.6C13.8212 3 14 3.2235 14 3.5C14 3.7765 13.8212 4 13.6 4Z"
      fill="#1581BA"
    />
    <path
      d="M13.6001 13H8.0001C7.7789 13 7.6001 12.7765 7.6001 12.5C7.6001 12.2235 7.7789 12 8.0001 12H13.6001C13.8213 12 14.0001 12.2235 14.0001 12.5C14.0001 12.7765 13.8213 13 13.6001 13Z"
      fill="#1581BA"
    />
  </svg>
);

export default MoreMenu;
