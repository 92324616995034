// @flow
import React, { useCallback, useContext } from "react";

import { ModalBody } from "@chakra-ui/react";

import type { ImportedProcess, ImportModalAction } from "src/types";
import * as colors from "src/styles/constants/colors";
import { appendImportTitleString as appendTitle } from "src/utils/workflow";
import Radio from "src/components/Radio";

import { importActions as A, ImportStateContext } from "./importReducer";
import {
  modalBody,
  PanelTitle,
  PanelButtons,
  PanelBody,
  CollidedTemplate,
  CollidedTemplateRadio
} from "./styles";
import ModalButton from "../../ModalButton";

type Props = {
  handleNextCollision: ?() => void | Promise<void>,
  dispatch: ImportModalAction => void,
  handleBack: () => void
};

const CollisionPanel = ({
  handleNextCollision,
  handleBack,
  dispatch
}: Props) => {
  const { collisions } = useContext(ImportStateContext);
  const handleChange = useCallback(
    (e: "copy" | "exclude", process: ImportedProcess, index: number) => {
      dispatch({
        type: A.collisions,
        payload: {
          collisions: [
            ...collisions.slice(0, index),
            {
              ...process,
              title: appendTitle(process),
              copy: e === "copy",
              exclude: e === "exclude"
            },
            ...collisions.slice(index + 1)
          ]
        }
      });
    },
    [collisions]
  );
  return (
    <ModalBody sx={modalBody}>
      <PanelTitle>
        The following processes already exist. How would you like to proceed?
      </PanelTitle>
      <PanelBody>
        {collisions.map((process, index) => (
          <CollidedTemplate key={`${process.id}_${index}`}>
            {process.title}
            {process?.copy}
            <CollidedTemplateRadio>
              <Radio
                id={`notcopy${process.id}-${index}`}
                value={`notcopy${process.id}-${index}`}
                checked={
                  process?.copy === undefined ? true : process?.copy === false
                }
                handleChange={() => handleChange("exclude", process, index)}
              >
                <label>Do not import</label>
              </Radio>
              <Radio
                id={`copy${process.id}-${index}`}
                value={`copy${process.id}-${index}`}
                checked={process?.copy === true}
                handleChange={() => handleChange("copy", process, index)}
              >
                <label>Import as copy</label>
              </Radio>
            </CollidedTemplateRadio>
          </CollidedTemplate>
        ))}
      </PanelBody>
      <PanelButtons>
        <ModalButton
          label="Back"
          onClick={handleBack}
          loading={false}
          disabled={false}
          color={colors.grey41}
          textColor="#101828"
          margin="0"
        />
        <ModalButton
          label="Next"
          onClick={handleNextCollision}
          loading={false}
          disabled={false}
          margin="0"
        />
      </PanelButtons>
    </ModalBody>
  );
};

export default CollisionPanel;
