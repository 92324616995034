// @flow

import React from "react";

const BlueCross = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.80571L11.1943 4L8 7.19429L4.80571 4L4 4.80571L7.19429 8L4 11.1943L4.80571 12L8 8.80571L11.1943 12L12 11.1943L8.80571 8L12 4.80571Z"
      fill="#2F80ED"
    />
  </svg>
);

export default BlueCross;
