// @flow
import React from "react";

import type { UID } from "src/types";

import UserTag from "./UserTag";

import type { UseBooleanReturn } from "src/types";

type Props = {
  user: UID,
  closeDropdown: $PropertyType<UseBooleanReturn, "setFalse">,
  toggleDropdown: $PropertyType<UseBooleanReturn, "setTrue">,
  edit: $PropertyType<UseBooleanReturn, "value">
};

const SingleSelection = ({
  user,
  closeDropdown,
  toggleDropdown,
  edit
}: Props) => {
  return (
    <>
      {user && (
        <UserTag
          uid={user}
          closeDropdown={closeDropdown}
          toggleDropdown={toggleDropdown}
          edit={edit}
        />
      )}
    </>
  );
};

export default SingleSelection;
