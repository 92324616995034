// @flow

import React from "react";
import * as R from "ramda";

import type { ChecklistHeader } from "src/types";

type Props = {
  id: number,
  checklists: Array<ChecklistHeader>
};

const Name = ({ checklists, id }: Props) => {
  const checklist = R.head(R.filter(c => c.id === id, checklists));

  return <span>{checklist ? checklist.title : ""}</span>;
};

export default Name;
