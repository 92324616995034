// @flow

import { connect } from "react-redux";

import Me from "src/components/user/Me";
import { getCurrentUserId } from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUserId: getCurrentUserId(app)
});

export default connect(mapStateToProps, {})(Me);
