// @flow

import React from "react";

import type { AdvancedBulkUpdateFunction } from "./index";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean,
  onClose: Function,
  bulkUpdate: AdvancedBulkUpdateFunction
};

export default function AdvancedBulkUpdater({
  isOpen,
  onClose,
  bulkUpdate
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Apply bulk changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to apply changes to multiple conversations?
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => bulkUpdate("append")}
          >
            Repeat last action to all
          </Button>

          <Button variant="ghost" onClick={() => bulkUpdate("replace")}>
            Replace all
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
