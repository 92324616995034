// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, Form, RoomId, FieldId } from "src/types";

export const createForm = (form: Form) => ({
  type: atypes.CREATE_FORM_REQUEST,
  payload: {
    form
  }
});

export const deleteForm = (id: number) => ({
  type: atypes.DELETE_FORM_SUCCESS,
  payload: { formId: id }
});

export const getAllForm = () => ({
  type: atypes.FETCH_FORMS_REQUEST,
  payload: {}
});

export const updateForm = (form: Form) => ({
  type: atypes.UPDATE_FORM_REQUEST,
  payload: {
    form
  }
});

export const openFormModal = () => ({
  type: atypes.OPEN_FORM_MODAL,
  payload: {}
});

export const closeFormModal = () => ({
  type: atypes.CLOSE_FORM_MODAL,
  payload: {}
});

export const searchForm = (searchQuery: string) => ({
  type: atypes.SEARCH_FORMS_REQUEST,
  payload: searchQuery
});

export const fetchForm = (formId: number, modal: boolean = true) => ({
  type: atypes.FETCH_FORM_REQUEST,
  payload: { id: formId, modal }
});

export const setForm = (meta: { query?: string }): Action => ({
  type: atypes.SET_FORMS_REQUEST,
  payload: {},
  meta
});

export const getUniqueValues = (formId: number): Action => ({
  type: atypes.GET_FORM_UNIQUE_INSTANCE_VALUES_REQUEST,
  payload: { formId }
});

export const downloadInstance = (formId: number, filter: Object) => ({
  type: atypes.GET_FORM_INSTANCE_FILE_REQUEST,
  payload: {
    formId,
    filter
  }
});

export const toggleFormRowSelection = (row: number) => ({
  type: atypes.TOGGLE_FORM_ROW_SELECTION,
  payload: { row }
});

export const clearFormRowSelection = () => ({
  type: atypes.CLEAR_FORM_ROW_SELECTION,
  payload: {}
});

export const updateChecklistFromFormManageView = (
  roomId: RoomId,
  value: Object,
  index: number,
  id: FieldId,
  formId: number
) => ({
  type: atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_REQUEST,
  payload: {
    roomId,
    value,
    index,
    id,
    formId
  }
});
