// @flow

import { SystemCSSProperties } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

export const container: SystemCSSProperties = {
  width: "100%"
};

export const signatureModalContent: SystemCSSProperties = {};

export const signatureModalBody: SystemCSSProperties = {
  p: "1.5rem 2rem",
  pb: 0
};

export const singatureModalHeader: SystemCSSProperties = {
  p: "1rem 1.5rem",
  backgroundColor: "unifize_grey0",
  fontSize: "xl",
  fontWeight: "700",
  margin: "0"
};

export const actionButton: SystemCSSProperties = {
  width: "100%",
  borderRadius: "4px",
  bgColor: colors.lightBlue25,
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "20px",
  "&:disabled": {
    opacity: 1,
    color: colors.inkLightest,
    bgColor: colors.grey43,
    borderColor: colors.inkLightest
  }
};

export const formFieldWrapper: SystemCSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  /* For label and input wrapper */
  "> div:first-of-type": {
    width: "100%",
    "> label": {
      flexGrow: 1,
      margin: 0,
      minWidth: "80px"
    },
    "> input": {
      flexGrow: 1,
      width: "100%"
    }
  },
  /* For helper text below each field */
  "> div:not(:first-of-type)": {
    marginTop: "0",
    fontSize: "12px"
  }
};

export const formFieldInput: SystemCSSProperties = {
  height: "32px",
  borderRadius: "4px",
  fontSize: "0.875rem",
  border: `1px solid ${colors.grey40}`,
  outline: "none",
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  }
};

export const systemGeneratedContainer: SystemCSSProperties = {
  flexGrow: 1,
  "> label > span": {
    whiteSpace: "nowrap"
  }
};

export const modalFooter: SystemCSSProperties = {
  width: "100%",
  alignItems: "end",
  padding: "1rem 2rem 1.25rem 1rem"
};

export const modalCloseButton: SystemCSSProperties = {
  margin: "0.5rem 0",
  background: "transparent"
};

export const confirmButton: SystemCSSProperties = {
  "&:disabled": {
    opacity: 1,
    bgColor: colors.grey42
  },
  "&:hover:disabled": {
    bgColor: colors.grey42,
    opacity: 1
  }
};
