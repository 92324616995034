// @flow

import React, { useCallback } from "react";

type Props = {
  chatroomType?: string
};

const Chatroom = ({ chatroomType }: Props) => {
  const renderWorkflow = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 15 15"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3.5 7.5h11M11 4l3.5 3.5L11 11M.5.5v14M6.5.5v4M6.5 10.5v4" />
        </g>
      </svg>
    ),
    []
  );

  const renderApproval = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12.8"
        viewBox="0 0 15 16"
      >
        <g fill="none" fillRule="evenodd">
          <path
            stroke="#888"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M.5 5.5v-5h5"
          />
          <path
            fill="#888"
            fillRule="nonzero"
            d="M14 1H9.5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 1 1-1 0V1zM.146.854A.5.5 0 0 1 .854.146l7 7A.5.5 0 0 1 8 7.5v8a.5.5 0 1 1-1 0V7.707L.146.854z"
          />
          <path
            fill="#888"
            fillRule="nonzero"
            d="M14.146.146a.5.5 0 0 1 .708.708l-5 5a.5.5 0 1 1-.708-.708l5-5z"
          />
        </g>
      </svg>
    ),
    []
  );

  const renderTask = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13.7"
        viewBox="0 0 14 16"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M11.5 1.5h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h1" />
          <path d="M4.5.5h5v2h-5zM4 9l2 2 4-4" />
        </g>
      </svg>
    ),
    []
  );

  const renderFeed = useCallback(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 16 16"
      >
        <g fill="none" fillRule="nonzero" stroke="#979797">
          <path d="M15 15.5C15 7.492 8.508 1 .5 1M12 15.5C12 9.149 6.851 4 .5 4M9 15.5A8.5 8.5 0 0 0 .5 7M6 15.5A5.5 5.5 0 0 0 .5 10" />
        </g>
      </svg>
    ),
    []
  );

  const renderDiscussion = useCallback(
    () => (
      <svg
        viewBox="-2 -1 24 24"
        preserveAspectRatio="xMinYMin slice"
        height="12"
        width="12"
        stroke="#888888"
        fill="none"
      >
        <g>
          <path d="M11.719.469L14.53 3.28h-3.004a3.75 3.75 0 0 0-2.803 1.26l-5.262 5.92A3.752 3.752 0 0 1 .66 11.719H.469M.469 3.281H.66a3.75 3.75 0 0 1 2.803 1.26l.964 1.084M11.719 14.531l2.812-2.812h-3.004a3.75 3.75 0 0 1-2.803-1.26l-.963-1.084" />
        </g>
      </svg>
    ),
    []
  );

  switch (chatroomType) {
    case "approval":
      return renderApproval();
    case "group":
      return renderFeed();
    case "task":
      return renderTask();
    case "workflow":
      return renderWorkflow();
    case "discussion":
    case "conversation":
      return renderDiscussion();
    default:
      return <></>;
  }
};

export default Chatroom;
