// @flow

import React from "react";
import moment from "moment";

import { User as StyledUser, Time, Members as StyledMembers } from "./styles";
import ChatroomTitle from "./ChatroomTitle";
import Members from "src/components/Group/Users";
import User from "src/containers/user/ImageLabel/Small";

type Props = {
  conversation: Object
};

const ProcessRow = ({ conversation }: Props) => {
  return (
    <tr>
      <ChatroomTitle id={conversation.id} />
      <StyledMembers>
        <Members users={conversation.members} />
      </StyledMembers>
      <StyledUser>
        {conversation.owner ? <User uid={conversation.owner} /> : null}
      </StyledUser>
      <Time>
        {conversation.createdAt
          ? moment(conversation.createdAt).format("MMM DD, YYYY hh:mm A")
          : null}
      </Time>
      <Time>
        {conversation.completedAt
          ? moment(conversation.completedAt).format("MMM DD, YYYY hh:mm A")
          : null}
      </Time>
    </tr>
  );
};

export default ProcessRow;
