// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { colors } from "./constants";
import * as radius from "src/styles/constants/radius";

export const AttachButton = styled.button`
  cursor: pointer;
  outline: none;
  background: inherit;
  border: none;
  width: 118px;
  padding-left: 21px;
  position: absolute;
  right: 72px;
  top: 8px;
`;

export const AddFolder = styled.div`
  display: flex;

  div {
    display: inline-block;
    margin: 1em 5px 1em 0;
    cursor: pointer;
  }

  div:nth-of-type(2) {
    color: #2e4c68;
    padding-top: 4px;
    font-size: 11px;
  }
`;

export const CreateFolderForm = styled.form`
  padding: 0 !important;
  margin-top: 10px;
  text-align: center;

  div {
    display: flex;
    justify-content: space-evenly;
  }

  input {
    width: 80%;
    border: solid 1px #e7e8ec;
    border-radius: 2px;
  }

  button {
    border-radius: 5px;
    width: 100px;
    height: 30px;
  }
`;

export const UploadOptionHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.simpleGrey};
  padding: 1em;
  height: 50px;
  border-radius: 5px;
  border: solid 1px ${colors.simpleGrey};
  justify-content: space-evenly;

  div:nth-last-child(1) {
    color: #4b4b4b;
    text-align: center;
    margin-top: 0.5em;
  }
`;

export const UploadOption = styled.div`
  color: #46454c;

  textarea {
    margin-top: 1em;
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 5px;
    background-color: #ffffff;
    resize: none;
  }

  > div {
    margin-top: 1em;
  }

  > div:nth-last-child(1) {
    display: flex;
    justify-content: space-between;
  }
`;

export const UploadOptionButton = styled.button`
  border-radius: 5px;
  border: solid 1px #979797;
  padding: 7px;
  background-color: #fff;
  min-width: 50px;
  cursor: pointer;
  color: #807e82;

  ${props =>
    props.continue &&
    css`
      background-color: rgba(151, 219, 107, 0.2);
    `};

  ${({ cancel }) =>
    cancel &&
    css`
      background-color: rgba(255, 0, 0, 0.2);
    `};
`;

export const FileHistoryPopover = styled.div`
  width: 296px;
  z-index: 100;
  color: #46454c;
  border: thin solid ${colors.simpleGrey};
  border-radius: 0.25em;
  background-color: ${colors.pureWhite};
  padding: 5px;
  font-size: 12px;

  a {
    font-size: 10px;
  }
`;

export const UploadButton = styled.div`
  display: inline;

  > button {
    padding: 7px 50px 0 0;
    width: 100%;
    height: 80%;
    right: 0px;
    top: 0px;
  }

  > svg {
    float: right;
    position: relative;
    top: 2px;
    right: 14px;
    cursor: pointer;
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
  position: relative;
  max-width: 300px;

  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  li {
    position: relative;
    margin-bottom: 0.5em;
    width: 100%;
    padding: 5px;
    display: flex;
    border-bottom: solid 1px ${colors.simpleGrey};
    color: rgba(75, 75, 75, 0.8);
    align-items: center;

    > * {
      margin-right: 0.5em;
      align-items: center;
    }

    &:hover {
      cursor: pointer;
      ${({ hover }) =>
        hover &&
        css`
          background-color: #f5f6f7;
        `};
    }

    &.active {
      background-color: #f5f6f7;
    }

    div.icon {
      height: 15px;
      width: 18px;
    }

    img {
      height: 15px;
      width: 18px;
      margin-bottom: 0;
    }

    div.name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 72%;

      &: hover {
        overflow: visible;
        white-space: normal;
      }
    }

    .dot-menu {
      display: none;
    }

    &:hover .dot-menu {
      display: inline;
    }
  }
`;

export const FileVersion = styled.button`
  max-width: 30px;
  color: #2a2e36;
  background-color: rgba(187, 187, 187, 0.3);
  border: none;
  cursor: pointer;
`;

export const FileListItem = styled.li`
  display: flex;
  margin: 0.2em;

  span {
    margin-left: 0.5em;
  }
`;

export const StyledFolderList = styled(FileListItem)`
  cursor: pointer;
`;

export const FolderBrowserNav = styled.div`
  display: flex;
  flex-flow: wrap;
  color: #2a2a2a;

  a {
    color: #2a2a2a;
  }

  margin-top: 0.5em;

  label {
    margin-left: 0.5em;
  }

  span: last-child {
    margin-left: auto;
    margin-right: 0.5em;
  }
`;

export const FolderNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  >div: nth-of-type(1) {
    width: 90%;
  }
`;

export const SortBy = styled.div`
  display: flex;
  align-items: center;
  color: rgba(75, 75, 75, 0.8);
  margin-top: 1em;
`;

export const Display = styled.div`
  display: flex;
  margin-bottom: 1.2em;
`;

export const FileName = styled(Display)`
  margin-bottom: 0px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0em;
    > label {
      padding: 0px;
    }
    > span {
      width: 390px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const Thumbnail = styled.div`
  > div {
    width: 64px;
    height: 64px;
    object-fit: contain;
    border-radius: ${radius.medium};
  }
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 0.88rem;
  color: ${colors.black};
`;
