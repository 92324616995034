// @flow

import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import { SearchResult } from "./styles";
import IconLabel from "src/components/IconLabel";
import type { Workflow } from "src/types";
import { iconSize } from "src/styles/constants/size";

type Props = {
  focus: boolean,
  workflow: Workflow,
  handleSelect: Function
};

class Item extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { focus } = this.props;
    if (prevProps.focus !== focus) {
      if (focus) {
        // eslint-disable-next-line react/no-find-dom-node
        const target = findDOMNode(this);
        // $FlowFixMe
        target.parentNode.scrollTop = target.offsetTop - 5;
      }
    }
  }

  selectItem = (e: any) => {
    const { workflow, handleSelect } = this.props;
    e.stopPropagation();
    handleSelect(workflow);
  };

  render() {
    const { focus, workflow } = this.props;
    return (
      <SearchResult
        data-cy="dropdownItem"
        tabIndex="0"
        onClick={this.selectItem}
        onKeyPress={this.selectItem}
        focus={focus}
        aria-selected={focus}
      >
        <IconLabel
          icon="workflow"
          label={workflow.title}
          size={iconSize.space_l}
        />
      </SearchResult>
    );
  }
}

export default Item;
