// @flow

export const checklistMaxWidths = Object.freeze({
  "1x": ("1": "1"),
  "2x": ("2": "2"),
  "3x": ("3": "3"),
  max: ("max": "max")
});

export const getDefaultFieldLayout = (fieldType: string) => {
  let defaultFieldLayout = {
    maxWidth: checklistMaxWidths["max"],
    startOnNewRow: false
  };

  switch (fieldType) {
    case "date":
    case "number":
    case "pdf":
    case "select":
    case "text":
    case "user":
      return { ...defaultFieldLayout, maxWidth: checklistMaxWidths["1x"] };
    default:
      return defaultFieldLayout;
  }
};
